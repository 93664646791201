import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  time: '',
};
// {
//   open: true,
//   onCallRejectEnd: onCallRejectEnd,
//   handleMuteAndUnmute: handleMuteAndUnmute,
// }

export const agoraMiniSlice = createSlice({
  name: 'agoraMiniSlice',
  initialState,
  reducers: {
    setAgoraMiniState: (state, action) => {
      state = action.payload;
      return state;
    },
    setAgoraMiniTimeState: (state, action) => {
      state = { ...state, time: action.payload };
      return state;
    },
    resetAgoraMiniState: () => {
      return initialState;
    },
  },
});

export const { setAgoraMiniState, setAgoraMiniTimeState, resetAgoraMiniState } = agoraMiniSlice.actions;

export default agoraMiniSlice.reducer;
