import * as React from 'react';
import { useState } from 'react';

import { Select } from 'antd';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, Switch, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import moment from 'moment';

import { createOffer, getCompanyType, updateOffer, uploadBanner } from '../../../services/Service';
import { usStates, indiaStates } from '../../../utils/stateList';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  companyId: '',
  title: '',
  subtitle: '',
  callNumber: '',
  redirectLink: '',
  description: '',
  status: true,
  state: [],
  country: 'United States',
  startDate: '',
  endDate: '',
  socialMedia: [],
};

export default function AddOfferModal({ open, onClose, data, isDisplay, companyList, socialList }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [fileValue, setFileValue] = React.useState(null);
  const [companyTypes, setCompanyTypes] = React.useState([]);
  const [companyNameList, setCompanyNameList] = React.useState([]);
  const [selectedCompanyTypes, setSelectedCompanyTypes] = React.useState('');

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    setFileValue(null);
    setCompanyNameList([]);
    setSelectedCompanyTypes('');
    onClose();
  };

  // console.log('companyList', companyList);

  const fetchCompanyType = async () => {
    try {
      const res = await getCompanyType();
      setCompanyTypes(res.data.data.map((e) => e.type));
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      if (!fileValue) {
        toast.error('Please select a image');
        return;
      }
      const res_image = await uploadBanner(fileValue);

      const res = await createOffer({ ...fieldValue, image: res_image.data.url });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };
  const onSubmitUpdate = async () => {
    setIsDisableAll(true);

    try {
      let imgUrl = data?.image;
      if (fileValue) {
        const res_image = await uploadBanner(fileValue);
        imgUrl = res_image.data.url;
      }
      const res = await updateOffer({ _id: data._id, ...fieldValue, image: imgUrl });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    fetchCompanyType();
    const refineSocialMedia = socialList?.map((e) => ({
      name: e?.name,
      type: e?.type,
      logo: e?.logo,
      webRedirectLink: e?.webRedirectLink,
      mobileRedirectLink: e?.mobileRedirectLink,
      isWeb: e?.isWeb,
      isMobile: e?.isMobile,
      status: e?.status,
    }));
    setFieldValue((prev) => ({ ...prev, socialMedia: refineSocialMedia }));

    if (open === true && data !== null) {
      setSelectedCompanyTypes(data?.company?.companyType || '');
      const filterList = companyList?.filter(
        (ex) => ex?.companyType?.toLowerCase() === data?.company?.companyType?.toLowerCase()
      );
      setCompanyNameList(filterList);

      const refineSocialMedia1 = socialList?.map((e) => {
        const findSocial = data?.socialMedia?.find((st) => st.type === e.type);
        return {
          name: e?.name,
          type: e?.type,
          logo: e?.logo,
          webRedirectLink: e?.webRedirectLink,
          mobileRedirectLink: e?.mobileRedirectLink,
          isWeb: e?.isWeb,
          isMobile: e?.isMobile,
          status: findSocial?.status !== undefined ? findSocial?.status : e?.status,
        };
      });

      setFieldValue({
        companyId: data.company._id,
        title: data.title,
        subtitle: data.subtitle,
        callNumber: data.callNumber,
        redirectLink: data.redirectLink,
        description: data.description,
        status: data.status,
        state: data.state,
        country: data.country,
        startDate: moment(data.startDate).format('yyyy-MM-DD'),
        endDate: moment(data.endDate).format('yyyy-MM-DD'),
        socialMedia: refineSocialMedia1,
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  const stateArray =
    fieldValue.country === 'India' ? indiaStates : fieldValue.country === 'United States' ? usStates : [];

  const stateArrayX = [{ name: 'All', abbreviation: 'All' }, ...stateArray];

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Offer Info' : isDisplay ? 'Offer Info' : 'Update Offer Info'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <CustomMuiSelect
            required
            label="Company Type"
            options={[...companyTypes?.map((ex) => ({ label: ex }))]}
            onChange={(newValue) => {
              setSelectedCompanyTypes(newValue?.label || '');
              const filterList = companyList?.filter(
                (ex) => ex?.companyType?.toLowerCase() === newValue.label?.toLowerCase()
              );
              setCompanyNameList(filterList);
              setFieldValue((prev) => ({ ...prev, companyId: '' }));
            }}
            value={selectedCompanyTypes}
            variant="outlined"
            size="small"
            disabled={isDisableAll ? true : data !== null}
          />
          {/* companyId */}
          <CustomMuiSelect
            required
            options={companyNameList?.map((ex) => ({ label: ex.name, id: ex._id }))}
            value={
              fieldValue?.companyId
                ? {
                    label: companyNameList?.find((e) => e._id === fieldValue?.companyId)?.name,
                    id: fieldValue?.companyId,
                  }
                : null
            }
            onChange={(e) => setFieldValue((prev) => ({ ...prev, companyId: e?.id }))}
            label="Company"
            size="small"
            variant="outlined"
            disabled={!selectedCompanyTypes ? true : isDisableAll ? true : data !== null}
          />
          {/* Image File */}
          <TextField
            onChange={(e) => {
              setFileValue(e.target.files[0]);
            }}
            type="file"
            required
            size="small"
            fullWidth
            variant="outlined"
            InputProps={{
              accept: 'image/*',
            }}
          />
          {/* title */}
          <TextField
            value={fieldValue.title}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, title: e.target.value }))}
            required
            label="Title"
            name="title"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* subtitle */}
          <TextField
            value={fieldValue.subtitle}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, subtitle: e.target.value }))}
            required
            label="Sub-Title"
            name="subtitle"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* callNumber */}
          <TextField
            value={fieldValue.callNumber}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, callNumber: e.target.value }))}
            required
            label="Phone Number"
            name="callNumber"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* redirectLink */}
          <TextField
            value={fieldValue.redirectLink}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, redirectLink: e.target.value }))}
            required
            label="Redirect Link"
            name="redirectLink"
            size="small"
            type="url"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* country - state */}
          <div style={{ display: 'flex', gap: '12px' }}>
            <TextField
              value={fieldValue.country}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, country: e.target.value }))}
              required
              select
              label="Country"
              size="small"
              fullWidth
              disabled={isDisableAll ? true : data !== null}
              variant="outlined"
              sx={{ flex: '1' }}
            >
              <MenuItem value="United States">United States</MenuItem>
              <MenuItem value="India">India</MenuItem>
            </TextField>
            <Select
              title="State"
              mode="multiple"
              allowClear
              disabled={isDisableAll}
              style={{
                flex: '1',
              }}
              variant="outlined"
              dropdownStyle={{
                zIndex: 20000,
              }}
              value={fieldValue.state}
              defaultValue={fieldValue.state}
              placeholder="Please select state"
              onChange={(values) => setFieldValue((prev) => ({ ...prev, state: values }))}
              options={stateArrayX.map((exs) => ({ label: exs.name, value: exs.abbreviation }))}
            />
          </div>
          {/* startDate - endDate */}
          <div style={{ display: 'flex', gap: '12px' }}>
            <TextField
              value={fieldValue.startDate}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, startDate: e.target.value }))}
              required
              label="Start Date"
              name="startDate"
              size="small"
              type="date"
              fullWidth
              variant="outlined"
              disabled={isDisableAll}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              value={fieldValue.endDate}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, endDate: e.target.value }))}
              required
              label="End Date"
              name="endDate"
              size="small"
              type="date"
              fullWidth
              variant="outlined"
              disabled={isDisableAll}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <TextField
            value={fieldValue.status === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
          {/* description */}
          <TextField
            value={fieldValue.description}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, description: e.target.value }))}
            multiline
            minRows={2}
            maxRows={3}
            label="Description"
            name="description"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          <div>
            <Typography variant="h4" gutterBottom sx={{ fontSize: '20px', fontWeight: 700, color: '#19c25f' }}>
              Social Media Links
            </Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {fieldValue?.socialMedia?.map((sm, index) => {
                const findSocial = socialList.find((st) => st.type === sm.type);
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Switch
                      style={{ color: '#19c25f' }}
                      disabled={!findSocial?.status}
                      checked={sm.status}
                      onChange={() =>
                        setFieldValue((prev) => {
                          const newObj = { ...prev };
                          newObj.socialMedia[index] = {
                            ...newObj.socialMedia[index],
                            status: !newObj.socialMedia[index].status,
                          };
                          return newObj;
                        })
                      }
                    />
                    <span>{sm?.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
