import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Iconify from '../../Iconify';

export default function ActionButton({ className, disabled, icon, onClick, style, id }) {
  return (
    <button type="button" className={classnames('btn-action', { disabled }, className)} id={id} onClick={onClick}>
      <Iconify icon={icon} style={style} mr={1} />
    </button>
  );
}

ActionButton.defaultProps = {
  className: null,
  disabled: false,
};

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};