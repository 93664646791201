import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import { createSafalLeaderConfigMaster, updateSafalLeaderConfigMaster } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  application: '',
  megaModule: '',
  activityName: '',
  activityKey: '',
  frequencyConfig: '',
  frequency: '',
  status: true,
  points: 0,
};

export default function AddSafalLeaderConfigMasterModal({
  open,
  onClose,
  data,
  megaModuleList,
  applicationsList,
  frequencyConfigList,
}) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    onClose();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await createSafalLeaderConfigMaster(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdateSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await updateSafalLeaderConfigMaster({ ...fieldValue, _id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        status: data?.status,
        activityName: data?.activityName || '',
        activityKey: data?.activityKey || '',
        frequency: data?.frequency || '',
        points: data?.points,
        megaModule: data?.megaModule?._id || '',
        application: data?.application?._id || '',
        frequencyConfig: data?.frequencyConfig?._id || '',
      });
    } else {
      setFieldValue(initFieldValue);
    }
  }, [open, data]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add SafalLeaderBoard Config' : 'Update SafalLeaderBoard Config'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* applicationName */}
          <TextField
            value={fieldValue.application}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, application: e.target.value }))}
            required
            select
            label="Application Name"
            size="small"
            disabled={data !== null}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {applicationsList?.map((e, index) => (
              <MenuItem key={index} value={e?._id}>
                {e?.name}
              </MenuItem>
            ))}
          </TextField>
          {/* mega-module */}
          <TextField
            value={fieldValue.megaModule}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, megaModule: e.target.value }))}
            required
            select
            label="Mega Module"
            size="small"
            fullWidth
            variant="outlined"
            disabled={data !== null}
          >
            {megaModuleList?.map((e, index) => (
              <MenuItem key={index} value={e?._id}>
                {e?.megaModule}
              </MenuItem>
            ))}
          </TextField>
          {/* activityName */}
          <TextField
            value={fieldValue.activityName}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, activityName: e.target.value }))}
            required
            label="Activity Name"
            name="activityName"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
          />
          {/* activityKey */}
          <TextField
            value={fieldValue.activityKey}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, activityKey: e.target.value }))}
            required
            label="Activity Key"
            name="activityKey"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={data !== null ? true : isSubmitting}
          />
          {/* frequency */}
          <TextField
            value={fieldValue.frequency}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, frequency: e.target.value }))}
            required
            label="Frequency"
            name="frequency"
            size="small"
            type="text"
            helperText="This is just for reference"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
          />
          {/* frequencyConfig */}
          <TextField
            value={fieldValue.frequencyConfig}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, frequencyConfig: e.target.value }))}
            required
            select
            label="Frequency Config"
            size="small"
            fullWidth
            disabled={isSubmitting}
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {frequencyConfigList?.map((e, index) => (
              <MenuItem key={index} value={e?._id}>
                {e?.frequencyName}
              </MenuItem>
            ))}
          </TextField>
          {/* points */}
          <TextField
            value={fieldValue.points}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, points: parseInt(e.target.value, 10) }))}
            required
            label="Points"
            name="points"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
          />
          <TextField
            value={fieldValue.status === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            disabled={isSubmitting}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={data === null ? onSubmit : onUpdateSubmit}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            {data === null ? 'Create' : 'Update'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
