import { Button, InputAdornment, OutlinedInput, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './GlobalShareBusiness.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { getGlobalShareLinkList } from '../../../services/Service';
import TableViewSocial from './TableViewSocial';
import AddOfferModalSocial from './AddOfferModalSocial';

const GlobalShareBusiness = () => {
  const [addModalOpenSocial, setAddModalOpenSocial] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [rawDataSocial, setRawDataSocial] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getGlobalShareLinkList();
      if (res.status === 200) {
        setRawDataSocial(res.data.data);
      }
    } catch (err) {
      console.log('OffersList fetchData error', err.message);
    }
  };

  const onAddModalOpenDisplaySocial = (data) => {
    setAddModalOpenSocial({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenEditSocial = (data) => {
    setAddModalOpenSocial({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalCloseSocial = () => {
    setAddModalOpenSocial({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Global Share Links - SafalSubscriptions</title>
      </Helmet>

      <div id="OffersList">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Global Share Links
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div" />
        </div>

        <TableViewSocial
          onEdit={onAddModalOpenEditSocial}
          onDisplay={onAddModalOpenDisplaySocial}
          data={rawDataSocial}
        />
      </div>
      {/* <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={selectedToggle === 'offers' ? onDelete : onDeleteSocial}
      /> */}
      <AddOfferModalSocial
        open={addModalOpenSocial.open}
        data={addModalOpenSocial.data}
        isDisplay={addModalOpenSocial.isDisplay}
        onClose={onAddModalCloseSocial}
      />
    </>
  );
};

export default GlobalShareBusiness;
