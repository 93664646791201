/* eslint-disable no-plusplus */
import { Box, Button, Fab, IconButton, Modal, Tab, Tooltip } from '@mui/material';
// import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useEffect, useState } from 'react';
// import { TabContext, TabList, TabPanel } from '@mui/lab';
// import { useSelector } from 'react-redux';
// import Swal from 'sweetalert2';

import './FaqAIModal.css';
import InputBox from './InputBox';
import ChatMessageX from './ChatMessageX';
import { faqAiList } from '../../services/Service';
// import aiChatLogo from '../../assets/ai-chat-logo.png';
import useUserTimeZone from '../../hooks/useUserTimeZone';

// Green Color #01b855

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '98vh',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  '@media (max-width: 640px)': {
    width: '95vw',
    // height: '95vh',
    overflow: 'auto',
  },
  '@media (max-width: 1000px)': {
    width: '95vw',
    // height: '95vh',
    overflow: 'auto',
  },
};

const formatDateInterval = (arrx, formatDate) => {
  let lastDate = '';
  const arr = [...arrx];
  for (let index = arr.length - 1; index >= 0; index--) {
    const element = arr[index];
    if (formatDate(element.createdAt) !== lastDate) {
      element.isDate = true;
      lastDate = formatDate(element.createdAt);
    }
  }
  return arr;
};

const FaqAIModal = ({ onClose, open }) => {
  // const login = useSelector((state) => state.login);
  // const { registered } = login.user;
  const { formatDate } = useUserTimeZone();
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [mainChatArray, setMainChatArray] = useState([]);

  const onModalClose = () => {
    resetTranscript();
    onClose();
  };

  const fetchChatList = async () => {
    try {
      const res = await faqAiList();
      setMainChatArray(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!open) return;
    fetchChatList();
  }, [open]);

  if (!browserSupportsSpeechRecognition) {
    return <></>;
  }

  const formatDateIntervalChatArray = formatDateInterval(mainChatArray, formatDate);

  return (
    <>
      <Modal open={open} onClose={onModalClose}>
        <Box sx={modalStyle}>
          <div
            style={{
              backgroundColor: 'white',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '8px',
              flex: 'none',
              borderBottom: '1px solid black',
            }}
          >
            <button
              onClick={onModalClose}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '6px',
                right: '6px',
                width: '28px',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '99999px',
                border: 'none',
                backgroundColor: '#01b855',
              }}
            >
              <CloseIcon sx={{ width: '14px', height: '14px', color: 'white' }} />
            </button>
            <span style={{ color: 'black', fontSize: '20px', display: 'flex', alignItems: 'center' }}>FAQ's AI</span>
          </div>
          {/* <div style={{ margin: '0px 16px', height: '60px', display: 'flex', flex: 'none' }}>
            <button
              onClick={() => handleChangeTab('1')}
              className={`new-head-tab-right ${tabValue === '1' && 'new-head-tab-active'}`}
            >
              SafalSubscriptions AI
            </button>
            <button
              onClick={() => handleChangeTab('2')}
              className={`new-head-tab-left ${tabValue === '2' && 'new-head-tab-active-1'}`}
            >
              SafalAIGPT
            </button>
          </div> */}
          <div id="FaqAIModal">
            <div className="ai-chat">
              {formatDateIntervalChatArray.map((e, index) => (
                <ChatMessageX fetchChatList={fetchChatList} data={e} key={index} isLast={index === 0} />
              ))}
            </div>
            <InputBox
              lastMessage={mainChatArray.length > 0 ? mainChatArray[0]?.message : ''}
              fetchChatList={fetchChatList}
              listening={listening}
              transcript={transcript}
              speechRecognition={SpeechRecognition}
              resetTranscript={resetTranscript}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default FaqAIModal;
