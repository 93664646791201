/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Avatar,
  AvatarGroup,
  Badge,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useSelector } from 'react-redux';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import './SafalLeaderBoard.css';
import CustomSearch from '../../../components/UI/CustomSearch';
import CustomMenu from '../../../components/UI/CustomMenu';
import SafalLeaderBoard_Call from '../../../assets/SafalLeaderBoard_Call.png';
import SafalLeaderBoard_Chat from '../../../assets/SafalLeaderBoard_Chat.png';
import SafalLeaderBoard_Video from '../../../assets/SafalLeaderBoard_Video.png';
import kingImg from '../../../assets/kingImg.png';
import safalGiftIcon from '../../../assets/safalgift_new1.png';
import {
  getSafalLeaderBoard,
  addSafalBuddy,
  updateSafalBuddy,
  getSafalBuddy,
  firebasePushNotification,
} from '../../../services/Service';
import FaqModal from '../../../components/Modals/FaqModal';
import useProfileImage from '../../../hooks/useProfileImage';
import useDebounce from '../../../hooks/useDebounce';
import SafalBuddy from './SafalBuddy';
import { db } from '../../../firebase';
import UserChatModal from '../../../components/Modals/UserChatModal';
import useWindowSize from '../../../hooks/useWindowSize';
import Inlineloader from '../../../components/Loader/InlineLoader';
import { UserNewProfileModal } from '../../../components/Modals/UserNewProfileModal';

const SafalLeaderBoard = () => {
  const [loading, setLoading] = useState(false);
  const [mainList, setMainList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [lastMonthData, setLastMonthData] = useState([]);
  const [selectedType, setSelectedType] = useState('monthly');
  const [searchValue, setSearchValue] = useState('');
  const [safalBuddyOpen, setSafalBuddyOpen] = useState({
    open: false,
  });
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const [buddyList, setBuddyList] = useState([]);
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const [userChatModal, setUserChatModal] = useState({
    open: false,
    otherUserId: '',
    type: 'chat', // chat. call
    user: null,
  });

  const windowSize = useWindowSize();

  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);
  const safalGift_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'safalGift');

  // lifeTime, daily, weekly, monthly, yearly
  const fetchList = async (loadingFlag) => {
    try {
      loadingFlag && setLoading(true);
      const res = await getSafalLeaderBoard({
        application: 'SafalSubscriptions',
        search: searchValue,
        page: 1,
        limit: 2000,
        type: selectedType,
      });
      setMainList(res.data.data);
      setCurrentUser(res.data.currentUser);
      setLastMonthData(res.data.lastData);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const fetchBuddy = async () => {
    try {
      const res = await getSafalBuddy();
      setBuddyList(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchList(true);
  }, [selectedType]);

  useEffect(() => {
    fetchBuddy();
  }, []);

  // DeBounce Function
  useDebounce(
    async () => {
      await fetchList(true);
    },
    [searchValue],
    1000
  );

  const onOpenBuddy = () => {
    setSafalBuddyOpen({
      open: true,
    });
  };

  const onCloseBuddy = () => {
    setSafalBuddyOpen({
      open: false,
    });
  };

  const onCallClick = async (otherUserId, otherUserInfo, type) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    onConnectCall(otherUserInfo, type);
  };

  const onConnectCall = async (user, callModeX) => {
    const currentUserId = currentFirebaseUser.uid;
    const otherUserId = user?.uid;
    // user to user calls
    const otherUserDocDataRef = await getDoc(doc(db, 'users', otherUserId));
    const otherUserDocData = otherUserDocDataRef.data();
    const callId = uuidv4();
    const callLogRef = doc(db, 'callLogs', callId);
    const currentUserCallDocRef = doc(db, 'userCalls', currentUserId);
    const otherUserCallDocRef = doc(db, 'userCalls', otherUserId);
    try {
      const getDocData = await getDoc(otherUserCallDocRef);
      if (getDocData.exists) {
        const data = getDocData.data();
        if (data.isCall) {
          toast('User is busy in another call');
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }

    const callMode = callModeX ?? 'audio';

    const callObj = {
      callId: callId,
      isCall: true,
      callMode: callMode, // audio/video,
      type: 'user',
      status: 'pending', // "pending", "accept", "reject", "end"
      currentUserId: currentUserId, // undefined or string
      otherUserId: otherUserId, // undefined or string
      flag: 'user_to_user', // user_to_user or support_to_user or user_to_support
      startAt: serverTimestamp(),
    };

    await setDoc(callLogRef, callObj);
    await setDoc(currentUserCallDocRef, callObj);
    await setDoc(otherUserCallDocRef, callObj);

    const notificationPayload = {
      data: {
        notification: { image: '', title: 'Calling', body: `${currentFirebaseUser?.displayName} calling you` },
        data: {
          type: 'call',
          messageId: '',
          otheruid: '',
          image_url: otherUserDocData?.photoURL,
          chatID: '',
          displayName: otherUserDocData?.displayName,
          userType: 'USER',
          isAudio: '1',
          OtherUid: currentUserId,
          channelId: callId,
        },
        apns: { payload: { aps: { sound: 'afro_ringtone.mp3', badge: 1 } } },
      },
      userId: otherUserDocData?.userID,
    };
    await firebasePushNotification(notificationPayload);
  };

  const onChatClick = async (otherUserId, otherUserInfo) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    setUserChatModal({
      open: true,
      otherUserId: otherUserId,
      type: 'chat', // chat. call
      otherUserInfo: otherUserInfo,
    });
  };

  const handleUserChatClose = () => {
    localStorage.removeItem('SafalBuddyChat');
    localStorage.removeItem('SafalBuddyChatNew');
    setUserChatModal({
      open: false,
      otherUserId: '',
      type: 'chat', // chat. call
      user: null,
    });
  };

  const [openUserNewProfileModal, setOpenUserNewProfileModal] = useState({
    open: false,
    data: null,
  });
  const openUserNewProfileOpen = (userId) => {
    setOpenUserNewProfileModal({
      open: true,
      data: userId,
    });
  };
  const openUserNewProfileClose = () => {
    setOpenUserNewProfileModal({
      open: false,
      data: null,
    });
  };

  const isMobile = windowSize.width < 640;

  if (loading) return <Inlineloader />;

  return (
    <>
      <Helmet>
        <title>SafalLeaderBoard - SafalSubscriptions</title>
      </Helmet>
      <div id="SafalLeaderBoard" style={{ display: 'flex', overflow: 'hidden', height: '90%' }}>
        <div className="SafalLeaderBoard-rank">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
              SafalLeaderBoard - <i style={{ fontStyle: 'normal', textTransform: 'capitalize' }}>{selectedType}</i>
            </Typography>
            <FaqModal module="SafalLeaderBoard" color="#3D71FF" />
          </div>
          <div
            style={{
              height: isMobile ? '300px' : '400px',
              position: 'relative',
              margin: '12px 0px',
              marginRight: '20px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                backgroundColor: '#3d68ff',
                width: '100%',
                height: isMobile ? '35px' : '70px',
              }}
            />
            <div
              className="SafalLeaderBoard-trapezoid"
              style={{
                position: 'absolute',
                bottom: isMobile ? '35px' : '70px',
                backgroundColor: '#7b9efd',
                width: '100%',
                height: '20px',
              }}
            />
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                left: `calc(50% - 16.5%)`,
                backgroundColor: '#4f79ea',
                width: '33%',
                height: isMobile ? '60px' : '120px',
              }}
            />
            <div
              className="SafalLeaderBoard-trapezoid"
              style={{
                position: 'absolute',
                bottom: isMobile ? '60px' : '120px',
                left: `calc(50% - 16.5%)`,
                backgroundColor: '#7b9efd',
                width: '33%',
                height: '20px',
              }}
            />
            {/* 2nd */}
            <TopUserBox
              onClick={(userId) => openUserNewProfileOpen(userId)}
              onChat={onChatClick}
              onCall={onCallClick}
              selectedType={selectedType}
              data={lastMonthData[1]}
              top={isMobile ? '40%' : '33%'} // 55%
              left={isMobile ? '8%' : '0%'} // 8%
            />
            {/* 1rd */}
            <TopUserBox
              onClick={(userId) => openUserNewProfileOpen(userId)}
              onChat={onChatClick}
              onCall={onCallClick}
              selectedType={selectedType}
              data={lastMonthData[0]}
              top={isMobile ? '10%' : '10%'} // 38%
              left={isMobile ? '39%' : '33%'} // 39%
              king
            />
            {/* 3st */}
            <TopUserBox
              onClick={(userId) => openUserNewProfileOpen(userId)}
              onChat={onChatClick}
              onCall={onCallClick}
              selectedType={selectedType}
              data={lastMonthData[3]}
              top={isMobile ? '40%' : '33%'} // 55%
              left={isMobile ? '70%' : '66%'} // 70%
            />
            <div
              className="SafalLeaderBoard-rank-number"
              style={{
                fontSize: isMobile ? '26px' : '48px',
                color: '#C0C0C0',
                position: 'absolute',
                bottom: '0px',
                left: '12%',
              }}
            >
              2
            </div>
            <div
              className="SafalLeaderBoard-rank-number"
              style={{
                fontSize: isMobile ? '26px' : '48px',
                color: '#B5A642',
                position: 'absolute',
                bottom: '0px',
                right: '12%',
              }}
            >
              3
            </div>
            <div
              className="SafalLeaderBoard-rank-number"
              style={{
                fontSize: isMobile ? '40px' : '72px',
                color: '#FFD700',
                position: 'absolute',
                bottom: isMobile ? '0px' : '20px',
                right: '47%',
              }}
            >
              1
            </div>
          </div>
          <div
            style={{
              marginRight: '20px',
              backgroundColor: '#0932a1',
              padding: '4px 16px',
              color: 'white',
              fontSize: '16px',
            }}
          >
            <span>Where you stand</span>
          </div>
          {/* Where you stand */}
          <CurrentUserBox
            onClick={(userId) => openUserNewProfileOpen(userId)}
            onOpenBuddy={onOpenBuddy}
            buddyList={buddyList}
            currentUser={currentUser}
            selectedType={selectedType}
            topData={mainList[0]}
          />
        </div>
        <div className="SafalLeaderBoard-hr" />
        <div className="SafalLeaderBoard-list">
          <div style={{ flex: 'none' }}>
            <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
              SafalSubscriptions Users Rank -{' '}
              <i style={{ fontStyle: 'normal', textTransform: 'capitalize' }}>{selectedType}</i>
            </Typography>
          </div>
          <div style={{ flex: 'none', display: 'flex', justifyContent: 'space-between', margin: '16px 0px' }}>
            <CustomSearch placeholder="Search" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            <div />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {safalGift_DynamicMenuState && (
                <Button
                  title="SafalGift"
                  variant="contained"
                  size="small"
                  component={Link}
                  to="/safalgift"
                  sx={{
                    textTransform: 'capitalize',
                    backgroundColor: '#3D71FF',
                    borderRadius: '30px',
                  }}
                  startIcon={
                    <img
                      style={{ width: '16px', height: '16px', objectFit: 'contain' }}
                      src={safalGiftIcon}
                      alt="safalGiftIcon"
                    />
                  }
                >
                  SafalGift
                </Button>
              )}
              <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
                <Select
                  labelId="SafalLeaderBoard-selected"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  {/* lifeTime, daily, weekly, monthly, yearly */}
                  <MenuItem value="lifeTime">Life-Time</MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div
            style={{
              overflow: 'auto',
              height: '700px',
              gap: '8px',
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '32px',
            }}
          >
            {mainList.map((e) => (
              <UserListBox
                onClick={(userId) => openUserNewProfileOpen(userId)}
                onChat={onChatClick}
                onCall={onCallClick}
                refetch={fetchList}
                data={e}
                selectedType={selectedType}
              />
            ))}
          </div>
        </div>
      </div>
      <SafalBuddy
        onChat={onChatClick}
        onCall={onCallClick}
        open={safalBuddyOpen.open}
        onClose={onCloseBuddy}
        data={buddyList}
        refetch={fetchBuddy}
      />
      <UserChatModal
        open={userChatModal.open}
        buddyUser={userChatModal.otherUserInfo}
        handleClose={() => handleUserChatClose()}
      />
      <UserNewProfileModal
        open={openUserNewProfileModal.open}
        userId={openUserNewProfileModal.data}
        onClose={openUserNewProfileClose}
      />
    </>
  );
};

export default SafalLeaderBoard;

const UserListBox = ({ data, selectedType, refetch, onChat, onCall, onClick }) => {
  const profileImage = useProfileImage(data?.user?.profilePic);
  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 640;
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const currentUserToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  const isChatCallEnable = currentUserToUserChatEnable ? otherUserInfo?.userToUserChat === true : false;

  const fullName = `${data?.user?.firstName} ${data?.user?.lastName}`;
  const rank =
    selectedType === 'lifeTime'
      ? data?.lifeTimeRank
      : selectedType === 'daily'
      ? data?.dailyRank
      : selectedType === 'weekly'
      ? data?.weeklyRank
      : selectedType === 'monthly'
      ? data?.monthlyRank
      : data?.yearlyRank;
  const lastRank =
    selectedType === 'yearly'
      ? data?.lastYearRank
      : selectedType === 'daily'
      ? data?.lastDayRank
      : selectedType === 'weekly'
      ? data?.lastWeekRank
      : selectedType === 'monthly'
      ? data?.lastMonthRank
      : 0;
  const points =
    selectedType === 'lifeTime'
      ? data?.lifeTimePoints
      : selectedType === 'daily'
      ? data?.dailyPoints
      : selectedType === 'weekly'
      ? data?.weeklyPoints
      : selectedType === 'monthly'
      ? data?.monthlyPoints
      : data?.yearlyPoints;

  const rankUp = lastRank - rank;
  // pending, accept, reject, unBuddy, block, default
  const menuListForNew = ['New Buddy Request', 'Block This User'];
  const menuListForPendingForUser = ['Cancel Buddy Request', 'Block This User'];
  const menuListForPending = ['Accept', 'Reject', 'Block This User'];
  const menuListForAccept = ['Unbuddy', 'Block This User'];
  const menuListForReject = ['Block This User'];
  const menuListForBlockMe = ['Unblock'];
  // const menuListForBlockUser = [];

  const buddy = data?.buddy;
  const thisIsReceiver = buddy?.receiver === data?.user?._id;

  const menuList = buddy
    ? buddy?.status === 'pending'
      ? thisIsReceiver
        ? menuListForPendingForUser
        : menuListForPending
      : buddy?.status === 'default'
      ? menuListForNew
      : buddy?.status === 'accept'
      ? menuListForAccept
      : buddy?.status === 'reject'
      ? menuListForReject
      : buddy?.status === 'unBuddy'
      ? menuListForReject
      : buddy?.status === 'block'
      ? thisIsReceiver
        ? menuListForBlockMe
        : []
      : menuListForNew
    : menuListForNew;

  const isPendingForUser = !thisIsReceiver && buddy?.status === 'pending';
  const isBlockForUser = thisIsReceiver && buddy?.status === 'block';

  const onMenuClick = async (key) => {
    switch (key) {
      case 'New Buddy Request':
        updateBuddy({
          id: buddy?._id,
          userId: data?.user?._id,
          status: 'pending',
        });
        break;
      case 'Cancel Buddy Request':
        updateBuddy({
          id: buddy?._id,
          userId: data?.user?._id,
          status: 'default',
        });
        break;
      case 'Accept':
        updateBuddy({
          id: buddy?._id,
          userId: data?.user?._id,
          status: 'accept',
        });
        break;
      case 'Reject':
        updateBuddy({
          id: buddy?._id,
          userId: data?.user?._id,
          status: 'reject',
        });
        break;
      case 'Block This User':
        updateBuddy({
          id: buddy?._id,
          userId: data?.user?._id,
          status: 'block',
        });
        break;
      case 'Unbuddy':
        updateBuddy({
          id: buddy?._id,
          userId: data?.user?._id,
          status: 'default',
        });
        break;
      case 'Unblock':
        updateBuddy({
          id: buddy?._id,
          userId: data?.user?._id,
          status: 'unblock',
        });
        break;
      default:
        break;
    }
  };

  const updateBuddy = async ({ id, userId, status }) => {
    try {
      let res;
      if (buddy) {
        res = await updateSafalBuddy({ _id: id, status, otherUserId: userId });
      } else {
        res = await addSafalBuddy({ otherUserId: userId, status });
      }
      if (res?.status === 200) refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchOtherUserFirebase = async (otherUserId) => {
    if (!otherUserId) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', otherUserId));
    if (otherUserInfoRef.exists()) {
      setOtherUserInfo(otherUserInfoRef.data());
    }
  };

  useEffect(() => {
    fetchOtherUserFirebase(data?.user?._id);
  }, []);

  const onCallClick = async () => {
    onCall(data?.user?._id, otherUserInfo, 'audio');
  };
  const onCallClickVideo = async () => {
    onCall(data?.user?._id, otherUserInfo, 'video');
  };
  const onChatClick = async () => {
    onChat(data?.user?._id, otherUserInfo);
  };

  return (
    <div
      style={{
        backgroundColor: buddy?.status === 'accept' ? '#bcceff' : isPendingForUser ? '#b4aa48' : 'white',
        flex: 'none',
        display: 'flex',
        border: isBlockForUser ? '2px solid #ff3b30' : '1px solid #A0A0A0',
        height: '75px',
        borderRadius: '20px',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: isMobile ? '0px 8px' : '0px 16px',
        boxShadow: '0px 4px 4px 0px #00000040',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: isMobile ? '8px' : '32px' }}>
        <span
          style={{ color: '#3D71FF', fontSize: isMobile ? '24px' : '32px' }}
          className="SafalLeaderBoard-rank-number"
        >
          {rank}
        </span>
        <Badge
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          color="success"
          overlap="circular"
          badgeContent=" "
        >
          <Avatar
            onClick={() => onClick(data?.user?._id)}
            sx={{
              cursor: 'pointer',
              height: isMobile ? '40px' : '50px',
              width: isMobile ? '40px' : '50px',
              border: buddy?.status === 'accept' && '2px solid #0072e2',
            }}
            alt={fullName}
            src={profileImage}
          />
        </Badge>
        <div>
          <span
            style={{ fontSize: isMobile ? '18px' : '24px', color: '#001E6F', lineHeight: '16px' }}
            className="SafalLeaderBoard-user-title"
          >
            {fullName}
          </span>
          <div
            style={{ display: 'flex', alignItems: 'center', marginRight: isMobile ? '4px' : '16px', height: '24px' }}
          >
            <span style={{ fontSize: '24px', color: '#03A600' }} className="SafalLeaderBoard-user-title">
              {Math.abs(rankUp)}
            </span>
            <PlayArrowIcon sx={{ rotate: rankUp >= 0 ? '-90deg' : '90deg', color: '#B88F00' }} />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', gap: isMobile ? '4px' : '16px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100px' }}>
          <div
            style={{
              backgroundColor: '#0932a1',
              color: 'white',
              height: '26px',
              borderRadius: '8px',
              padding: '0px 6px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ lineHeight: '14px' }}>{points} Spts</span>
          </div>
          <CustomMenu
            onClick={onMenuClick}
            menuItem={menuList}
            disabled={menuList.length === 0}
            label="Actions"
            sx={{
              backgroundColor: '#ffffff',
              color: '#0932a1',
              border: '1px solid #0932a1',
              height: '26px',
              ':hover': {
                backgroundColor: '#ffffff',
                color: '#0932a1',
                border: '1px solid #0932a1',
              },
            }}
          />
        </div>
        <div
          style={{
            width: '2px',
            backgroundColor: '#9790cd',
            opacity: '0.25',
            margin: isMobile ? '0px 4px' : '0px 16px',
            marginRight: isMobile ? '4px' : '8px',
          }}
        />
        <div style={{ display: 'flex', gap: '4px' }}>
          <button
            title="Chat"
            onClick={onChatClick}
            disabled={!isChatCallEnable}
            style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
          >
            <img
              src={SafalLeaderBoard_Chat}
              style={{
                width: '20px',
                height: '20px',
                objectFit: 'contain',
                filter: !isChatCallEnable ? 'grayscale(1)' : '',
              }}
              alt="SafalLeaderBoard_Chat"
            />
          </button>
          <button
            title="Audio Call"
            onClick={onCallClick}
            disabled={!isChatCallEnable}
            style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
          >
            <img
              src={SafalLeaderBoard_Call}
              style={{
                width: '20px',
                height: '20px',
                objectFit: 'contain',
                filter: !isChatCallEnable ? 'grayscale(1)' : '',
              }}
              alt="SafalLeaderBoard_Call"
            />
          </button>
          <button
            title="Video Call"
            onClick={onCallClickVideo}
            disabled={!isChatCallEnable}
            style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
          >
            <img
              src={SafalLeaderBoard_Video}
              style={{
                width: '20px',
                height: '20px',
                objectFit: 'contain',
                filter: !isChatCallEnable ? 'grayscale(1)' : '',
              }}
              alt="SafalLeaderBoard_Video"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

const CurrentUserBox = ({ onOpenBuddy, currentUser, selectedType, buddyList, onClick }) => {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 640;

  const navigate = useNavigate();

  const profileImage = useProfileImage(currentUser?.user?.profilePic);
  const rank =
    selectedType === 'lifeTime'
      ? currentUser?.lifeTimeRank
      : selectedType === 'daily'
      ? currentUser?.dailyRank
      : selectedType === 'weekly'
      ? currentUser?.weeklyRank
      : selectedType === 'monthly'
      ? currentUser?.monthlyRank
      : currentUser?.yearlyRank;
  const lastRank =
    selectedType === 'yearly'
      ? currentUser?.lastYearRank
      : selectedType === 'daily'
      ? currentUser?.lastDayRank
      : selectedType === 'weekly'
      ? currentUser?.lastWeekRank
      : selectedType === 'monthly'
      ? currentUser?.lastMonthRank
      : 0;
  const points =
    selectedType === 'lifeTime'
      ? currentUser?.lifeTimePoints
      : selectedType === 'daily'
      ? currentUser?.dailyPoints
      : selectedType === 'weekly'
      ? currentUser?.weeklyPoints
      : selectedType === 'monthly'
      ? currentUser?.monthlyPoints
      : currentUser?.yearlyPoints;

  const rankUp = lastRank - rank;

  const pendingBuddy = buddyList.filter((e) => e?.status === 'pending' && e?.receiver?._id === currentUser?.user?._id);

  const pointNeedToTop = currentUser?.grabYourCrown < 0 ? 0 : currentUser?.grabYourCrown;

  return (
    <div
      style={{
        marginRight: '20px',
        marginTop: '20px',
        display: 'flex',
        border: '1px solid #A0A0A0',
        borderRadius: '20px',
        //   justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 16px',
        boxShadow: '0px 4px 4px 0px #00000040',
        overflow: 'hidden',
      }}
    >
      {/* 1st */}
      {!isMobile && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            color="success"
            overlap="circular"
            badgeContent=" "
            sx={{ flex: 'none' }}
          >
            <Avatar
              onClick={() => onClick(currentUser?.user?._id)}
              sx={{ height: '64px', width: '64px', cursor: 'pointer' }}
              alt="Remy Sharp"
              src={profileImage}
            />
          </Badge>
          <div style={{ color: '#001E6F', fontSize: '20px', fontWeight: 'bold' }}>You</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '16px', color: '#03A600' }}>{Math.abs(rankUp)}</span>
            <PlayArrowIcon sx={{ rotate: rankUp >= 0 ? '-90deg' : '90deg', color: '#B88F00', fontSize: '20px' }} />
          </div>
        </div>
      )}
      {/* 2nd */}
      <div
        style={{ display: 'flex', flexDirection: 'column', marginLeft: isMobile ? '0px' : '20px', marginRight: '6px' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', color: '#3d71ff', lineHeight: '22px', gap: '4px' }}>
          <div
            style={{
              marginTop: '16px',
              backgroundColor: '#3D71FF',
              color: 'white',
              height: '26px',
              borderRadius: '8px',
              padding: '0px 6px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ lineHeight: '14px', textTransform: 'capitalize' }}>Your Rank - {selectedType}</span>
          </div>
          <div style={{ display: 'flex', gap: '4px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                border: '1px solid #C5BDBD',
                padding: '2px',
                paddingBottom: '4px',
                width: '64px',
                borderRadius: '4px',
              }}
            >
              <span style={{ fontSize: '12px', color: 'black', fontWeight: 'bold' }}>Previous</span>
              <span className="SafalLeaderBoard-rank-number" style={{ fontSize: '30px' }}>
                {rank}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyItems: 'center',
                border: '1px solid #C5BDBD',
                padding: '2px',
                paddingBottom: '4px',
                width: '64px',
                borderRadius: '4px',
              }}
            >
              <span style={{ fontSize: '12px', color: 'black', fontWeight: 'bold' }}>Current</span>
              <span className="SafalLeaderBoard-rank-number" style={{ fontSize: '30px' }}>
                {rank}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: '16px',
            backgroundColor: '#0932a1',
            color: 'white',
            height: '26px',
            borderRadius: '8px',
            padding: '0px 6px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span style={{ lineHeight: '14px' }}>{points} Spts</span>
        </div>
      </div>
      {/* 3rd */}
      <div style={{ marginLeft: 'auto', gap: '4px', flex: 'none', display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #C5BDBD',
            padding: '4px',
            borderRadius: '4px',
          }}
        >
          <span style={{ color: '#001E6F', fontSize: '16px' }}>Grab your crown</span>
          <div style={{ color: '#001E6F', fontSize: '14px', display: 'flex', alignItems: 'center' }}>
            <b
              className="SafalLeaderBoard-rank-number"
              style={{ fontSize: '20px', color: '#3D71FF', marginRight: '4px' }}
            >
              {pointNeedToTop}
            </b>
            Spts more need
            <img style={{ height: '40px', objectFit: 'contain' }} src={kingImg} alt="crown" />
          </div>
        </div>
        {!isMobile && pendingBuddy.length > 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <span style={{ color: '#4F79EA', borderBottom: '1px solid #4F79EA', fontSize: '14px' }}>
              New Buddy Requests Received
            </span>
            <AvatarGroup
              title="Open SafalBuddy"
              // onClick={onOpenBuddy}
              onClick={() => {
                navigate('/safalbuddy?status=pending');
              }}
              sx={{ marginRight: 'auto', '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 } }}
              max={8}
            >
              {pendingBuddy.map((e, index) => (
                <ReturnAvatar key={index} data={e} />
              ))}
            </AvatarGroup>
          </div>
        ) : (
          <Button
            sx={{
              backgroundColor: '#3D71FF',
              borderRadius: '30px',
              textTransform: 'capitalize',
              width: '110px',
            }}
            onClick={() => {
              navigate('/safalbuddy');
            }}
            // onClick={onOpenBuddy}
            title="Open SafalBuddy"
            variant="contained"
            size="small"
            startIcon={
              <img
                style={{ width: '20px', height: '20px', objectFit: 'contain', filter: 'invert(1)' }}
                src={'/assets/images/buddy_icon.png'}
                alt="SafalBuddy"
              />
            }
          >
            SafalBuddy
          </Button>
        )}
      </div>
    </div>
  );
};

const ReturnAvatar = ({ data }) => {
  const profileImage = useProfileImage(data?.sender?.profilePic);
  const fullName = `${data?.sender?.firstName} ${data?.sender?.lastName}`;
  return <Avatar alt={fullName} src={profileImage} />;
};

const TopUserBox = ({ top, left, data, selectedType, king, onChat, onCall, onClick }) => {
  const profileImage = useProfileImage(data?.user?.profilePic);
  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const currentUserToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;
  const windowSize = useWindowSize();

  const isYou = currentFirebaseUser?.uid === data?.user?._id;
  const fullName = isYou ? 'You' : `${data?.user?.firstName || ''} ${data?.user?.lastName || ''}`;

  const isChatCallEnable = isYou ? false : currentUserToUserChatEnable ? otherUserInfo?.userToUserChat === true : false;

  const fetchOtherUserFirebase = async (otherUserId) => {
    if (!otherUserId) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', otherUserId));
    if (otherUserInfoRef.exists()) {
      setOtherUserInfo(otherUserInfoRef.data());
    }
  };
  useEffect(() => {
    fetchOtherUserFirebase(data?.user?._id);
  }, [data?.user?._id]);

  const onCallClick = async () => {
    onCall(data?.user?._id, otherUserInfo, 'audio');
  };
  const onCallClickVideo = async () => {
    onCall(data?.user?._id, otherUserInfo, 'video');
  };
  const onChatClick = async () => {
    onChat(data?.user?._id, otherUserInfo);
  };

  const points =
    selectedType === 'yearly'
      ? data?.lastYearPoints
      : selectedType === 'daily'
      ? data?.lastWeekPoints
      : selectedType === 'weekly'
      ? data?.weeklyPoints
      : selectedType === 'monthly'
      ? data?.lastMonthPoints
      : 0;

  const isMobile = windowSize.width < 640;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: isMobile ? '100px' : '200px',
        position: 'absolute',
        top: top,
        left: left,
      }}
    >
      {king && <img src={kingImg} style={{ width: '40px', objectFit: 'contain' }} alt="kingImg" />}
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        color="success"
        overlap="circular"
        badgeContent=" "
        sx={{ flex: 'none' }}
      >
        <Avatar
          onClick={() => onClick(data?.user?._id)}
          sx={{ height: isMobile ? '32px' : '64px', width: isMobile ? '32px' : '64px', cursor: 'pointer' }}
          alt={fullName}
          src={profileImage}
        />
      </Badge>
      <span className="SafalLeaderBoard-user-title" style={{ color: '#001E6F', fontSize: isMobile ? '16px' : '24px' }}>
        {fullName}
      </span>
      <div
        style={{
          backgroundColor: '#0932a1',
          color: 'white',
          height: '26px',
          borderRadius: '8px',
          padding: '0px 6px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span style={{ lineHeight: '14px' }}>{points} Spts</span>
      </div>
      <div style={{ display: 'flex', gap: '4px', marginTop: '4px' }}>
        <button
          onClick={onChatClick}
          disabled={!isChatCallEnable}
          style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
        >
          <img
            src={SafalLeaderBoard_Chat}
            style={{
              width: isMobile ? '16px' : '20px',
              height: isMobile ? '16px' : '20px',
              objectFit: 'contain',
              filter: !isChatCallEnable ? 'grayscale(1)' : '',
            }}
            alt="SafalLeaderBoard_Chat"
          />
        </button>
        <button
          onClick={onCallClick}
          disabled={!isChatCallEnable}
          style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
        >
          <img
            src={SafalLeaderBoard_Call}
            style={{
              width: isMobile ? '16px' : '20px',
              height: isMobile ? '16px' : '20px',
              objectFit: 'contain',
              filter: !isChatCallEnable ? 'grayscale(1)' : '',
            }}
            alt="SafalLeaderBoard_Call"
          />
        </button>
        <button
          onClick={onCallClickVideo}
          disabled={!isChatCallEnable}
          style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
        >
          <img
            src={SafalLeaderBoard_Video}
            style={{
              width: isMobile ? '16px' : '20px',
              height: isMobile ? '16px' : '20px',
              objectFit: 'contain',
              filter: !isChatCallEnable ? 'grayscale(1)' : '',
            }}
            alt="SafalLeaderBoard_Video"
          />
        </button>
      </div>
    </div>
  );
};
