const SafalRoomAbout = ({ data, roomInfo }) => {
  console.log('DEBUG about', data);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '16px', gap: '6px' }}>
      {/* {data?.logo && (
        <img
          style={{
            width: '100px',
            objectFit: 'contain',
            border: '1px solid #bcceff',
            borderRadius: '8px',
          }}
          src={data?.logo}
          alt={data?.name}
        />
      )} */}
      <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{data?.name}</span>
      <span style={{ fontSize: '18px', color: '#3D71FF' }}>{data?.companyType}</span>
      <a href={data?.website}>{data?.website}</a>
      <span style={{ fontSize: '16px', lineHeight: 'normal' }}>{data?.description}</span>
    </div>
  );
};
export default SafalRoomAbout;
