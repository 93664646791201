import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import MDEditor from '@uiw/react-md-editor';

import { updatePrivacyPolicyAndTermOfCondition } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  termOfCondition: '',
  termOfConditionMD: '',
  privacyPolicy: '',
  privacyPolicyMD: '',
};

export default function UpdatePrivacyPolicyAndTermConditionModal({ open, onClose, data }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    onClose();
  };

  const onUpdateSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await updatePrivacyPolicyAndTermOfCondition({ ...fieldValue, _id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue(data);
    } else {
      setFieldValue(initFieldValue);
    }
  }, [open, data]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          Update Privacy Policy And Term & Condition
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <span>Terms & Conditions Markdown & Text Format</span>
          <MDEditor
            height="250px"
            data-color-mode="light"
            value={fieldValue?.termOfConditionMD}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, termOfConditionMD: e }))}
          />
          <TextField
            value={fieldValue?.termOfCondition}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, termOfCondition: e.target.value }))}
            label={`Term & Condition Text`}
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            minRows={2}
            maxRows={3}
            disabled={isSubmitting}
          />
          <span>Privacy Policy Markdown & Text Format</span>
          <MDEditor
            height="250px"
            data-color-mode="light"
            value={fieldValue?.privacyPolicyMD}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, privacyPolicyMD: e }))}
          />
          <TextField
            value={fieldValue?.privacyPolicy}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, privacyPolicy: e.target.value }))}
            label={`Privacy Policy Text`}
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            minRows={2}
            maxRows={3}
            disabled={isSubmitting}
          />
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={onUpdateSubmit}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Update
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
