import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RefreshIcon from '@mui/icons-material/Refresh';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import FileDownload from 'js-file-download';
import Swal from 'sweetalert2';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../user';
import Feedback from '../../pages/common/Feedback';
import UserFeedbackModal from '../../components/Modals/UserFeedbackModal';
import { getUserFeedbacksAction } from '../../store/feedbackSlice';
import { ExcelDownload, PDFDownload, TabDownload, TxtDownload } from '../../services/Service';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import CustomButton from '../../components/UI/CustomButton';

const TABLE_HEAD = [
  { id: 'safalFeedbackNo', label: 'SafalFeedback No', alignRight: false },
  { id: 'fromValue', label: 'From Value ', alignRight: false },
  { id: 'toValue', label: 'To Value', alignRight: false },
  { id: 'userName', label: 'UserName', alignRight: false },
  { id: 'dateTime', label: 'Date & Time', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'comments', label: 'Comments', alignRight: false },
  { id: 'nextSteps', label: 'Next Steps', alignRight: false },
  { id: 'whoChanged', label: '', alignRight: false },
  { id: 'whoChanged', label: '', alignRight: false },
];

const FeedbackLog = () => {
  const [value, setValue] = React.useState(null);
  const [fromDate, setFromDate] = React.useState('');
  const [toDate, setToDate] = React.useState('');

  const [feedback, setFeedback] = React.useState(false);
  const [feedBackNo, setFeedBackNo] = React.useState();
  const [statusValue, setStatusValue] = React.useState('');
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.user);
  const { user } = useSelector((state) => state.login);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isSubmited, setSubmited] = React.useState(null);
  const [search, setSearch] = React.useState({});
  const [searchFilter, setSearchFilter] = React.useState({});

  const [selectedDate, setSelectedDate] = useState(null);
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);

  const [selectedToDate, setSelectedToDate] = useState(null);
  const [isDisabledField, setIsDisabledField] = useState(true);

  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  function getFirstDayOfMonth() {
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01T00:00:00.000Z`;
  }

  const handleFeedbackOpen = () => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setFeedback(true);
      setSubmited(false);
    }
  };
  const handleFeedbackClose = () => {
    setSubmited(false);
    setFeedback(false);
  };

  function formatDateToISOString(date = new Date()) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}T23:59:00.000Z`;
  }

  useEffect(() => {
    setSearch({ ...search, supportId: feedBackNo && feedBackNo !== '' ? Number(feedBackNo) : '' });
  }, []);

  const obj = React.useMemo(() => ({ ...search }), [search]);
  useEffect(() => {
    if (search.supportId < 1) {
      // eslint-disable-next-line no-undef
      delete obj.supportId;
    }
    dispatch(
      getUserFeedbacksAction({
        id: userData?._id,
        filter: {
          sort: {
            supportId: 1,
          },
          // eslint-disable-next-line no-undef
          search: obj,
          //   updatedAt: {
          //     from: fromDate,
          //     to: toDate
          // }
        },
        from: fromDate,
        to: toDate,
      })
    );
  }, [search]);

  const handleFilterReports = () => {
    const payload = { status: statusValue, supportId: feedBackNo };

    if (!payload.supportId) {
      // eslint-disable-next-line no-undef
      delete payload.supportId;
    }

    if (!payload.status) {
      delete payload.status;
    } else {
      setSearch({ ...search, status: payload.status });
    }

    const newToDate = toDate.split('T')[0].concat('T23:59:00.000Z');
    dispatch(
      getUserFeedbacksAction({
        id: userData?._id,
        filter: {
          // eslint-disable-next-line no-undef
          search: payload,
          //   updatedAt: {
          //     from: fromDate,
          //     to: toDate
          // }
        },
        from: fromDate,
        to: newToDate,
      })
    );
  };
  const refreshData = () => {
    const payload = { status: statusValue, supportId: feedBackNo };

    if (!payload.supportId) {
      // eslint-disable-next-line no-undef
      delete payload.supportId;
    }

    if (!payload.status) {
      delete payload.status;
    }

    const newToDate = toDate.split('T')[0].concat('T23:59:00.000Z');
    dispatch(
      getUserFeedbacksAction({
        id: userData?._id,
        filter: {
          // eslint-disable-next-line no-undef
          search: payload,
          //   updatedAt: {
          //     from: fromDate,
          //     to: toDate
          // }
        },

        from: fromDate,
        to: newToDate,
      })
    );
    setRowsPerPage(5);
  };
  const handleClearFilters = () => {
    setSelectedDate(null);
    setSelectedToDate(null);
    setFromDate('');
    setToDate('');
    setFeedBackNo('');
    setStatusValue('');
    setSearch({});
  };

  const downloadFileNew = async (type) => {
    switch (type) {
      case 'dnl':
        TabDownload({ id: userData?._id, toDate: toDate, fromDate: fromDate, search: search });
        break;
      case 'pdf':
        PDFDownload({ id: userData?._id, fromDate: fromDate, toDate: toDate, search: search });
        break;
      case 'txt':
        TxtDownload({ id: userData?._id, toDate: toDate, fromDate: fromDate, search: search });
        break;
      case 'csv':
        ExcelDownload({ id: userData?._id, toDate: toDate, fromDate: fromDate, search: search });
        break;
      default:
        break;
    }
  };
  return (
    <Box>
      <Grid sm={12} md={12} lg={12} container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
        <Grid item sm={12} md={6} lg={3.5} xl={3.5}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Typography sx={{ color: '#000000', fontWeight: 400, mr: 2, mt: 1 }}>Date</Typography> */}
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <DesktopDatePicker
                  label={<Typography sx={{ color: '#B6B6B6' }}>From</Typography>}
                  inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                  onChange={(e) => {
                    const d = moment(e).utc(e).format('YYYY-MM-DD[T]00:00:00.000[Z]');
                    // console.log(moment(e).utc(e).format('YYYY-MM-DD[T]00:00:00.000[Z]'),"dddddddd")
                    setFromDate(d);
                  }}
                  value={fromDate !== '' ? new Date(fromDate) : null}
                  renderInput={(params) => (
                    <TextField
                      // name="From"
                      variant="standard"
                      size="small"
                      {...params}
                      sx={{
                        svg: { color: '#0071E3', mr: '5px' },
                      }}
                      // error={
                      //   "please enter / select valid date"
                      //  }
                      //  helperText={
                      //    "please enter / select valid date"
                      //  }
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth sx={{ ml: 2 }}>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <DesktopDatePicker
                  label={<Typography sx={{ color: '#B6B6B6' }}>To</Typography>}
                  inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                  onChange={(e) => {
                    // if (new Date(e) >= new Date(fromDate)) {
                    const d = moment(e).utc(e).format('YYYY-MM-DD[T]00:00:00.000[Z]');
                    setToDate(d);
                    // }
                    //  else {
                    //   alert('Please select valid date.');
                    // }
                  }}
                  disabled={fromDate === ''}
                  value={toDate !== '' ? new Date(toDate) : null}
                  renderInput={(params) => (
                    <TextField
                      // name="To"
                      variant="standard"
                      size="small"
                      {...params}
                      sx={{
                        svg: { color: '#0071E3', mr: '5px' },
                      }}
                      // error={
                      //     "please enter / select valid date"
                      // }
                      // helperText={
                      //   "please enter / select valid date"
                      // }
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
          <FormHelperText>
            {toDate && fromDate ? (
              new Date(toDate) >= new Date(fromDate) ? (
                ''
              ) : (
                <Typography sx={{ color: '#FF4842' }}> Please select valid To Date</Typography>
              )
            ) : (
              ''
            )}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2.5} xl={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, mt: 1, whiteSpace: 'nowrap' }}>
              SafalFeedback No
            </Typography>
            <FormControl
              fullWidth
              sx={{ ml: 2 }}
              // error={SubscriptionForm.touched.website && SubscriptionForm.errors.website}
            >
              <TextField
                id="1"
                label=" "
                name="safalFeedbackNo"
                variant="standard"
                size="small"
                type={'number'}
                value={feedBackNo}
                onChange={(e) => {
                  // setSearch({
                  //   ...search,
                  //   supportId: Number(e.target.value),
                  // });
                  setFeedBackNo(Number(e.target.value));
                }}
                //   value={SubscriptionForm.values.website}
                //   onChange={SubscriptionForm.handleChange}
              />
              {/* {EditForm.touched.website && EditForm.errors.website ? (
                        <FormHelperText>{EditForm.touched.website && EditForm.errors.website}</FormHelperText>
                      ) : null} */}
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2.5} xl={2}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ color: '#000000', fontWeight: 400, mt: 1, whiteSpace: 'nowrap' }}>Status</Typography>
            <FormControl
              fullWidth
              // error={SubscriptionForm.touched.frequency && Boolean(SubscriptionForm.errors.frequency)}
            >
              <InputLabel id="select3" sx={{ color: '#B6B6B6' }} />
              <Select
                labelId="select3"
                id="select3"
                name="status"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Status<span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                variant="standard"
                size="small"
                value={statusValue}
                onChange={(e) => {
                  setStatusValue(e.target.value);
                }}
                //   value={SubscriptionForm.values.frequency}
                //   onChange={SubscriptionForm.handleChange}
                sx={{
                  ml: 2,
                  '&:after': {
                    borderBottomColor: '#0000',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#0071E3',
                  },
                }}
              >
                <MenuItem value={'Submitted'}>Submitted</MenuItem>
                <MenuItem value={'In-progress'}>In-progress</MenuItem>
                <MenuItem value={'Closed'}>Closed</MenuItem>
                <MenuItem value={'Re-opened'}>Re-opened</MenuItem>
              </Select>
              {/* {SubscriptionForm.touched.frequency && SubscriptionForm.errors.frequency ? (
              <FormHelperText>{SubscriptionForm.touched.frequency && SubscriptionForm.errors.frequency}</FormHelperText>
            ) : null} */}
            </FormControl>
          </Box>
        </Grid>

        <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', padding: '8px 2px', gap: '12px' }}>
          <Tooltip title="Search">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={handleFilterReports}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Clear Filter">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={handleClearFilters}
            >
              <FilterAltOffIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add new feedback">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={handleFeedbackOpen}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refresh">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={refreshData}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <ReportsTabsDownloadMenu downloadFile={downloadFileNew} toastSuccess />
        </div>
      </Grid>
      <Feedback
        search={search}
        fromDate={fromDate}
        toDate={toDate}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />

      <UserFeedbackModal
        open={feedback}
        handleClose={handleFeedbackClose}
        isSubmited={isSubmited}
        setSubmited={setSubmited}
      />
    </Box>
  );
};

export default FeedbackLog;
