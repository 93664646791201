/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/jsx-boolean-value */

import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Switch,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import { Image } from 'antd';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReactECharts from 'echarts-for-react';

import DateField from '../../components/UI/DateField';
import CustomButton from '../../components/UI/CustomButton';
import useSortHook from '../../hooks/useSortHook';
import {
  CustomTableCellForBody,
  CustomTableCellForLoading,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import UserCommentsModal from '../../components/Modals/UserCommentsModal';
import {
  downloadSubLabelReport,
  downloadSubscriptionsTrend,
  getSignedURL,
  getSubLabelReport,
  getSubscriptionsTrend,
} from '../../services/Service';
import useDebounce from '../../hooks/useDebounce';
import Iconify from '../../components/Iconify';
import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import SubscriptionTrendFilterMenu from './SubscriptionTrendFilterMenu';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import CustomRating from '../../components/UI/CustomRating';
import LabelReportFilterMenu from './LabelReportFilterMenu';
import Inlineloader from '../../components/Loader/InlineLoader';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const LabelReport = () => {
  const [filterState, setFilterState] = useState({
    nextBillingFrom: '',
    nextBillingTo: '',
    amountTo: '',
    amountFrom: '',
    search: '',
    frequency: '',
    companyType: '',
    status: '',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [labelChartMode, setLabelChartMode] = useState('bar'); // bar/pie
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { handleSort, newSortData, sortState } = useSortHook(tableData);
  const { user } = useSelector((state) => state.login);
  const currencySymbol = user?.country === 'United States' ? '$' : '₹';
  // DeBounce Function
  useDebounce(
    async () => {
      await fetchData();
    },
    [filterState],
    1000
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const _filterState = { ...filterState };
      if (_filterState.nextBillingFrom) {
        _filterState.nextBillingFrom = moment(_filterState.nextBillingFrom).add(1, 'days').toISOString();
      }
      if (_filterState.nextBillingTo) {
        _filterState.nextBillingTo = moment(_filterState.nextBillingTo).add(1, 'days').toISOString();
      }

      const res = await getSubLabelReport(_filterState);
      setTableDataRaw(res.data.data);
      // const newData = res.data.data.map((ex) => ({
      //   logo: ex.company?.logo,
      //   companyName: ex.company?.name,
      //   companyType: ex.company?.companyType,
      //   totalUsers: ex.totalUsers,
      //   averageRating: ex.averageRating,
      //   like: ex.like.likeCount,
      //   dislike: ex.like.dislikeCount,
      //   isNeutral: ex.like.likeCount === ex.like.dislikeCount,
      // }));
      setTableData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  const downloadTrend = async (type) => {
    try {
      const res = await downloadSubLabelReport({ ...filterState, fileType: type });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  const chartOption = {
    title: {
      text: 'Label Report', // Your desired title here
      textStyle: {
        fontWeight: 'bold',
        fontSize: 18,
      },
      left: 'left', // Adjust the position of the title if needed
    },
    tooltip: {
      trigger: 'axis',
      position: function (point, params, dom, rect, size) {
        // Positioning tooltip on the right side
        return [point[0] + 10, '10%'];
      },
      formatter: (params) => {
        let tooltip = `${params[0].name}<br/>`;
        params.forEach((param) => {
          tooltip += `${param.seriesName}: ${currencySymbol}${new Intl.NumberFormat().format(param.value)}<br/>`;
        });
        return tooltip;
      },
    },
    // legend: {
    //   data: ['Budget', 'Over Budget', 'Under Budget'],
    // },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    calculable: true,
    grid: {
      top: '25%', // Increase top margin to ensure space for labels
      bottom: '10%', // Add bottom margin to ensure space for labels
    },
    xAxis: [
      {
        type: 'category',
        data: tableData.map((e) => e.name),
        axisLabel: {
          interval: 0, // Show all labels
          // rotate: 45, // Rotate labels to prevent overlap
          // formatter: (value) => {
          //   return value.length > 10 ? `${value.slice(0, 10)}...` : value; // Optional: truncate long labels
          // },
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          formatter: `${currencySymbol} {value}`,
        },
      },
    ],
    dataZoom: [
      {
        type: 'inside', // Enable zooming
        start: 0,
        end: 100,
      },
      {
        type: 'slider', // Enable a slider for zooming
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: 'Budget',
        type: 'bar',
        data: tableData.map((e) => e.budget || 0),
        color: '#3e71ff', // Blue color for Budget
        label: {
          show: true,
          position: 'top',
          verticalAlign: 'middle',
          rotate: 45,
          distance: 10,
          formatter: `${currencySymbol} {c}`,
        },
      },
      {
        name: 'Actual Budget',
        type: 'bar',
        data: tableData.map((e) => e.total),
        itemStyle: {
          color: (p) => {
            const dataIndex = p.dataIndex;
            if (tableData[dataIndex].budget <= p.value) return '#FF0000';
            return '#01bc56';
          }, // Green color for Actual (Under Budget)
        },
        label: {
          show: true,
          position: 'top',
          rotate: 45,
          verticalAlign: 'middle',
          distance: 10,
          formatter: (params) => {
            return params.value > 0 ? `${currencySymbol} ${new Intl.NumberFormat().format(params.value)}` : '';
          },
        },
      },
    ],
  };

  const labelOption = {
    title: {
      text: 'Label Report', // Your desired title here
      textStyle: {
        fontWeight: 'bold',
        fontSize: 18,
      },
      left: 'left', // Adjust the position of the title if needed
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'item',
      // formatter: `{a} <br/>{b} : ${currencySymbol}{c} ({d}%)`,
      formatter: function (params) {
        return `${params.seriesName} <br/>${params.name} : ${currencySymbol}${new Intl.NumberFormat().format(
          params.value
        )} (${params.percent}%)`;
      },
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 10,
      top: 20,
      bottom: 20,
      data: tableData.map((e) => e.name),
    },
    series: [
      {
        name: 'Label Wise Report',
        type: 'pie',
        radius: '55%',
        center: ['40%', '50%'],
        data: tableData.map((e) => ({ name: e.name, value: e.total })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        label: {
          // formatter: `${currencySymbol}{@2012} ({d}%)`,
          formatter: function (params) {
            return `${currencySymbol}${new Intl.NumberFormat().format(params.value)} (${params.percent}%)`;
          },
        },
      },
    ],
  };

  if (isLoading) return <Inlineloader />;

  return (
    <div style={{ overflow: 'auto' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <SearchStyle
            sx={{
              width: '220px',
            }}
            value={filterState.search}
            onChange={(e) => setFilterState((prev) => ({ ...prev, search: e.target.value }))}
            placeholder="Search by Label Name"
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ fontSize: '14px' }}>Bar</span>
            <Switch
              style={{ color: '#2065d1' }}
              checked={labelChartMode === 'pie'}
              onChange={(e) => setLabelChartMode(e.target.checked ? 'pie' : 'bar')}
              size="normal"
            />
            <span style={{ fontSize: '14px' }}>Pie</span>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {/* <DateField
            width="140px"
            value={filterState.nextBillingFrom}
            variant="standard"
            label="From"
            onChange={(e) => setFilterState((prev) => ({ ...prev, nextBillingFrom: e.toISOString() }))}
          />
          <DateField
            width="140px"
            value={filterState.nextBillingTo}
            variant="standard"
            label="To"
            onChange={(e) => setFilterState((prev) => ({ ...prev, nextBillingTo: e.toISOString() }))}
          /> */}
          <LabelReportFilterMenu state={filterState} onFilterApply={onFilterApply} />
          <Tooltip title="Refresh">
            <IconButton
              sx={{
                height: '40px',
                flex: 'none',
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={fetchData}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <ReportsTabsDownloadMenu downloadFile={downloadTrend} />
        </div>
      </div>
      <div>
        {tableData.length} of {tableData.length}
        <div className="flex-col-1000px" style={{ display: 'flex', gap: '16px' }}>
          <div style={{ flex: '1', position: 'relative' }}>
            <ReactECharts
              notMerge={true}
              lazyUpdate={true}
              option={labelChartMode === 'bar' ? chartOption : labelOption}
              style={{ height: '100%', minHeight: '400px', maxHeight: '600px' }}
            />
            {labelChartMode === 'bar' && (
              <div
                style={{
                  position: 'absolute',
                  top: 22,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <div style={{ backgroundColor: '#3e71ff', width: '20px', height: '16px', borderRadius: '4px' }} />
                  <span style={{ whiteSpace: 'nowrap' }}>Budget</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <div style={{ backgroundColor: '#FF0000', width: '20px', height: '16px', borderRadius: '4px' }} />
                  <span style={{ whiteSpace: 'nowrap' }}>Over Budget</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                  }}
                >
                  <div style={{ backgroundColor: '#01bc56', width: '20px', height: '16px', borderRadius: '4px' }} />
                  <span style={{ whiteSpace: 'nowrap' }}>Under Budget</span>
                </div>
              </div>
            )}
          </div>
          <div className="width-full-1000px" style={{ flex: 'none', width: '60%' }}>
            {tableData.map((label) => {
              const labelBudget = label.budget || 0;
              const labelVariance = labelBudget - label.total;

              return (
                <Accordion sx={{ backgroundColor: '#f2f2f2', marginBottom: '12px', minWidth: '100px' }}>
                  <AccordionSummary
                    sx={{
                      fontSize: '18px',
                      fontWeight: 'bold',
                      backgroundColor: '#3d71ff',
                      borderRadius: '12px',
                      minHeight: '38px !important',
                      height: '38px !important',
                      color: 'white',
                    }}
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                  >
                    <div
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                    >
                      <span
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          // maxWidth: '140px',
                          whiteSpace: 'nowrap',
                          fontSize: '14px',
                        }}
                      >
                        <Tooltip title={label.name}>{label.name}</Tooltip>
                      </span>
                      <span style={{ fontSize: '14px' }}>
                        Budget {currencySymbol}
                        {new Intl.NumberFormat().format(label.budget) || '0'} | Actual{' '}
                        <span
                          style={{
                            color: label.total >= 0 ? '#0aff79' : 'red',
                          }}
                        >
                          {currencySymbol}
                          {new Intl.NumberFormat().format(label.total)}
                        </span>{' '}
                        | Variance{' '}
                        <span
                          style={{
                            color: labelVariance >= 0 ? '#0aff79' : 'red',
                          }}
                        >
                          {currencySymbol}
                          {new Intl.NumberFormat().format(labelVariance)}
                        </span>
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: '#f2f2f2',
                      minHeight: 0,
                      overflow: 'auto',
                      textAlign: 'center',
                      // height: '100px',
                    }}
                  >
                    {label?.subscriptions.length === 0 && <span>No subscription attach to this label</span>}
                    {label?.subscriptions.length > 0 && <span style={{ fontWeight: 'bold' }}>{label.name}</span>}
                    {label?.subscriptions.length > 0 && (
                      <TableContainer>
                        <CustomTableView headData={['Logo', 'Company Name', 'Frequency', 'Amount']}>
                          {label?.subscriptions?.map((e, index) => (
                            <TableRow key={index}>
                              <CustomTableCellForBody align="center">
                                <Image src={e?.company?.logo} height={40} />
                              </CustomTableCellForBody>
                              <CustomTableCellForBody align="center">
                                <p style={tableCellPStyle}>{e.subscriptionName}</p>
                              </CustomTableCellForBody>
                              <CustomTableCellForBody align="center">
                                <p style={tableCellPStyle}>{e.frequency}</p>
                              </CustomTableCellForBody>
                              <CustomTableCellForBody align="center">
                                <p style={tableCellPStyle}>
                                  {currencySymbol} {new Intl.NumberFormat().format(e.amount)}
                                </p>
                              </CustomTableCellForBody>
                            </TableRow>
                          ))}
                        </CustomTableView>
                      </TableContainer>
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      </div>
      {/* <UserCommentsModal open={isUserCommentsModal.open} onClose={onCloseUserCommentsModal} /> */}
    </div>
  );
};

export default LabelReport;
