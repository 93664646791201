/* eslint-disable */
import { Tooltip } from '@mui/material';
import BotModal from '../Modals/BotModal';
import React from 'react';
import { useSelector } from 'react-redux';

const Chatbot = () => {
  const [open, setOpen] = React.useState(false);
  const { supportAlert, userAlert } = useSelector((state) => state.chats);
  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);

  const safalBot_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'safal bot');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {safalBot_DynamicMenuState && (
        <>
          {userAlert && <div className="chat-bot1"></div>}
          {supportAlert && <div className="chat-bot1"></div>}

          <Tooltip title="SafalBot">
            {/* Chatbot Notification Dot  */}
            <img
              className="chat-bot"
              onClick={handleClickOpen}
              aria-label="add"
              src="/assets/images/Group.png"
              alt=""
            />
          </Tooltip>
          <BotModal open={open} handleClose={handleClose} />
        </>
      )}
    </>
  );
};

export default Chatbot;
