import React from 'react';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function CollapseShow({ item }) {
  console.log('Collapse props', item.email);

  return (
    <div>
      <Collapse
        // eslint-disable-next-line no-unneeded-ternary
        in
        timeout="auto"
        unmountOnExit
        style={{
          border: '1px solid #7B9EFD',
          borderRadius: 15,
          padding: '4px 0px 4px 19px',
          width: '100%',
          marginTop: 10,
        }}
      >
        {item.email}
      </Collapse>
    </div>
  );
}

export default CollapseShow;
