import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete }) => {
  const { formatDateTime } = useUserTimeZone();

  const headData = [
    'Who Created',
    'Ease Of Use',
    'Money Saving Features',
    'Overall Experience',
    'AI Chat Subscription',
    'Overall Satisfaction',
    'Created at',
  ];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {data.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
        {data?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">{e?.whoCreated?.userID}</CustomTableCellForBody>
            <CustomTableCellForBody align="center">{e?.easeOfUse}</CustomTableCellForBody>
            <CustomTableCellForBody align="center">{e?.moneySavingFeatures}</CustomTableCellForBody>
            <CustomTableCellForBody align="center">{e?.overallExperience}</CustomTableCellForBody>
            <CustomTableCellForBody align="center">{e?.aiChatSubscription}</CustomTableCellForBody>
            <CustomTableCellForBody align="center">{e?.overallSatisfaction}</CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
