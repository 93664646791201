/* eslint-disable no-useless-escape */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import toast from 'react-hot-toast';
import { InputAdornment, MenuItem, TextField, Checkbox, Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import {
  addAssetModulecompany,
  uploadAssetModuleImages,
  updateAssetModulecompany,
  getCompanyType,
  addCompanyType,
} from '../../../services/Service';
import ErrorToast from '../../../components/Toast/Error';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  type: '',
  website: '',
  description: '',
  country: 'USA',
};
const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}([\/\w-]*)*\/?$/;

export default function AddAssetModuleCompanyModal({ open, onClose, data }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [fieldValueFile, setFieldValueFile] = React.useState(null);
  const [nameError, setNameError] = React.useState(false);
  const [fileError, setFileError] = React.useState(false);
  const [companyTypeError, setCompanyTypeError] = React.useState(false);
  const [websiteError, setWebsiteError] = React.useState(false);
  const [countryError, setCountryError] = React.useState(false);
  const [descError, setDescError] = React.useState(false);
  const [isSubscription, setIsSubscription] = React.useState(false);
  const [companyTypeList, setCompanyTypeList] = React.useState([]);
  const [addCompanyTypeFlag, setAddCompanyTypeFlag] = React.useState(false);
  const [addCompanyTypeValue, setAddCompanyTypeValue] = React.useState(null);
  const [addCompanyTypeError, setAddCompanyTypeError] = React.useState(false);
  const { user } = useSelector((state) => state.login);

  const handleClose = () => {
    onClose();
    setFieldValue(initFieldValue);
    setFieldValueFile(null);
    setCompanyTypeList([]);
    setAddCompanyTypeFlag(false);
    setAddCompanyTypeValue(null);
    setAddCompanyTypeError(false);
  };

  const onChangeField = (e) => {
    // console.log('e.target.name-->', e.target.name);
    if (e.target.name === 'name') {
      setNameError(false);
    } else if (e.target.name === 'type') {
      setCompanyTypeError(false);
      if (e.target.value === 'Not Listed') {
        setAddCompanyTypeFlag(true);
      } else {
        setAddCompanyTypeFlag(false);
        setAddCompanyTypeValue(null);
        setAddCompanyTypeError(false);
      }
    } else if (e.target.name === 'website') {
      setWebsiteError(false);
      const websiteValue = e.target.value.trim();
      if (websiteRegex.test(websiteValue)) {
        if (websiteValue.startsWith('www.')) {
          setFieldValue((prev) => ({ ...prev, website: `https://${websiteValue}` }));
        } else {
          setFieldValue((prev) => ({ ...prev, website: websiteValue }));
        }
      } else {
        setWebsiteError(true);
      }
    } else if (e.target.name === 'country') {
      setCountryError(false);
    } else if (e.target.name === 'description') {
      setDescError(false);
    }
    setFieldValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = async () => {
    if (!fieldValueFile) {
      setFileError(true);
      return;
    }
    if (!fieldValue?.name || fieldValue?.name?.length < 3 || fieldValue?.name?.length > 100) {
      setNameError(true);
      return;
    }
    if (!fieldValue?.type || fieldValue?.type?.length < 3 || fieldValue?.type?.length > 100) {
      setCompanyTypeError(true);
      return;
    }

    if (
      (fieldValue?.type === 'Not Listed' && !addCompanyTypeValue) ||
      addCompanyTypeValue?.length < 3 ||
      addCompanyTypeValue?.length > 100
    ) {
      setAddCompanyTypeError(true);
      return;
    }
    if (!fieldValue.website || fieldValue.website.length < 3 || fieldValue.website.length > 100) {
      setWebsiteError(true);
      return;
    }
    if (!fieldValue.country || fieldValue.country.length < 3 || fieldValue.country.length > 100) {
      setCountryError(true);
      return;
    }
    if (!fieldValue.description || fieldValue.description.length < 3 || fieldValue.description.length > 250) {
      setDescError(true);
      return;
    }

    console.log('fieldValue 1', fieldValue);
    try {
      let comType = fieldValue.type;
      if (fieldValue.type === 'Not Listed') {
        const resx = await addCompanyType({ type: addCompanyTypeValue });
        comType = resx.data.data.type;
      }

      const imageRes = await uploadAssetModuleImages(fieldValueFile);
      if (imageRes.status !== 200) throw new Error('Image upload failed, please try again');

      const payload = { ...fieldValue, isSubscription, logoUrl: imageRes.data.url, type: comType };
      const res = await addAssetModulecompany(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      console.log('error ====>', err);
      toast.error(err.response.data.message);
    }
  };

  const onEdit = async () => {
    try {
      const updateValue = { ...fieldValue, _id: data._id, isSubscription: isSubscription };
      if (fieldValueFile) {
        const imageRes = await uploadAssetModuleImages(fieldValueFile);
        if (imageRes.status !== 200) throw new Error('Image upload failed, please try again');
        updateValue.logoUrl = imageRes.data.url;
      }

      const res = await updateAssetModulecompany(updateValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const fetchCompanyType = async () => {
    try {
      const res = await getCompanyType();
      setCompanyTypeList(res.data.data);
    } catch (err) {
      console.log('error ====>', err);
      toast.error(err.response.data.message);
    }
  };

  React.useEffect(() => {
    if (!open) return;
    fetchCompanyType();
    setFieldValue({
      name: data?.name,
      type: data?.type.toLowerCase(),
      website: data?.website,
      description: data?.description,
      country: data?.country,
    });
    setIsSubscription(data?.isMaster);
  }, [open]);

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add' : 'Edit'} Asset Module Company
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <TextField
            onChange={(e) => {
              if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|bmp|tiff)$/)) {
                ErrorToast('Please upload image in JPG, JPEG, TIFF, BMP, PNG Format ONLY.');
              } else {
                setFieldValueFile(e.target.files[0]);
                setFileError(false);
              }
            }}
            required
            name="logo"
            size="small"
            type="file"
            fullWidth
            variant="outlined"
            inputProps={{
              startAdornment: <InputAdornment position="start" />,
              accept: 'image/*',
            }}
            error={fileError}
            helperText={fileError ? 'Please select a file' : ''}
          />
          <TextField
            value={fieldValue.name}
            onChange={onChangeField}
            required
            label="Company Name"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            error={nameError}
            helperText={nameError ? 'Please enter the Company name(min 3 char)' : ''}
            inputProps={{
              minLength: 3,
              maxLength: 100,
            }}
          />
          <TextField
            value={fieldValue.type}
            onChange={onChangeField}
            select
            required
            label="Company Type"
            name="type"
            size="small"
            fullWidth
            // defaultValue="usa"
            variant="outlined"
            style={{ textTransform: 'capitalize' }}
            error={companyTypeError}
            helperText={companyTypeError ? 'Please select a company type' : ''}
          >
            {companyTypeList.map((e, index) => (
              <MenuItem style={{ textTransform: 'capitalize' }} key={index} value={e.type.toLowerCase()}>
                {e.type.toLowerCase()}
              </MenuItem>
            ))}
            <MenuItem style={{ textTransform: 'capitalize' }} value={'Not Listed'}>
              Not Listed
            </MenuItem>
          </TextField>
          {addCompanyTypeFlag && (
            <TextField
              value={addCompanyTypeValue}
              onChange={(e) => setAddCompanyTypeValue(e.target.value)}
              required
              label="New Company Type"
              size="small"
              type="text"
              fullWidth
              variant="outlined"
              error={addCompanyTypeError}
              helperText={addCompanyTypeError ? 'Please enter the Company Type(min 3 char)' : ''}
              inputProps={{
                minLength: 3,
                maxLength: 100,
              }}
            />
          )}
          <TextField
            value={fieldValue.website}
            onChange={onChangeField}
            required
            label="Website"
            name="website"
            size="small"
            type="url"
            fullWidth
            variant="outlined"
            error={websiteError}
            helperText={websiteError ? 'Please enter the valid website' : ''}
            inputProps={{
              minLength: 3,
              maxLength: 100,
            }}
          />
          <TextField
            value={fieldValue.country}
            onChange={onChangeField}
            required
            select
            label="Country"
            name="country"
            size="small"
            fullWidth
            defaultValue="usa"
            variant="outlined"
            error={countryError}
            helperText={countryError ? 'Please select the country' : ''}
            inputProps={{
              minLength: 3,
              maxLength: 100,
            }}
          >
            <MenuItem value={'USA'}>USA</MenuItem>
            <MenuItem value={'IND'}>India</MenuItem>
          </TextField>
          <TextField
            value={fieldValue.description}
            onChange={onChangeField}
            required
            label="Description"
            name="description"
            multiline
            maxRows={4}
            size="small"
            fullWidth
            variant="outlined"
            error={descError}
            helperText={descError ? 'Please enter the Description (min 3 char  max 250 char)' : ''}
            inputProps={{
              minLength: 3,
              maxLength: 250,
            }}
          />
        </div>
        <Grid item xs={12} sm={12} md={6}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={isSubscription}
              onChange={(event) => setIsSubscription(!isSubscription)}
              color="success"
              inputProps={{ 'aria-label': 'controlled' }}
            />
            {'Applicable for Subscription.'}
          </Box>
        </Grid>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button sx={{ backgroundColor: '#19c25f' }} onClick={handleClose} variant="contained">
            Cancel
          </Button>
          {data === null ? (
            <Button sx={{ backgroundColor: '#19c25f' }} onClick={onSubmit} variant="contained">
              Create
            </Button>
          ) : (
            <Button sx={{ backgroundColor: '#19c25f' }} onClick={onEdit} variant="contained">
              Save
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
