import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Image } from 'antd';
import { Field, FormikProvider, useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import ErrorToast from '../Toast/Error';
import { addCustomCompany, checkExistingSub, getCompanyType } from '../../services/Service';
import SuccessToast from '../Toast/Success';
import { debounce } from '../../utils/fn';
import CustomMuiSelect from '../UI/CustomMuiSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '10px',
  boxShadow: 24,
  p: '30px',
  // overflowY:"Auto"
};

const OtherModal = ({ open, onClose }) => {
  const [logoUploadFile, setLogoUploadFile] = React.useState([]);
  const [existingCompany, setExistingCompany] = React.useState(false);
  const { user } = useSelector((state) => state.login);
  const [companyTypeList, setCompanyTypeList] = React.useState([]);
  const [companyTypeNew, setCompanyTypeNew] = React.useState('');

  const SubscriptionFormSchema = Yup.object().shape({
    subType: Yup.string().required('Please Enter Subscription Type'),
    companyName: Yup.string().required('Please Enter Company'),
    description: Yup.string().max(250, 'Must be less than 250 characters!').required('Please Enter Description'),
    website: Yup.string()
      .matches(
        /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        { message: 'Enter a valid website' }
      )
      .required('Please enter a website'),
    price: Yup.number().min(0).max(9999999999).required('Please Enter Price'),
    logo: Yup.mixed().test('type', 'Only the following formats are accepted: .jpeg, .jpg, .png', (value) => {
      if (value) {
        return value[0].type === 'image/jpeg' || value[0].type === 'image/bmp' || value[0].type === 'image/png';
      }
      return true;
    }),
  });

  const initialValues = {
    // eslint-disable-next-line no-unneeded-ternary, no-extra-boolean-cast
    subType: '',
    companyName: '',
    description: '',
    website: '',
    logo: '',
  };

  const fetchCompanyType = async () => {
    try {
      const res = await getCompanyType();
      setCompanyTypeList(res.data.data?.map((e) => e?.type));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!open) return;
    fetchCompanyType();
  }, [open]);

  const SubscriptionForm = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: SubscriptionFormSchema,
    onSubmit: async (val) => {
      val.subType = val.subType === 'Not Listed' ? companyTypeNew : val.subType;
      val.logo = val.logo[0];
      await addCustomCompany(val).then((res) => {
        if (res.data.success === true) {
          val.logo = res.data.data.logo;
          val.id = res.data.data._id;
          val.name = res.data.data.name;
          SuccessToast('Successfully added company');
          handleClose(val);
        } else {
          ErrorToast('Error !!');
        }
      });
    },
  });

  const handleClose = async (data) => {
    setCompanyTypeNew('');
    SubscriptionForm.resetForm();
    setExistingCompany(false);
    onClose(data);
  };

  const handleFileEvent = (e) => {
    setLogoUploadFile([e.target.files[0]]);
    SubscriptionForm.setFieldValue('logo', [e.target.files[0]]);
  };

  const checkForExistingSubscription = (type, name) => {
    const val = {
      subType: type,
      subName: name,
      addOther: true,
    };

    checkExistingSub(val)
      .then((res) => {
        if (res.data.success === true) {
          setExistingCompany(true);
        } else {
          setExistingCompany(false);
        }
      })
      .catch((err) => {
        setExistingCompany(false);
      });
  };

  const newCompanyTypeError = companyTypeList?.find((e) => e?.toLowerCase() === companyTypeNew?.toLowerCase());

  const debouncedComapnySearch = React.useMemo(() => debounce(checkForExistingSubscription, 1000), []);
  return (
    <>
      <Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
        <Box
          id="scrollbar"
          sx={{
            ...style,
            height: { xs: '80%', sm: '80%', md: '70%', lg: '70%', xl: '50%' },
            width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' },
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography variant="h4" gutterBottom sx={{ fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}>
                Add/Create Other Subscription
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ fontSize: '15px', fontWeight: 400 }}>
                Enter your details below.
              </Typography>
            </Box>
            <Fab
              onClick={() => {
                setLogoUploadFile([]);
                handleClose();
              }}
              size="small"
              color="primary"
              aria-label="add"
            >
              <CloseIcon />
            </Fab>
          </Stack>
          <Box sx={{ height: '75%', overflow: 'auto' }}>
            <Scrollbar>
              <FormikProvider value={SubscriptionForm} validateOnMount>
                <>{existingCompany && <Box>*This is Subscription already exists </Box>}</>
                <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      error={SubscriptionForm.touched.subType && Boolean(SubscriptionForm.errors.subType)}
                    >
                      <CustomMuiSelect
                        label={
                          <>
                            Subscription Type<span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        options={[...companyTypeList?.map((ex) => ({ label: ex })), { label: 'Not Listed' }]}
                        error={SubscriptionForm.touched.subType && Boolean(SubscriptionForm.errors.subType)}
                        helperText={SubscriptionForm.touched.subType && SubscriptionForm.errors.subType}
                        onChange={(newValue) => {
                          SubscriptionForm.setFieldValue('subType', newValue?.label || '');
                          SubscriptionForm.setFieldTouched('subType', true);
                        }}
                        value={SubscriptionForm.values.subType || ''}
                        variant="standard"
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  {/* Not Listed Company type */}
                  {SubscriptionForm?.values?.subType === 'Not Listed' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth error={newCompanyTypeError !== undefined}>
                        <Field
                          as={TextField}
                          label={
                            <>
                              New Company Type<span style={{ color: 'red' }}>*</span>
                            </>
                          }
                          variant="standard"
                          size="small"
                          value={companyTypeNew}
                          onChange={(e) => setCompanyTypeNew(e.target.value)}
                          error={newCompanyTypeError !== undefined}
                          helperText={newCompanyTypeError !== undefined ? 'This Company type already exits.' : ''}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      error={SubscriptionForm.touched.companyName && Boolean(SubscriptionForm.errors.companyName)}
                    >
                      <Field
                        as={TextField}
                        id="input1"
                        name="companyName"
                        label={
                          <Typography sx={{ color: '#B6B6B6' }}>
                            Company Name<span style={{ color: 'red' }}>*</span>
                          </Typography>
                        }
                        variant="standard"
                        value={SubscriptionForm.values.companyName}
                        onChange={(e) => {
                          SubscriptionForm.handleChange(e);
                          debouncedComapnySearch(SubscriptionForm.values.subType, e.target.value);
                        }}
                        error={SubscriptionForm.touched.companyName && Boolean(SubscriptionForm.errors.companyName)}
                        helperText={SubscriptionForm.touched.companyName && SubscriptionForm.errors.companyName}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      error={SubscriptionForm.touched.description && Boolean(SubscriptionForm.errors.description)}
                    >
                      <Field
                        as={TextField}
                        id="input1"
                        name="description"
                        label={
                          <Typography sx={{ color: '#B6B6B6' }}>
                            Description<span style={{ color: 'red' }}>*</span>
                          </Typography>
                        }
                        variant="standard"
                        value={SubscriptionForm.values.description}
                        onChange={SubscriptionForm.handleChange}
                        error={SubscriptionForm.touched.description && Boolean(SubscriptionForm.errors.description)}
                        helperText={SubscriptionForm.touched.description && SubscriptionForm.errors.description}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      error={SubscriptionForm.touched.price && Boolean(SubscriptionForm.errors.price)}
                    >
                      <InputLabel htmlFor="price" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                        <Typography sx={{ color: '#B6B6B6' }}>
                          Amount<span style={{ color: 'red' }}>*</span>
                        </Typography>
                      </InputLabel>
                      <Field
                        as={Input}
                        id="input1"
                        name="price"
                        type={'number'}
                        variant="standard"
                        startAdornment={
                          <InputAdornment position="end">
                            {user?.country === 'United States' ? '$' : '₹'}{' '}
                          </InputAdornment>
                        }
                        value={SubscriptionForm.values.price}
                        onChange={(e) => {
                          if (e.target.value.length <= 10) {
                            SubscriptionForm.handleChange(e);
                          }
                        }}

                        // onChange={SubscriptionForm.handleChange}
                      />
                      {SubscriptionForm.touched.price && SubscriptionForm.errors.price ? (
                        <FormHelperText>
                          {SubscriptionForm.touched.price && SubscriptionForm.errors.price}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      error={SubscriptionForm.touched.website && Boolean(SubscriptionForm.errors.website)}
                    >
                      <Field
                        as={TextField}
                        id="input1"
                        name="website"
                        label={
                          <Typography sx={{ color: '#B6B6B6' }}>
                            Website<span style={{ color: 'red' }}>*</span>
                          </Typography>
                        }
                        variant="standard"
                        value={SubscriptionForm.values.website}
                        onChange={SubscriptionForm.handleChange}
                        error={SubscriptionForm.touched.website && Boolean(SubscriptionForm.errors.website)}
                        helperText={SubscriptionForm.touched.website && SubscriptionForm.errors.website}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                    <FormControl
                      fullWidth
                      error={SubscriptionForm.touched.logo && Boolean(SubscriptionForm.errors.logo)}
                    >
                      <input
                        id="contained-button-file2"
                        style={{ display: 'none' }}
                        type="file"
                        name="logo"
                        onChange={handleFileEvent}
                      />
                      <FormLabel
                        htmlFor="contained-button-file2"
                        sx={{ display: 'flex', alignItems: 'center', height: 30, width: 157 }}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          onBlur={() => {
                            SubscriptionForm.setFieldTouched('logo', true);
                          }}
                          sx={{
                            fontWeight: 700,
                            textTransform: 'none',
                            bgcolor: '#fff',
                            color: '#1D1D1F',
                            borderRadius: '30px',
                            boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                            ':hover': { bgcolor: 'transparent' },
                          }}
                        >
                          <Iconify icon="entypo:attachment" color="#3D71FF" width={20} height={20} mr={1} />
                          logo
                          {/* <span style={{ color: 'red' }}>*</span> */}
                        </Button>
                      </FormLabel>
                      {SubscriptionForm.touched.logo ? (
                        <FormHelperText>{SubscriptionForm.touched.logo && SubscriptionForm.errors.logo}</FormHelperText>
                      ) : null}
                    </FormControl>
                    {logoUploadFile.length > 0 && (
                      <Box
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      >
                        {logoUploadFile.map((val, i) => (
                          <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center', gap: '8px' }} key={i}>
                            <Image
                              preview={false}
                              src={URL.createObjectURL(val)}
                              style={{ maxHeight: '40px', objectFit: 'cover', borderRadius: '8px' }}
                              alt="logo"
                            />
                            {val?.name}
                            <IconButton
                              size="small"
                              // variant=""
                              color="error"
                              onClick={() => {
                                const newState = logoUploadFile.filter((arr) => val.name !== arr.name);
                                setLogoUploadFile(newState);
                              }}
                              sx={{ mr: 1 }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        ))}
                      </Box>
                    )}
                  </Grid>
                </Grid>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={SubscriptionForm.submitForm}
                  disabled={!(SubscriptionForm.isValid && SubscriptionForm.dirty) || existingCompany}
                  sx={{
                    width: '170px',
                    height: '45px',
                    backgroundColor: '#3D71FF',
                    borderRadius: '30px',
                    mt: '25px',
                    mb: '20px',
                    marginLeft: 'auto',
                  }}
                >
                  Create
                </Button>
              </FormikProvider>
            </Scrollbar>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default OtherModal;
