import { Typography } from '@mui/material';
import './InitialSubscriptionFeedback.css';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

import { getInitialFeedback } from '../../../services/Service';
import TableView from './TableView';

const calculateStats = (arr) => ({
  min: Math.min(...arr).toFixed(1),
  avg: (arr.reduce((acc, val) => acc + val, 0) / arr.length).toFixed(1),
  max: Math.max(...arr).toFixed(1),
});

const InitialSubscriptionFeedback = () => {
  const [rawData, setRawData] = useState([]);
  const [infoState, setInfoState] = useState({
    overallSatisfaction: { min: 0, avg: 0, max: 0 },
    aiChatSubscription: { min: 0, avg: 0, max: 0 },
    overallExperience: { min: 0, avg: 0, max: 0 },
    moneySavingFeatures: { min: 0, avg: 0, max: 0 },
    easeOfUse: { min: 0, avg: 0, max: 0 },
  });
  const fetchData = async () => {
    try {
      const res = await getInitialFeedback();
      if (res.status === 200) {
        setRawData(res.data.data);

        const attributes = {
          easeOfUse: res.data.data.map((obj) => obj.easeOfUse),
          moneySavingFeatures: res.data.data.map((obj) => obj.moneySavingFeatures),
          overallExperience: res.data.data.map((obj) => obj.overallExperience),
          aiChatSubscription: res.data.data.map((obj) => obj.aiChatSubscription),
          overallSatisfaction: res.data.data.map((obj) => obj.overallSatisfaction),
        };
        const result = {};

        Object.keys(attributes).forEach((key) => {
          result[key] = calculateStats(attributes[key]);
        });
        setInfoState(result);
      }
    } catch (err) {
      console.log('getInitialFeedback fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Initial Feedback - SafalSubscriptions</title>
      </Helmet>

      <div id="InitialSubscriptionFeedback">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Initial Feedback
            </Typography>
          </div>
        </div>
        <div className="data-info-x">
          {/* Ease Of Use */}
          <div className="data-info-x-c1">
            <div className="data-info-x-c1-head">
              <span>Ease Of Use</span>
            </div>
            <div className="data-info-x-d" />
            <div className="data-info-x-c1-content">
              <div className="data-info-x-c2">
                <span>Min</span>
                <span>{infoState.easeOfUse.min}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Avg</span>
                <span>{infoState.easeOfUse.avg}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Max</span>
                <span>{infoState.easeOfUse.max}</span>
              </div>
            </div>
          </div>
          {/* Money Saving Features */}
          <div className="data-info-x-c1 r">
            <div className="data-info-x-c1-head">
              <span>Money Saving Features</span>
            </div>
            <div className="data-info-x-d" />
            <div className="data-info-x-c1-content">
              <div className="data-info-x-c2">
                <span>Min</span>
                <span>{infoState.moneySavingFeatures.min}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Avg</span>
                <span>{infoState.moneySavingFeatures.avg}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Max</span>
                <span>{infoState.moneySavingFeatures.max}</span>
              </div>
            </div>
          </div>
          {/* Overall Experience */}
          <div className="data-info-x-c1">
            <div className="data-info-x-c1-head">
              <span>Overall Experience</span>
            </div>
            <div className="data-info-x-d" />
            <div className="data-info-x-c1-content">
              <div className="data-info-x-c2">
                <span>Min</span>
                <span>{infoState.overallExperience.min}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Avg</span>
                <span>{infoState.overallExperience.avg}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Max</span>
                <span>{infoState.overallExperience.max}</span>
              </div>
            </div>
          </div>
          {/* AI Chat Subscription */}
          <div className="data-info-x-c1 r">
            <div className="data-info-x-c1-head">
              <span>AI Chat Subscription</span>
            </div>
            <div className="data-info-x-d" />
            <div className="data-info-x-c1-content">
              <div className="data-info-x-c2">
                <span>Min</span>
                <span>{infoState.aiChatSubscription.min}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Avg</span>
                <span>{infoState.aiChatSubscription.avg}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Max</span>
                <span>{infoState.aiChatSubscription.max}</span>
              </div>
            </div>
          </div>
          {/* Overall Satisfaction */}
          <div className="data-info-x-c1">
            <div className="data-info-x-c1-head">
              <span>Overall Satisfaction</span>
            </div>
            <div className="data-info-x-d" />
            <div className="data-info-x-c1-content">
              <div className="data-info-x-c2">
                <span>Min</span>
                <span>{infoState.overallSatisfaction.min}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Avg</span>
                <span>{infoState.overallSatisfaction.avg}</span>
              </div>
              <div className="data-info-x-c2">
                <span>Max</span>
                <span>{infoState.overallSatisfaction.max}</span>
              </div>
            </div>
          </div>
        </div>
        <TableView data={rawData} />
      </div>
    </>
  );
};

export default InitialSubscriptionFeedback;
