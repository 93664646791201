import * as React from 'react';

import Box from '@mui/material/Box';
import { Image } from 'antd';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import { updateAdvertisementListFromVendor } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  py: 2,
  px: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function AdvertisementListModal({ open, onClose, data }) {
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState('');
  const { formatDate } = useUserTimeZone();
  const handleClose = (flag) => {
    onClose(flag);
  };

  // adId
  const onSubmit = async () => {
    const result = await Swal.fire({
      text: 'Are you sure you want to update this?',
      showCancelButton: true,
      confirmButtonColor: '#19c25f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      appendTo: 'body',
      customClass: {
        container: 'my-swal', // Add a custom class for styling
      },
      onBeforeOpen: () => {
        // Set a higher zIndex for the Swal modal
        document.querySelector('.my-swal').style.zIndex = 999999;
      },
    });
    if (!result.isConfirmed) {
      return;
    }

    setIsDisableAll(true);
    try {
      const res = await updateAdvertisementListFromVendor({
        id: data?.id,
        status: newStatus,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open && data) {
      setNewStatus(data?.status);
    }
  }, [open, data]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '8px', fontSize: '28px', fontWeight: 700, color: '#19c25f' }}
          >
            Advertisement View
          </Typography>
          <div style={{ display: 'flex', gap: '8px', maxHeight: '24px', alignItems: 'center' }}>
            <p style={{ fontWeight: 'bold' }}>Status</p>
            <select disabled={isDisableAll} value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
              <option value="PUBLISHED">PUBLISHED</option>
              <option value="INACIVE">INACIVE</option>
            </select>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '8px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                Advertisement Information
              </span>
              <KeyValueBox title={'Advertisement Id'} value={data?.adId} />
              <KeyValueBox title={'Advertisement Name'} value={data?.adName} alt />
              <KeyValueBox title={'Advertisement Page'} value={data?.adPage} />
              <KeyValueBox title={'Page Web Section'} value={data?.adPageWebSection?.name} alt />
              <KeyValueBox title={'Page Mobile Section'} value={data?.adPageMobileSection?.name} />
              <KeyValueBox title={'Redirect URL'} value={data?.redirectUrl} valueStyle={{ fontSize: '12px' }} alt />
              <KeyValueBox title={'Content Text'} value={data?.adContentText} />
              <KeyValueBox title={'Description'} value={data?.description} alt />
              {/* <KeyValueBox
                title={'Status'}
                value={
                  <select disabled={isDisableAll} value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
                    <option value="PUBLISHED">PUBLISHED</option>
                    <option value="INACIVE">INACIVE</option>
                  </select>
                }
              /> */}
              <KeyValueBox title={'Type'} value={data?.adType} alt />
              <KeyValueBox title={'Budget'} value={data?.budget ? `$ ${data?.budget}` : '$ 0'} />
              <KeyValueBox title={'Amount'} value={data?.amount ? `$ ${data?.amount}` : '$ 0'} alt />
              {data?.startDate && (
                <KeyValueBox
                  title={'Start Date'}
                  value={`${formatDate(data?.startDate)} ${getTimeZoneAbbreviation()}`}
                />
              )}
              {data?.endDate && (
                <KeyValueBox
                  title={'End Date'}
                  value={`${formatDate(data?.endDate)} ${getTimeZoneAbbreviation()}`}
                  alt
                />
              )}
              <KeyValueBox title={'Device Type'} value={data?.deviceType?.join(', ')} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                Geographical Restriction
              </span>
              <KeyValueBox title={'Country'} value={data?.geographicalRestriction?.country} />
              <KeyValueBox title={'State'} value={data?.geographicalRestriction?.state?.join(', ')} alt />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                Classification
              </span>
              <KeyValueBox title={'Gender'} value={data?.genderClassification} />
              <KeyValueBox title={'Age'} value={`${data?.ageFrom}y to ${data?.ageTo}y`} alt />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', flex: 1 }}>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                Business Info
              </span>
              <KeyValueBox title={'Name'} value={data?.businessInfo?.name} />
              <KeyValueBox title={'Business Type'} value={data?.businessInfo?.type?.type} alt />
              <KeyValueBox title={'Email'} value={data?.businessInfo?.email} />
              <KeyValueBox title={'Phone Number'} value={data?.businessInfo?.phoneNumber} alt />
              <KeyValueBox title={'Website'} value={data?.businessInfo?.website} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                User Info (Who Created this AD)
              </span>
              <KeyValueBox
                title={'Name'}
                value={`${data?.users?.firstName} ${data?.users?.middleName} ${data?.users?.lastName}`}
              />
              <KeyValueBox title={'Phone Number'} value={data?.users?.phoneNumber} alt />
              <KeyValueBox title={'Email'} value={data?.users?.email} />
              <KeyValueBox title={'Username'} value={data?.users?.username} alt />
              <KeyValueBox title={'Country'} value={data?.users?.country} />
              <KeyValueBox title={'Business Role'} value={data?.users?.businessRole} alt />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #19c25f' }}>
              <span
                style={{
                  color: '#19c25f',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  padding: '4px 8px',
                  fontStyle: 'italic',
                }}
              >
                Advertisement Images
              </span>
              <KeyValueBox img title={'Web AD Image'} value={data?.webImageUrl} />
              <KeyValueBox img title={'Mobile AD Image'} value={data?.mobileImageUrl} alt />
            </div>
            {/* {data?.webImageUrl && <Image src={data?.webImageUrl} />} */}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '12px', justifyContent: 'end' }}>
          <Button
            disabled={isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Close
          </Button>
          {newStatus && newStatus !== data?.status && (
            <Button
              disabled={isDisableAll}
              onClick={onSubmit}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              Update
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}

const KeyValueBox = ({ title, value, alt, valueStyle, img }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <div
      style={{
        display: 'flex',
        gap: '4px',
        backgroundColor: alt ? 'white' : '#eaffea',
        padding: '4px 8px',
        justifyContent: 'space-between',
      }}
    >
      <p style={{ fontWeight: 'bold' }}>{title}</p>

      {img ? (
        <div>
          <button
            style={{
              backgroundColor: '#19c25f',
              padding: '4px 8px',
              color: 'white',
              borderRadius: '4px',
              border: 'none',
              outline: 'none',
            }}
            onClick={() => setVisible(true)}
          >
            Open
          </button>
          <Image
            hidden
            width={10}
            style={{
              display: 'none',
            }}
            src={value}
            preview={{
              zIndex: 1000000,
              visible,
              onVisibleChange: (value) => {
                setVisible(value);
              },
            }}
          />
        </div>
      ) : (
        <p style={{ textAlign: 'right', ...valueStyle }}>{value}</p>
      )}
    </div>
  );
};
