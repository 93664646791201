/* eslint-disable react/jsx-boolean-value */
import ReactECharts from 'echarts-for-react';
import { useState, useEffect } from 'react';

import moment from 'moment';
import { getPlanedMRRActualMRR } from '../../../../services/Service';
import { formatNumberToShort } from '../../../../utils/general';

// const colors = ['#468b47','#90ee90', '#edf9eb'];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const PlanedActualMRRStackBarChartCard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const res = await getPlanedMRRActualMRR(filter);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const startDate = moment().startOf('year').toISOString();
    const endDate = moment().endOf('year').toISOString();

    fetchData({ endDate, startDate });
  }, []);

  const chartOption = {
    title: {
      text: 'Planed MRR vs Actual MRR',
      left: 'left',
      textStyle: {
        color: '#468b47',
        lineHeight: 16,
      },
    },
    tooltip: {
      trigger: 'item',
    },

    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data:
          data?.length > 0
            ? data?.map((e) => e.month)
            : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        axisLabel: {
          color: '#468b47', // Green color for x-axis labels
          fontWeight: 'bold',
        },
        axisLine: {
          show: false, // Remove x-axis line
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          color: '#468b47', // Green color for x-axis labels
        },
      },
    ],
    series: [
      {
        name: 'Planed MRR',
        type: 'bar',
        // label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data: data?.length > 0 ? data?.map((e) => e.mrr) : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: '#468b47',
          borderColor: '#468b47',
          borderWidth: 1,
          borderRadius: 4,
        },
      },
      {
        name: 'Actual MRR',
        type: 'bar',
        barGap: 0,
        // label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data: data?.length > 0 ? data?.map((e) => e.mrrActual) : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        itemStyle: {
          color: '#90ee90',
          borderColor: '#468b47',
          borderWidth: 1,
          borderRadius: 4,
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      {/* <select style={{ position: 'absolute', right: '12px', top: '12px' }}>
        <option value="option 1">option 1</option>
        <option value="option 2">option 2</option>
        <option value="option 3">option 3</option>
      </select> */}
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        option={chartOption}
        showLoading={loading}
        style={{ height: '400px', minHeight: '300px' }}
      />
      <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <LegendItem color="#468b47" text="Planed MRR" />
        <LegendItem color="#90ee90" text="Actual MRR" />
      </div>
    </div>
  );
};
export default PlanedActualMRRStackBarChartCard;

const LegendItem = ({ color, text }) => {
  return (
    <div style={{ display: 'flex', gap: '6px', alignItems: 'center', flex: 'none' }}>
      <div style={{ backgroundColor: color, borderRadius: '9999px', height: '18px', width: '18px' }} />
      <span style={{ fontSize: '14px', marginTop: '2px' }}>{text}</span>
    </div>
  );
};
