import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Image } from 'antd';

import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import CustomRating from '../../components/UI/CustomRating';
import CustomMuiSelect from '../../components/UI/CustomMuiSelect';
import DateField from '../../components/UI/DateField';
import { getHelpVideoModule } from '../../services/Service';

const UserAssessmentReportFilterMenu = ({ state, onFilterApply }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [filterState, setFilterState] = useState({
    type: '',
    fromSubmitDate: '',
    toSubmitDate: '',
    minPoints: '',
    maxPoints: '',
    status: '',
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
  };

  useEffect(() => {
    if (open) {
      setFilterState(state);
    }
  }, [open]);

  const handleFilterApply = () => {
    onFilterApply(filterState);
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterApply({
      type: '',
      fromSubmitDate: '',
      toSubmitDate: '',
      minPoints: '',
      maxPoints: '',
      status: '',
    });
    handleClose();
  };

  const isThisResetState =
    state?.type !== '' ||
    state?.fromSubmitDate !== '' ||
    state?.toSubmitDate !== '' ||
    state.status !== '' ||
    state.minPoints !== '' ||
    state.maxPoints !== '';

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '400px',
            padding: '0px 8px',
          },
        }}
      >
        <h3>Filter</h3>
        <TextField
          value={filterState?.type}
          focused={filterState?.type}
          onChange={(e) => onChangeField(e.target.value, 'type')}
          sx={{
            my: '8px',
          }}
          name="type"
          select
          label="Assessment type"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem value="MonthlyFeedback">Monthly Feedback</MenuItem>
          <MenuItem value="UpgradePlan">Upgrade Plan</MenuItem>
          <MenuItem value="DowngradePlan">Downgrade Plan</MenuItem>
          <MenuItem value="DeleteAccount">Delete Account</MenuItem>
        </TextField>
        {/* status */}
        <TextField
          value={filterState?.status}
          focused={filterState?.status}
          onChange={(e) => onChangeField(e.target.value, 'status')}
          sx={{
            my: '8px',
          }}
          name="status"
          select
          label="Status"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem value="Draft">Draft</MenuItem>
          <MenuItem value="Completed">Completed</MenuItem>
          <MenuItem value="Expired">Expired</MenuItem>
        </TextField>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '8px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Submission date
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <DateField
              value={filterState.fromSubmitDate}
              fullWidth
              variant="outlined"
              label="From"
              onChange={(e) => onChangeField(e.toISOString(), 'fromSubmitDate')}
            />
            <DateField
              value={filterState.toSubmitDate}
              fullWidth
              variant="outlined"
              label="To"
              onChange={(e) => onChangeField(e.toISOString(), 'toSubmitDate')}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '8px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            SafalPoints
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            {/* minPoints */}
            <TextField
              value={filterState?.minPoints}
              focused={filterState?.minPoints}
              onChange={(e) => onChangeField(e.target.value, 'minPoints')}
              name="minPoints"
              label="Min"
              size="small"
              type="number"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start" />,
              }}
            />
            {/* maxPoints */}
            <TextField
              value={filterState?.maxPoints}
              focused={filterState?.maxPoints}
              onChange={(e) => onChangeField(e.target.value, 'maxPoints')}
              name="maxPoints"
              label="Max"
              size="small"
              type="number"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start" />,
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default UserAssessmentReportFilterMenu;
