import { useEffect, useState } from 'react';
import { Radio } from '@mui/material';
import toast from 'react-hot-toast';
import moment from 'moment';

import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';
import { getSafalRoomPullUser, updateSafalRoomPullUser } from '../../../services/Service';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const SafalRoomPolls = ({ companyId }) => {
  const [loading, setLoading] = useState(false);
  const [pollList, setPollList] = useState([]);
  const [poll, setPoll] = useState(null);
  const [selectedPollValue, setSelectedPollValue] = useState('');
  const { timeFromNow2 } = useUserTimeZone();

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getSafalRoomPullUser({ company: companyId });
      if (res.status === 200) {
        setPollList(res.data.data);
        if (res?.data?.data?.length > 0) {
          setPoll(res?.data?.data[0]);
          const youVote = res?.data?.data[0]?.options?.find((ex) => ex?.youVote === true);
          if (youVote) {
            setSelectedPollValue(youVote?.option);
          } else {
            setSelectedPollValue('');
          }
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };
  const updateData = async (index) => {
    try {
      setLoading(true);
      const res = await updateSafalRoomPullUser({ _id: poll?._id, optionsIndex: index });
      if (res.status === 200) {
        setPollList((prev) => {
          const newPrev = [...prev];
          const findIndex = newPrev.findIndex((e) => e?._id === poll?._id);
          if (findIndex !== -1) {
            newPrev[findIndex] = res.data.data;
          }
          return newPrev;
        });
        setPoll(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePollChange = (value, index) => {
    updateData(index);
    setSelectedPollValue(value);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '16px',
        gap: '8px',
        overflow: 'auto',
        height: '100%',
      }}
    >
      {pollList.length > 0 && (
        <CustomSimpleSelect
          valueKey="_id"
          value={poll?._id || ''}
          onChange={(e) => {
            if (e) {
              const findPoll = pollList?.find((ex) => ex?._id === e);
              setPoll(findPoll);
              const youVote = findPoll?.options?.find((ex) => ex?.youVote === true);
              if (youVote) {
                setSelectedPollValue(youVote?.option);
              } else {
                setSelectedPollValue('');
              }
            }
          }}
          label="Search | Select Polls"
          options={pollList?.map((e) => ({ label: e?.name, ...e }))}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', lineHeight: 'normal', fontSize: '16px' }}>
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{poll?.question}</span>
        {/* <p style={{ fontSize: '14px' }}>{poll?.question}</p> */}
      </div>
      {loading && <div className="unique-loader-line" />}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {poll?.options?.map((e, index) => {
          const youVote = poll?.options?.find((ex) => ex?.youVote === true);
          const expiry = poll?.expiry ? moment(poll?.expiry).isBefore(moment(), 'day') : false;

          return (
            <PollBar
              onChange={(e) => handlePollChange(e, index)}
              value={selectedPollValue}
              name={poll?._id}
              data={e}
              key={index}
              youVote={expiry}
              show={youVote}
              // youVote={expiry ? true : youVote !== undefined}
            />
          );
        })}
      </div>
      {poll?.expiry && (
        <span style={{ marginLeft: 'auto', fontStyle: 'italic' }}>expiry {timeFromNow2(poll?.expiry)}</span>
      )}
      {pollList.length === 0 && (
        <div style={{ display: 'flex', height: '300px', alignItems: 'center', justifyContent: 'center' }}>
          <span>No polls found.</span>
        </div>
      )}
    </div>
  );
};
export default SafalRoomPolls;

const PollBar = ({ data, value, youVote, name, onChange, show }) => {
  return (
    <label
      htmlFor={data?.option}
      style={{
        border: '2px solid #d9e3ff',
        backgroundColor: 'transparent',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        padding: '2px 12px',
        fontSize: '16px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {show && (
        <div
          style={{
            backgroundColor: '#d9e3ff',
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: `${data?.percentage}%`,
          }}
        />
      )}
      <Radio
        disabled={youVote}
        id={data?.option}
        checked={value === data?.option}
        onChange={(e) => onChange(e.target.value)}
        value={data?.option}
        size="small"
        name={name}
      />
      <span style={{ flex: 1, zIndex: 1 }}>{data?.option}</span>
      {show && (
        <span style={{ flex: 'none', zIndex: 1 }}>
          {data?.percentage}% {data?.count !== 0 ? `| ${data?.count}` : ''}
        </span>
      )}
    </label>
  );
};
