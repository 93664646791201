import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Switch } from '@mui/material';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete, onDisplay, disabled, updateValue, updateNotificationValue }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, newSortData, sortState } = useSortHook(data, ['createdAt']);

  const headData = [
    'Activity Name',
    'Share To My Buddy',
    'Receive Buddies Activity',
    'Email',
    'Text',
    'Mobile App',
    'Notification Screen',
    'Notification Banner',
    'Actions',
  ];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: handleSort,
          sortHeadMap: [
            'activityName',
            'shareToMyBuddy',
            'receiveBuddiesActivity',
            'notification.email',
            'notification.text',
            'notification.mobileApp',
            'notification.notification',
            'notification.banner',
            '',
          ],
        }}
        headData={headData}
      >
        {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {newSortData?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.activityName}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'shareToMyBuddy'}
                onChange={() => updateValue(e, 'shareToMyBuddy', !e?.shareToMyBuddy)}
                checked={e?.shareToMyBuddy}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'receiveBuddiesActivity'}
                onChange={() => updateValue(e, 'receiveBuddiesActivity', !e?.receiveBuddiesActivity)}
                checked={e?.receiveBuddiesActivity}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'email'}
                onChange={() => updateNotificationValue(e, 'email', !e?.notification.email)}
                checked={e?.notification.email}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'text'}
                onChange={() => updateNotificationValue(e, 'text', !e?.notification.text)}
                checked={e?.notification.text}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'mobileApp'}
                onChange={() => updateNotificationValue(e, 'mobileApp', !e?.notification.mobileApp)}
                checked={e?.notification.mobileApp}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'notification'}
                onChange={() => updateNotificationValue(e, 'notification', !e?.notification.notification)}
                checked={e?.notification.notification}
                color="success"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <Switch
                disabled={disabled}
                name={'banner'}
                onChange={() => updateNotificationValue(e, 'banner', !e?.notification.banner)}
                checked={e?.notification.banner}
                color="success"
              />
            </CustomTableCellForBody>
            {/* <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{formatDateTime(e?.createdAt)}</p>
            </CustomTableCellForBody> */}
            <CustomTableCellForBody align="center">
              <ThreeDotMenu onDelete={() => onDelete(e)} onDisplay={() => onDisplay(e)} onEdit={() => onEdit(e)} />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
