/* eslint-disable react/jsx-boolean-value */
import { useEffect, useState } from 'react';
import moment from 'moment';
import ReactECharts from 'echarts-for-react';
import { getSalesRevenuePerPlan } from '../../../../services/Service';
import { formatNumberToShort } from '../../../../utils/general';

// const colors = ['#468b47','#90ee90', '#edf9eb',"#385239"];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const colors = ['#468b47', '#90ee90', '#edf9eb', '#385239'];
const SalesRevenuePieCard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState('CurrentMonth'); // CurrentMonth, LastMonth, CurrentYear, LastYear

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const res = await getSalesRevenuePerPlan(filter);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let startDate = '';
    let endDate = '';

    switch (mode) {
      case 'CurrentMonth':
        startDate = moment().startOf('month').unix();
        endDate = moment().endOf('month').unix();
        break;
      case 'LastMonth':
        startDate = moment().subtract(1, 'month').startOf('month').unix();
        endDate = moment().subtract(1, 'month').endOf('month').unix();
        break;
      case 'CurrentYear':
        startDate = moment().startOf('year').unix();
        endDate = moment().endOf('year').unix();
        break;
      case 'LastYear':
        startDate = moment().subtract(1, 'year').startOf('year').unix();
        endDate = moment().subtract(1, 'year').endOf('year').unix();
        break;

      default:
        break;
    }

    fetchData({ endDate, startDate });
  }, [mode]);

  const totalAmount = data?.reduce((acc, item) => acc + item.revenue, 0);
  const totalText = totalAmount ? `$ ${formatNumberToShort(totalAmount)}` : '$ 0';

  // formatNumberToShort

  const chartOption = {
    title: {
      text: totalText,
      subtext: 'Gross Revenue',
      left: 'left', // Align title to the left
      textStyle: {
        color: '#468b47', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
      subtextStyle: {
        color: '#468b47', // Green color for the subtitle
        lineHeight: 2, // Reduced line height for subtitle
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: $ {c} ({d}%)', // Tooltip shows name, value, and percentage
    },
    series: [
      {
        name: 'Revenue',
        type: 'pie',
        radius: '50%',
        data: data?.map((e) => ({
          value: e.revenue,
          name: e.name,
        })),
        itemStyle: {
          color: (params) => {
            return colors[params.dataIndex % colors.length]; // Apply custom colors
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <select
        value={mode}
        style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 1 }}
        onChange={(e) => setMode(e.target.value)}
      >
        <option value="CurrentMonth">Current Month</option>
        <option value="LastMonth">Last Month</option>
        <option value="CurrentYear">Current Year</option>
        <option value="LastYear">Last Year</option>
      </select>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        option={chartOption}
        showLoading={loading}
        style={{ height: '220px', minHeight: '200px' }}
      />
      <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'max-content',
        }}
      >
        {data?.map((e, index) => (
          <LegendItem color={colors[index]} text={e?.name} />
        ))}
      </div>
    </div>
  );
};
export default SalesRevenuePieCard;

const LegendItem = ({ color, text }) => {
  return (
    <div style={{ display: 'flex', gap: '6px', alignItems: 'center', flex: 'none' }}>
      <div style={{ backgroundColor: color, borderRadius: '9999px', height: '18px', width: '18px' }} />
      <span style={{ fontSize: '14px', marginTop: '2px' }}>{text}</span>
    </div>
  );
};
