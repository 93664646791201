import { useEffect, useRef, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import CloseIcon from '@mui/icons-material/Close';

import Attachment from '../../components/Attachments';

const DashboardNotificationsCarousel = ({ data, onDelete, childref }) => {
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showStatus={false}
      showArrows={false}
      onChange={(i) => localStorage.setItem('banner', i)}
      showIndicators
      showThumbs
      axis="horizontal"
      //   renderIndicator={(clickHandler, isSelected, index) => (
      //     <RenderIndicator clickHandler={clickHandler} index={index} isSelected={isSelected} />
      //   )}
    >
      {data.map((d, index) => (
        <div
          style={{
            backgroundColor: 'white',
            height: '100px',
            borderRadius: '16px',
            border: '1px solid #bcceff',
            display: 'flex',
            lineHeight: 'normal',
          }}
          key={index}
        >
          {d.logo && (
            <div style={{ flex: 'none', display: 'flex', alignItems: 'center', paddingLeft: '16px' }}>
              <img
                style={{ height: '70px', borderRadius: '12px', width: '70px', objectFit: 'cover' }}
                src={d.logo}
                alt="Company Logo"
              />
            </div>
          )}
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                lineHeight: 'normal',
                padding: '0px 16px',
              }}
            >
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{d.title}</span>
              <IconButton onClick={() => onDelete(index)} size="small">
                <CloseIcon />
              </IconButton>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                lineHeight: 'normal',
                padding: '0px 16px',
              }}
            >
              <p style={{ fontSize: '14px', textAlign: 'start', maxWidth: '80%' }}>{d.msg}</p>
              {d?.attachments?.length > 0 && (
                <AttachmentHOC childref={childref} data={d?.attachments?.map((attach) => ({ url: attach }))} />
              )}
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default DashboardNotificationsCarousel;

const AttachmentHOC = (props) => {
  const attachmentRef = useRef(null);
  const [openDownload, setOpenDownload] = useState(false);

  const handleClickOutside = (event) => {
    if (attachmentRef.current && !attachmentRef.current.contains(event.target)) {
      handleCloseDownload();
    }
  };
  useEffect(() => {
    props.childref.current = handleCloseDownload;
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickDownload = (event) => {
    setOpenDownload(event?.currentTarget);
  };

  const handleCloseDownload = () => {
    setOpenDownload(null);
  };
  return (
    <div ref={attachmentRef}>
      <Button onClick={(e) => handleClickDownload(e)}>
        <Attachment
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          handleCloseDownload={handleCloseDownload}
          {...props}
        />
      </Button>
    </div>
  );
};

const RenderIndicator = ({ clickHandler, isSelected, index }) => {
  console.log('RenderIndicator index', index);

  return (
    <div
      style={{ backgroundColor: 'red', width: '10px', height: '10px', position: 'absolute', top: '50%', left: '50%' }}
    />
  );
};
