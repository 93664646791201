import { createSlice } from '@reduxjs/toolkit';
import YourImage from '../assets/UserAvtarBackground.png';

const initialState = {
  userProfileImage: YourImage,
};

export const profileImageSlice = createSlice({
  name: 'profileImageSlice',
  initialState,
  reducers: {
    storeUserProfileImage: (state, action) => {
      state = {
        ...state,
        storeUserProfileImage: action.payload,
      };
      return state;
    },
  },
});

export const { storeUserProfileImage } = profileImageSlice.actions;

export default profileImageSlice.reducer;
