import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Image } from 'antd';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onSort, onDelete, onDisplay }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, sortState } = useSortHook(data, ['createdAt']);

  const headData = ['Image', 'Name', 'website', 'description', 'Status', 'Created Date', 'Actions'];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: (ex) => handleSort(ex, onSort),
          sortHeadMap: ['', 'name', 'website', 'description', 'status', 'createdAt', ''],
        }}
        headData={headData}
      >
        {data?.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {data?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <Image
                preview={e?.image !== ''}
                src={e?.image}
                style={{ maxHeight: '80px', objectFit: 'cover', borderRadius: '20px', border: '1px solid #027c34' }}
                alt="background"
                fallback="/assets/images/no_image.png"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.name}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.website}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.description}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.status}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              {e?.name !== 'Others' && (
                <ThreeDotMenu onEdit={() => onEdit(e)} onDelete={() => onDelete(e)} onDisplay={() => onDisplay(e)} />
              )}
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
