import styled from 'styled-components';
import { Container, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import NewUserIdForm from '../../sections/auth/forgetUserId/NewUserIdForm';

const ContentStyle = styled('div')(() => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  //   padding: theme.spacing(12, 0, 0, 0),
}));

const VerifyUserIdToken = () => {
  const { token } = useParams();

  return (
    <Container maxWidth="sm">
      <ContentStyle>
        <Typography variant="h4" gutterBottom>
          Enter Your New UserId
        </Typography>
        <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>
        <NewUserIdForm token={token} />
      </ContentStyle>
    </Container>
  );
};

export default VerifyUserIdToken;
