import { Mention, MentionsInput } from 'react-mentions';

const CustomMentionsInput = ({ disabled, maxLength, value, placeholder, onChange, children }) => {
  const styleX = {
    control: {
      backgroundColor: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
    },

    '&multiLine': {
      control: {
        fontFamily: 'monospace',
        minHeight: 63,
      },
      highlighter: {
        border: '1px solid transparent',
        boxSizing: 'border-box',
        overflow: 'hidden',
        height: 150,
        padding: '8px 16px',
        lineHeight: 'normal',
      },
      input: {
        border: '1px solid silver',
        overflow: 'auto',
        height: 150,
        padding: '8px 16px',
        borderRadius: '8px',
        lineHeight: 'normal',
      },
    },

    '&singleLine': {
      display: 'inline-block',
      width: 150,
      highlighter: {
        // padding: 1,
        border: '2px inset transparent',
        boxSizing: 'border-box',
        overflow: 'hidden',
        height: 150,
        padding: '8px 16px',
        lineHeight: 'normal',
      },
      input: {
        // padding: 1,
        border: '2px inset',
        overflow: 'auto',
        height: 150,
        padding: '8px 16px',
        borderRadius: '8px',
        lineHeight: 'normal',
      },
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
        maxHeight: '100px',
        overflow: 'auto',
        scrollbarWidth: 'none',
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  };
  return (
    <MentionsInput
      maxLength={maxLength}
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      style={styleX}
    >
      {children}
    </MentionsInput>
  );
};

export default CustomMentionsInput;
