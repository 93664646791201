/* eslint-disable */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, updateDoc, query, where } from 'firebase/firestore';
import UserStatus from './UserStatus';
import NotificationCount from './NotificationCount';
import LastMsg from './LastMsg';
import Scrollbar from '../Scrollbar';
import { db } from '../../firebase';
import { changeUser } from '../../store/ChatSlice';
import DisplayAvatar from './DisplayAvatar';

const SupportChatSidebar = (chats) => {
  const { user, chatMode, chatId, sid } = useSelector((state) => state.chats);
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.login?.user);
  const handleSelect = async (user) => {
    try {
      let id = user.uid;
      let sid = user.uid;
      if (role === 'user') {
        id =
          localStorage.getItem('fire') > user.uid
            ? localStorage.getItem('fire') + user.uid
            : user.uid + localStorage.getItem('fire');
      } else {
        sid = currentUser?.uid > user.uid ? currentUser?.uid + user.uid : user.uid + currentUser?.uid;
      }
      dispatch(changeUser({ user, id, sid }));
      // reset notification count
      try {
        if (chatMode === 'user') {
          const q = query(collection(db, `alert`, currentUser?.uid, user.uid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async () => {
            await deleteDoc(doc(db, `alert`, currentUser?.uid, user.uid, user.uid));
          });
        } else {
          await updateDoc(doc(db, `alert`, user.uid), { count: 0 });
        }
      } catch (e) {
        console.log('aaaaaaa', e);
      }
      console.log('aaaaaaa');
    } catch (e) {
      console.log('aaaaaaa', e);
    }
  };

  return (
    Array.isArray(chats?.chats) &&
    chats.chats.map(
      ({ key, userInfo }) =>
        userInfo && (
          <Scrollbar onKeyDown={() => {}}>
            <div
              onClick={() => handleSelect(userInfo)}
              className={`userChat ${user?.uid === userInfo.uid && 'selected'}`}
              key={key}
            >
              <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
                <DisplayAvatar uid={userInfo?.uid} />
                <div style={{ marginLeft: '-10px' }}>
                  <UserStatus uid={userInfo?.uid} />
                </div>
              </div>
              <div className="userChatInfo">
                <p className="userChatName">{userInfo.displayName}</p>
                <LastMsg senderId={userInfo.uid} />
              </div>
              <NotificationCount senderId={userInfo.uid} />
            </div>
          </Scrollbar>
        )
    )
  );
};

export default SupportChatSidebar;
