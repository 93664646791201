import { Box, Checkbox } from '@mui/material';
import React from 'react';

const CheckBoxForUnlimited = ({ name, checked, onChange }) => {
  const handleCheckBoxChange = (event) => {
    if (event.target.checked) {
      onChange(name, 'unlimited');
    } else {
      onChange(name, 0);
    }
  };

  const nub = typeof checked === 'number';

  return (
    <>
    <Box sx={{display:"flex",alignItems:"center"}}>
    <Checkbox
        checked={!nub}
        onChange={handleCheckBoxChange}
        color="success"
        inputProps={{ 'aria-label': 'controlled' }}
      />{'Unlimited'}
    </Box>
    </>
  );
};

export default CheckBoxForUnlimited;
