import React, { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Timestamp, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import audio from '../../assets/audio.mp3';
import { socket, PeerConnection } from './Communication';
import MainWindow from './Components/MainWindow';
import CallWindow from './Components/CallWindow';
import CallModal from './Components/CallModal';
import {
  setCallModal,
  setCallFrom,
  setCallWindow,
  setLocalSrc,
  setPc,
  setConfig,
  setPeerSrc,
  setIsStartCall,
} from '../../store/callSlice';
import { db } from '../../firebase';

const Call = () => {
  const {
    isStartCall,
    isEndCall,
    isRejectCall,
    callWindow,
    callModal,
    callFrom,
    localSrc,
    peerSrc,
    pc,
    config,
    callMode,
  } = useSelector((state) => state.call);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const { user, chatMode } = useSelector((state) => state.chats);
  const { login } = useSelector((state) => state);
  const { chats } = useSelector((state) => state);
  // const [audios, setAudios] = useState(new Audio(audio));
  // const audioRing = new Audio(audio);

  useEffect(() => {
    socket
      .on('request', ({ from: callFrom }) => {
        console.log('DEBUG - request', callFrom);
        dispatch(setCallModal('active'));
        dispatch(setCallFrom(callFrom));
      })
      .on('call', (data) => {
        console.log('DEBUG - call', data);
        if (data.sdp) {
          pc.setRemoteDescription(data.sdp);
          if (data.sdp.type === 'offer') {
            pc.createAnswer();
          }
        } else pc.addIceCandidate(data.candidate);
      })
      .on('end', (result) => {
        console.log('DEBUG - end', result);
        endCall.bind(null, false);
        endCall(false, result);
      })
      .emit('init', currentUser?.uid);

    return () => {
      socket.off('request');
      socket.off('call');
      socket.off('end');
    };
  }, [pc, currentUser?.uid]);

  useEffect(() => {
    if (isStartCall) {
      console.log('DEBUG - startCalling to', 'SpnGFPit9yY88uKWskbpPbT9MeH3');
      // console.log('DEBUG - startCalling to', user.uid);
      let config = { audio: true, video: true };
      if (callMode === 'audio') {
        config = {
          ...config,
          video: false,
        };
      }
      dispatch(setConfig(config));
      startCall(true, 'SpnGFPit9yY88uKWskbpPbT9MeH3', config);
      dispatch(setIsStartCall({ isCall: false, callMode: 'video' }));
    }
  }, [isStartCall]);

  const startCall = (isCaller, friendID, config) => {
    dispatch(setConfig(config));
    console.log('DEBUG - PeerConnection', friendID, config.video, chatMode, login.user.role, currentUser?.uid);
    const peerConn = new PeerConnection(friendID, config.video, chatMode, login.user.role, currentUser?.uid)
      .on('localStream', (src) => {
        const newState = { callWindow: 'active', localSrc: src };
        if (!isCaller) newState.callModal = '';
        dispatch(setCallWindow(newState.callWindow));
        dispatch(setLocalSrc(newState.localSrc));
        dispatch(setCallModal(newState.callModal));
      })
      .on('peerStream', (src) => dispatch(setPeerSrc(src)))
      .start(isCaller);
    console.log(peerConn);
    console.log('DEBUG - peerConn', peerConn);
    dispatch(setPc(peerConn));
  };

  const rejectCall = () => {
    socket.emit('end', { to: callFrom, chatMode });
    dispatch(setCallModal(''));
  };

  const endCall = async (isStarter, result) => {
    if (_.isFunction(pc.stop)) {
      pc.stop(isStarter);
    }
    dispatch(setPc({}));
    dispatch(setConfig(null));
    dispatch(setCallWindow(''));
    dispatch(setCallModal(''));
    dispatch(setLocalSrc(null));
    dispatch(setPeerSrc(null));
    const online = await getDoc(doc(db, 'online', chats.user.uid));
    // add firebase in here
    // await updateDoc(doc(db, 'chats', chats.chatId), {
    //   messages: arrayUnion({
    //     id: uuid(),
    //     text: 'Missed call',
    //     messageType: 'missed',
    //     senderId: currentUser?.uid,
    //     date: Timestamp.now(),
    //     msgStatus: online.exists() ? 1 : 0,
    //   }),
    // });
  };

  return (
    <div>
      {!_.isEmpty(config) && <CallWindow endCall={endCall} />}
      <CallModal status={callModal} startCall={startCall} rejectCall={() => rejectCall()} callFrom={callFrom} />
    </div>
  );
};

export default Call;
