import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

import './SafalLeaderBusiness.css';

import { getSafalLeaderBusiness } from '../../../services/Service';
import TableView from './TableView';

const application = 'SafalSubscriptions';

const SafalLeaderBusiness = () => {
  const [rawData, setRawData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('United States');
  const [selectedType, setSelectedType] = useState('lifeTime'); // lifeTime, daily, weekly, monthly, yearly

  const fetchData = async (country) => {
    try {
      const res = await getSafalLeaderBusiness({
        application,
        country,
      });
      if (res.status === 200) {
        setRawData(res.data.data);
        const sortData = [...res.data.data];
        switch (selectedType) {
          case 'lifeTime':
            sortData.sort((a, b) => a.lifeTimeRank - b.lifeTimeRank);
            setFilterData(
              sortData.map((ex) => ({
                user: ex.user,
                rank: ex.lifeTimeRank,
                points: ex.lifeTimePoints,
              }))
            );
            break;
          case 'daily':
            sortData.sort((a, b) => a.dailyRank - b.dailyRank);
            setFilterData(
              sortData.map((ex) => ({
                user: ex.user,
                rank: ex.dailyRank,
                points: ex.dailyPoints,
              }))
            );
            break;
          case 'weekly':
            sortData.sort((a, b) => a.weeklyRank - b.weeklyRank);
            setFilterData(
              sortData.map((ex) => ({
                user: ex.user,
                rank: ex.weeklyRank,
                points: ex.weeklyPoints,
              }))
            );
            break;
          case 'monthly':
            sortData.sort((a, b) => a.monthlyRank - b.monthlyRank);
            setFilterData(
              sortData.map((ex) => ({
                user: ex.user,
                rank: ex.monthlyRank,
                points: ex.monthlyPoints,
              }))
            );
            break;
          case 'yearly':
            sortData.sort((a, b) => a.yearlyRank - b.yearlyRank);
            setFilterData(
              sortData.map((ex) => ({
                user: ex.user,
                rank: ex.yearlyRank,
                points: ex.yearlyPoints,
              }))
            );
            break;
          default:
            break;
        }
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData(selectedCountry);
  }, [selectedCountry]);

  const selectedTypeChange = (value) => {
    setSelectedType(value);
    const sortData = [...rawData];
    switch (value) {
      case 'lifeTime':
        sortData.sort((a, b) => a.lifeTimeRank - b.lifeTimeRank);
        setFilterData(
          sortData.map((ex) => ({
            user: ex.user,
            rank: ex.lifeTimeRank,
            points: ex.lifeTimePoints,
          }))
        );
        break;
      case 'daily':
        sortData.sort((a, b) => a.dailyRank - b.dailyRank);
        setFilterData(
          sortData.map((ex) => ({
            user: ex.user,
            rank: ex.dailyRank,
            points: ex.dailyPoints,
          }))
        );
        break;
      case 'weekly':
        sortData.sort((a, b) => a.weeklyRank - b.weeklyRank);
        setFilterData(
          sortData.map((ex) => ({
            user: ex.user,
            rank: ex.weeklyRank,
            points: ex.weeklyPoints,
          }))
        );
        break;
      case 'monthly':
        sortData.sort((a, b) => a.monthlyRank - b.monthlyRank);
        setFilterData(
          sortData.map((ex) => ({
            user: ex.user,
            rank: ex.monthlyRank,
            points: ex.monthlyPoints,
          }))
        );
        break;
      case 'yearly':
        sortData.sort((a, b) => a.yearlyRank - b.yearlyRank);
        setFilterData(
          sortData.map((ex) => ({
            user: ex.user,
            rank: ex.yearlyRank,
            points: ex.yearlyPoints,
          }))
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>SafalLeaderBeard - SafalSubscriptions</title>
      </Helmet>

      <div id="SafalLeaderBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              SafalLeaderBeard
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="SafalLeaderBusiness-selectedCountry">Country</InputLabel>
              <Select
                labelId="SafalLeaderBusiness-selectedCountry"
                value={selectedCountry}
                label="Country"
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <MenuItem value="United States">United States</MenuItem>
                <MenuItem value="India">India</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="title-action-div">
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="SafalLeaderBusiness-selectedType">Type</InputLabel>
              <Select
                labelId="SafalLeaderBusiness-selectedType"
                value={selectedType}
                label="Type"
                onChange={(e) => selectedTypeChange(e.target.value)}
              >
                <MenuItem value="lifeTime">Life Time</MenuItem>
                <MenuItem value="daily">Daily</MenuItem>
                <MenuItem value="weekly">Weekly</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="yearly">Yearly</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <TableView data={filterData} />
      </div>
    </>
  );
};

export default SafalLeaderBusiness;
