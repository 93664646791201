import { Button, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './PlanFeature.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { getPlanList, getPlanFeature } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import TableView from './TableView';
import AddPlanFeatureModal from './AddPlanFeatureModal';

const PlanFeature = () => {
  const [addModalOpen, setAddModalOpen] = useState({
    open: false,
    data: null,
  });
  const [rawData, setRawData] = useState([]);
  const [rawPlanData, setRawPlanData] = useState([]);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getPlanFeature();
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('getPriceList fetchData error', err.message);
    }
  };
  const fetchPlanData = async () => {
    try {
      const res = await getPlanList();
      if (res.status === 200) {
        setRawPlanData(res.data.data);
      }
    } catch (err) {
      console.log('getPriceList fetchData error', err.message);
    }
  };

  const onAddModalOpen = () => {
    setAddModalOpen({
      open: true,
      data: null,
    });
  };
  const onEditModalOpen = (data) => {
    setAddModalOpen({
      open: true,
      data: data,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      open: false,
      data: null,
    });
    fetchData();
  };

  // const onDelete = async (data) => {
  //   try {
  //     const res = await deleteRSSLink(data._id);
  //     if (res.status === 200) {
  //       toast.success(res.data.message);
  //       onDeleteModalReset();
  //       fetchData();
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(err?.response?.data?.message || err.message);
  //   }
  // };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
    fetchPlanData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Payment Price - SafalSubscriptions</title>
      </Helmet>

      <div id="PlanFeature">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Plan Feature List
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          rawPlanData={rawPlanData}
          onDelete={(data) => {
            setIsDeleteConfirmationModalOpen({
              open: true,
              data,
            });
          }}
          onEdit={onEditModalOpen}
          data={rawData}
        />
      </div>
      {/* <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      /> */}
      <AddPlanFeatureModal
        plans={rawPlanData}
        data={addModalOpen.data}
        open={addModalOpen.open}
        onClose={onAddModalClose}
      />
    </>
  );
};

export default PlanFeature;
