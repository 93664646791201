import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const dynamicMenuSlice = createSlice({
  name: 'dynamicMenuSlice',
  initialState,
  reducers: {
    setDynamicMenuState: (state, action) => {
      state = action.payload;
      return state;
    },
    resetDynamicMenuState: () => {
      return initialState;
    },
  },
});

export const { setDynamicMenuState, resetDynamicMenuState } = dynamicMenuSlice.actions;

export default dynamicMenuSlice.reducer;
