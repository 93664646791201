import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getAssetModuleCompany } from '../../../services/Service';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';

const AssetModuleFilterMenu = ({ state, onFilterApply, onFilterReset, type }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const [rawCompanyData, setRawCompanyData] = useState([]);
  const [rawCompanyDataFilter, setRawCompanyDataFilter] = useState([]);
  const [rawCompanyTypeData, setRawCompanyTypeData] = useState([]);
  const [dateErr, setDateErr] = useState(false);
  const [filterState, setFilterState] = useState({
    companyId: '',
    pointsMin: '',
    pointsMax: '',
    expiryTo: '',
    expiryFrom: '',
    companyType: '',
    status: '',
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
    if (fieldName === 'expiryFrom') {
      const selectedDate = data ? new Date(data) : null;
      if (selectedDate > new Date(filterState.expiryTo)) {
        console.error('Error: "From" date should not be greater than "To" date');
        return;
      }
    }

    if (fieldName === 'expiryTo') {
      const selectedDate = data ? new Date(data) : null;
      if (selectedDate < new Date(filterState.expiryFrom)) {
        setDateErr(true);
        console.error('Error: "To" date should not be smaller than "From" date');
        return;
      }
    }

    setShowApply(false);
    setDateErr(false);
  };

  const fetchCompanyData = async () => {
    try {
      const res = await getAssetModuleCompany();
      if (res.status === 200) {
        setRawCompanyData(res.data.data);
        const comType = res.data.data.map((e) => e.type);
        const uniqueType = [];
        comType.forEach((ex) => {
          if (!uniqueType.includes(ex)) uniqueType.push(ex);
        });
        setRawCompanyTypeData(uniqueType);
        if (state?.companyId && state?.companyId !== '')
          setFilterState((prev) => ({ ...prev, companyId: state?.companyId }));
      }
    } catch (err) {
      console.log('fetchCompanyData error', err.message);
    }
  };

  useEffect(() => {
    if (open) {
      fetchCompanyData();
      setFilterState(state);
    }
  }, [open]);

  const handleFilterApply = () => {
    onFilterApply(filterState);
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterReset(filterState);
    handleClose();
  };
  // const [isThisResetState, setIsThisResetState] = useState(
  //   state.companyId !== '' || state.expiryFrom !== '' || state.expiryTo !== '' || state.points !== ''
  // );

  // Update resetState whenever the state changes
  useEffect(() => {
    setShowApply(isThisResetState);
  }, [state]);

  const isThisResetState =
    state.companyId !== '' ||
    state.expiryFrom !== '' ||
    state.expiryTo !== '' ||
    state.pointsMin !== '' ||
    state.pointsMax !== '' ||
    state.companyType !== '' ||
    state.status !== '';

  // State to store the boolean value
  const [showApply, setShowApply] = useState(isThisResetState);

  useEffect(() => {
    if (filterState.companyType) {
      const filterData = rawCompanyData.filter((ex) => ex.type === filterState.companyType);
      setRawCompanyDataFilter(filterData);
      if (!state.companyId || state.companyId === '') setFilterState((prev) => ({ ...prev, companyId: '' }));
    } else {
      setRawCompanyDataFilter(rawCompanyData);
    }
  }, [filterState.companyType, rawCompanyData]);

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '320px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        <CustomMuiSelect
          disablePortal={false}
          value={filterState.companyType}
          onChange={(e) => onChangeField(e.label, 'companyType')}
          sx={{
            my: '8px',
          }}
          label="Company Type"
          size="small"
          variant="outlined"
          title={filterState.companyType}
          focused={filterState.companyType}
          options={rawCompanyTypeData.map((e) => ({ label: e }))}
        />
        <CustomMuiSelect
          disablePortal={false}
          sx={{
            my: '8px',
          }}
          label="Company Name"
          size="small"
          variant="outlined"
          options={rawCompanyDataFilter.map((e) => ({ label: e.name, value: e._id }))}
          title={rawCompanyDataFilter.find((ex) => ex._id === filterState.companyId)?.name}
          value={rawCompanyDataFilter?.find((ex) => ex._id === filterState.companyId)?.name}
          onChange={(e) => onChangeField(e.value, 'companyId')}
          focused={rawCompanyDataFilter?.find((ex) => ex._id === filterState.companyId)?.name}
        />
        <TextField
          value={filterState.status === true ? 'Active' : filterState.status === false ? 'Inactive' : ''}
          onChange={(e) => onChangeField(e.target.value === 'Active', 'status')}
          sx={{
            my: '8px',
          }}
          name="status"
          select
          label="Status"
          size="small"
          fullWidth
          variant="outlined"
          focused={filterState.status}
        >
          <MenuItem title="Active" value="Active">
            Active
          </MenuItem>
          <MenuItem title="Inactive" value="Inactive">
            Inactive
          </MenuItem>
        </TextField>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            {type === 'loyalty' ? 'Points' : 'Value'}
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <TextField
              value={type === 'loyalty' ? filterState.pointsMin : `${filterState.pointsMin}`}
              onChange={(e) => onChangeField(e.target.value, 'pointsMin')}
              type={type === 'loyalty' ? 'text' : 'number'}
              label="Min"
              size="small"
              fullWidth
              variant="outlined"
              focused={filterState.pointsMin}
              InputProps={{
                startAdornment: type !== 'loyalty' && (
                  <InputAdornment position="start">{login.user.country === 'India' ? '₹' : '$'}</InputAdornment>
                ),
              }}
            />
            <TextField
              value={type === 'loyalty' ? filterState.pointsMax : `${filterState.pointsMax}`}
              onChange={(e) => onChangeField(e.target.value, 'pointsMax')}
              type={type === 'loyalty' ? 'text' : 'number'}
              label="Max"
              size="small"
              fullWidth
              variant="outlined"
              focused={filterState.pointsMax}
              InputProps={{
                startAdornment: type !== 'loyalty' && (
                  <InputAdornment position="start">{login.user.country === 'India' ? '₹' : '$'}</InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={login.user.country === 'India' ? 'en-gb' : 'en'}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0px', marginBottom: '12px' }}
          >
            <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
              Expiry Date
            </span>
            <div style={{ display: 'flex', gap: '8px' }}>
              <DatePicker
                value={filterState.expiryFrom ? new Date(filterState.expiryFrom) : null}
                onChange={(date) => onChangeField(`${date.format('YYYY-MM-DD')}T00:01:00.000Z`, 'expiryFrom')}
                name="expiryFrom"
                type="date"
                label="From"
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  readOnly: true,
                }}
                renderInput={(props) => (
                  <TextField {...props} sx={{ width: '100%' }} focused={filterState.expiryFrom} size="small" />
                )}
              />
              <DatePicker
                value={filterState.expiryTo ? new Date(filterState.expiryTo) : null}
                onChange={(date) =>
                  onChangeField(`${date.subtract(1, 'days').format('YYYY-MM-DD')}T23:59:00.000Z`, 'expiryTo')
                }
                name="expiryTo"
                type="date"
                label="To"
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  readOnly: true,
                }}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    sx={{ width: '100%' }}
                    size="small"
                    error={dateErr}
                    focused={filterState.expiryTo}
                    helperText={dateErr ? 'To date should not be smaller than From date' : ''}
                  />
                )}
              />
            </div>
          </div>
        </LocalizationProvider>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default AssetModuleFilterMenu;
