import { Button } from '@mui/material';
import Swal from 'sweetalert2';

import { addSafalBuddy, updateSafalBuddy } from '../../../services/Service';

const BuddyButtons = ({ mode, id, otherUserId, refetch, cancelPending, cancelBlock }) => {
  // accept,pending,reject,invite,

  // pending, accept, reject, unBuddy, block, default, unblock

  const updateBuddy = async (status) => {
    try {
      const res = await updateSafalBuddy({ _id: id, status, otherUserId });
      if (res?.status === 200) refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const addBuddy = async (status) => {
    try {
      let res = null;
      if (id) {
        res = await updateSafalBuddy({ _id: id, status, otherUserId });
      } else {
        res = await addSafalBuddy({ otherUserId: otherUserId, status });
      }
      if (res?.status === 200) refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const onAcceptClick = async () => {
    const result = await Swal.fire({
      text: 'Are you sure you want to accept this request?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;

    updateBuddy('accept');
  };
  const onCancelRequest = async () => {
    const result = await Swal.fire({
      text: 'Are you sure you want to cancel this request?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;

    updateBuddy('default');
  };
  const onRejectClick = async () => {
    const result = await Swal.fire({
      text: 'Are you sure you want to reject this request?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;

    updateBuddy('reject');
  };
  const onSendRequestClick = () => {
    addBuddy('pending');
  };
  const onSendBlockClick = async () => {
    const result = await Swal.fire({
      text: 'Are you sure you want to block this user?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;

    updateBuddy('block');
  };
  const onSendUnBuddyClick = async () => {
    const result = await Swal.fire({
      text: 'Are you sure you want to unbuddy this user?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;

    updateBuddy('unBuddy');
  };
  const onUnblock = async () => {
    const result = await Swal.fire({
      text: 'Are you sure you want to unblock this user?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;

    updateBuddy('unblock');
  };

  if (mode === 'accept')
    return (
      <>
        <Button
          onClick={onSendUnBuddyClick}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          UnBuddy
        </Button>
        <Button
          onClick={onSendBlockClick}
          sx={{
            textTransform: 'none',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Block
        </Button>
      </>
    );
  if (mode === 'invite' || mode === 'reject')
    return (
      <>
        <Button
          onClick={onSendRequestClick}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Send Request
        </Button>
        <Button
          onClick={onSendBlockClick}
          sx={{
            textTransform: 'none',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Block
        </Button>
      </>
    );

  if (cancelPending && mode === 'pending')
    return (
      <>
        <Button
          onClick={onCancelRequest}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Cancel Request
        </Button>
        <Button
          onClick={onSendBlockClick}
          sx={{
            textTransform: 'none',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Block
        </Button>
      </>
    );
  if (cancelBlock && mode === 'block')
    return (
      <>
        <Button
          onClick={onUnblock}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Unblock
        </Button>
      </>
    );
  if (mode === 'pending')
    return (
      <>
        <Button
          onClick={onAcceptClick}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Accept
        </Button>
        <Button
          onClick={onRejectClick}
          sx={{
            textTransform: 'none',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Reject
        </Button>
      </>
    );

  return <></>;
};
export default BuddyButtons;
