import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, newSortData, sortState } = useSortHook(data);

  const headData = ['Rank', 'First Name', 'Last Name', 'UserID', 'Points'];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: handleSort,
          sortHeadMap: ['rank', 'user.firstName', 'user.lastName', 'user.userID', 'points'],
        }}
        headData={headData}
      >
        {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {newSortData?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.rank}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.user?.firstName}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.user?.lastName}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.user?.userID}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.points}</p>
            </CustomTableCellForBody>
            {/* <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{formatDateTime(e?.createdAt)}</p>
            </CustomTableCellForBody> */}
            {/* <CustomTableCellForBody align="center">
              <ThreeDotMenu onDelete={() => onDelete(e)} onDisplay={() => onDisplay(e)} onEdit={() => onEdit(e)} />
            </CustomTableCellForBody> */}
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
