import { FormControl, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './SafalLeaderBoardActivitiesMasterConfig.css';

import {
  getSafalLeaderBoardActivitiesMasterConfig,
  deleteSafalLeaderBoardActivitiesMasterConfig,
  getPlanList,
  getApplicationList,
  updateSafalLeaderBoardActivitiesMasterConfig,
  updateSafalBuddyAndLeaderBoardMasterSwitch,
} from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddSafalLeaderBoardActivitiesMasterConfigModal from './AddSafalLeaderBoardActivitiesMasterConfigModal';

const SafalLeaderBoardActivitiesMasterConfig = () => {
  const [planList, setPlanList] = useState([]);
  const [applicationsList, setApplicationsList] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [masterSwitch, setMasterSwitch] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const [disableTableSwitch, setDisableTableSwitch] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState('SafalSubscriptions');
  const [selectedTier, setSelectedTier] = useState('0');

  const fetchData = async () => {
    try {
      const res = await getSafalLeaderBoardActivitiesMasterConfig();
      if (res.status === 200) {
        setRawData(res.data.data);
        const filterResult = res.data.data.filter(
          (e) => e?.tier === parseInt(selectedTier, 10) && e?.application?.name === selectedApplication
        );
        setMasterSwitch(res.data?.masterSwitch);
        setFilterData(filterResult);
      }
    } catch (err) {
      console.log('getSafalLeaderBoardActivitiesMasterConfig fetchData error', err.message);
    }
  };
  const fetchApplicationsList = async () => {
    try {
      const res = await getApplicationList();
      if (res.status === 200) {
        setApplicationsList(res.data.data);
      }
    } catch (err) {
      console.log('getFaqList fetchData error', err.message);
    }
  };
  const fetchPlanData = async () => {
    try {
      const res = await getPlanList();
      if (res.status === 200) {
        setPlanList(res.data.data);
      }
    } catch (err) {
      console.log('getPlanList fetchData error', err.message);
    }
  };

  const onAddModalOpenDisplay = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenEdit = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpen = () => {
    setAddModalOpen({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteSafalLeaderBoardActivitiesMasterConfig({
        id: data._id,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalOpen = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data,
    });
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
    fetchPlanData();
    fetchApplicationsList();
  }, []);

  useEffect(() => {
    const filterResult = rawData.filter(
      (e) => e?.tier === parseInt(selectedTier, 10) && e?.application?.name === selectedApplication
    );
    setFilterData(filterResult);
  }, [selectedTier, selectedApplication]);

  const updateValue = async (data, key, newValue) => {
    setDisableTableSwitch(true);
    try {
      const payload = { ...data, [key]: newValue };
      const res = await updateSafalLeaderBoardActivitiesMasterConfig(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableTableSwitch(false);
    }
  };

  const updateNotificationValue = async (data, key, newValue) => {
    setDisableTableSwitch(true);
    try {
      const payload = { ...data, notification: { ...data.notification, [key]: newValue } };
      const res = await updateSafalLeaderBoardActivitiesMasterConfig(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableTableSwitch(false);
    }
  };

  const masterSwitchValue = masterSwitch.find((e) => {
    const findApplication = applicationsList.find((appX) => appX.name === selectedApplication);
    if (findApplication && e?.application === findApplication?._id && e?.tier === parseInt(selectedTier, 10))
      return true;
    return false;
  });

  const masterSwitchValueCheck = masterSwitchValue ? masterSwitchValue?.leaderBoardStatus : false;

  const updateSwitchStatus = async (data, newValue) => {
    try {
      setDisableSwitch(true);
      const findApplication = applicationsList.find((appX) => appX.name === selectedApplication);
      const payload = {
        application: findApplication?._id,
        buddyStatus: data?.buddyStatus ? data?.buddyStatus : false,
        leaderBoardStatus: data?.leaderBoardStatus ? !data?.leaderBoardStatus : newValue,
        tier: parseInt(selectedTier, 10),
        screen: 'LeaderBoard', // SafalBuddy, LeaderBoard
      };
      const res = await updateSafalBuddyAndLeaderBoardMasterSwitch(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableSwitch(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>SafalLeaderBoard Activities Master Config - SafalSubscriptions</title>
      </Helmet>

      <div id="SafalLeaderBoardActivitiesMasterConfig">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              SafalLeaderBoard Activities Master Config
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="SafalLeaderBoardActivitiesMasterConfig-selectedApplication">Application</InputLabel>
              <Select
                labelId="SafalLeaderBoardActivitiesMasterConfig-selectedApplication"
                value={selectedApplication}
                label="Application"
                onChange={(e) => setSelectedApplication(e.target.value)}
              >
                {applicationsList?.map((e, index) => (
                  <MenuItem key={index} value={e?.name}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="SafalLeaderBoardActivitiesMasterConfig-selectedTier">Plan Tier</InputLabel>
              <Select
                labelId="SafalLeaderBoardActivitiesMasterConfig-selectedTier"
                value={selectedTier}
                label="Plan Tier"
                onChange={(e) => setSelectedTier(e.target.value)}
              >
                {planList?.map((e, index) => (
                  <MenuItem key={index} value={e?.tier}>
                    Tier {e.tier} : {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Enable SafalLeaderBoard</span>
            <Switch
              disabled={disableSwitch}
              onChange={(e) => updateSwitchStatus(masterSwitchValue, e.target.checked)}
              checked={masterSwitchValueCheck}
              color="success"
            />
          </div>
          <TableView
            disabled={disableTableSwitch}
            updateValue={updateValue}
            updateNotificationValue={updateNotificationValue}
            onDelete={onDeleteModalOpen}
            onEdit={onAddModalOpenEdit}
            onDisplay={onAddModalOpenDisplay}
            data={filterData}
          />
        </div>
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddSafalLeaderBoardActivitiesMasterConfigModal
        planList={planList}
        applicationsList={applicationsList}
        open={addModalOpen.open}
        data={addModalOpen.data}
        isDisplay={addModalOpen.isDisplay}
        onClose={onAddModalClose}
      />
    </>
  );
};

export default SafalLeaderBoardActivitiesMasterConfig;
