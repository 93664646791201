import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Image } from 'antd';
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import { tooltipClasses } from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';
import MomentUtils from '@date-io/moment';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import {
  EditsubsResponse,
  GetsubsResponse,
  deleteAssetModuleAccount,
  deleteAssetModuleCard,
  getAssetModuleAccounts,
  getCompanyByNameType,
} from '../../services/Service';
import SuccessToast from '../Toast/Success';
import { setSubscriptions } from '../../store/subscriptionSlice';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import { getProfile } from '../../store/loginSlice';
import ErrorToast from '../Toast/Error';
import DeleteConfirmationModal from '../../pages/user/AssetModule/DeleteConfirmationModal';
import { FileImageView } from './EditModal';
import CustomRating from '../UI/CustomRating';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '10px',
  boxShadow: 24,
  // p: "4 0 4 4",
  p: '30px',
};

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  maxHeight: '80px',
};

const DisplayModal = ({
  openDisplayModal,
  setOpenDisplayModal,
  data,
  setEditData,
  setOpenEventModal,
  setOpenTrackModal,
  setOpenDelete,
  handledelete,
  setSelected,
  setOpenEditModal,
  setDeleteId,
  subNames,
  setDeleteSubNames,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedEditFileName, setSelectedEditFileName] = useState('');
  const [indata, setInData] = useState(['attachment']);
  const { user } = useSelector((state) => state.login);
  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);
  const assets_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'assets');
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const handleClose = () => {
    setOpenDisplayModal(false);
    // setOpenEventModal(false);
    setEditData([]);
    setTabValue(0);
  };

  const [open, setOpen] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [rawData, setRawData] = useState([]);
  const [accountData, setAccountData] = useState([]);

  const handleMenuClick = (e, menuItem) => {
    if (menuItem === 'update') {
      navigate('/asset', { state: { stateFilter: e } });
    } else if (menuItem === 'delete') {
      console.log('delete', e);
    }
  };

  const onDeleteModalInit = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data,
    });
  };
  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  const onDelete = async (e) => {
    try {
      const res = await deleteAssetModuleAccount(e._id);
      if (res.status === 200) {
        SuccessToast(res.data.message);
        onDeleteModalReset();
        await fetchData(data);
      }
    } catch (err) {
      console.log('AssetModuleLoyaltyGiftReward onDelete', err.message);
      ErrorToast(err.message);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const validationSchema = Yup.object().shape({
    website: Yup.string().required('Please Enter Website'),
    frequency: Yup.string().required('Please Select frequency'),
    startDate: Yup.date().required('Please Select Contract Start Date').typeError('Please enter a valid date'),
    amount: Yup.number().required('Please Enter Amount'),
    autoRenewal: Yup.string().required('Please Select Auto Renewal'),
    nextBilling: Yup.date().required('Please Select next billing Date').typeError('Please enter a valid date'),
    status: Yup.string().required('Please select Status'),
  });

  const initialValues = {
    subType: data?.company?.companyType,
    website: data?.company?.website,
    _id: data?._id,
    rating: data?.rating,
    isLiked: data?.isLiked,
    frequency: data?.frequency,
    startDate: data?.startDate,
    nextBilling: data?.nextBilling,
    amount: data?.amount,
    autoRenewal: data?.autoRenewal ? 'true' : 'false',
    status: `${data?.status}`,
    comments: data?.comments,
    description: data?.description,
    attachment: data && data?.attachments?.length > 0 ? data?.attachments : [],
    review: data?.review,
    label: data?.label?.name,
    expiryDate: data?.expiryDate,
    frequencyNumber: data?.frequencyNumber,
  };
  const DisplayForm = useFormik({
    enableReinitialize: false,
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      values.subscriptionName = data?.subscriptionName;
      values.currencyAbr = user?.country === 'United States' ? 'USD' : 'INR';
      const evnt = indata.map((val) => {
        if (val === 'attachment') {
          return { event: val, oldValues: initialValues[val], newValues: values[val][0]?.name || 'removed' };
        }
        return { event: val, oldValues: initialValues[val], newValues: values[val] };
      });
      let valuesToBeSent = indata.map((val) => {
        if (val === 'attachment' && values[val] !== 'undefined') {
          if (values[val].length === 0) {
            return { attachment: [] };
          }
          return { attachment: [...values.attachment] };
        }
        if (val === 'attachment' && values[val] === 'undefined') {
          return { attachment: [...initialValues.attachment] };
        }
        return { [val]: values[val] };
      });
      valuesToBeSent = Object.assign({}, ...valuesToBeSent);
      valuesToBeSent.event = { call: 'EditSub', subName: values.subscriptionName, logs: evnt };
      console.log('DisplayModal valuesToBeSent => ', valuesToBeSent);
      EditsubsResponse(data._id, valuesToBeSent)
        .then((res) => {
          if (res.data.success === true) {
            setInData([]);
            GetsubsResponse()
              .then((res) => {
                if (res.data.success === true) {
                  dispatch(
                    setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems })
                  );
                  dispatch(getProfile()).unwrap();
                  handleClose();
                  SuccessToast(res.data.message);
                }
              })
              .catch((err) => {
                ErrorToast(err.response.data.message);
              });
            handleClose();
            resetForm(initialValues);
          } else {
            handleClose();
            ErrorToast(res.data.message);
          }
        })
        .catch((err) => {
          ErrorToast(err?.response?.data?.message);
          // handleClose();
        });
    },
  });

  const fetchData = async (data) => {
    const page = 1;
    const limit = 1000;
    const type = 'all';
    const filter = data;
    try {
      const res = await getAssetModuleAccounts(page, limit, type, filter);
      if (res.status === 200) {
        setRawData(res.data.data);
        setAccountData(res.data.data);
      }
    } catch (err) {
      console.log('AssetModule fetchData error', err.message);
    }
  };

  useEffect(() => {
    const formdata = {
      subType: data?.company?.companyType,
      website: data?.company?.website,
      _id: data?._id,
      rating: data?.rating,
      isLiked: data?.isLiked,
      frequency: data?.frequency,
      startDate: data?.startDate,
      nextBilling: data?.nextBilling,
      amount: data?.amount,
      autoRenewal: data?.autoRenewal ? 'true' : 'false',
      status: `${data?.status}`,
      comments: data?.comments,
      description: data?.description,
      attachment:
        data && data?.attachments?.length > 0 ? `${data?.attachments?.url}`.split('-').slice(4).join('-') : [],
      review: data?.review,
      label: data?.label?.name,
      paymentMode: data?.paymentInstrument?.paymentMode,
      paymentInstrument: data?.paymentInstrument?.nickNameField,
      expiryDate: data?.expiryDate,
      frequencyNumber: data?.frequencyNumber,
    };
    DisplayForm.setValues(formdata);
    setSelectedEditFileName(data?.attachments);
    setInData(['attachment']);
    fetchData(data);
  }, [data]);

  const accountDataFilter = accountData.filter((ex) => {
    if (ex.status === true) {
      if (ex.expiryDate) {
        if (moment() > moment(ex.expiryDate)) {
          // date is past
          return false;
        }
        if (data?.company?.companyType === ex?.companyId?.type && data?.company?.name === ex?.companyId?.name)
          return true;
        // date is future
        return false;
      }
      if (data?.company?.companyType === ex?.companyId?.type && data?.company?.name === ex?.companyId?.name)
        return true;
      return false;
    }
    return false;
  });

  const navigateToSafalRoom = async () => {
    if (data?.masterCompany?._id) {
      handleClose();
      navigate(`/safalroom?company=${data?.masterCompany?._id}`);
    } else {
      if (!data?.company) {
        toast.error('SafalRoom not available for this subscription');
        return;
      }
      try {
        const findCom = await getCompanyByNameType({
          name: data?.company?.name,
          type: data?.company?.companyType,
        });
        if (findCom.status === 200) {
          handleClose();
          navigate(`/safalroom?company=${findCom.data.data._id}`);
        }
      } catch (err) {
        toast.error('SafalRoom not available for this subscription');
      }
    }
  };

  const showFrequencyNumber =
    DisplayForm.values.frequencyNumber !== undefined &&
    DisplayForm.values.frequencyNumber !== null &&
    DisplayForm.values.frequencyNumber !== 1;

  return (
    // <div>
    <>
      <Modal
        onClose={() => {
          handleClose();
        }}
        sx={{ zIndex: 345 }}
        open={openDisplayModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          id="scrollbar"
          sx={{
            ...style,
            height: '85vh',
            paddingBottom: '4rem',
            width: { xs: '98%', sm: '400px', md: '800px', lg: '1000px' },
            overflowY: 'scroll',
            '@media (max-width: 640px)': {
              height: '98vh',
              width: '98vw',
              padding: '16px',
              overflow: 'auto',
            },
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '24px',
              fontWeight: 700,
              color: '#3D71FF',
            }}
          >
            {data?.subscriptionName} Subscription.
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange} style={{ display: 'flex', alignItems: 'center' }}>
            <Tab
              label="Subscriptions"
              sx={{
                textTransform: 'initial',
                fontSize: '20px',
              }}
            />
            {accountDataFilter.length > 0 && assets_DynamicMenuState && (
              <Tab
                label="Assets Information"
                sx={{
                  textTransform: 'initial',
                  fontSize: '20px',
                }}
              />
            )}
            <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
              <Fab
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                }}
                size="small"
                color="primary"
                aria-label="add"
              >
                <CloseIcon />
              </Fab>
            </div>
          </Tabs>

          {tabValue === 0 && (
            <div>
              {/* Content for Tab 1 */}
              <Typography>
                <Stack direction="row" justifyContent="space-between">
                  <Box />
                  <Box>
                    <Tooltip title={'SafalRoom'}>
                      <Button onClick={navigateToSafalRoom}>
                        <img
                          src="/assets/images/Safalroom_blue.png"
                          style={{
                            width: '22px',
                            height: '22px',
                            objectFit: 'contain',
                          }}
                          alt="SafalRoom"
                        />
                      </Button>
                    </Tooltip>
                    <Tooltip title={'Edit'}>
                      <Button
                        onClick={() => {
                          setOpenEditModal(true);
                          setOpenDisplayModal(false);
                        }}
                      >
                        <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
                      </Button>
                    </Tooltip>
                    <Tooltip title={'Delete'}>
                      <Button
                        onClick={() => {
                          setOpenDelete(true);
                          setDeleteId([data._id]);
                          setDeleteSubNames([data.subscriptionName]);
                          setOpenDisplayModal(false);
                        }}
                      >
                        <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
                      </Button>
                    </Tooltip>
                    <Tooltip title={'Changelog'}>
                      <Button
                        onClick={() => {
                          setOpenTrackModal(true);
                          setEditData(data?.company?.name);
                          setOpenDisplayModal(false);
                        }}
                      >
                        <Iconify icon="mdi:clipboard-text-search" color="#1877F2" width={22} height={22} />
                      </Button>
                    </Tooltip>
                  </Box>
                </Stack>

                <Scrollbar>
                  <Box sx={{ height: '100%', overflow: 'auto' }}>
                    <FormikProvider value={DisplayForm}>
                      <form onSubmit={DisplayForm.handleSubmit}>
                        <Grid
                          container
                          rowSpacing={4}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ overflowX: 'hidden' }}
                        >
                          <Grid item xs={12} sm={12} md={6}>
                            <Box>
                              <Image width={60} height={60} src={data?.company?.logo} />
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              {/* <FormLabel sx={{ fontSize: '14px', color: '#B6B6B6' }}>Subscription Type</FormLabel> */}
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                label="Subscription Type"
                                name="subType"
                                href={DisplayForm.values.subType}
                                InputProps={{ readOnly: true }}
                              >
                                {DisplayForm.values.subType}
                              </Field>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontSize: '14px', color: '#B6B6B6' }}>Website</FormLabel>
                              <Field
                                as={Link}
                                label="Website"
                                name="website"
                                href={
                                  DisplayForm.values.website?.includes('https://')
                                    ? DisplayForm.values.website
                                    : `https://${DisplayForm.values.website}`
                                }
                                target="_blank"
                              >
                                {DisplayForm.values.website?.includes('https://')
                                  ? DisplayForm.values.website
                                  : `https://${DisplayForm.values.website}`}
                              </Field>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                InputProps={{ readOnly: true }}
                                minDate={moment(DisplayForm.values.startDate).toDate()}
                                label={<Typography sx={{ color: '#B6B6B6' }}>Contract Start Date</Typography>}
                                // inputFormat="   MM/DD/YYYY"
                                name="startDate"
                                value={moment(DisplayForm.values.startDate).format(
                                  user.country === 'India' ? 'DD/MM/yyyy' : 'MM/DD/yyyy'
                                )}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                InputProps={{ readOnly: true }}
                                minDate={moment(DisplayForm.values.startDate).toDate()}
                                label={<Typography sx={{ color: '#B6B6B6' }}>Next Billing Date</Typography>}
                                // inputFormat="MM/DD/YYYY"
                                name="nextBilling"
                                value={moment(DisplayForm.values.nextBilling).format(
                                  user.country === 'India' ? 'DD/MM/yyyy' : 'MM/DD/yyyy'
                                )}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                InputProps={{ readOnly: true }}
                                minDate={moment(DisplayForm.values.startDate).toDate()}
                                label={<Typography sx={{ color: '#B6B6B6' }}>Expiry Date</Typography>}
                                // inputFormat="MM/DD/YYYY"
                                name="expiryDate"
                                value={
                                  DisplayForm.values?.expiryDate
                                    ? moment(DisplayForm.values.expiryDate).format(
                                        user.country === 'India' ? 'DD/MM/yyyy' : 'MM/DD/yyyy'
                                      )
                                    : ''
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              sx={{ mt: 2, width: '98%' }}
                              error={DisplayForm.touched.amount && DisplayForm.errors.amount}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                InputProps={{ readOnly: true }}
                                label="Amount"
                                name="amount"
                                value={`${user?.country === 'United States' ? '$' : '₹'} ${DisplayForm.values.amount}`}
                                // startAdornment={
                                //   <InputAdornment position="start">
                                //     {user?.country === 'United States' ? '$' : '₹'}
                                //   </InputAdornment>
                                // }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                label="Frequency"
                                name="frequency"
                                value={DisplayForm.values.frequency}
                                InputProps={{ readOnly: true }}
                              >
                                {DisplayForm.values.frequency}
                              </Field>
                            </FormControl>
                          </Grid>
                          {showFrequencyNumber && (
                            <Grid item xs={12} sm={12} md={6}>
                              <FormControl
                                sx={{ mt: 2, width: '98%' }}
                                error={DisplayForm.touched.frequencyNumber && DisplayForm.errors.frequencyNumber}
                              >
                                <Field
                                  as={TextField}
                                  variant="standard"
                                  size="small"
                                  InputProps={{ readOnly: true }}
                                  label="Frequency Number"
                                  name="frequencyNumber"
                                  value={DisplayForm.values.frequencyNumber}
                                />
                              </FormControl>
                            </Grid>
                          )}

                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                label="Auto Renewal"
                                name="autoRenewal"
                                value={DisplayForm.values.autoRenewal === 'true' ? 'Yes' : 'No'}
                                InputProps={{ readOnly: true }}
                              >
                                {DisplayForm.values.autoRenewal === 'true' ? 'Yes' : 'No'}
                              </Field>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                label="Status"
                                name="status"
                                InputProps={{ readOnly: true }}
                              >
                                {DisplayForm.values.status}
                              </Field>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                label="Label"
                                name="label"
                                InputProps={{ readOnly: true }}
                              >
                                {DisplayForm.values?.label}
                              </Field>
                            </FormControl>
                          </Grid>
                          {/* paymentMode */}
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                label="Payment Mode"
                                name="paymentMode"
                                InputProps={{ readOnly: true }}
                              >
                                {DisplayForm.values?.paymentMode}
                              </Field>
                            </FormControl>
                          </Grid>
                          {/* paymentInstrument */}
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '98%' }}
                            >
                              <Field
                                as={TextField}
                                variant="standard"
                                size="small"
                                label="Payment Instrument"
                                name="paymentInstrument"
                                InputProps={{ readOnly: true }}
                              >
                                {DisplayForm.values?.paymentInstrument}
                              </Field>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl
                              // fullWidth
                              sx={{ width: '99%' }}
                            >
                              <Field
                                as={TextField}
                                id="input1"
                                name="comments"
                                multiline
                                rows={3}
                                label={<Typography sx={{ color: '#B6B6B6' }}>Comments</Typography>}
                                variant="standard"
                                size="small"
                                value={DisplayForm.values.comments}
                                InputProps={{ readOnly: true }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <Typography
                              sx={{
                                color: ' #B6B6B6',
                                fontFamily: 'Roboto',
                                fontWeight: '400',
                                fontSize: '1rem',
                                lineHeight: '1.4375em',
                                letterSpacing: '0.00938em',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                mb: 1,
                              }}
                            >
                              Rating
                            </Typography>
                            <CustomRating precision={0.5} name="rating" value={+DisplayForm.values.rating} readOnly />
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <Typography
                              sx={{
                                color: ' #B6B6B6',
                                fontFamily: 'Roboto',
                                fontWeight: '400',
                                fontSize: '1rem',
                                lineHeight: '1.4375em',
                                letterSpacing: '0.00938em',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                mb: 1,
                              }}
                            >
                              Safals
                            </Typography>
                            <Box sx={{ display: 'flex' }} name="isLiked">
                              {DisplayForm.values.isLiked === 1 && (
                                <img src="/assets/images/Like.png" alt="Like Symbol" />
                              )}
                              {DisplayForm.values.isLiked === -1 && (
                                <img src="/assets/images/disLike.png" alt="DisLike Symbol" />
                              )}
                              {DisplayForm.values.isLiked === 0 && (
                                <>
                                  <img
                                    src="/assets/images/Like.png"
                                    alt="Like Symbol"
                                    style={{ filter: 'grayscale(100%)' }}
                                  />
                                  <img
                                    src="/assets/images/disLike.png"
                                    alt="DisLike Symbol"
                                    style={{ filter: 'grayscale(100%)' }}
                                  />
                                </>
                              )}
                            </Box>
                          </Grid>

                          {DisplayForm.values.review && (
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl
                                // fullWidth
                                sx={{ width: '99%' }}
                              >
                                <Field
                                  as={TextField}
                                  id="input1"
                                  name="review"
                                  multiline
                                  rows={3}
                                  label={<Typography sx={{ color: '#B6B6B6' }}>Review</Typography>}
                                  variant="standard"
                                  size="small"
                                  value={DisplayForm.values.review}
                                  InputProps={{ readOnly: true }}
                                />
                              </FormControl>
                            </Grid>
                          )}
                        </Grid>

                        <Grid>
                          <FormLabel
                            htmlFor="contained-button-file"
                            sx={{ display: 'flex', alignItems: 'center', mt: '16px' }}
                          >
                            <Button
                              variant="contained"
                              component="span"
                              sx={{
                                fontWeight: 700,
                                textTransform: 'none',
                                bgcolor: '#fff',
                                color: '#1D1D1F',
                                borderRadius: '30px',
                                boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                                ':hover': { bgcolor: 'transparent' },
                              }}
                            >
                              <Iconify icon="entypo:attachment" color="#3D71FF" width={20} height={20} mr={1} />
                              Attachment
                            </Button>
                          </FormLabel>
                          {/* {selectedEditFileName && (
                            <Box display={'flex'} flexWrap={'wrap'}>
                              {selectedEditFileName.map((val) => (
                                <Box key={val} sx={{ mt: 1, ml: 2 }}>
                                  {val.name || val?.url?.split('/').pop().substring(14)}
                                </Box>
                              ))}
                            </Box>
                          )} */}
                          {selectedEditFileName && (
                            <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap', marginTop: '20px' }}>
                              {selectedEditFileName.map((val, index) => (
                                <FileImageView key={index} value={val} />
                              ))}
                            </div>
                          )}
                        </Grid>
                      </form>
                    </FormikProvider>
                  </Box>
                </Scrollbar>
              </Typography>
            </div>
          )}
          {tabValue === 1 && assets_DynamicMenuState && (
            <div style={{ overflow: 'auto', height: '100%' }}>
              {/* Content for Tab 2 */}
              <Typography>
                <TableContainer component={Paper} sx={{ overflowY: 'hidden', overflowX: 'hidden', mt: 2 }}>
                  <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                    <TableHead style={{ backgroundColor: '#7C9EFB' }}>
                      <TableRow>
                        <TableCell style={{ color: '#fff' }}>Card Name</TableCell>
                        <TableCell style={{ color: '#fff' }}>Card Type</TableCell>
                        <TableCell style={{ color: '#fff' }}>Card Number</TableCell>
                        <TableCell style={{ color: '#fff' }}>Point / Value</TableCell>
                        <TableCell style={{ color: '#fff' }}>Action</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {accountDataFilter?.map((ex, index) => (
                        <TableRow key={index} hover role="checkbox">
                          <LightTooltip title={ex?.cardId?.name} placement="bottom">
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <p style={tableCellPStyle}>{ex?.cardId?.name}</p>
                            </TableCell>
                          </LightTooltip>
                          <LightTooltip title={ex?.cardId?.type} placement="bottom">
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <p style={tableCellPStyle}>{ex?.cardId?.type}</p>
                            </TableCell>
                          </LightTooltip>
                          <LightTooltip title={ex?.cardNumber} placement="bottom">
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <p style={tableCellPStyle}>{ex?.cardNumber}</p>
                            </TableCell>
                          </LightTooltip>
                          <LightTooltip title={ex?.points} placement="bottom">
                            <TableCell
                              align="left"
                              sx={{
                                backgroundColor: '#FFFFFF',
                              }}
                            >
                              <p style={tableCellPStyle}>{ex?.points}</p>
                            </TableCell>
                          </LightTooltip>
                          <TableCell
                            align="left"
                            sx={{
                              backgroundColor: '#FFFFFF',
                            }}
                          >
                            <div style={{ display: 'flex' }}>
                              <ThreeDotMenu onEdit={() => handleMenuClick(ex, 'update')} />
                              <LightTooltip title="Delete" placement="bottom">
                                <IconButton
                                  onClick={() => onDeleteModalInit(ex)}
                                  style={{ marginLeft: '10px' }}
                                  aria-label="delete"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </LightTooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
            </div>
          )}
        </Box>
      </Modal>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
    </>
  );
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })
);

export default DisplayModal;

const ThreeDotMenu = ({ onEdit }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    // setOpen(false);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDrop = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" aria-controls="settings-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu id="settings-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseDrop}>
        <MenuItem onClick={onEdit}>Edit</MenuItem>
      </Menu>
    </>
  );
};
