import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@mui/material';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Box {...other} sx={{ width: '100%' }}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Not found
      </Typography>
      {searchQuery ? (
        <Typography variant="body2" align="center">
          No results found for &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>.
        </Typography>
      ) : (
        <></>
      )}
      <Typography variant="body2" align="center">
        Try checking for types or using complete words.
      </Typography>
    </Box>
  );
}
