/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Fab, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './InitialFeedbackModal.css';
import smiley05 from '../../assets/smiley05.png';
import smiley04 from '../../assets/smiley04.png';
import smiley03 from '../../assets/smiley03.png';
import smiley02 from '../../assets/smiley02.png';
import smiley01 from '../../assets/smiley01.png';
import { addInitialFeedback } from '../../services/Service';
import SuccessToast from '../Toast/Success';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '95vh',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  '@media (max-width: 640px)': {
    width: '95vw',
    height: '95vh',
    overflow: 'auto',
  },
};

const initialFieldValue = {
  overallSatisfaction: undefined, // 1 - 5
  aiChatSubscription: undefined, // 1 - 5
  overallExperience: undefined, // 1 - 5
  moneySavingFeatures: undefined, // 1 - 5
  easeOfUse: undefined, // 1 - 5
};

export default function InitialFeedbackModal({ open, onClose }) {
  // const login = useSelector((state) => state.login);
  const [formState, setFormState] = useState(initialFieldValue);

  const handleClose = () => {
    setFormState(initialFieldValue);
    onClose();
  };

  const onChangeValue = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const onSubmit = async () => {
    try {
      if (formState.overallSatisfaction === undefined) return;
      if (formState.aiChatSubscription === undefined) return;
      if (formState.overallExperience === undefined) return;
      if (formState.moneySavingFeatures === undefined) return;
      if (formState.easeOfUse === undefined) return;

      const res = await addInitialFeedback(formState);
      SuccessToast('Thank you for submit your feedback');
      handleClose();
    } catch (err) {
      console.log(err);
    }
  };

  const isSubmitDisable =
    formState.overallSatisfaction === undefined ||
    formState.aiChatSubscription === undefined ||
    formState.overallExperience === undefined ||
    formState.moneySavingFeatures === undefined ||
    formState.easeOfUse === undefined;

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={handleClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }} id="InitialFeedbackModal">
            <h1 style={{ textAlign: 'center' }}>Submit Your Feedback</h1>
            <p>
              Hello there! We value your feedback on our application, and we'd love to hear your thoughts on a few key
              areas. Your input helps us improve and tailor our services to better meet your needs. Below are some
              parameters we'd like you to consider while using the app. Your insights on user experience, money-saving
              features, AI chat subscription, and overall satisfaction are highly appreciated. Let's work together to
              make your experience with our platform even better!
            </p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Ease of Use</span>
                  <span style={{ fontSize: '12px' }}>How easy is it to navigate and use?</span>
                </div>
                <SmileyRating value={formState.easeOfUse} onChange={(value) => onChangeValue('easeOfUse', value)} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Money Saving Features</span>
                  <span style={{ fontSize: '12px' }}>Does it help you save money?</span>
                </div>
                <SmileyRating
                  value={formState.moneySavingFeatures}
                  onChange={(value) => onChangeValue('moneySavingFeatures', value)}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Overall Experience</span>
                  <span style={{ fontSize: '12px' }}>How do you feel about its features?</span>
                </div>
                <SmileyRating
                  value={formState.overallExperience}
                  onChange={(value) => onChangeValue('overallExperience', value)}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>AI Chat Subscription</span>
                  <span style={{ fontSize: '12px' }}>How satisfied are you with AI chat?</span>
                </div>
                <SmileyRating
                  value={formState.aiChatSubscription}
                  onChange={(value) => onChangeValue('aiChatSubscription', value)}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Overall Satisfaction</span>
                  <span style={{ fontSize: '12px' }}>Would you recommend it?</span>
                </div>
                <SmileyRating
                  value={formState.overallSatisfaction}
                  onChange={(value) => onChangeValue('overallSatisfaction', value)}
                />
              </div>
            </div>
            <Button
              disabled={isSubmitDisable}
              onClick={onSubmit}
              size="small"
              variant="contained"
              sx={{ textTransform: 'capitalize' }}
            >
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

// Ease of Use: How easy is it to navigate and use?
// Money-saving Features: Does it help you save money?
// Overall Experience: How do you feel about its features?
// AI Chat Subscription: How satisfied are you with AI chat?
// Overall Satisfaction: Would you recommend it?

const SmileyRating = ({ value, onChange }) => {
  const classNameX5 = value === undefined ? '' : value === 5 ? 'selected' : 'not-selected';
  const classNameX4 = value === undefined ? '' : value === 4 ? 'selected' : 'not-selected';
  const classNameX3 = value === undefined ? '' : value === 3 ? 'selected' : 'not-selected';
  const classNameX2 = value === undefined ? '' : value === 2 ? 'selected' : 'not-selected';
  const classNameX1 = value === undefined ? '' : value === 1 ? 'selected' : 'not-selected';

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <img
        onClick={() => onChange(5)}
        className={`SmileyRating-img ${classNameX5}`}
        style={{ height: '32px', width: '32px' }}
        src={smiley05}
        alt="smiley05"
      />
      <img
        onClick={() => onChange(4)}
        className={`SmileyRating-img ${classNameX4}`}
        style={{ height: '32px', width: '32px' }}
        src={smiley04}
        alt="smiley04"
      />
      <img
        onClick={() => onChange(3)}
        className={`SmileyRating-img ${classNameX3}`}
        style={{ height: '32px', width: '32px' }}
        src={smiley03}
        alt="smiley03"
      />
      <img
        onClick={() => onChange(2)}
        className={`SmileyRating-img ${classNameX2}`}
        style={{ height: '32px', width: '32px' }}
        src={smiley02}
        alt="smiley02"
      />
      <img
        onClick={() => onChange(1)}
        className={`SmileyRating-img ${classNameX1}`}
        style={{ height: '32px', width: '32px' }}
        src={smiley01}
        alt="smiley01"
      />
    </div>
  );
};
