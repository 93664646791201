import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Image } from 'antd';

import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import CustomRating from '../../components/UI/CustomRating';
import { getPaymentMode } from '../../services/Service';
import CustomMuiSelect from '../../components/UI/CustomMuiSelect';

// const [filterState, setFilterState] = useState({
//   paymentMode: '',
//   amountMax: '',
//   amountMin: '',
//   subStatus: '',
// });

const PaymentModeReportFilterMenu = ({ state, onFilterApply, onFilterReset, companyData }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [filterState, setFilterState] = useState({
    paymentMode: '',
    amountMax: '',
    amountMin: '',
    subStatus: '',
  });
  const [paymentMode, setPaymentMode] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
    setShowApply(false);
  };

  useEffect(() => {
    if (open) {
      setFilterState(state);
    }
  }, [open]);

  const handleFilterApply = () => {
    onFilterApply(filterState);
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterApply({
      paymentMode: '',
      amountMax: '',
      amountMin: '',
      subStatus: '',
    });
    handleClose();
  };

  // Update resetState whenever the state changes
  useEffect(() => {
    setShowApply(isThisResetState);
  }, [state]);

  const isThisResetState =
    state?.paymentMode !== '' || state?.amountMax !== '' || state?.amountMin !== '' || state?.subStatus !== '';

  // State to store the boolean value
  const [showApply, setShowApply] = useState(isThisResetState);

  const fetchPaymentMode = async () => {
    try {
      const res = await getPaymentMode({ status: true });
      setPaymentMode(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPaymentMode();
  }, [open]);

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '300px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        {/* companyType */}
        <TextField
          value={filterState?.paymentMode}
          focused={filterState?.paymentMode}
          onChange={(e) => onChangeField(e.target.value, 'paymentMode')}
          sx={{
            my: '8px',
          }}
          name="paymentMode"
          select
          label="Payment Mode"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          {paymentMode.map((e) => (
            <MenuItem value={e._id}>{e.name}</MenuItem>
          ))}
        </TextField>
        {/* status */}
        {/* <TextField
          value={filterState?.subStatus}
          focused={filterState?.subStatus}
          onChange={(e) => onChangeField(e.target.value, 'subStatus')}
          sx={{
            my: '8px',
          }}
          name="subStatus"
          select
          label="Subscriptions Status"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </TextField> */}
        {/* amountMin */}
        <div style={{ display: 'flex', gap: '12px', marginTop: '8px', marginBottom: '8px' }}>
          <TextField
            value={filterState?.amountMin}
            focused={filterState?.amountMin}
            onChange={(e) => onChangeField(e.target.value, 'amountMin')}
            sx={{
              flex: 1,
            }}
            name="amountMin"
            label="Amount Min"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
          {/* amountMax */}
          <TextField
            value={filterState?.amountMax}
            focused={filterState?.amountMax}
            onChange={(e) => onChangeField(e.target.value, 'amountMax')}
            sx={{
              flex: 1,
            }}
            name="amountMax"
            label="Amount Max"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default PaymentModeReportFilterMenu;
