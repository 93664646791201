import { IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone from '../../../hooks/useUserTimeZone';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete }) => {
  const { formatDateTime } = useUserTimeZone();

  // productId.name
  const headData = ['Plan Name', 'Tier', 'Amount', 'Interval', 'Type', 'Status', 'Action'];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {data.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
        {data?.map((e, index) => (
          <TableRow key={index}>
            {/* Plan Name */}
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.productId?.name?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.tier?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{`${(e.unit_amount / 100).toFixed(2)} ${e.currency}`}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{`every ${e.interval_count} ${e.interval}`}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.type?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.status === true ? 'Active' : 'Inactive'}</p>
            </CustomTableCellForBody>
            {/* <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{formatDateTime(e?.createdAt)}</p>
            </CustomTableCellForBody> */}
            <CustomTableCellForBody align="center">
              <div
                style={{
                  display: 'flex',
                }}
              >
                {/* <IconButton onClick={() => onDelete(e)}>
                  <DeleteIcon />
                </IconButton> */}
                <IconButton onClick={() => onEdit(e)}>
                  <EditIcon />
                </IconButton>
              </div>
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
