/* eslint-disable */
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeUser } from '../../store/ChatSlice';

import { db } from '../../firebase';
import { toast } from 'react-hot-toast';
import DisplayAvatar from './DisplayAvatar';
import useDebounce from '../../hooks/useDebounce';

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `50px`,
        border: `none`,
        boxShadow: ` rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
        padding: `0px 20px`,
        fontSize: `16px`,
        fontWeight: 400,
        lineHeight: 1.5,
        color: `#C5C5C5`,
      },
    },
  },
})(TextField);
const Search = ({ open, username, setUsername }) => {
  const [user, setUser] = useState([]);
  const [err, setErr] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const { chatMode } = useSelector((state) => state.chats);
  const { role } = useSelector((state) => state.login?.user);
  const dispatch = useDispatch();

  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const userToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  /**
   * remove notification dot
   */
  const removeNotificationDot = async () => {
    await deleteDoc(doc(db, 'notificationsdot', currentUser?.uid));
  };

  useEffect(() => {
    if (open) removeNotificationDot();
  }, [open]);

  useEffect(() => {
    if (username.length < 2) setUser([]);
  }, [username]);

  // DeBounce Function
  useDebounce(
    async () => {
      await handleSearchFirebase();
    },
    [user, username],
    1000
  );

  const handleSearchFirebase = async () => {
    const input = username.trim().toLowerCase().replaceAll(' ', '');
    if (input.length < 2) {
      setUser([]);
      return;
    }
    try {
      const q = query(collection(db, 'users'), where('sUserID', '>=', input), where('sUserID', '<=', input + '\uf8ff'));
      const q2 = query(
        collection(db, 'users'),
        where('sDisplayName', '>=', input),
        where('sDisplayName', '<=', input + '\uf8ff')
      );

      const querySnapshot = await getDocs(q);
      const querySnapshot2 = await getDocs(q2);

      const data = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) data.push(doc.data());
      });
      querySnapshot2.forEach((doc) => {
        if (doc.exists()) data.push(doc.data());
      });
      // Remove Dublicate value
      const uniqueValue = [];
      data.forEach((e) => !uniqueValue.includes(e.uid) && uniqueValue.push(e.uid));
      const uniqueData = [];
      uniqueValue.forEach((e) => {
        const findValue = data.find((ex) => ex.uid === e);
        uniqueData.push(findValue);
      });

      const filterData = uniqueData.filter((d) => {
        if (d.uid === currentUser?.uid) return false;
        if (d.isDeleted) return false;
        else if (d.userToUserChat !== undefined) return d.userToUserChat;
        else return true;
      });
      setUser(filterData);
    } catch (e) {
      setErr(true);
      setUser([]);
      toast.error(e.message);
    }
  };

  const handleSearch = async (e) => {
    setUsername(e.target.value);
  };

  const handleKey = (e) => {
    if (e.code === 'Enter') handleSearchFirebase();
  };

  const handleSelect = async (d) => {
    dispatch(changeUser({ user: d, id: 'START_CHAT', sid: 'START_CHAT' }));
    setUser([]);
    setUsername('');
  };

  if (role === 'user' && !userToUserChatEnable) return <div style={{ height: '16px' }} />;

  return (
    <div className="search">
      <div className="searchForm">
        <StyledTextField
          type="text"
          onChange={handleSearch}
          placeholder="Search by Name or UserID"
          value={username}
          onKeyDown={handleKey}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon
                  onClick={handleKey}
                  style={{
                    cursor: 'pointer',
                    color: '#01B855',
                    width: '30px',
                    height: '30px',
                  }}
                />
              </InputAdornment>
            ),
            style: {
              fontSize: 16,
              fontWeight: 700,
              lineHeight: 1.5,
              color: 'rgb(99 91 91)',
              paddingLeft: 12,
            },
          }}
          style={{ width: '98%' }}
        />
      </div>
      {err && <span>User not found!</span>}
      <div style={{ flex: '1 1 0%', overflow: 'auto' }}>
        {user.length > 0 &&
          user.map((d, i) => {
            return (
              <div
                key={i}
                style={{ background: '#d1ffde' }}
                onClick={() => handleSelect(d)}
                onKeyDown={() => {}}
                className="userChat"
              >
                <DisplayAvatar uid={d?.uid} />
                <div className="userChatInfo">
                  <p style={{ color: 'black', fontSize: 17, margin: '5px 0px' }}>{d.displayName}</p>
                  {/* <p style={{ color: 'black', fontSize: 12, margin: '5px 0px' }}>{d.userID}</p> */}
                  {/* <p style={{ color: '#7B9EFD', fontSize: 12, margin: '5px 0px' }}>{d.email}</p> */}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Search;
