import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const currentFirebaseUserSlice = createSlice({
  name: 'currentFirebaseUser',
  initialState,
  reducers: {
    setCurrentFirebaseUser: (state, action) => {
      state = action.payload;
      return state;
    },
    resetCurrentFirebaseUser: () => {
      return initialState;
    },
  },
});

export const { setCurrentFirebaseUser, resetCurrentFirebaseUser } = currentFirebaseUserSlice.actions;

export default currentFirebaseUserSlice.reducer;
