import React, { useEffect, useState } from 'react';
import { Button, IconButton, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import toast from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';

import { deleteSubLabel, getSubLabelReport } from '../../services/Service';
import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import useUserTimeZone from '../../hooks/useUserTimeZone';
import Inlineloader from '../../components/Loader/InlineLoader';
import PaymentTabFilter from './PaymentTabFilter';
import CustomButton from '../../components/UI/CustomButton';
import AddCustomLabel from '../../components/Modals/AddCustomLabel';
import Iconify from '../../components/Iconify';
import ThreeDotMenu from '../../components/UI/ThreeDotMenu';
import FaqModal from '../../components/Modals/FaqModal';
import useSortHook from '../../hooks/useSortHook';

const SubLabelTab = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.login);
  const [data, setData] = useState([]);
  const { handleSort, newSortData, sortState } = useSortHook(data);
  const [addEditModal, setAddEditModal] = useState({
    open: false,
    data: null,
    display: false,
  });

  const [filterState, setFilterState] = useState({
    search: '',
    frequency: '',
    companyType: '',
    status: '',
  });
  const currencySymbol = user?.country === 'United States' ? '$' : '₹';

  const fetchData = async (newFilterState) => {
    try {
      setLoading(true);
      const res = await getSubLabelReport(newFilterState);
      setFilterState(newFilterState);
      setData(res.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filterState);
  }, []);

  const headData = ['Actions', 'Code', 'Name', 'Description', 'Budget', 'No of Sub', 'Status'];

  const onFilterApply = (newFilterState) => {
    fetchData(newFilterState);
    setFilterState({
      search: '',
      frequency: '',
      companyType: '',
      status: '',
    });
  };
  const onFilterReset = () => {
    const newFilterState = {
      dateFrom: '',
      dateTo: '',
      amountFrom: '',
      amountTo: '',
      transactionId: '',
    };
    fetchData(newFilterState);
  };

  const onDelete = async (e) => {
    try {
      const res = await deleteSubLabel(e._id, e.name);
      toast.success('Delete Successful');
      fetchData(filterState);
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const handleDoubleClick = (event, data) => {
    if (event.detail === 2) {
      setAddEditModal({
        open: true,
        data: data,
        display: true,
      });
    }
  };

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '32px', justifyContent: 'space-between' }}>
          <Typography
            variant="h4"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '24px',
              fontWeight: 700,
              color: '#3D71FF',
            }}
          >
            Subscription Label <FaqModal module="Subscriptions - Label" color="#3D71FF" />
          </Typography>
          <Tooltip title="Add new label">
            <IconButton
              onClick={() =>
                setAddEditModal({
                  open: true,
                  data: null,
                  display: false,
                })
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#3D71FF',
                height: '40px',
                width: '40px',
                '&:hover': { backgroundColor: '#3D71FF' },
              }}
            >
              <AddIcon sx={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
          {/* <PaymentTabFilter state={filterState} onFilterApply={onFilterApply} onFilterReset={onFilterReset} /> */}
        </div>
        <TableContainer sx={{ maxHeight: 600 }}>
          <CustomTableView
            isSort
            sort={{
              order: sortState.order,
              orderBy: sortState.orderBy,
              createSortHandler: handleSort,
              sortHeadMap: ['', 'code', 'name', 'description', 'budget', '', 'status'],
            }}
            headData={headData}
          >
            {!loading && data.length === 0 && <CustomTableCellForNoData message="You Don't have any data" />}
            {newSortData?.map((e, index) => {
              return (
                <TableRow key={index} onClick={(ev) => handleDoubleClick(ev, e)}>
                  <CustomTableCellForBody align="center">
                    <ThreeDotMenu
                      onDisplay={() =>
                        setAddEditModal({
                          open: true,
                          data: e,
                          display: true,
                        })
                      }
                      onEdit={() =>
                        setAddEditModal({
                          open: true,
                          data: e,
                          display: false,
                        })
                      }
                      onDelete={() => onDelete(e)}
                      disableDelete={e?.name === 'Others' ? true : e?.subscriptions?.length !== 0}
                      // disableEdit={e?.name === 'Others'}
                    />
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e?.code}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e?.name}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e?.description}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{`${currencySymbol} ${e?.budget === null ? 0 : e?.budget}`}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e?.subscriptions?.length || 0}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e?.status === true ? 'Active' : 'Inactive'}</p>
                  </CustomTableCellForBody>
                </TableRow>
              );
            })}
          </CustomTableView>
        </TableContainer>
      </div>
      <AddCustomLabel
        open={addEditModal.open}
        data={addEditModal.data}
        isDisplay={addEditModal.display}
        onEdit={() =>
          setAddEditModal((prev) => ({
            ...prev,
            display: false,
          }))
        }
        onDelete={(data) => onDelete(data)}
        onClose={() => {
          fetchData(filterState);
          setAddEditModal({
            open: false,
            data: null,
            display: false,
          });
        }}
      />
    </>
  );
};

export default SubLabelTab;
