import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Modal,
  InputAdornment,
  TextField,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';

import './HelpVideo.css';
import useWindowSize from '../../../hooks/useWindowSize';
import { getHelpVideoInventory, getGeneralKeyValueList } from '../../../services/Service';

const HelpVideo = () => {
  const [searchValue, setSearchValue] = useState('');
  const [introVideoSrc, setIntroVideoSrc] = useState('');
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [bgColor, setBgColor] = useState('#1e262f');
  const [rawData, setRawData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const onSearchChange = (value) => {
    setSearchValue(value);
    if (value.length > 2) {
      const filterRes = rawData.filter((e) => e?.videoShortDescription?.toLowerCase()?.includes(value?.toLowerCase()));
      setFilterData(filterRes);
    } else {
      setFilterData(rawData);
    }
  };

  const fetchData = async () => {
    try {
      const res = await getHelpVideoInventory({ status: true });
      if (res.status === 200) {
        setRawData(res.data.data);
        setFormattedData(res.data.formattedData);
        setFilterData(res.data.data);
      }
      getGeneralKeyValueList({ flag: 'HELP_VIDEO_LINK' }).then((res) =>
        setIntroVideoSrc(res.data?.data[0]?.data || '')
      );
      getGeneralKeyValueList({ flag: 'HELP_VIDEO_TITLE' }).then((res) => setTitle(res.data?.data[0]?.data || ''));
      getGeneralKeyValueList({ flag: 'HELP_VIDEO_SUB_TITLE' }).then((res) =>
        setSubTitle(res.data?.data[0]?.data || '')
      );
      getGeneralKeyValueList({ flag: 'HELP_VIDEO_BG_COLOR' }).then((res) => setBgColor(res.data?.data[0]?.data || ''));
    } catch (err) {
      console.log('HelpVideoInventoryBusiness fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div id="HelpVideo">
        <div className="HelpVideo-banner" style={{ backgroundColor: bgColor }}>
          <div style={{ color: 'white', display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: '26px', fontWeight: 'bold', width: '300px' }}>{title}</p>
            {/* <span style={{ fontSize: '26px', fontWeight: 'bold' }}>in 5 Minutes</span> */}
            <span style={{ fontSize: '16px' }}>{subTitle}</span>
          </div>
          <div>
            <YoutubeModal width="300px" height="200px" src={introVideoSrc} />
          </div>
        </div>
        <div style={{ display: 'flex', position: 'relative' }}>
          <div
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              left: '50%',
              borderRadius: '8px',
              width: '400px',
              maxWidth: '90vw',
              padding: '2px',
              top: '-26px',
              transform: 'translateX(-50%)',
            }}
          >
            <TextField
              value={searchValue}
              onChange={(e) => onSearchChange(e.target.value)}
              fullWidth
              size="small"
              placeholder="Search (min 3 char)"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div style={{ flex: 'none', width: '200px', marginTop: '32px', marginRight: '32px' }}>
            <NestedList list={formattedData} rawList={filterData} />
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: '32px', scrollBehavior: 'smooth' }}
          >
            {formattedData?.map((e) => {
              const uniqueNames = e?.module?.map((e2) => e2?.module);
              return uniqueNames.map((ex) => {
                const allVideo = filterData?.filter((exp) => exp?.megaModule === e?.megaModule && exp?.module === ex);
                if (allVideo.length === 0) return <></>;

                return (
                  <div style={{ width: '100%', marginTop: '16px' }}>
                    <a className="anchor" id={`${e?.megaModule}-${ex}`}>
                      {ex}
                    </a>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                      {ex} <span style={{ fontSize: '16px' }}>{`(${allVideo.length})`}</span>
                    </span>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                      {allVideo.map((ev) => (
                        <VideoCol src={ev?.youtubeURL} desc={ev?.videoShortDescription} />
                      ))}
                    </div>
                  </div>
                );
              });
            })}
            <div style={{ height: '400px', width: '100%' }} />
          </div>
        </div>
      </div>
    </>
  );
};
export default HelpVideo;

const VideoCol = ({ src, desc }) => {
  const { width } = useWindowSize();
  const mainWidth = width > 1000 ? '250px' : '120px';
  const mainHeight = width > 1000 ? '150px' : '80px';
  const mainFontSize = width > 1000 ? '14px' : '12px';

  return (
    <div style={{ width: mainWidth, backgroundColor: 'white', borderRadius: '8px', overflow: 'hidden' }}>
      <YoutubeModal width={mainWidth} height={mainHeight} src={src} />
      <p style={{ padding: '6px', height: '60px', fontSize: mainFontSize }}>{desc}</p>
    </div>
  );
};

const YoutubeModal = ({ src, width, height }) => {
  const [open, setOpen] = useState(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    displey: 'flex',
    flexDirection: 'column',
    gap: '12px',
    p: 4,
    maxHeight: '95vh',
    overflow: 'auto',
    '@media (max-width: 640px)': {
      width: '95vw',
    },
  };

  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div style={{ position: 'relative', width: width, height: height }}>
        <iframe
          style={{ position: 'absolute', top: '0px', left: '0px', zIndex: '5' }}
          width="100%"
          height="100%"
          src={`${src}&amp;controls=0`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowfullscreen
        />
        <button
          onClick={onOpen}
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            backgroundColor: 'transparent',
            zIndex: '6',
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      </div>
      <Modal
        sx={{ zIndex: 345 }}
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe
            style={{ position: 'absolute', top: '0px', left: '0px', zIndex: '19' }}
            width="100%"
            height="100%"
            src={`${src}&amp;autoplay=1`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowfullscreen
          />
        </Box>
      </Modal>
    </>
  );
};

function NestedList({ list, rawList }) {
  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      subheader={
        <ListSubheader component="div">
          <b>Mega Module</b>
        </ListSubheader>
      }
    >
      {list?.map((e, index) => {
        const moduleNames = rawList?.filter((ex) => ex.megaModule === e?.megaModule)?.map((m) => m.module);
        const uniqueNames = [];
        const newUniqueNames = [];
        moduleNames.forEach((exx) => {
          if (!uniqueNames.includes(exx)) uniqueNames.push(exx);
        });
        e?.module?.forEach((exp) => {
          if (uniqueNames.includes(exp?.module)) newUniqueNames.push(exp?.module);
        });

        if (newUniqueNames.length === 0) return <></>;
        return <SingleListItem key={index} name={e?.megaModule} logo={e?.megaModuleIcon} subList={newUniqueNames} />;
      })}
    </List>
  );
}

const SingleListItem = ({ name, logo, subList }) => {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };
  console.log('SingleListItem', logo);
  return (
    <>
      <ListItemButton onClick={handleClick}>
        {logo && (
          <img
            style={{ height: '20px', width: '20px', objectFit: 'cover', marginRight: '8px' }}
            src={logo}
            alt="logo"
          />
        )}
        <ListItemText primary={<b>{name}</b>} />
        {subList.length > 0 && open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      {subList.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {subList?.map((ex, index) => (
              <ListItemButton href={`#${name}-${ex}`} key={index} sx={{ pl: 4 }}>
                {/* <ListItemIcon>
              <FiberManualRecordIcon />
            </ListItemIcon> */}
                <ListItemText primary={ex} />
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};
