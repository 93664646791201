import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from 'firebase/auth';
import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';

// @mui
import { alpha } from '@mui/material/styles';
import { Divider, Stack, MenuItem, Avatar, IconButton, Fab, Tooltip } from '@mui/material';
import { getSignedURL } from '../../services/Service';

// components
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';
import MenuPopover from '../../components/MenuPopover';
import { logout } from '../../store/loginSlice';
import { auth, db } from '../../firebase';
import LogoutModal from '../../components/Modals/LogoutModal';
import DeleteAccount from '../../components/Modals/DeleteAccount';
import upgradeImg from '../../assets/upgrade-img.png';
import FeedbackLogModal from '../../components/Modals/FeedbackLogModal';
import FaqModalOnly from '../../components/Modals/FaqModalOnly';

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);
  const { currentUser } = useSelector((state) => state.auth);
  const [openLogOut, setOpenLogOut] = useState(false);
  const [feedbackLogOpen, setFeedbackLogOpen] = useState(false);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const [openFaq, setOpenFaq] = useState(false);
  const [profilePicSignUrl, setProfilePicSignUrl] = useState('');

  useEffect(() => {
    getSignedURL({ url: user?.profilePic })
      .then((e) => {
        setProfilePicSignUrl(e.data);
      })
      .catch((err) => {
        console.log('getSignedURL error for profilePic', err);
      });
  }, [user]);

  const getLink = () => {
    if (user?.role === 'admin') {
      return '/admin/profile';
    }

    if (user?.role === 'business') {
      return '/business/profile';
    }

    if (user?.role === 'user') {
      return '/profile';
    }
  };

  const MENU_OPTIONS = [
    // {
    //   label: 'Home',
    //   icon: '/assets/images/HomeIcon.png',
    //   linkTo: '/',
    // },
    {
      label: 'Settings',
      icon: '/assets/images/SettingsIcon.png',
      // linkTo: user?.role === 'admin' ? '/admin/profile' : user?.role === 'business' ? '/business/profile' : '/profile',
      linkTo: getLink(),
    },
  ];
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleLogout = () => {
    setOpen(null);
    dispatch(logout())
      .unwrap()
      .then(async () => {
        SuccessToast('Logout Successful!!');
        // firebase log out
        signOut(auth);
        await deleteDoc(doc(db, `online`, currentUser?.uid));
        // await localStorage.removeItem('mySTate');
        // localStorage.setItem('mySTate');
      })
      .catch((err) => {
        // ErrorToast("Something went wrong!!!")
      });
  };

  const onFeedbackLogOpen = () => {
    setFeedbackLogOpen(true);
  };
  const onFeedbackLogClose = () => {
    setFeedbackLogOpen(false);
  };
  const onFaqClickOpen = () => {
    setOpenFaq(true);
  };
  const onFaqClickClose = () => {
    setOpenFaq(false);
  };

  const onHelpClick = () => {
    navigate('/help');
  };

  return (
    <>
      <Tooltip title={`Account`}>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            p: 0,
            width: 25,
            height: 25,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <Avatar src={profilePicSignUrl} alt="photoURL" sx={{ width: 25, height: 25 }} />
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: '160px',
          p: 2,
          mt: 1.5,
          ml: 1.75,
          borderRadius: '25px',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack>
          {MENU_OPTIONS.map((option) => (
            <>
              <MenuItem
                sx={{ p: 0.5 }}
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
              >
                <img width="20px" height="20px" src={option.icon} alt="icon" style={{ marginRight: '10px' }} />
                {option.label}
              </MenuItem>
              <Divider />
            </>
          ))}
        </Stack>

        {/* {user?.role === 'user' && (
          <>
            <MenuItem sx={{ p: 0.5 }} onClick={onFeedbackLogOpen}>
              <img
                width="17px"
                height="17px"
                src="/assets/svgs/Feedback_blue.svg"
                alt="icon"
                style={{ marginRight: '10px', marginLeft: '2px' }}
              />
              Feedback Log
            </MenuItem>
            <Divider />
          </>
        )} */}
        {user?.role === 'user' && (
          <>
            <MenuItem sx={{ p: 0.5 }} onClick={onFaqClickOpen}>
              <img
                width="17px"
                height="17px"
                src="/assets/images/faq_new.jpg"
                alt="icon"
                style={{ marginRight: '10px', marginLeft: '2px' }}
              />
              FAQ's
            </MenuItem>
            <Divider />
          </>
        )}
        {user?.role === 'user' && (
          <>
            <MenuItem sx={{ p: 0.5 }} onClick={onHelpClick}>
              <img
                width="17px"
                height="17px"
                src="/assets/images/help_video_icon.png"
                alt="icon"
                style={{ marginRight: '10px', marginLeft: '2px' }}
              />
              Help Video
            </MenuItem>
            <Divider />
          </>
        )}
        {user?.role === 'user' && (
          <>
            <MenuItem sx={{ p: 0.5 }} onClick={() => setOpenDeleteAccount(true)}>
              <img
                width="17px"
                height="17px"
                src="/assets/images/delete.png"
                alt="icon"
                style={{ marginRight: '10px', marginLeft: '2px' }}
              />
              Delete Account
            </MenuItem>
            <Divider />
          </>
        )}
        {user?.role === 'user' && (
          <>
            <MenuItem sx={{ p: 0.5 }} to="/safalgift" component={RouterLink}>
              <img
                width="17px"
                height="17px"
                src={'/assets/images/safalGift_blue-m.jpg'}
                alt="icon"
                style={{ marginRight: '10px', marginLeft: '2px' }}
              />
              SafalGift
            </MenuItem>
            <Divider />
          </>
        )}
        {user?.role === 'user' && (
          <>
            <MenuItem sx={{ p: 0.5 }} to="/plans" component={RouterLink}>
              <img
                width="17px"
                height="17px"
                src={upgradeImg}
                alt="icon"
                style={{ marginRight: '10px', marginLeft: '2px' }}
              />
              Upgrade Plan
            </MenuItem>
            <Divider />
          </>
        )}

        <MenuItem sx={{ p: 0.5 }} onClick={() => setOpenLogOut(true)}>
          <img
            width="17px"
            height="17px"
            src="/assets/images/LogoutIcon.png"
            alt="icon"
            style={{ marginRight: '10px', marginLeft: '5px' }}
          />
          Logout
        </MenuItem>
      </MenuPopover>
      <LogoutModal openLogOut={openLogOut} setOpenLogOut={setOpenLogOut} setOpen={setOpen} />
      <DeleteAccount
        openDeleteAccount={openDeleteAccount}
        setOpenDeleteAccount={setOpenDeleteAccount}
        setOpen={setOpen}
      />
      {/* <FeedbackLogModal open={feedbackLogOpen} onClose={onFeedbackLogClose} /> */}
      <FaqModalOnly module={'All'} open={openFaq} onClose={onFaqClickClose} />
    </>
  );
}
