/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Avatar, Fab, FormControl, InputAdornment, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { doc, getDoc } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import useProfileImage from '../../../hooks/useProfileImage';
import {
  updateSafalRoomPrivateReq,
  updateSafalRoomPrivatePull,
  updateSafalRoomPrivateForumUser,
} from '../../../services/Service';
import Iconify from '../../../components/Iconify';
import SafalLeaderBoard_Call from '../../../assets/SafalLeaderBoard_Call.png';
import SafalLeaderBoard_Chat from '../../../assets/SafalLeaderBoard_Chat.png';
import { db } from '../../../firebase';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function InvitationModal({ open, onClose, data, list, refetch, polls, forum, refetchPollForum }) {
  const login = useSelector((state) => state.login);
  const currentUser = login?.user;
  const [searchValue, setSearchValue] = useState('');
  const [selectedType, setSelectedType] = useState('pending');
  const [selectedMode, setSelectedMode] = useState('room');
  const handleClose = async () => {
    onClose();
    setSelectedMode('room');
    setSelectedType('pending');
  };

  const filterData = list.filter((e) => e?.buddyList?.[0]?.status === selectedType);

  const updateBuddy = async (payload) => {
    try {
      const res = await updateSafalRoomPrivateReq(payload);
      if (res?.status === 200) {
        handleClose();
        refetch();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updatePoll = async (payload) => {
    try {
      const res = await updateSafalRoomPrivatePull(payload);
      if (res?.status === 200) {
        refetchPollForum();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const updateForum = async (payload) => {
    try {
      const res = await updateSafalRoomPrivateForumUser(payload);
      if (res?.status === 200) {
        refetchPollForum();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const pendingCount = list.filter((e) => e?.buddyList?.[0]?.status === 'pending');
  const acceptCount = list.filter((e) => e?.buddyList?.[0]?.status === 'accepted');
  const rejectCount = list.filter((e) => e?.buddyList?.[0]?.status === 'rejected');

  const onChangeSearch = (value) => {
    setSearchValue(value);
  };

  const filterDataNew = filterData.filter((e) => {
    return e?.name.toLowerCase().includes(searchValue.toLowerCase());
  });

  const SafalBuddyTitle =
    selectedType === 'pending'
      ? `Pending ( ${pendingCount?.length} )`
      : selectedType === 'rejected'
      ? `Rejected ( ${rejectCount?.length} )`
      : selectedType === 'accepted'
      ? `Accepted ( ${acceptCount?.length} )`
      : '';

  const onChangeMode = (value) => {
    setSelectedMode(value);
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Fab
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: '#3D71FF',
            color: 'white',
          }}
          onClick={handleClose}
          size="small"
          color="primary"
        >
          <CloseIcon />
        </Fab>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            {/* SafalRoom - {SafalBuddyTitle} */}
            Private SafalRoom
          </Typography>
          <div style={{ marginRight: '24px' }}>
            {selectedMode === 'room' && (
              <SearchStyle
                sx={{
                  width: '200px',
                }}
                value={searchValue}
                onChange={(e) => onChangeSearch(e.target.value)}
                placeholder="Search..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            )}
            {/* <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <Select
                labelId="SafalBuddy-selected"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
              <MenuItem value="pending">Pending ( {pendingCount?.length} )</MenuItem>
              <MenuItem value="rejected">Rejected ( {rejectCount?.length} )</MenuItem>
              <MenuItem value="accepted">Accepted ( {acceptCount?.length} )</MenuItem>
              </Select>
              </FormControl> */}
          </div>
        </div>
        <SelectBar value={selectedMode} onChange={onChangeMode} />
        {selectedMode === 'room' && (
          <div
            style={{
              marginTop: '12px',
              overflow: 'auto',
              height: '400px',
            }}
          >
            {filterDataNew.length > 0 ? (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                  gap: '12px',
                  marginTop: '20px',
                  overflow: 'auto',
                }}
              >
                {filterDataNew.map((e) => (
                  <UserCard currentUser={currentUser} data={e} selectedType={selectedType} updateBuddy={updateBuddy} />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>No Rooms found</span>
              </div>
            )}
          </div>
        )}
        {selectedMode === 'poll' && (
          <div
            style={{
              marginTop: '12px',
              overflow: 'auto',
              height: '400px',
            }}
          >
            {polls.length > 0 ? (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                  gap: '12px',
                  marginTop: '20px',
                  overflow: 'auto',
                }}
              >
                {polls.map((e) => (
                  <PollCard currentUser={currentUser} data={e} update={updatePoll} />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>No Polls found</span>
              </div>
            )}
          </div>
        )}
        {selectedMode === 'forum' && (
          <div
            style={{
              marginTop: '12px',
              overflow: 'auto',
              height: '400px',
            }}
          >
            {forum.length > 0 ? (
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(1, 1fr)',
                  gap: '12px',
                  marginTop: '20px',
                  overflow: 'auto',
                }}
              >
                {forum.map((e) => (
                  <ForumCard currentUser={currentUser} data={e} update={updateForum} />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>No Forums found</span>
              </div>
            )}
          </div>
        )}
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            variant="contained"
            color="error"
          >
            Close
          </Button>
          {/* <Button
              onClick={onSubmit}
              sx={{ backgroundColor: '#3d71ff', textTransform: 'capitalize', borderRadius: '30px' }}
              variant="contained"
            >
              Create
            </Button> */}
        </div>
      </Box>
    </Modal>
  );
}

const UserCard = ({ data, currentUser, selectedType, updateBuddy }) => {
  const profileImage = useProfileImage(data?.user?.profilePic);
  const fullName = `${data?.user?.firstName} ${data?.user?.lastName}`;

  return (
    <div
      style={{
        // backgroundColor: selectedType === 'pending' ? '#b4aa48' : '#c8d7ff',
        // backgroundColor: '#c8d7ff',
        // height: '60px',
        borderRadius: '12px',
        border: '1px solid #c8d7ff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
        padding: '4px 10px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Avatar sx={{ height: '40px', width: '40px' }} alt={fullName} src={profileImage} />
        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
          <p
            style={{
              // overflow: 'hidden',
              fontSize: '18px',
              color: '#001E6F',
              fontWeight: 'bold',
              // maxWidth: '135px',
              // textOverflow: 'ellipsis',
              // whiteSpace: 'nowrap',
            }}
          >
            {data?.name}
          </p>
          <span
            style={{
              fontSize: '16px',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {data?.company?.name}
            {' - '}
            <i
              style={{
                fontWeight: 'normal',
              }}
            >
              {data?.company?.companyType}
            </i>
          </span>
          <span
            style={{
              fontSize: '15px',
            }}
          >
            Invited by {fullName}
          </span>
        </div>
      </div>
      {selectedType === 'pending' && (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={() =>
              updateBuddy({
                _id: data._id,
                status: 'rejected',
                buddyListId: data?.buddyList?.[0]?._id,
                buddyId: data?.buddyList?.[0]?.buddy?._id,
              })
            }
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#A80000',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#A80000' },
            }}
            variant="contained"
            color="error"
            size="small"
          >
            Reject
          </Button>
          <Button
            onClick={() =>
              updateBuddy({
                _id: data._id,
                status: 'accepted',
                buddyListId: data?.buddyList?.[0]?._id,
                buddyId: data?.buddyList?.[0]?.buddy?._id,
              })
            }
            size="small"
            sx={{
              backgroundColor: '#025E16',
              textTransform: 'capitalize',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#025E16' },
            }}
            variant="contained"
          >
            Accept
          </Button>
        </div>
      )}
    </div>
  );
};

const PollCard = ({ data, update }) => {
  const profileImage = useProfileImage(data?.user?.profilePic);
  const fullName = `${data?.user?.firstName} ${data?.user?.lastName}`;

  return (
    <div
      style={{
        borderRadius: '12px',
        border: '1px solid #c8d7ff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
        padding: '4px 10px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Avatar
          sx={{ height: '40px', width: '40px', alignSelf: 'flex-start', marginTop: '8px' }}
          alt={fullName}
          src={profileImage}
        />
        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
          <p
            style={{
              fontSize: '18px',
              color: '#001E6F',
              fontWeight: 'bold',
            }}
          >
            {data?.roomId?.name}
          </p>
          <span
            style={{
              fontSize: '16px',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {data?.company?.name}
            {' - '}
            <i
              style={{
                fontWeight: 'normal',
              }}
            >
              {data?.company?.companyType}
            </i>
          </span>
          <span
            style={{
              fontSize: '15px',
            }}
          >
            Created by {fullName}
          </span>
          <div
            style={{ height: '1px', backgroundColor: '#c8d7ff', width: '100%', marginTop: '4px', marginBottom: '4px' }}
          />
          <b>{data?.name}</b>
          <p>
            <b>Question : </b>
            {data?.question}
          </p>
          {data?.options?.map((e, index) => (
            <p key={index}>
              <b>Option {index + 1} : </b>
              {e?.option}
            </p>
          ))}
        </div>
      </div>
      <div style={{ display: 'flex', gap: '8px', alignSelf: 'flex-start', marginTop: '8px' }}>
        <Button
          onClick={() =>
            update({
              _id: data?._id,
              status: false, // true, false
            })
          }
          sx={{
            textTransform: 'capitalize',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Reject
        </Button>
        <Button
          onClick={() =>
            update({
              _id: data?._id,
              status: true, // true, false
            })
          }
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'capitalize',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Accept
        </Button>
      </div>
    </div>
  );
};
const ForumCard = ({ data, update }) => {
  const profileImage = useProfileImage(data?.user?.profilePic);
  const fullName = `${data?.user?.firstName} ${data?.user?.lastName}`;

  return (
    <div
      style={{
        borderRadius: '12px',
        border: '1px solid #c8d7ff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
        padding: '4px 10px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Avatar
          sx={{ height: '40px', width: '40px', alignSelf: 'flex-start', marginTop: '8px' }}
          alt={fullName}
          src={profileImage}
        />
        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
          <p
            style={{
              fontSize: '18px',
              color: '#001E6F',
              fontWeight: 'bold',
            }}
          >
            {data?.roomId?.name}
          </p>
          <span
            style={{
              fontSize: '16px',
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {data?.company?.name}
            {' - '}
            <i
              style={{
                fontWeight: 'normal',
              }}
            >
              {data?.company?.companyType}
            </i>
          </span>
          <span
            style={{
              fontSize: '15px',
            }}
          >
            Created by {fullName}
          </span>
          <div
            style={{ height: '1px', backgroundColor: '#c8d7ff', width: '100%', marginTop: '4px', marginBottom: '4px' }}
          />
          <b>
            {data?.name} -{' '}
            <i
              style={{
                fontWeight: 'normal',
              }}
            >
              {data?.topic}
            </i>
          </b>
          <p>{data?.description}</p>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '8px', alignSelf: 'flex-start', marginTop: '8px' }}>
        <Button
          onClick={() =>
            update({
              _id: data?._id,
              businessStatus: 'rejected', // pending, approved, rejected
            })
          }
          sx={{
            textTransform: 'capitalize',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Reject
        </Button>
        <Button
          onClick={() =>
            update({
              _id: data?._id,
              businessStatus: 'approved', // pending, approved, rejected
            })
          }
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'capitalize',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Accept
        </Button>
      </div>
    </div>
  );
};

const SelectBar = ({ value, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '20px',
        borderRadius: '16px',
        overflow: 'hidden',
        width: 'fit-content',
        border: '1px solid #3d71ff',
      }}
    >
      <button
        onClick={() => onChange('room')}
        style={{
          border: 'none',
          backgroundColor: value === 'room' ? '#3d71ff' : 'white',
          color: value === 'room' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          width: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        Rooms
      </button>
      <button
        onClick={() => onChange('poll')}
        style={{
          border: 'none',
          backgroundColor: value === 'poll' ? '#3d71ff' : 'white',
          color: value === 'poll' ? 'white' : '#3d71ff',
          borderRight: '1px solid #3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          width: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        Polls
      </button>
      <button
        onClick={() => onChange('forum')}
        style={{
          border: 'none',
          backgroundColor: value === 'forum' ? '#3d71ff' : 'white',
          color: value === 'forum' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          width: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        Forums
      </button>
    </div>
  );
};
