import React from 'react';
import { Box, Button, Fab, Modal, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FeedbackLog from '../../sections/ReportsTabs/FeedbackLog';
import FaqModal from './FaqModal';

const FeedbackLogModal = ({ open, onClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
    // boxShadow: 24,
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            '@media (max-width: 640px)': {
              width: '95vw',
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab onClick={handleClose} size="small" color="primary" aria-label="close">
              <CloseIcon />
            </Fab>
          </Box>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '64px',
              fontSize: '24px',
              fontWeight: 700,
              color: '#3D71FF',
            }}
          >
            Feedback Log <FaqModal marginLeft="8px" module="Feedback Log" color="#3D71FF" />
          </Typography>
          <div style={{ padding: '64px', paddingTop: '8px' }}>
            <FeedbackLog />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FeedbackLogModal;
