import React, { useEffect, useState } from 'react';
import { Button, IconButton, TableContainer, TableRow, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';
import toast from 'react-hot-toast';
import moment from 'moment';

import { downloadUserPaymentHistory, getSignedURL, getUserPaymentHistory } from '../../services/Service';
import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import Inlineloader from '../../components/Loader/InlineLoader';
import PaymentTabFilter from './PaymentTabFilter';
import useSortHook from '../../hooks/useSortHook';
import ReportsTabsDownloadMenu from '../ReportsTabs/ReportsTabsDownloadMenu';

const PaymentTab = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  // const { currentUser } = useSelector((state) => state.auth);
  const { formatDateTime, formatDate2 } = useUserTimeZone();

  const { handleSort, newSortData, sortState } = useSortHook(data, ['paidDate', 'endDate']);

  const [filterState, setFilterState] = useState({
    dateFrom: '',
    dateTo: '',
    amountFrom: '',
    amountTo: '',
    transactionId: '',
  });

  const fetchData = async (newFilterState) => {
    try {
      const _filterState = { ...newFilterState };
      if (_filterState.dateFrom) {
        _filterState.dateFrom = moment(_filterState.dateFrom).add(1, 'days').toISOString();
      }
      if (_filterState.dateTo) {
        _filterState.dateTo = moment(_filterState.dateTo).add(1, 'days').toISOString();
      }

      setLoading(true);
      const res = await getUserPaymentHistory(_filterState);
      setFilterState(newFilterState);
      const dataX = res.data?.history.map((e) => ({
        invoiceId: e?.invoice?.number,
        receiptNumber: e?.invoice?.receipt_number,
        paidDate: e?.invoice?.lines?.data[0]?.period?.start,
        endDate: e?.invoice?.lines?.data[0]?.period?.end,
        amount: `${e?.invoice?.currency === 'usd' && '$'} ${(e?.invoice?.amount_paid / 100).toFixed(2)}`,
        pdfLink: e?.invoice?.invoice_pdf,
        status: e?.subscription?.status,
      }));
      setData(dataX);
      setCurrentData(res.data?.latestInvoice);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const downloadList = async (type) => {
    try {
      const res = await downloadUserPaymentHistory({ ...filterState, fileType: type });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  useEffect(() => {
    fetchData(filterState);
  }, []);

  const headData = [
    'Date of Payment',
    'Invoice number',
    'Receipt number',
    'Plan expiry date',
    'Paid Amount',
    'Status',
    'Actions',
  ];

  const onFilterApply = (newFilterState) => {
    fetchData(newFilterState);
    setFilterState({
      dateFrom: '',
      dateTo: '',
      amountFrom: '',
      amountTo: '',
      transactionId: '',
    });
  };
  const onFilterReset = () => {
    const newFilterState = {
      dateFrom: '',
      dateTo: '',
      amountFrom: '',
      amountTo: '',
      transactionId: '',
    };
    fetchData(newFilterState);
  };

  if (loading) return <Inlineloader />;

  return (
    <>
      <div style={{ width: '100%', height: '100%' }}>
        {currentData && (
          <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '18px' }}>
            <div>
              <div>
                <span>Current Plan : </span>
                <span>{currentData?.lines?.data[0]?.plan?.nickname}</span>
              </div>
              <div>
                <span>Amount : </span>
                <span>
                  ${(currentData?.lines?.data[0]?.plan?.amount / 100).toFixed(2)} /
                  {currentData?.lines?.data[0]?.plan?.interval}
                </span>
              </div>
            </div>
            <div>
              <div>
                <span>Activation date : </span>
                <span>{formatDate2(currentData?.lines?.data[0]?.period?.start * 1000)}</span>
              </div>
              <div>
                <span>Next payment date : </span>
                <span>{formatDate2(currentData?.lines?.data[0]?.period?.end * 1000)}</span>
              </div>
              <a href={currentData?.invoice_pdf} rel="noreferrer" target="_blank">
                Download latest invoice
              </a>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', alignItems: 'center', gap: '32px', justifyContent: 'space-between' }}>
          <div />
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <PaymentTabFilter state={filterState} onFilterApply={onFilterApply} onFilterReset={onFilterReset} />
            <Tooltip title="Refresh">
              <IconButton
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={() => fetchData(filterState)}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <ReportsTabsDownloadMenu downloadFile={downloadList} />
          </div>
        </div>
        {/* {loading && <Inlineloader />} */}
        {!loading && (
          <TableContainer sx={{ maxHeight: 600 }}>
            <CustomTableView
              isSort
              sort={{
                order: sortState.order,
                orderBy: sortState.orderBy,
                createSortHandler: handleSort,
                sortHeadMap: ['paidDate', 'invoiceId', 'receiptNumber', 'endDate', 'amount', 'status', ''],
              }}
              headData={headData}
            >
              {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any payment history." />}
              {newSortData?.map((e, index) => {
                return (
                  <TableRow key={index}>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>
                        {formatDateTime(e.paidDate * 1000)} {getTimeZoneAbbreviation()}
                      </p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.invoiceId}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.receiptNumber}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>
                        {formatDate2(e.endDate * 1000)} {getTimeZoneAbbreviation()}
                      </p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.amount}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.status}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <Button
                          sx={{
                            textTransform: 'capitalize',
                          }}
                          size="small"
                          variant="outlined"
                          startIcon={<DownloadIcon />}
                          onClick={() => {
                            window.open(e.pdfLink, '_blank');
                          }}
                        >
                          Invoice
                        </Button>
                      </div>
                    </CustomTableCellForBody>
                  </TableRow>
                );
              })}
            </CustomTableView>
          </TableContainer>
        )}
      </div>
    </>
  );
};

export default PaymentTab;
