import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Avatar, Chip, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { Select } from 'antd';
import { useSelector } from 'react-redux';

import { FeedMessage } from './SafalRoomFeed';
import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';
import {
  createSafalRoomPrivate,
  getSafalBuddy,
  getSignedURL,
  updateSafalRoomPrivateOwner,
} from '../../../services/Service';
import useProfileImage from '../../../hooks/useProfileImage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '16px',
  p: 3,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '', // min 3, max 50
  buddyList: [],
  company: '',
};

export default function RoomModal({ open, onClose, data, companyId, companyList, refetch }) {
  const [fieldValue, setFieldValue] = useState(initFieldValue);
  const [disabled, setDisabled] = useState(false);
  const [buddies, setBuddies] = useState([]);

  // This state is for update buddy role and status
  const [buddiesData, setBuddiesData] = useState([]);
  const { user } = useSelector((state) => state.login);

  const handleClose = () => {
    onClose();
    setBuddiesData([]);
    setFieldValue(initFieldValue);
  };

  const onSubmit = async () => {
    const comId = fieldValue.company === '' ? companyId : fieldValue.company;
    const payload = {
      ...fieldValue,
      company: comId,
    };

    try {
      setDisabled(true);
      const res = await createSafalRoomPrivate(payload);
      if (res.status === 200) {
        toast.success('Room Created');
        handleClose();
        refetch(false);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisabled(false);
    }
  };
  const onUpdate = async () => {
    const payload = {
      ...fieldValue,
      company: companyId,
      _id: data?._id,
    };
    const newBuddyList = [];
    payload.buddyList.forEach((e) => {
      const findBuddy = buddiesData?.find((ex) => e === ex?.buddy?._id);
      if (findBuddy) {
        newBuddyList.push({
          buddy: findBuddy?.buddy?._id,
          role: findBuddy?.role,
          status: findBuddy?.status,
          _id: findBuddy?._id,
        });
      } else {
        const findBuddyX = buddiesData?.find((ex) => e === ex?.buddy && ex?.newBuddy === true);
        if (findBuddyX) {
          newBuddyList.push(findBuddyX);
        } else {
          newBuddyList.push({
            buddy: e,
            role: 'DEFAULT', // can change to ADMIN, DEFAULT
            status: 'pending', // pending, accepted, rejected, kick
            newBuddy: true, // for adding new buddy
          });
        }
      }
    });
    payload.buddyList = newBuddyList;
    try {
      setDisabled(true);
      const res = await updateSafalRoomPrivateOwner(payload);
      if (res.status === 200) {
        toast.success(res?.data?.message);
        handleClose();
        refetch(true);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisabled(false);
    }
  };

  const fetchBuddies = async () => {
    try {
      const res = await getSafalBuddy({ status: 'accept' });
      if (res.status === 200) {
        const filterBuddies = formatBuddyData(res.data.data, user?._id);
        setBuddies(filterBuddies);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  useEffect(() => {
    if (open) {
      fetchBuddies();
      if (data) {
        setFieldValue({
          company: data?.company?._id, // min 3, max 50
          name: data?.name, // min 3, max 50
          buddyList: data?.buddyList?.map((e) => e?.buddy?._id) || [],
        });
        setBuddiesData(data?.buddyList);
      }
    }
  }, [open, data]);

  // const companyFullInfo = companyList?.find((e) => e?._id === companyId);

  const isSubmitDisabled =
    fieldValue.name.length < 3 || fieldValue.name.length > 50 || fieldValue.buddyList.length === 0;

  const comId = fieldValue.company === '' ? companyId : fieldValue.company;

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '8px', fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}
          >
            {data ? 'Update Private Room' : 'Create Private Room'}
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            {/* <TextField
              size="small"
              disabled
              value={companyFullInfo?.name}
              // onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
              label="Company"
              // inputProps={{ minLength: 3, maxLength: 50 }}
              // helperText={`${fieldValue.name.length}/50`}
            /> */}
            <CustomSimpleSelect
              sx={{
                width: '100%',
              }}
              disabled={data}
              label="Company"
              groupBy={(option) => option.type}
              valueKey="_id"
              value={comId}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, company: e }))}
              options={companyList?.map((e) => ({ label: e?.name, _id: e?._id, type: e?.companyType }))}
            />
            <TextField
              size="small"
              disabled={disabled}
              value={fieldValue.name}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
              label="Name"
              inputProps={{ minLength: 3, maxLength: 50 }}
              helperText={`${fieldValue.name.length}/50`}
            />
            <span style={{ marginLeft: '8px' }}>
              Buddies {fieldValue?.buddyList.length > 0 ? `(${fieldValue?.buddyList.length})` : ''}
            </span>
            <Select
              title="Buddies"
              mode="multiple"
              allowClear
              disabled={disabled}
              // style={{
              //   flex: '1',
              // }}
              variant="outlined"
              dropdownStyle={{
                zIndex: 20000,
              }}
              value={fieldValue.buddyList}
              defaultValue={fieldValue.buddyList}
              placeholder="Please select Buddies"
              onChange={(values) => setFieldValue((prev) => ({ ...prev, buddyList: values }))}
              options={buddies.map((exs) => ({ label: exs.user.userID, value: exs._id }))}
            />

            {fieldValue?.buddyList.length > 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', overflow: 'auto', height: '180px' }}>
                {fieldValue?.buddyList?.map((e, index) => (
                  <RenderBuddy
                    buddiesData={buddiesData}
                    setBuddiesData={setBuddiesData}
                    data={data}
                    key={index}
                    list={buddies}
                    id={e}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                  overflow: 'auto',
                  height: '180px',
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                No buddy found
              </div>
            )}
            <p style={{ fontSize: '14px' }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt iusto quae incidunt enim, mollitia ipsum
              natus non itaque assumenda est dicta distinctio ipsam id magni tempore eligendi earum, quis quaerat!
            </p>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'space-between' }}>
            <div />
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={handleClose}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  backgroundColor: '#ff4842',
                  ':hover': { backgroundColor: '#d40700' },
                }}
                variant="contained"
              >
                Close
              </Button>
              {!data && (
                <Button
                  onClick={onSubmit}
                  disabled={isSubmitDisabled}
                  sx={{
                    borderRadius: '30px',
                    textTransform: 'capitalize',
                    color: '#FFFFFF',
                    backgroundColor: '#3D71FF',
                    '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                  }}
                  variant="contained"
                >
                  Submit
                </Button>
              )}
              {data && (
                <Button
                  onClick={onUpdate}
                  sx={{
                    borderRadius: '30px',
                    textTransform: 'capitalize',
                    color: '#FFFFFF',
                    backgroundColor: '#3D71FF',
                    '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                  }}
                  variant="contained"
                >
                  Update
                </Button>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

const RenderBuddy = ({ list, id, buddiesData, data, setBuddiesData }) => {
  const login = useSelector((state) => state?.login);
  const findBuddy = list?.find((e) => e?._id === id);
  const userProfileImg = findBuddy?.user?.profilePic || '';
  const userFullName = `${findBuddy?.user?.firstName || ''} ${findBuddy?.user?.lastName || ''}`;

  const profileImage = useProfileImage(userProfileImg);
  const [selectedRole, setSelectedRole] = useState('DEFAULT');

  const findDataBuddy = data && formatBuddyDataForUpdate(buddiesData, login?.user?._id, id);

  // const fullName = login?.user?._id === user?._id ? 'You' : `${user?.firstName} ${user?.lastName}`;

  const onChangeRole = (newRole) => {
    setSelectedRole(newRole);
    setBuddiesData((prev) => {
      const newPrev = [...prev];
      const findBuddyIndex = newPrev.findIndex((e) => e?.buddy?._id === id);
      if (findBuddyIndex !== -1) {
        newPrev[findBuddyIndex].role = newRole;
      } else {
        const findBuddyIndex = newPrev.findIndex((e) => e?.buddy === findBuddy?._id && e?.newBuddy === true);
        if (findBuddyIndex !== -1) {
          newPrev[findBuddyIndex].role = newRole;
        } else {
          newPrev.push({
            buddy: findBuddy?._id,
            role: newRole, // can change to ADMIN, DEFAULT
            status: 'pending', // pending, accepted, rejected, kick
            newBuddy: true, // for adding new buddy
          });
        }
      }
      return newPrev;
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        paddingRight: '16px',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ height: '40px', width: '40px' }} alt={userFullName} src={profileImage} />
        <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
          <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#001E6F' }}>{userFullName}</span>
          <span style={{ fontSize: '14px' }}>@ {findBuddy?.user?.userID}</span>
        </div>
      </div>
      {data && (
        <div
          style={{
            display: 'flex',
            gap: '4px',
            alignItems: 'center',
          }}
        >
          {findDataBuddy?.status && (
            <Chip sx={{ backgroundColor: 'green', color: 'white' }} size="small" label={findDataBuddy?.status} />
          )}
          {/* <Chip sx={{ backgroundColor: 'red', color: 'white' }} size="small" label="Rejected" /> */}
          <select
            value={findDataBuddy ? findDataBuddy?.role : selectedRole}
            onChange={(e) => onChangeRole(e.target.value)}
            style={{ width: '80px', height: '24px' }}
          >
            <option value="DEFAULT">Default</option>
            <option value="ADMIN">Admin</option>
          </select>
        </div>
      )}
    </div>
  );
};

const formatBuddyData = (buddyList, myId) => {
  const resultU = [];
  const result = [];

  buddyList.forEach((e) => {
    if (e?.sender?._id === myId) {
      if (!result.includes(e?.receiver?.userID)) {
        result.push(e?.receiver?.userID);
        resultU.push({
          ...e,
          user: e?.receiver,
        });
      }
    }
    if (e?.receiver?._id === myId) {
      if (!result.includes(e?.sender?.userID)) {
        result.push(e?.sender?.userID);
        resultU.push({
          ...e,
          user: e?.sender,
        });
      }
    }
  });
  return resultU;
};
const formatBuddyDataForUpdate = (buddyList, myId, buddyId) => {
  const resultU = buddyList?.find((e) => e?.buddy?._id === buddyId);
  const buddyUser = resultU?.buddy?.sender?._id === myId ? resultU?.buddy?.receiver : resultU?.buddy?.sender;
  if (resultU) resultU.buddyUser = buddyUser;
  return resultU;
};
