import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Popover } from '@mui/material';
import { setCustomCompany } from '../../store/businessSlice';
import { GetCustomCompaniesResponse, RejectCustomCompany } from '../../services/Service';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';

const AcceptRejectMenu = ({ open, setopen, handleClickOpen, data }) => {
  const handleClosePopover = () => {
    setopen(null);
  };
  const menuOpen = Boolean(open);
  const dispatch = useDispatch();

  const handleReject = () => {
    RejectCustomCompany({ id: data._id })
      .then((res) => {
        if (res.data.success === true) {
          GetCustomCompaniesResponse().then((res) => {
            if (res.data.success === true) {
              dispatch(setCustomCompany({ CustomCopanyData: res.data.data }));
            }
          });
          SuccessToast('Rejected');
          handleClosePopover();
        }
      })
      .catch((err) => {
        ErrorToast('Error !!');
      });
  };

  const id = menuOpen ? 'simple-popover' : undefined;

  return (
    <>
      <Popover
        id={id}
        anchorEl={open}
        open={menuOpen}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: 10 }}>
          <Button
            onClick={() => {
              handleClosePopover();
              handleClickOpen(true);
            }}
            style={{ textAlign: 'left', marginBottom: 10 }}
          >
            Accept
          </Button>{' '}
          <br />
          <Button onClick={handleReject}>Reject</Button>
        </div>
      </Popover>
    </>
  );
};

export default AcceptRejectMenu;
