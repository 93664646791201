import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Image } from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

import {
  getBannerList,
  GetcompaniesResponse,
  getSafalRoomPublic,
  updateSafalRoomDefaultCompany,
} from '../../../services/Service';
import Inlineloader from '../../../components/Loader/InlineLoader';
import buddyProfileIcon from '../../../assets/buddyProfile.png';
import SubscribersCountChart from './SubscribersCountChart';
import SafalRoomFeed from './SafalRoomFeed';
import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';
import SafalRoomPolls from './SafalRoomPolls';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';
import SafalRoomCommunityForum from './SafalRoomCommunityForum';
import SafalRoomAbout from './SafalRoomAbout';
import CompanySelectModal from './CompanySelectModal';
import FaqModal from '../../../components/Modals/FaqModal';
import SideBanner from './SideBanner';
import AnalyticsClickImpression from '../../../components/AnalyticsClickImpression';

// "meta": {
//     "publicFeelings": 1.24,
//     "total": 17,
//     "totalAmount": 4742,
//     "activeCount": 17,
//     "cancelCount": 0
// }

const SafalRoom = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramCompany = searchParams.get('company');
  const [bannerList, setBannerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comLoading, setComLoading] = useState(false);
  const [safalRoomData, setSafalRoomData] = useState(null);
  const [safalRoomYearlyHistory, setSafalRoomYearlyHistory] = useState(null);
  const [safalRoomMonthlyHistory, setSafalRoomMonthlyHistory] = useState(null);
  const [safalRoomMeta, setSafalRoomMeta] = useState(null);
  const [selectedMainSection, setSelectedMainSection] = useState('feed'); // feed, poll, forum
  const [companyList, setCompanyList] = useState([]);
  const { user } = useSelector((state) => state.login);
  const safalRoomDefaultCompany = user?.safalRoomDefaultCompany;
  const [companyId, setCompanyId] = useState(paramCompany || safalRoomDefaultCompany);
  const [defaultRoomCompany, setDefaultRoomCompany] = useState(''); // null, "65e2d351f65e3930e7009c59", ""

  const navigate = useNavigate();

  const fetchExtraData = async () => {
    try {
      const res = await getBannerList('safalRoomPublic');
      if (res.status === 200) {
        setBannerList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const fetchCompanyData = async () => {
    try {
      setComLoading(true);
      const res = await GetcompaniesResponse();
      if (res.status === 200) {
        setCompanyList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setComLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const res = await getSafalRoomPublic({ companyId: companyId });
      if (res.status === 200) {
        if (res.data?.success) {
          setCompanyId(res?.data?.data?._id);
          setSafalRoomData(res.data.data);
          setSafalRoomYearlyHistory(res.data.yearlyHistory);
          setSafalRoomMonthlyHistory(res.data.monthlyHistory);
          setSafalRoomMeta(res.data.meta);
          setDefaultRoomCompany(res?.data?.defaultCompany || '');
        } else if (res.data.message === 'invalid company') {
          setDefaultRoomCompany(null);
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const refresh = async () => {
    setLoading(true);
    await fetchData();
    await fetchExtraData();
    setLoading(false);
  };

  useEffect(() => {
    fetchCompanyData();
  }, []);

  useEffect(() => {
    refresh();
  }, [companyId]);

  const onChangeMainSection = (newValue) => {
    setSelectedMainSection(newValue);
  };

  const onChangeCompany = (id) => {
    setCompanyId(id);
  };

  const onSetDefaultCompany = async () => {
    try {
      const res = await updateSafalRoomDefaultCompany({ id: companyId });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        setDefaultRoomCompany(companyId);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const onSelectCompanyByModal = (id) => {
    setDefaultRoomCompany(id);
    setCompanyId(id);
  };
  const onCloseCompanyByModal = () => {
    navigate(-1);
  };

  const onRoomPrivatePublic = (type) => {
    if (type === 'public') {
      navigate(`/safalroom?company=${companyId}`);
    } else {
      navigate(`/safalroom-private?company=${companyId}`);
    }
  };

  //   split banner array into 2
  const banner01 = bannerList.slice(0, Math.round(bannerList.length / 2));
  const banner02 = bannerList.slice(Math.round(bannerList.length / 2), bannerList.length);

  const companyListForMentions = companyList.map((item) => ({
    display: item?.name,
    id: item?._id,
    type: item?.companyType,
  }));

  if (loading || comLoading) return <Inlineloader />;
  return (
    <>
      <Helmet>
        <title>SafalRoom - SafalSubscriptions</title>
      </Helmet>
      <div id="SafalRoom" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 'none',
            marginBottom: '8px',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
              Public SafalRoom {safalRoomData?.name && `- ${safalRoomData?.name}`}
            </Typography>
            <FaqModal module="SafalRoom - Public" color="#3D71FF" />
          </div>
          <SelectBarPrivate value="public" onChange={onRoomPrivatePublic} />
        </div>

        {/* Carousel - Banner */}
        {bannerList.length !== 0 && (
          <div style={{ display: 'flex', gap: '16px' }}>
            <div style={{ position: 'relative', flex: 'none', width: '50%' }}>
              <Carousel
                autoPlay
                infiniteLoop
                showStatus={false}
                showArrows
                showThumbs={false}
                renderArrowNext={(clickHandler, hasNext, label) => (
                  <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
                )}
                renderArrowPrev={(clickHandler, hasPrev, label) => (
                  <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
                )}
                // onChange={(i) => localStorage.setItem('banner', i)}
                showIndicators
                axis="horizontal"
              >
                {banner01.map((e, index) => (
                  <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                    {e.type === 'image' ? (
                      <AnalyticsClickImpression data={e} id={e?._id} page="SafalRoomPublic">
                        <img
                          alt="banner01"
                          style={{ objectFit: 'cover', borderRadius: '16px', 'pointer-events': 'all' }}
                          height="150px"
                          width="100%"
                          src={e.value}
                        />
                      </AnalyticsClickImpression>
                    ) : (
                      <AnalyticsClickImpression data={e} id={e?._id} page="SafalRoomPublic">
                        <video
                          style={{ maxHeight: '150px', objectFit: 'cover', borderRadius: '16px' }}
                          width="100%"
                          height="150px"
                          autoPlay="true"
                          muted
                        >
                          <source src={e.value} />
                        </video>
                      </AnalyticsClickImpression>
                    )}
                  </a>
                ))}
              </Carousel>
            </div>
            <div style={{ position: 'relative', flex: 'none', width: '50%' }}>
              <Carousel
                autoPlay
                infiniteLoop
                showStatus={false}
                showArrows
                showThumbs={false}
                renderArrowNext={(clickHandler, hasNext, label) => (
                  <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
                )}
                renderArrowPrev={(clickHandler, hasPrev, label) => (
                  <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
                )}
                // onChange={(i) => localStorage.setItem('banner', i)}
                showIndicators
                axis="horizontal"
              >
                {banner02.map((e, index) => (
                  <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                    {e.type === 'image' ? (
                      <AnalyticsClickImpression data={e} id={e?._id} page="SafalRoomPublic">
                        <img
                          alt="banner02"
                          style={{ objectFit: 'cover', borderRadius: '16px', 'pointer-events': 'all' }}
                          height="150px"
                          width="100%"
                          src={e.value}
                        />
                      </AnalyticsClickImpression>
                    ) : (
                      <AnalyticsClickImpression data={e} id={e?._id} page="SafalRoomPublic">
                        <video
                          style={{ maxHeight: '150px', objectFit: 'cover', borderRadius: '16px' }}
                          width="100%"
                          height="150px"
                          autoPlay="true"
                          muted
                        >
                          <source src={e.value} />
                        </video>
                      </AnalyticsClickImpression>
                    )}
                  </a>
                ))}
              </Carousel>
            </div>
          </div>
        )}
        {/* Search & Action bar */}
        <div
          style={{
            marginTop: '12px',
            flex: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <CustomSimpleSelect
              sx={{
                width: '220px',
              }}
              groupBy={(option) => option.type}
              valueKey="_id"
              value={companyId}
              onChange={(e) => onChangeCompany(e)}
              options={companyList?.map((e) => ({ label: e?.name, _id: e?._id, type: e?.companyType }))}
            />
            <Tooltip
              title={
                defaultRoomCompany === safalRoomData?._id
                  ? 'This is your default SafalRoom'
                  : 'Set this SafalRoom is default'
              }
            >
              <button
                style={{ border: 'none', backgroundColor: 'transparent', padding: '2px' }}
                disabled={defaultRoomCompany === safalRoomData?._id}
                onClick={onSetDefaultCompany}
              >
                {defaultRoomCompany === safalRoomData?._id ? (
                  <img
                    style={{ height: '26px', width: '26px', objectFit: 'contain' }}
                    src="/assets/images/SafalRoomDefault2.png"
                    alt="Set this SafalRoom is default"
                  />
                ) : (
                  <img
                    style={{ height: '26px', width: '26px', objectFit: 'contain' }}
                    src="/assets/images/SafalRoomDefault.png"
                    alt="Set this SafalRoom is default"
                  />
                )}
              </button>
            </Tooltip>
            {/* <CustomSearch 
            value={searchValue} 
            onChange={(e) => onSearchChange(e.target.value)} 
            placeholder="Search" /> */}
            {/* <CustomSortMenu
              sortState={sortState}
              onChange={onSortChange}
              sort={[
                { value: 'points', label: 'SafalPoints' },
                { value: 'name', label: 'Product Name' },
              ]}
            /> */}
            {/* <OffersDownloadMenu downloadFile={downloadOffersList} /> */}
            {/* <UserOffersFilterMenu state={filterState} onFilterApply={onFilterApply} /> */}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <Tooltip title="Refresh">
              <IconButton
                size="small"
                sx={{
                  flex: 'none',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={refresh}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="SafalBuddy">
              <IconButton
                size="small"
                sx={{
                  flex: 'none',
                  color: '#FFFFFF',
                  backgroundColor: 'transparent',
                  '&:hover': { backgroundColor: 'white', color: '#FFFFFF' },
                }}
                onClick={() => {
                  navigate('/safalbuddy');
                }}
              >
                <img
                  src={buddyProfileIcon}
                  alt="buddyProfileIcon"
                  style={{ width: '28px', height: '28px', objectFit: 'cover' }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {/* main section */}
        <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
          <div
            style={{
              width: '380px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <CompanySideInfo safalRoomData={safalRoomData} safalRoomMeta={safalRoomMeta} />
            <SubscribersCountChart
              good={safalRoomMeta?.publicFeelingsGood || 0}
              bad={safalRoomMeta?.publicFeelingsBad || 0}
              monthlyHistory={safalRoomMonthlyHistory}
              yearlyHistory={safalRoomYearlyHistory}
            />
          </div>
          <div
            style={{
              display: 'flex',
              padding: '12px',
              backgroundColor: 'white',
              borderRadius: '16px',
              border: '1px solid #BCCEFF',
              flex: 1,
              gap: '8px',
            }}
          >
            {safalRoomData?._id && (
              <div
                style={{
                  backgroundColor: 'white',
                  height: '780px',
                  borderRadius: '12px',
                  padding: '8px',
                  marginTop: '14px',
                  // border: '1px solid #BCCEFF',
                  flex: 1,
                  position: 'relative',
                }}
              >
                <SelectBar value={selectedMainSection} onChange={onChangeMainSection} />
                {selectedMainSection === 'feed' && (
                  <SafalRoomFeed companyId={safalRoomData?._id} companyListForMentions={companyListForMentions} />
                )}
                {selectedMainSection === 'poll' && <SafalRoomPolls companyId={safalRoomData?._id} />}
                {selectedMainSection === 'forum' && (
                  <SafalRoomCommunityForum
                    companyId={safalRoomData?._id}
                    companyListForMentions={companyListForMentions}
                  />
                )}
                {selectedMainSection === 'about' && <SafalRoomAbout data={safalRoomData} />}
              </div>
            )}
            <SideBanner type="safalRoomPublicSide" />
          </div>
        </div>
      </div>
      <CompanySelectModal
        onSelect={onSelectCompanyByModal}
        data={companyList}
        open={defaultRoomCompany === null}
        onClose={onCloseCompanyByModal}
      />
    </>
  );
};

export default SafalRoom;

const RenderArrowNext = ({ clickHandler, hasNext, label }) => {
  return (
    <IconButton
      disabled={!hasNext}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        right: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );
};
const RenderArrowPrev = ({ clickHandler, hasPrev, label }) => {
  return (
    <IconButton
      disabled={!hasPrev}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        left: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
  );
};

const MetaCard = ({ title, value }) => {
  return (
    <div
      style={{
        // backgroundColor: '#BCCEFF',
        backgroundColor: 'white',
        color: '#3d71ff',
        height: '60px',
        flex: 1,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '6px',
        border: '1px solid #3d71ff',
      }}
    >
      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{title}</span>
      <span style={{ fontSize: '14px', fontWeight: 'bold', alignSelf: 'flex-end' }}>{value}</span>
    </div>
  );
};

const CompanySideInfo = ({ safalRoomData, safalRoomMeta }) => {
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('default', { month: 'short' });
  // const currentYear = currentDate.getFullYear();
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        padding: '16px',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #BCCEFF',
      }}
    >
      <span style={{ color: '#3D71FF', fontSize: '18px', fontWeight: 'bold' }}>Welcome to</span>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <div style={{ display: 'flex', gap: '12px' }}>
          <img
            src={safalRoomData?.logo}
            alt={safalRoomData?.name}
            style={{
              backgroundColor: 'white',
              height: '60px',
              objectFit: 'cover',
              borderRadius: '8px',
              aspectRatio: 2 / 1,
              boxShadow: '0px 4px 4px 0px #00000040',
            }}
          />
          <div>
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{safalRoomData?.name}</span>
            <p style={{ width: '200px', fontSize: '13px', color: '#3D71FF' }}>{safalRoomData?.companyType}</p>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '8px', width: '100%' }}>
          <MetaCard title="Active Subscribers" value={`${safalRoomMeta?.activeCount || 0} Nos`} />
          <MetaCard title={`Subscribers - ${currentMonth}`} value={`${safalRoomMeta?.currentMonthSubscribers} Nos`} />
        </div>
      </div>
    </div>
  );
};

const width = `calc(100% / 4)`;
const SelectBar = ({ value, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '-20px',
        marginLeft: '-8px',
        borderRadius: '16px',
        overflow: 'hidden',
        border: '1px solid #3d71ff',
      }}
    >
      <button
        onClick={() => onChange('feed')}
        style={{
          border: 'none',
          backgroundColor: value === 'feed' ? '#3d71ff' : 'white',
          color: value === 'feed' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          minWidth: '150px',
          width: width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'feed' ? '/assets/images/SafalRoomFeed_white.png' : '/assets/images/SafalRoomFeed.png'}
          alt="Feed"
        />
        <span id="feed-count-public">Feed</span>
      </button>
      <button
        onClick={() => onChange('forum')}
        style={{
          border: 'none',
          backgroundColor: value === 'forum' ? '#3d71ff' : 'white',
          borderLeft: '1px solid #3d71ff',
          borderRight: '1px solid #3d71ff',
          color: value === 'forum' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          minWidth: '150px',
          width: width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'forum' ? '/assets/images/SafalRoomForum_white.png' : '/assets/images/SafalRoomForum.png'}
          alt="Community Forum"
        />
        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Community Forum</span>
      </button>
      <button
        onClick={() => onChange('poll')}
        style={{
          border: 'none',
          backgroundColor: value === 'poll' ? '#3d71ff' : 'white',
          color: value === 'poll' ? 'white' : '#3d71ff',
          borderRight: '1px solid #3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          minWidth: '150px',
          width: width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'poll' ? '/assets/images/SafalRoomPolls_white.jpg' : '/assets/images/SafalRoomPolls.png'}
          alt="Polls"
        />
        <span>Polls</span>
      </button>
      <button
        onClick={() => onChange('about')}
        style={{
          border: 'none',
          backgroundColor: value === 'about' ? '#3d71ff' : 'white',
          color: value === 'about' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          minWidth: '150px',
          width: width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'about' ? '/assets/images/SafalRoomabout_white.jpg' : '/assets/images/SafalRoomabout.png'}
          alt="About"
        />
        <span>About</span>
      </button>
    </div>
  );
};

export const SelectBarPrivate = ({ value, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        // marginLeft: '-8px',
        borderRadius: '16px',
        overflow: 'hidden',
        border: '1px solid #3d71ff',
      }}
    >
      <button
        onClick={() => onChange('public')}
        style={{
          border: 'none',
          backgroundColor: value === 'public' ? '#3d71ff' : 'white',
          color: value === 'public' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '4px',
          cursor: 'pointer',
          width: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        {/* <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'feed' ? '/assets/images/SafalRoomFeed_white.png' : '/assets/images/SafalRoomFeed.png'}
          alt="Feed"
        /> */}
        <span>Public</span>
      </button>
      <button
        onClick={() => onChange('private')}
        style={{
          border: 'none',
          backgroundColor: value === 'private' ? '#3d71ff' : 'white',
          color: value === 'private' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '4px',
          cursor: 'pointer',
          width: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        {/* <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'about' ? '/assets/images/SafalRoomabout_white.jpg' : '/assets/images/SafalRoomabout.png'}
          alt="About"
        /> */}
        <span>Private</span>
      </button>
    </div>
  );
};
