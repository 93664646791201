import { Box, Button, Fab, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DeletAllResponse, GetsubsResponse } from '../../services/Service';
import { deleteSubscription, setSubscriptions } from '../../store/subscriptionSlice';
import SuccessToast from '../Toast/Success';
import ErrorToast from '../Toast/Error';

const DeleteModal = ({
  openDeleteModal,
  setOpenDelete,
  onCloseEdit,
  id,
  setSelected,
  subNames,
  setDeleteSubNames,
  pagination,
}) => {
  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
    // boxShadow: 24,
  };

  const handleDelete = (selectedIDs) => {
    // { id: selectedIDs, event: `You Deleted ${selectedIDs}` }
    const valuesToBeSent = { id: selectedIDs, event: { call: 'DeleteSub', subNames: subNames } };
    DeletAllResponse(valuesToBeSent)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(deleteSubscription(selectedIDs));
          setSelected([]);
          onCloseEdit();
          setDeleteSubNames([]);
          GetsubsResponse(pagination?.page ?? 1, pagination?.limit ?? 500).then((res) => {
            if (res.data.success === true) {
              // setCount(Math.ceil(res.data.pagination[0].totalItems / pagination.limit));
              const totalItemsX = res.data.pagination[0]?.totalItems || 0;
              dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: totalItemsX }));
            }
          });
          SuccessToast('Succesfully deleted');
        }
      })
      .catch((err) => {
        ErrorToast(err.message);
      });
  };
  console.log('subNamessubNames', subNames);
  return (
    <>
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 600,
            height: 210,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '16px',
            paddingTop: '6px',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', top: '6px', right: '6px', position: 'absolute' }}>
            <Fab onClick={() => setOpenDelete(false)} size="small" color="primary" aria-label="add">
              <CloseIcon />
            </Fab>
          </Box>
          <span style={{ flex: 'none', fontSize: '26px', fontWeight: 700, color: '#3D71FF', textAlign: 'center' }}>
            Delete
          </span>
          <div
            style={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!Array.isArray(subNames) || subNames.length <= 1 ? (
              <Typography
                id="alert-dialog-title"
                sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}
              >
                Are you sure you want to delete the subscription {subNames} ?
              </Typography>
            ) : (
              <Typography
                id="alert-dialog-title"
                sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}
              >
                Are you sure you want to delete these {Array.isArray(subNames) && subNames.length} subscriptions?
              </Typography>
            )}

            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'center',
              }}
            >
              <Box sx={{ display: 'flex', mt: 1 }}>
                <Button
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenDelete(false);
                  }}
                >
                  No
                </Button>
              </Box>
              <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
                <Button
                  sx={{
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(id[0]);
                    setOpenDelete(false);
                  }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteModal;
