import { Box, Button, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckBoxForUnlimited from './CheckBoxForUnlimited';
import ErrorToast from '../../components/Toast/Error';
import SuccessToast from '../../components/Toast/Success';
import { updateCallLimit } from '../../services/Service';
import { fetchCallLimit, setMemory } from '../../store/businessSlice';

const AudioVideoCall = () => {
  const { callLimit } = useSelector((state) => state.business);
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();
console.log(callLimit,"callLimit")
  useEffect(() => {
    dispatch(fetchCallLimit());
  }, []);
  const handleChange = (name, value) => {
    setInitialValues({
      ...initialValues,
      [name]: value,
    });
  };
  useEffect(() => {

    setInitialValues({
      betaUserAudio: callLimit[0]?.audio || '',
      betaUserVideo: callLimit[0]?.video || '',
      freeUserAudio: callLimit[1]?.audio|| '',
      freeUserVideo: callLimit[1]?.video|| '',
      paidUserAudio: callLimit[2]?.audio|| '',
      paidUserVideo: callLimit[2]?.video|| '',
      premiumUserAudio: callLimit[3]?.audio|| '',
      premiumUserVideo: callLimit[3]?.video|| '',
    })
  }, [callLimit]);


  const TextEmailsForm = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      console.log('this is TextEmailsForm values => ', values);
      const res = await updateCallLimit(values);
      if (res) {
        SuccessToast(res.data.message);
      } else {
        ErrorToast('Failed to update');
      }
    },
  });

  return (
    <Box sx={{ mt: 3, ml: 3 }}>
      <FormikProvider value={TextEmailsForm} validateOnMount>
        <form onSubmit={TextEmailsForm.handleSubmit}>
          <Stack direction="row" spacing={5}>
            <Box width={'50%'}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography>Audio : </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Free User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.freeUserAudio && Boolean(TextEmailsForm.errors.freeUserAudio)}
                  >
                    <Field
                      as={TextField}
                      name="freeUserAudio"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values?.freeUserAudio}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched?.freeUserAudio && Boolean(TextEmailsForm.errors?.freeUserAudio)}
                      helperText={TextEmailsForm.touched?.freeUserAudio && TextEmailsForm.errors?.freeUserAudio}
                    />
                  </FormControl>
                  <CheckBoxForUnlimited name="freeUserAudio" checked={TextEmailsForm.values?.freeUserAudio} onChange={handleChange} />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Basic paid User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.paidUserAudio && Boolean(TextEmailsForm.errors.paidUserAudio)}
                  >
                    <Field
                      as={TextField}
                      name="paidUserAudio"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values?.paidUserAudio}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched?.paidUserAudio && Boolean(TextEmailsForm.errors?.paidUserAudio)}
                      helperText={TextEmailsForm.touched?.paidUserAudio && TextEmailsForm.errors?.paidUserAudio}
                    />
                  </FormControl>
                  <CheckBoxForUnlimited name="paidUserAudio" checked={TextEmailsForm.values?.paidUserAudio} onChange={handleChange}/>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Premium User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.premiumUserAudio && Boolean(TextEmailsForm.errors.premiumUserAudio)}
                  >
                    <Field
                      as={TextField}
                      name="premiumUserAudio"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values?.premiumUserAudio}
                      onChange={TextEmailsForm.handleChange}
                      error={
                        TextEmailsForm.touched?.premiumUserAudio && Boolean(TextEmailsForm.errors?.premiumUserAudio)
                      }
                      helperText={TextEmailsForm.touched?.premiumUserAudio && TextEmailsForm.errors?.premiumUserAudio}
                    />
                  </FormControl>
                  <CheckBoxForUnlimited name="premiumUserAudio" checked={TextEmailsForm.values?.premiumUserAudio} onChange={handleChange}/>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Beta User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.betaUserAudio && Boolean(TextEmailsForm.errors.betaUserAudio)}
                  >
                    <Field
                      as={TextField}
                      name="betaUserAudio"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values?.betaUserAudio}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched?.betaUserAudio && Boolean(TextEmailsForm.errors?.betaUserAudio)}
                      helperText={TextEmailsForm.touched?.betaUserAudio && TextEmailsForm.errors?.betaUserAudio}
                    />
                  </FormControl>
                  <CheckBoxForUnlimited name="betaUserAudio" checked={TextEmailsForm.values?.betaUserAudio} onChange={handleChange}/>
                </Stack>
              </Grid>
            </Box>
            <Box width={'50%'}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography>Video : </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Free User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.freeUserVideo && Boolean(TextEmailsForm.errors.freeUserVideo)}
                  >
                    <Field
                      as={TextField}
                      name="freeUserVideo"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values?.freeUserVideo}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched?.freeUserVideo && Boolean(TextEmailsForm.errors?.freeUserVideo)}
                      helperText={TextEmailsForm.touched?.freeUserVideo && TextEmailsForm.errors?.freeUserVideo}
                    />
                  </FormControl>
                  <CheckBoxForUnlimited name="freeUserVideo" checked={TextEmailsForm.values?.freeUserVideo} onChange={handleChange}/>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Basic paid User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.paidUserVideo && Boolean(TextEmailsForm.errors.paidUserVideo)}
                  >
                    <Field
                      as={TextField}
                      name="paidUserVideo"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values?.paidUserVideo}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched?.paidUserVideo && Boolean(TextEmailsForm.errors?.paidUserVideo)}
                      helperText={TextEmailsForm.touched?.paidUserVideo && TextEmailsForm.errors?.paidUserVideo}
                    />
                  </FormControl>
                  <CheckBoxForUnlimited name="paidUserVideo" checked={TextEmailsForm.values?.paidUserVideo} onChange={handleChange}/>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Premium User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.premiumUserVideo && Boolean(TextEmailsForm.errors.premiumUserVideo)}
                  >
                    <Field
                      as={TextField}
                      name="premiumUserVideo"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values?.premiumUserVideo}
                      onChange={TextEmailsForm.handleChange}
                      error={
                        TextEmailsForm.touched?.premiumUserVideo && Boolean(TextEmailsForm.errors?.premiumUserVideo)
                      }
                      helperText={TextEmailsForm.touched?.premiumUserVideo && TextEmailsForm.errors?.premiumUserVideo}
                    />
                  </FormControl>
                  <CheckBoxForUnlimited name="premiumUserVideo" checked={TextEmailsForm.values?.premiumUserVideo} onChange={handleChange}/>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Beta User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.betaUserVideo && Boolean(TextEmailsForm.errors.betaUserVideo)}
                  >
                    <Field
                      as={TextField}
                      name="betaUserVideo"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values?.betaUserVideo}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched?.betaUserVideo && Boolean(TextEmailsForm.errors?.betaUserVideo)}
                      helperText={TextEmailsForm.touched?.betaUserVideo && TextEmailsForm.errors?.betaUserVideo}
                    />
                  </FormControl>
                  <CheckBoxForUnlimited name="betaUserVideo" checked={TextEmailsForm.values?.betaUserVideo} onChange={handleChange}/>
                </Stack>
              </Grid>
            </Box>
          </Stack>

          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              // type="submit"
              onClick={TextEmailsForm.handleSubmit}
              disabled={!(TextEmailsForm.isValid && TextEmailsForm.dirty)}
              sx={{
                width: '170px',
                height: '45px',
                bgcolor: '#19C25F',
                borderRadius: '30px',
                textTransform: 'none',
                mt: '25px',
                boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
              }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default AudioVideoCall;
