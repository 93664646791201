import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import { addAdvertisementpage, uploadAdvertisementImages, updateAdvertisementPage } from '../../../services/Service';
import RectangleDrawer from '../../../components/RectangleDrawer';
import AddAdPriceModal from './AddAdPriceModal';
import AdPriceModal from './AdPriceModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const sectionsList = [
  'Top',
  'Top-Left',
  'Top-Right',
  'Bottom',
  'Bottom-Left',
  'Bottom-Right',
  'Left',
  'Left-Top',
  'Left-Bottom',
  'Right',
  'Right-Top',
  'Right-Bottom',
];

const initFileFieldValue = {
  type: 'web',
  name: '',
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  isSelected: false,
};
const initFieldValue = {
  pageName: '',
  imageUrlWeb: '',
  imageUrlMobile: '',
  sectionsWeb: [],
  sectionsMobile: [],
  status: true,
};

export default function AddAdvertisementPageModal({ open, onClose, data, isDisplay, moduleList }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [openPriceModal, setOpenPriceModal] = React.useState({
    open: false,
  });
  const [imageUrlWeb, setImageUrlWeb] = React.useState(null);
  const [imageUrlMobile, setImageUrlMobile] = React.useState(null);
  const [webFiles, setWebFiles] = React.useState([]);
  const [mobileFiles, setMobileFiles] = React.useState([]);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    setWebFiles([]);
    setMobileFiles([]);
    setImageUrlMobile(null);
    setImageUrlWeb(null);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      if (!imageUrlWeb) {
        toast.error('Please add web image');
        return;
      }
      if (!imageUrlMobile) {
        toast.error('Please add mobile image');
        return;
      }
      if (webFiles.length < 1) {
        toast.error('Please add web section');
        return;
      }
      if (mobileFiles.length < 1) {
        toast.error('Please add mobile section');
        return;
      }
      const sectionsWeb = webFiles.map((item) => ({
        name: item.name,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
      }));
      const sectionsMobile = mobileFiles.map((item) => ({
        name: item.name,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
      }));
      const resImgWeb = await uploadAdvertisementImages(imageUrlWeb);
      const resImgMobile = await uploadAdvertisementImages(imageUrlMobile);
      const payload = {
        pageName: fieldValue?.pageName,
        imageUrlWeb: resImgWeb?.data?.url || '',
        imageUrlMobile: resImgMobile?.data?.url || '',
        sectionsWeb: sectionsWeb,
        sectionsMobile: sectionsMobile,
        status: fieldValue?.status,
      };

      const res = await addAdvertisementpage(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      if (webFiles.length < 1) {
        toast.error('Please add web section');
        return;
      }
      if (mobileFiles.length < 1) {
        toast.error('Please add mobile section');
        return;
      }
      const sectionsWeb = webFiles.map((item) => ({
        name: item.name,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
      }));
      const sectionsMobile = mobileFiles.map((item) => ({
        name: item.name,
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
      }));
      const payload = {
        pageName: fieldValue?.pageName,
        imageUrlWeb: data?.imageUrlWeb,
        imageUrlMobile: data?.imageUrlMobile,
        sectionsWeb: sectionsWeb,
        sectionsMobile: sectionsMobile,
        status: fieldValue?.status,
        _id: data._id,
      };
      if (imageUrlWeb) {
        const resImgWeb = await uploadAdvertisementImages(imageUrlWeb);
        payload.imageUrlWeb = resImgWeb?.data?.url || '';
      }
      if (imageUrlMobile) {
        const resImgMobile = await uploadAdvertisementImages(imageUrlMobile);
        payload.imageUrlMobile = resImgMobile?.data?.url || '';
      }
      const res = await updateAdvertisementPage(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true) {
      if (data !== null) {
        setFieldValue({
          pageName: data?.pageName || '',
          imageUrlWeb: data?.imageUrlWeb || '',
          imageUrlMobile: data?.imageUrlMobile || '',
          sectionsWeb: data?.sectionsWeb || [],
          sectionsMobile: data?.sectionsMobile || [],
          status: data?.status ?? true,
        });

        const sectionsWebX = data?.sectionsWeb?.map((e) => ({
          type: 'web',
          name: e?.name,
          x: e?.x,
          y: e?.y,
          w: e?.w,
          h: e?.h,
          isSelected: false,
        }));
        setWebFiles(sectionsWebX);
        const sectionsMobileX = data?.sectionsMobile?.map((e) => ({
          type: 'mobile',
          name: e?.name,
          x: e?.x,
          y: e?.y,
          w: e?.w,
          h: e?.h,
          isSelected: false,
        }));
        setMobileFiles(sectionsMobileX);
        setIsDisableAll(isDisplay);
      }
    }
  }, [open, data, isDisplay]);

  const addForWeb = () => {
    setMobileFiles((prev) => {
      const newPrev = [...prev];
      newPrev.forEach((item) => {
        item.isSelected = false;
      });
      return newPrev;
    });
    setWebFiles((prev) => {
      const newPrev = [...prev];
      newPrev.forEach((item) => {
        item.isSelected = false;
      });
      return [...newPrev, { ...initFileFieldValue, type: 'web', isSelected: true }];
    });
  };
  const addForMobile = () => {
    setWebFiles((prev) => {
      const newPrev = [...prev];
      newPrev.forEach((item) => {
        item.isSelected = false;
      });
      return newPrev;
    });
    setMobileFiles((prev) => {
      const newPrev = [...prev];
      newPrev.forEach((item) => {
        item.isSelected = false;
      });
      return [...newPrev, { ...initFileFieldValue, type: 'mobile', isSelected: true }];
    });
  };
  const toggleSelected = (type, index) => {
    setWebFiles((prev) => {
      const newPrev = [...prev];
      newPrev.forEach((item) => {
        item.isSelected = false;
      });
      if (type === 'web') {
        newPrev[index].isSelected = true;
      }
      return newPrev;
    });
    setMobileFiles((prev) => {
      const newPrev = [...prev];
      newPrev.forEach((item) => {
        item.isSelected = false;
      });
      if (type === 'mobile') {
        newPrev[index].isSelected = true;
      }
      return newPrev;
    });
  };
  const onChangeArray = (type, index, key, value) => {
    if (type === 'web') {
      setWebFiles((prev) => {
        const newPrev = [...prev];
        newPrev[index][key] = value;
        return newPrev;
      });
    }
    if (type === 'mobile') {
      setMobileFiles((prev) => {
        const newPrev = [...prev];
        newPrev[index][key] = value;
        return newPrev;
      });
    }
  };
  const onChangeArrayBox = (type, index, data) => {
    if (type === 'web') {
      setWebFiles((prev) => {
        const newPrev = [...prev];
        newPrev[index].x = data?.x;
        newPrev[index].y = data?.y;
        newPrev[index].w = data?.width;
        newPrev[index].h = data?.height;
        return newPrev;
      });
    }
    if (type === 'mobile') {
      setMobileFiles((prev) => {
        const newPrev = [...prev];
        newPrev[index].x = data?.x;
        newPrev[index].y = data?.y;
        newPrev[index].w = data?.width;
        newPrev[index].h = data?.height;
        return newPrev;
      });
    }
  };
  const removeFile = (type, index) => {
    setWebFiles((prev) => {
      const newPrev = [...prev];
      newPrev.forEach((item) => {
        item.isSelected = false;
      });
      if (type === 'web') {
        newPrev.splice(index, 1);
      }
      return newPrev;
    });
    setMobileFiles((prev) => {
      const newPrev = [...prev];
      newPrev.forEach((item) => {
        item.isSelected = false;
      });
      if (type === 'mobile') {
        newPrev.splice(index, 1);
      }
      return newPrev;
    });
  };

  const onOpenPriceModal = () => {
    setOpenPriceModal({
      open: true,
    });
  };
  const onClosePriceModal = () => {
    setOpenPriceModal({
      open: false,
    });
  };

  const allFiles = [...webFiles, ...mobileFiles];
  const currentSelected = allFiles.find((item) => item.isSelected === true);
  const currentSelectedIndex = allFiles.findIndex((item) => item.isSelected === true);

  const currentFile =
    currentSelected?.type === 'web' ? imageUrlWeb : currentSelected?.type === 'mobile' ? imageUrlMobile : undefined;
  const currentFileData =
    currentSelected?.type === 'web'
      ? data?.imageUrlWeb
      : currentSelected?.type === 'mobile'
      ? data?.imageUrlMobile
      : '';
  const currentImageUrl = data ? currentFileData : currentFile ? URL.createObjectURL(currentFile) : '';

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
          >
            {data === null ? 'Add Advertisement Page' : isDisplay ? 'Advertisement Page' : 'Update Advertisement Page'}
          </Typography>
          <div style={{ display: 'flex', gap: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: 'none', width: '500px' }}>
              {/* name */}
              {/* <TextField
            value={fieldValue.pageName}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Select Page"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          /> */}
              <TextField
                value={fieldValue.pageName}
                onChange={(e) => setFieldValue((prev) => ({ ...prev, pageName: e.target.value }))}
                required
                select
                label="Select Page"
                size="small"
                disabled={isDisableAll}
                fullWidth
                variant="outlined"
              >
                {moduleList?.map((e, index) => (
                  <MenuItem key={index} value={e?.megaModule}>
                    {e?.megaModule}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                // value={imageUrlWeb || null}
                onChange={(e) => setImageUrlWeb(e.target?.files?.[0] || null)}
                required
                label="Select a file for Web"
                size="small"
                type="file"
                variant="outlined"
                disabled={isDisableAll}
                fullWidth
                InputLabelProps={{ shrink: true }}
                // sx={{ width: '120px' }}
              />
              <TextField
                // value={imageUrlMobile || null}
                onChange={(e) => setImageUrlMobile(e.target?.files?.[0] || null)}
                required
                label="Select a file for Mobile"
                size="small"
                type="file"
                variant="outlined"
                disabled={isDisableAll}
                fullWidth
                InputLabelProps={{ shrink: true }}
                // sx={{ width: '120px' }}
              />
              {/* status */}
              <TextField
                value={fieldValue.status === true ? 'Active' : fieldValue.status === false ? 'Inactive' : ''}
                onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
                required
                select
                label="Status"
                size="small"
                fullWidth
                variant="outlined"
                disabled={isDisableAll}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
              {data?._id && (
                <Button
                  onClick={() => onOpenPriceModal()}
                  sx={{
                    borderColor: '#19c25f',
                    color: '#19c25f',
                    textTransform: 'capitalize',
                  }}
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  Configure Pricing
                </Button>
              )}
              {/* webFiles */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <div style={{ display: 'flex', gap: '6px', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Section for Web</span>
                  <Button
                    disabled={isDisableAll}
                    onClick={addForWeb}
                    sx={{
                      borderColor: '#19c25f',
                      color: '#19c25f',
                      textTransform: 'capitalize',
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Add
                  </Button>
                </div>
                {webFiles?.map((e, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      border: e?.isSelected ? '1px solid #19c25f' : '1px solid white',
                      borderRadius: '4px',
                      padding: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', gap: '12px' }}>
                      <TextField
                        value={e?.name || ''}
                        onChange={(e) => onChangeArray('web', index, 'name', e.target?.value)}
                        required
                        select
                        label="Section for Web"
                        size="small"
                        disabled={isDisableAll}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {sectionsList?.map((e, index) => (
                          <MenuItem key={index} value={e}>
                            {e}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button
                        disabled={isDisableAll}
                        onClick={() => removeFile('web', index)}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                        size="small"
                        variant="contained"
                        color="error"
                      >
                        Delete
                      </Button>
                      <Button
                        disabled={e?.isSelected}
                        onClick={() => toggleSelected('web', index)}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                        size="small"
                        variant="contained"
                        color="secondary"
                      >
                        Select
                      </Button>
                    </div>
                    <div style={{ display: 'flex', gap: '6px' }}>
                      <TextField
                        value={e?.x}
                        onChange={(e) => onChangeArray('web', index, 'x', parseFloat(e.target?.value) || 0)}
                        required
                        label="X"
                        size="small"
                        type="text"
                        variant="outlined"
                        disabled={isDisableAll}
                        sx={{ width: '140px', fontSize: '14px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        value={e?.y}
                        onChange={(e) => onChangeArray('web', index, 'y', parseFloat(e.target?.value) || 0)}
                        required
                        label="Y"
                        size="small"
                        type="text"
                        variant="outlined"
                        disabled={isDisableAll}
                        sx={{ width: '140px', fontSize: '14px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        value={e?.w}
                        onChange={(e) => onChangeArray('web', index, 'w', parseFloat(e.target?.value) || 0)}
                        required
                        label="W"
                        size="small"
                        type="text"
                        variant="outlined"
                        disabled={isDisableAll}
                        sx={{ width: '140px', fontSize: '14px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        value={e?.h}
                        onChange={(e) => onChangeArray('web', index, 'h', parseFloat(e.target?.value) || 0)}
                        required
                        label="H"
                        size="small"
                        type="text"
                        variant="outlined"
                        disabled={isDisableAll}
                        sx={{ width: '140px', fontSize: '14px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              {/* mobileFiles */}
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                <div style={{ display: 'flex', gap: '6px', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Section for Mobile</span>
                  <Button
                    disabled={isDisableAll}
                    onClick={addForMobile}
                    sx={{
                      borderColor: '#19c25f',
                      color: '#19c25f',
                      textTransform: 'capitalize',
                    }}
                    size="small"
                    variant="outlined"
                  >
                    Add
                  </Button>
                </div>
                {mobileFiles?.map((e, index) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      border: e?.isSelected ? '1px solid #19c25f' : '1px solid white',
                      borderRadius: '4px',
                      padding: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', gap: '12px' }}>
                      <TextField
                        value={e?.name || ''}
                        onChange={(e) => onChangeArray('mobile', index, 'name', e.target?.value)}
                        required
                        select
                        label="Section for Mobile"
                        size="small"
                        disabled={isDisableAll}
                        fullWidth
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      >
                        {sectionsList?.map((e, index) => (
                          <MenuItem key={index} value={e}>
                            {e}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Button
                        disabled={isDisableAll}
                        onClick={() => removeFile('mobile', index)}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                        size="small"
                        variant="contained"
                        color="error"
                      >
                        Delete
                      </Button>
                      <Button
                        disabled={e?.isSelected}
                        onClick={() => toggleSelected('mobile', index)}
                        sx={{
                          textTransform: 'capitalize',
                        }}
                        size="small"
                        variant="contained"
                        color="secondary"
                      >
                        Select
                      </Button>
                    </div>
                    <div style={{ display: 'flex', gap: '6px' }}>
                      <TextField
                        value={e?.x}
                        onChange={(e) => onChangeArray('mobile', index, 'x', parseFloat(e.target?.value) || 0)}
                        required
                        label="X"
                        size="small"
                        type="text"
                        variant="outlined"
                        disabled={isDisableAll}
                        sx={{ width: '140px', fontSize: '14px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        value={e?.y}
                        onChange={(e) => onChangeArray('mobile', index, 'y', parseFloat(e.target?.value) || 0)}
                        required
                        label="Y"
                        size="small"
                        type="text"
                        variant="outlined"
                        disabled={isDisableAll}
                        sx={{ width: '140px', fontSize: '14px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        value={e?.w}
                        onChange={(e) => onChangeArray('mobile', index, 'w', parseFloat(e.target?.value) || 0)}
                        required
                        label="W"
                        size="small"
                        type="text"
                        variant="outlined"
                        disabled={isDisableAll}
                        sx={{ width: '140px', fontSize: '14px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        value={e?.h}
                        onChange={(e) => onChangeArray('mobile', index, 'h', parseFloat(e.target?.value) || 0)}
                        required
                        label="H"
                        size="small"
                        type="text"
                        variant="outlined"
                        disabled={isDisableAll}
                        sx={{ width: '140px', fontSize: '14px' }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              {!currentImageUrl ? (
                <div
                  style={{
                    width: '200px',
                    height: '200px',
                    border: '1px solid red',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span>Select a section or file</span>
                </div>
              ) : (
                <RectangleDrawer
                  rectPercentages={{
                    x: currentSelected?.x || 0,
                    y: currentSelected?.y || 0,
                    width: currentSelected?.w || 0,
                    height: currentSelected?.h || 0,
                  }}
                  onChange={(data) =>
                    onChangeArrayBox(
                      currentSelected?.type,
                      currentSelected?.type === 'mobile'
                        ? currentSelectedIndex - webFiles.length
                        : currentSelectedIndex,
                      data
                    )
                  }
                  imageUrl={currentImageUrl}
                />
              )}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
            <Button
              disabled={isDisplay ? false : isDisableAll}
              onClick={handleClose}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              Cancel
            </Button>
            {!isDisplay && (
              <Button
                disabled={isDisableAll}
                onClick={data === null ? onSubmit : onSubmitUpdate}
                sx={{
                  backgroundColor: '#19c25f',
                  textTransform: 'capitalize',
                  ':hover': { backgroundColor: '#19c25f' },
                }}
                variant="contained"
              >
                {data === null ? 'Create' : 'Update'}
              </Button>
            )}
          </div>
        </Box>
      </Modal>
      <AdPriceModal adId={data?._id} onClose={onClosePriceModal} open={openPriceModal?.open} />
    </>
  );
}
