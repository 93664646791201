import { Box, Button, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorToast from '../../components/Toast/Error';
import SuccessToast from '../../components/Toast/Success';
import { SaveNoOfTextEmails } from '../../services/Service';
import { setMemory } from '../../store/businessSlice';
import SwitchForForms from './SwitchForForms';
import CheckBoxForUnlimited from './CheckBoxForUnlimited';

const TextEmails = () => {
  const { Memory } = useSelector((state) => state.business);
  const dispatch = useDispatch();

  useEffect(() => {
    setInitialValues({
      freeUserText: Memory.freeUserText ,
      paidUserText: Memory.paidUserText ,
      premiumUserText: Memory.premiumUserText ,
      betaUserText: Memory.betaUserText ,
      freeUserEmail: Memory.freeUserEmail,
      paidUserEmail: Memory.paidUserEmail,
      premiumUserEmail: Memory.premiumUserEmail ,
      betaUserEmail: Memory.betaUserEmail,
    });
  }, [Memory]);

  const [initialValues, setInitialValues] = useState({});
  
  const handleChange = (name, value) => {
    setInitialValues({
      ...initialValues,
      [name]: value,
    });
  };

  // const initialValues = {
  //   freeUserText: Memory.freeUserText || 0,
  //   paidUserText: Memory.paidUserText || 0,
  //   premiumUserText: Memory.premiumUserText || 0,
  //   betaUserText: Memory.betaUserText || 0,
  //   freeUserEmail: Memory.freeUserEmail || 0,
  //   paidUserEmail: Memory.paidUserEmail || 0,
  //   premiumUserEmail: Memory.premiumUserEmail || 0,
  //   betaUserEmail: Memory.betaUserEmail || 0,
  // };

  const TextEmailsForm = useFormik({
    enableReinitialize: true,
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      console.log('this is TextEmailsForm values => ', values);
      SaveNoOfTextEmails(values)
        .then((res) => {
          SuccessToast('details updated !!');
          dispatch(setMemory(res.data.data));
        })
        .catch((e) => {
          ErrorToast('Something went wrong !!');
        });
    },
  });

  return (
    <Box sx={{ mt: 3, ml: 3 }}>
      <FormikProvider value={TextEmailsForm} validateOnMount>
        <form onSubmit={TextEmailsForm.handleSubmit}>
          <Stack direction="row" spacing={5}>
            <Box width={'50%'}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography>Number of Text user can send : </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Free User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.freeUserText && Boolean(TextEmailsForm.errors.freeUserText)}
                  >
                    <Field
                      as={TextField}
                      name="freeUserText"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values.freeUserText}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched.freeUserText && Boolean(TextEmailsForm.errors.freeUserText)}
                      helperText={TextEmailsForm.touched.freeUserText && TextEmailsForm.errors.freeUserText}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="freeUserText"
                    checked={TextEmailsForm.values.freeUserText}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="freeUserText"
                    checked={TextEmailsForm.values.freeUserText}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Paid User</Typography>
                  <FormControl
                    error={TextEmailsForm.touched.paidUserText && Boolean(TextEmailsForm.errors.paidUserText)}
                  >
                    <Field
                      as={TextField}
                      name="paidUserText"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values.paidUserText}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched.paidUserText && Boolean(TextEmailsForm.errors.paidUserText)}
                      helperText={TextEmailsForm.touched.paidUserText && TextEmailsForm.errors.paidUserText}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="paidUserText"
                    checked={TextEmailsForm.values.paidUserText}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="paidUserText"
                    checked={TextEmailsForm.values.paidUserText}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Premium User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.premiumUserText && Boolean(TextEmailsForm.errors.premiumUserText)}
                  >
                    <Field
                      as={TextField}
                      name="premiumUserText"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values.premiumUserText}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched.premiumUserText && Boolean(TextEmailsForm.errors.premiumUserText)}
                      helperText={TextEmailsForm.touched.premiumUserText && TextEmailsForm.errors.premiumUserText}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="premiumUserText"
                    checked={TextEmailsForm.values.premiumUserText}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="premiumUserText"
                    checked={TextEmailsForm.values.premiumUserText}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Beta User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.betaUserText && Boolean(TextEmailsForm.errors.betaUserText)}
                  >
                    <Field
                      as={TextField}
                      name="betaUserText"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values.betaUserText}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched.betaUserText && Boolean(TextEmailsForm.errors.betaUserText)}
                      helperText={TextEmailsForm.touched.betaUserText && TextEmailsForm.errors.betaUserText}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="betaUserText"
                    checked={TextEmailsForm.values.betaUserText}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="betaUserText"
                    checked={TextEmailsForm.values.betaUserText}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Box>
            <Box width={'50%'}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography>Number of Emails user can send : </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Free User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.freeUserEmail && Boolean(TextEmailsForm.errors.freeUserEmail)}
                  >
                    <Field
                      as={TextField}
                      name="freeUserEmail"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values.freeUserEmail}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched.freeUserEmail && Boolean(TextEmailsForm.errors.freeUserEmail)}
                      helperText={TextEmailsForm.touched.freeUserEmail && TextEmailsForm.errors.freeUserEmail}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="freeUserEmail"
                    checked={TextEmailsForm.values.freeUserEmail}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="freeUserEmail"
                    checked={TextEmailsForm.values.freeUserEmail}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Paid User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.paidUserEmail && Boolean(TextEmailsForm.errors.paidUserEmail)}
                  >
                    <Field
                      as={TextField}
                      name="paidUserEmail"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values.paidUserEmail}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched.paidUserEmail && Boolean(TextEmailsForm.errors.paidUserEmail)}
                      helperText={TextEmailsForm.touched.paidUserEmail && TextEmailsForm.errors.paidUserEmail}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="paidUserEmail"
                    checked={TextEmailsForm.values.paidUserEmail}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="paidUserEmail"
                    checked={TextEmailsForm.values.paidUserEmail}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Premium User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.premiumUserEmail && Boolean(TextEmailsForm.errors.premiumUserEmail)}
                  >
                    <Field
                      as={TextField}
                      name="premiumUserEmail"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values.premiumUserEmail}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched.premiumUserEmail && Boolean(TextEmailsForm.errors.premiumUserEmail)}
                      helperText={TextEmailsForm.touched.premiumUserEmail && TextEmailsForm.errors.premiumUserEmail}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="premiumUserEmail"
                    checked={TextEmailsForm.values.premiumUserEmail}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="premiumUserEmail"
                    checked={TextEmailsForm.values.premiumUserEmail}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Beta User</Typography>
                  <FormControl
                    // fullWidth

                    error={TextEmailsForm.touched.betaUserEmail && Boolean(TextEmailsForm.errors.betaUserEmail)}
                  >
                    <Field
                      as={TextField}
                      name="betaUserEmail"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={TextEmailsForm.values.betaUserEmail}
                      onChange={TextEmailsForm.handleChange}
                      error={TextEmailsForm.touched.betaUserEmail && Boolean(TextEmailsForm.errors.betaUserEmail)}
                      helperText={TextEmailsForm.touched.betaUserEmail && TextEmailsForm.errors.betaUserEmail}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="betaUserEmail"
                    checked={TextEmailsForm.values.betaUserEmail}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="betaUserEmail"
                    checked={TextEmailsForm.values.betaUserEmail}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Box>
          </Stack>

          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              // type="submit"
              onClick={TextEmailsForm.handleSubmit}
              // disabled={!(TextEmailsForm.isValid && TextEmailsForm.dirty)}
              sx={{
                width: '170px',
                height: '45px',
                bgcolor: '#19C25F',
                borderRadius: '30px',
                textTransform: 'none',
                mt: '25px',
                boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
              }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default TextEmails;
