import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { createColorsAndBg, uploadBackgroundImage } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  value: '#90ee90',
  type: '',
  isDefault: false,
};

export default function AddColorBgModal({ open, onClose, mode }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setSelectedFile(null);
    onClose();
  };

  React.useEffect(() => {
    if (mode === 'color') {
      setFieldValue({
        value: '#90ee90',
        type: 'color',
        isDefault: false,
      });
    } else {
      setFieldValue({
        value: '',
        type: 'image',
        isDefault: false,
      });
    }
  }, [mode]);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload = { ...fieldValue, type: mode };
      if (mode === 'image') {
        console.log('setIsSubmitting', selectedFile);
        const fileRes = await uploadBackgroundImage(selectedFile);
        payload.value = fileRes.data.url;
      }
      const res = await createColorsAndBg(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {mode === 'color' ? 'Select new color' : 'Select new image'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {mode === 'image' ? (
            <TextField
              onChange={(e) => {
                if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
                  toast.error('Please upload image in JPG, JPEG, PNG Format ONLY.');
                } else {
                  setSelectedFile(e.target.files[0]);
                }
              }}
              type="file"
              required
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                accept: 'image/*',
              }}
            />
          ) : (
            <TextField
              value={fieldValue.value}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, value: e.target.value }))}
              type="color"
              required
              size="small"
              fullWidth
              variant="outlined"
            />
          )}
          <TextField
            value={fieldValue.isDefault ? 'yes' : 'no'}
            onChange={(e) => {
              const val = e.target.value === 'yes';
              setFieldValue((prev) => ({ ...prev, isDefault: val }));
            }}
            required
            select
            label="Is this default for all user"
            size="small"
            fullWidth
            variant="outlined"
          >
            <MenuItem value={'yes'}>Yes</MenuItem>
            <MenuItem value={'no'}>No</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button onClick={handleClose} sx={{ backgroundColor: '#19c25f' }} variant="contained">
            Cancel
          </Button>
          <Button onClick={onSubmit} sx={{ backgroundColor: '#19c25f' }} variant="contained">
            Create
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
