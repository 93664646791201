import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import { createSafalLeaderFrequencyConfig, updateSafalLeaderFrequencyConfig } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  frequencyName: '',
  frequencyKey: '',
  type: '', // day, month, week, year, firstTime, everyTime, onlyOnce
  duration: 1, //  default: 1, This only applicable for day, month, week, year
};

export default function AddSafalLeaderFrequencyConfigModal({ open, onClose, data, isDisplay }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      const res = await createSafalLeaderFrequencyConfig(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const res = await updateSafalLeaderFrequencyConfig({ _id: data._id, ...fieldValue });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        frequencyName: data?.frequencyName || '',
        frequencyKey: data?.frequencyKey || '',
        type: data?.type || '', // day, month, week, year, firstTime, everyTime, onlyOnce
        duration: data?.duration || 1, //  default: 1, This only applicable for day, month, week, year
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Frequency Config' : isDisplay ? 'Frequency Config' : 'Update Frequency Config'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* frequencyName */}
          <TextField
            value={fieldValue.frequencyName}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, frequencyName: e.target.value }))}
            required
            label="Frequency Name"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* frequencyKey */}
          <TextField
            value={fieldValue.frequencyKey}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, frequencyKey: e.target.value }))}
            required
            label="Frequency Key"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* type */}
          <TextField
            value={fieldValue.type}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, type: e.target.value }))}
            required
            select
            label="Type"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="year">Year</MenuItem>
            <MenuItem value="firstTime">First Time</MenuItem>
            <MenuItem value="everyTime">Every Time</MenuItem>
            <MenuItem value="onlyOnce">Only Once</MenuItem>
          </TextField>
          {/* duration */}
          {['day', 'week', 'month', 'year'].includes(fieldValue.type) && (
            <TextField
              value={fieldValue.duration}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, duration: parseInt(e.target.value, 10) }))}
              required
              label="Duration"
              name="name"
              size="small"
              type="Number"
              fullWidth
              variant="outlined"
              disabled={isDisableAll}
            />
          )}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
