import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import {
  createHelpVideoInventoryBusiness,
  getApplicationList,
  getHelpVideoModule,
  updateHelpVideoInventoryBusiness,
} from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  applicationName: '',
  megaModule: '',
  megaModuleId: '',
  module: '',
  subModule: '',
  videoShortDescription: '',
  videoDetailedDescription: '',
  youtubeURL: '',
  status: true, // default true
};

export default function AddHelpVideoModal({ open, onClose, data, isDisplay }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [megaModuleList, setMegaModuleList] = React.useState([]);
  const [moduleList, setModuleList] = React.useState([]);
  const [subModuleList, setSubModuleList] = React.useState([]);
  const [applicationsList, setApplicationsList] = React.useState([]);

  const fetchData = async () => {
    try {
      const applicationsListRes = await getApplicationList();
      setApplicationsList(applicationsListRes.data.data);
      const res = await getHelpVideoModule();
      if (res.status === 200) {
        setMegaModuleList(res.data.data);
        if (open === true && data !== null) {
          const findRes = res.data.data?.find((ex) => ex.megaModule === data?.megaModule);
          if (findRes) {
            setModuleList(findRes?.module || []);
            const findRes1 = findRes?.module?.find((ex) => ex.module === data?.module);
            if (findRes1) {
              setSubModuleList(findRes1?.subModule || []);
            }
          }
        }
      }
    } catch (err) {
      console.log('HelpVideoInventoryBusiness fetchData error', err.message);
    }
  };

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    setSubModuleList([]);
    setModuleList([]);
    setMegaModuleList([]);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      const res = await createHelpVideoInventoryBusiness(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const res = await updateHelpVideoInventoryBusiness({ _id: data._id, ...fieldValue });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open) fetchData();
  }, [open]);

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        applicationName: data?.applicationName,
        megaModule: data?.megaModule,
        megaModuleId: data?.megaModuleId,
        module: data?.module,
        subModule: data?.subModule,
        videoShortDescription: data?.videoShortDescription,
        videoDetailedDescription: data?.videoDetailedDescription,
        youtubeURL: data?.youtubeURL,
        status: data?.status, // default true
      });
      const findRes = megaModuleList?.find((ex) => ex.megaModule === data?.megaModule);
      if (findRes) {
        setModuleList(findRes?.module || []);
        const findRes1 = findRes?.module?.find((ex) => ex.module === data?.module);
        if (findRes1) {
          setSubModuleList(findRes1?.subModule || []);
        }
      }
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Help Video Info' : isDisplay ? 'Help Video Info' : 'Update Help Video Info'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* applicationName */}
          {/* <TextField
            value={fieldValue.applicationName}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, applicationName: e.target.value }))}
            required
            label="Application Name"
            name="applicationName"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          /> */}
          {/* applicationName */}
          <TextField
            value={fieldValue.applicationName}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, applicationName: e.target.value }))}
            required
            select
            label="Application Name"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {applicationsList?.map((e, index) => (
              <MenuItem key={index} value={e?.name}>
                {e?.name}
              </MenuItem>
            ))}
          </TextField>
          {/* megaModule */}
          <TextField
            value={fieldValue.megaModule}
            onChange={(e) => {
              const findRes = megaModuleList.find((ex) => ex.megaModule === e.target.value);
              setFieldValue((prev) => ({
                ...prev,
                megaModule: e.target.value,
                megaModuleId: findRes?._id,
                module: '',
                subModule: '',
              }));
              if (findRes) {
                setModuleList(findRes?.module);
                setSubModuleList([]);
              }
            }}
            required
            select
            label="Mega Module"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {megaModuleList.map((ex) => (
              <MenuItem value={ex.megaModule}>{ex.megaModule}</MenuItem>
            ))}
          </TextField>
          {/* module */}
          <TextField
            value={fieldValue.module}
            onChange={(e) => {
              setFieldValue((prev) => ({ ...prev, module: e.target.value, subModule: '' }));
              const findRes = moduleList.find((ex) => ex.module === e.target.value);
              if (findRes) {
                setSubModuleList(findRes?.subModule);
              }
            }}
            required
            select
            label="Module"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {moduleList.map((ex) => (
              <MenuItem value={ex?.module}>{ex?.module}</MenuItem>
            ))}
          </TextField>
          {/* subModule */}
          <TextField
            value={fieldValue.subModule}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, subModule: e.target.value }))}
            required
            select
            label="Sub-Module"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {subModuleList.map((ex) => (
              <MenuItem value={ex}>{ex}</MenuItem>
            ))}
          </TextField>
          {/* videoShortDescription */}
          <TextField
            value={fieldValue.videoShortDescription}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, videoShortDescription: e.target.value }))}
            required
            label="Video Short Description"
            name="videoShortDescription"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
            inputProps={{ maxLength: 40 }}
            helperText={`${fieldValue.videoShortDescription.length}/40`}
          />
          {/* videoDetailedDescription */}
          <TextField
            value={fieldValue.videoDetailedDescription}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, videoDetailedDescription: e.target.value }))}
            required
            label="Video Detailed Description"
            name="videoDetailedDescription"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
            multiline
            minRows={2}
            maxRows={3}
          />
          {/* youtubeURL */}
          <TextField
            value={fieldValue.youtubeURL}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, youtubeURL: e.target.value }))}
            required
            label="Youtube URL"
            name="youtubeURL"
            size="small"
            type="url"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* status */}
          <TextField
            value={fieldValue.status === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
