import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const AssetSortMenu = ({ sort, sortState, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        title="Sort"
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '40px',
          width: '40px',
          color: 'white',
          backgroundColor: '#3D71FF',
          ':hover': {
            color: 'white',
            backgroundColor: '#315acc',
          },
        }}
        size="small"
        variant="contained"
      >
        <SortIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {sort.map((e, index) => (
          <MenuItem
            onClick={() => {
              if (sortState.orderBy === e.value) {
                const newState = { orderBy: e.value, order: sortState.order === 'asc' ? 'desc' : 'asc' };
                onChange(newState);
                return;
              }
              const newState = { orderBy: e.value, order: 'asc' };
              onChange(newState);
            }}
            sx={{ width: '200px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            key={index}
          >
            {e.label}
            {sortState.orderBy === e.value ? (
              sortState.order === 'asc' ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            ) : (
              <></>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default AssetSortMenu;
