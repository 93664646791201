import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// material
import { tooltipClasses } from '@mui/material/Tooltip';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import MomentUtils from '@date-io/moment';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  FormControl,
  Divider,
  Popover,
  Badge,
  Box,
  Grid,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';

// component
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import Iconify from '../../components/Iconify';

import { setSubscriptions, subscriptionFilteredDataPayload } from '../../store/subscriptionSlice';
import { FilterResponse, GetsubsResponse } from '../../services/Service';
import Scrollbar from '../../components/Scrollbar';
import CustomRating from '../../components/UI/CustomRating';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 50,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0px 16px !important',
  paddingLeft: '16px !important',
  paddingRight: '16px !important',
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function UserListToolbar({
  labelList,
  filterName,
  onFilterName,
  setCname,
  cname,
  pagination,
  sendDataToSubscription,
}) {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [subscriptionName, setSubscriptionName] = useState(null);
  const [amount, setAmount] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [label, setLabel] = useState(null);
  const [nextBilling, setNextBilling] = useState(null);
  const [isLiked, setIsLiked] = useState(null);
  const [rating, setRating] = useState(null);
  const [subType, setSubType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSmallDesktop = useMediaQuery('(max-width: 1200px)');

  const searchFilterClick = () => {
    if (
      startDate ||
      nextBilling ||
      subscriptionName ||
      amount ||
      frequency ||
      status ||
      label ||
      isLiked ||
      rating ||
      subType
    ) {
      GetsubsResponse(1, 5, null, null, {
        startDate,
        nextBilling,
        subscriptionName,
        amount,
        frequency,
        status,
        label,
        isLiked,
        rating,
        subType,
      }).then((res) => {
        if (res.data.success === true) {
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data?.pagination[0]?.totalItems }));
          dispatch(
            subscriptionFilteredDataPayload({
              subscriptionFilter: {
                startDate,
                nextBilling,
                subscriptionName,
                amount,
                frequency,
                status,
                isLiked,
                rating,
                subType,
                label,
              },
            })
          );

          sendDataToSubscription({
            startDate,
            nextBilling,
            subscriptionName,
            amount,
            frequency,
            status,
            isLiked,
            rating,
            subType,
            label,
          });

          handleClose();
        } else {
          GetsubsResponse().then((res) => {
            if (res.data.success === true) {
              dispatch(
                setSubscriptions({
                  subscriptions: res.data.data[0].data,
                  totalItems: res.data?.pagination[0]?.totalItems,
                })
              );
              handleClose();
            }
          });
        }
      });
    } else {
      clearFilter();
    }
  };

  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkboxFilter = (name) => {
    if (!cname.includes(name)) {
      setCname((prev) => [...prev, name]);
      if (name === 'subscriptionType') {
        setSubType(null);
      }
      if (name === 'subscriptionName') {
        setSubscriptionName(null);
      }
      if (name === 'amount') {
        setAmount(null);
      }
      if (name === 'frequency') {
        setFrequency(null);
      }
      if (name === 'startDate') {
        setStartDate(null);
      }
      if (name === 'nextBilling') {
        setNextBilling(null);
      }
      if (name === 'status') {
        setStatus(null);
      }
      if (name === 'label') {
        setLabel(null);
      }
      if (name === 'isLiked') {
        setIsLiked(null);
      }
      if (name === 'rating') {
        setRating(null);
      }
    } else {
      setCname(cname.filter((val) => val !== name));
    }
  };
  const clearFilter = () => {
    setAnchorEl(null);
    setCname([]);

    // FilterResponse(null, null, null, null, null, null, null, null, null)
    GetsubsResponse().then((res) => {
      setSubscriptionName(null);
      setAmount(null);
      setFrequency(null);
      setStartDate(null);
      setStatus(null);
      setLabel(null);
      setNextBilling(null);
      setIsLiked(null);
      setRating(null);
      setSubType(null);
      setAnchorEl(null);
      if (res.data.success === true) {
        dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
      } else {
        GetsubsResponse().then((res) => {
          if (res.data.success === true) {
            dispatch(
              setSubscriptions({ subscriptions: res.data.data[0].data, totalItems: res.data.pagination[0].totalItems })
            );
          }
        });
      }
    });
  };

  return (
    <RootStyle
    // sx={{
    //   ...(numSelected > 0 && {
    //     color: 'primary.main',
    //     bgcolor: 'primary.lighter',
    //   }),
    // }}
    >
      {/* <SearchStyle
        value={filterName}
        onChange={onFilterName}
        placeholder="Search Subscription..."
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
          </InputAdornment>
        }
      /> */}

      <>
        <Tooltip title="Filter list">
          <IconButton
            onClick={handleClick}
            // style={{
            //   display: 'flex',
            //   alignItems: 'center',
            //   backgroundColor: '#3D71FF',
            //   // padding: '8px',
            //   borderRadius: '5px',
            //   height: '40px',
            //   // width: '40px',
            //   cursor: 'pointer',
            //   '&:hover': { backgroundColor: '#3D71FF' },
            //   '@media (max-width: 600px)': {
            //     ml: -3,
            //     mt: 2,
            //   },
            // }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              // ml: 2,
              backgroundColor: '#3D71FF',
              // height: '40px',
              height: isSmallDesktop ? '40px' : '40px',
              width: isSmallDesktop ? '90px' : '40px',
              '&:hover': { backgroundColor: '#3D71FF' },
              '@media (max-width: 600px)': {
                ml: -3,
                mt: 2,
                paddingLeft: '16px',
                paddingRight: '16px',
              },
            }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge color="error" variant="dot" invisible={!cname.length > 0 || cname[0] === ''}>
              <FilterAltIcon sx={{ color: 'white' }} />
            </Badge>
            <span
              style={{
                color: 'white',
                display: isSmallDesktop ? 'block' : 'none', // Default display for all screens
              }}
            >
              Filter
            </span>
          </IconButton>
        </Tooltip>
      </>
      {/* <Tooltip title="Filter list">
        <div
          onClick={handleClick}
          style={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3D71FF',
            padding: '8px',
            borderRadius: '5px',
            cursor: 'pointer',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Badge color="error" variant="dot" invisible={!cname.length > 0 || cname[0] === ''}>
            <img src="/assets/images/filterIcon.png" alt="filterIcon" style={{ marginRight: '8px' }} />
          </Badge>
          <span style={{ color: 'white' }}>Filter</span>
        </div>
      </Tooltip> */}

      <Popover
        open={open}
        anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              // transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        <Box>
          <Typography gutterTop align="center" variant="subtitle1">
            Filter list
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ height: '400px', width: '330px', overflowY: 'auto' }}>
          <Scrollbar>
            <FormGroup sx={{ px: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={cname.includes('subscriptionType')}
                    onChange={(e) => {
                      checkboxFilter('subscriptionType');
                      if (e.target.checked === false) {
                        setSubscriptionName(null);
                        setSubType(null);
                      }
                    }}
                    name="subscriptionType"
                  />
                }
                label="Subscription Type"
              />
              {cname.includes('subscriptionType') && (
                <SearchStyle
                  placeholder="Search Subscription Type..."
                  onChange={(e) => {
                    setSubType(e.target.value);
                  }}
                  value={subType}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={cname.includes('subscriptionName')}
                    onChange={(e) => {
                      checkboxFilter('subscriptionName');
                      e.target.checked === false && setSubscriptionName(null);
                    }}
                    name="subscriptionName"
                  />
                }
                label="Subscription Name"
              />
              {cname.includes('subscriptionName') && (
                <SearchStyle
                  placeholder="Search Subscription Name..."
                  onChange={(e) => {
                    setSubscriptionName(e.target.value);
                  }}
                  value={subscriptionName}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cname.includes('frequency')}
                    onChange={(e) => {
                      checkboxFilter('frequency');
                      e.target.checked === false && setFrequency(null);
                    }}
                    name="frequency"
                  />
                }
                label="Frequency"
              />
              {cname.includes('frequency') && (
                <Select
                  placeholder="Select frequency"
                  labelId="select7x"
                  id="select7x"
                  name="Frequency"
                  label="Frequency"
                  variant="standard"
                  value={frequency}
                  onChange={(e) => {
                    setFrequency(e.target.value);
                  }}
                  size="small"
                  sx={{
                    '&:after': {
                      borderBottomColor: '#0000',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#0071E3',
                    },
                  }}
                >
                  <MenuItem value={'Monthly'}>Monthly</MenuItem>
                  <MenuItem value={'Annually'}>Annually</MenuItem>
                </Select>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cname.includes('status')}
                    onChange={(e) => {
                      checkboxFilter('status');
                      e.target.checked === false && setStatus(null);
                    }}
                    name="status"
                  />
                }
                label="Status"
              />
              {cname && cname.includes('status') && (
                <Select
                  labelId="select5"
                  id="select5"
                  name="status"
                  label="Status"
                  variant="standard"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  size="small"
                  sx={{
                    '&:after': {
                      borderBottomColor: '#0000',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#0071E3',
                    },
                  }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              )}
              {/* Label Filter */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cname.includes('label')}
                    onChange={(e) => {
                      checkboxFilter('label');
                      e.target.checked === false && setLabel(null);
                    }}
                    name="label"
                  />
                }
                label="Label"
              />
              {cname && cname.includes('label') && (
                <Select
                  labelId="select53"
                  id="select53"
                  name="label"
                  label="Label"
                  variant="standard"
                  value={label}
                  onChange={(e) => {
                    setLabel(e.target.value);
                  }}
                  size="small"
                  sx={{
                    '&:after': {
                      borderBottomColor: '#0000',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#0071E3',
                    },
                  }}
                >
                  {labelList?.map((ex, idx) => (
                    <MenuItem key={idx} value={ex._id}>
                      {ex.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormGroup>
            <FormGroup sx={{ px: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cname.includes('amount')}
                    onClick={(e) => {
                      checkboxFilter('amount');
                      e.target.checked === false && setAmount(null);
                    }}
                    name="amount"
                  />
                }
                label="Amount"
              />

              {cname.includes('amount') && (
                <SearchStyle
                  placeholder="Search Amount..."
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  value={amount}
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              )}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={cname.includes('startDate')}
                    onClick={(e) => {
                      checkboxFilter('startDate');
                      e.target.checked === false && setStartDate(null);
                    }}
                    name="startDate"
                  />
                }
                label="Start Date"
              />

              {cname && cname.includes('startDate') && (
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={MomentUtils}>
                    <DesktopDatePicker
                      label={
                        <Typography sx={{ color: '#B6B6B6' }}>
                          Start Date<span style={{ color: 'red' }}>*</span>
                        </Typography>
                      }
                      inputFormat="MM/DD/YYYY"
                      value={startDate}
                      onChange={(newValue) => {
                        setStartDate(moment(newValue).format('yyyy-MM-DD'));
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="startDate"
                          variant="standard"
                          size="small"
                          {...params}
                          sx={{
                            svg: { color: '#0071E3', mr: '5px' },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              )} */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={cname.includes('nextBilling')}
                    onClick={(e) => {
                      checkboxFilter('nextBilling');
                      e.target.checked === false && setNextBilling(null);
                    }}
                    name="nextBilling"
                  />
                }
                label="Next Billing Date"
              />

              {cname && cname.includes('nextBilling') && (
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={MomentUtils}>
                    <DesktopDatePicker
                      label={
                        <Typography sx={{ color: '#B6B6B6' }}>
                          Next Billing Date<span style={{ color: 'red' }}>*</span>
                        </Typography>
                      }
                      inputFormat="MM/DD/YYYY"
                      value={nextBilling}
                      onChange={(newValue) => {
                        setNextBilling(cname.includes('nextBilling') ? moment(newValue).format('yyyy-MM-DD') : null);
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="nextBilling"
                          variant="standard"
                          size="small"
                          {...params}
                          sx={{
                            svg: { color: '#0071E3', mr: '5px' },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={cname.includes('isLiked')}
                    onChange={(e) => {
                      checkboxFilter('isLiked');
                      e.target.checked === false && setIsLiked(null);
                    }}
                    name="isLiked"
                  />
                }
                label="Safals"
              />
              {cname && cname.includes('isLiked') && (
                <>
                  <Select
                    labelId="select5"
                    id="select5"
                    name="isLiked"
                    label="isLiked"
                    variant="standard"
                    value={isLiked}
                    onChange={(e) => {
                      setIsLiked(e.target.value);
                    }}
                    size="small"
                    sx={{
                      '&:after': {
                        borderBottomColor: '#0000',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#0071E3',
                      },
                      '& .MuiSelect-select': {
                        display: 'flex',
                        justifyContent: 'center',
                      },
                    }}
                  >
                    <MenuItem value={-1} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <img src="/assets/images/disLike.png" alt="DisLike Symbol" />
                    </MenuItem>
                    <MenuItem value={'0'} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <>
                        <img src="/assets/images/Like.png" alt="Like Symbol" style={{ filter: 'grayscale(100%)' }} />
                        <img
                          src="/assets/images/disLike.png"
                          alt="DisLike Symbol"
                          style={{ filter: 'grayscale(100%)' }}
                        />
                      </>
                    </MenuItem>
                    <MenuItem value={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <img src="/assets/images/Like.png" alt="Like Symbol" />
                    </MenuItem>
                  </Select>
                </>
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={cname.includes('rating')}
                    onChange={(e) => {
                      checkboxFilter('rating');
                      setRating('0');
                      e.target.checked === false && setRating(null);
                    }}
                    name="rating"
                  />
                }
                label="Rating"
              />
              {cname && cname.includes('rating') && (
                <CustomRating
                  name="searchRating"
                  value={rating}
                  onChange={(e) => {
                    setRating(e.target.value);
                  }}
                  precision={0.5}
                />
              )}
            </FormGroup>
          </Scrollbar>
        </Box>
        <Divider />
        <Grid item sm={12} md={6} lg={3} xl={3} sx={{ display: 'flex', marginLeft: 'inherit', marginRight: '10px' }}>
          {/* <Button sx={{ mt: 1, ml: 2, mb: 1 }} tabIndex="-1" onClick={() => searchFilterClick()} disableFocusRipple>
          Search
        </Button> */}
          <div style={{ padding: '8px', display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            <LightTooltip title="Search" placement="bottom">
              <Button
                startIcon={<SearchIcon />}
                color="primary"
                variant="contained"
                type="submit"
                size="small"
                sx={{
                  // width: '100px',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  backgroundColor: '#3D71FF',
                }}
                tabIndex="-1"
                onClick={() => searchFilterClick()}
                disableFocusRipple
              >
                Search
              </Button>
            </LightTooltip>
            <LightTooltip title="Clear Filters" placement="bottom">
              <Button
                startIcon={<FilterAltOffIcon />}
                color="primary"
                variant="contained"
                type="submit"
                size="small"
                sx={{
                  // width: '100px',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  backgroundColor: '#3D71FF',
                }}
                tabIndex="-1"
                onClick={() => clearFilter()}
                disableFocusRipple
              >
                Clear Filter
              </Button>
            </LightTooltip>
            <LightTooltip title="Close" placement="bottom">
              <Button
                startIcon={<CloseIcon />}
                color="error"
                variant="contained"
                size="small"
                type="submit"
                sx={{
                  // width: '100px',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  backgroundColor: 'red',
                }}
                tabIndex="-1"
                onClick={() => handleClose()}
                disableFocusRipple
              >
                Close
              </Button>
            </LightTooltip>
          </div>
        </Grid>
      </Popover>
    </RootStyle>
  );
}

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })
);
