import { TextField, Typography } from '@mui/material';
import './PaymentInstrumentBusiness.css';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

import { getPaymentInstrument } from '../../../services/Service';
import TableView from './TableView';

const PaymentInstrumentBusiness = () => {
  const [rawData, setRawData] = useState([]);
  const [rawDataFilter, setRawDataFilter] = useState([]);
  const [searchString, setSearchString] = useState('');

  const fetchData = async () => {
    try {
      const res = await getPaymentInstrument();
      if (res.status === 200) {
        setRawData(res.data.data);
        setRawDataFilter(res.data.data);
      }
    } catch (err) {
      console.log('PaymentInstrumentBusiness fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSearchChange = (e) => {
    setSearchString(e.target.value);
    if (e.target.value === '') {
      setRawDataFilter(rawData);
    } else {
      const newFilter = rawData.filter(
        (ex) =>
          ex?.user?.userID.toLowerCase().includes(e.target.value.toLowerCase()) ||
          ex?.nickNameField.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setRawDataFilter(newFilter);
    }
  };

  return (
    <>
      <Helmet>
        <title>Payment Instrument - SafalSubscriptions</title>
      </Helmet>

      <div id="PaymentInstrumentBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Payment Instrument <span style={{ fontSize: '12px', color: '#027c34' }}>(Display Only)</span>
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <TextField
              sx={{ width: '300px', ml: '12px' }}
              onChange={onSearchChange}
              value={searchString}
              type="search"
              id="search-input"
              size="small"
              label="Search userId/Nickname"
              variant="outlined"
            />
          </div>
          <div className="title-action-div" />
        </div>
        <TableView data={rawDataFilter} />
      </div>
    </>
  );
};

export default PaymentInstrumentBusiness;
