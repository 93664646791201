import { FormControl, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './SafalRoomCommunityForums.css';

import { getSafalRoomForumUser, updateSafalRoomForumUser } from '../../../services/Service';

import TableView from './TableView';
import SafalRoomCommunityForumsModal from './SafalRoomCommunityForumsModal';

const SafalRoomCommunityForums = () => {
  const [rawData, setRawData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchString, setSearchString] = useState('');

  const [displayModalOpen, setDisplayModalOpen] = useState({
    data: null,
    open: false,
  });

  const [selectedStatus, setSelectedStatus] = useState('pending'); // pending, approved, rejected

  const fetchData = async () => {
    try {
      const res = await getSafalRoomForumUser({ business: true });
      if (res.status === 200) {
        setRawData(res.data.data);
        const filterData = res.data.data?.filter((e) => e?.businessStatus === selectedStatus);
        setFilterData(filterData);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangeStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
    setSearchString('');
    const filterData = rawData?.filter((e) => e?.businessStatus === newStatus);
    setFilterData(filterData);
  };

  const onAccept = async (e) => {
    try {
      const res = await updateSafalRoomForumUser({
        _id: e._id,
        businessStatus: 'approved', // pending, approved, rejected
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const onReject = async (e) => {
    try {
      const res = await updateSafalRoomForumUser({
        _id: e._id,
        businessStatus: 'rejected', // pending, approved, rejected
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onOpenModal = (data) => {
    setDisplayModalOpen({
      data: data,
      open: true,
    });
  };

  const onModalClose = () => {
    setDisplayModalOpen({
      data: null,
      open: false,
    });
  };

  const onSearchChange = (e) => {
    setSearchString(e.target.value);
    if (e.target.value === '') {
      const filterData = rawData?.filter((e) => e?.businessStatus === selectedStatus);
      setFilterData(filterData);
    } else {
      const filterData = rawData?.filter((e) => e?.businessStatus === selectedStatus);
      const newFilter = filterData.filter(
        (ex) =>
          ex.topic.toLowerCase().includes(e.target.value.toLowerCase()) ||
          ex.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilterData(newFilter);
    }
  };

  return (
    <>
      <Helmet>
        <title>SafalRoom Community Forums</title>
      </Helmet>

      <div id="SafalRoomCommunityForums">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              SafalRoom Community Forums
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="SafalGiftOrder-selectedApplication">Status</InputLabel>
              <Select
                labelId="SafalGiftOrder-selectedApplication"
                value={selectedStatus}
                label="Order Status"
                onChange={(e) => onChangeStatusChange(e.target.value)}
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="title-action-div">
            <TextField
              sx={{ width: '200px', ml: '12px' }}
              onChange={onSearchChange}
              value={searchString}
              type="search"
              id="search-input"
              size="small"
              label="Search forum name"
              variant="outlined"
            />
            {/* <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} /> */}
          </div>
        </div>
        <TableView onAccept={onAccept} onReject={onReject} onOpen={onOpenModal} data={filterData} />
      </div>
      <SafalRoomCommunityForumsModal
        onAccept={onAccept}
        onReject={onReject}
        open={displayModalOpen.open}
        data={displayModalOpen.data}
        onClose={onModalClose}
      />
    </>
  );
};

export default SafalRoomCommunityForums;
