/* eslint-disable react/button-has-type */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { collection, doc, getDocs, onSnapshot, setDoc, getDoc, collectionGroup } from 'firebase/firestore';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { db, auth } from '../../../firebase';
import ActionButton from './ActionButton';

function CallWindow({ endCall }) {
  const peerVideo = useRef(null);
  const localVideo = useRef(null);
  const { callWindow, localSrc, peerSrc, config, pc } = useSelector((state) => state.call);
  const [video, setVideo] = useState(config.video);
  const [audio, setAudio] = useState(config.audio);
  const { callModal, callFrom } = useSelector((state) => state.call);
  const { user, chatMode, chatId, sid } = useSelector((state) => state.chats);

  console.log('Call Window', callWindow);
  console.log('End Call Window', endCall);

  console.log('sid', sid)

  // useEffect(() => {
  //   if (peerVideo.current && peerSrc) peerVideo.current.srcObject = peerSrc;
  //   if (localVideo.current && localSrc) localVideo.current.srcObject = localSrc;
  // });

  useEffect(() => {

    if (peerVideo.current && peerSrc) peerVideo.current.srcObject = peerSrc;
    console.log("peerVideo.current.srcObject", peerVideo.current.srcObject);
    console.log("peerSrc",peerSrc )
    if (localVideo.current && localSrc) localVideo.current.srcObject = localSrc;
    console.log("localSrc", localSrc)
    console.log("localVideo.current.srcObject", localVideo.current.srcObject);
  });

  useEffect(() => {
    if (pc?.mediaDevice) {
      pc.mediaDevice.toggle('Video', video);
      pc.mediaDevice.toggle('Audio', audio);
    }
  });

  /**
   * Turn on/off a media device
   * @param {'Audio' | 'Video'} deviceType - Type of the device eg: Video, Audio
   */
  const toggleMediaDevice = (deviceType) => {
    if (deviceType === 'Video') {
      setVideo(!video);
      // const audio = new Audio(audio);
      // audio.play();
    }
    if (deviceType === 'Audio') {
      setAudio(!audio);
    }
    pc?.mediaDevice.toggle(deviceType);
  };

  const [prof, setProf] = useState(null);
  const [name, setName] = useState('');
  console.log('Name', name)

  const handleFunction = async () => {
    const user = await getDoc(doc(db, 'users', sid));
    console.log('sid', sid)
    setProf(user?.data()?.photoURL);
    setName(user?.data()?.displayName);
    // localStorage.setItem('pic', user?.data()?.photoURL);
  };

  useEffect(() => {
    handleFunction();
  });

  const [time, setTime] = useState({
    sec: 0,
    min: 0,
    hr: 0,
  });

  const [intervalId, setIntervalId] = useState();

  const updateTimer = () => {
    setTime((prev) => {
      let newTime = { ...prev };
      // update sec and see if we need to increase min
      if (newTime.sec < 59) newTime.sec += 1;
      else {
        newTime.min += 1;
        newTime.sec = 0;
      }
      // min has increased in *newTime* by now if it was updated, see if it has crossed 59
      if (newTime.min === 60) {
        newTime.min = 0;
        newTime.hr += 1;
      }

      return newTime;
    });
  };

  const startCalls = () => {
    if (!intervalId) {
      let id = setInterval(updateTimer, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setIntervalId('');
    }
  };

  const endCalls = () => {
    if (!intervalId) {
      let id = setInterval(updateTimer, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId);
      setIntervalId('');
    }
  };
  const reset = () => {
    clearInterval(intervalId);
    setTime({
      sec: 0,
      min: 0,
      hr: 0,
    });
  };

  return (
    <div className={classnames('call-window', callWindow)}>
      <div className="video-control1">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={prof} alt="" style={{ height: 100, width: 100, borderRadius: '50%' }} />
        </div>{' '}
        <br />
        <h2 style={{ color: 'white' }}> {`${name}`}</h2>
      </div>
      <video id="peerVideo" ref={peerVideo} autoPlay />
      <video className="videoScreen" id="localVideo" ref={localVideo} autoPlay muted />

      <div className="video-control">
        <div style={{ marginTop: 10 }}>
          <ActionButton
            style={{ height: 28, width: 28 }}
            key="btnVideo"
            icon={'wpf:video-call'}
            disabled={!video}
            onClick={() => toggleMediaDevice('Video')}
          />

          <ActionButton
            style={{ height: 28, width: 28 }}
            key="btnAudio"
            icon={audio ? 'ph:microphone-fill' : 'clarity:microphone-mute-solid'}
            disabled={!audio}
            onClick={() => toggleMediaDevice('Audio')}
          />

          <ActionButton
            className="hangup"
            icon={'material-symbols:call-end-sharp'}
            style={{ height: 30, width: 30 }}
            onClick={() => endCall(true)}
          />
        </div>
      </div>
    </div>
  );
}

CallWindow.propTypes = {
  endCall: PropTypes.func.isRequired,
};

export default CallWindow;
