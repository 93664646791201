const TEXT_LIGHT = '#90ba91';
const TEXT_NORMAL = '#468b47';

const InfoCard = ({ bottomValue, bottomTitle, subTitle, subValue, value, title, imgSrc }) => {
  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        gap: '24px',
      }}
    >
      <img
        src={imgSrc}
        alt="b_dashboard"
        style={{
          width: '48px',
          height: '48px',
          objectFit: 'contain',
          position: 'absolute',
          top: '12px',
          right: '12px',
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p style={{ color: TEXT_LIGHT, fontSize: '20px' }}>{title}</p>
        <p style={{ color: TEXT_NORMAL, fontSize: '20px', fontWeight: 'bold' }}>{value}</p>
        <p style={{ color: '#D9D9D9', fontSize: '16px' }}>
          <span
            style={{ color: subValue?.includes('-') ? '#FCBABA' : TEXT_LIGHT, fontSize: '16px', marginRight: '8px' }}
          >
            {subValue}
          </span>
          {subTitle}
        </p>
      </div>
      {/* <div
        style={{
          borderTop: '1px solid #d0f8d0',
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '12px',
        }}
      >
        <span style={{ color: '#adadad', fontSize: '16px' }}>{bottomTitle}</span>
        <span style={{ fontSize: '16px' }}>{bottomValue}</span>
      </div> */}
    </div>
  );
};

export default InfoCard;
