import { useSelector } from 'react-redux';

import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import safalAiImage from '../../assets/ai-chat-logo.png';
import useUserProfileImage from '../../hooks/useUserProfileImage';

const ChatMessageX = ({ data }) => {
  const { user } = useSelector((state) => state.login);
  const { formatTime, formatDate2 } = useUserTimeZone();
  const userProfileImage = useUserProfileImage();
  const userFullName = `${user.firstName} ${user.lastName}`;

  return (
    <>
      <div className={`ai-chat-message ${data.from !== 'SafalSubscriptions FAQ AI' && 'self'}`}>
        <div className="image-div">
          <img
            className="ai-image"
            src={data.from === 'SafalSubscriptions FAQ AI' ? safalAiImage : userProfileImage}
            alt="safal AI"
          />
        </div>
        <div style={{ maxWidth: '70%' }}>
          <div
            style={{
              backgroundColor: data.from === 'SafalSubscriptions FAQ AI' ? '#eaeaea' : '#01b855',
              padding: '12px',
              borderRadius: '10px',
              borderBottomLeftRadius: data.from === 'SafalSubscriptions FAQ AI' ? '0px' : '10px',
              borderBottomRightRadius: data.from === 'SafalSubscriptions FAQ AI' ? '10px' : '0px',
              color: data.from === 'SafalSubscriptions FAQ AI' ? 'black' : 'white',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {data.from === 'SafalSubscriptions FAQ AI' ? 'FAQ AI' : userFullName}
            </span>
            <p style={{ whiteSpace: 'pre-line' }}>{data.message}</p>
          </div>
          <span style={{ fontSize: '12px', color: '#B6B6B6' }}>
            {formatTime(data.createdAt)} {getTimeZoneAbbreviation()}
          </span>
        </div>
      </div>
      {data?.isDate && (
        <span style={{ textAlign: 'center' }}>
          {formatDate2(data.createdAt)} {getTimeZoneAbbreviation()}
        </span>
      )}
    </>
  );
};
export default ChatMessageX;
