/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import * as React from 'react';

import { Avatar, Fab } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import useProfileImage from '../../hooks/useProfileImage';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 3,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function UserNewProfileMoreModal({ open, onClose, data, onClick }) {
  const handleClose = () => {
    onClose();
  };

  const handleClick = (e) => {
    onClick(e);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Fab
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: '#3D71FF',
            color: 'white',
          }}
          onClick={handleClose}
          size="small"
          color="primary"
        >
          <CloseIcon />
        </Fab>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}
        >
          Mutual SafalBuddies
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', overflow: 'auto', maxHeight: '500px' }}>
          {data?.map((e, index) => (
            <UserProfileBox data={e} onClick={handleClick} key={index} />
          ))}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{ backgroundColor: '#3D71FF', textTransform: 'none', borderRadius: '30px' }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const UserProfileBox = ({ data, onClick }) => {
  const fullName = `${data?.firstName || ''} ${data?.lastName || ''}`;
  const profileImage = useProfileImage(data?.profilePic);
  return (
    <div
      onClick={() => onClick(data?._id)}
      style={{ display: 'flex', alignItems: 'center', flex: 'none', gap: '16px', cursor: 'pointer' }}
    >
      <Avatar sx={{ height: '40px', width: '40px' }} alt={fullName} src={profileImage} />
      <span style={{ textAlign: 'center', fontSize: '14px', color: '#4F79EA' }}>{fullName}</span>
    </div>
  );
};
