import { ref, set, onDisconnect } from 'firebase/database';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { realTimeDatabase } from '../firebase';

const useFirebase = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const [iamOnline, setIamOnline] = useState(true);

  useEffect(() => {
    window.addEventListener('online', () => {
      setIamOnline(true);
    });
    window.addEventListener('offline', () => {
      setIamOnline(false);
    });
  }, []);

  useEffect(() => {
    if (!currentUser) return;
    const currentUserRef = ref(realTimeDatabase, `/status/${currentUser.uid}`);
    set(currentUserRef, {
      isOnline: true,
      lastSeen: Timestamp.now(),
    });
    onDisconnect(currentUserRef).set({
      isOnline: false,
      lastSeen: Timestamp.now(),
    });
  }, [currentUser, iamOnline]);
};

export default useFirebase;
