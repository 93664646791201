import * as React from 'react';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import moment from 'moment';
import { getModuleConfigurationAppVersions, updateModuleConfigurationMenu } from '../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function AddEditDynamicMenuModal({ open, onClose, data, refetch }) {
  const [modulesNameList, setModulesNameList] = useState([]);
  const [subModulesNameList, setSubModulesNameList] = useState([]);

  const [selectedDeviceType, setSelectedDeviceType] = useState('');
  const [selectedModulesName, setSelectedModulesName] = useState('');
  const [selectedSubModulesName, setSelectedSubModulesName] = useState('');
  const [releaseType, setReleaseType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleClose = () => {
    onClose();
    resetState();
  };

  const getInitVersion = async () => {
    try {
      const res = await getModuleConfigurationAppVersions();
      setModulesNameList(res.data.data.menu.menus);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setModulesNameList([]);
    }
  };

  const onSetValues = () => {
    if (data === null) return;
    setReleaseType(data.release);
    setStartDate(moment(data.startDate).format('YYYY-MM-DD'));
    setEndDate(moment(data.endDate).format('YYYY-MM-DD'));
    setSelectedSubModulesName(data.submenu);
    setSelectedModulesName(data.menu);
    setSelectedDeviceType(data.device);
    setSubModulesNameList(modulesNameList[data.menu].submenu);
  };

  useEffect(() => {
    getInitVersion();
    onSetValues();
  }, [open]);

  const resetState = () => {
    setSelectedDeviceType('');
    setSelectedModulesName('');
    setSelectedSubModulesName('');
    setReleaseType('');
    setStartDate('');
    setEndDate('');
  };

  const onChangeDeviceType = (e) => {
    const value = e.target.value;
    setSelectedDeviceType(value);
  };

  const onChangeReleaseType = (e) => {
    const value = e.target.value;
    setReleaseType(value);
  };

  const onChangeModulesName = (e) => {
    const value = e.target.value;
    setSelectedModulesName(value);
    setSubModulesNameList(modulesNameList[value].submenu);
  };

  const onChangeSubModulesName = (e) => {
    const value = e.target.value;
    setSelectedSubModulesName(value);
  };

  const onChangeStartDate = (e) => {
    const value = e.target.value;
    setStartDate(value);
  };

  const onChangeEndDate = (e) => {
    const value = e.target.value;
    setEndDate(value);
  };

  const onSubmit = async () => {
    try {
      if (selectedDeviceType === '') throw new Error('Module must be selected');
      if (releaseType === '') throw new Error('Release type not found');
      if (selectedModulesName === '') throw new Error('Module must be selected');
      if (startDate === '') throw new Error('Start Date must be selected');
      if (endDate === '') throw new Error('End Date must be selected');

      const payload = {
        release: releaseType,
        device: selectedDeviceType,
        menu: selectedModulesName,
        startDate: startDate,
        endDate: endDate,
        status: 'active',
      };
      if (selectedSubModulesName) payload.submenu = selectedSubModulesName;

      await updateModuleConfigurationMenu(data._id, payload);
      refetch();
      resetState();
      handleClose();
      toast.success('Edit successful');
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          Edit Module Configuration
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span>Device</span>
            <FormControl size="small">
              <Select disabled={data !== null} value={selectedDeviceType} onChange={onChangeDeviceType} displayEmpty>
                <MenuItem value={'android'}>Android</MenuItem>
                <MenuItem value={'ios'}>IOS</MenuItem>
                <MenuItem value={'web'}>Web</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span>Release Type</span>
            <TextField
              disabled={data !== null}
              value={releaseType}
              onChange={onChangeReleaseType}
              id="release-type"
              size="small"
              variant="outlined"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span>Module</span>
            <FormControl size="small">
              <Select disabled={data !== null} value={selectedModulesName} onChange={onChangeModulesName} displayEmpty>
                {Object.keys(modulesNameList).map((e, index) => (
                  <MenuItem key={index} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span>Sub-Module</span>
            <FormControl size="small">
              <Select
                disabled={data !== null}
                value={selectedSubModulesName}
                onChange={onChangeSubModulesName}
                displayEmpty
              >
                {subModulesNameList.map((e, index) => (
                  <MenuItem key={index} value={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span>Start Date</span>
            <TextField
              onChange={onChangeStartDate}
              value={startDate}
              type="date"
              id="release-start-date"
              size="small"
              variant="outlined"
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <span>End Date</span>
            <TextField
              onChange={onChangeEndDate}
              value={endDate}
              type="date"
              id="release-end-date"
              size="small"
              variant="outlined"
            />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button onClick={handleClose} sx={{ backgroundColor: '#19c25f' }} variant="contained">
            Cancel
          </Button>
          <Button onClick={onSubmit} sx={{ backgroundColor: '#19c25f' }} variant="contained">
            Edit
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
