/* eslint-disable no-restricted-syntax */
/* eslint-disable  no-plusplus */

import { Button, Checkbox, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { getPlanList, getAssetLimitsBusiness, updateAssetLimitsBusiness } from '../../services/Service';
import Inlineloader from '../../components/Loader/InlineLoader';

function compareObjects(obj1, obj2) {
  if (obj1.loyaltyLimit.length === obj2.loyaltyLimit.length) return true;
  if (obj1.giftLimit.length === obj2.giftLimit.length) return true;
  if (obj1.rewardLimit.length === obj2.rewardLimit.length) return true;

  for (let index = 0; index < obj1.loyaltyLimit.length; index++) {
    const element = obj1.loyaltyLimit[index];
    const findTierIdx = obj2.loyaltyLimit.findIndex((exx) => exx.tier === element.tier);
    if (findTierIdx === -1) return false;
    const element2 = obj2.loyaltyLimit[findTierIdx];
    if (element.limit === element2.limit) return true;
    if (element.isUnlimited === element2.isUnlimited) return true;
  }
  for (let index = 0; index < obj1.giftLimit.length; index++) {
    const element = obj1.giftLimit[index];
    const findTierIdx = obj2.giftLimit.findIndex((exx) => exx.tier === element.tier);
    if (findTierIdx === -1) return false;
    const element2 = obj2.giftLimit[findTierIdx];
    if (element.limit === element2.limit) return true;
    if (element.isUnlimited === element2.isUnlimited) return true;
  }
  for (let index = 0; index < obj1.rewardLimit.length; index++) {
    const element = obj1.rewardLimit[index];
    const findTierIdx = obj2.rewardLimit.findIndex((exx) => exx.tier === element.tier);
    if (findTierIdx === -1) return false;
    const element2 = obj2.rewardLimit[findTierIdx];
    if (element.limit === element2.limit) return true;
    if (element.isUnlimited === element2.isUnlimited) return true;
  }
  return false;
}
// {
//   limit: Number,
//   isUnlimited: Boolean,
//   tier: Number,
// }
const AssetsLimits = () => {
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assetLimitsRaw, setAssetLimitsRaw] = useState({
    loyaltyLimit: [],
    giftLimit: [],
    rewardLimit: [],
  });

  const [assetLimits, setAssetLimits] = useState({
    loyaltyLimit: [],
    giftLimit: [],
    rewardLimit: [],
  });

  const fetchPlanData = async () => {
    try {
      const res = await getPlanList();
      if (res.status === 200) {
        setPlanList(res.data.data);
      }
    } catch (err) {
      console.log('getPlanList fetchData error', err.message);
    }
  };

  const fetchAssetLimitData = async () => {
    try {
      setLoading(true);
      const res = await getAssetLimitsBusiness();
      if (res.status === 200) {
        setAssetLimits({
          loyaltyLimit: res.data.data[0]?.loyaltyLimit || [],
          giftLimit: res.data.data[0]?.giftLimit || [],
          rewardLimit: res.data.data[0]?.rewardLimit || [],
        });
        setAssetLimitsRaw({
          loyaltyLimit: res.data.data[0]?.loyaltyLimit || [],
          giftLimit: res.data.data[0]?.giftLimit || [],
          rewardLimit: res.data.data[0]?.rewardLimit || [],
        });
      }
    } catch (err) {
      console.log('getPlanList fetchData error', err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlanData();
    fetchAssetLimitData();
  }, []);

  const onChangeValue = (type, tier, value) => {
    setAssetLimits((prev) => {
      const oldValue = { ...prev };
      const thisType = oldValue[type];
      const findValueIndex = thisType.findIndex((ex) => ex.tier === tier);
      if (findValueIndex === -1) {
        thisType.push({
          limit: value.limit,
          isUnlimited: value.isUnlimited,
          tier: tier,
        });
      } else {
        thisType[findValueIndex] = {
          limit: value.limit,
          isUnlimited: value.isUnlimited,
          tier: tier,
        };
      }
      oldValue[type] = thisType;
      return oldValue;
    });
  };

  const onUpdateLimits = async () => {
    try {
      const res = await updateAssetLimitsBusiness(assetLimits);
      toast.success(res.data.message);
      await fetchAssetLimitData();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  // const isUpdateEnable = compareObjects(assetLimitsRaw, assetLimits);

  if (loading) return <Inlineloader />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '32px' }}>
      {planList.map((e, index) => {
        const findLoyaltyLimit = assetLimits.loyaltyLimit.find((ex) => ex.tier === e.tier);
        const findGiftLimit = assetLimits.giftLimit.find((ex) => ex.tier === e.tier);
        const findRewardLimitLimit = assetLimits.rewardLimit.find((ex) => ex.tier === e.tier);
        return (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
              Tier {e.tier} : {e.name}
            </span>
            <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
              <InputBox
                tier={e.tier}
                onChange={(data) => onChangeValue('loyaltyLimit', e.tier, data)}
                limit={findLoyaltyLimit?.limit || 0}
                isUnlimited={findLoyaltyLimit?.isUnlimited || false}
                label="Loyalty Card"
              />
              <InputBox
                tier={e.tier}
                onChange={(data) => onChangeValue('giftLimit', e.tier, data)}
                limit={findGiftLimit?.limit || 0}
                isUnlimited={findGiftLimit?.isUnlimited || false}
                label="Gift Card"
              />
              <InputBox
                tier={e.tier}
                onChange={(data) => onChangeValue('rewardLimit', e.tier, data)}
                limit={findRewardLimitLimit?.limit || 0}
                isUnlimited={findRewardLimitLimit?.isUnlimited || false}
                label="Reward Certificates"
              />
            </div>
          </div>
        );
      })}
      <Button
        // disabled={isUpdateEnable}
        size="small"
        onClick={onUpdateLimits}
        variant="contained"
        sx={{
          marginLeft: 'auto',
          marginTop: '16px',
          width: '120px',
          backgroundColor: '#19c25f',
          ':hover': { backgroundColor: '#0f7539' },
          textTransform: 'capitalize',
        }}
      >
        Update
      </Button>
    </div>
  );
};

const InputBox = ({ label, onChange, tier, limit, isUnlimited }) => {
  const onChangeCheckbox = (event) => {
    onChange({
      isUnlimited: event.target.checked,
      limit: event.target.checked ? 0 : limit,
    });
  };

  const onChangeLimit = (event) => {
    onChange({
      isUnlimited: isUnlimited,
      // eslint-disable-next-line
      limit: parseInt(event.target.value),
    });
  };

  return (
    <label style={{ display: 'flex', alignItems: 'center' }} htmlFor="loyalty">
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          width: '150px',
        }}
        disabled={isUnlimited}
        value={limit}
        onChange={onChangeLimit}
        type="number"
        label={label}
        size="small"
        variant="outlined"
      />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox id={`checkbox-t${tier}-n${label}`} checked={isUnlimited} onChange={onChangeCheckbox} />
        <label style={{ cursor: 'pointer' }} htmlFor={`checkbox-t${tier}-n${label}`}>
          Unlimited
        </label>
      </div>
    </label>
  );
};

export default AssetsLimits;
