/* eslint-disable */
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineSearch } from 'react-icons/ai';
import { db } from '../../firebase';
import UserStatus from './UserStatus';
import NotificationCount from './NotificationCount';
import Scrollbar from '../Scrollbar';
import LastMsg from './LastMsg';
import Grid from '@mui/material/Grid';
import SupportChatSidebar from './SupportChatSidebar';
import UserChatSidebar from './UserChatSidebar';
import moment from 'moment';

const Chats = ({ username, buddyUser }) => {
  const { role } = useSelector((state) => state.login?.user);
  const { currentUser } = useSelector((state) => state.auth);
  const { user, chatMode, chatId, sid } = useSelector((state) => state.chats);
  const [userChatWindow, setUserChatWindow] = useState([]);
  const [supportChatWindow, setSupportChatWindow] = useState([]);
  const [supportChatWindowFilter, setSupportChatWindowFilter] = useState([]);
  const { notificationCount } = useSelector((state) => state.businessNotificationsDot);

  useEffect(() => {
    if (role === 'business' && notificationCount > 0) {
      const businessNotificationsDotRef = doc(db, 'businessNotificationsDot', 'supportChat');
      updateDoc(businessNotificationsDotRef, {
        notificationCount: 0,
      });
    }
  }, [role, notificationCount]);

  useEffect(() => {
    try {
      if (currentUser?.uid && role === 'user') {
        const unsub = onSnapshot(doc(db, `${chatMode}Chats`, currentUser?.uid), (doc) => {
          if (doc.exists()) {
            setUserChatWindow(
              Object.entries(doc.data())
                ?.sort((a, b) => b[1].date - a[1].date)
                .map((d) => {
                  return {
                    key: d[0],
                    userInfo: d[1]?.userInfo,
                    lastMessage: d[1]?.lastMessage,
                  };
                })
            );
          }
        });

        return () => {
          unsub();
        };
      } else {
        const unsub = onSnapshot(collection(db, `${chatMode}Chats`), (snapshot) => {
          const chatsData = [];
          snapshot.docs.forEach((doc) => {
            chatsData.push(doc.data());
          });
          const supportChatsFilterByDate = Object.entries(chatsData).filter(
            (e) =>
              Object.entries(e[1])[0]?.[1]?.date !== undefined && Object.entries(e[1])[0]?.[1]?.userInfo !== undefined
          );
          const supportChatsSortByDate = supportChatsFilterByDate.sort(
            (a, b) => Object.entries(b[1])[0]?.[1]?.date - Object.entries(a[1])[0]?.[1]?.date
          );
          const supportChats = supportChatsSortByDate.map((d) => ({
            key: d[0],
            userInfo: Object.values(d[1])[0]?.userInfo,
          }));
          setSupportChatWindow(supportChats);
          setSupportChatWindowFilter(supportChats);
        });

        return () => {
          unsub();
        };
      }
    } catch (e) {
      console.log(e);
    }
  }, [currentUser?.uid, user, chatId, sid, chatMode]);

  return (
    <>
      {role !== 'user' && <SupportSearch setFilterList={setSupportChatWindowFilter} list={supportChatWindow} />}
      <Container sx={{ overflowY: 'auto' }}>
        <div className="chat">
          {role === 'user' ? (
            username === '' && <UserChatSidebar chats={userChatWindow} buddyUser={buddyUser} />
          ) : (
            <>
              <SupportChatSidebar chats={supportChatWindowFilter} />
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default Chats;

const SupportSearch = ({ setFilterList, list }) => {
  const [searchValue, setSearchValue] = useState('');

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    const value = e.target.value.trim().toLowerCase();
    const filterList = list.filter((e) => e?.userInfo?.displayName?.trim().toLowerCase().includes(value));
    setFilterList(filterList);
  };

  return (
    <div className="support-search">
      <input
        onChange={onSearch}
        value={searchValue}
        type="text"
        id="support-search-input"
        placeholder="Search by name"
      />
    </div>
  );
};
