import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import MDEditor from '@uiw/react-md-editor';

import { createFaq, updateFaq } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  question: '',
  module: '',
  answer: [''],
  answerMD: [''],
  megaModuleId: '',
};

export default function AddFaqModal({ open, onClose, data, moduleArray, megaModuleList }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    onClose();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await createFaq(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdateSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await updateFaq({ ...fieldValue, _id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const incAnswerArray = () => {
    if (fieldValue.answer.length === 5) {
      toast.error('You can not add more than 5 answer');
      return;
    }
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.answer.push('');
      newPrev.answerMD.push('');
      return newPrev;
    });
  };
  const decAnswerArray = () => {
    if (fieldValue.answer.length === 1) {
      toast.error('You must have 1 answer');
      return;
    }
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.answer.pop();
      newPrev.answerMD.pop();
      return newPrev;
    });
  };
  const onChangeAnswerArray = (index, value) => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.answer[index] = value;
      return newPrev;
    });
  };
  const onChangeAnswerMDArray = (index, value) => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.answerMD[index] = value;
      return newPrev;
    });
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        question: data?.question || '',
        module: data?.module || '',
        answer: data?.answer || [''],
        answerMD: data?.answerMD || [''],
        megaModuleId: data?.megaModuleId?._id,
      });
    } else {
      setFieldValue(initFieldValue);
    }
  }, [open, data]);

  const isCreateEditButtonDisable =
    fieldValue.module === '' ||
    fieldValue.question === '' ||
    fieldValue.megaModuleId === '' ||
    fieldValue.answer[0] === '';

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add FAQ' : 'Update FAQ'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* mega-module */}
          <TextField
            value={fieldValue.megaModuleId}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, megaModuleId: e.target.value }))}
            required
            select
            label="Mega Module"
            helperText="We only display mega module name and logo in normal user side"
            size="small"
            fullWidth
            variant="outlined"
          >
            {megaModuleList?.map((e, index) => (
              <MenuItem key={index} value={e?._id}>
                {e?.megaModule}
              </MenuItem>
            ))}
          </TextField>
          {/* module */}
          <TextField
            value={fieldValue.module}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, module: e.target.value }))}
            required
            select
            label="Module Key"
            helperText="This is main key, this list and can't be change"
            size="small"
            fullWidth
            variant="outlined"
          >
            {moduleArray?.map((e, index) => (
              <MenuItem key={index} value={e}>
                {e}
              </MenuItem>
            ))}
          </TextField>
          {/* question */}
          <TextField
            value={fieldValue.question}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, question: e.target.value }))}
            required
            label="Question"
            name="question"
            size="small"
            type="text"
            multiline
            minRows={2}
            maxRows={3}
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
          />
          <div
            style={{
              padding: '0px 12px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#6a7a87',
            }}
          >
            <span>Answers List</span>
            <div style={{ display: 'flex', gap: '8px' }}>
              <IconButton disabled={isSubmitting} onClick={decAnswerArray} size="small">
                <RemoveIcon />
              </IconButton>
              <IconButton disabled={isSubmitting} onClick={incAnswerArray} size="small">
                <AddIcon />
              </IconButton>
            </div>
          </div>
          {/* answers array */}
          {fieldValue?.answer?.map((ans, index) => (
            <React.Fragment key={index}>
              <span>Answer {index + 1} Markdown & Text Format</span>
              <MDEditor
                height="250px"
                data-color-mode="light"
                value={fieldValue?.answerMD[index] || ''}
                onChange={(e) => onChangeAnswerMDArray(index, e)}
              />
              <TextField
                value={ans}
                onChange={(e) => onChangeAnswerArray(index, e.target.value)}
                required={index === 0}
                label={`Answer ${index + 1} Text`}
                name={`answer${index}`}
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                multiline
                minRows={2}
                maxRows={3}
                disabled={isSubmitting}
              />
            </React.Fragment>
          ))}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting ? true : isCreateEditButtonDisable}
            onClick={data === null ? onSubmit : onUpdateSubmit}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            {data === null ? 'Create' : 'Update'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
