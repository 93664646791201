/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-boolean-value */
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Menu,
  MenuItem,
  FormControl,
  FormLabel,
  Tooltip,
  IconButton,
  useTheme,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CSVLink } from 'react-csv';

// components
import { jsPDF } from 'jspdf';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead } from '../../sections/user';
import {
  CompaniesBulkUpload,
  DownloadLastBulkUpload,
  GetcompaniesResponse,
  SearchCompany,
  createBusinessLog,
} from '../../services/Service';
import DeleteCompanyModal from '../../components/Modals/DeleteCompanyModal';
import EditCompanyModal from '../../components/Modals/EditCompanyModal';
import AddCompanyModal from '../../components/Modals/AddCompanyModal';
import ActionMenu from './ActionMenu';
import { setBusiness } from '../../store/businessSlice';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';
import { debounce } from '../../utils/fn';
import 'jspdf-autotable';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    id: 'companyType',
    label: 'Type',
    alignRight: false,
  },
  { id: 'name', label: 'Name', alignRight: false, sort: true },
  { id: 'logo', label: 'Logo', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'website', label: 'Website', alignRight: false },
  { id: 'countryCode', label: 'Country code', alignRight: false, sort: true },
  { id: 'price', label: 'Price', alignRight: false, sort: true },
  // { id: 'ind', label: 'Price', alignRight: false, sort: true },
  // { id: 'price', label: 'Price', alignRight: false, sort: true },
  { id: 'popular', label: 'Popular', alignRight: false },
  { id: 'updatedAt', label: 'Updated At', alignRight: false, sort: true },
  { id: 'updatedBy', label: 'Updated By', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
  // { id: 'edit', label: 'Edit', alignRight: false },
  // { id: 'delete', label: 'Delete', alignRight: false },
];

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, cname) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    if (cname === 'description') {
      return filter(array, (_user) => _user?.description.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
    }
    if (cname === 'subscriptionName') {
      return filter(array, (_user) => _user?.subscriptionName.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
    }
    if (cname === 'updatedAt') {
      return filter(array, (_user) => _user?.updatedAt.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CompaniesList() {
  const [page, setPage] = useState(0);
  const [logoUploadFile, setLogoUploadFile] = React.useState(null);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [editData, setEditData] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [openSub, setOpenSub] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [cname, setCname] = useState('updatedAt');
  const [deleteid, setDeleteId] = useState();

  const [csvData, setCSVdata] = useState([]);
  const theme = useTheme();
  const [actionMenu, setActionmenu] = React.useState(null);
  const actionOpen = Boolean(actionMenu);
  const { formatDateTime } = useUserTimeZone();
  const handleActionClick = (event) => {
    setActionmenu(event.currentTarget);
  };

  const handleActionClose = () => {
    setActionmenu(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { BusinessData } = useSelector((state) => state.business);
  const openMenu = Boolean(anchorEl);

  React.useEffect(() => {
    GetcompaniesResponse()
      .then((res) => {
        if (res.data.success === true) {
          dispatch(setBusiness({ business: res.data.data }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/login');
        }
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = BusinessData.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleFilterByName = (event) => {
  //   setFilterName(event.target.value);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenSub = () => {
    setOpenSub(true);
  };
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handledelete = (id) => {
    setDeleteId(id);
  };
  const handleDeleteMultiple = (id) => {
    setDeleteId(id);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - BusinessData.length) : 0;

  const filteredSubs = applySortFilter(BusinessData, getComparator(order, orderBy), filterName, cname);

  const isUserNotFound = filteredSubs.length === 0;

  const headers = [
    { label: 'No.', key: 'no' },
    { label: 'Type', key: 'companyType' },
    { label: 'Name', key: 'name' },
    { label: 'Description', key: 'description' },
    { label: 'Website', key: 'website' },
    { label: 'Country', key: 'countryCode' },
    // { label: 'Price', key: 'price' },
    { label: 'Popular', key: 'popular' },
    { label: 'Updated At', key: 'updatedAt' },
    { label: 'Updated By', key: 'updatedBy' },
    { label: 'Price', key: 'price' },
  ];

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(() => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: 1,
      minWidth: 180,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          marginRight: 1.5,
        },
      },
    },
  }));

  const sortData = filteredSubs.map((row) => ({
    ...row,
    startDate: moment(row.createdAt).format('MM-DD-yyyy'),
    nextBilling: moment(row.updatedAt).format('MM-DD-yyyy'),
    popular: row.popular ? 'Yes' : 'No',
    price: `${row?.countryCode === 'IND' ? '₹' : '$'}${row?.price}`,
  }));

  const fatchCSV = async () => {
    try {
      const responseData = await DownloadLastBulkUpload();

      setCSVdata(responseData.data);
    } catch (err) {
      console.log(err);
    }
  };

  const downloadCSV = async (event, done) => {
    try {
      handleCloseMenu();
      const responseData = await DownloadLastBulkUpload();
      await createLog('company', 'Exported Last uploaded CSV.', 'N/A', 'N/A');
      setCSVdata(responseData.data);
      done();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fatchCSV();
  }, []);

  const handleCompanySearch = (val) => {
    SearchCompany({ name: val })
      .then((res) => {
        console.log('this is searchcompany response => ', res.data);
        if (res.data.success === true) {
          dispatch(setBusiness({ business: res.data.data }));
        }
      })
      .catch((err) => {
        console.log('this is searchcompany response => ', err);

        ErrorToast(err.response.data.message);
      });
  };

  const debouncedComapnySearch = React.useMemo(() => debounce(handleCompanySearch, 1000), []);

  const handleExportPDF = async (event) => {
    event.preventDefault();
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();

    const tableContent = sortData.map((row, index) => {
      return [
        index + 1,
        row?.companyType,
        row?.name,
        row?.description,
        row?.website,
        row?.countryCode,
        row?.popular,
        moment(row?.updatedAt).format('MM-DD-yyyy'),
        row?.updatedBy,
        row?.price,
      ];
    });

    const tableHeaders = headers.map((header) => header.label);

    doc.autoTable({
      startY: 30,
      margin: { horizontal: 10 },
      head: [tableHeaders],
      body: tableContent,
      columnStyles: {
        0: { columnWidth: 10 },
        1: { columnWidth: 20 },
        2: { columnWidth: 20 },
        3: { columnWidth: 20 },
        4: { columnWidth: 30 },
        5: { columnWidth: 15 },
        6: { columnWidth: 15 },
        7: { columnWidth: 20 },
        8: { columnWidth: 20 },
      },
      // eslint-disable-next-line func-names
      didParseCell: function (data) {
        if (data.row.index === 0) {
          data.cell.styles.fontStyle = '';
        } else {
          const cellData = data.cell.raw;
          if (typeof cellData === 'string' && cellData.length > 20) {
            data.cell.text = `${cellData.substring(0, 20)}...`;
          }
        }
      },
      didDrawPage: function (data) {
        const currentPage = doc.internal.getNumberOfPages();
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        // Header
        if (currentPage === 1) {
          doc.setFontSize(20);
          doc.setTextColor(40);
          doc.text('Safal Subscriptions', data.settings.margin.left, 22);
          doc.setFontSize(12);
          doc.setTextColor(40);
          doc.text(formatDateTime(new Date()), pageWidth - 60, 22);
        }

        // Footer
        const str = `Page ${currentPage}`;
        doc.setFontSize(10);

        const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });

    doc.save('Master-Companies.pdf');

    await createLog('company', 'Exported companies list in PDF format.', 'N/A', 'N/A');
  };

  const handleDownload = async (format) => {
    // Your custom logic here, for example, logging a message
    await createLog('company', `Exported companies list in ${format} format.`, 'N/A', 'N/A');
  };

  const createLog = async (module, message, oldVal, newVal) => {
    await createBusinessLog({
      module: module,
      message: message,
      oldVal: oldVal,
      newVal: newVal,
    })
      .then((res) => {
        console.log('Log created successfully!');
      })
      .catch((err) => {
        console.log('Error While creating log => ', err.message);

        ErrorToast(err.response.data.message);
      });
  };

  return (
    <Page title="Companieslist - SafalSubscriptions">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027C34' }}>
          Companies list
        </Typography>

        <SearchStyle
          // value={filterName}
          onChange={(e) => debouncedComapnySearch(e.target.value)}
          placeholder="Search Company..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
        <Box sx={{ display: 'flex' }}>
          <FormControl>
            <input
              id="contained-button-file3"
              style={{ display: 'none' }}
              type="file"
              name="Bulk-upload"
              onChange={(e) => {
                CompaniesBulkUpload({ csvfile: e.target.files[0] })
                  .then((res) => {
                    if (res.data.success === true) {
                      DownloadLastBulkUpload().then((responseData) => {
                        setCSVdata(responseData.data);
                      });

                      SuccessToast('Uploaded Successfully');
                      GetcompaniesResponse().then((res) => {
                        if (res.data.success === true) {
                          dispatch(setBusiness({ business: res.data.data }));
                        }
                      });
                    } else {
                      ErrorToast(res.data.errors);
                    }
                  })
                  .catch((e) => {
                    ErrorToast(e?.response?.data?.message);
                  });
                setLogoUploadFile(e.target.files[0]);
              }}
              accept=".csv ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <FormLabel htmlFor="contained-button-file3">
              <Button
                variant="contained"
                component="span"
                sx={{
                  backgroundColor: '#027C34',
                  ':hover': {
                    backgroundColor: '#027C34',
                  },
                }}
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Bulk Upload
              </Button>
            </FormLabel>
          </FormControl>
          <Button
            onClick={handleClickOpenSub}
            variant="contained"
            component={RouterLink}
            startIcon={<Iconify icon="eva:plus-fill" />}
            sx={{
              marginLeft: '15px',
              backgroundColor: '#027C34',
              ':hover': {
                backgroundColor: '#027C34',
              },
            }}
          >
            ADD Company
          </Button>
          <Box sx={{ height: '100%', alignItems: 'center' }}>
            <Button
              id="demo-customized-button"
              aria-controls={openMenu ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClickMenu}
              startIcon={<Iconify icon="ic:round-cloud-download" />}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                marginLeft: '15px',
                backgroundColor: '#027C34',
                ':hover': {
                  backgroundColor: '#027C34',
                },
              }}
            >
              Download
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
            >
              <CSVLink
                data={sortData}
                headers={headers}
                filename={'companies-list'}
                className="btn btn-primary"
                target="_blank"
                style={{ textDecoration: 'none', color: 'black' }}
                onClick={() => handleDownload('CSV')}
              >
                <MenuItem onClick={handleCloseMenu} disableRipple>
                  Export as Comma-Separated Spreadsheet(.CSV)
                </MenuItem>
              </CSVLink>
              <CSVLink
                separator={'\t'}
                data={sortData}
                headers={headers}
                filename={'Master-Companies.dnl'}
                className="btn btn-primary"
                target="_blank"
                style={{ textDecoration: 'none', color: 'black' }}
                onClick={() => handleDownload('DNL')}
              >
                <MenuItem onClick={handleCloseMenu} disableRipple>
                  Export as Tab-Delimited Spreadsheet(.DNL)
                </MenuItem>
              </CSVLink>
              <CSVLink
                data={sortData}
                headers={headers}
                filename={'Master-Companies.txt'}
                className="btn btn-primary"
                target="_blank"
                style={{ textDecoration: 'none', color: 'black' }}
                onClick={() => handleDownload('TXT')}
              >
                <MenuItem onClick={handleCloseMenu} disableRipple>
                  Export as Plain Text(.TXT)
                </MenuItem>
              </CSVLink>

              <CSVLink
                data={csvData}
                filename={'Master-Companies.csv'}
                asyncOnClick={true}
                className="btn btn-primary"
                target="_blank"
                style={{ textDecoration: 'none', color: 'black' }}
                onClick={downloadCSV}
              >
                <MenuItem disableRipple>Last uploaded CSV</MenuItem>
              </CSVLink>

              <CSVLink
                data={sortData}
                headers={headers}
                filename={'Master-Companies.pdf'}
                className="btn btn-primary"
                target="_blank"
                style={{ textDecoration: 'none', color: 'black' }}
                onClick={handleExportPDF}
              >
                <MenuItem onClick={handleCloseMenu} disableRipple>
                  Export as PDF(.PDF)
                </MenuItem>
              </CSVLink>
            </StyledMenu>
          </Box>
        </Box>
      </Stack>
      {selected.length > 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 2,
            backgroundColor: theme.palette.info.table,
            borderRadius: '35px',
            width: 'fit-content',
            height: '50px',
          }}
        >
          <Typography component="div" variant="subtitle1" color={theme.palette.secondary.font}>
            {selected.length} selected
          </Typography>

          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setOpenDelete(true);
                handleDeleteMultiple(selected);
              }}
            >
              <Iconify sx={{ color: '#DF3E30' }} icon="ic:baseline-delete-outline" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, overflowX: 'auto' }}>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={filteredSubs.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />

            <TableBody>
              {filteredSubs &&
                filteredSubs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const isItemSelected = selected.indexOf(row._id) !== -1;
                  return (
                    <>
                      {/* {row.price.map((val) => ( */}
                      <>
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell
                            padding="checkbox"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderBottomLeftRadius: '35px',
                              borderTopLeftRadius: '35px',
                            }}
                          >
                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row._id)} />
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <Typography variant="subtitle2" noWrap>
                              {row?.companyType}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                            {row?.name}
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                            <img height="30px" src={row?.logo} alt="Logo" style={{ maxWidth: 'none' }} />
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                            <span
                              style={{
                                width: '300px',
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {row?.description}
                            </span>
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                            <a
                              href={row?.website.startsWith('http') ? row?.website : `https://${row?.website}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {row?.website}
                            </a>
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                            {row?.countryCode === 'IND' ? (
                              <img
                                src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
                                alt="IND"
                                height={30}
                                width={40}
                              />
                            ) : (
                              <img
                                src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
                                alt="USA"
                                height={30}
                                width={40}
                              />
                            )}
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                            {row?.countryCode === 'IND' ? `₹` : `$`}
                            {row?.price}
                          </TableCell>
                          {/* <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                          ₹{row?.price?.ind}
                        </TableCell> */}
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                            {row?.popular ? 'Yes' : 'No'}
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                            {moment(row?.updatedAt).format('MM/DD/yyyy')} {getTimeZoneAbbreviation()}
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                            {row?.updatedBy}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderBottomRightRadius: '35px',
                              borderTopRightRadius: '35px',
                            }}
                          >
                            <Button
                              id="action-button"
                              aria-controls={actionOpen ? 'action-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={actionOpen ? 'true' : undefined}
                              onClick={(e) => {
                                handleActionClick(e);
                                setEditData(row);
                              }}
                            >
                              <MoreVertIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    </>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <AddCompanyModal openEditModal={openSub} setOpenEditModal={setOpenSub} />
      <EditCompanyModal
        openEditModal={open}
        setOpenEditModal={setOpen}
        data={editData}
        handleActionClose={handleActionClose}
      />
      <DeleteCompanyModal
        openDeleteModal={openDelete}
        setOpenDelete={setOpenDelete}
        id={[deleteid]}
        setSelected={setSelected}
        handleActionClose={handleActionClose}
      />
      <ActionMenu
        row={editData}
        actionMenu={actionMenu}
        actionOpen={actionOpen}
        handleActionClick={handleActionClick}
        handleActionClose={handleActionClose}
        handleClickOpen={handleClickOpen}
        setOpenDelete={setOpenDelete}
        handledelete={handledelete}
        setSelected={setSelected}
        setEditData={setEditData}
      />

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={BusinessData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}
