import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFireToken } from '../services/Service';
// import { getFireToken } from '../firebase';

// const requestPermission = (setToken) => {
//   console.log('Requesting permission...');
//   Notification.requestPermission().then((permission) => {
//     if (permission === 'granted') {
//       getFireToken(setToken);

//       console.log('Notification permission granted.');
//     } else {
//       console.log('Notification permission denied.');
//     }
//   });
// };
const NotificationListener = () => {
  const [token, setToken] = useState(null);
  const { user } = useSelector((state) => state.login);
  // requestPermission(setToken);

  useEffect(() => {
    if (token && user) {
      updateFireToken({ id: user._id, token });
    }
  }, token);

  return null;
};

export default NotificationListener;
