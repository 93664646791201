/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-else-return */

import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import callAudioFile from '../../assets/audio.mp3';
import { db } from '../../firebase';
import DisplayAvatar from '../Chat/DisplayAvatar';

const CallPopUp = ({ onAcceptCall, onRejectCall, onCallRejectEnd, agoraState, currentFirebaseUser }) => {
  const [displayName, setDisplayName] = useState('');
  const [imageFetchUID, setImageFetchUID] = useState(undefined);

  useEffect(() => {
    try {
      if (!currentFirebaseUser) return;
      if (!agoraState) return;

      let fetchId = '';
      if (agoraState.type === 'support' && currentFirebaseUser.uid === agoraState.currentUserId) {
        setDisplayName('support');
        return;
      } else if (agoraState.type === 'support' && currentFirebaseUser.uid !== agoraState.currentUserId) {
        fetchId = agoraState.currentUserId;
      } else {
        fetchId =
          currentFirebaseUser.uid === agoraState.otherUserId ? agoraState.currentUserId : agoraState.otherUserId;
      }
      setImageFetchUID(fetchId);
      const callDocRef = doc(db, 'users', fetchId);
      getDoc(callDocRef).then((snap) => {
        if (snap.exists()) setDisplayName(snap.data().displayName);
      });
    } catch (err) {
      setDisplayName('');
    }
  }, [agoraState, currentFirebaseUser]);

  const isRender = agoraState.status === 'pending' && currentFirebaseUser?.uid === agoraState.currentUserId;

  return isRender ? (
    <div className="CallPopUp">
      <div style={{ marginBottom: '10px' }}>
        <DisplayAvatar
          uid={imageFetchUID}
          isSupport={agoraState.type === 'support' && currentFirebaseUser?.uid === agoraState.currentUserId}
        />
      </div>
      <span>Call connecting... {displayName}</span>
      <div className="CallPopUp-btn-div">
        <button className="Call-Reject" onClick={onCallRejectEnd}>
          End
        </button>
      </div>
    </div>
  ) : (
    <div className="CallPopUp">
      <audio style={{ display: 'none' }} src={callAudioFile} autoPlay loop></audio>
      <div style={{ marginBottom: '10px' }}>
        <DisplayAvatar
          uid={imageFetchUID}
          isSupport={agoraState.type === 'support' && currentFirebaseUser?.uid === agoraState.currentUserId}
        />
      </div>
      <span>{agoraState.callMode} call from</span>
      <span>{displayName}</span>
      <div className="CallPopUp-btn-div">
        <button className="Call-Accept" onClick={onAcceptCall}>
          Accept
        </button>
        <button className="Call-Reject" onClick={onRejectCall}>
          Reject
        </button>
      </div>
    </div>
  );
};

export default CallPopUp;
