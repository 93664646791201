import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import { createPaymentModeBusiness, updatePaymentModeBusiness } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  status: true, // default true
  cardSchemeField: {
    label: '',
    status: true,
  },
  nickNameField: {
    label: '',
    status: true,
  },
  instrumentField: {
    label: '',
    status: true,
  },
};

export default function AddPaymentModeModal({ open, onClose, data, isDisplay }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      const res = await createPaymentModeBusiness(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const res = await updatePaymentModeBusiness({ _id: data._id, ...fieldValue });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        name: data?.name,
        status: data?.status, // default true
        cardSchemeField: {
          label: data?.cardSchemeField?.label,
          status: data?.cardSchemeField?.status,
        },
        nickNameField: {
          label: data?.nickNameField?.label,
          status: data?.nickNameField?.status,
        },
        instrumentField: {
          label: data?.instrumentField?.label,
          status: data?.instrumentField?.status,
        },
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Payment Mode' : isDisplay ? 'Payment Mode' : 'Update Payment Mode'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Name"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          <TextField
            value={fieldValue.status === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
          {/* cardSchemeField */}
          <div>
            <span style={{ marginLeft: '12px' }}>Card Scheme Field</span>
            <div style={{ display: 'flex', gap: '12px' }}>
              <TextField
                value={fieldValue.cardSchemeField.label}
                onChange={(e) =>
                  setFieldValue((prev) => ({
                    ...prev,
                    cardSchemeField: { ...prev.cardSchemeField, label: e.target.value },
                  }))
                }
                required
                label="Label"
                size="small"
                type="text"
                variant="outlined"
                disabled={isDisableAll}
                sx={{ flex: '1' }}
              />
              <TextField
                value={fieldValue.cardSchemeField.status === true ? 'Active' : 'Inactive'}
                onChange={(e) =>
                  setFieldValue((prev) => ({
                    ...prev,
                    cardSchemeField: { ...prev.cardSchemeField, status: e.target.value === 'Active' },
                  }))
                }
                required
                select
                label="Status"
                size="small"
                disabled={isDisableAll}
                variant="outlined"
                sx={{ width: '150px', flex: 'none' }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
            </div>
          </div>
          {/* nickNameField */}
          <div>
            <span style={{ marginLeft: '12px' }}>Nick Name Field</span>
            <div style={{ display: 'flex', gap: '12px' }}>
              <TextField
                value={fieldValue.nickNameField.label}
                onChange={(e) =>
                  setFieldValue((prev) => ({
                    ...prev,
                    nickNameField: { ...prev.nickNameField, label: e.target.value },
                  }))
                }
                required
                label="Label"
                size="small"
                type="text"
                variant="outlined"
                disabled={isDisableAll}
                sx={{ flex: '1' }}
              />
              <TextField
                value={fieldValue.nickNameField.status === true ? 'Active' : 'Inactive'}
                onChange={(e) =>
                  setFieldValue((prev) => ({
                    ...prev,
                    nickNameField: { ...prev.nickNameField, status: e.target.value === 'Active' },
                  }))
                }
                required
                select
                label="Status"
                size="small"
                disabled={isDisableAll}
                variant="outlined"
                sx={{ width: '150px', flex: 'none' }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
            </div>
          </div>
          {/* instrumentField */}
          <div>
            <span style={{ marginLeft: '12px' }}>Instrument Field</span>
            <div style={{ display: 'flex', gap: '12px' }}>
              <TextField
                value={fieldValue.instrumentField.label}
                onChange={(e) =>
                  setFieldValue((prev) => ({
                    ...prev,
                    instrumentField: { ...prev.instrumentField, label: e.target.value },
                  }))
                }
                required
                label="Label"
                size="small"
                type="text"
                variant="outlined"
                disabled={isDisableAll}
                sx={{ flex: '1' }}
              />
              <TextField
                value={fieldValue.instrumentField.status === true ? 'Active' : 'Inactive'}
                onChange={(e) =>
                  setFieldValue((prev) => ({
                    ...prev,
                    instrumentField: { ...prev.instrumentField, status: e.target.value === 'Active' },
                  }))
                }
                required
                select
                label="Status"
                size="small"
                disabled={isDisableAll}
                variant="outlined"
                sx={{ width: '150px', flex: 'none' }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </TextField>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
