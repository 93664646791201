import React from 'react';
import Page from '../../components/Page';

const AdminTest = () => (
  <Page title="AdminDashboard - SafalSubscriptions">
    <div>AdminTest</div>
  </Page>
);

export default AdminTest;
