import { useCookies } from 'react-cookie';

import { Box, Button, Fab, Modal, Typography, useTheme } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { signOut } from 'firebase/auth';

import moment from 'moment';

import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
// clean redux store
// import { resetSubscriptions } from 'src/store/subscriptionSlice';
import { resetSubscriptions } from '../../store/subscriptionSlice';
import { resetCompanies } from '../../store/companiesSlice';
import { resetFeedback } from '../../store/feedbackSlice';
import { resetChat } from '../../store/ChatSlice';
import { resetCall } from '../../store/callSlice';
import { resetBusiness } from '../../store/businessSlice';
import { resetAuth } from '../../store/AuthSlice';
import { getNotifications, logout, resetLogin } from '../../store/loginSlice';
import { auth, db } from '../../firebase';
import SuccessToast from '../Toast/Success';

import ErrorToast from '../Toast/Error';

const LogoutModal = ({ openLogOut, setOpenLogOut, setOpen }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { currentUser } = useSelector((state) => state.auth);
  const [removeCookie] = useCookies(['token']);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
    // boxShadow: 24,
  };
  const handleLogout = async () => {
    setOpen(null);
    signOut(auth);
    console.log('signInWithEmailAndPassword handleLogout');
    dispatch(logout())
      .unwrap()
      .then(async () => {
        // removeCookie("token", "", {
        //   expires: moment().subtract(1, "hours").toDate(),
        // });

        SuccessToast('Please come back soon !');
        // clean redux store
        dispatch(resetSubscriptions());
        dispatch(resetLogin());
        dispatch(resetFeedback());
        dispatch(resetCompanies());
        dispatch(resetChat());
        dispatch(resetCall());
        dispatch(resetBusiness());
        dispatch(resetAuth());
        const tempUserID = localStorage.getItem('userId');
        localStorage.clear();
        localStorage.setItem('userId', tempUserID);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 300);
      })
      .catch((err) => {
        ErrorToast('Something went wrong!!!');
        // removeCookie("token", "", {
        //   expires: moment().subtract(1, "hours"),
        // });

        // removeCookie('token');
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      });
  };
  return (
    <>
      <Modal
        open={openLogOut}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            '@media (max-width: 640px)': {
              width: '95vw',
            },
            width: 500,
            height: 180,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab
              onClick={() => setOpenLogOut(false)}
              size="small"
              aria-label="add"
              sx={{ background: theme.palette.secondary.title }}
            >
              <CloseIcon />
            </Fab>
          </Box>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Are you sure you want to Log Out ?
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Button
                variant="contained"
                onClick={() => handleLogout()}
                sx={{ background: theme.palette.secondary.title }}
              >
                Yes
              </Button>
            </Box>

            <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
              <Button
                variant="contained"
                sx={{ background: theme.palette.secondary.title }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenLogOut(false);
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LogoutModal;
