import { Box, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .ss_logo {
    position: fixed;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .rotate {
    width: 100px;
    animation: rotation 1.5s infinite linear;
  }
`;

const Inlineloader = (props) => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 9999,
      background: 'white',
      borderRadius: '10%',
      height: '150px',
      width: '150px',
      display : "flex" ,
      alignItems : 'center',
      justifyContent : 'center'
    }}
  >
    <StyledLoader>
      <img src="/assets/images/Loader.png" className="rotate" alt="logo" />
      <img src="/assets/images/ssLoaderIcon.png" alt="logo" className="ss_logo" />
    </StyledLoader>
  </Box>
);

export default Inlineloader;
