import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

export const CallRecoirdingAlertSlice = createSlice({
  name: 'CallRecoirdingAlertSlice',
  initialState,
  reducers: {
    setCallRecoirdingAlertState: (state, action) => {
      state = action.payload;
      return state;
    },
    resetCallRecoirdingAlertState: () => {
      return initialState;
    },
  },
});

export const { setCallRecoirdingAlertState, resetCallRecoirdingAlertState } = CallRecoirdingAlertSlice.actions;

export default CallRecoirdingAlertSlice.reducer;
