import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Avatar, Image } from 'antd';
import { IconButton, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import XIcon from '@mui/icons-material/X';
import { LinkedIn } from '@mui/icons-material';

import likeImg from '../../../assets/Like.png';
import disLike from '../../../assets/disLike.png';
import likeDislike from '../../../assets/likeDislike.png';

import shareFacebook from '../../../assets/share-facebook.png';
import shareLinkedin from '../../../assets/share-linkedin.png';
import shareMail from '../../../assets/share-mail.png';
import shareTwitter from '../../../assets/share-twitter.png';

import { fallbackImage } from '../../../utils/general';
import {
  addSafalLeaderBoardLog,
  createOfferComments,
  createOfferCommentsReply,
  getOffersCommentsListUser,
  getSignedURL,
  updateOfferComments,
} from '../../../services/Service';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import SingleOfferReplyModal from './SingleOfferReplyModal';
import { UserNewProfileModal } from '../../../components/Modals/UserNewProfileModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const offerEmail = 'support@safalvir.com';

export default function SingleOfferModal({ open, onClose, data }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [commentsList, setCommentsList] = React.useState([]);
  const [message, setMessage] = React.useState('');
  const [singleReply, setSingleReply] = React.useState({
    data: null,
    open: false,
  });
  const handleClose = () => {
    onClose();
    setIsSubmitting(false);
    setIsLoading(false);
    setCommentsList([]);
    setMessage('');
    setSingleReply({
      data: null,
      open: false,
    });
  };

  const fetchComments = async (d) => {
    setIsLoading(true);
    try {
      const res = await getOffersCommentsListUser({ id: d._id });
      setCommentsList(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateComments = async (id, like) => {
    setIsSubmitting(true);
    try {
      const res = await updateOfferComments({ commentsId: id, like });
      setMessage('');
      fetchComments(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const addComments = async (id, like) => {
    setIsSubmitting(true);
    try {
      if (message.length < 4) {
        toast.error('Comment message must be at least 3 characters long');
        return;
      }
      const res = await createOfferComments({ message: message, offerId: data._id });
      setMessage('');
      fetchComments(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      fetchComments(data);
    }
  }, [open, data]);

  const emailLink = `mailto:${offerEmail}?subject=SafalSubscriptions%20Offers&body=${data?.title}%0A${data?.subtitle}`;
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${data?.redirectLink}`;
  const twitterLink = `https://twitter.com/intent/tweet?text=${data?.title}&url=${data?.redirectLink}`;
  const linkedInLink = `http://www.linkedin.com/shareArticle?mini=true&url=${data?.redirectLink}&title=${data?.title}`;

  const onOpenSingleReply = (data) => {
    setSingleReply({
      data: data,
      open: true,
    });
  };
  const onCloseSingleReply = () => {
    setSingleReply({
      data: null,
      open: false,
    });
    fetchComments(data);
  };

  const addSafalLeaderBoard = async (offerId) => {
    try {
      await addSafalLeaderBoardLog({
        activityKey: 'OffersSharingOnSocialMediaPlatform',
        application: 'SafalSubscriptions',
        extraKey: offerId,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const addSafalLeaderBoard2 = async () => {
    try {
      await addSafalLeaderBoardLog({
        activityKey: 'OffersActualCompanyOffersLinkClicked',
        application: 'SafalSubscriptions',
        extraKey: data?._id,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
              <a
                onClick={() => {
                  addSafalLeaderBoard2();
                  return true;
                }}
                title="Click to open offer redirect link"
                href={data?.redirectLink}
                rel="noreferrer"
                target="_blank"
              >
                <Image
                  fallback={fallbackImage}
                  preview={false}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '16px',
                    border: '1px solid #bfbfbf',
                  }}
                  height="80px"
                  width="80px"
                  src={data?.company?.logo}
                />
              </a>
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: '16px',
                  lineHeight: 'normal',
                  overflow: 'hidden',
                  color: 'black',
                  justifyContent: 'space-evenly',
                }}
              >
                <span
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    fontSize: '24px',
                    fontWeight: 'bold',
                  }}
                >
                  {data?.title}
                </span>
                <hr />
                <span
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                >
                  {data?.subtitle}
                </span>
                <p>{data?.description}</p>
                <a
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    color: 'black',
                  }}
                  href={data?.company?.website}
                  rel="noreferrer"
                  target="_blank"
                >
                  {data?.company?.website}
                </a>
              </div>
            </div>
            <Image
              fallback={fallbackImage}
              preview={false}
              style={{
                objectFit: 'cover',
                borderRadius: '16px',
                border: '1px solid #bfbfbf',
              }}
              height="120px"
              width="100%"
              src={data?.image}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                flexWrap: 'wrap',
              }}
            >
              {data?.socialMedia?.map((social, index) => {
                if (social?.isWeb === false) return <></>;
                const newRedirectLink = social?.webRedirectLink?.replace(
                  '[[BODY]]',
                  `
${data?.company?.name} - ${data?.title}
  \nLink - ${process.env.REACT_APP_API_URL}/${data?.company?.companyType}/${data?._id}
  \nShare via SafalSubscriptions
`
                );
                const newRedirectLink1 = newRedirectLink?.replace(
                  '[[LINK]]',
                  `${process.env.REACT_APP_API_URL}/${data?.company?.companyType}/${data?._id}`
                );
                return (
                  <IconButton
                    onClick={() => {
                      addSafalLeaderBoard(data?._id);
                    }}
                    key={index}
                    title={social?.name}
                    target="_blank"
                    href={newRedirectLink1}
                    size="small"
                  >
                    <img
                      src={social?.logo}
                      alt={social?.name}
                      style={{ width: '22px', height: '22px', objectFit: 'cover', borderRadius: '9999px' }}
                    />
                  </IconButton>
                );
              })}
            </div>
          </div>
          {/* Comments main */}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px', gap: '4px' }}>
            {/* Comments title */}
            <span style={{ fontSize: '16px', flex: 'none', fontWeight: 'bold' }}>Comments</span>
            {/* Comments text box */}
            <div style={{ display: 'flex', flex: 'none', alignItems: 'center', gap: '8px' }}>
              <TextField
                disabled={isSubmitting}
                sx={{ fontSize: '14px' }}
                size="small"
                multiline
                minRows={1}
                maxRows={3}
                fullWidth
                variant="outlined"
                placeholder="Enter your comments..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                title="Post Comment"
                disabled={isSubmitting}
                sx={{
                  backgroundColor: '#3D71FF',
                  textTransform: 'capitalize',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 'auto',
                  borderRadius: '30px',
                }}
                onClick={addComments}
                size="medium"
                variant="contained"
                endIcon={<SendIcon />}
              >
                Post
              </Button>
            </div>
            {isLoading && <div className="loader-x33" />}
            {/* Comments view box */}
            <div
              style={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                padding: '8px 0px',
                gap: '4px',
                scrollbarWidth: 'thin',
              }}
            >
              {!isLoading && commentsList?.length === 0 && (
                <span style={{ textAlign: 'center' }}>No comments found.</span>
              )}
              {commentsList.map((e, index) => (
                <SingleComment
                  onOpenSingleReply={() => onOpenSingleReply(e)}
                  disabled={isSubmitting}
                  data={e}
                  key={index}
                  onUpdate={updateComments}
                  fetchComments={() => fetchComments(data)}
                />
              ))}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
            <Button
              title="Cancel"
              onClick={handleClose}
              sx={{
                textTransform: 'capitalize',
                backgroundColor: '#ff4842',
                color: 'white',
                borderRadius: '30px',
                ':hover': { backgroundColor: '#d40700' },
              }}
              variant="contained"
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
      <SingleOfferReplyModal open={singleReply.open} data={singleReply.data} onClose={onCloseSingleReply} />
    </>
  );
}

const SingleComment = ({ data, onUpdate, disabled, fetchComments, onOpenSingleReply }) => {
  const { timeFromNow2 } = useUserTimeZone();
  const [message, setMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [singleReply, setSingleReply] = React.useState({
    data: null,
    open: false,
  });

  const addComments = async () => {
    setIsSubmitting(true);
    try {
      if (message.length < 4) {
        toast.error('reply message must be at least 3 characters long');
        return;
      }
      await createOfferCommentsReply({ message: message, offerId: data.offer, commentsId: data._id });
      setMessage('');
      fetchComments();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenSingleReply = () => {
    // onCloseSingleReply()
    setSingleReply({
      data: null,
      open: true,
    });
  };
  const handleCloseSingleReply = () => {
    // onCloseSingleReply()
    setSingleReply({
      data: null,
      open: false,
    });
  };
  const handleSingleReplyPost = () => {
    // onCloseSingleReply()
  };

  const [openUserNewProfileModal, setOpenUserNewProfileModal] = React.useState({
    open: false,
    data: null,
  });
  const openUserNewProfileOpen = (userId) => {
    if (!userId) return;
    setOpenUserNewProfileModal({
      open: true,
      data: userId,
    });
  };
  const openUserNewProfileClose = () => {
    setOpenUserNewProfileModal({
      open: false,
      data: null,
    });
  };

  return (
    <>
      <div style={{ display: 'flex', borderRadius: '12px' }}>
        <div style={{ flex: 'none', marginTop: '8px', marginLeft: '8px' }}>
          <ProfileImageState
            onClick={() => openUserNewProfileOpen(data?.whoCreated?._id)}
            src={data?.whoCreated?.profilePic}
            name={data?.whoCreated?.firstName[0]}
          />
        </div>
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            lineHeight: 'normal',
            fontSize: '16px',
            padding: '8px',
            borderRadius: '8px',
          }}
        >
          <div
            style={{
              backgroundColor: '#dfe6ff',
              display: 'flex',
              flexDirection: 'column',
              padding: '4px',
              borderRadius: '8px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 'normal', fontSize: '16px' }}>
              <span style={{ fontWeight: 'bold' }}>{data?.whoCreated?.firstName}</span>
              <span style={{ fontWeight: '200' }}>{timeFromNow2(data?.createdAt)}</span>
            </div>
            <p>{data.message}</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '4px' }}>
            <div
              style={{
                flex: 'none',
                display: 'flex',
                gap: '8px',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              <div style={{ flex: 'none', display: 'flex', gap: '12px' }}>
                <div>
                  {data?.likeDislikeUser?.likeCount > 0 && (
                    <span style={{ fontSize: '12px', marginRight: '2px' }}>{data?.likeDislikeUser?.likeCount}</span>
                  )}
                  <Image
                    onClick={() => !disabled && onUpdate(data._id, 1)}
                    title="Safal"
                    preview={false}
                    style={{
                      filter: data?.likeDislikeUser?.you?.like !== 1 && 'grayscale(100%)',
                      objectFit: 'contain',
                      cursor: 'pointer',
                    }}
                    height="35px"
                    src={likeImg}
                  />
                </div>
                <div>
                  {data?.likeDislikeUser?.dislikeCount > 0 && (
                    <span style={{ fontSize: '12px', marginRight: '2px' }}>{data?.likeDislikeUser?.dislikeCount}</span>
                  )}
                  <Image
                    onClick={() => !disabled && onUpdate(data._id, -1)}
                    title="Unsafal"
                    preview={false}
                    style={{
                      filter: data?.likeDislikeUser?.you?.like !== -1 && 'grayscale(100%)',
                      cursor: 'pointer',
                      objectFit: 'contain',
                    }}
                    height="35px"
                    src={disLike}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: 'none', alignItems: 'center', gap: '8px' }}>
              <TextField
                disabled={isSubmitting}
                sx={{ fontSize: '14px' }}
                size="small"
                multiline
                minRows={1}
                maxRows={3}
                fullWidth
                variant="outlined"
                placeholder="Enter your reply..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                title="Post Reply"
                disabled={isSubmitting}
                sx={{
                  backgroundColor: '#3D71FF',
                  textTransform: 'capitalize',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 'auto',
                  borderRadius: '30px',
                }}
                onClick={addComments}
                size="medium"
                variant="contained"
                endIcon={<SendIcon />}
              >
                Post
              </Button>
            </div>
            {!singleReply.open && data?.replies.length > 0 && (
              <div
                style={{
                  paddingLeft: '16px',
                  display: 'flex',
                  gap: '8px',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                  <ProfileImageState
                    onClick={() => openUserNewProfileOpen(data?.replies[0].whoCreated?._id)}
                    size="small"
                    src={data?.replies[0].whoCreated?.profilePic}
                    name={data?.replies[0].whoCreated?.firstName[0]}
                  />
                  <p>
                    <b style={{ marginRight: '8px' }}>{data?.replies[0].whoCreated?.firstName}</b>
                    {data?.replies[0].message}
                  </p>
                </div>
                {/* more reply */}

                {data?.replies.length > 1 && (
                  <button className="offer-link-btn" onClick={handleOpenSingleReply}>
                    View More
                  </button>
                )}
              </div>
            )}
            {singleReply.open &&
              data?.replies.length > 0 &&
              data?.replies.map((ep, index) => (
                <SingleReply
                  onProfileOpen={openUserNewProfileOpen}
                  onClose={handleCloseSingleReply}
                  key={index}
                  data={ep}
                  isLast={index === data?.replies.length - 1}
                />
              ))}
          </div>
        </div>
      </div>
      <UserNewProfileModal
        open={openUserNewProfileModal.open}
        userId={openUserNewProfileModal.data}
        onClose={openUserNewProfileClose}
      />
    </>
  );
};

export const ProfileImageState = ({ name, src, size, onClick }) => {
  const [profileImg, setProfileImg] = React.useState('');
  const getProfileImage = async (url) => {
    if (url?.includes('safalsubscriptions-assets.s3.amazonaws.com')) {
      const newRes = await getSignedURL({ url: url });
      setProfileImg(newRes.data);
    }
  };

  React.useEffect(() => {
    getProfileImage(src);
  }, [src]);
  const _size = size === undefined ? 'default' : size;

  return (
    <Avatar onClick={onClick} style={{ cursor: 'pointer', flex: 'none' }} size={_size} src={profileImg}>
      {name}
    </Avatar>
  );
};

const SingleReply = ({ data, onUpdate, disabled, isLast, onClose, onProfileOpen }) => {
  const { timeFromNow2 } = useUserTimeZone();
  return (
    <div style={{ display: 'flex', borderRadius: '12px', gap: '4px' }}>
      <div style={{ flex: 'none', marginTop: '8px', marginLeft: '8px' }}>
        <ProfileImageState
          onClick={() => onProfileOpen(data?.whoCreated?._id)}
          src={data?.whoCreated?.profilePic}
          name={data?.whoCreated?.firstName[0]}
        />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 'normal',
          fontSize: '16px',
          padding: '8px',
          borderRadius: '8px',
          backgroundColor: '#dfe6ff',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 'normal', fontSize: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>{data?.whoCreated?.firstName}</span>
          <span style={{ fontWeight: '200' }}>{timeFromNow2(data?.createdAt)}</span>
        </div>
        <p>{data.message}</p>
        {isLast && (
          <button style={{ marginLeft: 'auto' }} className="offer-link-btn" onClick={onClose}>
            View Less
          </button>
        )}
      </div>
    </div>
  );
};
