import React, { useState } from 'react';
import { Tab, Tabs, Typography } from '@mui/material';

import SecurityTabChangePassword from './SecurityTabChangePassword';
import SecurityTabPermissions from './SecurityTabPermissions';
import SecurityTabPublicSetting from './SecurityTabPublicSetting';

const SecurityTab = () => {
  const [selectedSubTab, setSelectedSubTab] = useState(0);

  function a11yProps(index) {
    return {
      id: `SecurityTab-${index}`,
      key: index,
      'aria-controls': `SecurityTabPanel-${index}`,
    };
  }
  const handleChangeSubTab = (event, newValue) => {
    setSelectedSubTab(newValue);
  };

  return (
    <>
      <Tabs
        value={selectedSubTab}
        onChange={handleChangeSubTab}
        fullWidth
        sx={{
          marginBottom: '16px',
          borderRadius: '30px',
          p: 0.5,
          color: '#000000',
          '@media (max-width: 640px)': {
            padding: '4px !important',
          },
          '& .Mui-selected svg': {
            fill: 'white',
            mr: 0.7,
          },
          '& .MuiButtonBase-root.MuiTab-labelIcon.Mui-selected': {
            borderRadius: '30px',
            backgroundColor: '#3D71FF',
            textTransform: 'none',
            textDecoration: 'none',
            color: '#FFFFFF',
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        <Tab
          fullWidth
          {...a11yProps(0)}
          label={
            <>
              <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>Change Password</Typography>
            </>
          }
          iconPosition="start"
          sx={{
            width: '20%',
            minHeight: '30px',
            textTransform: 'none',
          }}
          style={{
            color: selectedSubTab === 0 ? '#FFFFFF' : '#000000',
            borderRadius: selectedSubTab === 0 && '30px',
            backgroundColor: selectedSubTab === 0 && '#3D71FF',
            textDecoration: selectedSubTab === 0 && 'none',
          }}
        />
        <Tab
          fullWidth
          {...a11yProps(1)}
          label={
            <>
              <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>Permissions</Typography>
            </>
          }
          iconPosition="start"
          sx={{
            width: '20%',
            minHeight: '30px',
            textTransform: 'none',
          }}
          style={{
            color: selectedSubTab === 1 ? '#FFFFFF' : '#000000',
            borderRadius: selectedSubTab === 1 && '30px',
            backgroundColor: selectedSubTab === 1 && '#3D71FF',
            textDecoration: selectedSubTab === 1 && 'none',
          }}
        />
        <Tab
          fullWidth
          {...a11yProps(2)}
          label={
            <>
              <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>Public Setting</Typography>
            </>
          }
          iconPosition="start"
          sx={{
            width: '20%',
            minHeight: '30px',
            textTransform: 'none',
          }}
          style={{
            color: selectedSubTab === 2 ? '#FFFFFF' : '#000000',
            borderRadius: selectedSubTab === 2 && '30px',
            backgroundColor: selectedSubTab === 2 && '#3D71FF',
            textDecoration: selectedSubTab === 2 && 'none',
          }}
        />
      </Tabs>
      {selectedSubTab === 0 ? (
        <SecurityTabChangePassword />
      ) : selectedSubTab === 1 ? (
        <SecurityTabPermissions />
      ) : selectedSubTab === 2 ? (
        <SecurityTabPublicSetting />
      ) : (
        <></>
      )}
    </>
  );
};

export default SecurityTab;
