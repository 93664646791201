import { Avatar, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Image } from 'antd';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import moment from 'moment';
import LockResetIcon from '@mui/icons-material/LockReset';

import {
  createSafalRoomPublicNormalChat,
  getSafalRoomPublicNormalChat,
  getSignedURL,
  updateSafalRoomPublicNormalChat,
  uploadSafalRoomImages,
} from '../../../services/Service';
import likeImg from '../../../assets/Like.png';
import disLike from '../../../assets/disLike.png';
import FeedMessageModal from './FeedMessageModal';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import FeedFilterMenu from './FeedFilterMenu';
import { RoomMentionsDisplay } from '../../../components/RoomMentionsInput';
import { CustomImageViewInFeed } from '../../../components/UI/CustomImageView';
import { UserNewProfileModal } from '../../../components/Modals/UserNewProfileModal';

const SafalRoomFeed = ({ companyId, companyListForMentions }) => {
  const [chatData, setChatData] = useState([]);
  const [chatDisabled, setChatDisabled] = useState(false);
  const [newPostModal, setNewPostModal] = useState({
    open: false,
    data: null,
  });
  const [filterState, setFilterState] = useState({
    userId: '',
    toDate: '',
    fromDate: '',
    search: '',
  });

  const newPostModalClose = () => {
    setNewPostModal({
      open: false,
      data: null,
    });
  };
  const newPostModalOpen = () => {
    setNewPostModal({
      open: true,
      data: null,
    });
  };
  const newPostModalReplyOpen = (data) => {
    setNewPostModal({
      open: true,
      data: data,
    });
  };

  const fetchData = async (flagData) => {
    try {
      const res = await getSafalRoomPublicNormalChat({ companyId: companyId });
      if (res.status === 200) {
        setChatData(res.data.data);
        if (res.data.data.length > 0) {
          const feedCountPublic = document.getElementById('feed-count-public');
          if (feedCountPublic) {
            feedCountPublic.innerHTML = `Feed (${res.data.data.length})`;
          }
        }

        if (flagData?.flag === 'REPLY') {
          const findChat = res.data.data?.find((e) => e?._id === flagData?.data?._id);
          if (findChat) {
            setNewPostModal((prev) => ({ ...prev, data: findChat }));
          }
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);

  const onPost = async ({ message, goodBad }, files) => {
    try {
      setChatDisabled(true);
      let images = [];
      if (files?.length > 0) {
        const resUpload = await uploadSafalRoomImages(files);
        images = resUpload?.data?.data;
      }
      const res = await createSafalRoomPublicNormalChat({ companyId: companyId, message, sentiment: goodBad, images });
      if (res.status === 200) {
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setChatDisabled(false);
    }
  };

  const onUpdateSafal = async (e, like) => {
    try {
      setChatDisabled(true);
      const res = await updateSafalRoomPublicNormalChat({
        chatId: e?._id,
        like,
        parentChatPath: e?.parentChatPath,
        parentChat: e?.parentChat,
      });
      if (res.status === 200) {
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setChatDisabled(false);
    }
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
  };
  const onFilterReset = () => {
    setFilterState({
      userId: '',
      toDate: '',
      fromDate: '',
      search: '',
    });
  };

  const uniqueUsers = uniqueUserFromFeed(chatData);

  const chatDataFilter = applyFilter(chatData, filterState);

  return (
    <>
      <FeedFilterMenu
        users={uniqueUsers}
        filterState={filterState}
        onFilterReset={onFilterReset}
        onChangeField={onChangeField}
      />
      <Button
        onClick={newPostModalOpen}
        size="small"
        sx={{
          width: '100px',
          position: 'absolute',
          zIndex: '200',
          top: '40px',
          right: '32px',
          borderRadius: '30px',
          color: '#FFFFFF',
          backgroundColor: '#3D71FF',
          '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
          textTransform: 'capitalize',
        }}
        startIcon={<AddIcon />}
        variant="contained"
      >
        Post
      </Button>
      {chatDataFilter.length > 0 ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            height: 'calc(100% - 16px)',
            padding: '16px 4px',
            scrollbarWidth: 'thin',
            gap: '8px',
          }}
        >
          {chatDataFilter?.map((e, index) => (
            <FeedMessage
              onUpdate={(safal) => onUpdateSafal(e, safal)}
              data={e}
              key={index}
              onReply={() => newPostModalReplyOpen(e)}
              isLast={index === chatDataFilter?.length - 1}
              disabled={chatDisabled}
            />
          ))}
        </div>
      ) : (
        <div style={{ display: 'flex', height: '300px', alignItems: 'center', justifyContent: 'center' }}>
          <span>No feed found</span>
        </div>
      )}
      <FeedMessageModal
        companyListForMentions={companyListForMentions}
        usersMentions={uniqueUsers}
        companyId={companyId}
        refetch={fetchData}
        onPost={onPost}
        open={newPostModal.open}
        data={newPostModal.data}
        onClose={newPostModalClose}
      />
    </>
  );
};
export default SafalRoomFeed;

export const FeedMessage = ({ data, onReply, isReply, onUpdate, disabled, size, isLast, thisReplyScreen }) => {
  const { timeFromNow2 } = useUserTimeZone();
  const { user } = useSelector((state) => state.login);

  const userProfileImg = data?.user?.profilePic || '';
  const userFullName =
    user?._id === data?.user?._id ? 'You' : `${data?.user?.firstName || ''} ${data?.user?.lastName || ''}`;
  const createdTime = data?.createdAt;
  const messageString = data?.message || '';
  const likeCount = data?.likeDislikeUser?.likeCount || 0;
  const dislikeCount = data?.likeDislikeUser?.dislikeCount || 0;
  const likeDislikeUserYou = data?.likeDislikeUser?.you?.like;
  const replies = data?.replies || [];
  const images = data?.images || [];

  const parentCompanyRef = data?.parentCompany?.name ? ` mentions in ${data?.parentCompany?.name}` : '';

  const [openUserNewProfileModal, setOpenUserNewProfileModal] = useState({
    open: false,
    data: null,
  });
  const openUserNewProfileOpen = (userId) => {
    setOpenUserNewProfileModal({
      open: true,
      data: userId,
    });
  };
  const openUserNewProfileClose = () => {
    setOpenUserNewProfileModal({
      open: false,
      data: null,
    });
  };

  return (
    <>
      <div
        style={{
          borderBottom: !isLast && !isReply && '1px solid #BCCEFF',
          display: 'flex',
          flexDirection: 'column',
          fontSize: '16px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <ProfileImageState
            onClick={() => openUserNewProfileOpen(data?.user?._id)}
            src={userProfileImg}
            name={userFullName}
            size={size}
          />
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
            <span style={{ fontWeight: 'bold' }}>{userFullName}</span>
            <span style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
              {timeFromNow2(createdTime)}
              {parentCompanyRef}
              {data?.parentRoom?.name && (
                <>
                  <LockResetIcon sx={{ fontSize: '13px', marginLeft: '4px' }} /> {data?.parentRoom?.name}
                </>
              )}
              {data?.parentPublicForum?.name && (
                <>
                  <img
                    style={{ height: '12px', objectFit: 'contain', marginLeft: '4px', marginRight: '2px' }}
                    src="/assets/images/SafalRoomForum.png"
                    alt={data?.parentPublicForum?.name}
                  />{' '}
                  {data?.parentPublicForum?.name}
                </>
              )}
              {data?.parentPrivateForum?.name && (
                <>
                  <img
                    style={{ height: '12px', objectFit: 'contain', marginLeft: '4px', marginRight: '2px' }}
                    src="/assets/images/SafalRoomForum.png"
                    alt={data?.parentPrivateForum?.name}
                  />{' '}
                  {data?.parentPrivateForum?.name}
                </>
              )}
            </span>
          </div>
        </div>
        <p
          style={{
            padding: '4px',
          }}
        >
          <RoomMentionsDisplay message={messageString} companyId={data?.company} />{' '}
          {data?.sentiment === 'good' ? (
            <span style={{ backgroundColor: '#58a979', color: 'white', padding: '2px 6px', borderRadius: '16px' }}>
              Happy
            </span>
          ) : data?.sentiment === 'bad' ? (
            <span style={{ backgroundColor: '#d38080', color: 'white', padding: '2px 6px', borderRadius: '16px' }}>
              Needs improvement
            </span>
          ) : (
            ''
          )}
        </p>
        <CustomImageViewInFeed urls={images} />
        {!isReply && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '4px' }}>
              <div>
                {!thisReplyScreen && (
                  <button
                    className="hover-underline"
                    onClick={onReply}
                    style={{
                      backgroundColor: 'transparent',
                      color: 'black',
                      fontSize: '14px',
                      cursor: 'pointer',
                      border: 'none',
                    }}
                  >
                    Add or view replies {replies.length > 0 ? ` (${replies.length})` : ''}
                  </button>
                )}
                {/* {replies.length > 0 && <span style={{ fontSize: '14px', marginLeft: '4px' }}>{replies.length}</span>} */}
              </div>
            </div>
            <div style={{ flex: 'none', display: 'flex', gap: '12px', marginRight: '16px' }}>
              <div>
                <Image
                  onClick={() => !disabled && onUpdate(1)}
                  title="Safal"
                  preview={false}
                  style={{
                    filter: likeDislikeUserYou !== 1 && 'grayscale(100%)',
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                  height="24px"
                  src={likeImg}
                />
                {likeCount > 0 && <span style={{ fontSize: '14px', marginLeft: '4px' }}>{likeCount}</span>}
              </div>
              <div>
                <Image
                  onClick={() => !disabled && onUpdate(-1)}
                  title="Unsafal"
                  preview={false}
                  style={{
                    filter: likeDislikeUserYou !== -1 && 'grayscale(100%)',
                    cursor: 'pointer',
                    objectFit: 'contain',
                  }}
                  height="24px"
                  src={disLike}
                />
                {dislikeCount > 0 && <span style={{ fontSize: '14px', marginLeft: '4px' }}>{dislikeCount}</span>}
              </div>
            </div>
          </div>
        )}
      </div>
      <UserNewProfileModal
        open={openUserNewProfileModal.open}
        userId={openUserNewProfileModal.data}
        onClose={openUserNewProfileClose}
      />
    </>
  );
};

export const ProfileImageState = ({ name, src, size, onClick }) => {
  const [profileImg, setProfileImg] = useState('');
  const getProfileImage = async (url) => {
    if (url?.includes('safalsubscriptions-assets.s3.amazonaws.com')) {
      const newRes = await getSignedURL({ url: url });
      setProfileImg(newRes.data);
    }
  };

  useEffect(() => {
    getProfileImage(src);
  }, [src]);
  const _size = size === undefined ? 'default' : size;

  return (
    <Avatar onClick={onClick} sx={{ cursor: 'pointer' }} size={_size} src={profileImg}>
      {name}
    </Avatar>
  );
};

export const uniqueUserFromFeed = (data) => {
  const arr = [];
  data.forEach((e) => {
    if (!arr.includes(e?.user?._id)) arr.push(e?.user?._id);
  });

  const returnData = arr.map((e) => {
    const findData = data?.find((d) => d?.user?._id === e);
    const userFullName = `${findData?.user?.firstName || ''} ${findData?.user?.lastName || ''}`;
    return {
      name: userFullName,
      id: e,
    };
  });
  return returnData;
};

export const applyFilter = (_data, filterState) => {
  let data = [..._data];
  if (filterState?.userId) data = data?.filter((e) => e?.user?._id === filterState?.userId);
  if (filterState?.toDate) data = data?.filter((e) => moment(e?.createdAt).isBefore(filterState?.toDate));
  if (filterState?.fromDate) data = data?.filter((e) => moment(e?.createdAt).isAfter(filterState?.fromDate));
  if (filterState?.search)
    data = data?.filter((e) => e?.message?.toLowerCase()?.includes(filterState?.search?.toLowerCase()));

  return data;
};
