import { InputAdornment, OutlinedInput, Fab } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: '100%', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '16px',
  p: 3,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function CompanySelectModal({ open, onClose, data, onSelect }) {
  const [searchValue, setSearchValue] = useState('');
  const [companyListFilter, setCompanyListFilter] = useState([]);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open) {
      setCompanyListFilter(data);
    }
  }, [open]);

  const onSearchChange = (value) => {
    setSearchValue(value);

    if (value?.length > 1) {
      const filterData = data?.filter(
        (e) =>
          e?.name?.toLowerCase().includes(value?.toLowerCase()) ||
          e?.companyType?.toLowerCase().includes(value?.toLowerCase())
      );
      setCompanyListFilter(filterData);
    } else {
      setCompanyListFilter(data);
    }
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: '28px', fontWeight: 700, color: '#3D71FF' }}>
            Select SafalRoom
          </Typography>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: '#3D71FF',
              color: 'white',
            }}
            onClick={handleClose}
            size="small"
            color="primary"
          >
            <CloseIcon />
          </Fab>
          <SearchStyle
            sx={{ marginBottom: '16px', marginTop: '16px' }}
            onChange={(e) => onSearchChange(e.target.value)}
            value={searchValue}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: '#3D71FF', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          <div style={{ display: 'flex', flexDirection: 'column', height: '400px', overflow: 'auto', gap: '8px' }}>
            {companyListFilter?.map((e, index) => (
              <button
                onClick={() => onSelect(e?._id)}
                key={index}
                style={{
                  flex: 'none',
                  backgroundColor: 'white',
                  padding: '8px 16px',
                  border: '1px solid #BCCEFF',
                  borderRadius: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                {e?.logo && (
                  <img
                    src={e?.logo}
                    style={{ height: '48px', objectFit: 'contain', borderRadius: '12px', overflow: 'hidden' }}
                    alt={e?.name}
                  />
                )}
                <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', textAlign: 'start' }}>
                  <span style={{ fontWeight: 'bold' }}>{e?.name}</span>
                  <span style={{ color: '#3D71FF' }}>{e?.companyType}</span>
                </div>
              </button>
            ))}
          </div>
        </Box>
      </Modal>
    </>
  );
}
