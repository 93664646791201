/* eslint-disable react/jsx-boolean-value */
import { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { registerMap } from 'echarts';

import { usStates, indiaStates } from '../../../../utils/stateList';
import geoUSA from '../../../../assets/geo/geoUSA.json';
import geoIND from '../../../../assets/geo/geoIND.json';
import { getBusinessDashboardTotalUsersCountry } from '../../../../services/Service';

const CountryChartCard = () => {
  const [isMount, setIsMount] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState('USA'); // USA, IND

  const fetchData = async (country) => {
    try {
      setLoading(true);
      const res = await getBusinessDashboardTotalUsersCountry({ country: country });
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    registerMap('IND', geoIND, {
      AN: {
        left: 88,
        top: 10,
        width: 2,
      },
    });
    registerMap('USA', geoUSA, {
      AK: {
        left: -125,
        top: 25,
        width: 50,
      },
      HI: {
        left: -115,
        top: 24,
        width: 8,
      },
    });
    setIsMount(true);
  }, []);
  useEffect(() => {
    fetchData(mode);
  }, [mode]);

  const stateList = mode === 'USA' ? usStates : indiaStates;
  // Generate state data based on abbreviation and value
  const stateData = stateList?.map((state) => {
    const value = data?.find((d) => d.state === state.abbreviation)?.count || 0;
    return {
      name: state.abbreviation,
      fullName: state.name,
      value: value,
      itemStyle: {
        color: getColorByValue(value), // Assign a color based on value
      },
    };
  });

  const chartOption = {
    title: {
      text: 'Users inside the country',
      left: 'left',
      textStyle: {
        color: '#468b47', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        // Find the corresponding state from the data using the abbreviation
        const state = stateData.find((s) => s.name === params.name);
        // If found, show the full state name and the value
        return state ? `${state.fullName} (${state.name}): ${state.value}` : `${params.name}: ${params.value}`;
      },
    },
    visualMap: {
      show: false, // Hide the color range selection
    },
    series: [
      {
        name: 'Users',
        type: 'map',
        map: mode,
        roam: false, // Disable zoom and pan
        zoom: 1, // Set default zoom to 120%
        emphasis: {
          label: {
            show: true,
          },
        },
        nameProperty: 'abbreviation',
        data: stateData, // Use abbreviation-based data
      },
    ],
  };

  const first7 = data.slice(0, 7);

  return (
    <div
      className="BusinessDashboard-country-chart"
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        // display: 'flex',
        // flexDirection: 'column',
        // position: 'relative',
        // gap: '24px',
      }}
    >
      <div>
        {isMount && (
          <ReactECharts
            showLoading={loading}
            notMerge={true}
            lazyUpdate={true}
            option={chartOption}
            style={{ height: '400px', minHeight: '300px' }}
          />
        )}
      </div>
      <div style={{ display: 'flex', padding: '8px 16px', gap: '28px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', justifyContent: 'space-evenly' }}>
          <RenderColorDiv color="#006837" title="User more than 100" />
          <RenderColorDiv color="#1a9850" title="User between 90 to 100" />
          <RenderColorDiv color="#66bd63" title="User between 80 to 90" />
          <RenderColorDiv color="#a6d96a" title="User between 70 to 80" />
          <RenderColorDiv color="#d9ef8b" title="User between 60 to 70" />
          <RenderColorDiv color="#ffffbf" title="User between 50 to 60" />
          <RenderColorDiv color="#fee08b" title="User between 30 to 50" />
          <RenderColorDiv color="#fdae61" title="User between 10 to 30" />
          <RenderColorDiv color="#f46d43" title="User below 10" />
        </div>
        <div
          style={{
            borderLeft: '2px solid #468B47',
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            padding: '0px 16px',
            gap: '20px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontSize: '20px', color: '#468B47', fontWeight: 'bold' }}>Top 7 users state</span>
            <select value={mode} style={{ zIndex: 1 }} onChange={(e) => setMode(e.target.value)}>
              <option value="USA">USA</option>
              <option value="IND">IND</option>
            </select>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', lineHeight: 'normal' }}>
            {first7.map((item, index) => (
              <RenderMetaInfo mode={mode} data={item} index={index} key={index} isLast={first7.length - 1 === index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CountryChartCard;

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Function to set colors based on value range
function getColorByValue(value) {
  if (value >= 100) return '#006837';
  if (value >= 90) return '#1a9850';
  if (value >= 80) return '#66bd63';
  if (value >= 70) return '#a6d96a';
  if (value >= 60) return '#d9ef8b';
  if (value >= 50) return '#ffffbf';
  if (value >= 30) return '#fee08b';
  if (value >= 10) return '#fdae61';
  return '#f46d43'; // Less than 20k
}

const RenderColorDiv = ({ color, title }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div style={{ backgroundColor: color, width: '28px', height: '22px', borderRadius: '4px' }} />
      <span style={{ fontSize: '16px', color: '#468B47', fontWeight: 'bold' }}>{title}</span>
    </div>
  );
};

const RenderMetaInfo = ({ isLast, data, index, mode }) => {
  const mainArray = mode === 'USA' ? usStates : indiaStates;
  const title = mainArray.find((e) => e.abbreviation === data?.state)?.name;
  const value = data?.count ?? 0;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: isLast ? 'none' : '1px solid #468B47',
        paddingBottom: '8px',
      }}
    >
      <div style={{ display: 'flex', gap: '8px' }}>
        <span style={{ fontSize: '16px', color: '#468B47', fontWeight: 'bold' }}>{index + 1}</span>
        <span style={{ fontSize: '16px', color: '#468B47', fontWeight: 'bold' }}>{title}</span>
      </div>
      <p style={{ fontSize: '16px', color: '#468B47' }}>
        {value} <span style={{ fontSize: '12px', color: '#468B47' }}>Users</span>
      </p>
    </div>
  );
};
