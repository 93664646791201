import * as Yup from 'yup';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import jwt_decode from 'jwt-decode';

import { Field, FormikProvider, useFormik } from 'formik';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  Box,
  Typography,
  Grid,
  Link,
  Input,
} from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import SendIcon from '@mui/icons-material/Send';
import { Icon } from '@iconify/react';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import SuccessToast from '../../../components/Toast/Success';
import ErrorToast from '../../../components/Toast/Error';
import { RegisterResponse, sendVerificationCodeResponse, VerifyPhoneResponse } from '../../../services/Service';
import { validateUserid } from '../../../store/loginSlice';
import Inlineloader from '../../../components/Loader/InlineLoader';
import { debounce, sayswho } from '../../../utils/fn';
import { usStates, indiaStates } from '../../../utils/stateList';

export default function RegisterForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [vPhone, setVPhone] = useState(false);
  const [otpField, setOtpField] = useState(false);
  const { Countries } = useSelector((state) => state.login);
  const [phoneDisable, setPhoneDisable] = useState(false);
  const [otpDisable, setOtpDisable] = useState(false);
  const [loading, setLoading] = useState(false);

  const [usId, setUsId] = useState();

  const checkUserId = (e) => {
    dispatch(validateUserid({ userId: e.target.value }))
      .unwrap()
      .then((res) => {
        // console.log(res.userID === e.target.value);
        console.log('ths is re => ', res);
        console.log(
          'ths is re =>  SignUpFormik.errors.userID',
          Boolean(SignUpFormik.errors.userID),
          SignUpFormik.errors.userID
        );

        if (res.userID === e.target.value) {
          console.log('ths is re => iffff ', res);
          setUsId(e.target.value);
        } else {
          console.log('ths is re => elseeee', res);
          setUsId(false);
        }
      })
      .catch(() => console.log('Error'));
  };

  const SignUpSchema = Yup.object().shape({
    userID: Yup.string()
      .required('User ID is required')
      .test('userID', 'User ID already exists', async (value) => {
        console.log('ths is re => usId ', usId);
        return !(usId && usId);
      }),
    firstName: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'First name should only contain alphabets')
      .required('First name is required'),
    lastName: Yup.string()
      .matches(/^[a-zA-Z]+$/, 'Last name should only contain alphabets')
      .required('Last Name is required'),
    middleName: Yup.string().matches(/^[a-zA-Z]+$/, 'Middle name should only contain alphabets'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    countryCode: Yup.string().required('Please select country code'),
    phoneNo: Yup.string()
      .matches(/^\d{10}$/, 'Phone number should contain 10 digits only')
      .required('Phone number is required'),
    otp: Yup.string().matches(/^\d{4}$/, 'Verification code should contain 4 digits only'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    agreeTremsAndConditions: Yup.boolean().isTrue('You must accept the terms and conditions').required(),

    dateOfBirth: Yup.date()
      .required('Date of birth is required')
      .typeError('Please enter a valid date')
      .test(
        'dateOfBirth',
        'Must be greater than or equal to 13 years',
        (value) => moment().diff(moment(value), 'years') >= 13
      ),
    state: Yup.string().when('countryCode', {
      is: (countryCode) => countryCode === 'United States' || countryCode === 'India', // Assuming 'IN' is the country code for India
      then: Yup.string().required('State is required'),
      otherwise: Yup.string().notRequired(),
    }),
    // otp: Yup.string().required('OTP is required'),
  });

  const initialValues = {
    userID: '',
    firstName: '',
    lastName: '',
    email: '',
    country: '',
    password: '',
    middleName: '',
    confirmPassword: '',
    dateOfBirth: '',
    agreeTremsAndConditions: false,
    phoneNo: '',
    gender: '',
    otp: '',
    countryCode: '',
    state: '',
  };

  const SignUpFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: SignUpSchema,
    onSubmit: async (values) => {
      values.role = 'business';
      // NOTE : Save User ROLE & Device Type and Version Saving
      values.deviceType = 'Web';
      values.deviceVersion = sayswho();
      RegisterResponse(values)
        .then(async (res) => {
          setLoading(true);
          if (res.data.success === true) {
            setLoading(false);
            SuccessToast('Registration Successful.');
            navigate('/login', { replace: true });
          }
        })
        .catch((e) => {
          setLoading(false);
          ErrorToast(e?.response.data.message || e.message);
        });
    },
  });

  const resetTimer = () => {
    setTimeout(() => {
      setOtpDisable(false);
    }, 5000);
  };

  const verifyPhone = () => {
    setTimeout(() => {
      setPhoneDisable(false);
    }, 3000);

    sendVerificationCodeResponse(SignUpFormik.values)
      .then((val) => {
        if (val.data.success === true) {
          console.log('this is Verification code => ', val.data);
          setOtpField(true);
        } else {
          if (val.data.isverified === true) {
            setVPhone(true);
            setPhoneDisable(true);
            SuccessToast(val.data.message);
          } else {
            ErrorToast(val.data.message);
          }
        }
      })
      .catch((err) => {
        ErrorToast(err.response.data.message);
      });
  };

  const verifyPhoneClick = () => {
    resetTimer();
    VerifyPhoneResponse(SignUpFormik.values.phoneNo, SignUpFormik.values.otp)
      .then((res) => {
        if (res.data.success === true) {
          setVPhone(true);
          setPhoneDisable(true);
          setOtpField(false);
        }
      })
      .catch((err) => {
        ErrorToast(err.response.data.message);
      });
  };

  const debouncedOnChangeUserId = debounce(checkUserId, 1500);
  const isRegisterBtnDisable = !(Object.keys(SignUpFormik.errors).length === 0) || !vPhone || usId;
  const stateData =
    SignUpFormik?.values?.country === '' ? [] : SignUpFormik?.values?.country === 'India' ? indiaStates : usStates;

  return (
    <>
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9998,
            }}
          />
          <Inlineloader />
        </>
      )}
      <FormikProvider value={SignUpFormik}>
        <form onSubmit={SignUpFormik.handleSubmit} style={{ position: 'relative' }}>
          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {/* user id */}
            <Grid item xs={12} sm={6} md={6}>
              <Field
                as={TextField}
                name="userID"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    User ID <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                }
                autoComplete="off"
                variant="standard"
                size="small"
                value={SignUpFormik.values.userID}
                onChange={(e) => {
                  SignUpFormik.handleChange(e);
                  debouncedOnChangeUserId(e);
                }}
                error={SignUpFormik.touched.userID && Boolean(SignUpFormik.errors.userID)}
                helperText={SignUpFormik.touched.userID && SignUpFormik.errors.userID}
                sx={{ width: '100%' }}
              />
            </Grid>
            {/* First Name */}
            <Grid item xs={12} sm={6} md={6}>
              <Field
                as={TextField}
                name="firstName"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    First Name<span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                variant="standard"
                size="small"
                onChange={SignUpFormik.handleChange}
                value={SignUpFormik.values.firstName}
                error={SignUpFormik.touched.firstName && Boolean(SignUpFormik.errors.firstName)}
                helperText={SignUpFormik.touched.firstName && SignUpFormik.errors.firstName}
                sx={{ width: '100%' }}
              />
            </Grid>
            {/* Middle Name */}
            <Grid item xs={12} sm={6} md={6}>
              <Field
                as={TextField}
                name="middleName"
                label={<Typography sx={{ color: '#B6B6B6' }}>Middle Name</Typography>}
                variant="standard"
                size="small"
                onChange={SignUpFormik.handleChange}
                value={SignUpFormik.values.middleName}
                error={SignUpFormik.touched.middleName && Boolean(SignUpFormik.errors.middleName)}
                helperText={SignUpFormik.touched.middleName && SignUpFormik.errors.middleName}
                sx={{ width: '100%' }}
              />
            </Grid>
            {/* Last Name */}
            <Grid item xs={12} sm={6} md={6}>
              <Field
                as={TextField}
                name="lastName"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Last Name<span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                }
                variant="standard"
                // size="small"
                onChange={SignUpFormik.handleChange}
                value={SignUpFormik.values.lastName}
                error={SignUpFormik.touched.lastName && Boolean(SignUpFormik.errors.lastName)}
                helperText={SignUpFormik.touched.lastName && SignUpFormik.errors.lastName}
                sx={{ width: '100%', mt: '-3px' }}
              />
            </Grid>
            {/* Gender */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: '#B6B6B6' }}>
                  Gender
                </FormLabel>

                <RadioGroup row name="gender" onChange={SignUpFormik.handleChange} value={SignUpFormik.values.gender}>
                  <Stack direction="row" sx={{ color: '#B6B6B6', pl: '20px' }}>
                    <FormControlLabel value="female" control={<Radio />} label="Female" />
                    <FormControlLabel value="male" control={<Radio />} label="Male" />
                    <FormControlLabel value="" control={<Radio />} label="Not Specify" defaultChecked />
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* Date Of Birth */}
            <Grid item xs={12} sm={6} md={6}>
              <LocalizationProvider dateAdapter={MomentUtils}>
                <Field
                  as={DesktopDatePicker}
                  keyboard
                  inputFormat="MM/DD/YYYY"
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Date Of Birth<span style={{ color: 'red' }}>*</span>{' '}
                    </Typography>
                  }
                  onChange={(e) => {
                    SignUpFormik.setFieldValue('dateOfBirth', moment(e).format('yyyy-MM-DD'));
                    SignUpFormik.setFieldTouched('dateOfBirth', true, false);
                  }}
                  value={SignUpFormik.values.dateOfBirth}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <Field
                      as={TextField}
                      variant="standard"
                      size="small"
                      {...params}
                      onBlur={(e) => {
                        SignUpFormik.setFieldTouched('dateOfBirth', true, false);
                      }}
                      sx={{ width: '100%', svg: { color: '#0071E3' } }}
                      helperText={SignUpFormik.touched.dateOfBirth && SignUpFormik.errors.dateOfBirth}
                      error={SignUpFormik.touched.dateOfBirth && Boolean(SignUpFormik.errors.dateOfBirth)}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            {/* Country */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl sx={{ minWidth: '100%' }}>
                <InputLabel sx={{ color: '#B6B6B6' }}>
                  Country<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Field
                  as={Select}
                  name="country"
                  value={SignUpFormik.values.country}
                  disabled={vPhone}
                  onChange={(e) => {
                    SignUpFormik.handleChange(e);
                    SignUpFormik.setFieldValue('countryCode', e.target.value === 'United States' ? 1 : 91);
                    setVPhone(false);
                    setOtpField(false);
                  }}
                  autoWidth
                  label="Country"
                  size="small"
                  variant="standard"
                  error={SignUpFormik.touched.country && Boolean(SignUpFormik.errors.country)}
                  sx={{
                    width: '100%',
                    '& .MuiSelect-select': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                    '&:after': {
                      borderBottomColor: '#0000',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#0071E3',
                    },
                  }}
                >
                  {Countries.map((val) => (
                    <MenuItem key={val.name} value={val.name} sx={{ display: 'flex' }}>
                      <img
                        src={val.image}
                        alt={val.code}
                        height={'20px'}
                        width={'20px'}
                        style={{ display: 'inline' }}
                      />
                      <span style={{ padding: '0 8px' }}>{val.name}</span>
                    </MenuItem>
                  ))}
                </Field>
                {SignUpFormik.errors.country && (
                  <FormHelperText sx={{ color: '#FF4842' }}>
                    {SignUpFormik.touched.country && SignUpFormik.errors.country}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* Phone */}
            <Grid item xs={12} sm={6} md={6}>
              <Stack
                direction={'row'}
                spacing={0.5}
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  mt: '8px',
                }}
              >
                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: '#B6B6B6', width: '50%' }}>
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Phone<span style={{ color: 'red' }}>*</span>
                  </Typography>{' '}
                </FormLabel>
                <FormControl sx={{ width: '200px', ml: '-14px' }}>
                  <Field
                    as={Input}
                    name="countryCode"
                    variant="standard"
                    size="small"
                    value={SignUpFormik.values.countryCode}
                    onChange={(e) => {
                      setVPhone(false);
                      setOtpField(false);
                      SignUpFormik.handleChange(e);
                    }}
                    autoWidth
                    disabled
                    error={SignUpFormik.touched.countryCode && Boolean(SignUpFormik.errors.countryCode)}
                    startAdornment={<InputAdornment position="start"> + </InputAdornment>}
                    sx={{
                      pt: '8px',
                      width: 70,
                      '&:after': {
                        borderBottomColor: '#0000',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#0071E3',
                      },
                    }}
                  />
                </FormControl>

                <Field
                  as={TextField}
                  type="number"
                  style={{ width: '400px' }}
                  name="phoneNo"
                  variant="standard"
                  onChange={(e) => {
                    SignUpFormik.handleChange(e);
                    setVPhone(false);
                    setOtpField(false);
                  }}
                  value={SignUpFormik.values.phoneNo}
                  error={SignUpFormik.touched.phoneNo && Boolean(SignUpFormik.errors.phoneNo)}
                  helperText={SignUpFormik.touched.phoneNo && SignUpFormik.errors.phoneNo}
                  disabled={phoneDisable}
                  InputProps={{
                    endAdornment: (
                      <>
                        {!vPhone && (
                          <Button
                            variant="standard"
                            onClick={() => {
                              if (SignUpFormik.values.countryCode) {
                                verifyPhone();
                                setPhoneDisable(true);
                              }
                            }}
                            endIcon={<SendIcon />}
                            sx={{ color: '#2065D1', textTransform: 'none' }}
                            disabled={!!(SignUpFormik.errors.countryCode || SignUpFormik.errors.phoneNo)}
                          >
                            Validate
                          </Button>
                        )}

                        {vPhone && (
                          <Button
                            variant="standard"
                            endIcon={<VerifiedIcon style={{ color: '#4CAF50' }} />}
                            sx={{ color: '#207420', textTransform: 'none' }}
                          >
                            Verified
                          </Button>
                        )}
                      </>
                    ),
                  }}
                  sx={{ width: '100%' }}
                />
              </Stack>
              {SignUpFormik.errors.countryCode && (
                <FormHelperText sx={{ color: '#FF4842' }}>
                  {SignUpFormik.touched.countryCode && SignUpFormik.errors.countryCode}
                </FormHelperText>
              )}
            </Grid>
            {/* Enter OTP */}
            {otpField && !vPhone && (
              <>
                <Grid item xs={12} sm={6} md={6} />
                <Grid item xs={12} sm={6} md={6}>
                  <Stack
                    direction={'row'}
                    spacing={2}
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {SignUpFormik?.values?.country === 'India' ? (
                      <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: '#B6B6B6', pt: '8px' }}>
                        Enter OTP
                      </FormLabel>
                    ) : (
                      <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: '#B6B6B6', pt: '8px' }}>
                        Enter Verification Code
                      </FormLabel>
                    )}

                    <Field
                      as={TextField}
                      type="number"
                      limit={4}
                      style={{ width: '400px' }}
                      name="otp"
                      label={
                        SignUpFormik?.values?.country === 'India' ? (
                          <Typography sx={{ color: '#B6B6B6' }}>Enter OTP</Typography>
                        ) : (
                          <Typography sx={{ color: '#B6B6B6' }}>Enter Verification Code</Typography>
                        )
                      }
                      variant="standard"
                      onChange={SignUpFormik.handleChange}
                      value={SignUpFormik.values.otp}
                      error={SignUpFormik.touched.otp && Boolean(SignUpFormik.errors.otp)}
                      helperText={SignUpFormik.touched.otp && SignUpFormik.errors.otp}
                      disabled={otpDisable}
                      InputProps={{
                        endAdornment: (
                          <>
                            <Button
                              variant="outlined"
                              disabled={otpDisable}
                              onClick={() => {
                                verifyPhoneClick();
                                setOtpDisable(true);
                              }}
                              sx={{ color: '#2065D1', textTransform: 'none' }}
                            >
                              Verify
                            </Button>
                          </>
                        ),
                      }}
                      sx={{ width: '100%' }}
                    />
                  </Stack>
                </Grid>
              </>
            )}
            {/* State */}
            <Grid item xs={12} sm={6} md={6}>
              <FormControl sx={{ minWidth: '100%' }}>
                <InputLabel sx={{ color: '#B6B6B6', ml: -2 }}>
                  State<span style={{ color: 'red' }}>*</span>
                </InputLabel>
                <Field
                  disabled={SignUpFormik?.values?.country === ''}
                  as={Select}
                  name="state"
                  value={SignUpFormik.values.state}
                  onChange={SignUpFormik.handleChange}
                  autoWidth
                  label="State"
                  size="small"
                  variant="standard"
                  error={SignUpFormik.touched.state && Boolean(SignUpFormik.errors.state)}
                  sx={{
                    width: '100%',
                    '& .MuiSelect-select': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                    '&:after': {
                      borderBottomColor: '#0000',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#0071E3',
                    },
                  }}
                >
                  {stateData.map((val) => (
                    <MenuItem key={val.abbreviation} value={val.abbreviation}>
                      {val.name}
                    </MenuItem>
                  ))}
                </Field>
                {SignUpFormik.errors.state && (
                  <FormHelperText sx={{ color: '#FF4842' }}>
                    {SignUpFormik.touched.state && SignUpFormik.errors.state}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* Email */}
            <Grid item xs={12} sm={6} md={6}>
              <Field
                as={TextField}
                name="email"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Email<span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                }
                variant="standard"
                size="small"
                onChange={SignUpFormik.handleChange}
                value={SignUpFormik.values.email}
                error={SignUpFormik.touched.email && Boolean(SignUpFormik.errors.email)}
                helperText={SignUpFormik.touched.email && SignUpFormik.errors.email}
                sx={{ width: '100%' }}
              />
            </Grid>
            {/* Password */}
            <Grid item xs={12} sm={6} md={6}>
              <Field
                as={TextField}
                type={showPassword ? 'text' : 'password'}
                name="password"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Password<span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                variant="standard"
                size="small"
                onChange={SignUpFormik.handleChange}
                value={SignUpFormik.values.password}
                error={SignUpFormik.touched.password && Boolean(SignUpFormik.errors.password)}
                helperText={SignUpFormik.touched.password && SignUpFormik.errors.password}
                sx={{ width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                        <Icon icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Confirm Password */}
            <Grid item xs={12} sm={6} md={6}>
              <Field
                as={TextField}
                type={showConfirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Confirm Password<span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                }
                variant="standard"
                size="small"
                onChange={SignUpFormik.handleChange}
                value={SignUpFormik.values.confirmPassword}
                error={SignUpFormik.touched.confirmPassword && Boolean(SignUpFormik.errors.confirmPassword)}
                helperText={SignUpFormik.touched.confirmPassword && SignUpFormik.errors.confirmPassword}
                sx={{ width: '100%' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        <Icon icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* Checkbox */}
            <Grid item xs={12}>
              <Field
                as={FormControlLabel}
                control={
                  <Checkbox
                    name="agreeTremsAndConditions"
                    label="Agree Terms & Conditions"
                    onChange={SignUpFormik.handleChange}
                    value={SignUpFormik.values.agreeTremsAndConditions}
                    onBlur={SignUpFormik.handleBlur}
                  />
                }
                error={
                  SignUpFormik.touched.agreeTremsAndConditions && Boolean(SignUpFormik.errors.agreeTremsAndConditions)
                }
                label="I accept the Terms & Conditions"
              />
              <FormHelperText
                sx={{
                  color: 'red',
                  mt: '-5px !important',
                  ml: '40px !important',
                }}
              >
                {SignUpFormik.touched.agreeTremsAndConditions && SignUpFormik.errors.agreeTremsAndConditions}
              </FormHelperText>
              <Typography variant="body2" sx={{ color: 'text.primary', mt: '15px' }}>
                By registering, I agree to SafalSubscriptions.com as well as SafalVir&nbsp;
                <Link underline="hover" color="#3D71FF" target="_blank" href="https://safalsubscriptions.com/">
                  Terms of Service & Privacy Policy
                </Link>
                .
              </Typography>
            </Grid>
          </Grid>

          <Box display={{ sm: 'block', md: 'flex' }} sx={{ width: '100%', justifyContent: 'space-between' }}>
            <Button
              // disabled={!(Object.keys(SignUpFormik.errors).length === 0) || !phoneDisable || usId}
              // disabled={!(SignUpFormik.isValid && SignUpFormik.dirty)}
              disabled={isRegisterBtnDisable}
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                width: '200px',
                height: '45px',
                backgroundColor: '#3D71FF',
                borderRadius: '30px',
                mt: '20px',
              }}
            >
              Register
            </Button>
            <Typography variant="body2" sx={{ mt: '31px', width: '100%', ml: '20px' }}>
              Already have an account?{' '}
              <Link variant="subtitle2" underline="hover" color="#3D71FF" to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </Box>
        </form>
      </FormikProvider>
    </>
  );
}
