/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { GetUserResponse } from '../../services/Service';
import Loader from '../Loader';
import SuccessToast from '../Toast/Success';
import ErrorToast from '../Toast/Error';

const ProtectedRoutes = (props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.login);
  const token = localStorage.getItem('Jtoken');
  const [loading, setLoading] = useState(true);
  const [isValidToken, setIsValidToken] = useState(true);

  async function validateToken() {
    if (token) {
      try {
        const response = await GetUserResponse();
        if (response?.data?.success) {
          setIsValidToken(true);
        } else {
          ErrorToast(response?.data?.message);
          localStorage.clear();
          setIsValidToken(false);
        }
      } catch (error) {
        console.log('error in catch');
        localStorage.clear();
        ErrorToast(error?.response?.data?.message || error.message);
        setIsValidToken(false);
      } finally {
        setLoading(false);
      }
    } else {
      navigate('/login');
    }
  }

  validateToken();

  const getDashboardComponent = (role) => {
    console.log('getDashboardComponent');
    // const redirectLocal = localStorage.getItem('redirect');
    // let userRedirectLink = '/dashboard';
    const userRedirectLink = '/dashboard';
    // if (redirectLocal) {
    //   let qValue = {};
    //   try {
    //     qValue = JSON.parse(redirectLocal);
    //   } catch (err) {
    //     userRedirectLink = '/dashboard';
    //     localStorage.removeItem('redirect');
    //     console.log(err);
    //   }
    //   if (qValue?.page === 'offers' && qValue?.type !== undefined && qValue?.id !== undefined) {
    //     userRedirectLink = `/offers/${qValue?.type}/${qValue?.id}`;
    //     localStorage.removeItem('redirect');
    //   }
    // }

    switch (role) {
      case 'business':
        return <Navigate to="/business" />;

      case 'admin':
        return <Navigate to="/admin/dashboard" />;

      default:
        return <Navigate to={userRedirectLink} />;
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (props.roleRequired) {
    return token && isValidToken ? (
      user?.role ? (
        props.roleRequired === user.role ? (
          <Outlet />
        ) : (
          <>{getDashboardComponent(user.role)}</>
        )
      ) : (
        <></>
        // <Loader />
      )
    ) : (
      <Navigate to="/login" />
    );
  }
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
