/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unneeded-ternary */

import { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Fab, IconButton, Modal, Radio, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';

import {
  getUserAssessmentUser,
  addUserAssessment,
  addUserAssessmentMonthly,
  updateSingleUserAssessment,
  addSingleUserAssessment,
  getUserAssessmentGroup,
} from '../../services/Service';

import UserAssessment1 from '../../assets/UserAssessment/UserAssessment1.png';
import UserAssessment2 from '../../assets/UserAssessment/UserAssessment2.png';
import UserAssessment3 from '../../assets/UserAssessment/UserAssessment3.png';
import UserAssessment4 from '../../assets/UserAssessment/UserAssessment4.png';
import UserAssessment5 from '../../assets/UserAssessment/UserAssessment5.png';

import UserAssessment1w from '../../assets/UserAssessment/UserAssessment1W.png';
import UserAssessment2w from '../../assets/UserAssessment/UserAssessment2W.png';
import UserAssessment3w from '../../assets/UserAssessment/UserAssessment3W.png';
import UserAssessment4w from '../../assets/UserAssessment/UserAssessment4W.png';
import UserAssessment5w from '../../assets/UserAssessment/UserAssessment5W.png';

// {
// "assessmentId": "66f6a70fbdf38cb265d60735",
// "textAnswer": "",
// "optionsAnswer": [],
// "yesNoAnswer": true,
// "yesAnswerText": "THis is datails detailed feedback"
// "noAnswerText": ""
// "notInterested": true
// "isDraft": true
// }

const UserAssessmentIcons = [UserAssessment1, UserAssessment2, UserAssessment3, UserAssessment4, UserAssessment5];
const UserAssessmentIconsActive = [
  UserAssessment1w,
  UserAssessment2w,
  UserAssessment3w,
  UserAssessment4w,
  UserAssessment5w,
];

// scenario = DowngradePlan, DeleteAccount, UpgradePlan, MonthlyFeedback

const UserAssessmentModal = ({ scenario, open, onClose, refetch, data, isReportMode }) => {
  const [rawData, setRawData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState(null);

  const [userAnsArray, setUserAnsArray] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getUserAssessmentUser({ scenario: scenario });
      if (res.status === 200) {
        if (res.data.data?.length > 0) {
          setRawData(res.data.data);
          setGroupId(res.data.groupId);
          if (scenario === 'MonthlyFeedback') {
            const draftValues = res.data.data?.filter((e) => e?.answer?.isDraft);
            const draftValuesPush = draftValues?.map((e) => ({
              _id: e?.answer?._id,
              assessmentId: e?.answer?.masterAssessment,
              textAnswer: e?.answer?.textAnswer,
              optionsAnswer: e?.answer?.optionsAnswer,
              yesNoAnswer: e?.answer?.yesNoAnswer,
              yesAnswerText: e?.answer?.yesAnswerText,
              noAnswerText: e?.answer?.noAnswerText,
              notInterested: e?.answer?.notInterested,
              isDraft: e?.answer?.isDraft,
            }));
            if (draftValuesPush.length > 0) setUserAnsArray(draftValuesPush);
          }
        }
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchDataReport = async () => {
    setLoading(true);
    try {
      const res = await getUserAssessmentGroup({ scenario: scenario, groupId: data?.groupId, date: data?.date });
      if (res.status === 200) {
        if (res.data.data?.length > 0) {
          setRawData(res.data.data);
          setGroupId(res.data.groupId);
          const draftValues = res.data.data;
          const draftValuesPush = draftValues?.map((e) => ({
            _id: e?.answer?._id,
            assessmentId: e?.answer?.masterAssessment,
            textAnswer: e?.answer?.textAnswer,
            optionsAnswer: e?.answer?.optionsAnswer,
            yesNoAnswer: e?.answer?.yesNoAnswer,
            yesAnswerText: e?.answer?.yesAnswerText,
            noAnswerText: e?.answer?.noAnswerText,
            notInterested: e?.answer?.notInterested,
            isDraft: e?.answer?.isDraft,
          }));
          if (draftValuesPush.length > 0) setUserAnsArray(draftValuesPush);
        }
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '1px solid #FFF',
    borderRadius: '10px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    p: 4,
    maxHeight: '95vh',
    overflow: 'auto',
    '@media (max-width: 640px)': {
      width: '95vw',
    },
  };

  const handleClose = () => {
    setCurrentPage(0);
    setUserAnsArray([]);
    setRawData([]);
    setGroupId(null);
    onClose();
  };

  useEffect(() => {
    if (open === true) {
      if (isReportMode) {
        fetchDataReport();
      } else {
        fetchData();
      }
    }
  }, [open, isReportMode]);

  const currentPageObj = rawData[currentPage - 1];

  const handleNextDraft = () => {
    if (isReportMode) {
      setCurrentPage((prev) => prev + 1);
      return;
    }
    if (scenario !== 'MonthlyFeedback') {
      setCurrentPage((prev) => prev + 1);
      return;
    }
    const draftPage = findLastIsDraftIndex(rawData);

    if (draftPage !== -1) {
      setCurrentPage(draftPage + 2);
    } else {
      setCurrentPage((prev) => prev + 1);
    }
  };
  const handleNext = async () => {
    if (!isReportMode && scenario === 'MonthlyFeedback') {
      await nextSaveAndUpdate();
    }
    setCurrentPage((prev) => prev + 1);
  };

  const nextSaveAndUpdate = async () => {
    const ansFindIndex = userAnsArray?.findIndex((e) => e?.assessmentId === currentPageObj?._id);

    if (ansFindIndex === -1) return;
    const ansFind = userAnsArray[ansFindIndex];
    try {
      let res = null;
      const payload = { ...ansFind, isDraft: true, groupId };

      if (payload?._id) {
        res = await updateSingleUserAssessment(payload);
      } else {
        res = await addSingleUserAssessment(payload);
      }
      if (res && res?.data?.data) {
        const answer = res?.data?.data;
        const newObj = {
          _id: answer?._id,
          assessmentId: answer?.masterAssessment,
          textAnswer: answer?.textAnswer,
          optionsAnswer: answer?.optionsAnswer,
          yesNoAnswer: answer?.yesNoAnswer,
          yesAnswerText: answer?.yesAnswerText,
          noAnswerText: answer?.noAnswerText,
          notInterested: answer?.notInterested,
          isDraft: answer?.isDraft,
        };
        setUserAnsArray((prev) => {
          const newAns = [...prev];
          newAns[ansFindIndex] = newObj;
          return newAns;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSkip = async () => {
    if (isReportMode) return;
    if (scenario === 'MonthlyFeedback') {
      const valueIndex = userAnsArray.findIndex((e) => e.assessmentId === currentPageObj?._id);
      let res = null;
      if (valueIndex !== -1) {
        const payload = { ...userAnsArray[valueIndex], groupId, isDraft: true };
        payload.textAnswer = null;
        payload.optionsAnswer = null;
        payload.yesNoAnswer = null;
        payload.yesAnswerText = null;
        payload.noAnswerText = null;
        payload.notInterested = true;

        if (payload?._id) {
          res = await updateSingleUserAssessment(payload);
        } else {
          res = await addSingleUserAssessment(payload);
        }
      } else {
        const payload = {
          assessmentId: currentPageObj?._id,
          notInterested: true,
          isDraft: true,
          groupId,
        };
        res = await addSingleUserAssessment(payload);
      }
      if (res && res?.data?.data) {
        const answer = res?.data?.data;
        const newObj = {
          _id: answer?._id,
          assessmentId: answer?.masterAssessment,
          textAnswer: answer?.textAnswer,
          optionsAnswer: answer?.optionsAnswer,
          yesNoAnswer: answer?.yesNoAnswer,
          yesAnswerText: answer?.yesAnswerText,
          noAnswerText: answer?.noAnswerText,
          notInterested: answer?.notInterested,
          isDraft: answer?.isDraft,
        };
        setUserAnsArray((prev) => {
          const newAns = [...prev];
          if (valueIndex === -1) {
            newAns.push(newObj);
          } else {
            newAns[valueIndex] = newObj;
          }
          return newAns;
        });
      }
    } else {
      setUserAnsArray((prev) => {
        const newAns = [...prev];
        const valueIndex = newAns.findIndex((e) => e.assessmentId === currentPageObj?._id);
        if (valueIndex !== -1) {
          newAns[valueIndex].textAnswer = undefined;
          newAns[valueIndex].optionsAnswer = undefined;
          newAns[valueIndex].yesNoAnswer = undefined;
          newAns[valueIndex].yesAnswerText = undefined;
          newAns[valueIndex].noAnswerText = undefined;
          newAns[valueIndex].notInterested = true;
        } else {
          const ansObj = {
            assessmentId: currentPageObj?._id,
            notInterested: true,
          };
          newAns.push(ansObj);
        }
        return newAns;
      });
    }
    if (rawData.length !== currentPage) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const handleSubmit = async () => {
    if (isReportMode) return;
    try {
      let res = null;
      if (scenario === 'MonthlyFeedback') {
        res = await addUserAssessmentMonthly({ assessments: userAnsArray, groupId });
      } else {
        res = await addUserAssessment({ assessments: userAnsArray, groupId });
      }
      if (res?.status === 200) {
        toast.success('Feedback Submitted Successfully!');
        handleClose();
        if (refetch) refetch();
      }
    } catch (e) {
      console.log(e);
    }
  };
  console.log('DEBUG userAnsArray', userAnsArray);

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <Box sx={{ ...style }}>
          {currentPage === 0 && (
            <IntroPage
              isReportMode={isReportMode}
              data={rawData}
              disabled={loading}
              handleClose={handleClose}
              handleNext={handleNextDraft}
            />
          )}
          {currentPage !== 0 && (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {isReportMode ? (
                  <div style={{ width: '100px' }} />
                ) : (
                  <Button onClick={handleSkip} variant="text" sx={{ textTransform: 'none' }}>
                    Not Interested
                  </Button>
                )}
                <ProgressBar total={rawData.length} progress={currentPage} />
                <Fab onClick={handleClose} size="small" color="primary" aria-label="add">
                  <CloseIcon />
                </Fab>
              </div>
              <p style={{ textAlign: 'center', margin: '24px 0px' }}>{currentPageObj?.question}</p>
              {currentPageObj?.questionType === 'text' && (
                <TextFieldPage
                  isReportMode={isReportMode}
                  data={currentPageObj}
                  setAns={setUserAnsArray}
                  ans={userAnsArray}
                />
              )}
              {currentPageObj?.questionType === 'yesNo' && (
                <YesNoPage
                  isReportMode={isReportMode}
                  data={currentPageObj}
                  setAns={setUserAnsArray}
                  ans={userAnsArray}
                />
              )}
              {currentPageObj?.questionType === 'options' && currentPageObj?.optionType === 'icon' && (
                <OptionsIconPage
                  isReportMode={isReportMode}
                  data={currentPageObj}
                  setAns={setUserAnsArray}
                  ans={userAnsArray}
                />
              )}
              {currentPageObj?.questionType === 'options' && currentPageObj?.optionType === 'text' && (
                <CheckBoxOptionsPage
                  isReportMode={isReportMode}
                  data={currentPageObj}
                  setAns={setUserAnsArray}
                  ans={userAnsArray}
                />
              )}
              <div
                style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px', alignItems: 'center' }}
              >
                <Button onClick={handlePrev} variant="outlined" sx={{ textTransform: 'none' }}>
                  Previous
                </Button>
                <NotInterestedText data={currentPageObj} ans={userAnsArray} />
                <NextButton
                  isReportMode={isReportMode}
                  data={currentPageObj}
                  ans={userAnsArray}
                  title={currentPage === rawData.length ? (isReportMode ? 'Close' : 'Submit') : 'Next'}
                  onClick={currentPage === rawData.length ? (isReportMode ? handleClose : handleSubmit) : handleNext}
                />
              </div>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default UserAssessmentModal;

const IntroPage = ({ handleClose, handleNext, disabled, data, isReportMode }) => {
  const draftIndex = findLastIsDraftIndex(data);

  return (
    <>
      <Fab
        sx={{ position: 'absolute', top: '16px', right: '16px' }}
        onClick={handleClose}
        size="small"
        color="primary"
        aria-label="add"
      >
        <CloseIcon />
      </Fab>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ marginBottom: '24px', fontSize: '22px', fontWeight: 700, color: '#202224', textAlign: 'center' }}
      >
        Help Us Improve Your Experience!
      </Typography>
      <div data-color-mode="light">
        <p style={{ textAlign: 'center', marginBottom: '64px', color: '#7E84A3' }}>
          Your feedback matters! Let us know how the platform has been performing for you this month. Your insights help
          us enhance features, fix issues, and ensure the platform meets your needs. Plus, your feedback earns you
          points!
        </p>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {isReportMode ? (
            <div />
          ) : (
            <Button onClick={handleClose} variant="outlined" sx={{ textTransform: 'none' }}>
              Rate Later
            </Button>
          )}
          <Button onClick={handleNext} disabled={disabled} variant="contained" sx={{ textTransform: 'none' }}>
            {isReportMode ? 'View your answers' : draftIndex !== -1 ? 'Continue from draft' : 'Rate our service'}
          </Button>
        </div>
      </div>
    </>
  );
};

const OptionsIconPage = ({ data, setAns, ans, isReportMode }) => {
  const valueIndex = ans?.findIndex((e) => e?.assessmentId === data?._id);
  const valueArray = valueIndex === -1 ? [] : ans[valueIndex]?.optionsAnswer;
  const value = valueArray?.[0];

  const checkedValue = parseInt(value, 10);

  const onChange = (index) => {
    if (isReportMode) return;
    if (valueIndex === -1) {
      setAns((prev) => {
        const newAns = [...prev];
        const ansObj = {
          assessmentId: data?._id,
          optionsAnswer: [index],
        };
        newAns.push(ansObj);
        return newAns;
      });
    } else {
      setAns((prev) => {
        const newAns = [...prev];
        if (value === index) {
          newAns[valueIndex].optionsAnswer = [];
        } else {
          newAns[valueIndex].optionsAnswer = [index];
        }
        return newAns;
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start', gap: '12px' }}>
      {data?.options?.map((e, index) => (
        <IconOption
          disabled={isReportMode}
          value={checkedValue}
          key={index}
          index={index}
          text={e}
          onClick={() => onChange(index)}
        />
      ))}
    </div>
  );
};
const YesNoPage = ({ data, setAns, ans, isReportMode }) => {
  const valueIndex = ans?.findIndex((e) => e?.assessmentId === data?._id);
  const value = valueIndex === -1 ? '' : ans[valueIndex]?.yesNoAnswer;
  const valueText =
    value === true ? ans[valueIndex]?.yesAnswerText : value === false ? ans[valueIndex]?.noAnswerText : '';

  const onChangeText = (e) => {
    if (isReportMode) return;
    const newValue = e.target.value;

    setAns((prev) => {
      const newAns = [...prev];
      if (value === true) {
        newAns[valueIndex].yesAnswerText = newValue;
      } else if (value === false) {
        newAns[valueIndex].noAnswerText = newValue;
      } else {
        newAns[valueIndex].noAnswerText = '';
        newAns[valueIndex].yesAnswerText = '';
      }
      return newAns;
    });
  };
  const onChange = (e) => {
    if (isReportMode) return;
    const newValue = e;
    if (valueIndex === -1) {
      setAns((prev) => {
        const newAns = [...prev];
        const ansObj = {
          assessmentId: data?._id,
          yesNoAnswer: newValue,
        };
        newAns.push(ansObj);
        return newAns;
      });
    } else {
      setAns((prev) => {
        const newAns = [...prev];
        // if (newAns[valueIndex].yesNoAnswer === newValue) {
        //   newAns[valueIndex].yesNoAnswer = '';
        // } else {
        //   newAns[valueIndex].yesNoAnswer = newValue;
        // }
        newAns[valueIndex].yesNoAnswer = newValue;
        newAns[valueIndex].notInterested = false;
        return newAns;
      });
    }
  };

  const isTextarea = value === true ? data?.isQuestionTypeYesText : data?.isQuestionTypeNoText;
  const textareaPlaceholder = value === true ? data?.yesPlaceholder : data?.noPlaceholder;

  const isCheckedTrue = value === true;
  const isCheckedFalse = value === false;

  const textareaValue = valueText;

  return (
    // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Radio
            disabled={isReportMode}
            name="radio-buttons-UseAcc"
            checked={isCheckedTrue}
            onChange={() => onChange(true)}
          />
          <span>Yes</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Radio
            disabled={isReportMode}
            name="radio-buttons-UseAcc"
            checked={isCheckedFalse}
            onChange={() => onChange(false)}
          />
          <span>No</span>
        </div>
      </div>
      {isTextarea && (
        <textarea
          disabled={isReportMode}
          value={textareaValue}
          onChange={onChangeText}
          placeholder={textareaPlaceholder}
          type="text"
          style={{
            width: '100%',
            border: '1px solid #D7DBEC',
            borderRadius: '12px',
            height: '120px',
            color: '#7E84A3',
            padding: '12px',
            resize: 'none',
            outline: 'none',
          }}
        />
      )}
    </div>
  );
};
const TextFieldPage = ({ data, setAns, ans, isReportMode }) => {
  const valueIndex = ans?.findIndex((e) => e?.assessmentId === data?._id);
  const value = valueIndex === -1 ? '' : ans[valueIndex]?.textAnswer;

  const textareaValue = value;

  const onChange = (e) => {
    if (isReportMode) return;
    const newValue = e.target.value;
    if (valueIndex === -1) {
      setAns((prev) => {
        const newAns = [...prev];
        const ansObj = {
          assessmentId: data?._id,
          textAnswer: newValue,
        };
        newAns.push(ansObj);
        return newAns;
      });
    } else {
      setAns((prev) => {
        const newAns = [...prev];
        newAns[valueIndex].textAnswer = newValue;
        newAns[valueIndex].notInterested = false;
        return newAns;
      });
    }
  };

  return (
    <textarea
      disabled={isReportMode}
      placeholder="Please, provide your feedback"
      type="text"
      value={textareaValue}
      onChange={onChange}
      style={{
        border: '1px solid #D7DBEC',
        borderRadius: '12px',
        height: '120px',
        color: '#7E84A3',
        padding: '12px',
        resize: 'none',
        outline: 'none',
      }}
    />
  );
};
const CheckBoxOptionsPage = ({ data, setAns, ans, isReportMode }) => {
  const valueIndex = ans?.findIndex((e) => e?.assessmentId === data?._id);
  const valueArray = valueIndex === -1 ? [] : ans[valueIndex]?.optionsAnswer;

  const checkedValues = valueArray?.map((e) => parseInt(e, 10));

  const onChange = (index, checked) => {
    if (isReportMode) return;
    if (valueIndex === -1) {
      setAns((prev) => {
        const newAns = [...prev];
        const ansObj = {
          assessmentId: data?._id,
          optionsAnswer: [index],
        };
        newAns.push(ansObj);
        return newAns;
      });
    } else {
      setAns((prev) => {
        const newAns = [...prev];
        const optionsAnswer = [...newAns[valueIndex].optionsAnswer];
        const incd = optionsAnswer?.includes(index);
        if (checked && !incd) {
          optionsAnswer.push(index);
        } else {
          optionsAnswer.splice(optionsAnswer.indexOf(index), 1);
        }
        newAns[valueIndex].optionsAnswer = optionsAnswer;
        newAns[valueIndex].notInterested = false;
        return newAns;
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        maxHeight: '400px',
        overflow: 'auto',
        paddingLeft: '32px',
      }}
    >
      {data?.options?.map((e, index) => (
        <CheckBoxOption
          disabled={isReportMode}
          values={checkedValues}
          index={index}
          key={index}
          text={e}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

const IconOption = ({ index, text, value, onClick, disabled }) => {
  const isSelected = index === value;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        disabled={disabled}
        onClick={onClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isSelected ? '#366dfa' : '#eceff7',
          borderRadius: '12px',
          width: '64px',
          height: '64px',
        }}
      >
        <img
          src={isSelected ? UserAssessmentIconsActive[index] : UserAssessmentIcons[index]}
          alt={text}
          style={{ width: '40px', height: '40px', objectFit: 'contain' }}
        />
      </div>
      <Tooltip title={text}>
        <p style={{ textAlign: 'center', fontSize: '14px' }} className="modernWay-max-lines-3">
          {text}
        </p>
      </Tooltip>
    </div>
  );
};
const CheckBoxOption = ({ text, values, index, onChange, disabled }) => {
  const checked = values?.includes(index);

  const handleChange = (event) => {
    onChange(index, event.target.checked);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Checkbox disabled={disabled} checked={checked} onChange={handleChange} />
      <Tooltip title={text}>
        <p className="modernWay-max-lines-2" style={{ fontSize: '14px' }}>
          {text}
        </p>
      </Tooltip>
    </div>
  );
};
const ProgressBar = ({ total, progress }) => {
  const _total = total ?? 0;
  const _progress = progress ?? 0;

  const current = _progress / _total;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px',
        marginRight: '48px',
      }}
    >
      <span>
        {_progress}/{_total}
      </span>
      <div
        style={{ width: '200px', backgroundColor: '#eceff7', height: '6px', borderRadius: '8px', position: 'relative' }}
      >
        <div
          style={{
            width: `${current * 100}%`,
            backgroundColor: '#366dfa',
            height: '100%',
            borderRadius: '8px',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      </div>
    </div>
  );
};

const NextButton = ({ data, ans, title, onClick, isReportMode }) => {
  const valueIndex = ans?.findIndex((e) => e?.assessmentId === data?._id);
  const optionsAnswer = valueIndex === -1 ? [] : ans[valueIndex]?.optionsAnswer;
  const yesNoAnswer = valueIndex === -1 ? '' : ans[valueIndex]?.yesNoAnswer;
  const textAnswer = valueIndex === -1 ? '' : ans[valueIndex]?.textAnswer;
  const notInterested = valueIndex === -1 ? false : ans[valueIndex]?.notInterested;

  const yesNoAnswerRes = typeof yesNoAnswer !== 'boolean';

  const isDisable = isReportMode
    ? false
    : notInterested === true
    ? false
    : data?.questionType === 'text'
    ? textAnswer?.length < 1
    : data?.questionType === 'yesNo'
    ? yesNoAnswerRes
    : data?.questionType === 'options'
    ? optionsAnswer.length < 1
    : false;

  return (
    <Button disabled={isDisable} onClick={onClick} variant="contained" sx={{ textTransform: 'none' }}>
      {title}
    </Button>
  );
};
const NotInterestedText = ({ data, ans, title, onClick }) => {
  const valueIndex = ans?.findIndex((e) => e?.assessmentId === data?._id);

  const notInterested = valueIndex === -1 ? false : ans[valueIndex]?.notInterested;

  if (notInterested) return <span style={{ fontSize: '14px', color: '#ff3b30' }}>Not Interested</span>;
};

function findLastIsDraftIndex(data) {
  let lastIndex = -1;
  data.forEach((item, index) => {
    if (item.answer && item.answer.isDraft === true) {
      lastIndex = index; // Update lastIndex if isDraft is true
    }
  });
  return lastIndex;
}
