import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import moment from 'moment';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { usStates, indiaStates } from '../../../utils/stateList';
import { getSafalGiftAddress } from '../../../services/Service';

import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import AddSafalGiftAddressModal from './AddSafalGiftAddressModal';
import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function SafalGiftOrderPrecessModal({
  open,
  onClose,
  data,
  onOrderProcess,
  disabled,
  yourCurrentPoints,
}) {
  const [userAddressList, setUserAddressList] = React.useState([]);
  const [userAddressSelected, setUserAddressSelected] = React.useState('');
  const { user } = useSelector((state) => state.login);
  const [orderAddressModal, setOrderAddressModal] = React.useState({
    open: false,
    data: null,
  });

  const onOrderAddressOpen = () => {
    setOrderAddressModal({
      open: true,
      data: null,
    });
  };
  const onOrderAddressClose = () => {
    fetchAddress();
    setOrderAddressModal({
      open: false,
      data: null,
    });
  };

  const { formatDate } = useUserTimeZone();
  const handleClose = () => {
    setUserAddressSelected('');
    onClose();
  };

  const fetchAddress = async () => {
    try {
      const res = await getSafalGiftAddress();
      if (res.status === 200) {
        setUserAddressList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (open) {
      fetchAddress();
    }
  }, [open]);

  const placeOrder = async () => {
    await onOrderProcess({
      cartId: data?._id,
      addressId: userAddressSelected,
    });
    handleClose();
  };

  const isOrderDisable = userAddressSelected?.length < 3;

  const findAddress = userAddressList?.find((e) => e?._id === userAddressSelected);
  const findState = usStates?.find((e) => e.abbreviation === findAddress?.state);

  const userFullName = `${user?.firstName} ${user?.middleName} ${user?.lastName}`;
  const userPoints = yourCurrentPoints?.points || 0;
  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}
          >
            SafalGift Order - Summary
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            <div style={{ display: 'flex', fontSize: '14px' }}>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#4F79EA' }}>
                  Date : {formatDate(moment().toString())} {getTimeZoneAbbreviation()}
                </span>
                {findAddress?.state && <span style={{ color: '#4F79EA' }}>State : {findState?.name}</span>}
                <span style={{ color: '#4F79EA' }}>Customer Information</span>
                <span>Customer Name: {userFullName}</span>
                {findAddress?.phoneNumber && <span>Phone Number: {findAddress?.phoneNumber || ''}</span>}
              </div>
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: '#4F79EA' }}>Select delivery address</span>
                {/* select */}
                <CustomSimpleSelect
                  // sx={{
                  //   width: '200px',
                  // }}
                  valueKey="_id"
                  value={userAddressSelected}
                  onChange={(e) => setUserAddressSelected(e)}
                  options={userAddressList?.map((e) => ({ label: e?.name, ...e }))}
                  onAddNew={onOrderAddressOpen}
                />
                {findAddress?.address1 && <span>Address 1 : {findAddress?.address1 || ''}</span>}
                {findAddress?.address2 && <span>Address 2 : {findAddress?.address2 || ''}</span>}
                {findAddress?.landmark && <span>Landmark : {findAddress?.landmark || ''}</span>}
                {findAddress?.zip && <span>Zip : {findAddress?.zip || ''}</span>}
              </div>
            </div>
            <div style={{ borderBottom: '1px solid #0932A14A' }}>
              <table className="CustomSimpleTable-com">
                <thead>
                  <tr>
                    <th>SL#</th>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Safal Points</th>
                    <th>Total Spts</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.cart?.map((e, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td style={{ gap: '8px', display: 'flex', alignItems: 'center' }}>
                        <img
                          style={{ height: '24px', objectFit: 'contain' }}
                          src={e?.product?.image}
                          alt={e?.product?.name}
                        />
                        {e?.product?.name}
                      </td>
                      <td>{e?.qty}</td>
                      <td>{new Intl.NumberFormat().format(e?.product?.points)} Spts</td>
                      <td>{new Intl.NumberFormat().format(e?.qty * e?.product?.points)} Spts</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '14px' }}>
              <div style={{ color: '#4F79EA', fontWeight: 'bold', display: 'flex', flexDirection: 'column' }}>
                <span>Wallet Balance</span>
                <span>
                  <b style={{ fontSize: '24px', fontFamily: 'Keania One' }}>{userPoints}</b> Spts
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '220px',
                  gap: '6px',
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Gross Total</span>
                  <span>{new Intl.NumberFormat().format(data?.totalPoints)} Spts</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderTop: '1px solid #0932A14A',
                    paddingTop: '4px',
                  }}
                >
                  <span>Net Total</span>
                  <span>{new Intl.NumberFormat().format(data?.totalPoints)} Spts</span>
                </div>
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
            <Button
              onClick={handleClose}
              disabled={disabled}
              sx={{
                textTransform: 'capitalize',
                backgroundColor: '#ff4842',
                ':hover': { backgroundColor: '#d40700' },
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              disabled={disabled === true ? true : isOrderDisable}
              onClick={placeOrder}
              sx={{ backgroundColor: '#3D71FF', textTransform: 'capitalize', ':hover': { backgroundColor: '#3D71FF' } }}
              variant="contained"
            >
              Place Order
            </Button>
          </div>
        </Box>
      </Modal>
      <AddSafalGiftAddressModal
        open={orderAddressModal.open}
        data={orderAddressModal.data}
        onClose={onOrderAddressClose}
      />
    </>
  );
}
