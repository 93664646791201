import { configureStore } from '@reduxjs/toolkit';
import subscriptionSlice from './subscriptionSlice';
import loginSlice from './loginSlice';
import companiesSlice from './companiesSlice';
import authSlice from './AuthSlice';
import chatSlice from './ChatSlice';
import feedbackSlice from './feedbackSlice';
import businessSlice from './businessSlice';
import callSlice from './callSlice';
import currentFirebaseUserSlice from './currentFirebaseUserSlice';
import BusinessNotificationsDotSlice from './businessNotificationsDotSlice';
import agoraSlice from './AgoraSlice';
import agoraMiniSlice from './AgoraMiniSlice';
import callRecoirdingAlertState from './CallRecoirdingAlert';
import toggleSlice from './ToggleSlice';
import dynamicMenuSlice from './DynamicMenuSlice';
import profileImageSlice from './ProfileImageSlice';
import newChatSlice from './NewChatSlice';
import safalLeaderBoardSwitchSlice from './SafalLeaderBoardSwitchSlice';
// import notificationSlice from './notificationSlice';

export const store = configureStore({
  reducer: {
    login: loginSlice,
    subscription: subscriptionSlice,
    companies: companiesSlice,
    business: businessSlice,
    // notification : notificationSlice,
    auth: authSlice,
    chats: chatSlice,
    feedback: feedbackSlice,
    call: callSlice,
    currentFirebaseUser: currentFirebaseUserSlice,
    businessNotificationsDot: BusinessNotificationsDotSlice,
    agoraState: agoraSlice,
    agoraMiniState: agoraMiniSlice,
    callRecoirdingAlertState: callRecoirdingAlertState,
    toggleState: toggleSlice,
    dynamicMenuState: dynamicMenuSlice,
    safalLeaderBoardSwitch: safalLeaderBoardSwitchSlice,
    profileImageState: profileImageSlice,
    newChatState: newChatSlice,
  },
});
