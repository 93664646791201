import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import toast from 'react-hot-toast';

import { FeedMessage } from './SafalRoomFeed';
import { createSafalRoomPrivateForumUser } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '16px',
  p: 3,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  topic: '', // min 3, max 30
  name: '', // min 3, max 50
  description: '', // min 100 char
};

export default function AddSafalRoomCommunityForumModal({ open, onClose, data, companyId, roomId }) {
  const [fieldValue, setFieldValue] = useState(initFieldValue);
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    onClose();
    setFieldValue(initFieldValue);
  };

  const validateFields = () => {
    if (fieldValue.name.length < 3 || fieldValue.name.length > 50) {
      toast.error('Name must be between 3 and 50 characters.');
      return false;
    }
    if (fieldValue.topic.length < 3 || fieldValue.topic.length > 200) {
      toast.error('Topic must be between 3 and 200 characters.');
      return false;
    }
    if (fieldValue.description.length < 100 || fieldValue.description.length > 500) {
      toast.error('Description must be between 100 and 500 characters.');
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!validateFields()) return;
    const payload = {
      ...fieldValue,
      company: companyId,
      roomId: roomId,
      status: true,
    };

    try {
      setDisabled(true);
      const res = await createSafalRoomPrivateForumUser(payload);
      if (res.status === 200) {
        toast.success(res?.data?.message);
        handleClose();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '8px', fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}
          >
            Create Community Forum
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              disabled={disabled}
              value={fieldValue.name}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
              label="Name"
              inputProps={{ minLength: 3, maxLength: 50 }}
              helperText={`${fieldValue.name.length}/50`}
            />
            <TextField
              disabled={disabled}
              value={fieldValue.topic}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, topic: e.target.value }))}
              label="Topic"
              inputProps={{ minLength: 3, maxLength: 200 }}
              helperText={`${fieldValue.topic.length}/200`}
            />
            <TextField
              disabled={disabled}
              value={fieldValue.description}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, description: e.target.value }))}
              multiline
              rows={3}
              label="Description"
              inputProps={{ minLength: 100, maxLength: 500 }}
              helperText={`${fieldValue.description.length}/500`}
            />
            <p style={{ fontSize: '14px' }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt iusto quae incidunt enim, mollitia ipsum
              natus non itaque assumenda est dicta distinctio ipsam id magni tempore eligendi earum, quis quaerat!
            </p>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'space-between' }}>
            <div />
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={handleClose}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  backgroundColor: '#ff4842',
                  ':hover': { backgroundColor: '#d40700' },
                }}
                variant="contained"
              >
                Close
              </Button>
              <Button
                onClick={onSubmit}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
