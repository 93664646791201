/* eslint-disable no-else-return */
import { Box, Button, Fab, MenuItem, Modal, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';

import { CustomTableCellForBody, CustomTableCellForNoData, CustomTableView } from '../UI/CustomTableView';
import ThreeDotMenu from './ThreeDotMenu';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '95vh',
  minHeight: '200px',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  p: 6,
  pt: 3,
  '@media (max-width: 640px)': {
    width: '95vw',
    height: '95vh',
    overflow: 'auto',
  },
};

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const EmailTableModal = ({ open, onClose, data }) => {
  const isModalOpen = open;
  const [uploadData, setUploadData] = useState([]);

  const onModalClose = async () => {
    onClose();
  };

  const onEdit = (e, index) => {};

  const onReject = (e, index) => {};
  const onAccept = (e, index) => {};

  return (
    <>
      <Modal
        onClose={onModalClose}
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={onModalClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <div id="EmailTableModal">
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '30px',
                fontWeight: 700,
                color: '#3D71FF',
                marginBottom: '12px',
              }}
            >
              Email Crawler Table
            </Typography>
            <div className="header-psi" style={{ gap: '12px' }}>
              <PdfTagUI label="Total" value={`12 Nos`} backgroundColor="#c7dcf0" color="black" />
              <PdfTagUI label="Matching" value={`12 Nos`} backgroundColor="#25d3eb" color="black" />
              <PdfTagUI label="Unmatched" value={`12 Nos`} backgroundColor="#c7dcf0" color="black" />
              <div style={{ margin: 'auto' }} />
              <PdfTagUI label="Accepted" value={`12 Nos`} backgroundColor="#8fc49e" />
              <PdfTagUI label="Rejected" value={`12 Nos`} backgroundColor="#bb7c7c" />
              <PdfTagUI label="Pending" value={`12 Nos`} backgroundColor="#bcc391" />
            </div>
            <div>
              <TableContainer>
                <CustomTableView
                  // isSort
                  // sort={{
                  //   order: sortState.order,
                  //   orderBy: sortState.orderBy,
                  //   createSortHandler: createSortHandler,
                  //   sortHeadMap: [
                  //     '',
                  //     'contractStartDate',
                  //     'company',
                  //     'subscriptionType',
                  //     'description',
                  //     'amount',
                  //     'flag',
                  //     'Actions',
                  //   ],
                  // }}
                  headData={['Sl No', 'Date', 'Company Name', 'Type', 'Description', 'Amount', 'Status', '']}
                >
                  {uploadData.length === 0 && <CustomTableCellForNoData message="We didn't find any match." />}
                  {uploadData?.map((e, index) => (
                    <TableRow key={index}>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {index + 1}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.contractStartDate}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.company}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.subscriptionType}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.description}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.amount?.toString()?.includes('$') ? e.amount : `$ ${e.amount}`}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.flag}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <ThreeDotMenu
                          onAccept={() => onAccept(e, index)}
                          onReject={() => onReject(e, index)}
                          onEdit={() => onEdit(e, index)}
                        />
                      </CustomTableCellForBody>
                    </TableRow>
                  ))}
                </CustomTableView>
              </TableContainer>
            </div>
          </div>
        </Box>
      </Modal>
      {/* <MultiSubscriptionsOptions
        open={openReviewMulti.open}
        onClick={onEdit}
        data={openReviewMulti.data}
        onClose={onReviewMultiClose}
      />
      <EditSubscriptionModal
        fetchPaymentModeInfo={fetchPaymentModeInfo}
        paymentCardSchemeList={paymentCardSchemeList}
        paymentInstrumentList={paymentInstrumentList}
        paymentModeList={paymentModeList}
        data={openReview.data}
        open={openReview.open}
        onClose={onCloseReview}
      />
      <EditCustomSubscriptionModal
        data={openReviewCustom.data}
        open={openReviewCustom.open}
        onClose={onCloseReviewCustom}
      /> */}
    </>
  );
};
export default EmailTableModal;

const PdfTagUI = ({ label, value, backgroundColor, color }) => {
  return (
    <div className="pdf-tag" style={{ backgroundColor, color }}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};
