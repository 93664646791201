import { Box, Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import ReactECharts from 'echarts-for-react';
import { useDispatch, useSelector } from 'react-redux';

import total_download1 from '../../assets/total_download1.png';
import total_upload1 from '../../assets/total_upload1.png';

import { getUserCallLimit, getCallChartData } from '../../services/Service';
import CustomNewDashboardBoxUnlimited from '../../components/UI/CustomNewDashboardBoxUnlimited';

const NewCallChart = () => {
  const { user } = useSelector((state) => state.login);
  const [callLimit, setCallLimit] = useState({
    audioCall: {
      used: user.AudioCallDuration,
      available: 0,
      allocated: 0,
    },
    videoCall: {
      used: user.videoCallDuration,
      available: 0,
      allocated: 0,
    },
  });

  useEffect(() => {
    getCallChartData().then((res) => {
      if (res.data.success === true) {
        if (user.tier === 'Beta') {
          setCallLimit({
            audioCall: {
              // used: user.AudioCallDuration,
              // available: res.data.data[0].audio,
              // allocated: 0,
              used: res.data.all.audio.used,
              available: res.data.all.audio.available,
              allocated: res.data.all.audio.max,
            },
            videoCall: {
              // used: user.videoCallDuration,
              // available: res.data.data[0].video,
              // allocated: 0,
              used: res.data.all.video.used,
              available: res.data.all.video.available,
              allocated: res.data.all.video.max,
            },
          });
        } else if (user.tier === 'Free') {
          setCallLimit({
            audioCall: {
              // used: user.AudioCallDuration,
              // available: res.data.data[1].audio,
              // allocated: 0,
              used: res.data.all.audio.used,
              available: res.data.all.audio.available,
              allocated: res.data.all.audio.max,
            },
            videoCall: {
              // used: user.videoCallDuration,
              // available: res.data.data[1].video,
              // allocated: 0,
              used: res.data.all.video.used,
              available: res.data.all.video.available,
              allocated: res.data.all.video.max,
            },
          });
        } else if (user.tier === 'Paid') {
          setCallLimit({
            audioCall: {
              // used: user.AudioCallDuration,
              // available: res.data.data[2].audio,
              // allocated: 0,
              used: res.data.all.audio.used,
              available: res.data.all.audio.available,
              allocated: res.data.all.audio.max,
            },
            videoCall: {
              // used: user.videoCallDuration,
              // available: res.data.data[2].video,
              // allocated: 0,
              used: res.data.all.video.used,
              available: res.data.all.video.available,
              allocated: res.data.all.video.max,
            },
          });
        } else if (user.tier === 'Premium') {
          setCallLimit({
            audioCall: {
              // used: user.AudioCallDuration,
              // available: res.data.data[3].audio,
              // allocated: 0,
              used: res.data.all.audio.used,
              available: res.data.all.audio.available,
              allocated: res.data.all.audio.max,
            },
            videoCall: {
              // used: user.videoCallDuration,
              // available: res.data.data[3].video,
              // allocated: 0,
              used: res.data.all.video.used,
              available: res.data.all.video.available,
              allocated: res.data.all.video.max,
            },
          });
        }
      }
    });
  }, []);

  const labelsUpload = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];
  const labelsDownload = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];

  const isUnlimitedAudio = false;
  const isUnlimitedVideo = false;
  // const isUnlimitedAudio = downloads.max === null && downloads.limit === null;
  // const isUnlimitedVideo = uploads.max === null && uploads.limit === null;

  return (
    <>
      {isUnlimitedVideo ? (
        <CustomNewDashboardBoxUnlimited
          maxWidth="350px"
          minWidth="320px"
          color="#0092a7"
          img={total_upload1}
          title="Total Upload"
          leftTitle="Plan"
          leftValue="Unlimited"
          rightTitle="Actual"
          rightValue={callLimit.videoCall.used}
          // onClick={navigateSubHandler}
        />
      ) : (
        <div
          style={{
            width: '50%',
            flex: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold' }}>Audio Call</span>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReactSpeedometer
              forceRender
              maxSegmentLabels={1}
              customSegmentStops={[0, callLimit?.videoCall?.used || 0, callLimit?.videoCall?.allocated || 0]}
              segmentColors={['#3e71ff', '#01bc56']}
              needleColor={'#3e71ff'}
              currentValueText={`${
                ((callLimit?.videoCall?.used / callLimit?.videoCall?.allocated) * 100).toFixed(2) || 0
              } %`}
              minValue={0}
              maxValue={callLimit?.videoCall?.allocated || 0}
              value={callLimit?.videoCall?.used || 0}
              textColor="#3e71ff"
              ringWidth={30}
              height={180}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            {labelsDownload?.map((e, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
                <span style={{ fontSize: '14px' }}>{e?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {isUnlimitedAudio ? (
        <CustomNewDashboardBoxUnlimited
          color="#f22f75"
          maxWidth="350px"
          minWidth="320px"
          img={total_download1}
          title="Total Download"
          leftTitle="Plan"
          leftValue="Unlimited"
          rightTitle="Actual"
          rightValue={callLimit?.audioCall?.used}
          // onClick={navigateSubHandler}
        />
      ) : (
        <div
          style={{
            width: '50%',
            flex: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold' }}>Video Call</span>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReactSpeedometer
              forceRender
              maxSegmentLabels={1}
              customSegmentStops={[0, callLimit?.audioCall?.used || 0, callLimit?.audioCall?.allocated || 0]}
              segmentColors={['#3e71ff', '#01bc56']}
              needleColor={'#3e71ff'}
              currentValueText={`${
                ((callLimit?.audioCall?.used / callLimit?.audioCall?.allocated) * 100).toFixed(2) || 0
              } %`}
              minValue={0}
              maxValue={callLimit?.audioCall?.allocated || 0}
              value={callLimit?.audioCall?.used || 0}
              textColor="#3e71ff"
              ringWidth={30}
              height={180}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            {labelsUpload?.map((e, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
                <span style={{ fontSize: '14px' }}>{e?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default NewCallChart;
