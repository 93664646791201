/* eslint-disable react/jsx-boolean-value */
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { getAdClicksAndImpressionsAnalytics } from '../../../../services/Service';

// const colors = ['#468b47','#90ee90', '#edf9eb',"#385239"];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const ClicksImpressionBarChartCard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState('ThisYear'); // ThisYear, LastYear

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const res = await getAdClicksAndImpressionsAnalytics(filter);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let startDate = '';
    let endDate = '';

    switch (mode) {
      case 'ThisYear':
        startDate = moment().startOf('year').toISOString();
        endDate = moment().endOf('year').toISOString();
        break;
      case 'LastYear':
        startDate = moment().subtract(1, 'year').startOf('year').toISOString();
        endDate = moment().subtract(1, 'year').endOf('year').toISOString();
        break;

      default:
        break;
    }

    fetchData({ endDate, startDate });
  }, [mode]);

  const chartOption = {
    title: {
      text: 'Ad Clicks vs Ad Impressions',
      left: 'left',
      textStyle: {
        color: '#468b47',
        lineHeight: 16,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    xAxis: {
      type: 'category',
      data: data?.map((e) => e.month) || [],
      axisTick: { show: false },
      axisLabel: {
        color: '#468b47', // Green color for x-axis labels
        fontWeight: 'bold',
      },
      axisLine: {
        show: false, // Remove x-axis line
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#468b47', // Green color for y-axis labels
      },
    },
    series: [
      {
        name: 'Impressions',
        data: data?.map((e) => e?.impressions) || [],
        type: 'bar',
        stack: 'total',
        itemStyle: {
          color: '#468b47', // A lighter shade of green for impressions
          borderRadius: 4,
        },
      },
      {
        name: 'Clicks',
        data: data?.map((e) => e?.clicks) || [],
        type: 'bar',
        stack: 'total',
        itemStyle: {
          color: '#c4e9c4', // Green color for clicks
          borderRadius: 4,
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <select
        value={mode}
        style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 1 }}
        onChange={(e) => setMode(e.target.value)}
      >
        <option value="ThisYear">This Year</option>
        <option value="LastYear">Last Year</option>
      </select>
      <ReactECharts
        showLoading={loading}
        notMerge={true}
        lazyUpdate={true}
        option={chartOption}
        style={{ height: '400px', minHeight: '300px' }}
      />
      <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <LegendItem color="#c4e9c4" text="Ad Clicks" />
        <LegendItem color="#468b47" text="Ad Impressions" />
      </div>
    </div>
  );
};
export default ClicksImpressionBarChartCard;

const LegendItem = ({ color, text }) => {
  return (
    <div style={{ display: 'flex', gap: '6px', alignItems: 'center', flex: 'none' }}>
      <div style={{ backgroundColor: color, borderRadius: '9999px', height: '18px', width: '18px' }} />
      <span style={{ fontSize: '14px', marginTop: '2px' }}>{text}</span>
    </div>
  );
};
