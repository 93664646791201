import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

// import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';

import CustomButton from '../../../components/UI/CustomButton';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useSortHook from '../../../hooks/useSortHook';
import { getAdvertisementPrice } from '../../../services/Service';
import AddAdPriceModal from './AddAdPriceModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

export default function AdPriceModal({ open, onClose, adId }) {
  const [data, setData] = React.useState([]);
  const [addPriceModal, setAddPriceModal] = React.useState({
    open: false,
    data: null,
    isDisplay: false,
  });

  const handleClose = () => {
    setData([]);
    onClose();
    onCloseModal();
  };

  const onAddNew = () => {
    setAddPriceModal({
      open: true,
      data: null,
      isDisplay: false,
    });
  };
  const onDisplay = (data) => {
    setAddPriceModal({
      open: true,
      data: data,
      isDisplay: true,
    });
  };
  const onEdit = (data) => {
    setAddPriceModal({
      open: true,
      data: data,
      isDisplay: false,
    });
  };
  const onCloseModal = (flag) => {
    setAddPriceModal({
      open: false,
      data: null,
      isDisplay: false,
    });
    if (flag) fetchData(adId);
  };

  const fetchData = async (id) => {
    try {
      const res = await getAdvertisementPrice({
        advertisementPageId: id,
      });
      setData(res.data?.data);
    } catch (err) {
      console.log('fetchData error', err.message);
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    if (open) fetchData(adId);
  }, [adId, open]);

  const { handleSort, newSortData, sortState } = useSortHook(data, ['createdAt']);
  const headData = ['Name', 'Price in cent', 'Interval', 'Duration', 'Status', 'Actions'];

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
            >
              Advertisement Price
            </Typography>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <CustomButton backgroundColor="#027c34" text="Add New Price" icon={<AddIcon />} onClick={onAddNew} />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <TableContainer>
              <CustomTableView
                isSort
                sort={{
                  order: sortState.order,
                  orderBy: sortState.orderBy,
                  createSortHandler: handleSort,
                  sortHeadMap: ['name', 'priceInCent', 'interval', 'duration', 'status', ''],
                }}
                headData={headData}
              >
                {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
                {newSortData?.map((e, index) => (
                  <TableRow key={index}>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e?.name}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>$ {e?.priceInCent}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e?.interval}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e?.duration}</p>
                    </CustomTableCellForBody>
                    {/* <CustomTableCellForBody align="center">
                    <p style={{ ...tableCellPStyle }}>{e?.sectionsMobile?.map((ex) => ex?.name)?.join(', ')}</p>
                  </CustomTableCellForBody> */}
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e?.status ? 'Active' : 'Inactive'}</p>
                    </CustomTableCellForBody>
                    {/* <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody> */}
                    <CustomTableCellForBody align="center">
                      <ThreeDotMenu onDisplay={() => onDisplay(e)} onEdit={() => onEdit(e)} />
                    </CustomTableCellForBody>
                  </TableRow>
                ))}
              </CustomTableView>
            </TableContainer>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
            <Button
              onClick={handleClose}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
      <AddAdPriceModal
        adId={adId}
        data={addPriceModal?.data}
        isDisplay={addPriceModal?.isDisplay}
        open={addPriceModal?.open}
        onClose={onCloseModal}
      />
    </>
  );
}
