import { Button, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import './UserPlanList.css';
import { Helmet } from 'react-helmet-async';
import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { checkoutPlan, getPlanCancel, getPriceListUser } from '../../../services/Service';
import CheckoutConfirmationModal from './CheckoutConfirmationModal';
import StripeCheckoutModal from './StripeCheckoutModal';
import { getProfile } from '../../../store/loginSlice';
import FaqModal from '../../../components/Modals/FaqModal';
import UserAssessmentModal from '../../../components/Modals/UserAssessmentModal';

const UserPlanList = () => {
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [priceList, setPriceList] = useState([]);
  const [priceMode, setPriceMode] = useState('month'); // month, year
  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    data: null,
    isCancelMode: false,
  });

  const [openUserAssessmentModal, setOpenUserAssessmentModal] = useState(false);
  const userAssessmentRefData = useRef(null);

  const [clientSecret, setClientSecret] = useState(null);

  const fetchPrice = async () => {
    try {
      const res = await getPriceListUser();
      setPriceList(res.data.data);
      const countryCurrency = user?.country === 'India' ? 'inr' : 'usd';
      let newInterval = 'year';
      res?.data?.data?.forEach((planX) => {
        const findPrice = planX?.prices?.find(
          (e) => e.interval_count === 1 && countryCurrency === e.currency && e?.id === user?.priceId
        );
        if (findPrice) newInterval = findPrice?.interval || 'year';
      });
      setPriceMode(newInterval);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPrice();
    return () => {
      setClientSecret(null);
      setPriceMode('month');
      setConfirmationModal({
        open: false,
        data: null,
        isCancelMode: false,
      });
    };
  }, []);

  const priceListFilter = priceList.filter((e) => {
    if (e.id === 'FREE') {
      return true;
    }
    return e.prices.length > 0;
  });

  const onClickPlanChange = (priceData, data) => {
    setConfirmationModal({
      open: true,
      data: priceData,
      isCancelMode: false,
    });
  };

  const onPlanSubmit = async (priceData, isCancelMode) => {
    try {
      if (isCancelMode) {
        // cancel plan api
        const res = await getPlanCancel();
        toast.success(res.data.message);
        dispatch(getProfile()).unwrap();
        window.location.reload(true);
        return;
      }
      if (priceData?.id) {
        const res = await checkoutPlan({
          priceId: priceData.id,
          device: 'web',
        });
        setClientSecret(res.data.clientSecret);
      } else {
        // cancel plan api
        const res = await getPlanCancel();
        toast.success(res.data.message);
        dispatch(getProfile()).unwrap();
        window.location.reload(true);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setConfirmationModal({
        open: false,
        data: null,
        isCancelMode: false,
      });
    }
  };

  const onClickPlanCancel = (priceData, data) => {
    setConfirmationModal({
      open: true,
      data: priceData,
      isCancelMode: true,
    });
  };

  const onConfirmationModalCancel = () => {
    setConfirmationModal({
      open: false,
      data: null,
      isCancelMode: false,
    });
  };

  const onCheckoutComplete = (e) => {
    dispatch(getProfile()).unwrap();
    window.location.reload(true);
    // console.log('onCheckoutComplete', e);
  };

  const onCheckoutCancel = async (e) => {
    setClientSecret(null);
  };

  const centerPriceList = (listX) => {
    const list1 = [...listX];
    const countryCurrency = user.country === 'India' ? 'inr' : 'usd';

    let indexToCenter = null;

    listX.forEach((planX, index) => {
      const findPrice = planX.prices.find(
        (e) => e.interval === priceMode && e.interval_count === 1 && countryCurrency === e.currency
      );
      const isThisCurrentPrice = findPrice?.id === user?.priceId;
      if (isThisCurrentPrice) indexToCenter = index;
    });
    if (indexToCenter !== null && indexToCenter !== 1) {
      if (indexToCenter === 0) {
        return [list1[1], list1[0], list1[2]];
      }
      if (indexToCenter === 2) {
        return [list1[0], list1[2], list1[1]];
      }
    }

    return list1;
  };

  const onCloseUserAssessmentModal = () => {
    setOpenUserAssessmentModal(false);
    if (userAssessmentRefData.current) onClickPlanCancel(userAssessmentRefData.current);
    userAssessmentRefData.current = null;
  };
  const onOpenUserAssessmentModal = (data) => {
    setOpenUserAssessmentModal(true);
    userAssessmentRefData.current = data;
  };

  return (
    <>
      <Helmet>
        <title>Plan List - SafalSubscriptions</title>
      </Helmet>

      <div id="UserPlanList">
        <div className="blue-bg-x1" />
        <div className="main-div">
          <div className="main-div-heading">
            {/* <span style={{ fontSize: '38px', color: 'white' }}>15 days free trial</span> */}
            {/* <span style={{ display: 'flex', alignItems: 'center' }}>
              Find simple plan for comprehensive automation{' '}
              <FaqModal marginLeft="8px" module="Upgrade Plan" color="#3D71FF" />
            </span> */}
            <div className="main-div-heading-sw">
              <span>Monthly</span>
              <CustomSwitch
                checked={priceMode === 'year'}
                onChange={(e) => setPriceMode(e.target.checked ? 'year' : 'month')}
              />
              <span>Annually</span>
              <FaqModal marginLeft="8px" module="Upgrade Plan" color="#3D71FF" />
            </div>
          </div>
          <div className="main-div-list">
            {centerPriceList(priceListFilter).map((e, index) => (
              <PlanBox
                onChange={onClickPlanChange}
                onCancel={onOpenUserAssessmentModal}
                user={user}
                key={index}
                priceMode={priceMode}
                data={e}
              />
            ))}
          </div>
        </div>
      </div>
      <CheckoutConfirmationModal
        isCancelMode={confirmationModal.isCancelMode}
        onSubmit={onPlanSubmit}
        onClose={onConfirmationModalCancel}
        open={confirmationModal.open}
        data={confirmationModal.data}
      />
      {clientSecret && (
        <StripeCheckoutModal onClose={onCheckoutCancel} onComplete={onCheckoutComplete} clientSecret={clientSecret} />
      )}
      <UserAssessmentModal
        scenario="DowngradePlan"
        open={openUserAssessmentModal}
        onClose={onCloseUserAssessmentModal}
      />
    </>
  );
};

export default UserPlanList;

const PlanBox = ({ data, priceMode, user, onChange, onCancel }) => {
  const countryCurrency = user.country === 'India' ? 'inr' : 'usd';
  const findPrice = data.prices.find(
    (e) => e.interval === priceMode && e.interval_count === 1 && countryCurrency === e.currency
  );

  const isThisCurrentPrice = findPrice?.id === user?.priceId;
  const isThisCurrentPlan = data?.id === user?.subscriptionId;

  return (
    <div
      style={{ border: isThisCurrentPrice && '4px solid #3d71ff', transform: isThisCurrentPrice && 'scale(1.15)' }}
      className="PlanBox"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '20px',
          fontWeight: '600',
          marginTop: '16px',
          textTransform: 'capitalize',
        }}
      >
        <span>{data.name}</span>
      </div>
      <div style={{ width: '25px', height: '4px', backgroundColor: '#3d71ff', margin: '16px auto' }} />
      {isThisCurrentPrice ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <span style={{ color: '#3d71ff', fontSize: '20px', fontWeight: '600' }}>This your current plan</span>
          {data.id !== 'FREE' && (
            <Button
              onClick={() => onCancel(findPrice, data)}
              size="small"
              sx={{
                textTransform: 'capitalize',
                backgroundColor: '#ff4842',
                color: 'white',
                borderRadius: '30px',
                ':hover': { backgroundColor: '#d40700' },
              }}
              variant="contained"
            >
              Cancel plan
            </Button>
          )}
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
          {findPrice ? (
            <span style={{ fontSize: '20px', fontWeight: '600' }}>
              {findPrice.currency === 'usd' && '$'}
              {findPrice.currency === 'inr' && '₹'}
              {(findPrice.unit_amount / 100).toFixed(2)}/{findPrice && findPrice.interval}
            </span>
          ) : (
            <span style={{ fontSize: '20px', fontWeight: '600' }}>{data.id === 'FREE' ? 'Free' : 'Not available'}</span>
          )}
          <Button
            onClick={() => onChange(findPrice, data)}
            size="small"
            sx={{ textTransform: 'capitalize', backgroundColor: '#3d71ff', borderRadius: '30px' }}
            variant="contained"
          >
            Select
          </Button>
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '6px', margin: '16px', marginTop: '32px' }}>
        {data.featureList.map((e, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px', overflow: 'hidden' }}>
            <DoneIcon sx={{ color: '#3d71ff' }} />
            <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{e.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  ({ theme }) => ({
    width: 56,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(30px)',
        color: '#3d71ff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#3d71ff',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
      backgroundColor: '#3d71ff',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  })
);
