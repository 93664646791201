/* eslint-disable jsx-a11y/media-has-caption */

import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Image } from 'antd';

import { getBannerList } from '../../../services/Service';
import AnalyticsClickImpression from '../../../components/AnalyticsClickImpression';

const UserAds = () => {
  const [data, setData] = useState([]);

  const fetchBanner = async () => {
    try {
      const res = await getBannerList();
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  return (
    data.length > 0 && (
      <div
        style={{
          width: '100%',
          height: '150px',
          overflow: 'hidden',
        }}
      >
        <Carousel
          autoPlay
          infiniteLoop
          interval={5000}
          showStatus={false}
          showArrows
          showIndicators
          showThumbs={false}
          axis="horizontal"
        >
          {data.map((e, index) => (
            <a href={e.link} rel="noreferrer" target="_blank" key={index}>
              {e.type === 'image' ? (
                <AnalyticsClickImpression data={e} id={e?._id} page="Dashboard">
                  <img
                    style={{ objectFit: 'cover', 'pointer-events': 'all' }}
                    height="150px"
                    width="100%"
                    src={e.value}
                    alt="Dashboard"
                  />
                </AnalyticsClickImpression>
              ) : (
                <AnalyticsClickImpression data={e} id={e?._id} page="Dashboard">
                  <video
                    style={{ maxHeight: '150px', objectFit: 'cover' }}
                    width="100%"
                    height="150"
                    autoPlay="true"
                    muted
                  >
                    <source src={e.value} />
                  </video>
                </AnalyticsClickImpression>
              )}
            </a>
          ))}
        </Carousel>
      </div>
    )
  );
};

export default UserAds;
