/* eslint-disable no-restricted-syntax */
/* eslint-disable  no-plusplus */

import { Button, Checkbox, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { getPlanList, getSubLabelLimitsBusiness, updateSubLabelLimitsBusiness } from '../../services/Service';
import Inlineloader from '../../components/Loader/InlineLoader';

// {
//   limit: Number,
//   isUnlimited: Boolean,
//   tier: Number,
// }
const SubLabelLimits = () => {
  const [planList, setPlanList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subLabelLimitsRaw, setSubLabelLimitsRaw] = useState({
    subLabelLimit: [],
  });

  const [subLabelLimits, setSubLabelLimits] = useState({
    subLabelLimit: [],
  });

  const fetchPlanData = async () => {
    try {
      const res = await getPlanList();
      if (res.status === 200) {
        setPlanList(res.data.data);
      }
    } catch (err) {
      console.log('getPlanList fetchData error', err.message);
    }
  };

  const fetchAssetLimitData = async () => {
    try {
      setLoading(true);
      const res = await getSubLabelLimitsBusiness();
      if (res.status === 200) {
        setSubLabelLimits({
          subLabelLimit: res.data.data[0]?.subLabelLimit || [],
        });
        setSubLabelLimitsRaw({
          subLabelLimit: res.data.data[0]?.subLabelLimit || [],
        });
      }
    } catch (err) {
      console.log('fetchAssetLimitData fetchData error', err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlanData();
    fetchAssetLimitData();
  }, []);

  const onChangeValue = (type, tier, value) => {
    setSubLabelLimits((prev) => {
      const oldValue = { ...prev };
      const thisType = oldValue[type];
      const findValueIndex = thisType.findIndex((ex) => ex.tier === tier);
      if (findValueIndex === -1) {
        thisType.push({
          limit: value.limit,
          isUnlimited: value.isUnlimited,
          tier: tier,
        });
      } else {
        thisType[findValueIndex] = {
          limit: value.limit,
          isUnlimited: value.isUnlimited,
          tier: tier,
        };
      }
      oldValue[type] = thisType;
      return oldValue;
    });
  };

  const onUpdateLimits = async () => {
    try {
      const res = await updateSubLabelLimitsBusiness(subLabelLimits);
      toast.success(res.data.message);
      await fetchAssetLimitData();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  if (loading) return <Inlineloader />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '32px' }}>
      {planList.map((e, index) => {
        const findSubLabelLimit = subLabelLimits.subLabelLimit.find((ex) => ex.tier === e.tier);
        return (
          <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
              Tier {e.tier} : {e.name}
            </span>
            <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
              <InputBox
                tier={e.tier}
                onChange={(data) => onChangeValue('subLabelLimit', e.tier, data)}
                limit={findSubLabelLimit?.limit || 0}
                isUnlimited={findSubLabelLimit?.isUnlimited || false}
                label="Subscription Label"
              />
            </div>
          </div>
        );
      })}
      <Button
        // disabled={isUpdateEnable}
        size="small"
        onClick={onUpdateLimits}
        variant="contained"
        sx={{
          marginLeft: 'auto',
          marginTop: '16px',
          width: '120px',
          backgroundColor: '#19c25f',
          ':hover': { backgroundColor: '#0f7539' },
          textTransform: 'capitalize',
        }}
      >
        Update
      </Button>
    </div>
  );
};

const InputBox = ({ label, onChange, tier, limit, isUnlimited }) => {
  const onChangeCheckbox = (event) => {
    onChange({
      isUnlimited: event.target.checked,
      limit: event.target.checked ? 0 : limit,
    });
  };

  const onChangeLimit = (event) => {
    onChange({
      isUnlimited: isUnlimited,
      // eslint-disable-next-line
      limit: parseInt(event.target.value),
    });
  };

  return (
    <label style={{ display: 'flex', alignItems: 'center' }} htmlFor="loyalty">
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          width: '150px',
        }}
        disabled={isUnlimited}
        value={limit}
        onChange={onChangeLimit}
        type="number"
        label={label}
        size="small"
        variant="outlined"
      />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox id={`checkbox-t${tier}-n${label}`} checked={isUnlimited} onChange={onChangeCheckbox} />
        <label style={{ cursor: 'pointer' }} htmlFor={`checkbox-t${tier}-n${label}`}>
          Unlimited
        </label>
      </div>
    </label>
  );
};

export default SubLabelLimits;
