/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, updateDoc, query, where } from 'firebase/firestore';
import ImageIcon from '@mui/icons-material/Image';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UserStatus from './UserStatus';
import NotificationCount from './NotificationCount';
import LastMsg from './LastMsg';
import Scrollbar from '../Scrollbar';
import { db } from '../../firebase';
import { changeUser } from '../../store/ChatSlice';
import DisplayAvatar from './DisplayAvatar';
import { changeChatResponsiveToggle } from '../../store/ToggleSlice';

const UserChatSidebar = (chats) => {
  const { user, chatMode, chatId, sid } = useSelector((state) => state.chats);
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.login?.user);
  const timeOutRef = useRef(null);
  const handleSelect = async (user) => {
    try {
      let id = user.uid;
      let sid = user.uid;
      if (role === 'user') {
        id =
          localStorage.getItem('fire') > user.uid
            ? localStorage.getItem('fire') + user.uid
            : user.uid + localStorage.getItem('fire');
      } else {
        sid = currentUser?.uid > user.uid ? currentUser?.uid + user.uid : user.uid + currentUser?.uid;
      }
      dispatch(changeUser({ user, id, sid }));
      dispatch(changeChatResponsiveToggle());
      // reset notification count
      try {
        if (chatMode === 'user') {
          const q = query(collection(db, `alert`, currentUser?.uid, user.uid));
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach(async () => {
            await deleteDoc(doc(db, `alert`, currentUser?.uid, user.uid, user.uid));
          });
        } else {
          await updateDoc(doc(db, `alert`, user.uid), { count: 0 });
        }
      } catch (e) {
        console.log('aaaaaaa', e);
      }
      console.log('aaaaaaa');
    } catch (e) {
      console.log('aaaaaaa', e);
    }
  };

  useEffect(() => {
    const SafalBuddyChat = localStorage.getItem('SafalBuddyChat');
    const SafalBuddyChatNew = localStorage.getItem('SafalBuddyChatNew');
    if (!chats?.buddyUser) return;
    const filterChat = chats?.chats?.find(({ userInfo }) => userInfo?.uid === chats?.buddyUser?.uid);
    if (chats?.chats?.length > 0 && filterChat) {
      if (SafalBuddyChat) return;
      handleSelect(filterChat?.userInfo);
      localStorage.setItem('SafalBuddyChat', true);
      if (timeOutRef.current !== null) {
        clearTimeout(timeOutRef.current);
        timeOutRef.current = null;
      }
    } else {
      if (SafalBuddyChatNew) return;
      timeOutRef.current = setTimeout(() => {
        localStorage.setItem('SafalBuddyChatNew', true);
        dispatch(changeUser({ user: chats?.buddyUser, id: 'START_CHAT', sid: 'START_CHAT' }));
      }, 1500);
    }
  }, [chats?.buddyUser, chats?.chats]);

  return chats?.chats.map(
    ({ userInfo, key, lastMessage }) =>
      userInfo && (
        <Scrollbar>
          <div
            onClick={() => handleSelect(userInfo)}
            className={`userChat ${user?.uid == userInfo.uid && 'selected'}`}
            key={key}
          >
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
              <DisplayAvatar uid={userInfo.uid} />
              <div style={{ marginLeft: '-10px' }}>
                <UserStatus uid={userInfo.uid} />
              </div>
            </div>
            <div className="userChatInfo" style={{ overflow: 'hidden' }}>
              <p className="userChatName">{userInfo.displayName}</p>
              <p
                className="userChatMsg"
                style={{
                  paddingTop: 10,
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {lastMessage?.messageType === 'image' ? (
                  <>
                    <ImageIcon style={{ height: '14px', width: '14px', marginRight: '4px' }} /> Image
                  </>
                ) : lastMessage?.messageType === 'video' ? (
                  <>
                    <VideoFileIcon style={{ height: '14px', width: '14px', marginRight: '4px' }} /> Video
                  </>
                ) : lastMessage?.messageType === 'pdf' ? (
                  <>
                    <PictureAsPdfIcon style={{ height: '14px', width: '14px', marginRight: '4px' }} /> PDF
                  </>
                ) : (
                  lastMessage?.text
                )}
              </p>
            </div>
            <NotificationCount senderId={userInfo.uid} />
          </div>
        </Scrollbar>
      )
  );
};

export default UserChatSidebar;
