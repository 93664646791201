import * as React from 'react';

import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, Switch, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { Image, Select } from 'antd';

import {
  createSafalGiftMasterInventory,
  updateSafalGiftMasterInventory,
  uploadLeaderBoardMasterCountries,
} from '../../../services/Service';

import { usStates, indiaStates } from '../../../utils/stateList';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  application: '', // application id
  image: '',
  name: '',
  description: '',
  expiryDate: '',
  country: '', // country id
  state: [],
  points: 0,
  price: 0,
  stock: 0,
  status: false,
  isPublish: false,
};

export default function AddSafalGiftMasterInventoryModal({
  open,
  onClose,
  data,
  isDisplay,
  applicationsList,
  countryList,
}) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    setSelectedFile(null);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      if (!selectedFile) {
        toast.error('Product image required');
        return;
      }
      const fileRes = await uploadLeaderBoardMasterCountries(selectedFile);
      const payload = { ...fieldValue, image: fileRes.data.url };
      const res = await createSafalGiftMasterInventory(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const payload = { ...data, ...fieldValue };
      if (selectedFile) {
        const fileRes = await uploadLeaderBoardMasterCountries(selectedFile);
        payload.image = fileRes.data.url;
      }
      const res = await updateSafalGiftMasterInventory(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        application: data?.application !== undefined ? data?.application?._id : '',
        image: data?.image !== undefined ? data?.image : '',
        name: data?.name !== undefined ? data?.name : '',
        description: data?.description !== undefined ? data?.description : '',
        expiryDate: data?.expiryDate !== undefined ? data?.expiryDate : '',
        country: data?.country !== undefined ? data?.country?._id : '',
        state: data?.state !== undefined ? data?.state : [],
        points: data?.points !== undefined ? data?.points : 0,
        price: data?.price !== undefined ? data?.price : 0,
        stock: data?.stock !== undefined ? data?.stock : 0,
        status: data?.status !== undefined ? data?.status : false,
        isPublish: data?.isPublish !== undefined ? data?.isPublish : false,
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  const logoImageUrl = selectedFile
    ? URL.createObjectURL(selectedFile)
    : fieldValue?.image?.length > 1
    ? fieldValue?.image
    : '';

  const findCountry = countryList.find((e) => e?._id === fieldValue.country);
  const stateArray =
    findCountry?.name === 'India' ? indiaStates : findCountry?.name === 'United States' ? usStates : [];
  const stateArrayX = [{ name: 'All', abbreviation: 'All' }, ...stateArray];

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add SafalGift Product' : isDisplay ? 'SafalGift Product' : 'Update SafalGift Product'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'flex-end',
            }}
          >
            {logoImageUrl.length > 1 && (
              <div style={{ flex: 'none' }}>
                <Image
                  preview={false}
                  style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                  src={logoImageUrl}
                  alt="logo"
                />
              </div>
            )}
            <TextField
              onChange={(e) => {
                if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
                  toast.error('Please upload image in JPG, JPEG, PNG Format ONLY.');
                } else {
                  setSelectedFile(e.target.files[0]);
                }
              }}
              type="file"
              required
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                accept: 'image/*',
              }}
            />
          </div>
          {/* application */}
          <TextField
            value={fieldValue.application}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, application: e.target.value }))}
            required
            select
            label="Application Name"
            size="small"
            disabled={data !== null ? true : isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {applicationsList?.map((e, index) => (
              <MenuItem key={index} value={e?._id}>
                {e?.name}
              </MenuItem>
            ))}
          </TextField>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Product Name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* description */}
          <TextField
            value={fieldValue.description}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, description: e.target.value }))}
            required
            label="Description"
            name="description"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
            multiline
            minRows={2}
            maxRows={3}
            inputProps={{ maxLength: 500 }}
            helperText={`${fieldValue.description.length}/500`}
          />
          {/* country */}
          <TextField
            value={fieldValue.country}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, country: e.target.value }))}
            required
            select
            label="Country"
            size="small"
            disabled={data !== null ? true : isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {countryList?.map((e, index) => (
              <MenuItem key={index} value={e?._id}>
                {e?.name}
              </MenuItem>
            ))}
          </TextField>
          {/* state */}
          <Select
            title="State"
            mode="multiple"
            allowClear
            disabled={isDisableAll}
            style={{
              flex: '1',
            }}
            variant="outlined"
            dropdownStyle={{
              zIndex: 20000,
            }}
            value={fieldValue.state}
            defaultValue={fieldValue.state}
            placeholder="Please select state"
            onChange={(values) => setFieldValue((prev) => ({ ...prev, state: values }))}
            options={stateArrayX.map((exs) => ({ label: exs.name, value: exs.abbreviation }))}
          />
          {/* points */}
          <TextField
            value={fieldValue.points}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, points: e.target.value }))}
            required
            label="SafalPoints"
            size="small"
            type="number"
            fullWidth
            disabled={isDisableAll}
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">Spts</InputAdornment>,
            }}
          />
          {/* price */}
          <TextField
            value={fieldValue.price}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, price: e.target.value }))}
            required
            label="Price"
            size="small"
            type="number"
            fullWidth
            disabled={isDisableAll}
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          {/* stock */}
          <TextField
            value={fieldValue.stock}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, stock: e.target.value }))}
            required
            label="Current Stock"
            size="small"
            type="number"
            fullWidth
            disabled={isDisableAll}
            variant="outlined"
          />
          {/* expiryDate */}
          <TextField
            value={fieldValue.expiryDate && moment(fieldValue.expiryDate).format('YYYY-MM-DD')}
            onChange={(e) => {
              // Get the value in YYYY-MM-DD format from the input
              const inputDate = e.target.value;
              const dateMoment = moment(inputDate, 'YYYY-MM-DD', true);
              if (dateMoment.isValid()) {
                const mongoDateString = dateMoment.startOf('day').toISOString();
                setFieldValue((prev) => ({ ...prev, expiryDate: mongoDateString }));
              }
            }}
            label="Expiry Date"
            size="small"
            type="date"
            fullWidth
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isDisableAll}
          />
          {/* status */}
          <TextField
            value={fieldValue.status === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
          {/* isPublish */}
          <TextField
            value={fieldValue.isPublish === true ? 'Yes' : 'No'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, isPublish: e.target.value === 'Yes' }))}
            required
            select
            label="Is Publish"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
