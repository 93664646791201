/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { useState, useCallback, useRef, useEffect } from 'react';

const RectangleDrawer = ({ imageUrl, onChange, rectPercentages }) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [rect, setRect] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const containerRef = useRef(null);

  const convertPercentagesToPixels = useCallback((percentages) => {
    if (!containerRef.current || !percentages) return null;

    const container = containerRef.current;
    const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();

    return {
      x: (percentages.x * containerWidth) / 100,
      y: (percentages.y * containerHeight) / 100,
      width: (percentages.width * containerWidth) / 100,
      height: (percentages.height * containerHeight) / 100,
    };
  }, []);

  // Update rect when rectPercentages changes
  useEffect(() => {
    if (rectPercentages && containerRef.current) {
      const pixelRect = convertPercentagesToPixels(rectPercentages);

      if (pixelRect) {
        setRect(pixelRect);
      }
    }
  }, [rectPercentages, convertPercentagesToPixels]);

  const onRectangleDrawn = (data) => {
    onChange(data.percentages);
  };

  const getRelativeCoordinates = useCallback((e) => {
    if (!containerRef.current) return { x: 0, y: 0 };

    const rect = containerRef.current.getBoundingClientRect();
    return {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
  }, []);

  const convertToPercentages = useCallback((rect) => {
    if (!containerRef.current) return rect;

    const container = containerRef.current;
    const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();

    return {
      x: Number(((rect.x / containerWidth) * 100).toFixed(2)),
      y: Number(((rect.y / containerHeight) * 100).toFixed(2)),
      width: Number(((rect.width / containerWidth) * 100).toFixed(2)),
      height: Number(((rect.height / containerHeight) * 100).toFixed(2)),
    };
  }, []);

  const handleMouseDown = useCallback(
    (e) => {
      const { x, y } = getRelativeCoordinates(e);
      setStartPoint({ x, y });
      setIsDrawing(true);
    },
    [getRelativeCoordinates]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDrawing) return;

      const { x: currentX, y: currentY } = getRelativeCoordinates(e);
      const width = Math.abs(currentX - startPoint.x);
      const height = Math.abs(currentY - startPoint.y);

      const x = currentX < startPoint.x ? currentX : startPoint.x;
      const y = currentY < startPoint.y ? currentY : startPoint.y;

      setRect({ x, y, width, height });
    },
    [isDrawing, startPoint, getRelativeCoordinates]
  );

  const handleMouseUp = useCallback(() => {
    if (!isDrawing) return;

    setIsDrawing(false);
    const percentageRect = convertToPercentages(rect);

    if (onRectangleDrawn) {
      onRectangleDrawn({
        pixels: rect,
        percentages: percentageRect,
      });
    }
  }, [isDrawing, rect, convertToPercentages, onRectangleDrawn]);

  // Handle mouse leaving the container
  const handleMouseLeave = useCallback(() => {
    if (isDrawing) {
      setIsDrawing(false);
    }
  }, [isDrawing]);

  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid #ddd',
      }}
    >
      <div
        ref={containerRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          userSelect: 'none',
          cursor: isDrawing ? 'crosshair' : 'default',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {rect.width > 0 && (
          <div
            style={{
              position: 'absolute',
              left: `${rect.x}px`,
              top: `${rect.y}px`,
              width: `${rect.width}px`,
              height: `${rect.height}px`,
              border: '2px solid #ff033e',
              backgroundColor: 'rgba(255, 3, 62, 0.3)',
              pointerEvents: 'none',
            }}
          />
        )}
      </div>
      <img
        src={imageUrl}
        alt="Drawing surface"
        style={{
          userSelect: 'none',
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          // display: 'block',
        }}
      />
    </div>
  );
};

export default RectangleDrawer;
