/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';

import useProfileImage from '../../../hooks/useProfileImage';
import SafalLeaderBoard_Call from '../../../assets/SafalLeaderBoard_Call.png';
import SafalLeaderBoard_Chat from '../../../assets/SafalLeaderBoard_Chat.png';
import BuddyButtons from './BuddyButtons';
import { db } from '../../../firebase';
import { UserNewProfileModal } from '../../../components/Modals/UserNewProfileModal';

const BuddyCard = ({ data, mode, refetch, onCall, onChat }) => {
  const [openUserNewProfileModal, setOpenUserNewProfileModal] = useState({
    open: false,
    data: null,
  });
  const openUserNewProfileOpen = (userId) => {
    setOpenUserNewProfileModal({
      open: true,
      data: userId,
    });
  };
  const openUserNewProfileClose = () => {
    setOpenUserNewProfileModal({
      open: false,
      data: null,
    });
  };

  const profileImage = useProfileImage(data?.metaUser?.profilePic);
  const fullName = data?.metaUser?.middleName
    ? `${data?.metaUser?.firstName} ${data?.metaUser?.middleName} ${data?.metaUser?.lastName}`
    : `${data?.metaUser?.firstName} ${data?.metaUser?.lastName}`;

  const buddyCount = data?.metaUser?.buddyCount ?? 0;
  const safalRoomsCount = data?.metaUser?.safalRoomsCount ?? 0;
  const mutualBuddyCount = data?.metaUser?.mutualBuddyCount ?? 0;
  const mutualRoomCount = data?.metaUser?.mutualRoomCount ?? 0;
  const mutualSubscriptionCount = data?.metaUser?.mutualSubscriptionCount ?? 0;
  const mutualAssetCount = data?.metaUser?.mutualAssetCount ?? 0;
  const tier = data?.metaUser?.tier ?? 0;
  const tierName =
    tier === 0
      ? 'Beta User'
      : tier === 1
      ? 'Free User'
      : tier === 2
      ? 'Basic User'
      : tier === 3
      ? 'Premium User'
      : 'Unknown Tier User';

  const otherUserId = data?.metaUser?._id;
  const buddyId = data?._id;

  // Firebase call
  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const currentUserToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  const isChatCallEnable = currentUserToUserChatEnable ? otherUserInfo?.userToUserChat === true : false;
  const fetchOtherUserFirebase = async (otherUserId) => {
    if (!otherUserId) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', otherUserId));
    if (otherUserInfoRef.exists()) {
      setOtherUserInfo(otherUserInfoRef.data());
    }
  };
  useEffect(() => {
    fetchOtherUserFirebase(otherUserId);
  }, []);

  const onCallClick = async () => {
    onCall(otherUserId, otherUserInfo, 'audio');
  };
  const onVideoCallClick = async () => {
    onCall(otherUserId, otherUserInfo, 'video');
  };
  const onChatClick = async () => {
    onChat(otherUserId, otherUserInfo);
  };

  // other user is receiver that mean i'm sending this request
  // if (mode === 'pending' && otherUserId === data?.receiver?._id) {
  //   return <></>;
  // }

  const cancelPending = mode === 'pending' && otherUserId === data?.receiver?._id;
  const cancelBlock = mode === 'block' && otherUserId === data?.receiver?._id;

  return (
    <>
      <div
        onClick={() => openUserNewProfileOpen(otherUserId)}
        style={{
          display: 'flex',
          border: '1px solid #BCCEFF',
          // margin: '16px',
          // padding: '20px',
          borderRadius: '16px',
          backgroundColor: 'white',
          gap: '4px',
          justifyContent: 'space-evenly',
          padding: '16px',
          height: 'fit-content',
          cursor: 'pointer',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
          <Avatar sx={{ height: '60px', width: '60px' }} alt={fullName} src={profileImage} />
          <div style={{ display: 'flex', gap: '4px' }}>
            <button
              title="Chat"
              onClick={onChatClick}
              disabled={!isChatCallEnable}
              style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
            >
              <img
                src={SafalLeaderBoard_Chat}
                style={{
                  width: '20px',
                  height: '20px',
                  objectFit: 'contain',
                  filter: !isChatCallEnable ? 'grayscale(1)' : '',
                }}
                alt="SafalLeaderBoard_Chat"
              />
            </button>
            <button
              title="Audio Call"
              onClick={onCallClick}
              disabled={!isChatCallEnable}
              style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
            >
              <img
                src={SafalLeaderBoard_Call}
                style={{
                  width: '20px',
                  height: '20px',
                  objectFit: 'contain',
                  filter: !isChatCallEnable ? 'grayscale(1)' : '',
                }}
                alt="SafalLeaderBoard_Call"
              />
            </button>
            <button
              title="Video Call"
              onClick={onVideoCallClick}
              disabled={!isChatCallEnable}
              style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
            >
              <img
                src="/assets/images/buddy/videoCall.png"
                style={{
                  width: '20px',
                  height: '20px',
                  objectFit: 'contain',
                  filter: !isChatCallEnable ? 'grayscale(1)' : '',
                }}
                alt="SafalLeaderBoard_Call"
              />
            </button>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginLeft: '6px' }}>
          <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#0932A1' }}>{fullName}</span>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <img
              src="/assets/images/buddy/newBuddyIconBlue.jpg"
              style={{
                width: '18px',
                height: '18px',
                objectFit: 'contain',
              }}
              alt="SafalBuddies"
            />
            <span style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{buddyCount} SafalBuddies</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <img
              src="/assets/images/Safalroom_blue.png"
              style={{
                width: '16px',
                height: '16px',
                objectFit: 'contain',
              }}
              alt="SafalRooms"
            />
            <span style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{safalRoomsCount} SafalRooms</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <img
              src="/assets/images/buddy/userTier.png"
              style={{
                width: '16px',
                height: '16px',
                objectFit: 'contain',
              }}
              alt="Premium User"
            />
            <span style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>{tierName}</span>
          </div>
        </div>
        <div style={{ backgroundColor: '#BCCEFF', width: '1px' }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
          <span style={{ fontSize: '14px', color: '#A0A0A0', whiteSpace: 'nowrap' }}>
            {mutualBuddyCount} Mutual SafalBuddies
          </span>
          <span style={{ fontSize: '14px', color: '#A0A0A0', whiteSpace: 'nowrap' }}>
            {mutualRoomCount} Mutual SafalRooms
          </span>
          <span style={{ fontSize: '14px', color: '#A0A0A0', whiteSpace: 'nowrap' }}>
            {mutualSubscriptionCount} Mutual Subscriptions
          </span>
          <span style={{ fontSize: '14px', color: '#A0A0A0', whiteSpace: 'nowrap' }}>
            {mutualAssetCount} Mutual Assets
          </span>
        </div>
        <div style={{ backgroundColor: '#BCCEFF', width: '1px' }} />
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center' }}>
          <BuddyButtons
            mode={mode}
            otherUserId={otherUserId}
            id={buddyId}
            refetch={refetch}
            cancelPending={cancelPending}
            cancelBlock={cancelBlock}
          />
        </div>
      </div>
      <UserNewProfileModal
        open={openUserNewProfileModal.open}
        userId={openUserNewProfileModal.data}
        onClose={openUserNewProfileClose}
      />
    </>
  );
};

export default BuddyCard;
