import { filter } from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
// material
import {
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Menu,
  MenuItem,
  FormControl,
  FormLabel,
  Tooltip,
  IconButton,
  useTheme,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../components/Iconify';
import Page from '../../components/Page';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';
import ActionMenu from './ActionMenu';
import { UserListHead } from '../../sections/user';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import Attachment from '../../components/Attachments';
import { getPromotionConfig, deletePromotion } from '../../services/Service';

const TABLE_HEAD = [
  { id: 'company', label: 'Company', alignRight: false, sort: true },
  { id: 'companyType', label: 'Company Type', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'country', label: 'Country', alignRight: false, sort: true },
  { id: 'message', label: 'Message', alignRight: false },

  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'promotionNo', label: 'Promotion No', alignRight: false },
  { id: 'senderName', label: 'Sender Name', alignRight: false },
  { id: 'usercount', label: 'User Count', alignRight: true },
  { id: 'promotionsendto', label: 'Promotion Send To', alignRight: true },

  { id: 'attachment', label: 'Attachment', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function PromotionsList() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [cname, setCname] = useState('updatedAt');
  const [promotionList, setPromotionList] = useState([]);
  const theme = useTheme();

  const closeAttachmentPopUpRef = useRef(() => {});
  const { user } = useSelector((state) => state.login);

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  const fetchPromotionList = async () => {
    await getPromotionConfig()
      .then((res) => {
        // console.log(res.data.data);
        setPromotionList(res.data.data);
      })
      .catch((error) => {
        console.log('Error fetching promotion data:', error);
      });
  };

  const deletepromotionhandler = (id) => {
    Swal.fire({
      title: 'Are you sure you want to delete this Promotion?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        deletePromotion(id)
          .then((res) => {
            SuccessToast(res?.data?.message);
            fetchPromotionList();
          })
          .catch((error) => {
            ErrorToast(error.message);
          });
      }
    });
  };

  const deletePromotionsHandler = async (ids) => {
    const result = await Swal.fire({
      title: 'Are you sure you want to delete this Promotions?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'cancel',
    });
    try {
      if (result.isConfirmed) {
        const resX = await Promise.all(ids.map((e) => deletePromotion(e)));
        SuccessToast('Promotions deleted successfully!');
        setSelected([]);
        fetchPromotionList();
      }
    } catch (error) {
      ErrorToast(error.message);
    }
  };

  const promotionListhandler = (data) => {
    const mediaList = data;
    console.log('mediaList', mediaList);

    const tableContent = mediaList.map((item, index) => {
      return `
            <tr>
              <td>${index + 1}</td>
              <td style="text-align: left; margin-left: 10px;">${item.username}</td>
              <td style="text-align: left; margin-left: 10px;">${item.name}</td>
              <td style="text-align: left; margin-left: 10px;">${item?.email}</td>
            </tr>
          `;
    });

    Swal.fire({
      title: 'Promotion Send to Users',
      html: `
            <div style="width: 600px; height: 300px; overflow-y: scroll;">
              <table style="margin: 0 auto;">
                <thead>
                  <tr>
                    <th>SNo</th>
                    <th style="text-align: left;">User ID</th>
                    <th>Name</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  ${tableContent.join('')}
                </tbody>
              </table>
            </div>
          `,
      showConfirmButton: false,
    });
  };

  function applySortFilter(array, comparator, query, cname) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      if (cname === 'company') {
        return filter(array, (_user) => _user?.country.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
      }
      if (cname === 'country') {
        return filter(array, (_user) => _user?.country.toLowerCase().indexOf(query?.toLowerCase()) !== -1);
      }
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = promotionList.map((n) => n.index);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredSubs = applySortFilter(promotionList, getComparator(order, orderBy), filterName, cname);

  const isUserNotFound = filteredSubs.length === 0;

  console.log('filteredSubs', filteredSubs);

  useEffect(() => {
    fetchPromotionList();
  }, []);

  const handlePromotionSearch = async (val) => {
    await getPromotionConfig(val)
      .then((res) => {
        setPromotionList(res.data.data);
      })
      .catch((error) => {
        console.log('Error fetching promotion data:', error);
      });
  };

  return (
    <Page title="Pramotionslist - SafalSubscriptions">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027C34' }}>
          Promotions List
        </Typography>
        <SearchStyle
          // value={filterName}
          onChange={(e) => handlePromotionSearch(e.target.value)}
          placeholder="Search Promotion..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      </Stack>
      {selected.length > 1 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            pl: 2,
            backgroundColor: theme.palette.info.table,
            borderRadius: '35px',
            width: 'fit-content',
            height: '50px',
          }}
        >
          <Typography component="div" variant="subtitle1" color={theme.palette.secondary.font}>
            {selected.length} selected
          </Typography>

          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                deletePromotionsHandler(selected);
              }}
            >
              <Iconify sx={{ color: '#DF3E30' }} icon="ic:baseline-delete-outline" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, overflowX: 'auto' }}>
          <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={filteredSubs.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />

            <TableBody>
              {filteredSubs &&
                filteredSubs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  const isItemSelected = selected.indexOf(row._id) !== -1;
                  return (
                    <>
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            borderBottomLeftRadius: '35px',
                            borderTopLeftRadius: '35px',
                          }}
                        >
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row._id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                          <Typography variant="subtitle2" noWrap>
                            {row?.companyName}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                          {row?.companyType}
                        </TableCell>

                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                          {row?.title}
                        </TableCell>
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                          {row?.countryCode === 'IND' ? (
                            <img
                              src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
                              alt="IND"
                              height={30}
                              width={40}
                            />
                          ) : (
                            <img
                              src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
                              alt="USA"
                              height={30}
                              width={40}
                            />
                          )}
                        </TableCell>
                        <TableCell align="right" sx={{ backgroundColor: '#FFFFFF' }}>
                          {row?.message}
                        </TableCell>
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                          {row?.createdAt
                            ? moment(row?.createdAt).format(
                                user.country === 'India' ? 'DD/MM/yyyy, hh:mm A' : 'MM/DD/yyyy, hh:mm A'
                              )
                            : ''}
                        </TableCell>

                        <TableCell align="center" sx={{ backgroundColor: '#FFFFFF' }}>
                          {row?.promotionId ? `P${row.promotionId}` : ' '}
                        </TableCell>

                        <TableCell align="center" sx={{ backgroundColor: '#FFFFFF' }}>
                          {row?.senderName ? row?.senderName : ''}
                        </TableCell>

                        <TableCell align="center" sx={{ backgroundColor: '#FFFFFF' }}>
                          {row?.notificationStatus.length}
                        </TableCell>
                        <TableCell align="center" sx={{ backgroundColor: '#FFFFFF' }}>
                          {/* {row?.notificationStatus.map((item) => item.userId).join(', ')} */}
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              // promotionListhandler(row?.notificationStatus.map((item) => item.username).join(', '))
                              promotionListhandler(row?.notificationStatus);
                            }}
                          >
                            <Iconify icon="twemoji:eyes" color="#1877F2" width={22} height={22} />
                            <span style={{ marginLeft: '8px' }}>See Users</span>
                          </MenuItem>
                        </TableCell>
                        <TableCell align="center" sx={{ backgroundColor: '#FFFFFF' }}>
                          {row?.attachments?.length > 0 && (
                            <div style={{ fontSize: 12, display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                              <AttachmentHOC
                                childref={closeAttachmentPopUpRef}
                                data={row.attachments.map((attach) => ({ url: attach }))}
                              />
                            </div>
                          )}
                          {/* {row?.attachments} */}
                        </TableCell>
                        <TableCell align="center" sx={{ backgroundColor: '#FFFFFF' }}>
                          {/* {row?.notificationStatus.map((item) => item.userId).join(', ')} */}
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              deletepromotionhandler(row?._id);
                            }}
                          >
                            <Iconify icon="fluent-mdl2:delete" color="#1877F2" width={22} height={26} />
                          </MenuItem>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <h3>No Promotion Data Found</h3>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={promotionList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
}

const AttachmentHOC = (props) => {
  const attachmentRef = useRef(null);
  const [openDownload, setOpenDownload] = useState(false);

  const handleClickOutside = (event) => {
    if (attachmentRef.current && !attachmentRef.current.contains(event.target)) {
      handleCloseDownload();
    }
  };
  useEffect(() => {
    console.log(' props.childref ================>', props);
    props.childref.current = handleCloseDownload;
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickDownload = (event) => {
    setOpenDownload(event?.currentTarget);
  };

  const handleCloseDownload = () => {
    setOpenDownload(null);
  };
  return (
    <div ref={attachmentRef}>
      <Button onClick={(e) => handleClickDownload(e)}>
        <Attachment
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          handleCloseDownload={handleCloseDownload}
          {...props}
        />
      </Button>
    </div>
  );
};
