import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Image } from 'antd';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from './ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onAccept, onReject, onCustomer, onProduct }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, newSortData, sortState } = useSortHook(data, ['createdAt', 'expiryDate']);

  const headData = [
    'Order Id',
    'Customer Id',
    'Qty * SKU',
    'Qty * Products',
    'Product Total Spts',
    'Status',
    'Request Date',
    'Actions',
  ];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: handleSort,
          sortHeadMap: ['orderId', 'user.userID', '', '', 'totalPoints', 'status', 'createdAt', ''],
        }}
        headData={headData}
      >
        {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {newSortData?.map((e, index) => (
          <TableRow key={index}>
            {/* <CustomTableCellForBody align="center">
              <Image
                preview
                style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                src={e?.image}
                alt="logo"
              />
            </CustomTableCellForBody> */}
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.orderId}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.user.userID}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle, whiteSpace: 'pre' }}>
                {e?.cartSnapShort?.map((e) => (
                  <>
                    <span>
                      {e?.qty} * {e?.sku}
                    </span>
                    <br />
                  </>
                ))}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle, whiteSpace: 'pre' }}>
                {e?.cartSnapShort?.map((e) => (
                  <>
                    <span>
                      {e?.qty} * {e?.name}
                    </span>
                    <br />
                  </>
                ))}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.totalPoints} Spts</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {e?.status === 'pending'
                  ? 'submitted'
                  : e?.status === 'accept'
                  ? 'accepted'
                  : e?.status === 'reject'
                  ? 'rejected'
                  : ''}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <ThreeDotMenu
                onAccept={e?.status === 'pending' && (() => onAccept(e))}
                onReject={e?.status === 'pending' && (() => onReject(e))}
                onProduct={() => onProduct(e)}
                onCustomer={() => onCustomer(e)}
              />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
