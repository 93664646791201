/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const CustomNewDashboardBoxUnlimited = ({
  color,
  img,
  title,
  leftTitle,
  leftValue,
  rightTitle,
  rightValue,
  onClick,
  maxWidth,
  minWidth,
  flex,
  style,
  height,
  flag,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        ...style,
        maxWidth: maxWidth,
        minWidth: minWidth,
        marginTop: style?.marginTop || '20px',
        filter: ' drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
        maxHeight: '160px',
        height: height || '160px',
        // width: '100%',
        flex: flex || '1',
        display: 'flex',
        position: 'relative',
        backgroundColor: 'white',
        padding: '16px',
        borderRadius: '12px',
        gap: '16px',
        // flex: 'none',
      }}
    >
      <img style={{ width: '25%', objectFit: 'contain', filter: 'invert(3%)', flex: 'none' }} src={img} alt="asdsad" />
      {/* floating img */}
      <div
        style={{
          filter: 'drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '60px',
          height: '60px',
          position: 'absolute',
          top: '-20px',
          left: '15px',
          backgroundColor: color,
          borderRadius: '12px',
        }}
      >
        <img
          style={{
            objectFit: 'cover',
            width: '60%',
            height: '60%',
          }}
          src={img}
          alt="one"
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: '1' }}>
        <span
          style={{
            color: '#3D71FF',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: 'normal',
          }}
        >
          {title}
        </span>
      </div>
      <div
        style={{
          justifyContent: 'space-between',
          gap: '12px',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          left: 0,
          display: 'flex',
          padding: '16px 32px',
          paddingLeft: flag === 'Number of Subscriptions' ? '100px' : '32px',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <span
            style={{
              // color: '#3D71FF',
              fontSize: '14px',
              lineHeight: 'normal',
            }}
          >
            {leftTitle}
          </span>
          <div style={{ height: '4px', backgroundColor: '#3D71FF', width: '90%', margin: '0px auto' }} />
          <span
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {leftValue}
          </span>
        </div>
        <div style={{ textAlign: 'center' }}>
          <span
            style={{
              // color: '#3D71FF',
              fontSize: '14px',
              lineHeight: 'normal',
            }}
          >
            {rightTitle}
          </span>
          <div style={{ height: '4px', backgroundColor: '#3D71FF', width: '90%', margin: '0px auto' }} />
          <span
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {rightValue}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomNewDashboardBoxUnlimited;
