import AreaChartCard from './Cards/AreaChartCard';
import BarChartCard from './Cards/BarChartCard';
import ChurnRateAreaChartCard from './Cards/ChurnRateAreaChartCard';
import ClicksImpressionBarChartCard from './Cards/ClicksImpressionBarChartCard';
import CountryChartCard from './Cards/CountryChartCard';
import CustomerRenewalPie from './Cards/CustomerRenewalPie';
import CustomersCountryWise from './Cards/CustomersCountryWise';
import InfoCard from './Cards/InfoCard';
import PieCard from './Cards/PieCard';
import StackBarChartCard from './Cards/StackBarChartCard';
import SubscriptionRevenueBarChart from './Cards/SubscriptionRevenueBarChart';
import UserActiveInactivePieCard from './Cards/UserActiveInactivePieCard';
import WelcomeBackCardSales from './Cards/WelcomeBackCardSales';

const SalesTab = () => {
  return (
    <>
      <div className="BusinessDashboard-grid-1">
        <WelcomeBackCardSales />
        <UserActiveInactivePieCard />
        <ChurnRateAreaChartCard />
      </div>
      <div className="BusinessDashboard-grid-3">
        <SubscriptionRevenueBarChart title={'Monthly Gross Sales'} />
        <CustomerRenewalPie />
      </div>
      <div className="BusinessDashboard-grid-3">
        <ClicksImpressionBarChartCard />
        <CustomersCountryWise />
      </div>
      <CountryChartCard />
    </>
  );
};

export default SalesTab;
