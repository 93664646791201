/* eslint-disable */

import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import QRCode from 'qrcode-svg';
import Barcode from 'react-barcode';
import html2canvas from 'html2canvas';
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fab, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import { toPng } from 'html-to-image';

import virtualCardImg from '../../../assets/virtualCard.png';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import {
  getColorsAndBgList,
  getColorsAndBgSelected,
  increaseDownload,
  updateColorsAndBgSelected,
  generateVirtualCardImage,
} from '../../../services/Service';
import { LoadingButton } from '@mui/lab';

import { downloadFile } from '../../../utils/fn';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function VirtualCardModal({ open, onClose, data, onEdit, onDelete }) {
  const svgRef = React.useRef(null);
  const downloadRef = React.useRef(null);
  const profile = useSelector((state) => state.auth.currentUser);
  const { formatDate } = useUserTimeZone();
  const [selectedColorBg, setSelectedColorBg] = useState({
    color: null,
    image: null,
  });
  const [isDownloding, setIsDownloding] = useState(false);
  const handleClose = () => {
    onClose();
  };

  const onShare = async () => {
    const shareData = `Dear SafalSubscriptions User,%0D%0A%0D%0AGreetings!%0D%0A%0D%0AWe are pleased to inform you that your Safal virtual card is now available. Please find your asset information below.%0D%0A%0D%0ACard Name : ${data?.companyId?.name}%0D%0ACard Number : ${data?.cardNumber}%0D%0A%0D%0AThank you for choosing Safalsubscription. If you have any questions or need further assistance, feel free to reach out to our support team.%0D%0A%0D%0ABest regards,%0D%0A%0D%0AThe Safalsubscription Team`;
    // const shareData = `Name : ${profile?.displayName}. Card Number : ${data?.cardNumber}`;
    // await navigator.clipboard.writeText(shareData);
    const mailtoLink = `mailto:email@example.com?subject=Safal Virtual Card Share&body=${shareData}`;
    window.location.href = mailtoLink;
    toast.success('Mail opening');
  };

  const onPrint = () => {
    const mywindow = window.open('', 'PRINT');
    const is_chrome = Boolean(mywindow.chrome);
    mywindow.document.write(`<html><head><title>${document.title}</title>`);
    mywindow.document.write('</head><body style="width:800px">');
    mywindow.document.write(document.getElementById('VirtualCardPrint').outerHTML);
    mywindow.document.write('</body></html>');

    if (is_chrome) {
      setTimeout(() => {
        // wait until all resources loaded
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
        mywindow.print(); // change window to winPrint
        mywindow.close(); // change window to winPrint
      }, 1000);
    } else {
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10

      mywindow.print();
      mywindow.close();
    }
  };

  const onDownload = async () => {
    try {
      setIsDownloding(true);

      const outerHTML = document.getElementById('VirtualCardPrint').outerHTML;
      const res = await generateVirtualCardImage({ imageHtml: outerHTML, type: data?.cardId?.type });

      downloadFile(res.data.url, res.data.name);
    } catch (err) {
      console.log(err);
    } finally {
      setIsDownloding(false);
    }
  };

  const color = selectedColorBg.color ? selectedColorBg.color.value : 'white';

  useEffect(() => {
    const qrData = `${data?.cardNumber}`;
    const qrcode = new QRCode({
      content: qrData,
      container: 'svg-viewbox', //Responsive use
      join: true, //Crisp rendering and 4-5x reduced file size
      width: 80,
      height: 80,
      color: color,
      background: 'transparent',
    });
    const svg = qrcode.svg();
    if (svgRef.current) svgRef.current.innerHTML = svg;
  }, [data?.cardNumber, color]);

  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Fab
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
          }}
          onClick={handleClose}
          size="small"
          color="primary"
          aria-label="add"
        >
          <CloseIcon />
        </Fab>
        <Typography variant="h4" gutterBottom sx={{ fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}>
          Virtual Card
        </Typography>
        <div style={{ display: 'flex', marginBottom: '12px', gap: '12px' }}>
          <IconButton
            onClick={onShare}
            sx={{
              backgroundColor: '#3D71FF',
              color: 'white',
              '&:hover': {
                backgroundColor: '#2a4fb2',
                color: 'white',
              },
            }}
          >
            <ShareIcon />
          </IconButton>
          <IconButton
            onClick={onPrint}
            sx={{
              backgroundColor: '#3D71FF',
              color: 'white',
              '&:hover': {
                backgroundColor: '#2a4fb2',
                color: 'white',
              },
            }}
          >
            <PrintIcon />
          </IconButton>
          <IconButton
            onClick={onDownload}
            disabled={isDownloding}
            sx={{
              backgroundColor: '#3D71FF',
              color: 'white',
              border: '1px solid #3D71FF',
              '&:hover': {
                backgroundColor: '#2a4fb2',
                color: 'white',
                border: '1px solid #2a4fb2',
              },
            }}
          >
            <DownloadIcon />
          </IconButton>
        </div>
        <div
          ref={downloadRef}
          id="VirtualCardPrint"
          style={{
            position: 'relative',
            // backgroundImage: `url(${virtualCardImg})`,
            backgroundSize: 'cover',
            aspectRatio: '1.704/1',
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            gap: '12px',
            paddingBottom: '8px',
          }}
        >
          <img
            style={{
              position: 'absolute',
              zIndex: '-1',
              height: '100%',
              objectFit: 'cover',
              aspectRatio: '1.704/1',
              top: '0px',
              left: '0px',
            }}
            src={selectedColorBg.image ? selectedColorBg.image.value : virtualCardImg}
            alt="virtualCardImg"
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              gap: '12px',
              marginLeft: '12px',
            }}
          >
            <img
              style={{
                maxHeight: '64px',
                objectFit: 'cover',
              }}
              src={data?.companyId?.logo}
              alt="company logo"
            />
            <span
              style={{
                fontSize: '28px',
                fontWeight: 'bold',
                color: color,
                lineHeight: 1,
              }}
            >
              {data?.companyId?.name}
            </span>
            {data?.expiryDate && (
              <span
                style={{
                  marginLeft: 'auto',
                  fontSize: '18px',
                  color: color,
                  lineHeight: 1,
                }}
              >
                Valid {formatDate(data.expiryDate)} {getTimeZoneAbbreviation()}
              </span>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginLeft: '12px',
            }}
          >
            <div
              style={{
                color: color,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span>Name</span>
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  lineHeight: 1,
                }}
              >
                {profile?.displayName}
              </span>
            </div>
            <div style={{ height: '80px', width: '80px' }} ref={svgRef} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              color: color,
              marginLeft: '12px',
            }}
          >
            <span>Card Number</span>
            <span
              style={{
                fontSize: '24px',
                fontWeight: 'bold',
                lineHeight: 1,
              }}
            >
              {data?.cardNumber}
            </span>
          </div>
          <Barcode
            value={data?.cardNumber || 'Safal Subscriptions'}
            displayValue={false}
            background="transparent"
            lineColor={color}
          />
          <div
            style={{
              display: 'flex',
              gap: '48px',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            <span style={{ textAlign: 'center', color: color }}>Card generated via SafalSubscriptions Web App</span>
            <img
              style={{ maxHeight: '48px' }}
              src="https://safal-dev-public-us.s3.amazonaws.com/assetModule/1717824186083-Logo.png"
              alt="Safal Subscriptions"
            />
          </div>
          <span style={{ textAlign: 'center', color: color, fontSize: '12px' }}>
            This system does not provide your actual wallet balance. Please check the associated system for real-time
            values.
          </span>
        </div>
        <ColorBackgroundUI assetData={data} selectedColorBg={selectedColorBg} setSelectedColorBg={setSelectedColorBg} />
      </Box>
    </Modal>
  );
}

const ColorBackgroundUI = ({ selectedColorBg, setSelectedColorBg, assetData }) => {
  const [selectedMode, setSelectedMode] = useState('color');
  const [isEdit, setIsEdit] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [selectedColorBgBackup, setSelectedColorBgBackup] = useState(null);

  const fetchUserColorBg = async () => {
    try {
      const res = await getColorsAndBgSelected({ assetId: assetData._id });
      setSelectedColorBg({
        color: res.data.data.color,
        image: res.data.data.background,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchData = async () => {
    try {
      const res = await getColorsAndBgList();
      setDataList(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onEdit = async () => {
    setSelectedColorBgBackup(selectedColorBg);
    setIsEdit(true);
    fetchUserColorBg();
    fetchData();
  };

  const filterList = dataList.filter((ex) => ex.type === selectedMode);

  const onUpdate = async (data) => {
    if (data?.type === 'color') {
      setSelectedColorBg((prev) => ({
        ...prev,
        color: data,
      }));
    } else {
      setSelectedColorBg((prev) => ({
        ...prev,
        image: data,
      }));
    }
  };

  const onReset = async () => {
    try {
      const payload = {
        assetId: assetData._id,
      };
      const res = await updateColorsAndBgSelected(payload);
      fetchUserColorBg();
      setIsEdit(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onSave = async () => {
    try {
      const payload = {
        assetId: assetData._id,
      };
      if (selectedColorBg.color._id !== 'DEFAULT') {
        payload.colorId = selectedColorBg.color._id;
        if (selectedColorBg.image && selectedColorBg.image._id !== 'DEFAULT') {
          payload.backgroundId = selectedColorBg.image._id;
        }
      }
      if (selectedColorBg.image._id !== 'DEFAULT') {
        payload.backgroundId = selectedColorBg.image._id;
        if (selectedColorBg.color && selectedColorBg.color._id !== 'DEFAULT') {
          payload.colorId = selectedColorBg.color._id;
        }
      }
      if (selectedColorBg.color._id === 'DEFAULT' && selectedColorBg.image._id === 'DEFAULT') return;

      const res = await updateColorsAndBgSelected(payload);
      onClose(false);
      fetchUserColorBg();
    } catch (err) {
      console.log(err);
    }
  };

  const onClose = (flag) => {
    setIsEdit(false);
    if (flag) {
      setSelectedColorBg({
        color: selectedColorBgBackup.color,
        image: selectedColorBgBackup.image,
      });
    }
    setSelectedColorBgBackup(null);
  };

  React.useEffect(() => {
    fetchUserColorBg();
  }, []);

  const isSaveEnable =
    selectedColorBg?.color?._id === selectedColorBgBackup?.color?._id
      ? selectedColorBg?.image?._id === selectedColorBgBackup?.image?._id
      : false;

  return (
    <>
      <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'space-between' }}>
        {isEdit ? (
          <div style={{ display: 'flex', gap: '12px' }}>
            <Button
              onClick={onReset}
              sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
              variant="contained"
            >
              Reset
            </Button>
          </div>
        ) : (
          <Button
            onClick={onEdit}
            sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
            variant="contained"
          >
            Edit
          </Button>
        )}
        {isEdit && (
          <div style={{ display: 'flex', gap: '12px' }}>
            <Button
              onClick={() => setSelectedMode('color')}
              sx={{
                textTransform: 'capitalize',
                backgroundColor: selectedMode === 'color' ? '#3D71FF' : '#f2f2f2',
                borderRadius: '30px',
                color: selectedMode === 'color' ? 'white' : '#3D71FF',
                border: '1px solid #3D71FF',
                '&:hover': {
                  backgroundColor: selectedMode === 'color' ? '#3D71FF' : '#f2f2f2',
                  color: selectedMode === 'color' ? 'white' : '#3D71FF',
                },
              }}
              variant="contained"
            >
              Color
            </Button>
            <Button
              onClick={() => setSelectedMode('image')}
              sx={{
                textTransform: 'capitalize',
                backgroundColor: selectedMode === 'image' ? '#3D71FF' : '#f2f2f2',
                borderRadius: '30px',
                color: selectedMode === 'image' ? 'white' : '#3D71FF',
                border: '1px solid #3D71FF',
                '&:hover': {
                  backgroundColor: selectedMode === 'image' ? '#3D71FF' : '#f2f2f2',
                  color: selectedMode === 'image' ? 'white' : '#3D71FF',
                },
              }}
              variant="contained"
            >
              Image
            </Button>
          </div>
        )}
      </div>
      {isEdit && (
        <div
          style={{
            display: 'flex',
            gap: '12px',
            overflow: 'auto',
            padding: '12px 0px',
            alignItems: 'center',
          }}
        >
          {filterList.map((e) =>
            e.type === 'image' ? (
              <img
                onClick={() => onUpdate(e)}
                src={e.value}
                style={{
                  flex: 'none',
                  height: '60px',
                  width: '120px',
                  objectFit: 'fill',
                  borderRadius: '16px',
                  border: selectedColorBg.image && selectedColorBg.image._id === e._id && '2px solid #3D71FF',
                }}
                alt="bg"
              />
            ) : (
              <div
                onClick={() => onUpdate(e)}
                style={{
                  flex: 'none',
                  height: '60px',
                  borderRadius: '16px',
                  width: '120px',
                  backgroundColor: e.value,
                  border: selectedColorBg.color && selectedColorBg.color._id === e._id && '2px solid #3D71FF',
                }}
              />
            )
          )}
        </div>
      )}
      {isEdit && (
        <div style={{ display: 'flex', gap: '12px', justifyContent: 'end' }}>
          <Button
            onClick={() => onClose(true)}
            sx={{
              backgroundColor: '#ff4842',
              borderRadius: '30px',
              textTransform: 'capitalize',
              ':hover': { backgroundColor: '#d40700' },
            }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={isSaveEnable}
            onClick={onSave}
            sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
            variant="contained"
          >
            Save
          </Button>
        </div>
      )}
    </>
  );
};
