import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Menu,
  Select,
  Stack,
  TextField,
  Typography,
  Tooltip,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Image } from 'antd';
import Swal from 'sweetalert2';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { ClickAwayListener } from '@mui/base';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@mui/styles';
import {
  EditsubsResponse,
  FilterResponse,
  GetsubsResponse,
  downloadAttachmentFile,
  getSignedURL,
  getSubLabel,
} from '../../services/Service';
import SuccessToast from '../Toast/Success';
import { setSubscriptions } from '../../store/subscriptionSlice';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import { getProfile } from '../../store/loginSlice';
import ErrorToast from '../Toast/Error';
import Inlineloader from '../Loader/InlineLoader';
import CustomRating from '../UI/CustomRating';
import CustomSelect from '../UI/CustomSelect';
import AddPaymentInstrumentModal from './AddPaymentInstrumentModal';
import CustomMuiSelect from '../UI/CustomMuiSelect';
import AddCustomLabel from './AddCustomLabel';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 100,
  },
}));

const style = {
  position: 'absolute',
  zIndex: 10,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '10px',
  boxShadow: 24,
  // p: "4 0 4 4",
  p: '30px',
};

const EditModal = ({
  setSelected,
  openEditModal,
  setOpenEditModal,
  data,
  setEditData,
  setOpenEventModal,
  setOpenTrackModal,
  setOpenDelete,
  handledelete,
  handleActionClose,
  paymentCardSchemeList,
  paymentInstrumentList,
  paymentModeList,
  fetchPaymentModeInfo,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedEditFileName, setSelectedEditFileName] = useState('');
  const [indata, setInData] = useState(['attachment']);
  const { user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const { startDate, nextBilling, subscriptionName, amount, frequency, status, isLiked, rating, subType } = useSelector(
    (state) => state.subscription
  );
  const [isExpiryDateEnable, setExpiryDateEnable] = React.useState(false);
  const [subLabel, setSubLabel] = React.useState([]);
  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState('');
  const [addPaymentInstrumentModalOpen, setAddPaymentInstrumentModalOpen] = React.useState(false);
  const [selectedPaymentInstrumentList, setSelectedPaymentInstrumentList] = React.useState([]);

  const isAttachmentsLengthAreSame = selectedEditFileName?.length === data?.attachments?.length;
  const [openCustomLabel, setOpenCustomLabel] = React.useState({
    open: false,
    data: null,
  });

  const handleClose = () => {
    if (!(isAttachmentsLengthAreSame ? indata.length < 2 : false)) {
      Swal.fire({
        text: 'Are you sure you want to close this?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        appendTo: 'body',
        customClass: {
          container: 'my-swal', // Add a custom class for styling
        },
        onBeforeOpen: () => {
          // Set a higher zIndex for the Swal modal
          document.querySelector('.my-swal').style.zIndex = 999999;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          closeThisModal();
        }
      });
    } else {
      closeThisModal();
    }
  };

  const closeThisModal = () => {
    handleActionClose && handleActionClose();
    setExpiryDateEnable(false);
    setOpenEditModal(false);
    setOpenEventModal(false);
    setEditData([]);
    setSelectedPaymentInstrumentList([]);
    setSelectedPaymentMode('');
  };

  const validationSchema = Yup.object().shape({
    website: Yup.string().required('Please Enter Website'),
    frequency: Yup.string().required('Please Select frequency'),
    // currencyAbr: Yup.string().required('Please Select Currency'),
    startDate: Yup.date().required('Please Select Contract Start Date').typeError('Please enter a valid date'),
    amount: Yup.number().min(0).required('Please Enter Amount'),
    autoRenewal: Yup.string().required('Please Select Auto Renewal'),
    nextBilling: Yup.date().required('Please Select next billing Date').typeError('Please enter a valid date'),
    // .test('nextBilling', 'Must be greater than today', (value) => {
    //   return moment(value) > moment();
    // })
    status: Yup.string().required('Please select Status'),
    frequencyNumber: Yup.number()
      .min(1)
      .positive()
      .typeError('Please enter a valid frequency number')
      .required('Please Enter frequency number'),
  });

  // const initialValues = React.memo(
  //   () => ({
  //     website: data?.company?.website,
  //     _id: data?._id,
  //     rating: data?.rating,
  //     isLiked: data?.isLiked,
  //     frequency: data?.frequency,
  //     startDate: data?.startDate,
  //     nextBilling: data?.nextBilling,
  //     amount: data?.amount,
  //     autoRenewal: data?.autoRenewal ? 'true' : 'false',
  //     status: `${data?.status}`,
  //     comments: data?.comments,
  //     description: data?.description,
  //     attachment: data?.attachments,
  //     review: data?.review,
  //   }),
  //   []
  // );

  const initialValues = {
    subType: data?.company?.companyType,
    website: data?.company?.website,
    _id: data?._id,
    rating: data?.rating,
    isLiked: data?.isLiked,
    frequency: data?.frequency,
    // currencyAbr: data?.currencyAbr,
    startDate: data?.startDate,
    nextBilling: data?.nextBilling,
    amount: data?.amount,
    autoRenewal: data?.autoRenewal ? 'true' : 'false',
    status: `${data?.status}`,
    comments: data?.comments,
    description: data?.description,
    attachment: data && data?.attachments?.length > 0 ? data?.attachments : [],
    review: data?.review,
    frequencyNumber: data?.frequencyNumber,
    // label: data?.label?.name,
    expiryDate: '',
  };
  const EditForm = useFormik({
    enableReinitialize: false,
    initialValues,
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values.subscriptionName = data?.subscriptionName;
      values.currencyAbr = user?.country === 'United States' ? 'USD' : 'INR';

      let valuesToBeSent = indata.map((val) => {
        if (val === 'attachment' && values[val] !== 'undefined' && values[val] === '') {
          if (values[val].length === 0) {
            return { attachment: [...initialValues.attachment] };
          }
          return { attachment: [...values.attachment] };
        }
        return { [val]: values[val] };
      });
      valuesToBeSent = Object.assign({}, ...valuesToBeSent);
      EditsubsResponse(data._id, valuesToBeSent)
        .then((res) => {
          if (res.data.success === true) {
            SuccessToast(res.data.message);
            setLoading(false);
            setSelectedPaymentInstrumentList([]);
            setSelectedPaymentMode('');
            setInData([]);
            // handleCloseSave()
            // handleClose();
            // FilterResponse(
            //   startDate,
            //   nextBilling,
            //   subscriptionName,
            //   amount,
            //   frequency,
            //   status,
            //   isLiked,
            //   rating,
            //   subType
            // )

            GetsubsResponse()
              .then((res) => {
                if (res.data.success === true) {
                  dispatch(
                    setSubscriptions({
                      subscriptions: res.data.data,
                      totalItems: res.data.pagination[0].totalItems,
                    })
                  );
                  dispatch(getProfile()).unwrap();
                  // handleClose();
                  // setOpenEditModal(false);
                }
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
                ErrorToast(err?.response?.data?.message);
                // if (err.response.status === 401) {
                //   console.log(err);
                // }
              });
            // handleClose();
            // setOpenEditModal(false);
            setOpenEditModal(false);
            resetForm(initialValues);
          } else {
            setLoading(false);
            handleClose();
            ErrorToast(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          ErrorToast(err?.response?.data?.message);
          setLoading(false);
          // handleClose();
        });
    },
  });

  const fetchSubLabel = async () => {
    try {
      const res = await getSubLabel();
      setSubLabel(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const formdata = {
      subType: data?.company?.companyType,
      website: data?.company?.website,
      _id: data?._id,
      rating: data?.rating,
      // currencyAbr: data?.currencyAbr,
      isLiked: data?.isLiked,
      frequency: data?.frequency,
      startDate: data?.startDate,
      nextBilling: data?.nextBilling,
      amount: data?.amount,
      autoRenewal: data?.autoRenewal ? 'true' : 'false',
      status: `${data?.status}`,
      comments: data?.comments,
      description: data?.description,
      attachment:
        data && data?.attachments?.length > 0 ? `${data?.attachments?.url}`.split('-').slice(4).join('-') : [],
      review: data?.review,
      label: data?.label?._id,
      expiryDate: data?.expiryDate ? data?.expiryDate : '',
      frequencyNumber: data?.frequencyNumber ? data?.frequencyNumber : '',
      paymentInstrument: data?.paymentInstrument?._id,
    };
    if (data?.paymentInstrument) {
      setSelectedPaymentMode(data?.paymentInstrument?.paymentModeId);
      const newPaymentInstrumentList = paymentInstrumentList?.filter(
        (ex) => ex?.paymentModeId?._id === data?.paymentInstrument?.paymentModeId
      );
      setSelectedPaymentInstrumentList(newPaymentInstrumentList);
    }

    if (data?.expiryDate) {
      setExpiryDateEnable(true);
    }
    EditForm.setValues(formdata);
    setSelectedEditFileName(data?.attachments);
    setInData(['attachment']);
    fetchSubLabel();
  }, [data]);

  const handleFileRemove = (val) => {
    const newState = selectedEditFileName.filter((arr) => val !== arr);
    setSelectedEditFileName(newState);
    indata.indexOf('attachment') === -1 && setInData((pre) => [...pre, 'attachment']);
    EditForm.setFieldValue('attachment', newState);
  };

  const onExpiryDateEnableChange = (e) => {
    setExpiryDateEnable(e.target.checked);
    // if (e.target.checked === false) {
    //   SubscriptionForm.setFieldValue('expiryDate', '');
    //   SubscriptionForm.setFieldTouched('expiryDate', false, false);
    //   setInitialValues((prev) => ({
    //     ...prev,
    //     expiryDate: '',
    //   }));
    // }
  };

  const onCloseAddPaymentInstrumentModal = async () => {
    console.log('onCloseAddPaymentInstrumentModal');
    setAddPaymentInstrumentModalOpen(false);
    const newListX = await fetchPaymentModeInfo();
    console.log('onCloseAddPaymentInstrumentModal', newListX);
    const newPaymentInstrumentList = newListX?.filter((ex) => ex?.paymentModeId?._id === selectedPaymentMode);
    setSelectedPaymentInstrumentList(newPaymentInstrumentList);
  };

  return (
    // <div>
    <>
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
            }}
          />
          <Inlineloader />
        </>
      )}
      <Modal
        sx={{ zIndex: 345 }}
        open={openEditModal}
        onClose={() => handleClose()}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        {/* <Box sx={{ ...style, height: '90%', width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' } }}>
         */}
        <Box
          id="scrollbar"
          sx={{
            ...style,
            height: '80vh',
            paddingBottom: '10rem',
            position: 'relative',
            width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' },
            '@media (max-width: 640px)': {
              height: '98vh',
              width: '98vw',
              padding: '16px',
              overflow: 'auto',
            },
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ display: 'flex', alignItems: 'center', fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}
              >
                Edit {data?.subscriptionName} Subscription
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ fontSize: '15px', fontWeight: 400 }}>
                Edit your details below.
              </Typography>
            </Box>

            <Fab
              onClick={(e) => {
                e.preventDefault();
                EditForm.setValues(initialValues);
                handleClose();
              }}
              size="small"
              color="primary"
              aria-label="add"
            >
              <CloseIcon />
            </Fab>
          </Stack>

          <Scrollbar>
            <Box sx={{ height: '100%', overflow: 'auto' }}>
              <FormikProvider value={EditForm}>
                <form onSubmit={EditForm.handleSubmit}>
                  <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ overflowX: 'hidden' }}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Box>
                        <Image width={60} height={60} src={data?.company?.logo} />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '98%' }}
                      >
                        {/* <FormLabel sx={{ fontSize: '14px', color: '#B6B6B6' }}>Subscription Type</FormLabel> */}
                        <Field
                          as={TextField}
                          variant="standard"
                          size="small"
                          label={
                            <>
                              Subscription Type<span style={{ color: 'red' }}>*</span>
                            </>
                          }
                          name="subType"
                          href={EditForm.values.subType}
                          disabled
                        >
                          {EditForm.values.subType}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth>
                        <FormLabel sx={{ fontSize: '14px', color: '#B6B6B6' }}>Website</FormLabel>
                        <Field
                          as={Link}
                          label="Website"
                          name="website"
                          href={
                            EditForm.values.website?.includes('https://')
                              ? EditForm.values.website
                              : `https://${EditForm.values.website}`
                          }
                          target="_blank"
                        >
                          {EditForm.values.website?.includes('https://')
                            ? EditForm.values.website
                            : `https://${EditForm.values.website}`}
                        </Field>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '98%' }}
                      >
                        <LocalizationProvider dateAdapter={MomentUtils}>
                          <Field
                            as={DesktopDatePicker}
                            maxDate={moment(EditForm.values.nextBilling).toDate()}
                            label={
                              <Typography sx={{ color: '#B6B6B6' }}>
                                Contract Start Date<span style={{ color: 'red' }}>*</span>
                              </Typography>
                            }
                            inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                            // name="startDate"
                            onChange={(e) => {
                              EditForm.setFieldValue('startDate', moment(e).format('yyyy-MM-DD'));
                              indata.indexOf('startDate') === -1 && setInData((pre) => [...pre, 'startDate']);
                              EditForm.setFieldTouched('startDate', true, false);
                            }}
                            value={EditForm.values.startDate}
                            // sx={{
                            //   '&:after': {
                            //     borderBottomColor: '#0000',
                            //   },
                            //   '& .MuiSvgIcon-root': {
                            //     color: '#0071E3',
                            //   },
                            // }}
                            renderInput={(params) => (
                              <Field
                                as={TextField}
                                // name="startDate"
                                variant="standard"
                                size="small"
                                {...params}
                                error={EditForm.touched.startDate && Boolean(EditForm.errors.startDate)}
                                helperText={EditForm.touched.startDate && EditForm.errors.startDate}
                                sx={{
                                  svg: { color: '#0071E3', mr: '15px' },
                                }}
                                onBlur={() => {
                                  EditForm.setFieldTouched('startDate', true, false);
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '98%' }}
                      >
                        <LocalizationProvider dateAdapter={MomentUtils}>
                          <Field
                            as={DesktopDatePicker}
                            minDate={moment(EditForm.values.startDate).toDate()}
                            label={
                              <Typography sx={{ color: '#B6B6B6' }}>
                                Next Billing Date<span style={{ color: 'red' }}>*</span>
                              </Typography>
                            }
                            inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                            // name="nextBilling"
                            onChange={(e) => {
                              EditForm.setFieldValue('nextBilling', moment(e).format('yyyy-MM-DD'));
                              indata.indexOf('nextBilling') === -1 && setInData((pre) => [...pre, 'nextBilling']);
                              EditForm.setFieldTouched('nextBilling', true, false);
                            }}
                            value={EditForm.values.nextBilling}
                            renderInput={(params) => (
                              <Field
                                as={TextField}
                                // name="nextBilling"
                                variant="standard"
                                size="small"
                                {...params}
                                error={EditForm.touched.nextBilling && Boolean(EditForm.errors.nextBilling)}
                                helperText={EditForm.touched.nextBilling && EditForm.errors.nextBilling}
                                sx={{
                                  svg: { color: '#0071E3', mr: '15px' },
                                }}
                                onBlur={() => {
                                  EditForm.setFieldTouched('nextBilling', true, false);
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Checkbox
                          defaultChecked={isExpiryDateEnable}
                          value={isExpiryDateEnable}
                          onChange={onExpiryDateEnableChange}
                          sx={{ padding: '8px 8px 4px 8px' }}
                        />
                        <FormControl
                          //  fullWidth
                          sx={{ width: '98%' }}
                        >
                          <LocalizationProvider dateAdapter={MomentUtils}>
                            <Field
                              disabled={!isExpiryDateEnable}
                              as={DesktopDatePicker}
                              // minDate={new Date()}
                              label={<Typography sx={{ color: '#B6B6B6' }}>Expiry Date</Typography>}
                              inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                EditForm.setFieldValue('expiryDate', moment(e).format('yyyy-MM-DD'));
                                indata.indexOf('expiryDate') === -1 && setInData((pre) => [...pre, 'expiryDate']);
                                EditForm.setFieldTouched('expiryDate', true, false);
                              }}
                              value={EditForm.values.expiryDate}
                              renderInput={(params) => (
                                <Field
                                  as={TextField}
                                  // name="nextBillingDate"
                                  variant="standard"
                                  size="small"
                                  onBlur={() => {
                                    EditForm.setFieldTouched('expiryDate', true, false);
                                  }}
                                  {...params}
                                  error={EditForm.touched.expiryDate && Boolean(EditForm.errors.expiryDate)}
                                  helperText={EditForm.touched.expiryDate && EditForm.errors.expiryDate}
                                  sx={{
                                    ':hover': {
                                      backgroundColor: 'white',
                                    },
                                    svg: {
                                      color: '#0071E3',
                                      mr: '15px',
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        sx={{ mt: 2, width: '98%' }}
                        error={EditForm.touched.amount && EditForm.errors.amount}
                      >
                        <InputLabel htmlFor="amount" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Amount<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Field
                          as={Input}
                          onWheel={(event) => {
                            event.preventDefault();
                          }}
                          label="Amount"
                          name="amount"
                          // type="number"
                          value={EditForm.values.amount}
                          onChange={(e) => {
                            if (e.target.value.length < 9) {
                              EditForm.handleChange(e);
                              indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                            }
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              {user?.country === 'United States' ? '$' : '₹'}
                              {/* {EditForm?.values?.currencyAbr === 'INR' ? '₹' : '$'} */}
                            </InputAdornment>
                          }
                        />
                        {EditForm.touched.amount && EditForm.errors.amount ? (
                          <FormHelperText>{EditForm.touched.amount && EditForm.errors.amount}</FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '98%' }}
                      >
                        <InputLabel id="select3" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Frequency<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Select
                          labelId="select3"
                          id="select3"
                          name="frequency"
                          label="Frequency"
                          variant="standard"
                          size="small"
                          value={`${EditForm.values.frequency}`}
                          onChange={(e) => {
                            EditForm.handleChange(e);
                            indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                          }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          <MenuItem value={'Monthly'}>Monthly</MenuItem>
                          <MenuItem value={'Annually'}>Annually</MenuItem>
                          <MenuItem value={'Weekly'}>Weekly</MenuItem>
                          <MenuItem value={'Daily'}>Daily</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        sx={{ mt: 2, width: '98%' }}
                        error={EditForm.touched.frequencyNumber && EditForm.errors.frequencyNumber}
                      >
                        <InputLabel htmlFor="amount" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Frequency Number<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Field
                          as={Input}
                          onWheel={(event) => {
                            event.preventDefault();
                          }}
                          label="Frequency Number"
                          name="frequencyNumber"
                          type="number"
                          value={EditForm.values.frequencyNumber}
                          onChange={(e) => {
                            if (e.target.value.length < 3) {
                              EditForm.handleChange(e);
                              indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                            }
                          }}
                        />
                        {EditForm.touched.frequencyNumber && EditForm.errors.frequencyNumber ? (
                          <FormHelperText>
                            {EditForm.touched.frequencyNumber && EditForm.errors.frequencyNumber}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '98%' }}
                      >
                        <InputLabel id="select4" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Auto Renewal<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Select
                          labelId="select4"
                          id="select4"
                          name="autoRenewal"
                          label="Auto Renewal"
                          variant="standard"
                          size="small"
                          value={EditForm.values.autoRenewal}
                          onChange={(e) => {
                            EditForm.handleChange(e);
                            indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                          }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '98%' }}
                      >
                        <InputLabel id="select4" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Status<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Select
                          labelId="select4"
                          id="select4"
                          name="status"
                          label="Status"
                          variant="standard"
                          size="small"
                          value={EditForm.values.status}
                          onChange={(e) => {
                            EditForm.handleChange(e);
                            indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                          }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          <MenuItem value={'Active'}>Active</MenuItem>
                          <MenuItem value={'Inactive'}>Inactive</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* Label */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '98%' }}
                      >
                        {/* <InputLabel id="select4" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Label
                        </InputLabel>
                        <Select
                          labelId="select5"
                          id="select5"
                          name="label"
                          label="Label"
                          variant="standard"
                          size="small"
                          value={EditForm.values.label}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          onChange={(e) => {
                            // EditForm.setFieldValue('label', valuex);
                            EditForm.handleChange(e);
                            indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                          }}
                          sx={{
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          {subLabel.map((exx, index) => (
                            <MenuItem key={index} value={exx._id}>
                              {exx.name}
                            </MenuItem>
                          ))}
                        </Select> */}
                        <CustomMuiSelect
                          disablePortal
                          label="Label"
                          size="small"
                          variant="standard"
                          options={subLabel.map((e) => ({ label: e.name, id: e._id }))}
                          value={subLabel?.find((ex) => ex._id === EditForm.values.label)?.name}
                          onAddNew={() => {
                            setOpenCustomLabel({ open: true, data: null });
                          }}
                          onChange={(e) => {
                            if (e) {
                              EditForm.setFieldValue('label', e.id);
                              indata.indexOf('label') === -1 && setInData((pre) => [...pre, 'label']);
                            } else {
                              EditForm.setFieldValue('label', '');
                              indata.indexOf('label') === -1 && setInData((pre) => [...pre, 'label']);
                            }
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {/* Payment Mode */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl sx={{ width: '98%' }}>
                        <InputLabel id="select55" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Payment Mode
                        </InputLabel>
                        <Field
                          as={Select}
                          labelId="select55"
                          id="select55"
                          name="status"
                          label="Payment Mode"
                          variant="standard"
                          size="small"
                          value={selectedPaymentMode}
                          onChange={(e) => {
                            setSelectedPaymentMode(e.target.value);
                            const newPaymentInstrumentList = paymentInstrumentList?.filter(
                              (ex) => ex?.paymentModeId?._id === e.target.value
                            );
                            setSelectedPaymentInstrumentList(newPaymentInstrumentList);
                          }}
                          sx={{
                            '&:after': {
                              borderBottomColor: '#0000',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          {paymentModeList?.map((e, index) => (
                            <MenuItem key={index} value={e._id}>
                              {e.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    {/* paymentInstrument */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl sx={{ width: '98%' }}>
                        <CustomMuiSelect
                          disablePortal
                          label="Payment Instrument"
                          size="small"
                          variant="standard"
                          options={selectedPaymentInstrumentList?.map((e) => ({ label: e.nickNameField, id: e._id }))}
                          value={
                            selectedPaymentInstrumentList?.find((ex) => ex._id === EditForm.values.paymentInstrument)
                              ?.nickNameField
                          }
                          onAddNew={() => {
                            setAddPaymentInstrumentModalOpen(true);
                          }}
                          onChange={(e) => {
                            if (e) {
                              EditForm.setFieldValue('paymentInstrument', e.id);
                              indata.indexOf('paymentInstrument') === -1 &&
                                setInData((pre) => [...pre, 'paymentInstrument']);
                            } else {
                              EditForm.setFieldValue('paymentInstrument', '');
                              indata.indexOf('paymentInstrument') === -1 &&
                                setInData((pre) => [...pre, 'paymentInstrument']);
                            }
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '99%' }}
                      >
                        <Field
                          as={TextField}
                          id="input1"
                          name="comments"
                          multiline
                          rows={3}
                          inputProps={{
                            maxlength: 250,
                          }}
                          helperText={`${EditForm.values.comments?.length || 0}/${250}`}
                          label={<Typography sx={{ color: '#B6B6B6' }}>Comments</Typography>}
                          variant="standard"
                          size="small"
                          value={EditForm.values.comments}
                          onChange={(e) => {
                            EditForm.handleChange(e);
                            indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        sx={{
                          color: ' #B6B6B6',
                          fontFamily: 'Roboto',
                          fontWeight: '400',
                          fontSize: '1rem',
                          lineHeight: '1.4375em',
                          letterSpacing: '0.00938em',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          mb: 1,
                        }}
                      >
                        Rating
                      </Typography>
                      <CustomRating
                        precision={0.5}
                        name="rating"
                        value={+EditForm.values.rating}
                        onChange={(e) => {
                          EditForm.setFieldValue('rating', e.target.value);
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        sx={{
                          color: ' #B6B6B6',
                          fontFamily: 'Roboto',
                          fontWeight: '400',
                          fontSize: '1rem',
                          lineHeight: '1.4375em',
                          letterSpacing: '0.00938em',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          mb: 1,
                        }}
                      >
                        Safals
                      </Typography>
                      <Box
                        sx={{ display: 'flex' }}
                        name="isLiked"
                        onChange={(e) => {
                          EditForm.handleChange(e);
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                        }}
                        onClick={() => {
                          EditForm.setFieldValue('isLiked', EditForm.values.isLiked === 1 ? -1 : 1);
                          indata.indexOf('isLiked') === -1 && setInData((pre) => [...pre, 'isLiked']);
                        }}
                      >
                        {EditForm.values.isLiked === 1 && <img src="/assets/images/Like.png" alt="Like Symbol" />}
                        {EditForm.values.isLiked === -1 && (
                          <img src="/assets/images/disLike.png" alt="DisLike Symbol" />
                        )}
                        {EditForm.values.isLiked === 0 && (
                          <>
                            <img
                              src="/assets/images/Like.png"
                              alt="Like Symbol"
                              style={{ filter: 'grayscale(100%)' }}
                            />
                            <img
                              src="/assets/images/disLike.png"
                              alt="DisLike Symbol"
                              style={{ filter: 'grayscale(100%)' }}
                            />
                          </>
                        )}

                        {/* <img
                          src={
                            EditForm.values.isLiked === 1 ? (
                              '/assets/images/Like.png'
                            ) : EditForm.values.isLiked === -1 ? (
                              '/assets/images/disLike.png'
                            ) : (
                              <>
                                <img
                                  src="/assets/images/Like.png"
                                  alt="Like Symbol"
                                  style={{ filter: 'grayscale(100%)' }}
                                />
                                <img
                                  src="/assets/images/disLike.png"
                                  alt="DisLike Symbol"
                                  style={{ filter: 'grayscale(100%)' }}
                                />
                              </>
                            )
                          }
                          alt="like"
                        /> */}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '99%' }}
                      >
                        <Field
                          as={TextField}
                          id="input1"
                          name="review"
                          multiline
                          rows={3}
                          inputProps={{
                            maxlength: 1000,
                          }}
                          label={<Typography sx={{ color: '#B6B6B6' }}>Review</Typography>}
                          helperText={`${EditForm.values.review?.length || 0}/${1000}`}
                          variant="standard"
                          size="small"
                          value={EditForm.values.review}
                          onChange={(e) => {
                            EditForm.handleChange(e);
                            indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid>
                    <input
                      id="contained-button-file"
                      style={{ display: 'none' }}
                      type="file"
                      name="attachment"
                      multiple
                      onChange={(e) => {
                        indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);

                        const uploadedFiles = e?.target?.files;
                        const updatedFiles = [...selectedEditFileName];

                        Object.keys(uploadedFiles).forEach((id) => {
                          const file = uploadedFiles[id];

                          // Check if file size exceeds 2 MB (2,097,152 bytes)
                          // if (file.size > 2097152) {
                          //   // Display an error message or handle the oversized file according to your requirements
                          //   console.log('File size exceeds the limit (2 MB)');
                          //   ErrorToast('File size exceeds the limit (2 MB)');
                          //   return; // Exit from the event handler
                          // }

                          // Rest of the code for processing valid files
                          const duplicateFile = updatedFiles.find((uploadedFile) => uploadedFile.name === file.name);

                          if (duplicateFile) {
                            let counter = 1;
                            let newFileName = `${file.name.substring(
                              0,
                              file.name.lastIndexOf('.')
                            )} (${counter})${file.name.substring(file.name.lastIndexOf('.'))}`;

                            // eslint-disable-next-line no-loop-func
                            while (updatedFiles.some((uploadedFile) => uploadedFile.name === newFileName)) {
                              // eslint-disable-next-line no-plusplus
                              counter++;
                              newFileName = `${file.name.substring(
                                0,
                                file.name.lastIndexOf('.')
                              )} (${counter})${file.name.substring(file.name.lastIndexOf('.'))}`;
                            }
                            const renamedFile = new File([file], newFileName, { type: file.type });
                            updatedFiles.push(renamedFile);
                          } else {
                            updatedFiles.push(file);
                          }
                        });

                        setSelectedEditFileName(updatedFiles);
                        EditForm.setFieldValue('attachment', updatedFiles);
                      }}
                    />

                    <FormLabel
                      htmlFor="contained-button-file"
                      sx={{ display: 'flex', alignItems: 'center', mt: '16px' }}
                    >
                      <Button
                        variant="contained"
                        component="span"
                        sx={{
                          fontWeight: 700,
                          textTransform: 'none',
                          bgcolor: '#fff',
                          color: '#1D1D1F',
                          borderRadius: '30px',
                          boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                          ':hover': { bgcolor: 'transparent' },
                        }}
                      >
                        <Iconify icon="entypo:attachment" color="#3D71FF" width={20} height={20} mr={1} />
                        Attachment
                      </Button>
                    </FormLabel>
                    {selectedEditFileName && (
                      <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap', marginTop: '20px' }}>
                        {selectedEditFileName.map((val, index) => (
                          <FileImageView key={index} value={val} onRemove={() => handleFileRemove(val)} />
                        ))}
                      </div>
                    )}
                  </Grid>
                </form>
              </FormikProvider>
            </Box>
          </Scrollbar>
          <Box sx={{ bottom: '20px' }}>
            <Button
              // startIcon={<CloseIcon />}
              color="error"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                EditForm.setValues(initialValues);
                handleClose();
              }}
              sx={{
                width: '150px',
                height: '45px',
                borderRadius: '30px',
                mt: '25px',
                boxShadow: 'none',
                textTransform: 'capitalize',
              }}
            >
              Cancel
            </Button>
            <Button
              // startIcon={<Iconify icon="mdi:clipboard-text-search" color="white" width={22} height={22} />}
              color="primary"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                setOpenTrackModal(true);
                setEditData(data?.company?.name);
                setOpenEditModal(false);
                setOpenEventModal(false);
              }}
              sx={{
                // width: '150px',
                // height: '45px',
                // backgroundColor: '#B6B6B6',
                // borderRadius: '30px',
                // mt: '25px',
                // ml: 5,
                // boxShadow: 'none',
                width: '150px',
                height: '45px',
                backgroundColor: '#3D71FF',
                borderRadius: '30px',
                mt: '25px',
                ml: 5,
                boxShadow: 'none',
                textTransform: 'capitalize',
              }}
            >
              Changelog
            </Button>
            <Button
              // startIcon={<DeleteIcon />}
              color="primary"
              variant="contained"
              onClick={(e) => {
                e.preventDefault();
                setOpenDelete(true);
                // setSelected([]);
                handledelete(data?._id);
              }}
              sx={{
                backgroundColor: '#3D71FF',
                width: '150px',
                height: '45px',
                borderRadius: '30px',
                mt: '25px',
                ml: 5,
                boxShadow: 'none',
                textTransform: 'capitalize',
              }}
            >
              Delete
            </Button>
            <Button
              // startIcon={<SaveIcon />}
              color="primary"
              variant="contained"
              // type="submit"
              onClick={() => {
                // handleCloseSave()
                EditForm.handleSubmit();
              }}
              disabled={isAttachmentsLengthAreSame ? indata.length < 2 : false}
              // disabled={!EditForm.isValid}
              // disabled={!EditForm.isValid || !EditForm.dirty}
              sx={{
                width: '150px',
                height: '45px',
                backgroundColor: '#3D71FF',
                borderRadius: '30px',
                mt: '25px',
                ml: 5,
                boxShadow: 'none',
                textTransform: 'capitalize',
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <AddCustomLabel
        open={openCustomLabel.open}
        data={openCustomLabel.data}
        onClose={(data) => {
          fetchSubLabel();
          if (data) {
            EditForm.setFieldValue('label', data._id);
            indata.indexOf('label') === -1 && setInData((pre) => [...pre, 'label']);
          }
          setOpenCustomLabel({
            open: false,
            data: null,
          });
        }}
      />
      <AddPaymentInstrumentModal
        open={addPaymentInstrumentModalOpen}
        onClose={onCloseAddPaymentInstrumentModal}
        paymentModeList={paymentModeList}
        paymentCardSchemeList={paymentCardSchemeList}
      />
    </>
  );
};

export default EditModal;

const getNameFromUrl = (urlString) => {
  try {
    const urlSplit1 = urlString.split('/');
    const fileName = urlSplit1.length > 0 ? urlSplit1[urlSplit1.length - 1] : 'attachment';
    const withoutTimeStamp = fileName.split('-');
    if (withoutTimeStamp.length > 0) {
      withoutTimeStamp.splice(0, 1);
      return withoutTimeStamp.join('-');
    }
    return fileName;
  } catch (e) {
    console.log(e);
    return 'attachment';
  }
};

export const FileImageView = ({ value, onRemove }) => {
  const isLocal = value.url === undefined;
  const splitX = isLocal ? value.name.split('.') : value.url.split('.');
  const fileExt = splitX[splitX.length - 1];
  const imageFileExt = ['jpg', 'png', 'gif'];
  const [imageX, setImageX] = useState(value.url || '');
  const localImageUrl = isLocal ? URL.createObjectURL(value) : '';
  const attachmentName = isLocal
    ? value.name
    : value?.url?.includes('safalsubscriptions-assets.s3.amazonaws.com')
    ? value?.url?.match(/[^/-]+$/)[0]
    : value?.url?.split('-').slice(4).join('-');

  const getImage = async () => {
    if (isLocal) return;
    const photoURL = value.url;
    if (photoURL.includes('safalsubscriptions-assets.s3.amazonaws.com')) {
      const newRes = await getSignedURL({ url: photoURL });
      setImageX(newRes.data);
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  const handleS3Download = async (e) => {
    e.preventDefault();
    try {
      const res = await downloadAttachmentFile({ url: value.url });
      if (res.data.success === true) {
        const link = document.createElement('a');
        link.href = res.data.data;
        link.setAttribute('download', `file`);
        document.body.appendChild(link);
        link.click();
      }
    } catch (err) {
      ErrorToast(err.response.data.message || 'Something went wrong !!');
    }
  };

  return imageFileExt.includes(fileExt) ? (
    <div style={{ position: 'relative', zIndex: 30000 }}>
      <Image width={60} height={60} src={isLocal ? localImageUrl : imageX} />
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {!isLocal && (
          <button
            onClick={handleS3Download}
            style={{
              backgroundColor: '#3d71ff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <DownloadIcon style={{ width: '18px', height: '18px', color: 'white' }} />
          </button>
        )}
        {onRemove && (
          <button
            onClick={onRemove}
            style={{
              backgroundColor: '#ff4842',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <DeleteIcon style={{ width: '18px', height: '18px', color: 'white' }} />
          </button>
        )}
      </div>
    </div>
  ) : (
    <div>
      <Tooltip placement="top" title={attachmentName}>
        <span
          style={{
            userSelect: 'none',
            height: '59px',
            width: '59px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            border: '1px solid #3d71ff',
            borderRadius: '2px',
            marginBottom: '7px',
          }}
        >
          {fileExt}
        </span>
      </Tooltip>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {!isLocal && (
          <button
            onClick={handleS3Download}
            style={{
              backgroundColor: '#3d71ff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <DownloadIcon style={{ width: '18px', height: '18px', color: 'white' }} />
          </button>
        )}
        {onRemove && (
          <button
            onClick={onRemove}
            style={{
              backgroundColor: '#ff4842',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '2px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            <DeleteIcon style={{ width: '18px', height: '18px', color: 'white' }} />
          </button>
        )}
      </div>
    </div>
  );
};
