import { Button, IconButton, InputAdornment, Menu, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import DateField from '../../components/UI/DateField';

const PaymentTabFilter = ({ state, onFilterApply, onFilterReset }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [filterState, setFilterState] = useState({
    dateFrom: '',
    dateTo: '',
    amountFrom: '',
    amountTo: '',
    transactionId: '',
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
    setShowApply(false);
  };

  useEffect(() => {
    if (open) {
      setFilterState(state);
    }
  }, [open]);

  const handleFilterApply = () => {
    onFilterApply(filterState);
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterApply({
      dateFrom: '',
      dateTo: '',
      amountFrom: '',
      amountTo: '',
      transactionId: '',
    });
    onFilterReset();
    handleClose();
  };

  // Update resetState whenever the state changes
  useEffect(() => {
    setShowApply(isThisResetState);
  }, [state]);

  const isThisResetState =
    state?.dateFrom !== '' ||
    state?.dateTo !== '' ||
    state?.amountFrom !== '' ||
    state.amountTo !== '' ||
    state.transactionId !== '';

  // State to store the boolean value
  const [showApply, setShowApply] = useState(isThisResetState);

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '250px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        {/* dateFrom */}
        <DateField
          value={filterState?.dateFrom}
          focused={filterState?.dateFrom}
          onChange={(date) => onChangeField(`${date.format('YYYY-MM-DD')}T00:01:00.000Z`, 'dateFrom')}
          name="dateFrom"
          label="Payment Date from"
          variant="outlined"
        />
        {/* dateTo */}
        <DateField
          value={filterState?.dateTo}
          focused={filterState?.dateTo}
          onChange={(date) => onChangeField(`${date.format('YYYY-MM-DD')}T00:01:00.000Z`, 'dateTo')}
          name="dateTo"
          label="Payment Date to"
          variant="outlined"
        />
        {/* amountFrom */}
        <TextField
          sx={{
            my: '8px',
          }}
          value={filterState?.amountFrom}
          focused={filterState?.amountFrom}
          onChange={(e) => onChangeField(e.target.value, 'amountFrom')}
          type="number"
          name="amountFrom"
          label="Amount from"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{login.user.country === 'India' ? '₹' : '$'}</InputAdornment>
            ),
          }}
        />
        {/* amountTo */}
        <TextField
          sx={{
            my: '8px',
          }}
          value={filterState?.amountTo}
          focused={filterState?.amountTo}
          onChange={(e) => onChangeField(e.target.value, 'amountTo')}
          type="number"
          name="amountTo"
          label="Amount to"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{login.user.country === 'India' ? '₹' : '$'}</InputAdornment>
            ),
          }}
        />
        {/* transactionId */}
        <TextField
          sx={{
            my: '8px',
          }}
          value={filterState?.transactionId}
          onChange={(e) => onChangeField(e.target.value, 'transactionId')}
          type="text"
          name="transactionId"
          label="Invoice/receipt search"
          size="small"
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default PaymentTabFilter;
