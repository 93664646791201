import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userChatList: [],
  chatMessages: [],
};

export const newChatSlice = createSlice({
  name: 'newChatSlice',
  initialState,
  reducers: {
    setUserChatList: (state, action) => {
      const chatMessages = Object.keys(action.payload).map((e) => ({
        chatId: e,
        messages: [],
        typing: false,
      }));
      state = {
        ...state,
        chatMessages: chatMessages,
        userChatList: action.payload,
      };
      return state;
    },
    resetNewChat: () => {
      return initialState;
    },
  },
});

export const { setUserChatList, resetNewChat } = newChatSlice.actions;

export default newChatSlice.reducer;
