import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Badge,
  Tooltip,
  Typography,
  ListItemAvatar,
} from '@mui/material';
import moment from 'moment/moment';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { getNotifications } from 'src/store/loginSlice';
import MenuPopover from '../../components/MenuPopover';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import FaqModal from '../../components/Modals/FaqModal';

const NotificationsPopover = ({ onClose }) => {
  const { notification } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const nav = useNavigate();
  const [open, setOpen] = useState(null);

  // notification is an object inside object
  const notificationArray = Object.keys(notification).map((e) => notification[e]);
  const notificationFilterArray = notificationArray.filter((e) => !e.isRead);
  const notificationCount = notificationFilterArray.length;

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getNotifications());
    }, 1000 * 300);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Tooltip title={`Notifications`}>
        <Badge badgeContent={notificationCount} max={99} color="success">
          <Avatar
            onClick={handleOpen}
            ref={anchorRef}
            sx={{ width: 25, height: 25, color: 'black', backgroundColor: '#FFFF', cursor: 'pointer' }}
          >
            <NotificationsNoneIcon sx={{ width: 18, height: 18 }} />
          </Avatar>
        </Badge>
      </Tooltip>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 2, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
              Notifications <FaqModal module="Settings - Notification" color="#3D71FF" />
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <List disablePadding sx={{ overflow: 'hidden', height: 400 }}>
          <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
            {notificationFilterArray &&
              notificationFilterArray.map((val, i) => (
                <ListItemButton
                  key={i}
                  onClick={() => {
                    navigate('/notifications');
                    handleClose();
                    onClose && onClose();
                  }}
                  sx={{
                    py: 1.5,
                    px: 2.5,
                    mt: '1px',
                    // bgcolor: 'action.selected',
                  }}
                >
                  {val?.logo && (
                    <ListItemAvatar>
                      <Avatar>
                        <img src={val?.logo} alt="Company Logo" />
                      </Avatar>
                    </ListItemAvatar>
                  )}
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2">
                        {val.message}
                        {/* <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
                          &nbsp; {`noCase(notification.description)`}
                        </Typography> */}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        sx={{
                          mt: 0.5,
                          display: 'flex',
                          alignItems: 'center',
                          color: 'text.disabled',
                        }}
                      >
                        <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                        {moment(val.createdAt).format(
                          notification.country === 'India' ? 'Do MMMM YYYY, hh:mm A' : 'MMMM Do YYYY, hh:mm A'
                        )}
                      </Typography>
                    }
                  />
                </ListItemButton>
              ))}
          </Scrollbar>
        </List>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => {
              handleClose();
              onClose && onClose();
              if (pathname !== '/notifications') nav('/notifications');
            }}
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

export default NotificationsPopover;
