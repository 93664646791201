import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, Switch, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import {
  createSafalBuddyActivitiesMasterConfig,
  updateSafalBuddyActivitiesMasterConfig,
} from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  application: '', // application id
  tier: 'All', // tier number
  activityName: '',
  activityKey: '',
  message: '',
  shareToMyBuddy: false,
  receiveBuddiesActivity: false,
  status: true,
  notification: {
    email: false,
    text: false,
    mobileApp: false,
    notification: false,
    banner: false,
  },
};

export default function AddSafalBuddyActivitiesMasterConfigModal({
  open,
  onClose,
  data,
  isDisplay,
  planList,
  applicationsList,
}) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      if (fieldValue.tier === 'All') {
        const mapX = planList?.map(async (e) => {
          const payload = { ...fieldValue, tier: e?.tier };
          await createSafalBuddyActivitiesMasterConfig(payload);
        });
        await Promise.allSettled(mapX);
        toast.success('created successfully');
        handleClose();
      } else {
        const res = await createSafalBuddyActivitiesMasterConfig(fieldValue);
        if (res.status === 200) {
          toast.success(res.data.message);
          handleClose();
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const res = await updateSafalBuddyActivitiesMasterConfig({ ...data, ...fieldValue });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        application: data?.application !== undefined ? data?.application?._id : '',
        tier: data?.tier !== undefined ? data?.tier : 'All',
        activityName: data?.activityName !== undefined ? data?.activityName : '',
        message: data?.message !== undefined ? data?.message : '',
        activityKey: data?.activityKey !== undefined ? data?.activityKey : '',
        shareToMyBuddy: data?.shareToMyBuddy !== undefined ? data?.shareToMyBuddy : false,
        receiveBuddiesActivity: data?.receiveBuddiesActivity !== undefined ? data?.receiveBuddiesActivity : false,
        status: data?.status !== undefined ? data?.status : true,
        notification:
          data?.notification !== undefined
            ? data?.notification
            : {
                email: false,
                text: false,
                mobileApp: false,
                notification: false,
                banner: false,
              },
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null
            ? 'Add Activities Master Config'
            : isDisplay
            ? 'SafalBuddy Activities Master Config'
            : 'Update Activities Master Config'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* application */}
          <TextField
            value={fieldValue.application}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, application: e.target.value }))}
            required
            select
            label="Application Name"
            size="small"
            disabled={data !== null ? true : isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {applicationsList?.map((e, index) => (
              <MenuItem key={index} value={e?._id}>
                {e?.name}
              </MenuItem>
            ))}
          </TextField>
          {/* tier */}
          <TextField
            value={fieldValue.tier}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, tier: e.target.value }))}
            required
            select
            label="Plan Tier"
            size="small"
            disabled={data !== null ? true : isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {planList?.map((e, index) => (
              <MenuItem key={index} value={e.tier}>
                Tier {e.tier} : {e.name}
              </MenuItem>
            ))}
            {data === null && <MenuItem value="All">All Tier</MenuItem>}
          </TextField>
          {/* activityName */}
          <TextField
            value={fieldValue.activityName}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, activityName: e.target.value }))}
            required
            label="Activity Name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* activityKey */}
          <TextField
            value={fieldValue.activityKey}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, activityKey: e.target.value }))}
            required
            label="Activity Key"
            size="small"
            type="text"
            fullWidth
            disabled={data !== null ? true : isDisableAll}
            variant="outlined"
          />
          {/* message */}
          <TextField
            value={fieldValue.message}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, message: e.target.value }))}
            required
            label="Message"
            size="small"
            type="text"
            fullWidth
            helperText="[[USER]] replace with user name, [[NAME]] replace with Subscription or Asset Name"
            disabled={isDisableAll}
            variant="outlined"
          />
          {/* shareToMyBuddy */}
          <TextField
            value={fieldValue.shareToMyBuddy === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, shareToMyBuddy: e.target.value === 'Active' }))}
            required
            select
            label="Share To My Buddy"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
          {/* receiveBuddiesActivity */}
          <TextField
            value={fieldValue.receiveBuddiesActivity === true ? 'Active' : 'Inactive'}
            onChange={(e) =>
              setFieldValue((prev) => ({ ...prev, receiveBuddiesActivity: e.target.value === 'Active' }))
            }
            required
            select
            label="Receive Buddies Activity"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
          {/* status */}
          <TextField
            value={fieldValue.status === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
          {/* notification */}
          <div>
            <Typography variant="h4" gutterBottom sx={{ fontSize: '20px', fontWeight: 700, color: '#19c25f' }}>
              Notification Config
            </Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {/* email */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  style={{ color: '#19c25f' }}
                  disabled={isDisableAll}
                  checked={fieldValue?.notification?.email}
                  onChange={() =>
                    setFieldValue((prev) => {
                      const newObj = { ...prev };
                      newObj.notification.email = !newObj.notification.email;
                      return newObj;
                    })
                  }
                />
                <span>Email</span>
              </div>
              {/* text */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  style={{ color: '#19c25f' }}
                  disabled={isDisableAll}
                  checked={fieldValue?.notification?.text}
                  onChange={() =>
                    setFieldValue((prev) => {
                      const newObj = { ...prev };
                      newObj.notification.text = !newObj.notification.text;
                      return newObj;
                    })
                  }
                />
                <span>Text</span>
              </div>
              {/* mobileApp */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  style={{ color: '#19c25f' }}
                  disabled={isDisableAll}
                  checked={fieldValue?.notification?.mobileApp}
                  onChange={() =>
                    setFieldValue((prev) => {
                      const newObj = { ...prev };
                      newObj.notification.mobileApp = !newObj.notification.mobileApp;
                      return newObj;
                    })
                  }
                />
                <span>Mobile App</span>
              </div>
              {/* notification */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  style={{ color: '#19c25f' }}
                  disabled={isDisableAll}
                  checked={fieldValue?.notification?.notification}
                  onChange={() =>
                    setFieldValue((prev) => {
                      const newObj = { ...prev };
                      newObj.notification.notification = !newObj.notification.notification;
                      return newObj;
                    })
                  }
                />
                <span>Notification Screen</span>
              </div>
              {/* banner */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switch
                  style={{ color: '#19c25f' }}
                  disabled={isDisableAll}
                  checked={fieldValue?.notification?.banner}
                  onChange={() =>
                    setFieldValue((prev) => {
                      const newObj = { ...prev };
                      newObj.notification.banner = !newObj.notification.banner;
                      return newObj;
                    })
                  }
                />
                <span>Notification Banner</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
