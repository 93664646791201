import { Autocomplete, Button, Popper, TextField } from '@mui/material';
import { useState } from 'react';

const CustomMuiSelect = ({
  required,
  variant,
  size,
  sx,
  value,
  onChange,
  options,
  label,
  error,
  helperText,
  disabled,
  title,
  disablePortal,
  focused,
  isOptionEqualToValue,
  className,
  onAddNew,
  placeholder,
  groupBy,
}) => {
  const _disablePortal = disablePortal === undefined ? true : disablePortal;
  const [open, setOpen] = useState(false);

  const CustomPopper = (props) => {
    return (
      <Popper {...props} style={{ width: 300 }}>
        {props.children}
        {open && (
          <Button
            variant="contained"
            onMouseDown={() => {
              props?.onAddNew();
              setOpen(false);
            }}
            size="small"
            style={{ margin: '10px', textTransform: 'capitalize', borderRadius: '30px' }}
          >
            Add New
          </Button>
        )}
      </Popper>
    );
  };

  return (
    <Autocomplete
      isOptionEqualToValue={isOptionEqualToValue}
      className={className}
      title={title}
      variant={variant} // standard
      size={size} // small
      disablePortal={_disablePortal}
      value={value}
      disabled={disabled}
      onChange={(event, newValue) => {
        onChange && onChange(newValue);
      }}
      options={options || []}
      sx={sx}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      focused={focused}
      groupBy={groupBy}
      PopperComponent={onAddNew && ((props) => <CustomPopper {...props} onAddNew={onAddNew} />)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          focused={focused}
          title={title}
          required={required}
          size={size}
          variant={variant}
          error={error}
          helperText={helperText}
          label={label}
        />
      )}
    />
  );
};

export default CustomMuiSelect;
