import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { getUserToggleSetting, getUserToggleSettingBusiness, updateUserToggleSetting } from '../../services/Service';
import ErrorToast from '../../components/Toast/Error';

const SecurityTabPermissions = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    userId: false,
    email: false,
    phoneNumber: false,
    tier: false,
    activeSubscriptionsCount: false,
    activeLoyaltyCount: false,
    activeGiftCount: false,
    activeRewardsCount: false,
    safalBuddiesCount: false,
    mutualSafalBuddiesCount: false,
    roomFeedsCount: false,
    currentMonthLeaderboardPosition: false,
    lastLoginDateTime: false,
  });
  const [dataBusiness, setDataBusiness] = useState({
    userId: false,
    email: false,
    phoneNumber: false,
    tier: false,
    activeSubscriptionsCount: false,
    activeLoyaltyCount: false,
    activeGiftCount: false,
    activeRewardsCount: false,
    safalBuddiesCount: false,
    mutualSafalBuddiesCount: false,
    roomFeedsCount: false,
    currentMonthLeaderboardPosition: false,
    lastLoginDateTime: false,
  });

  // updateUserToggleSetting
  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getUserToggleSetting();
      const resBusiness = await getUserToggleSettingBusiness();
      if (resBusiness.status === 200) {
        setDataBusiness(resBusiness.data.data);
      }
      if (res.status === 200) {
        setData(res.data.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = async (e) => {
    if (dataBusiness[e.target.name] === false) {
      ErrorToast(
        'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
      );
      return;
    }

    const payload = { ...data, [e.target.name]: e.target.checked };
    setLoading(true);
    try {
      const res = await updateUserToggleSetting(payload);
      if (res.status === 200) {
        fetchData();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div style={{ maxWidth: '600px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.userId}
            name="userId"
            title="User Id"
            subTitle="Allow the user ID visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.email}
            name="email"
            title="User Email"
            subTitle="Allow the email visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.phoneNumber}
            name="phoneNumber"
            title="User Phone Number"
            subTitle="Allow the phone number visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.tier}
            name="tier"
            title="User Type (Tier)"
            subTitle="Allow the type (tier) visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.activeSubscriptionsCount}
            name="activeSubscriptionsCount"
            title="Number of Active Subscriptions"
            subTitle="Allow the subscriptions count visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.activeLoyaltyCount}
            name="activeLoyaltyCount"
            title="Number of Active Loyalty Cards"
            subTitle="Allow the loyalty card count visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.activeGiftCount}
            name="activeGiftCount"
            title="Number of Active Gift Cards"
            subTitle="Allow the gift card count visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.activeRewardsCount}
            name="activeRewardsCount"
            title="Number of Active Reward Certificates"
            subTitle="Allow the reward certificates count visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.safalBuddiesCount}
            name="safalBuddiesCount"
            title="Number of SafalBuddies"
            subTitle="Allow the safalBuddies count visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.mutualSafalBuddiesCount}
            name="mutualSafalBuddiesCount"
            title="Number of Mutual SafalBuddies"
            subTitle="Allow the mutual safalBuddies count visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.roomFeedsCount}
            name="roomFeedsCount"
            title="Number of Feeds in Room"
            subTitle="Allow the feeds count visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.currentMonthLeaderboardPosition}
            name="currentMonthLeaderboardPosition"
            title="Current leaderboard position"
            subTitle="Allow the current month leaderboard position visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.lastLoginDateTime}
            name="lastLoginDateTime"
            title="Last Login Activity"
            subTitle="Allow the last login activity visible to other users"
          />
        </div>
      </div>
    </>
  );
};

const ToggleItem = ({ title, subTitle, checked, onChange, disabled, name }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</span>
        <span style={{ fontSize: '12px' }}>{subTitle}</span>
      </div>
      <Switch
        disabled={disabled}
        name={name}
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </div>
  );
};

export default SecurityTabPermissions;
