import { InputAdornment, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const CustomSearch = ({ onChange, value, placeholder, sx }) => {
  return (
    <SearchStyle
      sx={sx}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon sx={{ color: '#3D71FF', width: 20, height: 20 }} />
        </InputAdornment>
      }
    />
  );
};

export default CustomSearch;
