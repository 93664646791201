import { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';

import { deleteSafalGiftAddress, getSafalGiftAddress } from '../../../services/Service';
import { usStates, indiaStates } from '../../../utils/stateList';
import AddSafalGiftAddressModal from '../../../pages/user/SafalGiftPage/AddSafalGiftAddressModal';
import Iconify from '../../../components/Iconify';

const ProfileAddressTab = () => {
  const [userAddressList, setUserAddressList] = useState([]);

  const [orderAddressModal, setOrderAddressModal] = useState({
    open: false,
    data: null,
  });

  const onOrderAddressOpen = () => {
    setOrderAddressModal({
      open: true,
      data: null,
    });
  };
  const onOrderAddressOpenEdit = (data) => {
    setOrderAddressModal({
      open: true,
      data: data,
    });
  };

  const onOrderAddressClose = () => {
    fetchAddress();
    setOrderAddressModal({
      open: false,
      data: null,
    });
  };

  const fetchAddress = async () => {
    try {
      const res = await getSafalGiftAddress();
      if (res.status === 200) {
        setUserAddressList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onDeleteAddress = async (data) => {
    try {
      const res = await deleteSafalGiftAddress({ id: data?._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchAddress();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div />
          <div>
            {/* add */}
            <Tooltip title="Add new address">
              <IconButton
                onClick={() => onOrderAddressOpen()}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#3D71FF',
                  height: '40px',
                  width: '40px',
                  '&:hover': { backgroundColor: '#3D71FF' },
                }}
              >
                <AddIcon sx={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', height: '400px', overflow: 'auto' }}>
          {userAddressList.length < 0 ? (
            <div
              style={{
                height: '150px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>No Address Found.</span>
            </div>
          ) : (
            userAddressList?.map((e) => (
              <AddressBox data={e} onEdit={() => onOrderAddressOpenEdit(e)} onDelete={() => onDeleteAddress(e)} />
            ))
          )}
        </div>
      </div>
      <AddSafalGiftAddressModal
        open={orderAddressModal.open}
        data={orderAddressModal.data}
        onClose={onOrderAddressClose}
      />
    </>
  );
};

export default ProfileAddressTab;

const AddressBox = ({ data, onEdit, onDelete }) => {
  const findState = usStates?.find((e) => e.abbreviation === data?.state);
  return (
    <div
      style={{
        display: 'flex',
        gap: '12px',
        border: '1px solid grey',
        borderRadius: '8px',
        padding: '8px',
        width: '100%',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 'normal',
        }}
      >
        <span>Name : {data?.name}</span>
        <span>Address line 1 : {data?.address1}</span>
        <span>Address line 2 : {data?.address2}</span>
        <span>Phone Number : {data?.phoneNumber}</span>
        <span>Landmark : {data?.landmark}</span>
        <span>State : {findState?.name}</span>
        <span>Zip : {data?.zip}</span>
      </div>
      <div style={{ display: 'flex', gap: '12px', alignItems: 'center', flex: 'none' }}>
        <Tooltip title={'Edit'}>
          <IconButton onClick={onEdit}>
            <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Delete'}>
          <IconButton onClick={onDelete}>
            <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};
