import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { addAssetModuleCard, getAssetModuleCompany } from '../../../services/Service';

const sortCompareFn = (a, b) => {
  const nameA = a.toUpperCase(); // Convert names to uppercase
  const nameB = b.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  type: 'loyalty',
  companyId: '',
  description: '',
};

export default function AddAssetModuleModal({ open, onClose }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [rawCompanyData, setRawCompanyData] = React.useState([]);
  const [error, setError] = useState(false);
  const [companyError, setCompanyError] = useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    onClose();
  };

  const fetchCompanyData = async () => {
    try {
      const res = await getAssetModuleCompany();
      if (res.status === 200) {
        setRawCompanyData(res.data.data);
      }
    } catch (err) {
      console.log('fetchCompanyData error', err.message);
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    if (open) fetchCompanyData();
  }, [open]);

  const onChangeField = (e) => {
    console.log('e.target.name--->', e.target.name);
    if (e.target.name === 'name') {
      setError(false);
    } else if (e.target.name === 'companyId') {
      setCompanyError(false);
    }

    setFieldValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = async () => {
    if (fieldValue.name.trim().length < 3 || fieldValue.name.length > 100) {
      setError(true);
      return;
    }

    if (!fieldValue.companyId) {
      setCompanyError(true);
      return;
    }

    try {
      const res = await addAssetModuleCard(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          Add Asset Module
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <TextField
            value={fieldValue.name}
            onChange={onChangeField}
            required
            label="Card Name"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            error={error}
            helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
            inputProps={{
              minLength: 3,
              maxLength: 100,
            }}
          />
          <TextField
            value={fieldValue.companyId}
            onChange={onChangeField}
            select
            required
            label="Company"
            name="companyId"
            size="small"
            fullWidth
            defaultValue="usa"
            variant="outlined"
            error={companyError}
            helperText={companyError ? 'Please select a company' : ''}
          >
            {[...rawCompanyData]
              .sort((a, b) => sortCompareFn(a.name, b.name))
              .map((e, index) => (
                <MenuItem key={index} value={e._id}>
                  {e.name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            value={fieldValue.type}
            onChange={onChangeField}
            required
            select
            label="Type"
            name="type"
            size="small"
            fullWidth
            defaultValue="loyalty"
            variant="outlined"
          >
            <MenuItem value={'loyalty'}>Loyalty Card</MenuItem>
            <MenuItem value={'gift'}>Gift Card</MenuItem>
            <MenuItem value={'reward'}>Reward certificates</MenuItem>
          </TextField>
          <TextField
            value={fieldValue.description}
            onChange={onChangeField}
            label="Description"
            name="description"
            size="small"
            type="text"
            fullWidth
            multiline
            maxRows={3}
            minRows={2}
            variant="outlined"
            // error={error}
            // helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
            inputProps={{
              minLength: 3,
              maxLength: 500,
            }}
          />
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button onClick={handleClose} sx={{ backgroundColor: '#19c25f' }} variant="contained">
            Cancel
          </Button>
          <Button onClick={onSubmit} sx={{ backgroundColor: '#19c25f' }} variant="contained">
            Create
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
