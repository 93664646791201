import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getGeneralKeyValueList } from '../../services/Service';

const TemplatesTab = () => {
  const [fileLink, setFileLink] = useState('');
  const user = useSelector((state) => state.login.user);

  const fetchData = async () => {
    try {
      const res = await getGeneralKeyValueList();
      if (user?.country === 'United States') {
        const findFile = res?.data?.data?.find((ex) => ex?.flag === 'SUBSCRIPTIONS-BULK-UPLOAD-TEMPLATE-USA');
        if (findFile) setFileLink(findFile?.data);
      } else {
        const findFile = res?.data?.data?.find((ex) => ex?.flag === 'SUBSCRIPTIONS-BULK-UPLOAD-TEMPLATE-IND');
        if (findFile) setFileLink(findFile?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      This is template for bulk Upload for your subscriptions.
      <br />
      Please find following CSV template for reference, Download from following link :
      <br />
      <a href={fileLink} download>
        Download CSV Template
      </a>
    </div>
  );
};

export default TemplatesTab;
