/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { Icon } from '@iconify/react';
import downloadJS from 'downloadjs';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IconButton, Tooltip } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  setDoc,
  updateDoc,
  getDoc,
  collectionGroup,
  getFirestore,
  query,
  where,
  deleteDoc,
  writeBatch,
  serverTimestamp,
} from 'firebase/firestore';
import { db, auth } from '../../firebase';
// eslint-disable-next-line import/no-useless-path-segments
import Iconify from '../../components/Iconify';
import dIcon from '../../assets/d-icon.png';
import pdf from '../../assets/pdf.png';
import download from '../../assets/download_icon.png';
import ppt from '../../assets/ppt.png';
import docs from '../../assets/doc.png';
import { deleteAttachmentByURL, getSignedURL } from '../../services/Service';
import NewMedia from './NewMedia';
import DisplayAvatar from './DisplayAvatar';

const Message = ({ message, dateRef, isLast, messageIntervalTime }) => {
  const { currentUser } = useSelector((state) => state.auth);
  const { chats } = useSelector((state) => state);
  const { user, chatMode, chatId, sid } = useSelector((state) => state.chats);
  const { user: meUser } = useSelector((state) => state.login);
  const chatRef = useRef();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [receiverOnline, setReceiverOnline] = useState('');
  const [seen, setSeen] = useState('');
  // TODO : This can be change feature
  const isDeleteButtonVisible = message?.messageType !== 'text' || chatMode === 'user';

  const handleSentReceive = async () => {
    try {
      const receiver = await getDoc(doc(db, 'online', sid));
      setReceiverOnline(receiver?.data()?.id);
    } catch (e) {
      // console.log('');
    }
  };

  const supportMessageRightSide = meUser.role === 'business' && message?.senderRole === 'business';

  useEffect(() => {
    handleSentReceive();
  }, []);

  const handleSeen = async () => {
    try {
      const receiver = await getDoc(doc(db, 'notificationsdot', sid));
      setSeen(receiver?.data()?.count);
    } catch (e) {
      // console.log('');
    }
  };

  const msgSeen = seen === undefined;

  useEffect(() => {
    handleSeen();
  }, []);

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };
  useEffect(() => {
    const scrollDown = {
      block: 'end',
      behavior: 'smooth',
    };

    chatRef.current?.scrollIntoView(chats.chatMode && scrollDown);
  }, [message, chats.chatMode]);

  const deleteMsg = async () => {
    const msgIdToDelete = message?.id;

    getDoc(doc(db, 'chats', chatId))
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const chatData = docSnapshot.data();
          const messages = chatData.messages || [];
          let lastMessage;
          const updatedMessages = messages.map((msg) => {
            if (msg.id === msgIdToDelete) {
              lastMessage = {
                ...msg,
                messageType: 'deleted',
                text: 'This message has been deleted',
              };
              return {
                ...msg,
                messageType: 'deleted',
                text: 'This message has been deleted',
              };
            }
            return msg;
          });

          updateDoc(doc(db, 'chats', chatId), { messages: updatedMessages })
            .then(async () => {
              // console.log(`Message ${msgIdToDelete} deleted successfully from chat ${chatId}`);
              if (isLast && lastMessage) {
                await updateDoc(doc(db, `${chatMode}Chats`, currentUser?.uid), {
                  [`${chats.chatId}.lastMessage`]: lastMessage,
                  [`${chats.chatId}.date`]: serverTimestamp(),
                });

                await updateDoc(doc(db, `${chatMode}Chats`, sid), {
                  [`${chats.chatId}.lastMessage`]: lastMessage,
                  [`${chats.chatId}.date`]: serverTimestamp(),
                });
              }
            })
            .catch((error) => {
              console.error(`Error deleting message ${msgIdToDelete} from chat ${chatId}:`, error);
            });
        } else {
          console.log(`Chat ${chatId} does not exist!`);
        }
      })
      .catch((error) => {
        console.error(`Error getting chat ${chatId}:`, error);
      });
  };

  const deleteObj = async (url) => {
    // deleteMsg
    await deleteAttachmentByURL(url);
    deleteMsg();
  };

  return message.messageType === 'missed' ? (
    // <MissedCall message={message} dateRef={dateRef} />
    ''
  ) : (
    <>
      <p className="chatInfo" style={{ marginTop: '4px', marginBottom: '4px' }}>
        {messageIntervalTime}
      </p>
      <div
        className={`message-container ${
          supportMessageRightSide ? 'sent' : message?.senderId === currentUser?.uid ? 'sent' : 'recieve'
        }`}
        ref={chatRef}
        style={{ padding: '0px 20px' }}
      >
        {message.messageType === 'missed' ? (
          ''
        ) : (
          <DisplayAvatar uid={message?.senderId} isSupport={message?.senderRole === 'business'} />
        )}

        <div className="message">
          {message.messageType === 'deleted' ? (
            <>
              <div>
                <div className={`messageContent ${message?.senderId === currentUser?.uid ? 'sent' : 'recieve'}`}>
                  {message?.text === 'This message has been deleted' ? (
                    <p style={{ color: '#cbc8c8', fontStyle: 'italic' }}>
                      <BlockIcon style={{ marginBottom: '-6px' }} />
                      This message has been deleted
                    </p>
                  ) : (
                    <p className="text" style={{ whiteSpace: 'pre' }}>
                      {message.text}
                    </p>
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span
                    style={{ paddingLeft: '6px', paddingRight: '6px' }}
                    ref={dateRef}
                    name={moment(new Date(message?.date?.seconds * 1000)).format('dddd')}
                    className="time"
                  >
                    {moment
                      .tz(
                        new Date(message?.date?.seconds * 1000),
                        user.country === 'India' ? 'Asia/Kolkata' : 'America/New_York'
                      )
                      .format('LT')}
                  </span>
                  {/* <div>{message?.senderId === currentUser?.uid ? <div>{renderTick()}</div> : null}</div> */}
                </div>
              </div>
            </>
          ) : message?.url ? (
            <>
              <div className={`new-media-css ${message?.senderId !== currentUser?.uid && 'flex-start'}`}>
                <p className="person" style={{ display: 'flex', alignItems: 'center' }}>
                  {supportMessageRightSide
                    ? 'Safal subscriptions Team'
                    : message?.senderId === currentUser?.uid
                    ? currentUser?.displayName
                    : chats?.user?.displayName}
                  {isDeleteButtonVisible && message?.senderId === currentUser?.uid && (
                    <Iconify
                      onClick={deleteMsg}
                      onMouseOver={({ target }) => {
                        target.style.color = 'red';
                        target.style.cursor = 'pointer';
                      }}
                      onMouseOut={({ target }) => {
                        target.style.color = 'black';
                        target.style.cursor = 'normal';
                      }}
                      icon="ic:baseline-delete"
                      width={20}
                      height={20}
                    />
                  )}
                </p>
                <NewMedia message={message} self={message?.senderId === currentUser?.uid} />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span
                    ref={dateRef}
                    name={moment(new Date(message?.date?.seconds * 1000)).format('dddd')}
                    className="time"
                    style={{ paddingLeft: '6px', paddingRight: '6px' }}
                  >
                    {moment
                      .tz(
                        new Date(message?.date?.seconds * 1000),
                        user.country === 'India' ? 'Asia/Kolkata' : 'America/New_York'
                      )
                      .format('LT')}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <div className={`messageContent ${message?.senderId === currentUser?.uid ? 'sent' : 'recieve'}`}>
                  <p className="person">
                    {supportMessageRightSide
                      ? 'Safal subscriptions Team'
                      : message?.senderId === currentUser?.uid
                      ? currentUser?.displayName
                      : chats?.user?.displayName}
                    {isDeleteButtonVisible && message?.senderId === currentUser?.uid && (
                      <Iconify
                        onClick={deleteMsg}
                        onMouseOver={({ target }) => {
                          target.style.color = 'red';
                          target.style.cursor = 'pointer';
                        }}
                        onMouseOut={({ target }) => {
                          target.style.color = 'white';
                          target.style.cursor = 'normal';
                        }}
                        icon="ic:baseline-delete"
                        width={20}
                        height={20}
                      />
                    )}
                  </p>
                  {message?.text === 'This message has been deleted' ? (
                    <p style={{ color: '#cbc8c8', fontStyle: 'italic' }}>
                      <BlockIcon style={{ marginBottom: '-6px' }} />
                      This message has been deleted
                    </p>
                  ) : (
                    <p className="text" style={{ whiteSpace: 'pre' }}>
                      {message.text}
                    </p>
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <span
                    ref={dateRef}
                    name={moment(new Date(message?.date?.seconds * 1000)).format('dddd')}
                    className="time"
                    style={{ paddingLeft: '6px', paddingRight: '6px' }}
                  >
                    {moment
                      .tz(
                        new Date(message?.date?.seconds * 1000),
                        user.country === 'India' ? 'Asia/Kolkata' : 'America/New_York'
                      )
                      .format('LT')}
                  </span>
                  {/* <div>{message?.senderId === currentUser?.uid ? <div>{renderTick()}</div> : null}</div> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Message;
