import { useState } from 'react';
import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, CardContent, Stack, TextField, Typography, IconButton, CardHeader } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SuccessToast from '../../../components/Toast/Success';
import ErrorToast from '../../../components/Toast/Error';
import { getUserIdbyEmail, forgotUserID } from '../../../services/Service';
import { setTempUserID } from '../../../store/AuthSlice';

// ----------------------------------------------------------------------

export default function ForgetUserIdForm() {
  const [isUserId, setUserId] = useState(null);
  const [response, setResponse] = useState('');
  const navigate = useNavigate();
  const ForgetUserIdSchema = Yup.object().shape({
    firstname: Yup.string().required('Firstname is required'),
    lastname: Yup.string().required('Lastname is required'),
    email: Yup.string().email('Primary Email must be a valid email address').required('Primary Email is required'),
  });

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
  };

  const ForgetUserIdFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: ForgetUserIdSchema,
    onSubmit: async (values) => {
      //   const userId = await getUserIdbyEmail(values);
      //   if (userId?.data?.success === true) {
      //     setUserId(userId.data.data.userID);
      //   } else {
      //     ErrorToast('User Not Found!');
      //   }
      // },

      // getUserIdbyEmail(values)
      forgotUserID(values)
        .then((res) => {
          console.log('thos => ', res.data);
          // setUserId(res.data.data.userID);
          setResponse(res.data.message);
          SuccessToast(res.data.message);
        })
        .catch((err) => {
          ErrorToast('User Not Found!');
          setResponse('Sorry! User Not Found.');
        });
    },
  });

  return (
    <>
      <FormikProvider value={ForgetUserIdFormik}>
        <form onSubmit={ForgetUserIdFormik.handleSubmit}>
          {/* {isUserId ? ( */}
          {response ? (
            <>
              <Card sx={{ maxWidth: 345, mt: 5 }}>
                <CardContent sx={{ textAlign: 'center' }}>
                  {/* <Typography variant="h2" sx={{ fontSize: '16px ', fontWeight: 300, color: '#1D1D1F' }} mb={2}>
                    Following is your UserID :
                  </Typography>
                  <Typography variant="h2" sx={{ fontSize: '20px ', fontWeight: 700, color: '#1D1D1F' }} mb={2}>
                    {isUserId}
                  </Typography> */}
                  <Typography variant="h2" sx={{ fontSize: '16px ', fontWeight: 300, color: '#1D1D1F' }} mb={2}>
                    {response}
                  </Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      // setTempUserID(isUserId);
                      // localStorage.setItem('tempUserID', isUserId);
                      navigate('/login');
                    }}
                    sx={{
                      width: '200px',
                      borderRadius: '30px',
                      textTransform: 'none',
                      backgroundColor: '#3D71FF',
                      // mt: '25px',
                    }}
                  >
                    Login
                  </Button>{' '}
                </CardContent>
              </Card>
            </>
          ) : (
            <>
              {' '}
              <Stack spacing={3}>
                <Field
                  as={TextField}
                  variant="standard"
                  name="firstname"
                  label="First Name"
                  onChange={(e) => {
                    ForgetUserIdFormik.handleChange(e);
                    setUserId(null);
                  }}
                  value={ForgetUserIdFormik.values.firstname}
                  error={ForgetUserIdFormik.touched.firstname && Boolean(ForgetUserIdFormik.errors.firstname)}
                  helperText={ForgetUserIdFormik.touched.firstname && ForgetUserIdFormik.errors.firstname}
                />
                <Field
                  as={TextField}
                  variant="standard"
                  name="lastname"
                  label="Last Name"
                  onChange={(e) => {
                    ForgetUserIdFormik.handleChange(e);
                    setUserId(null);
                  }}
                  value={ForgetUserIdFormik.values.lastname}
                  error={ForgetUserIdFormik.touched.lastname && Boolean(ForgetUserIdFormik.errors.lastname)}
                  helperText={ForgetUserIdFormik.touched.lastname && ForgetUserIdFormik.errors.lastname}
                />
                <Field
                  as={TextField}
                  variant="standard"
                  name="email"
                  label="Primary Email Address"
                  onChange={(e) => {
                    ForgetUserIdFormik.handleChange(e);
                    setUserId(null);
                  }}
                  value={ForgetUserIdFormik.values.email}
                  error={ForgetUserIdFormik.touched.email && Boolean(ForgetUserIdFormik.errors.email)}
                  helperText={ForgetUserIdFormik.touched.email && ForgetUserIdFormik.errors.email}
                />
              </Stack>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!(ForgetUserIdFormik.isValid && ForgetUserIdFormik.dirty)}
                sx={{
                  width: '250px',
                  borderRadius: '30px',
                  textTransform: 'none',
                  backgroundColor: '#3D71FF',
                  mt: '25px',
                }}
              >
                Continue
              </Button>
            </>
          )}
        </form>
      </FormikProvider>
    </>
  );
}
