import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import MDEditor from '@uiw/react-md-editor';

import UserAssessment1 from '../../../assets/UserAssessment/UserAssessment1.png';
import UserAssessment2 from '../../../assets/UserAssessment/UserAssessment2.png';
import UserAssessment3 from '../../../assets/UserAssessment/UserAssessment3.png';
import UserAssessment4 from '../../../assets/UserAssessment/UserAssessment4.png';
import UserAssessment5 from '../../../assets/UserAssessment/UserAssessment5.png';
import { createUserAssessment, updateUserAssessment } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const UserAssessmentIcons = [UserAssessment1, UserAssessment2, UserAssessment3, UserAssessment4, UserAssessment5];

const initFieldValue = {
  scenario: '', // DowngradePlan, DeleteAccount, UpgradePlan, MonthlyFeedback
  questionType: '', // text, options, yesNo,
  question: '',
  optionType: '', // text, icon
  options: [],
  yesPlaceholder: '', // if questionType yesNo the yes place holder
  noPlaceholder: '', // if questionType yesNo the no place holder
  isQuestionTypeYesText: false, // text box enable for questionType yesNo 'yes'
  isQuestionTypeNoText: false, // text box enable for questionType yesNo 'no'
  status: true,
};

export default function AddUserAssessmentModal({ open, onClose, data, isDisplay }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    if (fieldValue.questionType === 'options') {
      if (fieldValue?.options.length < 2) {
        toast.error('options can not be empty');
        setIsDisableAll(false);
        return;
      }
      const fieldValueOptions = fieldValue?.options?.filter((item) => item?.length < 2);
      if (fieldValueOptions.length > 0) {
        toast.error('options can not be empty');
        setIsDisableAll(false);
        return;
      }
    }

    try {
      const res = await createUserAssessment(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onUpdateSubmit = async () => {
    setIsDisableAll(true);
    try {
      const res = await updateUserAssessment({ ...fieldValue, _id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const incArray = () => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.options.push('');
      return newPrev;
    });
  };

  const decArray = () => {
    if (fieldValue.options.length === 2) {
      toast.error('You must have 2 answer');
      return;
    }
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.options.pop();
      return newPrev;
    });
  };
  const onChangeOptionsArray = (index, value) => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.options[index] = value;
      return newPrev;
    });
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        scenario: data?.scenario ?? '', // DowngradePlan, DeleteAccount, UpgradePlan, MonthlyFeedback
        questionType: data?.questionType ?? '', // text, options, yesNo,
        question: data?.question ?? '',
        optionType: data?.optionType ?? '', // text, icon
        options: data?.options ?? [],
        yesPlaceholder: data?.yesPlaceholder ?? '', // if questionType yesNo the yes place holder
        noPlaceholder: data?.noPlaceholder ?? '', // if questionType yesNo the no place holder
        isQuestionTypeYesText: data?.isQuestionTypeYesText ?? false, // text box enable for questionType yesNo 'yes'
        isQuestionTypeNoText: data?.isQuestionTypeNoText ?? false, // text box enable for questionType yesNo 'no'
        status: data?.status ?? true,
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add User Assessment' : isDisplay ? 'User Assessment' : 'Update User Assessment'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* scenario */}
          <TextField
            value={fieldValue.scenario}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, scenario: e.target.value }))}
            required
            select
            label="Scenario"
            size="small"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          >
            <MenuItem value="DowngradePlan">Downgrade Plan</MenuItem>
            <MenuItem value="DeleteAccount">Delete Account</MenuItem>
            <MenuItem value="UpgradePlan">Upgrade Plan</MenuItem>
            <MenuItem value="MonthlyFeedback">Monthly Feedback</MenuItem>
          </TextField>
          {/* question */}
          <TextField
            value={fieldValue.question}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, question: e.target.value }))}
            required
            label="Question"
            name="question"
            size="small"
            type="text"
            multiline
            minRows={2}
            maxRows={3}
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* status */}
          <TextField
            value={fieldValue.status ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
          {/* questionType */}
          <TextField
            value={fieldValue.questionType}
            onChange={(e) => {
              setFieldValue((prev) => ({ ...prev, questionType: e.target.value }));
              if (e.target.value !== 'yesNo') {
                setFieldValue((prev) => ({
                  ...prev,
                  yesPlaceholder: '',
                  noPlaceholder: '',
                  isQuestionTypeYesText: false,
                  isQuestionTypeNoText: false,
                }));
              }
              if (e.target.value !== 'options') {
                setFieldValue((prev) => ({
                  ...prev,
                  options: [],
                  optionType: '',
                }));
              }
            }}
            required
            select
            label="Question Type"
            size="small"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          >
            <MenuItem value="text">Text Field</MenuItem>
            <MenuItem value="options">Options</MenuItem>
            <MenuItem value="yesNo">Yes or No</MenuItem>
          </TextField>
          {fieldValue.questionType === 'options' && (
            <>
              {/* optionType */}
              <TextField
                value={fieldValue.optionType}
                onChange={(e) => {
                  setFieldValue((prev) => ({ ...prev, optionType: e.target.value }));
                  if (e.target.value === 'icon') {
                    setFieldValue((prev) => ({ ...prev, options: ['', '', '', '', ''] }));
                  }
                  if (e.target.value === 'text') {
                    setFieldValue((prev) => ({ ...prev, options: ['', ''] }));
                  }
                }}
                required
                select
                label="Option Type"
                size="small"
                fullWidth
                variant="outlined"
                disabled={isDisableAll}
              >
                <MenuItem value="text">Text Options</MenuItem>
                <MenuItem value="icon">Icons Options</MenuItem>
              </TextField>
              {fieldValue?.options.length > 0 && fieldValue?.optionType === 'text' && (
                <div
                  style={{
                    padding: '0px 12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: '#6a7a87',
                  }}
                >
                  <span>Options List</span>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <IconButton disabled={isDisableAll} onClick={decArray} size="small">
                      <RemoveIcon />
                    </IconButton>
                    <IconButton disabled={isDisableAll} onClick={incArray} size="small">
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
              )}
              {/* options array */}
              {fieldValue?.options?.map((op, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  {fieldValue?.optionType === 'icon' && (
                    <img
                      src={UserAssessmentIcons[index]}
                      alt="UserAssessmentIcons"
                      style={{ width: '26px', height: '26px', objectFit: 'contain' }}
                    />
                  )}
                  <TextField
                    value={op}
                    onChange={(e) => onChangeOptionsArray(index, e.target.value)}
                    label={`Options ${index + 1}`}
                    size="small"
                    type="text"
                    fullWidth
                    variant="outlined"
                    disabled={isDisableAll}
                  />
                </div>
              ))}
            </>
          )}
          {fieldValue.questionType === 'yesNo' && (
            <>
              {/* isQuestionTypeYesText */}
              <TextField
                value={fieldValue.isQuestionTypeYesText ? 'Yes' : 'No'}
                onChange={(e) => {
                  setFieldValue((prev) => ({ ...prev, isQuestionTypeYesText: e.target.value === 'Yes' }));
                  if (e.target.value === 'No') {
                    setFieldValue((prev) => ({ ...prev, yesPlaceholder: '' }));
                  }
                }}
                required
                select
                label="Is Text Field appear for 'Yes'?"
                size="small"
                fullWidth
                variant="outlined"
                disabled={isDisableAll}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
              {/* yesPlaceholder */}
              {fieldValue.isQuestionTypeYesText && (
                <TextField
                  value={fieldValue.yesPlaceholder}
                  onChange={(e) => setFieldValue((prev) => ({ ...prev, yesPlaceholder: e.target.value }))}
                  required
                  label="Placeholder text for 'Yes'"
                  size="small"
                  type="text"
                  fullWidth
                  variant="outlined"
                  disabled={isDisableAll}
                />
              )}
              {/* isQuestionTypeNoText */}
              <TextField
                value={fieldValue.isQuestionTypeNoText ? 'Yes' : 'No'}
                onChange={(e) => {
                  setFieldValue((prev) => ({ ...prev, isQuestionTypeNoText: e.target.value === 'Yes' }));
                  if (e.target.value === 'No') {
                    setFieldValue((prev) => ({ ...prev, noPlaceholder: '' }));
                  }
                }}
                required
                select
                label="Is Text Field appear for 'No'?"
                size="small"
                fullWidth
                variant="outlined"
                disabled={isDisableAll}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </TextField>
              {/* noPlaceholder */}
              {fieldValue.isQuestionTypeNoText && (
                <TextField
                  value={fieldValue.noPlaceholder}
                  onChange={(e) => setFieldValue((prev) => ({ ...prev, noPlaceholder: e.target.value }))}
                  required
                  label="Placeholder text for 'No'"
                  size="small"
                  type="text"
                  fullWidth
                  variant="outlined"
                  disabled={isDisableAll}
                />
              )}
            </>
          )}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onUpdateSubmit}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
