import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Image } from 'antd';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete, onDisplay }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, newSortData, sortState } = useSortHook(data, ['createdAt']);

  const headData = ['Icon', 'Sequence', 'Mega Module', 'Modules', 'Created At', 'Actions'];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: handleSort,
          sortHeadMap: ['', 'index', 'megaModule', '', 'createdAt', ''],
        }}
        headData={headData}
      >
        {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
        {newSortData?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <Image
                preview
                style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                src={e?.megaModuleIcon}
                alt="logo"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.index}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.megaModule}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {e?.module?.map((ex) => (
                  <>
                    <span>
                      {ex?.index} : {ex?.module}
                    </span>
                    <br />
                  </>
                ))}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <ThreeDotMenu onDelete={() => onDelete(e)} onDisplay={() => onDisplay(e)} onEdit={() => onEdit(e)} />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
