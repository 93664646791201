import {
  Avatar,
  Box,
  Button,
  FormLabel,
  InputAdornment,
  TextField,
  Card,
  Grid,
  Typography,
  Tabs,
  Tab,
  Fab,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import toast from 'react-hot-toast';
import React, { useState, useEffect } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { doc, updateDoc } from 'firebase/firestore';
import Swal from 'sweetalert2';
import SuccessToast from '../../components/Toast/Success';
import { ProfilepicResponse, TotalBudgetResponse, getSignedURL } from '../../services/Service';
import { getProfile, updateLoginData } from '../../store/loginSlice';
import Page from '../../components/Page';
import PersonalInfoTab from '../../sections/SettingTabs/PersonalInfoTab';
import SecurityTab from '../../sections/SettingTabs/SecurityTab';
import ChatTab from '../../sections/SettingTabs/ChatTab';
import TemplatesTab from '../../sections/SettingTabs/TemplatesTab';
import NotificationTab from '../../sections/SettingTabs/NotificationTab';
import Currency from '../../components/Currency';
import ErrorToast from '../../components/Toast/Error';
import PersonalInfo from '../../assets/svgs/PersonalInfo.svg';
import Security from '../../assets/svgs/Security.svg';
import Templates from '../../assets/svgs/Templates.svg';
import { db } from '../../firebase';
import ExternalSystemId from '../../sections/SettingTabs/ExternalSystemId';
import PaymentTab from '../../sections/SettingTabs/PaymentTab';
import SubLabelTab from '../../sections/SettingTabs/SubLabelTab';
import labelPng from '../../assets/label.png';
import FaqModal from '../../components/Modals/FaqModal';
import { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';

const Profile = () => {
  const { user } = useSelector((state) => state.login);
  const profileData = useSelector((state) => state.dynamicMenuState);
  const profileSettingSubmenuItems = profileData.find((menuItem) => menuItem.menu === 'settings')?.submenu || [];
  const { registered } = user;

  const [value, setValue] = useState(0);
  const [budget, setBudget] = useState(user?.budget?.totalBudget);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  const [profilePicSignUrl, setProfilePicSignUrl] = useState('');
  let updatedProfileSettingSubmenuItems = profileSettingSubmenuItems.filter((item) => item !== 'dashboard');

  updatedProfileSettingSubmenuItems = ['profile', 'security', 'label', ...updatedProfileSettingSubmenuItems];
  useEffect(() => {
    getSignedURL({ url: user?.profilePic })
      .then((e) => {
        setProfilePicSignUrl(e.data);
      })
      .catch((err) => {
        console.log('getSignedURL error for profilePic', err);
      });
  }, [user]);

  const pictureUploader = (e) => {
    ProfilepicResponse(e)
      .then(async (res) => {
        if (res.data.success === true) {
          SuccessToast('Profile picture updated!');
          setProfilePicSignUrl(res?.data?.profilePic);

          dispatch(updateLoginData(res));
          if (user.role === 'business') {
            await updateDoc(doc(db, 'business', currentUser.uid), {
              photoURL: res.data.profilePic,
            });
          } else {
            await updateDoc(doc(db, 'users', currentUser.uid), {
              photoURL: res.data.profilePic,
            });
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const totalBudgetHandler = () => {
    const valuesToBeSent = {
      budget: budget,
      event: { call: 'changeBudget', logs: { oldValues: user?.budget?.totalBudget, newValues: budget } },
    };

    TotalBudgetResponse(valuesToBeSent)
      .then((res) => {
        if (res.data.success === true) {
          setTimeout(() => {
            dispatch(getProfile()).unwrap();
          }, 1000);
          SuccessToast('Budget Updated Successfully!!!');
          setEdit(false);
          // dispatch(getProfile()).unwrap();
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err.message);
        console.log(err);
      });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      key: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const imgStyle = {
    height: '70px',
    width: '70px',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '-18px',
    left: '20px',
  };

  const lineSyle = {
    fontSize: '16px',
    fontWeight: 700,
    pb: '7px',
    position: 'relative',
    pt: '22px',
    '::after': {
      position: 'absolute',
      content: '""',
      left: '0',
      bottom: '0',
      height: '3px',
      width: '60px',
      backgroundColor: '#C1C8F6',
    },
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Page title="Profile - SafalSubscriptions">
      <div>
        <form>
          <Box
            sx={{ fontSize: '24px', color: '#3D71FF', fontWeight: 700, mb: 15, display: 'flex', alignItems: 'center' }}
          >
            Settings
            <FaqModal module="Subscriptions - Label" color="#3D71FF" />
          </Box>
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid
              style={{ position: 'relative' }}
              item
              xs={12}
              sm={12}
              md={12}
              sx={{
                '@media (max-width: 640px)': {
                  paddingTop: '300px !important',
                  paddingLeft: '0px !important',
                },
              }}
            >
              <div style={{ position: 'absolute', top: '-5%', left: '5%', zIndex: 2 }}>
                <Avatar
                  src={profilePicSignUrl}
                  variant="square"
                  alt=""
                  sx={{
                    width: 150,
                    height: 150,
                    borderRadius: '20px',
                  }}
                />
                <input
                  style={{ display: 'none' }}
                  id="contained-button-file1"
                  type="file"
                  onChange={(e) => {
                    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|bmp|tiff)$/)) {
                      ErrorToast('Please upload image in JPG, JPEG, TIFF, BMP, PNG Format ONLY.');
                    } else {
                      pictureUploader(e);
                    }
                  }}
                />
                <FormLabel htmlFor="contained-button-file1">
                  <Avatar
                    sx={{
                      position: 'absolute',
                      mt: -3,
                      ml: 6.5,
                      color: '#FFFFFF',
                      backgroundColor: '#3D71FF',
                      cursor: 'pointer',
                      width: 50,
                      height: 50,
                    }}
                  >
                    <CameraAltOutlinedIcon />
                  </Avatar>
                </FormLabel>
              </div>
              <Card
                sx={{
                  borderRadius: '20px',
                  px: 7,
                  py: 4,
                  mx: 'auto',
                  zIndex: 1,
                  width: '100%',
                  '@media (max-width: 640px)': {
                    paddingLeft: '12px !important',
                    paddingRight: '12px !important',
                  },
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    width: '60%',
                    ml: '15%',
                    marginBottom: '8px',
                    '@media (max-width: 640px)': {
                      width: '100%',
                      ml: '0',
                    },
                  }}
                >
                  <Typography variant="body2" sx={{ color: 'black', fontSize: '13px', fontWeight: 400, ml: 2 }}>
                    Last Login:{' '}
                    {moment(user?.lastLoggedInAt).format(
                      user.country === 'India' ? 'Do MMMM YYYY, h:mm a' : 'MMMM Do YYYY, h:mm a'
                    )}{' '}
                    {getTimeZoneAbbreviation()}
                  </Typography>
                </Box>
                <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" xs={12}>
                  <Box
                    sx={{
                      p: 0,
                      width: '60%',
                      ml: '-11%',
                      '@media (max-width: 640px)': {
                        width: '100%',
                        ml: '0',
                      },
                    }}
                  >
                    <Tabs
                      className="Setting-Tabs-01"
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs"
                      fullWidth
                      sx={{
                        border: '1px solid #E2E2E2',
                        borderRadius: '30px',
                        p: 0.3,
                        color: '#000000',
                        '& .Mui-selected svg': {
                          fill: 'white',
                          mr: 0.7,
                        },
                        '& .MuiButtonBase-root.MuiTab-labelIcon.Mui-selected': {
                          borderRadius: '30px',
                          backgroundColor: '#3D71FF',
                          textTransform: 'none',
                          textDecoration: 'none',
                          color: '#FFFFFF',
                          // height:"40px"
                        },
                        '& .MuiTabs-indicator': {
                          display: 'none',
                        },
                        '@media (max-width: 640px)': {
                          '& .MuiTabs-flexContainer': {
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '8px 0px',
                          },
                        },
                      }}
                    >
                      {/* ------------------------old  */}
                      {updatedProfileSettingSubmenuItems.map((submenuItem, index) => {
                        // if (submenuItem === 'security') return <></>;
                        return (
                          <Tab
                            label={
                              <>
                                <Typography sx={{ fontSize: '15px' }}>{capitalizeFirstLetter(submenuItem)}</Typography>
                              </>
                            }
                            {...a11yProps(index)}
                            icon={
                              submenuItem === 'profile' ? (
                                <img src={PersonalInfo} alt="Personal Info" />
                              ) : submenuItem === 'security' ? (
                                <img src={Security} alt="Security" />
                              ) : submenuItem === 'templates' ? (
                                <img src={Templates} alt="Templates" />
                              ) : submenuItem === 'chat' ? (
                                <SyncLockIcon />
                              ) : submenuItem === 'notification' ? (
                                <NotificationsIcon />
                              ) : submenuItem === 'label' ? (
                                <img width="20px" height="20px" src={labelPng} alt="label Png" />
                              ) : (
                                ''
                              )
                            }
                            iconPosition="start"
                            sx={{
                              img: {
                                filter: value === index && 'invert(1)',
                              },
                              width:
                                user?.role === 'user' ? `${100 / updatedProfileSettingSubmenuItems.length}%` : '100%',
                              minHeight: '30px',
                              textTransform: 'none',
                              color: '#000000',
                              display:
                                user?.role !== 'user' &&
                                (submenuItem === 'templates' ||
                                  submenuItem === 'notification' ||
                                  submenuItem === 'chat')
                                  ? 'none'
                                  : '',
                            }}
                            onClick={(event) => {
                              if (
                                !registered &&
                                (submenuItem === 'templates' ||
                                  submenuItem === 'notification' ||
                                  submenuItem === 'chat')
                              ) {
                                handleChange(event, value);
                                Swal.fire(
                                  'For added security, we kindly ask you to confirm your primary email before utilizing this feature.'
                                );
                              }
                            }}
                          />
                        );
                      })}
                    </Tabs>
                  </Box>

                  <div
                    style={{
                      width: '90%',
                      // marginLeft: '-13%',
                      overflowX: 'scroll',
                    }}
                    className="settingPage-div-1"
                  >
                    {updatedProfileSettingSubmenuItems.map((submenuItem, index) => (
                      <TabPanel key={index} value={value} index={index}>
                        {submenuItem === 'profile' ? <PersonalInfoTab /> : null}
                        {submenuItem === 'security' ? <SecurityTab /> : null}
                        {/* {submenuItem === 'payment' ? <PaymentTab /> : null} */}
                        {submenuItem === 'label' ? <SubLabelTab /> : null}
                        {submenuItem === 'templates' ? <TemplatesTab /> : null}
                        {submenuItem === 'notification' ? <NotificationTab /> : null}
                        {submenuItem === 'chat' ? <ChatTab /> : null}
                      </TabPanel>
                    ))}
                  </div>
                </Grid>
              </Card>
              <div
                className="settingPage-div-total"
                style={{ position: 'absolute', top: '-5%', right: '4%', zIndex: 2, width: 250 }}
              >
                {user?.role === 'user' && (
                  <Card
                    sx={{
                      height: 150,
                      position: 'relative',
                      overflow: 'visible',
                      boxShadow: '0px 4px 10px 5px rgba(162, 162, 162, 0.15)',
                    }}
                  >
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                      <Grid item xs={9}>
                        <Box sx={{ ...imgStyle, background: 'linear-gradient(180deg, #FEB655 0%, #FFC774 100%)' }}>
                          <img height="35px" width="35px" src="/assets/images/budget.png" alt="budget" />
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        {!edit && (
                          <Fab
                            size="small"
                            onClick={() => {
                              !registered
                                ? Swal.fire(
                                    'For added security, we kindly ask you to confirm your primary email before utilizing this feature.'
                                  )
                                : setEdit(true);
                            }}
                            sx={{ backgroundColor: '#3D71FF', color: '#FFFFFF', mt: '15px', marginRight: '10px' }}
                          >
                            <EditIcon
                              sx={{
                                ':hover': {
                                  color: 'black',
                                },
                              }}
                            />
                          </Fab>
                        )}
                        {edit && (
                          <Fab
                            size="small"
                            onClick={() => setEdit(false)}
                            sx={{ backgroundColor: '#3D71FF', color: '#FFFFFF', mt: '15px' }}
                          >
                            <CloseOutlinedIcon
                              sx={{
                                ':hover': {
                                  color: 'black',
                                },
                              }}
                            />
                          </Fab>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                      style={{ marginLeft: '-8px', marginTop: '-27px' }}
                    >
                      <Grid item xs={11}>
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'baseline',
                            flexDirection: 'column',
                            '@media (max-width: 640px)': {
                              paddingLeft: '20px',
                            },
                          }}
                        >
                          <Box sx={{ ...lineSyle, color: '#FDBE69', fontSize: 20 }}>Total Annual Budget</Box>
                          {!edit && (
                            <Box sx={{ fontSize: '30px', fontWeight: 700, mt: '10px' }}>
                              <Currency value={user?.budget?.totalBudget} />
                            </Box>
                          )}
                          {edit && (
                            <Box sx={{ display: 'flex' }}>
                              <TextField
                                value={budget}
                                onChange={(e) => {
                                  if (e.target.value.length < 9) {
                                    setBudget(e.target.value);
                                  }
                                }}
                                variant="standard"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {user?.country === 'United States' ? '$' : '₹'}
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{ fontSize: '30px', fontWeight: 700, mt: '10px', width: '50%' }}
                              />
                              <Button
                                color="primary"
                                variant="contained"
                                // type="submit"
                                onClick={() => totalBudgetHandler()}
                                sx={{
                                  // width: '190px',
                                  height: '30px',
                                  backgroundColor: '#3D71FF',
                                  borderRadius: '30px',
                                  textTransform: 'none',
                                  mt: 1,
                                }}
                              >
                                Update
                              </Button>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Card>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </Page>
  );
};

export default Profile;
