/* eslint-disable react/jsx-boolean-value */
import { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';

import useWindowSize from '../../../../hooks/useWindowSize';
import { getCustomerRenewal } from '../../../../services/Service';

const CustomerRenewalPie = () => {
  const { width } = useWindowSize();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState('CurrentMonth'); // CurrentMonth, LastMonth

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const res = await getCustomerRenewal(filter);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let month = '';

    switch (mode) {
      case 'CurrentMonth':
        month = moment().startOf('month').format('YYYY-MM');
        break;
      case 'LastMonth':
        month = moment().subtract(1, 'month').startOf('month').format('YYYY-MM');
        break;

      default:
        break;
    }

    fetchData({ month });
  }, [mode]);

  const chartOption = {
    title: {
      text: 'Customer Renewal - each plan',
      left: 'left', // Align title to the left
      textStyle: {
        color: '#468b47', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)', // Tooltip shows name, value, and percentage
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      left: 'right', // Position legend to the right
      top: 'center', // Vertically center the legend
      formatter: (name) => {
        const data = chartOption.series[0].data;
        const total = data.reduce((sum, item) => sum + item.value, 0);
        const item = data.find((d) => d.name === name);
        const percentage = Number(((item.value / total) * 100).toFixed(2)) || 0; // Calculate percentage

        return `${name} (${percentage}%)`; // Display name with percentage
      },
    },
    series: [
      {
        name: 'Customer Renewal',
        type: 'pie',
        radius: width > 1400 ? '75%' : '50%',
        center: ['40%', '50%'], // Move the pie chart to the left
        data: data?.map((e) => ({
          value: e.renewal,
          name: e.name,
        })),
        itemStyle: {
          color: (params) => {
            const colors = ['#468b47', '#90ee90', '#edf9eb', '#385239'];
            return colors[params.dataIndex % colors.length]; // Apply custom colors
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <select
        value={mode}
        style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 1 }}
        onChange={(e) => setMode(e.target.value)}
      >
        <option value="CurrentMonth">Current Month</option>
        <option value="LastMonth">Last Month</option>
      </select>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        showLoading={loading}
        option={chartOption}
        style={{ height: '400px', minHeight: '300px' }}
      />
    </div>
  );
};
export default CustomerRenewalPie;
