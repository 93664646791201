import React, { useEffect, useState } from 'react';
import {
  Fab,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  FormControl,
  InputLabel,
  NativeSelect,
  Checkbox,
} from '@mui/material';
import _ from 'lodash';
import Scrollbar from '../../components/Scrollbar';
import { getChatLimit, updateChatLimit } from '../../services/Service';
import SuccessToast from '../../components/Toast/Success';

const ChatWithOtherUser = () => {
  const [tableBody, setTableBody] = useState();
  const [toast, setToast] = useState(false);

  useEffect(() => {
    console.log('data');
    getChatLimit().then((res) => {
      const getDesc = (d, data) => {
        let obj = null;
        switch (d) {
          case '0':
            obj = {
              id: d,
              desc: 'Beta',
              photo: data[d].photo,
              video: data[d].video,
              document: data[d].document,
            };
            break;
          case '1':
            obj = {
              id: d,
              desc: 'Free',
              photo: data[d].photo,
              video: data[d].video,
              document: data[d].document,
            };
            break;
          case '2':
            obj = {
              id: d,
              desc: 'paid',
              photo: data[d].photo,
              video: data[d].video,
              document: data[d].document,
            };
            break;
          case '3':
            obj = {
              id: d,
              desc: 'premium',
              photo: data[d].photo,
              video: data[d].video,
              document: data[d].document,
            };
            break;
          default:
            obj = null;
        }

        return obj;
      };
      res.data.data;
      const tableData = [];
      Object.keys(res?.data.data).map((d) => {
        console.log(d);
        const modifyObj = getDesc(d, res.data.data);
        if (modifyObj) {
          tableData.push(modifyObj);
        }
        return true;
      });
      console.log('dataaaaaaaa', tableData);
      setTableBody(tableData);
    });
  }, []);

  useEffect(() => {
    console.log('testttttt', tableBody);
    submitChanges();
  }, [tableBody]);

  // useEffect(() => {
  //   getNotificationConfig().then((res) => {
  //     setData(res.data[tier]);
  //     const tableData = [];
  //     Object.keys(res?.data[tier]).map((d) => {
  //       console.log(d);
  //       let modifyObj = TABLE_BODY_INIT.find((e) => e.id === d);

  //       if (modifyObj) {
  //         modifyObj = {
  //           ...modifyObj,
  //           ...res.data[tier][d],
  //         };
  //         tableData.push(modifyObj);

  //         return true;
  //       }
  //       return false;
  //     });
  //     setTableBody(tableData);
  //   });
  // }, [tier]);

  const handleChange = (e, val) => {
    console.log(val);
    // eslint-disable-line no-use-before-define
    const isEnabled = e.target.value !== 'on';
    console.log(isEnabled);
    const modifyObj = {
      ...val,
      [e.target.name]: e.target.checked,
    };
    const tableData = tableBody;
    tableData[tableData.findIndex((d) => d.id === val.id)] = modifyObj;
    console.log(tableData);
    setToast(true);
    setTableBody([...tableData]);
  };

  const submitChanges = async () => {
    console.log(tableBody);
    let updatedChanges = {};
    tableBody.map((d) => {
      updatedChanges = {
        ...updatedChanges,
        [d.id]: _.omit(d, ['desc', 'id']),
      };
      return null;
    });
    // const request = {
    //   [tier]: updatedChanges,
    // };
    await updateChatLimit(updatedChanges);
    toast && SuccessToast('Updated Successfully!');
    setToast(false);
  };

  return (
    <>
      <TableContainer sx={{ minWidth: 800, overflowX: 'auto', maxHeight: '90vh' }}>
        <Scrollbar>
          <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <TableHead style={{ padding: '20px' }}>
              <TableRow>
                <TableCell
                  rowSpan={2}
                  sx={{
                    backgroundColor: '#EAFFEA',
                    color: '#027C34',
                    borderBottomLeftRadius: '35px',
                    borderTopLeftRadius: '35px',
                    fontWeight: 700,
                  }}
                >
                  Tier
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: '#EAFFEA',
                    color: '#027C34',
                    fontWeight: 700,
                  }}
                >
                  Photo
                </TableCell>
                <TableCell sx={{ backgroundColor: '#EAFFEA', color: '#027C34', fontWeight: 700 }}>Video</TableCell>
                <TableCell
                  sx={{
                    backgroundColor: '#EAFFEA',
                    color: '#027C34',
                    fontWeight: 700,
                    borderBottomRightRadius: '35px',
                    borderTopRightRadius: '35px',
                  }}
                >
                  Document
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {/* {const isItemSelected = selected.indexOf(row._id) !== -1;} */}
              {tableBody?.map((val) => (
                <TableRow
                  hover
                  key={1}
                  tabIndex={-1}
                  style={{
                    backgroundColor: '#FFFFFF',
                    borderBottomLeftRadius: '200px',
                    borderTopLeftRadius: '200px',
                    paddingLeft: '30px',
                    borderRadius: '200px',
                    boxShadow: 'rgb(100 100 111 / 23%) 0px 0px 8px 2px',
                  }}
                >
                  <TableCell
                    padding="none"
                    sx={{
                      backgroundColor: '#FFFFFF',
                      borderBottomLeftRadius: '35px',
                      borderTopLeftRadius: '35px',
                      paddingTop: 0,
                      paddingBottom: 0,
                      whiteSpace: 'nowrap',
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <Typography variant="subtitle2" noWrap sx={{ marginLeft: 5 }}>
                      {val.desc}
                    </Typography>
                  </TableCell>

                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="subtitle2" noWrap>
                        <Checkbox
                          name={'photo'}
                          defaultChecked={val.photo}
                          onChange={(e) => handleChange(e, val)}
                          sx={{ m: 1 }}
                          color="success"
                        />
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                    <Typography variant="subtitle2" noWrap>
                      <Checkbox
                        name={'video'}
                        defaultChecked={val.video}
                        onChange={(e) => handleChange(e, val)}
                        sx={{ m: 1 }}
                        color="success"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    sx={{
                      backgroundColor: '#FFFFFF',
                      p: 0,
                      borderBottomRightRadius: '35px',
                      borderTopRightRadius: '35px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="subtitle2" noWrap>
                        <Checkbox
                          name={'document'}
                          defaultChecked={val.document}
                          onChange={(e) => handleChange(e, val)}
                          sx={{ m: 1 }}
                          color="success"
                        />
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
    </>
  );
};

export default ChatWithOtherUser;
