import { Button, InputAdornment, OutlinedInput, TablePagination, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './UserAssessmentBusiness.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { deleteUserAssessmentBusiness, getSignedURL, getUserAssessmentBusiness } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddUserAssessmentModal from './AddUserAssessmentModal';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';
import DownloadMenu from './DownloadMenu';
import FilterMenu from './FilterMenu';
import useDebounce from '../../../hooks/useDebounce';
import UserAssessmentModalLog from './UserAssessmentModalLog';

const initFilter = {
  status: '',
  scenario: '',
  questionType: '',
};

const UserAssessmentBusiness = () => {
  const [filter, setFilter] = useState({ ...initFilter });
  const [addModalOpen, setAddModalOpen] = useState({
    open: false,
    data: null,
    isDisplay: false,
  });
  const [logModalOpen, setLogModalOpen] = useState({
    open: false,
    data: null,
  });
  const [rawData, setRawData] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    limit: 10,
  });
  const [searchString, setSearchString] = useState('');
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async (myFilter) => {
    try {
      const res = await getUserAssessmentBusiness(myFilter);
      if (res.status === 200) {
        setRawData(res.data.data);
        setPagination(res.data.pagination);
      }
    } catch (err) {
      console.log('getUserAssessmentBusiness fetchData error', err.message);
    }
  };

  const onAddModalOpen = () => {
    setAddModalOpen({
      open: true,
      data: null,
      isDisplay: false,
    });
  };
  const onAddModalOpenForEdit = (data) => {
    setAddModalOpen({
      open: true,
      data: data,
      isDisplay: false,
    });
  };
  const onAddModalOpenForDisplay = (data) => {
    setAddModalOpen({
      open: true,
      data: data,
      isDisplay: true,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      open: false,
      data: null,
      isDisplay: false,
    });
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteUserAssessmentBusiness({ id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  // useEffect(() => {
  //   fetchData({ ...filter, ...pagination, search: searchString });
  // }, []);

  const onSearchChange = (e) => {
    setSearchString(e.target.value);
  };

  // const downloadTrend = async (type) => {
  //   try {
  //     const res = await getAllFaqDownload({ module: 'All', fileType: type });
  //     const signedURLRes = await getSignedURL({
  //       url: res.data.url,
  //     });
  //     const link = document.createElement('a');
  //     link.href = signedURLRes.data;
  //     link.setAttribute('download', `file`);
  //     document.body.appendChild(link);
  //     link.click();

  //     toast.success('download successful');
  //   } catch (err) {
  //     const msg = err?.response?.data?.message || 'download failed, please try again';
  //     toast.error(msg);
  //   }
  // };

  const handleChangePage = (_, value) => {
    fetchData({
      ...filter,
      ...pagination,
      search: searchString,
      page: value + 1,
    });
  };

  const handleChangeRowsPerPage = (e) => {
    fetchData({
      ...filter,
      ...pagination,
      search: searchString,
      limit: e.target.value,
      page: 1,
    });
  };

  const onFilterReset = () => {
    setFilter({ ...initFilter });
    fetchData({
      ...initFilter,
      ...pagination,
      search: searchString,
    });
  };
  const onFilterApply = (newFilterState) => {
    fetchData({
      ...newFilterState,
      ...pagination,
      search: searchString,
    });
    setFilter(newFilterState);
  };

  useDebounce(
    async () => {
      await fetchData({
        ...filter,
        ...pagination,
        search: searchString,
      });
    },
    [searchString],
    1000
  );

  const onViewResponse = (data) => {
    setLogModalOpen({
      data: data,
      open: true,
    });
  };
  const onCloseViewResponse = (data) => {
    setLogModalOpen({
      data: null,
      open: false,
    });
  };

  return (
    <>
      <Helmet>
        <title>User Assessment - SafalSubscriptions</title>
      </Helmet>

      <div id="UserAssessmentBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              User Assessment
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <TextField
              sx={{ width: '200px', ml: '12px' }}
              onChange={onSearchChange}
              value={searchString}
              type="search"
              id="search-input"
              size="small"
              label="Search"
              variant="outlined"
            />
          </div>
          <div className="title-action-div">
            {/* <DownloadMenu downloadFile={downloadTrend} /> */}
            <FilterMenu filterState={filter} onFilterReset={onFilterReset} onFilterApply={onFilterApply} />
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TablePagination
          component="div"
          size="small"
          count={pagination?.total}
          rowsPerPageOptions={[10, 15, 25, 50]}
          rowsPerPage={pagination?.limit}
          page={pagination?.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <TableView
          onDelete={(data) => {
            setIsDeleteConfirmationModalOpen({
              open: true,
              data,
            });
          }}
          onDisplay={onAddModalOpenForDisplay}
          onEdit={onAddModalOpenForEdit}
          onViewResponse={onViewResponse}
          data={rawData}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddUserAssessmentModal
        isDisplay={addModalOpen.isDisplay}
        open={addModalOpen.open}
        data={addModalOpen.data}
        onClose={onAddModalClose}
      />
      <UserAssessmentModalLog open={logModalOpen.open} data={logModalOpen.data} onClose={onCloseViewResponse} />
    </>
  );
};

export default UserAssessmentBusiness;
