import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  Typography,
  Box,
  Fab,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Field, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import MultiAttach from '../../components/MultiAttach';
import SuccessToast from '../../components/Toast/Success';
import { GetcompaniesCountry, PromotionResponse } from '../../services/Service';
import { setCompanies } from '../../store/companiesSlice';

const sortCompareFn = (a, b) => {
  const nameA = a.toUpperCase(); // Convert names to uppercase
  const nameB = b.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  // names must be equal
  return 0;
};

function Promotions(props) {
  const dispatch = useDispatch();
  const { allCompaniesData } = useSelector((state) => state.companies);
  const [countries, setCountries] = useState(null);
  const { user } = useSelector((state) => state.login);
  const [attached, setAttached] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [selectedCompanyType, setSelectedCompanyType] = useState([]);

  useEffect(() => {
    GetcompaniesCountry(countries).then((res) => {
      dispatch(setCompanies({ allCompaniesData: res.data.data }));
      const companiesType = [...new Set(res.data.data.map((item) => item.companyType))];
      setCompanyTypes(companiesType);
    });
  }, [countries]);

  const PromotionFormSchema = Yup.object().shape({
    country: Yup.string().required('Please Select Your Country').typeError('Please Select Your Country'),
    companyType: Yup.string().required('Please Select Company Type'),
    title: Yup.string().required('Please Enter Title'),
    message: Yup.string().required('Please Enter Message'),
  });

  const initialValues = {
    country: null,
    companyType: '',
    title: '',
    message: '',
    company: '',
    attached: '',
  };

  const handleCompanyClick = (data) => {
    const CompanyType = allCompaniesData.filter((val) => val.companyType === data);
    setSelectedCompanyType(CompanyType);
  };

  const PromotionForm = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: PromotionFormSchema,
    onSubmit: async (values, { resetForm }) => {
      const { company } = values;
      console.log('Add modal values ==> ', values);
      const formData = new FormData();
      // eslint-disable-next-line array-callback-return
      Object.keys(values).map((d) => {
        if (d === 'title') {
          formData.append(d, `${values.company} - ${values.title}`);
        } else {
          formData.append(d, values[d]);
        }
      });
      attached && Array.from(attached).map((d) => formData.append('attachment', d));
      formData.append('userId', user._id);
      const res = await PromotionResponse(formData);
      if (res) {
        resetForm(initialValues);
        setAttached('');
        SuccessToast('Promotion Notifications sent successfully!');
      }
    },
  });

  const handleFileRemove = (val) => {
    const newState = attached.filter((arr) => val !== arr);
    setAttached(newState);
    PromotionForm.setFieldValue('attached', newState);
  };

  return (
    <div
      style={{
        width: '40%',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: '30px',
        boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
        padding: 40,
        background: '#FFFFFF',
      }}
    >
      <FormikProvider value={PromotionForm} validateOnMount>
        <form onSubmit={PromotionForm.handleSubmit}>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl fullWidth error={PromotionForm.touched.country && Boolean(PromotionForm.errors.country)}>
              <InputLabel id="country" name="country" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                Country<span style={{ color: 'red', ml: -2 }}>*</span>
              </InputLabel>

              <Field
                as={Select}
                labelId="country"
                name="country"
                label="Country"
                variant="standard"
                size="small"
                value={PromotionForm.values.country}
                onChange={(e) => {
                  PromotionForm.setFieldValue('country', e.target.value);
                  setCountries(e.target.value);
                }}
                sx={{
                  '&:after': {
                    borderBottomColor: '#0000',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#0071E3',
                  },
                }}
              >
                <MenuItem value={'IND'}> India </MenuItem>
                <MenuItem value={'USA'}> USA </MenuItem>
              </Field>
              {PromotionForm.touched.country && PromotionForm.errors.country ? (
                <FormHelperText>{PromotionForm.touched.country && PromotionForm.errors.country}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <br />

          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              fullWidth
              error={PromotionForm.touched.companyType && Boolean(PromotionForm.errors.companyType)}
            >
              <InputLabel id="companyType" name="companyType" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                Company Type<span style={{ color: 'red', ml: -2 }}>*</span>
              </InputLabel>

              <Field
                as={Select}
                labelId="companyType"
                name="companyType"
                label="Company Type"
                variant="standard"
                size="small"
                value={PromotionForm.values.companyType}
                onChange={PromotionForm.handleChange}
                sx={{
                  '&:after': {
                    borderBottomColor: '#0000',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#0071E3',
                  },
                }}
              >
                <MenuItem key="all" value="all_company" onClick={() => handleCompanyClick('all_company')}>
                  All Company Types
                </MenuItem>
                {[...companyTypes].sort(sortCompareFn).map((item) => (
                  <MenuItem key={item} value={item} onClick={() => handleCompanyClick(item)}>
                    {item}
                  </MenuItem>
                ))}
              </Field>
              {PromotionForm.touched.companyType && PromotionForm.errors.companyType ? (
                <FormHelperText>{PromotionForm.touched.companyType && PromotionForm.errors.companyType}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <br />

          <Grid item xs={12} sm={12} md={6}>
            <FormControl
              fullWidth
              sx={{ mt: 2 }}
              error={PromotionForm.touched.company && Boolean(PromotionForm.errors.company)}
            >
              <InputLabel id="company" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                Company
              </InputLabel>
              <Field
                as={Select}
                id="company"
                name="company"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Company<span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                variant="standard"
                size="small"
                value={PromotionForm.values.company}
                onChange={PromotionForm.handleChange}
                sx={{
                  '&:after': {
                    borderBottomColor: '#0000',
                  },
                  '& .MuiSvgIcon-root': {
                    color: '#0071E3',
                  },
                }}
              >
                {[...selectedCompanyType]
                  .sort((a, b) => sortCompareFn(a.name, b.name))
                  .map((item) => (
                    <MenuItem
                      key={item.name}
                      value={item.name}
                      onClick={() => {
                        PromotionForm.values.description = item.description || '';
                        PromotionForm.values.frequency = 'Monthly';
                        PromotionForm.values.amount = item.price || 0;
                        PromotionForm.values.website = item.website || '';
                        PromotionForm.values.logo = item.logo || '';
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Field>
              {PromotionForm.touched.company && PromotionForm.errors.company ? (
                <FormHelperText>{PromotionForm.touched.company && PromotionForm.errors.company}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <br />

          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth error={PromotionForm.touched.title && PromotionForm.errors.title}>
              <InputLabel htmlFor="title" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                Title<span style={{ color: 'red' }}>*</span>
              </InputLabel>
              <Field
                as={Input}
                type="text"
                label={<Typography sx={{ color: '#B6B6B6' }}>Title</Typography>}
                name="title"
                value={PromotionForm.values.title}
                onChange={PromotionForm.handleChange}
              />
              {PromotionForm.touched.title && PromotionForm.errors.title ? (
                <FormHelperText>{PromotionForm.touched.title && PromotionForm.errors.title}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <br />

          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth error={PromotionForm.touched.message && PromotionForm.errors.message}>
              <Field
                as={TextField}
                id="inputMessage"
                name="message"
                multiline
                rows={3}
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Message <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                value={PromotionForm.values.message}
                onChange={PromotionForm.handleChange}
                variant="standard"
                size="small"
              />
            </FormControl>
            {PromotionForm.touched.message && PromotionForm.errors.message ? (
              <FormHelperText style={{ color: 'red' }}>
                {PromotionForm.touched.message && PromotionForm.errors.message}
              </FormHelperText>
            ) : null}
          </Grid>
          <br />

          <MultiAttach attached={attached} setAttached={setAttached} />
          <br />
          {attached && (
            <Box display={'flex'} flexWrap={'wrap'}>
              {attached.map((val) => (
                <Box key={val} sx={{ mt: 1, ml: 2 }}>
                  <Fab
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      handleFileRemove(val);
                    }}
                    sx={{ mr: 1 }}
                  >
                    <CloseIcon />
                  </Fab>
                  {val.name || val?.url?.split('-').slice(4).join('-')}
                </Box>
              ))}
            </Box>
          )}

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                width: '170px',
                height: '45px',
                borderRadius: '30px',
                mt: '25px',
                mb: '20px',
                backgroundColor: 'rgb(1, 184, 85)',
              }}
            >
              Submit
            </Button>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
}

export default Promotions;
