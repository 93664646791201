import { Box, Button, Fab, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import EditModal from './EditModal';
import DisplayModal from './DisplayModal';
import DeleteModal from './DeleteModal';
import TrackModal from './TrackModal';
import { getCardScheme, getPaymentInstrument, getPaymentMode } from '../../services/Service';

const EventClickModal = ({
  openEventModal,
  setOpenEventModal,
  openEditModal,
  setOpenEditModal,
  editData,
  setEditData,
  icsData,
  saveIcs,
  setOpenDisplayModal,
  openDisplayModal,
  openTrackModal,
  setOpenTrackModal,
  openDelete,
  setOpenDelete,
  setDeleteId,
  handledelete,
  setSelected,
  subNames,
  setDeleteSubNames,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
    '@media (max-width: 640px)': {
      width: '98vw !important',
    },
    // boxShadow: 24,
    // pt: 2,
    // px: 4,
    // pb: 3,
  };

  const [paymentModeList, setPaymentModeList] = useState([]);
  const [paymentInstrumentList, setPaymentInstrumentList] = useState([]);
  const [paymentCardSchemeList, setPaymentCardSchemeList] = useState([]);

  const fetchPaymentModeInfo = async () => {
    try {
      const res1 = await getPaymentMode({ status: true });
      setPaymentModeList(res1.data.data);
      const res2 = await getPaymentInstrument({ status: true });
      setPaymentInstrumentList(res2.data.data);
      const res3 = await getCardScheme({ status: true });
      setPaymentCardSchemeList(res3.data.data);
      return res2.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPaymentModeInfo();
  }, []);

  return (
    <>
      <Modal open={openEventModal} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
        <Box sx={{ ...style, width: 500, height: 180 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab onClick={() => setOpenEventModal(false)} size="small" color="primary" aria-label="add">
              <CloseIcon />
            </Fab>
          </Box>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Which Action Do you Want to Perform?
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenDisplayModal(true);
                  setOpenEventModal(false);
                }}
                sx={{
                  textTransform: 'none',
                  width: '100px',
                  '@media (max-width: 640px)': {
                    width: '75px',
                  },
                }}
              >
                Display
              </Button>
            </Box>

            <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
              <Button
                variant="contained"
                onClick={() => {
                  setOpenEditModal(true);
                  setOpenEventModal(false);
                }}
                sx={{
                  textTransform: 'none',
                  width: '100px',
                  '@media (max-width: 640px)': {
                    width: '75px',
                  },
                }}
              >
                Edit
              </Button>
            </Box>

            <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
              <Button
                variant="contained"
                onClick={() => {
                  saveIcs(icsData);
                  setOpenEventModal(false);
                }}
                sx={{
                  textTransform: 'none',
                  width: '100px',
                  '@media (max-width: 640px)': {
                    width: '75px',
                  },
                }}
              >
                Download
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <TrackModal setOpenTrackModal={setOpenTrackModal} openTrackModal={openTrackModal} subName={editData} />
      <EditModal
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        setOpenEventModal={setOpenEventModal}
        data={editData}
        setEditData={setEditData}
        setOpenTrackModal={setOpenTrackModal}
        setOpenDelete={setOpenDelete}
        handledelete={handledelete}
        setSelected={setSelected}
        setDeleteId={setDeleteId}
        fetchPaymentModeInfo={fetchPaymentModeInfo}
        paymentCardSchemeList={paymentCardSchemeList}
        paymentInstrumentList={paymentInstrumentList}
        paymentModeList={paymentModeList}
      />
      <DisplayModal
        openDisplayModal={openDisplayModal}
        setOpenEditModal={setOpenEditModal}
        setOpenDisplayModal={setOpenDisplayModal}
        data={editData}
        setEditData={setEditData}
        setOpenTrackModal={setOpenTrackModal}
        setOpenDelete={setOpenDelete}
        handledelete={handledelete}
        setSelected={setSelected}
        setDeleteId={setDeleteId}
        subNames={subNames}
        setDeleteSubNames={setDeleteSubNames}
      />
    </>
  );
};

export default EventClickModal;
