import { useSelector } from 'react-redux';
import { useEffect, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';

import CallAcceptPopUp from './CallAcceptPopUp';
import { generateAgoraToken } from '../../services/Service';

const agoraEngine = AgoraRTC.createClient({
  mode: 'rtc',
  codec: 'vp8',
});

function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const AgoraAudioCall = ({ agoraState, onCallEnd }) => {
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const channelParametersRef = useRef({
    localAudioTrack: null,
    remoteAudioTrack: null,
    remoteUid: null,
  });

  const resetChannel = () => {
    if (channelParametersRef.current.localAudioTrack) channelParametersRef.current.localAudioTrack.close();
    if (channelParametersRef.current.remoteAudioTrack) channelParametersRef.current.remoteAudioTrack.close();
    channelParametersRef.current.remoteUid = null;
    channelParametersRef.current.localAudioTrack = null;
    channelParametersRef.current.remoteAudioTrack = null;
  };

  const onAudioCallJoin = async (options) => {
    // Join a channel.
    const callUID = await agoraEngine.join(options.appId, options.channel, options.token, options.uid);
    console.log('AgoraAudioCall onAudioCallJoin', callUID);
    const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a local audio track from the microphone audio.
    channelParametersRef.current.localAudioTrack = localAudioTrack;
    await agoraEngine.publish(localAudioTrack);

    // This is Fixed Audio Not Coming, Mute and Unmute Issue
    setTimeout(() => {
      onMuteAndUnmute(true).then(() => {
        setTimeout(() => {
          onMuteAndUnmute(false);
        }, 200);
      });
    }, 500);
  };

  const onAudioCallLeave = async () => {
    await agoraEngine.leave();
    console.log('AgoraAudioCall onAudioCallLeave');
  };

  const agoraStateChange = async () => {
    const rantInt = randomIntFromInterval(1, 1000000);
    const res = await generateAgoraToken({
      channelName: agoraState.callId,
      uid: rantInt,
    });
    const newRtcProps = {
      appId: process.env.REACT_APP_AGORA_APP_ID,
      channel: res.data.channelName,
      token: res.data.token,
      uid: rantInt,
    };
    await onAudioCallJoin(newRtcProps);

    agoraEngine.on('user-published', async (user, mediaType) => {
      // Subscribe to the remote user when the SDK triggers the "user-published" event.
      await agoraEngine.subscribe(user, mediaType);
      console.log('AgoraAudioCall user-published', user, mediaType);
      // Subscribe and play the remote audio track.
      if (mediaType === 'audio') {
        const remoteUid = user.uid;

        if (user.hasAudio) {
          // Play the remote audio track.
          user.audioTrack.play();
          channelParametersRef.current.remoteAudioTrack = user.audioTrack;
        } else {
          channelParametersRef.current.remoteAudioTrack = null;
        }

        channelParametersRef.current.remoteUid = remoteUid;
      }
    });

    // Listen for the "user-unpublished" event.
    agoraEngine.on('user-unpublished', async (user, mediaType) => {
      console.log('AgoraAudioCall user-unpublished', user, mediaType);
      if (mediaType === 'audio') {
        const remoteUid = user.uid;

        if (user.hasAudio) {
          // Play the remote audio track.
          user.audioTrack.play();
          channelParametersRef.current.remoteAudioTrack = user.audioTrack;
        } else {
          channelParametersRef.current.remoteAudioTrack = null;
        }
        channelParametersRef.current.remoteUid = remoteUid;
      }
    });
    agoraEngine.on('user-joined', async (user) => {
      console.log('AgoraAudioCall user-joined', user);
    });
    agoraEngine.on('user-left', async (user) => {
      console.log('AgoraAudioCall user-left', user);
      await onAudioCallLeave();
      resetChannel();
      onCallEnd();
    });
  };

  const onMuteAndUnmute = async (value) => {
    if (channelParametersRef.current.localAudioTrack) {
      await channelParametersRef.current.localAudioTrack.setMuted(value);
    }
  };

  useEffect(() => {
    agoraStateChange();
    return () => {
      resetChannel();
    };
  }, []);

  return (
    <>
      <CallAcceptPopUp
        agoraState={agoraState}
        currentFirebaseUser={currentFirebaseUser}
        onMuteAndUnmute={onMuteAndUnmute}
        onCallRejectEnd={onAudioCallLeave}
      />
    </>
  );
};

export default AgoraAudioCall;
