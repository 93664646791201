import {
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tabs,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ErrorToast from '../../components/Toast/Error';
import SuccessToast from '../../components/Toast/Success';
import { updateNotificationConfigPremium, getNotificationConfig } from '../../services/Service';
import Scrollbar from '../../components/Scrollbar';
import { updateUserNotification } from '../../store/loginSlice';
import Inlineloader from '../../components/Loader/InlineLoader';
import { CustomTableCellForBody, CustomTableCellForNoData, CustomTableView } from '../../components/UI/CustomTableView';
import SafalBuddyUserNotificationConfig from './SafalBuddyUserNotificationConfig';
import SafalLeaderBoardUserNotificationConfig from './SafalLeaderBoardUserNotificationConfig';

const tableCellPStyle1 = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100px',
  maxHeight: '100px',
  paddingLeft: '12px',
  paddingRight: '12px',
};
const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  maxHeight: '100px',
};

const PayTierPremium = () => {
  const TABLE_BODY_INIT = [
    {
      id: 'budgetExceed',
      catagory: 'Event',
      desc: 'Over the Budget',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'subscriptionExpire',
      catagory: 'Event',
      desc: 'Subscription about to expire in (7 days)',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'subscriptionRenewal',
      catagory: 'Event',
      desc: 'Subscription is due for renewal ',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'primaryEmailVerify',
      catagory: 'User',
      desc: 'Primary Email Not Verified ',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'addonEmailVerify',
      catagory: 'User',
      desc: 'Add-On Emails Not Verified',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'inActiveUser',
      catagory: 'User',
      desc: 'Not login into your account since 45 days',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'userChat',
      catagory: 'User',
      desc: 'User chat',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'promotion',
      catagory: 'Information',
      desc: 'Promotion(s) available for Subscription  in your account',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'giftCard',
      catagory: 'User',
      desc: 'Gift card expire in (7 days)',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'rewardPoint',
      catagory: 'User',
      desc: 'Reward certificate expire in (7 days)',
      email: true,
      text: true,
      whatsapp: true,
      mobileApp: true,
      notification: true,
      banner: true,
    },
    {
      id: 'offersExpire',
      catagory: 'User',
      desc: 'Offers expire in (7 days)',
      email: false,
      text: false,
      whatsapp: false,
      mobileApp: false,
      notification: false,
      banner: false,
    },
    {
      id: 'userAssessment',
      catagory: 'User',
      desc: 'User Assessment Feedback',
      email: false,
      text: false,
      whatsapp: false,
      mobileApp: false,
      notification: false,
      banner: false,
    },
  ];

  const [data, setData] = useState([]);
  const [tier, setTier] = useState('premiumTier');
  const [tableBody, setTableBody] = useState([]);
  const [toast, setToast] = useState(false);
  const { user } = useSelector((state) => state.login);
  const [value, setValue] = useState(0);
  const [notifyData, setNotifyData] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (hasChanges && tableBody.length > 0) {
      setHasChanges(false);
      submitChanges();
      console.log('Submit Changes');
    } else {
      console.log('tableBody is empty, not calling submitChanges()');
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [hasChanges, tableBody]);

  useEffect(() => {
    getNotificationConfig().then((res) => {
      setNotifyData(res.data);
      console.log('Response', res.data);
    });
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      key: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChanges = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setData(user.notification);
    const tableData = [];
    Object.keys(user.notification).map((d) => {
      let modifyObj = TABLE_BODY_INIT.find((e) => e.id === d);

      if (modifyObj) {
        modifyObj = {
          ...modifyObj,
          ...user.notification[d],
        };
        tableData.push(modifyObj);

        return true;
      }
      return false;
    });
    setTableBody(tableData);
  }, [user?.notification]);

  const updateNotification = (updatedNotification) => {
    dispatch(updateUserNotification(updatedNotification));
  };

  const handleChange = (e, val) => {
    let key = user.tier;
    switch (key) {
      case 'Free':
        key = 'freeTier';
        break;
      case 'Paid':
        key = 'paidTier';
        break;
      case 'Premium':
        key = 'premiumTier';
        break;
      case 'Beta':
        key = 'betaTier';
        break;
      default:
        key = 'betaTier';
    }
    if (value === 0 && !notifyData[key][val.id][e.target.name]) {
      ErrorToast(
        // ' It is not part of your current subscription settings. Please look at current subscription setting(s)'
        'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
      );
      // ErrorToast('This will be enable after Beta release!');
      return;
    }

    if (user.tier !== 3 && value === 1) {
      // ErrorToast('Premium is required!');
      ErrorToast('Coming soon!');
      return;
    }
    console.log(val);
    // eslint-disable-line no-use-before-define
    const isEnabled = e.target.value !== 'on';
    console.log(isEnabled);
    const modifyObj = {
      ...val,
      [e.target.name]: e.target.checked,
    };
    const tableData = tableBody;
    tableData[tableData.findIndex((d) => d.id === val.id)] = modifyObj;
    console.log(tableData);
    setToast(true);
    setTableBody([...tableData]);

    setHasChanges(true);
  };

  const submitChanges = async () => {
    setLoading(true);

    console.log(tableBody, 'tablebody');
    let updatedChanges = {};
    tableBody.map((d) => {
      updatedChanges = {
        ...updatedChanges,
        [d.id]: _.omit(d, ['catagory', 'desc', 'id']),
      };
      return null;
    });
    const res = await updateNotificationConfigPremium({
      _id: user._id,
      notification: updatedChanges,
    })
      .then((res) => {
        updateNotification(updatedChanges);

        if (res.data.status === 'success') {
          SuccessToast(res.data.message);
        } else {
          ErrorToast(res.data.message);
        }
        setLoading(false);
      })
      .catch((error) => {
        ErrorToast(error.message);
        setLoading(false);
      });
    setToast(false);
  };

  useEffect(() => {
    console.log('tier', tier);
  }, [tier]);

  return (
    <>
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
            }}
          />
          <Inlineloader />
        </>
      )}
      {!loading && (
        <>
          <Box
            sx={{
              p: 0,
              '@media (max-width: 640px)': {
                overflow: 'auto !important',
              },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChanges}
              aria-label="basic tabs"
              fullWidth
              sx={{
                // border: '1px solid #E2E2E2',
                borderRadius: '30px',
                p: 0.5,
                color: '#000000',
                '@media (max-width: 640px)': {
                  padding: '4px !important',
                },
                '& .Mui-selected svg': {
                  fill: 'white',
                  mr: 0.7,
                },
                '& .MuiButtonBase-root.MuiTab-labelIcon.Mui-selected': {
                  borderRadius: '30px',
                  backgroundColor: '#3D71FF',
                  textTransform: 'none',
                  textDecoration: 'none',
                  color: '#FFFFFF',
                  // height:"40px"
                },
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              <Tab
                label={
                  <>
                    <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>Primary</Typography>
                  </>
                }
                {...a11yProps(0)}
                iconPosition="start"
                sx={{
                  width: '20%',
                  minHeight: '30px',
                  textTransform: 'none',
                }}
                style={{
                  color: value === 0 ? '#FFFFFF' : '#000000',
                  borderRadius: value === 0 && '30px',
                  backgroundColor: value === 0 && '#3D71FF',
                  textDecoration: value === 0 && 'none',
                }}
              />

              <Tab
                label={
                  <>
                    <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>SafalBuddy</Typography>
                  </>
                }
                {...a11yProps(1)}
                iconPosition="start"
                sx={{
                  width: '20%',
                  minHeight: '30px',
                  textTransform: 'none',
                }}
                style={{
                  color: value === 1 ? '#FFFFFF' : '#000000',
                  borderRadius: value === 1 && '30px',
                  backgroundColor: value === 1 && '#3D71FF',
                  textDecoration: value === 1 && 'none',
                }}
              />
              <Tab
                label={
                  <>
                    <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>SafalLeaderBoard</Typography>
                  </>
                }
                {...a11yProps(2)}
                iconPosition="start"
                sx={{
                  width: '20%',
                  minHeight: '30px',
                  textTransform: 'none',
                }}
                style={{
                  color: value === 2 ? '#FFFFFF' : '#000000',
                  borderRadius: value === 2 && '30px',
                  backgroundColor: value === 2 && '#3D71FF',
                  textDecoration: value === 2 && 'none',
                }}
              />
              <Tab
                label={
                  <>
                    <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>Social Media</Typography>
                  </>
                }
                {...a11yProps(3)}
                iconPosition="start"
                sx={{
                  width: '20%',
                  minHeight: '30px',
                  textTransform: 'none',
                }}
                style={{
                  color: value === 3 ? '#FFFFFF' : '#000000',
                  borderRadius: value === 3 && '30px',
                  backgroundColor: value === 3 && '#3D71FF',
                  textDecoration: value === 3 && 'none',
                }}
              />
            </Tabs>
          </Box>
          <Box
            sx={{
              p: 0,
              '@media (max-width: 640px)': {
                overflow: 'auto !important',
              },
            }}
          >
            <TabPanel
              value={value}
              index={0}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 0,
                m: 0,
              }}
            >
              <TableContainer sx={{ maxHeight: 600 }}>
                <CustomTableView
                  headData={[
                    'Notifications Category',
                    'Notification Description',
                    'Email',
                    'Text',
                    'Mobile App',
                    'Notif. Screen',
                    'Notif. Banner',
                  ]}
                >
                  {tableBody?.map((val, index) => (
                    <TableRow key={index}>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>{val.catagory}</p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>{val.desc}</p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>
                          <Switch
                            name={'email'}
                            onChange={(e) => handleChange(e, val)}
                            checked={val.email}
                            style={{ color: '#2065d1' }}
                          />
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>
                          <Switch
                            name={'text'}
                            onChange={(e) => handleChange(e, val)}
                            checked={val.text}
                            style={{ color: '#2065d1' }}
                          />
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>
                          <Switch
                            name={'mobileApp'}
                            onChange={(e) => handleChange(e, val)}
                            checked={val.mobileApp}
                            style={{ color: '#2065d1' }}
                          />
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>
                          <Switch
                            name={'notification'}
                            onChange={(e) => handleChange(e, val)}
                            checked={val.notification}
                            style={{ color: '#2065d1' }}
                          />
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>
                          <Switch
                            name={'banner'}
                            onChange={(e) => handleChange(e, val)}
                            checked={val.banner}
                            style={{ color: '#2065d1' }}
                          />
                        </p>
                      </CustomTableCellForBody>
                    </TableRow>
                  ))}
                </CustomTableView>
              </TableContainer>
            </TabPanel>
            <SafalBuddyUserNotificationConfig value={value} />
            <SafalLeaderBoardUserNotificationConfig value={value} />
            <TabPanel
              value={value}
              index={3}
              // className="SocialMedia-Blur"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 0,
              }}
            >
              <div className="SocialMedia-Blur">
                <TableContainer
                  sx={{
                    minWidth: 1250,
                    overflowX: 'auto',
                    maxHeight: '70vh',
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}
                  >
                    <TableHead style={{ padding: '0px' }}>
                      <TableRow>
                        <TableCell
                          rowSpan={2}
                          sx={{
                            backgroundColor: '#7B9EFD',
                            color: '#fff',
                            borderBottomLeftRadius: '35px',
                            borderTopLeftRadius: '35px',
                            fontWeight: 700,
                          }}
                        >
                          Notifications&nbsp;Category
                        </TableCell>
                        <TableCell
                          // rowSpan={2}
                          sx={{
                            backgroundColor: '#7B9EFD',
                            color: '#fff',
                            fontWeight: 700,
                          }}
                        >
                          Notification Description
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: '#7B9EFD',
                            color: '#fff',
                            fontWeight: 700,
                          }}
                        >
                          Slack
                        </TableCell>
                        <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>
                          Twitter
                        </TableCell>
                        <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>
                          Instagram
                        </TableCell>
                        <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>
                          Messenger
                        </TableCell>
                        <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>
                          Whatsapp
                        </TableCell>
                        <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>
                          Snapchat
                        </TableCell>
                        <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>
                          LinkedIn
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: '#7B9EFD',
                            color: '#fff',
                            fontWeight: 700,
                            borderBottomRightRadius: '35px',
                            borderTopRightRadius: '35px',
                          }}
                        >
                          Discord
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* {const isItemSelected = selected.indexOf(row._id) !== -1;} */}
                      {tableBody?.map((val) => (
                        <TableRow
                          hover
                          key={1}
                          tabIndex={-1}
                          // role="checkbox"
                          // selected={isItemSelected}
                          // aria-checked={isItemSelected}
                          style={{
                            backgroundColor: '#FFFFFF',
                            borderBottomLeftRadius: '200px',
                            borderTopLeftRadius: '200px',
                            paddingLeft: '0px',
                            borderRadius: '200px',
                            boxShadow: 'rgb(100 100 111 / 23%) 0px 0px 8px 2px',
                          }}
                        >
                          <TableCell
                            padding="none"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderBottomLeftRadius: '35px',
                              borderTopLeftRadius: '35px',
                              paddingTop: 0,
                              paddingBottom: 0,
                              whiteSpace: 'nowrap',
                              paddingRight: 0,
                              paddingLeft: 0,
                              width: '100px',
                            }}
                          >
                            <p style={tableCellPStyle1}>{val.catagory}</p>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <p style={tableCellPStyle}>{val.desc}</p>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Switch
                                name={'slack'}
                                onChange={(e) => handleChange(e, val)}
                                checked={val.slack}
                                style={{ color: '#2065d1' }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Switch
                                name={'twitter'}
                                onChange={(e) => handleChange(e, val)}
                                checked={val.twitter}
                                style={{ color: '#2065d1' }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Switch
                                name={'instagram'}
                                onChange={(e) => handleChange(e, val)}
                                checked={val.instagram}
                                style={{ color: '#2065d1' }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Switch
                                name={'messenger'}
                                onChange={(e) => handleChange(e, val)}
                                checked={val.messenger}
                                style={{ color: '#2065d1' }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Switch
                                name={'messenger'}
                                onChange={(e) => handleChange(e, val)}
                                checked={val.messenger}
                                style={{ color: '#2065d1' }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Switch
                                name={'snapchat'}
                                onChange={(e) => handleChange(e, val)}
                                checked={val.snapchat}
                                style={{ color: '#2065d1' }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Switch
                                name={'linkedin'}
                                onChange={(e) => handleChange(e, val)}
                                checked={val.linkedin}
                                style={{ color: '#2065d1' }}
                              />
                            </div>
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              p: 0,
                              borderBottomRightRadius: '35px',
                              borderTopRightRadius: '35px',
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Switch
                                name={'discord'}
                                onChange={(e) => handleChange(e, val)}
                                checked={val.discord}
                                style={{ color: '#2065d1' }}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="SocialMedia-Blur-Content">
                <span>Coming soon</span>
              </div>
            </TabPanel>
          </Box>
        </>
      )}
    </>
  );
};

export default PayTierPremium;
