import AdvertisementRevenueBarChartCard from './Cards/AdvertisementRevenueBarChartCard';
import AreaChartCard from './Cards/AreaChartCard';
import ArrMrr from './Cards/ArrMrr';
import BarChartCard from './Cards/BarChartCard';
import BigPieChartCard from './Cards/BigPieChartCard';
import CountryChartCard from './Cards/CountryChartCard';
import CustomersCountryWise from './Cards/CustomersCountryWise';
import DeviceTypeBigPieChartCard from './Cards/DeviceTypeBigPieChartCard';
import InfoCard from './Cards/InfoCard';
import PieCard from './Cards/PieCard';
import PlanedActualMRRStackBarChartCard from './Cards/PlanedActualMRRStackBarChartCard';
import SalesRevenuePieCard from './Cards/SalesRevenuePieCard';
import SalesNetRevenuePieCard from './Cards/SalesNetRevenuePieCard';
import StackBarChartCard from './Cards/StackBarChartCard';
import SubscriptionDifferentPaymentTier from './Cards/SubscriptionDifferentPaymentTier';
import SubscriptionRevenueBarChart from './Cards/SubscriptionRevenueBarChart';
import WelcomeBackCard from './Cards/WelcomeBackCard';

const FinancialTab = () => {
  return (
    <>
      <div className="BusinessDashboard-grid-1">
        <WelcomeBackCard />
        <SalesRevenuePieCard />
        <SalesNetRevenuePieCard />
        {/* <AreaChartCard /> */}
      </div>
      <ArrMrr />
      <div className="BusinessDashboard-grid-3">
        <SubscriptionRevenueBarChart title={'Subscription Net Revenue'} />
        <SubscriptionDifferentPaymentTier />
      </div>
      <div className="BusinessDashboard-grid-3">
        <AdvertisementRevenueBarChartCard />
        <BigPieChartCard />
        {/* <CustomersCountryWise /> */}
      </div>
      <div className="BusinessDashboard-grid-3">
        <PlanedActualMRRStackBarChartCard />
        <DeviceTypeBigPieChartCard />
        {/* <CustomersCountryWise /> */}
      </div>
    </>
  );
};

export default FinancialTab;
