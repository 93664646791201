/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// material
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Button,
  MenuItem,
  Divider,
  alpha,
  Menu,
  useMediaQuery,
} from '@mui/material';
//
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Iconify from './Iconify';
import NotificationCount from './Chat/NotificationCount';
import CustomMuiSubMenu from './UI/CustomMuiSubMenu';
import { navConfigBusiness } from '../layouts/dashboard/NavConfig';

// ----------------------------------------------------------------------

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    // backgroundColor: '#EAFFEA',
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const ListItemStyle = styled((props) => <ListItemButton disableGutters disableRipple {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 32,
  position: 'relative',
  textTransform: 'capitalize',
  // color: theme.palette.text.secondary,
  color: '#000000',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    img: {
      filter: 'invert(1)',
    },
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active, onClose }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const isSmallDesktop = useMediaQuery('(max-width: 1200px)');
  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;
  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);
  const safalLeaderBoardSwitch = useSelector((state) => state.safalLeaderBoardSwitch);
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  const activeRootStyle = {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.menu,
    borderRadius: '16px',
    paddingRight: 2,
    width: 'fit-content',
    img: {
      filter: 'invert(1)',
    },
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  const listItemTextStyle = {
    // position: 'relative',
  };
  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box component="span" />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} onClick={() => onClose()} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  function showMenuForTitle(title, dynamicMenuState) {
    const lowerCaseTitle = title.toLowerCase();
    // const matchingMenu = dynamicMenuState?.find((item) => item.menu.toLowerCase() === lowerCaseTitle);
    const matchingMenu = dynamicMenuState?.find(
      // (item) => item.menu.toLowerCase() === lowerCaseTitle || lowerCaseTitle !== 'assets'
      (item) => item.menu.toLowerCase() === lowerCaseTitle
    );

    // safalLeaderBoardSwitch?.leaderBoardStatus
    // console.log('matchingMenu  == >', JSON.stringify(matchingMenu));
    // console.log('title  == >', title);

    if (matchingMenu) {
      if (matchingMenu?.menu === 'safalLeaderBoard') {
        if (safalLeaderBoardSwitch?.leaderBoardStatus === true) {
          return true;
        }
        return false;
      }
      // Title matches a menu item, you can show the menu here
      return true;
    }
  }

  const handleClick = () => {
    if (path !== '/reports') return;

    // This code is fix for SFLS-2099
    const backBtn = document?.getElementById('Back_to_Report_Category');
    if (backBtn) {
      backBtn?.click();
    }
  };

  return (
    <>
      {showMenuForTitle(title, dynamicMenuState) && (
        <ListItemStyle
          onClick={handleClick}
          component={RouterLink}
          to={path}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            ':hover': {
              backgroundColor: theme.palette.info.main,
              color: theme.palette.text.menu,
              borderRadius: '16px',
            },
            pr: isSmallDesktop ? 0.2 : 1,
            marginLeft: '8px',
            marginRight: '8px',
          }}
          // onClick={(event) => {
          //   if (!registered && title !== 'Dashboard') {
          //     event.preventDefault(); // Prevent the redirection
          //     Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
          //   }
          // }}
        >
          <ListItemIconStyle sx={{ minWidth: '30px' }}>{icon && icon}</ListItemIconStyle>
          <ListItemText sx={{ ...(isActiveRoot && listItemTextStyle), pr: 0 }} disableTypography primary={title} />
          {info && info}
        </ListItemStyle>
      )}
    </>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, display = 'flex', onClose, ...other }) {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.login);
  const { notificationCount } = useSelector((state) => state.businessNotificationsDot);
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const isSmallDesktop = useMediaQuery('(max-width: 1050px)');
  // let arr = [];
  const [state, _state] = useState(false);
  const [supportAnchorEl, setSupportAnchorEl] = React.useState(null);
  const [companyAnchorEl, setCompanyAnchorEl] = React.useState(null);
  const [paymentAnchorEl, setPaymentAnchorEl] = React.useState(null);
  const [leaderBoardAnchorEl, setLeaderBoardAnchorEl] = React.useState(null);
  const [safalBuddyAnchorEl, setSafalBuddyAnchorEl] = React.useState(null);
  const [userTypeAnchorEl, setUserTypeAnchorEl] = React.useState(null);
  const [pramotionAnchorEl, setPramotionAnchorEl] = React.useState(null);

  const openSupport = Boolean(supportAnchorEl);
  const openCompany = Boolean(companyAnchorEl);
  const openPayment = Boolean(paymentAnchorEl);
  const openLeaderBoard = Boolean(leaderBoardAnchorEl);
  const openSafalBuddy = Boolean(safalBuddyAnchorEl);
  const openUserType = Boolean(userTypeAnchorEl);
  const openPramotion = Boolean(pramotionAnchorEl);

  const handlePramotionClick = (event) => {
    setPramotionAnchorEl(event.currentTarget);
  };
  const handlePramotionClose = () => {
    setPramotionAnchorEl(null);
  };

  const handleSupportClick = (event) => {
    setSupportAnchorEl(event.currentTarget);
  };
  const handleSupportClose = () => {
    setSupportAnchorEl(null);
  };

  const handleCompanyClick = (event) => {
    setCompanyAnchorEl(event.currentTarget);
  };
  const handlePaymentClick = (event) => {
    setPaymentAnchorEl(event.currentTarget);
  };
  const handleLeaderBoardClick = (event) => {
    setLeaderBoardAnchorEl(event.currentTarget);
  };
  const handleSafalBuddyClick = (event) => {
    setSafalBuddyAnchorEl(event.currentTarget);
  };
  const handleCompanyClose = () => {
    setCompanyAnchorEl(null);
  };
  const handlePaymentClose = () => {
    setPaymentAnchorEl(null);
  };
  const handleLeaderBoardClose = () => {
    setLeaderBoardAnchorEl(null);
  };
  const handleSafalBuddyClose = () => {
    setSafalBuddyAnchorEl(null);
  };
  const handleUserTypeClick = (event) => {
    setUserTypeAnchorEl(event.currentTarget);
  };
  const handleUserTypeClose = () => {
    setUserTypeAnchorEl(null);
  };
  useEffect(() => {
    if (pathname.includes('business')) {
      _state(true);
    } else {
      _state(false);
    }
  }, []);
  // console.log(openSupport,"open")
  const companyArray = navConfig?.filter((item) => {
    return item.roles.includes('business') && item.category === 'company';
  });
  const supportArray = navConfig?.filter((item) => {
    return item.roles.includes('business') && item.category === 'support';
  });
  const userTypeArray = navConfig?.filter((item) => {
    return item.roles.includes('business') && item.category === 'userType';
  });
  const pramotionArray = navConfig?.filter((item) => {
    return item.roles.includes('business') && item.category === 'self';
  });
  const paymentArray = navConfig?.filter((item) => {
    return item.roles.includes('business') && item.category === 'payment';
  });
  const leaderBoardArray = navConfig?.filter((item) => {
    return item.roles.includes('business') && item.category === 'leaderBoard';
  });
  const safalBuddyArray = navConfig?.filter((item) => {
    return item.roles.includes('business') && item.category === 'safalBuddy';
  });
  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  const isSupportTabActive = ['/business/chat', '/business/manage_call', '/business/feedback'].includes(pathname);
  const isCompanyTabActive = ['/business/custom_company', '/business/companieslist'].includes(pathname);
  const isPaymentTabActive = ['/business/plans', '/business/prices'].includes(pathname);
  const isLeaderBoardTabActive = ['/business/safal-leader-config-master'].includes(pathname);
  const isSafalBuddyTabActive = ['/business/safal-buddy-master-config'].includes(pathname);
  const isUserTypeTabActive = ['/business/managememory', '/business/notifications'].includes(pathname);
  const isPramotionTabActive = ['/business/promotions', '/business/promotionslist'].includes(pathname);

  const NavbarDesktop = () => {
    return (
      <>
        {navConfigBusiness?.map((e, index) => (
          <BusinessMenuIcon
            endIcon={<KeyboardArrowDownIcon />}
            key={index}
            subMenu={e?.subMenu}
            title={e?.title}
            icon={e?.icon}
            link={e?.link}
          />
        ))}
        <div style={{ marginRight: '8px' }} />
      </>
    );
  };
  const NavbarDesktopOld = () => {
    // console.log("pramotion",pramotion)
    // console.log("isUserTypeTabActive",isUserTypeTabActive)
    // Business User Menu

    return (
      <>
        {/* Support */}
        <Button
          id="demo-customized-button"
          aria-controls={openSupport ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openSupport ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleSupportClick}
          component="span"
          sx={{
            fontSize: '14px',
            color: '#027C34',
            borderRadius: '30px',
            backgroundColor: '#EAFFEA',
            ':hover': {
              backgroundColor: '#EAFFEA',
            },
            border: isSupportTabActive ? '2px solid #027C34' : '2px solid transparent',
            margin: '0 1%',
            maxHeight: '50px',
            textTransform: 'none',
            maxWidth: isSmallDesktop ? '100px' : '200px',
            boxShadow: 'none',
          }}
          endIcon={
            <>
              {notificationCount > 0 && <div className="red-dot" />}
              <KeyboardArrowDownIcon />
            </>
          }
        >
          Support
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={supportAnchorEl}
          open={openSupport}
          onClose={handleSupportClose}
        >
          {supportArray?.map((i) => (
            <Box
              key={i.title}
              onClick={() => {
                setSupportAnchorEl(null);
              }}
            >
              <ListItemStyle
                component={RouterLink}
                to={i.path}
                sx={{
                  // maxWidth: '100%',
                  // m:2
                  ml: 1,
                  pr: 2,
                }}
              >
                {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
                <Box marginRight={1}>{i.icon}</Box>
                <ListItemText sx={{ pr: 2.5 }} disableTypography primary={i.title} />
                {/* {i.title} */}
              </ListItemStyle>
            </Box>
          ))}
        </StyledMenu>
        {/* Payment */}
        <Button
          id="demo-customized-button"
          aria-controls={openPayment ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openPayment ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handlePaymentClick}
          component="span"
          sx={{
            color: '#027C34',
            borderRadius: '30px',
            backgroundColor: openPayment ? '#C6FFC6' : '#EAFFEA',
            ':hover': {
              backgroundColor: '#EAFFEA',
            },
            margin: '0 1%',
            maxHeight: '50px',
            maxWidth: isSmallDesktop ? '100px' : '200px',
            textTransform: 'none',
            fontSize: '14px',
            boxShadow: 'none',
            border: isPaymentTabActive ? '2px solid #027C34' : '2px solid transparent',
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Payment
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={paymentAnchorEl}
          open={openPayment}
          onClose={handlePaymentClose}
        >
          {paymentArray?.map((i) => (
            <Box
              key={i.title}
              onClick={() => {
                setPaymentAnchorEl(null);
              }}
            >
              <ListItemStyle
                component={RouterLink}
                to={i.path}
                sx={{
                  // maxWidth: '100%',
                  // m:2
                  ml: 1,
                  pr: 2,
                }}
              >
                {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
                <Box marginRight={1}>{i.icon}</Box>
                <ListItemText sx={{ pr: 2.5 }} disableTypography primary={i.title} />
                {/* {i.title} */}
              </ListItemStyle>
            </Box>
          ))}
        </StyledMenu>
        {/* leaderBoard */}
        <Button
          id="demo-customized-button"
          aria-controls={openLeaderBoard ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openLeaderBoard ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleLeaderBoardClick}
          component="span"
          sx={{
            color: '#027C34',
            borderRadius: '30px',
            backgroundColor: openLeaderBoard ? '#C6FFC6' : '#EAFFEA',
            ':hover': {
              backgroundColor: '#EAFFEA',
            },
            margin: '0 1%',
            maxHeight: '50px',
            maxWidth: isSmallDesktop ? '100px' : '200px',
            textTransform: 'none',
            fontSize: '14px',
            boxShadow: 'none',
            border: isLeaderBoardTabActive ? '2px solid #027C34' : '2px solid transparent',
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          LeaderBoard
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={leaderBoardAnchorEl}
          open={openLeaderBoard}
          onClose={handleLeaderBoardClose}
        >
          {leaderBoardArray?.map((i) => (
            <Box
              key={i.title}
              onClick={() => {
                setLeaderBoardAnchorEl(null);
              }}
            >
              <ListItemStyle
                component={RouterLink}
                to={i.path}
                sx={{
                  ml: 1,
                  pr: 2,
                }}
              >
                <Box marginRight={1}>{i.icon}</Box>
                <ListItemText sx={{ pr: 2.5 }} disableTypography primary={i.title} />
              </ListItemStyle>
            </Box>
          ))}
        </StyledMenu>
        {/* safalBuddy */}
        <Button
          id="demo-customized-button"
          aria-controls={openSafalBuddy ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openSafalBuddy ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleSafalBuddyClick}
          component="span"
          sx={{
            color: '#027C34',
            borderRadius: '30px',
            backgroundColor: openSafalBuddy ? '#C6FFC6' : '#EAFFEA',
            ':hover': {
              backgroundColor: '#EAFFEA',
            },
            margin: '0 1%',
            maxHeight: '50px',
            maxWidth: isSmallDesktop ? '100px' : '200px',
            textTransform: 'none',
            fontSize: '14px',
            boxShadow: 'none',
            border: isSafalBuddyTabActive ? '2px solid #027C34' : '2px solid transparent',
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          SafalBuddy
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={safalBuddyAnchorEl}
          open={openSafalBuddy}
          onClose={handleSafalBuddyClose}
        >
          {safalBuddyArray?.map((i) => (
            <Box
              key={i.title}
              onClick={() => {
                setSafalBuddyAnchorEl(null);
              }}
            >
              <ListItemStyle
                component={RouterLink}
                to={i.path}
                sx={{
                  ml: 1,
                  pr: 2,
                }}
              >
                <Box marginRight={1}>{i.icon}</Box>
                <ListItemText sx={{ pr: 2.5 }} disableTypography primary={i.title} />
              </ListItemStyle>
            </Box>
          ))}
        </StyledMenu>
        {/* Company */}
        <Button
          id="demo-customized-button"
          aria-controls={openCompany ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openCompany ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleCompanyClick}
          component="span"
          sx={{
            color: '#027C34',
            borderRadius: '30px',
            backgroundColor: openCompany ? '#C6FFC6' : '#EAFFEA',
            ':hover': {
              backgroundColor: '#EAFFEA',
            },
            margin: '0 1%',
            maxHeight: '50px',
            maxWidth: isSmallDesktop ? '100px' : '200px',
            textTransform: 'none',
            fontSize: '14px',
            boxShadow: 'none',
            border: isCompanyTabActive ? '2px solid #027C34' : '2px solid transparent',
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Company
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={companyAnchorEl}
          open={openCompany}
          onClose={handleCompanyClose}
        >
          {companyArray?.map((i) => (
            <Box
              key={i.title}
              onClick={() => {
                setCompanyAnchorEl(null);
              }}
            >
              <ListItemStyle
                component={RouterLink}
                to={i.path}
                sx={{
                  // maxWidth: '100%',
                  // m:2
                  ml: 1,
                  pr: 2,
                }}
              >
                {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
                <Box marginRight={1}>{i.icon}</Box>
                <ListItemText sx={{ pr: 2.5 }} disableTypography primary={i.title} />
                {/* {i.title} */}
              </ListItemStyle>
            </Box>
          ))}
        </StyledMenu>
        {/* User Type */}
        <Button
          id="demo-customized-button"
          aria-controls={openUserType ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openUserType ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handleUserTypeClick}
          component="span"
          sx={{
            color: '#027C34',
            borderRadius: '30px',
            backgroundColor: '#EAFFEA',
            ':hover': {
              backgroundColor: '#EAFFEA',
            },
            margin: '0 1%',
            maxHeight: '50px',
            width: isSmallDesktop ? '70px' : '140px',
            maxWidth: isSmallDesktop ? '70px' : '200px',
            textTransform: 'none',
            fontSize: '14px',
            boxShadow: 'none',
            border: isUserTypeTabActive ? '2px solid #027C34' : '2px solid transparent',
            whiteSpace: 'nowrap',
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          User Type
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={userTypeAnchorEl}
          open={openUserType}
          onClose={handleUserTypeClose}
        >
          {userTypeArray?.map((i) => (
            <Box
              key={i.title}
              onClick={() => {
                setUserTypeAnchorEl(null);
              }}
            >
              <ListItemStyle
                component={RouterLink}
                to={i.path}
                sx={{
                  // maxWidth: '100%',
                  // m:2
                  ml: 1,
                  pr: 2,
                }}
              >
                {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
                <Box marginRight={1}>{i.icon}</Box>
                <ListItemText sx={{ pr: 2.5 }} disableTypography primary={i.title} />
                {/* {i.title} */}
              </ListItemStyle>
            </Box>
          ))}
        </StyledMenu>
        {/* Promotion */}
        <Button
          id="demo-customized-button"
          aria-controls={openPramotion ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openPramotion ? 'true' : undefined}
          variant="contained"
          disableElevation
          onClick={handlePramotionClick}
          component="span"
          sx={{
            color: '#027C34',
            borderRadius: '30px',
            backgroundColor: '#EAFFEA',
            ':hover': {
              backgroundColor: '#EAFFEA',
            },
            margin: '0 1%',
            maxHeight: '50px',
            width: isSmallDesktop ? '70px' : '140px',
            maxWidth: isSmallDesktop ? '70px' : '200px',
            textTransform: 'none',
            fontSize: '14px',
            boxShadow: 'none',
            border: isPramotionTabActive ? '2px solid #027C34' : '2px solid transparent',
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Promotion
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={pramotionAnchorEl}
          open={openPramotion}
          onClose={handlePramotionClose}
        >
          {pramotionArray?.map((i) => (
            <Box
              key={i.title}
              onClick={() => {
                setPramotionAnchorEl(null);
              }}
            >
              <ListItemStyle
                component={RouterLink}
                to={i.path}
                sx={{
                  // maxWidth: '100%',
                  // m:2
                  ml: 1,
                  pr: 2,
                }}
              >
                {/* <ListItemIconStyle>{icon && icon}</ListItemIconStyle> */}
                <Box marginRight={1}>{i.icon}</Box>
                <ListItemText sx={{ pr: 2.5 }} disableTypography primary={i.title} />
                {/* {i.title} */}
              </ListItemStyle>
            </Box>
          ))}
        </StyledMenu>
      </>
    );
  };

  return (
    <Box {...other} sx={{ display: display }}>
      <List disablePadding sx={{ display: display }}>
        {isDesktop && state === true ? (
          <>
            {NavbarDesktop()}
            {/* {NavbarDesktopOld()} */}
          </>
        ) : (
          navConfig.map((item) => {
            return (
              item.roles &&
              item.roles.includes(user?.role) && (
                <NavItem onClose={onClose} key={item.title} item={item} active={match} />
              )
            );
          })
        )}
      </List>
    </Box>
  );
}

const BusinessMenuIcon = ({ isActive, endIcon, title, subMenu, icon, link }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    if (subMenu.length === 0) {
      navigate(link);
      return;
    }

    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isSmallDesktop = useMediaQuery('(max-width: 1050px)');

  // component={RouterLink}
  // to={i.path}
  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        component="span"
        sx={{
          fontSize: '14px',
          color: '#027C34',
          borderRadius: '30px',
          backgroundColor: '#EAFFEA',
          ':hover': {
            backgroundColor: '#EAFFEA',
          },
          whiteSpace: 'nowrap',
          border: isActive ? '2px solid #027C34' : '2px solid transparent',
          margin: '0px 4px',
          maxHeight: '50px',
          textTransform: 'none',
          minWidth: 'min-content',
          // maxWidth: isSmallDesktop ? '100px' : '200px',
          boxShadow: 'none',
        }}
        endIcon={subMenu.length > 0 && endIcon}
      >
        {icon}
        <span style={{ marginLeft: '6px' }}>{title}</span>
      </Button>
      {subMenu.length > 0 && (
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {subMenu?.map((ex, index) => (
            <CustomMuiSubMenu
              onClick={() => {
                setAnchorEl(null);
              }}
              key={index}
              link={ex.link}
              subMenu={ex?.subMenu}
              title={ex?.title}
              icon={ex?.icon}
            />
            // <Box
            //   key={index}
            //   onClick={() => {
            //     setAnchorEl(null);
            //   }}
            // >

            // </Box>
          ))}
        </StyledMenu>
      )}
    </>
  );
};
