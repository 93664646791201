import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { createPlanFeatureBusiness, updatePlanFeatureBusiness } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  tier: '',
  feature: [''],
};

export default function AddPlanFeatureModal({ open, plans, data, onClose }) {
  const [fieldValue, setFieldValue] = useState(initFieldValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    onClose();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await createPlanFeatureBusiness(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdate = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        _id: data._id,
        ...fieldValue,
      };
      const res = await updatePlanFeatureBusiness(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (data === null) return;
    setFieldValue({
      tier: data?.tier,
      feature: data?.feature,
    });
  }, [data]);

  const disabled = data === null ? isSubmitting : true;

  const incAnswerArray = () => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.feature.push('');
      return newPrev;
    });
  };
  const decAnswerArray = () => {
    if (fieldValue.feature.length === 1) {
      toast.error('You must have 1 feature');
      return;
    }
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.feature.pop();
      return newPrev;
    });
  };
  const onChangeAnswerArray = (index, value) => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.feature[index] = value;
      return newPrev;
    });
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data ? 'Update Feature' : 'Add Feature'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* plans */}
          <TextField
            value={fieldValue.tier}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, tier: e.target.value }))}
            required
            select
            label="Plan"
            size="small"
            fullWidth
            variant="outlined"
            disabled={disabled}
          >
            {plans?.map((e, index) => (
              <MenuItem key={index} value={e.tier}>{`Tier ${e.tier} : ${e.name}`}</MenuItem>
            ))}
          </TextField>
          <div
            style={{
              padding: '0px 12px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#6a7a87',
            }}
          >
            <span>Feature List</span>
            <div style={{ display: 'flex', gap: '8px' }}>
              <IconButton disabled={isSubmitting} onClick={decAnswerArray} size="small">
                <RemoveIcon />
              </IconButton>
              <IconButton disabled={isSubmitting} onClick={incAnswerArray} size="small">
                <AddIcon />
              </IconButton>
            </div>
          </div>
          {fieldValue?.feature?.map((ans, index) => (
            <React.Fragment key={index}>
              <span>Feature {index + 1}</span>
              <TextField
                value={ans}
                onChange={(e) => onChangeAnswerArray(index, e.target.value)}
                required={index === 0}
                label={`Feature ${index + 1} Text`}
                name={`feature${index}`}
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                disabled={isSubmitting}
              />
            </React.Fragment>
          ))}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          {data ? (
            <Button
              disabled={isSubmitting}
              onClick={onUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              Update
            </Button>
          ) : (
            <Button
              disabled={isSubmitting}
              onClick={onSubmit}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              Create
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
