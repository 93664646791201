import { Box, Button, Fab, FormControl, FormLabel, Grid, InputLabel, Menu, MenuItem, TextField } from '@mui/material';
import NativeSelect from '@mui/material/NativeSelect';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import moment from 'moment';
import Attachment from './Attachments';
import {
  getFeedbackAction,
  getFeedbacksAction,
  getUserFeedbacksAction,
  handleFeedbackAction,
} from '../store/feedbackSlice';
import MultiAttach from './MultiAttach';
import ErrorToast from './Toast/Error';
import SuccessToast from './Toast/Success';
import Scrollbar from './Scrollbar';
import { getSignedURL } from '../services/Service';

const FeedbackComponent = ({
  item: { description, details, updatedBy, createdAt, updatedAt, attachment, status, userRole },
  user = null,
  mode = 'business',
  isLast = false,
  color,
  openEditModal,
  setOpenEditModal,
  tempId,
  isEdit,
  feedbackId,
  prevData,
}) => {
  const { profilePic, _id } = useSelector((state) => state.login.user);
  const feedbackRef = useRef(null);
  const classes = useStyles();
  const [isReply, setReply] = useState(false);
  const [attached, setAttached] = useState([]);
  const dispatch = useDispatch();

  const [openDownload, setOpenDownload] = useState(false);
  const [profilePicSignUrl, setProfilePicSignUrl] = useState('');

  const handleClickDownload = (event) => {
    setOpenDownload(event?.currentTarget);
  };

  const handleCloseDownload = () => {
    setOpenDownload(null);
  };
  const handleClickOutside = (event) => {
    console.log('event.current', event.current)
    if (feedbackRef.current && !feedbackRef.current.contains(event.target)) {
      handleCloseDownload();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const { current } = useSelector((state) => state.feedback);

  const { user: users } = useSelector((state) => state.login);

  const EditFeedbackSchema = Yup.object().shape({
    details: Yup.string().required('Details is required'),
  });
  const initialValues = {
    details: '',
    attachment: '',
    status: status || current?.status,
  };

  const FeedbackFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: EditFeedbackSchema,
    onSubmit: (values, onSubmitProps, e, resetForm) => {
      console.log('calledddddddddddddd');
      const formData = new FormData();
      Object.keys(FeedbackFormik.values).map((d) => formData.append(d, FeedbackFormik.values[d]));
      attached && Array.from(attached).map((d) => formData.append('attachment', d));
      formData.append('userRole', mode);
      formData.append('updatedBy', _id);
      formData.append('id', current?._id);

      dispatch(handleFeedbackAction(formData))
        .unwrap()
        .then(async (data) => {
          setOpenEditModal(false);
          setReply(false);
          SuccessToast('Replied Successfully');
          if (mode === 'business') dispatch(getFeedbacksAction({ page: 1, limit: 5 }));
          else
            dispatch(
              getUserFeedbacksAction({
                id: current?.userId,
                filter: {
                  sort: {},
                  search: {},
                },
              })
            );
        })
        .catch((err) => {
          console.log(err);
          ErrorToast(err.data.message);
        });
    },
  });

  const getProfilePic = (userRole) => {
    if(userRole === "user") {
      return '/assets/images/UserAvtarBackground.png';
    }

    return '/assets/images/AvtarBackground.png';
    
  };

  useEffect(() => {

    getSignedURL({ url: user?.profilePic })
      .then((e) => {
        setProfilePicSignUrl(e.data);
      })
      .catch((err) => {
        console.log('getSignedURL error for profilePic', err);
      });
      
  }, [user?.profilePic]);

  const getDate = () => {
    let date = null;
    console.log(prevData);
    if (prevData) {
      if (
        users.country === 'India' &&
        moment(createdAt).tz('America/New_York').format('MM/DD/YYYY') !==
          moment(prevData?.createdAt).tz('America/New_York').format('MM/DD/YYYY')
      ) {
        date = moment(createdAt).tz('America/New_York').format('DD/MM/YYYY');
      } else if (moment(createdAt).format('DD/MM/YYYY') !== moment(prevData?.createdAt).format('DD/MM/YYYY')) {
        date = moment(createdAt).format('MM/DD/YYYY');
      }
    } else {
      if (users.country === 'India') {
        date = moment(createdAt).tz('America/New_York').format('DD/MM/YYYY');
      } else {
        date = moment(createdAt).format('MM/DD/YYYY');
      }
    }
    return date;
  };
  const StatusColor = (status) => {
    if (status === 'Submitted') {
      return 'blue';
    }
    if (status === 'Opened') {
      return 'orange';
    }
    if (status === 'In-progress') {
      return 'orange';
    }
    if (status === 'Re-opened') {
      return 'red';
    }
    if (status === 'Closed') {
      return 'green';
    }
    return status;
  };

  const getJsx = () => {
    let jsx = <></>;
    console.log('ttw mode', mode);
    console.log('ttw status', status);
    if (status === 'Closed' && mode === 'user') {
      console.log('testing');

      jsx = (
        <FormControl style={{ width: '50%' }}>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Status
          </InputLabel>
          <NativeSelect
            onChange={(e) => FeedbackFormik.setFieldValue('status', e.target.value)}
            defaultValue={30}
            inputProps={{
              name: 'Status',
              id: 'uncontrolled-native',
            }}
          >
            <option value=""> </option>
            <option value="Re-opened">Re-opened</option>
          </NativeSelect>
        </FormControl>
      );
    } else if (mode === 'business' && status !== 'Closed') {
      console.log('testing');

      jsx = (
        <FormControl style={{ width: '50%' }}>
          <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Status
          </InputLabel>
          <NativeSelect
            onChange={(e) => FeedbackFormik.setFieldValue('status', e.target.value)}
            defaultValue={30}
            inputProps={{
              name: 'Status',
              id: 'uncontrolled-native',
            }}
          >
            <option value=""> </option>

            <>
              {/* <option value="Open">Open</option> */}
              <option value="Re-opened">Re-opened</option>
              <option value="In-progress">In-progress</option>
              <option value="Closed">Closed</option>
            </>
          </NativeSelect>
        </FormControl>
      );
    }
    return jsx;
  };

  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>{getDate()}</h3>
      <div className={userRole === 'user' ? classes.reply_div : classes.reply_business_div}>
        <Grid container spacing={2} className={classes.grid_container}>
          <Grid item xs={2}>
            <img src={ (user?.profilePic && userRole === 'user') ? profilePicSignUrl : getProfilePic(userRole)} alt="" className={classes.feedback_img} />
          </Grid>
          <Grid item xs={8}>
            <Typography
              variant="h6"
              className={userRole === 'user' ? classes.username_typo : classes.business_username_typo}
            >
              {userRole === 'user' ? user?.firstName : "Safal Admin"}
            </Typography>
            <p className={classes.date_para}>
              {users.country === 'India'
                ? moment(createdAt).format('hh:mm:ss a')
                : moment(createdAt).tz('America/New_York').format('hh:mm:ss a')}
            </p>
            <div style={{ maxHeight: '35%', overflow: 'auto' }}>
              <Typography variant="h6" className={classes.msg_para}>
                {details}
              </Typography>
            </div>
            {attachment?.length > 0 && (
              <div ref={feedbackRef}>
                <Button onClick={(e) => handleClickDownload(e)}>
                  <Attachment
                    data={attachment.map((attach) => ({ url: attach }))}
                    openDownload={openDownload}
                    handleClickDownload={handleClickDownload}
                    handleCloseDownload={handleCloseDownload}
                    setOpenDownload={setOpenDownload}
                  />
                </Button>
              </div>
            )}
            {/* <div className={classes.opened_div}>
              <div className={classes.oranage_dot}> </div>
              <Typography
                sx={{ fontWeight: 'bold', color: StatusColor(status?.trim()) }}
                variant="h6"
                className={classes.opened_typo}
              >
                {status}
              </Typography>
            </div> */}
          </Grid>
          <Grid item xs={2}>
            {console.log('****', userRole)}
            {/* && mode !== userRole */}
            {isEdit === 'edit' && isLast && !isReply && (
              <Button
                className={userRole === 'user' ? classes.reply_btn : classes.business_reply_btn}
                onClick={() => setReply(!isReply)}
                variant="contained"
              >
                Reply
              </Button>
            )}
          </Grid>
        </Grid>
        {isReply && (
          <div style={{ width: '100%', padding: '2rem' }}>
            <Typography variant="h6">Reply</Typography>
            <FormikProvider value={FeedbackFormik}>
              <form onSubmit={FeedbackFormik.handleSubmit}>
                <FormControl
                  fullWidth
                  sx={{ mb: 3 }}
                  error={FeedbackFormik.touched.name && Boolean(FeedbackFormik.errors.name)}
                >
                  <Field
                    as={TextField}
                    id="input1"
                    name="details"
                    label="Details*"
                    variant="standard"
                    size="small"
                    onChange={FeedbackFormik.handleChange}
                    value={FeedbackFormik.values.details}
                    error={FeedbackFormik.touched.details && Boolean(FeedbackFormik.errors.details)}
                    helperText={FeedbackFormik.touched.details && FeedbackFormik.errors.details}
                  />
                </FormControl>
                <br />
                <br />
                {(() => {
                  console.log('testing', mode);
                })()}
                {isLast && getJsx()}
                <br />
                <br />

                <FormLabel
                  htmlFor="contained-button-file"
                  sx={{ display: 'flex', flexDirection: 'column', width: 'fit-content' }}
                >
                  <MultiAttach attached={attached} setAttached={setAttached} />
                </FormLabel>
                <div style={{ marginLeft: 2, marginTop: 2 }}>
                  {attached.length >= 0 && (
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                      {Array.from(attached).map((d, i) => (
                        <Box sx={{ mt: 1, mr: 2 }} style={{ display: 'block' }} key={i}>
                          <Fab
                            size="small"
                            variant="outlined"
                            color="error"
                            sx={{ mr: 1 }}
                            onClick={() => {
                              setAttached(Array.from(attached).filter((e, j) => i !== j));
                            }}
                          >
                            <CloseIcon />
                          </Fab>
                          {d.name}
                        </Box>
                      ))}
                    </div>
                  )}
                </div>
                <br />

                <div className={classes.button_flex}>
                  <Button
                    onClick={() => {
                      setReply(!isReply);
                      setAttached([]);
                      FeedbackFormik.resetForm();
                    }}
                    color="primary"
                    variant="contained"
                    className={classes.btn_cancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    sx={
                      color && {
                        backgroundColor: '#2065D1',
                      }
                    }
                    variant="contained"
                    className={classes.btn_send}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </FormikProvider>
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  feedback_img: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
  },
  reply_div: {
    backgroundColor: '#dee8f2',
    borderRadius: 20,
    marginTop: 50,
  },

  reply_btn: {
    backgroundColor: '#226EFD !important',
    borderRadius: '30px',
    mt: '25px',
    boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
  },
  business_reply_btn: {
    backgroundColor: '#19C25F !important',
    borderRadius: '30px',
    mt: '25px',
    boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
  },
  reply_business_div: {
    backgroundColor: '#def2e0',
    borderRadius: 20,
    marginTop: 50,
  },
  grid_container: {
    padding: '17px 35px',
  },
  opened_div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  oranage_dot: {
    height: 15,
    width: 15,
    backgroundColor: '#ffa500a8',
    borderRadius: '50%',
    marginRight: 10,
  },
  btn_cancel: {
    padding: '10px 60px',
    borderRadius: '30px',
    textTransform: 'none',
    display: 'block',
    backgroundColor: 'gray',
  },
  btn_send: {
    padding: '10px 60px',
    borderRadius: '30px',
    textTransform: 'none',
    backgroundColor: 'green',
    display: 'block',
    margin: '1rem',
  },
  username_typo: {
    fontWeight: 'bold',
    color: '#0058ffdb',
    fontSize: 22,
  },
  business_username_typo: {
    fontWeight: 'bold',
    color: 'green',
    fontSize: 22,
  },
  date_para: {
    fontSize: 14,
  },
  msg_para: {
    fontSize: 17,
    fontWeight: 300,
    color: 'black',
  },
  opened_typo: {
    fontSize: 17,
    fontWeight: 300,
  },
  button_flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

export default FeedbackComponent;
