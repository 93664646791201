import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Fab,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import WestIcon from '@mui/icons-material/West';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MDEditor from '@uiw/react-md-editor';
import SearchIcon from '@mui/icons-material/Search';

import { getFaqList } from '../../services/Service';
import Inlineloader from '../Loader/InlineLoader';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// This Modal only Open via Setting btn > FAQ btn
const FaqModalOnly = ({ module, open, onClose }) => {
  const [faqData, setFaqData] = useState([]);
  const [filterFaqData, setFilterFaqData] = useState([]);
  const [rawFormattedData, setRawFormattedData] = useState([]);
  const [filterFormattedData, setFilterRawFormattedData] = useState([]);

  const [selectedData, setSelectedData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [searchStringFaq, setSearchStringFaq] = useState('');
  const [searchString, setSearchString] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getFaqList({ module });
      if (res.status === 200) {
        setRawFormattedData(res.data?.formattedData || []);
        setFilterRawFormattedData(res.data?.formattedData || []);
      }
    } catch (err) {
      console.log('getFaqList fetchData error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    p: 0,
    maxHeight: '95vh',
    minHeight: '50vh',
    overflow: 'hidden',
    '@media (max-width: 640px)': {
      width: '95vw',
    },
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (open === true) {
      fetchData();
    }
  }, [open]);

  const onSelectCategory = (data) => {
    setSelectedData(data);
    setFaqData(data?.faqData);
    setFilterFaqData(data?.faqData);
    setSearchStringFaq('');
  };

  const onSelectCategoryRemove = () => {
    setSelectedData(null);
    setFaqData([]);
    setFilterFaqData([]);
    setSearchStringFaq('');
  };

  const onChangeSearchValue = (e) => {
    const value = e.target.value;
    setSearchString(value);
    if (value.length > 1) {
      const filterNewData = rawFormattedData.filter((ex) => ex?.megaModule?.toLowerCase()?.match(value?.toLowerCase()));
      setFilterRawFormattedData(filterNewData);
    } else {
      setFilterRawFormattedData(rawFormattedData);
    }
  };

  const onChangeSearchValueFaq = (e) => {
    const value = e.target.value;
    setSearchStringFaq(value);
    if (value.length > 1) {
      const filterNewData = faqData.filter((ex) => ex?.question?.toLowerCase()?.match(value?.toLowerCase()));
      setFilterFaqData(filterNewData);
    } else {
      setFilterFaqData(faqData);
    }
  };

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <Box sx={{ ...style }}>
          <div
            style={{
              flex: 'none',
              backgroundColor: '#bcceff',
              padding: '16px 32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Fab
              sx={{ position: 'absolute', top: '16px', right: '16px' }}
              onClick={handleClose}
              size="small"
              color="primary"
              aria-label="add"
            >
              <CloseIcon />
            </Fab>
            <Typography variant="h4" sx={{ fontSize: '28px', fontWeight: 700, color: '#3d71ff' }}>
              Frequently asked questions
            </Typography>
            {selectedData === null ? (
              <SearchStyle
                onChange={onChangeSearchValue}
                value={searchString}
                sx={{ marginRight: '100px' }}
                placeholder="Search category"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            ) : (
              <SearchStyle
                onChange={onChangeSearchValueFaq}
                value={searchStringFaq}
                sx={{ marginRight: '100px' }}
                placeholder="Search questions"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            )}
          </div>
          {loading ? (
            <Inlineloader />
          ) : (
            <div
              style={{
                padding: '32px',
                paddingTop: '16px',
                overflow: 'hidden',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  flex: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginBottom: '16px',
                  position: 'relative',
                }}
              >
                {selectedData !== null && (
                  <IconButton
                    onClick={onSelectCategoryRemove}
                    title="Back to Category"
                    sx={{
                      color: 'white',
                      backgroundColor: '#3D71FF',
                      position: 'absolute',
                      left: '16px',
                      ':hover': {
                        color: 'white',
                        backgroundColor: '#315acc',
                      },
                    }}
                    size="small"
                    variant="contained"
                  >
                    <WestIcon />
                  </IconButton>
                )}
                {selectedData === null ? (
                  <></>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <img
                      style={{
                        width: '22px',
                        height: '22px',
                        objectFit: 'contain',
                        borderRadius: '4px',
                      }}
                      src={selectedData?.megaModuleIcon}
                      alt="megaModuleIcon"
                    />
                    <span style={{ fontSize: '22px' }}>
                      {selectedData?.megaModule} ({selectedData?.faqData?.length})
                    </span>
                  </div>
                )}
                <span>Here are the answers to the questions we get asked the most.</span>
              </div>
              {selectedData === null ? (
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    overflow: 'auto',
                    flexWrap: 'wrap',
                    gap: '16px',
                    justifyContent: 'center',
                    padding: '20px 0px',
                  }}
                >
                  {filterFormattedData.map((e, index) => (
                    <FaqCategoryCard
                      onClick={() => onSelectCategory(e)}
                      key={index}
                      count={e?.faqData?.length}
                      name={e?.megaModule}
                      icon={e?.megaModuleIcon}
                    />
                  ))}
                </div>
              ) : (
                <div
                  data-color-mode="light"
                  style={{
                    flex: 1,
                    display: 'flex',
                    overflow: 'auto',
                    flexDirection: 'column',
                    gap: '16px',
                    padding: '20px 0px',
                  }}
                >
                  {filterFaqData?.map((e, index) => (
                    <Accordion sx={{ flex: 'none' }} key={index} defaultExpanded={index === 0}>
                      <AccordionSummary
                        sx={{ fontWeight: 'bold' }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={e._id}
                        id={e._id}
                      >
                        {e.question}
                      </AccordionSummary>
                      <AccordionDetails>
                        {e.answerMD?.map((exx, ix) => (
                          <MDEditor.Markdown
                            data-color-mode="light"
                            key={ix}
                            source={exx}
                            // style={{ whiteSpace: 'pre-wrap' }}
                          />
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default FaqModalOnly;

const FaqCategoryCard = ({ onClick, name, icon, count }) => {
  return (
    <button
      onClick={onClick}
      style={{
        flex: 'none',
        width: '200px',
        height: '140px',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'flex-end',
        display: 'flex',
        border: '1px solid grey',
        borderRadius: '8px',
        backgroundColor: 'white',
        cursor: 'pointer',
      }}
    >
      <img
        style={{
          width: '50px',
          height: '50px',
          objectFit: 'contain',
          position: 'absolute',
          left: '50%',
          top: '40%',
          transform: 'translate(-50%,-50%)',
        }}
        src={icon}
        alt="asd asd"
      />
      <p style={{ padding: '8px', fontSize: '15px', paddingBottom: '14px', fontWeight: 'bold' }}>
        {name} ({count})
      </p>
    </button>
  );
};
