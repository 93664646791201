/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { Avatar, IconButton, InputAdornment, OutlinedInput, TableContainer, TableRow, Tooltip } from '@mui/material';
import { Image } from 'antd';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import DateField from '../../components/UI/DateField';
import CustomButton from '../../components/UI/CustomButton';
import useSortHook from '../../hooks/useSortHook';
import {
  CustomTableCellForBody,
  CustomTableCellForLoading,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import UserCommentsModal from '../../components/Modals/UserCommentsModal';
import {
  getSignedURL,
  getSafalBuddyReport,
  getSafalBuddyReportDownload,
  firebasePushNotification,
} from '../../services/Service';
import useDebounce from '../../hooks/useDebounce';
import Iconify from '../../components/Iconify';
import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import SafalLeaderBoardReportFilterMenu from './SafalLeaderBoardReportFilterMenu';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import CustomRating from '../../components/UI/CustomRating';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import useProfileImage from '../../hooks/useProfileImage';
import SafalLeaderBoard_Call from '../../assets/SafalLeaderBoard_Call.png';
import SafalLeaderBoard_Chat from '../../assets/SafalLeaderBoard_Chat.png';
import buddyProfile from '../../assets/buddyProfile.png';
import { db } from '../../firebase';
import UserChatModal from '../../components/Modals/UserChatModal';
import SafalBuddyReportFilterMenu from './SafalBuddyReportFilterMenu';
import Inlineloader from '../../components/Loader/InlineLoader';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const SafalBuddyReport = () => {
  const navigate = useNavigate();
  const { formatDateTime } = useUserTimeZone();
  const [filterState, setFilterState] = useState({
    startDate: '',
    endDate: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 50,
  });
  const [metaData, setMetaData] = useState({ yourBuddy: 0, requested: 0, rejected: 0 });
  const [totalData, setTotalData] = useState(0);
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);

  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataFilter, setTableFilterData] = useState([]);
  const { handleSort, newSortData, sortState } = useSortHook(tableDataFilter, ['date']);
  const [userChatModal, setUserChatModal] = useState({
    open: false,
    otherUserId: '',
    type: 'chat', // chat. call
    user: null,
  });

  // DeBounce Function
  useDebounce(
    async () => {
      await fetchSubscriptionsTrend();
    },
    [filterState],
    1000
  );

  const fetchSubscriptionsTrend = async () => {
    setIsLoading(true);
    try {
      const _filterState = { ...filterState };
      if (_filterState.endDate) {
        _filterState.endDate = moment(_filterState.endDate).add(1, 'days').toISOString();
      }
      if (_filterState.startDate) {
        _filterState.startDate = moment(_filterState.startDate).add(1, 'days').toISOString();
      }

      const res = await getSafalBuddyReport({ ..._filterState, ...pagination, application: 'SafalSubscriptions' });
      setTableDataRaw(res.data.data);
      setTotalData(res?.data?.pagination?.total || 0);
      setMetaData(res?.data?.meta);
      const newData = res.data.data.map((ex) => ({
        user: ex?.user,
        date: ex.createdAt,
        message: ex.message,
      }));
      setTableData(newData);
      if (searchValue?.length > 1) {
        const filterData = newData?.filter((e) => e?.message?.toLowerCase()?.includes(searchValue?.toLowerCase()));
        setTableFilterData(filterData);
      } else {
        setTableFilterData(newData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  const downloadTrend = async (type) => {
    try {
      const res = await getSafalBuddyReportDownload({
        ...filterState,
        application: 'SafalSubscriptions',
        fileType: type,
      });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  const onCall = async (user) => {
    const user_id = user?._id;
    if (!user_id) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', user_id));
    if (otherUserInfoRef.exists()) {
      onConnectCall(otherUserInfoRef.data());
    }
  };

  const onConnectCall = async (user) => {
    const currentUserId = currentFirebaseUser.uid;
    const otherUserId = user?.uid;
    // user to user calls
    const otherUserDocDataRef = await getDoc(doc(db, 'users', otherUserId));
    const otherUserDocData = otherUserDocDataRef.data();
    const callId = uuidv4();
    const callLogRef = doc(db, 'callLogs', callId);
    const currentUserCallDocRef = doc(db, 'userCalls', currentUserId);
    const otherUserCallDocRef = doc(db, 'userCalls', otherUserId);
    try {
      const getDocData = await getDoc(otherUserCallDocRef);
      if (getDocData.exists) {
        const data = getDocData.data();
        if (data.isCall) {
          toast('User is busy in another call');
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }

    const callObj = {
      callId: callId,
      isCall: true,
      callMode: 'audio', // audio/video,
      type: 'user',
      status: 'pending', // "pending", "accept", "reject", "end"
      currentUserId: currentUserId, // undefined or string
      otherUserId: otherUserId, // undefined or string
      flag: 'user_to_user', // user_to_user or support_to_user or user_to_support
      startAt: serverTimestamp(),
    };

    await setDoc(callLogRef, callObj);
    await setDoc(currentUserCallDocRef, callObj);
    await setDoc(otherUserCallDocRef, callObj);

    const notificationPayload = {
      data: {
        notification: { image: '', title: 'Calling', body: `${currentFirebaseUser?.displayName} calling you` },
        data: {
          type: 'call',
          messageId: '',
          otheruid: '',
          image_url: otherUserDocData?.photoURL,
          chatID: '',
          displayName: otherUserDocData?.displayName,
          userType: 'USER',
          isAudio: '1',
          OtherUid: currentUserId,
          channelId: callId,
        },
        apns: { payload: { aps: { sound: 'afro_ringtone.mp3', badge: 1 } } },
      },
      userId: otherUserDocData?.userID,
    };
    await firebasePushNotification(notificationPayload);
  };

  const onChat = async (user) => {
    const user_id = user?._id;
    if (!user_id) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', user_id));
    if (otherUserInfoRef.exists()) {
      setUserChatModal({
        open: true,
        otherUserId: user_id,
        type: 'chat', // chat. call
        otherUserInfo: otherUserInfoRef.data(),
      });
    }
  };

  const handleUserChatClose = () => {
    localStorage.removeItem('SafalBuddyChat');
    localStorage.removeItem('SafalBuddyChatNew');
    setUserChatModal({
      open: false,
      otherUserId: '',
      type: 'chat', // chat. call
      user: null,
    });
  };

  const onChangeSearch = (value) => {
    setSearchValue(value);
    if (value?.length > 1) {
      const filterData = tableData?.filter((e) => e?.message?.toLowerCase()?.includes(value?.toLowerCase()));
      setTableFilterData(filterData);
    } else {
      setTableFilterData(tableData);
    }
  };

  if (isLoading) return <Inlineloader />;

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', gap: '16px', marginBottom: '8px' }}>
            <TopCard
              onClick={() => {
                navigate('/safalbuddy?status=accept');
              }}
              name="Your Buddies (active)"
              value={metaData?.yourBuddy}
            />
            <TopCard
              onClick={() => {
                navigate('/safalbuddy?status=pending');
              }}
              name="Requested"
              value={metaData?.requested}
            />
            <TopCard
              onClick={() => {
                navigate('/safalbuddy?status=reject');
              }}
              name="Rejected"
              value={metaData?.rejected}
            />
            <TopCard
              onClick={() => {
                navigate('/safalbuddy?status=block');
              }}
              name="Blocked"
              value={metaData?.blocked || 0}
            />
          </div>
          <div style={{ display: 'flex', gap: '16px' }}>
            <SearchStyle
              sx={{
                width: '200px',
              }}
              value={searchValue}
              onChange={(e) => onChangeSearch(e.target.value)}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
            <SafalBuddyReportFilterMenu state={filterState} onFilterApply={onFilterApply} />
            <Tooltip title="Refresh">
              <IconButton
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={fetchSubscriptionsTrend}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <ReportsTabsDownloadMenu downloadFile={downloadTrend} />
          </div>
        </div>
        <div>
          {tableData.length} of {totalData}
          <TableContainer sx={{ maxHeight: 600 }}>
            <CustomTableView
              isSort
              sort={{
                order: sortState.order,
                orderBy: sortState.orderBy,
                createSortHandler: handleSort,
                sortHeadMap: ['', '', 'message', 'date'],
              }}
              headData={['Buddy Name', '', 'Activity', 'Date and Time']}
            >
              {isLoading && <CustomTableCellForLoading />}
              {!isLoading && tableData.length === 0 && <CustomTableCellForNoData message="We didn't find any data." />}
              {!isLoading &&
                newSortData?.map((e, index) => (
                  <TableRow key={index}>
                    <CustomTableCellForBody align="center">
                      <UserProfile user={e.user} />
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>
                        <CallCard onCall={onCall} onChat={onChat} data={e} />
                      </p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.message}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>
                        {formatDateTime(e?.date)} {getTimeZoneAbbreviation()}
                      </p>
                    </CustomTableCellForBody>
                  </TableRow>
                ))}
            </CustomTableView>
          </TableContainer>
        </div>
        {/* <UserCommentsModal open={isUserCommentsModal.open} onClose={onCloseUserCommentsModal} /> */}
      </div>
      <UserChatModal
        open={userChatModal.open}
        buddyUser={userChatModal.otherUserInfo}
        handleClose={() => handleUserChatClose()}
      />
    </>
  );
};

export default SafalBuddyReport;

const TopCard = ({ name, value, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        height: '80px',
        width: '200px',
        border: '1px solid #bcceff',
        boxShadow: '0px 4px 4px 0px #00000040',
        borderRadius: '8px',
        display: 'flex',
        padding: '8px',
        justifyContent: 'space-between',
        backgroundColor: '#bcceff',
        cursor: 'pointer',
        // background: ' linear-gradient(180deg, #4F79EA 0%, #2D4484 100%)',
      }}
    >
      <img
        style={{
          width: '60px',
          height: '60px',
          objectFit: 'contain',
        }}
        src={buddyProfile}
        alt="buddyProfile"
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ color: '#001E6F' }}>{name}</span>
        <span style={{ color: '#001E6F', fontWeight: 'bold', marginLeft: 'auto' }}>{value}</span>
      </div>
    </div>
  );
};

const UserProfile = ({ user }) => {
  const login = useSelector((state) => state?.login);

  const profileImage = useProfileImage(user?.profilePic);

  const fullName = login?.user?._id === user?._id ? 'You' : `${user?.firstName} ${user?.lastName}`;

  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <Avatar sx={{ height: '40px', width: '40px' }} alt={fullName} src={profileImage} />
      <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
        <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#001E6F' }}>{fullName}</span>
        <span style={{ fontSize: '14px' }}>@ {user?.userID}</span>
      </div>
    </div>
  );
};

const CallCard = ({ onChat, onCall, data }) => {
  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const currentUserToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  const isChatCallEnable = currentUserToUserChatEnable ? otherUserInfo?.userToUserChat === true : false;

  const fetchOtherUserFirebase = async (otherUserId) => {
    if (!otherUserId) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', otherUserId));
    if (otherUserInfoRef.exists()) {
      setOtherUserInfo(otherUserInfoRef.data());
    }
  };

  useEffect(() => {
    fetchOtherUserFirebase(data?.user?._id);
  }, []);

  const isItMe = currentFirebaseUser?.uid === data?.user?._id;

  return (
    <div style={{ display: 'flex', gap: '4px' }}>
      <button
        disabled={isItMe ? true : !isChatCallEnable}
        title="Chat"
        onClick={() => onChat(data?.user)}
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          padding: '2px',
          cursor: 'pointer',
        }}
      >
        <img
          src={SafalLeaderBoard_Chat}
          style={{
            width: '26px',
            height: '26px',
            objectFit: 'contain',
            filter: isItMe ? 'grayscale(1)' : !isChatCallEnable ? 'grayscale(1)' : '',
          }}
          alt="SafalLeaderBoard_Chat"
        />
      </button>
      <button
        disabled={isItMe ? true : !isChatCallEnable}
        title="Call"
        onClick={() => onCall(data?.user)}
        style={{
          border: 'none',
          backgroundColor: 'transparent',
          padding: '2px',
          cursor: 'pointer',
        }}
      >
        <img
          src={SafalLeaderBoard_Call}
          style={{
            width: '26px',
            height: '26px',
            objectFit: 'contain',
            filter: isItMe ? 'grayscale(1)' : !isChatCallEnable ? 'grayscale(1)' : '',
          }}
          alt="SafalLeaderBoard_Call"
        />
      </button>
    </div>
  );
};
