/* eslint-disable no-else-return */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fab, InputAdornment, MenuItem, TextField, Tooltip, IconButton } from '@mui/material';
import toast from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';
import {
  getAssetModuleCompany,
  addAssetModuleCard,
  addAssetModulecompany,
  uploadAssetModuleImages,
  getCompanyType,
} from '../../../services/Service';
import CustomSelect from '../../../components/UI/CustomSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '95vh',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  '@media (max-width: 640px)': {
    width: '95vw',
    height: '95vh',
    overflow: 'auto',
  },
};

const resetFieldValue = {
  type: '',
  name: '',
  companyId: '',
  description: '',
};

const resetCompanyValue = {
  name: '',
  type: '',
  website: '',
  country: 'USA',
  description: '',
  logoUrl: '',
};

export default function AddCustomAssetModal({
  open,
  onClose,
  type,
  callback,
  helperText,
  setCompanyIdError,
  companyIdError,
}) {
  const [fieldValue, setFieldValue] = React.useState(resetFieldValue);
  const [fieldValueCompany, setFieldValueCompanyCompany] = React.useState(resetCompanyValue);

  const [selectedCompanyLogo, setSelectedCompanyLogo] = React.useState(null);
  const [selectedCompanyValue, setSelectedCompanyValue] = React.useState('');
  const [companyList, setCompanyList] = React.useState([]);
  const [companyTypeList, setCompanyTypeList] = React.useState([]);
  const [cardNameError, setCardNameError] = React.useState(false);
  const [selectedCompanyError, setSelectedCompanyError] = React.useState(false);
  const [companyNameError, setCompanyNameError] = React.useState(false);
  const [fileError, setFileError] = React.useState(false);
  const [companyTypeError, setCompanyTypeError] = React.useState(false);
  const [websiteError, setWebsiteError] = React.useState(false);
  const [descError, setDescError] = React.useState(false);
  const [countryErr, setCountryTypeErr] = React.useState(false);

  const handleClose = () => {
    setFieldValue(resetFieldValue);
    setFieldValueCompanyCompany(resetCompanyValue);
    setSelectedCompanyLogo(null);
    setSelectedCompanyValue('');
    setCompanyList([]);
    setCompanyTypeList([]);
    setCardNameError(false);
    setSelectedCompanyError(false);
    setCompanyNameError(false);
    setCompanyTypeError(false);
    setWebsiteError(false);
    setDescError(false);
    setFileError(false);
    onClose();
  };

  const onNameChange = (e) => {
    const cardName = e.target.value;

    // Validate that cardName does not contain special characters
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const isSpecialCharacterPresent = specialCharacterRegex.test(cardName);

    if (isSpecialCharacterPresent) {
      setCardNameError(true);
    } else {
      setCardNameError(false);
      setFieldValue((prev) => ({ ...prev, name: cardName }));
    }

    if (cardName.length < 2 || cardName.length > 250 || /[!@#$%^&*(),.?":{}|<>]/.test(cardName)) {
      setCardNameError(true);
    } else {
      setCardNameError(false);
      setFieldValue((prev) => ({ ...prev, name: cardName }));
    }
  };

  const isValidUrl = (url) => {
    const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return pattern.test(url);
  };

  const onChangeCompanyField = (e) => {
    if (e.target.name === 'description') {
      setDescError(false);
    } else if (e.target.name === 'name') {
      setCompanyNameError(false);
    } else if (e.target.name === 'type') {
      setCompanyTypeError(false);
    } else if (e.target.name === 'website') {
      setDescError(false);
      setWebsiteError(false); // Reset the error state

      const websiteValue = e.target.value.trim();
      if (websiteValue.startsWith('www.')) {
        setFieldValue((prev) => ({ ...prev, website: `https://${websiteValue}` }));
      } else {
        setFieldValue((prev) => ({ ...prev, website: websiteValue }));
      }

      // Check for a valid URL only if it doesn't start with "www."
      if (!websiteValue.startsWith('www.') && !isValidUrl(websiteValue)) {
        setWebsiteError(true);
      }
    }
    console.log('e.target.name--s>', e.target.name);
    setFieldValueCompanyCompany((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSelectCompanyById = (id) => {
    setSelectedCompanyValue(id);
    if (id === 'OTHERS') {
      setFieldValueCompanyCompany(resetCompanyValue);
      setSelectedCompanyError(false);
      return;
    }

    const comData = companyList.find((ex) => ex._id === id);
    const initCompanyValue = {
      name: comData?.name,
      type: comData?.type,
      website: comData?.website,
      country: comData?.country,
      description: comData?.description,
      logoUrl: comData?.logo,
    };
    setFieldValue((prev) => ({ ...prev, companyId: comData._id }));
    setSelectedCompanyLogo(null);
    setFieldValueCompanyCompany(initCompanyValue);
    setSelectedCompanyError(false);
  };

  const onSelectCompany = (e) => {
    setSelectedCompanyValue(e.target.value);
    if (e.target.value === 'OTHERS') {
      setFieldValueCompanyCompany(resetCompanyValue);
      setSelectedCompanyError(false);
      return;
    }

    const comData = companyList.find((ex) => ex._id === e.target.value);
    const initCompanyValue = {
      name: comData?.name,
      type: comData?.type,
      website: comData?.website,
      country: comData?.country,
      description: comData?.description,
      logoUrl: comData?.logo,
    };
    setFieldValue((prev) => ({ ...prev, companyId: comData._id }));
    setSelectedCompanyLogo(null);
    setFieldValueCompanyCompany(initCompanyValue);
    setSelectedCompanyError(false);
  };

  const onSubmit = async () => {
    if (selectedCompanyValue !== 'OTHERS') {
      if (!fieldValue.name) {
        setCardNameError(true);
        return;
      }
      if (!fieldValue.companyId) {
        setSelectedCompanyError(true);
        return;
      }
    } else if (selectedCompanyValue === 'OTHERS') {
      if (
        !fieldValueCompany?.name ||
        fieldValueCompany?.name.trim().length < 3 ||
        fieldValueCompany?.name.length > 100
      ) {
        setCompanyNameError(true);
        return;
      } else if (
        !fieldValueCompany?.type ||
        fieldValueCompany?.type.trim().length < 3 ||
        fieldValueCompany?.type.length > 100
      ) {
        setCompanyTypeError(true);
        return;
      } else if (
        !fieldValueCompany?.website ||
        fieldValueCompany?.website.trim().length < 3 ||
        fieldValueCompany?.website.length > 100
      ) {
        setWebsiteError(true);
        return;
      } else if (
        !fieldValueCompany?.description ||
        fieldValueCompany?.description.trim().length < 3 ||
        fieldValueCompany?.description.length > 100
      ) {
        setDescError(true);
        return;
      } else if (selectedCompanyLogo === null) {
        setFileError(true);
        return;
      }
    }
    try {
      if (selectedCompanyValue === 'OTHERS') {
        // NOTE : 1st Create Company then take that companyId and Create Card
        const imageRes = await uploadAssetModuleImages(selectedCompanyLogo);
        if (imageRes.status !== 200) throw new Error('Image upload failed, please try again');

        const companyPayload = {
          ...fieldValueCompany,
          logoUrl: imageRes.data.url,
        };
        const res = await addAssetModulecompany(companyPayload);
        const resCard = await addAssetModuleCard({ ...fieldValue, companyId: res.data.data._id });
        const companyObj = {
          name: fieldValueCompany?.name,
          type: fieldValueCompany?.type,
          website: fieldValueCompany?.website,
          country: fieldValueCompany?.country,
          description: fieldValueCompany?.description,
        };
        callback(resCard.data.data, companyObj);
        handleClose();
      } else {
        // Note : Create Only Card
        const res = await addAssetModuleCard(fieldValue);
        const companyObj = {
          name: fieldValueCompany?.name,
          type: fieldValueCompany?.type,
          website: fieldValueCompany?.website,
          country: fieldValueCompany?.country,
          description: fieldValueCompany?.description,
        };
        callback(res.data.data, companyObj);
        handleClose();
      }
    } catch (err) {
      if (err.response.data.message === "Cannot read property 'size' of undefined") {
        toast.error('Unsupported File Size');
      } else {
        toast.error(err.response.data.message);
      }
    }
  };

  const logoImageUrl = selectedCompanyLogo ? URL.createObjectURL(selectedCompanyLogo) : fieldValueCompany.logoUrl;

  const isFieldDisable = selectedCompanyValue !== 'OTHERS';

  const fetchCompanyTypeData = async () => {
    try {
      const res = await getCompanyType();
      if (res.status === 200) {
        const typeList = [];
        res.data.data.forEach((e) => {
          if (!typeList.includes(e.type)) typeList.push(e.type);
        });
        setCompanyTypeList(typeList);
      }
    } catch (err) {
      console.log('fetchCompanyTypeData error', err.message);
      toast.error(err.message);
    }
  };
  const fetchCompanyData = async () => {
    try {
      const res = await getAssetModuleCompany();
      if (res.status === 200) {
        setCompanyList(res.data.data);
      }
    } catch (err) {
      console.log('fetchCompanyData error', err.message);
      toast.error(err.message);
    }
  };

  React.useEffect(() => {
    if (!open) return;
    fetchCompanyData();
    fetchCompanyTypeData();
    setFieldValue((prev) => ({ ...prev, type: type }));
  }, [open, type]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={handleClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <Typography variant="h4" gutterBottom sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            {type === 'loyalty' && `Add New Loyalty Card`}
            {type === 'gift' && `Add New Gift Card`}
            {type === 'reward' && `Add New Reward Certificate`}
          </Typography>
          <div className="sm-flex-direction-column" style={{ display: 'flex', gap: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
              <TextField
                value={fieldValue.name}
                onChange={onNameChange}
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    New Card Name <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                size="small"
                type="text"
                fullWidth
                variant="standard"
                error={cardNameError}
                helperText={cardNameError ? 'Please select the card name' : ''}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
              <TextField
                value={fieldValue?.description}
                onChange={(e) => setFieldValue((prev) => ({ ...prev, description: e.target.value }))}
                label={<Typography sx={{ color: '#B6B6B6' }}>Card Description</Typography>}
                size="small"
                type="text"
                fullWidth
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
              <CustomSelect
                id="SelectCompany"
                name="Select Company"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Select Company <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                onChange={onSelectCompanyById}
                // onCustomClick={onAddCustomCompany}
                options={[
                  ...[...companyList].sort((a, b) => a.name.localeCompare(b.name)),
                  { _id: 'OTHERS', name: 'Others' },
                ]}
                selectKey="_id"
                labelKey="name"
                value={selectedCompanyValue}
                helperText={helperText}
                setCompanyIdError={setCompanyIdError}
                companyIdError={companyIdError}
                flagXX1
                sortFalse
                // disabled={editData !== null}
              />

              {!isFieldDisable && (
                <TextField
                  value={fieldValueCompany.name}
                  onChange={onChangeCompanyField}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Company Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  name="name"
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  error={companyNameError}
                  helperText={companyNameError ? 'Please enter company name' : ''}
                  inputProps={{
                    startAdornment: <InputAdornment position="start" />,
                    minLength: 3,
                    maxLength: 100,
                  }}
                />
              )}
              <TextField
                value={fieldValueCompany.type?.toLowerCase()}
                onChange={onChangeCompanyField}
                select
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Company Type <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                name="type"
                size="small"
                fullWidth
                disabled={isFieldDisable}
                variant="standard"
                error={companyTypeError}
                helperText={companyTypeError ? 'Please select company type' : ''}
              >
                {companyTypeList.map((e, index) => (
                  <MenuItem sx={{ textTransform: 'capitalize' }} key={index} value={e?.toLowerCase()}>
                    {e}
                  </MenuItem>
                ))}
              </TextField>
              {/* <TextField
                value={fieldValueCompany.type}
                onChange={onChangeCompanyField}
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Company Type <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                name="type"
                size="small"
                type="text"
                disabled={isFieldDisable}
                fullWidth
                variant="standard"
                error={companyTypeError}
                helperText={companyTypeError ? 'Please enter company type' : ''}
                inputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  minLength: 3,
                  maxLength: 100,
                }}
              /> */}
              <TextField
                value={fieldValueCompany.country}
                onChange={onChangeCompanyField}
                select
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Country <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                name="country"
                size="small"
                fullWidth
                disabled={isFieldDisable}
                defaultValue="USA"
                variant="standard"
              >
                <MenuItem value={'USA'}>USA</MenuItem>
                <MenuItem value={'IND'}>India</MenuItem>
              </TextField>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
              <div style={{ display: 'flex', gap: '12px' }}>
                <TextField
                  onChange={(e) => {
                    setSelectedCompanyLogo(e.target.files[0]);
                    setFileError(false);
                  }}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Company Logo <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  size="small"
                  type="file"
                  fullWidth
                  disabled={isFieldDisable}
                  variant="standard"
                  error={fileError}
                  helperText={fileError ? 'Please select logo' : ''}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                />
                {fieldValueCompany.logoUrl.length > 5 && (
                  <img
                    style={{ objectFit: 'cover', width: '40px', height: '40px', borderRadius: '4px' }}
                    src={logoImageUrl}
                    alt="Company Logo"
                  />
                )}
                {selectedCompanyLogo !== null && (
                  <img
                    style={{ objectFit: 'cover', width: '40px', height: '40px', borderRadius: '4px' }}
                    src={logoImageUrl}
                    alt="Company Logo"
                  />
                )}
              </div>
              <TextField
                value={fieldValueCompany.website}
                onChange={onChangeCompanyField}
                disabled={isFieldDisable}
                name="website"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Website <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                size="small"
                type="text"
                fullWidth
                variant="standard"
                error={websiteError}
                helperText={websiteError ? 'Please insert the valid website' : ''}
                inputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  minLength: 3,
                  maxLength: 100,
                }}
              />
              <TextField
                value={fieldValueCompany.description}
                onChange={onChangeCompanyField}
                disabled={isFieldDisable}
                name="description"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    Description <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                size="small"
                type="text"
                fullWidth
                multiline
                maxRows={3}
                variant="standard"
                error={descError}
                helperText={descError ? 'Please enter the Description (min 3 char , max 100 char)' : ''}
                inputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  minLength: 3,
                  maxLength: 100,
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
            <Button
              sx={{
                textTransform: 'capitalize',
                backgroundColor: '#ff4842',
                color: 'white',
                borderRadius: '30px',
                ':hover': { backgroundColor: '#d40700' },
              }}
              onClick={handleClose}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
              onClick={onSubmit}
              variant="contained"
              type="submit"
            >
              Create
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
