import { Box, Button, Fab, Modal, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useDispatch } from 'react-redux';
import Scrollbar from '../Scrollbar';

const UploadErrorModal = ({ open, setOpen, data }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
    // boxShadow: 24,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Scrollbar sx={{ ...style }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab
              onClick={() => setOpen(false)}
              size="small"
              aria-label="add"
              sx={{ background: theme.palette.secondary.title }}
            >
              <CloseIcon />
            </Fab>
          </Box>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Upload count : {data?.uploadCount}
          </Typography>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Error count : {data?.Errors?.length}
          </Typography>

          <Scrollbar>
            {data?.Uploaded &&
              data?.Uploaded.map((val, idx) => (
                <Typography
                  key={idx}
                  id="alert-dialog-title"
                  sx={{ fontSize: '20px', fontWeight: 500, mb: 1.5, ml: '8%' }}
                >
                  {val}
                </Typography>
              ))}
            {data?.Errors &&
              data?.Errors.map((val, idx) => (
                <Typography
                  key={idx}
                  id="alert-dialog-title"
                  sx={{ fontSize: '20px', fontWeight: 500, mb: 1.5, ml: '8%' }}
                >
                  {val}
                </Typography>
              ))}
          </Scrollbar>

          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              sx={{ background: theme.palette.secondary.title }}
              onClick={(e) => {
                e.preventDefault();
                setOpen(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Scrollbar>
      </Modal>
    </>
  );
};

export default UploadErrorModal;
