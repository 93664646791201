import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const SupportAccessPermission = ({ user }) => {
  const [thisUserState, setThisUserState] = useState({
    isSupportVideoCallAllow: true,
    isSupportAudioCallAllow: true,
  });

  const onAllowVideo = async () => {
    const docRef = doc(db, 'users', user.uid);
    await updateDoc(docRef, {
      isSupportVideoCallAllow: !thisUserState.isSupportVideoCallAllow,
    });
  };

  const onAllowAudio = async () => {
    const docRef = doc(db, 'users', user.uid);
    await updateDoc(docRef, {
      isSupportAudioCallAllow: !thisUserState.isSupportAudioCallAllow,
    });
  };

  useEffect(() => {
    const docRef = doc(db, 'users', user.uid);
    const unsubscribe = onSnapshot(docRef, async (snapshot) => {
      if (snapshot.exists()) setThisUserState(snapshot.data());
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  const isDisplay =
    Object.hasOwn(thisUserState, 'isSupportVideoCallAllow') || Object.hasOwn(thisUserState, 'isSupportAudioCallAllow');

  return (
    <Paper
      elevation={4}
      sx={{
        p: '12px',
        m: '10px',
        display: isDisplay ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',
        width: '400px',
      }}
    >
      <span style={{ marginLeft: '10px', marginBottom: '10px' }}>Allow this user to Call Support</span>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Button
          size="small"
          onClick={onAllowVideo}
          color={thisUserState?.isSupportVideoCallAllow ? 'error' : 'success'}
          variant="contained"
        >
          {thisUserState?.isSupportVideoCallAllow ? 'Refuse Video Call' : 'Allow Video Call'}
        </Button>
        <Button
          size="small"
          onClick={onAllowAudio}
          color={thisUserState?.isSupportAudioCallAllow ? 'error' : 'success'}
          variant="contained"
        >
          {thisUserState?.isSupportAudioCallAllow ? 'Refuse Audio Call' : 'Allow Audio Call'}
        </Button>
      </div>
    </Paper>
  );
};
export default SupportAccessPermission;
