import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { createBanner, uploadBanner } from '../../../services/Service';
import { getPageType } from './TableView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  link: '',
  pageType: '',
};

export default function AddBannerModal({ open, onClose }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [fileValue, setFileValue] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setFileValue(null);
    onClose();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      // upload function
      const res_image = await uploadBanner(fileValue);

      const payload = {
        ...fieldValue,
        value: res_image.data.url,
        type: res_image.data.type,
      };

      const res = await createBanner(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          Add Banner
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <TextField
            onChange={(e) => {
              setFileValue(e.target.files[0]);
            }}
            type="file"
            required
            size="small"
            fullWidth
            variant="outlined"
            InputProps={{
              accept: 'image/*,video/mp4',
            }}
          />
          {/* pageType */}
          <TextField
            value={fieldValue.pageType}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, pageType: e.target.value }))}
            required
            select
            label="Which page this gonna display ?"
            size="small"
            fullWidth
            variant="outlined"
          >
            <MenuItem value={'dashboard'}>{getPageType('dashboard')}</MenuItem>
            <MenuItem value={'offers'}>{getPageType('offers')}</MenuItem>
            <MenuItem value={'offersSquare'}>{getPageType('offersSquare')}</MenuItem>
            <MenuItem value={'safalGift'}>{getPageType('safalGift')}</MenuItem>
            <MenuItem value={'safalRoomPublic'}>{getPageType('safalRoomPublic')}</MenuItem>
            <MenuItem value={'safalRoomPublicSide'}>{getPageType('safalRoomPublicSide')}</MenuItem>
            <MenuItem value={'safalRoomPrivate'}>{getPageType('safalRoomPrivate')}</MenuItem>
            <MenuItem value={'safalRoomPrivateSide'}>{getPageType('safalRoomPrivateSide')}</MenuItem>
            <MenuItem value={'safalBuddyTopLeft'}>{getPageType('safalBuddyTopLeft')}</MenuItem>
            <MenuItem value={'safalBuddyLeft'}>{getPageType('safalBuddyLeft')}</MenuItem>
          </TextField>
          <TextField
            value={fieldValue.link}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, link: e.target.value }))}
            required
            label="Link"
            name="Link"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
            // error={error}
            // helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
          />
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button disabled={isSubmitting} onClick={handleClose} sx={{ backgroundColor: '#19c25f' }} variant="contained">
            Cancel
          </Button>
          <Button disabled={isSubmitting} onClick={onSubmit} sx={{ backgroundColor: '#19c25f' }} variant="contained">
            Create
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
