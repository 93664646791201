import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import safalAiImage from '../../assets/ai-chat-logo.png';
import useUserProfileImage from '../../hooks/useUserProfileImage';
import { getCompanyType, safalAiSend } from '../../services/Service';

const customCompanyMatchString = `company doesn't exist.\nAdd this company to our custom list by selecting company type.\nEnter "cancel" to close this action.`;

const ChatMessageX = ({ type, data, fetchChatList, isLast }) => {
  const { user } = useSelector((state) => state.login);
  const { formatTime, formatDate2 } = useUserTimeZone();
  const userProfileImage = useUserProfileImage();
  const userFullName = `${user.firstName} ${user.lastName}`;

  return (
    <>
      <div className={`ai-chat-message ${data.from !== 'SafalSubscriptions AI' && 'self'}`}>
        <div className="image-div">
          <img
            className="ai-image"
            src={data.from === 'SafalSubscriptions AI' ? safalAiImage : userProfileImage}
            alt="safal AI"
          />
        </div>
        <div style={{ maxWidth: '70%' }}>
          <div
            style={{
              backgroundColor: data.from === 'SafalSubscriptions AI' ? '#eaeaea' : '#ED8A0E',
              padding: '12px',
              borderRadius: '10px',
              borderBottomLeftRadius: data.from === 'SafalSubscriptions AI' ? '0px' : '10px',
              borderBottomRightRadius: data.from === 'SafalSubscriptions AI' ? '10px' : '0px',
              color: data.from === 'SafalSubscriptions AI' ? 'black' : 'white',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>
              {data.from === 'SafalSubscriptions AI' ? data.from : userFullName}
            </span>
            <p style={{ whiteSpace: 'pre-line' }}>{data.message}</p>
            {data?.message?.includes(customCompanyMatchString) && (
              <SelectField type={type} fetchChatList={fetchChatList} message={data?.message} disable={!isLast} />
            )}
          </div>
          <span style={{ fontSize: '12px', color: '#B6B6B6' }}>
            {formatTime(data.createdAt)} {getTimeZoneAbbreviation()}
          </span>
        </div>
      </div>
      {data?.isDate && (
        <span style={{ textAlign: 'center' }}>
          {formatDate2(data.createdAt)} {getTimeZoneAbbreviation()}
        </span>
      )}
    </>
  );
};
export default ChatMessageX;

const SelectField = ({ type, disable, message, fetchChatList }) => {
  const [selectedType, setSelectedType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [internalDisable, setInternalDisable] = useState(true);

  const fetchCompanyType = async () => {
    try {
      setLoading(true);
      const res = await getCompanyType();
      setSelectedType(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setInternalDisable(disable);
    if (disable) return;
    fetchCompanyType();
  }, [disable]);

  const onSelectCompanyType = async (value) => {
    try {
      const companyName = message.split(customCompanyMatchString)[0].trim();
      const finalText = `create custom company "${companyName}" type "${value}"`;
      await safalAiSend({ text: finalText, type: type });
      setInternalDisable(true);
      fetchChatList();
    } catch (err) {
      console.log(err);
    }
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      loading={loading}
      disabled={internalDisable}
      dropdownStyle={{ zIndex: 99900 }}
      style={{ width: '50%' }}
      showSearch
      placeholder="Select company type"
      optionFilterProp="children"
      onChange={onSelectCompanyType}
      size="small"
      filterOption={filterOption}
      options={selectedType.map((e) => ({
        value: e.type,
        label: e.type,
      }))}
    />
  );
};
