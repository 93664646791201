import {
  Button,
  InputAdornment,
  OutlinedInput,
  Pagination,
  Switch,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './AssetModule.css';
import { Helmet } from 'react-helmet-async';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';

import TrackModal from '../../../components/Modals/TrackModal';
import AddAssetModal from './AddAssetModal';
import {
  getAssetModuleAccounts,
  getAssetModuleCards,
  deleteAssetModuleAccount,
  getAssetModuleAccountSingle,
  getPaymentMode,
  getPaymentInstrument,
  getCardScheme,
} from '../../../services/Service';
import AssetModuleFilterMenu from './AssetModuleFilterMenu';
import AssetModuleDownloadMenu from './AssetModuleDownloadMenu';
import CustomButton from '../../../components/UI/CustomButton';
import CheckboxAction from './CheckboxAction';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import AssetCard from './AssetCard';
import TableView from './TableView';
import HeySafalSpeechRecognition from '../../../components/HeySafalSpeechRecognition/HeySafalSpeechRecognition';
import FaqModal from '../../../components/Modals/FaqModal';
import useSortHook from '../../../hooks/useSortHook';
import AssetSortMenu from './AssetSortMenu';

import assetMetaActiveIcon from '../../../assets/subMetaActive.png';
import assetMetaInactiveIcon from '../../../assets/assetMetaInactive.png';
import assetMetaExpiryIcon from '../../../assets/assetMetaExpiry.png';
import assetInactiveValue from '../../../assets/assetInactiveValue.png';
import assetActiveValue from '../../../assets/assetActiveValue.png';
import InitialFeedbackModal from '../../../components/InitialFeedback/InitialFeedbackModal';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const initFilterState = {
  companyId: '',
  pointsMin: '',
  pointsMax: '',
  expiryTo: '',
  expiryFrom: '',
  companyType: '',
  status: '',
};

const fineInitMenu = (assetsDynamicMenuState) => {
  const submenu = assetsDynamicMenuState.submenu;
  if (submenu.includes('loylty program')) return 'loyalty';
  if (submenu.includes('gift card')) return 'gift';
  if (submenu.includes('reward certificate')) return 'reward';
};

const AssetModule = () => {
  const login = useSelector((state) => state.login);
  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);
  const { registered } = login.user;
  const location = useLocation();
  const navigate = useNavigate();
  const { stateFilter } = location.state || {};
  const [rawData, setRawData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [paginationData, setPaginationData] = useState({
    total: 0,
    page: 1,
    limit: 25,
  });

  const [applyFilterState, setApplyFilterState] = useState(initFilterState);
  const [isTableView, setIsTableView] = useState(false);
  const [openInitialFeedback, setOpenInitialFeedback] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState([]);
  const [assetMetaInfo, setAssetMetaInfo] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const assetsDynamicMenuState = dynamicMenuState?.find((e) => e?.menu === 'assets');
  const [selectedToggle, setSelectedToggle] = useState(fineInitMenu(assetsDynamicMenuState));
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState({
    open: false,
    isDisplayMode: false,
  });
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });
  const [editData, setEditData] = useState(null);
  const [openChangeLogModal, setOpenChangeLogModal] = useState({
    open: false,
    data: null,
  });
  const { handleSort, newSortData, sortState } = useSortHook(accountData, ['expiryDate']);

  const [paymentModeList, setPaymentModeList] = useState([]);
  const [paymentInstrumentList, setPaymentInstrumentList] = useState([]);
  const [paymentCardSchemeList, setPaymentCardSchemeList] = useState([]);

  const fetchPaymentModeInfo = async () => {
    try {
      const res1 = await getPaymentMode({ status: true });
      setPaymentModeList(res1.data.data);
      const res2 = await getPaymentInstrument({ status: true });
      setPaymentInstrumentList(res2.data.data);
      const res3 = await getCardScheme({ status: true });
      setPaymentCardSchemeList(res3.data.data);
      return res2.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  const onAddAssetModalOpen = (isDisplayMode) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setIsAddAssetModalOpen({
        open: true,
        isDisplayMode: isDisplayMode,
      });
      fetchAllCardsData(selectedToggle);
    }
  };

  const onAddAssetModalClose = () => {
    setIsAddAssetModalOpen({
      open: false,
      isDisplayMode: false,
    });
    setEditData(null);
    fetchData(paginationData.page, paginationData.limit, selectedToggle, applyFilterState);
    window.history.replaceState({}, document.title);
  };

  const onChangeToggleValue = (value) => {
    setSelectedToggle(value);
    navigate(location.pathname);
  };

  const onChangeSearchValue = (e) => {
    setSearchValue(e.target.value);
    const value = e.target.value.toLowerCase().trim();
    const res = rawData.filter(
      (ex) =>
        ex?.cardId?.name.toLowerCase().includes(value) ||
        ex?.companyId?.name.toLowerCase().includes(value) ||
        ex?.cardNumber.toLowerCase().includes(value)
    );
    setAccountData(res);
  };

  const onSelectedChange = (id) => {
    setSelectedAccount((prev) => {
      if (prev.includes(id)) {
        return prev.filter((e) => e !== id);
      }
      return [...prev, id];
    });
  };

  const fetchData = async (page, limit, type, filter) => {
    setLoading(true);
    try {
      const res = await getAssetModuleAccounts(page, limit, type, filter);
      if (res.status === 200) {
        setRawData(res.data.data);
        setAccountData(res.data.data);
        setPaginationData({
          total: res.data.totalCount,
          page: page,
          limit: limit,
        });
        setAssetMetaInfo(res.data?.metaData);
        setApplyFilterState(filter);
        setSearchValue('');
        if (res?.data?.initialFeedback === 1) {
          setOpenInitialFeedback(true);
        }
      }
    } catch (err) {
      console.log('AssetModule fetchData error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllCardsData = async (type) => {
    try {
      const res = await getAssetModuleCards(1, 1000, type);
      if (res.status === 200) {
        setCardData(res.data.data);
      }
    } catch (err) {
      console.log('AssetModule fetchData error', err.message);
    }
  };

  const onOpenEdit = (data, flag) => {
    onOpenEditWithPassword(flag, data);
  };

  const onOpenEditWithPassword = async (flag, data) => {
    try {
      const res = await getAssetModuleAccountSingle(data._id);
      if (res.status === 200) {
        onAddAssetModalOpen(flag);
        setEditData(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const handleChangePage = async (e, value) => {
    await fetchData(value, paginationData.limit, selectedToggle, applyFilterState);
  };

  const handleChangeRowsPerPage = async (e) => {
    await fetchData(paginationData.page, e.target.value, selectedToggle, applyFilterState);
  };

  const onFilterApply = async (newState) => {
    await fetchData(paginationData.page, paginationData.limit, selectedToggle, newState);
  };

  const onFilterReset = async () => {
    await fetchData(paginationData.page, paginationData.limit, selectedToggle, initFilterState);
  };

  useEffect(() => {
    if (stateFilter) {
      onOpenEdit(stateFilter, false);
    }
    fetchPaymentModeInfo();
    fetchData(paginationData.page, paginationData.limit, selectedToggle, initFilterState);
  }, [selectedToggle]);

  const onDelete = async (data) => {
    try {
      const res = await deleteAssetModuleAccount(data._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData(paginationData.page, paginationData.limit, selectedToggle, applyFilterState);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onCheckActionEdit = (id) => {
    const data = rawData.find((e) => e._id === id);
    onOpenEdit(data, false);
  };

  const onCheckActionDelete = async (ids) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data: ids,
      isBulk: true,
    });
  };
  const onCheckActionDeleteBulk = async (ids) => {
    try {
      const res = await deleteAssetModuleAccount(ids.join(','));
      if (res.status === 200) {
        toast.success(res.data.message);
        setSelectedAccount([]);
        setIsDeleteConfirmationModalOpen({
          open: false,
          data: null,
        });
        fetchData(paginationData.page, paginationData.limit, selectedToggle, applyFilterState);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalInit = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data,
    });
  };

  const onDeleteModalReset = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  // Change Log
  const onCloseChangeLog = () => {
    setOpenChangeLogModal({
      data: null,
      open: false,
    });
  };
  // Change Log
  const onOpenChangelog = (data) => {
    setOpenChangeLogModal({
      data: data,
      open: true,
    });
  };

  const CurrSymbol = login?.user?.country === 'United States' ? '$' : '₹';

  console.log('DEBUG assetMetaInfo', assetMetaInfo);

  return (
    <>
      <Helmet>
        <title>Asset Module - SafalSubscriptions</title>
      </Helmet>

      <div id="AssetModule">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
              Your Assets
            </Typography>
            <FaqModal module="Asset" color="#3D71FF" />
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <div className="card-view-switch">
              <span>Card View</span>
              <Switch
                checked={isTableView}
                onChange={() => setIsTableView((prev) => !prev)}
                style={{ color: '#3D71FF' }}
              />
              <span>List View</span>
            </div>
            <div style={{ display: 'flex', gap: '12px', marginLeft: 'auto' }}>
              {assetsDynamicMenuState.submenu.includes('loylty program') && (
                <Button
                  onClick={() => onChangeToggleValue('loyalty')}
                  startIcon={
                    <img
                      style={{ height: '16px', objectFit: 'contain' }}
                      src={
                        selectedToggle === 'loyalty'
                          ? '/assets/images/dashboard3.png'
                          : '/assets/images/LoyaltyCardBlue.png'
                      }
                      alt="Loyalty Card"
                    />
                  }
                  sx={{
                    backgroundColor: selectedToggle === 'loyalty' ? '#3D71FF' : '#f2f2f2',
                    borderRadius: '999px',
                    color: selectedToggle === 'loyalty' ? 'white' : '#3D71FF',
                    border: '1px solid #3D71FF',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: selectedToggle === 'loyalty' ? '#3D71FF' : '#f2f2f2',
                      color: selectedToggle === 'loyalty' ? 'white' : '#3D71FF',
                    },
                    textTransform: 'initial',
                  }}
                  variant="contained"
                >
                  Loyalty Card
                </Button>
              )}
              {assetsDynamicMenuState.submenu.includes('gift card') && (
                <Button
                  onClick={() => onChangeToggleValue('gift')}
                  startIcon={
                    <img
                      style={{ height: '16px', objectFit: 'contain' }}
                      src={
                        selectedToggle === 'gift'
                          ? '/assets/images/GiftDashboard.png'
                          : '/assets/images/giftBlueInon.png'
                      }
                      alt="Gift Card"
                    />
                  }
                  sx={{
                    backgroundColor: selectedToggle === 'gift' ? '#3D71FF' : '#f2f2f2',
                    borderRadius: '999px',
                    color: selectedToggle === 'gift' ? 'white' : '#3D71FF',
                    border: '1px solid #3D71FF',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: selectedToggle === 'gift' ? '#3D71FF' : '#f2f2f2',
                      color: selectedToggle === 'gift' ? 'white' : '#3D71FF',
                    },
                    textTransform: 'initial',
                  }}
                  variant="contained"
                >
                  Gift Card
                </Button>
              )}
              {assetsDynamicMenuState.submenu.includes('reward certificate') && (
                <Button
                  onClick={() => onChangeToggleValue('reward')}
                  startIcon={
                    <img
                      style={{ height: '16px', objectFit: 'contain' }}
                      src={
                        selectedToggle === 'reward'
                          ? '/assets/images/RewardDashboard.png'
                          : '/assets/images/rewardBlueIcon.png'
                      }
                      alt="Reward Certificate"
                    />
                  }
                  sx={{
                    backgroundColor: selectedToggle === 'reward' ? '#3D71FF' : '#f2f2f2',
                    borderRadius: '999px',
                    color: selectedToggle === 'reward' ? 'white' : '#3D71FF',
                    border: '1px solid #3D71FF',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: selectedToggle === 'reward' ? '#3D71FF' : '#f2f2f2',
                      color: selectedToggle === 'reward' ? 'white' : '#3D71FF',
                    },
                    textTransform: 'initial',
                  }}
                  variant="contained"
                >
                  Reward Certificate
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            {selectedAccount.length > 0 && (
              <CheckboxAction
                selectedAccount={selectedAccount}
                onEdit={onCheckActionEdit}
                onDelete={onCheckActionDelete}
              />
            )}
            <SearchStyle
              onChange={onChangeSearchValue}
              value={searchValue}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
          </div>
          <div className="title-action-div">
            {/* Download */}
            <AssetModuleDownloadMenu cardType={selectedToggle} />
            {/* Sort */}
            {!isTableView && (
              <AssetSortMenu
                sortState={sortState}
                onChange={(ex) => handleSort(ex.orderBy)}
                sort={[
                  { value: 'cardId.name', label: 'Card Name' },
                  { value: 'cardNumber', label: 'Card Number' },
                  { value: 'points', label: selectedToggle === 'loyalty' ? 'Points' : 'Values' },
                  { value: 'companyId.name', label: 'Company Name' },
                  { value: 'status', label: 'Status' },
                  { value: 'expiryDate', label: 'Expiry Date' },
                ]}
              />
            )}
            {/* FIlter */}
            <AssetModuleFilterMenu
              state={applyFilterState}
              onFilterApply={onFilterApply}
              onFilterReset={onFilterReset}
              type={selectedToggle}
            />
            <Tooltip title="Refresh">
              <IconButton
                sx={{
                  height: '40px',
                  flex: 'none',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={() => fetchData(paginationData.page, paginationData.limit, selectedToggle, initFilterState)}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            {/* Safal Ai */}
            <HeySafalSpeechRecognition
              type="asset"
              onClose={() => fetchData(paginationData.page, paginationData.limit, selectedToggle, initFilterState)}
            />
            {/* add */}
            <Tooltip title="Add new asset">
              <IconButton
                onClick={() => onAddAssetModalOpen(false)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#3D71FF',
                  height: '40px',
                  width: '40px',
                  '&:hover': { backgroundColor: '#3D71FF' },
                }}
              >
                <AddIcon sx={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', justifyContent: 'center' }}>
          <AssetMetaCard
            imgSrc={assetMetaActiveIcon}
            title={'Active Card'}
            value={
              assetMetaInfo?.activeCount !== undefined && assetMetaInfo?.activeCount !== null
                ? `${assetMetaInfo?.activeCount} Nos`
                : `0 Nos`
            }
          />
          {selectedToggle === 'loyalty' ? (
            <AssetMetaCard
              imgSrc={assetMetaInactiveIcon}
              title={'Inactive Card'}
              value={
                assetMetaInfo?.inactiveCount !== undefined && assetMetaInfo?.inactiveCount !== null
                  ? `${assetMetaInfo?.inactiveCount} Nos`
                  : `0 Nos`
              }
            />
          ) : (
            <AssetMetaCard
              imgSrc={assetInactiveValue}
              title={'Inactive Value'}
              value={
                assetMetaInfo?.totalInactiveValue !== undefined && assetMetaInfo?.totalInactiveValue !== null
                  ? `${CurrSymbol} ${new Intl.NumberFormat().format(assetMetaInfo?.totalInactiveValue)}`
                  : `${CurrSymbol} 0`
              }
            />
          )}
          {selectedToggle === 'loyalty' ? (
            <AssetMetaCard
              imgSrc={assetMetaExpiryIcon}
              title={'Expiry Card'}
              value={
                assetMetaInfo?.expiryCount !== undefined && assetMetaInfo?.expiryCount !== null
                  ? `${assetMetaInfo?.expiryCount} Nos`
                  : `0 Nos`
              }
            />
          ) : (
            <AssetMetaCard
              imgSrc={assetActiveValue}
              title={'Active Value'}
              value={
                assetMetaInfo?.totalActiveValue !== undefined && assetMetaInfo?.totalActiveValue !== null
                  ? `${CurrSymbol} ${new Intl.NumberFormat().format(assetMetaInfo?.totalActiveValue)}`
                  : `${CurrSymbol} 0`
              }
            />
          )}
        </div>
        <span>{!isTableView && `${accountData.length} of ${accountData.length}`}</span>
        {!isTableView && (
          <div style={{ display: accountData.length === 0 && 'flex' }} className="main-content">
            {!loading && accountData.length === 0 && (
              <span style={{ margin: 'auto' }}>
                There are no recorded{' '}
                {selectedToggle === 'loyalty'
                  ? 'loyalty card'
                  : selectedToggle === 'gift'
                  ? 'gift card'
                  : 'reward certificate'}{' '}
                for you. Kindly add a new one.
              </span>
            )}
            {accountData.length !== 0 &&
              newSortData.map((e, index) => (
                <AssetCard
                  onDelete={onDeleteModalInit}
                  key={index}
                  data={e}
                  selected={selectedAccount.includes(e._id)}
                  onSelectedChange={onSelectedChange}
                  onOpenEdit={onOpenEdit}
                  onChangeLog={onOpenChangelog}
                  selectedToggle={selectedToggle}
                />
              ))}
          </div>
        )}
        {isTableView && (
          <TableView
            selectedAccount={selectedAccount}
            onSelectedChange={onSelectedChange}
            onDelete={onDeleteModalInit}
            onEdit={onOpenEdit}
            data={accountData}
            paginationData={paginationData}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            selectedToggle={selectedToggle}
            onChangeLog={onOpenChangelog}
          />
        )}
        {!isTableView && (
          <div className="main-pagination">
            <Pagination
              size="small"
              count={Math.ceil(paginationData.total / paginationData.limit)}
              rowsPerPageOptions={[25, 50, 100, 150, 200]}
              rowsPerPage={paginationData.limit}
              page={paginationData.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
      <AddAssetModal
        fetchPaymentModeInfo={fetchPaymentModeInfo}
        paymentCardSchemeList={paymentCardSchemeList}
        paymentInstrumentList={paymentInstrumentList}
        paymentModeList={paymentModeList}
        editData={editData}
        type={selectedToggle}
        cardData={cardData}
        open={isAddAssetModalOpen.open}
        onClose={onAddAssetModalClose}
        isDisplayMode={isAddAssetModalOpen.isDisplayMode}
        onChangeLog={onOpenChangelog}
      />
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={isDeleteConfirmationModalOpen?.isBulk ? onCheckActionDeleteBulk : onDelete}
      />

      <TrackModal openTrackModal={openChangeLogModal.open} onClose={onCloseChangeLog} data={openChangeLogModal.data} />
      <InitialFeedbackModal open={openInitialFeedback} onClose={() => setOpenInitialFeedback(false)} />
    </>
  );
};

export default AssetModule;

const AssetMetaCard = ({ title, value, valueColor, imgSrc }) => {
  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        position: 'relative',
        height: '80px',
        width: '200px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        filter: 'drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))',
        padding: '8px',
        alignItems: 'center',
      }}
    >
      <span style={{ fontWeight: 'bold', color: '#3D71FF', alignSelf: 'start', marginLeft: '8px' }}>{title}</span>
      <span style={{ fontWeight: 'bold', color: valueColor || 'black' }}>{value || ''}</span>
      <img
        style={{
          position: 'absolute',
          height: '50%',
          objectFit: 'cover',
          top: '25%',
          right: '16px',
          filter: 'invert(0.9)',
        }}
        src={imgSrc}
        alt="metaCardIcon01"
      />
    </div>
  );
};
