import { IconButton, MenuItem, TablePagination, TextField, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import SideBanner from './SideBanner';
import SelectBar from './SelectBar';
import BuddyProfileTop from './BuddyProfileTop';
import CustomSearch from '../../../components/UI/CustomSearch';
import CustomSortMenu from '../../../components/UI/CustomSortMenu';
import FilterMenu from './FilterMenu';
import BuddyCard from './BuddyCard';
import Inlineloader from '../../../components/Loader/InlineLoader';
import { firebasePushNotification, getNewBuddyList } from '../../../services/Service';
import useDebounce from '../../../hooks/useDebounce';
import BuddyInvitePage from './BuddyInvitePage';
import UserChatModal from '../../../components/Modals/UserChatModal';
import { db } from '../../../firebase';
import FaqModal from '../../../components/Modals/FaqModal';

const initFilter = {
  assetMin: '',
  assetMax: '',
  subscriptionMin: '',
  subscriptionMax: '',
  mutualBuddyMin: '',
  mutualBuddyMax: '',
  startDate: '',
  endDate: '',
  mutualRoomMin: '',
  mutualRoomMax: '',
  tier: '',
};

const SafalBuddyPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const statusParam = searchParams.get('status');

  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const [selectedMode, setSelectedMode] = useState(statusParam ?? 'accept'); // accept,pending,reject,invite, block
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pendingStatusMode, setPendingStatusMode] = useState('Received');
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 6,
    total: 0,
  });
  const [sortState, setSortState] = useState({
    sort: '',
    order: '',
  });
  const [myMetaInfo, setMyMetaInfo] = useState({
    safalBuddies: 0,
    pendingSafalBuddies: 0,
    rejectedSafalBuddies: 0,
    blockedUsers: 0,
    subscriptions: 0,
    assets: 0,
  });
  const [filter, setFilter] = useState(initFilter);

  const fetchData = async (newFilter) => {
    try {
      setLoading(true);
      const res = await getNewBuddyList(newFilter);
      if (res.status === 200 && res.data.success === true) {
        setData(res.data.data);
        setMyMetaInfo(res.data.meta);
        setPagination({
          page: res.data?.pagination?.currentPage ?? 1,
          limit: res.data?.pagination?.limit ?? 6,
          total: res.data?.pagination?.total ?? 0,
        });
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedMode !== 'invite') fetchData({ ...filter, ...pagination, search: searchValue, status: selectedMode });
  }, [selectedMode]);

  const onChangeMode = (newValue) => {
    setSelectedMode(newValue);
  };

  const onSearchChange = (value) => {
    setSearchValue(value);
  };

  const handleChangePage = (_, value) => {
    fetchData({
      ...filter,
      ...pagination,
      search: searchValue,
      status: selectedMode,
      page: value + 1,
      order: sortState.order,
      orderBy: sortState.sort,
    });
  };

  const handleChangeRowsPerPage = (e) => {
    fetchData({
      ...filter,
      ...pagination,
      search: searchValue,
      status: selectedMode,
      limit: e.target.value,
      page: 1,
      order: sortState.order,
      orderBy: sortState.sort,
    });
  };

  useDebounce(
    async () => {
      await fetchData({
        ...filter,
        ...pagination,
        search: searchValue,
        status: selectedMode,
        order: sortState.order,
        orderBy: sortState.sort,
      });
    },
    [searchValue],
    2000
  );

  const onFilterReset = () => {
    setFilter(initFilter);
    fetchData({
      ...initFilter,
      ...pagination,
      search: searchValue,
      status: selectedMode,
      order: sortState.order,
      orderBy: sortState.sort,
    });
  };
  const onFilterApply = (newFilterState) => {
    fetchData({
      ...newFilterState,
      ...pagination,
      search: searchValue,
      status: selectedMode,
      order: sortState.order,
      orderBy: sortState.sort,
    });
    setFilter(newFilterState);
  };

  const refetch = () => {
    fetchData({
      ...filter,
      ...pagination,
      search: searchValue,
      status: selectedMode,
      order: sortState.order,
      orderBy: sortState.sort,
    });
  };

  // FIREBASE call and chat
  const [userChatModal, setUserChatModal] = useState({
    open: false,
    otherUserId: '',
    type: 'chat', // chat. call
    user: null,
  });
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const onCallClick = async (otherUserId, otherUserInfo, type) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    onConnectCall(otherUserInfo, type);
  };
  const onConnectCall = async (user, type) => {
    const currentUserId = currentFirebaseUser.uid;
    const otherUserId = user?.uid;
    // user to user calls
    const otherUserDocDataRef = await getDoc(doc(db, 'users', otherUserId));
    const otherUserDocData = otherUserDocDataRef.data();
    const callId = uuidv4();
    const callLogRef = doc(db, 'callLogs', callId);
    const currentUserCallDocRef = doc(db, 'userCalls', currentUserId);
    const otherUserCallDocRef = doc(db, 'userCalls', otherUserId);
    try {
      const getDocData = await getDoc(otherUserCallDocRef);
      if (getDocData.exists) {
        const data = getDocData.data();
        if (data.isCall) {
          toast('User is busy in another call');
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }

    const callObj = {
      callId: callId,
      isCall: true,
      callMode: type, // audio/video,
      type: 'user',
      status: 'pending', // "pending", "accept", "reject", "end"
      currentUserId: currentUserId, // undefined or string
      otherUserId: otherUserId, // undefined or string
      flag: 'user_to_user', // user_to_user or support_to_user or user_to_support
      startAt: serverTimestamp(),
    };

    await setDoc(callLogRef, callObj);
    await setDoc(currentUserCallDocRef, callObj);
    await setDoc(otherUserCallDocRef, callObj);

    const notificationPayload = {
      data: {
        notification: { image: '', title: 'Calling', body: `${currentFirebaseUser?.displayName} calling you` },
        data: {
          type: 'call',
          messageId: '',
          otheruid: '',
          image_url: otherUserDocData?.photoURL,
          chatID: '',
          displayName: otherUserDocData?.displayName,
          userType: 'USER',
          isAudio: '1',
          OtherUid: currentUserId,
          channelId: callId,
        },
        apns: { payload: { aps: { sound: 'afro_ringtone.mp3', badge: 1 } } },
      },
      userId: otherUserDocData?.userID,
    };
    await firebasePushNotification(notificationPayload);
  };
  const onChatClick = async (otherUserId, otherUserInfo) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    setUserChatModal({
      open: true,
      otherUserId: otherUserId,
      type: 'chat', // chat. call
      otherUserInfo: otherUserInfo,
    });
  };
  const handleUserChatClose = () => {
    localStorage.removeItem('SafalBuddyChat');
    localStorage.removeItem('SafalBuddyChatNew');
    setUserChatModal({
      open: false,
      otherUserId: '',
      type: 'chat', // chat. call
      user: null,
    });
  };
  // END FIREBASE call and chat

  const onClickInvite = () => {
    setSelectedMode('invite');
  };

  const onSortChange = (newSort) => {
    setSortState(newSort);
    fetchData({
      ...filter,
      ...pagination,
      search: searchValue,
      status: selectedMode,
      order: newSort.order,
      orderBy: newSort.sort,
    });
  };

  const newFilterData = selectedMode === 'pending' ? filterPendingDataByStatus(data, pendingStatusMode) : data;

  const pendingReceivedCount = data?.filter((e) => e?.metaUser?._id !== e?.receiver?._id).length;
  const pendingSentCount = data?.filter((e) => e?.metaUser?._id === e?.receiver?._id).length;

  if (loading) return <Inlineloader />;
  return (
    <>
      <Helmet>
        <title>SafalBuddy - SafalSubscriptions</title>
      </Helmet>
      <div id="SafalBuddyPage" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 'none', marginBottom: '8px' }}>
          <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            SafalBuddy
          </Typography>
          <FaqModal module="SafalBuddy" color="#3D71FF" />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {/* <BuddyProfileTop meta={myMetaInfo} /> */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginRight: '24px' }}>
              <SelectBar value={selectedMode} onChange={onChangeMode} meta={myMetaInfo} />
              <Tooltip title="Invite User">
                <IconButton
                  size="small"
                  onClick={onClickInvite}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // ml: 2,
                    backgroundColor: selectedMode === 'invite' ? '#3D71FF' : 'white',
                    border: '1px solid #3D71FF',
                    height: '40px',
                    width: '40px',
                    '&:hover': { backgroundColor: selectedMode === 'invite' ? '#3D71FF' : 'white' },
                  }}
                >
                  <img
                    src={
                      selectedMode === 'invite'
                        ? '/assets/images/buddy/add_user.png'
                        : '/assets/images/buddy/add_user_b.png'
                    }
                    style={{ height: '24px', width: '24px', objectFit: 'contain' }}
                    alt="idd"
                  />
                </IconButton>
              </Tooltip>
            </div>
            {selectedMode !== 'invite' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    margin: '16px 16px',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <CustomSearch
                      value={searchValue}
                      onChange={(e) => onSearchChange(e.target.value)}
                      placeholder="Search..."
                    />
                    {selectedMode === 'pending' && (
                      <TextField
                        value={pendingStatusMode}
                        onChange={(e) => setPendingStatusMode(e.target.value)}
                        select
                        size="small"
                        variant="outlined"
                        sx={{
                          width: '150px',
                        }}
                      >
                        <MenuItem value="Received">
                          Received{pendingReceivedCount > 0 && ` (${pendingReceivedCount})`}
                        </MenuItem>
                        <MenuItem value="Sent">Sent{pendingSentCount > 0 && ` (${pendingSentCount})`}</MenuItem>
                      </TextField>
                    )}
                    <FilterMenu filterState={filter} onFilterReset={onFilterReset} onFilterApply={onFilterApply} />
                    <CustomSortMenu
                      size={'medium'}
                      sortState={sortState}
                      onChange={onSortChange}
                      sort={[
                        { value: 'firstName', label: 'First Name' },
                        { value: 'lastName', label: 'LastName' },
                        { value: 'mutualBuddyCount', label: 'Mutual Buddy' },
                      ]}
                    />
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <TablePagination
                      component="div"
                      size="small"
                      count={pagination?.total}
                      rowsPerPageOptions={[6, 10, 15, 20]}
                      rowsPerPage={pagination?.limit}
                      page={pagination?.page - 1}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
                {newFilterData?.length > 0 ? (
                  <div
                    style={{
                      gridTemplateColumns: 'repeat(2, 1fr)',
                      display: 'grid',
                      gap: '16px',
                      margin: '0px 16px',
                      maxHeight: '440px',
                      overflow: 'auto',
                    }}
                  >
                    {newFilterData?.map((e, index) => (
                      <BuddyCard
                        pendingStatusMode={pendingStatusMode}
                        key={index}
                        data={e}
                        mode={selectedMode}
                        refetch={refetch}
                        onChat={onChatClick}
                        onCall={onCallClick}
                      />
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      margin: '0px 16px',
                      height: '400px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <span>No Buddies Found</span>
                  </div>
                )}
              </>
            ) : (
              <BuddyInvitePage onChat={onChatClick} onCall={onCallClick} />
            )}
          </div>
          <SideBanner type="safalBuddyTopLeft" type2="safalBuddyLeft" />
        </div>
      </div>
      <UserChatModal
        open={userChatModal.open}
        buddyUser={userChatModal.otherUserInfo}
        handleClose={() => handleUserChatClose()}
      />
    </>
  );
};

export default SafalBuddyPage;

// pendingStatus Received Sent
const filterPendingDataByStatus = (data, pendingStatus) => {
  const newData = data?.filter((e) =>
    pendingStatus === 'Sent' ? e?.metaUser?._id === e?.receiver?._id : e?.metaUser?._id !== e?.receiver?._id
  );
  return newData;
};
