import * as React from 'react';

import Box from '@mui/material/Box';
import { Image } from 'antd';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import {
  createWarrantyStoreRetailer,
  updateWarrantyStoreRetailer,
  globalImagesUpload,
} from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  image: '',
  website: '',
  description: '',
  status: '', // Active, Inactive
};

export default function AddStoreRetailerModal({ open, onClose, data, isDisplay }) {
  const [fieldValue, setFieldValue] = React.useState({ ...initFieldValue });
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [imageFile, setImageFile] = React.useState(null);

  const handleClose = () => {
    setFieldValue({ ...initFieldValue });
    setIsDisableAll(false);
    setImageFile(null);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);

    try {
      let imageUrl = '';
      if (imageFile) {
        const fileRes = await globalImagesUpload([imageFile], 'product_category', 'public');
        if (!fileRes.data?.data?.[0]) throw new Error('image not uploaded');
        imageUrl = fileRes.data?.data?.[0];
      }

      const payload = { ...fieldValue, image: imageUrl };

      const res = await createWarrantyStoreRetailer(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onUpdateSubmit = async () => {
    setIsDisableAll(true);
    try {
      const payload = { ...fieldValue };
      if (imageFile) {
        const fileRes = await globalImagesUpload([imageFile], 'product_category', 'public');
        if (!fileRes.data?.data?.[0]) throw new Error('image not uploaded');
        payload.image = fileRes.data?.data?.[0];
      }

      const res = await updateWarrantyStoreRetailer({ ...payload, _id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        name: data?.name ?? '',
        image: data?.image ?? '',
        website: data?.website ?? '',
        description: data?.description ?? '',
        status: data?.status ?? '',
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  const logoImageUrl = imageFile
    ? URL.createObjectURL(imageFile)
    : fieldValue?.image?.length > 1
    ? fieldValue?.image
    : '';

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Store/Retailer' : isDisplay ? 'Store/Retailer' : 'Update Store/Retailer'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'flex-end',
            }}
          >
            {logoImageUrl.length > 1 && (
              <div style={{ flex: 'none' }}>
                <Image
                  preview={{
                    zIndex: 1000000,
                  }}
                  style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                  src={logoImageUrl}
                  alt="logo"
                />
              </div>
            )}
            <TextField
              onChange={(e) => {
                if (!e?.target?.files[0]?.name?.match(/\.(jpg|jpeg|png)$/)) {
                  toast.error('Please upload image in JPG, JPEG, PNG Format ONLY.');
                } else {
                  setImageFile(e.target.files[0]);
                }
              }}
              // value={imageFile}
              type="file"
              size="small"
              fullWidth
              variant="outlined"
              disabled={isDisableAll}
              InputProps={{
                accept: 'image/*',
              }}
            />
          </div>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* website */}
          <TextField
            value={fieldValue.website}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, website: e.target.value }))}
            label="Website"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* description */}
          <TextField
            value={fieldValue.description}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, description: e.target.value }))}
            label="Description"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* status */}
          <TextField
            value={fieldValue.status}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value }))}
            select
            label="Status"
            size="small"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onUpdateSubmit}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
