import React, { useEffect, useState } from 'react';
import { Box, Button, Fab, FormLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from './Iconify';

function MultiAttach({ attached, setAttached }) {
  const classes = useStyles();
  const [attachCount, setAttachCount] = useState(0);

  const handleChange = (e) => {
    setAttached([...attached, ...e.target.files]);
    const attachCount = e.target.files.length;
    setAttachCount(attached.length + attachCount);
  };

  useEffect(() => { });

  console.log('Attached', attached);
  console.log('Attached', attachCount);

  return (
    <>
      <div style={{ width: 141 }}>
        <input id="contained-button-file" style={{ display: 'none' }} type="file" multiple onChange={(e) => {
        

        const uploadedFiles = e?.target?.files;
        const updatedFiles = [...attached];

        Object.keys(uploadedFiles).forEach((id) => {
          const file = uploadedFiles[id];
          const duplicateFile = updatedFiles.find((uploadedFile) => uploadedFile.name === file.name);

          if (duplicateFile) {
            let counter = 1;
            let newFileName = `${file.name.substring(
              0,
              file.name.lastIndexOf('.')
            )} (${counter})${file.name.substring(file.name.lastIndexOf('.'))}`;

            // eslint-disable-next-line no-loop-func
            while (updatedFiles.some((uploadedFile) => uploadedFile.name === newFileName)) {
              // eslint-disable-next-line no-plusplus
              counter++;
              newFileName = `${file.name.substring(
                0,
                file.name.lastIndexOf('.')
              )} (${counter})${file.name.substring(file.name.lastIndexOf('.'))}`;
            }
            const renamedFile = new File([file], newFileName, { type: file.type });
            updatedFiles.push(renamedFile);
          } else {
            updatedFiles.push(file);
          }
        });

        setAttached(updatedFiles);
        

        // const abc = [];
        // Object.keys(e?.target?.files).map((id) => abc.push(e?.target?.files[id]));
        // const data = [...selectedEditFileName, abc].flat();
        // setSelectedEditFileName(data);
        // EditForm.setFieldValue('attachment', data);
      }} />
        <FormLabel htmlFor="contained-button-file" sx={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="contained" component="span" className={classes.attach_btn}>
            <Iconify icon="entypo:attachment" color="#3D71FF" width={20} height={20} mr={1} />
            Attachment
          </Button>
        </FormLabel>
      </div>
      {/* <div>
        {attachCount !== 0 && (
          <div style={attached?.length >= 3 ? { height: 100, overflowY: 'scroll' } : {}}>
            {Array.from(attached).map((d, i) => (
              <Box sx={{ mt: 1 }} style={{ display: 'block' }} key={i}>
                <Fab
                  size="small"
                  variant="outlined"
                  color="error"
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setAttached(Array.from(attached).filter((e, j) => i !== j));
                  }}
                >
                  <CloseIcon />
                </Fab>
                {d.name}
              </Box>
            ))}
          </div>
        )}
      </div> */}
    </>
  );
}

export default MultiAttach;

const useStyles = makeStyles({
  attach_btn: {
    fontWeight: 700,
    textTransform: 'none',
    backgroundColor: '#fff',
    color: '#1D1D1F',
    borderRadius: '30px',
    boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
    ':hover': { bgcolor: 'transparent' },
  },
});