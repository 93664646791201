import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import useProfileImage from '../../../../hooks/useProfileImage';
import CustomRatingBusiness from '../../../../components/UI/CustomRatingBusiness';
import { getBusinessDashboard } from '../../../../services/Service';

const WelcomeBackCardSales = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  // const user = useSelector((state) => state.login.user);

  // const fullName = user?.middleName
  //   ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
  //   : `${user?.firstName} ${user?.lastName}`;

  // const profileImage = useProfileImage(user.profilePic);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getBusinessDashboard({ page: 'sales' });
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const avgSubscriptionRating = data?.avgSubscriptionRating ?? 0;
  const avgAssetRating = data?.avgAssetRating ?? 0;

  const monthlySubscribersCount = data?.monthlySubscribersCount ?? 0;
  const quarterSubscribersCount = data?.quarterSubscribersCount ?? 0;
  const yearSubscribersCount = data?.yearSubscribersCount ?? 0;
  const monthlySubscribersCountInc = data?.monthlySubscribersCountInc ?? 0;
  const quarterSubscribersCountInc = data?.quarterSubscribersCountInc ?? 0;
  const yearSubscribersCountInc = data?.yearSubscribersCountInc ?? 0;
  const monthlySubscribersGrowthRate = data?.monthlySubscribersGrowthRate ?? 0;
  const quarterSubscribersGrowthRate = data?.quarterSubscribersGrowthRate ?? 0;
  const yearSubscribersGrowthRate = data?.yearSubscribersGrowthRate ?? 0;
  const monthlySubscribersGrowthRateInc = data?.monthlySubscribersGrowthRateInc ?? 0;
  const quarterSubscribersGrowthRateInc = data?.quarterSubscribersGrowthRateInc ?? 0;
  const yearSubscribersGrowthRateInc = data?.yearSubscribersGrowthRateInc ?? 0;

  return (
    <div
      style={{
        padding: '12px 16px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Top sections */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* <div style={{ display: 'flex', flex: 'none', alignItems: 'center', gap: '12px' }}>
          <img
            style={{
              width: '38px',
              height: '38px',
              objectFit: 'contain',
              borderRadius: '999px',
              border: '1px solid #468b47',
            }}
            src={profileImage}
            alt="Profile"
          />
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', color: '#468b47' }}>
            <span style={{ fontSize: '16px', whiteSpace: 'nowrap' }}>Welcome Back</span>
            <span style={{ fontSize: '22px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{fullName}</span>
          </div>
        </div> */}
        <div style={{ display: 'flex', gap: '12px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
            <span
              style={{ color: '#468B47', fontWeight: 'bold', fontSize: '16px', opacity: '0.75', paddingLeft: '2px' }}
            >
              Avg Subscription Rating
            </span>
            <CustomRatingBusiness value={avgSubscriptionRating} readOnly precision={0.5} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
            <span
              style={{ color: '#468B47', fontWeight: 'bold', fontSize: '16px', opacity: '0.75', paddingLeft: '2px' }}
            >
              Avg Asset Rating
            </span>
            <CustomRatingBusiness value={avgAssetRating} readOnly precision={0.5} />
          </div>
        </div>
      </div>
      <div style={{ height: '2px', backgroundColor: '#19C25F', marginTop: '8px' }} />
      {/* Bottom sections */}
      <div style={{ display: 'flex', marginTop: '12px' }}>
        {/* Monthly */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter' }}>Monthly</span>
          {/* Count */}
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '8px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: '#468b47',
                fontSize: '22px',
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              {monthlySubscribersCount}
              <span
                style={{
                  color: monthlySubscribersCountInc < 0 ? '#fcbaba' : '#90EE90',
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'baseline',
                  marginLeft: '4px',
                }}
              >
                <div
                  className={
                    monthlySubscribersCountInc < 0 ? 'BusinessDashboard-arrow-down' : 'BusinessDashboard-arrow-up'
                  }
                />
                {Math.abs(monthlySubscribersCountInc)}
              </span>
            </p>
            <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter', color: '#468b47' }}>
              Subscribers count
            </span>
          </div>
          {/* Growth Rate */}
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '12px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: monthlySubscribersGrowthRate < 0 ? '#fcbaba' : '#468b47',
                fontSize: '22px',
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              {Math.abs(monthlySubscribersGrowthRate)}%
              <span
                style={{
                  color: monthlySubscribersGrowthRateInc < 0 ? '#fcbaba' : '#90EE90',
                  fontSize: '13px',
                  marginLeft: '4px',
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <div
                  className={
                    monthlySubscribersGrowthRateInc < 0 ? 'BusinessDashboard-arrow-down' : 'BusinessDashboard-arrow-up'
                  }
                />
                {Math.abs(monthlySubscribersGrowthRateInc)}%
              </span>
            </p>
            <span
              style={{
                fontSize: '14',
                fontWeight: 'lighter',
                fontFamily: 'inter',
                color: '#468b47',
              }}
            >
              Growth Rate
            </span>
          </div>
        </div>
        {/* Quarterly */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter' }}>Quarterly</span>
          {/* Count */}
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '8px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: '#468b47',
                fontSize: '22px',
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              {quarterSubscribersCount}
              <span
                style={{
                  color: quarterSubscribersCountInc < 0 ? '#fcbaba' : '#90EE90',
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'baseline',
                  marginLeft: '4px',
                }}
              >
                <div
                  className={
                    quarterSubscribersCountInc < 0 ? 'BusinessDashboard-arrow-down' : 'BusinessDashboard-arrow-up'
                  }
                />
                {Math.abs(quarterSubscribersCountInc)}
              </span>
            </p>
            <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter', color: '#468b47' }}>
              Subscribers count
            </span>
          </div>
          {/* Growth Rate */}
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '12px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: quarterSubscribersGrowthRate < 0 ? '#fcbaba' : '#468b47',
                fontSize: '22px',
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              {Math.abs(quarterSubscribersGrowthRate)}%
              <span
                style={{
                  color: quarterSubscribersGrowthRateInc < 0 ? '#fcbaba' : '#90EE90',
                  fontSize: '13px',
                  marginLeft: '4px',
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <div
                  className={
                    quarterSubscribersGrowthRateInc < 0 ? 'BusinessDashboard-arrow-down' : 'BusinessDashboard-arrow-up'
                  }
                />
                {Math.abs(quarterSubscribersGrowthRateInc)}%
              </span>
            </p>
            <span
              style={{
                fontSize: '14',
                fontWeight: 'lighter',
                fontFamily: 'inter',
                color: '#468b47',
              }}
            >
              Growth Rate
            </span>
          </div>
        </div>
        {/* Yearly */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter' }}>Yearly</span>
          {/* Count */}
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '8px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: '#468b47',
                fontSize: '22px',
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              {yearSubscribersCount}
              <span
                style={{
                  color: yearSubscribersCountInc < 0 ? '#fcbaba' : '#90EE90',
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'baseline',
                  marginLeft: '4px',
                }}
              >
                <div
                  className={
                    yearSubscribersCountInc < 0 ? 'BusinessDashboard-arrow-down' : 'BusinessDashboard-arrow-up'
                  }
                />
                {Math.abs(yearSubscribersCountInc)}
              </span>
            </p>
            <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter', color: '#468b47' }}>
              Subscribers count
            </span>
          </div>
          {/* Growth Rate */}
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '12px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: yearSubscribersGrowthRate < 0 ? '#fcbaba' : '#468b47',
                fontSize: '22px',
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              {Math.abs(yearSubscribersGrowthRate)}%
              <span
                style={{
                  color: yearSubscribersGrowthRateInc < 0 ? '#fcbaba' : '#90EE90',
                  fontSize: '13px',
                  marginLeft: '4px',
                  display: 'flex',
                  alignItems: 'baseline',
                }}
              >
                <div
                  className={
                    yearSubscribersGrowthRateInc < 0 ? 'BusinessDashboard-arrow-down' : 'BusinessDashboard-arrow-up'
                  }
                />
                {Math.abs(yearSubscribersGrowthRateInc)}%
              </span>
            </p>
            <span
              style={{
                fontSize: '14',
                fontWeight: 'lighter',
                fontFamily: 'inter',
                color: '#468b47',
              }}
            >
              Growth Rate
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBackCardSales;
