/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import './changeLog.css';

import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DatePicker } from '@mui/x-date-pickers';
import { Select, MenuItem, InputLabel, FormControl, TextField, IconButton, TablePagination } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  downloadBusinessReportData,
  downloadAttachmentFile,
  getBusinessReportDownload,
  getBusinessReportLog,
  getSignedURL,
} from '../../services/Service';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';

function ChangeLog() {
  const { user: profile } = useSelector((state) => state.login);
  const [rawData, setRawData] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(1);
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'));

  const [fieldVal, setField] = useState('');
  const [searchVal, setSearch] = useState('');

  const fetchBusinessReportLog = async (page, limit, field, search, from, to) => {
    try {
      const payload = {
        from: from,
        to: moment(to).add(1, 'd').toISOString(),
        field: field,
        search: search,
      };
      const res = await getBusinessReportLog(page, limit, payload);
      if (res.status !== 200) return;
      if (res.data.data.length === 0) return;
      setRawData(res.data.data[0].data);

      if (res.data.data[0].pagination.length === 0) return;
      setTotalItem(res.data.data[0].pagination[0]?.totalItems);
      setCurrentPage(res.data.data[0].pagination[0]?.page);
      setPerPage(res.data.data[0].pagination[0]?.pageSize);
    } catch (error) {
      console.log('Business ChangeLog Error', error);
    }
  };

  const handleDownload = (url) => {
    downloadAttachmentFile({ url })
      .then((res) => {
        console.log('this is attachment download => ', res.data);
        if (res.data.success === true) {
          const link = document.createElement('a');
          link.href = res.data.data;
          link.setAttribute('download', `file`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log('this is attachment err => ', err);
        toast.error(err.response.data.message || 'Something went wrong !!');
      });
  };

  const downloadBusinessReportLog = async (page, limit, field, search, from, to) => {
    try {
      const payload = {
        from: from,
        to: to,
        field: field,
        search: search,
      };
      const res = await downloadBusinessReportData(page, limit, payload)
        .then((res) => {
          if (res.data.success === true) {
            toast.success('Downloaded Sucessfully!');
            handleDownload(res.data.data);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message || 'Something went wrong !!');
        });
    } catch (error) {
      console.log('Download Business ChangeLog Error', error);
    }
  };
  useEffect(() => {
    fetchBusinessReportLog(
      currentPage,
      perPage,
      fieldVal,
      searchVal,
      moment(fromDate).toISOString(),
      moment(toDate).toISOString()
    );
  }, [currentPage, perPage, fieldVal, searchVal, fromDate, toDate]);

  const onRefreshData = async () => {
    await fetchBusinessReportLog(
      currentPage,
      perPage,
      fieldVal,
      searchVal,
      moment(fromDate).toISOString(),
      moment(toDate).toISOString()
    );
    toast.success('Refresh complete');
  };
  const onDownloadData = async () => {
    console.log('Download is clicked');

    try {
      const response = await getBusinessReportDownload(profile._id);

      if (response.data && response.data.data) {
        const signedUrlResponse = await getSignedURL({ url: response.data.data });
        console.log('Signed URL Response:', signedUrlResponse);
        toast.success('Download Successful');
        // window.open(signedUrlResponse.data, '_blank');
        window.location.href = signedUrlResponse.data;
      } else {
        console.error('Invalid or missing URL in the response');
        toast.error('Download Failed');
      }
    } catch (error) {
      console.error('Error during download:', error);
      toast.error('Download Failed');
    }
  };
  const handleChangePage = async (e, value) => {
    await fetchBusinessReportLog(
      value + 1,
      perPage,
      fieldVal,
      searchVal,
      moment(fromDate).toISOString(),
      moment(toDate).toISOString()
    );
  };
  const handleChangeRowsPerPage = async (e) => {
    await fetchBusinessReportLog(
      currentPage,
      e.target.value,
      fieldVal,
      searchVal,
      moment(fromDate).toISOString(),
      moment(toDate).toISOString()
    );
  };

  const onFromDateChange = (e) => {
    setFromDate(e?.format('YYYY-MM-DD'));
  };

  const onToDateChange = (e) => {
    setToDate(e?.format('YYYY-MM-DD'));
  };

  const onCategoryChange = async (e) => {
    const newfield = e.target.value;
    setField(newfield);
    await fetchBusinessReportLog(
      currentPage,
      perPage,
      newfield,
      searchVal,
      moment(fromDate).toISOString(),
      moment(toDate).toISOString()
    );
  };

  const onSearchChange = async (e) => {
    const newsearch = e.target.value;
    setSearch(newsearch);
    await fetchBusinessReportLog(
      currentPage,
      perPage,
      fieldVal,
      newsearch,
      moment(fromDate).toISOString(),
      moment(toDate).toISOString()
    );
  };

  const dowloadData = async () => {
    await downloadBusinessReportLog(
      currentPage,
      perPage,
      fieldVal,
      searchVal,
      moment(fromDate).toISOString(),
      moment(toDate).toISOString()
    );
  };

  return (
    <>
      <div id="ChangeLog">
        <div className="action-bar">
          <h2 style={{ color: '#017c33' }}>Change Log</h2>
        </div>
        <div className="select-bar">
          <div>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={profile.country === 'India' ? 'en-gb' : 'en'}
            >
              <DatePicker
                label="From"
                value={fromDate}
                onChange={onFromDateChange}
                renderInput={(props) => <TextField {...props} sx={{ width: '150px' }} size="small" />}
              />
            </LocalizationProvider>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale={profile.country === 'India' ? 'en-gb' : 'en'}
            >
              <DatePicker
                label="To"
                value={toDate}
                onChange={onToDateChange}
                renderInput={(props) => <TextField {...props} sx={{ width: '150px' }} size="small" />}
              />
            </LocalizationProvider>
            <TextField
              onChange={onSearchChange}
              value={searchVal}
              focused
              type="text"
              id="search-input"
              size="small"
              label="Search..."
              sx={{ width: '150px' }}
              variant="outlined"
            />
            <FormControl size="small" sx={{ width: '200px' }}>
              <InputLabel id="changelog-changed-category-label">Category</InputLabel>
              <Select
                labelId="changelog-changed-category-label"
                id="changelog-changed-category"
                // value={selectedReleaseType}
                label="Changed Category"
                onChange={onCategoryChange}
              >
                <MenuItem value={''}> </MenuItem>
                <MenuItem value={'General'}>General</MenuItem>
                <MenuItem value={'login'}>Login</MenuItem>
                <MenuItem value={'logout'}>Logout</MenuItem>
                <MenuItem value={'personal details'}>Personal Details</MenuItem>
                <MenuItem value={'primary email'}>Primary Email</MenuItem>
                <MenuItem value={'phone number'}>Phone Number</MenuItem>
                <MenuItem value={'password'}>Password</MenuItem>
                <MenuItem value={'menu'}>Menu</MenuItem>
                <MenuItem value={'shift'}>Shift</MenuItem>
                <MenuItem value={'company'}>Company</MenuItem>
                <MenuItem value={'custom company'}>Custom Company</MenuItem>
                <MenuItem value={'downloadReports'}>Download Reports</MenuItem>
                <MenuItem value={'download feedback'}>Download Feedback</MenuItem>
                <MenuItem value={'feedback'}>Feedback</MenuItem>
                <MenuItem value={'memory'}>Storage Memory</MenuItem>
                <MenuItem value={'subscription limit'}>Subscription Limit</MenuItem>
                <MenuItem value={'chat'}>Chat</MenuItem>
                <MenuItem value={'call'}>Call</MenuItem>
                <MenuItem value={'text limit'}>Text Limit</MenuItem>
                <MenuItem value={'email limit'}>Email Limit</MenuItem>
                <MenuItem value={'download'}>Download Limit</MenuItem>
                <MenuItem value={'upload'}>Upload Limit</MenuItem>
                <MenuItem value={'promotion'}>Promotion</MenuItem>
                <MenuItem value={'notification'}>Notification Setting</MenuItem>
              </Select>
            </FormControl>

            {/* <IconButton onClick={dowloadData} style={{ marginLeft: '10px' }} aria-label="download">
              <DownloadIcon />
            </IconButton> */}
          </div>
          <div>
            {/* <IconButton style={{ marginLeft: '10px' }} aria-label="filter">
              <FilterAltOffIcon />
            </IconButton> */}
            <IconButton onClick={onRefreshData} style={{ marginLeft: '10px' }} aria-label="delete">
              <RefreshIcon />
            </IconButton>

            <IconButton onClick={onDownloadData} style={{ marginLeft: '10px' }} aria-label="delete">
              <ArrowDownwardIcon />
            </IconButton>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead style={{ backgroundColor: '#eaffea', color: '#007b32' }}>
              <TableRow>
                <TableCell style={{ color: '#007b32' }}>UserId</TableCell>
                <TableCell style={{ color: '#007b32' }}>Full Name</TableCell>
                <TableCell style={{ color: '#007b32' }}>Module</TableCell>
                <TableCell style={{ color: '#007b32' }}>Description</TableCell>
                <TableCell style={{ color: '#007b32' }}>Old Value</TableCell>
                <TableCell style={{ color: '#007b32' }}>New Value</TableCell>
                <TableCell style={{ color: '#007b32' }}>Date & Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rawData.map((e, index) => (
                <TableRow key={index}>
                  <TableCell>{e?.user?.userID}</TableCell>
                  <TableCell>{`${e?.user?.firstName} ${e?.user?.middleName} ${e?.user?.lastName}`}</TableCell>
                  <TableCell>{e?.ModuleName}</TableCell>
                  <TableCell>{e?.message}</TableCell>
                  <TableCell>
                    <p style={{ maxHeight: '100px', overflow: 'hidden' }}>{e?.oldValue}</p>
                  </TableCell>
                  <TableCell>
                    <p style={{ maxHeight: '100px', overflow: 'hidden' }}>{e?.newValue}</p>
                  </TableCell>
                  <TableCell>
                    {profile.country === 'India'
                      ? moment(e?.createdAt).format('DD-MM-YYYY hh:mm:ss a')
                      : moment(e?.createdAt).format('MM-DD-YYYY hh:mm:ss a')}{' '}
                    {getTimeZoneAbbreviation()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
            component="div"
            count={totalItem}
            rowsPerPage={perPage}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    </>
  );
}

export default ChangeLog;
