import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Avatar, Image } from 'antd';
import { IconButton, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import XIcon from '@mui/icons-material/X';
import { LinkedIn } from '@mui/icons-material';

import likeImg from '../../../assets/Like.png';
import disLike from '../../../assets/disLike.png';
import likeDislike from '../../../assets/likeDislike.png';
import { fallbackImage } from '../../../utils/general';
import {
  createOfferComments,
  createOfferCommentsReply,
  getOffersCommentsListUser,
  getSignedURL,
  updateOfferComments,
} from '../../../services/Service';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const offerEmail = 'support@safalvir.com';

export default function SingleOfferModal({ open, onClose, data }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [commentsList, setCommentsList] = React.useState([]);
  const [message, setMessage] = React.useState('');
  const [singleReply, setSingleReply] = React.useState({
    data: null,
    open: false,
  });
  const handleClose = () => {
    onClose();
    setIsSubmitting(false);
    setIsLoading(false);
    setCommentsList([]);
    setMessage('');
    setSingleReply({
      data: null,
      open: false,
    });
  };

  const fetchComments = async (d) => {
    setIsLoading(true);
    try {
      const res = await getOffersCommentsListUser({ id: d._id });
      setCommentsList(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateComments = async (id, like) => {
    setIsSubmitting(true);
    try {
      const res = await updateOfferComments({ commentsId: id, like });
      setMessage('');
      fetchComments(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const addComments = async (id, like) => {
    setIsSubmitting(true);
    try {
      if (message.length < 4) {
        toast.error('Comment message must be at least 3 characters long');
        return;
      }
      const res = await createOfferComments({ message: message, offerId: data._id });
      setMessage('');
      fetchComments(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      fetchComments(data);
    }
  }, [open, data]);

  const emailLink = `mailto:${offerEmail}?subject=SafalSubscriptions%20Offers&body=${data?.title}%0A${data?.subtitle}`;
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${data?.redirectLink}`;
  const twitterLink = `https://twitter.com/intent/tweet?text=${data?.title}&url=${data?.redirectLink}`;
  const linkedInLink = `http://www.linkedin.com/shareArticle?mini=true&url=${data?.redirectLink}&title=${data?.title}`;

  const onOpenSingleReply = (data) => {
    setSingleReply({
      data: data,
      open: true,
    });
  };
  const onCloseSingleReply = () => {
    setSingleReply({
      data: null,
      open: false,
    });
    fetchComments(data);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div style={{ display: 'flex', gap: '8px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <a title="Click to open offer redirect link" href={data?.redirectLink} rel="noreferrer" target="_blank">
                <Image
                  fallback={fallbackImage}
                  preview={false}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '16px',
                    border: '1px solid #bfbfbf',
                  }}
                  height="180px"
                  width="180px"
                  src={data?.company?.logo}
                />
              </a>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  gap: '4px',
                  marginTop: '8px',
                }}
              >
                {/* <span>Share</span> */}
                <IconButton title="Share via Facebook" href={facebookLink} size="small">
                  <FacebookIcon />
                </IconButton>
                <IconButton title="Share via X (Twitter)" href={twitterLink} size="small">
                  <XIcon />
                </IconButton>
                <IconButton title="Share via Email" href={linkedInLink} size="small">
                  <LinkedIn />
                </IconButton>
                <IconButton title="Share via Email" href={emailLink} size="small">
                  <EmailIcon />
                </IconButton>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                fontSize: '16px',
                lineHeight: 'normal',
                overflow: 'hidden',
                color: 'black',
                justifyContent: 'space-evenly',
              }}
            >
              <span
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}
              >
                {data?.title}
              </span>
              <hr />
              <span
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                {data?.subtitle}
              </span>
              <p>{data?.description}</p>
              <a
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  color: 'black',
                }}
                href={data?.company?.website}
                rel="noreferrer"
                target="_blank"
              >
                {data?.company?.website}
              </a>
              <Image
                fallback={fallbackImage}
                preview={false}
                style={{
                  objectFit: 'cover',
                  borderRadius: '16px',
                  border: '1px solid #bfbfbf',
                }}
                height="120px"
                width="100%"
                src={data?.image}
              />
            </div>
          </div>
          {/* Comments main */}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px', gap: '4px' }}>
            {/* Comments title */}
            <span style={{ fontSize: '16px', flex: 'none', fontWeight: 'bold' }}>Comments</span>
            {isLoading && <div className="loader-x33" />}
            {/* Comments view box */}
            <div
              style={{
                height: '400px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                padding: '8px 0px',
                gap: '4px',
              }}
            >
              {!isLoading && commentsList?.length === 0 && (
                <span style={{ textAlign: 'center' }}>No comments found.</span>
              )}
              {commentsList.map((e, index) => (
                <SingleComment
                  onOpenSingleReply={() => onOpenSingleReply(e)}
                  disabled={isSubmitting}
                  data={e}
                  key={index}
                  onUpdate={updateComments}
                  fetchComments={() => fetchComments(data)}
                />
              ))}
            </div>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
            <Button
              onClick={handleClose}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

const SingleComment = ({ data, onUpdate, disabled, fetchComments }) => {
  const { timeFromNow2 } = useUserTimeZone();
  const [message, setMessage] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [singleReply, setSingleReply] = React.useState({
    data: null,
    open: false,
  });

  const addComments = async () => {
    setIsSubmitting(true);
    try {
      if (message.length < 4) {
        toast.error('reply message must be at least 3 characters long');
        return;
      }
      await createOfferCommentsReply({ message: message, offerId: data.offer, commentsId: data._id });
      setMessage('');
      fetchComments();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOpenSingleReply = () => {
    // onCloseSingleReply()
    setSingleReply({
      data: null,
      open: true,
    });
  };
  const handleCloseSingleReply = () => {
    // onCloseSingleReply()
    setSingleReply({
      data: null,
      open: false,
    });
  };
  const handleSingleReplyPost = () => {
    // onCloseSingleReply()
  };

  return (
    <div style={{ display: 'flex', borderRadius: '12px' }}>
      <div style={{ flex: 'none', marginTop: '8px', marginLeft: '8px' }}>
        <ProfileImageState src={data?.whoCreated?.profilePic} name={data?.whoCreated?.firstName[0]} />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 'normal',
          fontSize: '16px',
          padding: '8px',
          borderRadius: '8px',
        }}
      >
        <div
          style={{
            backgroundColor: '#eaffea',
            display: 'flex',
            flexDirection: 'column',
            padding: '4px',
            borderRadius: '8px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 'normal', fontSize: '16px' }}>
            <span style={{ fontWeight: 'bold' }}>{data?.whoCreated?.firstName}</span>
            <span style={{ fontWeight: '200' }}>{timeFromNow2(data?.createdAt)}</span>
          </div>
          <p>{data.message}</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '4px' }}>
          <div
            style={{
              flex: 'none',
              display: 'flex',
              gap: '8px',
              justifyContent: 'end',
              alignItems: 'center',
            }}
          >
            <div style={{ flex: 'none', display: 'flex', gap: '12px' }}>
              <div>
                {data?.likeDislikeUser?.likeCount > 0 && (
                  <span style={{ fontSize: '12px', marginRight: '2px' }}>{data?.likeDislikeUser?.likeCount}</span>
                )}
                <Image
                  title="Safal"
                  preview={false}
                  style={{
                    filter: data?.likeDislikeUser?.you?.like !== 1 && 'grayscale(100%)',
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                  height="35px"
                  src={likeImg}
                />
              </div>
              <div>
                {data?.likeDislikeUser?.dislikeCount > 0 && (
                  <span style={{ fontSize: '12px', marginRight: '2px' }}>{data?.likeDislikeUser?.dislikeCount}</span>
                )}
                <Image
                  title="Unsafal"
                  preview={false}
                  style={{
                    filter: data?.likeDislikeUser?.you?.like !== -1 && 'grayscale(100%)',
                    cursor: 'pointer',
                    objectFit: 'contain',
                  }}
                  height="35px"
                  src={disLike}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flex: 'none', alignItems: 'center', gap: '8px' }}>
            <TextField
              disabled={isSubmitting}
              sx={{ fontSize: '14px' }}
              size="small"
              multiline
              minRows={1}
              maxRows={3}
              fullWidth
              variant="outlined"
              placeholder="Enter your reply..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              disabled={isSubmitting}
              sx={{
                backgroundColor: '#19c25f',
                textTransform: 'capitalize',
                display: 'flex',
                alignItems: 'center',
                marginTop: 'auto',
              }}
              onClick={addComments}
              size="medium"
              variant="contained"
              endIcon={<SendIcon />}
            >
              Post
            </Button>
          </div>
          {!singleReply.open && data?.replies.length > 0 && (
            <div
              style={{
                paddingLeft: '16px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                <ProfileImageState
                  size="small"
                  src={data?.replies[0].whoCreated?.profilePic}
                  name={data?.replies[0].whoCreated?.firstName[0]}
                />
                <p>
                  <b style={{ marginRight: '8px' }}>{data?.replies[0].whoCreated?.firstName}</b>
                  {data?.replies[0].message}
                </p>
              </div>
              {/* more reply */}

              {data?.replies.length > 1 && (
                <button className="offer-link-btn" onClick={handleOpenSingleReply}>
                  View More
                </button>
              )}
            </div>
          )}
          {singleReply.open &&
            data?.replies.length > 0 &&
            data?.replies.map((ep, index) => (
              <SingleReply
                onClose={handleCloseSingleReply}
                key={index}
                data={ep}
                isLast={index === data?.replies.length - 1}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export const ProfileImageState = ({ name, src, size }) => {
  const [profileImg, setProfileImg] = React.useState('');
  const getProfileImage = async (url) => {
    if (url?.includes('safalsubscriptions-assets.s3.amazonaws.com')) {
      const newRes = await getSignedURL({ url: url });
      setProfileImg(newRes.data);
    }
  };

  React.useEffect(() => {
    getProfileImage(src);
  }, [src]);
  const _size = size === undefined ? 'default' : size;

  return (
    <Avatar size={_size} src={profileImg}>
      {name}
    </Avatar>
  );
};

const SingleReply = ({ data, onUpdate, disabled, isLast, onClose }) => {
  const { timeFromNow2 } = useUserTimeZone();
  return (
    <div style={{ display: 'flex', borderRadius: '12px', gap: '4px' }}>
      <div style={{ flex: 'none', marginTop: '8px', marginLeft: '8px' }}>
        <ProfileImageState src={data?.whoCreated?.profilePic} name={data?.whoCreated?.firstName[0]} />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 'normal',
          fontSize: '16px',
          padding: '8px',
          borderRadius: '8px',
          backgroundColor: '#eaffea',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 'normal', fontSize: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>{data?.whoCreated?.firstName}</span>
          <span style={{ fontWeight: '200' }}>{timeFromNow2(data?.createdAt)}</span>
        </div>
        <p>{data.message}</p>
        {isLast && (
          <button style={{ marginLeft: 'auto' }} className="offer-link-btn" onClick={onClose}>
            View Less
          </button>
        )}
      </div>
    </div>
  );
};
