import { useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';

import ThreeDotMenu from './ThreeDotMenu';
import defaultCard from '../../../assets/defaultCard.png';
import useUserTimeZone from '../../../hooks/useUserTimeZone';

const AssetCard = ({ data, selected, onSelectedChange, onOpenEdit, onDelete, onChangeLog, selectedToggle }) => {
  const { user } = useSelector((state) => state.login);
  const frontImageUrl = data?.companyId?.logo ? data?.companyId?.logo : defaultCard;
  const { expiryCheck } = useUserTimeZone();

  const amountSym = user.country === 'United States' ? '$' : '₹';
  const amountSym01 = selectedToggle === 'loyalty' ? '' : `${amountSym} `;

  const handleDoubleClick = (event) => {
    if (event.detail === 2) {
      onOpenEdit(data, true);
    }
  };

  const onChangeLogClick = () => {
    onChangeLog(data);
  };

  const expiryCheckFlag = data?.expiryDate ? expiryCheck(data.expiryDate) : false;

  return (
    <div id="asset-card">
      <button className="card-main" onClick={(ev) => handleDoubleClick(ev)}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <span className="card-name">{data?.companyId?.name}</span>
          <ThreeDotMenu
            onEdit={(flag) => onOpenEdit(data, flag)}
            onDelete={() => onDelete(data)}
            data={data}
            onChangeLog={onChangeLogClick}
          />
        </div>
        <div
          style={{ backgroundColor: expiryCheckFlag ? '#f7a6a5' : !data.status ? '#a7b1bc' : '#01bc56' }}
          className="div-br"
        />
        <span className="card-number">{data?.cardNumber}</span>
        <span className="card-points">
          {selectedToggle === 'loyalty' ? 'Points : ' : 'Values : '}
          {data?.points.length > 0 ? amountSym01 + new Intl.NumberFormat().format(data?.points) : data?.points}
        </span>
      </button>
      {expiryCheckFlag && <div className="expire-bar">Card is expired</div>}
      {!expiryCheckFlag && !data.status && <div className="inactive-bar">Card is inactive</div>}
      <div className="card-image">
        <img src={frontImageUrl} alt="frontImageUrl" />
        <div className="card-checkbox">
          <Checkbox checked={selected} onChange={() => onSelectedChange(data._id)} />
        </div>
      </div>
    </div>
  );
};

export default AssetCard;
