import { doc, serverTimestamp, setDoc, getDoc } from 'firebase/firestore';
import { Button, DialogActions, DialogContentText, DialogContent, DialogTitle, Dialog } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import CallIcon from '@mui/icons-material/Call';
import CloseIcon from '@mui/icons-material/Close';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import { useSelector, useDispatch } from 'react-redux';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import SearchIcon from '@mui/icons-material/Search';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Tooltip from '@mui/material/Tooltip';
import customer from '../../assets/Customer.png';
import DisplayAvatar from './DisplayAvatar';
import { db } from '../../firebase';
import {
  getChartdetails,
  firebasePushNotification,
  cloudRecordingStart,
  getAvailableSupportUserId,
} from '../../services/Service';
import NewCallLogModal from '../Modals/NewCallLogModal';
import ChatSearch from './ChatSearch';
import { setCallRecoirdingAlertState } from '../../store/CallRecoirdingAlert';
import SupportAccessPermission from './SupportAccessPermission';
import { changeChatResponsiveToggle } from '../../store/ToggleSlice';

const NavRight = ({ isSupport, handleClose, messages, setMessages }) => {
  const { chatMode, user, sid, chatId } = useSelector((state) => state.chats);
  const { user: profile } = useSelector((state) => state.login);
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const [openCallLog, setOpenCallLog] = useState(null); // null or id
  const [isSearchOpen, setIsSearchOpen] = useState(false); // null or id
  const [isCallRecoirdingAlert, setIsCallRecoirdingAlert] = useState({ isOpen: false, callMode: '' });
  const dispatch = useDispatch();

  const userToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  const isSupportAudioCallAllow = Object.hasOwn(currentFirebaseUser, 'isSupportAudioCallAllow')
    ? currentFirebaseUser.isSupportAudioCallAllow
    : false;

  const isSupportVideoCallAllow = Object.hasOwn(currentFirebaseUser, 'isSupportVideoCallAllow')
    ? currentFirebaseUser.isSupportVideoCallAllow
    : false;

  const handleClickPersonalCallLog = () => {
    setOpenCallLog(sid || 'support');
  };

  const onConnectCall = async (callMode) => {
    const currentUserId = currentFirebaseUser.uid;
    const otherUserId = user?.uid || sid;
    // user to user calls
    if (chatMode === 'user' && otherUserId !== undefined && otherUserId !== null) {
      const otherUserDocDataRef = await getDoc(doc(db, 'users', otherUserId));
      const otherUserDocData = otherUserDocDataRef.data();
      const callId = uuidv4();
      const callLogRef = doc(db, 'callLogs', callId);
      const currentUserCallDocRef = doc(db, 'userCalls', currentUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', otherUserId);
      try {
        const getDocData = await getDoc(otherUserCallDocRef);
        if (getDocData.exists) {
          const data = getDocData.data();
          if (data.isCall) {
            toast('User is busy in another call');
            return;
          }
        }
      } catch (err) {
        console.log(err);
      }

      const callObj = {
        callId: callId,
        isCall: true,
        callMode: callMode, // audio/video,
        type: 'user',
        status: 'pending', // "pending", "accept", "reject", "end"
        currentUserId: currentUserId, // undefined or string
        otherUserId: otherUserId, // undefined or string
        flag: 'user_to_user', // user_to_user or support_to_user or user_to_support
        startAt: serverTimestamp(),
      };

      await setDoc(callLogRef, callObj);
      await setDoc(currentUserCallDocRef, callObj);
      await setDoc(otherUserCallDocRef, callObj);

      const notificationPayload = {
        data: {
          notification: { image: '', title: 'Calling', body: `${currentFirebaseUser?.displayName} calling you` },
          data: {
            type: 'call',
            messageId: '',
            otheruid: '',
            image_url: otherUserDocData?.photoURL,
            chatID: chatId,
            displayName: otherUserDocData?.displayName,
            userType: 'USER',
            isAudio: callMode === 'audio' ? '1' : '2',
            OtherUid: currentUserId,
            channelId: callId,
          },
          apns: { payload: { aps: { sound: 'afro_ringtone.mp3', badge: 1 } } },
        },
        userId: otherUserDocData?.userID,
      };
      await firebasePushNotification(notificationPayload);
    }
    // support to user calls
    else if (
      chatMode === 'support' &&
      otherUserId !== undefined &&
      otherUserId !== null &&
      profile.role === 'business'
    ) {
      const otherUserDocDataRef = await getDoc(doc(db, 'users', otherUserId));
      const otherUserDocData = otherUserDocDataRef.data();
      const callId = uuidv4();
      const callLogRef = doc(db, 'callLogs', callId);
      const currentUserCallDocRef = doc(db, 'supportCalls', currentUserId);
      // const currentUserCallDocRef = doc(db, 'supportCalls', 'businessSupportUser');
      const otherUserCallDocRef = doc(db, 'userCalls', otherUserId);
      const getDocData = await getDoc(otherUserCallDocRef);
      if (getDocData.exists) {
        const data = getDocData.data();
        if (data.isCall) {
          toast('User is busy in another call');
          return;
        }
      }
      const callObj = {
        callId: callId,
        isCall: true,
        callMode: callMode, // audio/video,
        type: 'support',
        status: 'pending', // "pending", "accept", "reject", "end"
        supportUserId: currentUserId,
        currentUserId: currentUserId, // undefined or string
        otherUserId: otherUserId, // undefined or string
        flag: 'support_to_user', // user_to_user or support_to_user or user_to_support
        startAt: serverTimestamp(),
      };
      await setDoc(callLogRef, callObj);
      await setDoc(currentUserCallDocRef, callObj);
      await setDoc(otherUserCallDocRef, callObj);

      const notificationPayload = {
        data: {
          notification: { image: '', title: 'Calling', body: `Support calling you` },
          data: {
            type: 'call',
            messageId: '',
            otheruid: '',
            image_url: otherUserDocData?.photoURL,
            chatID: chatId,
            displayName: otherUserDocData?.displayName,
            userType: 'SUPPORT',
            isAudio: callMode === 'audio' ? '1' : '2',
            OtherUid: currentUserId,
            channelId: callId,
          },
          apns: { payload: { aps: { sound: 'afro_ringtone.mp3', badge: 1 } } },
        },
        userId: otherUserDocData?.userID,
      };
      await firebasePushNotification(notificationPayload);
    }
    // user to support calls
    else if (chatMode === 'support' && profile.role === 'user') {
      setIsCallRecoirdingAlert({
        isOpen: true,
        callMode: callMode,
      });
    }
  };

  const onVideoCallClick = async () => {
    if (profile?.role === 'user') {
      const res = await getChartdetails();
      if (res.status !== 200) {
        toast('your limits is over or something worng');
        return;
      }
      const data = res.data.data.videoCall;
      if (data.available >= data.limit) {
        toast('your limits is over.');
        return;
      }
    }

    await onConnectCall('video');
  };

  const onAudioCallClick = async () => {
    if (profile?.role === 'user') {
      const res = await getChartdetails();
      if (res.status !== 200) {
        toast('your limits is over or something worng');
        return;
      }

      const data = res.data.data.audioCall;
      if (data.available >= data.limit) {
        toast('your limits is over.');
        return;
      }
    }
    await onConnectCall('audio');
  };

  const onSearchClick = () => {
    setIsSearchOpen((prev) => !prev);
  };

  const onCallRecoirdingAlertClose = async (value) => {
    // this only applicable for user to support call
    try {
      let supportUserId = 'busy';
      let firebaseUid = 'businessSupportUser';
      const supportUserIdRes = await getAvailableSupportUserId();
      if (supportUserIdRes.status === 200) {
        supportUserId = supportUserIdRes.data.data.userId;
        firebaseUid = supportUserIdRes.data.data.firebaseUid;
      }

      if (value) {
        const currentUserId = currentFirebaseUser.uid;
        const callId = uuidv4();
        const callLogRef = doc(db, 'callLogs', callId);
        const currentUserCallDocRef = doc(db, 'userCalls', currentUserId);
        const supportUserCallDocRef = doc(db, 'supportCalls', firebaseUid);
        // const supportUserCallDocRef = doc(db, 'supportCalls', 'businessSupportUser');
        const getDocData = await getDoc(supportUserCallDocRef);
        if (getDocData.exists) {
          const data = getDocData.data();
          if (data.isCall) {
            toast('Support is busy in another call');
            return;
          }
        }
        const callObj = {
          callId: callId,
          isCall: true,
          callMode: isCallRecoirdingAlert.callMode, // audio/video,
          type: 'support',
          status: 'pending', // "pending", "accept", "reject", "end"
          currentUserId: currentUserId, // undefined or string
          supportUserId: firebaseUid, // null or string
          otherUserId: firebaseUid,
          flag: 'user_to_support', // user_to_user or support_to_user or user_to_support
          startAt: serverTimestamp(),
        };
        await setDoc(callLogRef, callObj);
        await setDoc(currentUserCallDocRef, callObj);
        await setDoc(supportUserCallDocRef, callObj);
        const res = await cloudRecordingStart({
          uid: '1',
          channelName: callId,
          callId: callId,
        });
        dispatch(setCallRecoirdingAlertState(res.data));
        const notificationPayload = {
          data: {
            notification: { image: '', title: 'Calling', body: `User calling you` },
            data: {
              type: 'call',
              otheruid: '',
              messageId: '',
              image_url: '',
              chatID: chatId,
              displayName: 'Support',
              userType: 'SUPPORT',
              isAudio: isCallRecoirdingAlert.callMode === 'audio' ? '1' : '2',
              OtherUid: firebaseUid,
              channelId: callId,
            },
            apns: { payload: { aps: { sound: 'afro_ringtone.mp3', badge: 1 } } },
          },
          userId: supportUserId,
        };
        await firebasePushNotification(notificationPayload);
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setIsCallRecoirdingAlert({
        isOpen: false,
        callMode: '',
      });
    }
  };

  const onChatResponsiveToggle = () => {
    dispatch(changeChatResponsiveToggle());
  };

  return (
    <>
      <div
        className="chat-nav-right-responsive"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '5px 12px',
          borderBottom: '1px solid gray',
        }}
      >
        {chatMode !== 'support' && (
          <MenuOpenIcon
            onClick={onChatResponsiveToggle}
            style={{
              height: '20px',
              width: '20px',
              marginRight: '4px',
              color: 'black',
              cursor: 'pointer',
            }}
            className="closeOnDesktop"
          />
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {chatMode === 'support' && profile?.role !== 'business' ? (
            <img className="user-profilepic" src={customer} alt="" />
          ) : (
            <DisplayAvatar uid={user?.uid} buddyProfile />
          )}{' '}
          &nbsp;
          <p style={{ fontWeight: 'bold' }}>{user?.displayName}</p>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Tooltip title="Search">
            <SearchIcon
              className="chat-nav-right-icon"
              onClick={onSearchClick}
              style={{
                height: 45,
                width: 45,
                borderRadius: '50%',
                backgroundColor: 'rgb(1, 184, 85)',
                padding: 10,
                color: 'white',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
          &nbsp;
          {chatMode === 'support' && profile?.role === 'user'
            ? isSupportVideoCallAllow && (
                <Tooltip title="Video">
                  <VideocamOutlinedIcon
                    className="chat-nav-right-icon"
                    onClick={onVideoCallClick}
                    style={{
                      height: 45,
                      width: 45,
                      borderRadius: '50%',
                      backgroundColor: 'rgb(1, 184, 85)',
                      padding: 10,
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )
            : userToUserChatEnable && (
                <Tooltip title="Video">
                  <VideocamOutlinedIcon
                    className="chat-nav-right-icon"
                    onClick={onVideoCallClick}
                    style={{
                      height: 45,
                      width: 45,
                      borderRadius: '50%',
                      backgroundColor: 'rgb(1, 184, 85)',
                      padding: 10,
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
          &nbsp;
          {chatMode === 'support' && profile?.role === 'user'
            ? isSupportAudioCallAllow && (
                <Tooltip title="Audio">
                  <CallIcon
                    className="chat-nav-right-icon"
                    onClick={onAudioCallClick}
                    style={{
                      height: 45,
                      width: 45,
                      borderRadius: '50%',
                      backgroundColor: 'rgb(1, 184, 85)',
                      padding: 10,
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )
            : userToUserChatEnable && (
                <Tooltip title="Audio">
                  <CallIcon
                    className="chat-nav-right-icon"
                    onClick={onAudioCallClick}
                    style={{
                      height: 45,
                      width: 45,
                      borderRadius: '50%',
                      backgroundColor: 'rgb(1, 184, 85)',
                      padding: 10,
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  />
                </Tooltip>
              )}
          &nbsp;
          <Tooltip title="Call Log">
            <ContactPageIcon
              className="chat-nav-right-icon"
              onClick={handleClickPersonalCallLog}
              style={{
                height: 45,
                width: 45,
                borderRadius: '50%',
                backgroundColor: 'rgb(1, 184, 85)',
                padding: 10,
                color: 'white',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
          &nbsp;
          <Tooltip title="Close">
            <div style={{ marginLeft: '15%' }} className="chat-close-responsive">
              {isSupport !== 'true' && (
                <CloseIcon
                  onClick={handleClose}
                  sx={{
                    justifySelf: 'end',
                    marginRight: '30px',
                    color: '#01B855',
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
          </Tooltip>
        </div>
        <NewCallLogModal
          open={openCallLog}
          onClose={() => setOpenCallLog(null)}
          type={chatMode}
          myUid={currentFirebaseUser.uid}
          profile={profile}
        />
      </div>
      {isSearchOpen && (
        <ChatSearch messages={messages} setMessages={setMessages} onClose={() => setIsSearchOpen(false)} />
      )}
      {chatMode === 'support' && profile?.role === 'business' && <SupportAccessPermission user={user} />}

      <CallRecoirdingAlertDialog open={isCallRecoirdingAlert.isOpen} onClose={onCallRecoirdingAlertClose} />
    </>
  );
};

export default NavRight;

const CallRecoirdingAlertDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Support Call Recording'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Hello Safalsubscription user, your call is important to us. To ensure the highest quality of service and for
          training purposes, we'd like to record this call. By continuing with this call, you're providing your consent
          for the call to be recorded.If you agree to this call being recorded, please tap on the '<b>Agree</b>' . If
          you do not wish for the call to be recorded, simply tap '<b>Disagree</b>'. We appreciate your understanding.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Disagree</Button>
        <Button onClick={() => onClose(true)} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
