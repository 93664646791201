/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import Chat from '../Chat/Chat';
import { changeChatMode, changeSupport } from '../../store/ChatSlice';
import { db } from '../../firebase';

const SupportChatModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { chatId, chatMode } = useSelector((state) => state.chats);
  const { currentUser } = useSelector((state) => state.auth);
  const { role } = useSelector((state) => state.login?.user);

  const chanegePerson = async () => {
    const q = query(collection(db, 'support'));
    try {
      const supportTeam = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        supportTeam.push(doc.data());
      });
      dispatch(changeSupport(supportTeam));
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (open) {
      dispatch(changeChatMode('support'));
      chanegePerson();
    }
  }, [open]);

  React.useEffect(() => {
    if (chatId) createChatRoom();
  }, [chatId]);
  const removeSupportAlertForUser = async () => {
    if (currentUser?.uid && role === 'user') {
      const user = await getDoc(doc(db, 'alert', currentUser?.uid));
      if (user.data()?.count > 0) {
        await updateDoc(user.ref, { count: 0 });
      }
    }
  };
  React.useEffect(() => {
    removeSupportAlertForUser();

    // chanegePerson()
    // createChatRoom()
  }, [open]);

  const createChatRoom = async () => {
    try {
      // check whether the group exists, if not CREATE
      const res = await getDoc(doc(db, 'chats', chatId));
      if (!res.exists()) {
        await setDoc(doc(db, 'chats', chatId), { messages: [] });
        // create user chats
        await updateDoc(doc(db, `${chatMode}Chats`, currentUser?.uid), {
          [`${chatId}.userInfo`]: {
            uid: currentUser?.uid,
            displayName: currentUser?.displayName,
            photo: currentUser?.photoURL,
          },
          [`${chatId}.date`]: serverTimestamp(),
        });
        // await updateDoc(doc(db, `${chatMode}Chats`, user.uid), {
        //     [`${chatId}.userInfo`]:{
        //         uid:currentUser?.uid,
        //         displayName: currentUser?.displayName,
        //         photo: currentUser?.photoURL
        //     },
        //     [`${chatId}.date`]: serverTimestamp()
        // })
      }
    } catch (e) {
      console.log(e);
    }

    // setUser(null)
    // setUsername('')
    //  Create user chats
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 0,
            borderRadius: '10px',
            height: '80%',
            display: 'flex',
            overflow: 'hidden',
            '@media (max-width: 640px)': {
              width: '98%',
              height: '98%',
            },
          }}
        >
          <Chat open={open} handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
};

export default SupportChatModal;
