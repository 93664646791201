import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ImageIcon from '@mui/icons-material/Image';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { db } from '../../firebase';

const LastMsg = ({ senderId }) => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('text');
  const { currentUser } = useSelector((state) => state.auth);
  const { chatMode } = useSelector((state) => state.chats);

  useEffect(() => {
    try {
      if (currentUser?.uid && senderId && chatMode === 'user') {
        const combineId = currentUser?.uid > senderId ? currentUser?.uid + senderId : senderId + currentUser?.uid;
        const unsub = onSnapshot(doc(db, `userChats`, senderId, combineId), (doc) => {
          if (doc.exists()) setMessage(doc.data()?.lastMessage?.text || '');
        });
        return () => {
          unsub();
        };
      }
      if (chatMode === 'support') {
        const unsub = onSnapshot(doc(db, `supportChats`, senderId), (doc) => {
          if (doc.exists()) {
            setMessage(doc.data()[senderId]?.lastMessage?.text || '');
            setMessageType(doc.data()[senderId]?.lastMessage?.messageType || 'text');
          }
        });
        return () => {
          unsub();
        };
      }
    } catch (e) {
      console.log(e);
    }
  }, [currentUser?.uid]);

  // return <p className="userChatMsg">{message}</p>;
  return (
    <p
      className="userChatMsg"
      style={{
        paddingTop: 10,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {messageType === 'image' ? (
        <>
          <ImageIcon style={{ height: '14px', width: '14px', marginRight: '4px' }} /> Image
        </>
      ) : messageType === 'video' ? (
        <>
          <VideoFileIcon style={{ height: '14px', width: '14px', marginRight: '4px' }} /> Video
        </>
      ) : messageType === 'pdf' ? (
        <>
          <PictureAsPdfIcon style={{ height: '14px', width: '14px', marginRight: '4px' }} /> PDF
        </>
      ) : (
        message
      )}
    </p>
  );
};

export default LastMsg;
