import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, IconButton, ListItemIcon, Typography } from '@mui/material';

import Iconify from '../Iconify';

// customButton = [{name:"",icon:"",onClick:()=>{}}]
export default function ThreeDotMenu({
  onEdit,
  onDelete,
  onDisplay,
  data,
  onChangeLog,
  onComment,
  onViewResponse,
  disableDelete,
  disableEdit,
  customButton,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDisplayClick = () => {
    handleClose();
    onDisplay(data);
  };

  const onEditClick = () => {
    handleClose();
    onEdit(data);
  };

  const onDeleteClick = () => {
    handleClose();
    onDelete(data);
  };
  const onChangeLogClick = () => {
    handleClose();
    onChangeLog(data);
  };
  const onCommentClick = () => {
    handleClose();
    onComment(data);
  };
  const onViewResponseClick = () => {
    handleClose();
    onViewResponse(data);
  };

  return (
    <>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {onDisplay && (
            <MenuItem onClick={onDisplayClick}>
              <ListItemIcon>
                <Iconify icon="vaadin:glasses" color="#1877F2" width={22} height={22} />
              </ListItemIcon>
              <Typography variant="inherit">Display</Typography>
            </MenuItem>
          )}
          {onEdit && (
            <MenuItem disabled={disableEdit} onClick={onEditClick}>
              <ListItemIcon>
                <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
              </ListItemIcon>
              <Typography variant="inherit">Edit</Typography>
            </MenuItem>
          )}
          {onDelete && (
            <MenuItem disabled={disableDelete} onClick={onDeleteClick}>
              <ListItemIcon>
                <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
              </ListItemIcon>
              <Typography variant="inherit">Delete</Typography>
            </MenuItem>
          )}
          {onChangeLog && (
            <MenuItem onClick={onChangeLogClick}>
              <Iconify icon="mdi:clipboard-text-search" color="#1877F2" width={22} height={22} />
              <span style={{ marginLeft: '8px' }}>Change log</span>
            </MenuItem>
          )}
          {onComment && (
            <MenuItem onClick={onCommentClick}>
              <Iconify icon="material-symbols:comment" color="#1877F2" width={22} height={22} />
              <span style={{ marginLeft: '8px' }}>Comments</span>
            </MenuItem>
          )}
          {onViewResponse && (
            <MenuItem onClick={onViewResponseClick}>
              <Iconify icon="carbon:result" color="#1877F2" width={22} height={22} />
              <span style={{ marginLeft: '8px' }}>View Response</span>
            </MenuItem>
          )}
          {customButton &&
            customButton?.map((item) => (
              <MenuItem onClick={item?.onClick}>
                <Iconify icon={item?.icon} color="#1877F2" width={22} height={22} />
                <span style={{ marginLeft: '8px' }}>{item?.name}</span>
              </MenuItem>
            ))}
        </Menu>
      </div>
    </>
  );
}
