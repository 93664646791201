import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFeedbackAction } from '../../store/feedbackSlice';
import FeedbackComponent from '../FeedbackComponent';
import Scrollbar from '../Scrollbar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '20px',
  boxShadow: 24,
  p: '30px',
};
const ReplyFeedbackModal = ({ openEditModal, setOpenEditModal, id, isEdit }) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state.login);
  const { current } = useSelector((state) => state.feedback);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (openEditModal && dispatch) dispatch(getFeedbackAction({ id }));
  }, [openEditModal, dispatch]);

  
  return (
    <Modal open={openEditModal} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <Box
        sx={{
          ...style,
          overflow: 'hidden',
          height: '86%',
          backgroundColor: '#F2F2F2',
          width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' },
        }}
      >
        <div className={classes.close_div1}>
          <div className={classes.close_div} onClick={handleClose} aria-hidden="true">
            <CloseIcon className={classes.close_icon} />
          </div>
        </div>

        <Typography variant="h4" gutterBottom sx={{ fontSize: '27px', fontWeight: 'bolder', color: '#4F7942' }}>
          {isEdit === 'edit' ? <p> SS{current?.supportId} - {current?.description} ... Replies</p> : <p>Feedback SS{current?.supportId} - {current?.description} ... Comments</p>}
        </Typography>
        <Scrollbar sx={{ paddingBottom: '20px' }}>
          {Array.isArray(current?.feedback) &&
            _.reverse([...current?.feedback])?.map((item, index) => (
              <FeedbackComponent
                key={index}
                item={item}
                user={current?.feedbackUsers.find((d) => d._id === item.updatedBy)}
                isLast={index === 0}
                color={'0058ffdb'}
                mode={'business'}
                feedbackId={current?._id}
                openEditModal={openEditModal}
                setOpenEditModal={setOpenEditModal}
                isEdit={isEdit}
                prevData={index !== 0 && _.reverse([...current.feedback])[index - 1]}
              />
            ))}
          {current?.feedback?.length === 0 && (
            <FeedbackComponent
              item={{
                description: current?.description,
                details: current?.details,
                updatedBy: user?.userID,
                createdAt: current?.createdAt,
                updatedAt: current?.updatedAt,
                attachment: current?.attachment,
                status: current?.status,
                userRole: 'user',
              }}
              user={user}
              color={'0058ffdb'}
              isLast={current?.feedback?.length === 0}
              mode={'business'}
              feedbackId={current?._id}
              openEditModal={openEditModal}
              setOpenEditModal={setOpenEditModal}
              isEdit={isEdit}
            />
          )}
        </Scrollbar>
      </Box>
    </Modal>
  );
};

const useStyles = makeStyles({
  close_div1: {
    float: 'right',
    marginTop: '-15px',
    marginRight: '-15px',
  },
  close_div: {
    height: 45,
    width: 45,
    borderRadius: '50%',
    backgroundColor: '#90EE90',
    padding: '8px 9px',
    cursor: 'pointer',
  },
  close_icon: {
    height: 27,
    width: 27,
  },
});

export default ReplyFeedbackModal;