import React, { useEffect, useRef, cloneElement, useState } from 'react';
import { Tooltip } from '@mui/material';
import { addAdClicksAndImpressionsAnalytics } from '../services/Service';

const AnalyticsClickImpression = ({ children, id, page, data }) => {
  const componentRef = useRef(null);

  const handleClick = async () => {
    try {
      const payload = {
        adId: id,
        type: 'click', // click, impression
        page: page, // Dashboard, Offers, SafalRoomPrivate, SafalRoomPublic, SafalBuddy
        device: 'Web', // Web, IOS, Android
      };
      await addAdClicksAndImpressionsAnalytics(payload);
    } catch (err) {
      console.log(err);
    }
  };

  // Detect when component is in view
  const handleObserver = async () => {
    if (componentRef?.current?.isVisible) return;
    componentRef.current.isVisible = true;
    try {
      const payload = {
        adId: id,
        type: 'impression', // click, impression
        page: page, // Dashboard, Offers, SafalRoomPrivate, SafalRoomPublic, SafalBuddy
        device: 'Web', // Web, IOS, Android
      };
      await addAdClicksAndImpressionsAnalytics(payload);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleObserver(id);
        }
      },
      {
        root: null, // default is the viewport
        rootMargin: '0px', // no margin
        threshold: 0.25, // 25% of target visible
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    // Clean up the observer
    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const returnEle = React.Children.only(
    cloneElement(children, {
      ref: componentRef,
      onClick: handleClick,
    })
  );
  if (data?.adContentText) {
    return <Tooltip title={data?.adContentText}>{returnEle}</Tooltip>;
  }
  // Ensure that the child is a single React element
  return returnEle;
};

export default AnalyticsClickImpression;
