import { Switch } from '@mui/material';
import React from 'react';

const SwitchForForms = ({ name, checked, onChange }) => {
  const handleSwitchChange = (event) => {
    if (event.target.checked) {
      onChange(name, 'unlimited');
    } else {
      onChange(name, 0);
    }
  };

  const nub = typeof checked === 'number';

  return (
    <>
      <Switch
        checked={!nub}
        onChange={handleSwitchChange}
        color="success"
        inputProps={{ 'aria-label': 'controlled' }}
      />{' '}
    </>
  );
};

export default SwitchForForms;
