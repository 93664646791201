import { Box, Card, Grid, Typography, Tabs, Tab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import NumberOfSubscriptions from '../../sections/BusinessTabs/NumberOfSubscriptions';
import TextEmails from '../../sections/BusinessTabs/TextEmails';
import ChatWithOtherUser from '../../sections/BusinessTabs/ChatWithOtherUser';
import AudioVideoCall from '../../sections/BusinessTabs/AudioVideoCall';
import Downloads from '../../sections/BusinessTabs/Downloads';
import Uploads from '../../sections/BusinessTabs/Uploads';
import Ads from '../../sections/BusinessTabs/Ads';
import AssistanceBySupport from '../../sections/BusinessTabs/AssistanceBySupport';
import { GetMemoryDetails } from '../../services/Service';
import { setMemory } from '../../store/businessSlice';
import AssetsLimits from '../../sections/BusinessTabs/AssetsLimits';
import SafalAiLimits from '../../sections/BusinessTabs/SafalAiLimits';
import SubLabelLimits from '../../sections/BusinessTabs/SubLabelLimits';

const ManageMermory = () => {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      key: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Tabnames = [
    'Number of Subscriptions',
    'Text & Emails',
    'Chat with other Users',
    'Audio & video call',
    'Uploads',
    'Downloads / Upload SubList',
    'Assets Limits',
    'Safal-Ai Limits',
    'Sub-Label',
  ];

  const TabContent = (data) => {
    switch (data) {
      case 0:
        return <NumberOfSubscriptions />;
      case 1:
        return <TextEmails />;
      case 2:
        return <ChatWithOtherUser />;
      case 3:
        return <AudioVideoCall />;
      case 4:
        return <Uploads />;
      case 5:
        return <Downloads />;
      case 6:
        return <AssetsLimits />;
      case 7:
        return <SafalAiLimits />;
      case 8:
        return <SubLabelLimits />;

      default:
        `Loading ....`;
    }
  };

  useEffect(() => {
    GetMemoryDetails().then((res) => {
      dispatch(setMemory(res.data.data));
    });
  }, []);

  return (
    <Page title="Profile - SafalSubscriptions">
      <div>
        <form>
          <Box sx={{ fontSize: '40px', color: '#027C34', fontWeight: 700, mb: 2 }}>Manage Tiers</Box>
          <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={12}>
              <Card sx={{ borderRadius: '20px', px: 7, py: 4 }}>
                <Box sx={{ p: 0 }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs"
                    fullWidth
                    sx={{
                      border: '1px solid #E2E2E2',
                      borderRadius: '30px',
                      p: 0.5,
                      color: '#000000',
                      '& .Mui-selected svg': {
                        fill: 'white',
                        mr: 0.7,
                      },
                      '& .MuiButtonBase-root.Mui-selected': {
                        borderRadius: '30px',
                        backgroundColor: '#EAFFEA',
                        textTransform: 'none',
                        textDecoration: 'none',
                        color: '#027C34',
                        border: '1px solid #027C34',

                        // height:"40px"
                      },
                      '& .MuiTabs-indicator': {
                        display: 'none',
                      },
                    }}
                  >
                    {Tabnames.map((i, idx) => (
                      <Tab
                        key={i}
                        label={
                          <>
                            <Typography sx={{ fontSize: '15px' }}>{i}</Typography>
                          </>
                        }
                        {...a11yProps(idx)}
                        iconPosition="start"
                        sx={{
                          width: `${(100 / Tabnames.length).toFixed(2)}%`,
                          minHeight: '35px',
                          textTransform: 'none',
                          color: '#000000',
                        }}
                      />
                    ))}
                  </Tabs>
                </Box>

                {TabContent(value)}
              </Card>
            </Grid>
          </Grid>
        </form>
      </div>
    </Page>
  );
};

export default ManageMermory;
