import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { FeedMessage } from './SafalRoomFeed';
import { createSafalRoomPrivatePolls } from '../../../services/Service';
import DateField from '../../../components/UI/DateField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '16px',
  p: 3,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  question: '', // min 3, max 500
  name: '', // min 3, max 50
  expiry: '',
  // description: '', // min 100 char
  options: [''],
};

export default function AddPollsModal({ open, onClose, data, companyId, roomId, myRole }) {
  const [fieldValue, setFieldValue] = useState(initFieldValue);
  const [disabled, setDisabled] = useState(false);

  const handleClose = () => {
    onClose();
    setFieldValue(initFieldValue);
  };

  const validateFields = () => {
    if (fieldValue.name.length < 3 || fieldValue.name.length > 50) {
      toast.error('Name must be between 3 and 50 characters.');
      return false;
    }
    if (fieldValue.question.length < 3 || fieldValue.question.length > 500) {
      toast.error('Topic must be between 3 and 30 characters.');
      return false;
    }
    if (fieldValue.options.length < 1 || fieldValue.options.length > 10) {
      toast.error('options must be between 1 and 10 characters.');
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    if (!validateFields()) return;
    const payload = {
      ...fieldValue,
      roomId: roomId,
      company: companyId,
      description: '',
      status: myRole === 'ADMIN',
    };
    const filterOptions = fieldValue?.options
      ?.filter((e) => e?.trim() !== '')
      ?.map((e) => ({
        option: e,
        pullUser: [],
      }));
    if (filterOptions.length === 0) {
      toast.error('You must have 1 option');
      return;
    }
    payload.options = filterOptions;

    try {
      setDisabled(true);
      const res = await createSafalRoomPrivatePolls(payload);
      if (res.status === 200) {
        toast.success(res?.data?.message);
        handleClose();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisabled(false);
    }
  };

  const incAnswerArray = () => {
    if (fieldValue.options.length === 10) {
      toast.error('You can not add more than 5 answer');
      return;
    }
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.options.push('');
      return newPrev;
    });
  };
  const decAnswerArray = () => {
    if (fieldValue.options.length === 1) {
      toast.error('You must have 1 answer');
      return;
    }
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.options.pop();
      return newPrev;
    });
  };
  const onChangeAnswerArray = (index, value) => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.options[index] = value;
      return newPrev;
    });
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '8px', fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}
          >
            Create Poll
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <TextField
              size="small"
              disabled={disabled}
              value={fieldValue.name}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
              label={
                <Typography sx={{ color: '#B6B6B6' }}>
                  Name <span style={{ color: 'red' }}>*</span>
                </Typography>
              }
              inputProps={{ minLength: 3, maxLength: 50 }}
              helperText={`${fieldValue.name.length}/50`}
            />
            <DateField
              disablePast
              value={fieldValue.expiry}
              fullWidth
              variant="outlined"
              size="small"
              label={<Typography sx={{ color: '#B6B6B6' }}>Expiry Date</Typography>}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, expiry: e?.toISOString() || '' }))}
            />
            <TextField
              size="small"
              disabled={disabled}
              value={fieldValue.question}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, question: e.target.value }))}
              label={
                <Typography sx={{ color: '#B6B6B6' }}>
                  Question <span style={{ color: 'red' }}>*</span>
                </Typography>
              }
              multiline
              rows={2}
              inputProps={{ minLength: 3, maxLength: 500 }}
              helperText={`${fieldValue.question.length}/500`}
            />
            <div
              style={{
                padding: '0px 12px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: '#6a7a87',
              }}
            >
              <span>Options List</span>
              <div style={{ display: 'flex', gap: '8px' }}>
                <IconButton disabled={disabled} onClick={decAnswerArray} size="small">
                  <RemoveIcon />
                </IconButton>
                <IconButton disabled={disabled} onClick={incAnswerArray} size="small">
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
              {fieldValue?.options?.map((ans, index) => (
                <TextField
                  key={index}
                  value={ans}
                  onChange={(e) => onChangeAnswerArray(index, e.target.value)}
                  label={`Options ${index + 1}`}
                  name={`Options${index}`}
                  size="small"
                  type="text"
                  fullWidth
                  disabled={disabled}
                />
              ))}
            </div>
            {/* <TextField
              disabled={disabled}
              value={fieldValue.description}
              onChange={(e) => setFieldValue((prev) => ({ ...prev, description: e.target.value }))}
              multiline
              rows={3}
              label="Description"
              inputProps={{ minLength: 100, maxLength: 500 }}
              helperText={`${fieldValue.description.length}/500`}
            /> */}
            <p style={{ fontSize: '14px' }}>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt iusto quae incidunt enim, mollitia ipsum
              natus non itaque assumenda est dicta distinctio ipsam id magni tempore eligendi earum, quis quaerat!
            </p>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'space-between' }}>
            <div />
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <Button
                onClick={handleClose}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  backgroundColor: '#ff4842',
                  ':hover': { backgroundColor: '#d40700' },
                }}
                variant="contained"
              >
                Close
              </Button>
              <Button
                onClick={onSubmit}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
