// active,pending,reject,block
const SelectBar = ({ value, onChange, meta }) => {
  return (
    <div
      style={{
        flex: 'none',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '12px',
        overflow: 'hidden',
        border: '1px solid #BCCEFF',
        margin: '0px 16px',
        backgroundColor: 'white',
        padding: '4px 8px',
        gap: '8px',
      }}
    >
      <button
        onClick={() => onChange('accept')}
        style={{
          border: 'none',
          backgroundColor: 'white',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
        className={value === 'accept' ? 'button-bottom-bar-buddy-select' : ''}
      >
        <img
          style={{ height: '18px', objectFit: 'contain' }}
          src={
            value === 'accept'
              ? '/assets/images/buddy/buddy_accept_color.png'
              : '/assets/images/buddy/buddy_accept_bw.png'
          }
          alt="accept"
        />
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: value === 'accept' ? '#05b22b' : '#a7a3a3',
            fontWeight: 'bold',
          }}
        >
          SafalBuddies {meta?.safalBuddies ? `(${meta?.safalBuddies})` : ''}
        </span>
      </button>
      <button
        onClick={() => onChange('pending')}
        style={{
          border: 'none',
          backgroundColor: 'white',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
        className={value === 'pending' ? 'button-bottom-bar-buddy-select' : ''}
      >
        <img
          style={{ height: '18px', objectFit: 'contain' }}
          src={
            value === 'pending'
              ? '/assets/images/buddy/buddy_pending_color.png'
              : '/assets/images/buddy/buddy_pending_bw.png'
          }
          alt="pending"
        />
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: value === 'pending' ? '#b88f00' : '#a7a3a3',
            fontWeight: 'bold',
          }}
        >
          Pending SafalBuddies {meta?.pendingSafalBuddies ? `(${meta?.pendingSafalBuddies})` : ''}
        </span>
      </button>
      <button
        onClick={() => onChange('reject')}
        style={{
          border: 'none',
          backgroundColor: 'white',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
        className={value === 'reject' ? 'button-bottom-bar-buddy-select' : ''}
      >
        <img
          style={{ height: '18px', objectFit: 'contain' }}
          src={
            value === 'reject'
              ? '/assets/images/buddy/buddy_reject_color.png'
              : '/assets/images/buddy/buddy_reject_bw.png'
          }
          alt="reject"
        />
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: value === 'reject' ? '#fb6464' : '#a7a3a3',
            fontWeight: 'bold',
          }}
        >
          Rejected SafalBuddies {meta?.rejectedSafalBuddies ? `(${meta?.rejectedSafalBuddies})` : ''}
        </span>
      </button>
      <button
        onClick={() => onChange('block')}
        style={{
          border: 'none',
          backgroundColor: 'white',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
        className={value === 'block' ? 'button-bottom-bar-buddy-select' : ''}
      >
        <img
          style={{ height: '18px', objectFit: 'contain' }}
          src={
            value === 'block' ? '/assets/images/buddy/buddy_block_color.png' : '/assets/images/buddy/buddy_block_bw.png'
          }
          alt="block"
        />
        <span
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: value === 'block' ? '#ff0000' : '#a7a3a3',
            fontWeight: 'bold',
          }}
        >
          Blocked Users {meta?.blockedUsers ? `(${meta?.blockedUsers})` : ''}
        </span>
      </button>
    </div>
  );
};

export default SelectBar;
