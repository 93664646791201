import * as React from 'react';
import { Image } from 'antd';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { IconButton, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import {
  createHelpVideoModuleBusiness,
  updateHelpVideoModuleBusiness,
  uploadHelpVideoIcon,
} from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  megaModule: '',
  megaModuleIcon: '',
  index: 1,
  module: [
    {
      index: 1,
      module: '',
      subModule: [],
    },
  ],
  status: true, // default true
};

export default function AddHelpVideoModuleModal({ open, onClose, data, isDisplay }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    setSelectedFile(null);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      if (!selectedFile) {
        toast.error('select a icon');
        return;
      }
      const fileRes = await uploadHelpVideoIcon(selectedFile);
      const payload = { ...fieldValue, megaModuleIcon: fileRes.data.url };
      const res = await createHelpVideoModuleBusiness(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const payload = { ...fieldValue, _id: data._id };
      if (selectedFile) {
        const fileRes = await uploadHelpVideoIcon(selectedFile);
        payload.megaModuleIcon = fileRes.data.url;
      }

      const res = await updateHelpVideoModuleBusiness(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        megaModule: data?.megaModule,
        megaModuleIcon: data?.megaModuleIcon,
        module: data?.module,
        index: data?.index,
        status: data?.status, // default true
      });

      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  const incAnswerArray = () => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.module.push({
        index: newPrev.module.length + 1,
        module: '',
        subModule: [],
      });
      return newPrev;
    });
  };
  const decAnswerArray = () => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.module.pop();
      return newPrev;
    });
  };

  const onChangeModuleArray = (index, value) => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.module[index].module = value;
      return newPrev;
    });
  };
  const onChangeSubModuleArray = (index, value) => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      newPrev.module[index].subModule = value?.split(',');
      return newPrev;
    });
  };
  const onChangeModuleSequence = (index, value) => {
    setFieldValue((prev) => {
      const newPrev = { ...prev };
      const indexX = parseInt(value, 10);
      newPrev.module[index].index = indexX < 1 ? 1 : indexX;
      return newPrev;
    });
  };

  const logoImageUrl = selectedFile
    ? URL.createObjectURL(selectedFile)
    : fieldValue?.megaModuleIcon?.length > 1
    ? fieldValue?.megaModuleIcon
    : '';

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Help Video Info' : isDisplay ? 'Help Video Info' : 'Update Help Video Info'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'flex-end',
            }}
          >
            <div style={{ flex: 'none' }}>
              <Image
                preview={false}
                style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                src={logoImageUrl}
                alt="logo"
              />
            </div>
            <TextField
              onChange={(e) => {
                if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
                  toast.error('Please upload image in JPG, JPEG, PNG Format ONLY.');
                } else {
                  setSelectedFile(e.target.files[0]);
                }
              }}
              type="file"
              required
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                accept: 'image/*',
              }}
            />
          </div>
          {/* megaModule */}
          <TextField
            value={fieldValue.megaModule}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, megaModule: e.target.value }))}
            required
            label="Mega Module Name"
            name="megaModule"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* index */}
          <TextField
            value={fieldValue.index}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, index: parseInt(e.target.value, 10) }))}
            required
            label="Mega Module Sequence"
            name="index"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          <div
            style={{
              padding: '0px 12px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              color: '#6a7a87',
            }}
          >
            <span>Module List</span>
            <div style={{ display: 'flex', gap: '8px' }}>
              <IconButton disabled={isDisableAll} onClick={decAnswerArray} size="small">
                <RemoveIcon />
              </IconButton>
              <IconButton disabled={isDisableAll} onClick={incAnswerArray} size="small">
                <AddIcon />
              </IconButton>
            </div>
          </div>
          {fieldValue?.module?.map((ans, index) => (
            <React.Fragment key={index}>
              {/* <span>Answer {index + 1} Markdown & Text Format</span> */}
              <div style={{ display: 'flex', gap: '8px' }}>
                <TextField
                  value={ans.index}
                  onChange={(e) => onChangeModuleSequence(index, e.target.value)}
                  required={index === 0}
                  label={`Module ${index + 1} sequence`}
                  name={`answer${index}Sequence`}
                  size="small"
                  type="number"
                  variant="outlined"
                  disabled={isDisableAll}
                  sx={{ width: '200px' }}
                />
                <TextField
                  value={ans.module}
                  onChange={(e) => onChangeModuleArray(index, e.target.value)}
                  required={index === 0}
                  label={`Module ${index + 1}`}
                  name={`answer${index}`}
                  size="small"
                  type="text"
                  fullWidth
                  variant="outlined"
                  disabled={isDisableAll}
                />
              </div>
              <TextField
                value={ans.subModule?.join(',')}
                onChange={(e) => onChangeSubModuleArray(index, e.target.value)}
                label={`Module ${index + 1} - SubModule Names (Comma separated)`}
                size="small"
                type="text"
                fullWidth
                variant="outlined"
                disabled={isDisableAll}
              />
            </React.Fragment>
          ))}

          {/* status */}
          {/* <TextField
            value={fieldValue.status === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField> */}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
