import * as React from 'react';
import { makeStyles } from '@mui/styles';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import MenuItem from '@mui/material/MenuItem';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import { usePopupState, bindHover, bindFocus, bindMenu, bindTrigger } from 'material-ui-popup-state/hooks';
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { NavLink as RouterLink } from 'react-router-dom';

const ListItemStyle = styled((props) => <ListItemButton disableGutters disableRipple {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 32,
  position: 'relative',
  textTransform: 'capitalize',
  // color: theme.palette.text.secondary,
  color: '#000000',
  borderRadius: theme.shape.borderRadius,
  //   '&:hover': {
  //     img: {
  //       filter: 'invert(1)',
  //     },
  //   },
}));

const useCascadingMenuStyles = makeStyles((theme) => ({
  submenu: {
    marginTop: theme.spacing(-1),
  },
  title: {
    flexGrow: 1,
  },
  moreArrow: {
    marginRight: theme.spacing(-1),
  },
}));

const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
});

function CascadingMenuItem({ onClick, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext);
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu');
  const handleClick = React.useCallback(
    (event) => {
      rootPopupState.close(event);
      if (onClick) onClick(event);
    },
    [rootPopupState, onClick]
  );

  return <MenuItem {...props} onClick={handleClick} />;
}

function CascadingSubmenu({ title, popupId, ...props }) {
  const classes = useCascadingMenuStyles();
  const { parentPopupState } = React.useContext(CascadingContext);
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    parentPopupState,
  });

  return (
    <>
      <MenuItem {...bindHover(popupState)} {...bindFocus(popupState)}>
        <span className={classes.title}>{title}</span>
        <ChevronRight className={classes.moreArrow} />
      </MenuItem>
      <CascadingMenu
        {...props}
        classes={{ ...props.classes, paper: classes.submenu }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        popupState={popupState}
      />
    </>
  );
}

function CascadingMenu({ popupState, ...props }) {
  const { rootPopupState } = React.useContext(CascadingContext);
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  );

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu {...props} {...bindMenu(popupState)} />
    </CascadingContext.Provider>
  );
}

const CustomMuiSubMenu = ({ title, onClick, icon, link, subMenu }) => {
  const popupState = usePopupState({
    popupId: 'demoMenu',
    variant: 'popover',
  });
  return (
    <>
      {subMenu?.length > 0 ? (
        <ListItemStyle
          onClick={onClick}
          sx={{
            ml: 1,
            pr: 2,
          }}
          {...bindHover(popupState)}
          {...bindFocus(popupState)}
        >
          <Box marginRight={1} marginLeft={1}>
            {icon}
          </Box>
          <ListItemText sx={{ pr: 2.5 }} disableTypography primary={title} />
          <ChevronRight sx={{ color: '#90EE90' }} />
        </ListItemStyle>
      ) : (
        <ListItemStyle
          onClick={onClick}
          component={RouterLink}
          to={link}
          sx={{
            ml: 1,
            pr: 2,
          }}
          {...bindHover(popupState)}
          {...bindFocus(popupState)}
        >
          <Box marginRight={1} marginLeft={1}>
            {icon}
          </Box>
          <ListItemText sx={{ pr: 2.5 }} disableTypography primary={title} />
        </ListItemStyle>
      )}
      {subMenu?.length > 0 && (
        <CascadingMenu
          popupState={popupState}
          anchorOrigin={{ vertical: 'left', horizontal: 'right' }}
          // transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {subMenu?.map((e) => (
            <CascadingMenuItem onClick={onClick} component={RouterLink} to={e.link}>
              <div
                style={{
                  height: '8px',
                  width: '8px',
                  borderRadius: '9999px',
                  backgroundColor: '#027C34',
                  marginRight: '6px',
                }}
              />
              {e?.title}
            </CascadingMenuItem>
          ))}
          {/* <CascadingSubmenu popupId="moreChoicesCascadingMenu" title="More Choices">
          <CascadingMenuItem>Cheesecake</CascadingMenuItem>
          <CascadingMenuItem>Cheesedeath</CascadingMenuItem>
          <CascadingSubmenu popupId="evenMoreChoicesCascadingMenu" title="Even More Choices">
            <CascadingMenuItem>Cake (the band)</CascadingMenuItem>
            <CascadingMenuItem>Death Metal</CascadingMenuItem>
          </CascadingSubmenu>
          <CascadingSubmenu popupId="moreBenignChoices" title="More Benign Choices">
            <CascadingMenuItem>Salad</CascadingMenuItem>
            <CascadingMenuItem>Lobotomy</CascadingMenuItem>
          </CascadingSubmenu>
        </CascadingSubmenu> */}
        </CascadingMenu>
      )}
    </>
  );
};

export default CustomMuiSubMenu;
