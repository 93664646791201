/* eslint-disable react/jsx-boolean-value */
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { getBusinessDashboardTotalUsers, getChurnRate } from '../../../../services/Service';
import { formatNumberToShort } from '../../../../utils/general';

// const colors = ['#468b47','#90ee90', '#edf9eb'];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const ChurnRateAreaChartCard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [mode, setMode] = useState('CurrentMonth'); // CurrentMonth, LastMonth

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const res = await getChurnRate(filter);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let startDate = '';
    let endDate = '';

    switch (mode) {
      case 'CurrentMonth':
        startDate = moment().startOf('month').toISOString();
        endDate = moment().endOf('month').toISOString();
        break;
      case 'LastMonth':
        startDate = moment().subtract(1, 'month').startOf('month').toISOString();
        endDate = moment().subtract(1, 'month').endOf('month').toISOString();
        break;

      default:
        break;
    }

    fetchData({ endDate, startDate });
  }, [mode]);

  const totalUserCount = data?.totalUserCount ?? 0;
  const addLastMonthUserCount = data?.addLastMonthUserCount ?? 0;

  const dates = data?.churnRate ? Object.keys(data?.churnRate) : [];
  const counts = data?.churnRate ? Object.values(data?.churnRate) : [];
  const chartOption = {
    title: {
      text: formatNumberToShort(totalUserCount),
      subtext: 'Users Churn Rate',
      left: 'left', // Align title to the left
      textStyle: {
        color: '#468b47', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
      subtextStyle: {
        color: '#468b47', // Green color for the subtitle
        lineHeight: 2, // Reduced line height for subtitle
      },
    },
    // tooltip: {
    //   trigger: 'item',
    // },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dates,
      axisTick: { show: false },
      axisLabel: {
        show: false, // Remove x-axis labels
      },
      splitLine: {
        show: false, // Remove vertical grid lines
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#468b47', // Green line at the bottom (x-axis)
          width: 5, // Increased line thickness for the y-axis
        },
        offset: -10, // Add offset
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: false, // Remove y-axis labels
      },
      splitLine: {
        show: false, // Remove horizontal grid lines
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#468b47', // Green line on the left (y-axis)
          width: 5, // Increased line thickness for the y-axis
        },
        offset: 10, // Add offset
      },
    },
    series: [
      {
        data: counts,
        type: 'line',
        areaStyle: {
          color: '#468b47', // Green area color
        },
        lineStyle: {
          color: '#468b47', // Change top line color (black in this case)
          width: 2, // Optional: Adjust the line width if needed
        },
        symbol: 'none', // Remove the dots on the line
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <select
        value={mode}
        style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 1 }}
        onChange={(e) => setMode(e.target.value)}
      >
        <option value="CurrentMonth">Current Month</option>
        <option value="LastMonth">Last Month</option>
      </select>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        showLoading={loading}
        option={chartOption}
        style={{ height: '220px', minHeight: '200px' }}
      />
      <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'max-content',
          color: '#468b47',
          fontSize: '14px',
        }}
      >
        {formatNumberToShort(addLastMonthUserCount)} users added last month
      </div>
    </div>
  );
};
export default ChurnRateAreaChartCard;
