import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

// routes
import { Box, CircularProgress } from '@mui/material';
import { useCookies } from 'react-cookie';
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import MainRoutes from './routes';
import { getProfile, getNotifications } from './store/loginSlice';
import './styles/global.css';
import Chatbot from './components/SafalBot/Chatbot';
import ChatListener from './components/ChatListener';
import NotificationListener from './components/NotificationListener';
import Call from './components/Call';
import useChatListener from './hooks/useChatListener';
import useFirebase from './hooks/useFirebase';
import AgoraUI from './components/AgoraUI/AgoraUI';
import { getModuleConfiguration, getSafalLeaderBoardActivitiesConfig } from './services/Service';
import { setDynamicMenuState } from './store/DynamicMenuSlice';
import { setSafalLeaderBoardSwitch } from './store/SafalLeaderBoardSwitchSlice';
// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tokennn, removeCookie] = useCookies(['token']);
  // const {
  //   user: { role },
  // } = useSelector((state) => state.login);

  const { user } = useSelector((state) => state.login);
  const { role } = user;
  const location = useLocation();

  const [showChatbot, setShowChatbot] = useState(false);
  React.useEffect(() => {
    dispatch(getProfile())
      .unwrap()
      .catch(() => {
        localStorage.removeItem('Jtoken');
        // navigate('/login')
      });

    dispatch(getNotifications())
      .unwrap()
      .catch(() => {
        // navigate('/login')
      });

    localStorage.setItem('rememberToken', tokennn.token);
  }, [dispatch, tokennn.token]);

  React.useEffect(() => {
    const DYNAMIC_MENU_VERSION = 'Beta 1.0';
    getModuleConfiguration(DYNAMIC_MENU_VERSION, 'web')
      .then((res) => {
        const res_data = res?.data?.data;
        if (res_data) {
          dispatch(setDynamicMenuState(res_data));
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // SafalLeaderBoard Master Config Fetch
    getSafalLeaderBoardActivitiesConfig({ application: 'SafalSubscriptions' }).then((res) => {
      const masterSwitch = res?.data?.masterSwitch;
      if (masterSwitch && masterSwitch.length > 0) {
        const resData = masterSwitch[0];
        dispatch(setSafalLeaderBoardSwitch(resData));
      }
    });
  }, []);

  // this is use for Listener Chat notifications for business user
  useChatListener();
  // this hook can use for all firebase communication
  useFirebase();

  // Determine whether to show the chatbot based on the user's role and the current route
  useEffect(() => {
    if (user.role === 'user' && !location.pathname.includes('/login')) {
      setShowChatbot(true);
    } else {
      setShowChatbot(false);
    }
  }, [user.role, location.pathname]);

  return (
    <ThemeProvider>
      {role && (
        <>
          <NotificationListener />
          {/* {role === 'user' && <Chatbot />} */}
          {role === 'user' && showChatbot && <Chatbot />}
          <ChatListener />
          <Call />
        </>
      )}
      {/* <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: 9999,
        }}
      >
        <CircularProgress />
      </Box> */}
      <ScrollToTop />
      <MainRoutes />
      <Toaster position="top-center" />
      <AgoraUI />
    </ThemeProvider>
  );
}
