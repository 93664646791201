import { createSlice } from '@reduxjs/toolkit';

const initialState = null;
// const initialState = {
//   callId: undefined, // uuidv4()
//   isCall: false, //
//   type: undefined, // user or support
//   callMode: 'audio', // audio/video,
//   currentUserId: undefined, // currentUserId or undefined
//   otherUserId: undefined, // undefined or otherUserId
//   status: undefined, // undefined,"pending", "accept", "reject", "end"
//   startAt: undefined, // serverTimestamp or undefined
//   endAt: undefined, // serverTimestamp() or undefined
// };

export const agoraSlice = createSlice({
  name: 'agoraSlice',
  initialState,
  reducers: {
    setAgoraState: (state, action) => {
      state = action.payload;
      return state;
    },
    resetAgoraState: () => {
      return initialState;
    },
  },
});

export const { setAgoraState, resetAgoraState } = agoraSlice.actions;

export default agoraSlice.reducer;
