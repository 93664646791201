import { Button, InputAdornment, OutlinedInput, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './OffersList.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import {
  getOffersList,
  deleteOffer,
  GetcompaniesResponse,
  getOfferSocialMedia,
  deleteOfferSocialMedia,
} from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddOfferModal from './AddOfferModal';
import SingleOfferModal from './SingleOfferModal';
import TableViewSocial from './TableViewSocial';
import AddOfferModalSocial from './AddOfferModalSocial';

const OffersList = () => {
  const [addModalOpen, setAddModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [addModalOpenSocial, setAddModalOpenSocial] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [commentOpen, setCommentOpen] = useState({
    data: null,
    open: false,
  });
  const [selectedToggle, setSelectedToggle] = useState('offers');
  const [rawData, setRawData] = useState([]);
  const [rawDataSocial, setRawDataSocial] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getOffersList();
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('OffersList fetchData error', err.message);
    }
  };
  const fetchDataSocial = async () => {
    try {
      const res = await getOfferSocialMedia();
      if (res.status === 200) {
        setRawDataSocial(res.data.data);
      }
    } catch (err) {
      console.log('fetchDataSocial error', err.message);
    }
  };
  const fetchCompanyData = async () => {
    try {
      const res = await GetcompaniesResponse();
      if (res.status === 200) {
        setCompanyList(res.data.data);
      }
    } catch (err) {
      console.log('OffersList fetchData error', err.message);
    }
  };

  const onAddModalOpenDisplay = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenDisplaySocial = (data) => {
    setAddModalOpenSocial({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenEdit = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpenEditSocial = (data) => {
    setAddModalOpenSocial({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpen = () => {
    setAddModalOpen({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpenSocial = () => {
    setAddModalOpenSocial({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData();
  };
  const onAddModalCloseSocial = () => {
    setAddModalOpenSocial({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchDataSocial();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteOffer(data._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const onDeleteSocial = async (data) => {
    try {
      const res = await deleteOfferSocialMedia(data._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchDataSocial();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onOpenCommentModal = (data) => {
    setCommentOpen({
      open: true,
      data: data,
    });
  };

  const onCloseCommentModal = (data) => {
    setCommentOpen({
      open: false,
      data: null,
    });
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
    fetchDataSocial();
    fetchCompanyData();
  }, []);

  const handleChangeToggle = async (e) => {
    const value = e.target.value;
    setSelectedToggle(value);
    if (value === 'offers') {
      fetchData();
    } else {
      fetchDataSocial();
    }
  };

  return (
    <>
      <Helmet>
        <title>Offers List - SafalSubscriptions</title>
      </Helmet>

      <div id="OffersList">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Offers List
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={selectedToggle}
              exclusive
              onChange={handleChangeToggle}
            >
              <ToggleButton sx={{ px: '12px', textTransform: 'capitalize' }} value="offers">
                Offers List
              </ToggleButton>
              <ToggleButton sx={{ px: '12px', textTransform: 'capitalize' }} value="social">
                Social Media Link
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="title-action-div">
            <CustomButton
              backgroundColor="#027c34"
              text="Add New"
              icon={<AddIcon />}
              onClick={selectedToggle === 'offers' ? onAddModalOpen : onAddModalOpenSocial}
            />
          </div>
        </div>
        {selectedToggle === 'offers' && (
          <TableView
            onDelete={(data) => {
              setIsDeleteConfirmationModalOpen({
                open: true,
                data,
              });
            }}
            onEdit={onAddModalOpenEdit}
            onDisplay={onAddModalOpenDisplay}
            onComment={onOpenCommentModal}
            data={rawData}
          />
        )}
        {selectedToggle === 'social' && (
          <TableViewSocial
            onDelete={(data) => {
              setIsDeleteConfirmationModalOpen({
                open: true,
                data,
              });
            }}
            onEdit={onAddModalOpenEditSocial}
            onDisplay={onAddModalOpenDisplaySocial}
            data={rawDataSocial}
          />
        )}
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={selectedToggle === 'offers' ? onDelete : onDeleteSocial}
      />
      <AddOfferModal
        socialList={rawDataSocial}
        companyList={companyList}
        open={addModalOpen.open}
        data={addModalOpen.data}
        isDisplay={addModalOpen.isDisplay}
        onClose={onAddModalClose}
      />
      <AddOfferModalSocial
        open={addModalOpenSocial.open}
        data={addModalOpenSocial.data}
        isDisplay={addModalOpenSocial.isDisplay}
        onClose={onAddModalCloseSocial}
      />
      <SingleOfferModal open={commentOpen.open} data={commentOpen.data} onClose={onCloseCommentModal} />
    </>
  );
};

export default OffersList;
