import { Link as RouterLink, useParams } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import Page from '../../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404Offers() {
  const { id, type } = useParams();

  return (
    <Page title="404 Page Not Found">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, you need to login to view this offer
          </Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography> */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <Button
              sx={{
                backgroundColor: '#3D71FF',
                textTransform: 'capitalize',
                borderRadius: '30px',
                width: '120px',
              }}
              to={`/register`}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Register
            </Button>
            <Button
              sx={{
                backgroundColor: '#3D71FF',
                textTransform: 'capitalize',
                borderRadius: '30px',
                width: '120px',
              }}
              to={`/login?page=offers&type=${type}&id=${id}`}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Login
            </Button>
          </div>
        </ContentStyle>
      </Container>
    </Page>
  );
}
