/* eslint-disable */
import { collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore';
import { Box, Paper, Modal, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import './NewCallLogModal.css';
import { db } from '../../firebase';
import { getAllFirebaseCallLogs } from '../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
  borderRadius: '10px',
  height: '80%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: '20px',
  paddingTop: '0',
};

// const adas = {
//   callId: '07a854fa-af2d-4246-b72b-7af1b400b6b3',
//   callMode: 'video',
//   currentUserId: 'FMUyCI7IcCO1QEPYkp8yAru0mFW2',
//   endAt: 'nt {seconds: 1695053561, nanoseconds: 651000000}',
//   isCall: false,
//   startAt: 'nt {seconds: 1695053547, nanoseconds: 494000000}',
//   status: 'end',
//   supportUserId: null,
//   type: 'support',
// };

const NewCallLogModal = ({ open, onClose, type, profile, myUid }) => {
  const [logsArray, setLogsArray] = useState([]);

  const getAllLogs = async () => {
    if (!myUid) return;
    const payload = {
      uid: myUid,
      type: type,
    };

    if (open !== 'support' && open !== 'self') {
      const otherUid = open?.length > 30 ? (open.split(myUid).length > 0 ? open.split(myUid)[0] : undefined) : open;

      payload.otherUid = otherUid;
    }
    const res = await getAllFirebaseCallLogs(payload);
    if (res.status === 200 && res.data.success) {
      setLogsArray(res.data.data);
    }
  };

  useEffect(() => {
    if (open === null) return;
    getAllLogs();
  }, [open, type, myUid]);

  return (
    <Modal open={open !== null} onClose={onClose}>
      <Box id="NewCallLogModal" sx={style}>
        <div className="log-title">
          <span>{type} Log</span>
          <button onClick={onClose}>Close</button>
        </div>
        <TableContainer component={Paper}>
          <Table stickyHeader size="small" aria-label="call log table">
            <TableHead>
              <TableRow>
                <TableCell>Incomming/Outgoing</TableCell>
                <TableCell>From</TableCell>
                <TableCell>To</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Mode</TableCell>
                <TableCell>Start At</TableCell>
                <TableCell>End At</TableCell>
                <TableCell>Durations</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logsArray.map((log, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{log.type}</TableCell>
                  <TableCell>{log.to}</TableCell>
                  <TableCell>{log.from}</TableCell>
                  <TableCell>{log.status}</TableCell>
                  <TableCell>{log.mode}</TableCell>
                  <TableCell>
                    {profile.country === 'India'
                      ? moment(log.startAt).format('DD/MM/YYYY hh:mm:ss a')
                      : moment(log.startAt).format('MM/DD/YYYY hh:mm:ss a')}
                  </TableCell>
                  <TableCell>
                    {profile.country === 'India'
                      ? moment(log.endAt).format('DD/MM/YYYY hh:mm:ss a')
                      : moment(log.endAt).format('MM/DD/YYYY hh:mm:ss a')}
                  </TableCell>
                  <TableCell>{log.durations}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default NewCallLogModal;
