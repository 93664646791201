/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  chatId: null,
  user: {},
  chatMode: null,
  onlineUsers: [],
  sid: null,
  supportAlert: false,
  userAlert: false,
  attachLimit: null,
};
export const chatSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    changeUser: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
        chatId: action.payload.id,
        sid: action.payload?.sid,
      };
    },
    changeSupport: (state, action) => {
      return {
        ...state,
        user: action.payload,
        chatId: localStorage.getItem('fire'),
      };
    },
    changeChatMode: (state, action) => {
      return {
        ...state,
        chatMode: action.payload,
      };
    },
    updateOnlineUsers: (state, action) => {
      return {
        ...state,
        onlineUsers: _.uniq(action.payload, true),
      };
    },
    updateSupportChat: (state, action) => {
      return {
        ...state,
        supportAlert: action.payload,
      };
    },
    updateUserChat: (state, action) => {
      return {
        ...state,
        userAlert: action.payload,
      };
    },
    updateAttachLimit: (state, action) => {
      return {
        ...state,
        attachLimit: action.payload,
      };
    },
    resetChat: () => {
      return initialState;
    },
    resetChatSoft: () => {
      return {
        chatMode: null,
        onlineUsers: [],
        supportAlert: false,
        userAlert: false,
        attachLimit: null,
      };
    },
  },
});

export const {
  changeUser,
  changeChatMode,
  changeSupport,
  updateOnlineUsers,
  updateSupportChat,
  updateUserChat,
  updateAttachLimit,
  resetChat,
  resetChatSoft,
} = chatSlice.actions;

export default chatSlice.reducer;
