import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment/moment';
import { countries } from '../_mock/countries';
import { GetNotifications, GetUserResponse, LoginResponse, Logout, ValidateUserId } from '../services/Service';
import ErrorToast from '../components/Toast/Error';

const initialState = {
  isLoading: false,
  NotificationisLoading: false,
  user: {},
  notification: {},
  Countries: countries,
};

export const loginUser = createAsyncThunk('auth/login', async (params, { rejectWithValue }) => {
  try {
    const response = await LoginResponse(params);
    // if (response.data.success && response.data.data.registered === true ) {
    if (response.data.success) {
      localStorage.setItem('Jtoken', response.data.token);
      localStorage.setItem('UserAssessmentMonthlyFeedback', 'TRUE');
    } else {
      ErrorToast(response.data.message);
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const getProfile = createAsyncThunk('auth/getProfile', async (params, { rejectWithValue }) => {
  try {
    const response = await GetUserResponse();
    if (!response.data.success) {
      rejectWithValue(response.data);
    }
    switch (response.data.data.tier) {
      case 0:
        response.data.data.tier = 'Beta';
        return response?.data;
      case 1:
        response.data.data.tier = 'Free';
        return response?.data;
      case 2:
        response.data.data.tier = 'Paid';
        return response?.data;
      case 3:
        response.data.data.tier = 'Premium';
        return response?.data;
      default:
        return response?.data;
    }
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});
// --------------------
export const validateUserid = createAsyncThunk('auth/validateUid', async (params, { rejectWithValue }) => {
  try {
    const response = await ValidateUserId(params);
    if (!response.data.success) {
      rejectWithValue(response.data);
    }
    return response?.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

// --------------------------

export const logout = createAsyncThunk('auth/logout', async (params, { rejectWithValue }) => {
  try {
    const response = await Logout();
    if (!response.data.success) {
      rejectWithValue(response.data);
    } else {
      const userId = localStorage.getItem('userId');
      const loginToken = localStorage.getItem('loginToken');
      localStorage.clear();
      userId && localStorage.setItem('userId', userId);
      loginToken && localStorage.setItem('loginToken', loginToken);
    }
    return true;
  } catch (error) {
    return false;
  }
});

export const getNotifications = createAsyncThunk('user/getNotifications', async (params, { rejectWithValue }) => {
  try {
    const response = await GetNotifications();
    if (!response.data.success) {
      rejectWithValue(response.data);
    }
    const data = Object.values(response?.data.data).sort((a, b) => moment(b.createdAt).diff(a.createdAt));

    // console.log("dlice notifid" , data);
    // return response.data
    return data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setLogindata: (state, action) => {
      state.user = { ...state.user, ...action.payload.data };
      return state;
    },
    updateLoginData: (state, { payload }) => {
      state.user.profilePic = payload.data.profilePic;
      return state;
    },
    updateUserNotification: (state, action) => {
      state.user.notification = action.payload;
    },
    resetLogin: () => {
      return initialState;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      switch (payload.data.tier) {
        case 0:
          payload.data.tier = 'Beta';
          state.user = payload.data;
          break;
        case 1:
          payload.data.tier = 'Free';
          state.user = payload.data;
          break;
        case 2:
          payload.data.tier = 'Paid';
          state.user = payload.data;
          break;
        case 3:
          payload.data.tier = 'Premium';
          state.user = payload.data;
          break;

        default:
          state.user = payload.data;
          break;
      }
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },
    [getProfile.pending]: (state) => {
      state.isLoading = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.user = { ...state.user, ...payload.data };
    },
    [getProfile.rejected]: (state) => {
      state.isLoading = false;
      // localStorage.clear();
    },
    [getNotifications.pending]: (state) => {
      state.NotificationisLoading = true;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.NotificationisLoading = false;
      state.notification = { ...state.notification, ...payload };
    },
    [getNotifications.rejected]: (state) => {
      state.NotificationisLoading = false;
    },
    [logout.pending]: (state) => {
      state.isLoading = true;
    },
    [logout.fulfilled]: (state) => {
      state.isLoading = false;
      state.user = {};
    },
    [logout.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { setLogindata, updateLoginData, updateUserNotification, resetLogin } = loginSlice.actions;

export default loginSlice.reducer;
