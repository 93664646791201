import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Image } from 'antd';

import { getBannerList } from '../../../services/Service';
import useImageCarousel from '../../../hooks/useImageCarousel';

const SideBanner = ({ type, type2 }) => {
  const [bannerList, setBannerList] = useState([]);
  const [bannerList2, setBanner2List] = useState([]);
  const currentImg = useImageCarousel(bannerList, 5000);
  const currentImg2 = useImageCarousel(bannerList2, 5000);

  const fetchBanner = async () => {
    try {
      const res = await getBannerList(type);
      if (res.status === 200) {
        setBannerList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const fetchBanner2 = async () => {
    try {
      const res = await getBannerList(type2);
      if (res.status === 200) {
        setBanner2List(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  useEffect(() => {
    fetchBanner();
    fetchBanner2();
  }, [type, type2]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', flex: 'none' }}>
      <div style={{ flex: 'none', width: '140px', backgroundColor: 'white', borderRadius: '8px', maxHeight: '1000px' }}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <Image
            preview={false}
            style={{ objectFit: 'cover', borderRadius: '16px' }}
            height="100%"
            width="140px"
            src={currentImg?.value}
          />
        </div>
      </div>
      <div style={{ flex: 'none', width: '140px', backgroundColor: 'white', borderRadius: '8px', maxHeight: '1000px' }}>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <Image
            preview={false}
            style={{ objectFit: 'cover', borderRadius: '16px' }}
            height="100%"
            width="140px"
            src={currentImg2?.value}
          />
        </div>
      </div>
    </div>
  );
};
export default SideBanner;
