import { Box, Button, FormControl, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ErrorToast from '../../components/Toast/Error';
import SuccessToast from '../../components/Toast/Success';
import { SaveNoOfDownloads } from '../../services/Service';
import { setMemory } from '../../store/businessSlice';
import SwitchForForms from './SwitchForForms';
import CheckBoxForUnlimited from './CheckBoxForUnlimited';

const NumberOfSubscriptions = () => {
  const { Memory } = useSelector((state) => state.business);
  const dispatch = useDispatch();
  useEffect(() => {
    setInitialValues({
      freeUserDownload: Memory.freeUserDownload,
      paidUserDownload: Memory.paidUserDownload,
      premiumUserDownload: Memory.premiumUserDownload,
      betaUserDownload: Memory.betaUserDownload,
      freeUserUploadList: Memory.freeUserUploadList || 0,
      paidUserUploadList: Memory.paidUserUploadList || 0,
      premiumUserUploadList: Memory.premiumUserUploadList || 0 ,
      betaUserUploadList: Memory.betaUserUploadList || 0,
    });
  }, [Memory]);

  const [initialValues, setInitialValues] = useState({});

  const handleChange = (name, value) => {
    setInitialValues({
      ...initialValues,
      [name]: value,
    });
  };

  // const initialValues = {
  //   freeUserDownload: Memory.freeUserDownload,
  //   paidUserDownload: Memory.paidUserDownload,
  //   premiumUserDownload: Memory.premiumUserDownload,
  //   betaUserDownload: Memory.betaUserDownload,
  // };

  const DownloadsForm = useFormik({
    enableReinitialize: true,
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      console.log('this is DownloadsForm values => ', values);
      SaveNoOfDownloads(values)
        .then((res) => {
          SuccessToast('details updated !!');
          dispatch(setMemory(res.data.data));
        })
        .catch((e) => {
          ErrorToast('Something went wrong !!');
        });
    },
  });

  return (
    <Box sx={{ mt: 3, ml: 3 }}>
      <FormikProvider value={DownloadsForm} validateOnMount>
        <form onSubmit={DownloadsForm.handleSubmit}>
          <Grid sx={{ display: "flex", width: "100%", justifyContent: "space-around" }}>
            <Box>
              <Grid item xs={12} sm={6} md={10}>
                <Typography>Ability to Download Subscription List, Feedback Log, Activity Log : </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={12} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Free User</Typography>
                  <FormControl
                    // fullWidth

                    error={DownloadsForm.touched.freeUserDownload && Boolean(DownloadsForm.errors.freeUserDownload)}
                  >
                    <Field
                      as={TextField}
                      name="freeUserDownload"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={DownloadsForm.values.freeUserDownload}
                      onChange={DownloadsForm.handleChange}
                      error={DownloadsForm.touched.freeUserDownload && Boolean(DownloadsForm.errors.freeUserDownload)}
                      helperText={DownloadsForm.touched.freeUserDownload && DownloadsForm.errors.freeUserDownload}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="freeUserDownload"
                    checked={DownloadsForm.values.freeUserDownload}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="freeUserDownload"
                    checked={DownloadsForm.values.freeUserDownload}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={12} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Paid User</Typography>
                  <FormControl
                    // fullWidth

                    error={DownloadsForm.touched.paidUserDownload && Boolean(DownloadsForm.errors.paidUserDownload)}
                  >
                    <Field
                      as={TextField}
                      name="paidUserDownload"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={DownloadsForm.values.paidUserDownload}
                      onChange={DownloadsForm.handleChange}
                      error={DownloadsForm.touched.paidUserDownload && Boolean(DownloadsForm.errors.paidUserDownload)}
                      helperText={DownloadsForm.touched.paidUserDownload && DownloadsForm.errors.paidUserDownload}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="paidUserDownload"
                    checked={DownloadsForm.values.paidUserDownload}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="paidUserDownload"
                    checked={DownloadsForm.values.paidUserDownload}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={12} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Premium User</Typography>
                  <FormControl
                    // fullWidth

                    error={DownloadsForm.touched.premiumUserDownload && Boolean(DownloadsForm.errors.premiumUserDownload)}
                  >
                    <Field
                      as={TextField}
                      name="premiumUserDownload"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={DownloadsForm.values.premiumUserDownload}
                      onChange={DownloadsForm.handleChange}
                      error={DownloadsForm.touched.premiumUserDownload && Boolean(DownloadsForm.errors.premiumUserDownload)}
                      helperText={DownloadsForm.touched.premiumUserDownload && DownloadsForm.errors.premiumUserDownload}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="premiumUserDownload"
                    checked={DownloadsForm.values.premiumUserDownload}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="premiumUserDownload"
                    checked={DownloadsForm.values.premiumUserDownload}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={12} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Beta User</Typography>
                  <FormControl
                    // fullWidth

                    error={DownloadsForm.touched.betaUserDownload && Boolean(DownloadsForm.errors.betaUserDownload)}
                  >
                    <Field
                      as={TextField}
                      name="betaUserDownload"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={DownloadsForm.values.betaUserDownload}
                      onChange={DownloadsForm.handleChange}
                      error={DownloadsForm.touched.betaUserDownload && Boolean(DownloadsForm.errors.betaUserDownload)}
                      helperText={DownloadsForm.touched.betaUserDownload && DownloadsForm.errors.betaUserDownload}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="betaUserDownload"
                    checked={DownloadsForm.values.betaUserDownload}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="betaUserDownload"
                    checked={DownloadsForm.values.betaUserDownload}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Box>
            {/* second section */}
            <Box>
              <Grid item >
                <Typography>Ability to Upload Subscription List </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={12} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Free User </Typography>
                  <FormControl
                    // fullWidth

                    error={DownloadsForm.touched.freeUserUploadList && Boolean(DownloadsForm.errors.freeUserUploadList)}
                  >
                    <Field
                      as={TextField}
                      name="freeUserUploadList"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={DownloadsForm.values.freeUserUploadList}
                      onChange={DownloadsForm.handleChange}
                      error={DownloadsForm.touched.freeUserUploadList && Boolean(DownloadsForm.errors.freeUserUploadList)}
                      helperText={DownloadsForm.touched.freeUserUploadList && DownloadsForm.errors.freeUserUploadList}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="freeUserDownload"
                    checked={DownloadsForm.values.freeUserDownload}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="freeUserUploadList"
                    checked={DownloadsForm.values.freeUserUploadList}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={12} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Paid User</Typography>
                  <FormControl
                    // fullWidth

                    error={DownloadsForm.touched.paidUserUploadList && Boolean(DownloadsForm.errors.paidUserUploadList)}
                  >
                    <Field
                      as={TextField}
                      name="paidUserUploadList"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={DownloadsForm.values.paidUserUploadList}
                      onChange={DownloadsForm.handleChange}
                      error={DownloadsForm.touched.paidUserUploadList && Boolean(DownloadsForm.errors.paidUserUploadList)}
                      helperText={DownloadsForm.touched.paidUserUploadList && DownloadsForm.errors.paidUserUploadList}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="paidUserUploadList"
                    checked={DownloadsForm.values.paidUserUploadList}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="paidUserUploadList"
                    checked={DownloadsForm.values.paidUserUploadList}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={12} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Premium User</Typography>
                  <FormControl
                    // fullWidth

                    error={DownloadsForm.touched.premiumUserUploadList && Boolean(DownloadsForm.errors.premiumUserUploadList)}
                  >
                    <Field
                      as={TextField}
                      name="premiumUserUploadList"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={DownloadsForm.values.premiumUserUploadList}
                      onChange={DownloadsForm.handleChange}
                      error={DownloadsForm.touched.premiumUserUploadList && Boolean(DownloadsForm.errors.premiumUserUploadList)}
                      helperText={DownloadsForm.touched.premiumUserUploadList && DownloadsForm.errors.premiumUserUploadList}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="premiumUserUploadList"
                    checked={DownloadsForm.values.premiumUserUploadList}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="premiumUserUploadList"
                    checked={DownloadsForm.values.premiumUserUploadList}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={6} md={12} sx={{ mt: 2 }}>
                <Stack direction="row" spacing={2}>
                  <Typography>Beta User</Typography>
                  <FormControl
                    // fullWidth

                    error={DownloadsForm.touched.betaUserUploadList && Boolean(DownloadsForm.errors.betaUserUploadList)}
                  >
                    <Field
                      as={TextField}
                      name="betaUserUploadList"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      
                      value={DownloadsForm.values.betaUserUploadList}
                      onChange={DownloadsForm.handleChange}
                      error={DownloadsForm.touched.betaUserUploadList && Boolean(DownloadsForm.errors.betaUserUploadList)}
                      helperText={DownloadsForm.touched.betaUserUploadList && DownloadsForm.errors.betaUserUploadList}
                    />
                  </FormControl>
                  {/* <SwitchForForms
                    name="betaUserUploadList"
                    checked={DownloadsForm.values.betaUserUploadList}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="betaUserUploadList"
                    checked={DownloadsForm.values.betaUserUploadList}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              // type="submit"
              onClick={DownloadsForm.handleSubmit}
              // disabled={!(DownloadsForm.isValid && DownloadsForm.dirty)}
              sx={{
                width: '170px',
                height: '45px',
                bgcolor: '#19C25F',
                borderRadius: '30px',
                textTransform: 'none',
                mt: '25px',
                boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
              }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default NumberOfSubscriptions;
