import { Box, Button, Fab, Modal, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { useDispatch } from 'react-redux';
import { MarkAsReadNotification } from '../../services/Service';
import SuccessToast from '../Toast/Success';
import ErrorToast from '../Toast/Error';
import { getNotifications } from '../../store/loginSlice';

const DismissModal = ({ openDismiss, setOpenDismiss, ids }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',

    '@media (max-width: 640px)': {
      width: '100%',
    },

    // boxShadow: 24,
  };

  const handleMarkAllRead = () => {
    MarkAsReadNotification({ id: ids }).then((res) => {
      if (res.data.success === true) {
        SuccessToast('All Dismissed !!!');
        dispatch(getNotifications()).unwrap();
        setOpenDismiss(false);
      } else {
        ErrorToast(res.data.message);
        setOpenDismiss(false);
      }
    });
  };
  return (
    <>
      <Modal
        open={openDismiss}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500, height: 180 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab
              onClick={() => setOpenDismiss(false)}
              size="small"
              aria-label="add"
              sx={{ background: theme.palette.secondary.title }}
            >
              <CloseIcon />
            </Fab>
          </Box>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Are you sure you want to Dismiss All Message ?
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Button
                variant="contained"
                onClick={() => handleMarkAllRead()}
                sx={{ background: theme.palette.secondary.title }}
              >
                Yes
              </Button>
            </Box>

            <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
              <Button
                variant="contained"
                sx={{ background: theme.palette.secondary.title }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDismiss(false);
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DismissModal;
