import { Button, Menu, MenuItem } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { makeStyles } from '@mui/styles';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import styled from 'styled-components';
import ErrorToast from '../Toast/Error';

// Assuming that the data is stored in a variable called 'data'

import Iconify from '../Iconify';
import { downloadAttachmentFile } from '../../services/Service';

// import { saveAs } from "file-saver";
const Attachment = (props) => {
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(() => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: 1,
      minWidth: 180,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          marginRight: 1.5,
        },
      },
    },
  }));
  const classes = useStyles();

  // ***** Multiple Image Download (like single images) ****/
  const fileDownloadHandler = async () => {
    const urls = await Promise.allSettled(
      props?.data.map(async (d, i) =>
        // setTimeout(
        //   (file) => {
        //     console.log(file);
        //     window.location = file;
        //   },
        //   2000 + i * 200,
        //   d
        // )
        downloadAttachmentFile({ url: d.url })
          .then((res) => {
            console.log('this is attachment download => ', res.data);
            if (res.data.success === true) {
              // const url = window.URL.createObjectURL(new Blob(res.data.data));
              // const link = document.createElement('a');
              // link.href = res.data.data;
              // link.setAttribute('download', `file`);
              // document.body.appendChild(link);
              // link.click();
              // props?.handleCloseDownload()
              console.log('File Data', res.data);
              return res.data.data;
            }
          })
          .catch((err) => {
            props?.handleCloseDownload();
            console.log('this is attachment err => ', err);
            ErrorToast(err.response.data.message || 'Something went wrong !!');
          })
      )
    );
    console.log(urls);
    try {
      const zip = new JSZip();
      const remoteZips = urls.map(async (file) => {
        const response = await fetch(file.value);
        const data = await response.blob();
        zip.file(`${file.value.split('?')[0].split('/')[file.value.split('?')[0].split('/').length - 1]}`, data);
        return data;
      });
      console.log('Remote zip', remoteZips);
      await Promise.all(remoteZips)
        .then(() => {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            // give the zip file a name
            saveAs(content, 'attachment.zip');
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
    // urls.forEach((url, i) => {
    //   console.log('Hello');
    //   window.open(url.value, '_blank');
    // });

    // // urls.map((url, i) => {
    // //   const link = document.createElement('a');
    // //   link.href = url.value;
    // //   link.setAttribute('download', `file`);
    // //   link.setAttribute('id', `${i}`);
    // //   document.body.appendChild(link);
    // //   document.getElementById(`${i}`).click()
    // // })
    // props?.handleCloseDownload();
  };

  const handleS3Download = (url) => {
    downloadAttachmentFile({ url })
      .then((res) => {
        console.log('this is attachment download => ', res.data);
        if (res.data.success === true) {
          // const url = window.URL.createObjectURL(new Blob(res.data.data));
          const link = document.createElement('a');
          link.href = res.data.data;
          link.setAttribute('download', `file`);
          document.body.appendChild(link);
          link.click();
          props?.handleCloseDownload();
        }
      })
      .catch((err) => {
        props?.handleCloseDownload();
        console.log('this is attachment err => ', err);
        ErrorToast(err.response.data.message || 'Something went wrong !!');
        // ErrorToast(err.response.data.message);
      });
  };
  console.log('Url data', props?.data);
  return (
    <>
      <Iconify
        icon="entypo:attachment"
        onClick={props?.handleClickDownload}
        color={props.color || '#3D71FF'}
        cursor="pointer"
        style={props.style}
        width={props.width || 20}
        height={props.height || 20}
        mr={1}
      />

      {/* <ClickAwayListener onClickAway={() => props?.handleCloseDownload()}> */}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={props?.openDownload}
        open={Boolean(props?.openDownload)}
        onClose={(e) => {
          props?.setOpenDownload(e.parentNode);
        }}
      >
        {props?.data?.length !== 0 ? (
          <div className={classes.download_all_flex_div}>
            <Button className={classes.download_all_btn} onClick={fileDownloadHandler}>
              Download All
            </Button>
          </div>
        ) : (
          ''
        )}
        {Array.isArray(props?.data) &&
          props?.data?.map((item, idx) =>
            item?.url ? (
              <MenuItem key={idx} value={item} onClick={() => handleS3Download(item.url)}>
                {item?.url?.includes('safalsubscriptions-assets.s3.amazonaws.com')
                  ? item?.url?.match(/[^/-]+$/)[0]
                  : item?.url?.split('-').slice(4).join('-')}
              </MenuItem>
            ) : (
              <a href={item} key={item} style={{ textDecoration: 'none' }}>
                <MenuItem key={idx} value={item}>
                  {item && item.toString().split('/').pop()}
                </MenuItem>
              </a>
            )
          )}
      </StyledMenu>
    </>
  );
};
export default Attachment;
const useStyles = makeStyles({
  download_all_btn: {
    textTransform: 'capitalize',
    textAlign: 'right',
    fontSize: 16,
  },
  download_all_flex_div: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});
