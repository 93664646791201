import { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Fab,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MDEditor from '@uiw/react-md-editor';

import faq_blue from '../../assets/faq_blue.png';
import faq_white from '../../assets/faq_white.png';
import { getFaqList } from '../../services/Service';

const FaqModal = ({ height, width, color, margin, marginLeft, marginRight, module }) => {
  const [open, setOpen] = useState(false);
  const [rawData, setRawData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getFaqList({ module });
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('getFaqList fetchData error', err.message);
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #FFF',
    borderRadius: '10px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    p: 4,
    maxHeight: '95vh',
    overflow: 'auto',
    '@media (max-width: 640px)': {
      width: '95vw',
    },
  };

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open === true) {
      fetchData();
    }
  }, [open]);

  const colorDefault = color !== undefined ? color : 'white';

  return (
    <>
      <Tooltip title="Click to View FAQ">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'transparent',
            height: height || '28px',
            width: width || '28px',
            '&:hover': { backgroundColor: 'transparent' },
            margin: margin,
            marginLeft: marginLeft,
            marginRight: marginRight,
          }}
        >
          <img
            alt="faq"
            src={colorDefault === 'white' ? faq_white : faq_blue}
            style={{ flex: 'none' }}
            height={'100%'}
            width={'100%'}
          />
        </IconButton>
      </Tooltip>
      <Modal onClose={handleClose} open={open}>
        <Box sx={{ ...style }}>
          <Fab
            sx={{ position: 'absolute', top: '16px', right: '16px' }}
            onClick={handleClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '16px', fontSize: '28px', fontWeight: 700, color: '#3d71ff' }}
          >
            Frequently asked questions
          </Typography>
          <div data-color-mode="light">
            {rawData?.map((e, index) => (
              <Accordion key={index} defaultExpanded={index === 0}>
                <AccordionSummary
                  sx={{ fontWeight: 'bold' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={e._id}
                  id={e._id}
                >
                  {e.question}
                </AccordionSummary>
                <AccordionDetails>
                  {e.answerMD?.map((exx, ix) => (
                    <MDEditor.Markdown
                      data-color-mode="light"
                      key={ix}
                      source={exx}
                      // style={{ whiteSpace: 'pre-wrap' }}
                    />
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default FaqModal;
