import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Chats from './Chats';
import Navbar from './Navbar';
import Search from './Search';

const Sidebar = ({ open, handleClose, buddyUser }) => {
  const { chatMode } = useSelector((state) => state.chats);
  const { chatResponsiveToggle } = useSelector((state) => state.toggleState);
  const [username, setUsername] = useState('');

  return (
    <div className={chatResponsiveToggle ? 'sidebar' : 'sidebar chatResponsiveToggle'}>
      <Navbar handleClose={handleClose} />
      {chatMode !== 'support' && <Search open={open} username={username} setUsername={setUsername} />}
      <Chats username={username} setUsername={setUsername} buddyUser={buddyUser} />
    </div>
  );
};

export default Sidebar;
