import { useState } from 'react';
import { Helmet } from 'react-helmet-async';

import './BusinessDashboard.css';

import SalesTab from './SalesTab';
import FinancialTab from './FinancialTab';

const BusinessDashboard = () => {
  const [selectedTab, setSelectedTab] = useState('Financial'); // Financial, Sales, Technical

  const onChangeTab = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard - SafalSubscriptions</title>
      </Helmet>
      <PageAction selected={selectedTab} />
      <div
        style={{
          backgroundColor: '#e3f1e3',
          marginTop: '16px',
          padding: '16px',
          display: 'flex',
          gap: '16px',
          borderRadius: '16px',
          flexDirection: 'column',
        }}
      >
        <MainAction selected={selectedTab} onChange={onChangeTab} />
        {selectedTab === 'Financial' && <FinancialTab />}
        {selectedTab === 'Sales' && <SalesTab />}
        {selectedTab === 'Technical' && <SalesTab />}
      </div>
    </>
  );
};
export default BusinessDashboard;

const PageAction = ({ selected }) => {
  return (
    <div style={{ display: 'flex', gap: '4px', marginTop: '8px' }}>
      <button
        style={{
          color: '#468B47',
          border: 'none',
          outline: 'none',
          backgroundColor: 'transparent',
          fontSize: '24px',
          fontWeight: 'bold',
          padding: '2px 4px',
          lineHeight: 'normal',
        }}
      >
        Home Page
      </button>
      <div style={{ width: '4px', backgroundColor: '#BCF3BC', margin: '2px 0px' }} />
      <button
        style={{
          color: '#83B984',
          border: 'none',
          outline: 'none',
          backgroundColor: 'transparent',
          fontSize: '24px',
          fontWeight: 'bold',
          padding: '2px 4px',
          lineHeight: 'normal',
        }}
      >
        {selected}
      </button>
    </div>
  );
};
const MainAction = ({ onChange, refresh, selected }) => {
  return (
    <div
      className="BusinessDashboard-MainAction"
      style={{ padding: '10px', display: 'flex', gap: '16px', alignItems: 'center' }}
    >
      <button
        onClick={() => onChange('Financial')}
        className="BusinessDashboard-MainAction-btn"
        style={{
          color: selected !== 'Financial' ? '#468b47' : 'white',
          outline: 'none',
          backgroundColor: selected === 'Financial' ? '#468b47' : 'white',
          border: '1px solid #468b47',
          fontSize: '15px',
          fontWeight: 'bold',
          padding: '8px 28px',
          lineHeight: 'normal',
          cursor: 'pointer',
          borderRadius: '10px',
        }}
      >
        Financial Dashboard
      </button>
      <button
        onClick={() => onChange('Sales')}
        className="BusinessDashboard-MainAction-btn"
        style={{
          color: selected !== 'Sales' ? '#468b47' : 'white',
          outline: 'none',
          backgroundColor: selected === 'Sales' ? '#468b47' : 'white',
          border: '1px solid #468b47',
          fontSize: '15px',
          fontWeight: 'bold',
          padding: '8px 28px',
          lineHeight: 'normal',
          cursor: 'pointer',
          borderRadius: '10px',
        }}
      >
        Sales Dashboard
      </button>
      <button
        onClick={() => onChange('Technical')}
        className="BusinessDashboard-MainAction-btn"
        style={{
          color: selected !== 'Technical' ? '#468b47' : 'white',
          outline: 'none',
          backgroundColor: selected === 'Technical' ? '#468b47' : 'white',
          border: '1px solid #468b47',
          fontSize: '15px',
          fontWeight: 'bold',
          padding: '8px 28px',
          lineHeight: 'normal',
          cursor: 'pointer',
          borderRadius: '10px',
        }}
      >
        Technical Dashboard
      </button>
      {/* <button
        onClick={refresh}
        title="Refresh"
        style={{
          color: '#468b47',
          outline: 'none',
          backgroundColor: 'white',
          border: '1px solid #468b47',
          cursor: 'pointer',
          borderRadius: '99999px',
          height: '34px',
          width: '34px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 'none',
        }}
      >
        <img
          src="/assets/images/business_refresh.png"
          style={{ width: '20px', height: '20px', objectFit: 'contain' }}
          alt="business_refresh"
        />
      </button> */}
    </div>
  );
};
