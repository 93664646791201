import { Button, Fab, Tooltip } from '@mui/material';

const CustomButton = ({ icon, onClick, text, title, backgroundColor }) => {
  const _backgroundColor = backgroundColor === undefined ? '#3D71FF' : backgroundColor;
  if (title !== undefined) {
    return (
      <Tooltip title={title}>
        <Button
          onClick={onClick}
          variant="text"
          startIcon={
            <Fab
              size="small"
              sx={{
                color: '#FFFFFF',
                backgroundColor: _backgroundColor,
                ml: '-5px',
                '&:hover': { backgroundColor: _backgroundColor },
              }}
            >
              {icon}
            </Fab>
          }
          sx={{
            backgroundColor: '#FFFFFF',
            color: '#1D1D1F',
            borderRadius: '30px',
            height: '40px',
            textTransform: 'none',
            zIndex: 5,
            '@media (max-width: 600px)': {
              mt: 2,
            },
          }}
        >
          {text}
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      onClick={onClick}
      variant="text"
      startIcon={
        <Fab
          size="small"
          sx={{
            color: '#FFFFFF',
            backgroundColor: _backgroundColor,
            ml: '-5px',
            '&:hover': { backgroundColor: _backgroundColor },
          }}
        >
          {icon}
        </Fab>
      }
      sx={{
        backgroundColor: '#FFFFFF',
        color: '#1D1D1F',
        borderRadius: '30px',
        height: '40px',
        textTransform: 'none',
        zIndex: 5,
        '@media (max-width: 600px)': {
          mt: 2,
        },
      }}
    >
      {text}
    </Button>
  );
};
export default CustomButton;
