import React, { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';

const UserStatus = ({ uid }) => {
  const [status, setStatus] = useState('offline');

  useEffect(() => {
    if (!uid) return;
    const userRef = doc(db, 'users', uid);
    const unsubscribe = onSnapshot(userRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        if (data.isOnline) {
          setStatus('online');
        } else {
          setStatus('offline');
        }
      }
    });
    return () => unsubscribe();
  }, [uid]);

  return <div className={`${status}Dot`} />;
};

export default UserStatus;
