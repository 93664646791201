import * as React from 'react';
import { useState, useEffect } from 'react';

import { Fab, IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import toast from 'react-hot-toast';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

import UserAssessment1 from '../../../assets/UserAssessment/UserAssessment1.png';
import UserAssessment2 from '../../../assets/UserAssessment/UserAssessment2.png';
import UserAssessment3 from '../../../assets/UserAssessment/UserAssessment3.png';
import UserAssessment4 from '../../../assets/UserAssessment/UserAssessment4.png';
import UserAssessment5 from '../../../assets/UserAssessment/UserAssessment5.png';
import { getSignedURL, getUserAssessmentUserLog, getUserAssessmentUserLogDownload } from '../../../services/Service';
import DownloadMenuLog from './DownloadMenuLog';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const UserAssessmentIcons = [UserAssessment1, UserAssessment2, UserAssessment3, UserAssessment4, UserAssessment5];

export default function UserAssessmentModalLog({ open, onClose, data }) {
  const [rawData, setRawData] = useState([]);
  const { formatDateTime } = useUserTimeZone();

  const headDataText = ['Full Name', 'UserID', 'Response', 'Created At'];
  const headDataYesNo = ['Full Name', 'UserID', 'Yes/No', 'Yes Text', 'No Text', 'Created At'];
  const headDataOptions = ['Full Name', 'UserID', 'Response', 'Created At'];

  const headData =
    data?.questionType === 'yesNo' ? headDataYesNo : data?.questionType === 'options' ? headDataOptions : headDataText;

  const handleClose = () => {
    setRawData([]);
    onClose();
  };

  const fetchData = async (data) => {
    try {
      const res = await getUserAssessmentUserLog({ masterAssessmentId: data?._id, page: 1, limit: 10000 });
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (open === true && data !== null) {
      fetchData(data);
    }
  }, [open, data]);

  const downloadFile = async (type) => {
    try {
      const res = await getUserAssessmentUserLogDownload({
        masterAssessmentId: data?._id,
        fileType: type,
        questionType: data?.questionType,
      });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  const tableCellPStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    maxHeight: '200px',
  };

  const notInterestedColor = '#127ee2';

  return (
    <Modal open={open}>
      <Box sx={style}>
        <div>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
          >
            User Assessment Response
          </Typography>
          <span>
            <b>Question - </b>
            {data?.question}
          </span>
        </div>
        <DownloadMenuLog downloadFile={downloadFile} />
        <Fab
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
            backgroundColor: '#19c25f',
            '&:hover': { backgroundColor: '#19c25f' },
          }}
          onClick={handleClose}
          size="small"
          color="primary"
          aria-label="add"
        >
          <CloseIcon />
        </Fab>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <TableContainer>
            <CustomTableView headData={headData}>
              {rawData.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
              {rawData?.map((e, index) => (
                <TableRow key={index}>
                  <CustomTableCellForBody align="center">
                    <p
                      style={{ ...tableCellPStyle, color: e?.notInterested && notInterestedColor }}
                    >{`${e?.user?.firstName} ${e?.user?.lastName}`}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={{ ...tableCellPStyle, color: e?.notInterested && notInterestedColor }}>
                      {e?.user?.userID}
                    </p>
                  </CustomTableCellForBody>
                  {data?.questionType === 'text' && (
                    <CustomTableCellForBody align="center">
                      <p
                        style={{ ...tableCellPStyle, maxWidth: '300px', color: e?.notInterested && notInterestedColor }}
                      >
                        {e?.textAnswer?.toString()}
                      </p>
                    </CustomTableCellForBody>
                  )}
                  {data?.questionType === 'yesNo' && (
                    <CustomTableCellForBody align="center">
                      <p style={{ ...tableCellPStyle, color: e?.notInterested && notInterestedColor }}>
                        {e?.yesNoAnswer === true ? 'Yes' : 'No'}
                      </p>
                    </CustomTableCellForBody>
                  )}
                  {data?.questionType === 'yesNo' && (
                    <CustomTableCellForBody align="center">
                      <p
                        style={{ ...tableCellPStyle, maxWidth: '300px', color: e?.notInterested && notInterestedColor }}
                      >
                        {e?.yesAnswerText?.toString()}
                      </p>
                    </CustomTableCellForBody>
                  )}
                  {data?.questionType === 'yesNo' && (
                    <CustomTableCellForBody align="center">
                      <p
                        style={{ ...tableCellPStyle, maxWidth: '300px', color: e?.notInterested && notInterestedColor }}
                      >
                        {e?.noAnswerText?.toString()}
                      </p>
                    </CustomTableCellForBody>
                  )}
                  {data?.questionType === 'options' && (
                    <CustomTableCellForBody align="center">
                      <p
                        style={{ ...tableCellPStyle, maxWidth: '400px', color: e?.notInterested && notInterestedColor }}
                      >
                        {data?.options?.map((ex, index) => (
                          <>
                            <span key={index}>{`${ex}${
                              e?.optionsAnswer?.includes(index?.toString()) ? ' - Yes' : ''
                            }`}</span>
                            <br />
                          </>
                        ))}
                      </p>
                    </CustomTableCellForBody>
                  )}
                  <CustomTableCellForBody align="center">
                    <p style={{ ...tableCellPStyle, color: e?.notInterested && notInterestedColor }}>
                      {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
                    </p>
                  </CustomTableCellForBody>
                </TableRow>
              ))}
            </CustomTableView>
          </TableContainer>
        </div>
      </Box>
    </Modal>
  );
}
