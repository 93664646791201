/* eslint-disable no-else-return */
import { Box, Button, Fab, MenuItem, Modal, TableContainer, TableRow, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';

import './PdfStatementImport.css';
import { CustomTableCellForBody, CustomTableCellForNoData, CustomTableView } from '../UI/CustomTableView';
import {
  getCardScheme,
  getPaymentInstrument,
  getPaymentMode,
  getPdfScanType,
  GetsubsResponse,
  uploadPdfStatement,
} from '../../services/Service';
import EditSubscriptionModal from './EditSubscriptionModal';
import { setSubscriptions } from '../../store/subscriptionSlice';
import ThreeDotMenu from './ThreeDotMenu';
import MultiSubscriptionsOptions from './MultiSubscriptionsOptions';
import EditCustomSubscriptionModal from './EditCustomSubscriptionModal';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '95vh',
  minHeight: '200px',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  p: 6,
  pt: 3,
  '@media (max-width: 640px)': {
    width: '95vw',
    height: '95vh',
    overflow: 'auto',
  },
};

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

function formatDate(inputDate) {
  const currentYear = new Date().getFullYear();

  // Concatenate the current year to the inputDate
  const fullDate = `${inputDate}/${currentYear}`;

  // Parse the full date using moment
  const parsedDate = moment(fullDate, 'MM/DD/YYYY');

  // Format dateXX as MM/DD/YYYY
  const contractStartDate = parsedDate.format('MM/DD/YYYY');

  // Add 1 month to the parsed date to get the next date
  const nextBillingDate = parsedDate.add(1, 'months').format('MM/DD/YYYY');

  return { contractStartDate, nextBillingDate };
}

const theSortFunction = (data, orderBy, order) => {
  // Define a function to compare values based on the specified orderBy parameter
  const compareValues = (a, b) => {
    if (orderBy === 'contractStartDate') {
      // If orderBy is 'Date', parse dates using Moment.js
      const dateA = moment(a[orderBy]);
      const dateB = moment(b[orderBy]);

      if (order === 'desc') {
        return dateB - dateA; // Descending order
      } else {
        return dateA - dateB; // Ascending order (default)
      }
    } else {
      // For string and number comparison
      const valueA = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
      const valueB = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];

      if (order === 'desc') {
        if (valueB < valueA) {
          return -1;
        }
        if (valueB > valueA) {
          return 1;
        }
        return 0;
      } else {
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      }
    }
  };

  // Sort the data array based on the compareValues function
  data.sort((a, b) => {
    return compareValues(a, b);
  });

  return data;
};

const PdfStatementImport = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const isModalOpen = open;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileState, setFileState] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [uploadData, setUploadData] = useState([]);
  const [uploadDataSort, setUploadDataSort] = useState([]);
  const [unmatchCount, setUnmatchCount] = useState(0);
  const [sortState, setSortState] = useState({
    orderBy: '', // asc - desc - ""
    order: '',
  });

  const [paymentModeList, setPaymentModeList] = useState([]);
  const [paymentInstrumentList, setPaymentInstrumentList] = useState([]);
  const [paymentCardSchemeList, setPaymentCardSchemeList] = useState([]);

  const [countObj, setCountObj] = useState({
    accept: 0,
    reject: 0,
    matching: 0,
  });

  const [openReview, setOpenReview] = useState({
    open: false,
    data: null,
  });
  const [openReviewCustom, setOpenReviewCustom] = useState({
    open: false,
    data: null,
  });

  const [openReviewMulti, setOpenReviewMulti] = useState({
    open: false,
    data: null,
  });

  const [cardTypeList, setCardTypeList] = useState([]);
  const fetchData = async () => {
    try {
      const res = await getPdfScanType({ status: true });
      if (res.status === 200) {
        setCardTypeList(res.data.data);
      }
    } catch (err) {
      console.log('CardSchemeBusiness fetchData error', err.message);
    }
  };

  const fetchPaymentModeInfo = async () => {
    try {
      const res1 = await getPaymentMode({ status: true });
      setPaymentModeList(res1.data.data);
      const res2 = await getPaymentInstrument({ status: true });
      setPaymentInstrumentList(res2.data.data);
      const res3 = await getCardScheme({ status: true });
      setPaymentCardSchemeList(res3.data.data);
      return res2.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (open) fetchData();
  }, [open]);

  const onModalClose = async () => {
    onClose();
    setUnmatchCount(0);
    setUploadData([]);
    setUploadDataSort([]);
    setSelectedBank(null);
    setFileState(null);
    setIsSubmitting(false);
    setCountObj({
      accept: 0,
      reject: 0,
      matching: 0,
    });
    setSortState({
      orderBy: '',
      order: '',
    });
    const resX = await GetsubsResponse();
    if (resX.data.success === true) {
      dispatch(setSubscriptions({ subscriptions: resX.data.data, totalItems: resX.data.pagination[0].totalItems }));
    }
  };

  const uploadPdfFile = async () => {
    setIsSubmitting(true);
    try {
      fetchPaymentModeInfo();
      const res = await uploadPdfStatement(fileState, selectedBank);
      const dataFilter = res.data.data.map((ex, index) => {
        const contractStartDate = formatDate(ex.date).contractStartDate;
        const nextBillingDate = formatDate(ex.date).nextBillingDate;
        return {
          logo: ex.company.logo,
          website: ex.company.website,
          subscriptionType: ex.company.companyType,
          company: ex.company.name,
          description: ex.company.description,
          frequency: 'Monthly',
          contractStartDate: contractStartDate,
          nextBillingDate: nextBillingDate,
          currencyAbr: 'USD',
          autoRenewal: true,
          status: 'Active',
          comments: ex.description,
          amount: ex.amount,
          companyId: ex.company._id,
          'event[call]': 'AddSub',
          flag: 'Matching',
          raw: ex,
        };
      });
      const objPush = [];
      if (Array.isArray(res.data?.unmatchData)) {
        res.data?.unmatchData.forEach((unData) => {
          const contractStartDate = formatDate(unData.date).contractStartDate;
          const nextBillingDate = formatDate(unData.date).nextBillingDate;
          const obj = {
            logo: '',
            website: '',
            subscriptionType: '',
            company: '',
            description: unData.description,
            frequency: '',
            contractStartDate: contractStartDate,
            nextBillingDate: nextBillingDate,
            currencyAbr: 'USD',
            autoRenewal: true,
            status: '',
            comments: '',
            amount: unData.amount,
            companyId: '',
            flag: 'Unmatched',
          };
          objPush.push(obj);
        });
      }
      setCountObj((prev) => ({ ...prev, matching: dataFilter.filter((e) => e.flag === 'Matching').length }));
      dataFilter.push(...objPush);
      setUnmatchCount(res.data.unmatchCount);
      setUploadData(dataFilter);
      setUploadDataSort(dataFilter);
    } catch (err) {
      console.error('uploadPdfFile', err.message);
      toast.error(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onReview = (e, index) => {
    const initialValues = {
      company: {
        logo: e.logo,
      },
      companyType: e?.subscriptionType,
      website: e?.website,
      frequency: e?.frequency,
      startDate: e?.contractStartDate,
      nextBilling: e?.nextBillingDate,
      amount: e?.amount,
      autoRenewal: e?.autoRenewal ? 'true' : 'false',
      status: `${e?.status}`,
      comments: e?.comments,
      description: e?.description,
      rating: '0',
      isLiked: false,
      raw: e,
    };

    setOpenReview({
      open: true,
      data: initialValues,
      index: index,
    });
  };
  const onReviewCustom = (e, index) => {
    const initialValues = {
      company: {
        logo: e.logo,
      },
      companyType: e?.subscriptionType,
      website: e?.website,
      frequency: e?.frequency,
      startDate: e?.contractStartDate,
      nextBilling: e?.nextBillingDate,
      amount: e?.amount,
      autoRenewal: e?.autoRenewal ? 'true' : 'false',
      status: `${e?.status}`,
      comments: e?.comments,
      description: e?.description,
      rating: '0',
      isLiked: false,
      raw: e,
    };

    setOpenReviewCustom({
      open: true,
      data: initialValues,
      index: index,
    });
  };
  const onEdit = (e, index) => {
    if (e.raw.subscriptions.length === 1) {
      const subData = e.raw.subscriptions[0];
      const initialValues = {
        company: {
          logo: e.logo,
        },
        _id: subData._id,
        companyType: e?.subscriptionType,
        website: e?.website,
        frequency: subData?.frequency,
        startDate: subData?.nextBilling,
        nextBilling: subData?.nextBilling,
        amount: subData?.amount,
        autoRenewal: subData?.autoRenewal ? 'true' : 'false',
        status: `${subData?.status}`,
        comments: subData?.comments,
        description: subData?.description,
        rating: subData?.rating,
        isLiked: subData?.isLiked,
        attachments: subData?.attachments,
        label: subData?.label || '',
        paymentInstrument: subData?.paymentInstrument || '',
        raw: e,
      };

      setOpenReview({
        open: true,
        data: initialValues,
        index: index,
      });
    } else {
      setOpenReviewMulti({
        open: true,
        data: {
          data: e,
          index: index,
        },
      });
    }
  };

  const onCloseReviewCustom = (isSuccess) => {
    if (isSuccess) {
      // Remove on Accept
      setUploadData((prev) => {
        const newArrObj = prev.filter((e, i) => i !== openReviewCustom.index);
        return newArrObj;
      });
      // Remove on Accept
      setUploadDataSort((prev) => {
        const newArrObj = prev.filter((e, i) => i !== openReviewCustom.index);
        return newArrObj;
      });

      setCountObj((prev) => ({ ...prev, accept: prev.accept + 1 }));
    }
    setOpenReviewCustom({
      open: false,
      data: null,
    });
  };
  const onCloseReview = (isSuccess) => {
    if (isSuccess) {
      // Remove on Accept
      setUploadData((prev) => {
        const newArrObj = prev.filter((e, i) => i !== openReview.index);
        return newArrObj;
      });
      // Remove on Accept
      setUploadDataSort((prev) => {
        const newArrObj = prev.filter((e, i) => i !== openReview.index);
        return newArrObj;
      });

      setCountObj((prev) => ({ ...prev, accept: prev.accept + 1 }));
    }
    setOpenReview({
      open: false,
      data: null,
    });
  };

  const onReject = (index) => {
    setUploadData((prev) => {
      const newArrObj = prev.filter((e, i) => i !== index);
      return newArrObj;
    });
    setUploadDataSort((prev) => {
      const newArrObj = prev.filter((e, i) => i !== index);
      return newArrObj;
    });
    setCountObj((prev) => ({ ...prev, reject: prev.reject + 1 }));
  };

  const acceptCount = countObj.accept;
  const rejectCount = countObj.reject;
  const pendingCount = uploadData.length;

  // 1st click asc
  // 2nd click desc
  // 3rd click reset
  const createSortHandler = (newOrderBy) => {
    if (newOrderBy === sortState.orderBy) {
      switch (sortState.order) {
        case 'desc':
          setSortState({
            orderBy: '',
            order: '',
          });
          setUploadDataSort([...uploadData]);
          break;
        case 'asc':
          setSortState({
            orderBy: newOrderBy,
            order: 'desc',
          });
          setUploadDataSort(theSortFunction([...uploadData], newOrderBy, 'desc'));
          break;
        default:
          setSortState({
            orderBy: newOrderBy,
            order: 'asc',
          });
          setUploadDataSort(theSortFunction([...uploadData], newOrderBy, 'asc'));
          break;
      }
    } else {
      setSortState({
        orderBy: newOrderBy,
        order: 'asc',
      });
      setUploadDataSort(theSortFunction(uploadData, newOrderBy, 'asc'));
    }
  };

  const onReviewMultiClose = () => {
    setOpenReviewMulti({
      open: false,
      data: null,
    });
  };

  return (
    <>
      <Modal
        onClose={onModalClose}
        open={isModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={onModalClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <div id="PdfStatementImport">
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '30px',
                fontWeight: 700,
                color: '#3D71FF',
                marginBottom: '20px',
              }}
            >
              Safal Intelliscan
            </Typography>
            <div className="header-psi">
              <TextField
                select
                label="Select your bank"
                value={selectedBank}
                onChange={(e) => setSelectedBank(e.target.value)}
                disabled={isSubmitting}
                size="small"
                fullWidth
              >
                {cardTypeList.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                style={{ marginLeft: '12px' }}
                onChange={(e) => {
                  setFileState(e.target.files[0]);
                }}
                size="small"
                type="file"
                required
                fullWidth
                disabled={isSubmitting}
              />
              <div className="item-psi" style={{ marginLeft: '12px' }}>
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  disabled={isSubmitting}
                  onClick={uploadPdfFile}
                  variant="contained"
                  type="submit"
                >
                  Analyse
                </Button>
              </div>
            </div>
            {isSubmitting && (
              <div className="animate-bar">
                <div className="animate-bar-line" />
              </div>
            )}
            <div className="header-psi" style={{ marginTop: '20px', gap: '12px' }}>
              <PdfTagUI
                label="Total"
                value={`${countObj.matching + unmatchCount} Nos`}
                backgroundColor="#c7dcf0"
                color="black"
              />
              <PdfTagUI label="Matching" value={`${countObj.matching} Nos`} backgroundColor="#25d3eb" color="black" />
              <PdfTagUI label="Unmatched" value={`${unmatchCount} Nos`} backgroundColor="#c7dcf0" color="black" />
              <div style={{ margin: 'auto' }} />
              <PdfTagUI label="Accepted" value={`${acceptCount} Nos`} backgroundColor="#8fc49e" />
              <PdfTagUI label="Rejected" value={`${rejectCount} Nos`} backgroundColor="#bb7c7c" />
              <PdfTagUI label="Pending" value={`${pendingCount} Nos`} backgroundColor="#bcc391" />
            </div>
            <div>
              <TableContainer>
                <CustomTableView
                  isSort
                  sort={{
                    order: sortState.order,
                    orderBy: sortState.orderBy,
                    createSortHandler: createSortHandler,
                    sortHeadMap: [
                      '',
                      'contractStartDate',
                      'company',
                      'subscriptionType',
                      'description',
                      'amount',
                      'flag',
                      'Actions',
                    ],
                  }}
                  headData={['Sl No', 'Date', 'Company Name', 'Type', 'Description', 'Amount', 'Status', '']}
                >
                  {uploadData.length === 0 && <CustomTableCellForNoData message="We didn't find any match." />}
                  {uploadDataSort?.map((e, index) => (
                    <TableRow key={index}>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {index + 1}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.contractStartDate}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.company}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.subscriptionType}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.description}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.amount?.toString()?.includes('$') ? e.amount : `$ ${e.amount}`}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p
                          style={{ ...tableCellPStyle, color: e?.raw?.subscriptions?.length > 0 ? '#229A16' : 'black' }}
                        >
                          {e.flag}
                        </p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <ThreeDotMenu
                          onAccept={() => (e.flag === 'Unmatched' ? onReviewCustom(e, index) : onReview(e, index))}
                          onReject={() => onReject(index)}
                          onEdit={e?.raw?.subscriptions?.length > 0 && (() => onEdit(e, index))}
                        />
                      </CustomTableCellForBody>
                    </TableRow>
                  ))}
                </CustomTableView>
              </TableContainer>
            </div>
          </div>
        </Box>
      </Modal>
      <MultiSubscriptionsOptions
        open={openReviewMulti.open}
        onClick={onEdit}
        data={openReviewMulti.data}
        onClose={onReviewMultiClose}
      />
      <EditSubscriptionModal
        fetchPaymentModeInfo={fetchPaymentModeInfo}
        paymentCardSchemeList={paymentCardSchemeList}
        paymentInstrumentList={paymentInstrumentList}
        paymentModeList={paymentModeList}
        data={openReview.data}
        open={openReview.open}
        onClose={onCloseReview}
      />
      <EditCustomSubscriptionModal
        data={openReviewCustom.data}
        open={openReviewCustom.open}
        onClose={onCloseReviewCustom}
      />
    </>
  );
};
export default PdfStatementImport;

const PdfTagUI = ({ label, value, backgroundColor, color }) => {
  return (
    <div className="pdf-tag" style={{ backgroundColor, color }}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};
