import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Badge, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { Image } from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Carousel } from 'react-responsive-carousel';
import ShareIcon from '@mui/icons-material/Share';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import AddIcon from '@mui/icons-material/Add';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

import {
  createPrivateRoomInviteCode,
  deleteSafalRoomPrivate,
  getBannerList,
  GetcompaniesResponse,
  getPrivateSafalRoomAllReqList,
  getPrivateSafalRoomList,
  getPrivateSafalRoomReqList,
  getSafalRoomPrivate,
  updateSafalRoomDefaultCompany,
} from '../../../services/Service';
import FaqModal from '../../../components/Modals/FaqModal';
import Inlineloader from '../../../components/Loader/InlineLoader';
import buddyProfileIcon from '../../../assets/buddyProfile.png';
import SubscribersCountChart from './SubscribersCountChart';
import SafalRoomFeed from './SafalRoomFeed';
import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';
import SafalRoomPolls from './SafalRoomPolls';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';
import SafalRoomCommunityForum from './SafalRoomCommunityForum';
import SafalRoomAbout from './SafalRoomAbout';
import CompanySelectModal from './CompanySelectModal';
import { SelectBarPrivate } from '../SafalRoom/SafalRoom';
import RoomModal from './RoomModal';
import InvitationModal from './InvitationModal';
import { GlobalShareModal } from '../../../components/Modals/GlobalShareModal';
import SideBanner from '../SafalRoom/SideBanner';
import AnalyticsClickImpression from '../../../components/AnalyticsClickImpression';

// "meta": {
//     "publicFeelings": 1.24,
//     "total": 17,
//     "totalAmount": 4742,
//     "activeCount": 17,
//     "cancelCount": 0
// }

const SafalRoomPrivate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramCompany = searchParams.get('company');
  const [bannerList, setBannerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [comLoading, setComLoading] = useState(false);
  const [safalRoomData, setSafalRoomData] = useState(null);
  const [safalRoomYearlyHistory, setSafalRoomYearlyHistory] = useState(null);
  const [safalRoomMonthlyHistory, setSafalRoomMonthlyHistory] = useState(null);
  const [safalRoomMeta, setSafalRoomMeta] = useState(null);
  const [selectedMainSection, setSelectedMainSection] = useState('feed'); // feed, poll, forum
  const [companyList, setCompanyList] = useState([]);
  const { user } = useSelector((state) => state.login);
  const safalRoomDefaultCompany = user?.safalRoomDefaultCompany;
  const [companyId, setCompanyId] = useState(paramCompany || safalRoomDefaultCompany);
  const [companyRoomModal, setCompanyRoomModal] = useState({
    open: false,
  });
  const [roomModal, setRoomModal] = useState({
    open: false,
    data: null,
  });
  const [invitationModal, setInvitationModal] = useState({
    open: false,
    data: null,
  });
  const [roomInfo, setRoomInfo] = useState(null);
  const [roomInfo2, setRoomInfo2] = useState(null);
  const [roomList, setRoomList] = useState([]);
  const [roomReqList, setRoomReqList] = useState([]);
  const [allRoomReqList, setAllRoomReqList] = useState({
    polls: [],
    forum: [],
  });
  // const [defaultRoomCompany, setDefaultRoomCompany] = useState(''); // null, "65e2d351f65e3930e7009c59", ""
  const [shareModal, setShareModal] = useState({ open: false, link: '' });
  const navigate = useNavigate();

  const fetchExtraData = async () => {
    try {
      const res = await getBannerList('safalRoomPrivate');
      if (res.status === 200) {
        setBannerList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const fetchCompanyData = async () => {
    try {
      setComLoading(true);
      const res = await GetcompaniesResponse();
      if (res.status === 200) {
        setCompanyList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setComLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const res = await getSafalRoomPrivate({ roomId: roomInfo?._id });
      if (res.status === 200) {
        setCompanyId(res?.data?.data?.company?._id);
        setSafalRoomData(res.data.data?.company);
        setRoomInfo2(res.data.data);
        setSafalRoomYearlyHistory(res.data.yearlyHistory);
        setSafalRoomMonthlyHistory(res.data.monthlyHistory);
        setSafalRoomMeta(res.data.meta);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const fetchPrivateData = async () => {
    try {
      const res = await getPrivateSafalRoomList({ company: companyId, status: 'accepted' });
      if (res.status === 200) {
        setRoomList(res.data?.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const fetchPrivateReqData = async () => {
    try {
      const res = await getPrivateSafalRoomReqList();
      if (res.status === 200) {
        setRoomReqList(res.data?.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const fetchPrivateReqDataAll = async () => {
    try {
      const res = await getPrivateSafalRoomAllReqList();
      if (res.status === 200) {
        setAllRoomReqList({
          polls: res.data?.polls,
          forum: res.data?.forum,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const refresh = async () => {
    setLoading(true);
    await fetchPrivateData();
    await fetchData();
    await fetchExtraData();
    setLoading(false);
  };
  const onInvitation = async () => {
    setInvitationModal({
      open: true,
      data: null,
    });
  };
  const onInvitationModalClose = () => {
    setInvitationModal({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchPrivateReqData();
    fetchCompanyData();
    fetchPrivateReqDataAll();
  }, []);

  useEffect(() => {
    if (!companyId) setCompanyRoomModal({ open: true });
    else fetchPrivateData();
  }, [companyId]);

  useEffect(() => {
    if (roomInfo) refresh();
  }, [roomInfo]);

  const onChangeMainSection = (newValue) => {
    setSelectedMainSection(newValue);
  };

  const onChangeCompany = (id) => {
    setCompanyId(id);
  };

  const onChangeRoom = (id) => {
    const findRoom = roomList?.find((e) => e?._id === id);
    if (findRoom) setRoomInfo(findRoom);
  };

  // const onSetDefaultCompany = async () => {
  //   try {
  //     const res = await updateSafalRoomDefaultCompany({ id: companyId });
  //     if (res.status === 200) {
  //       toast.success(res?.data?.message);
  //       setDefaultRoomCompany(companyId);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.error(err?.response?.data?.message || err.message);
  //   }
  // };
  const onSelectCompanyByModal = (data) => {
    // setDefaultRoomCompany(id);
    setCompanyRoomModal({ open: false });
    setCompanyId(data?.companyId);
    setRoomList(data?.roomList);
    setRoomInfo(data?.roomId);
  };
  const onCloseCompanyByModal = () => {
    navigate(-1);
  };
  const onRoomPrivatePublic = (type) => {
    if (type === 'public') {
      navigate(`/safalroom?company=${companyId}`);
    } else {
      navigate(`/safalroom-private?company=${companyId}`);
    }
  };

  const onAddRoomClick = () => {
    setRoomModal({
      open: true,
      data: null,
    });
  };
  const onUpdateRoomClick = () => {
    setRoomModal({
      open: true,
      data: roomInfo,
    });
  };
  const onRoomModalClose = () => {
    setRoomModal({
      open: false,
      data: null,
    });
  };

  const onDeleteRoom = async () => {
    try {
      const res = await deleteSafalRoomPrivate({ id: roomInfo?._id });
      if (res.status === 200) {
        toast.success(res?.data?.message);
        refresh();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteRoomClick = async () => {
    const result = await Swal.fire({
      text: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Recusandae obcaecati alias neque non, natus, aspernatur odio doloribus odit sed, sit nam tempore molestias. Commodi tempora, cum architecto obcaecati numquam ipsa.\nAre you sure you want to delete this room?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ff4842',
      confirmButtonText: 'Yes',
      appendTo: 'body',
      customClass: {
        container: 'my-swal', // Add a custom class for styling
      },
      onBeforeOpen: () => {
        // Set a higher zIndex for the Swal modal
        document.querySelector('.my-swal').style.zIndex = 999999;
      },
    });
    if (result.isConfirmed) {
      onDeleteRoom();
    }
    // setRoomModal({
    //   open: true,
    //   data: roomInfo,
    // });
  };

  const onRoomShareClick = async () => {
    try {
      const res = await createPrivateRoomInviteCode({ roomId: roomInfo?._id });
      if (!res.data.data?.code) {
        toast.success(`Something went wrong`);
        return;
      }
      const inviteLink = `${window.location.origin}/safalroom-private-invite?code=${res.data.data?.code}`;
      onShareClick(inviteLink);
      // await navigator.clipboard.writeText(inviteLink);
      // toast.success(`Room link copied to clipboard`, {
      //   duration: 4000,
      // });
    } catch (err) {
      console.log(err);
    }
  };
  const onShareClick = (link) => {
    setShareModal((prev) => ({ ...prev, link: link, open: true }));
  };
  const onShareClose = () => {
    setShareModal({ open: false, link: '' });
  };

  const myBuddy = formatBuddyData(roomInfo?.buddyList, user?._id);
  const myRole = roomInfo?.user?._id === user?._id ? 'ADMIN' : myBuddy ? myBuddy?.role : 'DEFAULT';
  const pendingRoomCount = roomReqList.filter((e) => e?.buddyList?.[0]?.status === 'pending').length;
  const pendingRoomCountAll = allRoomReqList.polls.length + allRoomReqList.forum.length + pendingRoomCount;

  const isAddBtn = roomInfo?.company?._id !== companyId ? true : roomInfo?.user?._id !== user?._id;

  //   split banner array into 2
  const banner01 = bannerList.slice(0, Math.round(bannerList.length / 2));
  const banner02 = bannerList.slice(Math.round(bannerList.length / 2), bannerList.length);

  const companyListForMentions = companyList.map((item) => ({
    display: item?.name,
    id: item?._id,
    type: item?.companyType,
  }));

  if (loading || comLoading) return <Inlineloader />;
  return (
    <>
      <Helmet>
        <title>SafalRoom - SafalSubscriptions</title>
      </Helmet>
      <div id="SafalRoom" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 'none',
            marginBottom: '8px',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
              Private SafalRoom {safalRoomData?.name && `- ${safalRoomData?.name}`}
            </Typography>
            <FaqModal module="SafalRoom - Private" color="#3D71FF" />
          </div>
          <SelectBarPrivate value="private" onChange={onRoomPrivatePublic} />
        </div>

        {/* Carousel - Banner */}
        {bannerList.length !== 0 && (
          <div style={{ display: 'flex', gap: '16px' }}>
            <div style={{ position: 'relative', flex: 'none', width: '50%' }}>
              <Carousel
                autoPlay
                infiniteLoop
                showStatus={false}
                showArrows
                showThumbs={false}
                renderArrowNext={(clickHandler, hasNext, label) => (
                  <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
                )}
                renderArrowPrev={(clickHandler, hasPrev, label) => (
                  <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
                )}
                // onChange={(i) => localStorage.setItem('banner', i)}
                showIndicators
                axis="horizontal"
              >
                {banner01.map((e, index) => (
                  <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                    {e.type === 'image' ? (
                      <AnalyticsClickImpression data={e} id={e?._id} page="SafalRoomPrivate">
                        <img
                          alt={'SafalRoomPrivate'}
                          style={{ objectFit: 'cover', borderRadius: '16px', 'pointer-events': 'all' }}
                          height="150px"
                          width="100%"
                          src={e.value}
                        />
                      </AnalyticsClickImpression>
                    ) : (
                      <AnalyticsClickImpression data={e} id={e?._id} page="SafalRoomPrivate">
                        <video
                          style={{ maxHeight: '150px', objectFit: 'cover', borderRadius: '16px' }}
                          width="100%"
                          height="150px"
                          autoPlay="true"
                          muted
                        >
                          <source src={e.value} />
                        </video>
                      </AnalyticsClickImpression>
                    )}
                  </a>
                ))}
              </Carousel>
            </div>
            <div style={{ position: 'relative', flex: 'none', width: '50%' }}>
              <Carousel
                autoPlay
                infiniteLoop
                showStatus={false}
                showArrows
                showThumbs={false}
                renderArrowNext={(clickHandler, hasNext, label) => (
                  <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
                )}
                renderArrowPrev={(clickHandler, hasPrev, label) => (
                  <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
                )}
                // onChange={(i) => localStorage.setItem('banner', i)}
                showIndicators
                axis="horizontal"
              >
                {banner02.map((e, index) => (
                  <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                    {e.type === 'image' ? (
                      <AnalyticsClickImpression data={e} id={e?._id} page="SafalRoomPrivate">
                        <img
                          alt="SafalRoomPrivate"
                          style={{ objectFit: 'cover', borderRadius: '16px', 'pointer-events': 'all' }}
                          height="150px"
                          width="100%"
                          src={e.value}
                        />
                      </AnalyticsClickImpression>
                    ) : (
                      <AnalyticsClickImpression data={e} id={e?._id} page="SafalRoomPrivate">
                        <video
                          style={{ maxHeight: '150px', objectFit: 'cover', borderRadius: '16px' }}
                          width="100%"
                          height="150px"
                          autoPlay="true"
                          muted
                        >
                          <source src={e.value} />
                        </video>
                      </AnalyticsClickImpression>
                    )}
                  </a>
                ))}
              </Carousel>
            </div>
          </div>
        )}
        {/* Search & Action bar */}
        <div
          style={{
            marginTop: '12px',
            flex: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <CustomSimpleSelect
              sx={{
                width: '220px',
              }}
              groupBy={(option) => option.type}
              valueKey="_id"
              value={companyId}
              onChange={(e) => onChangeCompany(e)}
              options={companyList?.map((e) => ({ label: e?.name, _id: e?._id, type: e?.companyType }))}
            />
            <CustomSimpleSelect
              sx={{
                width: '220px',
                '& .MuiOutlinedInput-root': {
                  border: !roomInfo && '1px solid #ff4842',
                },
              }}
              valueKey="_id"
              value={roomInfo?._id || ''}
              onChange={(e) => onChangeRoom(e)}
              options={roomList?.map((e) => ({
                label: `${e?.name}${user?._id === e?.user?._id ? ' (Owner)' : ''}`,
                _id: e?._id,
              }))}
            />
            {myRole === 'ADMIN' && (
              // {roomInfo?.user?._id === user?._id && (
              <Tooltip title="Update this room">
                <IconButton
                  size="small"
                  sx={{
                    flex: 'none',
                    color: '#FFFFFF',
                    backgroundColor: '#3D71FF',
                    '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                  }}
                  onClick={onUpdateRoomClick}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            )}
            {roomInfo?.user?._id === user?._id && (
              <Tooltip title="Delete this room">
                <IconButton
                  size="small"
                  sx={{
                    flex: 'none',
                    color: '#FFFFFF',
                    backgroundColor: '#ff4842',
                    '&:hover': { backgroundColor: '#d40700', color: '#FFFFFF' },
                  }}
                  onClick={onDeleteRoomClick}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {isAddBtn && (
              <Tooltip title="Create a new room">
                <IconButton
                  size="small"
                  sx={{
                    flex: 'none',
                    color: '#FFFFFF',
                    backgroundColor: '#3D71FF',
                    '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                  }}
                  onClick={onAddRoomClick}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
            {myRole === 'ADMIN' && (
              <Tooltip title="Share this link">
                <IconButton
                  size="small"
                  sx={{
                    flex: 'none',
                    color: '#FFFFFF',
                    backgroundColor: '#3D71FF',
                    '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                  }}
                  onClick={onRoomShareClick}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* <Tooltip
              title={
                defaultRoomCompany === safalRoomData?._id
                  ? 'This is your default SafalRoom'
                  : 'Set this SafalRoom is default'
              }
            >
              <button
                style={{ border: 'none', backgroundColor: 'transparent', padding: '2px' }}
                disabled={defaultRoomCompany === safalRoomData?._id}
                onClick={onSetDefaultCompany}
              >
                {defaultRoomCompany === safalRoomData?._id ? (
                  <img
                    style={{ height: '26px', width: '26px', objectFit: 'contain' }}
                    src="/assets/images/SafalRoomDefault2.png"
                    alt="Set this SafalRoom is default"
                  />
                ) : (
                  <img
                    style={{ height: '26px', width: '26px', objectFit: 'contain' }}
                    src="/assets/images/SafalRoomDefault.png"
                    alt="Set this SafalRoom is default"
                  />
                )}
              </button>
            </Tooltip> */}
            {/* <CustomSearch 
            value={searchValue} 
            onChange={(e) => onSearchChange(e.target.value)} 
            placeholder="Search" /> */}
            {/* <CustomSortMenu
              sortState={sortState}
              onChange={onSortChange}
              sort={[
                { value: 'points', label: 'SafalPoints' },
                { value: 'name', label: 'Product Name' },
              ]}
            /> */}
            {/* <OffersDownloadMenu downloadFile={downloadOffersList} /> */}
            {/* <UserOffersFilterMenu state={filterState} onFilterApply={onFilterApply} /> */}
          </div>
          <div
            style={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <Badge color="success" badgeContent={pendingRoomCountAll}>
              <Tooltip title="SafalRoom Invitation">
                <IconButton
                  size="small"
                  sx={{
                    flex: 'none',
                    color: '#FFFFFF',
                    backgroundColor: '#3D71FF',
                    '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                  }}
                  onClick={onInvitation}
                >
                  <NotificationsActiveIcon />
                </IconButton>
              </Tooltip>
            </Badge>
            {/* <Tooltip title="Refresh">
              <IconButton
                size="small"
                sx={{
                  flex: 'none',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={refresh}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip> */}
            <Tooltip title="SafalBuddy">
              <IconButton
                size="small"
                sx={{
                  flex: 'none',
                  color: '#FFFFFF',
                  backgroundColor: 'transparent',
                  '&:hover': { backgroundColor: 'white', color: '#FFFFFF' },
                }}
                onClick={() => {
                  navigate('/safalbuddy');
                }}
              >
                <img
                  src={buddyProfileIcon}
                  alt="buddyProfileIcon"
                  style={{ width: '28px', height: '28px', objectFit: 'cover' }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {/* main section */}
        <div style={{ display: 'flex', gap: '8px', marginTop: '8px', filter: !roomInfo && 'blur(6px)' }}>
          <div
            style={{
              width: '380px',
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <CompanySideInfo
              safalRoomData={safalRoomData}
              safalRoomMeta={safalRoomMeta}
              roomInfo2={roomInfo2}
              roomInfo={roomInfo}
            />
            <SubscribersCountChart
              roomInfo={roomInfo}
              good={safalRoomMeta?.publicFeelingsGood || 0}
              bad={safalRoomMeta?.publicFeelingsBad || 0}
              monthlyHistory={safalRoomMonthlyHistory}
              yearlyHistory={safalRoomYearlyHistory}
            />
          </div>
          <div
            style={{
              display: 'flex',
              padding: '12px',
              backgroundColor: 'white',
              borderRadius: '16px',
              border: '1px solid #BCCEFF',
              flex: 1,
              gap: '8px',
            }}
          >
            {!safalRoomData?._id && !roomInfo?._id && <div style={{ flex: 1 }} />}
            {safalRoomData?._id && roomInfo?._id && (
              <div
                style={{
                  backgroundColor: 'white',
                  height: '780px',
                  borderRadius: '12px',
                  padding: '8px',
                  marginTop: '14px',
                  // border: '1px solid #BCCEFF',
                  flex: 1,
                  position: 'relative',
                }}
              >
                <SelectBar value={selectedMainSection} onChange={onChangeMainSection} />
                {selectedMainSection === 'feed' && (
                  <SafalRoomFeed
                    companyListForMentions={companyListForMentions}
                    myRole={myRole}
                    roomInfo={roomInfo}
                    companyId={safalRoomData?._id}
                  />
                )}
                {selectedMainSection === 'poll' && (
                  <SafalRoomPolls myRole={myRole} roomInfo={roomInfo} companyId={safalRoomData?._id} />
                )}
                {selectedMainSection === 'forum' && (
                  <SafalRoomCommunityForum
                    companyListForMentions={companyListForMentions}
                    myRole={myRole}
                    roomInfo={roomInfo}
                    companyId={safalRoomData?._id}
                  />
                )}
                {selectedMainSection === 'about' && (
                  <SafalRoomAbout myRole={myRole} roomInfo={roomInfo} data={safalRoomData} />
                )}
              </div>
            )}
            <SideBanner type="safalRoomPrivateSide" />
          </div>
        </div>
        {!roomInfo && (
          <span
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            Create or select a private SafalRoom
          </span>
        )}
      </div>
      <CompanySelectModal
        companyId={companyId}
        onSelect={onSelectCompanyByModal}
        data={companyList}
        open={companyRoomModal.open}
        onClose={onCloseCompanyByModal}
      />
      <RoomModal
        refetch={(flag) => {
          if (flag) {
            fetchData();
          } else {
            fetchPrivateData();
          }
        }}
        companyList={companyList}
        onClose={onRoomModalClose}
        open={roomModal.open}
        data={roomModal.data}
        companyId={companyId}
      />
      <InvitationModal
        refetch={() => {
          fetchPrivateData();
          fetchPrivateReqData();
        }}
        refetchPollForum={() => fetchPrivateReqDataAll()}
        onClose={onInvitationModalClose}
        list={roomReqList}
        polls={allRoomReqList.polls}
        forum={allRoomReqList.forum}
        open={invitationModal.open}
        data={invitationModal.data}
      />
      <GlobalShareModal
        link={shareModal.link}
        title="SafalRoom Private"
        open={shareModal.open}
        onClose={onShareClose}
      />
    </>
  );
};

export default SafalRoomPrivate;

const RenderArrowNext = ({ clickHandler, hasNext, label }) => {
  return (
    <IconButton
      disabled={!hasNext}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        right: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );
};
const RenderArrowPrev = ({ clickHandler, hasPrev, label }) => {
  return (
    <IconButton
      disabled={!hasPrev}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        left: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
  );
};

const MetaCard = ({ title, value }) => {
  return (
    <div
      style={{
        // backgroundColor: '#BCCEFF',
        backgroundColor: 'white',
        color: '#3d71ff',
        height: '60px',
        flex: 1,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '6px',
        border: '1px solid #3d71ff',
      }}
    >
      <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{title}</span>
      <span style={{ fontSize: '14px', fontWeight: 'bold', alignSelf: 'flex-end' }}>{value}</span>
    </div>
  );
};

const CompanySideInfo = ({ safalRoomData, roomInfo2, safalRoomMeta, roomInfo }) => {
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('default', { month: 'short' });
  // const currentYear = currentDate.getFullYear();
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        padding: '16px',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #BCCEFF',
      }}
    >
      <span style={{ color: '#3D71FF', fontSize: '18px', fontWeight: 'bold' }}>Welcome to</span>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: '12px',
        }}
      >
        <div style={{ display: 'flex', gap: '12px' }}>
          <img
            src={safalRoomData?.logo}
            alt={safalRoomData?.name}
            style={{
              backgroundColor: 'white',
              height: '60px',
              objectFit: 'cover',
              borderRadius: '8px',
              aspectRatio: 2 / 1,
              boxShadow: '0px 4px 4px 0px #00000040',
            }}
          />
          <div>
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{safalRoomData?.name}</span>
            <p style={{ width: '200px', fontSize: '13px', color: '#3D71FF' }}>{safalRoomData?.companyType}</p>
            <p style={{ width: '200px', fontSize: '14px', color: '#3D71FF', fontWeight: 'bold' }}>{roomInfo?.name}</p>
          </div>
        </div>
        {/* <BuddiesInfo roomInfo={roomInfo2} /> */}
        <div style={{ display: 'flex', gap: '8px', width: '100%' }}>
          <MetaCard title="Active Subscribers" value={`${safalRoomMeta?.activeCount || 0} Nos`} />
          <MetaCard title={`Subscribers - ${currentMonth}`} value={`${safalRoomMeta?.currentMonthSubscribers} Nos`} />
        </div>
      </div>
    </div>
  );
};

const width = `calc(100% / 4)`;
const SelectBar = ({ value, onChange }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '-20px',
        marginLeft: '-8px',
        borderRadius: '16px',
        overflow: 'hidden',
        border: '1px solid #3d71ff',
      }}
    >
      <button
        onClick={() => onChange('feed')}
        style={{
          border: 'none',
          backgroundColor: value === 'feed' ? '#3d71ff' : 'white',
          color: value === 'feed' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          minWidth: '150px',
          width: width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'feed' ? '/assets/images/SafalRoomFeed_white.png' : '/assets/images/SafalRoomFeed.png'}
          alt="Feed"
        />
        <span id="feed-count-private">Feed</span>
      </button>
      <button
        onClick={() => onChange('forum')}
        style={{
          border: 'none',
          backgroundColor: value === 'forum' ? '#3d71ff' : 'white',
          borderLeft: '1px solid #3d71ff',
          borderRight: '1px solid #3d71ff',
          color: value === 'forum' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          minWidth: '150px',
          width: width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'forum' ? '/assets/images/SafalRoomForum_white.png' : '/assets/images/SafalRoomForum.png'}
          alt="Community Forum"
        />
        <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Community Forum</span>
      </button>
      <button
        onClick={() => onChange('poll')}
        style={{
          border: 'none',
          backgroundColor: value === 'poll' ? '#3d71ff' : 'white',
          color: value === 'poll' ? 'white' : '#3d71ff',
          borderRight: '1px solid #3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          minWidth: '150px',
          width: width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'poll' ? '/assets/images/SafalRoomPolls_white.jpg' : '/assets/images/SafalRoomPolls.png'}
          alt="Polls"
        />
        <span>Polls</span>
      </button>
      <button
        onClick={() => onChange('about')}
        style={{
          border: 'none',
          backgroundColor: value === 'about' ? '#3d71ff' : 'white',
          color: value === 'about' ? 'white' : '#3d71ff',
          fontSize: '14px',
          padding: '10px 4px',
          cursor: 'pointer',
          minWidth: '150px',
          width: width,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <img
          style={{ height: '16px', objectFit: 'contain' }}
          src={value === 'about' ? '/assets/images/SafalRoomabout_white.jpg' : '/assets/images/SafalRoomabout.png'}
          alt="About"
        />
        <span>About</span>
      </button>
    </div>
  );
};

const BuddiesInfo = ({ roomInfo }) => {
  const pending = roomInfo?.buddyList?.filter((e) => e?.status === 'pending');
  const accepted = roomInfo?.buddyList?.filter((e) => e?.status === 'accepted');
  const rejected = roomInfo?.buddyList?.filter((e) => e?.status === 'rejected');

  return (
    <div style={{ display: 'flex', gap: '8px', flexDirection: 'column', width: '100%' }}>
      <div
        style={{
          backgroundColor: 'white',
          flex: 1,
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          padding: '6px',
          border: '1px solid #3d71ff',
        }}
      >
        <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#3d71ff' }}>Buddies</span>
        <span style={{ fontSize: '14px', fontWeight: 'bold', alignSelf: 'flex-end' }}>
          Pending {pending?.length} | Accepted {accepted?.length} | Rejected {rejected?.length}
        </span>
      </div>
    </div>
  );
};

// https://safal-dev-public-us.s3.amazonaws.com/assetModule/1711186243628-yopmail-logo-mundocuentas.jpg

const formatBuddyData = (buddyList, myId) => {
  return buddyList?.find((e) => e?.buddy?.sender?._id === myId || e?.buddy?.receiver?._id === myId);
};
