/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/jsx-boolean-value */

import { FormControl, MenuItem, Select } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { useState } from 'react';

const SubscribersCountChart = ({ monthlyHistory, yearlyHistory, good, bad }) => {
  const thisYear = moment().format('YYYY');
  const [selectedYear, setSelectedYear] = useState(thisYear);

  const yearsOptions = Object.keys(yearlyHistory || {});

  const chartData = yearlyHistory?.[selectedYear]?.map((e) => e?.count) ?? [];
  const chartLabel = yearlyHistory?.[selectedYear]?.map((e) => e?.month) ?? [];

  const chartOption = {
    color: ['#BCCEFF'],
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: chartLabel,
      axisLabel: {
        interval: 0,
        fontSize: 10,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        fontSize: 10,
      },
    },
    series: [
      {
        data: chartData,
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {},
      },
    ],
    grid: {
      left: '2%',
      right: '3%',
      bottom: '3%',
      top: '5%',
      containLabel: true,
    },
    textStyle: {
      fontSize: 10,
    },
  };

  const onChangeYear = (year) => {
    setSelectedYear(year);
  };

  const isAllZero = chartData.every((value) => value === 0);

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        padding: '16px',
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #BCCEFF',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
        <span style={{ color: '#3D68FF', fontWeight: 'bold', fontSize: '16px' }}>Subscribers on this product</span>
        <select value={selectedYear} onChange={(e) => onChangeYear(e.target.value)} style={{ width: '80px' }}>
          {yearsOptions?.map((e) => (
            <option value={e}>{thisYear === e ? 'This year' : e}</option>
          ))}
        </select>
      </div>
      <div style={{ position: 'relative' }}>
        <div style={{ filter: isAllZero && 'blur(2px)' }}>
          <ReactECharts notMerge={true} lazyUpdate={true} option={chartOption} />
        </div>
        {isAllZero && (
          <div className="SocialMedia-Blur-Content">
            <span style={{ fontSize: '16px' }}>No record found</span>
          </div>
        )}
      </div>
      <p style={{ fontSize: '14px' }}>
        Monitor your progress with the Subscribers Growth Graph, showcasing the increase in subscribers over time to
        help you gauge the success of your strategies.
      </p>
      <div style={{ height: '2px', backgroundColor: '#BCCEFF', marginTop: '16px' }} />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
        <span style={{ color: '#3D68FF', fontWeight: 'bold', fontSize: '16px' }}>Sentiments</span>
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
          <div
            style={{
              border: 'none',
              borderLeft: '2px solid white',
              backgroundColor: '#58A979',
              height: '34px',
              flex: 1,
              color: 'white',
              borderTopLeftRadius: '30px',
              borderBottomLeftRadius: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Happy {good}%
          </div>
          <div
            style={{
              border: 'none',
              backgroundColor: '#D38080',
              height: '34px',
              flex: 1,
              color: 'white',
              borderTopRightRadius: '30px',
              borderBottomRightRadius: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '13px',
            }}
          >
            Needs improvement {bad}%
          </div>
        </div>
        <p>
          Track and visualize customer sentiment trends over time with our Sentiment offering insights to better
          understand and improve user experiences.
        </p>
      </div>
    </div>
  );
};

export default SubscribersCountChart;
