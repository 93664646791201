/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import Swal from 'sweetalert2';

import { addSafalBuddy, updateSafalBuddy } from '../../services/Service';

export const UserNewProfileButtons = ({ buddy, otherUserId, refetch }) => {
  const [loading, setLoading] = useState(false);

  const currentUser = useSelector((state) => state.login.user);
  const isCurrentUser = currentUser?._id === otherUserId;

  // pending, accept, reject, unBuddy, block, default, unblock

  // pendingMe,pending, accept, reject, unBuddy,blockMe, block, default, unblock

  // pendingMe = current user is sender
  // pending = current user is receiver
  // blockMe = current user is sender
  // block = current user is receiver

  // accept = UnBuddy, Block
  // reject = send red, Block
  // pendingMe = cancel req, Block
  // blockMe = unblock
  // block = nothing
  // default/null/unBuddy = send red, Block

  const status =
    buddy?.status === 'pending'
      ? buddy?.receiver === otherUserId
        ? 'pendingMe'
        : buddy?.status
      : buddy?.status === 'block'
      ? buddy?.receiver === otherUserId
        ? 'blockMe'
        : buddy?.status
      : buddy?.status;

  const updateBuddy = async (status) => {
    setLoading(true);
    try {
      const res = await updateSafalBuddy({ _id: buddy?._id, status, otherUserId });
      if (res?.status === 200) refetch();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const addBuddy = async (status) => {
    setLoading(true);
    try {
      let res = null;
      if (buddy?._id) {
        res = await updateSafalBuddy({ _id: buddy?._id, status, otherUserId });
      } else {
        res = await addSafalBuddy({ otherUserId: otherUserId, status });
      }
      if (res?.status === 200) refetch();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onAcceptClick = async () => {
    const result = await Swal.fire({
      customClass: {
        container: 'my-swal',
      },
      text: 'Are you sure you want to accept this request?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;
    updateBuddy('accept');
  };
  const onCancelRequest = async () => {
    const result = await Swal.fire({
      customClass: {
        container: 'my-swal',
      },
      text: 'Are you sure you want to cancel this request?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;
    updateBuddy('default');
  };
  const onRejectClick = async () => {
    const result = await Swal.fire({
      customClass: {
        container: 'my-swal',
      },
      text: 'Are you sure you want to reject this request?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;
    updateBuddy('reject');
  };
  const onSendRequestClick = () => {
    addBuddy('pending');
  };
  const onSendBlockClick = async () => {
    const result = await Swal.fire({
      customClass: {
        container: 'my-swal',
      },
      text: 'Are you sure you want to block this user?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;
    addBuddy('block');
  };
  const onSendUnBuddyClick = async () => {
    const result = await Swal.fire({
      customClass: {
        container: 'my-swal',
      },
      text: 'Are you sure you want to unbuddy this user?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;
    updateBuddy('unBuddy');
  };
  const onUnblock = async () => {
    const result = await Swal.fire({
      customClass: {
        container: 'my-swal',
      },
      text: 'Are you sure you want to unblock this user?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    });
    if (!result.isConfirmed) return;
    updateBuddy('unblock');
  };

  if (isCurrentUser) return <></>;

  // block = nothing
  if (status === 'block') return <></>;

  // accept = UnBuddy, Block
  if (status === 'accept')
    return (
      <>
        <Button
          disabled={loading}
          onClick={onSendUnBuddyClick}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          UnBuddy
        </Button>
        <Button
          disabled={loading}
          onClick={onSendBlockClick}
          sx={{
            textTransform: 'none',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Block
        </Button>
      </>
    );

  // pendingMe = cancel req, Block
  if (status === 'pendingMe')
    return (
      <>
        <Button
          disabled={loading}
          onClick={onCancelRequest}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Cancel Request
        </Button>
        <Button
          onClick={onSendBlockClick}
          sx={{
            textTransform: 'none',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Block
        </Button>
      </>
    );

  // blockMe = unblock
  if (status === 'blockMe')
    return (
      <>
        <Button
          disabled={loading}
          onClick={onUnblock}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Unblock
        </Button>
      </>
    );

  // pending = accept, reject
  if (status === 'pending')
    return (
      <>
        <Button
          disabled={loading}
          onClick={onAcceptClick}
          size="small"
          sx={{
            backgroundColor: '#025E16',
            textTransform: 'none',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#025E16' },
          }}
          variant="contained"
        >
          Accept
        </Button>
        <Button
          disabled={loading}
          onClick={onRejectClick}
          sx={{
            textTransform: 'none',
            backgroundColor: '#A80000',
            color: 'white',
            borderRadius: '30px',
            ':hover': { backgroundColor: '#A80000' },
          }}
          variant="contained"
          color="error"
          size="small"
        >
          Reject
        </Button>
      </>
    );

  // default/null/unBuddy/reject = send red, Block
  return (
    <>
      <Button
        disabled={loading}
        onClick={onSendRequestClick}
        size="small"
        sx={{
          backgroundColor: '#025E16',
          textTransform: 'none',
          borderRadius: '30px',
          ':hover': { backgroundColor: '#025E16' },
        }}
        variant="contained"
      >
        Send Request
      </Button>
      <Button
        disabled={loading}
        onClick={onSendBlockClick}
        sx={{
          textTransform: 'none',
          backgroundColor: '#A80000',
          color: 'white',
          borderRadius: '30px',
          ':hover': { backgroundColor: '#A80000' },
        }}
        variant="contained"
        color="error"
        size="small"
      >
        Block
      </Button>
    </>
  );
};
