import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Image } from 'antd';
import { Helmet } from 'react-helmet-async';
import { Carousel } from 'react-responsive-carousel';
import toast from 'react-hot-toast';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';

import './SafalGiftPage.css';
import {
  getBannerList,
  getSafalGiftInventoryUser,
  getSafalGiftUserCart,
  getSafalLeaderUserPoints,
  placeSafalGiftUserOrder,
  updateSafalGiftUserCart,
} from '../../../services/Service';
import CustomSortMenu from '../../../components/UI/CustomSortMenu';
import CustomSearch from '../../../components/UI/CustomSearch';
import SafalGiftCart from './SafalGiftCart';
import Inlineloader from '../../../components/Loader/InlineLoader';
import SafalGiftOrderPrecessModal from './SafalGiftOrderPrecessModal';
import SafalGiftOrderPlaceSuccessModal from './SafalGiftOrderPlaceSuccessModal';
import FaqModal from '../../../components/Modals/FaqModal';

const SafalGiftPage = () => {
  const [bannerList, setBannerList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productFilterList, setProductFilterList] = useState([]);
  const [userCart, setUserCart] = useState({
    cart: [],
    _id: null,
    totalPrice: 0,
    totalPoints: 0,
  });
  const [isCartUpdating, setIsCartUpdating] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [yourCurrentPoints, setYourCurrentPoints] = useState(null);
  const [orderProcessModal, setOrderProcessModal] = useState({
    open: false,
    data: null,
  });
  const [orderProcessSuccessModal, setOrderProcessSuccessModal] = useState({
    open: false,
    data: null,
  });

  const [sortState, setSortState] = useState({
    sort: 'name',
    order: 'desc',
  });

  const fetchData = async () => {
    try {
      const res = await getBannerList('safalGift');
      if (res.status === 200) {
        setBannerList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const fetchProduct = async () => {
    try {
      setSearchValue('');
      const res = await getSafalGiftInventoryUser({ application: 'SafalSubscriptions', ...sortState });
      if (res.status === 200) {
        setProductList(res.data.data);
        setProductFilterList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const fetchCart = async () => {
    try {
      getSafalLeaderUserPoints().then((res) => {
        if (res.data.data) {
          setYourCurrentPoints(res.data.data);
        }
      });

      const res = await getSafalGiftUserCart();
      if (res.status === 200) {
        setUserCart({
          cart: res.data.data?.cart,
          _id: res.data.data?._id,
          totalPrice: res.data.totalPrice,
          totalPoints: res.data.totalPoints,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onSortChange = (newSort) => {
    setSortState(newSort);
  };

  const refresh = async () => {
    setLoading(true);
    await fetchProduct();
    await fetchData();
    await fetchCart();
    setLoading(false);
  };

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    fetchProduct();
  }, [sortState]);

  const onOrderProcessOpen = () => {
    setOrderProcessModal({
      open: true,
      data: userCart,
    });
  };
  const onOrderProcessClose = () => {
    setOrderProcessModal({
      open: false,
      data: null,
    });
  };
  const onOrderProcessSuccessClose = () => {
    setOrderProcessSuccessModal({
      open: false,
      data: null,
    });
    refresh();
  };
  const onOrderProcess = async (data) => {
    try {
      setIsOrdering(true);
      const payload = { ...data, pointId: yourCurrentPoints?._id };
      const res = await placeSafalGiftUserOrder(payload);
      if (res.status === 200) {
        setOrderProcessSuccessModal({ data: res.data.data, open: true });
        toast.success(res.data.message, {
          duration: 5000,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsOrdering(false);
    }
  };

  const banner01 = bannerList.slice(0, Math.round(bannerList.length / 2));
  const banner02 = bannerList.slice(Math.round(bannerList.length / 2), bannerList.length);

  const fetchCartUpdate = async (payload) => {
    try {
      setIsCartUpdating(true);
      const res = await updateSafalGiftUserCart(payload);
      if (res.status === 200) {
        fetchCart();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsCartUpdating(false);
    }
  };

  const productQtyChange = async (id, newQty) => {
    const findIndex = userCart?.cart.findIndex((e) => e?.product?._id === id);

    const newPayload = userCart?.cart?.map((e) => ({
      product: e?.product?._id,
      qty: e?.product?._id === id ? newQty : e?.qty,
    }));
    if (findIndex === -1) newPayload.push({ product: id, qty: newQty });

    const newCart = newPayload.filter((e) => e?.qty > 0);

    await fetchCartUpdate({
      cart: newCart,
    });
  };

  const onSearchChange = (value) => {
    setSearchValue(value);
    if (value?.length < 2) {
      setProductFilterList(productList);
    } else {
      const filterData = productList.filter((e) => e?.name?.toLowerCase()?.includes(value?.toLowerCase()));
      setProductFilterList(filterData);
    }
  };

  if (loading) return <Inlineloader />;

  return (
    <>
      <Helmet>
        <title>SafalGift - SafalSubscriptions</title>
      </Helmet>
      <div id="SafalGiftPage" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 'none', marginBottom: '8px' }}>
          <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            SafalGift
          </Typography>
          <FaqModal module="SafalGift" color="#3D71FF" />
        </div>
        {/* Carousel */}
        {bannerList.length !== 0 && (
          <div style={{ display: 'flex', gap: '16px' }}>
            <div style={{ position: 'relative', flex: 'none', width: '50%' }}>
              <Carousel
                autoPlay
                infiniteLoop
                showStatus={false}
                showArrows
                showThumbs={false}
                renderArrowNext={(clickHandler, hasNext, label) => (
                  <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
                )}
                renderArrowPrev={(clickHandler, hasPrev, label) => (
                  <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
                )}
                // onChange={(i) => localStorage.setItem('banner', i)}
                showIndicators
                axis="horizontal"
              >
                {banner01.map((e, index) => (
                  <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                    {e.type === 'image' ? (
                      <Image
                        preview={false}
                        style={{ objectFit: 'cover', borderRadius: '16px' }}
                        height="150px"
                        width="100%"
                        src={e.value}
                      />
                    ) : (
                      <video
                        style={{ maxHeight: '150px', objectFit: 'cover', borderRadius: '16px' }}
                        width="100%"
                        height="150px"
                        autoPlay="true"
                        muted
                      >
                        <source src={e.value} />
                      </video>
                    )}
                  </a>
                ))}
              </Carousel>
            </div>
            <div style={{ position: 'relative', flex: 'none', width: '50%' }}>
              <Carousel
                autoPlay
                infiniteLoop
                showStatus={false}
                showArrows
                showThumbs={false}
                renderArrowNext={(clickHandler, hasNext, label) => (
                  <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
                )}
                renderArrowPrev={(clickHandler, hasPrev, label) => (
                  <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
                )}
                // onChange={(i) => localStorage.setItem('banner', i)}
                showIndicators
                axis="horizontal"
              >
                {banner02.map((e, index) => (
                  <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                    {e.type === 'image' ? (
                      <Image
                        preview={false}
                        style={{ objectFit: 'cover', borderRadius: '16px' }}
                        height="150px"
                        width="100%"
                        src={e.value}
                      />
                    ) : (
                      <video
                        style={{ maxHeight: '150px', objectFit: 'cover', borderRadius: '16px' }}
                        width="100%"
                        height="150px"
                        autoPlay="true"
                        muted
                      >
                        <source src={e.value} />
                      </video>
                    )}
                  </a>
                ))}
              </Carousel>
            </div>
          </div>
        )}
        {/* Search & Action bar */}
        <div
          style={{
            marginTop: '12px',
            flex: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            <CustomSearch value={searchValue} onChange={(e) => onSearchChange(e.target.value)} placeholder="Search" />
            <CustomSortMenu
              sortState={sortState}
              onChange={onSortChange}
              sort={[
                { value: 'points', label: 'SafalPoints' },
                { value: 'name', label: 'Product Name' },
              ]}
            />
            {/* <OffersDownloadMenu downloadFile={downloadOffersList} /> */}
            {/* <UserOffersFilterMenu state={filterState} onFilterApply={onFilterApply} /> */}
            <Tooltip title="Refresh">
              <IconButton
                size="small"
                sx={{
                  flex: 'none',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={refresh}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {productFilterList.length > 0 ? (
            <div className="cat-table-grid-x1">
              {productFilterList?.map((e, index) => (
                <ProductBox key={index} data={e} productQtyChange={productQtyChange} disabled={isCartUpdating} />
              ))}
            </div>
          ) : (
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '16px 0px', flex: 1 }}
            >
              <span>No Product Found</span>
            </div>
          )}
          <SafalGiftCart
            yourCurrentPoints={yourCurrentPoints}
            userCart={userCart}
            updateCart={fetchCartUpdate}
            disabled={isCartUpdating}
            onOrderProcess={onOrderProcessOpen}
          />
        </div>
      </div>
      <SafalGiftOrderPrecessModal
        open={orderProcessModal.open}
        data={orderProcessModal.data}
        onOrderProcess={onOrderProcess}
        onClose={onOrderProcessClose}
        disabled={isOrdering}
        yourCurrentPoints={yourCurrentPoints}
      />
      <SafalGiftOrderPlaceSuccessModal
        onClose={onOrderProcessSuccessClose}
        open={orderProcessSuccessModal.open}
        data={orderProcessSuccessModal.data}
      />
    </>
  );
};

export default SafalGiftPage;

const RenderArrowNext = ({ clickHandler, hasNext, label }) => {
  return (
    <IconButton
      disabled={!hasNext}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        right: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );
};
const RenderArrowPrev = ({ clickHandler, hasPrev, label }) => {
  return (
    <IconButton
      disabled={!hasPrev}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        left: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
  );
};

const ProductBox = ({ data, productQtyChange, disabled }) => {
  return (
    <div
      style={{
        width: '180px',
        flex: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '6px',
        padding: '6px',
      }}
    >
      <Image
        preview
        style={{ objectFit: 'cover', borderRadius: '8px' }}
        height="120px"
        width="150px"
        src={data.image}
      />
      <p style={{ fontWeight: 'bold', fontSize: '16px', textAlign: 'center' }}>{data?.name}</p>
      <div
        style={{
          backgroundColor: '#4f79ea',
          borderRadius: '8px',
          padding: '5px 0px',
          width: '100%',
          textAlign: 'center',
          color: 'white',
        }}
      >
        {data?.points} Spts / ${data?.price}
      </div>
      <Button
        disabled={disabled}
        fullWidth
        sx={{ backgroundColor: '#001e6e', borderRadius: '8px', textTransform: 'capitalize' }}
        onClick={() => productQtyChange(data?._id, 1)}
        variant="contained"
      >
        Buy
      </Button>
    </div>
  );
};
