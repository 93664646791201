import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Image } from 'antd';
import { IconButton, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import SendIcon from '@mui/icons-material/Send';

import likeImg from '../../../assets/Like.png';
import disLike from '../../../assets/disLike.png';
import likeDislike from '../../../assets/likeDislike.png';
import { fallbackImage } from '../../../utils/general';
import { getOffersCommentsReplyListUser, createOfferCommentsReply } from '../../../services/Service';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import { ProfileImageState } from './SingleOfferModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function SingleOfferReplyModal({ open, onClose, data }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [commentsListReply, setCommentsListReply] = React.useState([]);
  const handleClose = () => {
    onClose();
    setIsSubmitting(false);
    setMessage('');
    setCommentsListReply([]);
  };

  const addComments = async () => {
    setIsSubmitting(true);
    try {
      if (message.length < 4) {
        toast.error('reply message must be at least 3 characters long');
        return;
      }
      const res = await createOfferCommentsReply({ message: message, offerId: data.offer, commentsId: data._id });
      setMessage('');
      fetchComments();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchComments = async () => {
    try {
      const res = await getOffersCommentsReplyListUser({ id: data?._id });
      setCommentsListReply(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setCommentsListReply(data?.replies || []);
    }
  }, [open, data]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {/* Comments main */}
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px', gap: '4px' }}>
          {/* Comments title */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '8px 0px',
              gap: '4px',
            }}
          >
            <SingleComment disabled={isSubmitting} data={data} />
          </div>
          <span style={{ fontSize: '16px', flex: 'none', fontWeight: 'bold' }}>Replies</span>
          {/* Comments text box */}
          <div style={{ display: 'flex', flex: 'none', alignItems: 'center', gap: '8px' }}>
            <TextField
              disabled={isSubmitting}
              sx={{ fontSize: '14px' }}
              size="small"
              multiline
              minRows={1}
              maxRows={3}
              fullWidth
              variant="outlined"
              placeholder="Enter your reply..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              title="Post Reply"
              disabled={isSubmitting}
              sx={{
                backgroundColor: '#3D71FF',
                textTransform: 'capitalize',
                display: 'flex',
                alignItems: 'center',
                marginTop: 'auto',
                borderRadius: '30px',
              }}
              onClick={addComments}
              size="medium"
              variant="contained"
              endIcon={<SendIcon />}
            >
              Reply
            </Button>
          </div>
          {/* Comments view box */}
          <div
            style={{
              height: '280px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              padding: '8px 0px',
              gap: '4px',
            }}
          >
            {commentsListReply?.length === 0 && <span style={{ textAlign: 'center' }}>No reply found.</span>}
            {commentsListReply?.map((e, index) => (
              <SingleReply disabled={isSubmitting} data={e} key={index} />
            ))}
          </div>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            title="Close"
            onClick={handleClose}
            sx={{ backgroundColor: '#3D71FF', textTransform: 'capitalize' }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const SingleComment = ({ data, onUpdate, disabled }) => {
  const { formatDateTime } = useUserTimeZone();
  return (
    <div
      style={{
        backgroundColor: '#f2f2f2',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: 'normal',
        fontSize: '16px',
        padding: '8px',
        borderRadius: '8px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 'normal', fontSize: '16px' }}>
        <span style={{ fontWeight: 'bold' }}>{data?.whoCreated?.firstName}</span>
        <span style={{ fontWeight: '200' }}>
          {formatDateTime(data?.createdAt)} {getTimeZoneAbbreviation()}
        </span>
      </div>
      <p>{data.message}</p>
      <div style={{ display: 'flex', gap: '8px', justifyContent: 'end' }}>
        <div>
          {/* <span style={{ fontSize: '12px' }}>23</span> */}
          <Image
            onClick={() => !disabled && onUpdate(data._id, 1)}
            title="Safal"
            preview={false}
            style={{
              filter: data?.likeDislikeUser?.you?.like !== 1 && 'grayscale(100%)',
              objectFit: 'contain',
              cursor: 'pointer',
            }}
            height="35px"
            src={likeImg}
          />
        </div>
        <div>
          {/* <span style={{ fontSize: '12px' }}>23</span> */}
          <Image
            onClick={() => !disabled && onUpdate(data._id, -1)}
            title="Unsafal"
            preview={false}
            style={{
              filter: data?.likeDislikeUser?.you?.like !== -1 && 'grayscale(100%)',
              cursor: 'pointer',
              objectFit: 'contain',
            }}
            height="35px"
            src={disLike}
          />
        </div>
      </div>
    </div>
  );
};
const SingleReply = ({ data, onUpdate, disabled }) => {
  const { formatDateTime } = useUserTimeZone();
  return (
    <div style={{ display: 'flex', backgroundColor: '#dfe6ff', borderRadius: '12px' }} className="hover-bg-white-x2">
      <div style={{ flex: 'none', marginTop: '8px', marginLeft: '8px' }}>
        <ProfileImageState src={data?.whoCreated?.profilePic} name={data?.whoCreated?.firstName[0]} />
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          lineHeight: 'normal',
          fontSize: '16px',
          padding: '8px',
          borderRadius: '8px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', lineHeight: 'normal', fontSize: '16px' }}>
          <span style={{ fontWeight: 'bold' }}>{data?.whoCreated?.firstName}</span>
          <span style={{ fontWeight: '200' }}>
            {formatDateTime(data?.createdAt)} {getTimeZoneAbbreviation()}
          </span>
        </div>
        <p>{data.message}</p>
      </div>
    </div>
  );
};
