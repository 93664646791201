import React, { useEffect } from 'react';
import moment from 'moment';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Avatar, Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { useSelector, connect } from 'react-redux';
import NavSection from '../../components/NavSection';
import navConfig from './NavConfig';
import NotificationsPopover from './NotificationsPopover';
import LanguagePopover from './LanguagePopover';
import AccountPopover from './AccountPopover';
/* eslint-disable */

const Sidebar = ({ open, onClose }) => {
  const { user } = useSelector((state) => state.login);
  const theme = useTheme();

  const getLink = () => {
    if (user?.role === 'admin') {
      return '/admin/profile';
    }

    if (user?.role === 'business') {
      return '/business/profile';
    }

    if (user?.role === 'user') {
      return '/profile';
    }
  };

  const MENU_OPTIONS = [
    {
      label: 'Home',
      icon: '/assets/images/HomeIcon.png',
      linkTo: '/',
    },
    {
      label: 'Settings',
      icon: '/assets/images/SettingsIcon.png',
      // linkTo: user?.role === 'admin' ? '/admin/profile' : user?.role === 'business' ? '/business/profile' : '/profile',
      linkTo: getLink(),
    },
  ];

  useEffect(() => {
    console.log('window.location.pathname ====', window.location.pathname);
    onClose();
  }, [window.location.pathname]);

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <List>
        <ListItem>
          <Stack direction={'row'}>
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle2" sx={{ color: 'black', fontSize: '13px', ml: 2, fontWeight: 700 }}>
                {user?.firstName}
              </Typography>
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" spacing={{ xs: 1.5, sm: 1.5 }}>
            <NotificationsPopover onClose={onClose} />
            <span style={{ marginTop: '-4px' }} onClick={onClose}>
              <LanguagePopover />
            </span>
            <AccountPopover />
          </Stack>
        </ListItem>
        <Divider />
        <ListItem>
          <NavSection navConfig={navConfig} display={'block'} onClick={onClose} />
        </ListItem>
      </List>
    </Drawer>
  );
};

// export default Sidebar;

export default connect()(Sidebar);
