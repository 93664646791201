/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Swal from 'sweetalert2';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

import { firebasePushNotification, getSafalRoomForumUser } from '../../../services/Service';
import { ProfileImageState } from './SafalRoomFeed';
import SafalLeaderBoard_Call from '../../../assets/SafalLeaderBoard_Call.png';
import SafalLeaderBoard_Chat from '../../../assets/SafalLeaderBoard_Chat.png';
import SafalRoomCommunityForumFeed from './SafalRoomCommunityForumFeed';
import AddSafalRoomCommunityForumModal from './AddSafalRoomCommunityForumModal';
import UserChatModal from '../../../components/Modals/UserChatModal';
import { db } from '../../../firebase';
import CustomSearch from '../../../components/UI/CustomSearch';

const SafalRoomCommunityForum = ({ companyId, companyListForMentions }) => {
  const { user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [selectedBusinessStatus, setSelectedBusinessStatus] = useState('approved');
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const [forumList, setForumList] = useState([]);
  const [forumListFilter, setForumFilterList] = useState([]);
  const [selectedForum, setSelectedForum] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [openCommunityForumModal, setOpenCommunityForumModal] = useState({
    open: false,
    data: null,
  });
  const myForumFeedRef = useRef(null);
  const [userChatModal, setUserChatModal] = useState({
    open: false,
    otherUserId: '',
    type: 'chat', // chat. call
    user: null,
  });
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getSafalRoomForumUser({ company: companyId });
      if (res.status === 200) {
        setForumList(res.data.data);
        let filterX = [];
        if (selectedBusinessStatus === 'approved') {
          filterX = res.data.data.filter((e) => e?.businessStatus === selectedBusinessStatus);
        } else {
          filterX = res.data.data.filter(
            (e) => e?.businessStatus === selectedBusinessStatus && e?.user?._id === user?._id
          );
        }
        setForumFilterList(filterX);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangeBusinessStatus = (value) => {
    setSelectedBusinessStatus(value);
    let filterX = [];
    if (value === 'approved') {
      filterX = forumList.filter((e) => e?.businessStatus === value);
    } else {
      filterX = forumList.filter((e) => e?.businessStatus === value && e?.user?._id === user?._id);
    }
    setForumFilterList(filterX);
  };

  const onChangeForum = (data) => {
    if (selectedBusinessStatus !== 'approved') return;
    setSelectedForum(data);
  };

  const onChangeBack = (data) => {
    setSelectedForum(null);
  };

  const onOpenForumModal = () => {
    setOpenCommunityForumModal({
      open: true,
      data: null,
    });
  };
  const onCloseForumModal = () => {
    setOpenCommunityForumModal({
      open: false,
      data: null,
    });
    fetchData();
  };

  const approvedCount = forumList.filter((e) => e?.businessStatus === 'approved').length;
  const rejectedCount = forumList.filter((e) => e?.businessStatus === 'rejected' && e?.user?._id === user?._id).length;
  const pendingCount = forumList.filter((e) => e?.businessStatus === 'pending' && e?.user?._id === user?._id).length;

  const openFeedPostModal = () => {
    if (myForumFeedRef.current) {
      myForumFeedRef.current.onPost();
    }
  };

  const handleUserChatClose = () => {
    localStorage.removeItem('SafalBuddyChat');
    localStorage.removeItem('SafalBuddyChatNew');
    setUserChatModal({
      open: false,
      otherUserId: '',
      type: 'chat', // chat. call
      user: null,
    });
  };

  const onChatClick = async (otherUserId, otherUserInfo) => {
    if (!user?.registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    setUserChatModal({
      open: true,
      otherUserId: otherUserId,
      type: 'chat', // chat. call
      otherUserInfo: otherUserInfo,
    });
  };

  const onCallClick = async (otherUserId, otherUserInfo) => {
    if (!user?.registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    onConnectCall(otherUserInfo);
  };

  const onConnectCall = async (user) => {
    const currentUserId = currentFirebaseUser.uid;
    const otherUserId = user?.uid;
    // user to user calls
    const otherUserDocDataRef = await getDoc(doc(db, 'users', otherUserId));
    const otherUserDocData = otherUserDocDataRef.data();
    const callId = uuidv4();
    const callLogRef = doc(db, 'callLogs', callId);
    const currentUserCallDocRef = doc(db, 'userCalls', currentUserId);
    const otherUserCallDocRef = doc(db, 'userCalls', otherUserId);
    try {
      const getDocData = await getDoc(otherUserCallDocRef);
      if (getDocData.exists) {
        const data = getDocData.data();
        if (data.isCall) {
          toast('User is busy in another call');
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }

    const callObj = {
      callId: callId,
      isCall: true,
      callMode: 'audio', // audio/video,
      type: 'user',
      status: 'pending', // "pending", "accept", "reject", "end"
      currentUserId: currentUserId, // undefined or string
      otherUserId: otherUserId, // undefined or string
      flag: 'user_to_user', // user_to_user or support_to_user or user_to_support
      startAt: serverTimestamp(),
    };

    await setDoc(callLogRef, callObj);
    await setDoc(currentUserCallDocRef, callObj);
    await setDoc(otherUserCallDocRef, callObj);

    const notificationPayload = {
      data: {
        notification: { image: '', title: 'Calling', body: `${currentFirebaseUser?.displayName} calling you` },
        data: {
          type: 'call',
          messageId: '',
          otheruid: '',
          image_url: otherUserDocData?.photoURL,
          chatID: '',
          displayName: otherUserDocData?.displayName,
          userType: 'USER',
          isAudio: '1',
          OtherUid: currentUserId,
          channelId: callId,
        },
        apns: { payload: { aps: { sound: 'afro_ringtone.mp3', badge: 1 } } },
      },
      userId: otherUserDocData?.userID,
    };
    await firebasePushNotification(notificationPayload);
  };

  const forumListFilterSearched = forumListFilter.filter((e) => {
    if (searchValue === '') {
      return true;
    }
    return (
      e?.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
      e?.topic?.toLowerCase().includes(searchValue.toLowerCase())
    );
  });

  return (
    <>
      {selectedForum === null ? (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: 'calc(100% - 16px)',
              padding: '16px 4px',
              gap: '8px',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControl size="small" sx={{ width: '200px' }}>
                <Select
                  size="small"
                  value={selectedBusinessStatus}
                  onChange={(e) => onChangeBusinessStatus(e.target.value)}
                >
                  <MenuItem value="approved">Approved ({approvedCount})</MenuItem>
                  <MenuItem value="rejected">Rejected ({rejectedCount})</MenuItem>
                  <MenuItem value="pending">Pending ({pendingCount})</MenuItem>
                </Select>
              </FormControl>
              <CustomSearch
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search..."
              />
              <Button
                onClick={onOpenForumModal}
                size="small"
                sx={{
                  width: '140px',
                  borderRadius: '30px',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                  whiteSpace: 'nowrap',
                  textTransform: 'capitalize',
                }}
                title="Create New Community Forum"
                startIcon={<AddIcon />}
                variant="contained"
              >
                Create Forum
              </Button>
            </div>
            {forumListFilterSearched.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  flex: 1,
                  // height: 'calc(100% - 16px)',
                  // padding: '16px 4px',
                  scrollbarWidth: 'thin',
                  gap: '8px',
                }}
              >
                {forumListFilterSearched?.map((e, index) => (
                  <CommunityForumSingle data={e} key={index} onClick={() => onChangeForum(e)} />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '300px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>No Forum Found</span>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100% - 16px)',
            padding: '16px 4px',
            gap: '8px',
          }}
        >
          <CommunityForumHead
            onChat={onChatClick}
            onCall={onCallClick}
            data={selectedForum}
            onChangeBack={onChangeBack}
            openFeedPostModal={openFeedPostModal}
          />
          <SafalRoomCommunityForumFeed
            companyListForMentions={companyListForMentions}
            ref={myForumFeedRef}
            companyId={companyId}
            forumId={selectedForum?._id}
          />
        </div>
      )}
      <AddSafalRoomCommunityForumModal
        data={openCommunityForumModal.data}
        open={openCommunityForumModal.open}
        onClose={onCloseForumModal}
        companyId={companyId}
      />
      <UserChatModal
        open={userChatModal.open}
        buddyUser={userChatModal.otherUserInfo}
        handleClose={() => handleUserChatClose()}
      />
    </>
  );
};
export default SafalRoomCommunityForum;

const CommunityForumHead = ({ data, onChangeBack, openFeedPostModal, onChat, onCall }) => {
  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const user = data?.user;
  const userProfileImg = user?.profilePic || '';
  const userFullName = `${user?.firstName || ''} ${user?.lastName || ''}`;

  const forumName = data?.name || '';
  const forumTopic = data?.topic || '';
  const forumDescription = data?.description || '';

  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const currentUserToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;
  const isChatCallEnable = currentUserToUserChatEnable ? otherUserInfo?.userToUserChat === true : false;

  const chatCallSameUser = currentFirebaseUser.uid === data?.user?._id;
  const onChatClick = async () => {
    if (chatCallSameUser) return;
    onChat(data?.user?._id, otherUserInfo);
  };
  const onCallClick = async () => {
    if (chatCallSameUser) return;
    onCall(data?.user?._id, otherUserInfo);
  };

  const fetchOtherUserFirebase = async (otherUserId) => {
    if (!otherUserId) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', otherUserId));
    if (otherUserInfoRef.exists()) {
      setOtherUserInfo(otherUserInfoRef.data());
    }
  };

  useEffect(() => {
    fetchOtherUserFirebase(data?.user?._id);
  }, []);

  return (
    <div
      style={{
        flex: 'none',
        border: '2px solid #BCCEFF',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        borderRadius: '16px',
        gap: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          flex: 'none',
          width: '100px',
          gap: '2px',
        }}
      >
        <ProfileImageState src={userProfileImg} name={userFullName} />
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: 'normal',
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          {userFullName}
        </span>
        {!chatCallSameUser && (
          <div style={{ display: 'flex', gap: '6px' }}>
            <button
              title="Chat"
              onClick={onChatClick}
              disabled={!isChatCallEnable}
              style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
            >
              <img
                src={SafalLeaderBoard_Chat}
                style={{
                  width: '16px',
                  height: '16px',
                  objectFit: 'contain',
                  filter: !isChatCallEnable ? 'grayscale(1)' : '',
                }}
                alt="SafalLeaderBoard_Chat"
              />
            </button>
            <button
              title="Call"
              onClick={onCallClick}
              disabled={!isChatCallEnable}
              style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
            >
              <img
                src={SafalLeaderBoard_Call}
                style={{
                  width: '16px',
                  height: '16px',
                  objectFit: 'contain',
                  filter: !isChatCallEnable ? 'grayscale(1)' : '',
                }}
                alt="SafalLeaderBoard_Call"
              />
            </button>
            {/* <button
            title="Add to SafalBuddy"
            style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
          >
            <img
              src={SafalLeaderBoard_Call}
              style={{
                width: '16px',
                height: '16px',
                objectFit: 'contain',
              }}
              alt="SafalLeaderBoard_Call"
            />
          </button> */}
          </div>
        )}
      </div>
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{forumName}</span>
            <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#3D71FF' }}>{forumTopic}</span>
          </div>
          <div>
            <Button
              onClick={onChangeBack}
              size="small"
              sx={{
                fontSize: '12px',
                width: '80px',
                borderRadius: '30px',
                color: '#3D71FF',
                '&:hover': { color: '#3D71FF' },
                whiteSpace: 'nowrap',
                textTransform: 'capitalize',
              }}
              title="Go Back to Community Forum List"
              startIcon={<ArrowBackIosNewIcon />}
              variant="outlined"
            >
              Back
            </Button>
            <Button
              onClick={openFeedPostModal}
              size="small"
              sx={{
                marginLeft: '8px',
                fontSize: '12px',
                width: '80px',
                borderRadius: '30px',
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                whiteSpace: 'nowrap',
                textTransform: 'capitalize',
              }}
              title="Add a reply"
              startIcon={<AddIcon />}
              variant="contained"
            >
              Reply
            </Button>
            {/* <button
              title="Edit this forum"
              style={{ border: 'none', padding: '2px', backgroundColor: 'transparent', cursor: 'pointer' }}
            >
              <EditIcon sx={{ fontSize: '16px', color: '#3D71FF' }} />
            </button>
            <button
              title="Delete this forum"
              style={{
                border: 'none',
                padding: '2px',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                marginLeft: '8px',
              }}
            >
              <DeleteIcon sx={{ fontSize: '16px', color: 'red' }} />
            </button> */}
          </div>
        </div>
        <p style={{ fontSize: '13px' }}>{forumDescription}</p>
      </div>
    </div>
  );
};
const CommunityForumSingle = ({ data, onClick }) => {
  const user = data?.user;
  const userProfileImg = user?.profilePic || '';
  const userFullName = `${user?.firstName || ''} ${user?.lastName || ''}`;

  const forumName = data?.name || '';
  const forumTopic = data?.topic || '';
  const forumDescription = data?.description || '';

  const onOpenForum = () => {
    if (data?.businessStatus !== 'approved') return;
    onClick();
  };

  return (
    <div
      onClick={onOpenForum}
      className="hover-blue-border-xz1"
      style={{
        border: '2px solid #BCCEFF',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        borderRadius: '16px',
        gap: '16px',
        userSelect: 'none',
        cursor: 'pointer',
      }}
      title="Open This Forum"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          flex: 'none',
          width: '100px',
          gap: '2px',
        }}
      >
        <ProfileImageState src={userProfileImg} name={userFullName} />
        <span
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineHeight: 'normal',
            textAlign: 'center',
            fontSize: '14px',
          }}
        >
          {userFullName}
        </span>
      </div>
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <span style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center', gap: '8px' }}>
          {forumName} {data?.totalFeed > 0 && <FeedCount count={data?.totalFeed} />}
        </span>
        <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#3D71FF' }}>{forumTopic}</span>
        <p style={{ fontSize: '13px' }} className="text-overflow-line-clamp-2">
          {forumDescription}
        </p>
      </div>
      {data?.businessStatus === 'approved' && <KeyboardArrowRightIcon sx={{ marginLeft: 'auto' }} />}
    </div>
  );
};

const FeedCount = ({ count }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '12px', fontWeight: 'normal' }}>
      <TextsmsIcon sx={{ height: '16px', width: '16px' }} />
      <span>{count}</span>
    </div>
  );
};
