import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Image } from 'antd';

import { getBannerList } from '../../../services/Service';
import useImageCarousel from '../../../hooks/useImageCarousel';
import AnalyticsClickImpression from '../../../components/AnalyticsClickImpression';

const SideBanner = ({ type, id, data }) => {
  const [bannerList, setBannerList] = useState([]);
  const currentImg = useImageCarousel(bannerList, 5000);

  const fetchBanner = async () => {
    try {
      const res = await getBannerList(type);
      if (res.status === 200) {
        setBannerList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  useEffect(() => {
    fetchBanner();
  }, [type]);

  const page =
    type === 'safalBuddyTopLeft'
      ? 'SafalBuddy'
      : type === 'safalRoomPublicSide'
      ? 'SafalRoomPublic'
      : 'SafalRoomPrivate';

  return (
    <div style={{ flex: 'none', width: '140px', backgroundColor: 'gray', borderRadius: '8px', maxHeight: '1000px' }}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <AnalyticsClickImpression data={currentImg} id={currentImg?._id} page={page}>
          <img
            style={{ objectFit: 'cover', borderRadius: '16px', 'pointer-events': 'all' }}
            height="100%"
            width="140px"
            src={currentImg?.value}
            alt="adasd"
          />
        </AnalyticsClickImpression>
      </div>
    </div>
  );
};
export default SideBanner;
