import { Input } from 'antd';
import { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { useSelector } from 'react-redux';

import NewChatMainHeading from './NewChatMainHeading';
import { db } from '../../firebase';
import NewChatSingleMessage from './NewChatSingleMessage';

const { TextArea } = Input;

export default function NewChatMainPanel({ chatId }) {
  const { user } = useSelector((state) => state.login);
  const [messageList, setMessageList] = useState({
    messages: [],
    typing: false,
  });

  console.log('messageList', messageList);

  useEffect(() => {
    if (!chatId) return;
    const dbRef = doc(db, 'chats', chatId);
    const unsubscribe = onSnapshot(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.data();
        setMessageList(data);
      }
    });
    return () => unsubscribe();
  }, [chatId]);

  const onSendMessage = () => {};

  return (
    <div
      style={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        padding: '12px 16px',
      }}
    >
      {/* heading */}
      <NewChatMainHeading />
      <div
        style={{
          // backgroundColor: 'blue',
          flex: '1',
          display: 'flex',
          flexDirection: 'column-reverse',
          overflow: 'auto',
          marginTop: '8px',
          padding: '16px',
          gap: '4px',
        }}
      >
        {[...messageList.messages].reverse().map((e, index) => (
          <NewChatSingleMessage key={index} data={e} isSelf={e.senderId === user._id} />
        ))}
      </div>
      <div style={{ backgroundColor: 'red', flex: 'none' }}>
        <TextArea placeholder="Autosize height based on content lines" autoSize />
      </div>
    </div>
  );
}
