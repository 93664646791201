import { useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { version as uuidVersion, validate as uuidValidate } from 'uuid';
import { Avatar, Button, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import toast from 'react-hot-toast';
import { Image } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';

import {
  addSafalBuddy,
  getBannerList,
  getPrivateRoomInviteCodeInfo,
  getSafalBuddyByOtherUserId,
  rejectPrivateRoomInviteCode,
  updateSafalBuddy,
  updateSafalRoomPrivateOwner,
} from '../../../services/Service';
import useProfileImage from '../../../hooks/useProfileImage';

// http://localhost:8080/safalroom-private-invite?code=0ae2ab1f-d6bd-4f40-9c0d-b6e56756d5fb
// http://localhost:8080/safalroom-private-invite?code=df909be8-ea06-412e-9ac7-201faf6e4d9e
// http://localhost:8080/safalroom-private-invite?code=4311b5c3-7a54-4ce3-875c-b97995241bb6 - exp
// http://localhost:8080/safalroom-private-invite?code=7bf86922-4b95-4acf-bd61-d465e08ce153 - done

const SafalRoomPrivateInvite = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.login);
  const [searchParams, setSearchParams] = useSearchParams();
  const [inviteInfo, setInviteInfo] = useState(null);
  const [roomMeta, setRoomMeta] = useState(null);
  const [bannerList, setBannerList] = useState([]);
  const [inviteError, setInviteError] = useState('');
  const [buddyInfo, setBuddyInfo] = useState(null);

  const inviteCode = searchParams.get('code');
  const isCodeValid = uuidValidateV4(inviteCode);

  const ownerProfile = useProfileImage(inviteInfo?.roomId?.user?.profilePic);

  const fetchInviteData = async (code) => {
    try {
      const res = await getPrivateRoomInviteCodeInfo({ code });
      if (res.data.success === true) {
        setInviteInfo(res.data?.data);
        const resBuddy = await getSafalBuddyByOtherUserId({ otherUserId: res.data?.data?.roomId?.user?._id });
        setBuddyInfo(resBuddy.data.data);

        setRoomMeta(res.data?.meta);
        if (res.data?.data?.valid === false) {
          setInviteError('Your invitation is expired');
        }
      } else {
        setInviteError('Your invitation is not valid');
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
      setInviteError(err?.response?.data?.message || err.message);
    }
  };

  const onRejectInvite = async (code) => {
    try {
      const res = await rejectPrivateRoomInviteCode({
        code: inviteInfo?.code,
        roomId: inviteInfo?.roomId?._id,
        user: inviteInfo?.user?._id,
      });
      if (res.data.success === true) {
        toast.success('Your invitation has been rejected', { duration: 3000 });
        navigate('/dashboard');
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const fetchExtraData = async () => {
    try {
      const res = await getBannerList('safalRoomPublic');
      if (res.status === 200) {
        setBannerList(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onAcceptInvite = async () => {
    try {
      const payload = {
        _id: inviteInfo?.roomId?._id,
        name: inviteInfo?.roomId?.name,
        status: inviteInfo?.roomId?.status,
        buddyList: [
          ...inviteInfo?.roomId?.buddyList,
          {
            buddy: buddyInfo?._id,
            role: 'DEFAULT', // can change to ADMIN, DEFAULT
            status: 'accepted', // pending, accepted, rejected, kick
            newBuddy: true, // for adding new buddy
          },
        ],
        company: inviteInfo?.roomId?.company?._id,
        inviteCode: inviteInfo?.code,
      };

      const res = await updateSafalRoomPrivateOwner(payload);
      if (res.data.success === true) {
        toast.success('Invitation accepted', { duration: 3000 });
        navigate('/dashboard');
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onSendBuddyRequest = async () => {
    try {
      const payloadNew = {
        otherUserId: inviteInfo?.roomId?.user?._id,
        status: 'pending',
      };
      const payload = {
        _id: buddyInfo?._id,
        otherUserId: inviteInfo?.roomId?.user?._id,
        status: 'pending',
      };
      let res = null;
      if (buddyInfo) {
        res = await updateSafalBuddy(payload);
      } else {
        res = await addSafalBuddy(payloadNew);
      }
      if (res.data.success === true) {
        toast.success(
          'Buddy request sent, after accepting it will be added to your buddy list, then you can join this room',
          { duration: 5000 }
        );
        navigate('/dashboard');
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  useEffect(() => {
    fetchExtraData();
    if (isCodeValid) {
      fetchInviteData(inviteCode);
    } else {
      setInviteError('Your invitation is not valid');
    }
  }, []);

  const youThisRoom = isYouAreInThisRoom(user?._id, inviteInfo, buddyInfo?._id);
  //   split banner array into 2
  const banner01 = bannerList.slice(0, Math.round(bannerList.length / 2));
  const banner02 = bannerList.slice(Math.round(bannerList.length / 2), bannerList.length);

  return (
    <>
      <Helmet>
        <title>SafalRoom Private Invitation - SafalSubscriptions</title>
      </Helmet>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '20px',
          gap: '20px',
        }}
      >
        <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
          Private SafalRoom invitation
        </Typography>
        {inviteError && <span style={{ fontSize: '18px', fontWeight: 'bold', color: '#ff4842' }}>{inviteError}</span>}
        {!inviteError && (
          <div
            style={{
              backgroundColor: 'white',
              borderRadius: '12px',
              width: '800px',
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {bannerList.length !== 0 && (
              <div style={{ display: 'flex', gap: '16px', width: 'calc(100% - 16px)' }}>
                <div style={{ position: 'relative', flex: 'none', width: '50%' }}>
                  <Carousel
                    autoPlay
                    infiniteLoop
                    showStatus={false}
                    showArrows
                    showThumbs={false}
                    renderArrowNext={(clickHandler, hasNext, label) => (
                      <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
                    )}
                    renderArrowPrev={(clickHandler, hasPrev, label) => (
                      <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
                    )}
                    // onChange={(i) => localStorage.setItem('banner', i)}
                    showIndicators
                    axis="horizontal"
                  >
                    {banner01.map((e, index) => (
                      <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                        {e.type === 'image' ? (
                          <Image
                            preview={false}
                            style={{ objectFit: 'cover', borderRadius: '16px' }}
                            height="120px"
                            width="100%"
                            src={e.value}
                          />
                        ) : (
                          <video
                            style={{ maxHeight: '120px', objectFit: 'cover', borderRadius: '16px' }}
                            width="100%"
                            height="120px"
                            autoPlay="true"
                            muted
                          >
                            <source src={e.value} />
                          </video>
                        )}
                      </a>
                    ))}
                  </Carousel>
                </div>
                <div style={{ position: 'relative', flex: 'none', width: '50%' }}>
                  <Carousel
                    autoPlay
                    infiniteLoop
                    showStatus={false}
                    showArrows
                    showThumbs={false}
                    renderArrowNext={(clickHandler, hasNext, label) => (
                      <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
                    )}
                    renderArrowPrev={(clickHandler, hasPrev, label) => (
                      <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
                    )}
                    // onChange={(i) => localStorage.setItem('banner', i)}
                    showIndicators
                    axis="horizontal"
                  >
                    {banner02.map((e, index) => (
                      <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                        {e.type === 'image' ? (
                          <Image
                            preview={false}
                            style={{ objectFit: 'cover', borderRadius: '16px' }}
                            height="120px"
                            width="100%"
                            src={e.value}
                          />
                        ) : (
                          <video
                            style={{ maxHeight: '120px', objectFit: 'cover', borderRadius: '16px' }}
                            width="100%"
                            height="120px"
                            autoPlay="true"
                            muted
                          >
                            <source src={e.value} />
                          </video>
                        )}
                      </a>
                    ))}
                  </Carousel>
                </div>
              </div>
            )}
            <div style={{ display: 'flex', marginTop: '32px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 'none',
                  width: '40%',
                  padding: '20px',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ height: '80px', width: '80px' }} alt="Remy Sharp" src={ownerProfile} />
                <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {inviteInfo?.roomId?.user?.firstName} {inviteInfo?.roomId?.user?.lastName} invited you
                </span>
                <span>{inviteInfo?.roomId?.name}</span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  borderLeft: '1px solid #C2BABA',
                  paddingLeft: '20px',
                  gap: '8px',
                }}
              >
                <div style={{ display: 'flex', gap: '16px' }}>
                  <Image
                    preview={false}
                    style={{ objectFit: 'cover', borderRadius: '4px' }}
                    height="60px"
                    src={inviteInfo?.roomId?.company?.logo}
                  />
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{inviteInfo?.roomId?.company?.name}</span>
                    <a href={inviteInfo?.roomId?.company?.website} rel="noreferrer" target="_blank">
                      {inviteInfo?.roomId?.company?.website}
                    </a>
                  </div>
                </div>
                <p>
                  Join us in the room to discuss how subscription models and digital asset services can drive your
                  forward.
                </p>
                <div style={{ display: 'flex', color: '#4F79EA' }}>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: '#4F79EA', fontWeight: '600' }}>Total Feed</span>
                    <span style={{ color: '#001E6F', fontWeight: '700' }}>{roomMeta?.totalFeed} Nos</span>
                  </div>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: '#4F79EA', fontWeight: '600' }}>Total Forum topic</span>
                    <span style={{ color: '#001E6F', fontWeight: '700' }}>{roomMeta?.totalForum} Nos</span>
                  </div>
                </div>
                <div style={{ display: 'flex', color: '#4F79EA' }}>
                  <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: '#4F79EA', fontWeight: '600' }}>Total active polls</span>
                    <span style={{ color: '#001E6F', fontWeight: '700' }}>{roomMeta?.totalPoll} Nos</span>
                  </div>
                  {/* <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <span style={{ color: '#4F79EA', fontWeight: '600' }}>Total Forum topic</span>
                    <span style={{ color: '#001E6F', fontWeight: '700' }}>28 Nos</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '32px', gap: '64px' }}>
              {youThisRoom.isYouAreInThisRoom ? (
                <span>{youThisRoom.message}</span>
              ) : buddyInfo?.status === 'accept' ? (
                <>
                  <Button
                    onClick={onAcceptInvite}
                    sx={{
                      width: '150px',
                      borderRadius: '30px',
                      textTransform: 'capitalize',
                      color: '#FFFFFF',
                      backgroundColor: '#19c25f',
                      '&:hover': { backgroundColor: '#19c25f', color: '#FFFFFF' },
                    }}
                    variant="contained"
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={onRejectInvite}
                    sx={{
                      width: '150px',
                      borderRadius: '30px',
                      textTransform: 'capitalize',
                      backgroundColor: '#ff4842',
                      ':hover': { backgroundColor: '#d40700' },
                    }}
                    variant="contained"
                  >
                    Reject
                  </Button>
                </>
              ) : buddyInfo === null || ['default', 'unBuddy'].includes(buddyInfo?.status) ? (
                <Button
                  onClick={onSendBuddyRequest}
                  sx={{
                    width: '300px',
                    borderRadius: '30px',
                    textTransform: 'capitalize',
                    color: '#FFFFFF',
                    backgroundColor: '#19c25f',
                    '&:hover': { backgroundColor: '#19c25f', color: '#FFFFFF' },
                  }}
                  variant="contained"
                >
                  Send Buddy Request
                </Button>
              ) : (
                <span>You can't join this room, you are not a buddy with the owner of this room.</span>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SafalRoomPrivateInvite;

function uuidValidateV4(uuid) {
  return uuidValidate(uuid) && uuidVersion(uuid) === 4;
}

const RenderArrowNext = ({ clickHandler, hasNext, label }) => {
  return (
    <IconButton
      disabled={!hasNext}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        right: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );
};
const RenderArrowPrev = ({ clickHandler, hasPrev, label }) => {
  return (
    <IconButton
      disabled={!hasPrev}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        left: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
  );
};

const isYouAreInThisRoom = (currentUserId, roomInfo, buddyId) => {
  let message = 'BUDDY';
  let isYouAreInThisRoom = false;

  if (currentUserId === roomInfo?.roomId?.user?._id) {
    isYouAreInThisRoom = true;
    message = 'You are the owner of this room';
  }
  roomInfo?.roomId?.buddyList?.forEach((buddy) => {
    if (buddyId === buddy?._id) {
      isYouAreInThisRoom = true;
      message = 'You are in this room';
    }
  });
  return {
    isYouAreInThisRoom,
    message,
  };
};
