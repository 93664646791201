import { Autocomplete, Button, FormControl, InputLabel, MenuItem, Popper, Select, TextField } from '@mui/material';
import { useState } from 'react';

// const options = [
//   { label: 'options 1', year: 1994 },
//   { label: 'options 2', year: 1972 },
// ];

const CustomSimpleSelect = ({
  sx,
  variant,
  value,
  onChange,
  options,
  valueKey,
  label,
  disabled,
  title,
  onAddNew,
  groupBy,
}) => {
  const [open, setOpen] = useState(false);

  const CustomPopper = (props) => {
    return (
      <Popper {...props} style={{ width: 300 }}>
        {props.children}
        {open && (
          <Button
            variant="contained"
            onMouseDown={() => {
              props?.onAddNew();
              setOpen(false);
            }}
            size="small"
            style={{ margin: '10px', textTransform: 'capitalize', borderRadius: '30px' }}
          >
            Add New
          </Button>
        )}
      </Popper>
    );
  };

  const newOptions = options || [];
  const newValue = newOptions?.find((e) => e?.[valueKey] === value) || '';

  return (
    <Autocomplete
      disabled={disabled}
      size="small"
      variant={variant}
      disablePortal
      options={newOptions}
      title={title}
      sx={sx}
      disableClearable
      onChange={(e, newValue) => {
        if (newValue) onChange && onChange(newValue?.[valueKey]);
      }}
      groupBy={groupBy}
      value={newValue}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => <TextField variant={variant} {...params} label={label} />}
      PopperComponent={onAddNew && ((props) => <CustomPopper {...props} onAddNew={onAddNew} />)}
    />
  );
};

export default CustomSimpleSelect;
