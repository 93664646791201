import moment from 'moment';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Fab,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';
import MomentUtils from '@date-io/moment';
import React, { useEffect, useState } from 'react';
import {
  EditComapnysubsResponse,
  GetCustomCompaniesResponse,
  GetcompaniesResponse,
  SaveCompanyResponse,
  getCompanyType,
} from '../../services/Service';
import SuccessToast from '../Toast/Success';
import { setSubscriptions } from '../../store/subscriptionSlice';
import { setCompanies } from '../../store/companiesSlice';
import ErrorToast from '../Toast/Error';
import { setBusiness, setCustomCompany } from '../../store/businessSlice';
import Scrollbar from '../Scrollbar';
import CustomMuiSelect from '../UI/CustomMuiSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '20px',
  boxShadow: 24,
  // p: "4 0 4 4",
  p: '30px',
};

const AddCompanyModal = ({ openEditModal, setOpenEditModal, data }) => {
  const [image, setImage] = useState();
  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [companyTypeNew, setCompanyTypeNew] = useState('');
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const CompaniesFormSchema = Yup.object().shape({
    name: Yup.string().required('Please enter name'),
    website: Yup.string().required('Please enter website'),
    description: Yup.string().required('Please enter description'),
    companyType: Yup.string().required('Please enter company type'),
    countryCode: Yup.string().required('Please Select country code'),
    price: Yup.number().min(0).typeError('Please enter a valid number'),
  });

  const initialValues = {
    name: '',
    website: '',
    countryCode: '',
    price: '',
    description: '',
    logo: '',
    companyType: '',
    popular: false,
  };

  const handleClose = () => {
    setOpenEditModal(false);
    CompaniesForm.resetForm();
    setCompanyTypeNew('');
    // Reset Asset Check boxes
    setIsChecked({
      isRewardChecked: false,
      isGiftChecked: false,
      isLoyltyChecked: false,
    });
  };

  const CompaniesForm = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: CompaniesFormSchema,
    onSubmit: (values, { resetForm }) => {
      values.updatedBy = user.firstName;

      const companyType = values.companyType === 'Not Listed' ? companyTypeNew : values.companyType;

      // Include checkbox values in the form submission
      const formData = {
        ...values,
        companyType: companyType,
        isLoyltyChecked: isChecked.isLoyltyChecked,
        isGiftChecked: isChecked.isGiftChecked,
        isRewardChecked: isChecked.isRewardChecked,
      };
      console.log('========formData values========>', formData);

      SaveCompanyResponse(formData)
        .then((res) => {
          if (res.data.success === true) {
            handleClose();
            resetForm(initialValues);
            setCompanyTypeNew('');
            GetcompaniesResponse().then((res) => {
              if (res.data.success === true) {
                dispatch(setBusiness({ business: res.data.data }));
                dispatch(setCompanies({ allCompaniesData: res.data.data }));
                SuccessToast('Added Succesfully !!');
                // setIsChecked(false)
              }
            });

            GetCustomCompaniesResponse().then((res) => {
              console.log('this is custome company data ==> ', res.data);
              if (res.data.success === true) {
                dispatch(setCustomCompany({ CustomCopanyData: res.data.data }));
              }
            });
          }
        })
        .catch((err) => {
          ErrorToast(err?.response.data.message || 'Something went wrong !!!');
        });
    },
  });

  const [isChecked, setIsChecked] = useState({
    isRewardChecked: data?.isRewardChecked || false,
    isGiftChecked: data?.isGiftChecked || false,
    isLoyltyChecked: data?.isLoyltyChecked || false,
  });
  const handleCheckBoxChange = (event, name) => {
    setIsChecked((prev) => ({
      ...prev,
      [name]: event.target.checked,
    }));
  };

  const fetchCompanyType = async () => {
    try {
      const res = await getCompanyType();
      setCompanyTypeList(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCompanyType();
    setIsChecked({
      isRewardChecked: data?.isRewardChecked || false,
      isGiftChecked: data?.isGiftChecked || false,
      isLoyltyChecked: data?.isLoyltyChecked || false,
    });
  }, [data]);

  const newCompanyTypeError = companyTypeList.find((e) => e.type.toLowerCase() === companyTypeNew?.toLowerCase());

  return (
    <Modal
      open={openEditModal}
      // onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box
        id="scrollbar"
        sx={{
          ...style,
          height: '75vh',
          paddingBottom: '5rem',
          width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' },
        }}
      >
        {/* <Card sx={{ p: 3, width: '930px' }}> */}
        <Stack direction="row" justifyContent="space-between">
          <Box mb={5}>
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#19C25F' }}>
              Add Company
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ fontSize: '15px', fontWeight: 400 }}>
              Enter details below.
            </Typography>
          </Box>
          <Fab
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
            size="small"
            aria-label="add"
            sx={{ backgroundColor: '#90EE90', color: '#19C25F' }}
          >
            <CloseIcon height={35} />
          </Fab>
        </Stack>
        <Box sx={{ height: '80%', overflow: 'auto' }}>
          <Scrollbar>
            <FormikProvider value={CompaniesForm}>
              <form onSubmit={CompaniesForm.handleSubmit}>
                <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ overflow: 'hidden' }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <FormLabel sx={{ fontSize: '14px', color: '#B6B6B6' }}>
                      logo<span style={{ color: 'red' }}>*</span>
                    </FormLabel>
                    <br />
                    <Input
                      type="file"
                      name="logo"
                      variant="standard"
                      size="small"
                      inputProps={{ accept: 'image/*' }}
                      onChange={(event) => {
                        CompaniesForm.setFieldValue('logo', event.target.files[0]);
                        setImage(event.target.files[0]);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth error={CompaniesForm.touched.name && Boolean(CompaniesForm.errors.name)}>
                      <Field
                        as={TextField}
                        id="input1"
                        name="name"
                        label={
                          <>
                            Company Name<span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        variant="standard"
                        size="small"
                        value={CompaniesForm.values.name}
                        onChange={CompaniesForm.handleChange}
                        error={CompaniesForm.touched.name && Boolean(CompaniesForm.errors.name)}
                        helperText={CompaniesForm.touched.name && CompaniesForm.errors.name}
                      />
                    </FormControl>
                  </Grid>
                  {/* company type */}
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={CompaniesForm.touched.companyType && Boolean(CompaniesForm.errors.companyType)}
                    >
                      <CustomMuiSelect
                        label={
                          <>
                            Company Type<span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        options={[
                          ...companyTypeList?.map((ex) => ({ label: ex.type, name: ex.type })),
                          { label: 'Not Listed', name: 'Not Listed' },
                        ]}
                        error={CompaniesForm.touched.companyType && Boolean(CompaniesForm.errors.companyType)}
                        helperText={CompaniesForm.touched.companyType && CompaniesForm.errors.companyType}
                        onChange={(newValue) => {
                          CompaniesForm.setFieldValue('companyType', newValue?.label || '');
                          CompaniesForm.setFieldTouched('companyType', true);
                        }}
                        value={CompaniesForm.values.companyType}
                        variant="standard"
                        size="small"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={CompaniesForm.touched.website && Boolean(CompaniesForm.errors.website)}
                    >
                      <Field
                        as={TextField}
                        id="input1"
                        name="website"
                        label={
                          <>
                            Website<span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        variant="standard"
                        size="small"
                        value={CompaniesForm.values.website}
                        onChange={CompaniesForm.handleChange}
                        error={CompaniesForm.touched.website && Boolean(CompaniesForm.errors.website)}
                        helperText={CompaniesForm.touched.website && CompaniesForm.errors.website}
                      />
                    </FormControl>
                  </Grid>
                  {/* Not Listed Company type */}
                  {CompaniesForm?.values?.companyType === 'Not Listed' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth error={newCompanyTypeError !== undefined}>
                        <Field
                          as={TextField}
                          label={
                            <>
                              New Company Type<span style={{ color: 'red' }}>*</span>
                            </>
                          }
                          variant="standard"
                          size="small"
                          value={companyTypeNew}
                          onChange={(e) => setCompanyTypeNew(e.target.value)}
                          error={newCompanyTypeError !== undefined}
                          helperText={newCompanyTypeError !== undefined ? 'This Company type already exits.' : ''}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={CompaniesForm.touched.description && Boolean(CompaniesForm.errors.description)}
                    >
                      <Field
                        as={TextField}
                        id="input1"
                        name="description"
                        multiline
                        rows={3}
                        label={
                          <>
                            Description<span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        variant="standard"
                        size="small"
                        value={CompaniesForm.values.description}
                        onChange={CompaniesForm.handleChange}
                        error={CompaniesForm.touched.description && Boolean(CompaniesForm.errors.description)}
                        helperText={CompaniesForm.touched.description && CompaniesForm.errors.description}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={CompaniesForm.touched.countryCode && Boolean(CompaniesForm.errors.countryCode)}
                    >
                      <InputLabel htmlFor="countryCode" sx={{ ml: -2 }}>
                        Country code<span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <Field
                        as={Select}
                        label="countryCode"
                        name="countryCode"
                        // type="number"
                        variant="standard"
                        size="small"
                        value={CompaniesForm.values.countryCode}
                        onChange={CompaniesForm.handleChange}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        sx={{
                          width: '100%',
                          '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                          },
                          '&:after': {
                            borderBottomColor: '#0000',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#0071E3',
                          },
                        }}
                      >
                        <MenuItem
                          key="IND"
                          value="IND"
                          onClick={(e) => {
                            CompaniesForm.setFieldValue('countryCode', e.target.value);
                          }}
                          sx={{ display: 'flex' }}
                        >
                          <img
                            src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
                            alt="USA"
                            height={20}
                            width={20}
                            style={{ display: 'inline' }}
                          />
                          <span style={{ padding: '0 8px' }}> IND </span>
                        </MenuItem>
                        <MenuItem
                          key="USA"
                          value="USA"
                          onClick={(e) => {
                            CompaniesForm.setFieldValue('countryCode', e.target.value);
                          }}
                          sx={{ display: 'flex' }}
                        >
                          <img
                            src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
                            alt="USA"
                            height={20}
                            width={20}
                            style={{ display: 'inline' }}
                          />
                          <span style={{ padding: '0 8px' }}>USA</span>
                        </MenuItem>
                      </Field>
                      {CompaniesForm.touched.countryCode && CompaniesForm.errors.countryCode ? (
                        <FormHelperText>
                          {CompaniesForm.touched.countryCode && CompaniesForm.errors.countryCode}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth error={CompaniesForm.touched.price && Boolean(CompaniesForm.errors.price)}>
                      <InputLabel htmlFor="price">Price</InputLabel>
                      <Field
                        as={Input}
                        label="Price"
                        name="price"
                        type="number"
                        variant="standard"
                        size="small"
                        value={CompaniesForm.values.price}
                        onChange={CompaniesForm.handleChange}
                        startAdornment={
                          <InputAdornment position="end" sx={{ pr: 1 }}>
                            {CompaniesForm.values.countryCode === 'IND'
                              ? '₹'
                              : CompaniesForm.values.countryCode === 'USA'
                              ? '$'
                              : ''}
                          </InputAdornment>
                        }
                      />
                      {CompaniesForm.touched.price && CompaniesForm.errors.price ? (
                        <FormHelperText>{CompaniesForm.touched.price && CompaniesForm.errors.price}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormControl
                      fullWidth
                      error={CompaniesForm.touched.popular && Boolean(CompaniesForm.errors.popular)}
                    >
                      <InputLabel id="select4">Popular </InputLabel>
                      <Field
                        as={Select}
                        labelId="select4"
                        id="select4"
                        name="popular"
                        label="Popular"
                        variant="standard"
                        size="small"
                        value={CompaniesForm.values.popular}
                        onChange={CompaniesForm.handleChange}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Field>
                      {CompaniesForm.touched.popular && CompaniesForm.errors.popular ? (
                        <FormHelperText>{CompaniesForm.touched.popular && CompaniesForm.errors.popular}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={isChecked?.isLoyltyChecked}
                        onChange={(event) => handleCheckBoxChange(event, 'isLoyltyChecked')}
                        color="success"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      {'Applicable for Loyalty card'}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={isChecked?.isGiftChecked}
                        onChange={(event) => handleCheckBoxChange(event, 'isGiftChecked')}
                        color="success"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      {'Applicable for Gift Cards'}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={isChecked?.isRewardChecked}
                        onChange={(event) => handleCheckBoxChange(event, 'isRewardChecked')}
                        color="success"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                      {'Applicable for Reward Certificates'}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} sx={{ marginTop: '70px' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={
                        CompaniesForm?.values?.companyType === 'Not Listed'
                          ? newCompanyTypeError === undefined
                            ? !(CompaniesForm.isValid && CompaniesForm.dirty)
                            : true
                          : !(CompaniesForm.isValid && CompaniesForm.dirty)
                      }
                      sx={{
                        width: '170px',
                        height: '45px',
                        bgcolor: '#19C25F',
                        borderRadius: '30px',
                        // mt: '25px',
                        // ml: '20px',
                        textTransform: 'none',
                        boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                      }}
                    >
                      Create
                    </Button>
                    <Button
                      variant="contained"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClose();
                      }}
                      sx={{
                        width: '170px',
                        height: '45px',
                        backgroundColor: '#19C25F',
                        borderRadius: '30px',
                        // mt: '25px',
                        ml: '8px',
                        boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                        '@media (max-width: 640px)': {
                          ml: '0px',
                        },
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FormikProvider>
          </Scrollbar>
        </Box>
        {/* </Card> */}
      </Box>
    </Modal>
  );
};

export default AddCompanyModal;
