import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Image } from 'antd';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from './ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onAccept, onReject, onOpen }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, newSortData, sortState } = useSortHook(data, ['createdAt']);

  const headData = [
    'Company Logo',
    'Company Name',
    'Room Owner',
    'Room Name',
    'Total Buddies',
    // 'Status',
    'Room created at',
    'Actions',
  ];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: handleSort,
          sortHeadMap: ['', 'company.name', 'user.userID', 'name', 'totalBuddies', 'createdAt', ''],
        }}
        headData={headData}
      >
        {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {newSortData?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <Image
                preview
                style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                src={e?.company?.logo}
                alt="logo"
              />
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.company?.name}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.user.userID}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.name}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.totalBuddies}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <ThreeDotMenu onAccept={() => onAccept(e)} onReject={() => onReject(e)} onOpen={() => onOpen(e)} />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
