import { FormControl, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './SafalGiftOrder.css';

import {
  getSafalGiftMasterInventory,
  getApplicationList,
  getLeaderBoardMasterCountries,
  getSafalGiftOrdersBusiness,
  updateSafalGiftUserOrderBusiness,
  getSignedURL,
  getDownloadSafalGiftOrders,
} from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import SafalGiftOrderModal from './SafalGiftOrderModal';
import DownloadMenu from './DownloadMenu';

const SafalGiftOrder = () => {
  const [searchString, setSearchString] = useState('');
  const [applicationsList, setApplicationsList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [productList, setProductList] = useState([]);

  const [rawData, setRawData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [customerProductModalOpen, setCustomerMProductModalOpen] = useState({
    data: null,
    open: false,
    type: 'customer', // customer, product
  });

  const [selectedStatus, setSelectedStatus] = useState('pending');

  const fetchData = async () => {
    try {
      const res = await getSafalGiftOrdersBusiness();
      if (res.status === 200) {
        setRawData(res.data.data);
        const filterData = res.data.data?.filter((e) => e?.status === selectedStatus);
        setFilterData(filterData);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    }
  };

  const fetchApplicationsList = async () => {
    try {
      const res = await getApplicationList();
      if (res.status === 200) {
        setApplicationsList(res.data.data);
      }
      const res1 = await getLeaderBoardMasterCountries();
      if (res1.status === 200) {
        setCountryList(res1.data.data);
      }
      const res2 = await getSafalGiftMasterInventory({ application: 'SafalSubscriptions' });
      if (res2.status === 200) {
        setProductList(res2.data.data);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    }
  };

  const onSearchChange = (e) => {
    setSearchString(e.target.value);
    const value = e.target.value.toLowerCase();
    if (e.target.value === '') {
      const filterData = rawData?.filter((e) => e?.status === selectedStatus);
      setFilterData(filterData);
    } else {
      const filterData = rawData?.filter((e) => e?.status === selectedStatus);
      const newFilter = filterData.filter((ex) => {
        const cartSnapShort = ex?.cartSnapShort?.map((cs) => cs?.sku)?.join(' ');

        return (
          ex.orderId.toLowerCase().includes(value) ||
          ex.user.userID.toLowerCase().includes(value) ||
          cartSnapShort.toLowerCase().includes(value)
        );
      });
      setFilterData(newFilter);
    }
  };

  useEffect(() => {
    fetchApplicationsList();
    fetchData();
  }, []);

  const onChangeStatusChange = (newStatus) => {
    setSelectedStatus(newStatus);
    const filterData = rawData?.filter((e) => e?.status === newStatus);
    setFilterData(filterData);
  };

  const onAccept = async (e) => {
    try {
      const res = await updateSafalGiftUserOrderBusiness({
        id: e._id,
        status: 'accept', // pending, accept, reject
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const onReject = async (e) => {
    try {
      const res = await updateSafalGiftUserOrderBusiness({
        id: e._id,
        status: 'reject', // pending, accept, reject
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const onCustomer = (data) => {
    setCustomerMProductModalOpen({
      data: data,
      open: true,
      type: 'customer', // customer, product
    });
  };
  const onProduct = (data) => {
    setCustomerMProductModalOpen({
      data: data,
      open: true,
      type: 'product', // customer, product
    });
  };

  const onCustomerModalClose = () => {
    setCustomerMProductModalOpen({
      data: null,
      open: false,
      type: 'customer', // customer, product
    });
  };

  const downloadFile = async (type) => {
    try {
      const res = await getDownloadSafalGiftOrders({ fileType: type, status: selectedStatus });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  return (
    <>
      <Helmet>
        <title>SafalGift Order - SafalSubscriptions</title>
      </Helmet>

      <div id="SafalGiftOrder">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              SafalGift Order
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="SafalGiftOrder-selectedApplication">Order Status</InputLabel>
              <Select
                labelId="SafalGiftOrder-selectedApplication"
                value={selectedStatus}
                label="Order Status"
                onChange={(e) => onChangeStatusChange(e.target.value)}
              >
                <MenuItem value="pending">Submitted Orders</MenuItem>
                <MenuItem value="accept">Accepted Orders</MenuItem>
                <MenuItem value="reject">Rejected Orders</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ width: '200px', ml: '12px' }}
              onChange={onSearchChange}
              value={searchString}
              type="search"
              id="search-input"
              size="small"
              label="Search"
              variant="outlined"
            />
          </div>
          <div className="title-action-div">
            <DownloadMenu downloadFile={downloadFile} />
            {/* <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} /> */}
          </div>
        </div>
        <TableView
          onAccept={onAccept}
          onReject={onReject}
          onCustomer={onCustomer}
          onProduct={onProduct}
          data={filterData}
        />
      </div>
      <SafalGiftOrderModal
        open={customerProductModalOpen.open}
        data={customerProductModalOpen.data}
        type={customerProductModalOpen.type}
        onClose={onCustomerModalClose}
      />
    </>
  );
};

export default SafalGiftOrder;
