import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { GetDownloadsChart, GetUploadsChart } from '../../services/Service';

const NewAssetsLimitChart = ({ data }) => {
  const { user } = useSelector((state) => state.login);

  const assetLimits = data.assetLimits;

  const Options = {
    color: ['#3e71ff', '#01bc56'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    barWidth: 40,
    legend: {},
    grid: {
      left: '3%',
      right: '5%',
      bottom: '3%',
      containLabel: true,
    },
    yAxis: {
      type: 'value',
    },
    xAxis: {
      type: 'category',
      data: ['Loyalty Card', 'Gift Card', 'Reward Certificates'],
    },
    series: [
      {
        name: 'Consumed',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data: [assetLimits?.loyalty?.used || 0, assetLimits?.gift?.used || 0, assetLimits?.reward?.used || 0],
      },
      {
        name: 'Available',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
        },
        emphasis: {
          focus: 'series',
        },
        data: [
          assetLimits.loyalty.isUnlimited ? Infinity : Math.abs(assetLimits.loyalty.limit - assetLimits.loyalty.used),
          assetLimits.gift.isUnlimited ? Infinity : Math.abs(assetLimits.gift.limit - assetLimits.gift.used),
          assetLimits.reward.isUnlimited ? Infinity : Math.abs(assetLimits.reward.limit - assetLimits.reward.used),
        ],
      },
    ],
  };

  const labels = [
    { name: 'Available', color: '#01bc56' },
    { name: 'Consumed', color: '#3e71ff' },
  ];

  return (
    <div style={{ flex: '1', display: 'flex', flexWrap: 'wrap' }}>
      <ReactECharts option={Options} style={{ height: '300px', minWidth: '200px', flex: '1' }} />
      <div
        style={{
          flex: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
        }}
      >
        <span style={{ fontSize: '14px', color: '#3e71ff' }}>Assets Limits</span>
        {labels?.map((e, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
            <p style={{ fontSize: '14px' }}>{e?.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewAssetsLimitChart;
