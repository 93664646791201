import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Container, Typography, Box, Button } from '@mui/material';
// components
import Page from '../../components/Page';
// sections
import { RegisterForm } from '../../sections/auth/register';
import FaqModal from '../../components/Modals/FaqModal';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  background: `url(${'/assets/images/login-background.png'})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',

  backgroundPosition: 'center',
  minHeight: '100%',
  height: '100%',
}));

const HeaderStyle = styled('header')(() => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  padding: '8px 20px',
  justifyContent: 'space-between',
  backgroundColor: '#BCCEFF',
}));

// const SectionStyle = styled(Card)(({ theme }) => ({
//   width: '100%',
//   maxWidth: 464,
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   margin: theme.spacing(2, 0, 2, 2),
// }));

const ContentStyle = styled(Card)(() => ({
  maxWidth: 800,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '35px',
}));

// ----------------------------------------------------------------------

export default function Register() {
  const location = useLocation();

  const navigate = useNavigate();

  const token = localStorage.getItem('Jtoken');

  React.useEffect(() => {
    if (token) {
      navigate('/dashboard', { replace: true });
    }
  }, []);

  return (
    <Page title="Register">
      <RootStyle>
        <HeaderStyle>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src="/assets/images/Logo.png" alt="login" style={{ objectFit: 'contain' }} />
          </Box>
          <Button
            onClick={() => navigate('/login')}
            color="primary"
            variant="contained"
            type="submit"
            sx={{
              width: '115px',
              height: '35px',
              borderRadius: '30px',
              textTransform: 'none',
              backgroundColor: '#3D71FF',
            }}
          >
            Log In
          </Button>
        </HeaderStyle>
        <Container>
          <Box display="inline-block" height={{ xs: '100%', sm: '100%', md: '100vh' }} sx={{ width: '100%' }} py={5}>
            <ContentStyle>
              <Typography variant="h4" sx={{ fontSize: '30px', fontWeight: 400 }}>
                {location.pathname === '/register' ? (
                  <>
                    Get started absolutely <span style={{ color: '#3D71FF', fontWeight: 700 }}>free.</span>{' '}
                  </>
                ) : (
                  <></>
                )}
              </Typography>

              <Typography sx={{ color: 'text.primary', mb: 2, mt: 1, display: 'flex', alignItems: 'center' }}>
                {location.pathname === '/register' ? (
                  <>
                    Enter your details below. <FaqModal marginLeft="4px" color="blue" module="Register" />
                  </>
                ) : (
                  <></>
                )}
              </Typography>

              <RegisterForm />
            </ContentStyle>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
