import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';

const DownloadMenu = ({ downloadFile, toastSuccess }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  const onDownloadClick = async (type) => {
    if (downloadFile) {
      await downloadFile(type);
      console.log('toastSuccess', toastSuccess);
      if (toastSuccess) {
        setTimeout(() => {
          toast.success('Download successful.');
        }, 2000);
      }
    }
    handleClose();
  };

  return (
    <div>
      <Tooltip title="Download">
        <IconButton
          sx={{
            color: '#FFFFFF',
            backgroundColor: '#027c34',
            '&:hover': { backgroundColor: '#027c34', color: '#FFFFFF' },
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          marginTop: '6px',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => onDownloadClick('csv')}>Export as CSV</MenuItem>
        <MenuItem onClick={() => onDownloadClick('txt')}>Export as TXT</MenuItem>
        <MenuItem onClick={() => onDownloadClick('dnl')}>Export as DNL</MenuItem>
        <MenuItem onClick={() => onDownloadClick('pdf')}>Export as PDF</MenuItem>
      </Menu>
    </div>
  );
};

export default DownloadMenu;
