import { deleteDoc, doc, getDoc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { db } from '../../firebase';

const NotificationCount = ({ senderId }) => {
  const [count, setCount] = useState(0);
  const { currentUser } = useSelector((state) => state.auth);
  const { chatMode, user } = useSelector((state) => state.chats);
  const [pic, setPic] = useState(null);

  const handleFunction = async () => {
    const user = await getDoc(doc(db, 'users', localStorage.getItem('fire')));
    setPic(user?.data()?.photoURL);
    // localStorage.setItem('pic', user?.data()?.photoURL);
  };

  useEffect(() => {
    handleFunction();
  });

  const onShowNotificationClicked = (message) => {
    const greeting = new Notification(currentUser?.displayName, {
      body: count === 1 ? `You have a ${message} New Message` : `You have ${message} New Messages`,
      icon: pic,
    });
    greeting.close();
  };

  useEffect(() => {
    try {
      if (currentUser?.uid && senderId && chatMode === 'user') {
        const unsub = onSnapshot(doc(db, `alert`, currentUser?.uid, senderId, senderId), (doc) => {
          if (doc.exists()) {
            if (senderId === user?.uid || senderId === currentUser?.uid) {
              deleteDoc(doc.ref);
              setCount(0);
            } else {
              setCount(doc.data().count);
            }
          } else setCount(0);
        });
        return () => {
          unsub();
        };
      }
      if (chatMode === 'support') {
        const unsub = onSnapshot(doc(db, `alert`, senderId), (doc) => {
          if (doc.exists()) {
            setCount(doc.data().count);
            onShowNotificationClicked();
          } else setCount(0);
        });
        return () => {
          unsub();
        };
      }
    } catch (e) {
      console.log(e);
    }
  }, [currentUser, user, senderId]);

  useEffect(() => {
    onShowNotificationClicked;
  }, [count]);

  return (
    count > 0 && (
      <div className="notification_div">
        <p className="noti_num">{count}</p>
      </div>
    )
  );
};

export default NotificationCount;
