/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import './AssetModuleBusiness.css';
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { getAssetModuleCompany, getAssetModuleCards } from '../../../services/Service';
import AssetModuleLoyaltyGiftReward from './AssetModuleLoyaltyGiftReward';
import AddAssetModuleCompanyModal from './AddAssetModuleCompanyModal';
import AddAssetModuleModal from './AddAssetModuleModal';
import AssetModuleCompany from './AssetModuleCompany';

function AssetModuleBusiness() {
  const [rawCompanyDataBusinessOnly, setRawCompanyDataBusinessOnly] = useState([]);
  const [rawCompanyDataBusinessOnlyFilter, setRawCompanyDataBusinessOnlyFilter] = useState([]);
  const [rawCardData, setRawCardData] = useState([]);
  const [companySearchValue, setCompanySearchValue] = useState('');

  const [cardPaginationOption, setCardPaginationOption] = useState({
    type: 'all',
    page: 1,
    limit: 20,
    total: 0,
    companyId: '',
  });

  const [companyPaginationOption, setCompanyPaginationOption] = useState({
    page: 1,
    limit: 20,
    total: 0,
  });

  const [selectedToggle, setSelectedToggle] = useState('company');
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = useState(false);
  const [companyModalEditData, setCompanyModalEditData] = useState(null);
  const [isAddAssetModalOpen, setIsAddAssetModalOpen] = useState(false);

  const fetchCompanyData = async (page, limit, skip) => {
    try {
      const res2 = await getAssetModuleCompany(true, true, page, limit, skip);
      if (res2.status === 200) {
        setRawCompanyDataBusinessOnly(res2.data.data);
        setRawCompanyDataBusinessOnlyFilter(res2.data.data);
        setCompanySearchValue('');
        setCompanyPaginationOption({
          total: res2.data.totalCount,
          page: page,
          limit: limit,
        });
      }
    } catch (err) {
      console.log('fetchCompanyData error', err.message);
      toast.error(err.message);
    }
  };

  const fetchAssetData = async (page, limit, type, companyId) => {
    try {
      const _type = type === 'all' ? '' : type;
      const res = await getAssetModuleCards(page, limit, _type, companyId);
      if (res.status === 200) {
        setRawCardData(res.data.data);
        setCardPaginationOption({
          total: res.data.totalCount,
          type: type,
          page: page,
          limit: limit,
          companyId: companyId,
        });
      }
    } catch (err) {
      console.log('fetchAssetData error', err.message);
      toast.error(err.message);
    }
  };

  const onRefreshData = async () => {
    toast.success('Refresh complete');
  };

  const onAddCompanyModalOpen = () => {
    setIsAddCompanyModalOpen(true);
  };

  const onAddCompanyModalClose = () => {
    fetchCompanyData();
    setIsAddCompanyModalOpen(false);
    setCompanyModalEditData(null);
  };

  const onAddAssetModalOpen = () => {
    fetchCompanyData();
    setIsAddAssetModalOpen(true);
  };

  const onAddAssetModalClose = () => {
    fetchAssetData(
      cardPaginationOption.page,
      cardPaginationOption.limit,
      cardPaginationOption.type,
      cardPaginationOption.companyId
    );
    setIsAddAssetModalOpen(false);
  };

  const handleChangeToggle = async (e) => {
    const value = e.target.value;
    setSelectedToggle(value);
  };

  const handleChangeAssetModuleType = async (e) => {
    const value = e.target.value;
    fetchAssetData(cardPaginationOption.page, cardPaginationOption.limit, value, cardPaginationOption.companyId);
  };

  const onCompanyEdit = (data) => {
    setCompanyModalEditData(data);
    onAddCompanyModalOpen();
  };

  const onCompanySearchChange = (e) => {
    setCompanySearchValue(e.target.value);
    const value = e.target.value.toLowerCase().trim();
    if (value === '') {
      setRawCompanyDataBusinessOnlyFilter(rawCompanyDataBusinessOnly);
      return;
    }
    const res = rawCompanyDataBusinessOnly.filter(
      (ex) => ex?.name.toLowerCase().includes(value) || ex?.type.toLowerCase().includes(value)
    );
    setRawCompanyDataBusinessOnlyFilter(res);
  };

  return (
    <>
      <div id="AssetModuleBusiness">
        <div className="action-bar">
          <h2 style={{ color: '#017c33' }}>Asset Module</h2>
        </div>
        <div className="select-bar">
          <div className="select-bar-toggle">
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={selectedToggle}
              exclusive
              onChange={handleChangeToggle}
              aria-label="Asset Module"
            >
              <ToggleButton sx={{ px: '12px', textTransform: 'capitalize' }} value="company">
                Company
              </ToggleButton>
              <ToggleButton sx={{ px: '12px', textTransform: 'capitalize' }} value="asset">
                Loyalty cards, Gift cards, Reward certificates
              </ToggleButton>
            </ToggleButtonGroup>
            {selectedToggle === 'asset' && (
              <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
                <InputLabel id="asset-module-type-label">Type</InputLabel>
                <Select
                  labelId="asset-module-type-label"
                  id="asset-module-type"
                  value={cardPaginationOption.type}
                  label="Type"
                  onChange={handleChangeAssetModuleType}
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'loyalty'}>Loyalty Card</MenuItem>
                  <MenuItem value={'gift'}>Gift Card</MenuItem>
                  <MenuItem value={'reward'}>Reward Certificates</MenuItem>
                </Select>
              </FormControl>
            )}
            {selectedToggle === 'company' && (
              <TextField
                sx={{ width: '200px', ml: '12px' }}
                onChange={onCompanySearchChange}
                value={companySearchValue}
                type="search"
                id="search-input"
                size="small"
                label="Search"
                variant="outlined"
              />
            )}
          </div>
          <div className="select-bar-btn">
            <Button
              sx={{ backgroundColor: '#19c25f' }}
              onClick={selectedToggle === 'asset' ? onAddAssetModalOpen : onAddCompanyModalOpen}
              variant="contained"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
            {/* <IconButton onClick={onRefreshData} style={{ marginLeft: '10px' }} aria-label="delete">
              <RefreshIcon />
            </IconButton> */}
          </div>
        </div>
        {selectedToggle === 'asset' ? (
          <AssetModuleLoyaltyGiftReward
            fetchData={fetchAssetData}
            data={rawCardData}
            paginationOption={cardPaginationOption}
          />
        ) : (
          <AssetModuleCompany
            onEdit={onCompanyEdit}
            fetchData={fetchCompanyData}
            data={rawCompanyDataBusinessOnlyFilter}
            paginationOption={companyPaginationOption}
          />
        )}
      </div>
      <AddAssetModuleCompanyModal
        open={isAddCompanyModalOpen}
        onClose={onAddCompanyModalClose}
        data={companyModalEditData}
      />
      <AddAssetModuleModal open={isAddAssetModalOpen} onClose={onAddAssetModalClose} />
    </>
  );
}

export default AssetModuleBusiness;
