import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactSpeedometer from 'react-d3-speedometer';
import dimg8 from '../../assets/dashboard8.png';
import total_download1 from '../../assets/total_download1.png';
import total_upload1 from '../../assets/total_upload1.png';

import { GetDownloadsChart, GetUploadsChart } from '../../services/Service';
import CustomNewDashboardBoxUnlimited from '../../components/UI/CustomNewDashboardBoxUnlimited';

const NewDownloadsUploadsChart = () => {
  const [downloads, setDownloads] = useState({
    downloads: 0,
    limit: 0,
    max: 0,
  });

  const [uploads, setUploads] = useState({
    uploads: 0,
    limit: 0,
    max: 0,
  });

  useEffect(() => {
    GetUploadsChart().then((res) => {
      if (res.data.success === true) {
        setUploads({
          uploads: res.data.data.uploads,
          limit: res.data.data.limit,
          max: res.data.data.max,
        });
      }
    });
    GetDownloadsChart().then((res) => {
      if (res.data.success === true) {
        setDownloads({
          downloads: res.data.data.downloads,
          limit: res.data.data.limit,
          max: res.data.data.max,
        });
      }
    });
  }, []);

  const labelsUpload = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];
  const labelsDownload = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];

  const isUnlimitedDownload = downloads.max === null && downloads.limit === null;
  const isUnlimitedUpload = uploads.max === null && uploads.limit === null;

  return (
    <>
      {isUnlimitedUpload ? (
        <CustomNewDashboardBoxUnlimited
          maxWidth="350px"
          minWidth="320px"
          color="#0092a7"
          img={total_upload1}
          title="Total Upload"
          leftTitle="Plan"
          leftValue="Unlimited"
          rightTitle="Actual"
          rightValue={uploads.uploads}
          // onClick={navigateSubHandler}
        />
      ) : (
        <div
          style={{
            width: '50%',
            flex: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold' }}>Upload</span>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReactSpeedometer
              forceRender
              maxSegmentLabels={1}
              customSegmentStops={[0, downloads?.downloads || 0, downloads?.max || 0]}
              segmentColors={['#3e71ff', '#01bc56']}
              needleColor={'#3e71ff'}
              currentValueText={`${((downloads?.downloads / downloads?.max) * 100).toFixed(2) || 0} %`}
              minValue={0}
              maxValue={downloads?.max || 0}
              value={downloads?.downloads || 0}
              textColor="#3e71ff"
              ringWidth={30}
              height={180}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            {labelsDownload?.map((e, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
                <span style={{ fontSize: '14px' }}>{e?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {isUnlimitedDownload ? (
        <CustomNewDashboardBoxUnlimited
          color="#f22f75"
          maxWidth="350px"
          minWidth="320px"
          img={total_download1}
          title="Total Download"
          leftTitle="Plan"
          leftValue="Unlimited"
          rightTitle="Actual"
          rightValue={downloads.downloads}
          // onClick={navigateSubHandler}
        />
      ) : (
        <div
          style={{
            width: '50%',
            flex: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold' }}>Download</span>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReactSpeedometer
              forceRender
              maxSegmentLabels={1}
              customSegmentStops={[0, uploads?.uploads || 0, uploads?.max || 0]}
              segmentColors={['#3e71ff', '#01bc56']}
              needleColor={'#3e71ff'}
              currentValueText={`${((uploads?.uploads / uploads?.max) * 100).toFixed(2) || 0} %`}
              minValue={0}
              maxValue={uploads?.max || 0}
              value={uploads?.uploads || 0}
              textColor="#3e71ff"
              ringWidth={30}
              height={180}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            {labelsUpload?.map((e, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
                <span style={{ fontSize: '14px' }}>{e?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default NewDownloadsUploadsChart;
