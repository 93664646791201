import { Box, Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import ReactSpeedometer from 'react-d3-speedometer';
import dimg8 from '../../assets/dashboard8.png';
import total_text1 from '../../assets/total_text1.png';
import total_email1 from '../../assets/total_email1.png';

import { GetEmailsChart, GetSMSChart } from '../../services/Service';
import CustomNewDashboardBoxUnlimited from '../../components/UI/CustomNewDashboardBoxUnlimited';

const NewCommunicationChart = () => {
  const [textSMS, setTextSMS] = useState({
    sent: 0,
    available: 0,
    max: 0,
  });
  const [emails, setEmails] = useState({
    sent: 0,
    available: 0,
    max: 0,
  });

  useEffect(() => {
    GetSMSChart().then((res) => {
      if (res.data.success === true) {
        console.log('chart data ==> ', res.data);
        setTextSMS({
          sent: res.data.data.sent,
          available: res.data.data.available,
          max: res.data.data.max,
        });
      }
    });

    GetEmailsChart().then((res) => {
      if (res.data.success === true) {
        console.log('chart data EMAil ==> ', res.data);
        setEmails({
          sent: res.data.data.sent,
          available: res.data.data.available,
          max: res.data.data.max,
        });
      }
    });
  }, []);

  const labelsCalls = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];
  const labelsEmail = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];

  const isUnlimitedText = textSMS.max === null && textSMS.available === null;
  const isUnlimitedEmail = emails.max === null && emails.available === null;

  return (
    <>
      {isUnlimitedText ? (
        <CustomNewDashboardBoxUnlimited
          maxWidth="350px"
          minWidth="320px"
          color="#8f0304"
          img={total_text1}
          title="Total Text messages"
          leftTitle="Plan"
          leftValue="Unlimited"
          rightTitle="Actual"
          rightValue={textSMS.sent}
          // onClick={navigateSubHandler}
        />
      ) : (
        <div
          style={{
            width: '50%',
            flex: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold' }}>Text Messages</span>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReactSpeedometer
              forceRender
              maxSegmentLabels={1}
              customSegmentStops={[0, textSMS?.sent || 0, textSMS?.max || 0]}
              segmentColors={['#3e71ff', '#01bc56']}
              needleColor={'#3e71ff'}
              currentValueText={`${((textSMS?.sent / textSMS.max) * 100).toFixed(2) || 0} %`}
              minValue={0}
              maxValue={textSMS?.max || 0}
              value={textSMS?.sent || 0}
              textColor="#3e71ff"
              ringWidth={30}
              height={180}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            {labelsCalls?.map((e, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
                <span style={{ fontSize: '14px' }}>{e?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {isUnlimitedEmail ? (
        <CustomNewDashboardBoxUnlimited
          maxWidth="350px"
          minWidth="320px"
          color="#93b0fe"
          img={total_email1}
          title="Total Email"
          leftTitle="Plan"
          leftValue="Unlimited"
          rightTitle="Actual"
          rightValue={emails.sent}
          // onClick={navigateSubHandler}
        />
      ) : (
        <div
          style={{
            width: '50%',
            flex: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold' }}>Email</span>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReactSpeedometer
              forceRender
              maxSegmentLabels={1}
              customSegmentStops={[0, emails?.sent || 0, emails?.max || 0]}
              segmentColors={['#3e71ff', '#01bc56']}
              needleColor={'#3e71ff'}
              currentValueText={`${((emails?.sent / emails.max) * 100).toFixed(2) || 0} %`}
              minValue={0}
              maxValue={emails?.max || 0}
              value={emails?.sent || 0}
              textColor="#3e71ff"
              ringWidth={30}
              height={180}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            {labelsEmail?.map((e, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
                <span style={{ fontSize: '14px' }}>{e?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default NewCommunicationChart;
