import { Autocomplete, Box, Button, ClickAwayListener, Paper, Popper, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

// This is only use in asset
const CustomSelectWithImage = ({
  options,
  value,
  onChange,
  id,
  name,
  onCustomClick,
  label,
  disabled,
  helperText,
  companyIdError,
  setCompanyIdError,
  disablePortal,
  className,
}) => {
  const _disablePortal = disablePortal === undefined ? true : disablePortal;
  const [open, setOpen] = useState(false);

  const CustomPopper = (props) => {
    return (
      <Popper {...props} style={{ width: 300 }}>
        {props.children}
        {open && (
          <Button
            variant="contained"
            onMouseDown={() => {
              props?.onAddNew();
              setOpen(false);
            }}
            size="small"
            style={{ margin: '10px', textTransform: 'capitalize', borderRadius: '30px' }}
          >
            Add New
          </Button>
        )}
      </Popper>
    );
  };

  const newOptions = options?.map((ex) => ({
    label: ex?.companyId?.name ? `${ex?.companyId?.name || ''} - ${ex?.name}` : `${ex?.name}`,
    id: ex?._id,
    logo: ex?.companyId?.logo,
  }));

  const newFind = options?.find((ex) => ex?._id === value);
  const newValue = newFind
    ? newFind?.companyId?.name
      ? `${newFind?.companyId?.name || ''} - ${newFind?.name}`
      : `${newFind?.name}`
    : '';

  const renderImage = (props, option) => {
    const { key, ...optionProps } = props;

    return (
      <Box key={key} component="li" {...optionProps} sx={{ '& > img': { mr: 2, flexShrink: 0 } }}>
        <img loading="lazy" width="20px" height="20px" style={{ objectFit: 'cover' }} src={option?.logo} alt="" />
        {option?.label}
      </Box>
    );
  };

  return (
    <Autocomplete
      id={id}
      name={name}
      // isOptionEqualToValue={isOptionEqualToValue}
      className={className}
      // title={title}
      variant="standard" // standard
      size="small" // small
      disablePortal={_disablePortal}
      value={newValue}
      disabled={disabled}
      onChange={(event, newValue) => {
        onChange && onChange(newValue?.id);
      }}
      options={newOptions || []}
      // sx={sx}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      // focused={focused}
      PopperComponent={onCustomClick && ((props) => <CustomPopper {...props} onAddNew={onCustomClick} />)}
      renderInput={(params) => (
        <TextField
          {...params}
          // placeholder={placeholder}
          // focused={focused}
          // title={title}
          size="small"
          variant="standard"
          error={companyIdError}
          helperText={helperText}
          label={label}
        />
      )}
      renderOption={renderImage}
    />
  );
};

export default CustomSelectWithImage;
