import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Image } from 'antd';

import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import CustomRating from '../../components/UI/CustomRating';
import CustomMuiSelect from '../../components/UI/CustomMuiSelect';

const SubscriptionTrendFilterMenu = ({ state, onFilterApply, onFilterReset, companyData }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [filterState, setFilterState] = useState({
    companyType: '',
    companyName: '',
    rating: '',
    safal: '',
  });
  const [companyType, setCompanyType] = useState([]);
  const [companyName, setCompanyName] = useState([]);

  const ratingList = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
    setShowApply(false);
  };

  useEffect(() => {
    if (open) {
      setFilterState(state);
    }
  }, [open]);

  const handleFilterApply = () => {
    onFilterApply(filterState);
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterApply({
      companyType: '',
      companyName: '',
      rating: '',
      safal: '',
    });
    setCompanyName([]);
    handleClose();
  };

  // Update resetState whenever the state changes
  useEffect(() => {
    setShowApply(isThisResetState);
  }, [state]);

  const isThisResetState =
    state?.companyType !== '' || state?.companyName !== '' || state?.safal !== '' || state.rating !== '';

  // State to store the boolean value
  const [showApply, setShowApply] = useState(isThisResetState);

  useEffect(() => {
    const companyType = [];
    companyData.forEach((ex) => {
      if (!companyType.includes(ex.companyType)) companyType.push(ex.companyType);
    });
    setCompanyType(companyType);
  }, [companyData]);

  useEffect(() => {
    const ssadas = companyData.filter((ex) => ex.companyType === filterState.companyType);
    setCompanyName(ssadas);
  }, [filterState.companyType]);

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '300px',
            padding: '0px 8px',
          },
        }}
      >
        <h3>Subscriptions Trends Filter</h3>
        {/* companyType */}
        <CustomMuiSelect
          disablePortal={false}
          value={filterState.companyType}
          focused={filterState.companyType}
          onChange={(e) => onChangeField(e.label, 'companyType')}
          sx={{
            my: '8px',
          }}
          label="Company Type"
          size="small"
          variant="outlined"
          title={filterState.companyType}
          options={companyType.map((e) => ({ label: e }))}
        />
        {/* companyName */}
        <CustomMuiSelect
          disablePortal={false}
          value={filterState?.companyName}
          focused={filterState?.companyName}
          onChange={(e) => onChangeField(e.label, 'companyName')}
          sx={{
            my: '8px',
          }}
          label="Company Name"
          size="small"
          variant="outlined"
          title={filterState.companyName}
          options={companyName.map((e) => ({ label: e.companyName }))}
        />
        {/* rating */}
        <TextField
          value={filterState?.rating}
          focused={filterState?.rating}
          // onChange={(e) => onChangeField(e.target.value, 'rating')}
          sx={{
            my: '8px',
          }}
          name="rating"
          select
          label="Rating"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem
            sx={{
              backgroundColor: 'white !important',
              '& :hover': {
                backgroundColor: 'white !important',
              },
            }}
            value={filterState?.rating}
          >
            <CustomRating
              onChange={(e) => onChangeField(e.target.value, 'rating')}
              value={filterState?.rating}
              precision={0.5}
              size="normal"
            />
          </MenuItem>
        </TextField>
        {/* safal */}
        <TextField
          value={filterState?.safal}
          focused={filterState?.safal !== ''}
          onChange={(e) => onChangeField(e.target.value, 'safal')}
          sx={{
            my: '8px',
          }}
          name="safal"
          select
          label="Safal"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem value={1}>
            <span style={{ marginRight: 'auto' }}>Positive Safal</span>
            <Image src={likeImage} height={40} preview={false} />
          </MenuItem>
          <MenuItem value={0}>
            <span style={{ marginRight: 'auto' }}>Neutral Safal</span>
            <Image src={likeDislikeImage} height={40} preview={false} />
          </MenuItem>
          <MenuItem value={-1}>
            <span style={{ marginRight: 'auto' }}>Negative Safal</span>
            <Image src={disLikeImage} height={40} preview={false} />
          </MenuItem>
        </TextField>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default SubscriptionTrendFilterMenu;
