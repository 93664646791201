import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import DateField from '../../../components/UI/DateField';
import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';

const FeedFilterMenu = ({ onFilterReset, filterState, onChangeField, users }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const [filterStateLocal, setFilterStateLocal] = useState({
    userId: '',
    toDate: '',
    fromDate: '',
    search: '',
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterReset = () => {
    onFilterReset();
    setFilterStateLocal({
      userId: '',
      toDate: '',
      fromDate: '',
      search: '',
    });
    handleClose();
  };

  const handleFilterApply = () => {
    onChangeField(filterStateLocal.search, 'search');
    onChangeField(filterStateLocal.userId, 'userId');
    onChangeField(filterStateLocal.toDate, 'toDate');
    onChangeField(filterStateLocal.fromDate, 'fromDate');
  };

  const isThisResetState =
    filterState?.userId !== '' ||
    filterState?.toDate !== '' ||
    filterState?.fromDate !== '' ||
    filterState?.search !== '';

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          size="small"
          onClick={handleClick}
          sx={{
            position: 'absolute',
            display: 'flex',
            zIndex: '200',
            top: '35px',
            right: '150px',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '300px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        {/* Search */}
        <TextField
          style={{ marginBottom: '8px' }}
          value={filterStateLocal?.search}
          onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, search: e.target.value }))}
          label="Search"
          size="small"
          fullWidth
          variant="outlined"
        />
        {/* users */}
        <CustomSimpleSelect
          // sx={{
          //   width: '220px',
          // }}
          variant="outlined"
          label="Select User"
          valueKey="id"
          value={filterStateLocal?.userId}
          onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, userId: e }))}
          options={users?.map((e) => ({ label: login.user?._id === e?.id ? 'You' : e?.name, id: e?.id }))}
        />
        {/* frequency */}
        {/* <TextField
          value={filterState?.frequency}
          focused={filterState?.frequency}
          onChange={(e) => onChangeField(e.target.value, 'frequency')}
          sx={{
            my: '8px',
          }}
          name="frequency"
          select
          label="Frequency"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Trial">Trial</MenuItem>
          <MenuItem value="Monthly">Monthly</MenuItem>
          <MenuItem value="Annually">Annually</MenuItem>
        </TextField> */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '8px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Date
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <DateField
              value={filterStateLocal.fromDate}
              fullWidth
              variant="outlined"
              label="From"
              onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, fromDate: e.toISOString() }))}
            />
            <DateField
              value={filterStateLocal.toDate}
              fullWidth
              variant="outlined"
              label="To"
              onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, toDate: e.toISOString() }))}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Close
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default FeedFilterMenu;
