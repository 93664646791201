import { useEffect, useState } from 'react';
import { Button, FormControl, MenuItem, Radio, Select } from '@mui/material';
import toast from 'react-hot-toast';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';

import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';
import { getSafalRoomPrivatePullUser, updateSafalRoomPrivatePullUser } from '../../../services/Service';
import AddPollsModal from './AddPollsModal';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const SafalRoomPolls = ({ companyId, roomInfo, myRole }) => {
  const [loading, setLoading] = useState(false);
  const [pollList, setPollList] = useState([]);
  const [poll, setPoll] = useState(null);
  const [selectedPollValue, setSelectedPollValue] = useState('');
  const [selectedBusinessStatus, setSelectedBusinessStatus] = useState('approved');
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null,
  });
  const { timeFromNow2 } = useUserTimeZone();
  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getSafalRoomPrivatePullUser({ company: companyId, roomId: roomInfo?._id });
      if (res.status === 200) {
        setPollList(res.data.data);
        if (res?.data?.data?.length > 0) {
          setPoll(res?.data?.data[0]);
          const youVote = res?.data?.data[0]?.options?.find((ex) => ex?.youVote === true);
          if (youVote) {
            setSelectedPollValue(youVote?.option);
          } else {
            setSelectedPollValue('');
          }
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };
  const updateData = async (index) => {
    try {
      setLoading(true);
      const res = await updateSafalRoomPrivatePullUser({ _id: poll?._id, optionsIndex: index });
      if (res.status === 200) {
        setPollList((prev) => {
          const newPrev = [...prev];
          const findIndex = newPrev.findIndex((e) => e?._id === poll?._id);
          if (findIndex !== -1) {
            newPrev[findIndex] = res.data.data;
          }
          return newPrev;
        });
        setPoll(res.data.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePollChange = (value, index) => {
    updateData(index);
    setSelectedPollValue(value);
  };

  const onChangeBusinessStatus = (value) => {
    setSelectedBusinessStatus(value);
    // let filterX = [];
    // if (value === 'approved') {
    //   filterX = forumList.filter((e) => e?.businessStatus === value);
    // } else {
    //   filterX = forumList.filter((e) => e?.businessStatus === value && e?.user?._id === user?._id);
    // }
    // setForumFilterList(filterX);
  };

  const approvedCount = 0;
  const rejectedCount = 0;
  const pendingCount = 0;

  const onOpenForumModal = () => {
    setOpenModal({
      open: true,
      data: null,
    });
  };
  const onCloseForumModal = () => {
    setOpenModal({
      open: false,
      data: null,
    });
    fetchData();
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px',
          gap: '8px',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          {/* <FormControl size="small" sx={{ width: '200px' }}>
          <Select size="small" value={selectedBusinessStatus} onChange={(e) => onChangeBusinessStatus(e.target.value)}>
            <MenuItem value="approved">Approved ({approvedCount})</MenuItem>
            <MenuItem value="rejected">Rejected ({rejectedCount})</MenuItem>
            <MenuItem value="pending">Pending ({pendingCount})</MenuItem>
          </Select>
        </FormControl> */}
          <div />
          <Button
            onClick={onOpenForumModal}
            size="small"
            sx={{
              width: '160px',
              borderRadius: '30px',
              color: '#FFFFFF',
              backgroundColor: '#3D71FF',
              '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
            }}
            title="Create New Community Forum"
            startIcon={<AddIcon />}
            variant="contained"
          >
            Create Poll
          </Button>
        </div>
        {pollList.length > 0 && (
          <CustomSimpleSelect
            valueKey="_id"
            value={poll?._id || ''}
            onChange={(e) => {
              if (e) {
                const findPoll = pollList?.find((ex) => ex?._id === e);
                setPoll(findPoll);
                const youVote = findPoll?.options?.find((ex) => ex?.youVote === true);
                if (youVote) {
                  setSelectedPollValue(youVote?.option);
                } else {
                  setSelectedPollValue('');
                }
              }
            }}
            label="Search | Select Polls"
            options={pollList?.map((e) => ({ label: e?.name, ...e }))}
          />
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', lineHeight: 'normal', fontSize: '16px' }}>
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{poll?.question}</span>
          {/* <p style={{ fontSize: '14px' }}>{poll?.question}</p> */}
        </div>
        {loading && <div className="unique-loader-line" />}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          {poll?.options?.map((e, index) => {
            const youVote = poll?.options?.find((ex) => ex?.youVote === true);
            const expiry = poll?.expiry ? moment(poll?.expiry).isBefore(moment(), 'day') : false;
            return (
              <PollBar
                onChange={(e) => handlePollChange(e, index)}
                value={selectedPollValue}
                name={poll?._id}
                data={e}
                key={index}
                youVote={expiry}
                show={youVote}
                // youVote={expiry ? true : youVote !== undefined}
              />
            );
          })}
        </div>
        {poll?.expiry && (
          <span style={{ marginLeft: 'auto', fontStyle: 'italic' }}>expiry {timeFromNow2(poll?.expiry)}</span>
        )}
        {pollList.length === 0 && (
          <div style={{ display: 'flex', height: '100px', alignItems: 'center', justifyContent: 'center' }}>
            <span>No polls found.</span>
          </div>
        )}
      </div>
      <AddPollsModal
        myRole={myRole}
        open={openModal.open}
        onClose={onCloseForumModal}
        data={openModal.data}
        companyId={companyId}
        roomId={roomInfo?._id}
      />
    </>
  );
};
export default SafalRoomPolls;

const PollBar = ({ data, value, youVote, name, onChange, show }) => {
  return (
    <label
      htmlFor={data?.option}
      style={{
        border: '2px solid #d9e3ff',
        backgroundColor: 'transparent',
        borderRadius: '16px',
        display: 'flex',
        alignItems: 'center',
        padding: '2px 12px',
        fontSize: '16px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {show && (
        <div
          style={{
            backgroundColor: '#d9e3ff',
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: `${data?.percentage}%`,
          }}
        />
      )}
      <Radio
        disabled={youVote}
        id={data?.option}
        checked={value === data?.option}
        onChange={(e) => onChange(e.target.value)}
        value={data?.option}
        size="small"
        name={name}
      />
      <span style={{ flex: 1, zIndex: 1 }}>{data?.option}</span>
      {show && (
        <span style={{ flex: 'none', zIndex: 1 }}>
          {data?.percentage}% {data?.count !== 0 ? `| ${data?.count}` : ''}
        </span>
      )}
    </label>
  );
};
