import { Button, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './BannerList.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { getBannerList, deleteBanner } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddBannerModal from './AddBannerModal';

const BannerList = () => {
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getBannerList('all');
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('getBannerList fetchData error', err.message);
    }
  };

  const onAddModalOpen = () => {
    setAddModalOpen(true);
  };
  const onAddModalClose = () => {
    setAddModalOpen(false);
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteBanner(data._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Banner List - SafalSubscriptions</title>
      </Helmet>

      <div id="BannerList">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Banner List
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          onDelete={(data) => {
            setIsDeleteConfirmationModalOpen({
              open: true,
              data,
            });
          }}
          onEdit={() => {}}
          data={rawData}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddBannerModal open={addModalOpen} onClose={onAddModalClose} />
    </>
  );
};

export default BannerList;
