/* eslint-disable no-plusplus */
import { Box, Button, Fab, IconButton, Modal, Tab, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import CloseIcon from '@mui/icons-material/Close';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useEffect, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import './HeySafalSpeechRecognition.css';
import InputBox from './InputBox';
import ChatMessageX from './ChatMessageX';
import { safalAiList } from '../../services/Service';
import HeySafalSpeechRecognitionGPT from './HeySafalSpeechRecognitionGPT';
import aiChatLogo from '../../assets/ai-chat-logo.png';
import useUserTimeZone from '../../hooks/useUserTimeZone';
import FaqModal from '../Modals/FaqModal';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '98vh',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  '@media (max-width: 640px)': {
    width: '95vw',
    // height: '95vh',
    overflow: 'auto',
  },
  '@media (max-width: 1000px)': {
    width: '95vw',
    // height: '95vh',
    overflow: 'auto',
  },
};

const formatDateInterval = (arrx, formatDate) => {
  let lastDate = '';
  const arr = [...arrx];
  for (let index = arr.length - 1; index >= 0; index--) {
    const element = arr[index];
    if (formatDate(element.createdAt) !== lastDate) {
      element.isDate = true;
      lastDate = formatDate(element.createdAt);
    }
  }
  return arr;
};

const HeySafalSpeechRecognition = ({ onClose, mr, type }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const { formatDate } = useUserTimeZone();
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mainChatArray, setMainChatArray] = useState([]);
  const [tabValue, setTabValue] = useState('1');

  const handleChangeTab = (newValue) => {
    setTabValue(newValue);
  };

  const onModalOpen = async () => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setIsModalOpen(true);
      fetchChatList();
    }
  };

  const onModalClose = () => {
    resetTranscript();
    onClose();
    setIsModalOpen(false);
  };

  const fetchChatList = async () => {
    try {
      const res = await safalAiList({ type: type });
      setMainChatArray(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchChatList();
  }, []);

  if (!browserSupportsSpeechRecognition) {
    return <></>;
  }

  const formatDateIntervalChatArray = formatDateInterval(mainChatArray, formatDate);

  return (
    <>
      <Tooltip title="Safal AI">
        <IconButton
          onClick={onModalOpen}
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: mr,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
            p: 0,
          }}
        >
          <img height="40px" width="40px" src={aiChatLogo} alt="safal AI" />
        </IconButton>
      </Tooltip>
      <Modal
        open={isModalOpen}
        onClose={onModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <div
            style={{
              backgroundColor: '#bcceff',
              height: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '8px',
              flex: 'none',
            }}
          >
            <button
              onClick={onModalClose}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '6px',
                right: '6px',
                width: '28px',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '99999px',
                border: 'none',
                backgroundColor: '#3d71ff',
              }}
            >
              <CloseIcon sx={{ width: '14px', height: '14px', color: 'white' }} />
            </button>
            <span style={{ color: '#3d71ff', fontSize: '18px', display: 'flex', alignItems: 'center' }}>
              Safal AI <FaqModal module="SafalSubscriptions AI" color="#3D71FF" />
            </span>
          </div>
          <div style={{ margin: '0px 16px', height: '60px', display: 'flex', flex: 'none' }}>
            <button
              onClick={() => handleChangeTab('1')}
              className={`new-head-tab-right ${tabValue === '1' && 'new-head-tab-active'}`}
            >
              SafalSubscriptions AI
            </button>
            <button
              onClick={() => handleChangeTab('2')}
              className={`new-head-tab-left ${tabValue === '2' && 'new-head-tab-active-1'}`}
            >
              SafalAIGPT
            </button>
          </div>
          {tabValue === '1' && (
            <div id="HeySafalSpeechRecognition">
              <div className="ai-chat">
                {formatDateIntervalChatArray.map((e, index) => (
                  <ChatMessageX type={type} fetchChatList={fetchChatList} data={e} key={index} isLast={index === 0} />
                ))}
              </div>
              <InputBox
                type={type}
                lastMessage={mainChatArray.length > 0 ? mainChatArray[0]?.message : ''}
                fetchChatList={fetchChatList}
                listening={listening}
                transcript={transcript}
                speechRecognition={SpeechRecognition}
                resetTranscript={resetTranscript}
              />
              {/* <p className="transcript">{transcript}</p> */}
            </div>
          )}
          <HeySafalSpeechRecognitionGPT tabValue={tabValue} />
        </Box>
      </Modal>
    </>
  );
};
export default HeySafalSpeechRecognition;
