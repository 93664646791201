// eslint-disable no-unreachable
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';
import { ClickAwayListener } from '@mui/base';
import { useDispatch, useSelector } from 'react-redux';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';
import MomentUtils from '@date-io/moment';
import {
  SavesubsResponse,
  FilterResponse,
  GetsubsResponse,
  EditsubsResponse,
  getSubLabel,
} from '../../services/Service';
import SuccessToast from '../Toast/Success';
import { setSubscriptions } from '../../store/subscriptionSlice';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import { getProfile } from '../../store/loginSlice';
import ErrorToast from '../Toast/Error';
import Inlineloader from '../Loader/InlineLoader';
import CustomRating from '../UI/CustomRating';
import CustomMuiSelect from '../UI/CustomMuiSelect';
import AddCustomLabel from '../Modals/AddCustomLabel';
import AddPaymentInstrumentModal from '../Modals/AddPaymentInstrumentModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '10px',
  boxShadow: 24,
  // p: "4 0 4 4",
  p: '30px',
};

const EditSubscriptionModal = ({
  data,
  open,
  onClose,
  paymentCardSchemeList,
  paymentInstrumentList,
  paymentModeList,
  fetchPaymentModeInfo,
}) => {
  const [selectedEditFileName, setSelectedEditFileName] = useState('');
  const [indata, setInData] = useState(['attachment']);
  const { user } = useSelector((state) => state.login);
  const [loading, setLoading] = useState(false);
  const [subLabel, setSubLabel] = useState([]);

  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState('');
  const [addPaymentInstrumentModalOpen, setAddPaymentInstrumentModalOpen] = React.useState(false);
  const [selectedPaymentInstrumentList, setSelectedPaymentInstrumentList] = React.useState([]);

  const [openCustomLabel, setOpenCustomLabel] = useState({
    open: false,
    data: null,
  });

  const fetchSubLabel = async () => {
    try {
      const res = await getSubLabel();
      setSubLabel(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    Swal.fire({
      text: 'Are you sure you want to close this?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      appendTo: 'body',
      customClass: {
        container: 'my-swal', // Add a custom class for styling
      },
      onBeforeOpen: () => {
        // Set a higher zIndex for the Swal modal
        document.querySelector('.my-swal').style.zIndex = 999999;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        onClose();
        setSelectedPaymentMode('');
      }
    });
  };

  const validationSchema = Yup.object().shape({
    website: Yup.string().required('Please Enter Website'),
    frequency: Yup.string().required('Please Select frequency'),
    startDate: Yup.date().required('Please Select Contract Start Date').typeError('Please enter a valid date'),
    amount: Yup.number().min(0).required('Please Enter Amount'),
    autoRenewal: Yup.string().required('Please Select Auto Renewal'),
    nextBilling: Yup.date().required('Please Select next billing Date').typeError('Please enter a valid date'),
    status: Yup.string().required('Please select Status'),
  });

  const amountXZ = data?.amount
    ? data?.amount?.toString().includes('$')
      ? data?.amount.toString().replace('$', '')
      : data?.amount.toString()
    : 0;

  const initialValues = {
    subType: data?.companyType,
    website: data?.website,
    _id: data?._id,
    rating: data?.rating,
    isLiked: data?.isLiked,
    frequency: data?.frequency,
    startDate: data?.startDate,
    nextBilling: data?.nextBilling,
    amount: parseFloat(amountXZ),
    autoRenewal: data?.autoRenewal ? 'true' : 'false',
    status: `${data?.status}`,
    comments: data?.comments,
    description: data?.description,
    attachment: data && data?.attachments?.length > 0 ? data?.attachments : [],
    review: data?.review,
    paymentInstrument: '',
    label: '',
  };

  const EditForm = useFormik({
    enableReinitialize: false,
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (data?._id) {
          // Edit New Subscriptions
          setLoading(true);
          values.subscriptionName = data?.subscriptionName;
          values.currencyAbr = user?.country === 'United States' ? 'USD' : 'INR';

          let valuesToBeSent = indata.map((val) => {
            if (val === 'attachment' && values[val] !== 'undefined' && values[val] === '') {
              if (values[val].length === 0) {
                return { attachment: [...initialValues.attachment] };
              }
              return { attachment: [...values.attachment] };
            }
            return { [val]: values[val] };
          });

          valuesToBeSent = Object.assign({}, ...valuesToBeSent);
          const res = await EditsubsResponse(data._id, valuesToBeSent);
          if (res.data.success === true) {
            SuccessToast(res.data.message);
            setLoading(false);
            setInData([]);
            onClose(true);
            resetForm(initialValues);
          } else {
            setLoading(false);
            // handleClose();
            ErrorToast(res.data.message);
          }
        } else {
          // Create New Subscriptions
          setLoading(true);
          values.subscriptionName = data?.subscriptionName;
          values.currencyAbr = user?.country === 'United States' ? 'USD' : 'INR';

          let valuesToBeSent = indata.map((val) => {
            if (val === 'attachment' && values[val] !== 'undefined' && values[val] === '') {
              if (values[val].length === 0) {
                return { attachment: [...initialValues.attachment] };
              }
              return { attachment: [...values.attachment] };
            }
            return { [val]: values[val] };
          });

          valuesToBeSent = Object.assign({}, ...valuesToBeSent);
          const rawPayload = { ...data.raw, ...valuesToBeSent, amount: values.amount, device: 'Web_SafalIntelliscan' };
          const res = await SavesubsResponse(rawPayload);
          if (res.data.success === true) {
            SuccessToast(res.data.message);
            setLoading(false);
            setInData([]);
            onClose(true);
            resetForm(initialValues);
          } else {
            setLoading(false);
            // handleClose();
            ErrorToast(res.data.message);
          }
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
        ErrorToast(err?.response?.data?.message);
        // handleClose();
      }
    },
  });

  useEffect(() => {
    fetchSubLabel();
    console.log('EffectData', data);
    const amountXZX = data?.amount
      ? data?.amount?.toString().includes('$')
        ? data?.amount.toString().replace('$', '')
        : data?.amount.toString()
      : 0;
    const formdata = {
      subType: data?.companyType,
      website: data?.website,
      _id: data?._id,
      rating: data?.rating,
      isLiked: data?.isLiked || 0,
      frequency: data?.frequency,
      startDate: data?.startDate,
      nextBilling: data?.nextBilling,
      amount: parseFloat(amountXZX),
      autoRenewal: data?.autoRenewal ? 'true' : 'false',
      status: `${data?.status}`,
      comments: data?.comments,
      description: data?.description,
      attachment:
        data && data?.attachments?.length > 0 ? `${data?.attachments?.url}`.split('-').slice(4).join('-') : [],
      review: data?.review || '',
      paymentInstrument: data?.paymentInstrument?._id || '',
      label: data?.label || '',
    };
    if (data?.paymentInstrument) {
      setSelectedPaymentMode(data?.paymentInstrument?.paymentModeId);
      const newPaymentInstrumentList = paymentInstrumentList?.filter(
        (ex) => ex?.paymentModeId?._id === data?.paymentInstrument?.paymentModeId
      );
      setSelectedPaymentInstrumentList(newPaymentInstrumentList);
    }

    EditForm.setValues(formdata);
    setSelectedEditFileName(data?.attachments);
    setInData(['attachment']);
  }, [data]);

  const handleFileRemove = (val) => {
    const newState = selectedEditFileName.filter((arr) => val !== arr);
    setSelectedEditFileName(newState);
    indata.indexOf('attachment') === -1 && setInData((pre) => [...pre, 'attachment']);
    EditForm.setFieldValue('attachment', newState);
  };

  const onCloseAddPaymentInstrumentModal = async () => {
    setAddPaymentInstrumentModalOpen(false);
    const newListX = await fetchPaymentModeInfo();
    const newPaymentInstrumentList = newListX?.filter((ex) => ex?.paymentModeId?._id === selectedPaymentMode);
    setSelectedPaymentInstrumentList(newPaymentInstrumentList);
  };

  return (
    <>
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
            }}
          />
          <Inlineloader />
        </>
      )}
      <Modal open={open} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <ClickAwayListener onClickAway={() => handleClose}>
          <Box
            id="scrollbar"
            sx={{
              ...style,
              height: '80vh',
              paddingBottom: '10rem',
              position: 'relative',
              width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' },
              '@media (max-width: 640px)': {
                height: '98vh',
                width: '98vw',
                padding: '16px',
                overflow: 'auto',
              },
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ display: 'flex', alignItems: 'center', fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}
                >
                  {data?._id ? 'Update Subscription' : 'Add Subscription'}
                </Typography>
                <Typography variant="h4" gutterBottom sx={{ fontSize: '15px', fontWeight: 400 }}>
                  Edit your details below.
                </Typography>
              </Box>

              <Fab
                onClick={(e) => {
                  e.preventDefault();
                  EditForm.setValues(initialValues);
                  handleClose();
                }}
                size="small"
                color="primary"
                aria-label="add"
              >
                <CloseIcon />
              </Fab>
            </Stack>

            <Scrollbar>
              <Box sx={{ height: '100%', overflow: 'auto' }}>
                <FormikProvider value={EditForm}>
                  <form onSubmit={EditForm.handleSubmit}>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ overflowX: 'hidden' }}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Box>
                          <img height={60} width={'auto'} src={data?.company?.logo} alt="Logo" />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '98%' }}
                        >
                          <Field
                            as={TextField}
                            variant="standard"
                            size="small"
                            label={
                              <>
                                Subscription Type<span style={{ color: 'red' }}>*</span>
                              </>
                            }
                            name="subType"
                            href={EditForm.values.subType}
                            disabled
                          >
                            {EditForm.values.subType}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                          <FormLabel sx={{ fontSize: '14px', color: '#B6B6B6' }}>Website</FormLabel>
                          <Field
                            as={Link}
                            label="Website"
                            name="website"
                            href={
                              EditForm.values.website?.includes('https://')
                                ? EditForm.values.website
                                : `https://${EditForm.values.website}`
                            }
                            target="_blank"
                          >
                            {EditForm.values.website?.includes('https://')
                              ? EditForm.values.website
                              : `https://${EditForm.values.website}`}
                          </Field>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '98%' }}
                        >
                          <LocalizationProvider dateAdapter={MomentUtils}>
                            <Field
                              as={DesktopDatePicker}
                              maxDate={moment(EditForm.values.nextBilling).toDate()}
                              label={
                                <Typography sx={{ color: '#B6B6B6' }}>
                                  Contract Start Date<span style={{ color: 'red' }}>*</span>
                                </Typography>
                              }
                              inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                              // name="startDate"
                              onChange={(e) => {
                                EditForm.setFieldValue('startDate', moment(e).format('yyyy-MM-DD'));
                                indata.indexOf('startDate') === -1 && setInData((pre) => [...pre, 'startDate']);
                                EditForm.setFieldTouched('startDate', true, false);
                              }}
                              value={EditForm.values.startDate}
                              renderInput={(params) => (
                                <Field
                                  as={TextField}
                                  // name="startDate"
                                  variant="standard"
                                  size="small"
                                  {...params}
                                  error={EditForm.touched.startDate && Boolean(EditForm.errors.startDate)}
                                  helperText={EditForm.touched.startDate && EditForm.errors.startDate}
                                  sx={{
                                    svg: { color: '#0071E3', mr: '15px' },
                                  }}
                                  onBlur={() => {
                                    EditForm.setFieldTouched('startDate', true, false);
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '98%' }}
                        >
                          <LocalizationProvider dateAdapter={MomentUtils}>
                            <Field
                              as={DesktopDatePicker}
                              minDate={moment(EditForm.values.startDate).toDate()}
                              label={
                                <Typography sx={{ color: '#B6B6B6' }}>
                                  Next Billing Date<span style={{ color: 'red' }}>*</span>
                                </Typography>
                              }
                              inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                              // name="nextBilling"
                              onChange={(e) => {
                                EditForm.setFieldValue('nextBilling', moment(e).format('yyyy-MM-DD'));
                                indata.indexOf('nextBilling') === -1 && setInData((pre) => [...pre, 'nextBilling']);
                                EditForm.setFieldTouched('nextBilling', true, false);
                              }}
                              value={EditForm.values.nextBilling}
                              renderInput={(params) => (
                                <Field
                                  as={TextField}
                                  // name="nextBilling"
                                  variant="standard"
                                  size="small"
                                  {...params}
                                  error={EditForm.touched.nextBilling && Boolean(EditForm.errors.nextBilling)}
                                  helperText={EditForm.touched.nextBilling && EditForm.errors.nextBilling}
                                  sx={{
                                    svg: { color: '#0071E3', mr: '15px' },
                                  }}
                                  onBlur={() => {
                                    EditForm.setFieldTouched('nextBilling', true, false);
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          sx={{ mt: 2, width: '98%' }}
                          error={EditForm.touched.amount && EditForm.errors.amount}
                        >
                          <InputLabel htmlFor="amount" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                            Amount<span style={{ color: 'red' }}>*</span>
                          </InputLabel>
                          <Field
                            as={Input}
                            onWheel={(event) => {
                              event.preventDefault();
                            }}
                            label="Amount"
                            name="amount"
                            // type="number"
                            value={EditForm.values.amount}
                            onChange={(e) => {
                              if (e.target.value.length < 9) {
                                EditForm.handleChange(e);
                                indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                              }
                            }}
                            startAdornment={
                              <InputAdornment position="start">
                                {user?.country === 'United States' ? '$' : '₹'}
                              </InputAdornment>
                            }
                          />
                          {EditForm.touched.amount && EditForm.errors.amount ? (
                            <FormHelperText>{EditForm.touched.amount && EditForm.errors.amount}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '98%' }}
                        >
                          <InputLabel id="select3" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                            Frequency<span style={{ color: 'red' }}>*</span>
                          </InputLabel>
                          <Select
                            labelId="select3"
                            id="select3"
                            name="frequency"
                            label="Frequency"
                            variant="standard"
                            size="small"
                            value={`${EditForm.values.frequency}`}
                            onChange={(e) => {
                              EditForm.handleChange(e);
                              indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                color: '#0071E3',
                              },
                            }}
                          >
                            <MenuItem value={'Monthly'}>Monthly</MenuItem>
                            <MenuItem value={'Annually'}>Annually</MenuItem>
                            <MenuItem value={'Trial'}>Trial</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '98%' }}
                        >
                          <InputLabel id="select4" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                            Auto Renewal<span style={{ color: 'red' }}>*</span>
                          </InputLabel>
                          <Select
                            labelId="select4"
                            id="select4"
                            name="autoRenewal"
                            label="Auto Renewal"
                            variant="standard"
                            size="small"
                            value={EditForm.values.autoRenewal}
                            onChange={(e) => {
                              EditForm.handleChange(e);
                              indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                color: '#0071E3',
                              },
                            }}
                          >
                            <MenuItem value="true">Yes</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '98%' }}
                        >
                          <InputLabel id="select4" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                            Status<span style={{ color: 'red' }}>*</span>
                          </InputLabel>
                          <Select
                            labelId="select4"
                            id="select4"
                            name="status"
                            label="Status"
                            variant="standard"
                            size="small"
                            value={EditForm.values.status}
                            onChange={(e) => {
                              EditForm.handleChange(e);
                              indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                            }}
                            sx={{
                              '& .MuiSvgIcon-root': {
                                color: '#0071E3',
                              },
                            }}
                          >
                            <MenuItem value={'Active'}>Active</MenuItem>
                            <MenuItem value={'Inactive'}>Inactive</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Label */}
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '98%' }}
                        >
                          <CustomMuiSelect
                            disablePortal
                            label="Label"
                            size="small"
                            variant="standard"
                            options={subLabel?.map((e) => ({ label: e.name, id: e._id })) || []}
                            value={subLabel?.find((ex) => ex._id === EditForm.values.label)?.name || ''}
                            onAddNew={() => {
                              setOpenCustomLabel({ open: true, data: null });
                            }}
                            onChange={(e) => {
                              if (e) {
                                EditForm.setFieldValue('label', e.id);
                                indata.indexOf('label') === -1 && setInData((pre) => [...pre, 'label']);
                              } else {
                                EditForm.setFieldValue('label', '');
                                indata.indexOf('label') === -1 && setInData((pre) => [...pre, 'label']);
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>
                      {/* Payment Mode */}
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl sx={{ width: '98%' }}>
                          <InputLabel id="select55" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                            Payment Mode
                          </InputLabel>
                          <Field
                            as={Select}
                            labelId="select55"
                            id="select55"
                            name="status"
                            label="Payment Mode"
                            variant="standard"
                            size="small"
                            value={selectedPaymentMode}
                            onChange={(e) => {
                              setSelectedPaymentMode(e.target.value);
                              const newPaymentInstrumentList = paymentInstrumentList?.filter(
                                (ex) => ex?.paymentModeId?._id === e.target.value
                              );
                              setSelectedPaymentInstrumentList(newPaymentInstrumentList);
                            }}
                            sx={{
                              '&:after': {
                                borderBottomColor: '#0000',
                              },
                              '& .MuiSvgIcon-root': {
                                color: '#0071E3',
                              },
                            }}
                          >
                            {paymentModeList?.map((e, index) => (
                              <MenuItem key={index} value={e._id}>
                                {e.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </FormControl>
                      </Grid>
                      {/* paymentInstrument */}
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl sx={{ width: '98%' }}>
                          <CustomMuiSelect
                            disablePortal
                            label="Payment Instrument"
                            size="small"
                            variant="standard"
                            options={
                              selectedPaymentInstrumentList.map((e) => ({ label: e.nickNameField, id: e._id })) || []
                            }
                            value={
                              selectedPaymentInstrumentList?.find((ex) => ex._id === EditForm.values.paymentInstrument)
                                ?.nickNameField || ''
                            }
                            onAddNew={() => {
                              setAddPaymentInstrumentModalOpen(true);
                            }}
                            onChange={(e) => {
                              if (e) {
                                EditForm.setFieldValue('paymentInstrument', e.id);
                                indata.indexOf('paymentInstrument') === -1 &&
                                  setInData((pre) => [...pre, 'paymentInstrument']);
                              } else {
                                EditForm.setFieldValue('paymentInstrument', '');
                                indata.indexOf('paymentInstrument') === -1 &&
                                  setInData((pre) => [...pre, 'paymentInstrument']);
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '99%' }}
                        >
                          <Field
                            as={TextField}
                            id="input1"
                            name="comments"
                            multiline
                            rows={3}
                            inputProps={{
                              maxlength: 250,
                            }}
                            helperText={`${EditForm.values.comments?.length || 0}/${250}`}
                            label={<Typography sx={{ color: '#B6B6B6' }}>Comments</Typography>}
                            variant="standard"
                            size="small"
                            value={EditForm.values.comments}
                            onChange={(e) => {
                              EditForm.handleChange(e);
                              indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                            }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Typography
                          sx={{
                            color: ' #B6B6B6',
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            fontSize: '1rem',
                            lineHeight: '1.4375em',
                            letterSpacing: '0.00938em',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            mb: 1,
                          }}
                        >
                          Rating
                        </Typography>
                        <CustomRating
                          name="rating"
                          value={+EditForm.values.rating}
                          onChange={(e) => {
                            EditForm.setFieldValue('rating', e.target.value);
                            indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <Typography
                          sx={{
                            color: ' #B6B6B6',
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            fontSize: '1rem',
                            lineHeight: '1.4375em',
                            letterSpacing: '0.00938em',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            mb: 1,
                          }}
                        >
                          Safals
                        </Typography>
                        <Box
                          sx={{ display: 'flex' }}
                          name="isLiked"
                          onChange={(e) => {
                            EditForm.handleChange(e);
                            indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                          }}
                          onClick={() => {
                            EditForm.setFieldValue('isLiked', EditForm.values.isLiked === 1 ? -1 : 1);
                            indata.indexOf('isLiked') === -1 && setInData((pre) => [...pre, 'isLiked']);
                          }}
                        >
                          {EditForm.values.isLiked === 1 && <img src="/assets/images/Like.png" alt="Like Symbol" />}
                          {EditForm.values.isLiked === -1 && (
                            <img src="/assets/images/disLike.png" alt="DisLike Symbol" />
                          )}
                          {EditForm.values.isLiked === 0 && (
                            <>
                              <img
                                src="/assets/images/Like.png"
                                alt="Like Symbol"
                                style={{ filter: 'grayscale(100%)' }}
                              />
                              <img
                                src="/assets/images/disLike.png"
                                alt="DisLike Symbol"
                                style={{ filter: 'grayscale(100%)' }}
                              />
                            </>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        <FormControl
                          // fullWidth
                          sx={{ width: '99%' }}
                        >
                          <Field
                            as={TextField}
                            id="input1"
                            name="review"
                            multiline
                            rows={3}
                            inputProps={{
                              maxlength: 1000,
                            }}
                            label={<Typography sx={{ color: '#B6B6B6' }}>Review</Typography>}
                            helperText={`${EditForm.values.review?.length || 0}/${1000}`}
                            variant="standard"
                            size="small"
                            value={EditForm.values.review}
                            onChange={(e) => {
                              EditForm.handleChange(e);
                              indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid>
                      <input
                        id="contained-button-file"
                        style={{ display: 'none' }}
                        type="file"
                        name="attachment"
                        multiple
                        onChange={(e) => {
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);

                          const uploadedFiles = e?.target?.files;
                          const updatedFiles = [...selectedEditFileName];

                          Object.keys(uploadedFiles).forEach((id) => {
                            const file = uploadedFiles[id];

                            // Rest of the code for processing valid files
                            const duplicateFile = updatedFiles.find((uploadedFile) => uploadedFile.name === file.name);

                            if (duplicateFile) {
                              let counter = 1;
                              let newFileName = `${file.name.substring(
                                0,
                                file.name.lastIndexOf('.')
                              )} (${counter})${file.name.substring(file.name.lastIndexOf('.'))}`;

                              // eslint-disable-next-line no-loop-func
                              while (updatedFiles.some((uploadedFile) => uploadedFile.name === newFileName)) {
                                // eslint-disable-next-line no-plusplus
                                counter++;
                                newFileName = `${file.name.substring(
                                  0,
                                  file.name.lastIndexOf('.')
                                )} (${counter})${file.name.substring(file.name.lastIndexOf('.'))}`;
                              }
                              const renamedFile = new File([file], newFileName, { type: file.type });
                              updatedFiles.push(renamedFile);
                            } else {
                              updatedFiles.push(file);
                            }
                          });

                          setSelectedEditFileName(updatedFiles);
                          EditForm.setFieldValue('attachment', updatedFiles);
                        }}
                      />

                      <FormLabel
                        htmlFor="contained-button-file"
                        sx={{ display: 'flex', alignItems: 'center', mt: '16px' }}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          sx={{
                            fontWeight: 700,
                            textTransform: 'none',
                            bgcolor: '#fff',
                            color: '#1D1D1F',
                            borderRadius: '30px',
                            boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                            ':hover': { bgcolor: 'transparent' },
                          }}
                        >
                          <Iconify icon="entypo:attachment" color="#3D71FF" width={20} height={20} mr={1} />
                          Attachment
                        </Button>
                      </FormLabel>
                      {selectedEditFileName && (
                        <Box display={'flex'} flexWrap={'wrap'}>
                          {selectedEditFileName.map((val) => (
                            <Box key={val} sx={{ mt: 1, ml: 2 }}>
                              <Fab
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                  handleFileRemove(val);
                                }}
                                sx={{ mr: 1 }}
                              >
                                <CloseIcon />
                              </Fab>
                              {val.name || getNameFromUrl(val.url)}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Grid>
                  </form>
                </FormikProvider>
              </Box>
            </Scrollbar>
            <Box sx={{ bottom: '20px', display: 'flex' }}>
              <Button
                color="error"
                variant="contained"
                onClick={(e) => {
                  e.preventDefault();
                  EditForm.setValues(initialValues);
                  handleClose();
                }}
                sx={{
                  width: '150px',
                  height: '45px',
                  borderRadius: '30px',
                  mt: '25px',
                  boxShadow: 'none',
                  textTransform: 'capitalize',
                  ml: 'auto',
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  EditForm.handleSubmit();
                }}
                disabled={!EditForm.isValid || !EditForm.dirty}
                sx={{
                  width: '150px',
                  height: '45px',
                  backgroundColor: '#3D71FF',
                  borderRadius: '30px',
                  mt: '25px',
                  ml: 5,
                  boxShadow: 'none',
                  textTransform: 'capitalize',
                }}
              >
                {data?._id ? 'Save' : 'Create'}
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      </Modal>
      <AddCustomLabel
        open={openCustomLabel.open}
        data={openCustomLabel.data}
        onClose={(data) => {
          fetchSubLabel();
          if (data) {
            EditForm.setFieldValue('label', data._id);
            indata.indexOf('label') === -1 && setInData((pre) => [...pre, 'label']);
          }
          setOpenCustomLabel({
            open: false,
            data: null,
          });
        }}
      />
      <AddPaymentInstrumentModal
        open={addPaymentInstrumentModalOpen}
        onClose={onCloseAddPaymentInstrumentModal}
        paymentModeList={paymentModeList}
        paymentCardSchemeList={paymentCardSchemeList}
      />
    </>
  );
};

export default EditSubscriptionModal;

const getNameFromUrl = (urlString) => {
  try {
    const urlSplit1 = urlString.split('/');
    const fileName = urlSplit1.length > 0 ? urlSplit1[urlSplit1.length - 1] : 'attachment';
    const withoutTimeStamp = fileName.split('-');
    if (withoutTimeStamp.length > 0) {
      withoutTimeStamp.splice(0, 1);
      return withoutTimeStamp.join('-');
    }
    return fileName;
  } catch (e) {
    console.log(e);
    return 'attachment';
  }
};
