/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import './AssetModuleBusiness.css';
import {
  Select,
  MenuItem,
  Menu,
  InputLabel,
  FormControl,
  Button,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { getAssetModuleCompany, getAssetModuleCards } from '../../../services/Service';
import AssetModuleLoyaltyGiftRewardCustom from './AssetModuleLoyaltyGiftRewardCustom';
import AssetModuleCompanyCustom from './AssetModuleCompanyCustom';

function AssetModuleBusinessCustom() {
  const [rawCompanyDataBusinessOnly, setRawCompanyDataBusinessOnly] = useState([]);
  const [rawCompanyDataBusinessOnlyFilter, setRawCompanyDataBusinessOnlyFilter] = useState([]);
  const [rawCardData, setRawCardData] = useState([]);
  const [companySearchValue, setCompanySearchValue] = useState('');

  const [cardPaginationOption, setCardPaginationOption] = useState({
    type: 'all',
    page: 1,
    limit: 20,
    total: 0,
    companyId: '',
  });

  const [selectedToggle, setSelectedToggle] = useState('company');

  const fetchCompanyData = async () => {
    try {
      const res2 = await getAssetModuleCompany(true, false);
      if (res2.status === 200) {
        setRawCompanyDataBusinessOnly(res2.data.data);
        setRawCompanyDataBusinessOnlyFilter(res2.data.data);
        setCompanySearchValue('');
      }
    } catch (err) {
      console.log('fetchCompanyData error', err.message);
      toast.error(err.message);
    }
  };

  const fetchAssetData = async (page, limit, type, companyId) => {
    try {
      const _type = type === 'all' ? '' : type;
      const res = await getAssetModuleCards(page, limit, _type, companyId, false);
      if (res.status === 200) {
        setRawCardData(res.data.data);
        setCardPaginationOption({
          total: res.data.totalCount,
          type: type,
          page: page,
          limit: limit,
          companyId: companyId,
        });
      }
    } catch (err) {
      console.log('fetchAssetData error', err.message);
      toast.error(err.message);
    }
  };

  const handleChangeToggle = async (e) => {
    const value = e.target.value;
    setSelectedToggle(value);
  };

  const handleChangeAssetModuleType = async (e) => {
    const value = e.target.value;
    fetchAssetData(cardPaginationOption.page, cardPaginationOption.limit, value, cardPaginationOption.companyId);
  };

  const onCompanySearchChange = (e) => {
    setCompanySearchValue(e.target.value);
    const value = e.target.value.toLowerCase().trim();
    if (value === '') {
      setRawCompanyDataBusinessOnlyFilter(rawCompanyDataBusinessOnly);
      return;
    }
    const res = rawCompanyDataBusinessOnly.filter(
      (ex) => ex?.name.toLowerCase().includes(value) || ex?.type.toLowerCase().includes(value)
    );
    setRawCompanyDataBusinessOnlyFilter(res);
  };

  return (
    <>
      <div id="AssetModuleBusiness">
        <div className="action-bar">
          <h2 style={{ color: '#017c33' }}>Asset Module Custom</h2>
        </div>
        <div className="select-bar">
          <div className="select-bar-toggle">
            <ToggleButtonGroup
              color="primary"
              size="small"
              value={selectedToggle}
              exclusive
              onChange={handleChangeToggle}
              aria-label="Asset Module"
            >
              <ToggleButton sx={{ px: '12px', textTransform: 'capitalize' }} value="company">
                Custom Company
              </ToggleButton>
              <ToggleButton sx={{ px: '12px', textTransform: 'capitalize' }} value="asset">
                Custom Loyalty cards, Gift cards, Reward certificates
              </ToggleButton>
            </ToggleButtonGroup>
            {selectedToggle === 'asset' && (
              <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
                <InputLabel id="asset-module-type-label">Type</InputLabel>
                <Select
                  labelId="asset-module-type-label"
                  id="asset-module-type"
                  value={cardPaginationOption.type}
                  label="Type"
                  onChange={handleChangeAssetModuleType}
                >
                  <MenuItem value={'all'}>All</MenuItem>
                  <MenuItem value={'loyalty'}>Loyalty Card</MenuItem>
                  <MenuItem value={'gift'}>Gift Card</MenuItem>
                  <MenuItem value={'reward'}>Reward certificates</MenuItem>
                </Select>
              </FormControl>
            )}
            {selectedToggle === 'company' && (
              <TextField
                sx={{ width: '200px', ml: '12px' }}
                onChange={onCompanySearchChange}
                value={companySearchValue}
                type="search"
                id="search-input"
                size="small"
                label="Search"
                variant="outlined"
              />
            )}
          </div>
        </div>
        {selectedToggle === 'asset' ? (
          <AssetModuleLoyaltyGiftRewardCustom
            fetchData={fetchAssetData}
            data={rawCardData}
            paginationOption={cardPaginationOption}
          />
        ) : (
          <AssetModuleCompanyCustom fetchData={fetchCompanyData} data={rawCompanyDataBusinessOnlyFilter} />
        )}
      </div>
    </>
  );
}

export default AssetModuleBusinessCustom;
