import PropTypes from 'prop-types';
// material
import { Box, Checkbox, TableRow, TableCell, TableHead, Typography, useTheme } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from '@mui/styles';
// ----------------------------------------------------------------------

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  isCheckListRemove: PropTypes.any,
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  isCheckListRemove,
}) {
  const theme = useTheme();
  const classes = useStyles();

  const createAscSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ padding: '0px !important' }}>
      <TableRow>
        {isCheckListRemove !== 'true' && (
          <TableCell
            padding="checkbox"
            sx={{
              backgroundColor: theme.palette.info.table,
              borderBottomLeftRadius: '35px',
              borderTopLeftRadius: '35px',
            }}
          >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {headLabel.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            className={classes.paddingHead}
            align={headCell.alignRight ? 'right' : 'left'}
            sx={
              index === 0 && isCheckListRemove === 'true'
                ? {
                    backgroundColor: theme.palette.info.table,
                    borderBottomLeftRadius: '35px',
                    borderTopLeftRadius: '35px',
                    whiteSpace: 'pre-wrap',
                    paddingRight: 0,
                    paddingLeft: 0,
                    margin: '10px !important',
                    padding: '10px !important',
                    width: 140,
                  }
                : {
                    backgroundColor: theme.palette.info.table,
                    // div: {
                    //   justifyContent: 'center !important',
                    // },
                    // '&:nth-child(2)': {
                    //   div: {
                    //     justifyContent: 'left !important',
                    //   },
                    // },
                    // p: '15px',
                    '&:last-child': {
                      borderBottomRightRadius: '35px',
                      borderTopRightRadius: '35px',
                      whiteSpace: 'pre-wrap',
                      paddingRight: 0,
                      paddingLeft: 0,
                      paddingX: '10px !important',

                      // width: 150,
                    },
                  }
            }
          >
            <Box sx={{ display: 'flex', alignItems: 'center', color: theme.palette.secondary.font, fontWeight: 700 }}>
              <span>{headCell.label}</span>
              {headCell.sort && (
                <>
                  <Typography
                    onClick={createAscSortHandler(headCell.id)}
                    // onClick={() => {
                    //   console.log('this is headCell ==> ', headCell.id);
                    //   createAscSortHandler(headCell.id);
                    // }}
                    style={{ display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer' }}
                  >
                    <ArrowDropUpIcon
                      sx={{
                        ...(order === 'asc' && orderBy === headCell.id && { color: '#1877F2' }),
                        position: 'absolute',
                        bottom: '-8px',
                      }}
                    />
                    <ArrowDropDownIcon
                      sx={{
                        ...(order === 'desc' && orderBy === headCell.id && { color: '#1877F2' }),
                        position: 'absolute',
                        top: '-8px',
                      }}
                    />
                  </Typography>
                </>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles({
  paddingHead: {
    whiteSpace: 'pre-wrap',
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 5,
    paddingTop: 5,
    marginRight: 12,
    marginLeft: 12,
  },
});
