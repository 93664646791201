import { useSelector } from 'react-redux';
import useUserProfileImage from '../../hooks/useUserProfileImage';
import safalAiImage from '../../assets/ai-chat-logo.png';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';

const ChatMessageXGPT = ({ data, isDate }) => {
  const { user } = useSelector((state) => state.login);
  const { formatTime, formatDate2 } = useUserTimeZone();
  const userProfileImage = useUserProfileImage();

  const userFullName = `${user.firstName} ${user.lastName}`;

  return (
    <>
      <div className={`ai-chat-message ${data.from !== 'SafalAIGPT' && 'self'}`}>
        <div className="image-div">
          <img className="ai-image" src={data.from === 'SafalAIGPT' ? safalAiImage : userProfileImage} alt="safal AI" />
        </div>
        <div style={{ maxWidth: '70%' }}>
          <div
            style={{
              backgroundColor: data.from === 'SafalAIGPT' ? '#eaeaea' : '#0f92e7',
              padding: '12px',
              borderRadius: '10px',
              borderBottomLeftRadius: data.from === 'SafalAIGPT' ? '0px' : '10px',
              borderBottomRightRadius: data.from === 'SafalAIGPT' ? '10px' : '0px',
              color: data.from === 'SafalAIGPT' ? 'black' : 'white',
            }}
          >
            <span style={{ fontWeight: 'bold' }}>{data.from === 'SafalAIGPT' ? data.from : userFullName}</span>
            <p style={{ whiteSpace: 'pre-line' }}>{data.message}</p>
          </div>
          <span style={{ fontSize: '12px', color: '#B6B6B6' }}>
            {formatTime(data.createdAt)} {getTimeZoneAbbreviation()}
          </span>
        </div>
      </div>
      {data?.isDate && (
        <span style={{ textAlign: 'center' }}>
          {formatDate2(data.createdAt)} {getTimeZoneAbbreviation()}
        </span>
      )}
    </>
  );
};
export default ChatMessageXGPT;
