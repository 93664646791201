import * as React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { addCompanyType, uploadBanner, updateCompanyType } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  type: '',
  isOffer: true,
  logo: '',
};

export default function AddCompanyTypeModal({ open, onClose, data }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [fileValue, setFileValue] = React.useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setFileValue(null);
    onClose();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      // upload function
      const res_image = await uploadBanner(fileValue);

      const payload = {
        ...fieldValue,
        logo: res_image.data.url,
      };

      const res = await addCompanyType(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdateSubmit = async () => {
    setIsSubmitting(true);
    try {
      // upload function
      let logo = fieldValue.logo;
      if (fileValue) {
        const res_image = await uploadBanner(fileValue);
        logo = res_image.data.url;
      }

      const payload = {
        ...fieldValue,
        _id: data._id,
        logo: logo,
      };

      const res = await updateCompanyType(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        type: data?.type,
        isOffer: data?.isOffer || true,
        logo: data?.logo || '',
      });
    }
  }, [open, data]);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Company Type' : 'Update Company Type'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* Logo */}
          <TextField
            onChange={(e) => {
              setFileValue(e.target.files[0]);
            }}
            type="file"
            required
            size="small"
            fullWidth
            variant="outlined"
            InputProps={{
              accept: 'image/*',
            }}
          />
          {/* type */}
          <TextField
            value={fieldValue.type}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, type: e.target.value }))}
            required
            label="Type"
            name="type"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
            // error={error}
            // helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
          />
          {/* isOffer */}
          <TextField
            value={fieldValue.isOffer === true ? 'active' : 'inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, isOffer: e.target.value === 'active' }))}
            required
            select
            label="Is Offer Active"
            size="small"
            fullWidth
            variant="outlined"
          >
            <MenuItem value={'active'}>Active</MenuItem>
            <MenuItem value={'inactive'}>Inactive</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={data === null ? onSubmit : onUpdateSubmit}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            {data === null ? 'Create' : 'Update'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
