/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import './AssetModuleBusiness.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Menu, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ApprovalIcon from '@mui/icons-material/Approval';

import { updateAssetModulecompany } from '../../../services/Service';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const AssetModuleCompanyCustom = ({ fetchData, data }) => {
  const { formatDateTime } = useUserTimeZone();
  const onStatusChange = async (row, status) => {
    try {
      const payload = {
        _id: row._id,
      };
      if (status === 'approve') {
        payload.isVerified = true;
      } else {
        payload.isDeleted = true;
      }
      const res = await updateAssetModulecompany(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        await fetchData();
      }
    } catch (err) {
      console.log('AssetModuleCompany onDelete', err.message);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead style={{ backgroundColor: '#eaffea', color: '#007b32' }}>
          <TableRow>
            <TableCell style={{ color: '#007b32' }}>Logo</TableCell>
            <TableCell style={{ color: '#007b32' }}>Name</TableCell>
            <TableCell style={{ color: '#007b32' }}>Type</TableCell>
            <TableCell style={{ color: '#007b32' }}>Website</TableCell>
            <TableCell style={{ color: '#007b32' }}>Country</TableCell>
            <TableCell style={{ color: '#007b32' }}>Description</TableCell>
            <TableCell style={{ color: '#007b32' }}>Added by</TableCell>
            <TableCell style={{ color: '#007b32' }}>Created At</TableCell>
            <TableCell style={{ color: '#007b32' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((e, index) => (
            <TableRow key={index}>
              <TableCell>
                <img style={{ maxHeight: '64px' }} src={e?.logo} alt={e?.name} />
              </TableCell>
              <TableCell>{e?.name}</TableCell>
              <TableCell>{e?.type}</TableCell>
              <TableCell>
                <a href={e?.website}>{e?.website}</a>
              </TableCell>
              <TableCell>{e?.country}</TableCell>
              <TableCell>{e?.description}</TableCell>
              <TableCell>{e?.whoCreated?.userID}</TableCell>
              <TableCell>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </TableCell>
              <TableCell>
                <ActionMenu onChange={(status) => onStatusChange(e, status)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetModuleCompanyCustom;

function ActionMenu({ onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (status) => {
    setAnchorEl(null);
    onChange(status);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ApprovalIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClose('approve')}>Approve</MenuItem>
        <MenuItem onClick={() => handleClose('reject')}>Reject</MenuItem>
      </Menu>
    </div>
  );
}
