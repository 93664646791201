/* eslint-disable */
import './input.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { Avatar, FormLabel } from '@mui/material';
import { GrAttachment } from 'react-icons/gr';
import { BsSendFill } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';
import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import {
  getSignedURL,
  sendUserNotification,
  firebasePushNotification,
  uploadAttachment,
  getAvailableSupportUserId,
} from '../../services/Service';

import { db } from '../../firebase';
import ErrorToast from '../Toast/Error';

// file icons
import videoImg from '../../assets/v-icon.png';
import pdfImg from '../../assets/pdf.png';
import docImg from '../../assets/d-icon.png';
import pptImg from '../../assets/ppt.png';
import wordImg from '../../assets/doc.png';
import { toast } from 'react-hot-toast';

const Input = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const { chats } = useSelector((state) => state);
  const { user } = useSelector((state) => state.login);
  const { chatMode } = useSelector((state) => state.chats);

  const [text, setText] = useState('');
  const [typing, setTyping] = useState(false);
  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);

  const isDeleted = otherUserInfo?.isDeleted === true ? true : false;
  const userToUserChatEnable =
    chatMode === 'support'
      ? true
      : otherUserInfo !== null
      ? otherUserInfo.userToUserChat !== undefined
        ? otherUserInfo.userToUserChat
        : true
      : true;

  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const currentUserToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  const getFileType = (filename) => {
    const fileExtSplit = filename.split('.');
    const fileExt = fileExtSplit[fileExtSplit.length - 1];
    if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'jfif') {
      return 'image';
    } else if (fileExt === 'mp4' || fileExt === '3gp' || fileExt === 'flv' || fileExt === 'mkv') {
      return 'video';
    } else if (fileExt === 'pdf') {
      return 'pdf';
    } else if (fileExt === 'pptx' || fileExt === 'ppt') {
      return 'pptx';
    } else if (fileExt === 'doc' || fileExt === 'docx') {
      return 'doc';
    } else {
      return 'others';
    }
  };

  const onBlurInput = () => {
    updateDoc(doc(db, `chats`, chats.chatId), {
      typing: {
        [currentUser?.uid]: false,
      },
    });
  };
  const onFocusInput = () => {
    updateDoc(doc(db, `chats`, chats.chatId), {
      typing: {
        [currentUser?.uid]: true,
      },
    });
  };

  const handleSend = async () => {
    setIsLoading(true);
    onBlurInput();
    const messageId = uuid();
    try {
      const urls = [];
      if (text == '' && file.length <= 0) return;
      setText('');

      const otherUserId = chats?.user?.uid;
      let otherUserInfo = null;

      if (otherUserId) {
        const otherUserInfoRef = await getDoc(doc(db, 'users', otherUserId));
        if (otherUserInfoRef.exists()) otherUserInfo = otherUserInfoRef.data();
      }

      // File Upload
      if (file.length > 0) {
        const dataPayload = new FormData();
        file.forEach((e) => dataPayload.append('attachment', e));
        const fileRes = await uploadAttachment(dataPayload);
        if (fileRes.status !== 200) {
          throw new Error('file upload failed!');
        }
        fileRes.data.data.forEach((e) => urls.push(e));
      }
      let updatePaload = {
        id: messageId,
        text,
        senderId: currentUser?.uid,
        date: Timestamp.now(),
        msgStatus: otherUserInfo ? (otherUserInfo.isOnline ? 1 : 0) : 1,
        senderRole: user.role,
      };
      // set File Url to Firebase chats
      if (urls?.length > 0) {
        for await (const url of urls) {
          updatePaload.url = url;
          updatePaload.messageType = getFileType(url);
          await updateDoc(doc(db, 'chats', chats.chatId), {
            messages: arrayUnion(updatePaload),
          });
        }
      } else {
        updatePaload.messageType = 'text';
        await updateDoc(doc(db, 'chats', chats.chatId), {
          messages: arrayUnion(updatePaload),
        });
      }

      // FCM firebase/pushNotification
      if (otherUserInfo) {
        const userData = otherUserInfo;
        const userID = userData.userID;
        if (!otherUserInfo.isOnline) {
          const pushNotificationPayload = {
            userId: userID,
            data: {
              notification: {
                title:
                  chatMode === 'user'
                    ? `New message from ${currentUser.displayName || 'user'}`
                    : `New message from ${chatMode} team`,
                body: '',
                image: '',
              },
              apns: {
                payload: {
                  aps: {
                    sound: 'default',
                    badge: 1,
                  },
                },
              },
              data: {
                type: chatMode === 'user' ? 'user' : 'business',
                messageId: messageId,
                image_url: '',
                chatID: chatMode === 'user' ? chats.chatId : '',
                otheruid: chatMode === 'user' ? currentUser?.uid : '',
              },
            },
          };
          if (urls.length === 1) {
            if (getFileType(urls[0]) === 'image') {
              pushNotificationPayload.data.notification.body = 'an image received';
              const res = await getSignedURL({ url: urls[0] });
              if (res.status === 200) {
                pushNotificationPayload.data.data.image_url = res.data;
                pushNotificationPayload.data.notification.image = res.data;
              }
            } else {
              pushNotificationPayload.data.notification.body = 'an attachment received';
            }
          } else if (urls.length > 1) {
            pushNotificationPayload.data.notification.body = 'multiple attachments received';
          } else {
            pushNotificationPayload.data.notification.body = text;
          }
          await firebasePushNotification(pushNotificationPayload);
        }
      } else {
        // FCM firebase/pushNotification user to support
        // getting get-available-support-user-id
        const supportUserIdRes = await getAvailableSupportUserId();
        if (supportUserIdRes.status === 200) {
          const pushNotificationPayload = {
            userId: supportUserIdRes.data.data.userId,
            data: {
              notification: {
                title:
                  chatMode === 'user'
                    ? `New message from ${currentUser.displayName || 'user'}`
                    : `New message from ${chatMode} team`,
                body: '',
                image: '',
              },
              apns: {
                payload: {
                  aps: {
                    sound: 'default',
                    badge: 1,
                  },
                },
              },
              data: {
                type: chatMode === 'user' ? 'user' : 'business',
                messageId: messageId,
                image_url: '',
                chatID: chatMode === 'user' ? chats.chatId : '',
                otheruid: chatMode === 'user' ? currentUser?.uid : '',
              },
            },
          };
          if (urls.length === 1) {
            if (getFileType(urls[0]) === 'image') {
              pushNotificationPayload.data.notification.body = 'an image received';
              const res = await getSignedURL({ url: urls[0] });
              if (res.status === 200) {
                pushNotificationPayload.data.data.image_url = res.data;
                pushNotificationPayload.data.notification.image = res.data;
              }
            } else {
              pushNotificationPayload.data.notification.body = 'an attachment received';
            }
          } else if (urls.length > 1) {
            pushNotificationPayload.data.notification.body = 'multiple attachments received';
          } else {
            pushNotificationPayload.data.notification.body = text;
          }
          await firebasePushNotification(pushNotificationPayload);
        }
      }

      // FCM firebase/pushNotification END

      // notificationsdot business
      if (chats.user.uid && user.role === 'business') {
        const otherUserNotificationDotRef = doc(db, 'notificationsdot', chats.user.uid);
        await setDoc(otherUserNotificationDotRef, {
          senderName: 'business',
          senderId: currentUser?.uid,
          receiverId: currentUser?.uid,
          receiveruserID: user.userID,
          message: text,
          count: 1,
        });
      }

      // notificationsdot user : businessNotificationsDot
      if (user.role === 'user' && chatMode === 'support') {
        const businessNotificationsDotRef = doc(db, 'businessNotificationsDot', 'supportChat');
        await setDoc(businessNotificationsDotRef, {
          notificationCount: 1,
        });
      }

      // user notifaction
      if (user.role === 'user' && chatMode !== 'support') {
        await sendUserNotification({
          userId: chats.user?.uid,
          message: `New message from ${currentUser.displayName} : ${text}`,
        });
      }

      await updateDoc(doc(db, `${chatMode}Chats`, currentUser?.uid), {
        [`${chats.chatId}.lastMessage`]: updatePaload,
        [`${chats.chatId}.date`]: serverTimestamp(),
      });

      if (chatMode === 'user') {
        await updateDoc(doc(db, `${chatMode}Chats`, chats.user.uid), {
          [`${chats.chatId}.lastMessage`]: updatePaload,
          [`${chats.chatId}.date`]: serverTimestamp(),
        });
      } else {
        await updateDoc(doc(db, `${chatMode}Chats`, chats.chatId), {
          [`${chats.chatId}.lastMessage`]: updatePaload,
          [`${chats.chatId}.date`]: serverTimestamp(),
        });
      }

      /**
       * cReating alert for the receiver
       */
      // check whether the alert is created for the receiver
      let chatPathID = null;
      if (chatMode === 'user') {
        chatPathID = chats.user.uid;
      } else {
        chatPathID = currentUser?.uid;
      }
      const docRef = doc(db, 'alert', chatPathID);
      const notificationDotRef = doc(db, 'notificationsdot', chatPathID);

      let colRef;
      let newAlert;
      let alertRef;
      let supportAlertRef = null;
      let supportAlert = null;
      const notificationsDot = await getDoc(notificationDotRef);

      if (!notificationsDot.exists()) {
        await setDoc(notificationDotRef, {
          senderName: currentUser?.displayName,
          senderId: currentUser?.uid,
          receiverId: currentUser?.uid,
          receiveruserID: user.userID,
          message: text,
          count: 1,
        });
      }
      if (chatMode === 'user') {
        colRef = collection(docRef, currentUser?.uid);

        newAlert = await getDoc(doc(db, 'alert', `${chats.user.uid}/${currentUser?.uid}/${currentUser?.uid}`));
        alertRef = doc(db, 'alert', `${chats.user.uid}/${currentUser?.uid}/${currentUser?.uid}`);
      } else {
        colRef = collection(docRef, chats.chatId);
        supportAlert = await getDoc(doc(db, 'alert', `${chats.chatId}`));
        newAlert = await getDoc(doc(db, 'alert', `${chats.chatId}/${currentUser?.uid}/${currentUser?.uid}`));
        alertRef = doc(db, 'alert', `${chats.chatId}/${currentUser?.uid}/${currentUser?.uid}`);
        supportAlertRef = doc(db, 'alert', chatPathID);
      }

      if (supportAlertRef) {
        if (supportAlert.exists() || supportAlert.data()?.count > 0) {
          await updateDoc(supportAlertRef, {
            senderName: currentUser?.displayName,
            senderId: currentUser?.uid,
            photo: currentUser?.photoURL,
            count: supportAlert.data().count + 1,
          });
        } else {
          await setDoc(supportAlertRef, {
            senderName: currentUser?.displayName,
            senderId: currentUser?.uid,
            photo: currentUser?.photoURL,
            count: 1,
          });
        }
      }
      if (newAlert.exists()) {
        await updateDoc(alertRef, {
          senderName: currentUser?.displayName,
          senderId: currentUser?.uid,
          photo: currentUser?.photoURL,
          count: newAlert?.data()?.count + 1,
        });
      } else {
        // newAlert.docs.find(d => d.senderId === )
        await setDoc(alertRef, {
          senderName: currentUser?.displayName,
          senderId: currentUser?.uid,
          photo: currentUser?.photoURL,
          count: 1,
        });
      }
    } catch (e) {
      if (e.name === 'AxiosError' && e.code !== 'ERR_NETWORK') {
        toast.error(e.response.data.message);
      } else if (e.message.includes('No document to update')) {
        console.log('No document to update');
      } else {
        toast.error(e.message);
      }
    } finally {
      setIsLoading(false);
      setText('');
      setFile([]);
      // input focas
      setTimeout(() => {
        document.getElementById('chatInput_chatInput_chatInput').focus();
      }, 1000);
      const fileuploadEle = document.getElementById('file-upload');
      if (fileuploadEle) fileuploadEle.value = '';
    }
  };

  useEffect(() => {
    if (!chats.chatId) return;
    const unSub = onSnapshot(doc(db, 'chats', chats?.chatId), (doc) => {
      if (doc?.exists()) {
        const test = doc?.data();
        if ('typing' in test && `${chats?.user?.uid}` in test?.typing) setTyping(test?.typing[chats?.user?.uid]);
      }
      return () => {
        unSub();
      };
    });
  }, [chats.chatId]);

  useEffect(() => {
    if (!chats) return;
    if (!chats.user) return;
    if (!chats.user.uid) return;

    const unSub = onSnapshot(doc(db, 'users', chats.user.uid), (snapshot) => {
      const data = snapshot.data();
      setOtherUserInfo(data);
    });

    return () => {
      unSub();
    };
  }, [chats]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      handleSend();
    } else if (e.key === 'Enter' && e.shiftKey) {
      return;
    }
  };

  const handleFileInput = (e) => {
    let newFiles = [];
    Array.from(e.target.files).map((d) => {
      if (!chats.attachLimit.photo) {
        if (d.type.startsWith('image/')) {
          return ErrorToast('Image upload is not available on your tier.');
        }
      }
      if (!chats.attachLimit.video) {
        if (d.type.startsWith('video/')) {
          return ErrorToast('Video upload is not available on your tier.');
        }
      }
      if (!chats.attachLimit.document) {
        if (d.type.startsWith('application/')) {
          return ErrorToast('Document upload is not available on your tier.');
        }
      }
      newFiles.push(
        new File(
          [d],
          `firebase/${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
            .getDate()
            .toString()
            .padStart(2, '0')}_${new Date().getHours().toString().padStart(2, '0')}-${new Date()
            .getMinutes()
            .toString()
            .padStart(2, '0')}-${new Date().getSeconds().toString().padStart(2, '0')}${d.name}`
        )
      );
    });
    setFile(newFiles);
  };

  const getLimits = () => {
    let accept = '';
    if (chats?.attachLimit?.document) {
      accept = '.pdf,.doc,.docx,.ppt';
    }
    if (chats?.attachLimit?.photo) {
      if (accept === '') accept = 'image/*';
      else accept += ',image/*';
    }
    if (chats?.attachLimit?.video) {
      if (accept === '') accept = 'video/*';
      else accept += ',video/*';
    }
    return accept;
  };

  const onRemoveFile = (index) => {
    setFile((prev) => {
      const newPrev = [...prev];
      newPrev.splice(index, 1);
      return newPrev;
    });
  };

  useEffect(() => {
    return () => {
      onBlurInput();
    };
  }, []);

  if (isDeleted)
    return (
      <div className="disableInputDiv">
        <span>This user was deleted there account</span>
      </div>
    );

  if (!currentUserToUserChatEnable)
    return (
      <div className="disableInputDiv">
        <span>Your user to user chat is disable</span>
      </div>
    );

  return userToUserChatEnable ? (
    <div className="new-input">
      <div style={{ opacity: typing ? 1 : 0 }}>
        <span className="typing">Typing .....</span>
      </div>
      {file.length > 0 && (
        <div className="att-div">
          {file.map((e, index) => (
            <AttachmentItem file={e} key={index} disabled={isLoading} onDelete={() => onRemoveFile(index)} />
          ))}
        </div>
      )}
      {isLoading && file.length > 0 && <div className="progress-infinite"></div>}
      <div style={{ height: '48px', display: 'flex', alignItems: 'center' }}>
        <div className="input-div">
          <textarea
            disabled={isLoading}
            id="chatInput_chatInput_chatInput"
            type="text"
            onChange={(e) => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={onBlurInput}
            onFocus={onFocusInput}
            value={text}
            placeholder="Write your message...."
          ></textarea>
          {(chats?.attachLimit?.document || chats?.attachLimit?.photo || chats?.attachLimit?.video) && (
            <label htmlFor="file-upload">
              <input
                disabled={isLoading}
                style={{ display: 'none' }}
                id="file-upload"
                type="file"
                accept={getLimits()}
                multiple
                onChange={handleFileInput}
              />
              <GrAttachment size={20} />
            </label>
          )}
        </div>
        <button disabled={isLoading} onClick={handleSend} className="send-btn">
          <BsSendFill color="white" size={22} />
        </button>
      </div>
    </div>
  ) : (
    <div className="disableInputDiv">
      <span>This user disable there user to user messages</span>
    </div>
  );
};

export default Input;

const AttachmentItem = ({ file, onDelete, disabled }) => {
  const getFileType = (fileExt) => {
    if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'jfif') {
      return 'image';
    } else if (fileExt === 'mp4' || fileExt === '3gp' || fileExt === 'flv' || fileExt === 'mkv') {
      return 'video';
    } else if (fileExt === 'pdf') {
      return 'pdf';
    } else if (fileExt === 'pptx' || fileExt === 'ppt') {
      return 'pptx';
    } else if (fileExt === 'doc' || fileExt === 'docx') {
      return 'doc';
    } else {
      return 'others';
    }
  };

  const getAttachedIcon = (file) => {
    try {
      const fileExtSplit = file.name.split('.');
      const fileExt = fileExtSplit[fileExtSplit.length - 1];
      const fileType = getFileType(fileExt);
      switch (fileType) {
        case 'image':
          return URL.createObjectURL(file);
        case 'video':
          return videoImg;
        case 'pdf':
          return pdfImg;
        case 'pptx':
          return pptImg;
        case 'doc':
          return wordImg;
        default:
          return docImg;
      }
    } catch (e) {
      console.log(e);
      return 'https://dummyimage.com/500x500/000/fff.jpg&text=File';
    }
  };

  return (
    <div className="att">
      <img style={{ height: '100%' }} src={getAttachedIcon(file)} alt={file.name} />
      <button disabled={disabled} className="att-close-btn" onClick={onDelete}>
        <MdOutlineClose size={16} />
      </button>
    </div>
  );
};
