/* eslint-disable react/jsx-boolean-value */
import ReactECharts from 'echarts-for-react';

// const colors = ['#468b47','#90ee90', '#edf9eb',"#385239"];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const AdvertisementRevenueBarChartCard = () => {
  const chartOption = {
    title: {
      text: 'Advertisement Revenue',
      left: 'left',
      textStyle: {
        color: '#468b47',
        lineHeight: 16,
      },
    },
    tooltip: {
      trigger: 'item',
    },
    xAxis: {
      type: 'category',
      data: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      axisTick: { show: false },
      axisLabel: {
        color: '#468b47', // Green color for x-axis labels
        fontWeight: 'bold',
      },
      axisLine: {
        show: false, // Remove x-axis line
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#468b47', // Green color for x-axis labels
      },
    },
    series: [
      {
        data: [120, 200, 150, 80, 70, 110, 130, 32, 11, 341, 444, 189],
        type: 'bar',
        itemStyle: {
          color: '#468b47', // Green color for bars
          borderRadius: 4,
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        border: '1px solid #fcbaba',
      }}
    >
      <select style={{ position: 'absolute', right: '12px', top: '12px' }}>
        <option value="option 1">option 1</option>
        <option value="option 2">option 2</option>
        <option value="option 3">option 3</option>
      </select>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        option={chartOption}
        style={{ height: '400px', minHeight: '300px' }}
      />
    </div>
  );
};
export default AdvertisementRevenueBarChartCard;
