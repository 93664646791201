import { IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '400px',
};

const TableView = ({ data, onEdit }) => {
  const { formatDateTime } = useUserTimeZone();

  const headData = ['Privacy Policy', 'Term Of Condition', 'Created At', 'Actions'];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {data?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle, maxWidth: '500px' }}>{e?.privacyPolicy || e?.termOfCondition}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle, maxWidth: '500px' }}>{e?.termOfCondition}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <div
                style={{
                  display: 'flex',
                }}
              >
                <IconButton onClick={() => onEdit(e)}>
                  <EditIcon />
                </IconButton>
              </div>
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
