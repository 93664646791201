import React, { useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Button } from '@mui/material';
import Messages from './Messages';
import Input from './Input';
import NavRight from './NavRight';
import { changeUser } from '../../store/ChatSlice';
import { db } from '../../firebase';
import { changeChatResponsiveToggle } from '../../store/ToggleSlice';

const Chat = ({ isSupport, handleClose, open }) => {
  const { chatId, chatMode, user, sid } = useSelector((state) => state.chats);
  const { chatResponsiveToggle } = useSelector((state) => state.toggleState);
  const { currentUser } = useSelector((state) => state.auth);
  const [messages, setMessages] = useState([]);
  const [startChatLoading, setStartChatLoading] = useState(false);
  const dateRef = useRef(null);
  const dispatch = useDispatch();

  const onChatResponsiveToggle = () => {
    dispatch(changeChatResponsiveToggle());
  };

  const handleStartChat = async () => {
    if (currentUser?.uid === user?.uid) {
      console.log('DEBUG handleStartChat both uid are same', currentUser?.uid, user?.uid);
      return;
    }
    setStartChatLoading(true);
    let combinedId;
    const chatID = currentUser?.uid > user.uid ? currentUser?.uid + user.uid : user.uid + currentUser?.uid;
    combinedId = currentUser?.uid > user.uid ? currentUser?.uid + user.uid : user.uid + currentUser?.uid;
    if (chatMode === 'support') {
      combinedId = chatID;
    }
    try {
      // check whether the group exists, if not CREATE
      const res = await getDoc(doc(db, 'chats', chatID));
      const res2 = await getDoc(doc(db, `${chatMode}Chats`, currentUser?.uid));
      const res3 = await getDoc(doc(db, `${chatMode}Chats`, user.uid));
      if (!res2.exists()) {
        await setDoc(doc(db, `${chatMode}Chats`, currentUser?.uid), {});
      }
      if (!res3.exists()) {
        await setDoc(doc(db, `${chatMode}Chats`, user.uid), {});
      }
      if (!res.exists()) {
        await setDoc(doc(db, 'chats', chatID), { messages: [] });
        // create user chats
        await updateDoc(doc(db, `${chatMode}Chats`, currentUser?.uid), {
          [`${combinedId}.userInfo`]: {
            uid: user.uid,
            displayName: user.displayName,
            photo: user.photoURL,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        });
        await updateDoc(doc(db, `${chatMode}Chats`, user.uid), {
          [`${combinedId}.userInfo`]: {
            uid: currentUser?.uid,
            displayName: currentUser?.displayName,
            photo: currentUser?.photoURL,
          },
          [`${combinedId}.date`]: serverTimestamp(),
        });
      }
      dispatch(
        changeUser({
          user: {
            uid: user.uid,
            displayName: user.displayName,
            photo: user.photoURL,
          },
          id: combinedId,
          sid: user.uid,
        })
      );
    } catch (e) {
      console.log(e);
    } finally {
      setStartChatLoading(false);
      onChatResponsiveToggle();
    }
  };

  if (chatId === 'START_CHAT' && sid === 'START_CHAT') {
    return (
      <div className="chat">
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div>
            <MenuOpenIcon
              onClick={onChatResponsiveToggle}
              sx={{
                justifySelf: 'end',
                marginTop: '30px',
                marginLeft: '30px',
                color: '#01B855',
                cursor: 'pointer',
                float: 'left',
                display: 'none',
                '@media (max-width: 640px)': {
                  display: 'block',
                },
              }}
            />
            <CloseIcon
              onClick={handleClose}
              sx={{
                justifySelf: 'end',
                marginTop: '30px',
                marginRight: '30px',
                color: '#01B855',
                cursor: 'pointer',
                float: 'right',
              }}
            />
          </div>
          <div
            style={{
              flex: '1 1 0%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h3>Start new chat with {user.displayName}</h3>
            <Button disabled={startChatLoading} onClick={handleStartChat} variant="contained">
              Start Chat
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={chatResponsiveToggle ? 'chat chatResponsiveToggle' : 'chat'}>
      {chatId ? (
        <>
          <NavRight handleClose={handleClose} messages={messages} setMessages={setMessages} isSupport={isSupport} />
          <Messages open={open} dateRef={dateRef} messages={messages} setMessages={setMessages} />
          <Input />
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div>
            <MenuOpenIcon
              onClick={onChatResponsiveToggle}
              sx={{
                justifySelf: 'end',
                marginTop: '30px',
                marginLeft: '30px',
                color: '#01B855',
                cursor: 'pointer',
                float: 'left',
                display: 'none',
                '@media (max-width: 640px)': {
                  display: 'block',
                },
              }}
            />
            <CloseIcon
              onClick={handleClose}
              sx={{
                justifySelf: 'end',
                marginTop: '30px',
                marginRight: '30px',
                color: '#01B855',
                cursor: 'pointer',
                float: 'right',
              }}
            />
          </div>
          <div style={{ flex: '1 1 0%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1>Welcome to {chatMode} chat</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
