import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Switch } from '@mui/material';

import { CustomTableCellForBody, CustomTableView } from '../../../components/UI/CustomTableView';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, disabled, updateValue }) => {
  const headData = ['Name', 'Status'];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {/* userId */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>User Id</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'userId'}
              onChange={() => updateValue('userId', !data?.userId)}
              checked={data?.userId}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* email */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>User Email</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'email'}
              onChange={() => updateValue('email', !data?.email)}
              checked={data?.email}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* phoneNumber */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>User Phone Number</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'phoneNumber'}
              onChange={() => updateValue('phoneNumber', !data?.phoneNumber)}
              checked={data?.phoneNumber}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* tier */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>User Type (tier)</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'tier'}
              onChange={() => updateValue('tier', !data?.tier)}
              checked={data?.tier}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* activeSubscriptionsCount */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>Active Subscriptions Count</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'activeSubscriptionsCount'}
              onChange={() => updateValue('activeSubscriptionsCount', !data?.activeSubscriptionsCount)}
              checked={data?.activeSubscriptionsCount}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* activeLoyaltyCount */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>Active Loyalty Card Count</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'activeLoyaltyCount'}
              onChange={() => updateValue('activeLoyaltyCount', !data?.activeLoyaltyCount)}
              checked={data?.activeLoyaltyCount}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* activeGiftCount */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>Active Gift Card Count</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'activeGiftCount'}
              onChange={() => updateValue('activeGiftCount', !data?.activeGiftCount)}
              checked={data?.activeGiftCount}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* activeRewardsCount */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>Active Reward Certificate Count</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'activeRewardsCount'}
              onChange={() => updateValue('activeRewardsCount', !data?.activeRewardsCount)}
              checked={data?.activeRewardsCount}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* safalBuddiesCount */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>User SafalBuddies Count</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'safalBuddiesCount'}
              onChange={() => updateValue('safalBuddiesCount', !data?.safalBuddiesCount)}
              checked={data?.safalBuddiesCount}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* mutualSafalBuddiesCount */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>Mutual SafalBuddies Count</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'mutualSafalBuddiesCount'}
              onChange={() => updateValue('mutualSafalBuddiesCount', !data?.mutualSafalBuddiesCount)}
              checked={data?.mutualSafalBuddiesCount}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* roomFeedsCount */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>SafalRoom User's Feeds Count</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'roomFeedsCount'}
              onChange={() => updateValue('roomFeedsCount', !data?.roomFeedsCount)}
              checked={data?.roomFeedsCount}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* currentMonthLeaderboardPosition */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>Current Month Leaderboard Position</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'currentMonthLeaderboardPosition'}
              onChange={() => updateValue('currentMonthLeaderboardPosition', !data?.currentMonthLeaderboardPosition)}
              checked={data?.currentMonthLeaderboardPosition}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
        {/* lastLoginDateTime */}
        <TableRow>
          <CustomTableCellForBody align="center">
            <p style={tableCellPStyle}>User's Last Login Date-Time</p>
          </CustomTableCellForBody>
          <CustomTableCellForBody align="center">
            <Switch
              disabled={disabled}
              name={'lastLoginDateTime'}
              onChange={() => updateValue('lastLoginDateTime', !data?.lastLoginDateTime)}
              checked={data?.lastLoginDateTime}
              color="success"
            />
          </CustomTableCellForBody>
        </TableRow>
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
