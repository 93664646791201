/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TablePagination, Tooltip } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import { deleteAssetModuleCard } from '../../../services/Service';

const AssetModuleLoyaltyGiftReward = ({ fetchData, data, paginationOption }) => {
  const { formatDateTime } = useUserTimeZone();
  useEffect(() => {
    fetchData(paginationOption.page, paginationOption.limit, paginationOption.type, paginationOption.companyId);
  }, []);

  const onDelete = async (row) => {
    try {
      const res = await deleteAssetModuleCard(row._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        await fetchData(
          paginationOption.page,
          paginationOption.limit,
          paginationOption.type,
          paginationOption.companyId
        );
      }
    } catch (err) {
      console.log('AssetModuleLoyaltyGiftReward onDelete', err.message);
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const handleChangePage = async (e, value) => {
    await fetchData(value + 1, paginationOption.limit, paginationOption.type, paginationOption.companyId);
  };
  const handleChangeRowsPerPage = async (e) => {
    await fetchData(paginationOption.page, e.target.value, paginationOption.type, paginationOption.companyId);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead style={{ backgroundColor: '#eaffea', color: '#007b32' }}>
          <TableRow>
            <TableCell style={{ color: '#007b32' }}>Name</TableCell>
            <TableCell style={{ color: '#007b32' }}>Type</TableCell>
            <TableCell style={{ color: '#007b32' }}>Company Name</TableCell>
            <TableCell style={{ color: '#007b32' }}>Description</TableCell>
            <TableCell style={{ color: '#007b32' }}>Created At</TableCell>
            <TableCell style={{ color: '#007b32' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((e, index) => (
            <TableRow key={index}>
              <TableCell>{e?.name}</TableCell>
              <TableCell>{e?.type}</TableCell>
              <TableCell>{e?.companyId?.name}</TableCell>
              <TableCell>
                <Tooltip title={e?.description}>
                  <p style={{ width: '200px', maxHeight: '80px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {e?.description}
                  </p>
                </Tooltip>
              </TableCell>
              <TableCell>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => onDelete(e)} style={{ marginLeft: '10px' }} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
        component="div"
        count={paginationOption.total}
        rowsPerPage={paginationOption.limit}
        page={paginationOption.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default AssetModuleLoyaltyGiftReward;
