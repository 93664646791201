import { IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete, rawPlanData }) => {
  const { formatDateTime } = useUserTimeZone();

  // productId.name
  const headData = ['Plan Name', 'Tier', 'Features', 'Created At', 'Action'];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {data.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
        {data?.map((e, index) => {
          const planName = rawPlanData.find((exx) => exx.tier === e.tier);
          return (
            <TableRow key={index}>
              {/* Plan Name */}
              <CustomTableCellForBody align="center">
                <p style={tableCellPStyle}>{planName?.name}</p>
              </CustomTableCellForBody>
              <CustomTableCellForBody align="center">
                <p style={tableCellPStyle}>{e?.tier?.toString()}</p>
              </CustomTableCellForBody>
              <CustomTableCellForBody align="center">
                <p style={tableCellPStyle}>
                  {e?.feature?.map((ex, indexx) => (
                    <>
                      <span key={indexx}>{ex}</span>
                      <br />
                    </>
                  ))}
                </p>
              </CustomTableCellForBody>
              <CustomTableCellForBody align="center">
                <p style={tableCellPStyle}>
                  {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
                </p>
              </CustomTableCellForBody>
              <CustomTableCellForBody align="center">
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  {/* <IconButton onClick={() => onDelete(e)}>
                  <DeleteIcon />
                </IconButton> */}
                  <IconButton onClick={() => onEdit(e)}>
                    <EditIcon />
                  </IconButton>
                </div>
              </CustomTableCellForBody>
            </TableRow>
          );
        })}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
