import { Button, IconButton, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { safalAiSend } from '../../services/Service';
import { changeAddSubscriptionAnimation } from '../../store/ToggleSlice';

const amountPrompts = [
  'Could you kindly provide the amount for the subscription?',
  'would you mind specifying the amount for the subscription?',
  'could you please enter the amount for the subscription?',
];

const InputBox = ({ type, speechRecognition, listening, fetchChatList, transcript, resetTranscript, lastMessage }) => {
  const [textValue, setTextValue] = useState('');
  const [micStartFLag, setMicStartFLag] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.login.user);

  const isAmountPrompts = amountPrompts.includes(lastMessage);

  const onReset = () => {
    setTextValue('');
    resetTranscript();
  };

  const onSendValue = async (e) => {
    e.preventDefault();
    let newTextValue = textValue;
    if (isAmountPrompts) {
      if (userState.country === 'India') {
        if (!newTextValue.toLowerCase().includes('inr')) newTextValue = `${newTextValue} INR`;
      } else {
        if (!newTextValue.includes('$') || !newTextValue.toLowerCase().includes('usd'))
          newTextValue = `${newTextValue} USD`;
      }
    }
    onSendValueAPI(newTextValue);
  };

  const onSendValueAPI = async (value) => {
    try {
      const res = await safalAiSend({ text: value, type: type });
      if (res.data.message === 'subscription created') {
        dispatch(changeAddSubscriptionAnimation());
      }
      if (res.data.message?.includes('asset created')) {
        dispatch(changeAddSubscriptionAnimation());
      }
      fetchChatList();
      onReset();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onMicStart = async () => {
    await speechRecognition.startListening();
    setMicStartFLag(true);
  };

  useEffect(() => {
    if (micStartFLag === true && listening === false) {
      onSendValueAPI(transcript);
    }
  }, [micStartFLag, listening]);

  return (
    <form onSubmit={onSendValue} className="user-input">
      <input
        className="ai-user-input"
        type="text"
        value={listening ? transcript : textValue}
        // value={textValue}
        placeholder="Write your message ..."
        onChange={(e) => {
          e.preventDefault();
          setTextValue(e.target.value);
        }}
      />
      <Tooltip title="Microphone">
        <IconButton
          onClick={listening ? speechRecognition.stopListening : onMicStart}
          className={`mic-btn ${listening && 'on'}`}
        >
          <MicIcon sx={{ color: 'white' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Send">
        <IconButton type="submit" className="send-btn">
          <SendIcon sx={{ color: 'white' }} />
        </IconButton>
      </Tooltip>
    </form>
  );
};
export default InputBox;
