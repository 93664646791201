/* eslint-disable react/jsx-boolean-value */
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { getSubscriptionDifferentPaymentTier } from '../../../../services/Service';
import { formatNumberToShort } from '../../../../utils/general';

// const colors = ['#468b47','#90ee90', '#edf9eb'];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const SubscriptionDifferentPaymentTier = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mode, setMode] = useState('ThisYear'); // ThisYear, LastYear

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const res = await getSubscriptionDifferentPaymentTier(filter);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let startDate = '';
    let endDate = '';

    switch (mode) {
      case 'ThisYear':
        startDate = moment().startOf('year').toISOString();
        endDate = moment().endOf('year').toISOString();
        break;
      case 'LastYear':
        startDate = moment().subtract(1, 'year').startOf('year').toISOString();
        endDate = moment().subtract(1, 'year').endOf('year').toISOString();
        break;

      default:
        break;
    }

    fetchData({ endDate, startDate });
  }, [mode]);

  const dataKeys = data?.map((e) => e?.name);
  const dataActive = data?.map((e) => e?.activeCount);
  const dataInactive = data?.map((e) => e?.inactiveCount);
  const dataCancelled = data?.map((e) => e?.cancelledCount);

  const chartOption = {
    title: {
      text: 'Subscribers on different Payment Tiers',
      left: 'left',
      textStyle: {
        color: '#468b47',
        lineHeight: 16,
      },
    },
    tooltip: {
      trigger: 'item',
    },

    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: dataKeys.length > 0 ? dataKeys : ['Free', 'Basic', 'Premium'],
        axisLabel: {
          color: '#468b47', // Green color for x-axis labels
          fontWeight: 'bold',
        },
        axisLine: {
          show: false, // Remove x-axis line
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          color: '#468b47', // Green color for x-axis labels
        },
      },
    ],
    series: [
      {
        name: 'Active',
        type: 'bar',
        barGap: 0,
        // label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data: dataActive.length > 0 ? dataActive : [0, 0, 0],
        itemStyle: {
          color: '#90ee90',
          borderColor: '#468b47',
          borderWidth: 1,
          borderRadius: 4,
        },
      },
      {
        name: 'Inactive',
        type: 'bar',
        // label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data: dataInactive.length > 0 ? dataInactive : [0, 0, 0],
        itemStyle: {
          color: '#c4e9c4',
          borderColor: '#468b47',
          borderWidth: 1,
          borderRadius: 4,
        },
      },
      {
        name: 'Cancelled',
        type: 'bar',
        // label: labelOption,
        emphasis: {
          focus: 'series',
        },
        data: dataCancelled.length > 0 ? dataCancelled : [0, 0, 0],
        itemStyle: {
          color: '#468b47',
          borderColor: '#468b47',
          borderWidth: 1,
          borderRadius: 4,
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <select
        value={mode}
        style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 1 }}
        onChange={(e) => setMode(e.target.value)}
      >
        <option value="ThisYear">This Year</option>
        <option value="LastYear">Last Year</option>
      </select>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        option={chartOption}
        style={{ height: '400px', minHeight: '300px' }}
      />
      <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <LegendItem color="#90ee90" text="Active" />
        <LegendItem color="#c4e9c4" text="Inactive" />
        <LegendItem color="#468b47" text="Cancelled" />
      </div>
    </div>
  );
};
export default SubscriptionDifferentPaymentTier;

const LegendItem = ({ color, text }) => {
  return (
    <div style={{ display: 'flex', gap: '6px', alignItems: 'center', flex: 'none' }}>
      <div style={{ backgroundColor: color, borderRadius: '9999px', height: '18px', width: '18px' }} />
      <span style={{ fontSize: '14px', marginTop: '2px' }}>{text}</span>
    </div>
  );
};
