import { Button, IconButton, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useState } from 'react';

const initFilter = {
  status: '',
};

const FilterMenu = ({ onFilterReset, filterState, onFilterApply }) => {
  const [filterStateLocal, setFilterStateLocal] = useState({ ...initFilter });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setFilterStateLocal(filterState);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterStateLocal({ ...initFilter });
  };

  const handleFilterApply = () => {
    onFilterApply(filterStateLocal);
    handleClose();
  };

  const isThisResetState = filterState?.status !== '';

  const handleFilterReset = () => {
    isThisResetState && onFilterReset();
    setFilterStateLocal(initFilter);
    handleClose();
  };

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          size="small"
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            height: '40px',
            width: '40px',
            backgroundColor: '#027c34',
            '&:hover': { backgroundColor: '#027d35' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '300px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        <TextField
          value={filterStateLocal?.status}
          onChange={(e) => setFilterStateLocal((prev) => ({ ...prev, status: e.target.value }))}
          sx={{
            my: '8px',
          }}
          select
          label="Status"
          size="small"
          fullWidth
          variant="outlined"
        >
          <MenuItem value="Active">Active</MenuItem>
          <MenuItem value="Inactive">Inactive</MenuItem>
        </TextField>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Close
          </Button>
          <Button
            sx={{
              textTransform: 'capitalize',
              borderRadius: '30px',
              backgroundColor: '#027c34',
              '&:hover': { backgroundColor: '#027d35' },
            }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{
              textTransform: 'capitalize',
              borderRadius: '30px',
              backgroundColor: '#027c34',
              '&:hover': { backgroundColor: '#027d35' },
            }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default FilterMenu;
