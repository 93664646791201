/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import Sidebar from '../Chat/Sidebar';
import Chat from '../Chat/Chat';
import { changeChatMode } from '../../store/ChatSlice';

const UserChatModal = ({ open, handleClose, buddyUser }) => {
  const dispatch = useDispatch();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
    borderRadius: '10px',
    height: '80%',
    display: 'flex',
    overflow: 'hidden',
    '@media (max-width: 640px)': {
      width: '98%',
      height: '98%',
    },
  };

  React.useEffect(() => {
    dispatch(changeChatMode('user'));
  }, [open]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Sidebar open={open} handleClose={handleClose} buddyUser={buddyUser} />
          <Chat open={open} handleClose={handleClose} />
        </Box>
      </Modal>
    </>
  );
};

export default UserChatModal;
