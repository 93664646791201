import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { getDatabase } from 'firebase/database';
import { onBackgroundMessage } from 'firebase/messaging/sw';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

// export const getFireToken = (setToken) => {
//   return getToken(messaging, {
//     vapidKey: process.env.REACT_APP_VAPIDKEY,
//   })
//     .then((currentToken) => {
//       console.log('firebasetwt2', currentToken);

//       if (currentToken) {
//         console.log('firebasetwtssss', currentToken);

//         console.log('current token for client: ', currentToken);
//         setToken(currentToken);
//         // Track the token -> client mapping, by sending to backend server
//         // show on the UI that permission is secured
//       } else {
//         console.log('No registration token available. Request permission to generate one.');
//         setToken(null);
//         // shows on the UI that permission is required
//       }
//     })
//     .catch((err) => {
//       console.log('firebasetwt2 An error occurred while retrieving token. ', err);
//       // catch error while creating client token
//     });
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
// export const messaging = (async () => {
//   try {
//     const isSupportedBrowser = await isSupported();
//     console.log('SAFAL_DEBUG Firebase getMessaging working');
//     if (isSupportedBrowser) {
//       return getMessaging(app);
//     }
//     console.log('SAFAL_DEBUG Firebase getMessaging not supported this browser');
//     return null;
//   } catch (err) {
//     console.log(err);
//     return null;
//   }
// })();
// export const messaging = getMessaging(app);
export const realTimeDatabase = getDatabase(app);

// onMessage(messaging, (payload) => {
//   console.log('Message received', payload);
// });

// onBackgroundMessage(messaging, (payload) => {
//   console.log('[firebase-messaging-sw.js] Received background message ', payload);
//   // Customize notification here
//   const notificationTitle = 'Background Message Title';
//   const notificationOptions = {
//     body: 'Background Message body.',
//     icon: '/firebase-logo.png'
//   };

//   // self.registration.showNotification(notificationTitle,
//   //   notificationOptions);
// });
