/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select';
import {
  Autocomplete,
  Checkbox,
  Fab,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import toast from 'react-hot-toast';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space } from 'antd';

import {
  addAssetModuleAccount,
  deleteAssetModuleAccount,
  getCardScheme,
  getPaymentInstrument,
  getPaymentMode,
  updateAssetModuleAccount,
  uploadAssetModuleImages,
} from '../../../services/Service';
import AddCustomAssetModal from './AddCustomAssetModal';
import TopTitleAction from './TopTitleAction';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import cardBack from '../../../assets/cardBack.png';
import cardFront from '../../../assets/cardFront.png';
import CustomSelect from '../../../components/UI/CustomSelect';
import { changeAddSubscriptionAnimation } from '../../../store/ToggleSlice';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';
import AddPaymentInstrumentModal from '../../../components/Modals/AddPaymentInstrumentModal';
import CustomSelectWithImage from '../../../components/UI/CustomSelectWithImage';
import CustomRating from '../../../components/UI/CustomRating';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '95vh',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  '@media (max-width: 640px)': {
    width: '95vw',
    height: '95vh',
    overflow: 'auto',
  },
};

const resetFieldValue = {
  cardId: '',
  cardNumber: '',
  points: '',
  frontImage: '',
  backImage: '',
  expiryDate: '',
  description: '',
  comments: '',
  status: '',
  paymentInstrument: '',
  safals: 0, // optional, 1 safal, -1, unsafal, 0 nutral, default 0,
  rating: 0, // optional, 0 to 5
  review: '', // optional, default empty string ""
};

const resetCompanyValue = {
  name: '',
  type: '',
  website: '',
  country: '',
  description: '',
};

function replaceFirstFourChars(input) {
  if (input.length <= 4) {
    return '*'.repeat(input.length);
  }
  return '*'.repeat(input.length - 4) + input.slice(-4);
}

export default function AddAssetModal({
  cardData,
  open,
  onClose,
  type,
  editData,
  isDisplayMode,
  onChangeLog,
  paymentCardSchemeList,
  paymentInstrumentList,
  paymentModeList,
  fetchPaymentModeInfo,
}) {
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const [fieldValue, setFieldValue] = React.useState(resetFieldValue);
  const [selectedCompany, setSelectedCompany] = React.useState(resetCompanyValue);
  const { user: profile } = useSelector((state) => state.login);
  const [selectedFrontImage, setSelectedFrontImage] = React.useState(null);
  const [selectedBackImage, setSelectedBackImage] = React.useState(null);
  const [isAddCompanyModalOpen, setIsAddCompanyModalOpen] = React.useState(false);
  const [isExpiryDateDisable, setIsExpiryDateDisable] = React.useState(false);
  const [isDisplayModeActive, setIsDisplayModeActive] = React.useState(true);
  const [cardError, setCardError] = React.useState(false);
  const [companyIdError, setCompanyIdError] = React.useState(false);

  const [cardNumberError, setCardNumberError] = React.useState(false);
  // const [cardNumberVisibility, setCardNumberVisibility] = React.useState(false);
  const [pointError, setPointError] = React.useState('');
  const [expDateError, setExpDateError] = React.useState(false);
  const [cardDataList, setCardDataList] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isShowCardNumber, setIsShowCardNumber] = React.useState(false);

  const [selectedPaymentMode, setSelectedPaymentMode] = React.useState('');
  const [addPaymentInstrumentModalOpen, setAddPaymentInstrumentModalOpen] = React.useState(false);
  const [selectedPaymentInstrumentList, setSelectedPaymentInstrumentList] = React.useState([]);

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = React.useState({
    open: false,
    data: null,
  });

  const onCloseThis = () => {
    setFieldValue(resetFieldValue);
    setSelectedCompany(resetCompanyValue);
    setSelectedFrontImage(null);
    setSelectedBackImage(null);
    setIsExpiryDateDisable(false);
    setIsDisplayModeActive(true);
    setCardDataList([]);
    setIsSubmitting(false);
    onClose();
    setPointError('');
    setExpDateError(false);
    setCardNumberError(false);
    setCardError(false);
    setCompanyIdError(false);
    setSelectedPaymentMode('');
    setSelectedPaymentInstrumentList([]);
  };
  const handleClose = () => {
    if (isDisplayModeActive) {
      onCloseThis();
      return;
    }

    if (
      fieldValue.cardId === '' &&
      fieldValue.cardNumber === '' &&
      fieldValue.points === '' &&
      fieldValue.expiryDate === '' &&
      fieldValue.description === '' &&
      fieldValue.backImage === '' &&
      fieldValue.frontImage === '' &&
      fieldValue.comments === '' &&
      fieldValue.status === '' &&
      fieldValue.paymentInstrument === '' &&
      fieldValue.review === '' &&
      fieldValue.rating === 0 &&
      fieldValue.safals === 0
    ) {
      onCloseThis();
    } else {
      Swal.fire({
        text: 'Are you sure you want to close this?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        appendTo: 'body',
        customClass: {
          container: 'my-swal', // Add a custom class for styling
        },
        onBeforeOpen: () => {
          // Set a higher zIndex for the Swal modal
          document.querySelector('.my-swal').style.zIndex = 999999;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          onCloseThis();
        }
      });
    }
  };

  React.useEffect(() => {
    setCardDataList((prev) => [...prev, ...cardData]);
  }, [cardData]);

  React.useEffect(() => {
    if (isDisplayModeActive) {
      setIsShowCardNumber(false);
    } else {
      setIsShowCardNumber(true);
    }
  }, [isDisplayModeActive]);

  React.useEffect(() => {
    if (!open) return;
    if (isDisplayMode !== undefined) {
      setIsDisplayModeActive(isDisplayMode);
    }
    if (!editData) return;
    const initFieldValue = {
      cardId: editData?.cardId._id || '',
      cardNumber: editData?.cardNumber || '',
      points: editData?.points || '',
      frontImage: editData?.frontImage || '',
      backImage: editData?.backImage || '',
      expiryDate: editData?.expiryDate === null ? '' : moment(editData?.expiryDate).format('YYYY-MM-DD'),
      description: editData?.description || '',
      comments: editData?.comments || '',
      status: editData?.status !== undefined ? editData?.status : '',
      pin: editData?.pin || '',
      paymentInstrument: editData?.paymentInstrument?._id,
      safals: editData?.safals !== undefined ? editData?.safals : 0,
      rating: editData?.rating !== undefined ? editData?.rating : 0,
      review: editData?.review || '',
    };

    if (editData?.expiryDate !== null) setIsExpiryDateDisable(true);
    const comData = editData?.companyId;
    const cardDataX = editData?.cardId;

    if (cardDataX) {
      setCardDataList((prev) => [...prev, cardDataX]);
    }

    const initCompanyValue = {
      name: comData?.name || '',
      type: comData?.type || '',
      website: comData?.website || '',
      country: comData?.country || '',
      description: comData?.description || '',
    };
    setFieldValue(initFieldValue);
    setSelectedCompany(initCompanyValue);
    if (editData?.paymentInstrument) {
      setSelectedPaymentMode(editData?.paymentInstrument?.paymentModeId);
      const newPaymentInstrumentList = paymentInstrumentList?.filter(
        (ex) => ex?.paymentModeId?._id === editData?.paymentInstrument?.paymentModeId
      );
      setSelectedPaymentInstrumentList(newPaymentInstrumentList);
    }
  }, [open]);

  const onChangeCardId = (value) => {
    const findCard = cardData.find((ex) => ex._id === value);
    setFieldValue((prev) => ({ ...prev, cardId: value, description: findCard?.description || '' }));
    setSelectedCompany({
      name: findCard?.companyId?.name,
      type: findCard?.companyId?.type,
      website: findCard?.companyId?.website,
      country: findCard?.companyId?.country,
      description: findCard?.companyId?.description,
    });
    setCardError(false);
  };
  const onChangeField = (e) => {
    if (e.target.value === '') {
      setExpDateError(false);
    }
    if (e.target.name === 'status') {
      setFieldValue((prev) => ({ ...prev, [e.target.name]: e.target.value === 'active' }));
      return;
    }
    setFieldValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name === 'cardId') {
      const findCard = cardData.find((ex) => ex._id === e.target.value);
      setSelectedCompany({
        name: findCard?.companyId?.name,
        type: findCard?.companyId?.type,
        website: findCard?.companyId?.website,
        country: findCard?.companyId?.country,
        description: findCard?.companyId?.description,
      });
      setCardError(false);
    }

    if (e.target.name === 'cardNumber') {
      const alphanumericInput = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove special characters
      setFieldValue((prev) => ({ ...prev, [e.target.name]: alphanumericInput }));
      const isValid = /^[a-zA-Z0-9]+$/.test(alphanumericInput); // Check if input contains only numbers and alphabets
      setCardNumberError(!isValid);

      if (alphanumericInput.length < 4 || alphanumericInput.length > 40) {
        setCardNumberError(true);
      } else {
        setCardNumberError(false);
      }
      return;
    }

    if (e.target.name === 'expiryDate') {
      const selectedDate = moment(e.target.value);
      // const currentDate = moment();
      // const oneDayBefore = moment().subtract(1, 'day');

      const selectedDateJS = selectedDate.isValid() ? selectedDate.endOf('day').toDate() : null;

      setFieldValue((prev) => ({ ...prev, [e.target.name]: selectedDateJS }));

      setExpDateError(false);
      // if (selectedDate.isValid() && selectedDate.isSameOrAfter(currentDate, 'day')) {
      //   setExpDateError(false);
      // } else {
      //   setExpDateError(true);
      //   setFieldValue((prev) => ({ ...prev, [e.target.name]: null }));
      //   if (selectedDate.isBefore(oneDayBefore, 'day')) {
      //     toast.error('Expiry date cannot be in the past.');
      //   } else {
      //     toast.error('Expiry date is invalid');
      //   }
      // }
    }
  };

  const onCloseAddPaymentInstrumentModal = async () => {
    setAddPaymentInstrumentModalOpen(false);
    const newListX = await fetchPaymentModeInfo();
    const newPaymentInstrumentList = newListX?.filter((ex) => ex?.paymentModeId?._id === selectedPaymentMode);
    setSelectedPaymentInstrumentList(newPaymentInstrumentList);
  };

  const onChangeExpiryDateDisable = () => {
    setIsExpiryDateDisable((prev) => {
      setFieldValue((prevx) => ({ ...prevx, expiryDate: null }));
      if (!prev) {
        setFieldValue((prevx) => ({ ...prevx, expiryDate: null }));
      }
      return !prev;
    });
  };

  const onValidate = () => {
    if (
      !fieldValue.cardId ||
      !fieldValue.cardNumber ||
      fieldValue.cardNumber.length < 4 ||
      (type === 'gift' && (!fieldValue.points || fieldValue.points.length < 1))
    ) {
      toast.error('Please fill in all the required fields.');
      setCompanyIdError(!fieldValue.cardId);
      setCardNumberError(!fieldValue.cardNumber);
      if (type === 'gift') {
        setPointError(!fieldValue.points);
      }
      return;
    }
    // if (!fieldValue.cardId) {
    //   setCardError(true);
    //   return;
    // }

    // if (!fieldValue.cardNumber) {
    //   setCardNumberError(true);
    //   return;
    // }

    !isDisplayModeActive && editData !== null ? onEditSubmit() : onSubmit();
  };

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);
      const uniqueId = uuidv4();

      // Check card number
      if (fieldValue.cardNumber.length < 4) return;

      const payload = {
        ...fieldValue,
        status: fieldValue.status === '' ? true : fieldValue.status,
        uniqueId, // Add the unique ID to the payload
      };

      if (selectedFrontImage) {
        const frontImage = await uploadAssetModuleImages(selectedFrontImage);
        if (frontImage.status !== 200) throw new Error('Front image upload failed, please try again');
        payload.frontImage = frontImage.data.url;
      }
      if (selectedBackImage) {
        const backImage = await uploadAssetModuleImages(selectedBackImage);
        if (backImage.status !== 200) throw new Error('Front image upload failed, please try again');
        payload.backImage = backImage.data.url;
      }

      const res = await addAssetModuleAccount(payload);
      if (res.status === 200) {
        dispatch(changeAddSubscriptionAnimation());
        toast.success(res.data.message);
        onCloseThis();
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message === "Cannot read property 'size' of undefined") {
        toast.error('The file type is unsupported');
      } else {
        toast.error(err?.response?.data?.message || err.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const onEditSubmit = async (password, uniqueId) => {
    try {
      setIsSubmitting(true);
      const payload = {
        ...fieldValue,
        _id: editData._id,
        uniqueId: editData.uniqueId,
      };

      if (selectedFrontImage) {
        const frontImage = await uploadAssetModuleImages(selectedFrontImage);
        if (frontImage.status !== 200) throw new Error('Front image upload failed, please try again');
        payload.frontImage = frontImage.data.url;
      }
      if (selectedBackImage) {
        const backImage = await uploadAssetModuleImages(selectedBackImage);
        if (backImage.status !== 200) throw new Error('Front image upload failed, please try again');
        payload.backImage = backImage.data.url;
      }
      const withOutPin = { ...payload };
      if (withOutPin.pin === '') {
        delete withOutPin.pin;
      }
      const res = await updateAssetModuleAccount(withOutPin);
      if (res.status === 200) {
        toast.success(res.data.message);
        onCloseThis();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDelete = async () => {
    try {
      setIsSubmitting(true);
      const res = await deleteAssetModuleAccount(editData._id);
      if (res.status === 200) {
        onDeleteModalReset();
        toast.success(res.data.message);
        onCloseThis();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onAddCustomCompany = () => {
    setIsAddCompanyModalOpen(true);
  };

  const onAddCompanyModalClose = () => {
    setIsAddCompanyModalOpen(false);
  };

  const addCustomCallBack = (data, companyObj) => {
    setCardDataList((prev) => [...prev, data]);
    setFieldValue((prev) => ({ ...prev, cardId: data._id, description: data?.description || '' }));
    setSelectedCompany(companyObj);
  };

  const onDeleteModalInit = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data,
    });
  };

  const onDeleteModalReset = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  const onDownloadImage = async (src) => {
    window.open(src, '_blank');
  };

  const titleString = isDisplayModeActive ? '' : editData === null ? 'Add' : 'Edit';
  const frontImageUrl = selectedFrontImage
    ? URL.createObjectURL(selectedFrontImage)
    : fieldValue?.frontImage?.length > 1
    ? fieldValue?.frontImage
    : cardFront;
  const backImageUrl = selectedBackImage
    ? URL.createObjectURL(selectedBackImage)
    : fieldValue?.backImage?.length > 1
    ? fieldValue?.backImage
    : cardBack;

  const handleInputChange = (event) => {
    const isValidInput = /^-?\d*\.?\d*$/.test(event.target.value);
    if (isValidInput || event.target.value === '') {
      onChangeField(event);

      const pointsValue = event.target.value.trim();
      const pointsLength = pointsValue.length;

      if (pointsLength < 1 || pointsLength > 30) {
        setPointError('Points should have between 1 and 30 characters');
        setFieldValue((prev) => ({ ...prev, points: '' })); // Clear the points value
      } else {
        setPointError('');
      }
    } else {
      setPointError('Only numbers and decimals are allowed');
    }
  };

  const isPinRequired = type === 'gift' && profile.country === 'United States';

  const fieldValueStatus = fieldValue.status === '' ? '' : fieldValue.status === true ? 'active' : 'inactive';

  const editDataExpiryDate = editData?.expiryDate && moment(editData?.expiryDate).format('YYYY-MM-DD');
  const isEditButtonEnable =
    fieldValue?.backImage !== editData?.backImage ||
    fieldValue?.frontImage !== editData?.frontImage ||
    fieldValue?.cardId !== editData?.cardId?._id ||
    fieldValue?.description !== editData?.description ||
    fieldValue?.points !== editData?.points ||
    fieldValue?.status !== editData?.status ||
    fieldValue?.comments !== editData?.comments ||
    fieldValue?.review !== editData?.review ||
    fieldValue?.safals !== editData?.safals ||
    fieldValue?.rating !== editData?.rating ||
    fieldValue?.pin !== editData?.pin ||
    fieldValue?.cardNumber !== editData?.cardNumber ||
    fieldValue?.paymentInstrument !== editData?.paymentInstrument?._id ||
    fieldValue?.expiryDate !== editDataExpiryDate;

  return (
    <>
      <Modal
        sx={{ zIndex: 345 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={handleClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
              {type === 'loyalty' && `${titleString} Loyalty Card`}
              {type === 'gift' && `${titleString} Gift Card`}
              {type === 'reward' && `${titleString} Reward certificate`}
            </Typography>
            {isDisplayModeActive && (
              <TopTitleAction
                data={editData}
                onDelete={onDeleteModalInit}
                isEdit={isDisplayModeActive}
                onEdit={() => setIsDisplayModeActive(false)}
                isChangeLogVisible={onChangeLog !== undefined}
                onChangeLog={() => onChangeLog(editData)}
              />
            )}
          </div>
          <div className="sm-flex-direction-column" style={{ display: 'flex', gap: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
              {/* Card Name */}
              <CustomSelectWithImage
                id="cardId"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    {type === 'loyalty'
                      ? 'Loyalty card name'
                      : type === 'gift'
                      ? 'Gift Card Name'
                      : 'Reward certificate Name'}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                name="cardId"
                onChange={onChangeCardId}
                onCustomClick={onAddCustomCompany}
                options={cardDataList}
                selectKey="_id"
                labelKey="name"
                value={fieldValue.cardId}
                disabled={editData !== null}
                helperText={
                  companyIdError
                    ? type === 'loyalty'
                      ? 'Please Enter Loyalty card name'
                      : type === 'gift'
                      ? 'Please Enter Gift Card Name'
                      : 'Please Enter Reward certificate Name'
                    : ''
                }
                setCompanyIdError={setCompanyIdError}
                companyIdError={companyIdError}
                setFieldValue={setFieldValue}
              />
              <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                <Checkbox
                  disabled={isDisplayModeActive}
                  checked={isExpiryDateDisable}
                  onChange={onChangeExpiryDateDisable}
                  style={{
                    marginTop: '-8px',
                    position: 'absolute',
                    left: '20px',
                    flex: 'none',
                    top: `${!companyIdError ? '130px' : '152px'}`,
                  }}
                />
                {/* Expiry date */}
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={login.user.country === 'India' ? 'en-gb' : 'en'}
                >
                  <DatePicker
                    value={fieldValue.expiryDate}
                    onChange={(date) => {
                      if (date.isValid()) onChangeField({ target: { name: 'expiryDate', value: date?.toISOString() } });
                    }}
                    name="expiryDate"
                    type="date"
                    label={<span style={{ marginLeft: '8px' }}>Expiry Date</span>}
                    size="small"
                    fullWidth
                    variant="standard"
                    disabled={isDisplayModeActive || !isExpiryDateDisable}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" />,
                      readOnly: true,
                    }}
                    InputLabelProps={{ style: { marginLeft: '20px', color: 'black' } }}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        sx={{ width: '100%', ml: '20px', mb: '8px', my: '10px' }}
                        size="small"
                        variant="standard"
                        error={expDateError}
                        helperText={expDateError ? 'Expiry date must be greater than or equal to the today date' : ''}
                        disabled={isDisplayModeActive || !isExpiryDateDisable}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className="card-add-checkbox">
                {/* <Checkbox
                  disabled={isDisplayModeActive}
                  checked={isExpiryDateDisable}
                  onChange={onChangeExpiryDateDisable}
                /> */}
                {/* <span>is the card have expiry date</span> */}
              </div>
              {/* Comments */}
              <TextField
                disabled={isDisplayModeActive}
                value={fieldValue.comments}
                onChange={onChangeField}
                name="comments"
                label={<Typography sx={{ color: '#B6B6B6' }}>Comments</Typography>}
                size="small"
                type="text"
                fullWidth
                multiline
                maxRows={3}
                variant="standard"
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                helperText={`${fieldValue.comments.length}/${250}`}
                inputProps={{
                  maxlength: 250,
                }}
              />
              {/* Points/Value */}
              <TextField
                disabled={isDisplayModeActive}
                value={fieldValue.points}
                onChange={handleInputChange}
                name="points"
                size="small"
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    {type === 'loyalty' ? 'Points' : 'Value'}{' '}
                    {type === 'gift' && <span style={{ color: 'red' }}>*</span>}
                  </Typography>
                }
                type="text"
                fullWidth
                variant="standard"
                helperText={type === 'gift' ? (pointError ? 'Value is required' : '') : ''}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                error={Boolean(pointError)}
                InputProps={{
                  startAdornment:
                    type === 'loyalty' ? (
                      <InputAdornment position="start" />
                    ) : (
                      <InputAdornment position="start">{profile.country === 'India' ? '₹' : '$'}</InputAdornment>
                    ),
                }}
                inputProps={{
                  maxlength: 30,
                }}
              />
              {/* Payment Mode */}
              {type === 'gift' && (
                <TextField
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  disabled={isDisplayModeActive}
                  value={selectedPaymentMode}
                  onChange={(e) => {
                    setSelectedPaymentMode(e.target.value);
                    const newPaymentInstrumentList = paymentInstrumentList?.filter(
                      (ex) => ex?.paymentModeId?._id === e.target.value
                    );
                    setSelectedPaymentInstrumentList(newPaymentInstrumentList);
                  }}
                  select
                  label={<Typography sx={{ color: '#B6B6B6' }}>Payment Mode</Typography>}
                  size="small"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                >
                  {paymentModeList?.map((e, index) => (
                    <MenuItem key={index} value={e._id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {/* Payment Instrument */}
              {type === 'gift' && (
                <CustomMuiSelect
                  disablePortal={false}
                  disabled={isDisplayModeActive}
                  label="Payment Instrument"
                  size="small"
                  variant="standard"
                  options={selectedPaymentInstrumentList?.map((e) => ({ label: e.nickNameField, id: e._id })) || []}
                  value={
                    selectedPaymentInstrumentList?.find((ex) => ex._id === fieldValue?.paymentInstrument)
                      ?.nickNameField || ''
                  }
                  onAddNew={
                    !isDisplayModeActive &&
                    (() => {
                      setAddPaymentInstrumentModalOpen(true);
                    })
                  }
                  onChange={(e) => {
                    if (e) {
                      setFieldValue((prev) => ({ ...prev, paymentInstrument: e.id }));
                    } else {
                      setFieldValue((prev) => ({ ...prev, paymentInstrument: '' }));
                    }
                  }}
                />
                // <TextField
                //   InputLabelProps={{
                //     style: { color: 'black' },
                //   }}
                //   disabled={isDisplayModeActive}
                //   value={fieldValue?.paymentInstrument}
                //   onChange={(e) => {
                //     setFieldValue((prev) => ({ ...prev, paymentInstrument: e.target.value }));
                //   }}
                //   select
                //   label={<Typography sx={{ color: '#B6B6B6' }}>Payment Instrument</Typography>}
                //   size="small"
                //   fullWidth
                //   variant="standard"
                //   InputProps={{
                //     startAdornment: <InputAdornment position="start" />,
                //   }}
                // >
                //   {selectedPaymentInstrumentList?.map((e, index) => (
                //     <MenuItem key={index} value={e._id}>
                //       {e.nickNameField}
                //     </MenuItem>
                //   ))}
                // </TextField>
              )}
              {/* Review */}
              <TextField
                disabled={isDisplayModeActive}
                value={fieldValue.review}
                onChange={onChangeField}
                name="review"
                label={<Typography sx={{ color: '#B6B6B6' }}>Review</Typography>}
                size="small"
                type="text"
                fullWidth
                multiline
                maxRows={3}
                variant="standard"
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                helperText={`${fieldValue.review.length}/${250}`}
                inputProps={{
                  maxlength: 250,
                }}
              />
              {/* Front side -  Back side */}
              <span style={{ color: '#3d71ff', fontSize: '20px' }}>Upload card images</span>
              {!isDisplayModeActive && (
                <TextField
                  disabled={isDisplayModeActive}
                  onChange={(e) => {
                    setSelectedFrontImage(e.target.files[0]);
                  }}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Front side</Typography>}
                  size="small"
                  type="file"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  inputProps={{ accept: 'image/*' }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                />
              )}
              {!isDisplayModeActive && (
                <TextField
                  disabled={isDisplayModeActive}
                  onChange={(e) => {
                    setSelectedBackImage(e.target.files[0]);
                  }}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Back side</Typography>}
                  size="small"
                  type="file"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  inputProps={{ accept: 'image/*' }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                />
              )}
              <div style={{ display: 'flex', gap: '12px' }}>
                <Image
                  style={{ objectFit: 'cover', width: '80px', height: '80px', borderRadius: '8px' }}
                  src={frontImageUrl}
                  alt="frontImageUrl"
                  preview={
                    frontImageUrl === cardFront
                      ? false
                      : {
                          toolbarRender: (
                            _,
                            {
                              transform: { scale },
                              actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                            }
                          ) => (
                            <Space size={12} className="toolbar-wrapper">
                              <DownloadOutlined onClick={() => onDownloadImage(frontImageUrl)} />
                              <SwapOutlined rotate={90} onClick={onFlipY} />
                              <SwapOutlined onClick={onFlipX} />
                              <RotateLeftOutlined onClick={onRotateLeft} />
                              <RotateRightOutlined onClick={onRotateRight} />
                              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                            </Space>
                          ),
                        }
                  }
                />
                <Image
                  style={{ objectFit: 'cover', width: '80px', height: '80px', borderRadius: '8px' }}
                  src={backImageUrl}
                  alt="backImageUrl"
                  preview={
                    backImageUrl === cardBack
                      ? false
                      : {
                          toolbarRender: (
                            _,
                            {
                              transform: { scale },
                              actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                            }
                          ) => (
                            <Space size={12} className="toolbar-wrapper">
                              <DownloadOutlined onClick={() => onDownloadImage(backImageUrl)} />
                              <SwapOutlined rotate={90} onClick={onFlipY} />
                              <SwapOutlined onClick={onFlipX} />
                              <RotateLeftOutlined onClick={onRotateLeft} />
                              <RotateRightOutlined onClick={onRotateRight} />
                              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                            </Space>
                          ),
                        }
                  }
                />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
              {/* Card Number */}
              <TextField
                autoComplete="new-password"
                disabled={isDisplayModeActive}
                value={isShowCardNumber ? fieldValue.cardNumber : replaceFirstFourChars(fieldValue.cardNumber)}
                onChange={onChangeField}
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                label={
                  <Typography sx={{ color: '#B6B6B6' }}>
                    {type === 'loyalty'
                      ? 'Loyalty Card Number'
                      : type === 'gift'
                      ? 'Gift Card Number'
                      : 'Reward Certificate Number'}{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </Typography>
                }
                name="cardNumber"
                size="small"
                type="text"
                // type={cardNumberVisibility ? 'text' : 'password'}
                fullWidth
                variant="standard"
                error={cardNumberError}
                helperText={cardNumberError ? 'Please enter a valid card number(min 4 char, max 30 char)' : ''}
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsShowCardNumber((prev) => !prev)} edge="end">
                        {isShowCardNumber ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  minlength: 3,
                  maxlength: 30,
                }}
              />
              <div style={{ display: 'flex', gap: '12px', marginTop: '10px' }}>
                {/* PinField */}
                {type !== 'loyalty' && (
                  <PinField
                    required={isPinRequired}
                    disabled={isDisplayModeActive}
                    value={fieldValue?.pin}
                    onChange={onChangeField}
                  />
                )}
                {/* Status */}
                <TextField
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  disabled={isDisplayModeActive}
                  value={fieldValueStatus}
                  onChange={onChangeField}
                  select
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Status
                      {/* Status <span style={{ color: 'red' }}>*</span> */}
                    </Typography>
                  }
                  name="status"
                  size="small"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: <InputAdornment position="start" />,
                  }}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </TextField>
              </div>
              <div style={{ display: 'flex', gap: '16px' }}>
                <div>
                  <Typography
                    sx={{
                      color: ' #B6B6B6',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: '400',
                      fontSize: '14px',
                      mb: 0,
                    }}
                  >
                    Rating
                  </Typography>
                  <CustomRating
                    readOnly={isDisplayModeActive}
                    precision={0.5}
                    name="rating"
                    value={fieldValue.rating}
                    onChange={(e) => {
                      setFieldValue((prev) => ({ ...prev, rating: parseFloat(e.target.value) }));
                    }}
                  />
                </div>
                <div>
                  <Typography
                    sx={{
                      color: ' #B6B6B6',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                      fontWeight: '400',
                      fontSize: '14px',
                      mb: 0,
                    }}
                  >
                    Safals
                  </Typography>
                  <Box
                    sx={{ display: 'flex' }}
                    name="safals"
                    onClick={() => {
                      if (isDisplayModeActive) return;
                      setFieldValue((prev) => ({
                        ...prev,
                        safals: prev.safals === 1 ? -1 : prev.safals === -1 ? 0 : 1,
                      }));
                    }}
                  >
                    {fieldValue.safals === 1 && (
                      <img
                        style={{ cursor: 'pointer', height: '40px', objectFit: 'contain' }}
                        src="/assets/images/Like.png"
                        alt="Like Symbol"
                      />
                    )}
                    {fieldValue.safals === -1 && (
                      <img
                        style={{ cursor: 'pointer', height: '40px', objectFit: 'contain' }}
                        src="/assets/images/disLike.png"
                        alt="DisLike Symbol"
                      />
                    )}
                    {fieldValue.safals === 0 && (
                      <>
                        <img
                          src="/assets/images/Like.png"
                          alt="Like Symbol"
                          style={{ filter: 'grayscale(100%)', cursor: 'pointer', height: '40px', objectFit: 'contain' }}
                        />
                        <img
                          src="/assets/images/disLike.png"
                          alt="DisLike Symbol"
                          style={{ filter: 'grayscale(100%)', cursor: 'pointer', height: '40px', objectFit: 'contain' }}
                        />
                      </>
                    )}
                  </Box>
                </div>
              </div>
              {/* Description */}
              <TextField
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                // disabled={isDisplayModeActive}
                disabled
                value={fieldValue.description}
                onChange={onChangeField}
                label={<Typography sx={{ color: '#B6B6B6' }}>Description</Typography>}
                name="description"
                size="small"
                type="text"
                fullWidth
                multiline
                maxRows={2}
                minRows={2}
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
                inputProps={{
                  maxlength: 500,
                }}
                helperText={`${fieldValue.description.length}/${500}`}
              />
              <span style={{ color: '#3d71ff', fontSize: '20px' }}>
                {type === 'loyalty'
                  ? 'Loyalty Company Details'
                  : type === 'gift'
                  ? 'Gift Card Company Details'
                  : 'Reward Company Details'}
              </span>
              <TextField
                value={selectedCompany.name}
                disabled
                label="Company name"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
              <TextField
                value={selectedCompany.type}
                disabled
                label="Company type"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
              <TextField
                value={selectedCompany.website}
                disabled
                label="Website"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
              <TextField
                value={selectedCompany.country}
                disabled
                label="Country"
                size="small"
                type="text"
                fullWidth
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
              <TextField
                value={selectedCompany.description}
                disabled
                label="Description"
                size="small"
                type="text"
                fullWidth
                multiline
                maxRows={3}
                variant="standard"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '22px', justifyContent: 'end' }}>
            {!isDisplayModeActive && (
              <Button
                disabled={isSubmitting}
                sx={{
                  backgroundColor: '#ff4842',
                  ':hover': { backgroundColor: '#d40700' },
                  color: 'white',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                }}
                onClick={handleClose}
                variant="contained"
              >
                Cancel
              </Button>
            )}
            {!isDisplayModeActive && editData !== null && (
              <Button
                disabled={isSubmitting}
                sx={{ borderRadius: '30px', textTransform: 'capitalize', backgroundColor: '#3D71FF' }}
                onClick={onDeleteModalInit}
                variant="contained"
              >
                Delete
              </Button>
            )}
            {!isDisplayModeActive && editData !== null && (
              <Button
                sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
                onClick={onValidate}
                variant="contained"
                disabled={isSubmitting ? true : !isEditButtonEnable}
              >
                Save
              </Button>
            )}
            {editData === null && (
              <Button
                sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
                onClick={onValidate}
                variant="contained"
                disabled={isSubmitting}
              >
                Add
              </Button>
            )}
          </div>
        </Box>
      </Modal>
      <AddCustomAssetModal
        callback={addCustomCallBack}
        type={type}
        open={isAddCompanyModalOpen}
        onClose={onAddCompanyModalClose}
        helperText={
          companyIdError
            ? type === 'loyalty'
              ? 'Please Enter Company Name'
              : type === 'gift'
              ? 'Please Enter Gift Card Name'
              : 'Please Enter Reward Company Name'
            : ''
        }
        setCompanyIdError={setCompanyIdError}
        companyIdError={companyIdError}
      />
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddPaymentInstrumentModal
        open={addPaymentInstrumentModalOpen}
        onClose={onCloseAddPaymentInstrumentModal}
        paymentModeList={paymentModeList}
        paymentCardSchemeList={paymentCardSchemeList}
      />
    </>
  );
}

const PinField = ({ value, onChange, required }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <TextField
      autoComplete="new-password"
      value={value}
      onChange={onChange}
      InputLabelProps={{
        style: { color: 'black' },
      }}
      label={
        <Typography sx={{ color: '#B6B6B6' }}>Pin {required && <span style={{ color: 'red' }}>*</span>}</Typography>
      }
      name="pin"
      size="small"
      type={showPassword ? 'text' : 'password'}
      sx={{
        width: '200px',
      }}
      variant="standard"
      InputProps={{
        startAdornment: <InputAdornment position="start" />,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
