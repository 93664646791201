import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import useProfileImage from '../../../../hooks/useProfileImage';
import CustomRatingBusiness from '../../../../components/UI/CustomRatingBusiness';
import { getBusinessDashboard } from '../../../../services/Service';

const WelcomeBackCard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  // const user = useSelector((state) => state.login.user);

  // const fullName = user?.middleName
  //   ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
  //   : `${user?.firstName} ${user?.lastName}`;

  // const profileImage = useProfileImage(user.profilePic);

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getBusinessDashboard();
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const avgSubscriptionRating = data?.avgSubscriptionRating ?? 0;
  const avgAssetRating = data?.avgAssetRating ?? 0;

  return (
    <div
      style={{
        padding: '12px 16px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Top sections */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {/* <div style={{ display: 'flex', flex: 'none', alignItems: 'center', gap: '12px' }}>
          <img
            style={{
              width: '38px',
              height: '38px',
              objectFit: 'contain',
              borderRadius: '999px',
              border: '1px solid #468b47',
            }}
            src={profileImage}
            alt="Profile"
          />
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', color: '#468b47' }}>
            <span style={{ fontSize: '16px', whiteSpace: 'nowrap' }}>Welcome Back</span>
            <span style={{ fontSize: '22px', fontWeight: 'bold', whiteSpace: 'nowrap' }}>{fullName}</span>
          </div>
        </div> */}
        <div style={{ display: 'flex', gap: '12px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
            <span
              style={{ color: '#468B47', fontWeight: 'bold', fontSize: '16px', opacity: '0.75', paddingLeft: '2px' }}
            >
              Avg Subscription Rating
            </span>
            <CustomRatingBusiness value={avgSubscriptionRating} readOnly precision={0.5} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
            <span
              style={{ color: '#468B47', fontWeight: 'bold', fontSize: '16px', opacity: '0.75', paddingLeft: '2px' }}
            >
              Avg Asset Rating
            </span>
            <CustomRatingBusiness value={avgAssetRating} readOnly precision={0.5} />
          </div>
        </div>
      </div>
      <div style={{ height: '2px', backgroundColor: '#19C25F', marginTop: '8px' }} />
      {/* Bottom sections */}
      <div style={{ display: 'flex', marginTop: '12px' }}>
        {/* Monthly */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter' }}>Monthly</span>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '8px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: '#468b47',
                fontSize: '22px',
                display: 'flex',
                alignItems: 'baseline',
              }}
            >
              $ {data?.totalRevenueMonthly ?? 0}{' '}
              {/* <span style={{ color: '#90EE90', fontSize: '13px', display: 'flex', alignItems: 'baseline' }}>
                <div className="BusinessDashboard-arrow-up" /> $2.5K{' '}
              </span> */}
            </p>
            <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter', color: '#468b47' }}>
              Sales Revenue
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '12px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: data?.growthRateMonthly < 0 ? '#fcbaba' : '#468b47',
                fontSize: '22px',
              }}
            >
              {Math.abs(data?.growthRateMonthly) ?? 0}%{' '}
              {/* <span style={{ color: '#90EE90', fontSize: '13px' }}>4.21% </span> */}
            </p>
            <span
              style={{
                fontSize: '14',
                fontWeight: 'lighter',
                fontFamily: 'inter',
                color: data?.growthRateMonthly < 0 ? '#fcbaba' : '#468b47',
              }}
            >
              Growth Rate
            </span>
          </div>
        </div>
        {/* Quarterly */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter' }}>Quarterly</span>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '8px' }}>
            <p style={{ fontWeight: 'bold', color: '#468b47', fontSize: '22px' }}>
              $ {data?.totalRevenueQuarter ?? 0}{' '}
              {/* <span style={{ color: '#90EE90', fontSize: '13px' }}>$2.5K </span> */}
            </p>
            <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter', color: '#468b47' }}>
              Sales Revenue
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '12px' }}>
            <p
              style={{
                fontWeight: 'bold',
                color: data?.growthRateQuarter < 0 ? '#fcbaba' : '#468b47',
                fontSize: '22px',
              }}
            >
              {Math.abs(data?.growthRateQuarter) ?? 0}%{' '}
              {/* <span style={{ color: '#90EE90', fontSize: '13px' }}>4.21% </span> */}
            </p>
            <span
              style={{
                fontSize: '14',
                fontWeight: 'lighter',
                fontFamily: 'inter',
                color: data?.growthRateQuarter < 0 ? '#fcbaba' : '#468b47',
              }}
            >
              Growth Rate
            </span>
          </div>
        </div>
        {/* Yearly */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter' }}>Yearly</span>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '8px' }}>
            <p style={{ fontWeight: 'bold', color: '#468b47', fontSize: '22px' }}>
              $ {data?.totalRevenueYear ?? 0} {/* <span style={{ color: '#90EE90', fontSize: '13px' }}>$2.5K </span> */}
            </p>
            <span style={{ fontSize: '14', fontWeight: 'lighter', fontFamily: 'inter', color: '#468b47' }}>
              Sales Revenue
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', marginTop: '12px' }}>
            <p
              style={{ fontWeight: 'bold', color: data?.growthRateYear < 0 ? '#fcbaba' : '#468b47', fontSize: '22px' }}
            >
              {Math.abs(data?.growthRateYear) ?? 0}%{' '}
              {/* <span style={{ color: '#90EE90', fontSize: '13px' }}>4.21% </span> */}
            </p>
            <span
              style={{
                fontSize: '14',
                fontWeight: 'lighter',
                fontFamily: 'inter',
                color: data?.growthRateYear < 0 ? '#fcbaba' : '#468b47',
              }}
            >
              Growth Rate
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeBackCard;
