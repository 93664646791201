import { Box, Fab, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import queryString from 'query-string';
import toast from 'react-hot-toast';

import { getGlobalShareLinkList } from '../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '40px 24px',
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export const GlobalShareModal = ({ open, onClose, link, title }) => {
  const handleClose = () => {
    onClose();
  };

  const [rawDataSocial, setRawDataSocial] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getGlobalShareLinkList();
      if (res.status === 200) {
        setRawDataSocial(res.data.data);
      }
    } catch (err) {
      console.log('OffersList fetchData error', err.message);
    }
  };

  useEffect(() => {
    if (open) fetchData();
  }, [open]);

  const findFacebook = rawDataSocial?.find((e) => e?.type === 'facebook');
  const findWhatsapp = rawDataSocial?.find((e) => e?.type === 'whatsapp');
  const findLinkedin = rawDataSocial?.find((e) => e?.type === 'linkedin');
  const findSnapchat = rawDataSocial?.find((e) => e?.type === 'snapchat');
  const findMessenger = rawDataSocial?.find((e) => e?.type === 'messenger');
  const findEmail = rawDataSocial?.find((e) => e?.type === 'email');
  const findX = rawDataSocial?.find((e) => e?.type === 'x');

  const onClickLinks = (type) => {
    let openLink = 'https://dev.safalsubscriptions.com';

    if (type === 'facebook') {
      const qs = queryString.stringify({ u: link }, { skipNull: true });
      openLink = `https://www.facebook.com/sharer/sharer.php?${qs}`;
    }
    if (type === 'x') {
      const qs = queryString.stringify({ text: link }, { skipNull: true });
      openLink = `https://twitter.com/intent/tweet?${qs}`;
    }
    if (type === 'linkedin') {
      const qs = queryString.stringify({ url: link, mini: true }, { skipNull: true });
      openLink = `https://www.linkedin.com/shareArticle?${qs}`;
    }
    // if (type === 'snapchat') {
    //   const qs = queryString.stringify({ u: link }, { skipNull: true });
    //   openLink = `https://www.facebook.com/sharer/sharer.php?${qs}`;
    // }
    // if (type === 'messenger') {
    //   const qs = queryString.stringify({ u: link }, { skipNull: true });
    //   openLink = `https://www.facebook.com/sharer/sharer.php?${qs}`;
    // }
    if (type === 'mail') {
      const qs = queryString.stringify({ subject: title, body: link }, { skipNull: true });
      openLink = `mailto:?${qs}`;
    }
    if (type === 'whatsapp') {
      const qs = queryString.stringify({ text: link }, { skipNull: true });
      openLink = `https://wa.me/?${qs}`;
    }

    window.open(openLink, '_blank');
  };

  const onCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(link);
      toast.success('Link copied to clipboard');
    } catch (err) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: '#3D71FF',
              color: 'white',
            }}
            onClick={handleClose}
            size="small"
            color="primary"
          >
            <CloseIcon />
          </Fab>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ marginBottom: '8px', fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}
            >
              Share this link
            </Typography>
            <span>Share your amazing experience with the public and your friends.</span>
          </div>
          <div
            style={{ display: 'flex', flexDirection: 'column', padding: '16px 20px', gap: '20px', margin: '20px 0px' }}
          >
            {findSnapchat && findSnapchat?.isWeb && (
              <StyleLinkItem
                onClick={() => onClickLinks('snapchat')}
                img={'/assets/images/share_snapchat.png'}
                name={'Share on Snapchat'}
              />
            )}
            {findFacebook && findFacebook?.isWeb && (
              <StyleLinkItem
                onClick={() => onClickLinks('facebook')}
                img={'/assets/images/share_facebook.png'}
                name={'Share on Facebook'}
              />
            )}
            {findLinkedin && findLinkedin?.isWeb && (
              <StyleLinkItem
                onClick={() => onClickLinks('linkedin')}
                img={'/assets/images/share_linkedin.png'}
                name={'Share on Linkedin'}
              />
            )}
            {findX && findX?.isWeb && (
              <StyleLinkItem onClick={() => onClickLinks('x')} img={'/assets/images/share_x.png'} name={'Share on X'} />
            )}
            {findWhatsapp && findWhatsapp?.isWeb && (
              <StyleLinkItem
                onClick={() => onClickLinks('whatsapp')}
                img={'/assets/images/share_whatsapp.png'}
                name={'Share via WhatsApp'}
              />
            )}
            {findMessenger && findMessenger?.isWeb && (
              <StyleLinkItem
                onClick={() => onClickLinks('messenger')}
                img="/assets/images/share_messenger.png"
                name={'Share via Mesenger'}
              />
            )}
            {findEmail && findEmail?.isWeb && (
              <StyleLinkItem
                onClick={() => onClickLinks('mail')}
                img={'/assets/images/share_mail.png'}
                name={'Share via E-Mail'}
              />
            )}
          </div>
          <div
            style={{
              border: '1px solid #DEDADA',
              borderRadius: '8px',
              padding: '8px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span>{link}</span>
            <button
              onClick={onCopyLink}
              style={{
                color: '#0932A1',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              Copy
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

const StyleLinkItem = ({ name, img, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: 'white',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        cursor: 'pointer',
      }}
    >
      <img src={img} alt="iasdsa" style={{ objectFit: 'contain', height: '30px' }} />
      <span style={{ fontSize: '16px', fontWeight: '600' }}>{name}</span>
      <ArrowForwardIosIcon sx={{ fontSize: '14px', marginLeft: 'auto' }} />
    </button>
  );
};
