import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { usStates, indiaStates } from '../../../utils/stateList';

import { addSafalGiftAddress, updateSafalGiftAddress, getLeaderBoardMasterCountries } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  address1: '',
  address2: '',
  phoneNumber: '',
  landmark: '',
  zip: '',
  state: '',
  country: '',
};

export default function AddSafalGiftAddressModal({ open, onClose, data }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [countryList, setCountryList] = React.useState([]);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    onClose();
  };

  const fetchData = async () => {
    try {
      const res = await getLeaderBoardMasterCountries();
      if (res.status === 200) {
        setCountryList(res.data.data.filter((e) => e.code3 !== 'IND'));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      const res = await addSafalGiftAddress(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const res = await updateSafalGiftAddress({ _id: data._id, ...fieldValue });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open) fetchData();
    if (open === true && data !== null) {
      setFieldValue({
        name: data?.name || '',
        address1: data?.address1 || '',
        phoneNumber: data?.phoneNumber || '',
        address2: data?.address2 || '',
        landmark: data?.landmark || '',
        zip: data?.zip || '',
        state: data?.state || '',
        country: data?.country?._id || '',
      });
    }
  }, [open, data]);

  const findCountry = countryList.find((e) => e?._id === fieldValue.country);
  const stateArray =
    findCountry?.name === 'India' ? indiaStates : findCountry?.name === 'United States' ? usStates : [];

  const isDataValid =
    fieldValue.name === '' ||
    fieldValue.address1 === '' ||
    fieldValue.phoneNumber === '' ||
    fieldValue.address2 === '' ||
    fieldValue.country === '' ||
    fieldValue.landmark === '' ||
    fieldValue.state === '' ||
    fieldValue.zip === '';

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}
        >
          {data === null ? 'Add Address' : 'Update Address'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Name"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* address1 */}
          <TextField
            value={fieldValue.address1}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, address1: e.target.value }))}
            required
            label="Address line 1"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* address2 */}
          <TextField
            value={fieldValue.address2}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, address2: e.target.value }))}
            required
            label="Address line 2"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* landmark */}
          <TextField
            value={fieldValue.landmark}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, landmark: e.target.value }))}
            required
            label="Landmark"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* phoneNumber */}
          <TextField
            value={fieldValue.phoneNumber}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, phoneNumber: e.target.value }))}
            required
            label="Phone number"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* zip */}
          <TextField
            value={fieldValue.zip}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, zip: e.target.value }))}
            required
            label="Zip Code"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* country */}
          <TextField
            value={fieldValue.country}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, country: e.target.value }))}
            required
            select
            label="Country"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {countryList?.map((e, index) => (
              <MenuItem key={index} value={e?._id}>
                {e?.name}
              </MenuItem>
            ))}
          </TextField>
          {/* state */}
          <TextField
            value={fieldValue.state}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, state: e.target.value }))}
            required
            select
            label="State"
            size="small"
            disabled={fieldValue.country === '' ? true : isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            {stateArray?.map((e, index) => (
              <MenuItem key={index} value={e?.abbreviation}>
                {e?.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#3D71FF', textTransform: 'capitalize', ':hover': { backgroundColor: '#3D71FF' } }}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={isDataValid ? true : isDisableAll}
            onClick={data === null ? onSubmit : onSubmitUpdate}
            sx={{ backgroundColor: '#3D71FF', textTransform: 'capitalize', ':hover': { backgroundColor: '#3D71FF' } }}
            variant="contained"
          >
            {data === null ? 'Add' : 'Update'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
