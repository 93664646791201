/* eslint-disable */
import CloseIcon from '@mui/icons-material/Close';
import { Button, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { getAllSupportUser, addShiftTiming, updateShiftTiming } from '../../services/Service';
import toast from 'react-hot-toast';

import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const timeArray = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

const SupportCallTimingModal = ({ isOpen, data, onClose }) => {
  const classes = useStyles();
  const [businessUserList, setBusinessUserList] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedPerson, setSelectedPerson] = useState('');

  const fetchAllSupportUser = async () => {
    const res = await getAllSupportUser();
    if (res.status !== 200) return;
    setBusinessUserList(res.data.data);
  };

  const isEdit = data !== null;

  const resetState = () => {
    setSelectedPerson('');
    setEndTime('');
    setStartTime('');
  };

  useEffect(() => {
    resetState();
    if (isOpen) {
      fetchAllSupportUser();
      if (data === null) return;
      setStartTime(data.startTime);
      setEndTime(data.endTime);
      setSelectedPerson(data.supportId);
    }
  }, [isOpen]);

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const handlePersonChange = (event) => {
    setSelectedPerson(event.target.value);
  };

  const handleEditSubmit = async () => {
    try {
      if (startTime.length < 2) {
        toast.error('please select start time');
        return;
      }
      if (endTime.length < 2) {
        toast.error('please select end time');
        return;
      }
      const res = await updateShiftTiming({
        id: data.id,
        startTime: startTime,
        endTime: endTime,
      });
      if (res.status === 200) {
        toast.success('update successfully');
        onClose();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };
  const handleSubmit = async () => {
    try {
      if (selectedPerson.length < 2) {
        toast.error('please select support user');
        return;
      }
      if (startTime.length < 2) {
        toast.error('please select start time');
        return;
      }
      if (endTime.length < 2) {
        toast.error('please select end time');
        return;
      }
      const res = await addShiftTiming({
        supportId: selectedPerson,
        startTime: startTime,
        endTime: endTime,
      });
      if (res.status === 200) {
        toast.success('added successfully');
        onClose();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  return (
    <div>
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style} className={classes.modal_box}>
          <div className={classes.flex_div}>
            <h2 className={classes.feedback_h2}>{isEdit ? 'Edit Shift' : 'Add Shift'}</h2>
            <div className={classes.close_ico}>
              <div
                className={classes.close_icon_div}
                onClick={() => {
                  onClose();
                }}
              >
                <CloseIcon className={classes.close_icon} />
              </div>
            </div>
          </div>
          <div className="form_container">
            <TextField
              disabled={isEdit}
              className={classes.input_elem}
              select
              label="Support Person"
              value={selectedPerson}
              onChange={handlePersonChange}
              fullWidth
            >
              {businessUserList.map((user, index) => (
                <MenuItem key={index} value={user.userID}>
                  {`${user.firstName} ${user.lastName} (${user.userID})`}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.input_elem}
              select
              label="Start Time"
              value={startTime}
              onChange={handleStartTimeChange}
              fullWidth
            >
              {timeArray.map((time, index) => (
                <MenuItem key={index} value={time}>
                  {time}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.input_elem}
              select
              label="End Time"
              value={endTime}
              onChange={handleEndTimeChange}
              fullWidth
            >
              {timeArray.map((time, index) => (
                <MenuItem key={index} value={time}>
                  {time}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <Button
              className={`${classes.input_elem}${classes.green}`}
              variant="contained"
              color="primary"
              onClick={isEdit ? handleEditSubmit : handleSubmit}
            >
              {isEdit ? 'Update' : 'Save'}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SupportCallTimingModal;

const useStyles = makeStyles({
  button: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white !important',
    height: 48,
    padding: '0 30px',
    fontWeight: 'bold !important',
    marginTop: '50px !important',
  },
  form_container: {
    display: 'flex  !important',
    flexDirection: 'column  !important',
    columnGap: '2rem  !important',
  },
  input_elem: {
    marginBottom: '1rem !important',
  },
  modal_box: {
    borderRadius: '10px !important',
    border: 'none !important',
    width: '700px !important',
  },
  close_ico: {
    right: 0,
    top: 0,
    display: 'block',
    transform: 'translate(-10%,10%)',
  },
  flex_div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '-30px !important',
    padding: '1rem 0rem',
  },
  flex_div_row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'space-between',
    marginTop: '-20px !important',
    padding: '1rem',
  },
  flex_submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '-30px !important',
    padding: '1rem',
  },
  feedback_h2: {
    color: '#027C34 !important',
    textAlign: 'left',
  },
  close_icon_div: {
    height: '40px !important',
    width: '40px !important',
    borderRadius: '50% !important',
    backgroundColor: '#027C34 !important',
    marginRight: '-25px !important',
    marginTop: '-20px !important',
    cursor: 'pointer !important',
    position: 'relative',
  },
  close_icon: {
    color: 'white !important',
    position: 'absolute',
    top: '50%',
    fontSize: 'x-large',
    right: '50%',
    transform: 'translate(50%,-50%)',
  },
  attch_icon: {
    color: '#027C34 !important',
    transform: 'rotate(46deg) !important',
    paddingRight: '10px !important',
  },
  green: {
    backgroundColor: '#027C34',
  },
  attach_btn: {
    borderRadius: '50px !important',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    textTransform: 'capitalize !important',
    color: 'black !important',
    padding: '10px 30px !important',
    fontSize: '13px !important',
    fontWeight: 'bold !important',
  },
  send_btn: {
    backgroundColor: '#027C34 !important',
    borderRadius: '50px !important',
    color: 'white !important',
    fontSize: '13px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    padding: '10px 70px !important',
  },
});
