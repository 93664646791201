import * as Yup from 'yup';
import React, { useState } from 'react';
import { Field, FormikProvider, useFormik } from 'formik';
import { Box, Button, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';
import { updatePassword } from 'firebase/auth';
import { ChangePasswordResponse } from '../../services/Service';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';

const SecurityTabChangePassword = () => {
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);

  const PasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string()
      .required('New Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const PasswordFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      await ChangePasswordResponse(values.oldPassword, values.newPassword)
        .then(async (res) => {
          if (res.data.success === true) {
            const passwd = PasswordFormik.values.newPassword;
            console.log('SecurityTab Password is -->', passwd);
            console.log('SecurityTab Password is -->', currentUser);
            await updatePassword(currentUser, passwd)
              .then((res) => {
                console.log('Response', res);
                // ...
              })
              .catch((error) => {
                console.log(error);
                // ...
              });

            console.log('currentUser?.uid', currentUser?.uid);
            SuccessToast('Password Updated Successfully');
            resetForm(initialValues);
          } else {
            ErrorToast('Incorrect Old password!! Please Try again');
          }
        })
        .catch((err) => {
          console.log(err.message);
          ErrorToast('Incorrect Old password!! Please Try again');
        });
    },
  });

  return (
    <>
      <FormikProvider value={PasswordFormik}>
        <form onSubmit={PasswordFormik.handleSubmit}>
          <Stack spacing={3}>
            <Field
              as={TextField}
              type={showPasswordOld ? 'text' : 'password'}
              name="oldPassword"
              label={<Typography sx={{ color: '#B6B6B6' }}>Old Password</Typography>}
              variant="standard"
              size="small"
              onChange={PasswordFormik.handleChange}
              value={PasswordFormik.values.oldPassword}
              error={PasswordFormik.touched.oldPassword && Boolean(PasswordFormik.errors.oldPassword)}
              helperText={PasswordFormik.touched.oldPassword && PasswordFormik.errors.oldPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPasswordOld(!showPasswordOld)}
                      sx={{ color: '#3D71FF' }}
                    >
                      <Icon icon={showPasswordOld ? 'mdi:eye' : 'mdi:eye-off'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              type={showPasswordNew ? 'text' : 'password'}
              name="newPassword"
              label={<Typography sx={{ color: '#B6B6B6' }}>New Password</Typography>}
              variant="standard"
              size="small"
              onChange={PasswordFormik.handleChange}
              value={PasswordFormik.values.newPassword}
              error={PasswordFormik.touched.newPassword && Boolean(PasswordFormik.errors.newPassword)}
              helperText={PasswordFormik.touched.newPassword && PasswordFormik.errors.newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPasswordNew(!showPasswordNew)}
                      sx={{ color: '#3D71FF' }}
                    >
                      <Icon icon={showPasswordNew ? 'mdi:eye' : 'mdi:eye-off'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              type={showPasswordConfirm ? 'text' : 'password'}
              name="confirmPassword"
              label={<Typography sx={{ color: '#B6B6B6' }}>Confirm Password</Typography>}
              variant="standard"
              size="small"
              onChange={PasswordFormik.handleChange}
              value={PasswordFormik.values.confirmPassword}
              error={PasswordFormik.touched.confirmPassword && Boolean(PasswordFormik.errors.confirmPassword)}
              helperText={PasswordFormik.touched.confirmPassword && PasswordFormik.errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                      sx={{ color: '#3D71FF' }}
                    >
                      <Icon icon={showPasswordConfirm ? 'mdi:eye' : 'mdi:eye-off'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Box>
            <Button
              color="primary"
              variant="contained"
              //   type="submit"
              onClick={() => PasswordFormik.submitForm()}
              disabled={!PasswordFormik.isValid || !PasswordFormik.dirty}
              sx={{
                width: '190px',
                height: '45px',
                backgroundColor: '#3D71FF',
                borderRadius: '30px',
                mt: '35px',
                textTransform: 'none',
              }}
            >
              Update
            </Button>
          </Box>
        </form>
      </FormikProvider>
    </>
  );
};

export default SecurityTabChangePassword;
