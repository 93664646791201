/* eslint-disable react/jsx-boolean-value */
import ReactECharts from 'echarts-for-react';
import useWindowSize from '../../../../hooks/useWindowSize';

// const colors = ['#468b47','#90ee90', '#edf9eb',"#385239"];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const BigPieChartCard = () => {
  const { width } = useWindowSize();

  const chartOption = {
    title: {
      text: 'Advertisement Revenue by page',
      left: 'left', // Align title to the left
      textStyle: {
        color: '#468b47', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: {c} ({d}%)', // Tooltip shows name, value, and percentage
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      left: 'right', // Position legend to the right
      top: 'center', // Vertically center the legend
      formatter: (name) => {
        const data = chartOption.series[0].data;
        const total = data.reduce((sum, item) => sum + item.value, 0);
        const item = data.find((d) => d.name === name);
        const percentage = ((item.value / total) * 100).toFixed(2); // Calculate percentage

        return `${name} (${percentage}%)`; // Display name with percentage
      },
    },
    series: [
      {
        name: 'Advertisement Revenue',
        type: 'pie',
        radius: width > 1400 ? '75%' : '50%',
        center: ['40%', '50%'], // Move the pie chart to the left
        data: [
          { value: 5048, name: 'Dashboard' },
          { value: 1935, name: 'Offers' },
          { value: 3580, name: 'SafalRooms' },
          { value: 2580, name: 'SafalGifts' },
        ],
        itemStyle: {
          color: (params) => {
            const colors = ['#468b47', '#90ee90', '#edf9eb', '#385239'];
            return colors[params.dataIndex % colors.length]; // Apply custom colors
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        border: '1px solid #fcbaba',
      }}
    >
      <select style={{ position: 'absolute', right: '12px', top: '12px' }}>
        <option value="option 1">option 1</option>
        <option value="option 2">option 2</option>
        <option value="option 3">option 3</option>
      </select>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        option={chartOption}
        style={{ height: '400px', minHeight: '300px' }}
      />
      {/* <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '12px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'max-content',
        }}
      >
        <LegendItem color="#468b47" text="Plan 1" />
        <LegendItem color="#90ee90" text="Plan 2" />
        <LegendItem color="#edf9eb" text="Plan 3" />
      </div> */}
    </div>
  );
};
export default BigPieChartCard;

const LegendItem = ({ color, text }) => {
  return (
    <div style={{ display: 'flex', gap: '6px', alignItems: 'center', flex: 'none' }}>
      <div style={{ backgroundColor: color, borderRadius: '9999px', height: '18px', width: '18px' }} />
      <span style={{ fontSize: '14px', marginTop: '2px' }}>{text}</span>
    </div>
  );
};
