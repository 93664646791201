import { Button, Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCheckoutPlanInfo } from '../../../services/Service';
import Inlineloader from '../../../components/Loader/InlineLoader';
import NewLogo from '../../../assets/Logo.png';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import UserAssessmentModal from '../../../components/Modals/UserAssessmentModal';

// checkout-complete
const CheckoutComplete = () => {
  const { user } = useSelector((state) => state.login);
  const { formatDate2 } = useUserTimeZone();
  const [loading, setLoading] = useState(true);
  const [resData, setResData] = useState(null);
  const [openUserAssessmentModal, setOpenUserAssessmentModal] = useState(false);

  const fetchCheckoutPlanInfo = async (sessionId) => {
    try {
      const res = await getCheckoutPlanInfo(sessionId);
      setResData(res.data);
    } catch (err) {
      console.log('fetchCheckoutPlanInfo', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('id');

    fetchCheckoutPlanInfo(sessionId);

    setTimeout(() => {
      setOpenUserAssessmentModal(true);
    }, 2500);

    return () => {
      setResData(null);
    };
  }, []);

  const onCloseUserAssessmentModal = () => {
    setOpenUserAssessmentModal(false);
  };

  const status = resData?.charge?.status === 'succeeded';
  const planName = resData?.invoice?.lines?.data[0]?.plan?.nickname;
  const planInterval = resData?.invoice?.lines?.data[0]?.plan?.interval;
  const periodEnd = resData?.invoice?.lines?.data[0]?.period?.end;
  const invoicePdf = resData?.invoice?.invoice_pdf;
  const invoiceNumber = resData?.invoice?.number;
  const invoiceAmount = resData?.invoice?.amount_paid;
  const invoiceCurrency = resData?.invoice?.currency;
  const receiptNumber = resData?.invoice?.receipt_number;

  const onViewInvoice = () => {
    window.open(invoicePdf, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Checkout Complete - SafalSubscriptions</title>
      </Helmet>
      <div style={{ height: '100%', paddingTop: '64px' }}>
        {loading && <Inlineloader />}
        {!loading && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              backgroundColor: 'white',
              borderRadius: '20px',
              width: '90%',
              height: '90%',
              margin: 'auto',
              padding: '32px',
              gap: '12px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-33px',
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: '#4ac96e',
                width: '64px',
                height: '64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '99999px',
                border: '4px solid #3d71ff',
              }}
            >
              <DoneIcon sx={{ color: 'white', width: '48px', height: '48px' }} />
            </div>
            <span style={{ color: '#3d71ff', fontWeight: 'bold', fontSize: '32px', marginTop: '32px' }}>
              Payment Complete
            </span>
            <div>
              <img style={{ height: '64px', objectFit: 'cover' }} src={NewLogo} alt="NewLogo" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', alignItems: 'center' }}>
              <span style={{ color: '#3d71ff', fontWeight: 'bold', fontSize: '22px', textTransform: 'capitalize' }}>
                {planName}
              </span>
              <span style={{ color: '#3d71ff', fontWeight: 'lighter', fontSize: '18px', textTransform: 'capitalize' }}>
                {planInterval} subscription
              </span>
            </div>
            <span style={{ fontSize: '18px' }}>
              {invoiceCurrency === 'usd' && '$'}
              {(invoiceAmount / 100).toFixed(2)}
            </span>
            <span style={{ fontSize: '18px', fontWeight: '600' }}>Thank you for your transfer</span>
            <span style={{ fontSize: '16px' }}>Your invoice number: {invoiceNumber}</span>
            <span style={{ fontSize: '16px' }}>Your receipt number: {receiptNumber}</span>
            <span style={{ fontSize: '16px' }}>
              Next payment date : {formatDate2(periodEnd * 1000)} {getTimeZoneAbbreviation()}
            </span>
            <Button onClick={onViewInvoice} sx={{ textTransform: 'capitalize' }} variant="contained">
              View invoice
            </Button>
          </div>
        )}
      </div>
      <UserAssessmentModal scenario="UpgradePlan" open={openUserAssessmentModal} onClose={onCloseUserAssessmentModal} />
    </>
  );
};

export default CheckoutComplete;
