/* eslint-disable */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import MomentUtils from '@date-io/moment';
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmail } from 'firebase/auth';
import { ErrorMessage, Field, FieldArray, Form, FormikProvider, useFormik } from 'formik';
import {
  AddAddonEmails,
  DeleteAddonEmails,
  PersonalInfoResponse,
  sendVerificationCodeResponse,
  ValidateEmailId,
  VerifyEmailResponse,
  VerifyPhoneResponse,
} from '../../../services/Service';
import SuccessToast from '../../../components/Toast/Success';
import ErrorToast from '../../../components/Toast/Error';
import { getProfile } from '../../../store/loginSlice';
import DeleteAddonEmailModal from '../../../components/Modals/DeleteAddonEmailModal';
import useWindowSize from '../../../hooks/useWindowSize';
import { indiaStates, usStates } from '../../../utils/stateList';

const ProfileGeneralTab = () => {
  const { user } = useSelector((state) => state.login);
  const [indata, setInData] = useState([]);
  const [vPhone, setVPhone] = useState(user?.isPhoneVerified || false);
  const [vEmail, setVEmail] = useState(user?.registered);
  const [otpField, setOtpField] = useState(false);
  const [phoneDisable, setPhoneDisable] = useState(true);
  const [emailDisable, setEmailDisable] = useState(true);
  const [otpDisable, setOtpDisable] = useState(false);
  const [emailId, setEmailId] = useState();
  const [openDeleteAddon, setOpenDeleteAddon] = useState(false);
  const [willBeDeleteAddonEmail, setWillBeDeleteAddonEmail] = useState();
  const [willBeDeleteAddonEmailId, setWillBeDeleteAddonEmailId] = useState();
  const dispatch = useDispatch();

  const windowSize = useWindowSize();

  const { currentUser } = useSelector((state) => state.auth);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 3000);
    };
  };

  const validateEmail = (e) => {
    ValidateEmailId({ email: e.target.value }).then((res) => {
      if (res.data.isEmailExist === true) {
        if (res.data.sameEmail == false) {
          setEmailId(true);
        } else {
          setEmailId(false);
        }
      } else {
        setEmailId(false);
        console.log('Not Matched');
      }
    });
  };

  const UserSchema = Yup.object().shape({
    userId: Yup.string().required('User Id is required'),
    firstName: Yup.string().required('First Name is required'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Last Name is required'),
    dateOfBirth: Yup.date()
      .required('Date of Birth is required')
      .test('dateOfBirth', 'Must be greater than 13 years', (value) => moment().diff(moment(value), 'years') >= 13)
      .typeError('Please enter valid date'),
    phoneNo: Yup.string()
      .required('Phone Number is required')
      .matches(/^[0-9]{10}$/, 'Must be in Numbers & 10 digits'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    addOnEmails: Yup.array()
      .of(
        Yup.mixed().test('email', 'Invalid email format', function (value) {
          if (typeof value === 'string') {
            return Yup.string().email().isValidSync(value);
          }
          return true;
        })
      )
      .nullable(),
    state: Yup.string().required('state is required'),
  });

  const initialValues = {
    userId: user?.userID,
    firstName: user?.firstName,
    middleName: user?.middleName,
    lastName: user?.lastName,
    dateOfBirth: user?.dateOfBirth,
    gender: user?.gender,
    // countryCode: '',
    phoneNo: user?.phoneNo,
    email: user?.email,
    addOnEmails: user?.addOnEmails || [],
    otp: '',
    countryCode: user?.country === 'United States' ? 1 : 91,
    state: user?.state,
  };

  const stateData = user?.country === 'India' ? indiaStates : usStates;

  const handleVerifyEmail = () => {
    VerifyEmailResponse({
      email: PersonalInfoFormik.values.email,
      user: user?._id,
    }).then((res) => {
      if (res.data.success === true) {
        // SuccessToast('Mail sent successfully on your email.');
        SuccessToast(res.data?.message);
      } else if (res.data.success === false) {
        ErrorToast(res.data.meesage);
      }
    });
  };

  const PersonalInfoFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: UserSchema,
    onSubmit: async (values, { resetForm }) => {
      // Phone number toggle change
      setPhoneDisable(true);
      // Primary Email toggle change
      setEmailDisable(true);

      setInData([]);

      // const evnt = indata.map((val) => ({ event: val, oldValues: initialValues[val], newValues: values[val] }));
      let valuesToBeSent = indata.map((val) => ({ [val]: values[val] }));
      valuesToBeSent = Object.assign({}, ...valuesToBeSent);
      // valuesToBeSent.event = { call: 'personalDetails', logs: evnt };
      let obj = {
        email: PersonalInfoFormik.values.email,
        // user: currentUser?.userID,
        user: user?._id,
      };
      if (valuesToBeSent.email !== undefined) {
        VerifyEmailResponse(obj).then((res) => {
          if (res.data.success === true) {
            console.log('email sent to ==> ', PersonalInfoFormik.values.email);
            // SuccessToast("email sent!!")
          } else if (res.data.success === false) {
            ErrorToast(res.data.meesage);
          }
        });
      }

      if (valuesToBeSent.addOnEmails !== undefined) {
        console.log('valuesToBeSent', valuesToBeSent.addOnEmails, PersonalInfoFormik.values.addOnEmails);
        await AddAddonEmails({ email: PersonalInfoFormik.values.addOnEmails }).then((res) => {
          if (res.data.success === true) {
            console.log('email sent to ==> ', PersonalInfoFormik.values.email);
          }
        });
      }

      console.log('currentUser?.uid', currentUser?.uid);
      // handleVerifyEmail();
      PersonalInfoResponse(valuesToBeSent)
        .then((res) => {
          if (res.data.success === true) {
            console.log('DDDD');
            dispatch(getProfile()).unwrap();
            SuccessToast(res.data.message);
          } else {
            ErrorToast('Fill Up Correct Details');
          }
        })
        .catch((err) => {
          ErrorToast(err?.response?.data?.message || err.message);
        });
      // await updateDoc(doc(db, 'users', currentUser?.uid), {
      //   email: PersonalInfoFormik.values.email,
      // });
    },
  });

  const resetTimer = () => {
    setTimeout(() => {
      setOtpDisable(false);
    }, 5000);
  };

  const verifyPhone = () => {
    sendVerificationCodeResponse(PersonalInfoFormik.values)
      .then((val) => {
        if (val.data.success === true) {
          console.log('this is Verification code => ', val.data);
          setOtpField(true);
        } else if (val.data.success === false && val.data?.message !== undefined) {
          ErrorToast(val.data.message);
          if (val?.data?.isverified === true) {
            setVPhone(true);
            setPhoneDisable(true);
          }
        } else {
          if (val.data.isverified === true) {
            setVPhone(true);
            setPhoneDisable(false);
            SuccessToast(val.data.message);
          } else {
            ErrorToast('Something went wrong!!!');
          }
        }
      })
      .catch((error) => {
        ErrorToast(error);
      });
  };

  const verifyPhoneClick = () => {
    resetTimer();
    VerifyPhoneResponse(PersonalInfoFormik.values.phoneNo, PersonalInfoFormik.values.otp, user?._id)
      .then((res) => {
        if (res.data.success === true) {
          if (res?.data?.isverified === true) {
            setVPhone(true);
            setPhoneDisable(true);
            dispatch(getProfile()).unwrap();
          }
          setOtpField(false);
        } else {
          ErrorToast(res.data.message);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) ErrorToast(err?.response?.data?.message);
        else ErrorToast(err.message);
      });
  };

  const handleDeleteAddonEmail = () => {
    PersonalInfoFormik.setFieldValue(
      'addOnEmails',
      PersonalInfoFormik.values.addOnEmails.filter((i, idx) => idx !== willBeDeleteAddonEmailId)
    );
    // formikArrayHelperRemove(willBeDeleteAddonEmailId);
  };

  const updateBtnDisable = PersonalInfoFormik.values.phoneNo !== user.phoneNo;
  return (
    <>
      <FormikProvider value={PersonalInfoFormik}>
        <Form onSubmit={PersonalInfoFormik.handleSubmit}>
          <Stack spacing={3} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', p: 0 }}>
            {/* userId - read only */}
            <Field
              as={TextField}
              name="userId"
              label={<Typography sx={{ color: '#B6B6B6' }}>UserId</Typography>}
              variant="standard"
              size="small"
              onChange={(e) => {
                PersonalInfoFormik.handleChange(e);
                indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
              }}
              value={PersonalInfoFormik.values.userId}
              error={PersonalInfoFormik.touched.userId && Boolean(PersonalInfoFormik.errors.userId)}
              helperText={PersonalInfoFormik.touched.userId && PersonalInfoFormik.errors.userId}
              sx={{ color: '#B6B6B6' }}
              disabled
            />
            {/* country - read only */}
            <Field
              as={TextField}
              name="country"
              label={<Typography sx={{ color: '#B6B6B6' }}>Country</Typography>}
              variant="standard"
              size="small"
              value={user.country}
              sx={{ color: '#B6B6B6' }}
              disabled
            />
            {/* state */}
            <Field
              as={TextField}
              name="state"
              label={<Typography sx={{ color: '#B6B6B6' }}>State</Typography>}
              variant="standard"
              select
              size="small"
              onChange={(e) => {
                PersonalInfoFormik.handleChange(e);
                indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
              }}
              value={PersonalInfoFormik.values.state}
              error={PersonalInfoFormik.touched.state && Boolean(PersonalInfoFormik.errors.state)}
              helperText={PersonalInfoFormik.touched.state && PersonalInfoFormik.errors.state}
              sx={{ color: '#B6B6B6' }}
            >
              {stateData.map((val) => (
                <MenuItem key={val.abbreviation} value={val.abbreviation}>
                  {val.name}
                </MenuItem>
              ))}
            </Field>
            {/* firstName */}
            <Field
              as={TextField}
              name="firstName"
              label={<Typography sx={{ color: '#B6B6B6' }}>First Name</Typography>}
              variant="standard"
              size="small"
              onChange={(e) => {
                PersonalInfoFormik.handleChange(e);
                indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
              }}
              value={PersonalInfoFormik.values.firstName}
              error={PersonalInfoFormik.touched.firstName && Boolean(PersonalInfoFormik.errors.firstName)}
              helperText={PersonalInfoFormik.touched.firstName && PersonalInfoFormik.errors.firstName}
              sx={{ color: '#B6B6B6' }}
            />
            {/* middleName */}
            <Field
              as={TextField}
              name="middleName"
              label={<Typography sx={{ color: '#B6B6B6' }}>Middle Name</Typography>}
              variant="standard"
              size="small"
              onChange={(e) => {
                PersonalInfoFormik.handleChange(e);
                indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
              }}
              value={PersonalInfoFormik.values.middleName}
              error={PersonalInfoFormik.touched.middleName && Boolean(PersonalInfoFormik.errors.middleName)}
              helperText={PersonalInfoFormik.touched.middleName && PersonalInfoFormik.errors.middleName}
              sx={{ color: '#B6B6B6' }}
            />
            {/* lastName */}
            <Field
              as={TextField}
              name="lastName"
              label={<Typography sx={{ color: '#B6B6B6' }}>Last Name</Typography>}
              variant="standard"
              size="small"
              onChange={(e) => {
                PersonalInfoFormik.handleChange(e);
                indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
              }}
              value={PersonalInfoFormik.values.lastName}
              error={PersonalInfoFormik.touched.lastName && Boolean(PersonalInfoFormik.errors.lastName)}
              helperText={PersonalInfoFormik.touched.lastName && PersonalInfoFormik.errors.lastName}
              sx={{ color: '#B6B6B6' }}
            />
            {/* dateOfBirth */}
            <LocalizationProvider dateAdapter={MomentUtils}>
              <Field
                as={DesktopDatePicker}
                label={<Typography sx={{ color: '#B6B6B6' }}>Date Of Birth</Typography>}
                inputFormat={user?.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                onChange={(e) => {
                  PersonalInfoFormik.setFieldValue('dateOfBirth', moment(e).format('yyyy-MM-DD'));
                  indata.indexOf('dateOfBirth') === -1 && setInData((pre) => [...pre, 'dateOfBirth']);
                  PersonalInfoFormik.setFieldTouched('dateOfBirth', true, false);
                }}
                value={PersonalInfoFormik.values.dateOfBirth}
                renderInput={(params) => (
                  <Field
                    as={TextField}
                    // name="dateOfBirth"
                    {...params}
                    variant="standard"
                    size="small"
                    error={PersonalInfoFormik.touched.dateOfBirth && Boolean(PersonalInfoFormik.errors.dateOfBirth)}
                    helperText={PersonalInfoFormik.touched.dateOfBirth && PersonalInfoFormik.errors.dateOfBirth}
                    sx={{ svg: { color: '#0071E3', mr: '5px' } }}
                    onBlur={() => {
                      PersonalInfoFormik.setFieldTouched('dateOfBirth', true, false);
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            {/* Gender */}
            <FormControl
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: '#3D71FF' }}>
                Gender
              </FormLabel>

              <RadioGroup
                row
                name="gender"
                onChange={(e) => {
                  PersonalInfoFormik.handleChange(e);
                  indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                }}
                value={PersonalInfoFormik.values.gender}
              >
                <Stack
                  direction="row"
                  sx={{
                    color: '#B6B6B6',
                    pl: '20px',
                    '@media (max-width: 640px)': {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <FormControlLabel value="female" control={<Radio />} label="Female" />
                  <FormControlLabel value="male" control={<Radio />} label="Male" />
                  <FormControlLabel value="not specify" control={<Radio />} label="Not Specify" defaultChecked />
                </Stack>
              </RadioGroup>
            </FormControl>
            {/* phoneNo */}
            <Stack direction={'row'} spacing={2}>
              <FormControl
                sx={{
                  width: '200px',
                  '@media (max-width: 640px)': {
                    width: '100px',
                  },
                  justifyContent: 'end',
                }}
              >
                <Field
                  as={TextField}
                  label={<Typography sx={{ color: '#B6B6B6', mt: '-5px' }}>Country Code</Typography>}
                  name="countryCode"
                  variant="standard"
                  size="small"
                  value={PersonalInfoFormik.values.countryCode}
                  onChange={PersonalInfoFormik.handleChange}
                  fullWidth
                  disabled
                  error={PersonalInfoFormik.touched.countryCode && Boolean(PersonalInfoFormik.errors.countryCode)}
                  InputProps={{
                    startAdornment: <>+</>,
                  }}
                  sx={{
                    width: '100%',
                    '@media (max-width: 640px)': {
                      width: '100px',
                    },
                    '&:after': {
                      borderBottomColor: '#0000',
                    },
                    '& .MuiSvgIcon-root': {
                      color: '#0071E3',
                    },
                  }}
                ></Field>
                {PersonalInfoFormik.errors.countryCode && (
                  <FormHelperText sx={{ color: '#FF4842' }}>
                    {PersonalInfoFormik.touched.countryCode && PersonalInfoFormik.errors.countryCode}
                  </FormHelperText>
                )}
              </FormControl>
              <Field
                as={TextField}
                fullWidth
                name="phoneNo"
                value={PersonalInfoFormik.values.phoneNo}
                onChange={(e) => {
                  PersonalInfoFormik.handleChange(e);
                  setVPhone(false);
                  indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                }}
                error={PersonalInfoFormik.touched.phoneNo && Boolean(PersonalInfoFormik.errors.phoneNo)}
                helperText={PersonalInfoFormik.touched.phoneNo && PersonalInfoFormik.errors.phoneNo}
                label={<Typography sx={{ color: '#B6B6B6' }}>Phone Number</Typography>}
                variant="standard"
                size="small"
                disabled={phoneDisable}
                InputProps={{
                  endAdornment: (
                    <>
                      {phoneDisable && vPhone ? (
                        <Button
                          variant="standard"
                          endIcon={<VerifiedIcon style={{ color: '#4CAF50' }} />}
                          sx={{ color: '#207420', textTransform: 'none' }}
                        >
                          {windowSize.width > 640 && 'Verified'}
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={() => verifyPhone()}
                            variant="text"
                            sx={{ color: '#2065D1', textTransform: 'none' }}
                          >
                            Verify
                          </Button>
                        </>
                      )}

                      {phoneDisable && (
                        <Button
                          variant="standard"
                          onClick={() => setPhoneDisable(false)}
                          endIcon={<SendIcon />}
                          sx={{ color: '#2065D1', textTransform: 'none' }}
                        >
                          change
                        </Button>
                      )}
                    </>
                  ),
                }}
              />
            </Stack>
            {/* Enter OTP */}
            {otpField && (
              <>
                <Stack
                  direction={'row'}
                  spacing={2}
                  sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <FormLabel id="demo-row-radio-buttons-group-label" sx={{ color: '#3D71FF', pt: '8px' }}>
                    Enter OTP
                  </FormLabel>
                  <Field
                    as={TextField}
                    style={{ width: '400px' }}
                    name="otp"
                    label={<Typography sx={{ color: '#B6B6B6' }}>Enter OTP</Typography>}
                    variant="standard"
                    onChange={PersonalInfoFormik.handleChange}
                    value={PersonalInfoFormik.values.otp}
                    error={PersonalInfoFormik.touched.otp && Boolean(PersonalInfoFormik.errors.otp)}
                    helperText={PersonalInfoFormik.touched.otp && PersonalInfoFormik.errors.otp}
                    disabled={otpDisable}
                    InputProps={{
                      endAdornment: (
                        <>
                          <Button
                            variant="outlined"
                            disabled={otpDisable}
                            onClick={() => {
                              verifyPhoneClick();
                              setOtpDisable(true);
                            }}
                            sx={{ color: '#2065D1', textTransform: 'none' }}
                          >
                            Verify
                          </Button>
                        </>
                      ),
                    }}
                    sx={{ width: '100%' }}
                  />
                </Stack>
              </>
            )}
            {/* Primary Email */}
            <FormControl
              sx={{
                width: '500px',
                '@media (max-width: 640px)': {
                  width: '100%',
                },
              }}
            >
              <Field
                as={TextField}
                name="email"
                label={<Typography sx={{ color: '#B6B6B6' }}>Primary Email</Typography>}
                variant="standard"
                size="small"
                disabled={emailDisable}
                InputProps={{
                  endAdornment: (
                    <>
                      {emailDisable && vEmail ? (
                        <Button
                          variant="standard"
                          endIcon={<VerifiedIcon style={{ color: '#4CAF50' }} />}
                          sx={{ color: '#207420', textTransform: 'none' }}
                        >
                          {windowSize.width > 640 && 'Verified'}
                        </Button>
                      ) : (
                        <>
                          <Button
                            onClick={handleVerifyEmail}
                            variant="text"
                            sx={{ color: '#2065D1', textTransform: 'none' }}
                          >
                            Verify
                          </Button>
                        </>
                      )}

                      {emailDisable && (
                        <Button
                          variant="standard"
                          onClick={() => setEmailDisable(false)}
                          endIcon={<SendIcon />}
                          sx={{ color: '#2065D1', textTransform: 'none' }}
                        >
                          change
                        </Button>
                      )}
                    </>
                  ),
                }}
                onChange={(e) => {
                  PersonalInfoFormik.handleChange(e);
                  indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                  debounce(validateEmail(e));
                }}
                value={PersonalInfoFormik.values.email}
                error={PersonalInfoFormik.touched.email && Boolean(PersonalInfoFormik.errors.email)}
                helperText={PersonalInfoFormik.touched.email && PersonalInfoFormik.errors.email}
                sx={{ width: '100%' }}
              />
              {emailId ? (
                <FormHelperText sx={{ color: '#FF4842' }}>
                  {PersonalInfoFormik.touched.email && PersonalInfoFormik.errors.email}
                  {emailId && emailId ? 'Primary email Already Exist' : ''}
                </FormHelperText>
              ) : (
                ''
              )}
            </FormControl>
            {/* addOnEmails */}
            {user?.role === 'user' ? (
              <FieldArray
                name="addOnEmails"
                render={({ push, remove }) => {
                  return (
                    <>
                      {PersonalInfoFormik.values.addOnEmails.length > 0 &&
                        PersonalInfoFormik.values.addOnEmails.map((email, idx) => {
                          return (
                            <div className="row" key={idx}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Field
                                  fullWidth
                                  as={TextField}
                                  type={'email'}
                                  variant="standard"
                                  label={<Typography sx={{ color: '#B6B6B6' }}>Addon email</Typography>}
                                  name={`addOnEmails.${idx}`}
                                  placeholder="jane@acme.com"
                                  error={
                                    PersonalInfoFormik.touched.addOnEmails &&
                                    Boolean(PersonalInfoFormik.errors?.addOnEmails?.[idx])
                                  }
                                  helperText={
                                    PersonalInfoFormik.touched.addOnEmails &&
                                    PersonalInfoFormik.errors?.addOnEmails?.[idx]
                                  }
                                  onChange={(e) => {
                                    PersonalInfoFormik.handleChange(e);
                                    indata.indexOf('addOnEmails') === -1 && setInData((pre) => [...pre, 'addOnEmails']);
                                    const newEmails = [...PersonalInfoFormik.values.addOnEmails];
                                    newEmails[idx] = e.target.value;
                                    // PersonalInfoFormik.setFieldTouched('addOnEmails',true,true)
                                    return PersonalInfoFormik.setFieldValue('addOnEmails', newEmails);
                                  }}
                                  value={PersonalInfoFormik.values?.addOnEmails[idx]?.email}
                                  InputProps={{
                                    endAdornment: PersonalInfoFormik.values?.addOnEmails[idx]?.verified ? (
                                      <Button
                                        variant="standard"
                                        endIcon={<VerifiedIcon style={{ color: '#4CAF50' }} />}
                                        sx={{ color: '#207420', textTransform: 'none' }}
                                      >
                                        {windowSize.width > 640 && 'Verified'}
                                      </Button>
                                    ) : (
                                      <>
                                        {
                                          PersonalInfoFormik.values?.addOnEmails[idx]?.email ? (
                                            <Button
                                              variant="text"
                                              sx={{ color: '#2065D1', textTransform: 'none' }}
                                              onClick={() => {
                                                console.log(
                                                  'addon email sent to ==> ',
                                                  PersonalInfoFormik.values?.addOnEmails[idx]
                                                );
                                                AddAddonEmails({
                                                  email: PersonalInfoFormik.values?.addOnEmails[idx]?.email
                                                    ? [PersonalInfoFormik.values?.addOnEmails[idx]?.email]
                                                    : [PersonalInfoFormik.values?.addOnEmails[idx]],
                                                }).then((res) => {
                                                  if (res.data.success === true) {
                                                    // SuccessToast('Mail sent successfully on your email.');
                                                    SuccessToast(res.data?.message);
                                                  } else if (res.data.success === false && res.data.message) {
                                                    ErrorToast(`${res.data.message}`);
                                                  } else {
                                                    ErrorToast(`Something went wrong!`);
                                                  }
                                                });
                                              }}
                                            >
                                              Verify
                                            </Button>
                                          ) : null /* If email is empty, don't render the button */
                                        }
                                      </>
                                    ),
                                  }}
                                />

                                <div className="col">
                                  <div>
                                    <Fab
                                      variant="outlined"
                                      color="error"
                                      sx={{ ml: 1, mr: 1, height: '20px', width: '35px' }}
                                      onClick={(e) => {
                                        if (PersonalInfoFormik.values?.addOnEmails[idx].length <= 0) {
                                          remove(idx);
                                        } else {
                                          setOpenDeleteAddon(true);
                                          setWillBeDeleteAddonEmail(email.email);
                                          setWillBeDeleteAddonEmailId(idx);
                                        }

                                        // DeleteAddonEmails({ email: email.email }).then((res) => {
                                        //   if (res.data.success === true) {
                                        //     // console.log('email deletted');
                                        //     SuccessToast('email deleted!!');
                                        //   }
                                        // });
                                        // remove(idx);
                                      }}
                                    >
                                      <CloseIcon />
                                    </Fab>
                                  </div>
                                </div>
                              </Box>
                              {/* {PersonalInfoFormik.errors.addOnEmails && PersonalInfoFormik.touched.addOnEmails && (
                            <FormHelperText sx={{ color: '#FF4842' }}>
                              {PersonalInfoFormik.errors?.addOnEmails[idx]}
                            </FormHelperText>
                          )} */}
                            </div>
                          );
                        })}
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link
                          variant="contained"
                          onClick={(e) => {
                            e.preventDefault();
                            try {
                              if (PersonalInfoFormik.values.addOnEmails.length < 5) {
                                push('');
                              } else {
                                ErrorToast('Cannot add more than 5 Addon emails');
                              }
                            } catch (error) {
                              console.error('Error adding Addon email:', error);
                            }
                          }}
                          style={{
                            textTransform: 'none',
                            textDecoration: 'none',
                            color: '#2065D1',
                          }}
                        >
                          + Add Addon email
                        </Link>
                      </Box>
                    </>
                  );
                }}
              ></FieldArray>
            ) : (
              <></>
            )}
          </Stack>
          <Box>
            <Button
              color="primary"
              variant="contained"
              // type="submit"
              onClick={PersonalInfoFormik.submitForm}
              disabled={updateBtnDisable || !PersonalInfoFormik.dirty || emailId}
              sx={{
                width: '190px',
                height: '45px',
                backgroundColor: '#3D71FF',
                borderRadius: '30px',
                mt: '35px',
                textTransform: 'none',
              }}
            >
              Update
            </Button>
          </Box>
        </Form>
      </FormikProvider>
      <DeleteAddonEmailModal
        openDeleteAddon={openDeleteAddon}
        setOpenDeleteAddon={setOpenDeleteAddon}
        email={willBeDeleteAddonEmail}
        handleDeleteAddonEmail={handleDeleteAddonEmail}
        //  remove
        //  idx
      />
    </>
  );
};

export default ProfileGeneralTab;
