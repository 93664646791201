import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// material
import { tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MomentUtils from '@date-io/moment';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  FormControl,
  Divider,
  Popover,
  Badge,
  Box,
  Grid,
  useMediaQuery,
  Menu,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';

// component
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import Iconify from '../../components/Iconify';

import { setSubscriptions, subscriptionFilteredDataPayload } from '../../store/subscriptionSlice';
import { FilterResponse, GetcompaniesResponse, GetsubsResponse, getCompanyType } from '../../services/Service';
import Scrollbar from '../../components/Scrollbar';
import CustomRating from '../../components/UI/CustomRating';
import CustomMuiSelect from '../../components/UI/CustomMuiSelect';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 50,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0px 16px !important',
  paddingLeft: '16px !important',
  paddingRight: '16px !important',
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

SubscriptionFilterMenu.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function SubscriptionFilterMenu({
  labelList,
  filterName,
  onFilterName,
  setCname,
  cname,
  pagination,
  sendDataToSubscription,
  orderBy,
  order,
}) {
  const dispatch = useDispatch();
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [subscriptionName, setSubscriptionName] = useState(null);
  const [amountMin, setAmountMin] = useState(null);
  const [amountMax, setAmountMax] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [status, setStatus] = useState(null);
  const [label, setLabel] = useState(null);
  const [nextBillingFrom, setNextBillingFrom] = useState(null);
  const [nextBillingTo, setNextBillingTo] = useState(null);
  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);
  const [expiryDateFrom, setExpiryDateFrom] = useState(null);
  const [expiryDateTo, setExpiryDateTo] = useState(null);
  const [isLiked, setIsLiked] = useState(null);
  const [rating, setRating] = useState(null);
  const [subType, setSubType] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSmallDesktop = useMediaQuery('(max-width: 1200px)');
  const [rawCompanyDataRaw, setRawCompanyDataRaw] = useState([]);
  const [rawCompanyDataFilter, setRawCompanyDataFilter] = useState([]);
  const [rawCompanyTypeData, setRawCompanyTypeData] = useState([]);

  const searchFilterClick = () => {
    if (
      startDateFrom ||
      nextBillingFrom ||
      nextBillingTo ||
      expiryDateFrom ||
      expiryDateTo ||
      startDateFrom ||
      startDateTo ||
      subscriptionName ||
      amountMin ||
      amountMax ||
      frequency ||
      status ||
      label ||
      isLiked ||
      rating ||
      subType
    ) {
      GetsubsResponse(pagination?.page || 1, pagination?.limit || 5, orderBy, order, {
        startDate: startDateFrom,
        nextBillingFrom: nextBillingFrom,
        nextBillingTo: nextBillingTo,
        startDateFrom: startDateFrom,
        startDateTo: startDateTo,
        expiryDateFrom: expiryDateFrom,
        expiryDateTo: expiryDateTo,
        subscriptionName,
        amountMin: amountMin,
        amountMax: amountMax,
        frequency,
        status,
        label,
        isLiked,
        rating,
        subType,
      }).then((res) => {
        if (res.data.success === true) {
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data?.pagination[0]?.totalItems }));
          dispatch(
            subscriptionFilteredDataPayload({
              subscriptionFilter: {
                startDate: startDateFrom,
                nextBillingFrom: nextBillingFrom,
                nextBillingTo: nextBillingTo,
                startDateFrom: startDateFrom,
                startDateTo: startDateTo,
                expiryDateFrom: expiryDateFrom,
                expiryDateTo: expiryDateTo,
                subscriptionName,
                amountMin: amountMin,
                amountMax: amountMax,
                frequency,
                status,
                isLiked,
                rating,
                subType,
                label,
              },
            })
          );

          sendDataToSubscription({
            startDate: startDateFrom,
            nextBillingFrom: nextBillingFrom,
            nextBillingTo: nextBillingTo,
            startDateFrom: startDateFrom,
            startDateTo: startDateTo,
            expiryDateFrom: expiryDateFrom,
            expiryDateTo: expiryDateTo,
            subscriptionName,
            amountMin: amountMin,
            amountMax: amountMax,
            frequency,
            status,
            isLiked,
            rating,
            subType,
            label,
          });

          handleClose();
        } else {
          GetsubsResponse(pagination?.page || 1, pagination?.limit || 5, orderBy, order).then((res) => {
            if (res.data.success === true) {
              dispatch(
                setSubscriptions({
                  subscriptions: res.data.data[0].data,
                  totalItems: res.data?.pagination[0]?.totalItems,
                })
              );
              handleClose();
            }
          });
        }
      });
    } else {
      clearFilter();
    }
  };

  useEffect(() => {
    if (open) {
      fetchCompanyType();
      fetchCompanyData();
    }
  }, [open]);

  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchCompanyType = async () => {
    try {
      const res = await getCompanyType();
      const typeData = res.data.data.map((e) => e.type);
      setRawCompanyTypeData(typeData.sort((a, b) => a.localeCompare(b)));
    } catch (err) {
      console.log(err);
    }
  };
  const fetchCompanyData = async () => {
    try {
      const res = await GetcompaniesResponse();
      setRawCompanyDataRaw(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const checkboxFilterNew = (name, flag) => {
    if (flag) {
      setCname((prev) => [...prev, name]);
    } else {
      setCname(cname.filter((val) => val !== name));
    }
  };
  const clearFilter = () => {
    setAnchorEl(null);
    setCname([]);
    sendDataToSubscription({});

    // FilterResponse(null, null, null, null, null, null, null, null, null)
    GetsubsResponse(pagination?.page || 1, pagination?.limit || 5, orderBy, order).then((res) => {
      setSubscriptionName(null);
      setAmountMin(null);
      setAmountMax(null);
      setFrequency(null);
      setStartDateFrom(null);
      setStatus(null);
      setLabel(null);
      setNextBillingFrom(null);
      setNextBillingTo(null);
      setStartDateFrom(null);
      setStartDateTo(null);
      setExpiryDateFrom(null);
      setExpiryDateTo(null);
      setIsLiked(null);
      setRating(null);
      setSubType(null);
      setAnchorEl(null);
      if (res.data.success === true) {
        dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
      } else {
        GetsubsResponse(pagination?.page || 1, pagination?.limit || 5, orderBy, order).then((res) => {
          if (res.data.success === true) {
            dispatch(
              setSubscriptions({ subscriptions: res.data.data[0].data, totalItems: res.data.pagination[0].totalItems })
            );
          }
        });
      }
    });
  };

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            ml: 2,
            mr: 2,
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!cname.length > 0 || cname[0] === ''}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '350px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        {/* subscriptionType */}
        <CustomMuiSelect
          disablePortal={false}
          value={subType}
          onChange={(e) => {
            if (e) {
              setSubType(e.label);
              checkboxFilterNew('subscriptionType', true);
              setRawCompanyDataFilter(
                rawCompanyDataRaw?.filter((ex) => ex?.companyType?.toLowerCase() === e.label?.toLowerCase())
              );
            } else {
              setSubType(null);
              setRawCompanyDataFilter([]);
              checkboxFilterNew('subscriptionType', false);
            }
          }}
          sx={{
            my: '8px',
            // '& .MuiInputBase-root': {
            //   border: subType && '1px solid #3d71ff',
            // },
          }}
          focused={subType}
          label="Subscriptions Type"
          size="small"
          variant="outlined"
          title={subType}
          options={rawCompanyTypeData.map((e) => ({ label: e }))}
        />
        {/* subscriptionName */}
        <CustomMuiSelect
          disablePortal={false}
          sx={{
            my: '8px',
          }}
          label="Subscriptions Name"
          size="small"
          variant="outlined"
          groupBy={(option) => option.type}
          options={
            subType
              ? rawCompanyDataFilter.map((e) => ({ label: e.name }))
              : rawCompanyDataRaw.map((e) => ({ label: e.name, type: e.companyType }))
          }
          title={subscriptionName}
          value={subscriptionName}
          focused={subscriptionName}
          onChange={(e) => {
            if (e) {
              setSubscriptionName(e.label);
              checkboxFilterNew('subscriptionName', true);
            } else {
              setSubscriptionName(null);
              checkboxFilterNew('subscriptionName', false);
            }
          }}
        />
        {/* frequency */}
        <TextField
          value={frequency}
          focused={frequency}
          onChange={(e) => {
            setFrequency(e.target.value);
            checkboxFilterNew('frequency', true);
          }}
          sx={{
            my: '8px',
          }}
          select
          label="Frequency"
          size="small"
          fullWidth
          variant="outlined"
        >
          <MenuItem title="Annually" value="Annually">
            Annually
          </MenuItem>
          <MenuItem title="Monthly" value="Monthly">
            Monthly
          </MenuItem>
          <MenuItem title="Weekly" value="Weekly">
            Weekly
          </MenuItem>
          <MenuItem title="Daily" value="Daily">
            Daily
          </MenuItem>
        </TextField>
        {/* status */}
        <TextField
          value={status}
          focused={status}
          onChange={(e) => {
            setStatus(e.target.value);
            checkboxFilterNew('status', true);
          }}
          sx={{
            my: '8px',
          }}
          name="status"
          select
          label="Status"
          size="small"
          fullWidth
          variant="outlined"
        >
          <MenuItem title="Active" value="Active">
            Active
          </MenuItem>
          <MenuItem title="Inactive" value="Inactive">
            Inactive
          </MenuItem>
        </TextField>
        {/* label */}
        <CustomMuiSelect
          disablePortal={false}
          sx={{
            my: '8px',
          }}
          label="Label"
          size="small"
          variant="outlined"
          options={labelList.map((e) => ({ label: e.name, id: e._id }))}
          title={labelList.find((e) => e._id === label)?.name}
          value={labelList.find((e) => e._id === label)?.name}
          focused={labelList.find((e) => e._id === label)?.name}
          onChange={(e) => {
            if (e) {
              setLabel(e.id);
              checkboxFilterNew('label', true);
            } else {
              setLabel(null);
              checkboxFilterNew('label', false);
            }
          }}
        />
        {/* amount */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Amount
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <TextField
              value={amountMin}
              focused={amountMin}
              onChange={(e) => {
                if (e.target.value) {
                  checkboxFilterNew('amountMin', true);
                  setAmountMin(e.target.value);
                } else {
                  checkboxFilterNew('amountMin', false);
                  setAmountMin(null);
                }
              }}
              type={'number'}
              name="points"
              label="Min"
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{login.user.country === 'India' ? '₹' : '$'}</InputAdornment>
                ),
              }}
            />
            <TextField
              value={amountMax}
              focused={amountMax}
              onChange={(e) => {
                if (e.target.value) {
                  checkboxFilterNew('amountMax', true);
                  setAmountMax(e.target.value);
                } else {
                  checkboxFilterNew('amountMax', false);
                  setAmountMax(null);
                }
              }}
              type={'number'}
              name="points"
              label="Max"
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{login.user.country === 'India' ? '₹' : '$'}</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <LocalizationProvider dateAdapter={MomentUtils} adapterLocale={login.user.country === 'India' ? 'en-gb' : 'en'}>
          {/* Start Date */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0px' }}>
            <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
              Start Date
            </span>
            <div style={{ display: 'flex', gap: '8px' }}>
              <DatePicker
                value={startDateFrom}
                onChange={(newValue) => {
                  const dateV = moment(newValue).format('yyyy-MM-DD');
                  if (dateV === 'Invalid date') {
                    setStartDateFrom(null);
                    checkboxFilterNew('startDateFrom', false);
                  } else {
                    checkboxFilterNew('startDateFrom', true);
                    setStartDateFrom(moment(newValue).format('yyyy-MM-DD'));
                  }
                }}
                type="date"
                label="From"
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  readOnly: true,
                }}
                renderInput={(props) => (
                  <TextField {...props} sx={{ width: '100%' }} size="small" focused={startDateFrom} />
                )}
              />
              <DatePicker
                value={startDateTo}
                onChange={(newValue) => {
                  const dateV = moment(newValue).format('yyyy-MM-DD');
                  if (dateV === 'Invalid date') {
                    setStartDateTo(null);
                    checkboxFilterNew('startDateFrom', false);
                  } else {
                    checkboxFilterNew('startDateFrom', true);
                    setStartDateTo(moment(newValue).format('yyyy-MM-DD'));
                  }
                }}
                type="date"
                label="To"
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  readOnly: true,
                }}
                renderInput={(props) => (
                  <TextField {...props} sx={{ width: '100%' }} size="small" focused={startDateTo} />
                )}
              />
            </div>
          </div>
          {/* Next Billing Date - nextBilling */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0px' }}>
            <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
              Next Billing Date
            </span>
            <div style={{ display: 'flex', gap: '8px' }}>
              <DatePicker
                value={nextBillingFrom}
                onChange={(newValue) => {
                  const dateV = moment(newValue).format('yyyy-MM-DD');
                  if (dateV === 'Invalid date') {
                    setNextBillingFrom(null);
                    checkboxFilterNew('nextBillingFrom', false);
                  } else {
                    checkboxFilterNew('nextBillingFrom', true);
                    setNextBillingFrom(moment(newValue).format('yyyy-MM-DD'));
                  }
                }}
                type="date"
                label="From"
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  readOnly: true,
                }}
                renderInput={(props) => (
                  <TextField {...props} sx={{ width: '100%' }} size="small" focused={nextBillingFrom} />
                )}
              />
              <DatePicker
                value={nextBillingTo}
                onChange={(newValue) => {
                  const dateV = moment(newValue).format('yyyy-MM-DD');
                  if (dateV === 'Invalid date') {
                    setNextBillingTo(null);
                    checkboxFilterNew('nextBillingTo', false);
                  } else {
                    checkboxFilterNew('nextBillingTo', true);
                    setNextBillingTo(moment(newValue).format('yyyy-MM-DD'));
                  }
                }}
                type="date"
                label="To"
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  readOnly: true,
                }}
                renderInput={(props) => (
                  <TextField {...props} sx={{ width: '100%' }} size="small" focused={nextBillingTo} />
                )}
              />
            </div>
          </div>
          {/* Expiry Date */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '4px 0px' }}>
            <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
              Expiry Date
            </span>
            <div style={{ display: 'flex', gap: '8px' }}>
              <DatePicker
                value={expiryDateFrom}
                onChange={(newValue) => {
                  const dateV = moment(newValue).format('yyyy-MM-DD');
                  if (dateV === 'Invalid date') {
                    setExpiryDateFrom(null);
                    checkboxFilterNew('expiryDateFrom', false);
                  } else {
                    checkboxFilterNew('expiryDateFrom', true);
                    setExpiryDateFrom(moment(newValue).format('yyyy-MM-DD'));
                  }
                }}
                type="date"
                label="From"
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  readOnly: true,
                }}
                renderInput={(props) => (
                  <TextField {...props} sx={{ width: '100%' }} size="small" focused={expiryDateFrom} />
                )}
              />
              <DatePicker
                value={nextBillingTo}
                onChange={(newValue) => {
                  const dateV = moment(newValue).format('yyyy-MM-DD');
                  if (dateV === 'Invalid date') {
                    setExpiryDateTo(null);
                    checkboxFilterNew('expiryDateTo', false);
                  } else {
                    checkboxFilterNew('expiryDateTo', true);
                    setExpiryDateTo(moment(newValue).format('yyyy-MM-DD'));
                  }
                }}
                type="date"
                label="To"
                size="small"
                fullWidth
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start" />,
                  readOnly: true,
                }}
                renderInput={(props) => (
                  <TextField {...props} sx={{ width: '100%' }} size="small" focused={nextBillingTo} />
                )}
              />
            </div>
          </div>
        </LocalizationProvider>
        {/* isLiked */}
        <TextField
          focused={isLiked}
          value={isLiked}
          onChange={(e) => {
            setIsLiked(e.target.value);
            checkboxFilterNew('isLiked', true);
          }}
          select
          label="Safals"
          size="small"
          fullWidth
          variant="outlined"
          sx={{
            my: '8px',
            '&:after': {
              borderBottomColor: '#0000',
            },
            '& .MuiSvgIcon-root': {
              color: '#0071E3',
            },
            '& .MuiSelect-select': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        >
          <MenuItem value={-1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/assets/images/disLike.png" alt="DisLike Symbol" />
          </MenuItem>
          <MenuItem value={'0'} sx={{ display: 'flex', justifyContent: 'center' }}>
            <>
              <img src="/assets/images/Like.png" alt="Like Symbol" style={{ filter: 'grayscale(100%)' }} />
              <img src="/assets/images/disLike.png" alt="DisLike Symbol" style={{ filter: 'grayscale(100%)' }} />
            </>
          </MenuItem>
          <MenuItem value={1} sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src="/assets/images/Like.png" alt="Like Symbol" />
          </MenuItem>
        </TextField>
        {/* rating */}
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '6px', marginBottom: '12px' }}>
          <span className="filter-sub-label-x2">Rating</span>
          <CustomRating
            name="searchRating"
            value={rating}
            onChange={(e) => {
              setRating(e.target.value);
              checkboxFilterNew('rating', true);
            }}
            precision={0.5}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={() => handleClose()}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={() => clearFilter()}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          {/* {cname.length > 0 && (
            <Button
              sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
              onClick={() => clearFilter()}
              size="small"
              variant="contained"
            >
              Reset
            </Button>
          )} */}
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={() => searchFilterClick()}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
}

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })
);
