import { Box, Button, Fab, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const DeleteConfirmationModal = ({ open, onClose, onSubmit, data }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
  };

  const onCloseLocal = () => {
    onClose();
  };

  return (
    <>
      <Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
        <Box sx={{ ...style, width: 500, height: 180 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab onClick={onCloseLocal} size="small" color="primary" aria-label="add">
              <CloseIcon />
            </Fab>
          </Box>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Do you sure want to Delete?
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Button
                sx={{
                  backgroundColor: '#ff4842',
                  ':hover': { backgroundColor: '#d40700' },
                  color: 'white',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                onClick={onCloseLocal}
              >
                No
              </Button>
            </Box>
            <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
              <Button
                sx={{
                  color: 'white',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                onClick={() => onSubmit(data)}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteConfirmationModal;
