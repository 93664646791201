import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, IconButton, Typography } from '@mui/material';

export default function ThreeDotMenu({ onEdit, onAccept, data, onReject }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = () => {
    handleClose();
    onEdit(data);
  };
  const onAcceptClick = () => {
    handleClose();
    onAccept(data);
  };
  const onRejectClick = () => {
    handleClose();
    onReject(data);
  };

  return (
    <>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            padding: '0px',
          }}
        >
          {onEdit && (
            <MenuItem
              sx={{
                backgroundColor: '#3d71ff',
                color: 'white',
                ':hover': {
                  backgroundColor: '#315acc',
                  color: 'white',
                },
              }}
              onClick={onEditClick}
            >
              <Typography variant="inherit">Edit</Typography>
            </MenuItem>
          )}
          {onAccept && (
            <MenuItem
              sx={{
                backgroundColor: '#229A16',
                color: 'white',
                ':hover': {
                  backgroundColor: '#1b7b12',
                  color: 'white',
                },
              }}
              onClick={onAcceptClick}
            >
              <Typography variant="inherit">Accept</Typography>
            </MenuItem>
          )}
          {onReject && (
            <MenuItem
              sx={{
                backgroundColor: '#ff4842',
                color: 'white',
                ':hover': {
                  backgroundColor: '#cc3a35',
                  color: 'white',
                },
              }}
              onClick={onRejectClick}
            >
              <Typography variant="inherit">Reject</Typography>
            </MenuItem>
          )}
        </Menu>
      </div>
    </>
  );
}
