/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import moment from 'moment';
import { getFeedbackAction, getFeedbacksAction, handleFeedbackAction } from '../../store/feedbackSlice';
import FeedbackComponent from '../FeedbackComponent';
import Scrollbar from '../Scrollbar';
import ErrorToast from '../Toast/Error';
import SuccessToast from '../Toast/Success';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '20px',
  boxShadow: 24,
  // p: "4 0 4 4",
  p: '30px',
};

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
      outline: 'none',
      borderRadius: 60,
    },
  },
});
const FeedbackModalView = (props) => {
  const [showFullShortDescription, setShowFullShortDescription] = useState(false);
  const { openEditModal, setOpenEditModal, id, color, isEdit } = props;
  const useStyles = makeStyles({
    close_div1: {
      float: 'right',
      marginTop: '-15px',
      marginRight: '-15px',
    },
    close_div: {
      height: 45,
      width: 45,
      borderRadius: '50%',
      backgroundColor: `${color ? '#0058ffdb' : '#90EE90'}`,
      color: `${color && '#FFFFFF'}`,
      padding: '8px 9px',
      cursor: 'pointer',
    },
    close_icon: {
      height: 27,
      width: 27,
    },
  });
  const classes = useStyles();
  const { user } = useSelector((state) => state.login);
  const { current } = useSelector((state) => state.feedback);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenEditModal(false);
  };

  useEffect(() => {
    if (openEditModal && dispatch) dispatch(getFeedbackAction({ id }));
  }, [openEditModal, dispatch]);

  const validationSchema = Yup.object().shape({
    details: Yup.string().required('Please enter details'),
    description: Yup.string().required('Please enter description'),
    attachment: Yup.mixed().required('Please select any one.'),
  });

  const initialValues = {
    details: '',
    attachment: null,
    description: '',
    updatedBy: user?.firstName,
  };

  const editValues = {
    details: current?.details,
    attachment: current?.attachment,
    description: current?.description,
    status: current?.status,
  };
  return (
    <Modal open={openEditModal} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
      <Scrollbar
        sx={{
          ...style,
          // overflow:'auto',
          height: '86%',
          backgroundColor: '#F2F2F2',
          width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' },
        }}
      >
        <div className={classes.close_div1} style={{ position: 'fixed', right: '30px' }}>
          <div className={classes.close_div} onClick={handleClose} aria-hidden="true">
            <CloseIcon className={classes.close_icon} />
          </div>
        </div>

        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontSize: '27px', fontWeight: 'bolder', color: `${color ? '#0058ffdb' : '#4F7942'}` }}
        >
          <p>
            {' '}
            SS{current?.supportId} -{' '}
            {current?.description.length < 80
              ? current?.description
              : !showFullShortDescription
              ? current?.description.slice(0, 80)
              : current?.description}
            {
              <button
                style={{ cursor: 'pointer', background: 'none', border: 'none' }}
                onClick={() => setShowFullShortDescription(!showFullShortDescription)}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontSize: '27px', fontWeight: 'bolder', color: `${color ? '#0058ffdb' : '#4F7942'}` }}
                >
                  ...
                </Typography>
              </button>
            }
          </p>
        </Typography>

        <FeedbackComponent
          item={{
            description: current?.description,
            details: current?.details,
            updatedBy: user?.userID,
            createdAt: current?.createdAt,
            updatedAt: current?.updatedAt,
            attachment: current?.attachment,
            status: current?.status,
            userRole: 'user',
          }}
          user={user}
          color={'0058ffdb'}
          isLast={current?.feedback?.length === 0}
          mode={'user'}
          feedbackId={current?._id}
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          isEdit={isEdit}
        />
        <Scrollbar sx={{ paddingBottom: '40%', height: '40vh' }}>
          {Array.isArray(current?.feedback) &&
            _.reverse([...current?.feedback])?.map((item, index) => (
              <FeedbackComponent
                item={{
                  description: item?.description,
                  details: item?.details,
                  updatedBy: user?.userID,
                  createdAt: item?.createdAt,
                  updatedAt: item?.updatedAt,
                  attachment: item?.attachment,
                  status: item?.status,
                  userRole: item?.userRole,
                }}
                user={current?.feedbackUsers.find((d) => d._id === item.updatedBy)}
                isLast={index === 0}
                color={'0058ffdb'}
                mode={'user'}
                feedbackId={current?._id}
                openEditModal={openEditModal}
                setOpenEditModal={setOpenEditModal}
                isEdit={isEdit}
                prevData={index !== 0 && _.reverse([...current.feedback])[index - 1]}
              />
            ))}
        </Scrollbar>
      </Scrollbar>
    </Modal>
  );
};

export default FeedbackModalView;
