// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { getSignedURL } from '../../services/Service';

const NewMedia = ({ message, self }) => {
  const [urlState, setUrlState] = useState('');

  const messageType = message.messageType ? message.messageType : '';

  const isOtherType = messageType !== 'image' ? messageType !== 'video' : false;

  const getValidSignedURL = async () => {
    if (message.url) {
      const res = await getSignedURL({ url: message.url });
      if (res.status === 200) {
        setUrlState(res.data);
      } else {
        setUrlState('https://dummyimage.com/1000x1000/000/fff&text=Image');
      }
    } else {
      setUrlState('https://dummyimage.com/1000x1000/000/fff&text=Image');
    }
  };

  useEffect(() => {
    getValidSignedURL();
  }, []);

  const onDownload = async () => {
    const res = await getSignedURL({ url: message.url });
    if (res.status === 200) {
      window.open(res.data, '_blank');
    }
  };

  const _fileName = message.url.split('/');
  const fileName = _fileName[_fileName.length - 1] || messageType;

  return (
    <>
      <div style={{ position: 'relative', width: isOtherType ? '100%' : '50%' }}>
        {isOtherType && <div className={`message-type-file ${!self && 'not-self'}`}>{fileName}</div>}
        {messageType === 'image' && <img src={urlState} alt="message" />}
        {messageType === 'video' && <ReactPlayer width="100%" height="100%" url={urlState} controls />}
        <span
          className={messageType === 'video' ? 'download-link-video' : 'download-link'}
          onClick={onDownload}
          onKeyDown={onDownload}
          aria-hidden="true"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V12.1578L16.2428 8.91501L17.657 10.3292L12.0001 15.9861L6.34326 10.3292L7.75748 8.91501L11 12.1575V5Z"
              fill="currentColor"
            />
            <path
              d="M4 14H6V18H18V14H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14Z"
              fill="currentColor"
            />
          </svg>
        </span>
      </div>
      {message.text && message.text.length > 0 && (
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <span className={`message-type-file ${!self && 'not-self'}`}>{message.text}</span>
        </div>
      )}
    </>
  );
};

export default NewMedia;
