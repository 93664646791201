import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Box, Button, Fab, Modal, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import SuccessToast from '../Toast/Success';
import ErrorToast from '../Toast/Error';
import { deleteAccountConfig } from '../../services/Service';
import UserAssessmentModal from './UserAssessmentModal';

const DeleteAccount = ({ openDeleteAccount, setOpenDeleteAccount, setOpen }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [openUserAssessmentModal, setOpenUserAssessmentModal] = useState(false);

  const handleDeleteAccount = () => {
    deleteAccountConfig()
      .then(() => {
        SuccessToast('Your Account has been Deleted !');
        localStorage.clear();
        navigate('/');
      })
      .then(() => {})
      .catch((error) => {
        ErrorToast('some error occured');
      });
  };

  const onCloseUserAssessmentModal = () => {
    setOpenUserAssessmentModal(false);
    handleDeleteAccount();
  };
  const onOpenUserAssessmentModal = () => {
    setOpenUserAssessmentModal(true);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
    // boxShadow: 24,
  };
  return (
    <>
      <Modal
        open={openDeleteAccount}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 500,
            height: 180,
            '@media (max-width: 640px)': {
              width: '95vw',
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab
              onClick={() => setOpenDeleteAccount(false)}
              size="small"
              aria-label="add"
              sx={{ background: theme.palette.secondary.title }}
            >
              <CloseIcon />
            </Fab>
          </Box>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Are you sure you want to Delete Your Account ?
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Button
                variant="contained"
                onClick={() => onOpenUserAssessmentModal()}
                sx={{ background: theme.palette.secondary.title, textTransform: 'capitalize' }}
              >
                Yes
              </Button>
            </Box>

            <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
              <Button
                variant="contained"
                sx={{ background: theme.palette.secondary.title, textTransform: 'capitalize' }}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenDeleteAccount(false);
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <UserAssessmentModal
        scenario="DeleteAccount"
        open={openUserAssessmentModal}
        onClose={onCloseUserAssessmentModal}
      />
    </>
  );
};

export default DeleteAccount;
