import { IconButton, Tooltip } from '@mui/material';
import Iconify from '../../../components/Iconify';

const CheckboxAction = ({ onEdit, onDelete, selectedAccount }) => {
  return (
    <div className="CheckboxAction">
      <div
        style={{
          paddingLeft: '12px',
          display: 'flex',
          backgroundColor: 'rgb(123, 158, 253)',
          color: 'white',
          alignItems: 'center',
          borderRadius: '20px',
        }}
      >
        <span>{selectedAccount.length} selected</span>
        <Tooltip title={'Delete'}>
          <IconButton onClick={() => onDelete(selectedAccount)}>
            <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
          </IconButton>
        </Tooltip>
        {selectedAccount.length === 1 && (
          <Tooltip title={'Edit'}>
            <IconButton onClick={() => onEdit(selectedAccount[0])}>
              <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
export default CheckboxAction;
