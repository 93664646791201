/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Typography } from '@mui/material';

import ProfileGeneralTab from './ProfileTabs/ProfileGeneralTab';
import SecurityTab from './SecurityTab';
import ProfileAddressTab from './ProfileTabs/ProfileAddressTab';

const PersonalInfoTab = () => {
  const newValueLocal = localStorage.getItem('PersonalInfoTab-handleChangeSubTab');

  const [selectedSubTab, setSelectedSubTab] = useState(newValueLocal ? parseInt(newValueLocal, 10) : 0);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      key: index,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChangeSubTab = (event, newValue) => {
    localStorage.setItem('PersonalInfoTab-handleChangeSubTab', newValue);
    setSelectedSubTab(newValue);
  };

  useEffect(() => {
    return () => {
      localStorage.setItem('PersonalInfoTab-handleChangeSubTab', 0);
    };
  }, []);

  return (
    <>
      <Tabs
        value={selectedSubTab}
        onChange={handleChangeSubTab}
        fullWidth
        sx={{
          marginBottom: '16px',
          borderRadius: '30px',
          p: 0.5,
          color: '#000000',
          '@media (max-width: 640px)': {
            padding: '4px !important',
          },
          '& .Mui-selected svg': {
            fill: 'white',
            mr: 0.7,
          },
          '& .MuiButtonBase-root.MuiTab-labelIcon.Mui-selected': {
            borderRadius: '30px',
            backgroundColor: '#3D71FF',
            textTransform: 'none',
            textDecoration: 'none',
            color: '#FFFFFF',
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        <Tab
          fullWidth
          {...a11yProps(0)}
          label={
            <>
              <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>General</Typography>
            </>
          }
          iconPosition="start"
          sx={{
            width: '20%',
            minHeight: '30px',
            textTransform: 'none',
          }}
          style={{
            color: selectedSubTab === 0 ? '#FFFFFF' : '#000000',
            borderRadius: selectedSubTab === 0 && '30px',
            backgroundColor: selectedSubTab === 0 && '#3D71FF',
            textDecoration: selectedSubTab === 0 && 'none',
          }}
        />
        {/* <Tab
          fullWidth
          {...a11yProps(1)}
          label={
            <>
              <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>Security</Typography>
            </>
          }
          iconPosition="start"
          sx={{
            width: '20%',
            minHeight: '30px',
            textTransform: 'none',
          }}
          style={{
            color: selectedSubTab === 1 ? '#FFFFFF' : '#000000',
            borderRadius: selectedSubTab === 1 && '30px',
            backgroundColor: selectedSubTab === 1 && '#3D71FF',
            textDecoration: selectedSubTab === 1 && 'none',
          }}
        /> */}
        <Tab
          fullWidth
          {...a11yProps(1)}
          label={
            <>
              <Typography sx={{ fontSize: '15px', whiteSpace: 'nowrap' }}>Address</Typography>
            </>
          }
          iconPosition="start"
          sx={{
            width: '20%',
            minHeight: '30px',
            textTransform: 'none',
          }}
          style={{
            color: selectedSubTab === 1 ? '#FFFFFF' : '#000000',
            borderRadius: selectedSubTab === 1 && '30px',
            backgroundColor: selectedSubTab === 1 && '#3D71FF',
            textDecoration: selectedSubTab === 1 && 'none',
          }}
        />
      </Tabs>
      {selectedSubTab === 0 ? (
        <ProfileGeneralTab />
      ) : // ) : selectedSubTab === 1 ? (
      //   <SecurityTab />
      selectedSubTab === 1 ? (
        <ProfileAddressTab />
      ) : (
        <></>
      )}
    </>
  );
};

export default PersonalInfoTab;
