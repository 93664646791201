import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, IconButton, ListItemIcon, Typography } from '@mui/material';
import toast from 'react-hot-toast';

import Iconify from '../../../components/Iconify';
import VirtualCardModal from './VirtualCardModal';
import { getAssetModuleAccountSingle } from '../../../services/Service';
import virtualCardicon from '../../../assets/virtualCardicon1.png';
import { GlobalShareModal } from '../../../components/Modals/GlobalShareModal';

export default function ThreeDotMenu({ onEdit, onDelete, data, onChangeLog }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [virtualCardModalOpen, setVirtualCardModalOpen] = React.useState({
    open: false,
    data: null,
  });
  const [shareModal, setShareModal] = React.useState({ open: false });
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDisplayClick = () => {
    handleClose();
    onEdit(true);
  };

  const onEditClick = () => {
    handleClose();
    onEdit(false);
  };

  const onDeleteClick = () => {
    handleClose();
    onDelete();
  };

  const onVirtualCardOpen = async () => {
    try {
      const res = await getAssetModuleAccountSingle(data._id);
      setVirtualCardModalOpen({
        open: true,
        data: res.data.data,
      });
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message === "Cannot read property 'size' of undefined") {
        toast.error('The file type is unsupported');
      } else {
        toast.error(err?.response?.data?.message || err.message);
      }
    }
  };

  const onVirtualCardClose = async () => {
    setVirtualCardModalOpen({
      open: false,
      data: null,
    });
  };

  const onShareClick = () => {
    setShareModal((prev) => ({ ...prev, open: true }));
  };
  const onShareClose = () => {
    setShareModal({ open: false });
  };

  return (
    <>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={onEditClick}>
            <ListItemIcon>
              <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
            </ListItemIcon>
            <Typography variant="inherit">Edit</Typography>
          </MenuItem>
          <MenuItem onClick={onDeleteClick}>
            <ListItemIcon>
              <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
            </ListItemIcon>
            <Typography variant="inherit">Delete</Typography>
          </MenuItem>
          <MenuItem onClick={onDisplayClick}>
            <ListItemIcon>
              <Iconify icon="vaadin:glasses" color="#1877F2" width={22} height={22} />
            </ListItemIcon>
            <Typography variant="inherit">Display</Typography>
          </MenuItem>
          <MenuItem onClick={onShareClick}>
            <Iconify icon="material-symbols:share" color="#1877F2" width={22} height={22} />
            <span style={{ marginLeft: '8px' }}>Share</span>
          </MenuItem>
          <MenuItem onClick={onChangeLog}>
            <Iconify icon="mdi:clipboard-text-search" color="#1877F2" width={22} height={22} />
            <span style={{ marginLeft: '8px' }}>Change log</span>
          </MenuItem>
          <MenuItem onClick={onVirtualCardOpen}>
            <ListItemIcon>
              <img src={virtualCardicon} width={22} height={22} style={{ objectFit: 'fill' }} alt="virtualCardicon" />
            </ListItemIcon>
            <Typography variant="inherit">Virtual Card</Typography>
          </MenuItem>
        </Menu>
      </div>
      <VirtualCardModal
        onClose={onVirtualCardClose}
        open={virtualCardModalOpen.open}
        data={virtualCardModalOpen.data}
        onEdit={onEditClick}
        onDelete={onDeleteClick}
      />
      <GlobalShareModal
        link="https://dev.safalsubscriptions.com/asset"
        title="Asset"
        open={shareModal.open}
        onClose={onShareClose}
      />
    </>
  );
}
