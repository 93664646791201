import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './SafalLeaderFrequencyConfig.css';

import { getSafalLeaderFrequencyConfig, deleteSafalLeaderFrequencyConfig } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddSafalLeaderFrequencyConfigModal from './AddSafalLeaderFrequencyConfigModal';

const SafalLeaderFrequencyConfig = () => {
  const [addModalOpen, setAddModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });

  const [rawData, setRawData] = useState([]);

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getSafalLeaderFrequencyConfig();
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('getSafalLeaderFrequencyConfig fetchData error', err.message);
    }
  };

  const onAddModalOpenDisplay = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenEdit = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpen = () => {
    setAddModalOpen({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteSafalLeaderFrequencyConfig({
        id: data._id,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalOpen = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data,
    });
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>SafalLeaderBoard Frequency Config - SafalSubscriptions</title>
      </Helmet>

      <div id="SafalLeaderFrequencyConfig">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              SafalLeaderBoard Frequency Config
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          onDelete={onDeleteModalOpen}
          onEdit={onAddModalOpenEdit}
          onDisplay={onAddModalOpenDisplay}
          data={rawData}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddSafalLeaderFrequencyConfigModal
        open={addModalOpen.open}
        data={addModalOpen.data}
        isDisplay={addModalOpen.isDisplay}
        onClose={onAddModalClose}
      />
    </>
  );
};

export default SafalLeaderFrequencyConfig;
