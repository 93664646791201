import { Box, Button, Fab, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const StripeCheckoutModal = ({ clientSecret, onComplete, onClose }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
  };

  const onCloseLocal = () => {
    onClose();
  };

  return (
    <>
      <Modal open>
        <Box sx={style}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab onClick={onCloseLocal} size="small" color="primary" aria-label="add">
              <CloseIcon />
            </Fab>
          </Box>
          <div
            style={{
              position: 'relative',
              padding: '32px',
            }}
          >
            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret, onComplete }}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default StripeCheckoutModal;
