/* eslint-disable */
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';
import { NotificationSettings } from '../../services/Service';
import PayTierPremium from 'src/pages/user/PayTierPremium';

const NotificationTab = () => {
  const { notificationSettings } = useSelector((state) => state.login.user);
  // const NotificationSchema = Yup.object().shape({

  //   isNotifyOverBudget:Yup.string().required('isNotifyOverBudget is required'),
  //     notifyOverBudgetEachDay : Yup.string().required('notifyOverBudgetEachDay is required'),
  //     isNotifyDueExpiryRenew: Yup.string().required('isNotifyDueExpiryRenew is required'),
  //     notifyDueExpiryRenewBeforeDay: Yup.string().required('notifyDueExpiryRenewBeforeDay is required')
  // });

  const NotificationFormik = useFormik({
    initialValues: {
      isNotifyOverBudget: notificationSettings?.isNotifyOverBudget || false,
      notifyOverBudgetEachDay: notificationSettings?.notifyOverBudgetEachDay || '',
      isNotifyDueExpiryRenew: notificationSettings?.isNotifyDueExpiryRenew || false,
      isEnabledFeedbackStatus: notificationSettings?.isEnabledFeedbackStatus || false,
      notifyDueExpiryRenewBeforeDay: notificationSettings?.notifyDueExpiryRenewBeforeDay || '',
    },

    // initialValues: notificationSettings,
    // validationSchema : NotificationSchema,

    onSubmit: (values) => {
      NotificationSettings({ notificationSettings: values }).then((res) => {
        if (res.data.success === true) {
          SuccessToast('Notification Settings updated');
        } else {
          ErrorToast('Something went wrong');
        }
      });
    },
  });

  // return (
  //   <form onSubmit={NotificationFormik.handleSubmit}>
  //     <div>
  //       <Box>
  //         <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //           <Box>• To be notified when Actual Spend is over the budget</Box>

  //           <Switch
  //             name="isNotifyOverBudget"
  //             onChange={(e) => {
  //               // NotificationFormik.handleChange(e)
  //               NotificationFormik.setFieldValue('isNotifyOverBudget', e.target.checked);
  //             }}
  //             checked={NotificationFormik.values.isNotifyOverBudget}
  //           />
  //           {/* <RadioGroup
  //             aria-labelledby="demo-radio-buttons-group-label"
  //             name="isNotifyOverBudget"
  //             onChange={NotificationFormik.handleChange}
  //             value={NotificationFormik.values.isNotifyOverBudget}
  //           >
  //             <Stack direction="row" sx={{ color: '#B6B6B6', pl: '20px' }}>
  //               <FormControlLabel value={true} control={<Radio />} label="Yes" />
  //               <FormControlLabel value={false} control={<Radio />} label="No" />
  //             </Stack>
  //           </RadioGroup> */}
  //         </Box>

  //         <FormControl disabled={NotificationFormik.values.isNotifyOverBudget === true ? false : true}>
  //           <FormLabel sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
  //             Notification will be again send in
  //             <TextField
  //               name="notifyOverBudgetEachDay"
  //               type={'number'}
  //               value={NotificationFormik.values.notifyOverBudgetEachDay}
  //               onChange={NotificationFormik.handleChange}
  //               disabled={NotificationFormik.values.isNotifyOverBudget === true ? false : true}
  //               variant="standard"
  //               size="small"
  //               sx={{ fontSize: '30px', fontWeight: 700, mx: 1, mt: 0.5 }}
  //             />
  //             days
  //           </FormLabel>
  //         </FormControl>

  //         <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
  //           <Box>• To be notified when Subscription is due for expiry or renewal </Box>
  //           <Switch
  //             name="isNotifyDueExpiryRenew"
  //             onClick={(e) => {
  //               if (e.target.checked === true) {
  //                 NotificationFormik.setFieldValue('isNotifyDueExpiryRenew', true);
  //               } else {
  //                 NotificationFormik.setFieldValue('isNotifyDueExpiryRenew', false);
  //               }
  //             }}
  //             // onChange={(e) =>  NotificationFormik.handleChange(e)}
  //             checked={NotificationFormik.values.isNotifyDueExpiryRenew}
  //           />
  //           {/* <RadioGroup
  //             aria-labelledby="demo-radio-buttons-group-label"
  //             name="isNotifyDueExpiryRenew"
  //             onChange={NotificationFormik.handleChange}
  //             value={NotificationFormik.values.isNotifyDueExpiryRenew}
  //           >
  //             <Stack direction="row" sx={{ color: '#B6B6B6', pl: '20px' }}>
  //               <FormControlLabel value={true} control={<Radio />} label="Yes" />
  //               <FormControlLabel value={false} control={<Radio />} label="No" />
  //             </Stack>
  //           </RadioGroup> */}
  //         </Box>
  //         <FormControl disabled={NotificationFormik.values.isNotifyDueExpiryRenew === 'true' ? false : true}>
  //           <FormLabel sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
  //             Notify
  //             <TextField
  //               type={'number'}
  //               name="notifyDueExpiryRenewBeforeDay"
  //               value={NotificationFormik.values.notifyDueExpiryRenewBeforeDay}
  //               onChange={NotificationFormik.handleChange}
  //               disabled={NotificationFormik.values.isNotifyDueExpiryRenew === true ? false : true}
  //               variant="standard"
  //               size="small"
  //               sx={{ fontSize: '30px', fontWeight: 700, mx: 1, mt: 0.5 }}
  //             />
  //             days before the expiry or renewal
  //           </FormLabel>
  //         </FormControl>
  //       </Box>

  //       <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
  //         <Box>• To be notified via E-mail when feedback status changes </Box>
  //         <Switch
  //           name="isEnabledFeedbackStatus"
  //           onClick={(e) => {
  //             if (e.target.checked === true) {
  //               NotificationFormik.setFieldValue('isEnabledFeedbackStatus', true);
  //             } else {
  //               NotificationFormik.setFieldValue('isEnabledFeedbackStatus', false);
  //             }
  //           }}
  //           // onChange={(e) =>  NotificationFormik.handleChange(e)}
  //           checked={NotificationFormik.values.isEnabledFeedbackStatus}
  //         />
  //         {/* <RadioGroup
  //             aria-labelledby="demo-radio-buttons-group-label"
  //             name="isEnabledFeedbackStatus"
  //             onChange={NotificationFormik.handleChange}
  //             value={NotificationFormik.values.isEnabledFeedbackStatus}
  //           >
  //             <Stack direction="row" sx={{ color: '#B6B6B6', pl: '20px' }}>
  //               <FormControlLabel value={true} control={<Radio />} label="Yes" />
  //               <FormControlLabel value={false} control={<Radio />} label="No" />
  //             </Stack>
  //           </RadioGroup> */}
  //       </Box>

  //       <Button
  //         color="primary"
  //         variant="contained"
  //         // type="submit"
  //         // disabled={!NotificationFormik.isValid || !NotificationFormik.dirty}

  //         onClick={() => NotificationFormik.submitForm()}
  //         sx={{
  //           width: '150px',
  //           // height: '30px',
  //           backgroundColor: '#3D71FF',
  //           borderRadius: '30px',
  //           textTransform: 'none',
  //           mt: 3,
  //         }}
  //       >
  //         Update
  //       </Button>
  //     </div>
  //   </form>
  // );

  return <PayTierPremium />
};

export default NotificationTab;
