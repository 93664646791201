/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Menu, MenuItem, TablePagination } from '@mui/material';
import ApprovalIcon from '@mui/icons-material/Approval';

import DeleteIcon from '@mui/icons-material/Delete';

import { updateAssetModuleCard } from '../../../services/Service';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const AssetModuleLoyaltyGiftRewardCustom = ({ fetchData, data, paginationOption }) => {
  const { formatDateTime } = useUserTimeZone();
  useEffect(() => {
    fetchData(paginationOption.page, paginationOption.limit, paginationOption.type, paginationOption.companyId);
  }, []);

  const onStatusChange = async (row, status) => {
    try {
      const payload = {
        _id: row._id,
      };
      if (status === 'approve') {
        payload.isVerified = true;
      } else {
        payload.isDeleted = true;
      }
      const res = await updateAssetModuleCard(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        await fetchData();
      }
    } catch (err) {
      console.log('AssetModuleCompany onDelete', err.message);
      toast.error(err.message);
    }
  };

  const handleChangePage = async (e, value) => {
    await fetchData(value + 1, paginationOption.limit, paginationOption.type, paginationOption.companyId);
  };
  const handleChangeRowsPerPage = async (e) => {
    await fetchData(paginationOption.page, e.target.value, paginationOption.type, paginationOption.companyId);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead style={{ backgroundColor: '#eaffea', color: '#007b32' }}>
          <TableRow>
            <TableCell style={{ color: '#007b32' }}>Name</TableCell>
            <TableCell style={{ color: '#007b32' }}>Type</TableCell>
            <TableCell style={{ color: '#007b32' }}>Company Name</TableCell>
            <TableCell style={{ color: '#007b32' }}>Created At</TableCell>
            <TableCell style={{ color: '#007b32' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((e, index) => (
            <TableRow key={index}>
              <TableCell>{e?.name}</TableCell>
              <TableCell>{e?.type}</TableCell>
              <TableCell>{e?.companyId?.name}</TableCell>
              <TableCell>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </TableCell>
              <TableCell>
                <ActionMenu onChange={(status) => onStatusChange(e, status)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
        component="div"
        count={paginationOption.total}
        rowsPerPage={paginationOption.limit}
        page={paginationOption.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default AssetModuleLoyaltyGiftRewardCustom;

function ActionMenu({ onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (status) => {
    setAnchorEl(null);
    onChange(status);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <ApprovalIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClose('approve')}>Approve</MenuItem>
        <MenuItem onClick={() => handleClose('reject')}>Reject</MenuItem>
      </Menu>
    </div>
  );
}
