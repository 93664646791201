import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Image } from 'antd';

import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import CustomRating from '../../components/UI/CustomRating';
import CustomMuiSelect from '../../components/UI/CustomMuiSelect';
import DateField from '../../components/UI/DateField';
import { getHelpVideoModule } from '../../services/Service';

const SafalLeaderBoardReportFilterMenu = ({ state, onFilterApply, onFilterReset, companyData }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [filterState, setFilterState] = useState({
    startDate: '',
    endDate: '',
    minPoints: '',
    maxPoints: '',
    megaModule: '',
  });
  const [moduleList, setModuleList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
    setShowApply(false);
  };

  useEffect(() => {
    if (open) {
      setFilterState(state);
    }
  }, [open]);

  const handleFilterApply = () => {
    onFilterApply(filterState);
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterApply({
      startDate: '',
      endDate: '',
      minPoints: '',
      maxPoints: '',
      megaModule: '',
    });
    handleClose();
  };

  const fetchModule = async () => {
    try {
      const res = await getHelpVideoModule();
      setModuleList(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  // Update resetState whenever the state changes
  useEffect(() => {
    setShowApply(isThisResetState);
  }, [state]);
  useEffect(() => {
    fetchModule();
  }, []);

  const isThisResetState =
    state?.startDate !== '' ||
    state?.endDate !== '' ||
    state?.minPoints !== '' ||
    state.maxPoints !== '' ||
    state.megaModule !== '';

  // State to store the boolean value
  const [showApply, setShowApply] = useState(isThisResetState);

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '400px',
            padding: '0px 8px',
          },
        }}
      >
        <h3>Filter</h3>
        <TextField
          value={filterState?.megaModule}
          focused={filterState?.megaModule}
          onChange={(e) => onChangeField(e.target.value, 'megaModule')}
          sx={{
            my: '8px',
          }}
          name="megaModule"
          select
          label="Category"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          {moduleList?.map((e) => (
            <MenuItem value={e?.megaModule}>{e?.megaModule}</MenuItem>
          ))}
        </TextField>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '8px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Created date
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <DateField
              value={filterState.startDate}
              fullWidth
              variant="outlined"
              label="From"
              onChange={(e) => onChangeField(e.toISOString(), 'startDate')}
            />
            <DateField
              value={filterState.endDate}
              fullWidth
              variant="outlined"
              label="To"
              onChange={(e) => onChangeField(e.toISOString(), 'endDate')}
            />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '8px', margin: '8px 0px' }}>
          {/* minPoints */}
          <TextField
            value={filterState?.minPoints}
            focused={filterState?.minPoints}
            onChange={(e) => onChangeField(e.target.value, 'minPoints')}
            name="minPoints"
            label="Min Points"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
          {/* maxPoints */}
          <TextField
            value={filterState?.maxPoints}
            focused={filterState?.maxPoints}
            onChange={(e) => onChangeField(e.target.value, 'maxPoints')}
            name="maxPoints"
            label="Max Points"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default SafalLeaderBoardReportFilterMenu;
