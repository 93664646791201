import { useEffect, useState } from 'react';

import { Box, Button, Checkbox, Fab, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MDEditor from '@uiw/react-md-editor';

import { getPrivacyPolicyAndTermOfCondition } from '../../services/Service';

const PrivacyPolicyModal = ({ open, onClose, onChange, checked }) => {
  const [rawData, setRawData] = useState({
    privacyPolicy: '',
    privacyPolicyMD: '',
    privacyPolicyHTML: '',
    termOfCondition: '',
    termOfConditionMD: '',
    termOfConditionHTML: '',
  });

  const fetchData = async () => {
    try {
      const res = await getPrivacyPolicyAndTermOfCondition();
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('getPrivacyPolicyAndTermOfCondition fetchData error', err.message);
    }
  };

  useEffect(() => {
    if (open) fetchData();
  }, [open]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid #FFF',
    borderRadius: '10px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    p: 4,
    maxHeight: '95vh',
    overflow: 'hidden',
    '@media (max-width: 640px)': {
      width: '95vw',
    },
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <Box sx={{ ...style }}>
          <Fab
            sx={{ position: 'absolute', top: '16px', right: '16px' }}
            onClick={handleClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '16px', fontSize: '24px', fontWeight: 700, color: '#3d71ff' }}
          >
            Privacy Policy
          </Typography>
          <div data-color-mode="light" style={{ overflow: 'auto' }}>
            <MDEditor.Markdown data-color-mode="light" source={rawData.privacyPolicyMD} />
            <div
              style={{
                padding: '12px',
                paddingBottom: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                gap: '12px',
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#ff4842',
                  ':hover': { backgroundColor: '#d40700' },
                  color: 'white',
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                }}
                onClick={() => {
                  onChange(false);
                  onClose();
                }}
                variant="contained"
              >
                Reject
              </Button>
              <Button
                onClick={() => {
                  onChange(true);
                  onClose();
                }}
                variant="contained"
                sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
              >
                Accept
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PrivacyPolicyModal;
