import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { GetcompaniesResponse, getCompanyType } from '../../../services/Service';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';

const UserOffersFilterMenu = ({ state, onFilterApply }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [rawCompanyDataRaw, setRawCompanyDataRaw] = useState([]);
  const [rawCompanyDataFilter, setRawCompanyDataFilter] = useState([]);

  const [filterState, setFilterState] = useState({
    companyType: '',
    companyName: '',
  });
  const [companyType, setCompanyType] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
    setShowApply(false);
  };

  useEffect(() => {
    if (open) {
      setFilterState(state);
    }
  }, [open]);

  const handleFilterApply = () => {
    onFilterApply(filterState);
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterApply({
      companyType: '',
      companyName: '',
    });
    handleClose();
  };

  // Update resetState whenever the state changes
  useEffect(() => {
    setShowApply(isThisResetState);
  }, [state]);

  const isThisResetState = state?.companyType !== '' || state?.companyName !== '';

  // State to store the boolean value
  const [showApply, setShowApply] = useState(isThisResetState);

  const fetchCompanyData = async () => {
    try {
      const res = await GetcompaniesResponse();
      setRawCompanyDataRaw(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchComType = async () => {
    try {
      const res = await getCompanyType();
      const typeData = res.data.data.map((e) => e.type);
      setCompanyType(typeData.sort((a, b) => a.localeCompare(b)));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCompanyData();
    fetchComType();
  }, [open]);

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3D71FF',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '250px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        {/* companyType */}
        <CustomMuiSelect
          disablePortal={false}
          value={filterState?.companyType}
          focused={filterState?.companyType}
          onChange={(e) => {
            if (e) {
              onChangeField(e.label, 'companyType');
              setRawCompanyDataFilter(
                rawCompanyDataRaw?.filter((ex) => ex?.companyType?.toLowerCase() === e.label?.toLowerCase())
              );
            } else {
              onChangeField('', 'companyType');
              setRawCompanyDataFilter([]);
            }
          }}
          sx={{
            my: '8px',
          }}
          label="Company Type"
          size="small"
          variant="outlined"
          options={companyType.map((e) => ({ label: e }))}
        />
        {/* companyName */}
        <CustomMuiSelect
          disablePortal={false}
          sx={{
            my: '8px',
          }}
          label="Company Name"
          size="small"
          variant="outlined"
          options={rawCompanyDataFilter.map((e) => ({ label: e.name }))}
          value={filterState?.companyName}
          focused={filterState?.companyName}
          onChange={(e) => {
            if (e) {
              onChangeField(e.label, 'companyName');
            } else {
              onChangeField('', 'companyName');
            }
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default UserOffersFilterMenu;
