import { useState, useEffect } from 'react';

function useImageCarousel(images, interval = 2000) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const imageSwitchInterval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(imageSwitchInterval);
  }, [images, interval]);

  return images[currentImageIndex];
}

export default useImageCarousel;
