import { Button, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './ManageColorBg.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { getColorsAndBgList, deleteColorsAndBg } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddColorBgModal from './AddColorBgModal';

const ManageColorBg = () => {
  const [selectedToggle, setSelectedToggle] = useState('color');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getColorsAndBgList();
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('ManageColorBg fetchData error', err.message);
    }
  };

  const onChangeToggleValue = (value) => {
    setSelectedToggle(value);
  };

  const onAddModalOpen = () => {
    setAddModalOpen(true);
  };
  const onAddModalClose = () => {
    setAddModalOpen(false);
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteColorsAndBg(data._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Manage Color Bg - SafalSubscriptions</title>
      </Helmet>

      <div id="ManageColorBg">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Manage Color & Background
            </Typography>
          </div>
          <div style={{ display: 'flex', gap: '12px' }}>
            <Button
              onClick={() => onChangeToggleValue('color')}
              sx={{
                backgroundColor: selectedToggle === 'color' ? '#027c34' : '#f2f2f2',
                borderRadius: '999px',
                color: selectedToggle === 'color' ? 'white' : '#027c34',
                border: '1px solid #027c34',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: selectedToggle === 'color' ? '#027c34' : '#f2f2f2',
                  color: selectedToggle === 'color' ? 'white' : '#027c34',
                },
              }}
              variant="contained"
            >
              Colors
            </Button>
            <Button
              onClick={() => onChangeToggleValue('image')}
              sx={{
                backgroundColor: selectedToggle === 'image' ? '#027c34' : '#f2f2f2',
                borderRadius: '999px',
                color: selectedToggle === 'image' ? 'white' : '#027c34',
                border: '1px solid #027c34',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: selectedToggle === 'image' ? '#027c34' : '#f2f2f2',
                  color: selectedToggle === 'image' ? 'white' : '#027c34',
                },
              }}
              variant="contained"
            >
              Background Images
            </Button>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          mode={selectedToggle}
          onDelete={(data) => {
            setIsDeleteConfirmationModalOpen({
              open: true,
              data,
            });
          }}
          onEdit={() => {}}
          data={rawData}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddColorBgModal open={addModalOpen} onClose={onAddModalClose} mode={selectedToggle} />
    </>
  );
};

export default ManageColorBg;
