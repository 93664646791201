import { useSelector } from 'react-redux';
import AddSubscriptionAnimation from './AddSubscriptionAnimation';

const AnimationLoop = () => {
  const { addSubscriptionAnimation } = useSelector((state) => state.toggleState);

  return <>{addSubscriptionAnimation && <AddSubscriptionAnimation />}</>;
};

export default AnimationLoop;
