import { IconButton, Tooltip } from '@mui/material';

import Iconify from '../../components/Iconify';

const TopTitleLabelAction = ({ onEdit, onDelete, onChangeLog }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '24px',
        }}
      >
        {onChangeLog && (
          <Tooltip title={'Changelog'}>
            <IconButton onClick={onChangeLog}>
              <Iconify icon="mdi:clipboard-text-search" color="#1877F2" width={22} height={22} />
            </IconButton>
          </Tooltip>
        )}
        {onEdit && (
          <Tooltip title={'Edit'}>
            <IconButton onClick={onEdit}>
              <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
            </IconButton>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title={'Delete'}>
            <IconButton onClick={onDelete}>
              <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
};
export default TopTitleLabelAction;
