import { Typography } from '@mui/material';
import './PrivacyPolicyAndTermConditionBusiness.css';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';

import { getPrivacyPolicyAndTermOfCondition } from '../../../services/Service';
import TableView from './TableView';
import UpdatePrivacyPolicyAndTermConditionModal from './UpdatePrivacyPolicyAndTermConditionModal';

const PrivacyPolicyAndTermConditionBusiness = () => {
  const [addModalOpen, setAddModalOpen] = useState({
    open: false,
    data: null,
  });
  const [rawData, setRawData] = useState([]);

  const fetchData = async () => {
    try {
      const res = await getPrivacyPolicyAndTermOfCondition();
      if (res.status === 200) {
        setRawData([res.data.data]);
      }
    } catch (err) {
      console.log('fetchData fetchData error', err.message);
    }
  };

  const onAddModalOpenForEdit = (data) => {
    setAddModalOpen({
      open: true,
      data: data,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      open: false,
      data: null,
    });
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy And Term & Condition- SafalSubscriptions</title>
      </Helmet>

      <div id="PrivacyPolicyAndTermConditionBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Privacy Policy And Terms & Condition
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
        </div>
        <TableView onEdit={onAddModalOpenForEdit} data={rawData} />
      </div>
      <UpdatePrivacyPolicyAndTermConditionModal
        open={addModalOpen.open}
        data={addModalOpen.data}
        onClose={onAddModalClose}
      />
    </>
  );
};

export default PrivacyPolicyAndTermConditionBusiness;
