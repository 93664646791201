import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { db } from '../firebase';
import { setBusinessNotificationsDotCount } from '../store/businessNotificationsDotSlice';
import { getUserChatLimit } from '../services/Service';
import { updateAttachLimit } from '../store/ChatSlice';

const useChatListener = () => {
  const { user } = useSelector((state) => state.login);
  const { chatId } = useSelector((state) => state.chats);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.role === undefined) return;
    if (user.role === 'business') {
      const businessNotificationsDotRef = doc(db, 'businessNotificationsDot', 'supportChat');
      const unsubscribe = onSnapshot(businessNotificationsDotRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.data();
          if (Object.hasOwn(data, 'notificationCount')) {
            dispatch(setBusinessNotificationsDotCount(data));
          }
        }
      });
      return () => unsubscribe();
    }
  }, [user.role]);
  useEffect(() => {
    if (!chatId) return;
    getUserChatLimit(user?._id)
      .then((res) => {
        dispatch(updateAttachLimit(res.data.data));
      })
      .catch((e) => console.log(e));
  }, [chatId]);
};

export default useChatListener;
