import * as React from 'react';

import { Badge } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Image } from 'antd';

import { fallbackImage } from '../../../utils/general';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function MoreCategoryModal({ open, onClose, data, onClick }) {
  const handleClose = () => {
    onClose();
  };

  const handleClick = (e) => {
    onClick(e);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}
        >
          More Category
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', overflow: 'auto' }}>
          {data?.map((e) => (
            <button
              onClick={() => handleClick(e)}
              style={{
                backgroundColor: 'transparent',
                padding: '4px 8px',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
              }}
            >
              <Badge
                sx={{
                  '.MuiBadge-badge': {
                    borderRadius: '1000px',
                    top: '4px',
                    right: '4px',
                    backgroundColor: '#54d62c',
                    fontWeight: 'bold',
                    color: 'black',
                    height: '26px',
                    width: '26px',
                  },
                }}
                max={999}
                badgeContent={e?.data?.length || 0}
                color="primary"
              >
                <Image
                  fallback={fallbackImage}
                  preview={false}
                  style={{
                    objectFit: 'cover',
                    borderRadius: '16px',
                    border: '1px solid #bfbfbf',
                    filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
                  }}
                  height="40px"
                  width="40px"
                  src={e?.logo}
                />
              </Badge>
              <span
                style={{
                  lineHeight: 'normal',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  textTransform: 'capitalize',
                }}
              >
                {e?.type}
              </span>
            </button>
          ))}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{ backgroundColor: '#3D71FF', textTransform: 'capitalize' }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
