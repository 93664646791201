import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import './GeneralKvBusiness.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { getGeneralKeyValueList, deleteGeneralKeyValueListBusiness } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddGeneralKvModal from './AddGeneralKvModal';

const GeneralKvBusiness = () => {
  const [addModalOpen, setAddModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });

  const [rawData, setRawData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getGeneralKeyValueList();
      if (res.status === 200) {
        setRawData(res.data.data);
        setFilterData(res.data.data);
      }
    } catch (err) {
      console.log('getGeneralKeyValueList fetchData error', err.message);
    }
  };

  const onAddModalOpenDisplay = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenEdit = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpen = () => {
    setAddModalOpen({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteGeneralKeyValueListBusiness({
        id: data._id,
        flag: data.flag,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalOpen = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data,
    });
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>General Key Value List - SafalSubscriptions</title>
      </Helmet>

      <div id="GeneralKvBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              General Key Value List
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          onDelete={onDeleteModalOpen}
          onEdit={onAddModalOpenEdit}
          onDisplay={onAddModalOpenDisplay}
          data={filterData}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddGeneralKvModal
        open={addModalOpen.open}
        data={addModalOpen.data}
        isDisplay={addModalOpen.isDisplay}
        onClose={onAddModalClose}
      />
    </>
  );
};

export default GeneralKvBusiness;
