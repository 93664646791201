import { IconButton, Tooltip } from '@mui/material';
import CallEndIcon from '@mui/icons-material/CallEnd';
import MicOffIcon from '@mui/icons-material/MicOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MinimizeIcon from '@mui/icons-material/Minimize';
import MicIcon from '@mui/icons-material/Mic';
import { useDispatch, useSelector } from 'react-redux';
import { setAgoraMiniState } from '../../store/AgoraMiniSlice';
import { CallTimeUpdate } from './CallAcceptPopUp';

const MiniCallUI = () => {
  const dispatch = useDispatch();
  const agoraMiniState = useSelector((state) => state.agoraMiniState);

  const onFullScreen = () => {
    dispatch(setAgoraMiniState({ open: false }));
  };

  return (
    <div
      style={{
        backgroundColor: '#bcceff',
        position: 'fixed',
        bottom: '20px',
        left: '20px',
        display: 'flex',
        padding: '12px',
        gap: '12px',
        zIndex: '99999',
        borderRadius: '8px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{agoraMiniState?.displayName}</span>
        <span>{agoraMiniState?.time}</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Tooltip title="Full Screen">
          <IconButton
            onClick={onFullScreen}
            size="small"
            sx={{
              backgroundColor: '#3d71ff',
              color: 'white',
              ':hover': { backgroundColor: '#3d71ff', color: 'white' },
            }}
          >
            <FullscreenIcon color="white" />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Mute">
          <IconButton
            onClick={agoraMiniState?.handleMuteAndUnmute()}
            size="small"
            sx={{
              backgroundColor: '#3d71ff',
              color: 'white',
              ':hover': { backgroundColor: '#3d71ff', color: 'white' },
            }}
          >
            <MicOffIcon color="white" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Call End">
          <IconButton
            onClick={agoraMiniState?.onCallRejectEnd()}
            size="small"
            sx={{
              backgroundColor: '#ff4842',
              color: 'white',
              ':hover': { backgroundColor: '#ff4842', color: 'white' },
            }}
          >
            <CallEndIcon color="white" />
          </IconButton>
        </Tooltip> */}
      </div>
    </div>
  );
};
export default MiniCallUI;
