/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import './dynamicMenu.css';
import moment from 'moment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Select, MenuItem, FormControl, TextField, IconButton, TableSortLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
  getModuleConfigurationAppVersions,
  deleteModuleConfigurationMenu,
  updateModuleConfigurationMenu,
  getModuleConfigurationBusiness,
  createModuleConfigurationMenu,
} from '../../services/Service';
import AddEditDynamicMenuModal from './AddEditDynamicMenuModal';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import DateField from '../../components/UI/DateField';

function DynamicMenu() {
  const { formatDate } = useUserTimeZone();
  const [moduleConfigurationDataRaw, setModuleConfigurationDataRaw] = useState([]);
  const [moduleConfigurationData, setModuleConfigurationData] = useState([]);

  const [deviceOrderBy, setDeviceOrderBy] = useState('NO_SORT'); // asc | desc | NO_SORT
  const [isEditOpen, setIsEditOpen] = useState({ open: false, data: null });

  const fetchModuleConfigurationData = async () => {
    try {
      const res = await getModuleConfigurationBusiness();
      if (res.status === 200 && res.data.success === true) {
        setModuleConfigurationDataRaw(res.data.menus);
        setModuleConfigurationData(res.data.menus);
      }
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const onDelete = async (row) => {
    try {
      await deleteModuleConfigurationMenu(row._id);
      await fetchModuleConfigurationData();
      toast.success('Delete successful');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const onToggle = async (row) => {
    try {
      const updatePayload = { ...row, status: row.status === 'active' ? 'inactive' : 'active' };
      delete updatePayload._id;
      await updateModuleConfigurationMenu(row._id, updatePayload);
      await fetchModuleConfigurationData();
      toast.success('Update successful');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchModuleConfigurationData();
  }, []);

  const handleDeviceSort = (orderBy) => {
    setDeviceOrderBy(orderBy);
    const newData = [...moduleConfigurationDataRaw];
    if (orderBy === 'NO_SORT') {
      setModuleConfigurationData(newData);
      return;
    }
    newData.sort((a, b) => {
      if (orderBy === 'asc') {
        if (a.device < b.device) return -1;
        if (a.device > b.device) return 1;
      } else {
        if (b.device < a.device) return -1;
        if (b.device > a.device) return 1;
      }
      return 0;
    });
    setModuleConfigurationData(newData);
  };

  const onEditClick = (row) => {
    setIsEditOpen({
      open: true,
      data: row,
    });
  };

  const onEditClose = () => {
    setIsEditOpen({
      open: false,
      data: null,
    });
  };

  return (
    <>
      <div id="DynamicMenu">
        <div className="action-bar">
          <h2 style={{ color: '#017c33' }}>Manage Module Configuration</h2>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <TableHead style={{ backgroundColor: '#eaffea', color: '#007b32' }}>
              <TableRow>
                <TableCell style={{ color: '#007b32' }}>
                  <TableSortLabel
                    active={deviceOrderBy === 'desc' || deviceOrderBy === 'asc'}
                    direction={deviceOrderBy === 'NO_SORT' ? undefined : deviceOrderBy}
                    onClick={() =>
                      handleDeviceSort(deviceOrderBy === 'asc' ? 'desc' : deviceOrderBy === 'desc' ? 'NO_SORT' : 'asc')
                    }
                  >
                    Device
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ color: '#007b32' }}>Release Type</TableCell>
                <TableCell style={{ color: '#007b32' }}>Module</TableCell>
                <TableCell style={{ color: '#007b32' }}>Sub-Module</TableCell>
                <TableCell style={{ color: '#007b32' }}>Start Date</TableCell>
                <TableCell style={{ color: '#007b32' }}>End Date</TableCell>
                <TableCell style={{ color: '#007b32' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <EditTableRow key={'EditTableRow'} refetch={fetchModuleConfigurationData} />
              {moduleConfigurationData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.device}</TableCell>
                  <TableCell>{row.release}</TableCell>
                  <TableCell>{row.menu}</TableCell>
                  <TableCell>{row.submenu}</TableCell>
                  <TableCell>
                    {formatDate(row.startDate)} {getTimeZoneAbbreviation()}
                  </TableCell>
                  <TableCell>
                    {formatDate(row.endDate)} {getTimeZoneAbbreviation()}
                  </TableCell>
                  <TableCell>
                    <Switch checked={row.status === 'active'} onChange={() => onToggle(row)} />
                    <IconButton onClick={() => onEditClick(row)} style={{ marginLeft: '10px' }} aria-label="delete">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDelete(row)} style={{ marginLeft: '10px' }} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <AddEditDynamicMenuModal
        refetch={fetchModuleConfigurationData}
        open={isEditOpen.open}
        data={isEditOpen.data}
        onClose={onEditClose}
      />
    </>
  );
}

export default DynamicMenu;

const EditTableRow = ({ refetch }) => {
  const [modulesNameList, setModulesNameList] = useState([]);
  const [subModulesNameList, setSubModulesNameList] = useState([]);

  const [selectedDeviceType, setSelectedDeviceType] = useState('');
  const [selectedModulesName, setSelectedModulesName] = useState('');
  const [selectedSubModulesName, setSelectedSubModulesName] = useState('');
  const [releaseType, setReleaseType] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const getInitVersion = async () => {
    try {
      const res = await getModuleConfigurationAppVersions();
      setModulesNameList(res.data.data.menu.menus);
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      setModulesNameList([]);
    }
  };

  const resetState = () => {
    setSelectedDeviceType('');
    setSelectedModulesName('');
    setSelectedSubModulesName('');
    setReleaseType('');
    setStartDate('');
    setEndDate('');
  };

  const onChangeDeviceType = (e) => {
    const value = e.target.value;
    setSelectedDeviceType(value);
  };

  const onChangeReleaseType = (e) => {
    const value = e.target.value;
    setReleaseType(value);
  };

  const onChangeModulesName = (e) => {
    const value = e.target.value;
    setSelectedModulesName(value);
    setSubModulesNameList(modulesNameList[value].submenu);
  };

  const onChangeSubModulesName = (e) => {
    const value = e.target.value;
    setSelectedSubModulesName(value);
  };

  const onChangeStartDate = (e) => {
    const value = e.toISOString();
    setStartDate(value);
  };

  const onChangeEndDate = (e) => {
    const value = e.toISOString();
    setEndDate(value);
  };

  useEffect(() => {
    getInitVersion();
  }, []);

  const onSubmit = async () => {
    try {
      if (selectedDeviceType === '') throw new Error('Module must be selected');
      if (releaseType === '') throw new Error('Release type not found');
      if (selectedModulesName === '') throw new Error('Module must be selected');
      if (startDate === '') throw new Error('Start Date must be selected');
      if (endDate === '') throw new Error('End Date must be selected');

      const payload = {
        release: releaseType,
        device: selectedDeviceType,
        menu: selectedModulesName,
        startDate: startDate,
        endDate: endDate,
        status: 'active',
      };
      if (selectedSubModulesName) payload.submenu = selectedSubModulesName;
      await createModuleConfigurationMenu(payload);
      refetch();
      resetState();
      toast.success('Create successful');
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || error.message);
    }
  };

  return (
    <TableRow>
      <TableCell>
        <FormControl size="small" sx={{ width: '150px' }}>
          <Select value={selectedDeviceType} onChange={onChangeDeviceType} displayEmpty>
            <MenuItem value={'android'}>Android</MenuItem>
            <MenuItem value={'ios'}>IOS</MenuItem>
            <MenuItem value={'web'}>Web</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <TextField
          value={releaseType}
          onChange={onChangeReleaseType}
          id="release-type"
          size="small"
          // label="Outlined"
          sx={{ width: '150px' }}
          variant="outlined"
        />
      </TableCell>
      <TableCell>
        <FormControl size="small" sx={{ width: '150px' }}>
          <Select value={selectedModulesName} onChange={onChangeModulesName} displayEmpty>
            {Object.keys(modulesNameList).map((e, index) => (
              <MenuItem key={index} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <FormControl size="small" sx={{ width: '150px' }}>
          <Select value={selectedSubModulesName} onChange={onChangeSubModulesName} displayEmpty>
            {subModulesNameList.map((e, index) => (
              <MenuItem key={index} value={e}>
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <DateField onChange={onChangeStartDate} value={startDate} variant="outlined" width="150px" />
      </TableCell>
      <TableCell>
        <DateField onChange={onChangeEndDate} value={endDate} variant="outlined" width="150px" />
      </TableCell>
      <TableCell>
        <Button onClick={onSubmit} variant="contained" startIcon={<AddIcon />}>
          Add
        </Button>
      </TableCell>
    </TableRow>
  );
};
