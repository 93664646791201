import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fab, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import CloseIcon from '@mui/icons-material/Close';
import Swal from 'sweetalert2';

import { FeedMessage } from './SafalRoomFeed';
import {
  createSafalRoomPrivateFeedReply,
  updateSafalRoomPrivateFeedReply,
  uploadSafalRoomImages,
} from '../../../services/Service';
import RoomMentionsInput from '../../../components/RoomMentionsInput';
import { CustomImageView, CustomImageViewInput } from '../../../components/UI/CustomImageView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '16px',
  p: 3,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function FeedMessageModal({
  open,
  onPost,
  onClose,
  data,
  refetch,
  companyId,
  roomInfo,
  usersMentions,
  companyListForMentions,
}) {
  const [message, setMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [goodBad, setGoodBad] = useState(''); // good, bad
  const [uploadImageFiles, setUploadImageFiles] = useState([]); // flies

  const directClose = () => {
    onClose();
    setGoodBad('');
    setMessage('');
    setUploadImageFiles([]);
  };

  const handleClose = async (flag) => {
    if (flag) {
      directClose();
      return;
    }
    if (message !== '' && message.length > 0) {
      const result = await Swal.fire({
        text: 'Are you sure you want to close this?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#ff4842',
        confirmButtonText: 'Yes',
        appendTo: 'body',
        customClass: {
          container: 'my-swal', // Add a custom class for styling
        },
        onBeforeOpen: () => {
          // Set a higher zIndex for the Swal modal
          document.querySelector('.my-swal').style.zIndex = 999999;
        },
      });
      if (result.isConfirmed) {
        directClose();
      }
    } else {
      directClose();
    }
  };

  const replies = data?.replies || [];

  const onPostFeed = async () => {
    if (data !== null) {
      onFeedReply();
    } else {
      await onPost({ message, goodBad }, uploadImageFiles);
      handleClose(true);
    }
  };

  const onFeedReply = async () => {
    try {
      setDisabled(true);
      let images = [];
      if (uploadImageFiles.length > 0) {
        const resUpload = await uploadSafalRoomImages(uploadImageFiles);
        images = resUpload?.data?.data;
      }
      const payload = {
        message: message,
        companyId: companyId,
        chatId: data?._id,
        sentiment: goodBad, // good, bad
        roomId: roomInfo?._id,
        images,
        parentChatPath: data?.parentChatPath,
        parentChat: data?.parentChat,
      };

      const res = await createSafalRoomPrivateFeedReply(payload);
      if (res.status === 200) {
        refetch({
          flag: 'REPLY',
          data: data,
        });
        setUploadImageFiles([]);
        setGoodBad('');
        setMessage('');
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisabled(false);
    }
  };

  const onUpdateFeedReply = async (e, safal) => {
    const payload = {
      like: safal,
      replyId: e?._id,
    };

    try {
      setDisabled(true);
      const res = await updateSafalRoomPrivateFeedReply(payload);
      if (res.status === 200) {
        refetch({
          flag: 'REPLY',
          data: data,
        });
        setGoodBad('');
        setMessage('');
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisabled(false);
    }
  };

  const onDeleteImageFile = (index) => {
    const newFiles = [...uploadImageFiles];
    newFiles.splice(index, 1);
    setUploadImageFiles(newFiles);
  };

  return (
    <>
      <Modal sx={{ zIndex: 345 }} open={open}>
        <Box sx={style}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: '#3D71FF',
              color: 'white',
            }}
            onClick={handleClose}
            size="small"
            color="primary"
          >
            <CloseIcon />
          </Fab>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '8px', fontSize: '28px', fontWeight: 700, color: '#3D71FF' }}
          >
            {data ? (replies?.length > 0 ? `Replies (${replies?.length})` : 'Replies') : 'Feed'}
          </Typography>
          {data && (
            <>
              <FeedMessage data={data} isReply />
              {/* <div style={{ backgroundColor: '#BCCEFF', height: '1px', width: '100%' }} /> */}
              {/* <div style={{ fontSize: '16px', color: '#3D71FF', fontWeight: 'bold', padding: '6px 8px' }}>
                Replies {replies?.length > 0 && `(${replies?.length})`}
              </div> */}
              {replies.length > 0 ? (
                <div
                  style={{
                    border: '1px solid #BCCEFF',
                    maxHeight: '400px',
                    overflow: 'auto',
                    padding: '16px',
                    marginBottom: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    borderRadius: '12px',
                  }}
                >
                  {replies?.map((e, index) => (
                    <FeedMessage
                      thisReplyScreen
                      data={e}
                      key={index}
                      onUpdate={(safal) => onUpdateFeedReply(e, safal)}
                      size="small"
                      isLast={index === replies?.length - 1}
                    />
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    border: '1px solid #BCCEFF',
                    height: '60px',
                    overflow: 'hidden',
                    marginBottom: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span>No replies found.</span>
                </div>
              )}
            </>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <TextField
              disabled={disabled}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={replies?.length > 0 ? 2 : 4}
              placeholder={data ? `Add an reply` : `Share an idea`}
              inputProps={{ maxLength: 500 }}
              helperText={`${message.length}/500`}
            /> */}
            <RoomMentionsInput
              companiesMention={!data}
              disabled={disabled}
              value={message}
              placeholder={data ? `Add an reply` : `Share an idea`}
              onChange={(e) => setMessage(e.target.value)}
              users={usersMentions?.map((e) => ({ id: e?.id, display: e?.name }))}
              companies={companyListForMentions}
            />
            <CustomImageView onDelete={onDeleteImageFile} files={uploadImageFiles} />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              marginTop: '16px',
              justifyContent: 'space-between',
              fontSize: '14px',
            }}
          >
            <div>
              <button
                onClick={() => setGoodBad((prev) => (prev === 'good' ? '' : 'good'))}
                style={{
                  border: 'none',
                  backgroundColor: goodBad === 'good' ? '#bfd0ff' : '#f2f6ff',
                  height: '34px',
                  width: '150px',
                  color: 'green',
                  borderTopLeftRadius: '30px',
                  borderBottomLeftRadius: '30px',
                }}
              >
                Happy
              </button>
              <button
                onClick={() => setGoodBad((prev) => (prev === 'bad' ? '' : 'bad'))}
                style={{
                  border: 'none',
                  borderLeft: '2px solid white',
                  backgroundColor: goodBad === 'bad' ? '#bfd0ff' : '#f2f6ff',
                  height: '34px',
                  width: '150px',
                  color: 'red',
                  borderTopRightRadius: '30px',
                  borderBottomRightRadius: '30px',
                  fontSize: '13px',
                }}
              >
                Needs improvement
              </button>
            </div>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              {/* <Button
                onClick={handleClose}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  backgroundColor: '#ff4842',
                  ':hover': { backgroundColor: '#d40700' },
                }}
                variant="contained"
              >
                Close
              </Button> */}
              <CustomImageViewInput disabled={disabled} onChange={(files) => setUploadImageFiles(files)} />
              <Button
                onClick={onPostFeed}
                sx={{
                  borderRadius: '30px',
                  textTransform: 'capitalize',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                variant="contained"
              >
                Post
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
