import PropTypes from 'prop-types';
import { useMemo } from 'react';
// material
import { CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
//
import paletteUser from './paletteUser';
import paletteBusiness from './paletteBusiness';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { user } = useSelector((state) => state.login);

  const themeOptionsUser = useMemo(
    () => ({
      palette: paletteUser,
      shape: { borderRadius: 8 },
      // typography,
      shadows,
      customShadows,
    }),
    []
  );

  const themeOptionsBusiness = useMemo(
    () => ({
      palette: paletteBusiness,
      shape: { borderRadius: 8 },
      // typography,
      shadows,
      customShadows,
    }),
    []
  );
  const theme = createTheme(user?.role === 'user' ? themeOptionsUser : themeOptionsBusiness);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
