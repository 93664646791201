import { Box, Card, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ForgetUserIdForm from '../../sections/auth/forgetUserId/ForgetUserIdForm';
import Page from '../../components/Page';

// const ContentStyle = styled('div')(({ theme }) => ({
//     maxWidth: 480,
//     margin: 'auto',
//     minHeight: '100vh',
//     display: 'flex',
//     justifyContent: 'center',
//     flexDirection: 'column',
//     //   padding: theme.spacing(12, 0, 0, 0),
// }));
const RootStyle = styled('div')(() => ({
  background: `url(${'/assets/images/login-background.png'})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  // [theme.breakpoints.up('md')]: {
  //   display: 'flex',
  // },
}));

const ForgetUserId = () => (
  <Page title="ForgetUserId">
    <RootStyle>
      <Container>
        <Box display="inline-block" sx={{ width: '100%', height: '100vh' }} py={4}>
          <Grid container height={'100%'}>
            <Grid item display={{ xs: 'none', md: 'block' }} xs={0} md={7} height={'100%'}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px',
                  width: '100%',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 50px 0px #C1C8F6',
                  border: '2px solid #3D71FF',
                  zIndex: 1,
                }}
              >
                <Box>
                  <img src="/assets/images/Logo.png" alt="login" width="30%" style={{ objectFit: 'contain' }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <Typography variant="h2" align="center" sx={{ fontSize: '38px', fontWeight: 300 }} mb={2}>
                    Welcome to <span style={{ color: '#3D71FF', fontWeight: 700 }}>SafalSubscriptions</span>
                  </Typography>

                  <Typography
                    variant="h2"
                    sx={{ fontSize: '16px ', fontWeight: 300, color: '#1D1D1F', textAlign: 'center' }}
                    mb={2}
                  >
                    Home to track Subscriptions, Provide Safals and Chat with SafalSubscriptions Users
                  </Typography>

                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      src="/assets/images/login-image.png"
                      alt="login"
                      width="85%"
                      height="400px"
                      style={{ objectFit: 'contain' }}
                    />
                  </Box>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} md={5} sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <Card
                sx={{
                  height: 'calc(100% - 22%)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  padding: '50px',
                  width: '100%',
                  borderRadius: '0px',
                  borderTopRightRadius: '10px',
                  borderBottomRightRadius: '10px',
                  boxShadow: 'none',
                  pl: '80px',
                }}
              >
                <Box
                  sx={{
                    '@media (min-width: 640px)': {
                      display: 'none !importent',
                    },
                  }}
                >
                  <img src="/assets/images/Logo.png" alt="login" width="50%" style={{ objectFit: 'contain' }} />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    // alignItems: 'center',
                    justifyContent: 'center',
                    // height: '100%',
                    // height: "calc(100% - 50%)",
                    width: '100%',
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{ fontSize: '24px', fontWeight: 700, alignItems: 'center', color: '#3D71FF' }}
                    mb={1}
                  >
                    Forgot User ID
                  </Typography>

                  {/* <Typography variant="body1" sx={{ fontSize: '15px', fontWeight: 300, color: '#1D1D1F' }} mb={3}>

                                            Enter your email address
                                        </Typography> */}
                </Box>
                <ForgetUserIdForm />
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </RootStyle>
  </Page>
);

export default ForgetUserId;
