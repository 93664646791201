import React, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import safalAi01 from '../../assets/safal-ai01.png';
import CustomNewDashboardBoxUnlimited from '../../components/UI/CustomNewDashboardBoxUnlimited';

const NewLabelLimitsChart = ({ data }) => {
  const subLabelLimit = data?.subLabelLimit;
  const labels = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];

  if (subLabelLimit?.isUnlimited)
    return (
      <CustomNewDashboardBoxUnlimited
        maxWidth="350px"
        minWidth="320px"
        color="#004da7"
        img={safalAi01}
        title="Total Safal AI Messages"
        leftTitle="Plan"
        leftValue="Unlimited"
        rightTitle="Actual"
        rightValue={subLabelLimit?.used}
        // onClick={navigateSubHandler}
      />
    );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
        Subscriptions Label
      </span>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ReactSpeedometer
          forceRender
          maxSegmentLabels={1}
          customSegmentStops={[0, subLabelLimit?.used || 0, subLabelLimit?.limit || 0]}
          segmentColors={['#3e71ff', '#01bc56']}
          needleColor={'#3e71ff'}
          currentValueText={`${((subLabelLimit?.used / subLabelLimit?.limit) * 100).toFixed(2) || 0} %`}
          minValue={0}
          maxValue={subLabelLimit?.limit || 0}
          value={subLabelLimit?.used || 0}
          textColor="#3e71ff"
          ringWidth={30}
          height={180}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', gap: '8px' }}>
        {labels?.map((e, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
            <span style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{e?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewLabelLimitsChart;
