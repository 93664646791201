import React, { useEffect } from 'react';
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  setDoc,
  getDoc,
  collectionGroup,
  updateDoc,
  query,
  where,
  arrayUnion,
  documentId,
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { getUserChatLimit } from '../services/Service';
import { db, auth } from '../firebase';
import { updateAttachLimit, updateOnlineUsers, updateSupportChat, updateUserChat } from '../store/ChatSlice';
import { setCurrentUser } from '../store/AuthSlice';
import { setCurrentFirebaseUser } from '../store/currentFirebaseUserSlice';

const ChatListener = () => {
  const dispatch = useDispatch();
  const { chatMode } = useSelector((state) => state.chats);
  const { currentUser } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.login);

  useEffect(() => {
    if (currentUser?.uid) {
      onSnapshot(doc(db, 'users', currentUser?.uid), (snapshot) => {
        const data = snapshot.data();
        dispatch(setCurrentFirebaseUser(data));
      });
    }
  }, [currentUser]);

  const onBeforeUnload = async () => {
    navigator.sendBeacon(
      `${process.env.REACT_APP_API_URL}/firebase`,
      JSON.stringify({ id: localStorage.getItem('fire') })
    );
  };

  useEffect(() => {
    // onlineListener()
    getUserChatLimit(user?._id)
      .then((res) => {
        dispatch(updateAttachLimit(res.data.data));
      })
      .catch((e) => console.log(e));
    const listenForOnline = onSnapshot(collection(db, 'online'), (snapshot) => {
      const online = [];
      try {
        snapshot.docs.forEach(async (onLineDoc) => {
          online.push(onLineDoc.data().id);
          const messageStatus = await getDoc(doc(db, 'userChats', onLineDoc.data().id));
          const chatId = Object.keys(messageStatus.data())[0];
          if (chatId && !chatId.includes('null') && !chatId.includes('undefined')) {
            const chatRef = doc(collection(db, 'chats'), chatId);
            const q = query(chatRef, where('messages', 'array-contains', { msgStatus: 0 }));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach(async (doc) => {
              await updateDoc(doc.ref, {
                messages: arrayUnion({
                  msgStatus: 1,
                }),
              });
            });
          }
        });
      } catch (e) {
        console.log(e);
      }

      dispatch(updateOnlineUsers(online));
    });
    let listenForSupportChat;
    let listenForAlerts;
    try {
      listenForAlerts = onSnapshot(doc(db, `notificationsdot`, currentUser?.uid), (doc) => {
        if (doc.data() && doc.data().count > 0) {
          dispatch(updateUserChat(true));
        } else {
          dispatch(updateUserChat(false));
        }
      });
      listenForSupportChat = onSnapshot(doc(db, `alert`, currentUser?.uid), (doc) => {
        if (doc.exists() && doc.data().count > 0) {
          dispatch(updateSupportChat(true));
        } else {
          dispatch(updateSupportChat(false));
        }
      });
    } catch (e) {
      console.log(e);
    }

    // alert listener
    return () => {
      listenForOnline();
      if (currentUser !== null) {
        listenForSupportChat();
        listenForAlerts();
      }
    };
  }, [currentUser]);

  const handleFunction = async () => {
    const user = await getDoc(doc(db, 'users', localStorage.getItem('fire')));

    if (user.data().isOnline) {
      await setDoc(doc(db, 'online', localStorage.getItem('fire')), {
        id: localStorage.getItem('fire'),
        displayName: user.data().displayName,
      });
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        localStorage.setItem('fire', user?.uid);
        dispatch(setCurrentUser({ currentUser: user }));
        chatMode === 'user' && handleFunction();
        window.addEventListener('beforeunload', onBeforeUnload);
      }
    });

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [chatMode]);

  return <></>;
};

export default ChatListener;
