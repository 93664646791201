import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import { addAdvertisementPrice, updateAdvertisementPrice } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  priceInCent: 0,
  advertisementPageId: '',
  status: '', // true, false
  interval: '', // hour day week month year
  country: ['USA', 'IND'], // "USA","IND"
  duration: 1, // min 1, if not pass default 1
};

export default function AddAdPriceModal({ open, onClose, data, isDisplay, adId }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);

  const handleClose = (flag) => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    onClose(flag);
  };

  // adId
  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      const res = await addAdvertisementPrice({
        ...fieldValue,
        advertisementPageId: adId,
        priceInCent: Number(fieldValue.priceInCent),
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const res = await updateAdvertisementPrice({
        _id: data._id,
        ...fieldValue,
        priceInCent: Number(fieldValue.priceInCent),
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        name: data?.name ?? '',
        priceInCent: data?.priceInCent ?? '',
        advertisementPageId: data?.advertisementPageId ?? '',
        status: data?.status ?? true, // true, false
        interval: data?.interval ?? '', // hour day week month year
        country: data?.country ?? [], // "USA","IND"
        duration: data?.duration ?? 1, // min 1, if not pass default 1
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  console.log('DEBUG isDisableAll', isDisableAll);

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Advertisement Price' : isDisplay ? 'Advertisement Price' : 'Update Advertisement Price'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Price Name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={data === null ? isDisableAll : isDisplay ? isDisableAll : false}
          />
          {/* priceInCent */}
          <TextField
            value={fieldValue.priceInCent}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, priceInCent: e.target.value }))}
            required
            label="Price In Cent ($)"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            disabled={data === null ? isDisableAll : isDisplay ? isDisableAll : false}
          />
          {/* interval */}
          <TextField
            value={fieldValue.interval}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, interval: e.target.value }))}
            required
            select
            label="Interval"
            size="small"
            fullWidth
            variant="outlined"
            disabled={data === null ? isDisableAll : isDisplay ? isDisableAll : false}
          >
            <MenuItem value="hour">Hour</MenuItem>
            <MenuItem value="day">Day</MenuItem>
            <MenuItem value="week">Week</MenuItem>
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="year">Year</MenuItem>
          </TextField>
          {/* duration */}
          <TextField
            value={fieldValue.duration}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, duration: e.target.value }))}
            required
            label="Duration"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={data === null ? isDisableAll : isDisplay ? isDisableAll : false}
          />
          {/* status */}
          <TextField
            value={fieldValue.status === true ? 'Active' : fieldValue.status === false ? 'Inactive' : ''}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            fullWidth
            variant="outlined"
            disabled={data === null ? isDisableAll : isDisplay ? isDisableAll : false}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
