/* eslint-disable no-template-curly-in-string */
/* eslint-disable react/jsx-boolean-value */

import React, { useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSelector } from 'react-redux';
import { IconButton, TableContainer, TableRow, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';
import ReactECharts from 'echarts-for-react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { CustomTableCellForBody, CustomTableView, tableCellPStyle } from '../../components/UI/CustomTableView';
import { downloadPaymentModeReport, getPaymentModeReport, getSignedURL } from '../../services/Service';
import useDebounce from '../../hooks/useDebounce';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import PaymentModeReportFilterMenu from './PaymentModeReportFilterMenu';
import PaymentModeReportView from './PaymentModeReportView';
import useSortHook from '../../hooks/useSortHook';
import Iconify from '../../components/Iconify';
import Inlineloader from '../../components/Loader/InlineLoader';

const PaymentModeReport = () => {
  const [filterState, setFilterState] = useState({
    paymentMode: '',
    amountMax: '',
    amountMin: '',
    subStatus: '',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [viewModal, setViewModal] = useState({
    open: false,
    type: null, // sub, asset
    data: [],
  });
  const [tableData, setTableData] = useState([]);
  const { handleSort, newSortData, sortState } = useSortHook(tableData);
  const { user } = useSelector((state) => state.login);
  const currencySymbol = user?.country === 'United States' ? '$' : '₹';
  // DeBounce Function
  useDebounce(
    async () => {
      await fetchData();
    },
    [filterState],
    1000
  );

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await getPaymentModeReport(filterState);
      setTableData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  const downloadTrend = async (type) => {
    try {
      const res = await downloadPaymentModeReport({ ...filterState, fileType: type });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  const labelOption = {
    title: {
      text: 'Payment Method/Type Distribution Report', // Your desired title here
      textStyle: {
        fontWeight: 'bold',
        fontSize: 18,
      },
      left: 'left', // Adjust the position of the title if needed
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    tooltip: {
      trigger: 'item',
      // formatter: `{a} <br/>{b} : ${currencySymbol}{c} ({d}%)`,
      formatter: function (params) {
        return `${params.seriesName} <br/>${params.name} : ${currencySymbol}${new Intl.NumberFormat().format(
          params.value
        )} (${params.percent}%)`;
      },
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 10,
      top: 20,
      bottom: 20,
      data: tableData.map((e) => e.name),
    },
    series: [
      {
        name: 'Payment Method/Type Distribution Report',
        type: 'pie',
        radius: '55%',
        center: ['40%', '50%'],
        data: tableData.map((e) => ({ name: e.name, value: e.totalAmount })),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        label: {
          // formatter: `${currencySymbol}{@2012} ({d}%)`,
          formatter: function (params) {
            return `${currencySymbol}${new Intl.NumberFormat().format(params.value)} (${params.percent}%)`;
          },
        },
      },
    ],
  };

  if (isLoading) return <Inlineloader />;

  return (
    <>
      <div style={{ overflow: 'none', maxWidth: '98%' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div />
          <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
            <PaymentModeReportFilterMenu state={filterState} onFilterApply={onFilterApply} />
            <Tooltip title="Refresh">
              <IconButton
                sx={{
                  height: '40px',
                  flex: 'none',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={fetchData}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            <ReportsTabsDownloadMenu downloadFile={downloadTrend} />
          </div>
        </div>
        <div className="flex-col-1000px" style={{ display: 'flex', gap: '16px', overflow: 'none' }}>
          <div style={{ flex: '1' }}>
            <div>
              <ReactECharts
                notMerge={true}
                lazyUpdate={true}
                option={labelOption}
                style={{ height: '100%', minHeight: '400px', maxHeight: '600px' }}
              />
            </div>
          </div>
          <div className="width-full-1000px" style={{ flex: 'none', width: '50%', overflow: 'none' }}>
            <TableContainer>
              <CustomTableView
                isSort
                sort={{
                  order: sortState.order,
                  orderBy: sortState.orderBy,
                  createSortHandler: handleSort,
                  sortHeadMap: [
                    'name',
                    'transactionsCount',
                    'subscriptionsCount',
                    'assetCount',
                    'totalAmount',
                    'percentageAmount',
                  ],
                }}
                headData={[
                  'Payment Method/Type',
                  'No. of Transactions',
                  'No. of Subscriptions',
                  'No. of Assets (Gift Card)',
                  'Total Amount',
                  '% Amount',
                ]}
              >
                {newSortData?.map((e, index) => (
                  <TableRow key={index}>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.name}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.transactionsCount}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>
                        {e.subscriptionsCount}
                        {e?.subscriptions.length > 0 && (
                          <Tooltip title="No. of Subscriptions">
                            <IconButton
                              onClick={() => {
                                setViewModal({
                                  open: true,
                                  type: 'sub', // sub, asset
                                  data: e?.subscriptions,
                                });
                              }}
                              size="small"
                            >
                              <Iconify icon="vaadin:glasses" color="#1877F2" width={22} height={22} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>
                        {e.assetCount}
                        {e?.assets.length > 0 && (
                          <Tooltip title="No. of Assets (Gift Card)">
                            <IconButton
                              onClick={() => {
                                setViewModal({
                                  open: true,
                                  type: 'asset', // sub, asset
                                  data: e?.assets,
                                });
                              }}
                              size="small"
                            >
                              <Iconify icon="vaadin:glasses" color="#1877F2" width={22} height={22} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={{ ...tableCellPStyle, whiteSpace: 'nowrap' }}>
                        {currencySymbol} {new Intl.NumberFormat().format(e.totalAmount)}
                      </p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{`${new Intl.NumberFormat().format(e.percentageAmount)} %`}</p>
                    </CustomTableCellForBody>
                  </TableRow>
                ))}
              </CustomTableView>
            </TableContainer>
          </div>
        </div>
      </div>
      <PaymentModeReportView
        currencySymbol={currencySymbol}
        open={viewModal.open}
        type={viewModal.type}
        data={viewModal.data}
        onClose={() =>
          setViewModal({
            open: false,
            type: null, // sub, asset
            data: [],
          })
        }
      />
    </>
  );
};

export default PaymentModeReport;
