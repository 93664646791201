/* eslint-disable react/jsx-boolean-value */
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import moment from 'moment';

import { getSubscriptionRevenue } from '../../../../services/Service';
import { formatNumberToShort } from '../../../../utils/general';

// const colors = ['#468b47','#90ee90', '#edf9eb',"#385239"];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const SubscriptionRevenueBarChart = ({ title }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [mode, setMode] = useState('ThisYear'); // ThisYear, LastYear

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const res = await getSubscriptionRevenue({ ...filter, page: title === 'Monthly Sales' ? 'sales' : '' });
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let startDate = '';
    let endDate = '';

    switch (mode) {
      case 'ThisYear':
        startDate = moment().startOf('year').unix();
        endDate = moment().endOf('year').unix();
        break;
      case 'LastYear':
        startDate = moment().subtract(1, 'year').startOf('year').unix();
        endDate = moment().subtract(1, 'year').endOf('year').unix();
        break;

      default:
        break;
    }

    fetchData({ endDate, startDate });
  }, [mode]);

  const dataKeys = data ? Object.keys(data) : [];
  const dataValues = data ? Object.values(data) : [];

  const chartOption = {
    title: {
      text: title,
      left: 'left',
      textStyle: {
        color: '#468b47',
        lineHeight: 16,
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}: $ {c}', // Tooltip shows name, value
    },
    xAxis: {
      type: 'category',
      data: dataKeys,
      axisTick: { show: false },
      axisLabel: {
        color: '#468b47', // Green color for x-axis labels
        fontWeight: 'bold',
      },
      axisLine: {
        show: false, // Remove x-axis line
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#468b47', // Green color for x-axis labels
        formatter: '$ {value}',
      },
    },
    series: [
      {
        data: dataValues,
        type: 'bar',
        itemStyle: {
          color: '#468b47', // Green color for bars
          borderRadius: 4,
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <select
        value={mode}
        style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 1 }}
        onChange={(e) => setMode(e.target.value)}
      >
        <option value="ThisYear">This Year</option>
        <option value="LastYear">Last Year</option>
      </select>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        option={chartOption}
        showLoading={loading}
        style={{ height: '400px', minHeight: '300px' }}
      />
    </div>
  );
};
export default SubscriptionRevenueBarChart;
