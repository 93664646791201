import { FormControl, InputLabel, MenuItem, Select, Switch, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './SafalGiftMasterInventory.css';

import {
  getSafalGiftMasterInventory,
  getApplicationList,
  deleteSafalGiftMasterInventory,
  getLeaderBoardMasterCountries,
} from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddSafalGiftMasterInventoryModal from './AddSafalGiftMasterInventoryModal';

const SafalGiftMasterInventory = () => {
  const [applicationsList, setApplicationsList] = useState([]);
  const [countryList, setCountryList] = useState([]);

  const [rawData, setRawData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [filterState, setFilterState] = useState({
    isDeleted: false,
  });

  const [addModalOpen, setAddModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const [selectedApplication, setSelectedApplication] = useState('SafalSubscriptions');

  const fetchData = async () => {
    try {
      const res = await getSafalGiftMasterInventory({ application: selectedApplication, ...filterState });
      if (res.status === 200) {
        setRawData(res.data.data);
        setFilterData(res.data.data);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    }
  };

  const fetchApplicationsList = async () => {
    try {
      const res = await getApplicationList();
      if (res.status === 200) {
        setApplicationsList(res.data.data);
      }
      const res1 = await getLeaderBoardMasterCountries();
      if (res1.status === 200) {
        setCountryList(res1.data.data);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    }
  };

  const onAddModalOpenDisplay = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenEdit = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpen = () => {
    setAddModalOpen({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteSafalGiftMasterInventory({
        id: data._id,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalOpen = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data,
    });
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchApplicationsList();
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedApplication, filterState]);

  return (
    <>
      <Helmet>
        <title>SafalGift Master Inventory - SafalSubscriptions</title>
      </Helmet>

      <div id="SafalGiftMasterInventory">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              SafalGift Master Inventory
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="SafalGiftMasterInventory-selectedApplication">Application</InputLabel>
              <Select
                labelId="SafalGiftMasterInventory-selectedApplication"
                value={selectedApplication}
                label="Application"
                onChange={(e) => setSelectedApplication(e.target.value)}
              >
                {applicationsList?.map((e, index) => (
                  <MenuItem key={index} value={e?.name}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="SafalBuddyActivitiesMasterConfig-selectedTier">Plan Tier</InputLabel>
              <Select
                labelId="SafalBuddyActivitiesMasterConfig-selectedTier"
                value={selectedTier}
                label="Plan Tier"
                onChange={(e) => setSelectedTier(e.target.value)}
              >
                {planList?.map((e, index) => (
                  <MenuItem key={index} value={e?.tier}>
                    Tier {e.tier} : {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          onDelete={onDeleteModalOpen}
          onEdit={onAddModalOpenEdit}
          onDisplay={onAddModalOpenDisplay}
          data={filterData}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddSafalGiftMasterInventoryModal
        applicationsList={applicationsList}
        countryList={countryList}
        open={addModalOpen.open}
        data={addModalOpen.data}
        isDisplay={addModalOpen.isDisplay}
        onClose={onAddModalClose}
      />
    </>
  );
};

export default SafalGiftMasterInventory;
