/* eslint-disable arrow-body-style */
import { Button, ClickAwayListener, Menu, MenuItem } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

import Iconify from '../../components/Iconify';
import Attachment from '../../components/Attachments';
import { getCompanyByNameType } from '../../services/Service';
import { GlobalShareModal } from '../../components/Modals/GlobalShareModal';

const ActionMenu = ({
  row,
  actionMenu,
  actionOpen,
  handleActionClose,
  handleClickOpen,
  setOpenDelete,
  handledelete,
  setSelected,
  isDelete = true,
  setOpenTrackModal,
  setEditData,
  setOpenEditModal,
  setOpenDisplayModal,
  setDeleteSubNames,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openDownload, setOpenDownload] = useState(false);
  const [shareModal, setShareModal] = useState({ open: false });
  const menuRef = useRef(null);
  const handleClickDownload = (event) => {
    console.log('download open => ', event.target);
    setOpenDownload(event?.currentTarget);
  };

  const handleCloseDownload = () => {
    setOpenDownload(null);
    console.log('download close => ', openDownload);
  };
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      handleCloseDownload();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const navigateToSafalRoom = async () => {
    if (row?.masterCompany?._id) {
      handleActionClose();
      navigate(`/safalroom?company=${row?.masterCompany?._id}`);
    } else {
      if (!row?.company) {
        toast.error('SafalRoom not available for this subscription');
        return;
      }
      try {
        const findCom = await getCompanyByNameType({
          name: row?.company?.name,
          type: row?.company?.companyType,
        });
        if (findCom.status === 200) {
          handleActionClose();
          navigate(`/safalroom?company=${findCom.data.data._id}`);
        }
      } catch (err) {
        toast.error('SafalRoom not available for this subscription');
      }
    }
  };

  const onShareClick = () => {
    setShareModal((prev) => ({ ...prev, open: true }));
  };
  const onShareClose = () => {
    setShareModal({ open: false });
  };

  return pathname === '/business/companieslist' ? (
    <Menu
      id="action-menu"
      anchorEl={actionMenu}
      open={actionOpen}
      // onClick={handleActionClick}
      onClose={handleActionClose}
      MenuListProps={{
        'aria-labelledby': 'action-button',
      }}
    >
      <MenuItem>
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleClickOpen();
            // setOpenEditModal(true);
            handleActionClose();
          }}
        >
          <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
          Edit
        </Button>
      </MenuItem>
      {isDelete && (
        <MenuItem>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setOpenDelete(true);
              handledelete(row?._id);
              setSelected([]);
              handleActionClose();
            }}
          >
            <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
            Delete
          </Button>
        </MenuItem>
      )}
    </Menu>
  ) : (
    <>
      <Menu
        id="action-menu"
        anchorEl={actionMenu}
        open={actionOpen}
        // onClick={handleActionClick}
        onClose={handleActionClose}
        MenuListProps={{
          'aria-labelledby': 'action-button',
        }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            setOpenEditModal(true);
            handleActionClose();
          }}
        >
          <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
          <span style={{ marginLeft: '8px' }}>Edit</span>
        </MenuItem>
        {isDelete && (
          <MenuItem
            // >
            //   <Button
            onClick={(e) => {
              e.preventDefault();
              setOpenDelete(true);
              handledelete(row?._id);
              setDeleteSubNames(row?.subscriptionName);
              setSelected([]);
              handleActionClose();
            }}
          >
            <Iconify icon="ic:twotone-delete" color="#DF3E30" width={24} height={24} />
            <span style={{ marginLeft: '8px' }}>Delete</span>
          </MenuItem>
        )}

        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            setOpenDisplayModal(true);
            handleActionClose();
          }}
        >
          <Iconify icon="vaadin:glasses" color="#1877F2" width={22} height={22} />
          <span style={{ marginLeft: '8px' }}>Display</span>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            onShareClick();
            handleActionClose();
          }}
        >
          <Iconify icon="material-symbols:share" color="#1877F2" width={22} height={22} />
          <span style={{ marginLeft: '8px' }}>Share</span>
        </MenuItem>
        {/* safalROOM display */}
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            navigateToSafalRoom();
          }}
        >
          <img
            src="/assets/images/Safalroom_blue.png"
            style={{
              width: '22px',
              height: '22px',
              objectFit: 'contain',
            }}
            alt="SafalRoom"
          />
          <span style={{ marginLeft: '8px' }}>SafalRoom</span>
        </MenuItem>

        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            setOpenTrackModal(true);
            setEditData(row?.company?.name);
            handleActionClose();
          }}
        >
          <Iconify icon="mdi:clipboard-text-search" color="#1877F2" width={22} height={22} />
          <span style={{ marginLeft: '8px' }}>Change log</span>
        </MenuItem>

        {row?.attachments && row?.attachments.length > 0 && (
          <div ref={menuRef}>
            <MenuItem onClick={(e) => handleClickDownload(e)}>
              <>
                <Attachment
                  data={row.attachments}
                  openDownload={openDownload}
                  // handleClickDownload={handleClickDownload}
                  handleCloseDownload={handleCloseDownload}
                  handleActionClose={handleActionClose}
                  setOpenDownload={setOpenDownload}
                />
                Attachments
              </>
            </MenuItem>
          </div>
        )}
      </Menu>
      <GlobalShareModal
        link="https://dev.safalsubscriptions.com/subscription"
        title="Subscription"
        open={shareModal.open}
        onClose={onShareClose}
      />
    </>
  );
};

export default ActionMenu;
