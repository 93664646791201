import React, { useEffect, useState } from 'react';
import ReactSpeedometer from 'react-d3-speedometer';

import { GetLastMonthSubsChart } from '../../services/Service';
import CustomNewDashboardBoxUnlimited from '../../components/UI/CustomNewDashboardBoxUnlimited';
import dimg8 from '../../assets/dashboard8.png';
import no_of_subImg from '../../assets/no_of_sub.png';

const NewSubscriptionChart = ({ setUnlimited, onClick }) => {
  const [lastMonthSubs, setLastMonthSubs] = useState({
    totalSubscriptions: 0,
    count: 0,
    limit: 0,
    max: 0,
  });

  const fetchData = async () => {
    try {
      const res = await GetLastMonthSubsChart();
      if (res.data.success === true) {
        setLastMonthSubs({
          totalSubscriptions: res.data.data.totalSubscriptions,
          count: res.data.data.count,
          limit: res.data.data.limit,
          max: res.data.data.max,
        });
        if (res.data.data.max === null && res.data.data.limit === null) setUnlimited();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const labels = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];

  const isUnlimited = lastMonthSubs.max === null;

  return (
    <>
      {!isUnlimited && (
        <div
          style={{
            flex: 'none',
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold', marginBottom: '16px' }}>
            Monthly subscriptions count
          </span>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ReactSpeedometer
              forceRender
              maxSegmentLabels={1}
              customSegmentStops={[0, lastMonthSubs?.totalSubscriptions || 0, lastMonthSubs?.max || 0]}
              segmentColors={['#3e71ff', '#01bc56']}
              needleColor={'#3e71ff'}
              currentValueText={`${((lastMonthSubs?.totalSubscriptions / lastMonthSubs.max) * 100).toFixed(2) || 0} %`}
              minValue={0}
              maxValue={lastMonthSubs?.max || 0}
              value={lastMonthSubs?.totalSubscriptions || 0}
              textColor="#3e71ff"
              ringWidth={30}
              height={180}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
            {labels?.map((e, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
                <span style={{ fontSize: '14px' }}>{e?.name}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      <CustomNewDashboardBoxUnlimited
        maxWidth="350px"
        minWidth="320px"
        height="120px"
        color="#025d17"
        img={no_of_subImg}
        title="Number of Subscriptions"
        leftTitle="Plan"
        flag="Number of Subscriptions"
        leftValue={isUnlimited ? 'Unlimited' : lastMonthSubs?.max}
        rightTitle="Actual"
        flex="none"
        style={{
          marginTop: isUnlimited ? '100px' : '40px',
        }}
        rightValue={lastMonthSubs?.totalSubscriptions}
        onClick={onClick}
      />
    </>
  );
};

export default NewSubscriptionChart;
