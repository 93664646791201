import { Box, Button, Fab, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const EventClickModalReuse = ({ open, onClose, onDisplay, onEdit, onDownload }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
    '@media (max-width: 640px)': {
      width: '98vw !important',
    },
  };

  return (
    <>
      <Modal open={open} aria-labelledby="child-modal-title" aria-describedby="child-modal-description">
        <Box sx={{ ...style, width: 500, height: 180 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab onClick={onClose} size="small" color="primary" aria-label="add">
              <CloseIcon />
            </Fab>
          </Box>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Which Action Do you Want to Perform?
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ display: 'flex', mt: 1 }}>
              <Button
                variant="contained"
                onClick={onDisplay}
                sx={{
                  textTransform: 'none',
                  width: '100px',
                  '@media (max-width: 640px)': {
                    width: '75px',
                  },
                }}
              >
                Display
              </Button>
            </Box>

            <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
              <Button
                variant="contained"
                onClick={onEdit}
                sx={{
                  textTransform: 'none',
                  width: '100px',
                  '@media (max-width: 640px)': {
                    width: '75px',
                  },
                }}
              >
                Edit
              </Button>
            </Box>

            <Box sx={{ display: 'flex', ml: 3, mt: 1 }}>
              <Button
                variant="contained"
                onClick={onDownload}
                sx={{
                  textTransform: 'none',
                  width: '100px',
                  '@media (max-width: 640px)': {
                    width: '75px',
                  },
                }}
              >
                Download
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EventClickModalReuse;
