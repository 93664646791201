import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';
import { Button, Stack, TextField } from '@mui/material';
import SuccessToast from '../../../components/Toast/Success';
import ErrorToast from '../../../components/Toast/Error';
import { ForgotpassResponse } from '../../../services/Service';

// ----------------------------------------------------------------------

export default function ForgetPasswordForm() {
  const ForgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Primary Email must be a valid email address')
      .required('Primary Email is required'),
  });

  const initialValues = {
    email: '',
  };

  const ForgetPasswordFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: ForgetPasswordSchema,
    onSubmit: (values) => {
      console.log(values, 'values');
      ForgotpassResponse(values)
        .then((res) => {
          SuccessToast('Reset Password Link send Successfully');
          console.log(res.data, 'forgot');
        })
        .catch((err) => {
          console.log(err.message, 'forgot err');
          ErrorToast(err?.response.data.message || err.message);
        });
    },
  });

  return (
    <>
      <FormikProvider value={ForgetPasswordFormik}>
        <form onSubmit={ForgetPasswordFormik.handleSubmit}>
          <Stack spacing={3}>
            <Field
              as={TextField}
              variant="standard"
              name="email"
              label="Primary Email"
              onChange={ForgetPasswordFormik.handleChange}
              value={ForgetPasswordFormik.values.email}
              error={ForgetPasswordFormik.touched.email && Boolean(ForgetPasswordFormik.errors.email)}
              helperText={ForgetPasswordFormik.touched.email && ForgetPasswordFormik.errors.email}
            />
          </Stack>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!(ForgetPasswordFormik.isValid && ForgetPasswordFormik.dirty)}
            sx={{ width: '250px', borderRadius: '30px', textTransform: 'none', backgroundColor: '#3D71FF', mt: '25px' }}
          >
            Reset My Password
          </Button>
        </form>
      </FormikProvider>
    </>
  );
}
