import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { createPlan, updatePlan } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const mode = '';

const initFieldValue = {
  name: '',
  tier: '',
  description: '',
  status: true,
};

export default function AddPaymentPlanModal({ open, onClose, data }) {
  const [fieldValue, setFieldValue] = useState(initFieldValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    onClose();
  };

  useEffect(() => {
    if (data === null) return;
    setFieldValue({
      name: data.name,
      tier: data.tier,
      description: data.description,
      status: data.status,
    });
  }, [data]);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await createPlan(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdate = async () => {
    setIsSubmitting(true);
    try {
      const res = await updatePlan({ ...fieldValue, _id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data ? 'Update Plan' : 'Add Plan'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Name"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
            // error={error}
            // helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
          />
          {/* description */}
          <TextField
            value={fieldValue.description}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, description: e.target.value }))}
            required
            label="Description"
            name="description"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
            // error={error}
            // helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
          />
          {/* tier */}
          <TextField
            value={fieldValue.tier}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, tier: e.target.value }))}
            required
            label="Tier"
            name="tier"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
            // error={error}
            // helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
          />
          {/* status */}
          <TextField
            value={fieldValue.status === true ? 'active' : 'inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'active' }))}
            required
            select
            label="Status"
            size="small"
            fullWidth
            variant="outlined"
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          {data ? (
            <Button
              disabled={isSubmitting}
              onClick={onUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              Update
            </Button>
          ) : (
            <Button
              disabled={isSubmitting}
              onClick={onSubmit}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              Create
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
