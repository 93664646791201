import {
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tabs,
} from '@mui/material';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getSafalLeaderBoardActivitiesConfig,
  updateSafalBuddyAndLeaderBoardSwitch,
  updateSafalLeaderBoardActivitiesConfig,
} from '../../services/Service';
import ErrorToast from '../../components/Toast/Error';
import { setSafalLeaderBoardSwitch } from '../../store/SafalLeaderBoardSwitchSlice';

const tableCellPStyle1 = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '100px',
  maxHeight: '100px',
  paddingLeft: '12px',
  paddingRight: '12px',
};

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px',
  maxHeight: '100px',
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const SafalLeaderBoardUserNotificationConfig = ({ value }) => {
  const [rawData, setRawData] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [businessSwitchData, setBusinessSwitchData] = useState([]);
  const [switchData, setSwitchData] = useState([]);
  const [disableTableSwitch, setDisableTableSwitch] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const res = await getSafalLeaderBoardActivitiesConfig({ application: 'SafalSubscriptions' });
      if (res.status === 200) {
        setRawData(res.data.data);
        setBusinessData(res.data?.businessData);
        setSwitchData(res.data?.masterSwitch);
        setBusinessSwitchData(res.data?.masterSwitchBusiness);
        const masterSwitch = res?.data?.masterSwitch;
        if (masterSwitch && masterSwitch.length > 0) {
          const resData = masterSwitch[0];
          dispatch(setSafalLeaderBoardSwitch(resData));
        }
      }
    } catch (err) {
      console.log('getSafalBuddyActivitiesConfig fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateValue = async (data, key, newValue) => {
    const findBusiness = businessData.find((e) => e?.activityKey === data?.activityKey);
    if (findBusiness && findBusiness[key] === false) {
      ErrorToast(
        'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
      );
      return;
    }
    setDisableTableSwitch(true);
    try {
      const payload = { ...data, [key]: newValue };
      const res = await updateSafalLeaderBoardActivitiesConfig(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableTableSwitch(false);
    }
  };

  const updateNotificationValue = async (data, key, newValue) => {
    const findBusiness = businessData.find((e) => e?.activityKey === data?.activityKey);
    if (findBusiness && findBusiness?.notification[key] === false) {
      ErrorToast(
        'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
      );
      return;
    }
    setDisableTableSwitch(true);
    try {
      const payload = { ...data, notification: { ...data.notification, [key]: newValue } };
      const res = await updateSafalLeaderBoardActivitiesConfig(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableTableSwitch(false);
    }
  };

  const switchDataValue = switchData[0];

  const updateActivitiesConfigSwitch = async () => {
    const findBusiness = businessSwitchData[0];
    if (!findBusiness) {
      ErrorToast(
        'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
      );
      return;
    }
    if (findBusiness?.leaderBoardStatus === false) {
      ErrorToast(
        'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
      );
      return;
    }

    if (!switchDataValue) {
      ErrorToast(
        'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
      );
      return;
    }
    setDisableTableSwitch(true);
    try {
      const payload = { ...switchDataValue, leaderBoardStatus: !switchDataValue.leaderBoardStatus };
      const res = await updateSafalBuddyAndLeaderBoardSwitch(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableTableSwitch(false);
    }
  };

  return (
    <TabPanel
      value={value}
      index={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 0,
      }}
    >
      {switchDataValue && (
        <div>
          <span>Enable SafalLeaderBoard</span>
          <Switch
            disabled={disableTableSwitch}
            onChange={() => updateActivitiesConfigSwitch()}
            checked={switchDataValue.leaderBoardStatus}
            style={{ color: '#2065d1' }}
          />
        </div>
      )}
      <TableContainer
        sx={{
          minWidth: 1250,
          overflowX: 'auto',
          maxHeight: '70vh',
        }}
      >
        <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
          <TableHead style={{ padding: '0px' }}>
            <TableRow>
              <TableCell
                rowSpan={2}
                sx={{
                  backgroundColor: '#7B9EFD',
                  color: '#fff',
                  borderBottomLeftRadius: '35px',
                  borderTopLeftRadius: '35px',
                  fontWeight: 700,
                }}
              >
                Activity Name
              </TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>
                Share To My Buddy
              </TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>
                Receive Buddies Activity
              </TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>Email</TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>Text</TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>MobileApp</TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>Notif. Screen</TableCell>
              <TableCell sx={{ backgroundColor: '#7B9EFD', color: '#fff', fontWeight: 700 }}>Notif. Banner</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rawData?.map((val) => (
              <TableRow
                hover
                key={1}
                tabIndex={-1}
                style={{
                  backgroundColor: '#FFFFFF',
                  borderBottomLeftRadius: '200px',
                  borderTopLeftRadius: '200px',
                  paddingLeft: '0px',
                  borderRadius: '200px',
                  boxShadow: 'rgb(100 100 111 / 23%) 0px 0px 8px 2px',
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderBottomLeftRadius: '35px',
                    borderTopLeftRadius: '35px',
                  }}
                >
                  <p style={{ ...tableCellPStyle, paddingLeft: '12px', paddingRight: '12px' }}>{val.activityName}</p>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'shareToMyBuddy'}
                      onChange={() => updateValue(val, 'shareToMyBuddy', !val.shareToMyBuddy)}
                      checked={val.shareToMyBuddy}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'receiveBuddiesActivity'}
                      onChange={() => updateValue(val, 'receiveBuddiesActivity', !val.receiveBuddiesActivity)}
                      checked={val.receiveBuddiesActivity}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'email'}
                      onChange={() => updateNotificationValue(val, 'email', !val.notification.email)}
                      checked={val.notification.email}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'text'}
                      onChange={() => updateNotificationValue(val, 'text', !val.notification.text)}
                      checked={val.notification.text}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'mobileApp'}
                      onChange={() => updateNotificationValue(val, 'mobileApp', !val.notification.mobileApp)}
                      checked={val.notification.mobileApp}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'notification'}
                      onChange={() => updateNotificationValue(val, 'notification', !val.notification.notification)}
                      checked={val.notification.notification}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  padding="none"
                  sx={{
                    backgroundColor: '#FFFFFF',
                    p: 0,
                    borderBottomRightRadius: '35px',
                    borderTopRightRadius: '35px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Switch
                      disabled={disableTableSwitch}
                      name={'banner'}
                      onChange={() => updateNotificationValue(val, 'banner', !val.notification.banner)}
                      checked={val.notification.banner}
                      style={{ color: '#2065d1' }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TabPanel>
  );
};

export default SafalLeaderBoardUserNotificationConfig;
