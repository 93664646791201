import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography, useTheme, Button, Tooltip, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useEffect, useState } from 'react';
// components
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NavSection from '../../components/NavSection';
import navConfig from './NavConfig';
import NotificationsPopover from './NotificationsPopover';
import Sidebar from './Sidebar';

// ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 25;
const APPBAR_DESKTOP = 45;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  zIndex: 9,
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(0, 5),
    minHeight: APPBAR_DESKTOP,
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  // position: 'absolute',
  // padding: theme.spacing(1),
  padding: '2px 0px 2px 20px',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.primary.header,
  // [theme.breakpoints.up('md')]: {
  //   alignItems: 'flex-start',
  //   padding: theme.spacing(7, 5, 0, 7),
  // },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar() {
  const { user } = useSelector((state) => state.login);
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallDesktop = useMediaQuery('(max-width: 1200px)');

  // eslint-disable-next-line no-unneeded-ternary
  const [openSidebar, setOpenSidebar] = useState(window.innerWidth <= 600);

  const listenerFn = () => {
    if (window.innerWidth <= 600) {
      setOpenSidebar(false);
    } else {
      setOpenSidebar(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', listenerFn);

    return () => {
      window.removeEventListener('resize', listenerFn);
    };
  }, []);

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const handleClick = () => {
    navigate('/');
  };

  return (
    <RootStyle>
      <HeaderStyle>
        <Sidebar open={openSidebar} onClose={handleSidebarClose} />

        <Box>
          <Stack direction={'row'} spacing={5}>
            <Button
              sx={{ display: { xs: 'inline-block', sm: 'inline-block', md: 'none', lg: 'none' } }}
              onClick={() => setOpenSidebar(!openSidebar)}
            >
              <MenuIcon />
            </Button>

            <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' } }} onClick={handleClick}>
              <img
                src="/assets/images/Logo.png"
                width="150px"
                height="40px"
                alt="login"
                style={{ objectFit: 'contain', cursor: 'pointer' }}
              />
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              margin: 'auto 0',
              height: '40px',
              width: isSmallDesktop ? '10%' : '15%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={handleClick}
          >
            <img
              src="/assets/images/Logo.png"
              // width="150px"
              height="40px"
              alt="login"
              style={{ objectFit: 'contain', cursor: 'pointer', maxWidth: 'none' }}
            />
          </Box>

          <ToolbarStyle>
            <NavSection navConfig={navConfig} />

            <Box
              sx={{
                height: '30px',
                borderRadius: '30px',
                backgroundColor: theme.palette.info.main,
                display: 'flex',
                alignItems: 'center',
                p: '8px',
                ml: isSmallDesktop ? 0 : '5px',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title={user?.firstName}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: theme.palette.secondary.font,
                      fontSize: '13px',
                      fontWeight: 700,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'inline-block',
                      maxWidth: '100px',
                    }}
                  >
                    {user?.firstName}
                  </Typography>
                </Tooltip>
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.secondary.font,
                    fontSize: '13px',
                    fontWeight: 700,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {`(${user?.tier})`}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                height: '33px',
                borderRadius: '30px',
                backgroundColor: theme.palette.info.main,
                p: '8px',
                ml: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                <NotificationsPopover />
                <LanguagePopover />
                <AccountPopover />
              </Stack>
            </Box>
          </ToolbarStyle>
        </Box>
      </HeaderStyle>
    </RootStyle>
  );
}
