import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: null,
  tempUserID: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload.currentUser;
      return state;
    },
    setTempUserID: (state, action) => {
      console.log(action.payload)
      state.tempUserID = action.payload;
      return state;
    },
    resetAuth:()=>{
      return initialState;
    },
  },
});

export const { setCurrentUser, setTempUserID, resetAuth } = authSlice.actions;

export default authSlice.reducer;
