/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import Swal from 'sweetalert2';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { createPaymentInstrument } from '../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function AddPaymentInstrumentModal({ open, onClose, paymentModeList, paymentCardSchemeList }) {
  const initFieldValue = {
    status: true, // default true
    paymentMode: '',
    paymentModeId: '',
    cardSchemeField: '',
    cardSchemeFieldId: '',
    nickNameField: '',
    instrumentField: '', // this only take last 4 digit
  };

  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [paymentModeError, setPaymentModeError] = React.useState('');
  const [nickNameFieldError, setNickNameFieldError] = React.useState('');
  const directClose = () => {
    setFieldValue(initFieldValue);
    onClose();
  };

  const handleClose = async () => {
    // if (isDisplay) {
    //   directClose();
    //   return;
    // }
    if (
      fieldValue.paymentMode === '' &&
      fieldValue.paymentModeId === '' &&
      fieldValue.cardSchemeField === '' &&
      fieldValue.cardSchemeFieldId === '' &&
      fieldValue.nickNameField === '' &&
      fieldValue.instrumentField === ''
    ) {
      directClose();
    } else {
      const result = await Swal.fire({
        text: 'Are you sure you want to close this?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        appendTo: 'body',
        customClass: {
          container: 'my-swal', // Add a custom class for styling
        },
        onBeforeOpen: () => {
          // Set a higher zIndex for the Swal modal
          document.querySelector('.my-swal').style.zIndex = 999999;
        },
      });
      if (result.isConfirmed) {
        directClose();
      }
    }
  };

  const onChangeField = (e) => {
    setFieldValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = async () => {
    try {
      if (!fieldValue.paymentModeId) {
        setPaymentModeError('Required');
        return;
      }
      setPaymentModeError('');
      if (selectedPaymentMode?.nickNameField?.status && !fieldValue.nickNameField) {
        setNickNameFieldError('Required');
        return;
      }
      setNickNameFieldError('');

      const res = await createPaymentInstrument(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        directClose(res.data.data);
      }
    } catch (err) {
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const selectedPaymentMode = paymentModeList?.find((eXX) => eXX._id === fieldValue.paymentModeId);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <Typography variant="h4" gutterBottom sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            Create Payment Instrument
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" gutterBottom sx={{ fontSize: '15px', fontWeight: 400 }}>
              Enter your details below.
            </Typography>
          </div>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* paymentModeId */}
          <TextField
            value={fieldValue.paymentModeId}
            onChange={(e) => {
              const nameXX = paymentModeList?.find((eXX) => eXX._id === e.target.value);
              setFieldValue((prev) => ({ ...prev, paymentModeId: e.target.value, paymentMode: nameXX.name }));
            }}
            select
            label={
              <Typography sx={{ color: '#B6B6B6' }}>
                Payment Mode<span style={{ color: 'red' }}>*</span>
              </Typography>
            }
            size="small"
            fullWidth
            variant="outlined"
            error={paymentModeError}
            helperText={paymentModeError}
          >
            {paymentModeList?.map((ex) => (
              <MenuItem value={ex._id}>{ex.name}</MenuItem>
            ))}
          </TextField>
          {/* cardSchemeFieldId */}
          {selectedPaymentMode?.cardSchemeField?.status && (
            <TextField
              value={fieldValue.cardSchemeFieldId}
              onChange={(e) => {
                const nameXX = paymentCardSchemeList?.find((eXX) => eXX._id === e.target.value);
                setFieldValue((prev) => ({ ...prev, cardSchemeFieldId: e.target.value, cardSchemeField: nameXX.name }));
              }}
              select
              label={<Typography sx={{ color: '#B6B6B6' }}>{selectedPaymentMode?.cardSchemeField?.label}</Typography>}
              size="small"
              type="text"
              fullWidth
              variant="outlined"
            >
              {paymentCardSchemeList?.map((ex) => (
                <MenuItem value={ex._id}>{ex.name}</MenuItem>
              ))}
            </TextField>
          )}
          {/* instrumentField */}
          {selectedPaymentMode?.instrumentField?.status && (
            <TextField
              value={fieldValue.instrumentField}
              onChange={onChangeField}
              label={<Typography sx={{ color: '#B6B6B6' }}>{selectedPaymentMode?.instrumentField?.label}</Typography>}
              name="instrumentField"
              size="small"
              type="number"
              fullWidth
              variant="outlined"
            />
          )}
          {/* nickNameField */}
          {selectedPaymentMode?.nickNameField?.status && (
            <TextField
              value={fieldValue.nickNameField}
              onChange={onChangeField}
              label={
                <Typography sx={{ color: '#B6B6B6' }}>
                  {selectedPaymentMode?.nickNameField?.label}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
              }
              name="nickNameField"
              size="small"
              type="text"
              fullWidth
              variant="outlined"
              error={nickNameFieldError}
              helperText={nickNameFieldError}
            />
          )}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            sx={{ backgroundColor: '#3d71ff', textTransform: 'capitalize', borderRadius: '30px' }}
            variant="contained"
          >
            Create
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
