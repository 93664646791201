import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { collection, doc, getDocs, onSnapshot, setDoc, getDoc, collectionGroup } from 'firebase/firestore';
import ActionButton from './ActionButton';
import { db, auth } from '../../../firebase';
import audio from '../../../assets/audio.mp3';

function CallModal({ rejectCall, startCall }) {
  const { callModal, callFrom } = useSelector((state) => state.call);
  const [audios, setAudios] = useState(new Audio(audio));
  const acceptWithVideo = (video) => {
    const config = { audio: true, video };
    return () => startCall(false, callFrom, config);
  };
  console.log('Call from', callFrom);

  // useEffect(async () => {
  //   const callProfile = await getDoc(doc(db, 'users', callFrom));
  //   console.log('callProfile', callProfile);
  // });
  const [prof, setProf] = useState(null);
  const [name, setName] = useState('');

  const handleFunction = async () => {
    try {
      const user = await getDoc(doc(db, 'users', callFrom));
        setProf(user?.data()?.photoURL);
        setName(user?.data()?.displayName);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  useEffect(() => {
    handleFunction();
  });


  useEffect(() => {
    console.log('callModal', callModal)
    if(callModal === 'active'){
      audios.play();
    } else {
      audios.pause();
      audios.currentTime = 0;
    }
  }, [callModal])

  // console.log(prof);

  return (
    // <div style={{ backgroundColor: 'white', borderRadius: 10, boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
    <div className={classnames('call-modal', callModal)}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={prof} alt="" style={{ height: 100, width: 100, borderRadius: '50%' }} />
      </div>{' '}
      <p className="caller"> {`${name}`}</p>
      <p className="caller" style={{ fontSize: 15 }}>
        {' '}
        is calling ...
      </p>
      <ActionButton
        style={{ border: 'none', height: 28, width: 28 }}
        icon={'wpf:video-call'}
        onClick={acceptWithVideo(true)}
      />
      <ActionButton
        id="call_only"
        style={{ border: 'none', height: 28, width: 28 }}
        icon={'material-symbols:call'}
        onClick={acceptWithVideo(false)}
      />
      <ActionButton
        style={{ border: 'none', height: 30, width: 30 }}
        className="hangup"
        icon={'mdi:phone-hangup'}
        onClick={rejectCall}
      />
    </div>
    // </div>
  );
}

CallModal.propTypes = {
  rejectCall: PropTypes.func.isRequired,
  startCall: PropTypes.func.isRequired,
};

export default CallModal;