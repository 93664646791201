import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { createPrice, updatePrice } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  planId: '',
  currency: '',
  unit_amount: '',
  interval_count: 1,
  interval: '', // month, day, week, year
  status: true,
};

export default function AddRSSLinkModal({ open, plans, data, onClose }) {
  const [fieldValue, setFieldValue] = useState(initFieldValue);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    onClose();
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const res = await createPrice(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onUpdate = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        status: fieldValue.status,
        _id: data._id,
      };
      const res = await updatePrice(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (data === null) return;
    setFieldValue({
      planId: data?.productId?._id,
      currency: data?.currency,
      unit_amount: data?.unit_amount,
      interval_count: data?.interval_count,
      interval: data?.interval, // month, day, week, year
      status: true,
    });
  }, [data]);

  const disabled = data === null ? isSubmitting : true;

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data ? 'Update Price' : 'Add Price'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* plans */}
          <TextField
            value={fieldValue.planId}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, planId: e.target.value }))}
            required
            select
            label="Plan"
            size="small"
            fullWidth
            variant="outlined"
            disabled={disabled}
          >
            {plans?.map((e, index) => (
              <MenuItem key={index} value={e._id}>{`Tier ${e.tier} : ${e.name}`}</MenuItem>
            ))}
          </TextField>
          {/* currency */}
          <TextField
            value={fieldValue.currency}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, currency: e.target.value }))}
            required
            select
            label="Currency"
            size="small"
            fullWidth
            variant="outlined"
            disabled={disabled}
          >
            <MenuItem value="usd">USD</MenuItem>
            <MenuItem value="inr">INR</MenuItem>
          </TextField>
          {/* unit_amount */}
          <TextField
            value={fieldValue.unit_amount}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, unit_amount: e.target.value }))}
            required
            label="Amount in cent"
            name="unit_amount"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            disabled={disabled}
            // error={error}
            // helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
          />
          {/* interval */}
          <TextField
            value={fieldValue.interval}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, interval: e.target.value }))}
            required
            select
            label="Interval"
            size="small"
            fullWidth
            variant="outlined"
            disabled={disabled}
          >
            {/* <MenuItem value="day">Day</MenuItem> */}
            {/* <MenuItem value="week">Week</MenuItem> */}
            <MenuItem value="month">Month</MenuItem>
            <MenuItem value="year">Year</MenuItem>
          </TextField>
          {/* interval_count */}
          {/* <TextField
            value={fieldValue.interval_count}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, interval_count: e.target.value }))}
            required
            label="Interval Count"
            name="interval_count"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            disabled={disabled}
            // error={error}
            // helperText={error ? 'Please enter a valid card name(min 3 char)' : ''}
          /> */}
          {/* status */}
          <TextField
            value={fieldValue.status === true ? 'active' : 'inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'active' }))}
            required
            select
            label="Status"
            size="small"
            fullWidth
            variant="outlined"
            disabled={isSubmitting}
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">Inactive</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isSubmitting}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          {data ? (
            <Button
              disabled={isSubmitting}
              onClick={onUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              Update
            </Button>
          ) : (
            <Button
              disabled={isSubmitting}
              onClick={onSubmit}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              Create
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
