/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-else-return */
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import MinimizeIcon from '@mui/icons-material/Minimize';

import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './AgoraUI.css';
import DisplayAvatar from '../Chat/DisplayAvatar';
import { setAgoraMiniState, setAgoraMiniTimeState } from '../../store/AgoraMiniSlice';

const CallAcceptPopUp = ({ onCallRejectEnd, agoraState, currentFirebaseUser, onMuteAndUnmute }) => {
  const [displayName, setDisplayName] = useState('');
  const [imageFetchUID, setImageFetchUID] = useState(undefined);
  const [isMuted, setIsMuted] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    try {
      if (!currentFirebaseUser) return;
      if (!agoraState) return;

      let fetchId = '';
      if (agoraState.type === 'support' && currentFirebaseUser.uid === agoraState.currentUserId) {
        setDisplayName('support');
        return;
      } else if (agoraState.type === 'support' && currentFirebaseUser.uid !== agoraState.currentUserId) {
        fetchId = agoraState.currentUserId;
      } else {
        fetchId =
          currentFirebaseUser.uid === agoraState.otherUserId ? agoraState.currentUserId : agoraState.otherUserId;
      }
      setImageFetchUID(fetchId);
      const callDocRef = doc(db, 'users', fetchId);
      getDoc(callDocRef).then((snap) => {
        if (snap.exists()) setDisplayName(snap.data().displayName);
      });
    } catch (err) {
      setDisplayName('unknown user');
    }
  }, [agoraState, currentFirebaseUser]);

  const handleMuteAndUnmute = async () => {
    await onMuteAndUnmute(!isMuted);
    setIsMuted(!isMuted);
  };

  const onMinimizeClick = () => {
    dispatch(
      setAgoraMiniState({
        open: true,
        displayName: displayName,
      })
    );
  };

  return (
    <div className="CallPopUp">
      <Tooltip title="Minimize">
        <IconButton
          onClick={onMinimizeClick}
          size="small"
          sx={{
            position: 'absolute',
            top: '6px',
            right: '6px',
            backgroundColor: '#3d71ff',
            color: 'white',
            ':hover': { backgroundColor: '#3d71ff', color: 'white' },
          }}
        >
          <MinimizeIcon color="white" />
        </IconButton>
      </Tooltip>
      <div style={{ marginBottom: '10px' }}>
        <DisplayAvatar
          uid={imageFetchUID}
          isSupport={agoraState.type === 'support' && currentFirebaseUser?.uid === agoraState.currentUserId}
        />
      </div>
      <span>Audio call from</span>
      <span>{displayName}</span>
      <div className="CallPopUp-btn-div">
        {agoraState && agoraState.isCall && <CallTimeUpdate agoraState={agoraState} />}
        <button className="Call-Accept" onClick={handleMuteAndUnmute}>
          {isMuted ? 'Unmute' : 'Mute'}
        </button>
        <button className="Call-Reject" onClick={onCallRejectEnd}>
          End
        </button>
      </div>
    </div>
  );
};

export default CallAcceptPopUp;

export const CallTimeUpdate = () => {
  // const { user: profile } = useSelector((state) => state.login);
  const [nowTime, setNowTime] = useState('');
  const dispatch = useDispatch();
  const timeRef = useRef(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      const startAtMoment = moment(timeRef.current);
      const endAtMoment = moment(new Date());

      const durations = moment.duration(endAtMoment.diff(startAtMoment, 'seconds'), 'seconds');
      const totalDurations = moment.utc(durations.asMilliseconds()).format('HH:mm:ss');
      setNowTime(totalDurations);
      // console.log('CallTimeUpdate useEffect');
      // const interval = setInterval(() => {
      //   console.log('CallTimeUpdate setInterval Start');
      //   const startAtMoment =
      //     profile.country === 'India'
      //       ? moment(agoraState.startAt?.toDate()).tz('Asia/Kolkata')
      //       : moment(agoraState.startAt?.toDate()).tz('America/New_York');
      //   const endAtMoment =
      //     profile.country === 'India' ? moment(new Date()).tz('Asia/Kolkata') : moment(new Date()).tz('America/New_York');

      //   const durations = moment.duration(endAtMoment.diff(startAtMoment, 'seconds'), 'seconds');
      //   const totalDurations = moment.utc(durations.asMilliseconds()).format('HH:mm:ss');
      //   console.log('CallTimeUpdate totalDurations', totalDurations);
      //   setNowTime(totalDurations);
    }, 1000);

    return () => {
      clearInterval(interval);
      setNowTime('');
    };
  }, []);

  useEffect(() => {
    dispatch(setAgoraMiniTimeState(nowTime));
  }, [nowTime]);

  return <div className="Time">{nowTime}</div>;
};
