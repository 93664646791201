import { TextField, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';

import './SafalWarrantyType.css';

import { getWarrantyType, deleteWarrantyType } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import TableView from './TableView';
import AddWarrantyTypeModal from './AddWarrantyTypeModal';
import Inlineloader from '../../../components/Loader/InlineLoader';
import useDebounce from '../../../hooks/useDebounce';
import FilterMenu from './FilterMenu';
import DownloadMenuBusiness from './DownloadMenuBusiness';

const SafalWarrantyType = () => {
  const [modalOpen, setModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [rawData, setRawData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    orderBy: 'createdAt',
    order: 'desc',
    search: '',
    status: '',
  });

  const fetchData = async (filterObj, flag) => {
    try {
      !flag && setLoading(true);
      const res = await getWarrantyType(filterObj);
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      !flag && setLoading(false);
    }
  };

  const onDelete = async (data) => {
    const result = await Swal.fire({
      text: 'Are you sure you want to delete this?',
      showCancelButton: true,
      confirmButtonColor: '#19c25f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      appendTo: 'body',
      customClass: {
        container: 'my-swal', // Add a custom class for styling
      },
      onBeforeOpen: () => {
        // Set a higher zIndex for the Swal modal
        document.querySelector('.my-swal').style.zIndex = 999999;
      },
    });
    if (!result.isConfirmed) {
      return;
    }

    try {
      const res = await deleteWarrantyType({ id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onModalOpenEdit = (data) => {
    setModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onModalOpenDisplay = (data) => {
    setModalOpen({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onModalOpenNew = () => {
    setModalOpen({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onModalClose = () => {
    setModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData(filter, true);
  };

  const onSort = (sortObj) => {
    setFilter((prev) => ({ ...prev, orderBy: sortObj.orderBy, order: sortObj?.order ?? '' }));
    // fetchData({ ...filter, orderby: orderBy, order: sortObj?.order ?? '' });
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter]);

  useDebounce(
    () => {
      setFilter((prev) => ({ ...prev, search: searchValue }));
    },
    [searchValue],
    1500
  );

  const onFilterReset = () => {
    setFilter((prev) => ({ ...prev, status: '' }));
  };
  const onFilterApply = (newFilterState) => {
    if (newFilterState?.status !== '') setFilter((prev) => ({ ...prev, status: newFilterState.status }));
  };

  return (
    <>
      <Helmet>
        <title>SafalWarranty - Warranty Type</title>
      </Helmet>
      {loading && <Inlineloader />}
      <div id="SafalWarrantyType">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Warranty Type
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <TextField
              sx={{ width: '200px', ml: '12px' }}
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              type="search"
              id="search-input"
              size="small"
              label="Search"
              variant="outlined"
            />
          </div>
          <div className="title-action-div">
            <DownloadMenuBusiness data={rawData} />
            <FilterMenu filterState={filter} onFilterReset={onFilterReset} onFilterApply={onFilterApply} />
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onModalOpenNew} />
          </div>
        </div>
        <TableView
          onDelete={onDelete}
          onEdit={onModalOpenEdit}
          onDisplay={onModalOpenDisplay}
          data={rawData}
          onSort={onSort}
        />
      </div>
      <AddWarrantyTypeModal
        data={modalOpen?.data}
        isDisplay={modalOpen?.isDisplay}
        onClose={onModalClose}
        open={modalOpen?.open}
      />
    </>
  );
};

export default SafalWarrantyType;
