import { Button, IconButton, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import { useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import toast from 'react-hot-toast';

import { safalAiSendGpt } from '../../services/Service';

const InputBoxGPT = ({ speechRecognition, listening, fetchChatList, transcript, resetTranscript }) => {
  const [textValue, setTextValue] = useState('');
  const [micStartFLag, setMicStartFLag] = useState(false);

  const onReset = () => {
    setTextValue('');
    resetTranscript();
  };

  const onSendValue = async (e) => {
    e.preventDefault();
    onSendValueAPI(textValue);
  };

  const onSendValueAPI = async (value) => {
    try {
      const res = await safalAiSendGpt({ text: value });
      fetchChatList();
      onReset();
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onMicStart = async () => {
    await speechRecognition.startListening();
    setMicStartFLag(true);
  };

  useEffect(() => {
    if (micStartFLag === true && listening === false) {
      onSendValueAPI(transcript);
    }
  }, [micStartFLag, listening]);

  return (
    <form onSubmit={onSendValue} className="user-input">
      <input
        className="ai-user-input"
        type="text"
        value={listening ? transcript : textValue}
        // value={textValue}
        placeholder="Write your message ..."
        onChange={(e) => {
          e.preventDefault();
          setTextValue(e.target.value);
        }}
      />
      <Tooltip title="Microphone">
        <IconButton
          onClick={listening ? speechRecognition.stopListening : onMicStart}
          className={`mic-btn ${listening && 'on'}`}
        >
          <MicIcon sx={{ color: 'white' }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Send">
        <IconButton type="submit" className="send-btn">
          <SendIcon sx={{ color: 'white' }} />
        </IconButton>
      </Tooltip>
    </form>
  );
};
export default InputBoxGPT;
