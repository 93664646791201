/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select';
import {
  Autocomplete,
  Checkbox,
  Fab,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import toast from 'react-hot-toast';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space } from 'antd';

import { createWarrantyUser, updateWarrantyUser } from '../../../services/Service';
// import AddCustomAssetModal from './AddCustomAssetModal';
// import TopTitleAction from './TopTitleAction';
// import DeleteConfirmationModal from './DeleteConfirmationModal';
import cardBack from '../../../assets/cardBack.png';
import cardFront from '../../../assets/cardFront.png';
import CustomSelect from '../../../components/UI/CustomSelect';
import { changeAddSubscriptionAnimation } from '../../../store/ToggleSlice';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';
import AddPaymentInstrumentModal from '../../../components/Modals/AddPaymentInstrumentModal';
import CustomSelectWithImage from '../../../components/UI/CustomSelectWithImage';
import CustomRating from '../../../components/UI/CustomRating';
import CustomSimpleSelect from '../../../components/UI/CustomSimpleSelect';
import DateField from '../../../components/UI/DateField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '95vh',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  '@media (max-width: 640px)': {
    width: '95vw',
    height: '95vh',
    overflow: 'auto',
  },
};

const calculateExpireDate = (warrantyDetailsStartDate, warrantyDetailsDuration, warrantyDetailsInterval) => {
  // Validate input
  if (!warrantyDetailsStartDate || !moment(warrantyDetailsStartDate).isValid()) {
    return null;
  }

  if (typeof warrantyDetailsDuration !== 'number' || warrantyDetailsDuration <= 0) {
    return null;
  }

  const validIntervals = ['day', 'week', 'month', 'year'];
  if (!validIntervals.includes(warrantyDetailsInterval)) {
    return null;
  }

  // Calculate the expiry date
  const startDate = moment(warrantyDetailsStartDate);
  const expireDate = startDate.add(warrantyDetailsDuration, warrantyDetailsInterval);

  return expireDate.toISOString();
};

const validationField = (value, option) => {
  const min = option?.min ?? 3;
  const max = option?.max ?? 5000;

  // Check if the value is null, undefined, or an empty string
  if (value === null || value === undefined || value === '') {
    return false;
  }
  // Check for strings
  if (typeof value === 'string') {
    const length = value.trim().length;
    return length >= min && length <= max;
  }
  // Check for numbers
  if (typeof value === 'number') {
    return value >= min && value <= max;
  }
  // Check for dates using Moment.js
  if (moment.isMoment(value) || moment(value, moment.ISO_8601, true).isValid()) {
    return true; // Date is valid
  }
  // If the value doesn't match any valid type
  return false;
};

const resetFieldValue = {
  productName: '',
  productCategoryId: '',
  productCategoryName: '',
  productSubCategoryId: '',
  productSubCategoryName: '',
  productModal: '',
  productSerialNo: '',
  purchaseDate: '',
  purchasePrice: '',
  purchaseStoreRetailerId: '',
  purchaseStoreRetailerName: '',
  purchaseLocationCountry: '',
  purchaseLocationState: '',
  purchaseLocationCity: '',
  purchaseInvoice: '',
  warrantyDetailsStartDate: '',
  warrantyDetailsDuration: 1,
  warrantyDetailsInterval: 'year', // (day, week, month, year)
  warrantyDetailsExpireDate: '',
  warrantyDetailsWarrantyTypeId: '',
  warrantyDetailsWarrantyTypeName: '',
  warrantyDetailsRenewalOption: '',
  warrantyDetailsWarrantyCertificate: '',
  extendedWarranty: '',
  upgradeOptions: '',
  serviceCenterPhoneNumber: '',
  serviceCenterURL: '',
  serviceCenterAddressLine1: '',
  serviceCenterAddressLine2: '',
  serviceCenterCountry: '',
  serviceCenterState: '',
  claimProcessInformation: '',
  claimProcessURL: '',
};

export default function AddWarrantyModal({
  data,
  open,
  onClose,
  isDisplay,
  productCategoryData,
  productSubCategoryData,
  storeRetailerData,
  warrantyTypeData,
}) {
  const { user } = useSelector((state) => state.login);
  // const dispatch = useDispatch();

  const [fieldValue, setFieldValue] = React.useState({ ...resetFieldValue });
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(1);

  const isNothingChanged = () => {
    return (
      fieldValue.productName === '' ||
      fieldValue.productCategoryName === '' ||
      fieldValue.productCategoryId === '' ||
      fieldValue.productSubCategoryId === '' ||
      fieldValue.productSubCategoryName === '' ||
      fieldValue.productModal === '' ||
      fieldValue.productSerialNo === '' ||
      fieldValue.purchaseDate === '' ||
      fieldValue.purchasePrice === '' ||
      fieldValue.purchaseStoreRetailerId === '' ||
      fieldValue.purchaseStoreRetailerName === '' ||
      fieldValue.purchaseLocationCountry === '' ||
      fieldValue.purchaseLocationState === '' ||
      fieldValue.purchaseLocationCity === '' ||
      fieldValue.purchaseInvoice === '' ||
      fieldValue.warrantyDetailsStartDate === '' ||
      fieldValue.warrantyDetailsDuration === 1 ||
      fieldValue.warrantyDetailsInterval === 'year' || // (day, week, month, year)
      fieldValue.warrantyDetailsExpireDate === '' ||
      fieldValue.warrantyDetailsWarrantyTypeId === '' ||
      fieldValue.warrantyDetailsWarrantyTypeName === '' ||
      fieldValue.warrantyDetailsRenewalOption === '' ||
      fieldValue.warrantyDetailsWarrantyCertificate === '' ||
      fieldValue.extendedWarranty === '' ||
      fieldValue.upgradeOptions === '' ||
      fieldValue.serviceCenterPhoneNumber === '' ||
      fieldValue.serviceCenterURL === '' ||
      fieldValue.serviceCenterAddressLine1 === '' ||
      fieldValue.serviceCenterAddressLine2 === '' ||
      fieldValue.serviceCenterCountry === '' ||
      fieldValue.serviceCenterState === '' ||
      fieldValue.claimProcessInformation === '' ||
      fieldValue.claimProcessURL === ''
    );
  };

  const onCloseThis = (refetchFlag) => {
    setCurrentStep(1);
    setIsDisableAll(false);
    setFieldValue({ ...resetFieldValue });

    onClose(refetchFlag);
  };
  const handleClose = async (refetchFlag) => {
    if (isDisplay) {
      onCloseThis(refetchFlag);
      return;
    }

    if (isNothingChanged()) {
      onCloseThis(refetchFlag);
      return;
    }
    const result = await Swal.fire({
      text: 'Are you sure you want to close this?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      appendTo: 'body',
      customClass: {
        container: 'my-swal', // Add a custom class for styling
      },
      onBeforeOpen: () => {
        // Set a higher zIndex for the Swal modal
        document.querySelector('.my-swal').style.zIndex = 999999;
      },
    });

    if (result.isConfirmed) {
      onCloseThis(refetchFlag);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        productName: data?.productName ?? '',
        productCategoryId: data?.productCategoryId?._id ?? '',
        productCategoryName: data?.productCategoryName ?? '',
        productSubCategoryId: data?.productSubCategoryId?._id ?? '',
        productSubCategoryName: data?.productSubCategoryName ?? '',
        productModal: data?.productModal ?? '',
        productSerialNo: data?.productSerialNo ?? '',
        purchaseDate: data?.purchaseDate ?? '',
        purchasePrice: data?.purchasePrice ?? '',
        purchaseStoreRetailerId: data?.purchaseStoreRetailerId?._id ?? '',
        purchaseStoreRetailerName: data?.purchaseStoreRetailerName ?? '',
        purchaseLocationCountry: data?.purchaseLocationCountry ?? '',
        purchaseLocationState: data?.purchaseLocationState ?? '',
        purchaseLocationCity: data?.purchaseLocationCity ?? '',
        purchaseInvoice: data?.purchaseInvoice ?? '',
        warrantyDetailsStartDate: data?.warrantyDetailsStartDate ?? '',
        warrantyDetailsDuration: data?.warrantyDetailsDuration ?? 1,
        warrantyDetailsInterval: data?.warrantyDetailsInterval ?? 'year', // (day, week, month, year)
        warrantyDetailsExpireDate: data?.warrantyDetailsExpireDate ?? '',
        warrantyDetailsWarrantyTypeId: data?.warrantyDetailsWarrantyTypeId?._id ?? '',
        warrantyDetailsWarrantyTypeName: data?.warrantyDetailsWarrantyTypeName ?? '',
        warrantyDetailsRenewalOption: data?.warrantyDetailsRenewalOption ?? '',
        warrantyDetailsWarrantyCertificate: data?.warrantyDetailsWarrantyCertificate ?? '',
        extendedWarranty: data?.extendedWarranty ?? '',
        upgradeOptions: data?.upgradeOptions ?? '',
        serviceCenterPhoneNumber: data?.serviceCenterPhoneNumber ?? '',
        serviceCenterURL: data?.serviceCenterURL ?? '',
        serviceCenterAddressLine1: data?.serviceCenterAddressLine1 ?? '',
        serviceCenterAddressLine2: data?.serviceCenterAddressLine2 ?? '',
        serviceCenterCountry: data?.serviceCenterCountry ?? '',
        serviceCenterState: data?.serviceCenterState ?? '',
        claimProcessInformation: data?.claimProcessInformation ?? '',
        claimProcessURL: data?.claimProcessURL ?? '',
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  React.useEffect(() => {
    if (open === true && data === null) {
      const expireDate = calculateExpireDate(
        fieldValue.warrantyDetailsStartDate,
        fieldValue.warrantyDetailsDuration,
        fieldValue.warrantyDetailsInterval
      );
      if (expireDate) setFieldValue({ ...fieldValue, warrantyDetailsExpireDate: expireDate });
    }
  }, [
    fieldValue.warrantyDetailsDuration,
    fieldValue.warrantyDetailsInterval,
    fieldValue.warrantyDetailsStartDate,
    open,
    data,
  ]);

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      const payload = {
        ...fieldValue,
        productSubCategoryId:
          fieldValue.productSubCategoryId === 'Others' ? undefined : fieldValue.productSubCategoryId,
        extendedWarranty: fieldValue.extendedWarranty === '' ? undefined : fieldValue.extendedWarranty,
        warrantyDetailsRenewalOption:
          fieldValue.warrantyDetailsRenewalOption === '' ? undefined : fieldValue.warrantyDetailsRenewalOption,
        upgradeOptions: fieldValue.upgradeOptions === '' ? undefined : fieldValue.upgradeOptions,
      };
      // TODO File upload

      const res = await createWarrantyUser(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };
  const onUpdate = async () => {
    setIsDisableAll(true);
    try {
      const payload = {
        ...fieldValue,
        productSubCategoryId:
          fieldValue.productSubCategoryId === 'Others' ? undefined : fieldValue.productSubCategoryId,
        extendedWarranty: fieldValue.extendedWarranty === '' ? undefined : fieldValue.extendedWarranty,
        warrantyDetailsRenewalOption:
          fieldValue.warrantyDetailsRenewalOption === '' ? undefined : fieldValue.warrantyDetailsRenewalOption,
        upgradeOptions: fieldValue.upgradeOptions === '' ? undefined : fieldValue.upgradeOptions,
      };
      // TODO File upload
      const res = await updateWarrantyUser({ ...payload, _id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose(true);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  // const backImageUrl = selectedBackImage
  //   ? URL.createObjectURL(selectedBackImage)
  //   : fieldValue?.backImage?.length > 1
  //   ? fieldValue?.backImage
  //   : cardBack;

  const amountSym = user.country === 'United States' ? '$' : '₹';

  const selectedProductCategory = productCategoryData.find((item) => item?._id === fieldValue?.productCategoryId);
  const _subProductCategory = productSubCategoryData.filter(
    (item) => item?.category?._id === fieldValue?.productCategoryId
  );
  const subProductCategory = [..._subProductCategory, { _id: 'Others', name: 'Others' }];
  const selectedProductSubCategory = subProductCategory.find((item) => item?._id === fieldValue?.productSubCategoryId);
  const selectedStoreRetailer = storeRetailerData.find((item) => item?._id === fieldValue?.purchaseStoreRetailerId);
  const selectedWarrantyType = warrantyTypeData.find((item) => item?._id === fieldValue?.warrantyDetailsWarrantyTypeId);

  return (
    <>
      <Modal sx={{ zIndex: 345 }} open={open} onClose={handleClose}>
        <Box sx={style}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={handleClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h4" gutterBottom sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
              {data === null ? 'Add Warranty' : isDisplay ? 'Warranty' : 'Update Warranty'}
            </Typography>
            {/* {isDisplayModeActive && (
              <TopTitleAction
                data={editData}
                onDelete={onDeleteModalInit}
                isEdit={isDisplayModeActive}
                onEdit={() => setIsDisplayModeActive(false)}
                isChangeLogVisible={onChangeLog !== undefined}
                onChangeLog={() => onChangeLog(editData)}
              />
            )} */}
          </div>
          <div
            style={{ display: 'flex', gap: '32px', marginTop: '16px', alignItems: 'center', justifyContent: 'center' }}
          >
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: currentStep === 1 ? '#3D71FF' : 'transparent',
                borderRadius: '50%',
                border: '1px solid #3D71FF',
              }}
            />
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: currentStep === 2 ? '#3D71FF' : 'transparent',
                borderRadius: '50%',
                border: '1px solid #3D71FF',
              }}
            />
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: currentStep === 3 ? '#3D71FF' : 'transparent',
                borderRadius: '50%',
                border: '1px solid #3D71FF',
              }}
            />
          </div>
          {currentStep === 1 && (
            <div className="sm-flex-direction-column" style={{ display: 'flex', gap: '16px', marginTop: '32px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
                <span style={{ fontSize: '20px', fontWeight: 700, color: '#3D71FF' }}>Product Details</span>
                {/* productName */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.productName}
                  onChange={(e) => setFieldValue({ ...fieldValue, productName: e.target.value })}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Product Name <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    // shrink: true,
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 250,
                  }}
                />
                {/* productCategoryId */}
                <CustomSimpleSelect
                  variant="standard"
                  disabled={isDisableAll}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Product Category <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  value={fieldValue.productCategoryId}
                  onChange={(value) =>
                    setFieldValue({
                      ...fieldValue,
                      productSubCategoryName: '',
                      productSubCategoryId: '',
                      productCategoryName: '',
                      productCategoryId: value || '',
                    })
                  }
                  valueKey="_id"
                  options={productCategoryData?.map((e) => ({
                    label: e?.name,
                    ...e,
                  }))}
                />
                {/* productCategoryName */}
                {selectedProductCategory?.name === 'Others' && (
                  <TextField
                    disabled={isDisableAll}
                    value={fieldValue.productCategoryName}
                    onChange={(e) => setFieldValue({ ...fieldValue, productCategoryName: e.target.value })}
                    label={
                      <Typography sx={{ color: '#B6B6B6' }}>
                        Product Category Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    }
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      style: { color: 'black' },
                      shrink: true,
                    }}
                    // helperText={`${fieldValue.comments.length}/${250}`}
                    inputProps={{
                      maxlength: 100,
                    }}
                  />
                )}
                {/* productSubCategoryId */}
                <CustomSimpleSelect
                  variant="standard"
                  disabled={isDisableAll}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Product Sub-Category <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  value={fieldValue.productSubCategoryId}
                  onChange={(value) =>
                    setFieldValue({ ...fieldValue, productSubCategoryName: '', productSubCategoryId: value || '' })
                  }
                  valueKey="_id"
                  options={subProductCategory?.map((e) => ({
                    label: e?.name,
                    ...e,
                  }))}
                />
                {/* productSubCategoryName */}
                {selectedProductSubCategory?.name === 'Others' && (
                  <TextField
                    disabled={isDisableAll}
                    value={fieldValue.productSubCategoryName}
                    onChange={(e) => setFieldValue({ ...fieldValue, productSubCategoryName: e.target.value })}
                    label={
                      <Typography sx={{ color: '#B6B6B6' }}>
                        Product Sub-Category Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    }
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      style: { color: 'black' },
                      shrink: true,
                    }}
                    // helperText={`${fieldValue.comments.length}/${250}`}
                    inputProps={{
                      maxlength: 100,
                    }}
                  />
                )}
                {/* productModal */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.productModal}
                  onChange={(e) => setFieldValue({ ...fieldValue, productModal: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Product Modal</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    // shrink: true,
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 250,
                  }}
                />
                {/* productSerialNo */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.productSerialNo}
                  onChange={(e) => setFieldValue({ ...fieldValue, productSerialNo: e.target.value })}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Product Serial No <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    // shrink: true,
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 250,
                  }}
                />
                {/* purchaseInvoice - FILE */}
                <TextField
                  disabled={isDisableAll}
                  // value={fieldValue.productSerialNo}
                  // onChange={(e) => setFieldValue({ ...fieldValue, productSerialNo: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Purchase Invoice</Typography>}
                  size="small"
                  type="file"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    shrink: true,
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
                <span style={{ fontSize: '20px', fontWeight: 700, color: '#3D71FF' }}>Purchase Details</span>
                {/* purchaseDate */}
                <DateField
                  disabled={isDisableAll}
                  value={fieldValue.purchaseDate}
                  fullWidth
                  variant="standard"
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Purchase Date <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  onChange={(e) => setFieldValue((prev) => ({ ...prev, purchaseDate: e.toISOString() }))}
                />
                {/* purchasePrice */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.purchasePrice}
                  onChange={(e) => setFieldValue({ ...fieldValue, purchasePrice: e.target.value })}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Purchase Price <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  size="small"
                  type="number"
                  fullWidth
                  variant="standard"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{amountSym}</InputAdornment>,
                  }}
                  InputLabelProps={{
                    style: { color: 'black' },
                    // shrink: true,
                  }}
                />
                {/* purchaseStoreRetailerId */}
                <CustomSimpleSelect
                  variant="standard"
                  disabled={isDisableAll}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Store / Retailer <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  value={fieldValue.purchaseStoreRetailerId}
                  onChange={(value) =>
                    setFieldValue({
                      ...fieldValue,
                      purchaseStoreRetailerName: '',
                      purchaseStoreRetailerId: value || '',
                    })
                  }
                  valueKey="_id"
                  options={storeRetailerData?.map((e) => ({
                    label: e?.name,
                    ...e,
                  }))}
                />
                {/* purchaseStoreRetailerName */}
                {selectedStoreRetailer?.name === 'Others' && (
                  <TextField
                    disabled={isDisableAll}
                    value={fieldValue.purchaseStoreRetailerName}
                    onChange={(e) => setFieldValue({ ...fieldValue, purchaseStoreRetailerName: e.target.value })}
                    label={
                      <Typography sx={{ color: '#B6B6B6' }}>
                        Store / Retailer Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    }
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    // helperText={`${fieldValue.comments.length}/${250}`}
                    inputProps={{
                      maxlength: 100,
                    }}
                  />
                )}
                <span style={{ fontSize: '20px', fontWeight: 700, color: '#3D71FF' }}>Purchase Location</span>
                {/* purchaseLocationCountry */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.purchaseLocationCountry}
                  onChange={(e) => setFieldValue({ ...fieldValue, purchaseLocationCountry: e.target.value })}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Country <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    // shrink: true,
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 250,
                  }}
                  select
                >
                  <MenuItem value="USA">United States</MenuItem>
                  <MenuItem value="IND">India</MenuItem>
                </TextField>
                {/* purchaseLocationState */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.purchaseLocationState}
                  onChange={(e) => setFieldValue({ ...fieldValue, purchaseLocationState: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>State</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
                {/* purchaseLocationCity */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.purchaseLocationCity}
                  onChange={(e) => setFieldValue({ ...fieldValue, purchaseLocationCity: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>City</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div className="sm-flex-direction-column" style={{ display: 'flex', gap: '16px', marginTop: '32px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
                <span style={{ fontSize: '20px', fontWeight: 700, color: '#3D71FF' }}>Warranty Details</span>
                {/* warrantyDetailsStartDate */}
                <DateField
                  disabled={isDisableAll}
                  value={fieldValue.warrantyDetailsStartDate}
                  fullWidth
                  variant="standard"
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Warranty Start Date <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  onChange={(e) => setFieldValue((prev) => ({ ...prev, warrantyDetailsStartDate: e.toISOString() }))}
                />
                {/* warrantyDetailsInterval */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.warrantyDetailsInterval}
                  onChange={(e) => setFieldValue({ ...fieldValue, warrantyDetailsInterval: e.target.value })}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Interval <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    // shrink: true,
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  select
                >
                  <MenuItem value="year">Year</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="day">Day</MenuItem>
                </TextField>
                {/* warrantyDetailsDuration */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.warrantyDetailsDuration}
                  onChange={(e) => setFieldValue({ ...fieldValue, warrantyDetailsDuration: e.target.value })}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Duration <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  size="small"
                  type="number"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    // shrink: true,
                  }}
                />
                {/* warrantyDetailsExpireDate */}
                <DateField
                  disabled={isDisableAll}
                  value={fieldValue.warrantyDetailsExpireDate}
                  fullWidth
                  variant="standard"
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Expiration Date <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  onChange={(e) => setFieldValue((prev) => ({ ...prev, warrantyDetailsExpireDate: e.toISOString() }))}
                />
                {/* warrantyDetailsWarrantyTypeId */}
                <CustomSimpleSelect
                  variant="standard"
                  disabled={isDisableAll}
                  label={
                    <Typography sx={{ color: '#B6B6B6' }}>
                      Warranty Type <span style={{ color: 'red' }}>*</span>
                    </Typography>
                  }
                  value={fieldValue.warrantyDetailsWarrantyTypeId}
                  onChange={(value) =>
                    setFieldValue({
                      ...fieldValue,
                      warrantyDetailsWarrantyTypeName: '',
                      warrantyDetailsWarrantyTypeId: value || '',
                    })
                  }
                  valueKey="_id"
                  options={warrantyTypeData?.map((e) => ({
                    label: e?.name,
                    ...e,
                  }))}
                />
                {/* warrantyDetailsWarrantyTypeName */}
                {selectedWarrantyType?.name === 'External' && (
                  <TextField
                    disabled={isDisableAll}
                    value={fieldValue.warrantyDetailsWarrantyTypeName}
                    onChange={(e) => setFieldValue({ ...fieldValue, warrantyDetailsWarrantyTypeName: e.target.value })}
                    label={
                      <Typography sx={{ color: '#B6B6B6' }}>
                        Warranty Type Name <span style={{ color: 'red' }}>*</span>
                      </Typography>
                    }
                    size="small"
                    type="text"
                    fullWidth
                    variant="standard"
                    InputLabelProps={{
                      style: { color: 'black' },
                    }}
                    // helperText={`${fieldValue.comments.length}/${250}`}
                    inputProps={{
                      maxlength: 100,
                    }}
                  />
                )}
                {/* warrantyDetailsWarrantyCertificate - FILE */}
                <TextField
                  disabled={isDisableAll}
                  // value={fieldValue.productSerialNo}
                  // onChange={(e) => setFieldValue({ ...fieldValue, productSerialNo: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Warranty Certificate</Typography>}
                  size="small"
                  type="file"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    shrink: true,
                  }}
                />
                {/* warrantyDetailsRenewalOption */}
                <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                  <Checkbox
                    disabled={isDisableAll}
                    checked={fieldValue.warrantyDetailsRenewalOption}
                    onChange={(e) =>
                      setFieldValue({
                        ...fieldValue,
                        warrantyDetailsRenewalOption: e.target.checked,
                      })
                    }
                  />
                  <span>Renewal Option</span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
                <span style={{ fontSize: '20px', fontWeight: 700, color: '#3D71FF' }}>Additional Service</span>
                {/* extendedWarranty */}
                <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                  <Checkbox
                    disabled={isDisableAll}
                    checked={fieldValue.extendedWarranty}
                    onChange={(e) =>
                      setFieldValue({
                        ...fieldValue,
                        extendedWarranty: e.target.checked,
                      })
                    }
                  />
                  <span>Extended Warranty</span>
                </div>
                {/* upgradeOptions */}
                <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                  <Checkbox
                    disabled={isDisableAll}
                    checked={fieldValue.upgradeOptions}
                    onChange={(e) =>
                      setFieldValue({
                        ...fieldValue,
                        upgradeOptions: e.target.checked,
                      })
                    }
                  />
                  <span>Upgrade Options</span>
                </div>
                <span style={{ fontSize: '18px', fontWeight: 500, color: '#637381' }}>Service Center Information</span>
                {/* serviceCenterPhoneNumber */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.serviceCenterPhoneNumber}
                  onChange={(e) => setFieldValue({ ...fieldValue, serviceCenterPhoneNumber: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Phone Number</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 10,
                  }}
                />
                {/* serviceCenterURL */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.serviceCenterURL}
                  onChange={(e) => setFieldValue({ ...fieldValue, serviceCenterURL: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>URL</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
                {/* serviceCenterAddressLine1 */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.serviceCenterAddressLine1}
                  onChange={(e) => setFieldValue({ ...fieldValue, serviceCenterAddressLine1: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Address Line 1</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
                {/* serviceCenterAddressLine2 */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.serviceCenterAddressLine2}
                  onChange={(e) => setFieldValue({ ...fieldValue, serviceCenterAddressLine2: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Address Line 2</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
                {/* serviceCenterCountry */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.serviceCenterCountry}
                  onChange={(e) => setFieldValue({ ...fieldValue, serviceCenterCountry: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Country</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                    // shrink: true,
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 250,
                  }}
                  select
                >
                  <MenuItem value="USA">United States</MenuItem>
                  <MenuItem value="IND">India</MenuItem>
                </TextField>
                {/* serviceCenterState */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.serviceCenterState}
                  onChange={(e) => setFieldValue({ ...fieldValue, serviceCenterState: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>State</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div className="sm-flex-direction-column" style={{ display: 'flex', gap: '16px', marginTop: '32px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }}>
                <span style={{ fontSize: '20px', fontWeight: 700, color: '#3D71FF' }}>Claim Procedure Process</span>
                {/* claimProcessInformation */}
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.claimProcessInformation}
                  onChange={(e) => setFieldValue({ ...fieldValue, claimProcessInformation: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>Information</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
                <TextField
                  disabled={isDisableAll}
                  value={fieldValue.claimProcessURL}
                  onChange={(e) => setFieldValue({ ...fieldValue, claimProcessURL: e.target.value })}
                  label={<Typography sx={{ color: '#B6B6B6' }}>URL</Typography>}
                  size="small"
                  type="text"
                  fullWidth
                  variant="standard"
                  InputLabelProps={{
                    style: { color: 'black' },
                  }}
                  // helperText={`${fieldValue.comments.length}/${250}`}
                  inputProps={{
                    maxlength: 100,
                  }}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: '1 1 0%' }} />
            </div>
          )}
          <div style={{ display: 'flex', gap: '12px', marginTop: '22px', justifyContent: 'end' }}>
            <Button
              disabled={isDisplay ? false : isDisableAll}
              sx={{
                backgroundColor: '#ff4842',
                ':hover': { backgroundColor: '#d40700' },
                color: 'white',
                borderRadius: '30px',
                textTransform: 'capitalize',
              }}
              onClick={handleClose}
              variant="contained"
            >
              Cancel
            </Button>
            {currentStep > 1 && (
              <Button
                sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
                onClick={() => setCurrentStep((prev) => prev - 1)}
                variant="contained"
                disabled={isDisplay ? false : isDisableAll}
              >
                Previous
              </Button>
            )}
            {currentStep !== 3 ? (
              <Button
                sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
                onClick={() => setCurrentStep((prev) => prev + 1)}
                variant="contained"
                disabled={isDisplay ? false : isDisableAll}
              >
                Next
              </Button>
            ) : (
              !isDisplay && (
                <Button
                  disabled={isDisableAll}
                  onClick={data === null ? onSubmit : onUpdate}
                  sx={{ backgroundColor: '#3D71FF', borderRadius: '30px', textTransform: 'capitalize' }}
                  variant="contained"
                >
                  {data === null ? 'Add' : 'Save'}
                </Button>
              )
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
