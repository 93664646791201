/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import './AssetModuleBusiness.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TablePagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { deleteAssetModuleCompany } from '../../../services/Service';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const AssetModuleCompany = ({ fetchData, data, onEdit, paginationOption }) => {
  const handleChangePage = async (e, value) => {
    await fetchData(value + 1, paginationOption.limit, paginationOption.type, paginationOption.companyId);
  };
  const handleChangeRowsPerPage = async (e) => {
    await fetchData(paginationOption.page, e.target.value, paginationOption.type, paginationOption.companyId);
  };
  const { formatDateTime } = useUserTimeZone();
  const onDelete = async (row) => {
    try {
      const res = await deleteAssetModuleCompany(row._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        await fetchData();
      }
    } catch (err) {
      console.log('AssetModuleCompany onDelete', err);
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      fetchData(paginationOption.page, paginationOption.limit, paginationOption.type, paginationOption.companyId);
    }
  }, [data, fetchData, paginationOption]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead style={{ backgroundColor: '#eaffea', color: '#007b32' }}>
          <TableRow>
            <TableCell style={{ color: '#007b32' }}>Logo</TableCell>
            <TableCell style={{ color: '#007b32' }}>Name</TableCell>
            <TableCell style={{ color: '#007b32' }}>Type</TableCell>
            <TableCell style={{ color: '#007b32' }}>Website</TableCell>
            <TableCell style={{ color: '#007b32' }}>Country</TableCell>
            <TableCell style={{ color: '#007b32' }}>Description</TableCell>
            <TableCell style={{ color: '#007b32' }}>Created At</TableCell>
            <TableCell style={{ color: '#007b32' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((e, index) => (
            <TableRow key={index}>
              <TableCell>
                <img style={{ maxHeight: '64px' }} src={e?.logo} alt={e?.name} />
              </TableCell>
              <TableCell>{e?.name}</TableCell>
              <TableCell>{e?.type}</TableCell>
              <TableCell>
                <a href={e?.website} target="_blank" rel="noreferrer">
                  {e?.website}
                </a>
              </TableCell>
              <TableCell>{e?.country}</TableCell>
              <TableCell>{e?.description}</TableCell>
              <TableCell>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </TableCell>
              <TableCell>
                <div style={{ display: 'flex' }}>
                  <IconButton onClick={() => onEdit(e)} style={{ marginLeft: '10px' }} aria-label="delete">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => onDelete(e)} style={{ marginLeft: '10px' }} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
        component="div"
        count={paginationOption.total}
        rowsPerPage={paginationOption.limit}
        page={paginationOption.page - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default AssetModuleCompany;
