import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { Image } from 'antd';

import {
  createLeaderBoardMasterCountries,
  uploadLeaderBoardMasterCountries,
  updateLeaderBoardMasterCountries,
} from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  code2: '',
  phoneCode: '',
  code3: '',
  image: '',
};

export default function AddLeaderBoardMasterCountriesModal({ open, onClose, data, isDisplay }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    setSelectedFile(null);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      const fileRes = await uploadLeaderBoardMasterCountries(selectedFile);
      const payload = { ...fieldValue, image: fileRes.data.url };
      const res = await createLeaderBoardMasterCountries(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      const payload = { ...fieldValue, _id: data._id };
      if (selectedFile) {
        const fileRes = await uploadLeaderBoardMasterCountries(selectedFile);
        payload.image = fileRes.data.url;
      }
      const res = await updateLeaderBoardMasterCountries(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        name: data?.name || '',
        code2: data?.code2 || '',
        phoneCode: data?.phoneCode || '',
        code3: data?.code3 || '',
        image: data?.image || '',
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  const logoImageUrl = selectedFile
    ? URL.createObjectURL(selectedFile)
    : fieldValue?.image?.length > 1
    ? fieldValue?.image
    : '';

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null
            ? 'Add SafalLeaderBoard Countries'
            : isDisplay
            ? 'SafalLeaderBoard Countries'
            : 'Update SafalLeaderBoard Countries'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              alignItems: 'flex-end',
            }}
          >
            <div style={{ flex: 'none' }}>
              <Image
                preview={false}
                style={{ objectFit: 'cover', width: '50px', height: '50px', flex: 'none', borderRadius: '8px' }}
                src={logoImageUrl}
                alt="logo"
              />
            </div>
            <TextField
              onChange={(e) => {
                if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
                  toast.error('Please upload image in JPG, JPEG, PNG Format ONLY.');
                } else {
                  setSelectedFile(e.target.files[0]);
                }
              }}
              type="file"
              required
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                accept: 'image/*',
              }}
            />
          </div>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Name"
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* code2 */}
          <TextField
            value={fieldValue.code2}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, code2: e.target.value }))}
            required
            label="IOS Code 2 char"
            name="code2"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* code3 */}
          <TextField
            value={fieldValue.code3}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, code3: e.target.value }))}
            required
            label="ISO Code 3 char"
            name="code3"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* phoneCode */}
          <TextField
            value={fieldValue.phoneCode}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, phoneCode: e.target.value }))}
            required
            label="Calling Phone Code"
            name="phoneCode"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
