import React, { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Grid, Box } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { GooglePlayButton, AppStoreButton } from 'react-mobile-app-button';

// hooks
// import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
// sections
import { LoginForm } from '../../sections/auth/login';
import FaqModal from '../../components/Modals/FaqModal';
// import AuthSocial from '../../sections/auth/AuthSocial';

// ----------------------------------------------------------------------

const IOSUrl = 'https://www.apple.com/in/app-store';
const APKUrl = 'https://play.google.com';

const RootStyle = styled('div')(({ theme }) => ({
  background: `url(${'/assets/images/login-background.png'})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const isLimitedHeight = useMediaQuery({
    query: '(max-height: 600px)',
  });

  return (
    <Page title="Login">
      <RootStyle>
        <Container>
          <Box display="inline-block" sx={{ width: '100%', height: '100vh' }} py={4}>
            <Grid container height={'100%'}>
              <Grid item display={{ xs: 'none', md: 'block' }} xs={0} md={7} height={'100%'}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    width: '100%',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 50px 0px #C1C8F6',
                    border: '2px solid #3D71FF',
                    zIndex: 1,
                  }}
                >
                  <Box>
                    <img src="/assets/images/Logo.png" alt="login" width="30%" style={{ objectFit: 'contain' }} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexFlow: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                    }}
                  >
                    <Typography variant="h2" align="center" sx={{ fontSize: '38px', fontWeight: 300 }} mb={2}>
                      Welcome to <span style={{ color: '#3D71FF', fontWeight: 700 }}>SafalSubscriptions</span>
                    </Typography>

                    <Typography
                      variant="h2"
                      sx={{ fontSize: '16px ', fontWeight: 300, color: '#1D1D1F', textAlign: 'center' }}
                      mb={2}
                    >
                      Home to track Subscriptions, Provide Safals and Chat with SafalSubscriptions Users.
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img
                        className="login_safal_img"
                        src="/assets/images/login-image.png"
                        alt="login"
                        width="85%"
                        height="400px"
                        style={{ objectFit: 'contain' }}
                      />
                    </Box>
                    <div
                      style={{
                        position: 'absolute',
                        display: 'flex',
                        marginTop: '12px',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '6px',
                        flexWrap: 'wrap',
                        bottom: '5%',
                        backgroundColor: 'white',
                      }}
                    >
                      <AppStoreButton
                        className="react-mobile-app-button-style"
                        height={60}
                        width={150}
                        url={IOSUrl}
                        theme={'light'}
                      />
                      <GooglePlayButton
                        className="react-mobile-app-button-style"
                        height={60}
                        width={150}
                        url={APKUrl}
                        theme={'light'}
                      />
                    </div>
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={5} sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                <Card
                  sx={{
                    height: isLimitedHeight ? '100%' : 'calc(100% - 22%)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '50px',
                    width: '100%',
                    borderRadius: '0px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    boxShadow: 'none',
                    pl: '80px',
                    '@media (max-width: 640px)': {
                      padding: '32px',
                    },
                  }}
                >
                  <Box
                    sx={{
                      '@media (min-width: 640px)': {
                        display: 'none !important',
                      },
                    }}
                  >
                    <img src="/assets/images/Logo.png" alt="login" width="50%" style={{ objectFit: 'contain' }} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexFlow: 'column',
                      // alignItems: 'center',
                      justifyContent: 'center',
                      // height: '100%',
                      // height: "calc(100% - 50%)",
                      width: '100%',
                    }}
                  >
                    <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 300, alignItems: 'center' }} mb={1}>
                      <span style={{ color: '#3D71FF', fontWeight: 700 }}>Sign in</span> to SafalSubscriptions
                    </Typography>

                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '15px',
                        fontWeight: 300,
                        color: '#1D1D1F',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      mb={2}
                    >
                      Enter your details below. <FaqModal marginLeft="4px" color="blue" module="Login" />
                    </Typography>
                  </Box>
                  <LoginForm />
                  <Typography variant="body2" sx={{ mt: 3 }}>
                    Don’t have an account?{' '}
                    <Link variant="subtitle2" underline="hover" component={RouterLink} to="/register">
                      Get started
                    </Link>
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
