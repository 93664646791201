import { useSelector } from 'react-redux';
// material
import { IconButton, Avatar, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// components

// ----------------------------------------------------------------------

const COUNTRIES_FLAG = [
  {
    label: 'India',
    icon: '/static/icons/Flag_of_India.svg',
  },
  {
    label: 'United-States',
    icon: '/static/icons/ic_flag_en.svg',
  },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const { user } = useSelector((state) => state.login);
  const navigate = useNavigate();

  const openSafalBuddy = () => {
    navigate('/safalbuddy');
  };

  return user.role === 'business' ? (
    <>
      <IconButton
        sx={{
          padding: 0,
          width: 25,
          height: 25,
        }}
      >
        <Tooltip title={`Country`}>
          <Avatar
            src={user.country === 'India' ? COUNTRIES_FLAG[0].icon : COUNTRIES_FLAG[1].icon}
            alt={user.country === 'India' ? COUNTRIES_FLAG[0].label : COUNTRIES_FLAG[1].label}
            sx={{ width: 25, height: 25 }}
          />
        </Tooltip>
      </IconButton>
    </>
  ) : (
    <>
      <IconButton
        sx={{
          padding: 0,
          width: 25,
          height: 25,
        }}
        onClick={openSafalBuddy}
      >
        <Tooltip title={`SafalBuddy`}>
          <Avatar src="/assets/images/buddy/newBuddyIcon.png" alt="SafalBuddy" sx={{ width: 25, height: 25 }} />
        </Tooltip>
      </IconButton>
    </>
  );
}
