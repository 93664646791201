import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function SafalGiftOrderPlaceSuccessModal({ open, onClose, data }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal open={open}>
        <Box sx={style}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#3D71FF' }}
          >
            SafalGift Order Confirmation
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Order id : {data?.orderId}</span>
            {data?.cartSnapShort?.map((e, index) => (
              <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'column' }}>
                <span style={{ color: 'grey', fontStyle: 'italic' }}>Order Item {index + 1}</span>
                <span>{e?.name}</span>
                <div style={{ fontSize: '14px', display: 'flex', justifyContent: 'space-between' }}>
                  <span>
                    {e?.qty} qty x {e?.points} Spts
                  </span>
                  <span>{e?.qty * e?.points} Spts</span>
                </div>
              </div>
            ))}
            <span>Total Points : {data?.totalPoints} Spts</span>
          </div>
          <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
            <Button
              onClick={handleClose}
              sx={{
                textTransform: 'capitalize',
                backgroundColor: '#ff4842',
                ':hover': { backgroundColor: '#d40700' },
              }}
              variant="contained"
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
