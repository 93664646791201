/* eslint-disable no-else-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-plusplus */

import {
  Box,
  Button,
  Fab,
  IconButton,
  MenuItem,
  Modal,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import DownloadIcon from '@mui/icons-material/Download';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import './EmailCrawlerModal.css';
import DateField from '../UI/DateField';
import EmailTableModal from './EmailTableModal';
import { addEmailCrawlerProcess, getEmailCrawlerProcess } from '../../services/Service';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  maxHeight: '95vh',
  minHeight: '200px',
  overflow: 'auto',
  flexDirection: 'column',
  gap: '12px',
  p: 6,
  pt: 3,
  '@media (max-width: 640px)': {
    width: '95vw',
    height: '95vh',
    overflow: 'auto',
  },
};

const EmailCrawlerModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [crawlerProcessList, setCrawlerProcessList] = useState([]);
  const [isEmailTableModalOpen, setIsEmailTableModalOpen] = useState({
    open: false,
    data: null,
  });
  const processFetchRef = useRef(null);
  const { formatDate } = useUserTimeZone();

  const [fieldValue, setFieldValue] = useState({
    email: '',
    password: '',
    startDate: '',
    endDate: '',
    host: '', // only pass when email not in Gmail, Outlook, Yahoo, AOL, Apple Mail
    port: '', // only pass when email not in Gmail, Outlook, Yahoo, AOL, Apple Mail
  });
  const [emailMode, setEmailMode] = useState('');

  const onModalEmailOpen = async () => {
    setIsEmailTableModalOpen({
      open: true,
      data: null,
    });
  };
  const onModalEmailClose = async () => {
    setIsEmailTableModalOpen({
      open: false,
      data: null,
    });
  };

  const onModalOpen = async () => {
    setIsModalOpen(true);
  };
  const onModalClose = async () => {
    setIsModalOpen(false);
    setCrawlerProcessList([]);
    setFieldValue({
      email: '',
      password: '',
      startDate: '',
      endDate: '',
      host: '',
      port: '',
    });
    setEmailMode('');
    setEmailError('');
    setPasswordError('');
  };

  const fetchEmailCrawlerProcess = async () => {
    try {
      const res = await getEmailCrawlerProcess();
      setCrawlerProcessList(res.data.data);
    } catch (err) {
      console.log('fetchEmailCrawlerProcess', err.message);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchEmailCrawlerProcess();
      processFetchRef.current = setInterval(() => {
        fetchEmailCrawlerProcess();
      }, 10 * 1000);
    } else {
      if (processFetchRef.current) clearInterval(processFetchRef.current);
    }
  }, [isModalOpen]);

  const onSubmit = async () => {
    try {
      if (!validateEmail(fieldValue?.email)) {
        setEmailError('Please enter valid email');
        return;
      } else {
        setEmailError('');
      }

      if (!fieldValue?.password) {
        setPasswordError('Please enter valid password');
        return;
      } else {
        setPasswordError('');
      }

      const res = await addEmailCrawlerProcess(fieldValue);
      if (res.data.success) {
        fetchEmailCrawlerProcess();
        setFieldValue({
          email: '',
          password: '',
          startDate: '',
          endDate: '',
          host: '',
          port: '',
        });
        setEmailMode('');
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  //   const isEmailHostHave = validateEmail(fieldValue?.email) && !emailHostFinder(fieldValue?.email);
  //   console.log('DEBUG isEmailHostHave', isEmailHostHave);

  const isSubmitDisabled = !fieldValue?.email || !fieldValue?.password || !emailMode;

  return (
    <>
      <Tooltip title="Email Crawler">
        <IconButton
          sx={{
            mr: 2,
            height: '40px',
            flex: 'none',
            color: '#FFFFFF',
            backgroundColor: '#3D71FF',
            '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
          }}
          onClick={onModalOpen}
        >
          <AlternateEmailIcon />
        </IconButton>
      </Tooltip>
      <Modal onClose={onModalClose} open={isModalOpen}>
        <Box sx={{ ...modalStyle, width: crawlerProcessList.length > 0 ? '1100px' : '600px' }}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
            }}
            onClick={onModalClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          <div id="EmailCrawlerModal" style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <div>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '30px',
                  fontWeight: 700,
                  color: '#3D71FF',
                }}
              >
                Email Crawler
              </Typography>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis placeat ex, eveniet accusantium
                illum minima illo nisi necessitatibus sit aperiam magni, blanditiis eos nihil, aspernatur rerum quos ea!
                Perspiciatis, vero.
              </p>
            </div>
            <div style={{ display: 'flex', gap: '12px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', flex: 1 }}>
                <TextField
                  // disabled={isThisOtherLabel ? true : isDisplay}
                  value={emailMode}
                  onChange={(e) => setEmailMode(e.target.value)}
                  select
                  label="Select email service provider"
                  size="small"
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value={'gmail'}>Gmail</MenuItem>
                  <MenuItem value={'outlook'}>Outlook</MenuItem>
                  <MenuItem value={'yahoo'}>Yahoo</MenuItem>
                  <MenuItem value={'custom'}>Custom</MenuItem>
                </TextField>
                <TextField
                  value={fieldValue.email}
                  onChange={(e) => setFieldValue((prev) => ({ ...prev, email: e.target.value }))}
                  label="Email Address"
                  // disabled={isThisOtherLabel ? true : isDisplay}
                  name="name"
                  size="small"
                  type="text"
                  fullWidth
                  variant="outlined"
                  error={emailError}
                  helperText={emailError}
                  inputProps={{
                    minLength: 5,
                    // maxLength: 50,
                  }}
                />
                <TextField
                  value={fieldValue.password}
                  onChange={(e) => setFieldValue((prev) => ({ ...prev, password: e.target.value }))}
                  label="Email Password"
                  // disabled={isThisOtherLabel ? true : isDisplay}
                  name="name"
                  size="small"
                  type="password"
                  fullWidth
                  error={passwordError}
                  helperText={passwordError}
                  variant="outlined"
                  //   inputProps={{
                  //     minLength: 3,
                  //     maxLength: 50,
                  //   }}
                />
                {emailMode === 'custom' && (
                  <>
                    <TextField
                      value={fieldValue.host}
                      onChange={(e) => setFieldValue((prev) => ({ ...prev, host: e.target.value }))}
                      label="Host"
                      // disabled={isThisOtherLabel ? true : isDisplay}
                      name="name"
                      size="small"
                      type="text"
                      fullWidth
                      variant="outlined"
                      // error={error}
                      // helperText={error ? 'Please enter a valid name(min 3 char, max 50 char)' : ''}
                      //   inputProps={{
                      //     minLength: 1,
                      //     maxLength: 50,
                      //   }}
                    />
                    <TextField
                      value={fieldValue.port}
                      onChange={(e) => setFieldValue((prev) => ({ ...prev, port: e.target.value }))}
                      label="Port"
                      // disabled={isThisOtherLabel ? true : isDisplay}
                      name="name"
                      size="small"
                      type="text"
                      fullWidth
                      variant="outlined"
                      // error={error}
                      // helperText={error ? 'Please enter a valid name(min 3 char, max 50 char)' : ''}
                      //   inputProps={{
                      //     minLength: 3,
                      //     maxLength: 50,
                      //   }}
                    />
                  </>
                )}
                <div style={{ display: 'flex', gap: '8px' }}>
                  <DateField
                    value={fieldValue.startDate}
                    fullWidth
                    variant="outlined"
                    label="From"
                    disableFuture
                    onChange={(e) => setFieldValue((prev) => ({ ...prev, startDate: e.toISOString() }))}
                  />
                  <DateField
                    value={fieldValue.endDate}
                    fullWidth
                    variant="outlined"
                    label="To"
                    disableFuture
                    onChange={(e) => setFieldValue((prev) => ({ ...prev, endDate: e.toISOString() }))}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    disabled={isSubmitDisabled}
                    onClick={onSubmit}
                    sx={{ backgroundColor: '#3d71ff', textTransform: 'capitalize', borderRadius: '30px' }}
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
              </div>
              {crawlerProcessList.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    flex: 1,
                    height: '250px',
                    overflow: 'auto',
                  }}
                >
                  {crawlerProcessList.map((item, index) => (
                    <EmailBoxUI
                      key={index}
                      onClick={() => onModalEmailOpen(item, index)}
                      date={`${formatDate(item?.createdAt)} ${getTimeZoneAbbreviation()}`}
                      email={item?.email}
                      status={item?.status}
                      matchCount={item?.matchCount}
                      errorMessage={item?.error}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      <EmailTableModal
        open={isEmailTableModalOpen.open}
        data={isEmailTableModalOpen.data}
        onClose={onModalEmailClose}
      />
    </>
  );
};
export default EmailCrawlerModal;

const EmailBoxUI = ({ email, date, status, onClick, matchCount, errorMessage }) => {
  return (
    <Tooltip title="Click to Open">
      <div
        onClick={onClick}
        id="EmailBoxUI"
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          border: '1px solid #3D71FF',
          lineHeight: 'normal',
          padding: '4px 12px',
          borderRadius: '8px',
          flex: 'none',
          cursor: 'pointer',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{email}</span>
          {status === 'Completed' && <span style={{ fontSize: '14px' }}>{matchCount} Match Found</span>}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span style={{ fontSize: '12px' }}>{date}</span>
          <span
            style={{
              fontSize: '14px',
              color: status === 'Completed' ? '#54d62c' : status === 'Error' ? '#DF3E30' : '#3D71FF',
              fontWeight: 'bold',
            }}
          >
            {status}
          </span>
        </div>
        {status === 'Error' && (
          <span style={{ fontSize: '14px', color: '#DF3E30', textAlign: 'end' }}>{errorMessage}</span>
        )}
      </div>
    </Tooltip>
  );
};

function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const emailHostFinder = (email) => {
  const host = ['@gmail.com', '@outlook.com'];
  if (!validateEmail(email)) return false;
  for (let i = 0; i < host?.length; i++) {
    const ho = host[i];
    if (email?.toLowerCase().includes(ho)) {
      return true;
    }
  }
  return false;
};
// #54d62c
