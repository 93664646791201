import { Checkbox, TablePagination, TableCell } from '@mui/material';
import { useSelector } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import ThreeDotMenu from './ThreeDotMenu';
import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import defaultCard from '../../../assets/defaultCard.png';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({
  data,
  paginationData,
  handleChangePage,
  handleChangeRowsPerPage,
  onEdit,
  onDelete,
  onSelectedChange,
  selectedAccount,
  selectedToggle,
  onChangeLog,
}) => {
  const { user } = useSelector((state) => state.login);
  const { handleSort, newSortData, sortState } = useSortHook(data, ['expiryDate']);
  const { expiryCheck, formatDate } = useUserTimeZone();
  const handleDoubleClick = (event, data) => {
    if (event.detail === 2) {
      onEdit(data, true);
    }
  };

  const headData = ['Actions', 'Logo', 'Card Name', 'Card Number', 'Points', 'Company Name', 'Expiry Date', 'Status'];
  const headData2 = ['Actions', 'Logo', 'Card Name', 'Card Number', 'Value', 'Company Name', 'Expiry Date', 'Status'];

  const amountSym = user.country === 'United States' ? '$' : '₹';
  const amountSym01 = selectedToggle === 'loyalty' ? '' : amountSym;

  const selectedToggleName =
    selectedToggle === 'loyalty' ? 'loyalty card' : selectedToggle === 'gift' ? 'gift card' : 'reward certificate';

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>
          {data.length} of {data.length}
        </span>
        <TablePagination
          component="div"
          size="small"
          count={paginationData.total}
          rowsPerPageOptions={[25, 50, 100, 150, 200]}
          rowsPerPage={paginationData.limit}
          page={paginationData.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <TableContainer sx={{ maxHeight: 600 }}>
        <CustomTableView
          isSort
          sort={{
            order: sortState.order,
            orderBy: sortState.orderBy,
            createSortHandler: handleSort,
            sortHeadMap: ['', '', 'cardId.name', 'cardNumber', 'points', 'companyId.name', 'expiryDate', 'status'],
          }}
          headData={selectedToggle === 'loyalty' ? headData : headData2}
        >
          {data.length === 0 && (
            <CustomTableCellForNoData
              message={`There are no recorded ${selectedToggleName} for you. Kindly add a new one.`}
            />
          )}
          {newSortData?.map((e, index) => {
            const expiryCheckFlag = e?.expiryDate ? expiryCheck(e.expiryDate) : false;
            return (
              <TableRow key={index} onClick={(ev) => handleDoubleClick(ev, e)}>
                <CustomTableCellForBody align="center" padding="checkbox">
                  <div style={{ display: 'flex' }}>
                    <Checkbox checked={selectedAccount.includes(e._id)} onChange={() => onSelectedChange(e._id)} />
                    <ThreeDotMenu
                      onChangeLog={() => onChangeLog(e)}
                      data={e}
                      onEdit={(flag) => onEdit(e, flag)}
                      onDelete={() => onDelete(e)}
                    />
                  </div>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="center">
                  <img
                    src={e?.companyId?.logo ? e?.companyId?.logo : defaultCard}
                    style={{ maxHeight: '50px', objectFit: 'cover', borderRadius: '8px' }}
                    alt="background"
                  />
                </CustomTableCellForBody>
                <CustomTableCellForBody align="center">
                  <p
                    style={{
                      ...tableCellPStyle,
                      color: expiryCheckFlag ? '#ff4c00' : !e.status ? '#a7b1bc' : '#229A16',
                    }}
                  >
                    {e?.cardId?.name}
                  </p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>{e?.cardNumber}</p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>
                    {e?.points.length > 0 && amountSym01}
                    {e?.points}
                  </p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>{e?.companyId?.name}</p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>
                    {e?.expiryDate ? `${formatDate(e?.expiryDate)} ${getTimeZoneAbbreviation()}` : ''}
                  </p>
                </CustomTableCellForBody>
                <CustomTableCellForBody align="left">
                  <p style={tableCellPStyle}>{e?.status === true ? 'Active' : 'Inactive'}</p>
                </CustomTableCellForBody>
              </TableRow>
            );
          })}
        </CustomTableView>
      </TableContainer>
    </>
  );
};

export default TableView;
