import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Fab, MenuItem, Switch, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { Image, Select } from 'antd';
import CloseIcon from '@mui/icons-material/Close';

import {
  createSafalGiftMasterInventory,
  updateSafalGiftMasterInventory,
  uploadLeaderBoardMasterCountries,
} from '../../../services/Service';

import { usStates, indiaStates } from '../../../utils/stateList';
import useUserTimeZone from '../../../hooks/useUserTimeZone';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function SafalGiftOrderModal({ open, onClose, data, onAccept, onReject }) {
  const { formatDate } = useUserTimeZone();
  const handleClose = () => {
    onClose();
  };

  const handleAccept = async () => {
    onAccept(data);
    handleClose();
  };
  const handleReject = async () => {
    onReject(data);
    handleClose();
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Fab
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            color: 'white',
            backgroundColor: '#ff4842',
            ':hover': { backgroundColor: '#d40700' },
          }}
          onClick={handleClose}
          size="small"
        >
          <CloseIcon />
        </Fab>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {'Forum Information'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <span>
            <b>Name</b> - {data?.name}
          </span>
          <span>
            <b>Topic</b> - {data?.topic}
          </span>
          <p>
            <b>Description</b> - {data?.description}
          </p>
          <span style={{ color: 'grey', fontStyle: 'italic' }}>Company Info</span>
          <span>
            <b>Name</b> - {data?.company?.name}
          </span>
          <span>
            <b>Type</b> - {data?.company?.companyType}
          </span>
          <span>
            <b>Description</b> - {data?.company?.description}
          </span>
          <span style={{ color: 'grey', fontStyle: 'italic' }}>User Info</span>
          <span>
            <b>Name</b> - {data?.user?.firstName} {data?.user?.middleName} {data?.user?.lastName}
          </span>
          <span>
            <b>Email</b> - {data?.user?.email}
          </span>
          <span>
            <b>UserID</b> - {data?.user?.userID}
          </span>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            onClick={handleReject}
            sx={{
              backgroundColor: '#ff4842',
              ':hover': { backgroundColor: '#d40700' },
              textTransform: 'capitalize',
              borderRadius: '30px',
            }}
            variant="contained"
          >
            Reject
          </Button>
          <Button
            onClick={handleAccept}
            sx={{
              backgroundColor: '#19c25f',
              textTransform: 'capitalize',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#19c25f' },
            }}
            variant="contained"
          >
            Accept
          </Button>
          {/* <Button
            onClick={handleClose}
            sx={{
              backgroundColor: '#19c25f',
              textTransform: 'capitalize',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#19c25f' },
            }}
            variant="contained"
          >
            Close
          </Button> */}
        </div>
      </Box>
    </Modal>
  );
}
