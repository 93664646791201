/* eslint-disable */
import { Box, Button, FormControlLabel, Radio, RadioGroup, Stack, Switch } from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ErrorToast from '../../components/Toast/Error';
import SuccessToast from '../../components/Toast/Success';
import { db } from '../../firebase';
import { addChangeLog } from '../../services/Service';

const ChatTab = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);

  const fireUser = currentUser?.uid;
  const [online, setOnline] = useState();

  const getUserdetails = async () => {
    const docRef = doc(db, 'users', fireUser);
    const docSnap = await getDoc(docRef);
    setOnline(docSnap?.data()?.isOnline);
  };

  useEffect(() => {
    getUserdetails();
  }, []);

  const handleOnline = async () => {
    await updateDoc(doc(db, 'users', currentUser?.uid), {
      isOnline: true,
    });
  };

  const handleOffline = async () => {
    console.log('Login DB', doc(db, 'users', currentUser?.uid));
    await updateDoc(doc(db, 'users', currentUser?.uid), {
      isOnline: false,
    });
  };

  const handleSubmit = () => {
    if (localStorage.getItem('status') === 'online') {
      handleOnline();
      SuccessToast('Chat Status updated');
    } else if (localStorage.getItem('status') === 'offline') {
      handleOffline();
      SuccessToast('Chat Status updated');
    } else {
      ErrorToast('Something went wrong');
    }
  };

  const changeAllowChatCommunication = async (e) => {
    try {
      const toggleCheck = e.target.checked;
      // Add Change Log
      await addChangeLog({
        message: 'You change Allow chat communication across different SafalSubscriptions users', // require
        moduleName: 'user',
        oldValue: toggleCheck ? 'Disable' : 'Enable',
        newValue: toggleCheck ? 'Enable' : 'Disable',
      });
      await updateDoc(doc(db, 'users', currentUser?.uid), {
        userToUserChat: toggleCheck,
      });
    } catch (err) {
      console.log('changeAllowChatCommunication', err);
    }
  };

  return (
    <form>
      <div>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
          <Box>• Allow chat communication across different safalsubscriptions.com &nbsp;users </Box>

          <Switch
            onChange={changeAllowChatCommunication}
            defaultChecked={currentFirebaseUser ? currentFirebaseUser.userToUserChat : true}
            value={currentFirebaseUser ? currentFirebaseUser.userToUserChat : true}
          />
          {/* <Stack direction="row" sx={{ color: '#B6B6B6', pl: '20px' }}>
              <FormControlLabel
                value="online"
                onChange={() => {
                  localStorage.setItem('status', 'online');
                }}
                control={<Radio />}
                label="Yes"
              />

              <FormControlLabel
                value="offline"
                onChange={() => {
                  localStorage.setItem('status', 'offline');
                }}
                control={<Radio />}
                label="No"
              />
            </Stack>
          </RadioGroup> */}
        </Box>

        {/* <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleSubmit}
          sx={{
            width: '150px',
            backgroundColor: '#3D71FF',
            borderRadius: '30px',
            textTransform: 'none',
            mt: 3,
          }}
        >
          Update
        </Button> */}
      </div>
    </form>
  );
};

export default ChatTab;
