/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Switch, InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { addSubLabel, sendOTP, updateSubLabel, verifyOTP } from '../../services/Service';
import TopTitleLabelAction from '../../sections/SettingTabs/TopTitleLabelAction';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

function validateEmail(email) {
  // Regular expression for email validation
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Check if the email matches the pattern
  if (emailPattern.test(email)) {
    return true;
  }
  return false;
}

function validatePhoneNumber(number) {
  // Regular expression for US phone number
  const usPattern = /^\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/;
  // Regular expression for Indian phone number
  const indianPattern = /^[789]\d{9}$/;

  // Check if the number matches either US or Indian pattern
  if (usPattern.test(number)) {
    return true;
  }
  if (indianPattern.test(number)) {
    return true;
  }
  return false;
}

export default function AddCustomLabel({ open, onClose, data, onEdit, isDisplay, onDelete }) {
  const { user } = useSelector((state) => state.login);

  const initFieldValue = {
    name: '', // required
    description: '', // optional , default = null
    status: true, // optional , default = true
    budget: '', // optional , default = null
    email: user?.email || '', // required , default = user email
    phoneNo: user?.phoneNo?.toString() || '', // required , default = user phone , phone number must include country code,
    notification: {
      // required
      email: false, // required , default = false
      text: false, // required , default = false
      mobileApp: false, // required , default = false
      notification: false, // required , default = false
      banner: false, // required , default = false
    },
  };

  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);

  const [isEmailCodeSend, setIsEmailCodeSend] = useState(null);
  const [isPhoneCodeSend, setIsPhoneCodeSend] = useState(null);
  const [localDisable, setLocalDisable] = useState(false);

  const [emailVerifyCode, setEmailVerifyCode] = useState('');
  const [phoneVerifyCode, setPhoneVerifyCode] = useState('');

  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);

  const resetError = () => {
    setError(false);
    setErrorPhone(false);
    setErrorEmail(false);
    setEmailVerified(false);
    setPhoneVerified(false);
    setEmailVerifyCode('');
    setPhoneVerifyCode('');
    setIsEmailCodeSend(null);
    setIsPhoneCodeSend(null);
  };

  const directClose = (data) => {
    setFieldValue(initFieldValue);
    resetError();
    onClose(data);
  };

  const handleClose = async () => {
    if (isDisplay) {
      directClose();
      return;
    }
    if (
      fieldValue.name === '' &&
      fieldValue.description === '' &&
      fieldValue.status === true &&
      fieldValue.budget === '' &&
      fieldValue.email === user?.email &&
      fieldValue.phoneNo === user?.phoneNo?.toString() &&
      fieldValue.notification.email === false &&
      fieldValue.notification.text === false &&
      fieldValue.notification.mobileApp === false &&
      fieldValue.notification.notification === false &&
      fieldValue.notification.banner === false
    ) {
      directClose();
    } else {
      const result = await Swal.fire({
        text: 'Are you sure you want to close this?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        appendTo: 'body',
        customClass: {
          container: 'my-swal', // Add a custom class for styling
        },
        onBeforeOpen: () => {
          // Set a higher zIndex for the Swal modal
          document.querySelector('.my-swal').style.zIndex = 999999;
        },
      });
      if (result.isConfirmed) {
        directClose();
      }
    }
  };

  const onChangeField = (e) => {
    setFieldValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onSubmit = async () => {
    resetError();
    let isError = false;
    if (fieldValue.name.trim().length < 3 || fieldValue.name.length > 50) {
      setError(true);
      isError = true;
    }
    if (!validateEmail(fieldValue.email.trim())) {
      setErrorEmail(true);
      isError = true;
    }
    if (!validatePhoneNumber(fieldValue.phoneNo.trim())) {
      setErrorPhone(true);
      isError = true;
    }
    if (isError) return;
    try {
      const res = await addSubLabel(fieldValue);
      if (res.status === 200) {
        toast.success(res.data.message);
        directClose(res.data.data);
        resetError();
      }
    } catch (err) {
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const onUpdate = async () => {
    resetError();
    let isError = false;
    if (fieldValue.name.trim().length < 3 || fieldValue.name.length > 50) {
      setError(true);
      isError = true;
    }
    if (!validateEmail(fieldValue.email.trim())) {
      setErrorEmail(true);
      isError = true;
    }
    if (!validatePhoneNumber(fieldValue.phoneNo.trim())) {
      setErrorPhone(true);
      isError = true;
    }
    if (isError) return;
    try {
      const res = await updateSubLabel({ ...fieldValue, _id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        directClose(res.data.data);
        resetError();
      }
    } catch (err) {
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const onSendOTP = async (type) => {
    try {
      if (type === 'email') {
        const res = await sendOTP({
          type: 'email',
          value: fieldValue.email,
        });
        setIsEmailCodeSend(res.data.id);
        setErrorEmail(false);
        setEmailVerified(false);
      } else {
        const res = await sendOTP({
          type: 'phone',
          value: fieldValue.phoneNo.toString(),
        });
        setIsPhoneCodeSend(res.data.id);
        setErrorPhone(false);
        setPhoneVerified(false);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };
  const onVerifyOTP = async (type) => {
    try {
      await verifyOTP({
        otp: type === 'email' ? parseInt(emailVerifyCode, 10) : parseInt(phoneVerifyCode, 10),
        id: type === 'email' ? isEmailCodeSend : isPhoneCodeSend,
      });
      if (type === 'email') {
        setFieldValue((prev) => ({ ...prev, emailVerifyId: isEmailCodeSend }));
        setIsEmailCodeSend(null);
        setEmailVerifyCode('');
        setEmailVerified(true);
      } else {
        setFieldValue((prev) => ({ ...prev, phoneNoVerifyId: isPhoneCodeSend }));
        setIsPhoneCodeSend(null);
        setPhoneVerifyCode('');
        setPhoneVerified(true);
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  useEffect(() => {
    if (!open) return;
    setFieldValue({
      name: data?.name || '', // required
      description: data?.description || '', // optional , default = null
      status: data?.status === undefined ? true : data?.status, // optional , default = true
      budget: data?.budget || '', // optional , default = null
      email: data?.email || user?.email, // required , default = user email
      phoneNo: data?.phoneNo || user?.phoneNo?.toString(), // required , default = user phone , phone number must include country code,
      notification: {
        // required
        email: data?.notification?.email || false, // required , default = false
        text: data?.notification?.text || false, // required , default = false
        mobileApp: data?.notification?.mobileApp || false, // required , default = false
        notification: data?.notification?.notification || false, // required , default = false
        banner: data?.notification?.banner || false, // required , default = false
      },
    });
    setPhoneVerified(true);
    setEmailVerified(true);
  }, [open, data]);

  useEffect(() => {
    if (data?.phoneNo === fieldValue.phoneNo) {
      setPhoneVerified(true);
      return;
    }
    if (fieldValue.phoneNo === user?.phoneNo.toString()) {
      setPhoneVerified(true);
    } else {
      setPhoneVerified(false);
    }
  }, [fieldValue.phoneNo]);

  const isUpdate = data !== null;

  const isThisOtherLabel = data?.name === 'Others';

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <Typography variant="h4" gutterBottom sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            {isDisplay ? `${data.name} label` : isUpdate ? `Update ${data.name} label` : 'Create New Label'}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h4" gutterBottom sx={{ fontSize: '15px', fontWeight: 400 }}>
              Enter your details below.
            </Typography>
            {isDisplay && (
              <TopTitleLabelAction
                onEdit={onEdit}
                onDelete={
                  !isThisOtherLabel &&
                  (() => {
                    onDelete(data);
                    directClose();
                  })
                }
              />
            )}
          </div>
        </Box>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* code */}
          {isUpdate && (
            <TextField
              value={data?.code}
              label={<Typography sx={{ color: '#B6B6B6' }}>Code</Typography>}
              disabled
              name="code"
              size="small"
              type="text"
              fullWidth
              variant="outlined"
              inputProps={{
                minLength: 3,
                maxLength: 100,
              }}
            />
          )}
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={onChangeField}
            label={
              <Typography sx={{ color: '#B6B6B6' }}>
                Name<span style={{ color: 'red' }}>*</span>
              </Typography>
            }
            disabled={isThisOtherLabel ? true : isDisplay}
            name="name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            error={error}
            helperText={error ? 'Please enter a valid name(min 3 char, max 50 char)' : ''}
            inputProps={{
              minLength: 3,
              maxLength: 50,
            }}
          />
          {/* description */}
          <TextField
            disabled={isThisOtherLabel ? true : isDisplay}
            value={fieldValue.description}
            onChange={onChangeField}
            label="Description"
            name="description"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            inputProps={{
              minLength: 3,
              maxLength: 100,
            }}
          />
          {/* budget */}
          <TextField
            disabled={isDisplay}
            value={fieldValue.budget}
            onChange={onChangeField}
            label="Budget"
            name="budget"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">{user?.country === 'India' ? '₹' : '$'}</InputAdornment>,
            }}
          />
          {/* email */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <TextField
              disabled={isDisplay}
              value={fieldValue.email}
              onChange={onChangeField}
              label="Email"
              name="email"
              size="small"
              type="email"
              fullWidth
              variant="outlined"
              error={errorEmail}
              helperText={errorEmail ? 'Please enter a valid email' : ''}
              InputProps={{
                endAdornment: emailVerified && (
                  <InputAdornment position="end">
                    <CheckCircleIcon sx={{ color: '#54d62c' }} />
                  </InputAdornment>
                ),
              }}
            />
            {!emailVerified && user?.email !== fieldValue.email && (
              <ResendAutoBtn onClick={() => onSendOTP('email')} disabled={!validateEmail(fieldValue.email)} />
            )}
          </div>
          {/* email verify */}
          {isEmailCodeSend && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <TextField
                value={emailVerifyCode}
                onChange={(e) => setEmailVerifyCode(e.target.value)}
                label="Email Verify Code"
                size="small"
                type="number"
                fullWidth
                variant="outlined"
              />
              <Button
                onClick={() => onVerifyOTP('email')}
                disabled={emailVerifyCode.length !== 4}
                variant="outlined"
                sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
              >
                Verify
              </Button>
            </div>
          )}
          {/* phoneNo */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <TextField
              disabled={isDisplay ? true : localDisable}
              value={fieldValue.phoneNo}
              onChange={onChangeField}
              label="Phone No"
              name="phoneNo"
              size="small"
              type="text"
              fullWidth
              variant="outlined"
              error={errorPhone}
              helperText={errorPhone ? 'Please enter a valid phone number' : ''}
              inputProps={{
                maxLength: 10,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{user?.country === 'India' ? '+91' : '+1'}</InputAdornment>
                ),
                endAdornment: phoneVerified && (
                  <InputAdornment position="end">
                    <CheckCircleIcon sx={{ color: '#54d62c' }} />
                  </InputAdornment>
                ),
              }}
            />
            {!phoneVerified && user?.phoneNo.toString() !== fieldValue.phoneNo && (
              <ResendAutoBtn
                localDisable={localDisable}
                setLocalDisable={setLocalDisable}
                onClick={() => onSendOTP('phone')}
                disabled={!validatePhoneNumber(fieldValue.phoneNo)}
              />
            )}
          </div>
          {/* phone verify */}
          {isPhoneCodeSend && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <TextField
                value={phoneVerifyCode}
                onChange={(e) => setPhoneVerifyCode(e.target.value)}
                label="Phone Verify Code"
                size="small"
                type="number"
                fullWidth
                variant="outlined"
              />
              <Button
                onClick={() => onVerifyOTP('phone')}
                disabled={phoneVerifyCode.length !== 4}
                variant="outlined"
                sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
              >
                Verify
              </Button>
            </div>
          )}
          {/* status */}
          <TextField
            disabled={isThisOtherLabel ? true : isDisplay}
            value={fieldValue.status ? 'active' : 'inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'active' }))}
            select
            label="Status"
            name="status"
            size="small"
            fullWidth
            defaultValue="loyalty"
            variant="outlined"
          >
            <MenuItem value={'active'}>Active</MenuItem>
            <MenuItem value={'inactive'}>Inactive</MenuItem>
          </TextField>
          <div>
            <Typography variant="h4" gutterBottom sx={{ fontSize: '20px', fontWeight: 700, color: '#3D71FF' }}>
              Notification channels
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ fontSize: '14px', fontWeight: 400 }}>
              Select your preferred notification method
            </Typography>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {/* email */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                style={{ color: '#2065d1' }}
                disabled={isDisplay}
                checked={fieldValue.notification.email}
                onChange={() =>
                  setFieldValue((prev) => ({
                    ...prev,
                    notification: { ...prev.notification, email: !prev.notification.email },
                  }))
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>E-Mail</span>
            </div>
            {/* text */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                style={{ color: '#2065d1' }}
                disabled={isDisplay}
                checked={fieldValue.notification.text}
                onChange={() =>
                  setFieldValue((prev) => ({
                    ...prev,
                    notification: { ...prev.notification, text: !prev.notification.text },
                  }))
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>Text</span>
            </div>
            {/* notification */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                style={{ color: '#2065d1' }}
                disabled={isDisplay}
                checked={fieldValue.notification.notification}
                onChange={() =>
                  setFieldValue((prev) => ({
                    ...prev,
                    notification: { ...prev.notification, notification: !prev.notification.notification },
                  }))
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>Notification Screen</span>
            </div>
            {/* mobileApp */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                style={{ color: '#2065d1' }}
                disabled={isDisplay}
                checked={fieldValue.notification.mobileApp}
                onChange={() =>
                  setFieldValue((prev) => ({
                    ...prev,
                    notification: { ...prev.notification, mobileApp: !prev.notification.mobileApp },
                  }))
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>Mobile App</span>
            </div>
            {/* banner */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                style={{ color: '#2065d1' }}
                disabled={isDisplay}
                checked={fieldValue.notification.banner}
                onChange={() =>
                  setFieldValue((prev) => ({
                    ...prev,
                    notification: { ...prev.notification, banner: !prev.notification.banner },
                  }))
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>Notification Banner</span>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          {isDisplay ? (
            <></>
          ) : // <Button
          //   disabled={data?._id === 'OTHERS'}
          //   onClick={onEdit}
          //   sx={{ backgroundColor: '#3d71ff', textTransform: 'capitalize', borderRadius: '30px' }}
          //   variant="contained"
          // >
          //   Edit
          // </Button>
          isUpdate ? (
            <Button
              onClick={onUpdate}
              sx={{ backgroundColor: '#3d71ff', textTransform: 'capitalize', borderRadius: '30px' }}
              variant="contained"
            >
              Update
            </Button>
          ) : (
            <Button
              onClick={onSubmit}
              sx={{ backgroundColor: '#3d71ff', textTransform: 'capitalize', borderRadius: '30px' }}
              variant="contained"
            >
              Create
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
const ResendAutoBtn = ({ onClick, disabled, localDisable, setLocalDisable }) => {
  const [timer, setTimer] = useState(30);

  const clickHandle = () => {
    onClick();
    setLocalDisable(true);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      setLocalDisable(false);
    }
  }, [timer]);
  const isDisable = localDisable ? true : localDisable;

  return (
    <Button
      onClick={clickHandle}
      disabled={isDisable}
      variant="outlined"
      sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}
    >
      {localDisable ? `Resend in ${timer}s` : 'Send OTP'}
    </Button>
  );
};
