/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputAdornment, MenuItem, TextField, Typography, IconButton, Button, Badge, Tooltip } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Image } from 'antd';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import toast from 'react-hot-toast';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useParams } from 'react-router-dom';

import './UserOffersList.css';

import vendorImg from '../../../assets/vendorImg.png';
import FaqModal from '../../../components/Modals/FaqModal';
import { usStates, indiaStates } from '../../../utils/stateList';
import {
  downloadAttachmentFile,
  getNotificationBanner,
  getOffersListDownload,
  getOffersListUser,
  getSignedURL,
} from '../../../services/Service';
import Inlineloader from '../../../components/Loader/InlineLoader';
import { fallbackImage } from '../../../utils/general';
import offers_more from '../../../assets/offers_more.png';
import MoreCategoryModal from './MoreCategoryModal';
import CustomSortMenu from '../../../components/UI/CustomSortMenu';
import SingleOfferModal from './SingleOfferModal';
import UserOffersFilterMenu from './UserOffersFilterMenu';
import useDebounce from '../../../hooks/useDebounce';
import CustomMuiSelect from '../../../components/UI/CustomMuiSelect';
import OffersDownloadMenu from './OffersDownloadMenu';
import AnalyticsClickImpression from '../../../components/AnalyticsClickImpression';

const UserOffersList = () => {
  const login = useSelector((state) => state.login);
  const currentUser = login?.user;
  const { id, type } = useParams();

  const [selectedState, setSelectedState] = useState(currentUser.state);
  const [moreCategoryOpen, setMoreCategoryOpen] = useState(false);
  const [notificationBanner, setNotificationBanner] = useState([]);
  const [offersPanel, setOffersPanel] = useState({
    open: false,
    data: null,
  });
  const [singleOffer, setSingleOffer] = useState({
    open: false,
    data: null,
  });
  const [offerData, setOfferData] = useState({
    banner: [],
    category: [],
    bannerSquare: [],
  });
  const [loading, setLoading] = useState(false);
  const [sortState, setSortState] = useState({
    sort: 'count',
    order: 'desc',
  });
  const [filterState, setFilterState] = useState({
    companyType: '',
    companyName: '',
  });

  const promotionsList = notificationBanner?.filter((e) => e?.type === 'promotions');

  const getOfferData = async (selectedState, sort, filterState) => {
    // setLoading(true);
    try {
      const res = await getOffersListUser({ state: selectedState, ...sort, ...filterState });
      if (res.status === 200) {
        setOfferData(res.data.data);
      }
    } catch (err) {
      console.log('getOfferData error', err.message);
    }
    //  finally {
    //   setLoading(false);
    // }
  };

  // DeBounce Function
  useDebounce(
    async () => {
      await getOfferData(selectedState, sortState, filterState);
    },
    [filterState],
    1000
  );

  const fetchNotificationBanner = async () => {
    setLoading(true);
    try {
      const res = await getNotificationBanner();
      if (res.status === 200) {
        setNotificationBanner(res.data.data);
      }
    } catch (err) {
      console.log('fetchNotificationBanner error', err.message);
    } finally {
      setLoading(false);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  const openOffer = (data) => {
    if (!data?.data) {
      toast('There are no offers available for this company type.', {
        duration: 2000,
      });
      return;
    }
    if (data?.data?.length === 0) {
      toast('There are no offers available for this company type.', {
        duration: 2000,
      });
      return;
    }
    const newData = {
      ...data,
      data: data?.data || [],
    };
    setOffersPanel({
      open: true,
      data: newData,
    });
  };
  const closeOffer = () => {
    setOffersPanel({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    getOfferData(selectedState, sortState);
    fetchNotificationBanner();
  }, [selectedState, sortState]);

  useEffect(() => {
    if (!type) return;
    if (!id) return;
    if (offerData?.category?.length === 0) return;
    const findNewOffer = offerData?.category?.find((e) => e?.type === type);
    if (findNewOffer) {
      const findId = findNewOffer?.data?.find((ex) => ex?._id === id);
      if (findId) onOpenSingleOffer(findId);
    }
  }, [type, id, offerData]);

  const stateArray =
    currentUser.country === 'India' ? indiaStates : currentUser.country === 'United States' ? usStates : [];

  if (loading) return <Inlineloader />;

  const onMoreClick = () => {
    setMoreCategoryOpen(true);
  };
  const onMoreClose = () => {
    setMoreCategoryOpen(false);
  };

  const onOpenSingleOffer = (data) => {
    setSingleOffer({
      open: true,
      data: data,
    });
  };
  const onCloseSingleOffer = () => {
    setSingleOffer({
      open: false,
      data: null,
    });
  };

  const firstCategory =
    offerData.category.length > 15
      ? [...offerData.category.slice(0, 15), { data: [], logo: offers_more, type: 'More Category' }]
      : [...offerData.category.slice(0, 15)]; // Get the first 15 elements
  const remainingCategory = offerData.category.slice(15); // Get all elements after the 15th element

  const onDownloadAttachment = async (url) => {
    try {
      const res = await downloadAttachmentFile({ url });
      if (res.data.success === true) {
        const link = document.createElement('a');
        link.href = res.data.data;
        link.setAttribute('download', `file`);
        document.body.appendChild(link);
        link.click();
      }
      toast.success('Download Complete');
    } catch (error) {
      console.log(error);
      toast.error('Download Error');
    }
  };

  const onSortChange = (newSort) => {
    setSortState(newSort);
  };

  const downloadOffersList = async (type) => {
    try {
      const res = await getOffersListDownload({ state: selectedState, fileType: type });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  return (
    <>
      <Helmet>
        <title>Offers - SafalSubscriptions</title>
      </Helmet>

      <div id="UserOffersList" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        {/* Title */}
        <div style={{ display: 'flex', alignItems: 'center', flex: 'none', marginBottom: '8px' }}>
          <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            Safal Offers
          </Typography>
          <FaqModal module="Offer" color="#3D71FF" />
        </div>
        {/* Carousel */}
        {offerData.banner.length !== 0 && (
          <div style={{ position: 'relative', flex: 'none' }}>
            <Carousel
              autoPlay
              infiniteLoop
              showStatus={false}
              showArrows
              showThumbs={false}
              renderArrowNext={(clickHandler, hasNext, label) => (
                <RenderArrowNext clickHandler={clickHandler} hasNext={hasNext} label={label} />
              )}
              renderArrowPrev={(clickHandler, hasPrev, label) => (
                <RenderArrowPrev clickHandler={clickHandler} hasPrev={hasPrev} label={label} />
              )}
              // onChange={(i) => localStorage.setItem('banner', i)}
              showIndicators
              axis="horizontal"
            >
              {offerData.banner.map((e, index) => (
                <a href={e.link} rel="noreferrer" target="_blank" key={index}>
                  {e.type === 'image' ? (
                    <AnalyticsClickImpression data={e} id={e?._id} page="Offers">
                      <img
                        style={{ objectFit: 'cover', borderRadius: '16px', 'pointer-events': 'all' }}
                        height="150px"
                        width="100%"
                        src={e.value}
                        alt="banner"
                      />
                    </AnalyticsClickImpression>
                  ) : (
                    <AnalyticsClickImpression data={e} id={e?._id} page="Offers">
                      <video
                        style={{ maxHeight: '150px', objectFit: 'cover', borderRadius: '16px' }}
                        width="100%"
                        height="150px"
                        autoPlay="true"
                        muted
                      >
                        <source src={e.value} />
                      </video>
                    </AnalyticsClickImpression>
                  )}
                </a>
              ))}
            </Carousel>
          </div>
        )}
        {/* Promotion Carousel */}
        {promotionsList.length !== 0 && (
          <div style={{ position: 'relative', flex: 'none' }}>
            <Carousel
              autoPlay
              infiniteLoop
              showStatus={false}
              showArrows
              showThumbs={false}
              showIndicators={false}
              axis="horizontal"
            >
              {promotionsList.map((e, index) => (
                <div
                  style={{
                    height: '40px',
                    backgroundColor: '#bcceff',
                    borderRadius: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0px 48px',
                  }}
                  key={index}
                >
                  <span>
                    {e?.title?.trim()} : {e?.message?.trim()}
                  </span>
                  <div style={{ display: 'flex', height: '100%', gap: '16px', alignItems: 'center' }}>
                    {e?.attachments?.map((e, index) => (
                      <span key={index}>
                        <AttachFileIcon
                          sx={{
                            cursor: 'pointer',
                            color: '3D71FF',
                            fontSize: '18px',
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                          }}
                          onClick={() => onDownloadAttachment(e)}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        )}
        {/* Select State bar */}
        <div
          style={{
            marginTop: '12px',
            flex: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
            }}
          >
            {/* State Change select */}
            <CustomMuiSelect
              disablePortal
              value={
                [
                  { label: 'All State', abbreviation: 'All' },
                  ...stateArray.map((e) => ({ label: e.name, abbreviation: e.abbreviation })),
                ].find((e) => e.abbreviation === selectedState).label
              }
              onChange={(e) => setSelectedState(e.abbreviation)}
              size="small"
              variant="outlined"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <PlaceIcon />
              //     </InputAdornment>
              //   ),
              // }}
              className="ufl-w-full-600"
              options={[
                { label: 'All State', abbreviation: 'All' },
                ...stateArray.map((e) => ({ label: e.name, abbreviation: e.abbreviation })),
              ]}
            />
            <CustomSortMenu
              sortState={sortState}
              onChange={onSortChange}
              sort={[
                { value: 'count', label: 'Offers Count' },
                { value: 'type', label: 'Category' },
              ]}
            />
            <OffersDownloadMenu downloadFile={downloadOffersList} />
            <UserOffersFilterMenu state={filterState} onFilterApply={onFilterApply} />
            <Tooltip title="Refresh">
              <IconButton
                size="small"
                sx={{
                  flex: 'none',
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={() => {
                  getOfferData(selectedState, sortState);
                  fetchNotificationBanner();
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
          {/* Close Offer Back Button */}
          {offersPanel.open && (
            <IconButton
              onClick={closeOffer}
              title="Back to Offers Category"
              sx={{
                color: 'white',
                backgroundColor: '#3D71FF',
                marginRight: '12px',
                ':hover': {
                  color: 'white',
                  backgroundColor: '#315acc',
                },
              }}
              size="small"
              variant="contained"
            >
              <WestIcon />
            </IconButton>
          )}
        </div>
        {!offersPanel.open && (
          <>
            <div className="cat-table-grid-x">
              {firstCategory.map((e, index) => (
                <div>
                  <Badge
                    sx={{
                      '.MuiBadge-badge': {
                        borderRadius: '1000px',
                        top: '6px',
                        right: '15px',
                        backgroundColor: '#54d62c',
                        fontWeight: 'bold',
                        color: 'black',
                        height: '32px',
                        width: '32px',
                      },
                    }}
                    max={999}
                    badgeContent={e?.data?.length || 0}
                    color="primary"
                  >
                    <CategorySquare
                      onClick={() => (e?.type === 'More Category' ? onMoreClick() : openOffer(e))}
                      key={index}
                      src={e?.logo}
                      label={e?.type}
                    />
                  </Badge>
                </div>
              ))}
            </div>
          </>
        )}
        {offersPanel.open && (
          <>
            {offersPanel?.data?.data?.length === 0 ? (
              <div style={{ flex: '1' }}>
                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: '20px', color: '#3d71ff' }}>
                  {offersPanel?.data?.type} Offers
                </span>
                <div
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px',
                    paddingTop: '32px',
                    display: 'flex',
                  }}
                >
                  <span>No Offer Found</span>
                </div>
              </div>
            ) : (
              <div style={{ flex: '1' }}>
                <span style={{ textTransform: 'capitalize', fontWeight: 'bold', fontSize: '20px', color: '#3d71ff' }}>
                  {offersPanel?.data?.type} Offers
                </span>
                <div
                  style={{
                    padding: '16px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '16px',
                  }}
                >
                  {offersPanel?.data?.data?.map((e, index) => (
                    <OfferSquare key={index} data={e} onClick={() => onOpenSingleOffer(e)} />
                  ))}
                </div>
              </div>
            )}
          </>
        )}
        {/* Banner Square */}
        <div style={{ display: 'flex', gap: '32px', overflow: 'auto', flex: 'none', flexWrap: 'wrap' }}>
          <SafalVendor />
          {offerData.bannerSquare.length !== 0 && (
            <div style={{ display: 'flex', gap: '32px', overflow: 'auto', flex: 'none' }}>
              {offerData.bannerSquare.map((e, index) => (
                <BannerSquare data={e} key={index} href={e.link} src={e.value} type={e.type} id={e?._id} />
              ))}
            </div>
          )}
        </div>
      </div>
      <MoreCategoryModal open={moreCategoryOpen} onClose={onMoreClose} data={remainingCategory} onClick={openOffer} />
      <SingleOfferModal open={singleOffer.open} data={singleOffer.data} onClose={onCloseSingleOffer} />
    </>
  );
};

export default UserOffersList;

const RenderArrowNext = ({ clickHandler, hasNext, label }) => {
  return (
    <IconButton
      disabled={!hasNext}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        right: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowRightIcon />
    </IconButton>
  );
};
const RenderArrowPrev = ({ clickHandler, hasPrev, label }) => {
  return (
    <IconButton
      disabled={!hasPrev}
      onClick={() => clickHandler()}
      sx={{
        position: 'absolute',
        left: 15,
        top: 'calc(50% - 20px)',
        backgroundColor: '#3d71ff',
        color: 'white',
        zIndex: 2,
        ':hover': {
          backgroundColor: '#648dff',
          color: 'white',
        },
      }}
      size="small"
    >
      <KeyboardArrowLeftIcon />
    </IconButton>
  );
};

const BannerSquare = ({ href, src, type, id, data }) => {
  return (
    <a href={href} rel="noreferrer" target="_blank">
      {type === 'image' ? (
        <AnalyticsClickImpression data={data} id={id} page="Offers">
          <img
            alt="safal"
            style={{ objectFit: 'cover', borderRadius: '16px', 'pointer-events': 'all' }}
            height="125px"
            width="125px"
            src={src}
          />
        </AnalyticsClickImpression>
      ) : (
        <AnalyticsClickImpression data={data} id={id} page="Offers">
          <video
            style={{ maxHeight: '125px', objectFit: 'cover', borderRadius: '16px' }}
            width="125px"
            height="125px"
            autoPlay="true"
            muted
          >
            <source src={src} />
          </video>
        </AnalyticsClickImpression>
      )}
    </a>
  );
};
const SafalVendor = () => {
  return (
    <div
      className="ufl-w-full-600-500"
      style={{
        display: 'flex',
        height: '125px',
        backgroundColor: '#ad77ff',
        borderRadius: '16px',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 16px',
      }}
    >
      <img
        className="ufl-hide-in-450"
        style={{
          objectFit: 'fill',
        }}
        height="80px"
        width="80px"
        src={vendorImg}
        alt="Have a business"
      />
      <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal', color: 'white', fontSize: '14px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '20px' }}>Have a business</span>
        <span>Start selling with us in</span>
        <span>starting just $5.00</span>
      </div>
      <Button
        title="Register SafalVendor"
        sx={{
          fontSize: '12px',
          color: 'white',
          textTransform: 'capitalize',
          backgroundColor: '#a32ff2',
          ':hover': {
            color: 'white',
            backgroundColor: '#b559f5',
          },
        }}
        target="_blank"
        size="small"
        variant="contained"
        href={`${process.env.REACT_APP_SAFALVENDOR_URL}/register`}
        endIcon={<EastIcon />}
      >
        Register SafalVendor
      </Button>
    </div>
  );
};
const CategorySquare = ({ onClick, src, label, id }) => {
  return (
    <a
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '2px 12px',
        height: '150px',
        width: '130px',
        textDecoration: 'none',
        color: 'black',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Image
        fallback={fallbackImage}
        preview={false}
        style={{
          objectFit: 'cover',
          borderRadius: '16px',
          border: '1px solid #bfbfbf',
          filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
        }}
        height="100px"
        width="100px"
        src={src}
      />
      <p
        style={{
          height: '43px',
          lineHeight: 'normal',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textTransform: 'capitalize',
        }}
      >
        {label}
      </p>
    </a>
  );
};

const OfferSquare = ({ data, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="hover-bg-white-x"
      style={{
        cursor: 'pointer',
        borderRadius: '16px',
        display: 'flex',
        width: '360px',
        gap: '8px',
        padding: '8px',
      }}
    >
      <div style={{ flex: 'none', height: '100px' }}>
        {/* <a style={{ flex: 'none', height: '100px' }} href={data?.redirectLink} rel="noreferrer" target="_blank"> */}
        <Image
          fallback={fallbackImage}
          preview={false}
          style={{
            objectFit: 'cover',
            borderRadius: '16px',
            border: '1px solid #bfbfbf',
          }}
          height="100%"
          width="140px"
          src={data?.company?.logo}
        />
        {/* </a> */}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontSize: '13px',
          lineHeight: 'normal',
          flex: '1',
          overflow: 'hidden',
          color: 'black',
          justifyContent: 'space-evenly',
        }}
      >
        <span
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {data?.title}
        </span>
        <span
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {data?.subtitle}
        </span>
        <span
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {data?.description}
        </span>
        <a
          style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: 'black',
          }}
          href={data?.company?.website}
          rel="noreferrer"
          target="_blank"
        >
          {data?.company?.website}
        </a>
      </div>
    </div>
  );
};
