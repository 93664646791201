import * as Yup from 'yup';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Field, FormikProvider, useFormik } from 'formik';
import { Button, Stack, TextField } from '@mui/material';
import SuccessToast from '../../../components/Toast/Success';
import ErrorToast from '../../../components/Toast/Error';
import { VerifyUserIdLoginToken } from '../../../services/Service';

// ----------------------------------------------------------------------

export default function NewUserIdForm(props) {
  const navigate = useNavigate();

  const NewUserIdSchema = Yup.object().shape({
    userID: Yup.string().required('UserID is required'),
  });

  const initialValues = {
    userID: '',
  };

  const NewUserIdFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: NewUserIdSchema,
    onSubmit: (values) => {
      VerifyUserIdLoginToken(values, props)
        .then((res) => {
          console.log(res.data);
          if (res.data.success === true) {
            SuccessToast('Your UserId has been Updated Successfully!');
            navigate('/login');
          }
        })
        .catch((err) => {
          console.log(err.message);
          ErrorToast('Something went wrong!');
        });
    },
  });

  return (
    <>
      <FormikProvider value={NewUserIdFormik}>
        <form onSubmit={NewUserIdFormik.handleSubmit}>
          <Stack spacing={3}>
            <Field
              as={TextField}
              type="text"
              name="userID"
              label="User Id"
              onChange={NewUserIdFormik.handleChange}
              value={NewUserIdFormik.values.userID}
              error={NewUserIdFormik.touched.userID && Boolean(NewUserIdFormik.errors.userID)}
              helperText={NewUserIdFormik.touched.userID && NewUserIdFormik.errors.userID}
            />

            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!(NewUserIdFormik.isValid && NewUserIdFormik.dirty)}
            >
              Send
            </Button>
          </Stack>
        </form>
      </FormikProvider>
    </>
  );
}
