import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useState } from 'react';
// import { useSelector } from 'react-redux';
// import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import { CSVLink } from 'react-csv';
// import { jsPDF } from 'jspdf';
// import moment from 'moment';

import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const DownloadMenuBusiness = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { formatDateTime } = useUserTimeZone();

  // const TITLE_NAME = 'Product Category';
  const FILE_NAME = 'SafalWarranty-Product-Category';

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  // const handleExportPDF = async (event) => {
  //   event.preventDefault();
  //   // eslint-disable-next-line new-cap
  //   const doc = new jsPDF();

  //   const tableContent = sortData.map((row, index) => {
  //     return [
  //       index + 1,
  //       row?.companyType,
  //       row?.name,
  //       row?.description,
  //       row?.website,
  //       row?.countryCode,
  //       row?.popular,
  //       moment(row?.updatedAt).format('MM-DD-yyyy'),
  //       row?.updatedBy,
  //       row?.price,
  //     ];
  //   });

  //   const tableHeaders = headers.map((header) => header.label);

  //   doc.autoTable({
  //     startY: 30,
  //     margin: { horizontal: 10 },
  //     head: [tableHeaders],
  //     body: tableContent,
  //     columnStyles: {
  //       0: { columnWidth: 10 },
  //       1: { columnWidth: 20 },
  //       2: { columnWidth: 20 },
  //       3: { columnWidth: 20 },
  //       4: { columnWidth: 30 },
  //       5: { columnWidth: 15 },
  //       6: { columnWidth: 15 },
  //       7: { columnWidth: 20 },
  //       8: { columnWidth: 20 },
  //     },
  //     // eslint-disable-next-line func-names
  //     didParseCell: function (data) {
  //       if (data.row.index === 0) {
  //         data.cell.styles.fontStyle = '';
  //       } else {
  //         const cellData = data.cell.raw;
  //         if (typeof cellData === 'string' && cellData.length > 20) {
  //           data.cell.text = `${cellData.substring(0, 20)}...`;
  //         }
  //       }
  //     },
  //     didDrawPage: function (data) {
  //       const currentPage = doc.internal.getNumberOfPages();
  //       const pageSize = doc.internal.pageSize;
  //       const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
  //       // Header
  //       if (currentPage === 1) {
  //         doc.setFontSize(20);
  //         doc.setTextColor(40);
  //         doc.text('SafalSubscriptions', data.settings.margin.left, 22);
  //         doc.setFontSize(12);
  //         doc.setTextColor(40);
  //         doc.text(formatDateTime(new Date()), pageWidth - 60, 22);
  //       }

  //       // Footer
  //       const str = `Page ${currentPage}`;
  //       doc.setFontSize(10);

  //       const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
  //       doc.text(str, data.settings.margin.left, pageHeight - 10);
  //     },
  //   });

  //   doc.save('Master-Companies.pdf');
  //   setTimeout(() => {
  //     toast.success('Download successful.');
  //   }, 1000);
  //   // await createLog('company', 'Exported companies list in PDF format.', 'N/A', 'N/A');
  // };

  const handleDownload = async (format) => {
    // await createLog('company', `Exported companies list in ${format} format.`, 'N/A', 'N/A');
    setTimeout(() => {
      toast.success('Download successful.');
    }, 1000);
  };

  const sortData = data?.map((e, index) => ({
    no: index + 1,
    name: e?.name,
    subCategoryCount: e?.subCategoryCount || '0',
    createdAt: `${formatDateTime(e?.createdAt)} ${getTimeZoneAbbreviation()}`,
    status: e?.status,
  }));

  const headers = [
    { label: 'No.', key: 'no' },
    { label: 'Name', key: 'name' },
    { label: 'Sub Category Count', key: 'subCategoryCount' },
    { label: 'Created At', key: 'createdAt' },
    { label: 'Status', key: 'status' },
  ];

  return (
    <div>
      <Tooltip title="Download">
        <IconButton
          sx={{
            color: '#FFFFFF',
            backgroundColor: '#027c34',
            '&:hover': { backgroundColor: '#027c34', color: '#FFFFFF' },
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          marginTop: '6px',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CSVLink
          data={sortData}
          headers={headers}
          filename={FILE_NAME}
          target="_blank"
          style={{ textDecoration: 'none', color: 'black' }}
          onClick={() => handleDownload('CSV')}
        >
          <MenuItem onClick={handleClose}>Export as CSV</MenuItem>
        </CSVLink>
        <CSVLink
          separator={'\t'}
          data={sortData}
          headers={headers}
          filename={`${FILE_NAME}.dnl`}
          target="_blank"
          style={{ textDecoration: 'none', color: 'black' }}
          onClick={() => handleDownload('DNL')}
        >
          <MenuItem onClick={handleClose}>Export as DNL</MenuItem>
        </CSVLink>
        <CSVLink
          data={sortData}
          headers={headers}
          filename={`${FILE_NAME}.txt`}
          target="_blank"
          style={{ textDecoration: 'none', color: 'black' }}
          onClick={() => handleDownload('TXT')}
        >
          <MenuItem onClick={handleClose}>Export as TXT</MenuItem>
        </CSVLink>
        {/* <CSVLink
          data={sortData}
          headers={headers}
          filename={'Master-Companies.pdf'}
          target="_blank"
          style={{ textDecoration: 'none', color: 'black' }}
          onClick={handleExportPDF}
        >
          <MenuItem onClick={handleClose}>Export as PDF</MenuItem>
        </CSVLink> */}
      </Menu>
    </div>
  );
};

export default DownloadMenuBusiness;
