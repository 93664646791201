import { useState } from 'react';
import { TablePagination } from '@mui/material';

import CustomSearch from '../../../components/UI/CustomSearch';
import { getBuddyInviteSearchList } from '../../../services/Service';
import useDebounce from '../../../hooks/useDebounce';
import BuddyCardInvite from './BuddyCardInvite';
import Inlineloader from '../../../components/Loader/InlineLoader';

const BuddyInvitePage = ({ onChat, onCall }) => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 6,
    total: 0,
  });

  const onSearchChange = (value) => {
    setSearchValue(value);
  };

  const fetchData = async (newFilter) => {
    try {
      setLoading(true);
      const res = await getBuddyInviteSearchList(newFilter);
      if (res.status === 200 && res.data.success === true) {
        setData(res.data.data);
        setPagination({
          page: res.data?.pagination?.currentPage ?? 1,
          limit: res.data?.pagination?.limit ?? 6,
          total: res.data?.pagination?.total ?? 0,
        });
      }
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
    }
  };

  useDebounce(
    async () => {
      await fetchData({ search: searchValue, ...pagination });
    },
    [searchValue],
    1500
  );
  const refetch = () => {
    fetchData({ search: searchValue, ...pagination });
  };

  const handleChangePage = (_, value) => {
    fetchData({ ...pagination, search: searchValue, page: value + 1 });
  };

  const handleChangeRowsPerPage = (e) => {
    fetchData({ ...pagination, search: searchValue, limit: e.target.value, page: 1 });
  };

  if (loading) return <Inlineloader />;

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          margin: '16px 16px',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <CustomSearch
            value={searchValue}
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder="Search Users for Invite"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
          <TablePagination
            component="div"
            size="small"
            count={pagination?.total}
            rowsPerPageOptions={[6, 10, 15, 20]}
            rowsPerPage={pagination?.limit}
            page={pagination?.page - 1}
            disabled={pagination?.total === 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {data?.length > 0 ? (
        <div
          style={{
            gridTemplateColumns: 'repeat(2, 1fr)',
            display: 'grid',
            gap: '16px',
            margin: '0px 16px',
            maxHeight: '440px',
            overflow: 'auto',
          }}
        >
          {data?.map((e, index) => (
            <BuddyCardInvite onChat={onChat} onCall={onCall} key={index} data={e} refetch={refetch} />
          ))}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            margin: '0px 16px',
            height: '400px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span>No Users Found</span>
        </div>
      )}
    </>
  );
};

export default BuddyInvitePage;
