import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import moment from 'moment';
import styled from 'styled-components';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import toast from 'react-hot-toast';
import { Box } from '@mui/system';
import { RRule } from 'rrule';
import { Grid, Typography, Tooltip, Stack } from '@mui/material';
import Page from '../../components/Page';
import {
  GetsubsResponse,
  singleEventResponse,
  getAssetModuleAccounts,
  getAssetModuleAccountSingle,
} from '../../services/Service';
import { setSubscriptions } from '../../store/subscriptionSlice';
import Iconify from '../../components/Iconify';
import EventClickModal from '../../components/Modals/EventClickModal';
import DeleteModal from '../../components/Modals/DeleteModal';
import EventClickModalReuse from '../../components/Modals/EventClickModalForAsset';
import AddAssetModal from './AssetModule/AddAssetModal';
import FaqModal from '../../components/Modals/FaqModal';

const locales = {
  'en-US': require('date-fns'),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const StyledWrapper = styled.div`
  .rbc-btn-group button {
    border-radius: 25px;
    background-color: #ffffff;
  }

  .rbc-toolbar button:focus {
    background-color: #3d71ff;
    color: #ffffff;
  }

  .rbc-toolbar button:active,
  .rbc-toolbar button.rbc-active {
    background-color: #3d71ff;
    color: #ffffff;
  }
  .rbc-month-row {
    margin-bottom: 10px;
  }
  .rbc-now {
    color: #ffffff;
  }
  .rbc-month-view {
    background: white;
  }

  .rbc-month-view .rbc-day-bg {
    border: 1px solid #3d71ff;
    margin-right: 10px;
  }
  .rbc-row-content {
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin: 5px 0 0 0;
  }
  .rbc-row-segment {
    padding: 0 1px 1px 8px;
  }
  .rbc-row-segment .rbc-event {
    width: calc(100% - 18px);
    border-radius: 25px;
  }

  .rbc-month-header {
    padding: 10px;
    border-radius: 25px;
    background: #7b9efd;
    margin-bottom: 15px;
    color: #ffffff;
  }

  .rbc-row-segment .rbc-event .rbc-event-content {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    text-align: center;
    padding: 2px 0px;
  }

  .rbc-date-cell {
    padding-right: 15px;
  }

  .rbc-month-view .rbc-today {
    background-color: rgb(123, 158, 253);
  }
  .rbc-off-range-bg {
    border: 1px solid #ccd0d6 !important;
    background-color: #ffffff;
  }
  .rbc-month-header > .rbc-header,
  .rbc-month-view,
  .rbc-month-row {
    border: none;
  }
  .toolbar-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .rbc-time-view {
    border: 1px solid #3d71ff !important;
  }

  .rbc-time-header.rbc-overflowing {
    border-right: 1px solid #3d71ff !important;
  }

  .rbc-time-header-content,
  .rbc-time-content > * + * > *,
  .rbc-time-view > .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #3d71ff !important;
  }
  .rbc-timeslot-group,
  .rbc-time-content {
    border-bottom: 1px solid #3d71ff !important;
  }
  .rbc-time-content {
    border-top: 1px solid #3d71ff !important;
  }
  .rbc-time-header-cell > .rbc-header {
    border-bottom: 1px solid #3d71ff !important;
    border-left: 1px solid #3d71ff !important;
  }

  .rbc-allday-cell > .rbc-row-bg > .rbc-day-bg {
    border-left: 1px solid #3d71ff !important;
  }

  .date-label-year {
    width: 150px;
  }

  .rbc-row-content {
    display: block;
  }
  .rbc-time-content {
    display: none;
  }
  .rbc-label {
    display: none;
  }

  .rbc-time-header {
    margin-right: 0px !important;
    height: 100%;
  }
  @media (max-width: 640px) {
    .rbc-row.rbc-month-header {
      padding: 4px;
    }
    .rbc-header {
      font-size: 12px;
    }
  }
`;

const Calendar = () => {
  const { SubscriptionData } = useSelector((state) => state.subscription);
  const [labelName, setLabelName] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [view, setView] = useState('month');
  const [open, setOpen] = useState(false);
  const [openDisplayModal, setOpenDisplayModal] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);
  const [editData, setEditData] = useState('');
  const [icsData, setIcsData] = useState('');
  const Calendarref = useRef();
  const [openTrackModal, setOpenTrackModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteid, setDeleteId] = useState();
  const [deleteSubNames, setDeleteSubNames] = useState([]);
  const [selected, setSelected] = useState([]);
  const [assetData, setAssetData] = useState([]);
  const [assetEventModal, setAssetEventModal] = useState({
    open: false,
    data: null,
  });
  const [displayEditAssetModal, setDisplayEditAssetModal] = useState({
    open: false,
    data: null,
    isDisplayMode: false,
  });

  function createFile(e) {
    const date = e.start;
    const eventDate = {
      start: date,
      end: date,
    };
    const summary = e.title;
    const description = e.desc;
    const link = document.querySelector('#downloadLink');
    const href = makeIcsFile(eventDate, summary, description);
    link.href = href;
    link?.click();
  }

  function createIcsFile(startData, endData, summary, description) {
    const eventDate = {
      start: startData,
      end: endData,
    };
    const link = document.querySelector('#downloadLink');
    const href = makeIcsFile(eventDate, summary, description);
    link.href = href;
    link?.click();
  }

  let icsFile = null;

  function makeIcsFile(date, summary, description) {
    const test =
      `BEGIN:VCALENDAR\n` +
      `CALSCALE:GREGORIAN\n` +
      `METHOD:PUBLISH\n` +
      `PRODID:-//Test Cal//EN\n` +
      `VERSION:2.0\n` +
      `BEGIN:VEVENT\n` +
      `UID:test-1\n` +
      `DTSTART:${moment(date.start).format('YYYYMMDDTHHmmss')}\n` +
      `DTEND:${moment(date.end).format('YYYYMMDDTHHmmss')}\n` +
      `SUMMARY:${summary}\n` +
      `DESCRIPTION:${description}\n` +
      `END:VEVENT\n` +
      `END:VCALENDAR`;
    const data = new File([test], { type: 'text/plain' });

    if (icsFile !== null) {
      window.URL.revokeObjectURL(icsFile);
    }

    icsFile = window.URL.createObjectURL(data);
    return icsFile;
  }

  const handledelete = (id) => {
    setDeleteId(id);
  };

  const CustomEvent = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const closeTooltip = () => {
      setShowTooltip(false);
    };

    const openTooltip = () => {
      setShowTooltip(true);
    };

    const data = {
      title: props?.event?.title,
      start: props?.event?.start,
      end: props?.event?.end,
      desc: `Your ${props?.event?.title} subscription is about to expire today`,
      allDay: true,
    };

    return (
      <div onMouseOver={openTooltip} onFocus={openTooltip}>
        {data.title}
        {props?.event?.autoRenewal === true && (
          <Tooltip title="Auto-renewal true" open={showTooltip} onMouseLeave={closeTooltip}>
            <Iconify name="Auto-renewal" icon="il:flag" color="green" />
          </Tooltip>
        )}
        {props?.event?.autoRenewal === false && <Iconify icon="il:flag" color="red" />}
      </div>
    );
  };

  const dispatch = useDispatch();

  const fetchSubscriptions = () => {
    GetsubsResponse(1, 500)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          console.log(err);
        }
      });
  };

  const fetchAssetData = async () => {
    try {
      const res = await getAssetModuleAccounts(1, 10000, 'all');
      setAssetData(res.data.data);
    } catch (err) {
      console.log('fetchAssetData error', err);
    }
  };

  useEffect(() => {
    fetchAssetData();
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    fetchSubscriptions();
  }, [openEventModal]);

  const onOpenAssetEventModal = (data) => {
    setAssetEventModal({
      open: true,
      data: data,
    });
  };

  const onCloseAssetEventModal = () => {
    setAssetEventModal({
      open: false,
      data: null,
    });
  };

  const onDownloadAsset = () => {
    createIcsFile(
      assetEventModal.data.raw.expiryDate,
      assetEventModal.data.raw.expiryDate,
      assetEventModal.data.title,
      assetEventModal.data.desc
    );
    onCloseAssetEventModal();
  };

  const onCloseDisplayEditAssetModal = () => {
    setDisplayEditAssetModal({
      open: false,
      data: null,
      isDisplayMode: false,
    });
  };

  const onDisplayAsset = async () => {
    onCloseAssetEventModal();
    try {
      const res = await getAssetModuleAccountSingle(assetEventModal.data.raw._id);
      if (res.status === 200) {
        setDisplayEditAssetModal({
          open: true,
          data: res.data.data,
          isDisplayMode: true,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onEditAsset = async () => {
    onCloseAssetEventModal();
    try {
      const res = await getAssetModuleAccountSingle(assetEventModal.data.raw._id);
      if (res.status === 200) {
        setDisplayEditAssetModal({
          open: true,
          data: res.data.data,
          isDisplayMode: false,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const _eventData = SubscriptionData?.filter((val) => val.status !== 'Inactive')?.flatMap((val) => {
    const currency = val.currencyAbr === 'INR' ? `₹` : `$`;
    if (val.autoRenewal && val.frequency === 'Trial') {
      const today = moment().startOf('day');
      const nextBillingDate = moment(val.nextBilling).startOf('day');
      const nextMonthStart = today.clone().add(1, 'month').startOf('month').toDate();
      const nextMonthEnd = today.clone().add(1, 'month').endOf('month').toDate();

      const trialEvent = {
        title: `${val.subscriptionName} ${val.frequency} ${currency}${val.amount} Trial`,
        start: nextBillingDate.isBefore(nextMonthEnd) ? nextBillingDate.toDate() : nextMonthStart,
        end: nextBillingDate.isBefore(nextMonthEnd) ? nextBillingDate.toDate() : nextMonthStart,
        desc: `${val.subscriptionName} ${val.frequency} ${currency}${val.amount} Trial for next month`,
        allDay: true,
        autoRenewal: val.autoRenewal,
        id: val._id,
      };

      return trialEvent;
    }
    if (val.autoRenewal) {
      const nextBillingDate = moment(val.nextBilling);
      const dayOfMonth = nextBillingDate.date();
      const currency = val.currencyAbr === 'INR' ? `₹` : `$`;

      const recurringEvent = {
        title: `${val.subscriptionName} ${val.frequency} ${currency}${val.amount} Autorenewal`,
        start: nextBillingDate,
        end: nextBillingDate,
        desc: `${val.subscriptionName} ${val.frequency} ${currency}${val.amount} will be Autorenew`,
        allDay: true,
        autoRenewal: val.autoRenewal,
        id: val._id,
        rrule: {
          freq: val.frequency === 'Annually' ? RRule.YEARLY : RRule.MONTHLY,
          bymonthday: dayOfMonth,
          ...(val.frequency === 'Annually' && { bymonth: [moment(nextBillingDate).month() + 1] }),
          // until: new Date('2030-01-01'),
        },
      };

      const recurrenceRule = new RRule(recurringEvent.rrule);
      const recurringDates = recurrenceRule.between(new Date(val.nextBilling), new Date('2030-01-01'), true);
      const recurringEvents = recurringDates.map((date) => ({
        ...recurringEvent,
        start: new Date(date),
        end: new Date(date),
      }));

      return recurringEvents;
    }
    return {
      title: `${val.subscriptionName} ${val.frequency} ${currency}${val.amount} Non-AutoRenewal`,
      start: new Date(val.nextBilling),
      end: new Date(val.nextBilling),
      desc:
        new Date(val.nextBilling) > new Date()
          ? `${val.subscriptionName} ${val.frequency} ${currency}${val.amount} Non-AutoRenewal`
          : `${val.subscriptionName} ${val.frequency} ${currency}${val.amount} has been Expired`,
      allDay: true,
      autoRenewal: val.autoRenewal,
      id: val._id,
    };
  });

  const generateEventDataForAsset = (data) => {
    const today = moment().startOf('day');
    const nextDate = moment(data.expiryDate).startOf('day');
    const nextMonthStart = today.clone().add(1, 'month').startOf('month').toDate();
    const nextMonthEnd = today.clone().add(1, 'month').endOf('month').toDate();

    const trialEvent = {
      title: `${data?.cardId?.type || 'unknown'} : ${data?.cardId?.name || 'unknown'} Expiry`,
      start: nextDate.isBefore(nextMonthEnd) ? nextDate.toDate() : nextMonthStart,
      end: nextDate.isBefore(nextMonthEnd) ? nextDate.toDate() : nextMonthStart,
      desc: `${data?.cardId?.type || 'unknown'} : ${data?.cardId?.name || 'unknown'} number ${
        data.cardNumber || 'unknown'
      }`,
      allDay: true,
      isAsset: true,
      id: data._id,
      raw: data,
    };

    return trialEvent;
  };

  const filterAssetData = assetData.filter((ex) => ex?.expiryDate && moment(ex?.expiryDate).isValid());

  const newData = filterAssetData.map((ex) => generateEventDataForAsset(ex));
  const eventData = [..._eventData, ...newData];

  const eventStyleGetter = (event) => {
    const currentDate = moment().format('YYYY-MM-DD');
    const days = moment(event.end).diff(currentDate, 'days');
    let backgroundColor;

    if (days <= 0) {
      backgroundColor = '#F36868';
    } else if (event?.autoRenewal === false) {
      backgroundColor = '#ff96aa';
    } else if (days > 0) {
      backgroundColor = '#76e7ce';
    }

    if (event?.isAsset === true) {
      if (event?.raw?.cardId?.type === 'loyalty') {
        backgroundColor = '#5cc4ff';
      } else if (event?.raw?.cardId?.type === 'gift') {
        backgroundColor = '#ab96ff';
      } else {
        backgroundColor = '#989893';
      }
    }

    const style = {
      backgroundColor,
      borderRadius: '10px',
      opacity: 0.8,
      color: '#fff',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  };

  return (
    <Page title="Calendar - SafalSubscriptions">
      <div className="calendars">
        <a style={{ display: 'none' }} href="#" id="downloadLink" download="event.ics">
          Download
        </a>
        <StyledWrapper>
          <Typography
            variant="h4"
            sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF', mr: 5, display: 'flex', alignItems: 'center' }}
          >
            Calendar
            <FaqModal marginLeft="8px" module="Calender" color="#3D71FF" />
          </Typography>
          <div className="rbc-toolbar">
            <span className="rbc-btn-group">
              <button
                type="button"
                onClick={() => {
                  setView('month');
                  Calendarref.current?.props?.onNavigate(new Date());
                  Calendarref.current?.props?.onView('month');
                }}
              >
                Today
              </button>
            </span>
            <span className="rbc-toolbar-label">
              <div className="toolbar-group">
                <PlayCircleFilledIcon
                  sx={{ color: '#3D71FF', transform: 'rotate(180deg)', marginRight: 2 }}
                  onClick={() => {
                    const t = moment(Calendarref.current?.props?.date).subtract(1, view).toDate();
                    Calendarref.current?.props?.onNavigate(t);
                  }}
                />
                <div className="date-label-year">
                  {labelName} {view !== 'month' ? moment(currentYear).year() : ''}
                </div>
                <PlayCircleFilledIcon
                  sx={{ color: '#3D71FF', marginLeft: 2 }}
                  onClick={() => {
                    const t = moment(Calendarref.current?.props?.date).add(1, view).toDate();
                    Calendarref.current?.props?.onNavigate(t);
                  }}
                />
              </div>
            </span>
            <span className="rbc-btn-group">
              {Calendarref.current?.props?.views
                .filter((f, idx) => idx < Calendarref.current?.props?.views?.length - 1)
                .map((val, idx) => (
                  <button
                    className={val === view ? 'rbc-active' : ''}
                    key={idx}
                    type="button"
                    onClick={() => {
                      Calendarref.current?.props?.onView(val);
                      setView(val);
                    }}
                  >
                    {val.charAt(0).toUpperCase() + val.slice(1)}
                  </button>
                ))}
            </span>
          </div>

          <Grid container spacing={2} sx={{ bgcolor: '#fff', mt: 2, p: 2, borderRadius: 2 }}>
            <Grid item xs={12}>
              <Box display="inline-block" sx={{ width: '100%' }} py={1}>
                <BigCalendar
                  ref={Calendarref}
                  events={eventData}
                  onSelectEvent={(e) => {
                    if (e?.isAsset === true) {
                      onOpenAssetEventModal(e);
                      return;
                    }
                    singleEventResponse(e.id).then((val) => {
                      setEditData(val.data.data);
                      setOpenEventModal(true);
                      setIcsData(e);
                    });
                  }}
                  defaultDate={new Date()}
                  localizer={localizer}
                  style={{ height: 580 }}
                  onShowMore={() => setView('day')}
                  tooltipAccessor={(e) => e.desc}
                  eventPropGetter={eventStyleGetter}
                  components={{
                    toolbar: (props) => {
                      setLabelName(props.label);
                      setCurrentYear(props.date);
                      return <></>;
                    },
                    event: (props) => CustomEvent(props, eventData),
                  }}
                />
              </Box>
            </Grid>
            <div style={{ display: 'flex', gap: '16px', padding: '0px 16px', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flex: '1 1 0%',
                  borderRadius: '16px',
                  border: '1px solid #3d71ff',
                  padding: '10px 16px',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                <span style={{ color: '#4074ff', fontWeight: 'bold', fontSize: '22px' }}>Subscription</span>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#76e7ce', borderRadius: '9999px', width: '80px', height: '20px' }} />
                  <span style={{ color: '#76e7ce' }}>Subscription with auto renewal</span>
                </div>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#ff96aa', borderRadius: '9999px', width: '80px', height: '20px' }} />
                  <span style={{ color: '#ff96aa' }}>Subscription without auto renewal</span>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flex: '1 1 0%',
                  borderRadius: '16px',
                  border: '1px solid #3d71ff',
                  padding: '10px 16px',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                <span style={{ color: '#4074ff', fontWeight: 'bold', fontSize: '22px' }}>Asset</span>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#5cc4ff', borderRadius: '9999px', width: '80px', height: '20px' }} />
                  <span style={{ color: '#5cc4ff' }}>Loyalty Card</span>
                </div>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#ab96ff', borderRadius: '9999px', width: '80px', height: '20px' }} />
                  <span style={{ color: '#ab96ff' }}>Gift Card</span>
                </div>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <div style={{ backgroundColor: '#989893', borderRadius: '9999px', width: '80px', height: '20px' }} />
                  <span style={{ color: '#989893' }}>Reward Certificate</span>
                </div>
              </div>
            </div>
          </Grid>
        </StyledWrapper>
      </div>
      <EventClickModal
        openEventModal={openEventModal}
        setOpenEventModal={setOpenEventModal}
        openEditModal={open}
        setOpen={setOpen}
        setOpenDisplayModal={setOpenDisplayModal}
        editData={editData}
        setEditData={setEditData}
        icsData={icsData}
        saveIcs={createFile}
        openDisplayModal={openDisplayModal}
        setOpenEditModal={setOpen}
        openDeleteModal={openDelete}
        setOpenDelete={setOpenDelete}
        setDeleteId={setDeleteId}
        handledelete={handledelete}
        openTrackModal={openTrackModal}
        setOpenTrackModal={setOpenTrackModal}
        subNames={setDeleteSubNames}
        setDeleteSubNames={setDeleteSubNames}
      />

      <DeleteModal
        openDeleteModal={openDelete}
        setOpenDelete={setOpenDelete}
        id={[deleteid]}
        onCloseEdit={() => {
          setOpenDelete(false);
        }}
        subNames={deleteSubNames}
        setSelected={setSelected}
        setDeleteSubNames={setDeleteSubNames}
      />
      <EventClickModalReuse
        open={assetEventModal.open}
        onClose={onCloseAssetEventModal}
        onDisplay={onDisplayAsset}
        onDownload={onDownloadAsset}
        onEdit={onEditAsset}
      />
      <AddAssetModal
        editData={displayEditAssetModal.data}
        type={displayEditAssetModal?.data?.cardId?.type || 'gift'}
        cardData={[]}
        open={displayEditAssetModal.open}
        onClose={onCloseDisplayEditAssetModal}
        isDisplayMode={displayEditAssetModal.isDisplayMode}
        // onChangeLog={onOpenChangelog}
      />
    </Page>
  );
};

export default Calendar;
