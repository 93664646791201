import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  useMediaQuery,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetMemoryDetails, SaveMemoryDetails } from '../../services/Service';
import { setMemory } from '../../store/businessSlice';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';

const Uploads = () => {
  const { Memory } = useSelector((state) => state.business);
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery('(min-width: 1100px)');

  const initialValues = {
    freeUser: Memory.freeUser || 0,
    freeUserUnits: Memory.freeUserUnits || '',
    paidUser: Memory.paidUser || 0,
    betaUser: Memory.betaUser || 0,
    paidUserUnits: Memory.paidUserUnits || '',
    premiumUser: Memory.premiumUser || 0,
    premiumUserUnits: Memory.premiumUserUnits || '',
    betaUserUnits: Memory.betaUserUnits || '',

    freeUserMemory: Memory.freeUserMemory || 0,
    freeUserUnitsMemory: Memory.freeUserUnitsMemory || '',
    paidUserMemory: Memory.paidUserMemory || 0,
    betaUserMemory: Memory.betaUserMemory || 0,
    paidUserUnitsMemory: Memory.paidUserUnitsMemory || '',
    premiumUserMemory: Memory.premiumUserMemory || 0,
    premiumUserUnitsMemory: Memory.premiumUserUnitsMemory || '',
    betaUserUnitsMemory: Memory.betaUserUnitsMemory || '',
  };

  const validateNumber = (fieldName) =>
    Yup.number().min(0, 'Must be a positive number or 0').required(`${fieldName} is required`);
  //   .positive(`${fieldName} must be positive`);

  const validateSelect = () => Yup.string().required(`At least one option must be selected`);

  const validationSchema = Yup.object().shape({
    freeUser: validateNumber('freeUser'),
    paidUser: validateNumber('paidUser'),
    premiumUser: validateNumber('premiumUser'),
    freeUserUnits: validateSelect(),
    paidUserUnits: validateSelect(),
    premiumUserUnits: validateSelect(),
    freeUserMemory: validateNumber('freeUserMemory'),
    paidUserMemory: validateNumber('paidUserMemory'),
    premiumUserMemory: validateNumber('premiumUserMemory'),
    freeUserUnitsMemory: validateSelect(),
    paidUserUnitsMemory: validateSelect(),
    premiumUserUnitsMemory: validateSelect(),
  });

  const ManageMemoryForm = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log('this is memory values => ', values);
      SaveMemoryDetails(values)
        .then((res) => {
          SuccessToast('details updated !!');
          dispatch(setMemory(res.data.data));
        })
        .catch((e) => {
          ErrorToast('Something went wrong !!');
        });
    },
  });

  const user = (usertype, label, unit) => (
    <>
      <FormControl
        fullWidth
        sx={{ mb: 3 }}
        error={ManageMemoryForm.touched[usertype] && Boolean(ManageMemoryForm.errors[usertype])}
      >
        <Field
          disabled={ManageMemoryForm.values[unit] === 'unlimited'}
          as={TextField}
          name={usertype}
          type="number"
          label={label}
          variant="standard"
          placeholder="Please Enter Value......"
          size="small"
          value={ManageMemoryForm.values[usertype]}
          onChange={ManageMemoryForm.handleChange}
          error={ManageMemoryForm.touched[usertype] && Boolean(ManageMemoryForm.errors[usertype])}
          helperText={ManageMemoryForm.touched[usertype] && ManageMemoryForm.errors[usertype]}
        />
      </FormControl>
      <FormControl
        fullWidth
        sx={{ mb: 3 }}
        error={ManageMemoryForm.touched[unit] && Boolean(ManageMemoryForm.errors[unit])}
      >
        <InputLabel htmlFor={unit} sx={{ mt: 1, ml: -2 }}>
          Unit
        </InputLabel>
        <Field
          as={Select}
          name={unit}
          // type="number"
          variant="standard"
          size="small"
          value={ManageMemoryForm.values[unit]}
          onChange={ManageMemoryForm.handleChange}
          // startAdornment={<InputAdornment position="start">$</InputAdornment>}
        >
          <MenuItem
            key="Bytes"
            value="Bytes"
            onClick={(e) => {
              ManageMemoryForm.setFieldValue(unit, e.target.value);
            }}
          >
            Bytes (B)
          </MenuItem>
          <MenuItem
            key="Megabytes"
            value="Megabytes"
            onClick={(e) => {
              ManageMemoryForm.setFieldValue(unit, e.target.value);
            }}
          >
            MegaBytes (MB)
          </MenuItem>
          <MenuItem
            key="Gigabytes"
            value="Gigabytes"
            onClick={(e) => {
              ManageMemoryForm.setFieldValue(unit, e.target.value);
            }}
          >
            GigaBytes (GB)
          </MenuItem>
          <MenuItem
            key="unlimited"
            value="unlimited"
            onClick={(e) => {
              ManageMemoryForm.setFieldValue(unit, e.target.value);
              ManageMemoryForm.setFieldValue(usertype, 0);
            }}
          >
            Unlimited
          </MenuItem>
        </Field>
        {ManageMemoryForm.touched[unit] && ManageMemoryForm.errors[unit] ? (
          <FormHelperText>{ManageMemoryForm.touched[unit] && ManageMemoryForm.errors[unit]}</FormHelperText>
        ) : null}
      </FormControl>
    </>
  );
  return (
    <Box sx={{ mt: 3, ml: 3 }}>
      <FormikProvider value={ManageMemoryForm} validateOnMount>
        <form onSubmit={ManageMemoryForm.handleSubmit}>
          <Grid
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              maxWidth: 'none',
              flexDirection: isDesktop ? 'row' : 'column',
            }}
            md={12}
          >
            <Box>
              <Box mb={3}>
                <Typography>
                  {/* Manage memory per User for uploading attachments :  */}
                  Total Memory Allocated Per User
                </Typography>
              </Box>
              <Grid item xs={12} sm={6} md={12}>
                <Stack direction="row" spacing={2}>
                  {user('betaUser', 'Beta User', 'betaUserUnits')}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Stack direction="row" spacing={2}>
                  {user('paidUser', 'Paid User', 'paidUserUnits')}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Stack direction="row" spacing={2}>
                  {user('premiumUser', 'Premium User', 'premiumUserUnits')}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Stack direction="row" spacing={2}>
                  {user('freeUser', 'Free User', 'freeUserUnits')}
                </Stack>
              </Grid>
            </Box>
            <Box>
              <Box mb={3}>
                <Typography>
                  {/* Manage memory per User for uploading attachments :  */}
                  Total attachments Size Per User
                </Typography>
              </Box>
              <Grid item xs={12} sm={6} md={12}>
                <Stack direction="row" spacing={2}>
                  {user('freeUserMemory', 'Free User', 'freeUserUnitsMemory')}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Stack direction="row" spacing={2}>
                  {user('paidUserMemory', 'Paid User', 'paidUserUnitsMemory')}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Stack direction="row" spacing={2}>
                  {user('premiumUserMemory', 'Premium User', 'premiumUserUnitsMemory')}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <Stack direction="row" spacing={2}>
                  {user('betaUserMemory', 'Beta User', 'betaUserUnitsMemory')}
                </Stack>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              // type="submit"
              onClick={ManageMemoryForm.submitForm}
              disabled={!(ManageMemoryForm.isValid && ManageMemoryForm.dirty)}
              sx={{
                width: '170px',
                height: '45px',
                bgcolor: '#19C25F',
                borderRadius: '30px',
                textTransform: 'none',
                mt: '25px',
                boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
              }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default Uploads;
