/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const CustomNewDashboardBox = ({
  minHeight,
  maxWidth,
  color,
  img,
  title,
  subtitle,
  value,
  onClick,
  count,
  mix,
  value1,
  value2,
  value3,
  isRed,
  isGreen,
  isUnlimited,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        filter: ' drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
        minWidth: maxWidth || '240px',
        minHeight: minHeight || '180px',
        width: '100%',
        display: 'flex',
        position: 'relative',
        backgroundColor: 'white',
        padding: '16px',
        borderRadius: '12px',
        gap: '16px',
      }}
    >
      <img style={{ width: '25%', objectFit: 'contain', filter: 'invert(3%)', flex: 'none' }} src={img} alt="asdsad" />
      <div
        style={{
          filter: 'drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1))',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '60px',
          height: '60px',
          position: 'absolute',
          top: '-20px',
          left: '15px',
          backgroundColor: color,
          borderRadius: '12px',
        }}
      >
        <img
          style={{
            objectFit: 'cover',
            width: '60%',
            height: '60%',
          }}
          src={img}
          alt="one"
        />
      </div>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          justifyContent: 'space-evenly',
          gap: '16px',
          bottom: '20px',
          left: '0',
          width: '100%',
        }}
      >
        {isUnlimited && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>Plan</span>
            <div style={{ height: '4px', backgroundColor: '#4F79EA' }} />
            <span
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              Unlimited
            </span>
          </div>
        )}
        {count !== undefined && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span>{isUnlimited ? 'Actual' : 'Count'}</span>
            <div style={{ height: '4px', backgroundColor: '#4F79EA' }} />
            <span
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {count}
            </span>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ fontSize: '14px' }}>{subtitle}</span>
          <div style={{ height: '4px', backgroundColor: '#4F79EA' }} />
          <span
            style={{
              color: isRed ? 'red' : isGreen ? 'green' : 'black',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {value}
          </span>
        </div>
      </div>
      {mix && (
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            bottom: '20px',
            left: '10px',
            width: 'calc(100% - 20px)',
            justifyContent: 'space-around',
            lineHeight: 'normal',
            gap: '4px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '14px' }}>Loyalty Card</span>
            <div style={{ height: '4px', backgroundColor: '#4F79EA' }} />
            <span
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {value1}
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '14px' }}>Gift Card</span>
            <div style={{ height: '4px', backgroundColor: '#4F79EA' }} />
            <span
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {value2}
            </span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '14px' }}>Reward Certificate</span>
            <div style={{ height: '4px', backgroundColor: '#4F79EA' }} />
            <span
              style={{
                color: 'black',
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              {value3}
            </span>
          </div>
        </div>
      )}
      <div style={{ display: 'flex', marginLeft: '8px', flexDirection: 'column', gap: '4px', flex: '1' }}>
        <span
          style={{
            color: '#3D71FF',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: 'normal',
          }}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

export default CustomNewDashboardBox;
