import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import './HelpVideoInventoryBusiness.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import {
  getHelpVideoInventory,
  deleteHelpVideoInventoryBusiness,
  getHelpVideoInventoryDownload,
  getSignedURL,
} from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddHelpVideoModal from './AddHelpVideoModal';
import DownloadMenu from './DownloadMenu';

const HelpVideoInventoryBusiness = () => {
  const [addModalOpen, setAddModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });

  const [rawData, setRawData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [megaModuleList, setMegaModuleList] = useState([]);
  const [selectedMegaModule, setSelectedMegaModule] = useState([]);
  // const [moduleList, setModuleList] = useState([]);

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getHelpVideoInventory();
      if (res.status === 200) {
        setRawData(res.data.data);
        setFilterData(res.data.data);
        const uniqueMegaModule = [];
        res?.data?.data?.forEach((e) => {
          if (!uniqueMegaModule.includes(e?.megaModule)) uniqueMegaModule.push(e?.megaModule);
        });
        setMegaModuleList(uniqueMegaModule);
      }
    } catch (err) {
      console.log('HelpVideoInventoryBusiness fetchData error', err.message);
    }
  };

  const onAddModalOpenDisplay = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenEdit = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpen = () => {
    setAddModalOpen({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteHelpVideoInventoryBusiness({
        id: data._id,
      });
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalOpen = (data) => {
    setIsDeleteConfirmationModalOpen({
      open: true,
      data,
    });
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectedMegaModuleChange = (value) => {
    setSelectedMegaModule(value);
    if (value === '') {
      setFilterData(rawData);
      return;
    }
    const filterResult = rawData.filter((e) => e.megaModule === value);
    setFilterData(filterResult);
  };

  const downloadTrend = async (type) => {
    try {
      const res = await getHelpVideoInventoryDownload({ fileType: type });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  return (
    <>
      <Helmet>
        <title>Help Video Inventory - SafalSubscriptions</title>
      </Helmet>

      <div id="HelpVideoInventoryBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Help Video Inventory
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <InputLabel id="HelpVideoInventoryBusiness-label01">Mega Module</InputLabel>
              <Select
                labelId="HelpVideoInventoryBusiness-label01"
                value={selectedMegaModule}
                label="Mega Module"
                onChange={(e) => handleSelectedMegaModuleChange(e.target.value)}
              >
                <MenuItem value={''}>All</MenuItem>
                {megaModuleList?.map((e, index) => (
                  <MenuItem value={e} key={index}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="title-action-div">
            <DownloadMenu downloadFile={downloadTrend} />
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          onDelete={onDeleteModalOpen}
          onEdit={onAddModalOpenEdit}
          onDisplay={onAddModalOpenDisplay}
          data={filterData}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddHelpVideoModal
        open={addModalOpen.open}
        data={addModalOpen.data}
        isDisplay={addModalOpen.isDisplay}
        onClose={onAddModalClose}
      />
    </>
  );
};

export default HelpVideoInventoryBusiness;
