import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import toast from 'react-hot-toast';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import CustomButton from '../../../components/UI/CustomButton';
import { downloadAssetModuleAccount, getSignedURL } from '../../../services/Service';

const AssetModuleDownloadMenu = ({ cardType }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = async () => {
    setAnchorEl(null);
  };

  const downloadFile = async (type) => {
    try {
      const res = await downloadAssetModuleAccount(cardType, type);
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    } finally {
      handleClose();
    }
  };

  return (
    <div>
      <Tooltip title="Download">
        <IconButton
          sx={{
            color: '#FFFFFF',
            backgroundColor: '#3D71FF',
            '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
          }}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <FileDownloadIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          marginTop: '6px',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => downloadFile('csv')}>Export as CSV</MenuItem>
        <MenuItem onClick={() => downloadFile('txt')}>Export as TXT</MenuItem>
        <MenuItem onClick={() => downloadFile('dnl')}>Export as DNL</MenuItem>
        <MenuItem onClick={() => downloadFile('pdf')}>Export as PDF</MenuItem>
      </Menu>
    </div>
  );
};

export default AssetModuleDownloadMenu;
