/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Attachment from '../../components/Attachments';
import CollapseShow from '../../components/CollpseShow';
import Iconify from '../../components/Iconify';
import FeedbackModalView from '../../components/Modals/FeedbackModalView';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../../sections/user';
import { getUserFeedbacksAction } from '../../store/feedbackSlice';
import Pagination from '../../layouts/dashboard/Pagination';

const TABLE_HEAD = [
  { id: 'feedbackId', label: 'Feedback ID', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'shortDescription', label: 'Short Description', alignRight: false },
  { id: 'detailDescription', label: 'Detail Description', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'updatedDate', label: 'Updated Date', alignRight: false },
  { id: 'attachment', label: 'Attachment ', alignRight: false },
  { id: 'action', label: 'Comments (View/ Add)', alignRight: false },
  // { id: 'action', label: 'Reply', alignRight: false },
];

export default function Feedback({ search, fromDate, toDate, rowsPerPage, setRowsPerPage }) {
  const isBigScreen = useMediaQuery({
    query: '(min-width: 1200px)',
  });
  const isLaptop = useMediaQuery({ query: '(max-width: 1200px)' });

  const dispatch = useDispatch();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const userData = useSelector((state) => state.login.user);
  const { user } = useSelector((state) => state.feedback.feedback);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const closeAttachmentPopUpRef = useRef(() => {});

  const [open, setOpen] = useState(false);
  const [isEdit, setEdit] = useState('');
  const [view, setView] = useState();
  const [openCol, setopenCol] = React.useState(false);
  useEffect(() => {
    const obj = {
      ...search,
    };
    if (search.supportId < 1) {
      delete obj.supportId;
    }
    dispatch(
      getUserFeedbacksAction({
        id: userData?._id,
      })
    );
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const payload = search;

    if (!payload.supportId) {
      delete payload.supportId;
    }
    if (!payload.status) {
      delete payload.status;
    }

    dispatch(
      getUserFeedbacksAction({
        page: newPage,
        limit: rowsPerPage,
        id: userData?._id,
        filter: { search: payload },
        from: fromDate,
        to: toDate,
      })
    );
  };
  const handleChangeRowsPerPage = (event) => {
    console.log('Rowperpage', event.target.value);
    const payload = search;

    if (!payload.supportId) {
      delete payload.supportId;
    }
    if (!payload.status) {
      delete payload.status;
    }

    dispatch(
      getUserFeedbacksAction({
        limit: event.target.value === -1 ? user?.pagination?.[0]?.total : event.target.value,
        page: 1,
        id: userData?._id,
        filter: { search: payload },
        from: fromDate,
        to: toDate,
      })
    );
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const [hover, setHover] = useState({});
  const [idx, setIdx] = useState();
  const idxClick = (event) => {
    setIdx(event);
    setopenCol(!openCol);
  };
  const StatusColor = (status) => {
    if (status === 'Submitted') {
      return 'blue';
    }
    if (status === 'Opened') {
      return 'orange';
    }
    if (status === 'In-progress') {
      return 'orange';
    }
    if (status === 'Re-opened') {
      return 'red';
    }
    if (status === 'Closed') {
      return 'green';
    }
    return status;
  };

  return (
    <div>
      <div>
        {user?.data?.length !== 0 && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span>
              {user?.data?.length} of {user?.pagination?.[0]?.total}
            </span>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={user?.pagination?.[0]?.total || 0}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={(e, newPage) => handleChangePage(e, newPage + 1)}
            />
          </div>
        )}
      </div>
      {isLaptop && (
        <>
          {user?.data?.length !== 0 ? (
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                <UserListHead headLabel={TABLE_HEAD} isCheckListRemove={'true'} />
                {user?.data?.map((item, i) => (
                  <TableBody key={i}>
                    <>
                      <TableRow hover tabIndex={-1} role="checkbox">
                        <TableCell
                          align="center"
                          padding="checkbox"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            borderBottomLeftRadius: '60px',
                            borderTopLeftRadius: '60px',
                            paddingLeft: 10,
                            fontSize: 12,
                          }}
                          className={classes.paddingLap12}
                        >
                          <p style={{ fontWeight: 'bold' }}>{item.supportId && `SS${item.supportId}`}</p>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            fontSize: 12,
                            backgroundColor: '#FFFFFF',
                            width: '100px !important',
                          }}
                          // onClick={handleClick}
                          onClick={() => {
                            // setopenCol(!openCol);
                            idxClick(i);
                          }}
                          className={classes.paddingLap}
                        >
                          <div style={{ display: 'flex' }}>
                            <p
                              style={{
                                fontSize: 12,
                                width: 120,
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                whitSpace: 'nowrap',
                                // width: '75%',
                                cursor: 'pointer',
                              }}
                            >
                              {item.email}
                            </p>
                          </div>
                          {/* {idx === i && openCol && <CollapseShow openCol item={item} />} */}
                        </TableCell>

                        <LightTooltip title={item.description} placement="bottom">
                          <TableCell
                            align="left"
                            sx={{ fontSize: 12, backgroundColor: '#FFFFFF' }}
                            className={classes.paddingLap}
                          >
                            {`${item.description.length < 10 ? item.description : item.description.slice(0, 10)}...`}
                          </TableCell>
                        </LightTooltip>
                        <LightTooltip title={item.details} placement="bottom">
                          <TableCell
                            align="left"
                            sx={{ fontSize: 12, backgroundColor: '#FFFFFF', width: 100, flexWrap: 'wrap' }}
                            className={classes.paddingLap}
                          >
                            {`${item.details.length < 10 ? item.details : item.details.slice(0, 10)}...`}
                          </TableCell>
                        </LightTooltip>
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: 12,
                            backgroundColor: '#FFFFFF',
                            fontWeight: 'bold',
                            color: StatusColor(item?.status.trim()),
                          }}
                          className={classes.paddingLap}
                        >
                          {item.status}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontSize: 12,
                            backgroundColor: '#FFFFFF',
                          }}
                          className={classes.paddingLap}
                        >
                          {moment(item?.updatedAt).format(
                            userData.country === 'India' ? 'DD/MM/yyyy, hh:mm A' : 'MM/DD/yyyy, hh:mm A'
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontSize: 12, backgroundColor: '#FFFFFF' }}
                          aria-hidden="true"
                          className={classes.paddingLap}
                        >
                          <div style={{ fontSize: 12, display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            {item.attachment.length > 0 ? (
                              <AttachmentHOC
                                childref={closeAttachmentPopUpRef}
                                data={item.attachment.map((attach) => ({ url: attach }))}

                                // handleClickDownload={handleClickDownload}
                              />
                            ) : (
                              <Attachment />
                            )}
                          </div>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{
                            fontSize: 12,
                            backgroundColor: '#FFFFFF',
                            borderBottomRightRadius: '35px',
                            borderTopRightRadius: '35px',
                          }}
                          className={classes.paddingLap}
                        >
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setOpen(true);
                              setView(item._id);
                              setEdit('edit');
                            }}
                          >
                            <Iconify
                              icon="material-symbols:sms"
                              color="#FFFFFF"
                              width={34}
                              height={34}
                              style={{
                                backgroundColor: '#7B9EFD',
                                paddingTop: 8,
                                paddingBottom: 8,
                                borderRadius: 30,
                              }}
                            />
                          </Button>
                          <p style={{ fontSize: 12, marginTop: '-7px' }}>{item?.feedback?.length}</p>
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          ) : (
            <p>No Data Found</p>
          )}
        </>
      )}

      {isBigScreen && (
        <>
          {user?.data?.length !== 0 ? (
            <TableContainer sx={{ maxHeight: 600 }}>
              <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
                <UserListHead headLabel={TABLE_HEAD} isCheckListRemove={'true'} />
                {user?.data?.map((item, i) => (
                  <TableBody key={i}>
                    <>
                      <TableRow hover tabIndex={-1} role="checkbox">
                        <TableCell
                          align="center"
                          padding="checkbox"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            borderBottomLeftRadius: '60px',
                            borderTopLeftRadius: '60px',
                            paddingLeft: 10,
                          }}
                          className={classes.padding12}
                        >
                          <p style={{ fontWeight: 'bold' }}>{item.supportId && `SS${item.supportId}`}</p>
                        </TableCell>

                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            width: '100px !important',
                          }}
                          // onClick={handleClick}
                          onClick={() => {
                            // setopenCol(!openCol);
                            idxClick(i);
                          }}
                          className={classes.padding1}
                        >
                          <LightTooltip title={item.email} placement="bottom">
                            <div style={{ display: 'flex' }}>
                              <p
                                style={{
                                  width: 150,
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whitSpace: 'nowrap',
                                  // width: '75%',
                                  // cursor: 'pointer',
                                }}
                              >
                                {item.email}
                              </p>
                            </div>
                          </LightTooltip>
                          {/* {idx === i && openCol && <CollapseShow openCol item={item} />} */}
                        </TableCell>

                        <LightTooltip title={item.description} placement="bottom">
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }} className={classes.padding1}>
                            {`${item.description.length < 15 ? item.description : item.description.slice(0, 15)}...`}
                          </TableCell>
                        </LightTooltip>
                        <LightTooltip title={item.details} placement="bottom">
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }} className={classes.padding1}>
                            {`${item.details.length < 15 ? item.details : item.details.slice(0, 15)}...`}
                          </TableCell>
                        </LightTooltip>
                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            fontWeight: 'bold',
                            color: StatusColor(item?.status.trim()),
                          }}
                          className={classes.padding1}
                        >
                          {item.status}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor: '#FFFFFF',
                          }}
                          className={classes.padding1}
                        >
                          {moment(item?.updatedAt).format(
                            userData.country === 'India' ? 'DD/MM/yyyy, hh:mm A' : 'MM/DD/yyyy, hh:mm A'
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ backgroundColor: '#FFFFFF' }}
                          aria-hidden="true"
                          className={classes.padding1}
                        >
                          <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                            {item.attachment.length > 0 ? (
                              <AttachmentHOC
                                childref={closeAttachmentPopUpRef}
                                data={item.attachment.map((attach) => ({ url: attach }))}

                                // handleClickDownload={handleClickDownload}
                              />
                            ) : (
                              <LightTooltip placement="bottom" title="No Attachments">
                                <div>
                                  <Attachment />
                                </div>
                              </LightTooltip>
                            )}
                          </div>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            borderBottomRightRadius: '35px',
                            borderTopRightRadius: '35px',
                          }}
                          className={classes.padding1}
                        >
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setOpen(true);
                              setView(item._id);
                              setEdit('edit');
                            }}
                          >
                            <Iconify
                              icon="material-symbols:sms"
                              color="#FFFFFF"
                              width={34}
                              height={34}
                              style={{
                                backgroundColor: '#7B9EFD',
                                paddingTop: 8,
                                paddingBottom: 8,
                                borderRadius: 30,
                              }}
                            />
                          </Button>
                          <p style={{ marginTop: '-7px' }}>{item?.feedback?.length}</p>
                        </TableCell>
                      </TableRow>
                    </>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // margin: '30px 0px',
                // height: '20vh',
                padding: '5% 0',
              }}
            >
              <h2 style={{ color: '#3D71FF' }}>No Data Found</h2>
            </div>
          )}
        </>
      )}
      <FeedbackModalView color="#7B9EFD" openEditModal={open} setOpenEditModal={setOpen} id={view} isEdit={isEdit} />
    </div>
  );
}

const AttachmentHOC = (props) => {
  const attachmentRef = useRef(null);
  const [openDownload, setOpenDownload] = useState(false);

  const handleClickOutside = (event) => {
    if (attachmentRef.current && !attachmentRef.current.contains(event.target)) {
      handleCloseDownload();
    }
  };
  useEffect(() => {
    console.log(' props.childref ================>', props);
    props.childref.current = handleCloseDownload;
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickDownload = (event) => {
    setOpenDownload(event?.currentTarget);
  };

  const handleCloseDownload = () => {
    setOpenDownload(null);
  };
  return (
    <div ref={attachmentRef}>
      <Button onClick={(e) => handleClickDownload(e)}>
        <Attachment
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          handleCloseDownload={handleCloseDownload}
          {...props}
        />
      </Button>
    </div>
  );
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })
);
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
      outline: 'none',
      borderRadius: 60,
    },
  },
});

const useStyles = makeStyles({
  modal_box: {
    borderRadius: '10px !important',
    border: 'none !important',
    width: '40%',
    backgroundColor: '#F2F2F2',
  },
  modal_edit_box: {
    borderRadius: '10px !important',
    border: 'none !important',
    width: '40%',
    backgroundColor: '#F2F2F2',
  },
  modal_edit_img_box: {
    borderRadius: '10px !important',
    border: 'none !important',
    // width: '35%',
    backgroundColor: '#F2F2F2',
    margin: 0,
    padding: 0,
  },
  flex_div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
  },
  close_icon_div: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  close_edit_icon_div: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  edit_feedback_h2: {
    backgroundColor: '#7B9EFD',
    borderRadius: 60,
    color: 'white',
    padding: '10px 30px',
    textAlign: 'center',
  },
  text_edit: {
    backgroundColor: 'white',
    borderRadius: 60,
    // padding: '30px 10px',
    width: '100%',
  },
  feedback_h2: {
    backgroundColor: '#7B9EFD',
    borderRadius: 60,
    color: 'white',
    padding: '10px 30px',
    textAlign: 'center',
  },
  edit_modal_typo: {
    backgroundColor: 'white',
    borderRadius: 60,
    padding: '10px 40px',
    width: '100%',
    fontSize: 16,
    display: 'flex',
  },
  edit_modal_label: {
    width: '100px !important',
  },
  button_flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  feedback_ok_btn: {
    backgroundColor: '#7B9EFD',
    borderRadius: 30,
    padding: '8px 30px',
    fontSize: 15,
    fontWeight: 'bold',
  },
  attachment_edit_flex: {
    backgroundColor: 'white',
    borderRadius: 60,
    // padding: '0px 10px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  padding1: {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    paddingRight: 5,
    paddingLeft: 5,
  },
  padding12: {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    paddingRight: 5,
    paddingLeft: 10,
  },
  paddingLap: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 5,
    paddingLeft: 5,
  },
  paddingLap12: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 5,
    paddingLeft: 10,
  },
});
