import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  '@media (max-width: 640px)': {
    width: '98vw !important',
  },
};

const MultiOptionModalNew = ({ oncCancel, onsSubmit, onClose, open }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" align="center">
            Subscription Already Exist!!!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center">
            Which Action Do you Want to Perform?
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, justifyContent: 'space-around' }}>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <Button
                variant="contained"
                onClick={() => oncCancel()}
                sx={{ borderRadius: '30px', backgroundColor: '#3D71FF' }}
              >
                Cancel
              </Button>
            </Box>
            <Box sx={{ display: 'flex', mb: 2 }}>
              <Button
                variant="contained"
                onClick={() => onsSubmit()}
                sx={{ borderRadius: '30px', backgroundColor: '#3D71FF' }}
              >
                Create
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MultiOptionModalNew;
