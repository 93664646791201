import { Box, Card } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';
import ReactSpeedometer from 'react-d3-speedometer';

import { GetMemoryUser } from '../../services/Service';

const NewStorageChart = () => {
  const { user } = useSelector((state) => state.login);

  const [chartValue, setChartValue] = useState({
    occupied: 0,
    free: 0,
    max: 0,
  });

  function formatNumber(number) {
    const exactValue = number < 1024 ? `${number}MB` : `${parseFloat(number / 1024).toFixed(2)}GB`;

    return exactValue;
  }

  useEffect(() => {
    GetMemoryUser().then((res) => {
      if (res.data.success === true) {
        const occupied = res.data.data.occupied * 1024;
        const free = res.data.data.free * 1024;
        const max = res.data.data.max * 1024;
        setChartValue({
          occupied: Math.round((occupied + Number.EPSILON) * 100) / 100,
          free: Math.round((free + Number.EPSILON) * 100) / 100,
          max: Math.round((max + Number.EPSILON) * 100) / 100,
        });
      }
    });
  }, []);

  const Options = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    barWidth: 40,
    legend: {},
    grid: {
      left: '3%',
      right: '5%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      axisLabel: {
        rotate: window.innerWidth < 640 ? 45 : 0,
      },
    },
    yAxis: {
      type: 'category',
      data: ['Storage'],
    },
    series: [
      {
        name: 'Consumed',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return formatNumber(params.value);
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: [+chartValue.occupied],
      },
      {
        name: 'Available',
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function (params) {
            return formatNumber(params.value);
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: [chartValue.free === null ? Infinity : +chartValue.free],
      },
    ],
  };

  const labels = [
    { name: 'Consumed', color: '#3e71ff' },
    { name: 'Available', color: '#01bc56' },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <span style={{ fontSize: '14px', color: '#3e71ff', fontWeight: 'bold', whiteSpace: 'nowrap' }}>Storage</span>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ReactSpeedometer
          forceRender
          maxSegmentLabels={1}
          customSegmentStops={[0, chartValue?.occupied || 0, chartValue?.max || 0]}
          segmentColors={['#3e71ff', '#01bc56']}
          needleColor={'#3e71ff'}
          currentValueText={`${((chartValue?.occupied / chartValue?.max) * 100).toFixed(2) || 0} %`}
          minValue={0}
          maxValue={chartValue?.max || 0}
          value={chartValue?.occupied || 0}
          textColor="#3e71ff"
          ringWidth={30}
          height={180}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', gap: '8px' }}>
        {labels?.map((e, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <div style={{ width: '20px', height: '20px', backgroundColor: e?.color, borderRadius: '9999px' }} />
            <span style={{ whiteSpace: 'nowrap', fontSize: '14px' }}>{e?.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewStorageChart;
