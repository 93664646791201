import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, OutlinedInput, Switch, TableContainer, TableRow, Tooltip } from '@mui/material';
import { Image } from 'antd';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import moment from 'moment';

import DateField from '../../components/UI/DateField';
import CustomButton from '../../components/UI/CustomButton';
import useSortHook from '../../hooks/useSortHook';
import {
  CustomTableCellForBody,
  CustomTableCellForLoading,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import UserCommentsModal from '../../components/Modals/UserCommentsModal';
import {
  downloadSubscriptionsTrend,
  getSubLabelReport,
  getSubscriptionsSpendBudget,
  getSubscriptionsSpendData,
  getSubscriptionsTrend,
} from '../../services/Service';
import useDebounce from '../../hooks/useDebounce';
import Iconify from '../../components/Iconify';
import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import SubscriptionTrendFilterMenu from './SubscriptionTrendFilterMenu';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import CustomRating from '../../components/UI/CustomRating';
import Inlineloader from '../../components/Loader/InlineLoader';

const SubscriptionSpendReport = () => {
  const [filterState, setFilterState] = useState({
    to: '',
    from: '',
  });
  const { user } = useSelector((state) => state.login);

  const [isLoading, setIsLoading] = useState(true);
  const [spendTableData, setSpendTableData] = useState([]);
  const [budgetTableData, setBudgetTableData] = useState([]);
  const [budgetTableMode, setBudgetTableMode] = useState('average');
  const currencySymbol = user?.country === 'United States' ? '$' : '₹';

  // DeBounce Function
  useDebounce(
    async () => {
      setIsLoading(true);
      await fetchSpendData();
      await fetchBudgetData();
      setIsLoading(false);
    },
    [filterState],
    1000
  );
  const fetchSpendData = async () => {
    try {
      const _filterState = { ...filterState };
      if (_filterState.to) {
        _filterState.to = moment(_filterState.to).add(1, 'days').toISOString();
      }
      if (_filterState.from) {
        _filterState.from = moment(_filterState.from).add(1, 'days').toISOString();
      }

      const res = await getSubscriptionsSpendData(_filterState);
      setSpendTableData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchBudgetData = async () => {
    try {
      const _filterState = { ...filterState };
      if (_filterState.to) {
        _filterState.to = moment(_filterState.to).add(1, 'days').toISOString();
      }
      if (_filterState.from) {
        _filterState.from = moment(_filterState.from).add(1, 'days').toISOString();
      }

      const res = await getSubscriptionsSpendBudget(_filterState);
      setBudgetTableData(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  const downloadTrend = async (type) => {
    try {
      // const res = await downloadSubscriptionsTrend({ ...filterState, fileType: type });
      // const signedURLRes = await getSignedURL({
      //   url: res.data.url,
      // });
      // const link = document.createElement('a');
      // link.href = signedURLRes.data;
      // link.setAttribute('download', `file`);
      // document.body.appendChild(link);
      // link.click();
      // toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  const getColorForValue = (value) => {
    const bb = budgetTableMode === 'average' ? value.avgBudget : value.snapshotBudget;
    if (value.totalSpend > bb) {
      return '#ff0000';
    }
    return '#3e71ff';
  };

  const budgetData2 = budgetTableData.map((e) => e.totalSpend);

  const budgetOption = {
    title: {
      text: 'Budget vs Spend', // Your desired title here
      subtext: budgetTableMode === 'average' ? 'Average Budget Wise' : 'Snapshot Budget Wise',
      left: 'left', // Adjust the position of the title if needed
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        return `${params[0].name}<br/>${params[0].seriesName}: ${currencySymbol}${new Intl.NumberFormat().format(
          params[0].value
        )}<br/>${params[2].seriesName}: ${currencySymbol}${new Intl.NumberFormat().format(params[2].value)}<br/>${
          params[1].seriesName
        }: ${currencySymbol}${new Intl.NumberFormat().format(params[1].value)}`;
      },
    },
    legend: {
      data: ['Budget', 'Forecasted Spend'],
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        data: budgetTableData.map((e) => e.date),
        axisLabel: {
          interval: 0, // Show all labels
          rotate: 45, // Rotate labels to prevent overlap
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          // formatter: `${currencySymbol} {value}`,
          formatter: function (params) {
            return `${currencySymbol}${new Intl.NumberFormat().format(params.value)}`;
          },
        },
      },
    ],
    series: [
      {
        name: 'Budget',
        type: 'line',
        yAxisIndex: 0,
        tooltip: {
          valueFormatter: function (value) {
            return `${currencySymbol} ${value}`;
          },
        },
        data: budgetTableData.map((e) => (budgetTableMode === 'average' ? e.avgBudget : e.snapshotBudget)),
      },
      {
        name: 'Cumulative Forecasted Spend',
        type: 'bar',
        data: budgetData2,
        stack: 'Total',
        itemStyle: {
          borderColor: 'transparent',
          color: 'transparent',
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent',
          },
        },
      },
      {
        name: 'Forecasted Spend',
        type: 'bar',
        stack: 'Total',
        color: '#3e71ff',
        // data: budgetData3,
        data: budgetTableData.map((value) => ({
          value: value.spend,
          itemStyle: {
            color: getColorForValue(value),
          },
        })),
      },
    ],
  };
  const spendOption = {
    title: {
      text: 'Subscriptions Spend trend (Snapshot)', // Your desired title here
      left: 'left', // Adjust the position of the title if needed
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        return `${params[0].name}<br/>${params[0].seriesName}: ${currencySymbol}${new Intl.NumberFormat().format(
          params[0].value
        )}`;
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: { show: true },
      },
    },
    calculable: true,
    xAxis: [
      {
        type: 'category',
        data: spendTableData.map((e) => e.date),
        axisLabel: {
          interval: 0, // Show all labels
          rotate: 45, // Rotate labels to prevent overlap
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          // formatter: `${currencySymbol} {value}`,
          formatter: function (params) {
            return `${currencySymbol}${new Intl.NumberFormat().format(params.value)}`;
          },
        },
      },
    ],
    series: [
      {
        name: 'Spend',
        type: 'bar',
        data: spendTableData.map((e) => e.value),
        color: '#3e71ff',
        label: {
          show: true,
          position: 'top',
          rotate: 45,
          distance: 10,
          // formatter: `${currencySymbol} {c}`,
          formatter: function (params) {
            return `${currencySymbol}${new Intl.NumberFormat().format(params.value)}`;
          },
        },
      },
    ],
  };

  const handleClearFilters = () => {
    setFilterState({
      to: '',
      from: '',
    });
  };

  const isThisResetFilter = filterState.from !== '' || filterState.to !== '';

  if (isLoading) return <Inlineloader />;

  return (
    <div style={{ height: '1000px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '16px' }} />
        <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          {/* <SearchStyle
            value={filterState.search}
            onChange={(e) => setFilterState((prev) => ({ ...prev, search: e.target.value }))}
            placeholder="Search Company name & type"
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
              </InputAdornment>
            }
          /> */}
          {/* <SubscriptionTrendFilterMenu state={filterState} companyData={tableData} onFilterApply={onFilterApply} /> */}
          {/* <ReportsTabsDownloadMenu downloadFile={downloadTrend} /> */}
          {/* <CustomButton text="Refresh" icon={<RefreshIcon />} onClick={fetchSubscriptionsTrend} /> */}
          <DateField
            width="140px"
            value={filterState.from || ''}
            variant="standard"
            label="Date From"
            onChange={(e) => setFilterState((prev) => ({ ...prev, from: e.toISOString() }))}
          />
          <DateField
            width="140px"
            value={filterState.to || ''}
            variant="standard"
            label="Date To"
            onChange={(e) => setFilterState((prev) => ({ ...prev, to: e.toISOString() }))}
          />
          {isThisResetFilter && (
            <Tooltip title="Clear Filter">
              <IconButton
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={handleClearFilters}
              >
                <FilterAltOffIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Refresh">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={() => {
                fetchSpendData();
                fetchBudgetData();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', height: '600px', gap: '12px' }}>
        <div className="w-full-break-x-flex" style={{ display: 'flex', flex: '1', gap: '12px' }}>
          <div
            className="w-full-break-x"
            style={{
              flex: 'none',
              width: '50%',
              padding: '12px',
              borderRadius: '16px',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
            }}
          >
            <ReactECharts option={spendOption} style={{ height: '100%', minHeight: '400px', maxHeight: '500px' }} />
          </div>
          <div
            style={{
              flex: '1',
              padding: '12px',
              borderRadius: '16px',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
              position: 'relative',
            }}
          >
            <ReactECharts option={budgetOption} style={{ height: '100%', minHeight: '400px', maxHeight: '500px' }} />
            <div style={{ position: 'absolute', top: '15px', right: '40px', display: 'flex', alignItems: 'center' }}>
              <span style={{ fontSize: '12px' }}>Average</span>
              <Switch
                style={{ color: '#2065d1' }}
                checked={budgetTableMode === 'snapshot'}
                onChange={(e) => setBudgetTableMode(e.target.checked ? 'snapshot' : 'average')}
                size="small"
              />
              <span style={{ fontSize: '12px' }}>Snapshot</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSpendReport;
