import { filter } from 'lodash';
import React, { useState } from 'react';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
// material
import {
  Table,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  Box,
  Menu,
  MenuItem,
  Fab,
  IconButton,
  Tooltip,
  OutlinedInput,
  InputAdornment,
  Link,
  TablePagination,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import moment from 'moment';
import Swal from 'sweetalert2';

// import { CSVLink } from 'react-csv';
import starImage from '../../assets/star.png';
import starEmptyImage from '../../assets/starEmpty.png';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../sections/user';
// mock
import { setSubscriptions } from '../../store/subscriptionSlice';
import SubscriptionModal from '../../components/Modals/SubscriptionModal';
import {
  downloadAttachmentFile,
  downloadSubData,
  getCardScheme,
  getPaymentInstrument,
  getPaymentMode,
  getSubLabel,
  GetsubsResponse,
  SavesubsBulkResponse,
  SearchSubscription,
} from '../../services/Service';
import EditModal from '../../components/Modals/EditModal';
import DeleteModal from '../../components/Modals/DeleteModal';
import TrackModal from '../../components/Modals/TrackModal';
import Pagination from '../../layouts/dashboard/Pagination';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';
import Attachment from '../../components/Attachments';
import Currency from '../../components/Currency';
import ActionMenu from '../business/ActionMenu';
import UploadErrorModal from '../../components/Modals/UploadErrorModal';
import DisplayModal from '../../components/Modals/DisplayModal';
import { debounce } from '../../utils/fn';
import HeySafalSpeechRecognition from '../../components/HeySafalSpeechRecognition/HeySafalSpeechRecognition';
import PdfStatementImport from '../../components/PdfStatementImport/PdfStatementImport';
import InitialFeedbackModal from '../../components/InitialFeedback/InitialFeedbackModal';
import CustomRating from '../../components/UI/CustomRating';
import FaqModal from '../../components/Modals/FaqModal';
import SubscriptionFilterMenu from './SubscriptionFilterMenu';

import subMetaActiveIcon from '../../assets/subMetaActive.png';
import subMetaMonthlyIcon from '../../assets/subMetaMonthly.png';
import subMetaYearIcon from '../../assets/subMetaYear.png';
import { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import EmailCrawlerModal from '../../components/EmailCrawlerModal/EmailCrawlerModal';

// ----------------------------------------------------------------------

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  marginLeft: 'auto',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const TABLE_HEAD = [
  { id: 'action', label: 'Act.', alignRight: false },
  // { id: 'subType.company.companyType', label: 'Type', alignRight: false , sort : true },
  { id: 'company.companyType', label: 'Type', alignRight: false, sort: true },
  // { id: 'edit', label: 'Edit', alignRight: false },
  // { id: 'delete', label: 'Delete', alignRight: false },
  // { id: 'track', label: 'Track', alignRight: false },
  // { id: 'attachments', label: '', alignRight: false },
  { id: 'logo', label: 'Logo', alignRight: false },
  {
    id: 'subscriptionName',
    label: 'Name',
    alignRight: false,
    sort: true,
  },
  { id: 'count', label: 'No.', alignRight: false },
  { id: 'website', label: 'Website', alignRight: false },
  { id: 'frequency', label: 'Frequency', alignRight: false, sort: true },
  { id: 'safals', label: 'Safals', alignRight: false, sort: true },
  { id: 'rating', label: 'Rating', alignRight: false, sort: true },
  { id: 'amount', label: 'Amount', alignRight: false, sort: true },
  // { id: 'startDate', label: 'Start Date', alignRight: false, sort: true },
  {
    id: 'nextBilling',
    label: 'Next Billing Date',
    alignRight: false,
    sort: true,
  },
  {
    id: 'expiryDate',
    label: 'Expiry Date',
    alignRight: false,
    sort: true,
  },
  { id: 'autoRenewal', label: 'Auto Renewal', alignRight: false, sort: true },
  { id: 'label', label: 'Label', alignRight: false, sort: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (orderBy === 'company.companyType') {
    if (b.company.companyType < a.company.companyType) {
      return -1;
    }
    if (b.company.companyType > a.company.companyType) {
      return 1;
    }
    return 0;
  }

  if (orderBy === 'amount') {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
    return -1;
  }
  if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query, cname) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (query) {
    if (cname) {
      return filter(array);
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Subscription() {
  // const [page, setPage] = useState(0);
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const { TotalSubscriptions } = useSelector((state) => state.subscription);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);
  const [editData, setEditData] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const [openTrackModal, setOpenTrackModal] = useState(false);
  const [isInitialFeedbackModal, setIsInitialFeedbackModal] = useState(false);

  const [pagination, setPagination] = useState({ page: 1, limit: 5 });
  const [count, setCount] = useState(TotalSubscriptions);

  const [orderBy, setOrderBy] = useState('created_at');
  const [open, setOpen] = useState(false);
  const [openDisplayModal, setOpenDisplayModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [openSub, setOpenSub] = useState(false);
  const [cname, setCname] = useState([]);
  const [deleteid, setDeleteId] = useState();
  const [labelList, setLabelList] = useState([]);
  const [deleteSubNames, setDeleteSubNames] = useState([]);
  const [actionMenu, setActionmenu] = React.useState(null);
  const [openUploadErrorModal, setOpenUploadErrorModal] = React.useState(false);
  const [pdfModalOpen, setPdfModalOpen] = React.useState(false);
  const [UploadErrorModalData, setUploadErrorModalData] = React.useState({
    uploadCount: 0,
    Errors: [],
    Uploaded: [],
  });
  const actionOpen = Boolean(actionMenu);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { SubscriptionData } = useSelector((state) => state.subscription);

  const { user } = useSelector((state) => state.login);
  const openMenu = Boolean(anchorEl);
  const openMenu2 = Boolean(anchorEl2);

  const [filterData, setFilterData] = useState({});

  const [openEventModal, setOpenEventModal] = useState(false);

  const [subscriptionMetaInfo, setSubscriptionMetaInfo] = useState(null);

  const [paymentModeList, setPaymentModeList] = useState([]);
  const [paymentInstrumentList, setPaymentInstrumentList] = useState([]);
  const [paymentCardSchemeList, setPaymentCardSchemeList] = useState([]);

  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);
  const subscriptions_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'subscriptions')?.submenu ?? [];

  const handleActionClick = (event) => {
    setActionmenu(event.currentTarget);
  };

  const handleActionClose = () => {
    fetchSubscriptions();
    setActionmenu(null);
  };

  const handleDataFromUserListToolbar = (data) => {
    setFilterData(data);
  };

  const fetchSubscriptions = async () => {
    try {
      const res = await GetsubsResponse(pagination.page, pagination.limit, orderBy, order, filterData);
      if (res.data.success === true) {
        setSubscriptionMetaInfo(res.data?.metaData);
        dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
        setPagination((prev) => ({ ...prev, limit: res.data.pagination[0].pageSize }));
      }
    } catch (err) {
      if (err.response.status === 401) navigate('/login');
    }
  };

  const fetchLabelList = async () => {
    try {
      const res = await getSubLabel();
      setLabelList(res.data.data);
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) navigate('/login');
    }
  };

  const fetchPaymentModeInfo = async () => {
    try {
      const res1 = await getPaymentMode({ status: true });
      setPaymentModeList(res1.data.data);
      const res2 = await getPaymentInstrument({ status: true });
      setPaymentInstrumentList(res2.data.data);
      const res3 = await getCardScheme({ status: true });
      setPaymentCardSchemeList(res3.data.data);
      return res2.data.data;
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    GetsubsResponse(pagination.page, pagination.limit, orderBy, order, filterData)
      .then((res) => {
        if (res.data.success === true) {
          setSubscriptionMetaInfo(res.data?.metaData);
          // setCount(Math.ceil(res.data.pagination[0].totalItems / pagination.limit));
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/login');
        }
      });
  }, [pagination.page, pagination.limit]);

  React.useEffect(() => {
    if (SubscriptionData.length === 0 && pagination.page > 1) {
      setPagination((prev) => ({ ...prev, page: prev.page - 1 }));
    }
    setCount(Math.ceil(TotalSubscriptions / pagination.limit));
  }, [SubscriptionData]);

  React.useEffect(() => {
    fetchLabelList();
    fetchPaymentModeInfo();
  }, []);

  React.useEffect(() => {
    setCount(TotalSubscriptions);
  }, [TotalSubscriptions]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const ordr = isAsc ? 'desc' : 'asc';
    setOrder(ordr);
    setOrderBy(property);

    GetsubsResponse(pagination.page, pagination.limit, property, ordr, filterData)
      .then((res) => {
        if (res.data.success === true) {
          setSubscriptionMetaInfo(res.data?.metaData);
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/login');
        }
      });
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = SubscriptionData?.map((n) => n._id);
      const newDeleteds = SubscriptionData?.map((n) => n.subscriptionName);
      setDeleteSubNames(newDeleteds);
      setSelected(newSelecteds);
      return;
    }
    setDeleteSubNames([]);
    setSelected([]);
  };

  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row._id);
    let newSelected = [];
    let newDeleted = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, row._id);
      newDeleted = newDeleted.concat(deleteSubNames, row.subscriptionName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newDeleted = newDeleted.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newDeleted = newDeleted.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      newDeleted = newDeleted.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setDeleteSubNames(newDeleted);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    GetsubsResponse(newPage + 1, pagination.limit, orderBy, order, filterData)
      .then((res) => {
        if (res.data.success === true) {
          setSubscriptionMetaInfo(res.data?.metaData);
          // setCount(Math.ceil(res.data.pagination[0].totalItems / pagination.limit));
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/login');
        }
      });
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
  };
  const handleChangeRowsPerPage = (event) => {
    setPagination((prev) => ({ ...prev, limit: event.target.value }));
    GetsubsResponse(pagination.page, event.target.value, orderBy, order, filterData)
      .then((res) => {
        if (res.data.success === true) {
          setSubscriptionMetaInfo(res.data?.metaData);
          // setCount(Math.ceil(res.data.pagination[0].totalItems / pagination.limit));
          setPagination((prev) => ({ ...prev, page: 1 }));
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/login');
        }
      });
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpenSub = () => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setOpenSub(true);
    }
  };

  const handleClickMenu = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClickMenu2 = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl2(event.currentTarget);
    }
  };

  const handleClickSubscriptionFinder = () => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setPdfModalOpen(true);
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
  };

  const handleDownload = (url) => {
    downloadAttachmentFile({ url })
      .then((res) => {
        console.log('this is attachment download => ', res.data);
        if (res.data.success === true) {
          const link = document.createElement('a');
          link.href = res.data.data;
          link.setAttribute('download', `file`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log('this is attachment err => ', err);
        ErrorToast(err.response.data.message || 'Something went wrong !!');
      });
  };

  const uploadCSV = (e) => {
    console.log('SavesubsBulkResponse', e.target.files);
    SavesubsBulkResponse({ csvfile: e.target.files[0], event: { call: 'savebulk' } })
      .then((d) => {
        if (d.data?.success) {
          if (d.data.uploadedWithErrors === true || d.data.Uploaded.length > 0) {
            handleCloseMenu();
            setUploadErrorModalData({
              uploadCount: d.data.data.insertedCount,
              Errors: d.data.Errors,
              Uploaded: d.data.Uploaded,
            });
            setOpenUploadErrorModal(true);
          }

          GetsubsResponse(pagination.page, pagination.limit, orderBy, order, filterData)
            .then((res) => {
              if (res.data.success === true) {
                setSubscriptionMetaInfo(res.data?.metaData);
                dispatch(
                  setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems })
                );
                // SuccessToast('successfully inserted in bulk !!');
                SuccessToast(res.data.message);
              } else {
                ErrorToast(res.data?.message);
              }
            })
            .catch((err) => {
              if (err.response.status === 401) {
                navigate('/login');
              }
            });
        } else {
          ErrorToast(d.data?.message);
        }
      })
      .catch((err) => {
        console.log('this is upload err => ', err);
        // console.log('this is upload err ==> ', err.response.data.Errors);
        // console.log('this is upload err  ==> ', err.response.data.data.insertedCount
        if (err?.response?.data?.success === false) {
          ErrorToast(`${err?.response?.data?.message}`);
        } else if (err?.response?.data && err.response.data.uploadedWithErrors === false) {
          handleCloseMenu();
          setUploadErrorModalData({
            uploadCount: 0,
            Errors: err.response.data.Errors,
            Uploaded: err.response.data.Uploaded,
          });
          setOpenUploadErrorModal(true);
        }
        if (err?.response?.data) {
          if (err?.response?.data?.message) {
            ErrorToast(err.response.data.message);
          } else {
            ErrorToast(`${err.response.data.Errors?.length || '0'} Error/s while uploading !!`);
          }
        } else {
          ErrorToast(`Something went wrong.. !!`);
        }
      });
    //   },
    // });
    // } else {
    //   ErrorToast('File is not .CSV!!');
    // }
  };

  const sortName = (a, b) => {
    if (a.subscriptionName < b.subscriptionName) {
      return -1;
    }
    if (a.subscriptionName > b.subscriptionName) {
      return 1;
    }
    return 0;
  };

  const filteredSubs = applySortFilter(SubscriptionData, getComparator(order, orderBy), filterName, cname);

  const isUserNotFound = filteredSubs.length === 0 && SubscriptionData?.length > 0;

  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(() => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: 1,
      minWidth: 180,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          marginRight: 1.5,
        },
      },
    },
  }));

  const handledelete = (id) => {
    setDeleteId(id);
    setSelected([]);
  };
  const handleDeleteMultiple = (id) => {
    setDeleteId(id);
  };

  const groupData = [...new Set(filteredSubs.map((row) => row.subscriptionName))];

  const displayData = SubscriptionData;
  // const displayData = groupData.map((type) => filteredSubs.filter((row) => row.subscriptionName === type)).flat();
  const handleSubscriptionSearch = (val) => {
    if (val === '') {
      GetsubsResponse(pagination.page, pagination.limit, orderBy, order, filterData).then((res) => {
        if (res.data.success === true) {
          setSubscriptionMetaInfo(res.data?.metaData);
          // setCount(Math.ceil(res.data.pagination[0].totalItems / pagination.limit));
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
        }
      });
    } else {
      SearchSubscription({ name: val })
        .then((res) => {
          setCname([]);
          if (res.data.success === true) {
            dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.data?.length }));
          }
        })
        .catch((err) => {
          setFilterName('subscriptionName');
          setCname(['']);
          dispatch(setSubscriptions({ subscriptions: [], totalItems: 0 }));
          // ErrorToast(err.response.data.message);
        });
    }
  };

  const CurrSymbol = user?.country === 'United States' ? '$' : '₹';

  const debouncedSubscriptionSearch = React.useMemo(() => debounce(handleSubscriptionSearch, 1000), [pagination.limit]);
  return (
    <Page title="Subscription - SafalSubscriptions">
      {/* <Stack direction={{ sm: 'column', lg: 'row', mb: 3 }} alignItems="center" justifyContent="space-between"> */}

      <Stack
        direction={{ sm: 'column', lg: 'row' }}
        alignItems={{ xs: 'start', sm: 'center' }}
        justifyContent="space-between"
        marginBottom={0}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            Your Subscriptions
          </Typography>
          <FaqModal marginLeft="4px" module="Subscriptions" color="#3D71FF" />
        </Box>

        <SearchStyle
          // value={filterName}
          onChange={(e) => debouncedSubscriptionSearch(e.target.value)}
          placeholder="Search Company..."
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
        <Box sx={{ display: { xs: 'inline', sm: 'flex' }, alignItems: 'center', marginLeft: '16px' }}>
          {/* Mass Subscription Upload - CSV file */}
          <Tooltip
            title={
              <>
                {'Mass Subscription Upload - CSV file'}
                <br />
                {
                  'Go To Profile > Settings > Templates Tab to use the template of Mass Upload along with detailed instructions'
                }
              </>
            }
          >
            <IconButton
              aria-controls={openMenu2 ? 'demo-customized-menu2' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu2 ? 'true' : undefined}
              onClick={handleClickMenu2}
              sx={{
                display: 'flex',
                alignItems: 'center',
                // ml: 2,
                backgroundColor: '#3D71FF',
                height: '40px',
                width: '40px',
                '&:hover': { backgroundColor: '#3D71FF' },
              }}
            >
              <img src="/assets/images/upload.png" alt="downloadImage" />
            </IconButton>
          </Tooltip>
          <StyledMenu
            id="demo-customized-menu2x"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button2x',
            }}
            sx={{ zIndex: 1200 }}
            anchorEl={anchorEl2}
            open={openMenu2}
            onClose={handleCloseMenu}
          >
            <MenuItem disableRipple html>
              <input
                type="file"
                accept=".csv ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                hidden
                name="kkk"
                id="kkk"
                // onClick={() => handleCloseMenu()}
                onChange={(e) => uploadCSV(e)}
              />
              <label htmlFor="kkk">Mass Subscription Upload. - CSV file</label>
            </MenuItem>
          </StyledMenu>
          {/* Download */}
          <Box
            sx={{
              ml: 2,
              height: '100%',
              alignItems: 'center',
              '@media (max-width: 600px)': {
                alignItems: 'start',
                mt: 2,
              },
            }}
          >
            {subscriptions_DynamicMenuState?.includes('Email Crawler') && <EmailCrawlerModal />}
            <Tooltip title="Download">
              <IconButton
                size="large"
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  ml: '-5px',
                  '&:hover': { backgroundColor: '#3D71FF' },
                }}
                color="primary"
                onClick={handleClickMenu}
              >
                <img src="/assets/images/download.png" alt="downloadImage" />
              </IconButton>
            </Tooltip>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
            >
              <MenuItem
                onClick={() => {
                  downloadSubData({
                    fileType: 'csv',
                    event: { call: 'downloadSubdata' },
                  })
                    .then((res) => {
                      if (res.data.success === true) {
                        handleDownload(res.data.data);
                      }
                    })
                    .catch((err) => {
                      ErrorToast(err.response.data.message);
                    });
                  handleCloseMenu();
                }}
                disableRipple
              >
                Export as CSV
              </MenuItem>
              <MenuItem
                onClick={() => {
                  downloadSubData({
                    fileType: 'dnl',
                    event: { call: 'downloadSubdata' },
                  })
                    .then((res) => {
                      if (res.data.success === true) {
                        handleDownload(res.data.data);
                      }
                    })
                    .catch((err) => {
                      ErrorToast(err.response.data.message);
                    });
                  handleCloseMenu();
                }}
                disableRipple
              >
                Export as DNL
              </MenuItem>
              <MenuItem
                onClick={() => {
                  downloadSubData({
                    fileType: 'txt',
                    event: { call: 'downloadSubdata' },
                  })
                    .then((res) => {
                      if (res.data.success === true) {
                        handleDownload(res.data.data);
                      }
                    })
                    .catch((err) => {
                      ErrorToast(err.response.data.message);
                    });
                  handleCloseMenu();
                }}
                disableRipple
              >
                Export as TXT
              </MenuItem>
              <MenuItem
                onClick={() => {
                  downloadSubData({
                    fileType: 'pdf',
                    event: { call: 'downloadSubdata' },
                  })
                    .then((res) => {
                      if (res.data.success === true) {
                        handleDownload(res.data.data);
                      }
                    })
                    .catch((err) => {
                      ErrorToast(err.response.data.message);
                    });
                  handleCloseMenu();
                }}
                disableRipple
              >
                Export as PDF
              </MenuItem>
            </StyledMenu>
          </Box>
          <SubscriptionFilterMenu
            orderBy={orderBy}
            order={order}
            labelList={labelList}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            selectedIDs={selected}
            setSelected={setSelected}
            onRequestSort={handleRequestSort}
            headLabel={TABLE_HEAD}
            setCname={setCname}
            cname={cname}
            pagination={pagination}
            sendDataToSubscription={handleDataFromUserListToolbar}
          />
          <Tooltip title="Refresh">
            <IconButton
              sx={{
                mr: 2,
                height: '40px',
                flex: 'none',
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={() => fetchSubscriptions()}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {/* Filter List */}
          {/* <UserListToolbar
            labelList={labelList}
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            selectedIDs={selected}
            setSelected={setSelected}
            onRequestSort={handleRequestSort}
            headLabel={TABLE_HEAD}
            setCname={setCname}
            cname={cname}
            pagination={pagination}
            sendDataToSubscription={handleDataFromUserListToolbar}
          /> */}
          {/* Safal Intelliscan */}
          {login.user.country !== 'India' && (
            <Tooltip title="Safal Intelliscan">
              <IconButton
                onClick={() => {
                  handleClickSubscriptionFinder();
                }}
                sx={{
                  marginRight: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0px',
                  backgroundColor: '#3D71FF',
                  height: '40px',
                  width: '40px',
                  '&:hover': { backgroundColor: '#3D71FF' },
                }}
              >
                <img src="/assets/images/Intelli_Scan.png" alt="downloadImage" />
              </IconButton>
            </Tooltip>
          )}
          {/* Safal AI */}
          <HeySafalSpeechRecognition type="sub" mr={2} onClose={fetchSubscriptions} />
          {/* Add new subscription */}
          <Tooltip title="Add new subscription">
            <IconButton
              onClick={handleClickOpenSub}
              sx={{
                display: 'flex',
                alignItems: 'center',
                // ml: 2,
                backgroundColor: '#3D71FF',
                height: '40px',
                width: '40px',
                '&:hover': { backgroundColor: '#3D71FF' },
              }}
            >
              <AddIcon sx={{ color: 'white' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>

      {selected.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            // pl: 2,
            // pr: 2,
            backgroundColor: '#7B9EFD',
            borderRadius: '35px',
            width: 'fit-content',
            height: '50px',
          }}
        >
          <Typography sx={{ m: 2 }} component="div" variant="subtitle1" color="#FFFFFF">
            {selected.length} selected
          </Typography>

          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setOpenDelete(true);
                handleDeleteMultiple(selected);
              }}
            >
              <Iconify sx={{ color: '#DF3E30' }} icon="ic:baseline-delete-outline" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography sx={{ m: 2 }} component="div" variant="subtitle1" color="#00000">
          {/* {SubscriptionData?.length} of {TotalSubscriptions} */}
          {(pagination.page - 1) * pagination.limit + 1} -{' '}
          {Math.min(pagination.page * pagination.limit, TotalSubscriptions)} of {TotalSubscriptions}
        </Typography>
        <TablePagination
          component="div"
          size="small"
          count={TotalSubscriptions}
          rowsPerPageOptions={[5, 10, 25]}
          rowsPerPage={pagination.limit}
          page={pagination.page - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onChange={handleChangePage}
        />
      </div>
      <div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap', marginBottom: '12px', justifyContent: 'center' }}>
        <SubscriptionMetaCard
          imgSrc={subMetaActiveIcon}
          title={'Active'}
          value={subscriptionMetaInfo?.totalActive && `${subscriptionMetaInfo?.totalActive} Nos`}
        />
        <SubscriptionMetaCard
          imgSrc={subMetaMonthlyIcon}
          title={moment().format('MMMM YYYY')}
          value={
            subscriptionMetaInfo?.costPerMonth &&
            `${CurrSymbol} ${new Intl.NumberFormat().format(subscriptionMetaInfo?.costPerMonth)}`
          }
        />
        <SubscriptionMetaCard
          imgSrc={subMetaYearIcon}
          title={`Year ${moment().format('YYYY')}`}
          value={
            subscriptionMetaInfo?.costPerYear &&
            `${CurrSymbol} ${new Intl.NumberFormat().format(subscriptionMetaInfo?.costPerYear)}`
          }
          valueColor="#54d62c"
        />
      </div>
      <TableContainer sx={{ maxHeight: 600, scrollbarWidth: 'thin' }}>
        <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
          <UserListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={displayData.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
          />
          {SubscriptionData.length === 0 ? (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={16} sx={{ borderRadius: '35px', backgroundColor: '#FFFFFF' }}>
                  {cname.length === 0 ? (
                    `There are no recorded subscriptions for you. Kindly add a new one.`
                  ) : (
                    <SearchNotFound />
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {displayData &&
                // displayData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                displayData?.map((row) => {
                  const isItemSelected = selected.indexOf(row._id) !== -1;
                  return (
                    <>
                      <TableRow
                        hover
                        key={row._id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        onDoubleClick={() => {
                          setEditData(row);
                          setOpenDisplayModal(true);
                        }}
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            borderBottomLeftRadius: '35px',
                            borderTopLeftRadius: '35px',
                          }}
                        >
                          <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, row)} />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            p: 0,
                            width: 10,
                          }}
                        >
                          <Button
                            id="action-button"
                            sx={{ padding: 0 }}
                            size="small"
                            aria-controls={actionOpen ? 'action-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={actionOpen ? 'true' : undefined}
                            onClick={(e) => {
                              handleActionClick(e);
                              setEditData(row);
                            }}
                          >
                            <MoreVertIcon />
                          </Button>
                        </TableCell>

                        <TableCell align="center" sx={{ backgroundColor: '#FFFFFF', p: 0 }}>
                          <Typography variant="subtitle2" noWrap>
                            {row?.company?.companyType}
                          </Typography>
                        </TableCell>

                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0 }}>
                          <img
                            height="40px"
                            src={row?.company?.logo}
                            alt="Logo"
                            style={{ maxWidth: '60px', resizeMode: 'contains' }}
                          />
                        </TableCell>

                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pl: '16px', pr: 0 }}>
                          <Typography
                            variant="subtitle2"
                            color={row.status === 'Active' ? '#229A16' : '#ff4c00'}
                            noWrap
                          >
                            {row.subscriptionName}
                          </Typography>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor: '#FFFFFF',
                            pr: 0,
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {row?.count}
                        </TableCell>
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0, textOverflow: 'ellipsis' }}>
                          <Tooltip title={row?.company?.website} placement="bottom-start">
                            <a href={row?.company?.website} rel="noreferrer noopener" target="_blank">
                              {`${row?.company?.website?.slice(0, 20)}...`}
                            </a>
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0 }}>
                          {row.frequency}
                        </TableCell>
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0 }}>
                          <Box sx={{ display: 'flex' }}>
                            {row.isLiked === 1 && <img src="/assets/images/Like.png" alt="Like Symbol" />}
                            {row.isLiked === -1 && <img src="/assets/images/disLike.png" alt="DisLike Symbol" />}
                            {row.isLiked === 0 && (
                              <>
                                <img
                                  src="/assets/images/Like.png"
                                  alt="Like Symbol"
                                  style={{ filter: 'grayscale(100%)' }}
                                />
                                <img
                                  src="/assets/images/disLike.png"
                                  alt="DisLike Symbol"
                                  style={{ filter: 'grayscale(100%)' }}
                                />
                              </>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', minWidth: '140px', pr: 0 }}>
                          <CustomRating precision={0.5} value={row.rating} readOnly />
                        </TableCell>

                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0 }}>
                          <Currency value={row.amount} />
                          {/* {(row.currencyAbr === 'INR' ? '₹' : '$') + '  ' + row.amount} */}
                        </TableCell>
                        {/* <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0 }}>
                            {moment(row.startDate).format('MM/DD/yyyy')}
                          </TableCell> */}
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0 }}>
                          {user.country === 'India'
                            ? `${moment(row.nextBilling).format('DD/MM/yyyy')} ${getTimeZoneAbbreviation()}`
                            : `${moment(row.nextBilling).format('MM/DD/yyyy')} ${getTimeZoneAbbreviation()}`}
                        </TableCell>
                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0 }}>
                          {row?.expiryDate
                            ? user.country === 'India'
                              ? `${moment(row?.expiryDate).format('DD/MM/yyyy')} ${getTimeZoneAbbreviation()}`
                              : `${moment(row?.expiryDate).format('MM/DD/yyyy')} ${getTimeZoneAbbreviation()}`
                            : ''}
                        </TableCell>

                        <TableCell align="left" sx={{ backgroundColor: '#FFFFFF', pr: 0 }}>
                          <LightTooltip
                            title={row?.autoRenewal ? 'Auto-renewal' : 'Non Auto-renewal'}
                            placement="bottom-start"
                          >
                            <Box>
                              {row?.autoRenewal ? (
                                <Iconify name="Auto-renewal" icon="il:flag" color="green" />
                              ) : (
                                <Iconify name="Auto-renewal" icon="il:flag" color="red" />
                              )}
                            </Box>
                          </LightTooltip>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            borderBottomRightRadius: '35px',
                            borderTopRightRadius: '35px',
                            backgroundColor: '#FFFFFF',
                            pr: 0,
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {row?.label?.name}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              {/* {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )} */}
            </TableBody>
          )}

          {isUserNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={16} sx={{ p: 0, borderRadius: '35px', backgroundColor: '#FFFFFF' }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <SubscriptionModal
        fetchPaymentModeInfo={fetchPaymentModeInfo}
        openModal={openSub}
        setOpenSubModal={setOpenSub}
        pagination={pagination}
        handledelete={handledelete}
        setSelected={setSelected}
        setOpenDelete={setOpenDelete}
        handleActionClose={handleActionClose}
        setOpenTrackModal={setOpenTrackModal}
        onOpenFeedback={() => setIsInitialFeedbackModal(true)}
        paymentCardSchemeList={paymentCardSchemeList}
        paymentInstrumentList={paymentInstrumentList}
        paymentModeList={paymentModeList}
      />
      <EditModal
        fetchPaymentModeInfo={fetchPaymentModeInfo}
        setOpenTrackModal={setOpenTrackModal}
        handleActionClose={handleActionClose}
        openEditModal={open}
        setOpenDelete={setOpenDelete}
        setOpenEditModal={(e) => {
          setOpen(e);
          handleActionClose();
        }}
        data={editData}
        setSelected={setSelected}
        handledelete={handledelete}
        setEditData={setEditData}
        setOpenEventModal={setOpenEventModal}
        paymentCardSchemeList={paymentCardSchemeList}
        paymentInstrumentList={paymentInstrumentList}
        paymentModeList={paymentModeList}
      />
      <DisplayModal
        setOpenTrackModal={setOpenTrackModal}
        handleActionClose={handleActionClose}
        setOpenEditModal={setOpen}
        openDisplayModal={openDisplayModal}
        setOpenDelete={setOpenDelete}
        setOpenDisplayModal={setOpenDisplayModal}
        data={editData}
        setSelected={setSelected}
        handledelete={handledelete}
        setEditData={setEditData}
        setDeleteId={setDeleteId}
      />
      <DeleteModal
        openDeleteModal={openDelete}
        setOpenDelete={setOpenDelete}
        id={[deleteid]}
        subNames={deleteSubNames}
        setSelected={setSelected}
        setDeleteSubNames={setDeleteSubNames}
        pagination={pagination}
        onCloseEdit={() => setOpen(false)}
      />
      <ActionMenu
        setOpenEditModal={setOpen}
        row={editData}
        actionMenu={actionMenu}
        actionOpen={actionOpen}
        handleActionClick={handleActionClick}
        handleActionClose={handleActionClose}
        setOpenDelete={setOpenDelete}
        handledelete={handledelete}
        setSelected={setSelected}
        setOpenTrackModal={setOpenTrackModal}
        setEditData={setEditData}
        setOpenDisplayModal={setOpenDisplayModal}
        setDeleteSubNames={setDeleteSubNames}
      />
      <TrackModal setOpenTrackModal={setOpenTrackModal} openTrackModal={openTrackModal} subName={editData} />
      <UploadErrorModal open={openUploadErrorModal} setOpen={setOpenUploadErrorModal} data={UploadErrorModalData} />
      <PdfStatementImport open={pdfModalOpen} onClose={() => setPdfModalOpen(false)} />
      <InitialFeedbackModal open={isInitialFeedbackModal} onClose={() => setIsInitialFeedbackModal(false)} />
    </Page>
  );
}

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })
);

const SubscriptionMetaCard = ({ title, value, valueColor, imgSrc }) => {
  return (
    <div
      style={{
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        position: 'relative',
        height: '80px',
        width: '200px',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        filter: 'drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06))',
        padding: '8px',
        alignItems: 'center',
      }}
    >
      <span style={{ fontWeight: 'bold', color: '#3D71FF', alignSelf: 'start', marginLeft: '8px' }}>{title}</span>
      <span style={{ fontWeight: 'bold', color: valueColor || 'black' }}>{value}</span>
      <img
        style={{
          position: 'absolute',
          height: '50%',
          objectFit: 'cover',
          top: '25%',
          right: '16px',
          filter: 'invert(0.9)',
        }}
        src={imgSrc}
        alt="metaCardIcon01"
      />
    </div>
  );
};
