import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatResponsiveToggle: true,
  pdfStatementModal: false,
  addSubscriptionAnimation: false,
};

export const toggleSlice = createSlice({
  name: 'toggleSlice',
  initialState,
  reducers: {
    changeChatResponsiveToggle: (state, action) => {
      state = {
        ...state,
        chatResponsiveToggle: action.payload !== undefined ? action.payload : !state.chatResponsiveToggle,
      };
      return state;
    },
    changePdfStatementModal: (state, action) => {
      state = {
        ...state,
        pdfStatementModal: action.payload !== undefined ? action.payload : !state.pdfStatementModal,
      };
      return state;
    },
    changeAddSubscriptionAnimation: (state, action) => {
      state = {
        ...state,
        addSubscriptionAnimation: action.payload !== undefined ? action.payload : !state.addSubscriptionAnimation,
      };
      return state;
    },
    resetToggleSlice: () => {
      return initialState;
    },
  },
});

export const { changeChatResponsiveToggle, resetToggleSlice, changePdfStatementModal, changeAddSubscriptionAnimation } =
  toggleSlice.actions;

export default toggleSlice.reducer;
