/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  isStartCall: null,
  isEndCall: null,
  isRejectCall: null,
  callWindow: '',
  callModal: '',
  callFrom: '',
  localSrc: null,
  peerSrc: null,
  pc: {},
  config: null,
  callMode: 'video',
};
export const callSlice = createSlice({
  name: 'calls',
  initialState,
  reducers: {
    setIsStartCall: (state, action) => {
      return {
        ...state,
        isStartCall: action.payload.isCall,
        callMode: action.payload.callMode,
      };
    },
    setIsEndCall: (state, action) => {
      return {
        ...state,
        isEndCall: action.payload,
      };
    },
    setIsRejectCall: (state, action) => {
      return {
        ...state,
        isRejectCall: action.payload,
      };
    },
    setCallWindow: (state, action) => {
      return {
        ...state,
        callWindow: action.payload,
      };
    },
    setCallModal: (state, action) => {
      return {
        ...state,
        callModal: action.payload,
      };
    },
    setLocalSrc: (state, action) => {
      return {
        ...state,
        localSrc: action.payload,
      };
    },
    setPeerSrc: (state, action) => {
      return {
        ...state,
        peerSrc: action.payload,
      };
    },
    setCallFrom: (state, action) => {
      return {
        ...state,
        callFrom: action.payload,
      };
    },
    setConfig: (state, action) => {
      return {
        ...state,
        config: action.payload,
      };
    },
    setPc: (state, action) => {
      return {
        ...state,
        pc: action.payload,
      };
    },
    resetCall: () => {
      return initialState;
    },
  },
});

export const {
  setIsStartCall,
  setIsEndCall,
  setIsRejectCall,
  setCallWindow,
  setCallModal,
  setLocalSrc,
  setPeerSrc,
  setCallFrom,
  setConfig,
  setPc,
  resetCall,
} = callSlice.actions;

export default callSlice.reducer;
