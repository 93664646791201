import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './AdvertisementPageListBusiness.css';

import { getAdvertisementPage, getHelpVideoModule } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import TableView from './TableView';
import AddAdvertisementPageModal from './AddAdvertisementPageModal';
import AdPriceModal from './AdPriceModal';

const AdvertisementPageListBusiness = () => {
  const [addModalOpen, setAddModalOpen] = useState({
    data: null,
    open: false,
    isDisplay: false,
  });
  const [openPriceModal, setOpenPriceModal] = useState({
    open: false,
    id: null,
  });

  const [rawData, setRawData] = useState([]);
  const [moduleList, setModuleList] = useState([]);

  const fetchData = async () => {
    try {
      const moduleRes = await getHelpVideoModule({ status: true });
      setModuleList(moduleRes.data.data);
      const res = await getAdvertisementPage();
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('fetchData error', err.message);
    }
  };

  const onAddModalOpenDisplay = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: true,
    });
  };
  const onAddModalOpenEdit = (data) => {
    setAddModalOpen({
      data: data,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalOpen = () => {
    setAddModalOpen({
      data: null,
      open: true,
      isDisplay: false,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      data: null,
      open: false,
      isDisplay: false,
    });
    fetchData();
  };

  const onOpenPriceModal = (data) => {
    setOpenPriceModal({
      open: true,
      id: data?._id,
    });
  };
  const onClosePriceModal = () => {
    setOpenPriceModal({
      open: false,
      id: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Advertisement Pages - SafalSubscriptions</title>
      </Helmet>

      <div id="ApplicationListBusiness">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Advertisement Pages List
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          moduleList={moduleList}
          onEdit={onAddModalOpenEdit}
          onDisplay={onAddModalOpenDisplay}
          onConfigurePricing={onOpenPriceModal}
          data={rawData}
        />
      </div>
      <AddAdvertisementPageModal
        moduleList={moduleList}
        open={addModalOpen.open}
        data={addModalOpen.data}
        isDisplay={addModalOpen.isDisplay}
        onClose={onAddModalClose}
      />
      <AdPriceModal adId={openPriceModal?.id} onClose={onClosePriceModal} open={openPriceModal?.open} />
    </>
  );
};

export default AdvertisementPageListBusiness;
