import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, OutlinedInput, TableContainer, TableRow, Tooltip } from '@mui/material';
import { Image } from 'antd';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import moment from 'moment';

import DateField from '../../components/UI/DateField';
import CustomButton from '../../components/UI/CustomButton';
import useSortHook from '../../hooks/useSortHook';
import {
  CustomTableCellForBody,
  CustomTableCellForLoading,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import UserCommentsModal from '../../components/Modals/UserCommentsModal';
import { getSignedURL, getSafalLeaderBoardReport, getSafalLeaderBoardReportDownload } from '../../services/Service';
import useDebounce from '../../hooks/useDebounce';
import Iconify from '../../components/Iconify';
import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import SafalLeaderBoardReportFilterMenu from './SafalLeaderBoardReportFilterMenu';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import CustomRating from '../../components/UI/CustomRating';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import Inlineloader from '../../components/Loader/InlineLoader';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const SafalLeaderBoardReport = () => {
  const { formatDateTime } = useUserTimeZone();
  const [filterState, setFilterState] = useState({
    startDate: '',
    endDate: '',
    minPoints: '',
    maxPoints: '',
    megaModule: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 1000,
  });
  const [metaData, setMetaData] = useState({ redeemRequest: 0, totalSafalPoints: 0, expiresSafalPoints: 0 });
  const [metaDataFilter, setMetaDataFilter] = useState({
    redeemRequest: 0,
    totalSafalPoints: 0,
    expiresSafalPoints: 0,
  });
  const [totalData, setTotalData] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableDataFilter, setTableFilterData] = useState([]);
  const { handleSort, newSortData, sortState } = useSortHook(tableDataFilter, ['date']);

  // DeBounce Function
  useDebounce(
    async () => {
      await fetchSubscriptionsTrend();
    },
    [filterState],
    1000
  );

  const fetchSubscriptionsTrend = async () => {
    setIsLoading(true);
    try {
      const _filterState = { ...filterState };
      if (_filterState.endDate) {
        _filterState.endDate = moment(_filterState.endDate).add(1, 'days').toISOString();
      }
      if (_filterState.startDate) {
        _filterState.startDate = moment(_filterState.startDate).add(1, 'days').toISOString();
      }

      const res = await getSafalLeaderBoardReport({
        ..._filterState,
        ...pagination,
        application: 'SafalSubscriptions',
      });
      setTableDataRaw(res.data.data);
      setTotalData(res?.data?.pagination?.total || 0);
      setMetaData(res?.data?.meta);
      setMetaDataFilter(res?.data?.metaFilter);
      const newData = res.data.data.map((ex) => ({
        newEvent: ex?.activityName,
        module: ex?.megaModule?.megaModule,
        moduleIcon: ex?.megaModule?.megaModuleIcon,
        date: ex.createdAt,
        points: ex.points,
        spend: ex.spend,
        total: ex.total,
      }));
      setTableData(newData);
      if (searchValue?.length > 1) {
        const filterData = newData?.filter((e) => e?.newEvent?.toLowerCase()?.includes(searchValue?.toLowerCase()));
        setTableFilterData(filterData);
      } else {
        setTableFilterData(newData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  const downloadTrend = async (type) => {
    try {
      const res = await getSafalLeaderBoardReportDownload({
        ...filterState,
        application: 'SafalSubscriptions',
        fileType: type,
      });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  const onChangeSearch = (value) => {
    setSearchValue(value);
    if (value?.length > 1) {
      const filterData = tableData?.filter((e) => e?.newEvent?.toLowerCase()?.includes(value?.toLowerCase()));
      setTableFilterData(filterData);
    } else {
      setTableFilterData(tableData);
    }
  };

  const isFilterApply =
    filterState.endDate !== '' ||
    filterState.maxPoints !== '' ||
    filterState.megaModule !== '' ||
    filterState.minPoints !== '' ||
    filterState.startDate !== '';

  if (isLoading) return <Inlineloader />;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '16px', marginBottom: '8px' }}>
          <TopCard
            name="Total Redeem Safalpoints"
            value={`${new Intl.NumberFormat().format(metaData?.redeemRequest)} Spts`}
          />
          <TopCard
            name="Total Safalpoints"
            value={
              isFilterApply
                ? `${new Intl.NumberFormat().format(metaDataFilter?.totalSafalPoints)} Spts`
                : `${new Intl.NumberFormat().format(metaData?.totalSafalPoints)} Spts`
            }
          />
          <TopCard
            name="Expired Safalpoints"
            value={`${new Intl.NumberFormat().format(metaData?.expiresSafalPoints)} Spts`}
          />
        </div>
        <div style={{ display: 'flex', gap: '16px' }}>
          <SearchStyle
            sx={{
              width: '200px',
            }}
            value={searchValue}
            onChange={(e) => onChangeSearch(e.target.value)}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          <SafalLeaderBoardReportFilterMenu state={filterState} onFilterApply={onFilterApply} />
          <Tooltip title="Refresh">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={fetchSubscriptionsTrend}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <ReportsTabsDownloadMenu downloadFile={downloadTrend} />
        </div>
      </div>
      <div>
        {tableData.length} of {totalData}
        <TableContainer sx={{ maxHeight: 600 }}>
          <CustomTableView
            isSort
            sort={{
              order: sortState.order,
              orderBy: sortState.orderBy,
              createSortHandler: handleSort,
              sortHeadMap: ['module', 'newEvent', 'points', 'spend', 'total', 'date'],
            }}
            headData={['Category ', 'Activity', 'Sfpts Earned', 'Sfpts Spend', 'Balance', 'Date and Time']}
          >
            {isLoading && <CustomTableCellForLoading />}
            {!isLoading && tableData.length === 0 && <CustomTableCellForNoData message="We didn't find any data." />}
            {!isLoading &&
              newSortData?.map((e, index) => (
                <TableRow key={index}>
                  <CustomTableCellForBody align="center">
                    <p style={{ ...tableCellPStyle, display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <img style={{ height: '30px', objectFit: 'contain' }} src={e.moduleIcon} alt="moduleIcon" />
                      {e.module}
                    </p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e.newEvent}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{`${new Intl.NumberFormat().format(e.points)} Spts`}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{`${new Intl.NumberFormat().format(e.spend)} Spts`}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{`${new Intl.NumberFormat().format(e.total)} Spts`}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>
                      {formatDateTime(e?.date)} {getTimeZoneAbbreviation()}
                    </p>
                  </CustomTableCellForBody>
                </TableRow>
              ))}
          </CustomTableView>
        </TableContainer>
      </div>
      {/* <UserCommentsModal open={isUserCommentsModal.open} onClose={onCloseUserCommentsModal} /> */}
    </div>
  );
};

export default SafalLeaderBoardReport;

const TopCard = ({ name, value }) => {
  return (
    <div
      style={{
        height: '80px',
        width: '200px',
        border: '1px solid #bcceff',
        boxShadow: '0px 4px 4px 0px #00000040',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        padding: '8px',
        justifyContent: 'space-between',
        backgroundColor: '#bcceff',
      }}
    >
      <span style={{ color: '#001E6F' }}>{name}</span>
      <span style={{ color: '#001E6F', fontWeight: 'bold', marginLeft: 'auto' }}>{value}</span>
    </div>
  );
};
