/* eslint-disable */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Fab } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { addFeedbackAction, getUserFeedbacksAction } from '../../store/feedbackSlice';
import ErrorToast from '../Toast/Error';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  '@media (max-width: 740px)': {
    width: '98% !important',
    height: '98% !important',
  },
};

const UserFeedbackModal = ({ open, handleClose, isSubmited, setSubmited }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.login);
  const [attached, setAttached] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const LoginSchema = Yup.object().shape({
    description: Yup.string().required('Description is required'),
    details: Yup.string().required('Details is required'),
    email: Yup.string().test('is-email-list', 'Must be a valid list of emails separated by semi-colon ;', (value) => {
      if (!value) return true;
      const emails = value.split(';');
      return emails.every((email) =>
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
          email.trim()
        )
      );
    }),
  });
  // useEffect(() => {
  //   setClose(false);
  // });

  const initialValues = {
    email: `${user.email}`,
    description: '',
    details: '',
    userId: user._id,
  };

  const FeedbackFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: LoginSchema,

    onSubmit: (values, onSubmitProps, e) => {
      setIsSubmitting(true);
      let formData = new FormData();
      Object.keys(values).map((d) => formData.append(d, values[d]));
      attached && Array.from(attached).map((d) => formData.append('attachment', d));
      dispatch(addFeedbackAction(formData))
        .unwrap()
        .then(async (data) => {
          console.log(data);
          setAttached([]);
          setSubmited(true);
          dispatch(getUserFeedbacksAction({ id: user._id }));
          onSubmitProps.setFieldError(false);
          onSubmitProps.resetForm();
          setIsSubmitting(false);
        })
        .catch((err) => {
          console.log(err);
          ErrorToast(err.data.message);
          setIsSubmitting(false);
        });
      // dispatch(loginUser(values))
      //   .unwrap()
      //   .then(async (data) => {
      //     // change it to register page

      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     ErrorToast(err.data.message)
      //   });
    },
  });

  useEffect(() => {
    FeedbackFormik.resetForm();
  }, [open]);

  const handleChange = (e) => {
    // console.log(e)
    setAttached(e.target.files);
  };

  const customInput = () => (
    <input
      accept="*"
      onChange={(e) => {
        const uploadedFiles = e?.target?.files;
        const updatedFiles = [...attached];
        Object.keys(uploadedFiles).forEach((id) => {
          const file = uploadedFiles[id];
          const duplicateFile = updatedFiles.find((uploadedFile) => uploadedFile.name === file.name);

          if (duplicateFile) {
            let counter = 1;
            let newFileName = `${file.name.substring(0, file.name.lastIndexOf('.'))} (${counter})${file.name.substring(
              file.name.lastIndexOf('.')
            )}`;

            // eslint-disable-next-line no-loop-func
            while (updatedFiles.some((uploadedFile) => uploadedFile.name === newFileName)) {
              // eslint-disable-next-line no-plusplus
              counter++;
              newFileName = `${file.name.substring(0, file.name.lastIndexOf('.'))} (${counter})${file.name.substring(
                file.name.lastIndexOf('.')
              )}`;
            }
            const renamedFile = new File([file], newFileName, { type: file.type });
            updatedFiles.push(renamedFile);
          } else {
            updatedFiles.push(file);
          }
        });
        setAttached(updatedFiles);

        // const abc = [];
        // Object.keys(e?.target?.files).map((id) => abc.push(e?.target?.files[id]));
        // const data = [...selectedEditFileName, abc].flat();
        // setSelectedEditFileName(data);
        // EditForm.setFieldValue('attachment', data);
      }}
      style={{ display: 'none' }}
      id="contained-button-file"
      type="file"
      multiple
    />
  );

  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {!isSubmited ? (
          <Box sx={style} className={classes.modal_box}>
            <div className={classes.flex_div}>
              <h2 className={classes.feedback_h2}>Share Your Feedback</h2>
              <div className={classes.close_ico}>
                <div
                  className={classes.close_icon_div}
                  onClick={() => {
                    handleClose();
                    setAttached([]);
                  }}
                >
                  <CloseIcon className={classes.close_icon} />
                </div>
              </div>
            </div>
            <div className="form_container">
              <FormikProvider value={FeedbackFormik}>
                <form onSubmit={FeedbackFormik.handleSubmit}>
                  <Stack spacing={3}>
                    <Field
                      disabled={isSubmitting}
                      as={TextField}
                      variant="standard"
                      name="description"
                      label="Short Description*"
                      onChange={FeedbackFormik.handleChange}
                      value={FeedbackFormik.values.description}
                      error={FeedbackFormik.touched.description && Boolean(FeedbackFormik.errors.description)}
                      inputProps={{
                        maxlength: 250,
                      }}
                      helperText={`${FeedbackFormik.values.description?.length || 0}/${250}`}
                    />
                    <Field
                      disabled={isSubmitting}
                      as={TextField}
                      variant="standard"
                      name="details"
                      multiline
                      rows={3}
                      type={'text'}
                      label="Write in Details*"
                      onChange={FeedbackFormik.handleChange}
                      value={FeedbackFormik.values.details}
                      error={FeedbackFormik.touched.details && Boolean(FeedbackFormik.errors.details)}
                      inputProps={{
                        maxlength: 1000,
                      }}
                      helperText={`${FeedbackFormik.values.details?.length || 0}/${1000}`}
                    />

                    <Field
                      disabled={isSubmitting}
                      as={TextField}
                      variant="standard"
                      name="email"
                      label="Email Address*"
                      onChange={(e) =>
                        FeedbackFormik.values.email.includes(';') || e.nativeEvent.data === ';'
                          ? FeedbackFormik.handleChange(e)
                          : null
                      }
                      value={FeedbackFormik.values.email}
                      error={FeedbackFormik.touched.email && Boolean(FeedbackFormik.errors.email)}
                      helperText={
                        Boolean(FeedbackFormik.errors.email)
                          ? FeedbackFormik.errors.email
                          : 'Multiple E-mails separated by semi-colon ;'
                      }
                      readOnly // Add the readOnly attribute
                    />
                  </Stack>
                  <label htmlFor="contained-button-file">
                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      color="primary"
                      component="span"
                      sx={{
                        display: 'flex',
                        width: '170px',
                        borderRadius: '30px',
                        textTransform: 'none',
                        backgroundColor: '#FFF',
                        margin: '1rem',
                        '&:hover': {
                          backgroundColor: '#FFF',
                        },
                      }}
                    >
                      <AttachFileIcon
                        sx={{
                          transform: 'rotate(46deg) !important',
                        }}
                        color="primary"
                      />
                      <Typography
                        variant="button"
                        display="block"
                        sx={{ marginLeft: '0.5rem', textTransform: 'none', fontWeight: 'bold' }}
                        color="#000"
                      >
                        Attachment
                      </Typography>
                    </Button>
                    <Field name="files" as={customInput} />
                  </label>
                  {attached && (
                    <p>
                      <b>Selected file:</b>
                    </p>
                  )}{' '}
                  {attached && (
                    <div style={attached?.length >= 3 ? { height: 100, overflowY: 'scroll' } : {}}>
                      {Array.from(attached).map((d, i) => (
                        <Box sx={{ mt: 1 }} style={{ display: 'block' }} key={i}>
                          <Fab
                            disabled={isSubmitting}
                            size="small"
                            variant="outlined"
                            color="error"
                            sx={{ mr: 1 }}
                            onClick={() => {
                              setAttached(Array.from(attached).filter((e, j) => i !== j));
                            }}
                          >
                            <CloseIcon />
                          </Fab>
                          {d.name}
                        </Box>
                      ))}
                    </div>
                  )}
                  <Button
                    disabled={isSubmitting}
                    color="primary"
                    variant="contained"
                    type="submit"
                    sx={{
                      width: '170px',
                      borderRadius: '30px',
                      textTransform: 'none',
                      backgroundColor: '#3D71FF',
                      display: 'block',
                      margin: '1rem',
                      marginLeft: 'auto',
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </FormikProvider>
            </div>
          </Box>
        ) : (
          <Box sx={style} className={classes.modal_box}>
            <div className={classes.flex_div_row}>
              <div
                sx={{
                  position: 'absolute',
                }}
                className={classes.close_ico}
              >
                <div className={classes.close_icon_div} onClick={handleClose}>
                  <CloseIcon className={classes.close_icon} />
                </div>
              </div>
              <div className={classes.flex_submit}>
                <img src={'/assets/images/Frame.png'} alt="" />
                <Typography mt={4} variant="h5">
                  Thank You!
                </Typography>
                <Typography mt={2} variant="p">
                  Thank you for reaching out and providing us with valuable feedback.
                </Typography>
                <Typography>We will get back to you soon!</Typography>
              </div>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default UserFeedbackModal;

const useStyles = makeStyles({
  button: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white !important',
    height: 48,
    padding: '0 30px',
    fontWeight: 'bold !important',
    marginTop: '50px !important',
  },
  form_container: {
    display: 'flex  !important',
    flexDirection: 'column  !important',
    columnGap: '2rem  !important',
  },
  modal_box: {
    borderRadius: '10px !important',
    border: 'none !important',
    width: '700px !important',
    '@media (max-width: 600px)': {
      width: '100% !important',
    },
  },

  close_ico: {
    right: 0,
    top: 0,
    display: 'block',
    transform: 'translate(-10%,10%)',
  },
  flex_div: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '-30px !important',
    padding: '1rem 0rem',
  },
  flex_div_row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'space-between',
    marginTop: '-20px !important',
    padding: '1rem',
  },
  flex_submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '-30px !important',
    padding: '1rem',
  },
  feedback_h2: {
    color: '#0058ffdb !important',
    textAlign: 'left',
  },
  close_icon_div: {
    height: '40px !important',
    width: '40px !important',
    borderRadius: '50% !important',
    backgroundColor: '#0058ffdb !important',
    marginRight: '-25px !important',
    marginTop: '-20px !important',
    cursor: 'pointer !important',
    position: 'relative',
  },
  close_icon: {
    color: 'white !important',
    position: 'absolute',
    top: '50%',
    fontSize: 'x-large',
    right: '50%',
    transform: 'translate(50%,-50%)',
  },
  attch_icon: {
    color: '#0058ffdb !important',
    transform: 'rotate(46deg) !important',
    paddingRight: '10px !important',
  },
  attach_btn: {
    borderRadius: '50px !important',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    textTransform: 'capitalize !important',
    color: 'black !important',
    padding: '10px 30px !important',
    fontSize: '13px !important',
    fontWeight: 'bold !important',
  },
  send_btn: {
    backgroundColor: '#0058ffdb !important',
    borderRadius: '50px !important',
    color: 'white !important',
    fontSize: '13px !important',
    fontWeight: 'bold !important',
    textTransform: 'capitalize !important',
    padding: '10px 70px !important',
  },
});
