import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';

import queryString from 'query-string';
import { Field, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';

import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../store/loginSlice';
import SuccessToast from '../../../components/Toast/Success';
import ErrorToast from '../../../components/Toast/Error';
import { auth, db } from '../../../firebase';
import { getModuleConfiguration, getSafalLeaderBoardActivitiesConfig } from '../../../services/Service';
import { setDynamicMenuState } from '../../../store/DynamicMenuSlice';
import { setSafalLeaderBoardSwitch } from '../../../store/SafalLeaderBoardSwitchSlice';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [rememberChecked, setRememberChecked] = useState(false);
  const [localCookie, setLocalCookie] = useState('');
  // const [state, setState] = useState(false);
  // const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    userID: Yup.string().nullable().required('UserID is required'),
    email: Yup.string().email('Email must be a valid email address'),
    // .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const tempUserID = localStorage.getItem('userId') || '';
  const initialValues = {
    userID: tempUserID && tempUserID !== 'null' ? tempUserID : '',
    email: '',
    password: '',
    remember: !!tempUserID,
  };

  function getCookies() {
    const cookies = document.cookie.split('; ');
    const cookieObj = {};

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split('=');
      const name = decodeURIComponent(cookie[0]);
      const value = decodeURIComponent(cookie[1]);
      cookieObj[name] = value;
    }

    return cookieObj;
  }
  useEffect(() => {
    const localUserId = localStorage.getItem('userId');
    if (localUserId && localUserId !== 'null') {
      setRememberChecked(true);
      LoginFormik.setFieldValue('userID', localUserId);
    }
  }, []);
  const LoginFormik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const queryStringValues = queryString.parse(search);
      if (
        queryStringValues?.page === 'offers' &&
        queryStringValues?.type !== undefined &&
        queryStringValues?.id !== undefined
      ) {
        localStorage.setItem('redirect', JSON.stringify(queryStringValues));
      }
      let payload;
      const cookieValue = getCookies();
      setLocalCookie(cookieValue.token);
      if (tempUserID) {
        // console.log(cookieValue,"cookieValue")
        const loginToken = localStorage.getItem('loginToken');
        if (rememberChecked) {
          payload = { ...values, loginToken: loginToken, remember: true };
        } else {
          payload = { ...values, loginToken: loginToken, remember: false };
        }
        delete payload.email;
      } else {
        // initialValues.password = ''
        if (rememberChecked) {
          payload = { ...values, remember: true };
        } else {
          payload = { ...values, remember: false };
        }
      }
      // !payload.remember && (document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;');
      !payload.remember && localStorage.removeItem('userId');
      !payload.remember && localStorage.removeItem('loginToken');
      dispatch(loginUser(payload))
        .unwrap()
        .then(async (data) => {
          if (payload.remember) {
            const cookieValues = getCookies();
            cookieValues.token !== localCookie &&
              cookieValues.token &&
              localStorage.setItem('loginToken', cookieValues.token);
            localStorage.setItem('userId', data.data.userID);
          }
          await signInWithEmailAndPassword(auth, data.data.email, values.password);
          const DYNAMIC_MENU_VERSION = 'Beta 1.0';
          getModuleConfiguration(DYNAMIC_MENU_VERSION, 'web')
            .then((res) => {
              const res_data = res?.data?.data;
              if (res_data) {
                dispatch(setDynamicMenuState(res_data));
              }
            })
            .catch((e) => {
              console.log('getModuleConfiguration', e);
            });
          // SafalLeaderBoard Master Config Fetch
          getSafalLeaderBoardActivitiesConfig({ application: 'SafalSubscriptions' }).then((res) => {
            const masterSwitch = res?.data?.masterSwitch;
            if (masterSwitch && masterSwitch.length > 0) {
              const resData = masterSwitch[0];
              dispatch(setSafalLeaderBoardSwitch(resData));
            }
          });
          if (data.role === 'admin') {
            navigate('/admin/dashboard');
            SuccessToast('Login Successful');
          }
          if (data.role === 'user') {
            navigate('/dashboard');
            SuccessToast('Login Successful');
          }
          if (data.role === 'business') {
            navigate('/business');
            SuccessToast('Login Successful');
          }
        })
        .catch((err) => {
          console.log(err);
          ErrorToast(err.data.message);
        });
    },
  });

  return (
    <>
      <FormikProvider value={LoginFormik}>
        <form onSubmit={LoginFormik.handleSubmit}>
          <Stack spacing={1}>
            <Field
              as={TextField}
              variant="standard"
              name="userID"
              label="User ID"
              onChange={LoginFormik.handleChange}
              value={LoginFormik.values.userID}
              error={LoginFormik.touched.userID && Boolean(LoginFormik.errors.userID)}
              helperText={LoginFormik.touched.userID && LoginFormik.errors.userID}
            />

            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Link variant="subtitle2" underline="hover" onClick={() => navigate('/forgetuserid')}>
                Forgot User ID?
              </Link>
            </div>

            <Field
              as={TextField}
              variant="standard"
              style={{ '-ms-reveal': 'none', '-ms-clear': 'none' }}
              type={showPassword ? 'text' : 'password'}
              name="password"
              label="Password"
              onChange={LoginFormik.handleChange}
              value={LoginFormik.values.password}
              error={LoginFormik.touched.password && Boolean(LoginFormik.errors.password)}
              helperText={LoginFormik.touched.password && LoginFormik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                      {/* <Icon icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                      <Icon icon={showPassword ? 'eva:eye-off-fill' : 'eva:eye-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    name="remember"
                    checked={rememberChecked}
                    onClick={() => setRememberChecked(!rememberChecked)}
                    label="Remember me"
                    onChange={LoginFormik.handleChange}
                  />
                }
                label={<Typography sx={{ fontSize: '15px' }}>Remember me</Typography>}
              />
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <Link color="#3D71FF" variant="subtitle2" underline="hover" onClick={() => navigate('/forgetpassword')}>
                  {' '}
                  Forgot password?
                </Link>
              </Stack>
            </Box>
          </Stack>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            sx={{ width: '170px', borderRadius: '30px', textTransform: 'none', backgroundColor: '#3D71FF' }}
          >
            Log In
          </Button>
        </form>
      </FormikProvider>
    </>
  );
}
