import { IconButton, MenuItem, TablePagination, TextField, Tooltip, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';

import './SafalWarrantyPage.css';
import Inlineloader from '../../../components/Loader/InlineLoader';
import FaqModal from '../../../components/Modals/FaqModal';
import FilterMenu from './FilterMenu';
import CustomSearch from '../../../components/UI/CustomSearch';
import TableView from './TableView';
import {
  getWarrantyUser,
  getWarrantyType,
  getWarrantyStoreRetailer,
  getWarrantyProductSubCategory,
  getWarrantyProductCategory,
  deleteWarrantyUser,
} from '../../../services/Service';
import useDebounce from '../../../hooks/useDebounce';
import AddWarrantyModal from './AddWarrantyModal';

const SafalWarrantyPage = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const statusParam = searchParams.get('status');

  // const login = useSelector((state) => state.login);
  // const { registered } = login.user;
  const [selectedData, setSelectedData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [productCategoryData, setProductCategoryData] = useState([]);
  const [productSubCategoryData, setProductSubCategoryData] = useState([]);
  const [storeRetailerData, setStoreRetailerData] = useState([]);
  const [warrantyTypeData, setWarrantyTypeData] = useState([]);

  const [totalData, setTotalData] = useState(0);
  const [modalControl, setModalControl] = useState({
    open: false,
    data: null,
    isDisplay: false,
  });
  const [filter, setFilter] = useState({
    page: 1,
    limit: 15,
    search: '',
    order: '',
    orderBy: '',
    expireDateTo: '',
    expireDateForm: '',
    purchaseDateTo: '',
    purchaseDateForm: '',
  });

  const fetchData = async (newFilter, loadingFlag) => {
    try {
      if (!loadingFlag) setLoading(true);
      const res = await getWarrantyUser(newFilter);
      if (res.status === 200 && res.data.success === true) {
        setData(res.data.data);
        setTotalData(res.data.total ?? 0);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      if (!loadingFlag) setLoading(false);
    }
  };

  const fetchExtra = () => {
    getWarrantyType().then((res) => setWarrantyTypeData(res.data.data || []));
    getWarrantyStoreRetailer().then((res) => setStoreRetailerData(res.data.data || []));
    getWarrantyProductSubCategory().then((res) => setProductSubCategoryData(res.data.data || []));
    getWarrantyProductCategory().then((res) => setProductCategoryData(res.data.data || []));
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter]);

  useEffect(() => {
    fetchExtra();
  }, []);

  useDebounce(
    () => {
      if (filter.search !== searchValue) {
        setFilter((prev) => ({ ...prev, search: searchValue }));
      }
    },
    [searchValue],
    1500
  );

  const onFilterReset = () => {};
  const onFilterApply = (newFilterState) => {};
  const onSelectedChange = (id) => {
    setSelectedData((prev) => {
      if (prev.includes(id)) {
        return prev.filter((e) => e !== id);
      }
      return [...prev, id];
    });
  };
  const onPageChange = (_, value) => {
    setFilter((prev) => ({ ...prev, page: value + 1 }));
  };

  const onRowsPerPageChange = (e) => {
    setFilter((prev) => ({ ...prev, page: 1, limit: e.target.value }));
  };

  const onModalClose = (refetchFlag) => {
    setModalControl({
      open: false,
      data: null,
      isDisplay: false,
    });
    if (refetchFlag === true) refetch();
  };
  const onAdd = () => {
    setModalControl({
      open: true,
      data: null,
      isDisplay: false,
    });
  };
  const onEdit = (data) => {
    setModalControl({
      open: true,
      data: data,
      isDisplay: false,
    });
  };
  const onDelete = async (data) => {
    const result = await Swal.fire({
      text: 'Are you sure you want to delete this?',
      showCancelButton: true,
      confirmButtonColor: '#3D71FF',
      cancelButtonColor: '#ff4842',
      confirmButtonText: 'Yes',
      appendTo: 'body',
      customClass: {
        container: 'my-swal', // Add a custom class for styling
      },
      onBeforeOpen: () => {
        // Set a higher zIndex for the Swal modal
        document.querySelector('.my-swal').style.zIndex = 999999;
      },
    });
    if (!result.isConfirmed) {
      return;
    }
    try {
      const res = await deleteWarrantyUser({ id: data._id });
      if (res.status === 200) {
        toast.success(res.data.message);
        refetch();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };
  const onDisplay = (data) => {
    setModalControl({
      open: true,
      data: data,
      isDisplay: true,
    });
  };
  const onChangeLog = () => {};

  const refetch = () => {
    fetchData(filter);
  };

  if (loading) return <Inlineloader />;
  return (
    <>
      <Helmet>
        <title>Warranty - SafalSubscriptions</title>
      </Helmet>
      <div id="SafalWarrantyPage" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 'none', marginBottom: '8px' }}>
          <Typography variant="h4" sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            Warranty
          </Typography>
          {/* <FaqModal module="SafalWarranty" color="#3D71FF" /> */}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
            <div style={{ display: 'flex', gap: '8px' }} />
            <div style={{ display: 'flex', gap: '8px' }}>
              <CustomSearch
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search..."
              />
              <FilterMenu filterState={filter} onFilterReset={onFilterReset} onFilterApply={onFilterApply} />
              <Tooltip title="Refresh">
                <IconButton
                  sx={{
                    height: '40px',
                    flex: 'none',
                    color: '#FFFFFF',
                    backgroundColor: '#3D71FF',
                    '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                  }}
                  onClick={() => refetch()}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add new">
                <IconButton
                  onClick={onAdd}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#3D71FF',
                    height: '40px',
                    width: '40px',
                    '&:hover': { backgroundColor: '#3D71FF' },
                  }}
                >
                  <AddIcon sx={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <TableView
            selectedAccount={selectedData}
            onSelectedChange={onSelectedChange}
            onDisplay={onDisplay}
            onDelete={onDelete}
            onEdit={onEdit}
            onChangeLog={onChangeLog}
            data={data}
            paginationData={{
              total: totalData ?? 0,
              page: filter?.page ?? 1,
              limit: filter?.limit ?? 15,
            }}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </div>
      </div>
      <AddWarrantyModal
        open={modalControl.open}
        onClose={onModalClose}
        isDisplay={modalControl.isDisplay}
        data={modalControl.data}
        productCategoryData={productCategoryData}
        productSubCategoryData={productSubCategoryData}
        storeRetailerData={storeRetailerData}
        warrantyTypeData={warrantyTypeData}
      />
    </>
  );
};

export default SafalWarrantyPage;
