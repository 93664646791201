/* eslint-disable jsx-a11y/media-has-caption */
import { IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete }) => {
  const { formatDateTime } = useUserTimeZone();

  const headData = ['Image/Video', 'Type', 'Page Type', 'Link', 'Created at', 'Action'];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {data.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
        {data?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              {e.type === 'image' ? (
                <img
                  src={e?.value}
                  style={{ maxHeight: '100px', objectFit: 'cover', borderRadius: '20px', border: '1px solid #027c34' }}
                  alt="background"
                />
              ) : (
                <video
                  muted
                  style={{ maxHeight: '100px', objectFit: 'cover', borderRadius: '20px', border: '1px solid #027c34' }}
                  width="300"
                  height="100"
                  controls="controls"
                  autoPlay="false"
                >
                  <source src={e.value} />
                </video>
              )}
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.type?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{getPageType(e?.pageType?.toString())}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.link?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <div
                style={{
                  display: 'flex',
                }}
              >
                <IconButton onClick={() => onDelete(e)}>
                  <DeleteIcon />
                </IconButton>
                {/* <IconButton onClick={() => onEdit(e)}>
                  <EditIcon />
                </IconButton> */}
              </div>
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;

export const getPageType = (pageType) => {
  switch (pageType) {
    case 'dashboard':
      return 'Dashboard page';
    case 'offers':
      return 'Offers page top banner';
    case 'offersSquare':
      return 'Offers page bottom square';
    case 'safalGift':
      return 'SafalGift page banner';
    case 'safalRoomPublic':
      return 'SafalRoom Public top banner';
    case 'safalRoomPublicSide':
      return 'safalRoom Public side banner';
    case 'safalRoomPrivate':
      return 'SafalRoom Private top banner';
    case 'safalRoomPrivateSide':
      return 'SafalRoom Private side banner';
    case 'safalBuddyLeft':
      return 'SafalBuddy page left banner';
    case 'safalBuddyTopLeft':
      return 'SafalBuddy page top left banner';
    default:
      return '';
  }
};
