import React, { useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, OutlinedInput, TableContainer, TableRow, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';

import useSortHook from '../../hooks/useSortHook';
import {
  CustomTableCellForBody,
  CustomTableCellForLoading,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import { getUserAssessmentReport } from '../../services/Service';
import useDebounce from '../../hooks/useDebounce';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import Inlineloader from '../../components/Loader/InlineLoader';
import UserAssessmentReportFilterMenu from './UserAssessmentReportFilterMenu';
import UserAssessmentModal from '../../components/Modals/UserAssessmentModal';

const UserAssessmentReport = () => {
  const { formatDateTime } = useUserTimeZone();
  const [filterState, setFilterState] = useState({
    type: '',
    fromSubmitDate: '',
    toSubmitDate: '',
    minPoints: '',
    maxPoints: '',
    status: '',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const { handleSort, newSortData, sortState } = useSortHook(tableDataRaw, ['date', 'submitDate']);

  // DeBounce Function
  useDebounce(
    async () => {
      await fetchReportData();
    },
    [filterState],
    1000
  );

  const fetchReportData = async () => {
    setIsLoading(true);
    try {
      const _filterState = { ...filterState };
      if (_filterState.fromSubmitDate) {
        _filterState.fromSubmitDate = moment(_filterState.fromSubmitDate).add(1, 'days').toISOString();
      }
      if (_filterState.toSubmitDate) {
        _filterState.toSubmitDate = moment(_filterState.toSubmitDate).add(1, 'days').toISOString();
      }

      const res = await getUserAssessmentReport({
        ..._filterState,
      });
      setTableDataRaw(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  // User Assessment
  const [openUserAssessmentModal, setOpenUserAssessmentModal] = useState({
    data: null,
    open: false,
    isReportMode: false,
  });

  const onCloseUserAssessmentModal = () => {
    setOpenUserAssessmentModal({
      data: null,
      open: false,
      isReportMode: false,
    });
  };

  const onViewClick = (values) => {
    console.log('DEBUG onViewClick values', values);
    setOpenUserAssessmentModal({
      data: values,
      open: true,
      isReportMode: true,
    });
  };
  const onContinueClick = (values) => {
    setOpenUserAssessmentModal({
      data: values,
      open: true,
      isReportMode: false,
    });
  };

  if (isLoading) return <Inlineloader />;
  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div />
          <div style={{ display: 'flex', gap: '16px' }}>
            <UserAssessmentReportFilterMenu state={filterState} onFilterApply={onFilterApply} />
            <Tooltip title="Refresh">
              <IconButton
                sx={{
                  color: '#FFFFFF',
                  backgroundColor: '#3D71FF',
                  '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
                }}
                onClick={fetchReportData}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          {/* {tableData.length} of {totalData} */}
          <TableContainer sx={{ maxHeight: 600 }}>
            <CustomTableView
              isSort
              sort={{
                order: sortState.order,
                orderBy: sortState.orderBy,
                createSortHandler: handleSort,
                sortHeadMap: ['date', 'type', 'submitDate', 'pointsReceived', 'status', ''],
              }}
              headData={[
                'Date ',
                'Assessment type',
                'Submission date',
                'Reward Received',
                'Assessment Status',
                'Action',
              ]}
            >
              {isLoading && <CustomTableCellForLoading />}
              {!isLoading && tableDataRaw.length === 0 && (
                <CustomTableCellForNoData message="We didn't find any data." />
              )}
              {!isLoading &&
                newSortData?.map((e, index) => (
                  <TableRow key={index}>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>
                        {formatDateTime(e?.date)} {getTimeZoneAbbreviation()}
                      </p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.type}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>
                        {formatDateTime(e?.submitDate)} {getTimeZoneAbbreviation()}
                      </p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{`${new Intl.NumberFormat().format(e.pointsReceived)} Spts`}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      <p style={tableCellPStyle}>{e.status}</p>
                    </CustomTableCellForBody>
                    <CustomTableCellForBody align="center">
                      {e.status === 'Draft' ? (
                        <LinkButton title="Continue" onClick={() => onContinueClick(e)} />
                      ) : (
                        <LinkButton title="View" onClick={() => onViewClick(e)} />
                      )}
                    </CustomTableCellForBody>
                  </TableRow>
                ))}
            </CustomTableView>
          </TableContainer>
        </div>
        <UserAssessmentModal
          refetch={() => fetchReportData()}
          scenario={openUserAssessmentModal?.data?.type ?? 'MonthlyFeedback'}
          data={openUserAssessmentModal?.data}
          isReportMode={openUserAssessmentModal?.isReportMode}
          open={openUserAssessmentModal?.open}
          onClose={onCloseUserAssessmentModal}
        />
      </div>
    </>
  );
};

const LinkButton = ({ title, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        textTransform: 'none',
        color: '#3D71FF',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        padding: '4px 6px',
        textDecoration: 'underline',
      }}
    >
      {title}
    </button>
  );
};

export default UserAssessmentReport;

// const TopCard = ({ name, value }) => {
//   return (
//     <div
//       style={{
//         height: '80px',
//         width: '200px',
//         border: '1px solid #bcceff',
//         boxShadow: '0px 4px 4px 0px #00000040',
//         borderRadius: '8px',
//         display: 'flex',
//         flexDirection: 'column',
//         padding: '8px',
//         justifyContent: 'space-between',
//         backgroundColor: '#bcceff',
//       }}
//     >
//       <span style={{ color: '#001E6F' }}>{name}</span>
//       <span style={{ color: '#001E6F', fontWeight: 'bold', marginLeft: 'auto' }}>{value}</span>
//     </div>
//   );
// };
