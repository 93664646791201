import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import './AddSubscriptionAnimation.css';
import aniGif from '../../assets/subadd.gif';
import { changeAddSubscriptionAnimation } from '../../store/ToggleSlice';

const AddSubscriptionAnimation = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(changeAddSubscriptionAnimation());
    }, 3500);
  }, []);

  return (
    <div id="AddSubscriptionAnimation">
      <img src={aniGif} alt="AddSubscriptionAnimation" />
    </div>
  );
};

export default AddSubscriptionAnimation;
