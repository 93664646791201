import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

const ChatSearch = ({ onClose, messages, setMessages }) => {
  const [searchInput, setSearchInput] = useState('');
  const backupMessageRef = useRef([]);

  useEffect(() => {
    backupMessageRef.current = messages;
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    const messagesFilter = backupMessageRef.current.filter((e) =>
      e?.text?.toLowerCase().includes(searchInput.trim().toLowerCase())
    );
    setMessages(messagesFilter);
  };

  const onReset = (e) => {
    e.preventDefault();
    setMessages(backupMessageRef.current);
    backupMessageRef.current = [];
    onClose();
  };

  return (
    <Paper
      onSubmit={onSearch}
      component="form"
      elevation={4}
      sx={{ p: '2px 4px', m: '10px', display: 'flex', alignItems: 'center', width: 'calc(100%-20px)' }}
    >
      <InputBase
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search messages in this conversation"
        inputProps={{ 'aria-label': 'search messages in this conversation' }}
      />
      <Tooltip title="Search">
        <IconButton onClick={onSearch} type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Tooltip>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <Tooltip title="Reset & Close">
        <IconButton onClick={onReset} color="primary" sx={{ p: '10px' }} aria-label="reset">
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};
export default ChatSearch;
