import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
// import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDisplay, onConfigurePricing }) => {
  // const { formatDateTime } = useUserTimeZone();
  const { handleSort, newSortData, sortState } = useSortHook(data, ['createdAt']);

  const headData = ['Pages', 'Sections-Web', 'Sections-Mobile', 'Status', 'Actions'];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: handleSort,
          sortHeadMap: ['pageName', '', '', 'status', ''],
        }}
        headData={headData}
      >
        {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any data." />}
        {newSortData?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.pageName}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle }}>{e?.sectionsWeb?.map((ex) => ex?.name)?.join(', ')}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle }}>{e?.sectionsMobile?.map((ex) => ex?.name)?.join(', ')}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.status ? 'Active' : 'Inactive'}</p>
            </CustomTableCellForBody>
            {/* <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody> */}
            <CustomTableCellForBody align="center">
              <ThreeDotMenu
                onDisplay={() => onDisplay(e)}
                onEdit={() => onEdit(e)}
                customButton={[
                  {
                    name: 'Configure Pricing',
                    icon: 'foundation:price-tag',
                    onClick: () => onConfigurePricing(e),
                  },
                ]}
              />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
