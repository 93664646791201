/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import '../../styles/global.css';
import NewCallLogModal from '../Modals/NewCallLogModal';
import NewOnlineUserModel from '../Modals/NewOnlineUserModel';
import { changeChatResponsiveToggle } from '../../store/ToggleSlice';

export default function Navbar({ handleClose }) {
  const [openOnline, setOpenOnline] = useState(false);
  const { chatMode } = useSelector((state) => state.chats);
  const { user: profile } = useSelector((state) => state.login);
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const [openCallLog, setOpenCallLog] = useState(null);
  const dispatch = useDispatch();

  const handleClickOnline = () => {
    setOpenOnline(true);
  };

  const handleClickCallLog = () => {
    setOpenCallLog('self');
  };

  const onChatResponsiveToggle = () => {
    dispatch(changeChatResponsiveToggle());
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0px 10px' }}>
      <span className="logo">Message</span>
      <div>
        <button
          style={{
            padding: '5px 8px',
            borderRadius: '12px',
            border: 'none',
            backgroundColor: '#01b855',
            color: 'white',
          }}
          onClick={handleClickCallLog}
        >
          Call Log
        </button>{' '}
        &nbsp;
        <button
          style={{
            padding: '5px 8px',
            borderRadius: '12px',
            border: 'none',
            backgroundColor: '#01b855',
            color: 'white',
          }}
          onClick={handleClickOnline}
        >
          Online
        </button>
        &nbsp;
        <button
          style={{
            padding: '5px 8px',
            borderRadius: '12px',
            border: 'none',
            backgroundColor: '#01b855',
            color: 'white',
          }}
          className="closeOnDesktop"
          onClick={onChatResponsiveToggle}
        >
          <MenuIcon
            style={{
              height: '13px',
              width: '13px',
            }}
          />
        </button>
        &nbsp;
        <button
          style={{
            padding: '5px 8px',
            borderRadius: '12px',
            border: 'none',
            backgroundColor: '#01b855',
            color: 'white',
          }}
          className="closeOnDesktop"
          onClick={handleClose}
        >
          <CloseIcon
            style={{
              height: '13px',
              width: '13px',
            }}
          />
        </button>
      </div>
      <NewOnlineUserModel openEditModal={openOnline} setOpenEditModal={setOpenOnline} />
      <NewCallLogModal
        open={openCallLog}
        onClose={() => setOpenCallLog(null)}
        type={chatMode}
        myUid={currentFirebaseUser?.uid}
        profile={profile}
      />
    </div>
  );
}
