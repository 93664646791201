import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Image } from 'antd';

import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import CustomRating from '../../components/UI/CustomRating';
import { getCompanyType } from '../../services/Service';
import CustomMuiSelect from '../../components/UI/CustomMuiSelect';
import DateField from '../../components/UI/DateField';

const LabelReportFilterMenu = ({ state, onFilterApply, onFilterReset, companyData }) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [filterState, setFilterState] = useState({
    nextBillingFrom: '',
    nextBillingTo: '',
    amountTo: '',
    amountFrom: '',
    search: '',
    frequency: '',
    companyType: '',
    status: '',
  });
  const [companyType, setCompanyType] = useState([]);

  const ratingList = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeField = (data, fieldName) => {
    setFilterState((prev) => ({ ...prev, [fieldName]: data }));
    setShowApply(false);
  };

  useEffect(() => {
    if (open) {
      setFilterState(state);
    }
  }, [open]);

  const handleFilterApply = () => {
    onFilterApply(filterState);
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterApply({
      nextBillingFrom: '',
      nextBillingTo: '',
      amountTo: '',
      amountFrom: '',
      search: '',
      frequency: '',
      companyType: '',
      status: '',
    });
    handleClose();
  };

  // Update resetState whenever the state changes
  useEffect(() => {
    setShowApply(isThisResetState);
  }, [state]);

  const isThisResetState =
    state?.nextBillingFrom !== '' ||
    state?.nextBillingTo !== '' ||
    state?.amountTo !== '' ||
    state?.amountFrom !== '' ||
    state?.search !== '' ||
    state?.frequency !== '' ||
    state?.companyType !== '' ||
    state?.status !== '';

  // State to store the boolean value
  const [showApply, setShowApply] = useState(isThisResetState);

  const fetchComType = async () => {
    try {
      const res = await getCompanyType();
      setCompanyType(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchComType();
  }, [open]);

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '300px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        {/* companyType */}
        <CustomMuiSelect
          disablePortal={false}
          value={filterState?.companyType}
          focused={filterState?.companyType}
          onChange={(e) => onChangeField(e.label, 'companyType')}
          sx={{
            my: '8px',
          }}
          label="Company Type"
          size="small"
          variant="outlined"
          title={filterState?.companyType}
          options={companyType.map((e) => ({ label: e.type }))}
        />
        {/* frequency */}
        <TextField
          value={filterState?.frequency}
          focused={filterState?.frequency}
          onChange={(e) => onChangeField(e.target.value, 'frequency')}
          sx={{
            my: '8px',
          }}
          name="frequency"
          select
          label="Frequency"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem value="All">All</MenuItem>
          {/* <MenuItem value="Trial">Trial</MenuItem> */}
          <MenuItem value="Monthly">Monthly</MenuItem>
          <MenuItem value="Annually">Annually</MenuItem>
        </TextField>
        {/* status */}
        <TextField
          value={filterState?.status === true ? 'active' : filterState?.status === false ? 'inactive' : undefined}
          focused={filterState?.status === true ? 'active' : filterState?.status === false ? 'inactive' : undefined}
          onChange={(e) => onChangeField(e.target.value === 'active', 'status')}
          sx={{
            mt: '8px',
          }}
          name="status"
          select
          label="Status"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </TextField>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '8px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Contract Start Date
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <DateField
              value={filterState.nextBillingFrom}
              fullWidth
              variant="outlined"
              label="From"
              onChange={(e) => onChangeField(e.toISOString(), 'nextBillingFrom')}
            />
            <DateField
              value={filterState.nextBillingTo}
              fullWidth
              variant="outlined"
              label="To"
              onChange={(e) => onChangeField(e.toISOString(), 'nextBillingTo')}
            />
          </div>
        </div>
        <div style={{ display: 'flex', gap: '8px', margin: '8px 0px' }}>
          {/* amountFrom */}
          <TextField
            value={filterState?.amountFrom}
            focused={filterState?.amountFrom}
            onChange={(e) => onChangeField(e.target.value, 'amountFrom')}
            name="amountFrom"
            label="Amount From"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
          {/* amountTo */}
          <TextField
            value={filterState?.amountTo}
            focused={filterState?.amountTo}
            onChange={(e) => onChangeField(e.target.value, 'amountTo')}
            name="amountTo"
            label="Amount To"
            size="small"
            type="number"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start" />,
            }}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default LabelReportFilterMenu;
