/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import './ManageCall.css';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';

import { getShiftTiming, deleteShiftTiming, updateShiftTiming } from '../../services/Service';
import SupportCallTimingModal from '../../components/Modals/SupportCallTimingModal';

function ManageCall() {
  const [shiftTimingListRaw, setShiftTimingListRaw] = useState([]);
  const [shiftTimingList, setShiftTimingList] = useState([]);
  const [modal, setModal] = useState({
    isOpen: false,
    data: null,
  });

  const fetchShiftTiming = async () => {
    const res = await getShiftTiming();
    if (res.status !== 200) {
      toast('got an error from getShiftTiming API');
      return;
    }
    setShiftTimingListRaw(res.data.data);
    const newShiftTimingList = res.data.data.map((e) => ({
      supportId: e.supportId,
      id: e._id,
      endTime: e.endTime,
      startTime: e.startTime,
      isEnable: e.isEnable,
    }));
    setShiftTimingList(newShiftTimingList);
  };

  useEffect(() => {
    fetchShiftTiming();
  }, []);

  const onDelete = async (value) => {
    try {
      const res = await deleteShiftTiming(value.id);
      if (res.status === 200) {
        toast.success('delete successfully');
        fetchShiftTiming();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  const onEnableDisable = async (value) => {
    try {
      const res = await updateShiftTiming({
        id: value.id,
        startTime: value.startTime,
        endTime: value.endTime,
        isEnable: !value.isEnable,
      });
      if (res.status === 200) {
        toast.success('update successfully');
        fetchShiftTiming();
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
  };

  return (
    <>
      <div id="ManageCall">
        <div className="action-bar">
          <h2>Manage support shift time</h2>
          <div>
            <Button style={{ marginRight: '10px' }} variant="outlined" onClick={fetchShiftTiming}>
              Refresh
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                setModal({
                  isOpen: true,
                  data: null,
                });
              }}
              startIcon={<AddIcon />}
            >
              Add Shift
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="caption table">
            <caption>Note : Time in here in 24h format (UTC Time Zone)</caption>
            <TableHead>
              <TableRow>
                <TableCell>User Id</TableCell>
                <TableCell>Start Time</TableCell>
                <TableCell>End Time</TableCell>
                <TableCell>Enable/Disable</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shiftTimingList.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.supportId}</TableCell>
                  <TableCell>{row.startTime}</TableCell>
                  <TableCell>{row.endTime}</TableCell>
                  <TableCell>
                    <Switch checked={row.isEnable} onChange={() => onEnableDisable(row)} />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setModal({
                          isOpen: true,
                          data: row,
                        });
                      }}
                      style={{ marginRight: '8px' }}
                      aria-label="delete"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        onDelete(row);
                      }}
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <SupportCallTimingModal
        isOpen={modal.isOpen}
        data={modal.data}
        onClose={() => {
          setModal({
            isOpen: false,
            data: null,
          });
        }}
      />
    </>
  );
}

export default ManageCall;
