import { Container, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NewPasswordForm } from '../../sections/auth/forgetPassword';
import checkmark_animate from '../../assets/checkmark_animate.gif';

const ContentStyle = styled('div')(() => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  //   padding: theme.spacing(12, 0, 0, 0),
}));

const VerifyToken = () => {
  const { token } = useParams();
  const [isTokenVerified, setIsTokenVerified] = useState(false);

  return (
    <Container maxWidth="sm">
      <ContentStyle>
        {isTokenVerified ? (
          <>
            <img
              style={{ margin: '0px auto' }}
              width="150px"
              height="150px"
              src={checkmark_animate}
              alt="successfully"
            />
            <Typography variant="h5" textAlign={'center'} gutterBottom>
              New password update successfully
            </Typography>
            <Typography variant="subtitle1" textAlign={'center'} gutterBottom>
              please login via our mobile app or use our web app to <Link to="/login">login</Link>.
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" gutterBottom>
              Enter Your New Password
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 5 }}>Enter your details below.</Typography>
            <NewPasswordForm token={token} setIsTokenVerified={setIsTokenVerified} />
          </>
        )}
      </ContentStyle>
    </Container>
  );
};

export default VerifyToken;
