import { useSelector } from 'react-redux';
import moment from 'moment';

export function getTimeZoneAbbreviation() {
  const currentTimeZone = moment.tz(moment.tz.guess()).zoneAbbr();
  // Return the corresponding abbreviation, or fall back to the full time zone
  return currentTimeZone;
}

const useUserTimeZone = () => {
  const { user } = useSelector((state) => state.login);
  const formatDateTime = (timeValue) => {
    if (user.country === 'India') {
      const newTime = moment(timeValue).format('DD/MM/YYYY hh:mm A');
      // const newTime = moment(timeValue).tz('Asia/Kolkata').format('DD/MM/YYYY hh:mm A');
      return newTime;
    }
    const newTime = moment(timeValue).format('MM/DD/YYYY hh:mm A');
    // const newTime = moment(timeValue).tz('America/New_York').format('MM/DD/YYYY hh:mm A');
    return newTime;
  };
  const formatTime = (timeValue) => {
    if (user.country === 'India') {
      const newTime = moment(timeValue).format('hh:mm A');
      // const newTime = moment(timeValue).tz('Asia/Kolkata').format('hh:mm A');
      return newTime;
    }
    const newTime = moment(timeValue).format('hh:mm A');
    // const newTime = moment(timeValue).tz('America/New_York').format('hh:mm A');
    return newTime;
  };
  const formatDate = (timeValue) => {
    if (user.country === 'India') {
      const newTime = moment(timeValue).format('DD/MM/YYYY');
      // const newTime = moment(timeValue).tz('Asia/Kolkata').format('DD/MM/YYYY');
      return newTime;
    }
    const newTime = moment(timeValue).add(1, 'days').format('MM/DD/YYYY');
    // const newTime = moment(timeValue).tz('America/New_York').add(1, 'days').format('MM/DD/YYYY');
    return newTime;
  };
  const formatDate2 = (timeValue) => {
    if (user.country === 'India') {
      const newTime = moment(timeValue).format('DD-MMM-YYYY');
      // const newTime = moment(timeValue).tz('Asia/Kolkata').format('DD-MMM-YYYY');
      return newTime;
    }
    const newTime = moment(timeValue).format('DD-MMM-YYYY');
    // const newTime = moment(timeValue).tz('America/New_York').format('DD-MMM-YYYY');
    return newTime;
  };
  const expiryCheck = (timeValue) => {
    const targetTimezone = user.country === 'India' ? 'Asia/Kolkata' : 'America/New_York';
    // Convert UTC date to a moment object
    const utcMoment = moment.utc(timeValue);

    // Convert UTC moment to the target timezone
    const targetMoment = utcMoment.clone().tz(targetTimezone);

    // Get the current moment in the target timezone
    const currentMoment = moment().tz(targetTimezone);

    // Compare the moments to check if the input moment has expired
    return targetMoment.isBefore(currentMoment);
  };

  const timeFromNow = (timeValue) => {
    if (user.country === 'India') {
      // new Date(message?.date?.seconds * 1000)
      const newTime = moment(timeValue).format('LT');
      // const newTime = moment(timeValue).tz('Asia/Kolkata').format('LT');
      return newTime;
    }
    const newTime = moment(timeValue).format('LT');
    // const newTime = moment(timeValue).tz('America/New_York').format('LT');
    return newTime;
  };
  const timeFromNow2 = (timeValue) => {
    const newTime = moment(timeValue).fromNow();
    return newTime;
  };

  return {
    formatDateTime,
    formatTime,
    formatDate,
    expiryCheck,
    timeFromNow,
    formatDate2,
    timeFromNow2,
  };
};

export default useUserTimeZone;
