import { Button, IconButton, InputAdornment, Menu, MenuItem, TextField, Tooltip, Badge } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import DateField from '../../components/UI/DateField';

const LogReportFilterMenu = ({
  field,
  setField,
  onFilterApply,
  toDate,
  setFromDate,
  fromDate,
  setToDate,
  onFilterReset,
}) => {
  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterApply = () => {
    onFilterApply();
    handleClose();
  };
  const handleFilterReset = () => {
    onFilterReset();
    handleClose();
  };

  const isThisResetState = field !== null || toDate !== '' || fromDate !== '';

  return (
    <div>
      <Tooltip title="Filter">
        <IconButton
          onClick={handleClick}
          sx={{
            display: 'flex',
            alignItems: 'center',
            // ml: 2,
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <Badge color="error" variant="dot" invisible={!isThisResetState}>
            <FilterAltIcon sx={{ color: 'white' }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            width: '350px',
            padding: '0px 8px',
          },
        }}
      >
        <h2>Filter</h2>
        {/* Category field */}
        <TextField
          value={field}
          focused={field}
          onChange={(e) => setField(e.target.value)}
          sx={{
            my: '8px',
          }}
          select
          label="Select Category"
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start" />,
          }}
        >
          <MenuItem value={'General'}>General</MenuItem>
          <MenuItem value={'AddSub'}>Add Subscription</MenuItem>
          <MenuItem value={'EditSub'}>Edit Subscription</MenuItem>
          <MenuItem value={'DeleteSub'}>Delete Subscription</MenuItem>
          <MenuItem value={'personalDetails'}>Personal Details</MenuItem>
          <MenuItem value={'EditPic'}>Edit Pic</MenuItem>
          <MenuItem value={'Phone Number'}>Phone Number</MenuItem>
          <MenuItem value={'Primary Email'}>Primary Email</MenuItem>
          <MenuItem value={'Addon Email'}>Addon Email</MenuItem>
          <MenuItem value={'login'}>Login</MenuItem>
          <MenuItem value={'logout'}>Logout</MenuItem>
          <MenuItem value={'password'}>Password</MenuItem>
          <MenuItem value={'downloadSubdata'}>Download Sub Data</MenuItem>
          <MenuItem value={'changeBudget'}>Change Budget</MenuItem>
          <MenuItem value={'Feedback'}>Feedback</MenuItem>
          <MenuItem value={'downloadReportsdata'}>Download Reports Data</MenuItem>
          <MenuItem value={'downloadFeedbacks'}>Download Feedback Report</MenuItem>
          <MenuItem value={'loyaltyAdded'}>Add Loyalty Card</MenuItem>
          <MenuItem value={'giftAdded'}>Add Gift Card</MenuItem>
          <MenuItem value={'rewardAdded'}>Add Reward Certificate</MenuItem>
          <MenuItem value={'loyaltyDataDownload'}>Download Loyalty Card</MenuItem>
          <MenuItem value={'giftDataDownload'}>Download Gift Card</MenuItem>
          <MenuItem value={'rewardDataDownload'}>Download Reward Certificate</MenuItem>
          <MenuItem value={'loyaltyUpdated'}>Edit Loyalty Card</MenuItem>
          <MenuItem value={'giftUpdated'}>Edit Gift Card</MenuItem>
          <MenuItem value={'rewardUpdated'}>Edit Reward Certificate</MenuItem>
          <MenuItem value={'loyaltyDeleted'}>Deleted Loyalty Card</MenuItem>
          <MenuItem value={'giftDeleted'}>Deleted Gift Card</MenuItem>
          <MenuItem value={'rewardDeleted'}>Deleted Reward Certificate</MenuItem>
        </TextField>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', margin: '8px 0px' }}>
          <span className="filter-sub-label-x2" style={{ paddingLeft: '6px' }}>
            Date
          </span>
          <div style={{ display: 'flex', gap: '8px' }}>
            <DateField
              value={fromDate}
              fullWidth
              variant="outlined"
              label="From"
              onChange={(e) => setFromDate(e.toISOString())}
            />
            <DateField
              value={toDate}
              fullWidth
              variant="outlined"
              label="To"
              onChange={(e) => setToDate(e.toISOString())}
            />
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
          <Button
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            onClick={handleClose}
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterReset}
            size="small"
            variant="contained"
          >
            Reset
          </Button>
          <Button
            sx={{ textTransform: 'capitalize', borderRadius: '30px' }}
            onClick={handleFilterApply}
            size="small"
            variant="contained"
          >
            Apply
          </Button>
        </div>
      </Menu>
    </div>
  );
};

export default LogReportFilterMenu;
