/* eslint-disable react/jsx-boolean-value */
import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getUserActiveInactivePie } from '../../../../services/Service';
import { formatNumberToShort } from '../../../../utils/general';

// const colors = ['#468b47','#90ee90', '#edf9eb',"#385239"];
// const colors = ['#468b47', '#66b267', '#90ee90', '#aad4ab', '#cce5cc', '#edf9eb'];

const UserActiveInactivePieCard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [mode, setMode] = useState('CurrentMonth'); // CurrentMonth, CurrentYear

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getUserActiveInactivePie();
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const monthlyActiveUsers = data?.monthlyActiveUsers ?? 0;
  const yearlyActiveUsers = data?.yearlyActiveUsers ?? 0;
  const dailyActiveUsers = data?.dailyActiveUsers ?? 0;
  const monthlyInactiveUsers = data?.monthlyInactiveUsers ?? 0;
  const lastMonthInactiveUsers = data?.lastMonthInactiveUsers ?? 0;
  const total = data?.total ?? 0;

  const chartOption = {
    title: {
      text: formatNumberToShort(total),
      subtext: 'Users',
      left: 'left', // Align title to the left
      textStyle: {
        color: '#468b47', // Green color for the title
        lineHeight: 16, // Reduced line height
      },
      subtextStyle: {
        color: '#468b47', // Green color for the subtitle
        lineHeight: 2, // Reduced line height for subtitle
      },
    },
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        name: 'Users',
        type: 'pie',
        radius: '50%',
        data: [
          {
            value: mode === 'CurrentMonth' ? monthlyActiveUsers : yearlyActiveUsers,
            name: mode === 'CurrentMonth' ? 'Monthly user' : 'Yearly user',
          },
          { value: dailyActiveUsers, name: 'Daily user' },
          { value: monthlyInactiveUsers, name: 'Not Active' },
        ],
        itemStyle: {
          color: (params) => {
            const colors = ['#468b47', '#90ee90', '#edf9eb'];
            return colors[params.dataIndex % colors.length]; // Apply custom colors
          },
        },
      },
    ],
  };

  return (
    <div
      style={{
        padding: '12px',
        borderRadius: '16px',
        backgroundColor: '#ffffff',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <select
        value={mode}
        style={{ position: 'absolute', right: '12px', top: '12px', zIndex: 1 }}
        onChange={(e) => setMode(e.target.value)}
      >
        <option value="CurrentMonth">Current Month</option>
        <option value="CurrentYear">Current Year</option>
      </select>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        option={chartOption}
        showLoading={loading}
        style={{ height: '220px', minHeight: '200px' }}
      />
      <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '28px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'max-content',
        }}
      >
        <LegendItem color="#468b47" text="Monthly user" />
        <LegendItem color="#90ee90" text="Daily user" />
        <LegendItem color="#edf9eb" text="Not Active" />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '12px',
          position: 'absolute',
          bottom: '6px',
          left: '50%',
          transform: 'translateX(-50%)',
          width: 'max-content',
          color: '#468b47',
          fontSize: '14px',
        }}
      >
        {formatNumberToShort(lastMonthInactiveUsers)} users inactivated last month
      </div>
    </div>
  );
};
export default UserActiveInactivePieCard;

const LegendItem = ({ color, text }) => {
  return (
    <div style={{ display: 'flex', gap: '6px', alignItems: 'center', flex: 'none' }}>
      <div style={{ backgroundColor: color, borderRadius: '9999px', height: '18px', width: '18px' }} />
      <span style={{ fontSize: '14px', marginTop: '2px' }}>{text}</span>
    </div>
  );
};
