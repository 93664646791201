import { Button, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import './CompanyTypeList.css';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import { getCompanyType, deleteCompanyType } from '../../../services/Service';
import CustomButton from '../../../components/UI/CustomButton';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import TableView from './TableView';
import AddCompanyTypeModal from './AddCompanyTypeModal';

const CompanyTypeList = () => {
  const [searchValue, setSearchValue] = useState('');
  const [addModalOpen, setAddModalOpen] = useState({
    open: false,
    data: null,
  });
  const [rawData, setRawData] = useState([]);
  const [rawDataFilter, setRawDataFilter] = useState([]);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState({
    open: false,
    data: null,
  });

  const fetchData = async () => {
    try {
      const res = await getCompanyType();
      if (res.status === 200) {
        setRawData(res.data.data);
        setRawDataFilter(res.data.data);
      }
    } catch (err) {
      console.log('getCompanyType fetchData error', err.message);
    }
  };

  const onAddModalOpen = () => {
    setAddModalOpen({
      open: true,
      data: null,
    });
  };
  const onAddModalOpenEdit = (data) => {
    setAddModalOpen({
      open: true,
      data: data,
    });
  };
  const onAddModalClose = () => {
    setAddModalOpen({
      open: false,
      data: null,
    });
    fetchData();
  };

  const onDelete = async (data) => {
    try {
      const res = await deleteCompanyType(data._id);
      if (res.status === 200) {
        toast.success(res.data.message);
        onDeleteModalReset();
        fetchData();
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err.message);
    }
  };

  const onDeleteModalReset = () => {
    setIsDeleteConfirmationModalOpen({
      open: false,
      data: null,
    });
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    const value = e.target.value.toLowerCase().trim();
    if (value === '') {
      setRawDataFilter(rawData);
      return;
    }
    const res = rawData.filter((ex) => ex?.type.toLowerCase().includes(value));
    setRawDataFilter(res);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Company Type List - SafalSubscriptions</title>
      </Helmet>

      <div id="CompanyTypeList">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              Company Type List
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix">
            <TextField
              sx={{ width: '200px', ml: '12px' }}
              onChange={onSearchChange}
              value={searchValue}
              type="search"
              id="search-input"
              size="small"
              label="Search"
              variant="outlined"
            />
          </div>
          <div className="title-action-div">
            <CustomButton backgroundColor="#027c34" text="Add New" icon={<AddIcon />} onClick={onAddModalOpen} />
          </div>
        </div>
        <TableView
          onDelete={(data) => {
            setIsDeleteConfirmationModalOpen({
              open: true,
              data,
            });
          }}
          onEdit={onAddModalOpenEdit}
          data={rawDataFilter}
        />
      </div>
      <DeleteConfirmationModal
        open={isDeleteConfirmationModalOpen.open}
        onClose={onDeleteModalReset}
        data={isDeleteConfirmationModalOpen.data}
        onSubmit={onDelete}
      />
      <AddCompanyTypeModal open={addModalOpen.open} data={addModalOpen.data} onClose={onAddModalClose} />
    </>
  );
};

export default CompanyTypeList;
