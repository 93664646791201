import { IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '200px',
};

const TableView = ({ data, onEdit, onDelete }) => {
  const { formatDateTime } = useUserTimeZone();

  const headData = ['Mega Module', 'Module', 'Question', 'Answer', 'Created At', 'Actions'];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {data.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
        {data?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.megaModuleId?.megaModule?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.module?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle, maxWidth: '300px' }}>{e?.question?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={{ ...tableCellPStyle, maxWidth: '400px' }}>
                {e?.answer?.map((ex, index) => (
                  <>
                    <span key={index}>{`Ans ${index + 1} : ${ex}`}</span>
                    <br />
                  </>
                ))}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <div
                style={{
                  display: 'flex',
                }}
              >
                <IconButton onClick={() => onDelete(e)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => onEdit(e)}>
                  <EditIcon />
                </IconButton>
              </div>
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
