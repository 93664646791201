import AgoraUIKit from 'agora-react-uikit';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateAgoraToken } from '../../services/Service';

const AgoraVideoCall = ({ agoraState, onCallEnd }) => {
  const [rtcProps, setRtcProps] = useState(null);

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const agoraStateChange = async () => {
    const rantInt = randomIntFromInterval(1, 1000000);
    const res = await generateAgoraToken({
      channelName: agoraState.callId,
      uid: rantInt,
    });
    const newRtcProps = {
      appId: process.env.REACT_APP_AGORA_APP_ID,
      channel: res.data.channelName,
      token: res.data.token,
      uid: rantInt,
    };
    setRtcProps(newRtcProps);
    console.log('AgoraVideoCall START');
  };

  useEffect(() => {
    agoraStateChange();

    return () => {
      setRtcProps(null);
    };
  }, []);

  const callbacks = {
    EndCall: async () => {
      console.log('AgoraVideoCall END');
      onCallEnd();
    },
  };

  return rtcProps && <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} />;
};

export default AgoraVideoCall;
