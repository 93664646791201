/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-else-return */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';
import { doc, onSnapshot, updateDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { resetAgoraState, setAgoraState } from '../../store/AgoraSlice';
import { updateCallDuration, cloudRecordingStop, sendUserNotificationForCall } from '../../services/Service';
import { db } from '../../firebase';
import './AgoraUI.css';
import CallPopUp from './CallPopUp';
import AgoraAudioCall from './AgoraAudioCall';
import AgoraVideoCall from './AgoraVideoCall';
import { resetCallRecoirdingAlertState } from '../../store/CallRecoirdingAlert';
import MiniCallUI from './MiniCallUI';
import { resetAgoraMiniState } from '../../store/AgoraMiniSlice';

// Call Object Referance
// callId: undefined, // uuidv4()
// isCall: false, //
// type: undefined, // user or support
// callMode: 'audio', // audio/video,
// currentUserId: undefined, // currentUserId or undefined
// otherUserId: undefined, // undefined or otherUserId
// status: undefined, // undefined,"pending", "accept", "reject", "end"
// startAt: undefined, // serverTimestamp or undefined
// endAt: undefined, // serverTimestamp() or undefined

const AgoraUI = () => {
  const dispatch = useDispatch();
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const callRecoirdingAlertState = useSelector((state) => state.callRecoirdingAlertState);
  const { user } = useSelector((state) => state.login);
  const agoraState = useSelector((state) => state.agoraState);
  const agoraMiniState = useSelector((state) => state.agoraMiniState);
  const isThisSupportUser = Object.hasOwn(user, 'role') ? user.role === 'business' : null;
  const autoDisconnectCallRef = useRef(null);

  const onCallDisconnect = async (agoraState, callRecoirdingAlertState) => {
    if (callRecoirdingAlertState) {
      await cloudRecordingStop(callRecoirdingAlertState);
    }
    if (agoraState === null) return;
    if (!agoraState.isCall) return;
    const callId = agoraState.callId;
    const callLogRef = doc(db, 'callLogs', agoraState.callId);
    const payloadObj = {
      isCall: false,
      status: 'missed', // "pending", "accept", "reject", "end"
      endAt: serverTimestamp(),
      startAt: serverTimestamp(),
    };

    if (agoraState.flag === 'user_to_user') {
      const currentUserCallDocRef = doc(db, 'userCalls', agoraState.currentUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.otherUserId);
      await updateDoc(otherUserCallDocRef, payloadObj);
      await updateDoc(currentUserCallDocRef, payloadObj);
      if (agoraState.otherUserId) {
        const displayName = user?.middleName
          ? `${user?.firstName} ${user?.middleName} ${user?.lastName}`
          : `${user?.firstName} ${user?.lastName}`;
        await sendUserNotificationForCall({
          userId: agoraState.otherUserId,
          message: `You received a Missed call from ${displayName}`,
          userName: displayName,
        });
      }
    } else if (agoraState.flag === 'support_to_user') {
      const currentUserCallDocRef = doc(db, 'supportCalls', agoraState.currentUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.otherUserId);
      await updateDoc(currentUserCallDocRef, payloadObj);
      await updateDoc(otherUserCallDocRef, payloadObj);
    } else if (agoraState.flag === 'user_to_support') {
      // user to support
      const currentUserCallDocRef = doc(db, 'supportCalls', agoraState.otherUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.currentUserId);
      await updateDoc(currentUserCallDocRef, payloadObj);
      await updateDoc(otherUserCallDocRef, payloadObj);
    }

    await updateDoc(callLogRef, payloadObj);
    await updateCallDuration({ callId });
    dispatch(resetAgoraState());
    dispatch(resetCallRecoirdingAlertState());
    dispatch(resetAgoraMiniState());
    if (autoDisconnectCallRef.current) {
      clearTimeout(autoDisconnectCallRef.current);
      autoDisconnectCallRef.current = null;
    }
  };

  const onCallRejectEnd = async (status) => {
    if (callRecoirdingAlertState) {
      await cloudRecordingStop(callRecoirdingAlertState);
    }
    if (agoraState === null) return;
    if (!agoraState.isCall) return;
    const callId = agoraState.callId;
    const callLogRef = doc(db, 'callLogs', agoraState.callId);
    const payloadObj = {
      isCall: false,
      status: status, // "pending", "accept", "reject", "end", "selfend"
      endAt: serverTimestamp(),
    };
    if (status === 'selfend' || status === 'reject' || status === 'missed') {
      payloadObj.startAt = serverTimestamp();
    }

    if (agoraState.flag === 'user_to_user') {
      const currentUserCallDocRef = doc(db, 'userCalls', agoraState.currentUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.otherUserId);
      await updateDoc(otherUserCallDocRef, payloadObj);
      await updateDoc(currentUserCallDocRef, payloadObj);
    } else if (agoraState.flag === 'support_to_user') {
      const currentUserCallDocRef = doc(db, 'supportCalls', agoraState.currentUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.otherUserId);
      await updateDoc(currentUserCallDocRef, payloadObj);
      await updateDoc(otherUserCallDocRef, payloadObj);
    } else if (agoraState.flag === 'user_to_support') {
      // user to support
      const currentUserCallDocRef = doc(db, 'supportCalls', agoraState.otherUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.currentUserId);
      await updateDoc(currentUserCallDocRef, payloadObj);
      await updateDoc(otherUserCallDocRef, payloadObj);
    }

    await updateDoc(callLogRef, payloadObj);
    await updateCallDuration({ callId });
    dispatch(resetAgoraState());
    dispatch(resetCallRecoirdingAlertState());
    if (autoDisconnectCallRef.current) {
      clearTimeout(autoDisconnectCallRef.current);
      autoDisconnectCallRef.current = null;
    }
  };

  const onCallAccept = async () => {
    if (agoraState === null) return;
    if (!agoraState.isCall) return;
    // NOTE : flag = user_to_user or support_to_user or user_to_support
    const callLogRef = doc(db, 'callLogs', agoraState.callId);
    const payloadObj = {
      startAt: serverTimestamp(),
      isCall: true,
      status: 'accept', // "pending", "accept", "reject", "end"
    };

    if (agoraState.flag === 'user_to_user') {
      const currentUserCallDocRef = doc(db, 'userCalls', agoraState.currentUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.otherUserId);
      await updateDoc(otherUserCallDocRef, payloadObj);
      await updateDoc(currentUserCallDocRef, payloadObj);
    } else if (agoraState.flag === 'support_to_user') {
      const currentUserCallDocRef = doc(db, 'supportCalls', agoraState.currentUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.otherUserId);
      await updateDoc(currentUserCallDocRef, payloadObj);
      await updateDoc(otherUserCallDocRef, payloadObj);
    } else if (agoraState.flag === 'user_to_support') {
      // user to support
      const currentUserCallDocRef = doc(db, 'supportCalls', agoraState.otherUserId);
      const otherUserCallDocRef = doc(db, 'userCalls', agoraState.currentUserId);
      await updateDoc(currentUserCallDocRef, payloadObj);
      await updateDoc(otherUserCallDocRef, payloadObj);
    }

    await updateDoc(callLogRef, payloadObj);
  };

  // this reset all state isCallReset
  useEffect(() => {
    const isCallReset = async (currentFirebaseUser, isThisSupportUser) => {
      try {
        if (isThisSupportUser === null) return;
        if (!currentFirebaseUser) return;
        const currentUserCallDocRef = isThisSupportUser
          ? doc(db, 'supportCalls', currentFirebaseUser.uid)
          : doc(db, 'userCalls', currentFirebaseUser.uid);
        await updateDoc(currentUserCallDocRef, {
          isCall: false,
        });
      } catch (err) {
        console.log('AgoraUI onSnapshot', err);
        console.log('AgoraUI onSnapshot', err.message.includes('No document to update'));
        if (err.message.includes('No document to update')) {
          const callId = uuidv4();
          const callObj = {
            callId: callId,
            isCall: false,
            callMode: 'audio',
            type: 'user',
            status: 'end',
            currentUserId: currentFirebaseUser.uid,
            otherUserId: currentFirebaseUser.uid,
            startAt: serverTimestamp(),
          };
          const currentUserCallDocRef = isThisSupportUser
            ? doc(db, 'supportCalls', currentFirebaseUser.uid)
            : doc(db, 'userCalls', currentFirebaseUser.uid);
          setDoc(currentUserCallDocRef, callObj);
        }
      }
    };
    isCallReset(currentFirebaseUser, isThisSupportUser);
    return () => isCallReset(currentFirebaseUser, isThisSupportUser);
  }, [currentFirebaseUser, isThisSupportUser]);

  useEffect(() => {
    if (isThisSupportUser === null) return;
    // this useEffect function will auto disconnect call after 1min of ring
    const autoDisconnectCall = (data, uid) => {
      if (data.currentUserId === uid && data.status === 'pending') {
        if (autoDisconnectCallRef.current === null) {
          autoDisconnectCallRef.current = setTimeout(() => {
            toast('call not answer');
            onCallDisconnect(data, callRecoirdingAlertState);
          }, 1000 * 60);
        }
      } else {
        if (autoDisconnectCallRef.current) {
          clearTimeout(autoDisconnectCallRef.current);
          autoDisconnectCallRef.current = null;
        }
      }
    };

    if (isThisSupportUser && currentFirebaseUser?.uid) {
      const callDocRef = doc(db, 'supportCalls', currentFirebaseUser.uid);
      // const callDocRef = doc(db, 'supportCalls', 'businessSupportUser');
      const unsubscribe = onSnapshot(callDocRef, async (snapshot) => {
        if (snapshot.exists()) {
          const snapshotData = snapshot.data();
          autoDisconnectCall(snapshotData, currentFirebaseUser.uid);
          dispatch(setAgoraState(snapshotData));
        }
      });
      return () => unsubscribe();
    } else if (currentFirebaseUser && currentFirebaseUser.uid) {
      const callDocRef = doc(db, 'userCalls', currentFirebaseUser.uid);
      const unsubscribe = onSnapshot(callDocRef, async (snapshot) => {
        if (snapshot.exists()) {
          const snapshotData = snapshot.data();
          autoDisconnectCall(snapshotData, currentFirebaseUser.uid);
          dispatch(setAgoraState(snapshotData));
        }
      });
      return () => unsubscribe();
    }
  }, [currentFirebaseUser, isThisSupportUser]);

  const isRender = agoraState !== null ? agoraState.isCall : false;

  const isVideoCallStart = agoraState !== null && agoraState.status === 'accept' && agoraState.callMode === 'video';
  const isAudioCallStart = agoraState !== null && agoraState.status === 'accept' && agoraState.callMode === 'audio';
  const isCallRing = agoraState !== null && agoraState.isCall === true && agoraState.status !== 'accept';

  return (
    <>
      {isRender && (
        <div
          style={{
            backgroundColor: isVideoCallStart ? 'black' : 'rgba(0, 0, 0, 0.5)',
            display: agoraMiniState?.open && 'none',
          }}
          className="AgoraUI"
        >
          {isAudioCallStart && <AgoraAudioCall agoraState={agoraState} onCallEnd={() => onCallRejectEnd('end')} />}
          {isVideoCallStart && <AgoraVideoCall agoraState={agoraState} onCallEnd={() => onCallRejectEnd('end')} />}
          {isCallRing && (
            <CallPopUp
              agoraState={agoraState}
              currentFirebaseUser={currentFirebaseUser}
              onAcceptCall={onCallAccept}
              onRejectCall={() => onCallRejectEnd('reject')}
              onCallRejectEnd={() => onCallRejectEnd('selfend')}
            />
          )}
        </div>
      )}
      {agoraMiniState?.open && <MiniCallUI />}
    </>
  );
};

export default AgoraUI;
