import { IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone from '../../../hooks/useUserTimeZone';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete }) => {
  const { formatDateTime } = useUserTimeZone();

  const headData = ['Tier', 'Name', 'Description', 'Prices', 'Status', 'Action'];
  // const headData = ['tier', 'name', 'description',"prices","status", 'Action'];

  return (
    <TableContainer>
      <CustomTableView headData={headData}>
        {data.length === 0 && <CustomTableCellForNoData message="You Don't have any plan. Please Add New." />}
        {data?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.tier?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.name?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.description?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {['BETA', 'FREE'].includes(e.id) && 'Not applicable'}
                {!['BETA', 'FREE'].includes(e.id) && e.prices.length === 0
                  ? 'No price are set'
                  : e.prices.map((ex) => (
                      <>
                        <span>{`${(ex.unit_amount / 100).toFixed(2)} ${ex.currency} per ${
                          ex.interval_count !== 1 ? ex.interval_count : ''
                        } ${ex.interval}`}</span>
                        <br />
                      </>
                    ))}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              {['BETA', 'FREE'].includes(e.id) && <p style={tableCellPStyle}>Active</p>}
              {!['BETA', 'FREE'].includes(e.id) && (
                <p style={tableCellPStyle}>{e?.status === true ? 'Active' : 'Inactive'}</p>
              )}
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <div
                style={{
                  display: 'flex',
                }}
              >
                {/* <IconButton onClick={() => onDelete(e)}>
                  <DeleteIcon />
                </IconButton> */}
                {/* <IconButton title="View in Stripe" onClick={() => onEdit(e)}>
                  <EditIcon />
                </IconButton> */}
                <IconButton disabled={['BETA', 'FREE'].includes(e.id)} title="Edit" onClick={() => onEdit(e)}>
                  <EditIcon />
                </IconButton>
              </div>
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
