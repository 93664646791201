/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationCount: 0,
};
export const BusinessNotificationsDotSlice = createSlice({
  name: 'businessNotificationsDotSlice',
  initialState,
  reducers: {
    setBusinessNotificationsDotCount: (state, action) => {
      return {
        ...state,
        notificationCount: action.payload.notificationCount,
      };
    },
  },
});

export const { setBusinessNotificationsDotCount } = BusinessNotificationsDotSlice.actions;

export default BusinessNotificationsDotSlice.reducer;
