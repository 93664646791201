import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, OutlinedInput, TableContainer, TableRow, Tooltip } from '@mui/material';
import { Image } from 'antd';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';
import moment from 'moment';

import DateField from '../../components/UI/DateField';
import CustomButton from '../../components/UI/CustomButton';
import useSortHook from '../../hooks/useSortHook';
import {
  CustomTableCellForBody,
  CustomTableCellForLoading,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import UserCommentsModal from '../../components/Modals/UserCommentsModal';
import {
  getSignedURL,
  getSafalLeaderBoardReport,
  getSafalLeaderBoardReportDownload,
  getUserSafalGiftOrdersHistory,
} from '../../services/Service';
import useDebounce from '../../hooks/useDebounce';
import Iconify from '../../components/Iconify';
import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import SafalGiftOrderReportFilterMenu from './SafalGiftOrderReportFilterMenu';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import CustomRating from '../../components/UI/CustomRating';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import Inlineloader from '../../components/Loader/InlineLoader';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const SafalGiftOrderReport = () => {
  const { formatDateTime } = useUserTimeZone();
  const [filterState, setFilterState] = useState({
    startDate: '',
    endDate: '',
    // minPoints: '',
    // maxPoints: '',
    status: '',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 200,
  });
  const [metaData, setMetaData] = useState({ redeemRequest: 0, totalSafalPoints: 0, expiresSafalPoints: 0 });
  const [totalData, setTotalData] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilterData, setTableFilterData] = useState([]);
  const { handleSort, newSortData, sortState } = useSortHook(tableFilterData, ['createdAt']);

  // DeBounce Function
  useDebounce(
    async () => {
      await fetchSubscriptionsTrend();
    },
    [filterState],
    1000
  );

  const fetchSubscriptionsTrend = async () => {
    setIsLoading(true);
    try {
      const _filterState = { ...filterState };
      if (_filterState.endDate) {
        _filterState.endDate = moment(_filterState.endDate).add(1, 'days').toISOString();
      }
      if (_filterState.startDate) {
        _filterState.startDate = moment(_filterState.startDate).add(1, 'days').toISOString();
      }

      const res = await getUserSafalGiftOrdersHistory({ ..._filterState });
      setTableDataRaw(res.data.data);
      const formatData = res.data.data?.map((e) => ({
        orderId: e?.orderId,
        totalPoints: e?.totalPoints,
        status: e?.status,
        createdAt: e?.createdAt,
        products: e?.cartSnapShort,
      }));

      setTableData(formatData);
      setTableFilterData(formatData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  // const downloadTrend = async (type) => {
  //   try {
  //     const res = await getSafalLeaderBoardReportDownload({
  //       ...filterState,
  //       application: 'SafalSubscriptions',
  //       fileType: type,
  //     });
  //     const signedURLRes = await getSignedURL({
  //       url: res.data.url,
  //     });
  //     const link = document.createElement('a');
  //     link.href = signedURLRes.data;
  //     link.setAttribute('download', `file`);
  //     document.body.appendChild(link);
  //     link.click();

  //     toast.success('download successful');
  //   } catch (err) {
  //     const msg = err?.response?.data?.message || 'download failed, please try again';
  //     toast.error(msg);
  //   }
  // };

  const onChangeSearch = (value) => {
    setSearchValue(value);
    if (value?.length > 1) {
      const filterData = tableData?.filter((e) => {
        const searchData = e?.products?.map((ex) => `Qty ${ex?.qty} - ${ex?.name}`)?.join(' ');
        return searchData.toLowerCase()?.includes(value?.toLowerCase());
      });
      setTableFilterData(filterData);
    } else {
      setTableFilterData(tableData);
    }
  };

  if (isLoading) return <Inlineloader />;
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '16px', marginBottom: '8px' }} />
        <div style={{ display: 'flex', gap: '16px' }}>
          <SearchStyle
            sx={{
              width: '200px',
            }}
            value={searchValue}
            onChange={(e) => onChangeSearch(e.target.value)}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          <SafalGiftOrderReportFilterMenu state={filterState} onFilterApply={onFilterApply} />
          <Tooltip title="Refresh">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={fetchSubscriptionsTrend}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          {/* <ReportsTabsDownloadMenu downloadFile={downloadTrend} /> */}
        </div>
      </div>
      <div>
        <TableContainer sx={{ maxHeight: 600 }}>
          <CustomTableView
            isSort
            sort={{
              order: sortState.order,
              orderBy: sortState.orderBy,
              createSortHandler: handleSort,
              sortHeadMap: ['orderId', '', 'totalPoints', 'status', 'createdAt'],
            }}
            headData={['Order Id ', 'Products', 'Total Points', 'Status', 'Date and Time']}
          >
            {isLoading && <CustomTableCellForLoading />}
            {!isLoading && tableData.length === 0 && <CustomTableCellForNoData message="We didn't find any data." />}
            {!isLoading &&
              newSortData?.map((e, index) => (
                <TableRow key={index}>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e.orderId}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>
                      {e?.products?.map((ex) => {
                        return (
                          <>
                            <span>
                              Qty {ex?.qty} - {ex?.name}
                            </span>
                            <br />
                          </>
                        );
                      })}
                    </p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{`${new Intl.NumberFormat().format(e.totalPoints)} Spts`}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>
                      {e?.status === 'pending'
                        ? 'submitted'
                        : e?.status === 'accept'
                        ? 'accepted'
                        : e?.status === 'reject'
                        ? 'rejected'
                        : ''}
                    </p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>
                      {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
                    </p>
                  </CustomTableCellForBody>
                </TableRow>
              ))}
          </CustomTableView>
        </TableContainer>
      </div>
      {/* <UserCommentsModal open={isUserCommentsModal.open} onClose={onCloseUserCommentsModal} /> */}
    </div>
  );
};

export default SafalGiftOrderReport;
