import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MenuItem, Switch, TextField } from '@mui/material';
import toast from 'react-hot-toast';
import { Image, Select } from 'antd';

import {
  createSafalGiftMasterInventory,
  updateSafalGiftMasterInventory,
  uploadLeaderBoardMasterCountries,
} from '../../../services/Service';

import { usStates, indiaStates } from '../../../utils/stateList';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

export default function SafalGiftOrderModal({ open, onClose, data, type }) {
  // type - customer, product

  const { formatDate } = useUserTimeZone();
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {type === 'customer' ? 'Customer Information' : 'Products Information'}
        </Typography>
        {type === 'customer' ? (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            <span>
              Name - {data?.user?.firstName} {data?.user?.middleName} {data?.user?.lastName}
            </span>
            <span>Email - {data?.user?.email}</span>
            <span>UserID - {data?.user?.userID}</span>
            <span>Country - {data?.user?.country}</span>
            <span>State - {data?.user?.state}</span>
            <span>Total SafalPoints - {data?.userPoints?.points} Spts</span>
            <span>Redeem SafalPoints - {data?.userPoints?.redeem} Spts</span>
            <span>
              SafalPoints Valid From - {formatDate(data?.userPoints?.validStart)} {getTimeZoneAbbreviation()}
            </span>
            <span>
              SafalPoints Valid To - {formatDate(data?.userPoints?.expiryDate)} {getTimeZoneAbbreviation()}
            </span>
            <span style={{ color: 'grey', fontStyle: 'italic' }}>Delivery Address</span>
            <span>Address 1 - {data?.address?.address1}</span>
            <span>Address 1 - {data?.address?.address2}</span>
            <span>State - {data?.address?.state}</span>
            <span>Landmark - {data?.address?.landmark}</span>
            <span>Zip - {data?.address?.zip}</span>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            {data?.cartSnapShort?.map((e, index) => {
              return (
                <>
                  <span style={{ color: 'grey', fontStyle: 'italic' }}>Order Item {index + 1}</span>
                  <span>SKU - {e?.sku}</span>
                  <span>Name - {e?.name}</span>
                  <span>Description - {e?.description}</span>
                  <span>
                    Points - {e?.points} Spts, Price - $ {e?.price}
                  </span>
                  <span>At the time of Order Stock - {e?.stock}</span>
                  <span>Order qty - {e?.qty}</span>
                  <span>Total Order Points - {e?.qty * e?.points}</span>
                </>
              );
            })}
          </div>
        )}
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize', ':hover': { backgroundColor: '#19c25f' } }}
            variant="contained"
          >
            Close
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
