import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  buddyStatus: false,
  leaderBoardStatus: false,
};

export const safalLeaderBoardSwitchSlice = createSlice({
  name: 'SafalLeaderBoardSwitchSlice',
  initialState,
  reducers: {
    setSafalLeaderBoardSwitch: (state, action) => {
      state = action.payload;
      return state;
    },
    resetSafalLeaderBoardSwitch: () => {
      return initialState;
    },
  },
});

export const { setSafalLeaderBoardSwitch, resetSafalLeaderBoardSwitch } = safalLeaderBoardSwitchSlice.actions;

export default safalLeaderBoardSwitchSlice.reducer;
