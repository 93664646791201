import { Switch } from '@mui/material';
import { useEffect, useState } from 'react';
import { getUserToggleSetting, getUserToggleSettingBusiness, updateUserToggleSetting } from '../../services/Service';
import ErrorToast from '../../components/Toast/Error';

const SecurityTabPublicSetting = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: true,
    lastName: true,
    profilePicture: true,
    country: true,
  });
  const [dataBusiness, setDataBusiness] = useState({
    firstName: true,
    lastName: true,
    profilePicture: true,
    country: true,
  });

  // updateUserToggleSetting
  // const fetchData = async () => {
  //   setLoading(true);
  //   try {
  //     const res = await getUserToggleSetting();
  //     const resBusiness = await getUserToggleSettingBusiness();
  //     if (resBusiness.status === 200) {
  //       setDataBusiness(resBusiness.data.data);
  //     }
  //     if (res.status === 200) {
  //       setData(res.data.data);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const onChange = async (e) => {
    // if (dataBusiness[e.target.name] === false) {
    //   ErrorToast(
    //     'This functionality falls outside the scope of your current subscription settings. Please review your existing subscription settings. in setting Notification screen'
    //   );
    //   return;
    // }
    // const payload = { ...data, [e.target.name]: e.target.checked };
    // setLoading(true);
    // try {
    //   const res = await updateUserToggleSetting(payload);
    //   if (res.status === 200) {
    //     fetchData();
    //   }
    // } catch (err) {
    //   console.log(err);
    // } finally {
    //   setLoading(false);
    // }
  };

  return (
    <>
      <div>
        <div style={{ maxWidth: '600px', display: 'flex', flexDirection: 'column', gap: '12px' }}>
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.firstName}
            name="firstName"
            title="First Name"
            subTitle="Allow the first name visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.lastName}
            name="lastName"
            title="Last Name"
            subTitle="Allow the last name visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.profilePicture}
            name="profilePicture"
            title="Profile Picture"
            subTitle="Allow the profile picture visible to other users"
          />
          <ToggleItem
            onChange={onChange}
            disabled={loading}
            checked={data?.country}
            name="country"
            title="Country"
            subTitle="Allow the country visible to other users"
          />
        </div>
      </div>
    </>
  );
};

const ToggleItem = ({ title, subTitle, checked, onChange, disabled, name }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}>
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</span>
        <span style={{ fontSize: '12px' }}>{subTitle}</span>
      </div>
      <Switch
        disabled={disabled}
        name={name}
        checked={checked}
        onChange={onChange}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </div>
  );
};

export default SecurityTabPublicSetting;
