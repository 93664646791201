import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, OutlinedInput, TableContainer, TableRow, Tooltip } from '@mui/material';
import { Image } from 'antd';
import { styled } from '@mui/material/styles';
import toast from 'react-hot-toast';

import DateField from '../../components/UI/DateField';
import CustomButton from '../../components/UI/CustomButton';
import useSortHook from '../../hooks/useSortHook';
import {
  CustomTableCellForBody,
  CustomTableCellForLoading,
  CustomTableCellForNoData,
  CustomTableView,
  tableCellPStyle,
} from '../../components/UI/CustomTableView';
import UserCommentsModal from '../../components/Modals/UserCommentsModal';
import { downloadSubscriptionsTrend, getSignedURL, getSubscriptionsTrend } from '../../services/Service';
import useDebounce from '../../hooks/useDebounce';
import Iconify from '../../components/Iconify';
import disLikeImage from '../../assets/disLike.png';
import likeImage from '../../assets/Like.png';
import likeDislikeImage from '../../assets/likeDislike.png';
import SubscriptionTrendFilterMenu from './SubscriptionTrendFilterMenu';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import CustomRating from '../../components/UI/CustomRating';
import Inlineloader from '../../components/Loader/InlineLoader';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const SubscriptionTrend = () => {
  const [filterState, setFilterState] = useState({
    to: '',
    from: '',
    search: '',
    companyName: '',
    companyType: '',
    rating: '',
    safal: '',
  });

  const [isLoading, setIsLoading] = useState(true);
  const [tableDataRaw, setTableDataRaw] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { handleSort, newSortData, sortState } = useSortHook(tableData);

  // const [isUserCommentsModal, setIsUserCommentsModal] = useState({
  //   open: false,
  // });
  // const onCloseUserCommentsModal = () => {
  //   setIsUserCommentsModal({ open: false });
  // };
  // const onOpenUserCommentsModal = () => {
  //   setIsUserCommentsModal({ open: true });
  // };

  // DeBounce Function
  useDebounce(
    async () => {
      await fetchSubscriptionsTrend();
    },
    [filterState],
    1000
  );

  const fetchSubscriptionsTrend = async () => {
    setIsLoading(true);
    try {
      const res = await getSubscriptionsTrend(filterState);
      setTableDataRaw(res.data.data);
      const newData = res.data.data.map((ex) => ({
        logo: ex.company?.logo,
        companyName: ex.company?.name,
        companyType: ex.company?.companyType,
        totalUsers: ex.totalUsers,
        averageRating: ex.averageRating,
        like: ex.like.likeCount,
        dislike: ex.like.dislikeCount,
        isNeutral: ex.like.likeCount === ex.like.dislikeCount,
      }));
      setTableData(newData);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onFilterApply = (newFilter) => {
    setFilterState((prev) => ({ ...prev, ...newFilter }));
  };

  const downloadTrend = async (type) => {
    try {
      const res = await downloadSubscriptionsTrend({ ...filterState, fileType: type });
      const signedURLRes = await getSignedURL({
        url: res.data.url,
      });
      const link = document.createElement('a');
      link.href = signedURLRes.data;
      link.setAttribute('download', `file`);
      document.body.appendChild(link);
      link.click();

      toast.success('download successful');
    } catch (err) {
      const msg = err?.response?.data?.message || 'download failed, please try again';
      toast.error(msg);
    }
  };

  if (isLoading) return <Inlineloader />;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: '16px' }}>
          {/* <DateField
            width="140px"
            value={filterState.from}
            variant="standard"
            label="From"
            onChange={(e) => setFilterState((prev) => ({ ...prev, from: e.toISOString() }))}
          />
          <DateField
            width="140px"
            value={filterState.to}
            variant="standard"
            label="To"
            onChange={(e) => setFilterState((prev) => ({ ...prev, to: e.toISOString() }))}
          /> */}
        </div>
        <div style={{ display: 'flex', gap: '16px' }}>
          <SearchStyle
            sx={{
              width: '200px',
            }}
            value={filterState.search}
            onChange={(e) => setFilterState((prev) => ({ ...prev, search: e.target.value }))}
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          <SubscriptionTrendFilterMenu state={filterState} companyData={tableData} onFilterApply={onFilterApply} />
          <Tooltip title="Refresh">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={fetchSubscriptionsTrend}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <ReportsTabsDownloadMenu downloadFile={downloadTrend} />
        </div>
      </div>
      <div>
        {tableData.length} of {tableData.length}
        <TableContainer sx={{ maxHeight: 600 }}>
          <CustomTableView
            isSort
            sort={{
              order: sortState.order,
              orderBy: sortState.orderBy,
              createSortHandler: handleSort,
              sortHeadMap: ['', '', 'companyName', 'companyType', 'totalUsers', 'averageRating', 'like'],
            }}
            headData={['Sr. No', 'Logo', 'Company Name', 'Company Type', 'Total Users', 'Rating', 'Safal']}
          >
            {isLoading && <CustomTableCellForLoading />}
            {!isLoading && tableData.length === 0 && <CustomTableCellForNoData message="We didn't find any data." />}
            {!isLoading &&
              newSortData?.map((e, index) => (
                <TableRow key={index}>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{index + 1}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <Image src={e?.logo} height={40} />
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e.companyName}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e.companyType}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <p style={tableCellPStyle}>{e.totalUsers}</p>
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    <CustomRating value={e.averageRating} readOnly precision={0.5} size="small" />
                  </CustomTableCellForBody>
                  <CustomTableCellForBody align="center">
                    {!e.isNeutral && (
                      <Image src={e.like > e.dislike ? likeImage : disLikeImage} height={40} preview={false} />
                    )}
                    {e.isNeutral && <Image src={likeDislikeImage} height={40} preview={false} />}
                  </CustomTableCellForBody>
                </TableRow>
              ))}
          </CustomTableView>
        </TableContainer>
      </div>
      {/* <UserCommentsModal open={isUserCommentsModal.open} onClose={onCloseUserCommentsModal} /> */}
    </div>
  );
};

export default SubscriptionTrend;
