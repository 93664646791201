/* eslint-disable react/jsx-no-duplicate-props */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Avatar, FormControl, InputAdornment, MenuItem, OutlinedInput, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { doc, getDoc } from 'firebase/firestore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import useProfileImage from '../../../hooks/useProfileImage';
import { updateSafalBuddy } from '../../../services/Service';
import Iconify from '../../../components/Iconify';
import SafalLeaderBoard_Call from '../../../assets/SafalLeaderBoard_Call.png';
import SafalLeaderBoard_Chat from '../../../assets/SafalLeaderBoard_Chat.png';
import { db } from '../../../firebase';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function SafalBuddy({ open, onClose, data, refetch, onCall, onChat }) {
  const login = useSelector((state) => state.login);
  const currentUser = login?.user;
  const [searchValue, setSearchValue] = useState('');
  const [selectedType, setSelectedType] = useState('pending');
  const handleClose = async () => {
    onClose();
  };

  const filterData =
    selectedType === 'pending'
      ? data.filter((e) => e?.status === selectedType && e?.receiver?._id === currentUser?._id)
      : data.filter((e) => e?.status === selectedType);

  const updateBuddy = async ({ id, status, otherUserId }) => {
    try {
      const res = await updateSafalBuddy({ _id: id, status, otherUserId });
      if (res?.status === 200) refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const pendingCount = data.filter((e) => e?.status === 'pending' && e?.receiver?._id === currentUser?._id);
  const acceptCount = data.filter((e) => e?.status === 'accept');
  const rejectCount = data.filter((e) => e?.status === 'reject');

  const onChangeSearch = (value) => {
    setSearchValue(value);
  };

  const filterDataNew = filterData.filter((e) => {
    const cardUser = currentUser._id === e?.sender?._id ? e?.receiver : e?.sender;
    const fullName = `${cardUser?.firstName} ${cardUser?.lastName}`;
    return fullName.toLowerCase().includes(searchValue.toLowerCase());
  });

  const SafalBuddyTitle =
    selectedType === 'pending'
      ? `Pending ( ${pendingCount?.length} )`
      : selectedType === 'reject'
      ? `Rejected ( ${rejectCount?.length} )`
      : selectedType === 'accept'
      ? `Accepted ( ${acceptCount?.length} )`
      : '';

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF' }}>
            SafalBuddy - {SafalBuddyTitle}
          </Typography>
          <div>
            <SearchStyle
              sx={{
                width: '200px',
              }}
              value={searchValue}
              onChange={(e) => onChangeSearch(e.target.value)}
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: '#3D71FF', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
            <FormControl size="small" sx={{ width: '200px', ml: '12px' }}>
              <Select
                labelId="SafalBuddy-selected"
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <MenuItem value="pending">Pending ( {pendingCount?.length} )</MenuItem>
                <MenuItem value="reject">Rejected ( {rejectCount?.length} )</MenuItem>
                <MenuItem value="accept">Accepted ( {acceptCount?.length} )</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div
          style={{
            marginTop: '20px',
            overflow: 'auto',
            height: '400px',
          }}
        >
          {filterDataNew.length > 0 ? (
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '12px',
                marginTop: '20px',
                overflow: 'auto',
              }}
            >
              {filterDataNew.map((e) => (
                <UserCard
                  onCall={onCall}
                  onChat={onChat}
                  currentUser={currentUser}
                  data={e}
                  selectedType={selectedType}
                  updateBuddy={updateBuddy}
                />
              ))}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>No Buddies found</span>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            onClick={handleClose}
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#ff4842',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#d40700' },
            }}
            variant="contained"
            color="error"
          >
            Close
          </Button>
          {/* <Button
              onClick={onSubmit}
              sx={{ backgroundColor: '#3d71ff', textTransform: 'capitalize', borderRadius: '30px' }}
              variant="contained"
            >
              Create
            </Button> */}
        </div>
      </Box>
    </Modal>
  );
}

const UserCard = ({ data, currentUser, selectedType, updateBuddy, onCall, onChat }) => {
  const cardUser = currentUser._id === data?.sender?._id ? data?.receiver : data?.sender;
  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const profileImage = useProfileImage(cardUser?.profilePic);
  const fullName = `${cardUser?.firstName} ${cardUser?.lastName}`;

  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const currentUserToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  const isChatCallEnable = currentUserToUserChatEnable ? otherUserInfo?.userToUserChat === true : false;
  const fetchOtherUserFirebase = async (otherUserId) => {
    if (!otherUserId) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', otherUserId));
    if (otherUserInfoRef.exists()) {
      setOtherUserInfo(otherUserInfoRef.data());
    }
  };

  useEffect(() => {
    fetchOtherUserFirebase(cardUser?._id);
  }, []);

  const onCallClick = async () => {
    onCall(cardUser?._id, otherUserInfo);
  };
  const onChatClick = async () => {
    onChat(cardUser?._id, otherUserInfo);
  };

  return (
    <div
      style={{
        backgroundColor: selectedType === 'pending' ? '#b4aa48' : '#c8d7ff',
        height: '60px',
        borderRadius: '12px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
        padding: '0px 10px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Avatar sx={{ height: '40px', width: '40px' }} alt={fullName} src={profileImage} />
        <div>
          <p
            style={{
              overflow: 'hidden',
              fontSize: '18px',
              color: '#001E6F',
              fontWeight: 'bold',
              maxWidth: '135px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {fullName}
          </p>
        </div>
      </div>
      <div style={{ display: 'flex', gap: '4px' }}>
        <button
          title="Chat"
          onClick={onChatClick}
          disabled={!isChatCallEnable}
          style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
        >
          <img
            src={SafalLeaderBoard_Chat}
            style={{
              width: '20px',
              height: '20px',
              objectFit: 'contain',
              filter: !isChatCallEnable ? 'grayscale(1)' : '',
            }}
            alt="SafalLeaderBoard_Chat"
          />
        </button>
        <button
          title="Call"
          onClick={onCallClick}
          disabled={!isChatCallEnable}
          style={{ border: 'none', backgroundColor: 'transparent', padding: '2px', cursor: 'pointer' }}
        >
          <img
            src={SafalLeaderBoard_Call}
            style={{
              width: '20px',
              height: '20px',
              objectFit: 'contain',
              filter: !isChatCallEnable ? 'grayscale(1)' : '',
            }}
            alt="SafalLeaderBoard_Call"
          />
        </button>
      </div>
      {selectedType === 'pending' && (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button
            onClick={() => updateBuddy({ id: data._id, status: 'reject', otherUserId: cardUser?._id })}
            sx={{
              textTransform: 'capitalize',
              backgroundColor: '#A80000',
              color: 'white',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#A80000' },
            }}
            variant="contained"
            color="error"
            size="small"
          >
            Reject
          </Button>
          <Button
            onClick={() => updateBuddy({ id: data._id, status: 'accept', otherUserId: cardUser?._id })}
            size="small"
            sx={{
              backgroundColor: '#025E16',
              textTransform: 'capitalize',
              borderRadius: '30px',
              ':hover': { backgroundColor: '#025E16' },
            }}
            variant="contained"
          >
            Accept
          </Button>
        </div>
      )}
    </div>
  );
};
