import { useEffect, useState } from 'react';
import moment from 'moment';

import InfoCard from './InfoCard';
import { getARPUData } from '../../../../services/Service';
import { formatNumberToShort } from '../../../../utils/general';

const ArrMrr = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    arpu: 0,
    arpuChange: 0,
    customerCount: 0,
    customerChange: 0,
    mrr: 0,
    mrrChange: 0,
    arr: 0,
    arrChange: 0,
  });

  const fetchData = async (filter) => {
    try {
      setLoading(true);
      const res = await getARPUData(filter);
      setData(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();

    fetchData({ endDate, startDate });
  }, []);

  return (
    <div className="BusinessDashboard-grid-2">
      <InfoCard
        title="Total Active MRR"
        value={`$ ${formatNumberToShort(data.mrr)}`}
        subValue={`${data.mrrChange}%`}
        subTitle="since last month"
        // bottomTitle="MRR Renewal Rate %"
        // bottomValue="32.2%"
        imgSrc="/assets/images/b_dashboard/pie_icon.png"
      />
      <InfoCard
        title="Customer Count"
        value={`${formatNumberToShort(data.customerCount)} Nos`}
        subValue={`${data.customerChange}%`}
        subTitle="since last month"
        // bottomTitle="New customer this month %"
        // bottomValue="32.2%"
        imgSrc="/assets/images/b_dashboard/count_icon.png"
      />
      <InfoCard
        title="Total Active ARR"
        value={`$ ${formatNumberToShort(data.arr)}`}
        subValue={`${data.arrChange}%`}
        subTitle="since last year"
        // bottomTitle="MRR Renewal Rate %"
        // bottomValue="32.2%"
        imgSrc="/assets/images/b_dashboard/pie_icon.png"
      />
      <InfoCard
        title="Total Active ARPU"
        value={`$ ${formatNumberToShort(data.arpu)}`}
        subValue={`${data.arpuChange}%`}
        subTitle="since last month"
        // bottomTitle="MRR Renewal Rate %"
        // bottomValue="32.2%"
        imgSrc="/assets/images/b_dashboard/pie_icon.png"
      />
    </div>
  );
};
export default ArrMrr;
