import * as React from 'react';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#468b47',
  },
  '& .MuiRating-iconHover': {
    color: '#468b47',
  },
  '& .MuiRating-iconEmpty': {
    color: '#468b47',
  },
});

const CustomRatingBusiness = ({ ...props }) => {
  return <StyledRating {...props} />;
};

export default CustomRatingBusiness;
