/* eslint-disable dot-notation */
import {
  Box,
  Button,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EditComapnysubsResponse,
  GetCustomCompaniesResponse,
  GetcompaniesResponse,
  SaveCompanyResponse,
  getCompanyType,
} from '../../services/Service';
import SuccessToast from '../Toast/Success';
import { setBusiness, setCustomCompany } from '../../store/businessSlice';
import { setCompanies } from '../../store/companiesSlice';
import Scrollbar from '../Scrollbar';
import CustomMuiSelect from '../UI/CustomMuiSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '20px',
  boxShadow: 24,
  // p: "4 0 4 4",
  p: '30px',
};

const EditCompanyModal = ({ openEditModal, setOpenEditModal, data, handleActionClose }) => {
  console.log('editData ===>', data);
  const [selectedCompanyType, setSelectedCompanyType] = React.useState([]);
  const [companyTypes, setCompanyTypes] = React.useState([]);
  const { allCompaniesData } = useSelector((state) => state.companies);
  const { user } = useSelector((state) => state.login);
  const [indata, setInData] = useState([]);
  const { pathname } = useLocation();

  const [companyTypeNew, setCompanyTypeNew] = useState('');
  const dispatch = useDispatch();

  const handleCompanyClick = (data) => {
    const CompanyType = allCompaniesData.filter((val) => val.companyType?.toLowerCase() === data?.toLowerCase());
    setSelectedCompanyType(CompanyType);
  };

  const handleClose = () => {
    setOpenEditModal(false);
    setCompanyTypeNew('');
  };

  const fetchCompanyType = async () => {
    try {
      const res = await getCompanyType();
      setCompanyTypes(res.data.data.map((e) => e.type));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetcompaniesResponse().then((res) => {
      dispatch(setCompanies({ allCompaniesData: res.data.data }));
      // const companiesType = [...new Set(res.data.data.map((item) => item.companyType))];
      // setCompanyTypes(companiesType);
    });
    fetchCompanyType();
  }, [openEditModal, data]);

  // const [isChecked, setIsChecked] = useState({
  //   isRewardChecked: data?.isRewardChecked,
  //   isGiftChecked: data?.isGiftChecked,
  //   isLoyltyChecked: data?.isLoyltyChecked,
  // });

  // const handleCheckBoxChange = (event, name) => {
  //   setIsChecked((prev) => ({
  //     ...prev,
  //     [name]: event.target.checked,
  //   }));
  // };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter name'),
    website: Yup.string().required('Please enter website'),
    // usa: Yup.number().required('Please enter US pricing'),
    // ind: Yup.number().required('Please enter INDIA pricing'),
    price: Yup.number().required('Please enter price').typeError('Please enter valid price'),
    description: Yup.string().required('Please enter description'),
    companyType: Yup.string().required('Please Select company type'),
    // logo: Yup.object().shape({
    // logo: Yup.mixed().required('Please select any one.'),
    // test('required', 'You need to provide a Logo', (value) => value?.length > 0),
    // })

    // popular: Yup.boolean().required('Please select any one.'),
  });

  const initialValues = {
    name: data?.name,
    logo: data?.logo,
    companyType: data?.companyType,
    createdAt: data?.createdAt,
    updatedBy: data?.updatedBy,
    countryCode: data?.countryCode,
    price: data?.price,
    // usa: data?.price?.find((item) => item.countryCode === 'usa').price,
    // ind: data?.price?.find((item) => item.countryCode === 'ind').price,
    popular: data?.popular ? 'true' : 'false',
    // status: `${data?.status}`,
    website: data?.website,
    description: data?.description,
    isGiftChecked: data?.isGiftChecked,
    isLoyltyChecked: data?.isLoyltyChecked,
    isRewardChecked: data?.isRewardChecked,
  };

  const EditForm = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // values.updatedBy = user?.firstName;
      // values.id = data._id;
      if (pathname === '/business/companieslist') {
        let valuesToBeSent = indata.map((val) => ({ [val]: values[val] }));
        valuesToBeSent.push({ updatedBy: user?.firstName });
        valuesToBeSent.push({ id: data._id });

        valuesToBeSent = Object.assign({}, ...valuesToBeSent);
        console.log('valuesToBeSent ===> ', valuesToBeSent);

        if (valuesToBeSent['companyType'] === 'Not Listed') {
          valuesToBeSent['companyType'] = companyTypeNew;
        }
        console.log('valuesToBeSent', valuesToBeSent);

        await EditComapnysubsResponse(valuesToBeSent).then((res) => {
          if (res.data.success === true) {
            setInData([]);
            SuccessToast('Succesfully edited !!!');
            handleClose();
            resetForm(initialValues);
          }
        });

        await GetcompaniesResponse()
          .then((res) => {
            if (res.data.success === true) {
              dispatch(setBusiness({ business: res.data.data }));
              // dispatch(setCompanies({ allCompaniesData: res.data.data }));
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              console.log(err);
            }
          });
      } else {
        values.updatedBy = user?.firstName;
        await SaveCompanyResponse(values).then((res) => {
          if (res.data.success === true) {
            SuccessToast('Succesfully Added !!!');
            handleClose();
            resetForm(initialValues);
          }
        });
        await GetCustomCompaniesResponse()
          .then((res) => {
            if (res.data.success === true) {
              dispatch(setCustomCompany({ CustomCopanyData: res.data.data }));
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              console.log(err);
            }
          });
      }
    },
  });

  const newCompanyTypeError = companyTypes.find((e) => e?.toLowerCase() === companyTypeNew?.toLowerCase());

  return (
    <Modal
      open={openEditModal}
      // onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...style, height: 'auto', width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' } }}>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ display: 'flex', alignItems: 'center', fontSize: '30px', fontWeight: 700, color: '#19C25F' }}
            >
              Edit {data.name} company
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ fontSize: '15px', fontWeight: 400 }}>
              Edit your details below.
            </Typography>
          </Box>
          <Fab
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleActionClose && handleActionClose();
            }}
            size="small"
            aria-label="add"
            sx={{ backgroundColor: '#90EE90', color: '#19C25F' }}
          >
            <CloseIcon height={35} />
          </Fab>
        </Stack>

        <Box sx={{ height: '90%', overflowY: 'auto' }}>
          <Scrollbar>
            <FormikProvider value={EditForm}>
              <form onSubmit={EditForm.handleSubmit}>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ overflowX: 'hidden' }}>
                  <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <img src={data?.logo} alt={data?.logo} height={60} width={125} />
                    <Input
                      type="file"
                      name="logo"
                      variant="standard"
                      size="small"
                      inputProps={{ accept: 'image/*' }}
                      onChange={(event) => {
                        EditForm.setFieldValue('logo', event.target.files[0]);
                        indata.indexOf('logo') === -1 && setInData((pre) => [...pre, 'logo']);
                        // setImage(event.target.files[0]);
                      }}
                    />
                  </Grid>

                  {pathname === '/business/companieslist' ? (
                    <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <CustomMuiSelect
                          label={
                            <>
                              Company Type<span style={{ color: 'red' }}>*</span>
                            </>
                          }
                          options={[...companyTypes?.map((ex) => ({ label: ex })), { label: 'Not Listed' }]}
                          // error={EditForm.touched.companyType && Boolean(EditForm.errors.companyType)}
                          // helperText={EditForm.touched.companyType && EditForm.errors.companyType}
                          onChange={(newValue) => {
                            EditForm.setFieldValue('companyType', newValue?.label || '');
                            EditForm.setFieldTouched('companyType', true);
                            handleCompanyClick(newValue?.label || '');
                            indata.indexOf('companyType') === -1 && setInData((pre) => [...pre, 'companyType']);
                          }}
                          value={EditForm.values.companyType}
                          variant="standard"
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControl
                        fullWidth
                        sx={{ mb: 3 }}
                        error={EditForm.touched.companyType && Boolean(EditForm.errors.companyType)}
                      >
                        <Field
                          as={TextField}
                          id="input1"
                          name="companyType"
                          label="Company Type"
                          variant="standard"
                          size="small"
                          value={EditForm.values.companyType}
                          onChange={(e) => {
                            EditForm.handleChange(e);
                            indata.indexOf(e.target.companyType) === -1 &&
                              setInData((pre) => [...pre, e.target.companyType]);
                          }}
                          error={EditForm.touched.companyType && Boolean(EditForm.errors.companyType)}
                          helperText={EditForm.touched.companyType && EditForm.errors.companyType}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl
                      fullWidth
                      sx={{ mb: 3 }}
                      error={EditForm.touched.name && Boolean(EditForm.errors.name)}
                    >
                      <Field
                        as={TextField}
                        id="input1"
                        name="name"
                        label={
                          <>
                            Name<span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        variant="standard"
                        size="small"
                        value={EditForm.values.name}
                        onChange={(e) => {
                          EditForm.handleChange(e);
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                        }}
                        error={EditForm.touched.name && Boolean(EditForm.errors.name)}
                        helperText={EditForm.touched.name && EditForm.errors.name}
                      />
                    </FormControl>
                  </Grid>

                  {/* Not Listed Company type */}
                  {EditForm?.values?.companyType === 'Not Listed' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl fullWidth error={newCompanyTypeError !== undefined}>
                        <Field
                          as={TextField}
                          label={
                            <>
                              New Company Type<span style={{ color: 'red' }}>*</span>
                            </>
                          }
                          variant="standard"
                          size="small"
                          value={companyTypeNew}
                          onChange={(e) => setCompanyTypeNew(e.target.value)}
                          error={newCompanyTypeError !== undefined}
                          helperText={newCompanyTypeError !== undefined ? 'This Company type already exits.' : ''}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl
                      fullWidth
                      sx={{ mb: 3 }}
                      error={EditForm.touched.description && Boolean(EditForm.errors.description)}
                    >
                      <Field
                        as={TextField}
                        id="input1"
                        name="description"
                        multiline
                        rows={3}
                        label={
                          <>
                            Description<span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        variant="standard"
                        size="small"
                        value={EditForm.values.description}
                        onChange={(e) => {
                          EditForm.handleChange(e);
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                        }}
                        error={EditForm.touched.description && Boolean(EditForm.errors.description)}
                        helperText={EditForm.touched.description && EditForm.errors.description}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl fullWidth>
                      <InputLabel id="select4" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                        Country<span style={{ color: 'red' }}>*</span>
                      </InputLabel>
                      <Select
                        labelId="select4"
                        id="select4"
                        name="countryCode"
                        // label="Popular"
                        variant="standard"
                        size="small"
                        value={EditForm.values.countryCode}
                        onChange={(e) => {
                          // EditForm.handleChange(e.target.value);
                          EditForm.setFieldValue('countryCode', e.target.value);
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                        }}
                        sx={{
                          width: '100%',
                          '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                          },
                          '&:after': {
                            borderBottomColor: '#0000',
                          },
                          '& .MuiSvgIcon-root': {
                            color: '#0071E3',
                          },
                        }}
                      >
                        <MenuItem key="IND" value="IND" sx={{ display: 'flex' }}>
                          <img
                            src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg"
                            alt="IND"
                            height={20}
                            width={20}
                            style={{ display: 'inline' }}
                          />
                          <span style={{ padding: '0 8px' }}>IND</span>
                        </MenuItem>
                        <MenuItem key="USA" value="USA" sx={{ display: 'flex' }}>
                          <img
                            src="https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg"
                            alt="USA"
                            height={20}
                            width={20}
                            style={{ display: 'inline' }}
                          />
                          <span style={{ padding: '0 8px' }}>USA</span>
                        </MenuItem>
                      </Select>
                      {EditForm.touched.countryCode && EditForm.errors.countryCode ? (
                        <FormHelperText>{EditForm.touched.countryCode && EditForm.errors.countryCode}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl
                      fullWidth
                      sx={{ mb: 3 }}
                      error={EditForm.touched.price && Boolean(EditForm.errors.price)}
                    >
                      {/* <InputLabel htmlFor="price"> */}

                      {/* </InputLabel> */}
                      <Field
                        as={Input}
                        id="input1"
                        name="price"
                        rows={3}
                        label={<>Price</>}
                        variant="standard"
                        size="small"
                        value={EditForm.values.price}
                        onChange={(e) => {
                          EditForm.handleChange(e);
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                        }}
                        startAdornment={
                          <InputAdornment position="end" sx={{ pr: 1 }}>
                            {EditForm.values.countryCode === 'IND'
                              ? '₹'
                              : EditForm.values.countryCode === 'USA'
                              ? '$'
                              : ''}
                          </InputAdornment>
                        }
                        error={EditForm.touched.price && Boolean(EditForm.errors.price)}
                        helperText={EditForm.touched.price && EditForm.errors.price}
                      />
                      {EditForm.touched.price && EditForm.errors.price ? (
                        <FormHelperText>{EditForm.touched.price && EditForm.errors.price}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl fullWidth>
                      <InputLabel id="select4" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                        Popular
                      </InputLabel>
                      <Select
                        labelId="select4"
                        id="select4"
                        name="popular"
                        label="Popular"
                        variant="standard"
                        size="small"
                        value={EditForm.values.popular}
                        onChange={(e) => {
                          EditForm.handleChange(e);
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                        }}

                        // onChange={EditForm.handleChange}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </Select>
                      {EditForm.touched.popular && EditForm.errors.popular ? (
                        <FormHelperText>{EditForm.touched.popular && EditForm.errors.popular}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl fullWidth error={EditForm.touched.website && EditForm.errors.website}>
                      {/* <InputLabel htmlFor="amount" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                        Website
                      </InputLabel> */}
                      <Field
                        as={TextField}
                        onWheel={(event) => {
                          event.preventDefault();
                        }}
                        label={
                          <>
                            Website<span style={{ color: 'red' }}>*</span>
                          </>
                        }
                        name="website"
                        variant="standard"
                        size="small"
                        value={EditForm.values.website}
                        onChange={(e) => {
                          EditForm.handleChange(e);
                          indata.indexOf(e.target.name) === -1 && setInData((pre) => [...pre, e.target.name]);
                        }}
                      />
                      {/* {EditForm.touched.website && EditForm.errors.website ? (
                        <FormHelperText>{EditForm.touched.website && EditForm.errors.website}</FormHelperText>
                      ) : null} */}
                    </FormControl>
                  </Grid>
                </Grid>

                {console.log('EditForm ===>', EditForm)}
                <Grid item xs={12} sm={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={EditForm.values.isLoyltyChecked}
                      // onChange={(event) => handleCheckBoxChange(event, 'isLoyltyChecked')}
                      onChange={(event) => {
                        EditForm.setFieldValue('isLoyltyChecked', event.target.checked);
                        indata.indexOf('isLoyltyChecked') === -1 && setInData((pre) => [...pre, 'isLoyltyChecked']);
                      }}
                      color="success"
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {'Applicable for Loyalty card'}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={EditForm?.values?.isGiftChecked}
                      onChange={(event) => {
                        EditForm.setFieldValue('isGiftChecked', event.target.checked);
                        indata.indexOf('isGiftChecked') === -1 && setInData((pre) => [...pre, 'isGiftChecked']);
                      }}
                      color="success"
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {'Applicable for Gift Cards'}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      checked={EditForm?.values?.isRewardChecked}
                      onChange={(event) => {
                        EditForm.setFieldValue('isRewardChecked', event.target.checked);
                        indata.indexOf('isRewardChecked') === -1 && setInData((pre) => [...pre, 'isRewardChecked']);
                      }}
                      color="success"
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    {'Applicable for Reward Certificates'}
                  </Box>
                </Grid>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={
                    EditForm?.values?.companyType === 'Not Listed'
                      ? newCompanyTypeError === undefined
                        ? !EditForm.isValid
                        : true
                      : !EditForm.isValid
                  }
                  // disabled={!EditForm.isValid || !EditForm.dirty}
                  sx={{
                    width: '170px',
                    height: '45px',
                    bgcolor: '#19C25F',
                    borderRadius: '30px',
                    mt: '25px',
                    boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                    textTransform: 'capitalize',
                  }}
                >
                  Save
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                    handleActionClose && handleActionClose();
                  }}
                  sx={{
                    width: '170px',
                    height: '45px',
                    borderRadius: '30px',
                    mt: '25px',
                    ml: '20px',
                    boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                    textTransform: 'capitalize',
                  }}
                >
                  Cancel
                </Button>
              </form>
            </FormikProvider>
          </Scrollbar>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditCompanyModal;
