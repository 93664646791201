export const countries = [
    {
        name: 'United States',
        code: 'US',
        emoji: '🇺🇸',
        unicode: 'U+1F1FA U+1F1F8',
        image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg',
      },
      {
        name: 'India',
        code: 'IN',
        emoji: '🇮🇳',
        unicode: 'U+1F1EE U+1F1F3',
        image: 'https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/IN.svg',
      }
 ];