/* eslint-disable no-plusplus */
import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { doc, onSnapshot } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';
import { changeUser, resetChat, resetChatSoft } from '../../store/ChatSlice';
import { db } from '../../firebase';
import Message from './Message';
import Scrollbar from '../Scrollbar';

function formatDateInterval(inputDate) {
  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');
  inputDate = moment(inputDate);
  let returnString = '';
  if (inputDate.isSame(today, 'd')) {
    returnString = 'Today';
  } else if (inputDate.isSame(yesterday, 'd')) {
    returnString = 'Yesterday';
  } else {
    returnString = inputDate.format('DD/MM/YYYY');
  }
  return returnString;
}

function compareArrays(arr1, arr2) {
  // Check if both arrays have the same length
  if (arr1.length !== arr2.length) {
    console.log('Check if both arrays have the same length');
    return false;
  }

  // Create an object for fast lookup based on IDs for arr2
  const dict2 = [];
  arr2.forEach((item) => {
    if (!dict2.includes(item.id)) dict2.push(item.id);
  });

  // Check if all IDs from arr1 exist in arr2 and have the same values
  for (let i = 0; i < arr1.length; i++) {
    const id = arr1[i].id;
    if (!dict2.includes(id)) {
      return false;
    }
  }

  return true;
}

const Messages = ({ dateRef, handleScroll, open, messages, setMessages }) => {
  const { chats } = useSelector((state) => state);
  const { currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const messageIntervalTimeRef = useRef('');
  const chatIdRef = useRef(null);

  useEffect(() => {
    if (!chats.chatId) {
      setMessages([]);
      return;
    }

    const unSub = onSnapshot(doc(db, 'chats', chats.chatId), (doc) => {
      if (doc.exists()) {
        const data = doc.data().messages;
        if (Array.isArray(data)) {
          if (!compareArrays(data, messages === undefined && [])) setMessages(data);
        }
      }
    });
    return () => {
      unSub();
      setMessages([]);
    };
  }, [chats]);

  return (
    <div className="messages">
      {Array.isArray(messages) &&
        messages.map((m, i) => {
          let messageIntervalTime = '';
          const currentTime = formatDateInterval(moment(new Date(m?.date?.seconds * 1000)));
          if (currentTime !== messageIntervalTimeRef.current) {
            messageIntervalTimeRef.current = currentTime;
            messageIntervalTime = currentTime;
          }
          return (
            <Message
              messageIntervalTime={messageIntervalTime}
              dateRef={dateRef}
              message={m}
              key={m.id}
              isLast={i === messages.length - 1}
            />
          );
        })}
    </div>
  );
};

export default Messages;
