/* eslint-disable */
import {
  Box,
  Modal,
  List,
  IconButton,
  ListItem,
  Avatar,
  ListItemText,
  ListItemAvatar,
  Pagination,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  collection,
  deleteDoc,
  doc,
  where,
  getDocs,
  query,
  getCountFromServer,
  startAt,
  startAfter,
  orderBy,
  limit,
} from 'firebase/firestore';

import { db } from '../../firebase';
import DisplayAvatar from '../Chat/DisplayAvatar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  height: '80%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  '@media (max-width: 640px)': {
    width: '90vw',
  },
};

const LIMIT = 20;

const NewOnlineUserModel = (props) => {
  const { openEditModal, setOpenEditModal } = props;
  const [rawUserList, setRawUserList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageOrderBy, setPageOrderBy] = useState('asc'); // asc or desc
  const [isUserOnline, setIsUserOnline] = useState('Online');

  const handleClose = () => {
    setOpenEditModal(false);
    setUserList([]);
    setRawUserList([]);
    setIsUserOnline('Online');
    setCurrentPage(1);
    setTotalPage(1);
  };

  const fetchUsers = async (status) => {
    const usersRef = collection(db, 'users');
    const list = [];
    const q = query(usersRef, where('isOnline', '==', status === 'Online'), orderBy('displayName', pageOrderBy));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => list.push(doc.data()));
    setRawUserList(list);
    setTotalPage(Math.ceil(list.length / LIMIT));
    const newList = list.slice(0, LIMIT);
    setUserList(newList);

    // FIXME Find a fix for this issue. add an index in all users object.
    // if (newPage === 1) {
    //   const q = query(usersRef, where('isOnline', '==', true), orderBy('displayName', 'desc'), limit(LIMIT));
    //   const querySnapshot = await getDocs(q);
    //   querySnapshot.forEach((doc) => list.push(doc.data()));
    // } else {
    //   // const lastVisible = userList[userList.length-1];
    //   const q = query(
    //     usersRef,
    //     where('isOnline', '==', true),
    //     orderBy('displayName', pageOrderBy),
    //     startAfter(lastVisible),
    //     limit(LIMIT)
    //   );
    //   const querySnapshot = await getDocs(q);
    //   querySnapshot.forEach((doc) => list.push(doc.data()));
    // }
    // setUserList(list);
    // console.log('NewOnlineUserModel', list);
  };

  useEffect(() => {
    if (!openEditModal) return;
    fetchUsers('Online');
  }, [openEditModal]);

  const onChangeCurrentPage = async (_, newPage) => {
    setCurrentPage(newPage);
    const endPage = newPage;
    const startPage = endPage - 1;
    const startIndex = startPage * LIMIT;
    const endIndex = rawUserList.length > endPage * LIMIT ? endPage * LIMIT : rawUserList.length - 1;
    const newList = rawUserList.slice(startIndex, endIndex);
    setUserList(newList);
  };

  const onChangesetIsUserOnline = async (_, newPage) => {
    setIsUserOnline(newPage);
    await fetchUsers(newPage);
  };

  return (
    <Modal open={openEditModal} onClose={handleClose}>
      <Box sx={style}>
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItem: 'center', padding: '2px 12px' }}>
            <h1>Online Users</h1>
            <IconButton onClick={handleClose} aria-label="Close" size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
          <div style={{ padding: '4px 12px' }}>
            <ToggleButtonGroup
              size="small"
              color="success"
              value={isUserOnline}
              exclusive
              onChange={onChangesetIsUserOnline}
              aria-label="Status"
            >
              <ToggleButton value="Online">Online</ToggleButton>
              <ToggleButton value="Offline">Offline</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <List sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto' }}>
            {userList.map((e, index) => (
              <ProfileListItem key={index} data={e} />
            ))}
          </List>
          <div style={{ padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
            <Pagination variant="outlined" count={totalPage} page={currentPage} onChange={onChangeCurrentPage} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default NewOnlineUserModel;

const ProfileListItem = ({ data }) => {
  // const lastOnline = data.lastSeen.toDate();

  return (
    <ListItem
    // secondaryAction={
    //   <IconButton edge="end" aria-label="delete">
    //     <CloseIcon />
    //   </IconButton>
    // }
    >
      <ListItemAvatar>
        <Avatar>
          <DisplayAvatar uid={data.uid} isSupport={false} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={data.displayName} />
      {/* <ListItemText primary={data.displayName} secondary={'Last Online : ' + lastOnline} /> */}
    </ListItem>
  );
};
