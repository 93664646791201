/* eslint-disable no-cond-assign */
import { useEffect, useRef, useState } from 'react';
import { Mention } from 'react-mentions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomMentionsInput from './UI/CustomMentionsInput';
import { getSafalBuddy } from '../services/Service';
import { UserMentionsModal } from './Modals/UserMentionsModal';
import { UserNewProfileModal } from './Modals/UserNewProfileModal';

const RoomMentionsInput = ({ disabled, value, placeholder, onChange, users, companies, companiesMention }) => {
  const [usersMentions, setUsersMentions] = useState(users || []);
  const login = useSelector((state) => state.login);

  const fetchBuddy = async () => {
    try {
      const res = await getSafalBuddy({ status: 'accept' });
      const uniqueUser = [];
      res.data.data?.forEach((user) => {
        uniqueUser.push(
          {
            id: user?.sender?._id,
            display: `${user?.sender?.firstName} ${user?.sender?.lastName}`,
          },
          {
            id: user?.receiver?._id,
            display: `${user?.receiver?.firstName} ${user?.receiver?.lastName}`,
          }
        );
      });
      const uniqueU = [];
      uniqueUser.forEach((user) => {
        if (!uniqueU.find((uu) => uu?.id === user?.id)) uniqueU.push(user);
      });
      users.forEach((user) => {
        if (!uniqueU.find((uu) => uu?.id === user?.id)) uniqueU.push(user);
      });
      const uniqueUFilter = uniqueU.filter((user) => user?.id !== login?.user?._id);
      setUsersMentions(uniqueUFilter);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBuddy();
  }, []);

  return (
    <CustomMentionsInput
      // maxLength="500"
      disabled={disabled}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    >
      <Mention
        markup="@[__display__](user:__id__)"
        displayTransform={(id, name) => `@${name}`}
        trigger="@"
        data={usersMentions}
        className="custom_mentions__mention_user"
      />
      {companiesMention && (
        <Mention
          markup="@[__display__](company:__id__)"
          trigger="$"
          displayTransform={(id, name) => `$${name}`}
          data={companies}
          className="custom_mentions__mention_room"
          renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
            <div
              className={`user ${focused ? 'focused' : ''}`}
              style={{ display: 'flex', flexDirection: 'column', lineHeight: 'normal' }}
            >
              {highlightedDisplay}
              <span style={{ fontSize: '12px' }}>{suggestion?.type}</span>
            </div>
          )}
        />
      )}
    </CustomMentionsInput>
  );
};

export default RoomMentionsInput;

export const RoomMentionsDisplay = ({ message, companyId, roomId }) => {
  const [openUserMentionModal, setOpenUserMentionModal] = useState({
    open: false,
    userId: null,
    companyId: null,
  });
  const mentionContainerRef = useRef(null); // Ref to the container element
  const navigate = useNavigate();

  const infoArray = extractInfo(message);

  let formattedMessage = message;

  // Replace mentions with bold names and custom class
  infoArray.forEach(({ name, type, id }) => {
    const mentionRegex = new RegExp(`@\\[${name}\\]\\(${type}:${id}\\)`, 'g');
    const prefix = type === 'user' ? '@' : '$';
    const className =
      type === 'user' ? 'custom_mentions__mention_user_display' : 'custom_mentions__mention_room_display';

    // Replace mention with formatted span containing the custom class
    // formattedMessage = formattedMessage.replace(mentionRegex, `<strong class="${className}">${prefix}${name}</strong>`);
    formattedMessage = formattedMessage.replace(
      mentionRegex,
      `<strong class="${className}" data-id="${id}" data-type="${type}">${prefix}${name}</strong>`
    );
  });

  const navigateTo = (id) => {
    navigate(`/safalroom?company=${id}`, { replace: true });
    window.location.reload();
  };

  // Use effect to handle clicks on strong tags
  useEffect(() => {
    const mentionContainer = mentionContainerRef.current;
    const handleClick = (event) => {
      const target = event.target;
      if (target.tagName === 'STRONG' && target.dataset.id && target.dataset.type) {
        const id = target.dataset.id;
        const type = target.dataset.type;
        if (type === 'user') {
          setOpenUserMentionModal({ open: true, userId: id, companyId: companyId });
        } else {
          navigateTo(id);
        }
        // You can handle the click event, e.g., redirect, show modal, etc.
      }
    };

    // Add event listener to the container once
    if (mentionContainer) {
      mentionContainer.addEventListener('click', handleClick);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (mentionContainer) {
        mentionContainer.removeEventListener('click', handleClick);
      }
    };
  }, []);

  const handleClose = () => {
    setOpenUserMentionModal({ open: false, userId: null, companyId: null });
  };

  return (
    <>
      <span ref={mentionContainerRef} dangerouslySetInnerHTML={{ __html: formattedMessage }} />
      <UserNewProfileModal
        open={openUserMentionModal.open}
        userId={openUserMentionModal.userId}
        onClose={handleClose}
        companyId={openUserMentionModal.companyId}
        roomId={roomId}
        mode={roomId ? 'PrivateRoom' : 'PublicRoom'}
      />
      {/* <UserMentionsModal
        open={openUserMentionModal.open}
        onClose={handleClose}
        userId={openUserMentionModal.userId}
        companyId={openUserMentionModal.companyId}
      /> */}
    </>
  );
};

function extractInfo(text) {
  const regex = /@\[([^\]]+)\]\((user|company):([a-zA-Z0-9]+)\)/g;
  const matches = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    matches.push({
      name: match[1], // Extracts the name
      id: match[3], // Extracts the ID
      type: match[2], // Extracts the type (user or company)
    });
  }

  return matches;
}
