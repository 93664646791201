import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import toast from 'react-hot-toast';

import Iconify from '../../../components/Iconify';
import virtualCardicon from '../../../assets/virtualCardicon1.png';
import VirtualCardModal from './VirtualCardModal';
import { getAssetModuleAccountSingle } from '../../../services/Service';

const TopTitleAction = ({ onEdit, onDelete, onChangeLog, data, isChangeLogVisible }) => {
  const [virtualCardModalOpen, setVirtualCardModalOpen] = useState({
    open: false,
    data: null,
  });

  const onVirtualCardOpen = async () => {
    try {
      const res = await getAssetModuleAccountSingle(data._id);
      setVirtualCardModalOpen({
        open: true,
        data: res.data.data,
      });
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.message === "Cannot read property 'size' of undefined") {
        toast.error('The file type is unsupported');
      } else {
        toast.error(err?.response?.data?.message || err.message);
      }
    }
  };

  const onVirtualCardClose = async () => {
    setVirtualCardModalOpen({
      open: false,
      data: null,
    });
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '24px',
        }}
      >
        {isChangeLogVisible && (
          <Tooltip title={'Changelog'}>
            <IconButton onClick={onChangeLog}>
              <Iconify icon="mdi:clipboard-text-search" color="#1877F2" width={22} height={22} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={'Virtual Card'}>
          <IconButton onClick={onVirtualCardOpen}>
            <img src={virtualCardicon} width={22} height={22} style={{ objectFit: 'fill' }} alt="virtualCardicon" />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Edit'}>
          <IconButton onClick={onEdit}>
            <Iconify icon="ic:twotone-mode-edit-outline" color="#1877F2" width={22} height={22} />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Delete'}>
          <IconButton onClick={onDelete}>
            <Iconify icon="ic:twotone-delete" color="#DF3E30" width={22} height={22} />
          </IconButton>
        </Tooltip>
      </div>
      <VirtualCardModal
        onClose={onVirtualCardClose}
        open={virtualCardModalOpen.open}
        data={virtualCardModalOpen.data}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    </>
  );
};
export default TopTitleAction;
