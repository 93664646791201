import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, IconButton, ListItemIcon, Typography } from '@mui/material';

export default function ThreeDotMenu({ onAccept, onReject, onOpen, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAcceptClick = () => {
    handleClose();
    onAccept(data);
  };
  const onRejectClick = () => {
    handleClose();
    onReject(data);
  };
  const onOpenClick = () => {
    handleClose();
    onOpen(data);
  };

  return (
    <>
      <div>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {onOpen && (
            <MenuItem onClick={onOpenClick}>
              <Typography variant="inherit">Info</Typography>
            </MenuItem>
          )}
          {onAccept && (
            <MenuItem onClick={onAcceptClick}>
              <Typography variant="inherit">Accept</Typography>
            </MenuItem>
          )}
          {onReject && (
            <MenuItem onClick={onRejectClick}>
              <Typography variant="inherit">Reject</Typography>
            </MenuItem>
          )}
        </Menu>
      </div>
    </>
  );
}
