import { Box, Button, FormControl, Grid, Stack, Switch, TextField, Typography } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveNoOfSubs } from '../../services/Service';
import SuccessToast from '../../components/Toast/Success';
import ErrorToast from '../../components/Toast/Error';
import { setMemory } from '../../store/businessSlice';
import SwitchForForms from './SwitchForForms';
import CheckBoxForUnlimited from './CheckBoxForUnlimited';

const NumberOfSubscriptions = () => {
  const { Memory } = useSelector((state) => state.business);

  const dispatch = useDispatch();

  useEffect(() => {
    setInitialValues({
      freeUserSubs: Memory.freeUserSubs,
      paidUserSubs: Memory.paidUserSubs,
      premiumUserSubs: Memory.premiumUserSubs,
      betaUserSubs: Memory.betaUserSubs,
    });
  }, [Memory]);

  const [initialValues, setInitialValues] = useState({});

  const handleChange = (name, value) => {
    setInitialValues({
      ...initialValues,
      [name]: value,
    });
  };

  // const initialValues = {
  //   freeUserSubs: Memory.freeUserSubs,
  //   paidUserSubs: Memory.paidUserSubs,
  //   premiumUserSubs: Memory.premiumUserSubs,
  //   betaUserSubs: Memory.betaUserSubs,
  // };
  const NoOfSubscriptionForm = useFormik({
    enableReinitialize: true,
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      console.log('this is NoOfSubscriptionForm values => ', values);
      SaveNoOfSubs(values)
        .then((res) => {
          SuccessToast('details updated !!');
          dispatch(setMemory(res.data.data));
        })
        .catch((e) => {
          ErrorToast('Something went wrong !!');
        });
    },
  });

  return (
    <Box sx={{ mt: 3, ml: 3 }}>
      <FormikProvider value={NoOfSubscriptionForm} validateOnMount>
        <form onSubmit={NoOfSubscriptionForm.handleSubmit}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography>Number of subscrioption user can add : </Typography>
          </Grid>
          <Stack direction="row" spacing={2}>
            <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
              <Stack direction="row" spacing={2}>
                <Grid item xs={4} sm={2} md={2}>
                  <Typography>Free User</Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={2} sx={{ mt: 2 }}>
                  <FormControl
                    // fullWidth

                    error={
                      NoOfSubscriptionForm.touched.freeUserSubs && Boolean(NoOfSubscriptionForm.errors.freeUserSubs)
                    }
                  >
                    <Field
                      as={TextField}
                      name="freeUserSubs"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={NoOfSubscriptionForm.values.freeUserSubs}
                      onChange={NoOfSubscriptionForm.handleChange}
                      error={
                        NoOfSubscriptionForm.touched.freeUserSubs && Boolean(NoOfSubscriptionForm.errors.freeUserSubs)
                      }
                      helperText={NoOfSubscriptionForm.touched.freeUserSubs && NoOfSubscriptionForm.errors.freeUserSubs}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={2} md={2} sx={{ mt: 2 }}>
                  {/* <SwitchForForms
                    name="freeUserSubs"
                    checked={NoOfSubscriptionForm.values.freeUserSubs}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="freeUserSubs"
                    checked={NoOfSubscriptionForm.values.freeUserSubs}
                    onChange={handleChange}
                  />
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={6} sx={{ mt: 6 }}>
              <Stack direction="row" spacing={2}>
                <Grid item xs={4} sm={2} md={2}>
                  <Typography>Paid User</Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={2} sx={{ mt: 2 }}>
                  <FormControl
                    // fullWidth

                    error={
                      NoOfSubscriptionForm.touched.paidUserSubs && Boolean(NoOfSubscriptionForm.errors.paidUserSubs)
                    }
                  >
                    <Field
                      as={TextField}
                      name="paidUserSubs"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={NoOfSubscriptionForm.values.paidUserSubs}
                      onChange={NoOfSubscriptionForm.handleChange}
                      error={
                        NoOfSubscriptionForm.touched.paidUserSubs && Boolean(NoOfSubscriptionForm.errors.paidUserSubs)
                      }
                      helperText={NoOfSubscriptionForm.touched.paidUserSubs && NoOfSubscriptionForm.errors.paidUserSubs}
                    />
                  </FormControl>
                </Grid>{' '}
                <Grid item xs={4} sm={2} md={2} sx={{ mt: 2 }}>
                  {/* <SwitchForForms
                    name="paidUserSubs"
                    checked={NoOfSubscriptionForm.values.paidUserSubs}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="paidUserSubs"
                    checked={NoOfSubscriptionForm.values.paidUserSubs}
                    onChange={handleChange}
                  />
                </Grid>
              </Stack>
            </Grid>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
              <Stack direction="row" spacing={2}>
                <Grid item xs={4} sm={2} md={2}>
                  <Typography>Premium User</Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={2} sx={{ mt: 2 }}>
                  <FormControl
                    // fullWidth

                    error={
                      NoOfSubscriptionForm.touched.premiumUserSubs &&
                      Boolean(NoOfSubscriptionForm.errors.premiumUserSubs)
                    }
                  >
                    <Field
                      as={TextField}
                      name="premiumUserSubs"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={NoOfSubscriptionForm.values.premiumUserSubs}
                      onChange={NoOfSubscriptionForm.handleChange}
                      error={
                        NoOfSubscriptionForm.touched.premiumUserSubs &&
                        Boolean(NoOfSubscriptionForm.errors.premiumUserSubs)
                      }
                      helperText={
                        NoOfSubscriptionForm.touched.premiumUserSubs && NoOfSubscriptionForm.errors.premiumUserSubs
                      }
                    />
                  </FormControl>
                </Grid>{' '}
                <Grid item xs={4} sm={2} md={2} sx={{ mt: 2 }}>
                  {/* <SwitchForForms
                    name="premiumUserSubs"
                    checked={NoOfSubscriptionForm.values.premiumUserSubs}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="premiumUserSubs"
                    checked={NoOfSubscriptionForm.values.premiumUserSubs}
                    onChange={handleChange}
                  />
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
              <Stack direction="row" spacing={2}>
                <Grid item xs={4} sm={2} md={2}>
                  <Typography>Beta User</Typography>
                </Grid>
                <Grid item xs={4} sm={2} md={2} sx={{ mt: 2 }}>
                  <FormControl
                    // fullWidth

                    error={
                      NoOfSubscriptionForm.touched.betaUserSubs && Boolean(NoOfSubscriptionForm.errors.betaUserSubs)
                    }
                  >
                    <Field
                      as={TextField}
                      name="betaUserSubs"
                      type="number"
                      // label="Free User"
                      variant="standard"
                      placeholder="Please Enter Value......"
                      size="small"
                      value={NoOfSubscriptionForm.values.betaUserSubs}
                      onChange={NoOfSubscriptionForm.handleChange}
                      error={
                        NoOfSubscriptionForm.touched.betaUserSubs && Boolean(NoOfSubscriptionForm.errors.betaUserSubs)
                      }
                      helperText={NoOfSubscriptionForm.touched.betaUserSubs && NoOfSubscriptionForm.errors.betaUserSubs}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={2} md={2} sx={{ mt: 2 }}>
                  {/* <SwitchForForms
                    name="betaUserSubs"
                    checked={NoOfSubscriptionForm.values.betaUserSubs}
                    onChange={handleChange}
                  /> */}
                  <CheckBoxForUnlimited
                    name="betaUserSubs"
                    checked={NoOfSubscriptionForm.values.betaUserSubs}
                    onChange={handleChange}
                  />
                </Grid>
              </Stack>
            </Grid>
          </Stack>

          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              // type="submit"
              onClick={NoOfSubscriptionForm.handleSubmit}
              // disabled={!(NoOfSubscriptionForm.isValid && NoOfSubscriptionForm.dirty)}
              sx={{
                width: '170px',
                height: '45px',
                bgcolor: '#19C25F',
                borderRadius: '30px',
                textTransform: 'none',
                mt: '25px',
                boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
              }}
            >
              Save
            </Button>
          </Grid>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default NumberOfSubscriptions;
