import { useEffect, useState } from 'react';

import { Box, Fab, Modal, TableContainer, TableRow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import useSortHook from '../../hooks/useSortHook';
import { CustomTableCellForBody, CustomTableView, tableCellPStyle } from '../../components/UI/CustomTableView';

const PaymentModeReportView = ({ data, type, open, onClose, currencySymbol }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '1px solid #FFF',
    borderRadius: '10px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    p: 4,
    maxHeight: '95vh',
    overflow: 'auto',
    '@media (max-width: 640px)': {
      width: '95vw',
    },
  };

  const { handleSort, newSortData, sortState } = useSortHook(data);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal onClose={handleClose} open={open}>
        <Box sx={{ ...style }}>
          <Fab
            sx={{ position: 'absolute', top: '16px', right: '16px' }}
            onClick={handleClose}
            size="small"
            color="primary"
            aria-label="add"
          >
            <CloseIcon />
          </Fab>
          {/* <Typography
            variant="h4"
            gutterBottom
            sx={{ marginBottom: '16px', fontSize: '28px', fontWeight: 700, color: '#3d71ff' }}
          >
            Frequently asked questions
          </Typography> */}
          <div data-color-mode="light">
            {type === 'sub' ? (
              <TableContainer>
                <CustomTableView
                  isSort
                  sort={{
                    order: sortState.order,
                    orderBy: sortState.orderBy,
                    createSortHandler: handleSort,
                    sortHeadMap: ['subscriptionName', 'frequency', 'amount'],
                  }}
                  headData={['Subscription Name', 'Frequency', 'Amount']}
                >
                  {newSortData?.map((e, index) => (
                    <TableRow key={index}>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>{e.subscriptionName}</p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>{e.frequency}</p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>
                          {currencySymbol} {new Intl.NumberFormat().format(e.amount)}
                        </p>
                      </CustomTableCellForBody>
                    </TableRow>
                  ))}
                </CustomTableView>
              </TableContainer>
            ) : (
              <TableContainer>
                <CustomTableView
                  isSort
                  sort={{
                    order: sortState.order,
                    orderBy: sortState.orderBy,
                    createSortHandler: handleSort,
                    sortHeadMap: ['cardId.name', '', 'points'],
                  }}
                  headData={['Card Name', 'Card Number', 'Value']}
                >
                  {newSortData?.map((e, index) => (
                    <TableRow key={index}>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>{e?.cardId?.name}</p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>{e.cardNumber}</p>
                      </CustomTableCellForBody>
                      <CustomTableCellForBody align="center">
                        <p style={tableCellPStyle}>
                          {currencySymbol} {e.points}
                        </p>
                      </CustomTableCellForBody>
                    </TableRow>
                  ))}
                </CustomTableView>
              </TableContainer>
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentModeReportView;
