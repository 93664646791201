import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Button } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
//
import DashboardNavbar from './DashboardNavbar';
import Footer from './Footer';
import AnimationLoop from '../../components/animation/AnimationLoop';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 25;
const APP_BAR_DESKTOP = 45;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  // overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  backgroundColor: '#F2F2F2',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    paddingLeft: '8px',
    paddingRight: '8px',
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  return (
    <>
      <RootStyle>
        <AnimationLoop />
        <DashboardNavbar />
        <MainStyle id="MainStyle-DashboardLayout">
          <Outlet />
        </MainStyle>
      </RootStyle>
      <Footer />
    </>
  );
}
