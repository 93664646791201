import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  SubscriptionData: [],
  TotalSubscriptions: 0,

  startDate: '',
  nextBillingFrom: '',
  nextBillingTo: '',
  subscriptionName: '',
  amount: '',
  frequency: '',
  status: '',
  isLiked: '',
  rating: '',
  subType: '',
};

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscriptions: (state, action) => {
      state.SubscriptionData = action.payload.subscriptions;
      state.TotalSubscriptions = action.payload.totalItems;
      return state;
    },

    deleteSubscription: (state, action) => {
      const newState = state.SubscriptionData?.filter((el) => action.payload.indexOf(el._id) < 0);
      state.SubscriptionData = newState;
      return state;
    },

    subscriptionFilteredDataPayload: (state, action) => {
      state.startDate = action.payload.subscriptionFilter.startDate;
      state.nextBillingFrom = action.payload.subscriptionFilter.nextBillingFrom;
      state.nextBillingTo = action.payload.subscriptionFilter.nextBillingTo;
      state.subscriptionName = action.payload.subscriptionFilter.subscriptionName;
      state.amount = action.payload.subscriptionFilter.amount;
      state.frequency = action.payload.subscriptionFilter.frequency;
      state.status = action.payload.subscriptionFilter.status;
      state.isLiked = action.payload.subscriptionFilter.isLiked;
      state.rating = action.payload.subscriptionFilter.rating;
      state.subType = action.payload.subscriptionFilter.subType;
    },
    resetSubscriptions: () => {
      return initialState;
    },
  },
});

export const { setSubscriptions, deleteSubscription, subscriptionFilteredDataPayload, resetSubscriptions } =
  subscriptionSlice.actions;

export default subscriptionSlice.reducer;
