import { useSelector } from 'react-redux';
import { Button } from '@mui/material';

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import useUserProfileImage from '../../../hooks/useUserProfileImage';

const SafalGiftCart = ({ userCart, updateCart, disabled, yourCurrentPoints, onOrderProcess }) => {
  const userImage = useUserProfileImage();
  const { user } = useSelector((state) => state.login);

  const productQtyChange = async (id, newQty) => {
    const newPayload = userCart?.cart?.map((e) => ({
      product: e?.product?._id,
      qty: e?.product?._id === id ? newQty : e?.qty,
    }));

    const newCart = newPayload.filter((e) => e?.qty > 0);

    await updateCart({
      cart: newCart,
    });
  };

  const userPoints = yourCurrentPoints?.points || 0;

  return (
    <div
      style={{
        width: '400px',
        minWidth: '200px',
        border: '1px solid #C6C0C0',
        borderRadius: '28px',
        padding: '20px',
        minHeight: '300px',
        margin: '16px 0px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <img
          style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '99999px', flex: 'none' }}
          src={userImage}
          alt={user?.firstName}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ color: '#001E6F', fontWeight: 'bold', fontSize: '16px' }}>
            {user?.firstName} {user?.lastName}
          </span>
          <span style={{ fontSize: '14px' }}>
            <b style={{ fontFamily: 'Keania One', color: '#3D71FF', fontSize: '20px' }}>{userPoints}</b> SafalPoints
          </span>
        </div>
      </div>
      <div style={{ backgroundColor: '#0932A138', height: '2px', borderRadius: '8px' }} />
      <span style={{ fontSize: '20px', fontWeight: 'lighter' }}>My Cart</span>
      <div style={{ backgroundColor: '#0932A138', height: '2px', borderRadius: '8px' }} />
      {userCart?.cart?.length > 0 ? (
        <div
          style={{
            minHeight: '200px',
            maxHeight: '400px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            padding: '12px 0px',
          }}
        >
          {userCart?.cart?.map((e) => (
            <CartItem disabled={disabled} data={e} productQtyChange={productQtyChange} />
          ))}
        </div>
      ) : (
        <div
          style={{
            minHeight: '200px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '12px 0px',
          }}
        >
          <span>Cart is empty</span>
        </div>
      )}
      <div style={{ backgroundColor: '#0932A138', height: '2px', borderRadius: '8px' }} />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>Total</span>
        <span>{userCart?.totalPoints} Spts</span>
      </div>
      <Button
        disabled={userCart?.cart.length === 0 ? true : userPoints < userCart?.totalPoints ? true : disabled}
        fullWidth
        sx={{ backgroundColor: '#001e6e', borderRadius: '8px', textTransform: 'capitalize' }}
        onClick={onOrderProcess}
        variant="contained"
      >
        Submit
      </Button>
    </div>
  );
};
export default SafalGiftCart;

const CartItem = ({ data, productQtyChange, disabled }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: 'none' }}>
      <img
        style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '8px', flex: 'none' }}
        src={data?.product?.image}
        alt={data?.product?.name}
      />
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span>{data?.product?.name}</span>
          <span style={{ fontSize: '14px' }}>{data?.product?.points} Spts</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', border: '1px solid #0932A1', borderRadius: '4px' }}>
            <button
              disabled={disabled}
              onClick={() => productQtyChange(data?.product?._id, data?.qty - 1)}
              style={{ border: 'none', padding: '0px', margin: '0px', height: '20px' }}
            >
              <RemoveIcon sx={{ height: '20px', width: '20px', color: '#0932A1' }} />
            </button>
            <input
              disabled={disabled}
              type="number"
              value={data?.qty}
              onChange={(e) => {
                const newValue = parseInt(e.target.value, 10);
                if (newValue >= 0) {
                  productQtyChange(data?.product?._id, newValue);
                }
              }}
              style={{
                height: '20px',
                border: 'none',
                width: '50px',
                borderLeft: '1px solid #0932A1',
                borderRight: '1px solid #0932A1',
                paddingTop: '2px',
                textAlign: 'center',
                backgroundColor: 'transparent',
              }}
            />
            <button
              disabled={disabled}
              onClick={() => productQtyChange(data?.product?._id, data?.qty + 1)}
              style={{ border: 'none', padding: '0px', margin: '0px', height: '20px' }}
            >
              <AddIcon sx={{ height: '20px', width: '20px', color: '#0932A1' }} />
            </button>
          </div>
          <span>{data?.product?.points * data?.qty} Spts</span>
        </div>
      </div>
    </div>
  );
};
