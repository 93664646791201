/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { Avatar, Box, Fab, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import queryString from 'query-string';
import toast from 'react-hot-toast';
import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import SafalLeaderBoard_Call from '../../assets/SafalLeaderBoard_Call.png';
import SafalLeaderBoard_Chat from '../../assets/SafalLeaderBoard_Chat.png';
import SafalLeaderBoard_Video from '../../assets/SafalLeaderBoard_Video.png';
import useProfileImage from '../../hooks/useProfileImage';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
import { firebasePushNotification, getSafalRoomUserInfo } from '../../services/Service';
import { db } from '../../firebase';
import UserChatModal from './UserChatModal';
import { Loader2 } from '../Loader';
import UserNewProfileMoreModal from './UserNewProfileMoreModal';
import { UserNewProfileButtons } from './UserNewProfileButtons';
import { getOrdinalSuffix } from '../../utils/general';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '1100px',
  // minWidth: '600px',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '24px',
  paddingTop: '16px',
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

// mode = PrivateRoom, PublicRoom
export const UserNewProfileModal = ({ open, onClose, userId, companyId, mode, roomId }) => {
  const handleClose = () => {
    onClose();
  };

  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const { formatDate } = useUserTimeZone();
  const [userInfo, setUserInfo] = useState(null);
  const [buddyInfo, setBuddyInfo] = useState(null);
  const [userToggleSetting, setUserToggleSetting] = useState(null);
  const [userInfoMeta, setUserInfoMeta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userChatModal, setUserChatModal] = useState({
    open: false,
    otherUserId: '',
    type: 'chat', // chat. call
    user: null,
  });
  const [mutualSafalBuddiesOthersModal, setMutualSafalBuddiesOthersModal] = useState({
    open: false,
    data: [],
  });

  const isCurrentUser = login?.user?._id === userId;

  const profileImage = useProfileImage(userInfo?.profilePic);

  const [openUserNewProfileModal, setOpenUserNewProfileModal] = useState({
    open: false,
    data: null,
  });
  const openUserNewProfileOpen = (userId) => {
    setOpenUserNewProfileModal({
      open: true,
      data: userId,
    });
  };
  const openUserNewProfileClose = () => {
    setOpenUserNewProfileModal({
      open: false,
      data: null,
    });
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getSafalRoomUserInfo({ companyId, id: userId, roomId });
      if (res.status === 200) {
        setUserInfo(res.data.data);
        setBuddyInfo(res.data.buddy);
        setUserInfoMeta(res.data.meta);
        setUserToggleSetting(res.data?.toggleSetting);
      }
    } catch (err) {
      console.log('OffersList fetchData error', err.message);
    } finally {
      setLoading(false);
    }
  };
  // handle call/chat

  const [otherUserInfo, setOtherUserInfo] = useState(null);
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const currentUserToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  const isChatCallEnable = isCurrentUser
    ? false
    : currentUserToUserChatEnable
    ? otherUserInfo?.userToUserChat === true
    : false;
  const fetchOtherUserFirebase = async (otherUserId) => {
    if (!otherUserId) return;
    const otherUserInfoRef = await getDoc(doc(db, 'users', otherUserId));
    if (otherUserInfoRef.exists()) {
      setOtherUserInfo(otherUserInfoRef.data());
    }
  };

  const onCallClick = async () => {
    onCallClickMain(userId, otherUserInfo, 'audio');
  };
  const onVideoClick = async () => {
    onCallClickMain(userId, otherUserInfo, 'video');
  };
  const onChatClick = async () => {
    onChatClickMain(userId, otherUserInfo);
  };
  const onCallClickMain = async (otherUserId, otherUserInfo, type) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    onConnectCall(otherUserInfo, type);
  };

  const onConnectCall = async (user, callModeX) => {
    const currentUserId = currentFirebaseUser.uid;
    const otherUserId = user?.uid;
    // user to user calls
    const otherUserDocDataRef = await getDoc(doc(db, 'users', otherUserId));
    const otherUserDocData = otherUserDocDataRef.data();
    const callId = uuidv4();
    const callLogRef = doc(db, 'callLogs', callId);
    const currentUserCallDocRef = doc(db, 'userCalls', currentUserId);
    const otherUserCallDocRef = doc(db, 'userCalls', otherUserId);
    try {
      const getDocData = await getDoc(otherUserCallDocRef);
      if (getDocData.exists) {
        const data = getDocData.data();
        if (data.isCall) {
          toast('User is busy in another call');
          return;
        }
      }
    } catch (err) {
      console.log(err);
    }
    const callMode = callModeX ?? 'audio';

    const callObj = {
      callId: callId,
      isCall: true,
      callMode: callMode, // audio/video,
      type: 'user',
      status: 'pending', // "pending", "accept", "reject", "end"
      currentUserId: currentUserId, // undefined or string
      otherUserId: otherUserId, // undefined or string
      flag: 'user_to_user', // user_to_user or support_to_user or user_to_support
      startAt: serverTimestamp(),
    };

    await setDoc(callLogRef, callObj);
    await setDoc(currentUserCallDocRef, callObj);
    await setDoc(otherUserCallDocRef, callObj);

    const notificationPayload = {
      data: {
        notification: { image: '', title: 'Calling', body: `${currentFirebaseUser?.displayName} calling you` },
        data: {
          type: 'call',
          messageId: '',
          otheruid: '',
          image_url: otherUserDocData?.photoURL,
          chatID: '',
          displayName: otherUserDocData?.displayName,
          userType: 'USER',
          isAudio: '1',
          OtherUid: currentUserId,
          channelId: callId,
        },
        apns: { payload: { aps: { sound: 'afro_ringtone.mp3', badge: 1 } } },
      },
      userId: otherUserDocData?.userID,
    };
    await firebasePushNotification(notificationPayload);
  };

  const handleUserChatClose = () => {
    localStorage.removeItem('SafalBuddyChat');
    localStorage.removeItem('SafalBuddyChatNew');
    setUserChatModal({
      open: false,
      otherUserId: '',
      type: 'chat', // chat. call
      user: null,
    });
  };

  const onChatClickMain = async (otherUserId, otherUserInfo) => {
    if (!registered) {
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    setUserChatModal({
      open: true,
      otherUserId: otherUserId,
      type: 'chat', // chat. call
      otherUserInfo: otherUserInfo,
    });
  };

  useEffect(() => {
    if (open) {
      fetchData();
      fetchOtherUserFirebase(userId);
    }
  }, [open, companyId, userId, roomId]);

  const fullName = `${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`;
  const country = userInfo?.country;
  const email = userInfo?.email;
  const phoneNo = userInfo?.phoneNo;
  const phoneNoCode = userInfo?.country === 'United States' ? '+1' : userInfo?.country === 'India' ? '+91' : '';
  const lastLoggedInAt = userInfo?.lastLoggedInAt;

  const tierName =
    userInfo?.tier === '0'
      ? 'Beta'
      : userInfo?.tier === '1'
      ? 'Free'
      : userInfo?.tier === '2'
      ? 'Basic'
      : userInfo?.tier === '3'
      ? 'Premium'
      : null;

  const mutualSafalBuddiesList = userInfoMeta?.mutualSafalBuddies ? userInfoMeta?.mutualSafalBuddies : [];

  const mutualSafalBuddiesList_six = mutualSafalBuddiesList.slice(0, 6);
  const mutualSafalBuddiesList_others = mutualSafalBuddiesList.slice(6);

  const mutualSafalBuddiesOthersOpen = (data) => {
    setMutualSafalBuddiesOthersModal({
      open: true,
      data: data,
    });
  };
  const mutualSafalBuddiesOthersClose = () => {
    setMutualSafalBuddiesOthersModal({
      open: false,
      data: [],
    });
  };
  const mutualSafalBuddiesOthersClick = (userId) => {
    openUserNewProfileOpen(userId);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Fab
            sx={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              backgroundColor: '#3D71FF',
              color: 'white',
            }}
            onClick={handleClose}
            size="small"
            color="primary"
          >
            <CloseIcon />
          </Fab>
          <Typography
            variant="h4"
            sx={{ fontSize: '24px', fontWeight: 700, textAlign: 'center', color: '#3D71FF', marginBottom: '12px' }}
          >
            Safal User
          </Typography>
          {loading && <Loader2 style={{ minHeight: '400px' }} />}
          {!loading && (
            <div style={{ display: 'flex', gap: '20px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  padding: '8px 16px',
                  width: '350px',
                }}
              >
                <div style={{ position: 'relative' }}>
                  <Avatar sx={{ height: '90px', width: '90px' }} alt={fullName} src={profileImage} />
                  <div style={{ display: 'flex', position: 'absolute', right: '-56px', bottom: '8px', gap: '4px' }}>
                    {country === 'United States' && (
                      <img
                        style={{ height: '20px', objectFit: 'contain' }}
                        src="/static/icons/ic_flag_en.svg"
                        alt="United-States"
                      />
                    )}
                    {country === 'India' && (
                      <img
                        style={{ height: '20px', objectFit: 'contain' }}
                        src="/static/icons/Flag_of_India.svg"
                        alt="India"
                      />
                    )}
                    {userToggleSetting?.tier && (
                      <div
                        style={{
                          height: '20px',
                          padding: '0 4px',
                          backgroundColor: '#dfecf7',
                          borderRadius: '4px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {tierName}
                      </div>
                    )}
                  </div>
                </div>
                <p
                  style={{
                    fontWeight: 'bold',
                    fontSize: '20px',
                    color: '#0932A1',
                    textAlign: 'center',
                  }}
                >
                  {fullName}
                </p>
                {/* Audio & Video Call and Chat  */}
                <div style={{ display: 'flex', gap: '8px' }}>
                  <button
                    onClick={onChatClick}
                    disabled={!isChatCallEnable}
                    style={{
                      border: 'none',
                      cursor: 'pointer',
                      backgroundColor: !isChatCallEnable ? '#787878' : '#4f79ea',
                      padding: '4px 8px',
                      borderRadius: '6px',
                    }}
                  >
                    <img
                      src={SafalLeaderBoard_Chat}
                      style={{
                        width: '20px',
                        height: '20px',
                        objectFit: 'contain',
                        filter: 'brightness(10)',
                      }}
                      alt="SafalLeaderBoard_Chat"
                    />
                  </button>
                  <button
                    onClick={onCallClick}
                    disabled={!isChatCallEnable}
                    style={{
                      border: 'none',
                      cursor: 'pointer',
                      backgroundColor: !isChatCallEnable ? '#787878' : '#4f79ea',
                      padding: '4px 8px',
                      borderRadius: '6px',
                    }}
                  >
                    <img
                      src={SafalLeaderBoard_Call}
                      style={{
                        width: '20px',
                        height: '20px',
                        objectFit: 'contain',
                        filter: 'brightness(10)',
                      }}
                      alt="SafalLeaderBoard_Call"
                    />
                  </button>
                  <button
                    onClick={onVideoClick}
                    disabled={!isChatCallEnable}
                    style={{
                      border: 'none',
                      cursor: 'pointer',
                      backgroundColor: !isChatCallEnable ? '#787878' : '#4f79ea',
                      padding: '4px 8px',
                      borderRadius: '6px',
                    }}
                  >
                    <img
                      src={SafalLeaderBoard_Video}
                      style={{
                        width: '20px',
                        height: '20px',
                        objectFit: 'contain',
                        filter: 'brightness(10)',
                      }}
                      alt="SafalLeaderBoard_Video"
                    />
                  </button>
                </div>
                {userToggleSetting?.email && (
                  <div
                    style={{
                      backgroundColor: '#4f79ea',
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                      padding: '4px 6px',
                      borderRadius: '4px',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{ height: '16px', objectFit: 'contain' }}
                      src="/assets/images/email_profile.png"
                      alt="Email"
                    />
                    <span style={{ color: 'white', fontSize: '14px' }}>{email}</span>
                  </div>
                )}
                {userToggleSetting?.phoneNumber && (
                  <div
                    style={{
                      backgroundColor: '#4f79ea',
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                      padding: '4px 6px',
                      borderRadius: '4px',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{ height: '16px', objectFit: 'contain' }}
                      src="/assets/images/phoneNumber_profile.png"
                      alt="Email"
                    />
                    <span style={{ color: 'white', fontSize: '14px' }}>
                      {phoneNoCode} {phoneNo}
                    </span>
                  </div>
                )}
                {userToggleSetting?.safalBuddiesCount && (
                  <div
                    style={{
                      backgroundColor: '#4f79ea',
                      display: 'flex',
                      gap: '8px',
                      alignItems: 'center',
                      padding: '4px 6px',
                      borderRadius: '4px',
                      width: '100%',
                    }}
                  >
                    <img
                      style={{ height: '16px', objectFit: 'contain' }}
                      src="/assets/images/buddy_white_icon.png"
                      alt="SafalBuddy"
                    />
                    <span style={{ color: 'white', fontSize: '14px' }}>
                      {userInfoMeta?.safalBuddiesCount} SafalBuddies
                    </span>
                  </div>
                )}
                {userToggleSetting?.lastLoginDateTime && (
                  <p style={{ fontSize: '12px', color: '#4F79EA' }}>
                    Last Login:{' '}
                    {moment(lastLoggedInAt).format(
                      country === 'India' ? 'Do MMMM YYYY, h:mm a' : 'MMMM Do YYYY, h:mm a'
                    )}{' '}
                    {getTimeZoneAbbreviation()}
                  </p>
                )}
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                <div
                  style={{
                    backgroundColor: '#f8f7f7',
                    padding: '32px',
                    paddingTop: '12px',
                    filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
                  }}
                >
                  <span style={{ fontSize: '16px', color: '#4F79EA', fontWeight: 'bold' }}>User Activity</span>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '32px',
                      justifyContent: 'center',
                      marginTop: '32px',
                    }}
                  >
                    {userToggleSetting?.activeSubscriptionsCount && (
                      <ActivityCard
                        value={userInfoMeta?.activeSubscriptionsCount}
                        title="Subscription"
                        icon="/assets/images/buddyActivity/subscriptions_blue.png"
                      />
                    )}
                    {userToggleSetting?.activeLoyaltyCount && (
                      <ActivityCard
                        value={userInfoMeta?.activeLoyaltyCount}
                        title="Loyalty Card"
                        icon="/assets/images/buddyActivity/loyalty.png"
                      />
                    )}
                    {userToggleSetting?.activeGiftCount && (
                      <ActivityCard
                        value={userInfoMeta?.activeGiftCount}
                        title="Gift Card"
                        icon="/assets/images/buddyActivity/gift.png"
                      />
                    )}
                    {userToggleSetting?.activeRewardsCount && (
                      <ActivityCard
                        value={userInfoMeta?.activeRewardsCount}
                        title="Reward Certificate"
                        icon="/assets/images/buddyActivity/reward.png"
                      />
                    )}
                    {mode !== 'PublicRoom' &&
                      mode !== 'PrivateRoom' &&
                      userToggleSetting?.currentMonthLeaderboardPosition && (
                        <ActivityCard
                          value={userInfoMeta?.currentMonthLeaderboardPosition}
                          title={`Leaderboard (${moment().format('MMMM')})`}
                          subTitle="Position"
                          icon="/assets/images/buddyActivity/leader_blue.png"
                        />
                      )}
                    {mode === 'PublicRoom' && userToggleSetting?.roomFeedsCount && (
                      <>
                        <ActivityCard
                          value={userInfoMeta?.countPublic}
                          title="Rooms Feeds"
                          icon="/assets/images/buddyActivity/room.png"
                        />
                        <ActivityCard
                          value={userInfoMeta?.countPublicForum}
                          title="Forums Feeds"
                          icon="/assets/images/buddyActivity/room.png"
                        />
                      </>
                    )}
                    {mode === 'PrivateRoom' && userToggleSetting?.roomFeedsCount && (
                      <>
                        <ActivityCard
                          value={userInfoMeta?.countPrivate}
                          title="Rooms Feeds"
                          icon="/assets/images/buddyActivity/room.png"
                        />
                        <ActivityCard
                          value={userInfoMeta?.countPrivateForum}
                          title="Forums Feeds"
                          icon="/assets/images/buddyActivity/room.png"
                        />
                      </>
                    )}
                  </div>
                </div>
                {!isCurrentUser &&
                  userToggleSetting?.mutualSafalBuddiesCount &&
                  userInfoMeta?.mutualSafalBuddies?.length > 0 && (
                    <div
                      style={{
                        backgroundColor: '#f8f7f7',
                        padding: '16px',
                        paddingTop: '12px',
                        filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
                      }}
                    >
                      <span style={{ fontSize: '16px', color: '#4F79EA', fontWeight: 'bold' }}>
                        Mutual SafalBuddies
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '8px',
                          marginTop: '16px',
                          justifyContent: mutualSafalBuddiesList_others.length > 0 ? 'space-evenly' : 'start',
                        }}
                      >
                        {mutualSafalBuddiesList_six?.map((e, index) => (
                          <UserProfileBox onClick={openUserNewProfileOpen} data={e} key={index} />
                        ))}
                        {mutualSafalBuddiesList_others.length > 0 && (
                          <UserMoreProfileBox
                            count={23}
                            onClick={() => mutualSafalBuddiesOthersOpen(mutualSafalBuddiesList_others)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                {/* UserNewProfileButtons */}
                {!isCurrentUser && (
                  <div style={{ display: 'flex', justifyContent: 'end', gap: '16px' }}>
                    <UserNewProfileButtons buddy={buddyInfo} otherUserId={userInfo?._id} refetch={() => fetchData()} />
                  </div>
                )}
              </div>
            </div>
          )}
        </Box>
      </Modal>
      <UserChatModal
        open={userChatModal.open}
        buddyUser={userChatModal.otherUserInfo}
        handleClose={() => handleUserChatClose()}
      />
      <UserNewProfileMoreModal
        open={mutualSafalBuddiesOthersModal.open}
        data={mutualSafalBuddiesOthersModal.data}
        onClose={mutualSafalBuddiesOthersClose}
        onClick={mutualSafalBuddiesOthersClick}
      />
      {userToggleSetting?.mutualSafalBuddiesCount && userInfoMeta?.mutualSafalBuddies?.length > 0 && (
        <UserNewProfileModal
          open={openUserNewProfileModal.open}
          userId={openUserNewProfileModal.data}
          onClose={openUserNewProfileClose}
        />
      )}
    </>
  );
};

const ActivityCard = ({ title, subTitle, icon, value }) => {
  const _subTitle = subTitle ?? 'Active';
  return (
    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        style={{
          backgroundColor: 'white',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          width: '120px',
          height: '100px',
          justifyContent: 'end',
          alignItems: 'center',
          borderRadius: '16px',
          filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
        }}
      >
        <span style={{ fontSize: '16px', color: '#4F79EA', fontWeight: 'bold' }}>{value}</span>
        <span style={{ fontSize: '14px', color: '#4F79EA' }}>{_subTitle}</span>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px',
          borderRadius: '999px',
          backgroundColor: '#f8f7f7',
          width: '64px',
          height: '64px',
          position: 'absolute',
          top: '-32px',
          left: 'calc(50% - 32px)',
        }}
      >
        <img style={{ height: '28px', width: '28px', objectFit: 'contain' }} src={icon} alt={title} />
      </div>
      <p style={{ fontSize: '14px', color: '#4F79EA', fontWeight: 'bold', textAlign: 'center', marginTop: '4px' }}>
        {title}
      </p>
    </div>
  );
};

const UserProfileBox = ({ data, onClick }) => {
  const fullName = `${data?.firstName || ''} ${data?.lastName || ''}`;
  const profileImage = useProfileImage(data?.profilePic);
  return (
    <div
      onClick={() => onClick(data?._id)}
      style={{ width: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
    >
      <Avatar sx={{ height: '40px', width: '40px' }} alt={fullName} src={profileImage} />
      <span style={{ textAlign: 'center', fontSize: '14px', color: '#4F79EA' }}>{fullName}</span>
    </div>
  );
};
const UserMoreProfileBox = ({ count, onClick }) => {
  return (
    <div onClick={onClick} style={{ width: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div
        style={{
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          fontSize: '14px',
          color: '#4F79EA',
          filter: 'drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06))',
          cursor: 'pointer',
        }}
      >
        {count}
      </div>
      <span style={{ textAlign: 'center', fontSize: '14px', color: '#4F79EA' }}>More</span>
    </div>
  );
};
