import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';
import ThreeDotMenu from '../../../components/UI/ThreeDotMenu';
import useSortHook from '../../../hooks/useSortHook';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete, onDisplay }) => {
  const { formatDateTime } = useUserTimeZone();
  const { handleSort, newSortData, sortState } = useSortHook(data, ['createdAt']);

  const headData = [
    'Name',
    'Card Scheme Label',
    'Card Scheme Status',
    'Nick Name Label',
    'Nick Name Status',
    'Instrument Label',
    'Instrument Status',
    'Status',
    'Created At',
    'Actions',
  ];

  return (
    <TableContainer>
      <CustomTableView
        isSort
        sort={{
          order: sortState.order,
          orderBy: sortState.orderBy,
          createSortHandler: handleSort,
          sortHeadMap: [
            'name',
            'cardSchemeField.label',
            'cardSchemeField.status',
            'nickNameField.label',
            'nickNameField.status',
            'instrumentField.label',
            'instrumentField.status',
            'status',
            'createdAt',
            '',
          ],
        }}
        headData={headData}
      >
        {newSortData.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
        {newSortData?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.name}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.cardSchemeField?.label}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.cardSchemeField?.status === true ? 'Active' : 'Inactive'}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.nickNameField?.label?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.nickNameField?.status === true ? 'Active' : 'Inactive'}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.instrumentField?.label?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.instrumentField?.status === true ? 'Active' : 'Inactive'}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.status === true ? 'Active' : 'Inactive'}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <ThreeDotMenu onDelete={() => onDelete(e)} onDisplay={() => onDisplay(e)} onEdit={() => onEdit(e)} />
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
