import * as React from 'react';
import { useState } from 'react';
import { Image } from 'antd';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { InputAdornment, MenuItem, TextField } from '@mui/material';
import toast from 'react-hot-toast';

import { addOfferSocialMedia, editOfferSocialMedia, uploadOfferSocialMedia } from '../../../services/Service';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  displey: 'flex',
  flexDirection: 'column',
  gap: '12px',
  p: 4,
  maxHeight: '95vh',
  overflow: 'auto',
  '@media (max-width: 640px)': {
    width: '95vw',
  },
};

const initFieldValue = {
  name: '',
  type: '',
  logo: '',
  isWeb: true,
  isMobile: true,
  webRedirectLink: '',
  mobileRedirectLink: '',
  status: true,
};

export default function AddOfferModalSocial({ open, onClose, data, isDisplay, companyList }) {
  const [fieldValue, setFieldValue] = React.useState(initFieldValue);
  const [isDisableAll, setIsDisableAll] = React.useState(false);
  const [fileValue, setFileValue] = React.useState(null);

  const handleClose = () => {
    setFieldValue(initFieldValue);
    setIsDisableAll(false);
    setFileValue(null);
    onClose();
  };

  const onSubmit = async () => {
    setIsDisableAll(true);
    try {
      if (!fileValue) {
        toast.error('Please select a image');
        return;
      }
      const res_image = await uploadOfferSocialMedia(fileValue);

      const res = await addOfferSocialMedia({ ...fieldValue, logo: res_image.data.url });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  const onSubmitUpdate = async () => {
    setIsDisableAll(true);
    try {
      let imgUrl = data?.logo;
      if (fileValue) {
        const res_image = await uploadOfferSocialMedia(fileValue);
        imgUrl = res_image.data.url;
      }
      const res = await editOfferSocialMedia({ _id: data._id, ...fieldValue, logo: imgUrl });
      if (res.status === 200) {
        toast.success(res.data.message);
        handleClose();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setIsDisableAll(false);
    }
  };

  React.useEffect(() => {
    if (open === true && data !== null) {
      setFieldValue({
        name: data?.name,
        type: data?.type,
        logo: data?.logo,
        isWeb: data?.isWeb,
        isMobile: data?.isMobile,
        webRedirectLink: data?.webRedirectLink,
        mobileRedirectLink: data?.mobileRedirectLink,
        status: data?.status,
      });
      setIsDisableAll(isDisplay);
    }
  }, [open, data, isDisplay]);

  const logoImageUrl = fileValue
    ? URL.createObjectURL(fileValue)
    : fieldValue?.logo?.length > 1
    ? fieldValue?.logo
    : '';

  return (
    <Modal open={open}>
      <Box sx={style}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ marginBottom: '16px', fontSize: '30px', fontWeight: 700, color: '#19c25f' }}
        >
          {data === null ? 'Add Offers Social Media' : isDisplay ? 'Offers Social Media' : 'Update Offers Social Media'}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {/* Image File */}
          <div style={{ display: 'flex', alignItems: 'end', gap: '16px' }}>
            {logoImageUrl !== '' && (
              <Image
                preview
                src={logoImageUrl}
                style={{ maxHeight: '60px', objectFit: 'cover', borderRadius: '8px' }}
                alt="logo"
              />
            )}
            <TextField
              onChange={(e) => {
                setFileValue(e.target.files[0]);
              }}
              type="file"
              required
              size="small"
              fullWidth
              variant="outlined"
              InputProps={{
                accept: 'image/*',
              }}
            />
          </div>
          {/* name */}
          <TextField
            value={fieldValue.name}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, name: e.target.value }))}
            required
            label="Name"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* type */}
          <TextField
            value={fieldValue.type}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, type: e.target.value }))}
            required
            label="Type (Can't be change after)"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={data !== null ? true : isDisableAll}
          />
          {/* webRedirectLink */}
          <TextField
            value={fieldValue.webRedirectLink}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, webRedirectLink: e.target.value }))}
            required
            label="Web Redirect Link"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            helperText="[[BODY]] replace with body, [[LINK]] replace with share link"
            disabled={isDisableAll}
          />
          {/* mobileRedirectLink */}
          <TextField
            value={fieldValue.mobileRedirectLink}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, mobileRedirectLink: e.target.value }))}
            required
            label="Mobile Redirect Link"
            size="small"
            type="text"
            fullWidth
            variant="outlined"
            disabled={isDisableAll}
          />
          {/* isWeb */}
          <TextField
            value={fieldValue.isWeb === true ? 'Yes' : 'No'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, isWeb: e.target.value === 'Yes' }))}
            required
            select
            label="Enable for Web"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
          {/* isMobile */}
          <TextField
            value={fieldValue.isMobile === true ? 'Yes' : 'No'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, isMobile: e.target.value === 'Yes' }))}
            required
            select
            label="Enable for Mobile"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </TextField>
          {/* status */}
          <TextField
            value={fieldValue.status === true ? 'Active' : 'Inactive'}
            onChange={(e) => setFieldValue((prev) => ({ ...prev, status: e.target.value === 'Active' }))}
            required
            select
            label="Status"
            size="small"
            disabled={isDisableAll}
            fullWidth
            variant="outlined"
            sx={{ flex: '1' }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Inactive">Inactive</MenuItem>
          </TextField>
        </div>
        <div style={{ display: 'flex', gap: '12px', marginTop: '16px', justifyContent: 'end' }}>
          <Button
            disabled={isDisplay ? false : isDisableAll}
            onClick={handleClose}
            sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
            variant="contained"
          >
            Cancel
          </Button>
          {!isDisplay && (
            <Button
              disabled={isDisableAll}
              onClick={data === null ? onSubmit : onSubmitUpdate}
              sx={{ backgroundColor: '#19c25f', textTransform: 'capitalize' }}
              variant="contained"
            >
              {data === null ? 'Create' : 'Update'}
            </Button>
          )}
        </div>
      </Box>
    </Modal>
  );
}
