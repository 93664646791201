import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCallLimit } from '../services/Service';

const initialState = {
  BusinessData: [],
  CustomCopanyData : [],
  Memory : [],
  callLimit: {
    0: {
      audio: 0,
      video: 0
    },
    1: {
      audio: 0,
      video: 0
    },
    2: {
      audio: 0,
      video: 0
    },
    3: {
      audio: 0,
      video: 0
    }
   }
};
export const fetchCallLimit = createAsyncThunk('/storage/call', async (params, { rejectWithValue }) => {
  try {
    const response = await getCallLimit();
    console.log('getRESP', response)
    if (!response.data.success) {
      rejectWithValue(response.data);
    }
    return response?.data;
  } catch (error) {
    console.log(error);
    console.log('getRESP', error)
    return rejectWithValue(error?.response ? error.response : error);
  }
});
export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    setBusiness: (state, action) => {
      state.BusinessData = action.payload.business;
      return state;
    },

    setCustomCompany: (state, action) => {
      state.CustomCopanyData = action.payload.CustomCopanyData;
      return state;
    },

    deleteBusiness: (state, action) => {
      const newState = state.BusinessData.filter((el) => action.payload.indexOf(el._id) < 0);
      state.BusinessData = newState;
      return state;
    },

    setMemory : (state,action) => {
      state.Memory = action.payload;
      return state;
    },
    resetBusiness: () => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchCallLimit.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchCallLimit.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.callLimit = payload.data;
    },
    [fetchCallLimit.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },
  }
});



export const { setBusiness, deleteBusiness , setMemory, setCallLimit , setCustomCompany, resetBusiness } = businessSlice.actions;

export default businessSlice.reducer;
