import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSignedURL } from '../services/Service';
import YourImage from '../assets/UserAvtarBackground.png';

const useProfileImage = (url) => {
  const [userProfileImage, setImage] = useState(YourImage);

  const getProfileImage = async () => {
    const photoURL = url || '';
    if (photoURL?.includes('safalsubscriptions-assets.s3.amazonaws.com')) {
      const newRes = await getSignedURL({ url: photoURL });
      setImage(newRes.data);
      return;
    }
    if (photoURL) {
      setImage(photoURL);
    }
  };

  useEffect(() => {
    getProfileImage();
  }, [url]);

  return userProfileImage;
};

export default useProfileImage;
