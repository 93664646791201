import { Image } from 'antd';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { IconButton, Tooltip } from '@mui/material';

export const CustomImageView = ({ files, onDelete }) => {
  const urls = files?.map((file) => URL.createObjectURL(file)) || [];

  return (
    <div style={{ display: 'flex', gap: '8px', overflow: 'auto', margin: '4px', padding: '4px' }}>
      {urls.map((url, index) => (
        <div
          key={index}
          style={{
            flex: 'none',
            display: 'flex',
            gap: '2px',
            flexDirection: 'column',
            zIndex: 30000,
          }}
        >
          <Image src={url} style={{ height: '40px', width: '40px', objectFit: 'cover', borderRadius: '4px' }} />
          <button
            onClick={() => onDelete(index)}
            style={{
              cursor: 'pointer',
              backgroundColor: '#ff4842',
              color: 'white',
              fontWeight: 'bold',
              border: 'none',
              padding: '2px 0px',
              borderRadius: '4px',
            }}
          >
            X
          </button>
        </div>
      ))}
    </div>
  );
};
export const CustomImageViewInFeed = ({ urls }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        overflow: 'auto',
        margin: '4px',
        padding: '4px',
        paddingTop: '0px',
        marginTop: '0px',
        flexWrap: 'wrap',
      }}
    >
      <Image.PreviewGroup>
        {urls.map((url, index) => (
          <Image key={index} src={url} style={{ height: '80px', objectFit: 'contain', borderRadius: '4px' }} />
        ))}
      </Image.PreviewGroup>
    </div>
  );
};

export const CustomImageViewInput = ({ onChange, disabled }) => {
  return (
    <label htmlFor="CustomImageViewInput-File">
      <input
        disabled={disabled}
        type="file"
        style={{ display: 'none' }}
        multiple
        id="CustomImageViewInput-File"
        accept=".jpg, .jpeg, .png, .gif"
        onChange={(e) => {
          onChange(Array.from(e.target.files));
        }}
      />
      <Tooltip title="Upload Images">
        <IconButton
          disabled={disabled}
          component="span"
          size="small"
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#3D71FF',
            height: '40px',
            width: '40px',
            '&:hover': { backgroundColor: '#3D71FF' },
          }}
        >
          <AddPhotoAlternateIcon sx={{ color: 'white' }} />
        </IconButton>
      </Tooltip>
    </label>
  );
};
