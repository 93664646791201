/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Select,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Stack,
  Typography,
  TextField,
  InputAdornment,
  Input,
  Fab,
  FormLabel,
  Link,
  Autocomplete,
  Paper,
  Popper,
  Checkbox,
  // ClickAwayListener,
} from '@mui/material';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import { ClickAwayListener } from '@mui/base';
import CloseIcon from '@mui/icons-material/Close';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import MomentUtils from '@date-io/moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Field, FormikProvider, useFormik } from 'formik';
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import { Image } from 'antd';

import * as Yup from 'yup';
import { setCompanies } from '../../store/companiesSlice';
import SuccsessModal from '../SuccsessModal';
import { setSubscriptions } from '../../store/subscriptionSlice';
import SuccessToast from '../Toast/Success';
import {
  GetcompaniesResponse,
  GetsubsResponse,
  SavesubsResponse,
  SavesubsResponseForAlready,
  checkExistingSub,
  getCompanyType,
  getSubLabel,
} from '../../services/Service';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';
import MultiOptionModal from './MultiOptionModal';
import { getProfile } from '../../store/loginSlice';
import OtherModal from './OtherModal';
import ErrorToast from '../Toast/Error';
import EditModal, { FileImageView } from './EditModal';
import Inlineloader from '../Loader/InlineLoader';
import MultiOptionModalNew from './MultiOptionModalNew';
import { changeAddSubscriptionAnimation } from '../../store/ToggleSlice';
import CustomRating from '../UI/CustomRating';
import CustomSelect from '../UI/CustomSelect';
import AddCustomLabel from './AddCustomLabel';
import FaqModal from './FaqModal';
import AddPaymentInstrumentModal from './AddPaymentInstrumentModal';
import CustomMuiSelect from '../UI/CustomMuiSelect';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #FFF',
  borderRadius: '10px',
  boxShadow: 24,
  p: '30px',
  // overflowY:"Auto"
};
const MAX_COUNT = 2000000;

const SubscriptionModal = ({
  openModal,
  setOpenSubModal,
  pagination,
  setOpenTrackModal,
  handledelete,
  setSelected,
  setOpenDelete,
  handleActionClose,
  onOpenFeedback,
  paymentCardSchemeList,
  paymentInstrumentList,
  paymentModeList,
  fetchPaymentModeInfo,
}) => {
  const { user } = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [subId, setSubId] = React.useState('');
  const [openOptionModal, setOpenOptionModal] = React.useState(false);
  const [openOtherModal, setOpenOtherModal] = React.useState(false);
  const [openOtherModalCompany, setOpenOtherModalCompany] = React.useState(false);
  const { allCompaniesData } = useSelector((state) => state.companies);
  const [subLabel, setSubLabel] = React.useState([]);
  const [companyTypes, setCompanyTypes] = React.useState([]);
  const [selectedCompanyType, setSelectedCompanyType] = React.useState([]);
  const [masterComapny, setMasterComapny] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [companyOptions, setCompanyOptions] = React.useState(false);
  const [subExist, setSubExist] = React.useState(false);
  const [isExpiryDateEnable, setExpiryDateEnable] = React.useState(false);
  const [openCustomLabel, setOpenCustomLabel] = React.useState({
    open: false,
    data: null,
  });

  // frequency
  // "Monthly", "Annually", "Daily", "Weekly"
  const [frequencyState, setFrequencyState] = useState({
    active: false,
    frequency: '',
  });
  const [selectedPaymentMode, setSelectedPaymentMode] = useState('');
  const [addPaymentInstrumentModalOpen, setAddPaymentInstrumentModalOpen] = useState(false);
  const [selectedPaymentInstrumentList, setSelectedPaymentInstrumentList] = useState([]);

  const [editData, setEditData] = useState('');
  const [openEdit, setOpenEdit] = useState('');

  const [isSaveSubsAlready, setIsSaveSubsAlready] = useState({
    open: false,
    data: null,
  });

  const [initialValues, setInitialValues] = React.useState({
    logo: '',
    website: '',
    subscriptionType: '',
    company: '',
    description: '',
    frequency: '',
    frequencyNumber: 1,
    contractStartDate: '',
    nextBillingDate: '',
    currencyAbr: user?.country === 'United States' ? 'USD' : 'INR',
    amount: null,
    autoRenewal: '',
    status: '',
    comments: '',
    attachment: '',
    ID: '',
    rating: '',
    isLiked: 0,
    label: '',
    expiryDate: '',
    paymentInstrument: '',
  });
  const buttonRef = useRef(null);
  const companyRef = useRef(null);

  const [selectedFileName, setSelectedFileName] = React.useState();
  const handleCompanyClick = (data) => {
    const CompanyType = allCompaniesData.filter((val) => val.companyType?.toLowerCase() === data?.toLowerCase());
    CompanyType.sort((a, b) => a.name.localeCompare(b.name));
    setSelectedCompanyType(CompanyType);
    // CompanyType.sort((a, b) => a.localeCompare(b));
  };

  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [fileLimit, setFileLimit] = React.useState(false);
  const [loading, setLoading] = useState(false);

  // const inputFileRef = useRef(null);

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    // eslint-disable-next-line array-callback-return
    files.map((file) => {
      const extensionIndex = file.name.lastIndexOf('.');
      const extension = file.name.substring(extensionIndex);
      const fileNameWithoutExtension = file.name.substring(0, extensionIndex);

      let counter = 1;
      let newFileName = `${fileNameWithoutExtension}${extension}`;

      // eslint-disable-next-line no-loop-func
      while (uploaded.some((uploadedFile) => uploadedFile.name === newFileName)) {
        newFileName = `${fileNameWithoutExtension} (${counter})${extension}`;
        // eslint-disable-next-line no-plusplus
        counter++;
      }

      const renamedFile = new File([file], newFileName, { type: file.type });

      uploaded.push(renamedFile);

      if (uploaded.length === MAX_COUNT) setFileLimit(true);
      if (uploaded.length > MAX_COUNT) {
        alert(`You can only add a maximum of ${MAX_COUNT} files`);
        setFileLimit(false);
        limitExceeded = true;
      }
    });

    if (!limitExceeded) {
      setUploadedFiles(uploaded);
      SubscriptionForm.setFieldValue('attachment', uploaded);
    }
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenSubModal(false);
    SuccessToast('Subscription is added successfully');
    setIsSaveSubsAlready({
      open: false,
      data: null,
    });
  };

  const fetchSubLabel = async () => {
    try {
      const res = await getSubLabel();
      setSubLabel(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCompanyType = async () => {
    try {
      const res = await getCompanyType();
      const typeData = res.data.data.map((e) => e.type);
      setCompanyTypes(typeData.sort((a, b) => a.localeCompare(b)));
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchSubLabel();
    fetchCompanyType();
    GetsubsResponse(pagination.page, pagination.limit)
      .then((res) => {
        if (res.data.success === true) {
          dispatch(setSubscriptions({ subscriptions: res.data.data, totalItems: res.data.pagination[0].totalItems }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          // navigate('/login');
        }
      });
    GetcompaniesResponse().then((res) => {
      dispatch(setCompanies({ allCompaniesData: res.data.data }));
      // const companiesType = [...new Set(res.data.data.map((item) => item.companyType))];
      // companiesType.sort((a, b) => a.localeCompare(b));
      // setCompanyTypes(companiesType);
    });
  }, [openModal, selectedFileName]);

  const SubscriptionFormSchema = Yup.object().shape({
    subscriptionType: Yup.string()
      .typeError('Please Select Subscription Type')
      .required('Please Select Subscription Type'),
    company: Yup.string().typeError('Please Select Company').required('Please Select Company'),
    description: Yup.string().required('Please Enter Description'),
    frequency: Yup.string().required('Please Select frequency'),
    contractStartDate: Yup.date().typeError('Please enter a valid date').required('Please Select Contract Start Date'),
    amount: Yup.number().min(0).positive().typeError('Please enter a valid Number').required('Please Enter Amount'),
    frequencyNumber: Yup.number()
      .positive()
      .min(1)
      .test('test-1', 'min value 2 is required', (value) => {
        if (value && ['Annually', 'Monthly'].includes(frequencyState.frequency) && value < 2) return false;
        return true;
      })
      .typeError('Please enter a valid duration number')
      .required('Please enter a valid duration number'),
    autoRenewal: Yup.string().required('Please Select Auto Renewal'),
    nextBillingDate: Yup.date()
      .required('Please Select next billing Date')
      .typeError('Please enter a valid date')
      .test('is-past', 'Next billing date can not be a past date', (value) => {
        return !value || moment(value).isSameOrAfter(moment().subtract(1, 'days'));
      })
      .test(
        'is-greater-than-or-equal',
        'Next billing date must be greater than or equal to the start date',
        (value) => {
          return (
            !value ||
            !SubscriptionForm.values.contractStartDate ||
            moment(value).format('yyyy-MM-DD') >= moment(SubscriptionForm.values.contractStartDate).format('yyyy-MM-DD')
          );
        }
      ),
    expiryDate: Yup.date()
      .optional()
      .typeError('Please enter a valid date')
      .test('is-greater-than-or-equal2', 'Expiry date must be greater than or equal to start date', (value) => {
        return (
          !value ||
          !SubscriptionForm.values.contractStartDate ||
          moment(value).format('yyyy-MM-DD') >= moment(SubscriptionForm.values.contractStartDate).format('yyyy-MM-DD')
        );
      }),
    // nextBillingDate: Yup.date(),
    status: Yup.string().required('Please select Status'),
    comments: Yup.string(),
  });

  const setOtherModalValues = (data) => {
    setInitialValues({
      ...initialValues,
      subscriptionType: data.subType,
      company: data.companyName,
      description: data.description,
      logo: data.logo,
      popular: data.popular,
      amount: data.price,
      website: data.website,
      ID: data.id,
    });
  };

  const onAlreadySubSave = async () => {
    try {
      setLoading(true);
      const res = await SavesubsResponseForAlready(isSaveSubsAlready.data);
      // setSubId(res.data.data._id);
      // setOpenOptionModal(true);
      setIsSaveSubsAlready({
        open: false,
        data: null,
      });
      setMasterComapny(null);
      setCompanyTypes(null);
      setUploadedFiles([]);
      setInitialValues({
        logo: '',
        website: '',
        subscriptionType: '',
        company: '',
        description: '',
        frequency: '',
        frequencyNumber: 1,
        contractStartDate: '',
        nextBillingDate: '',
        currencyAbr: user?.country === 'United States' ? 'USD' : 'INR',
        amount: null,
        autoRenewal: '',
        status: '',
        comments: '',
        attachment: [],
        ID: '',
        rating: '',
        isLiked: 0,
        label: '',
        paymentInstrument: '',
        expiryDate: '',
      });
      setLoading(false);
      handleCloseSub(true);
    } catch (err) {
      setLoading(false);
      ErrorToast(err.response.data.message);
    }
  };
  const onAlreadySubCancel = async () => {
    setIsSaveSubsAlready({
      open: false,
      data: null,
    });
  };

  const fetchCompany = () => {
    console.log('fetchCompany');
    GetcompaniesResponse().then((res) => {
      dispatch(setCompanies({ allCompaniesData: res.data.data }));
      const companiesType = [...new Set(res.data.data.map((item) => item.companyType))];
      companiesType.sort((a, b) => a.localeCompare(b));
      setCompanyTypes(companiesType);
    });
  };

  const SubscriptionForm = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: SubscriptionFormSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        values.companyId = !masterComapny ? initialValues.ID : masterComapny._id;
        values.currencyAbr = user?.country === 'United States' ? 'USD' : 'INR';
        if (values.attachment === '') {
          values.attachment = null;
        }
        values.event = {
          call: 'AddSub',
        };
        const newValue = {
          ...values,
          frequency: values.frequency === 'Others' ? frequencyState.frequency : values.frequency,
        };
        const res = await SavesubsResponse(newValue);
        if (res.data.alreadyExists === true) {
          setLoading(false);
          setIsSaveSubsAlready({
            open: true,
            data: newValue,
          });
        } else {
          if (res.data.success === true) {
            setMasterComapny(null);
            setCompanyTypes(null);
            setUploadedFiles([]);
            dispatch(getProfile()).unwrap();
            // setOpen(true); // Disable to show modal on create subscription
            SuccessToast(res.data.message);
            setOpenSubModal(false); // Show only success message
            resetForm(initialValues);
            setInitialValues({
              logo: '',
              website: '',
              subscriptionType: '',
              company: '',
              description: '',
              frequency: '',
              frequencyNumber: 1,
              contractStartDate: '',
              nextBillingDate: '',
              currencyAbr: user?.country === 'United States' ? 'USD' : 'INR',
              amount: null,
              autoRenewal: '',
              status: '',
              comments: '',
              attachment: [],
              ID: '',
              rating: '',
              isLiked: 0,
              label: '',
              expiryDate: '',
              paymentInstrument: '',
            });
            setLoading(false);
            setSelectedPaymentMode('');
            dispatch(changeAddSubscriptionAnimation());
            // InitialFeedbackModal
            if (res.data.count === 1) {
              onOpenFeedback();
            }
          }
        }
      } catch (e) {
        if (e.response.data.alreadyExists === true) {
          setSubId(e.response.data.data._id);
          setOpenOptionModal(true);
          setLoading(false);
          ErrorToast('Already added this subscription.');
        } else {
          setLoading(false);
          ErrorToast(e.response.data.message);
        }
      }
    },
  });

  // pass true if you directly close without any warning
  const handleCloseSub = (flag) => {
    if (flag === true) {
      setSubExist(false);
      setOpenSubModal(false);
      setCompanyOptions(false);
      setAnchorEl(false);
      setSelectedFileName();
      setUploadedFiles([]);
      setInitialValues({
        logo: '',
        website: '',
        subscriptionType: '',
        company: '',
        description: '',
        frequency: '',
        frequencyNumber: 1,
        contractStartDate: '',
        nextBillingDate: '',
        currencyAbr: user?.country === 'United States' ? 'USD' : 'INR',
        amount: null,
        autoRenewal: '',
        status: '',
        comments: '',
        attachment: '',
        ID: '',
        rating: '',
        isLiked: 0,
      });
      SubscriptionForm.resetForm();
      setSelectedPaymentMode('');
      return;
    }
    if (
      SubscriptionForm.values.logo === '' &&
      SubscriptionForm.values.website === '' &&
      SubscriptionForm.values.subscriptionType === '' &&
      SubscriptionForm.values.company === '' &&
      SubscriptionForm.values.description === '' &&
      SubscriptionForm.values.frequency === '' &&
      SubscriptionForm.values.frequencyNumber === 1 &&
      SubscriptionForm.values.contractStartDate === '' &&
      SubscriptionForm.values.expiryDate === '' &&
      SubscriptionForm.values.nextBillingDate === '' &&
      SubscriptionForm.values.currencyAbr === 'USD' &&
      SubscriptionForm.values.amount === null &&
      SubscriptionForm.values.autoRenewal === '' &&
      SubscriptionForm.values.status === '' &&
      SubscriptionForm.values.comments === '' &&
      SubscriptionForm.values.attachment === '' &&
      SubscriptionForm.values.ID === '' &&
      SubscriptionForm.values.rating === '' &&
      SubscriptionForm.values.isLiked === 0
    ) {
      setSubExist(false);
      setOpenSubModal(false);
      setCompanyOptions(false);
      setAnchorEl(false);
      setSelectedFileName();
      setUploadedFiles([]);
      setInitialValues({
        logo: '',
        website: '',
        subscriptionType: '',
        company: '',
        description: '',
        frequency: '',
        frequencyNumber: 1,
        contractStartDate: '',
        nextBillingDate: '',
        currencyAbr: user?.country === 'United States' ? 'USD' : 'INR',
        amount: null,
        autoRenewal: '',
        status: '',
        comments: '',
        attachment: '',
        ID: '',
        rating: '',
        isLiked: 0,
        label: '',
        expiryDate: '',
      });
      SubscriptionForm.resetForm();
      setSelectedPaymentMode('');
    } else {
      Swal.fire({
        text: 'Are you sure you want to close this?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        appendTo: 'body',
        customClass: {
          container: 'my-swal', // Add a custom class for styling
        },
        onBeforeOpen: () => {
          // Set a higher zIndex for the Swal modal
          document.querySelector('.my-swal').style.zIndex = 999999;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setSubExist(false);
          setOpenSubModal(false);
          setCompanyOptions(false);
          setAnchorEl(false);
          setSelectedFileName();
          setUploadedFiles([]);
          setInitialValues({
            logo: '',
            website: '',
            subscriptionType: '',
            company: '',
            description: '',
            frequency: '',
            frequencyNumber: 1,
            contractStartDate: '',
            nextBillingDate: '',
            currencyAbr: user?.country === 'United States' ? 'USD' : 'INR',
            amount: null,
            autoRenewal: '',
            status: '',
            comments: '',
            attachment: '',
            ID: '',
            rating: '',
            label: '',
            expiryDate: '',
            isLiked: 0,
          });
          SubscriptionForm.resetForm();
          setSelectedPaymentMode('');
        }
      });
    }
  };

  const resetFrom = () => {
    setInitialValues({
      logo: '',
      website: '',
      subscriptionType: '',
      company: '',
      description: '',
      frequency: '',
      frequencyNumber: 1,
      contractStartDate: '',
      nextBillingDate: '',
      currencyAbr: user?.country === 'United States' ? 'USD' : 'INR',
      amount: null,
      autoRenewal: '',
      status: '',
      comments: '',
      attachment: '',
      ID: '',
      rating: '',
      expiryDate: '',
      label: '',
      isLiked: 0,
      paymentInstrument: '',
    });
    SubscriptionForm.resetForm();
    setMasterComapny(null);
    setFrequencyState({
      active: false,
      frequency: '',
    });
  };

  const handleOpen = () => {
    setAnchorEl(true);
    // setCompanyOptions(false);
  };
  const handleOpenCompany = () => {
    setCompanyOptions(true);
    // setAnchorEl(false);
  };

  const handleCloseSubAutoComplete = () => {
    setAnchorEl(false);
  };
  const handleCloseCompanyAutoComplete = () => {
    setCompanyOptions(false);
  };
  const getUpdatedFileName = (files) => {
    const newFiles = [];
    files.forEach((file) => {
      const newFile = {};
      if (newFiles.length === 0) {
        newFile.name = file.data.name;
        newFile.id = file.id;
        newFiles.push(newFile);
      } else {
        if (newFiles.filter((f) => f.name.includes(file.data.name)).length >= 1) {
          newFile.name = `${file.data.name.split('.')[0]}(${
            newFiles.filter((f) => f.name.includes(file.data.name)).length
          }).${file.data.name.split('.')[1]}`;
          newFile.id = file.id;
        } else {
          newFile.name = file.data.name;
          newFile.id = file.id;
        }
        newFiles.push(newFile);
      }
    });
    console.log('New File', newFiles);
    return newFiles;
  };

  const checkForExistingSubscription = (type, name) => {
    const val = {
      subType: type,
      subName: name,
    };

    checkExistingSub(val)
      .then((res) => {
        console.log('this is checkForExistingSubscription response ==> ', res.data);
        console.log('this is checkForExistingSubscription response ==> ', SubscriptionForm);
        if (res.data.success === true) {
          setSubExist(true);
          setEditData(res.data.data);
        } else {
          setSubExist(false);
        }
      })
      .catch((err) => {
        console.log('this is checkForExistingSubscription error ==> ', err.response.data);
        setSubExist(false);
        console.log('this is checkForExistingSubscription response ==> ', SubscriptionForm);
      });
  };

  const onExpiryDateEnableChange = (e) => {
    setExpiryDateEnable(e.target.checked);
    if (e.target.checked === false) {
      SubscriptionForm.setFieldValue('expiryDate', '');
      SubscriptionForm.setFieldTouched('expiryDate', false, false);
      setInitialValues((prev) => ({
        ...prev,
        expiryDate: '',
      }));
    }
  };

  const onCloseAddPaymentInstrumentModal = async () => {
    setAddPaymentInstrumentModalOpen(false);
    const newListX = await fetchPaymentModeInfo();
    const newPaymentInstrumentList = newListX?.filter((ex) => ex?.paymentModeId?._id === selectedPaymentMode);
    setSelectedPaymentInstrumentList(newPaymentInstrumentList);
  };

  return (
    <>
      {loading && (
        <>
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 9999,
            }}
          />
          <Inlineloader />
        </>
      )}
      <Modal
        sx={{ zIndex: 345 }}
        disableBackdropClick={false}
        open={openModal}
        onClose={handleCloseSub}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          id="scrollbar"
          sx={{
            ...style,
            height: '90vh',
            paddingBottom: '10rem',
            '@media (max-width: 640px)': {
              height: '100vh',
            },
            width: { xs: '100%', sm: '400px', md: '600px', lg: '800px' },
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Box>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontSize: '24px', fontWeight: 700, color: '#3D71FF', display: 'flex', alignItems: 'center' }}
              >
                Add Subscription <FaqModal marginLeft="4px" color="blue" module="Subscriptions - Create" />
              </Typography>
              <Typography variant="h4" gutterBottom sx={{ fontSize: '15px', fontWeight: 400 }}>
                Enter your details below.
              </Typography>
            </Box>
            <Fab onClick={handleCloseSub} size="small" color="primary" aria-label="add">
              <CloseIcon />
            </Fab>
          </Stack>
          <Scrollbar>
            <Box sx={{ height: '100%', overflow: 'auto' }}>
              <FormikProvider value={SubscriptionForm} validateOnMount>
                <form onSubmit={SubscriptionForm.handleSubmit}>
                  <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ overflow: 'hidden' }}>
                    {SubscriptionForm.values.company && (
                      <Grid item xs={12} sm={6} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Image width={60} height={60} src={SubscriptionForm.values.logo} />
                        </Box>
                        <FormControl fullWidth sx={{ mt: 2, ml: 3 }}>
                          <FormLabel sx={{ fontSize: '14px', color: '#B6B6B6' }}>Website</FormLabel>
                          <Field
                            as={Link}
                            label="Website"
                            name="website"
                            href={
                              SubscriptionForm.values.website?.includes('https://')
                                ? SubscriptionForm.values.website
                                : `https://${SubscriptionForm.values.website}`
                            }
                            target="_blank"
                          >
                            {SubscriptionForm.values.website?.includes('https://')
                              ? SubscriptionForm.values.website
                              : `https://${SubscriptionForm.values.website}`}
                          </Field>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', alignItems: 'center' }} />
                    {/* subscriptionType */}
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ mt: 2, width: '98%' }}
                        error={
                          SubscriptionForm.touched.subscriptionType && Boolean(SubscriptionForm.errors.subscriptionType)
                        }
                      >
                        <Box ref={buttonRef} onClick={() => handleOpen()}>
                          <Field
                            as={Autocomplete}
                            id="subscriptionType"
                            name="subscriptionType"
                            value={SubscriptionForm.values.subscriptionType}
                            disablePortal
                            // eslint-disable-next-line no-unneeded-ternary
                            disableCloseOnSelect={SubscriptionForm.values.subscriptionType ? false : true}
                            open={anchorEl}
                            options={!companyTypes ? [] : companyTypes}
                            getOptionLabel={(option) => option}
                            onOpen={() => setAnchorEl(true)}
                            onClose={() => {
                              setAnchorEl(false);
                            }}
                            getOptionSelected={(option, value) => option._id === value?._id}
                            onChange={(e, value) => {
                              SubscriptionForm.setFieldValue('subscriptionType', value);
                              handleCompanyClick(value);
                              setSubExist(false);
                              SubscriptionForm.setFieldValue('logo', '');
                              SubscriptionForm.setFieldValue('website', '');
                              SubscriptionForm.setFieldValue('company', '');
                              SubscriptionForm.setFieldValue('description', '');
                              SubscriptionForm.setFieldValue('frequency', '');
                              SubscriptionForm.setFieldValue('nextBillingDate', '');
                              SubscriptionForm.setFieldValue(
                                'currencyAbr',
                                user?.country === 'United States' ? 'USD' : 'INR'
                              );
                              SubscriptionForm.setFieldValue('amount', '');
                              SubscriptionForm.setFieldValue('autoRenewal', '');
                              SubscriptionForm.setFieldValue('status', '');
                              SubscriptionForm.setFieldValue('comments', '');
                              SubscriptionForm.setFieldValue('attachment', '');
                              SubscriptionForm.setFieldValue('ID', '');
                              SubscriptionForm.setFieldValue('rating', '');
                              SubscriptionForm.setFieldValue('label', '');
                              SubscriptionForm.setFieldValue('expiryDate', '');
                              SubscriptionForm.setFieldValue('isLiked', 0);
                              SubscriptionForm.setFieldValue('contractStartDate', '');
                            }}
                            ListboxProps={{ style: { maxHeight: 150 } }}
                            // disableListWrap
                            PopperComponent={(props) => (
                              <ClickAwayListener>
                                <Popper {...props} disablePortal>
                                  <Paper sx={{ background: '#fff' }}>
                                    {props.children}
                                    <Button
                                      variant="contained"
                                      size="small"
                                      style={{ margin: '10px', textTransform: 'capitalize', borderRadius: '30px' }}
                                      // disabled={!!SubscriptionForm.values.subscriptionType}
                                      onClick={(e) => {
                                        setOpenOtherModal(true);
                                        setOpenOtherModalCompany(false);
                                        resetFrom();
                                      }}
                                      onTouchEnd={() => {
                                        setOpenOtherModal(false);
                                        setOpenOtherModalCompany(true);
                                        resetFrom();
                                      }}
                                      onMouseDown={(e) => {
                                        setOpenOtherModal(true);
                                        setOpenOtherModalCompany(false);
                                        resetFrom();
                                      }}
                                    >
                                      Add New
                                    </Button>
                                  </Paper>
                                </Popper>
                              </ClickAwayListener>
                            )}
                            renderInput={(params) => (
                              <ClickAwayListener onClickAway={() => setAnchorEl(false)}>
                                <TextField
                                  label={
                                    <Typography
                                      sx={{
                                        color:
                                          SubscriptionForm.touched.subscriptionType &&
                                          SubscriptionForm.errors.subscriptionType
                                            ? '#FF4842'
                                            : '#B6B6B6',
                                      }}
                                    >
                                      Subscription Type<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                  }
                                  error={
                                    SubscriptionForm.touched.subscriptionType &&
                                    SubscriptionForm.errors.subscriptionType
                                  }
                                  {...params}
                                  variant="standard"
                                  size="small"
                                />
                              </ClickAwayListener>
                            )}
                          />
                          <OtherModal
                            fetchCompany={fetchCompany}
                            openOtherModal={openOtherModal}
                            setOpenOtherModal={setOpenOtherModal}
                            setOtherModalValues={setOtherModalValues}
                            setCompanyOptions={setCompanyOptions}
                          />
                        </Box>
                        {SubscriptionForm.touched.subscriptionType && SubscriptionForm.errors.subscriptionType ? (
                          <FormHelperText sx={{ ml: 0 }}>
                            {SubscriptionForm.touched.subscriptionType && SubscriptionForm.errors.subscriptionType}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    {/* company */}
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl
                        // fullWidth
                        sx={{ mt: 2, width: '98%' }}
                        error={SubscriptionForm.touched.company && Boolean(SubscriptionForm.errors.company)}
                      >
                        <Box ref={companyRef} onClick={() => handleOpenCompany()}>
                          <Field
                            as={Autocomplete}
                            id="company"
                            name="company"
                            value={SubscriptionForm.values.company}
                            disablePortal
                            open={companyOptions}
                            // eslint-disable-next-line arrow-body-style
                            options={selectedCompanyType?.map((item) => item)}
                            onOpen={() => setCompanyOptions(true)}
                            onClose={() => {
                              setCompanyOptions(false);
                            }}
                            // onBlur = {() => setCompanyOptions(false)}
                            getOptionLabel={(option) => {
                              if (typeof option === 'object') {
                                return option.name;
                              }
                              return option;
                            }}
                            getOptionSelected={(option, value) => option._id === value?._id}
                            onChange={(e, value) => {
                              SubscriptionForm.setFieldValue('company', value.name);
                              setMasterComapny(value);
                              SubscriptionForm.setFieldValue('description', value.description);
                              SubscriptionForm.setFieldValue('amount', value.amount);
                              SubscriptionForm.setFieldValue('website', value.website);
                              SubscriptionForm.setFieldValue('logo', value.logo);
                              // SubscriptionForm.values.description = value.description || '';
                              // SubscriptionForm.values.amount = value.price || 0;
                              // SubscriptionForm.values.website = value.website || '';
                              // SubscriptionForm.values.logo = value.logo || '';
                              checkForExistingSubscription(SubscriptionForm.values.subscriptionType, value.name);
                            }}
                            ListboxProps={{ style: { maxHeight: 150 } }}
                            disableListWrap
                            PopperComponent={(props) => (
                              <ClickAwayListener
                              // onClickAway={() => setCompanyOptions(false)}
                              >
                                <Popper {...props} disablePortal sx={{ background: '#fff' }}>
                                  {props.children}
                                  <Button
                                    variant="contained"
                                    size="small"
                                    style={{ margin: '10px', textTransform: 'capitalize', borderRadius: '30px' }}
                                    // disabled={!!SubscriptionForm.values.company}
                                    onClick={() => {
                                      console.log('ClickAwayListener onClick');
                                      setOpenOtherModal(false);
                                      setOpenOtherModalCompany(true);
                                      resetFrom();
                                      // setCompanyOptions(false);
                                    }}
                                    onTouchEnd={() => {
                                      setOpenOtherModal(false);
                                      setOpenOtherModalCompany(true);
                                      resetFrom();
                                    }}
                                    onMouseDown={(e) => {
                                      setOpenOtherModal(false);
                                      setOpenOtherModalCompany(true);
                                      resetFrom();
                                    }}
                                  >
                                    Add New
                                  </Button>
                                </Popper>
                              </ClickAwayListener>
                            )}
                            renderInput={(params) => (
                              <ClickAwayListener onClickAway={() => setCompanyOptions(false)}>
                                <TextField
                                  label={
                                    <Typography
                                      sx={{
                                        color:
                                          SubscriptionForm.touched.company && SubscriptionForm.errors.company
                                            ? '#FF4842'
                                            : '#B6B6B6',
                                      }}
                                    >
                                      Company<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                  }
                                  error={SubscriptionForm.touched.company && SubscriptionForm.errors.company}
                                  {...params}
                                  variant="standard"
                                  size="small"
                                />
                              </ClickAwayListener>
                            )}
                          />
                          <OtherModal
                            fetchCompany={fetchCompany}
                            openOtherModal={openOtherModalCompany}
                            setOpenOtherModal={setOpenOtherModalCompany}
                            setCompanyOptions={setCompanyOptions}
                            setOtherModalValues={setOtherModalValues}
                            valueSubsciption={SubscriptionForm?.values?.subscriptionType}
                          />
                        </Box>
                        {SubscriptionForm.touched.company && SubscriptionForm.errors.company ? (
                          <FormHelperText sx={{ ml: 0 }}>
                            {SubscriptionForm.touched.company && SubscriptionForm.errors.company}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    {/* description */}
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl
                        sx={{ width: '99%' }}
                        error={SubscriptionForm.touched.description && Boolean(SubscriptionForm.errors.description)}
                      >
                        <Field
                          as={TextField}
                          id="input1"
                          name="description"
                          multiline
                          rows={3}
                          label={
                            <Typography sx={{ color: '#B6B6B6' }}>
                              Description {/* <span style={{ color: 'red' }}>*</span> */}
                            </Typography>
                          }
                          variant="standard"
                          size="small"
                          value={SubscriptionForm.values.description}
                          onChange={SubscriptionForm.handleChange}
                          error={SubscriptionForm.touched.description && Boolean(SubscriptionForm.errors.description)}
                          helperText={SubscriptionForm.touched.description && SubscriptionForm.errors.description}
                          disabled
                          onClick={() => {
                            !!SubscriptionForm.values.description && setCompanyOptions(false);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {/* frequency */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        // sx={{ mb: 3 }}
                        sx={{ width: '98%' }}
                        error={SubscriptionForm.touched.frequency && Boolean(SubscriptionForm.errors.frequency)}
                      >
                        <InputLabel id="select3" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Frequency<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Field
                          as={Select}
                          labelId="select3"
                          id="select3"
                          name="frequency"
                          label={
                            <Typography sx={{ color: '#B6B6B6' }}>
                              Frequency<span style={{ color: 'red' }}>*</span>
                            </Typography>
                          }
                          variant="standard"
                          size="small"
                          value={SubscriptionForm.values.frequency}
                          onChange={(e) => {
                            if (e.target.value === 'Others') {
                              setFrequencyState((prev) => ({ ...prev, frequency: 'Weekly', active: true }));
                              SubscriptionForm.setFieldValue('frequencyNumber', 1);
                            } else {
                              setFrequencyState((prev) => ({ ...prev, frequency: '', active: false }));
                              SubscriptionForm.setFieldValue('frequencyNumber', 1);
                            }
                            SubscriptionForm.handleChange(e);
                            SubscriptionForm.setErrors({ ...SubscriptionForm.errors, contractStartDate: undefined });
                          }}
                          sx={{
                            '&:after': {
                              borderBottomColor: '#0000',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          <MenuItem value={'Monthly'}>Monthly</MenuItem>
                          <MenuItem value={'Annually'}>Annually</MenuItem>
                          <MenuItem value={'Others'}>Others</MenuItem>
                        </Field>
                        {SubscriptionForm.touched.frequency && SubscriptionForm.errors.frequency ? (
                          <FormHelperText sx={{ ml: 0 }}>
                            {SubscriptionForm.touched.frequency && SubscriptionForm.errors.frequency}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    {/* Others frequency */}
                    {SubscriptionForm.values.frequency === 'Others' && (
                      <>
                        {/* Duration - Others frequency */}
                        <Grid item xs={12} sm={12} md={6}>
                          <FormControl
                            sx={{ width: '98%' }}
                            error={
                              SubscriptionForm.touched.frequencyNumber &&
                              Boolean(SubscriptionForm.errors.frequencyNumber)
                            }
                          >
                            <InputLabel htmlFor="frequencyNumber" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                              Duration<span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <div style={{ display: 'flex', gap: '8px', marginTop: '12px' }}>
                              <Field
                                as={Input}
                                name="frequencyNumber"
                                type="number"
                                value={SubscriptionForm.values.frequencyNumber}
                                onChange={(e) => {
                                  if (e.target.value.length < 3) {
                                    SubscriptionForm.handleChange(e);
                                  }
                                }}
                              />
                              <Field
                                as={Select}
                                labelId="select4"
                                id="select4"
                                name="Othersfrequency"
                                variant="standard"
                                size="small"
                                value={frequencyState.frequency}
                                onChange={(e) => {
                                  setFrequencyState((prev) => ({ ...prev, frequency: e.target.value }));
                                }}
                                sx={{
                                  width: '50%',
                                  '&:after': {
                                    borderBottomColor: '#0000',
                                  },
                                  '& .MuiSvgIcon-root': {
                                    color: '#0071E3',
                                  },
                                }}
                              >
                                <MenuItem value={'Weekly'}>Week</MenuItem>
                                <MenuItem value={'Daily'}>Day</MenuItem>
                                <MenuItem value={'Monthly'}>Month</MenuItem>
                                <MenuItem value={'Annually'}>Year</MenuItem>
                              </Field>
                            </div>
                            {SubscriptionForm.touched.frequencyNumber && SubscriptionForm.errors.frequencyNumber ? (
                              <FormHelperText sx={{ ml: 0 }}>
                                {SubscriptionForm.touched.frequencyNumber && SubscriptionForm.errors.frequencyNumber}
                              </FormHelperText>
                            ) : null}
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    {/* Amount */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        // sx={{ mb: 3 }}
                        sx={{ width: '98%' }}
                        error={SubscriptionForm.touched.amount && Boolean(SubscriptionForm.errors.amount)}
                      >
                        <InputLabel htmlFor="amount" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Amount<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Field
                          as={Input}
                          label={
                            <Typography sx={{ color: '#B6B6B6' }}>
                              Amount<span style={{ color: 'red' }}>*</span>
                            </Typography>
                          }
                          name="amount"
                          type="number"
                          value={SubscriptionForm.values.amount}
                          onChange={(e) => {
                            if (e.target.value.length < 9) {
                              SubscriptionForm.handleChange(e);
                            }
                          }}
                          startAdornment={
                            <InputAdornment position="start">
                              {user?.country === 'United States' ? '$' : '₹'}
                            </InputAdornment>
                          }
                        />
                        {SubscriptionForm.touched.amount && SubscriptionForm.errors.amount ? (
                          <FormHelperText sx={{ ml: 0 }}>
                            {SubscriptionForm.touched.amount && SubscriptionForm.errors.amount}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    {/* Contract Start Date */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl sx={{ width: '98%' }}>
                        <LocalizationProvider dateAdapter={MomentUtils}>
                          <Field
                            disabled={SubscriptionForm.values.frequency === ''}
                            as={DesktopDatePicker}
                            label={
                              <Typography sx={{ color: '#B6B6B6' }}>
                                Contract Start Date<span style={{ color: 'red' }}>*</span>
                              </Typography>
                            }
                            inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                            onChange={(e) => {
                              // SubscriptionForm.setFieldTouched('contractStartDate', true, false);
                              SubscriptionForm.setFieldValue('contractStartDate', moment(e).format('yyyy-MM-DD'));
                              switch (SubscriptionForm.values.frequency) {
                                case 'Monthly':
                                  SubscriptionForm.setFieldValue(
                                    'nextBillingDate',
                                    moment(e).add(1, 'months').format('yyyy-MM-DD')
                                  );
                                  break;
                                case 'Annually':
                                  SubscriptionForm.setFieldValue(
                                    'nextBillingDate',
                                    moment(e).add(1, 'years').format('yyyy-MM-DD')
                                  );
                                  break;
                                case 'Daily':
                                  SubscriptionForm.setFieldValue(
                                    'nextBillingDate',
                                    moment(e).add(1, 'days').format('yyyy-MM-DD')
                                  );
                                  break;
                                case 'Weekly':
                                  SubscriptionForm.setFieldValue(
                                    'nextBillingDate',
                                    moment(e).add(1, 'weeks').format('yyyy-MM-DD')
                                  );
                                  break;

                                default:
                                  SubscriptionForm.setFieldValue('nextBillingDate', '');
                                  break;
                              }
                            }}
                            value={SubscriptionForm.values.contractStartDate}
                            renderInput={(params) => (
                              <Field
                                as={TextField}
                                name="contractStartDate"
                                variant="standard"
                                size="small"
                                onBlur={() => {
                                  SubscriptionForm.setFieldTouched('contractStartDate', true, false);
                                }}
                                {...params}
                                error={
                                  SubscriptionForm.touched.contractStartDate &&
                                  Boolean(SubscriptionForm.errors.contractStartDate)
                                }
                                helperText={
                                  SubscriptionForm.touched.contractStartDate &&
                                  SubscriptionForm.errors.contractStartDate
                                }
                                sx={{
                                  svg: { color: '#0071E3', mr: '15px' },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    {/* Next Billing Date */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        //  fullWidth
                        sx={{ width: '98%' }}
                      >
                        <LocalizationProvider dateAdapter={MomentUtils}>
                          <Field
                            as={DesktopDatePicker}
                            // minDate={new Date()}
                            label={
                              <Typography sx={{ color: '#B6B6B6' }}>
                                Next Billing Date<span style={{ color: 'red' }}>*</span>
                              </Typography>
                            }
                            inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                            variant="standard"
                            size="small"
                            onChange={(e) => {
                              SubscriptionForm.setFieldValue('nextBillingDate', moment(e).format('yyyy-MM-DD'));
                              SubscriptionForm.setFieldTouched('nextBillingDate', true, false);
                            }}
                            disablePast
                            minDate={SubscriptionForm.values.contractStartDate}
                            value={SubscriptionForm.values.nextBillingDate}
                            renderInput={(params) => (
                              <Field
                                as={TextField}
                                // name="nextBillingDate"
                                variant="standard"
                                size="small"
                                onBlur={() => {
                                  SubscriptionForm.setFieldTouched('nextBillingDate', true, false);
                                }}
                                {...params}
                                error={
                                  SubscriptionForm.touched.nextBillingDate &&
                                  Boolean(SubscriptionForm.errors.nextBillingDate)
                                }
                                helperText={
                                  SubscriptionForm.touched.nextBillingDate && SubscriptionForm.errors.nextBillingDate
                                }
                                sx={{
                                  ':hover': {
                                    backgroundColor: 'white',
                                  },
                                  svg: {
                                    color: '#0071E3',
                                    mr: '15px',
                                  },
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                    {/* expiryDate */}
                    <Grid item xs={12} sm={12} md={6}>
                      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <Checkbox
                          value={isExpiryDateEnable}
                          onChange={onExpiryDateEnableChange}
                          sx={{ padding: '8px 8px 4px 8px' }}
                        />
                        <FormControl
                          //  fullWidth
                          sx={{ width: '98%' }}
                        >
                          <LocalizationProvider dateAdapter={MomentUtils}>
                            <Field
                              disabled={!isExpiryDateEnable}
                              as={DesktopDatePicker}
                              // minDate={new Date()}
                              label={<Typography sx={{ color: '#B6B6B6' }}>Expiry Date</Typography>}
                              inputFormat={user.country === 'India' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                SubscriptionForm.setFieldValue('expiryDate', moment(e).format('yyyy-MM-DD'));
                                SubscriptionForm.setFieldTouched('expiryDate', true, false);
                              }}
                              value={SubscriptionForm.values.expiryDate}
                              minDate={SubscriptionForm.values.contractStartDate}
                              renderInput={(params) => (
                                <Field
                                  as={TextField}
                                  // name="nextBillingDate"
                                  variant="standard"
                                  size="small"
                                  onBlur={() => {
                                    SubscriptionForm.setFieldTouched('expiryDate', true, false);
                                  }}
                                  {...params}
                                  error={
                                    SubscriptionForm.touched.expiryDate && Boolean(SubscriptionForm.errors.expiryDate)
                                  }
                                  helperText={SubscriptionForm.touched.expiryDate && SubscriptionForm.errors.expiryDate}
                                  sx={{
                                    ':hover': {
                                      backgroundColor: 'white',
                                    },
                                    svg: {
                                      color: '#0071E3',
                                      mr: '15px',
                                    },
                                  }}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </div>
                    </Grid>
                    {/* Auto Renewal */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        // sx={{ mb: 3 }}
                        sx={{ width: '98%' }}
                        error={SubscriptionForm.touched.autoRenewal && Boolean(SubscriptionForm.errors.autoRenewal)}
                      >
                        <InputLabel id="select4" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Auto Renewal<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Field
                          as={Select}
                          labelId="select4"
                          id="select4"
                          name="autoRenewal"
                          label="Auto Renewal"
                          variant="standard"
                          size="small"
                          value={SubscriptionForm.values.autoRenewal}
                          onChange={SubscriptionForm.handleChange}
                          sx={{
                            '&:after': {
                              borderBottomColor: '#0000',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          <MenuItem value="true">Yes</MenuItem>
                          <MenuItem value="false">No</MenuItem>
                        </Field>
                        {SubscriptionForm.touched.autoRenewal && SubscriptionForm.errors.autoRenewal ? (
                          <FormHelperText sx={{ ml: 0 }}>
                            {SubscriptionForm.touched.autoRenewal && SubscriptionForm.errors.autoRenewal}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    {/* Payment Mode */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl sx={{ width: '98%' }}>
                        <InputLabel id="select55" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Payment Mode
                        </InputLabel>
                        <Field
                          as={Select}
                          labelId="select55"
                          id="select55"
                          name="status"
                          label="Payment Mode"
                          variant="standard"
                          size="small"
                          value={selectedPaymentMode}
                          onChange={(e) => {
                            setSelectedPaymentMode(e.target.value);
                            const newPaymentInstrumentList = paymentInstrumentList?.filter(
                              (ex) => ex?.paymentModeId?._id === e.target.value
                            );
                            setSelectedPaymentInstrumentList(newPaymentInstrumentList);
                          }}
                          sx={{
                            '&:after': {
                              borderBottomColor: '#0000',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          {paymentModeList.map((e, index) => (
                            <MenuItem key={index} value={e._id}>
                              {e.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                    {/* paymentInstrument */}
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomSelect
                        id="paymentInstrument"
                        label={<Typography sx={{ color: '#B6B6B6' }}>Payment Instrument</Typography>}
                        name="paymentInstrument"
                        onChange={(valuex) => {
                          SubscriptionForm.setFieldValue('paymentInstrument', valuex);
                        }}
                        onCustomClick={() => {
                          setAddPaymentInstrumentModalOpen(true);
                        }}
                        options={selectedPaymentInstrumentList.map((exx) => ({
                          _id: exx._id,
                          name: exx.nickNameField,
                        }))}
                        selectKey="_id"
                        labelKey="name"
                        value={SubscriptionForm.values.paymentInstrument}
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        // fullWidth
                        // sx={{ mb: 3 }}
                        sx={{ width: '98%' }}
                        error={SubscriptionForm.touched.status && Boolean(SubscriptionForm.errors.status)}
                      >
                        <InputLabel id="select5" sx={{ color: '#B6B6B6', ml: '-14px' }}>
                          Status<span style={{ color: 'red' }}>*</span>
                        </InputLabel>
                        <Field
                          as={Select}
                          labelId="select5"
                          id="select5"
                          name="status"
                          label="Status"
                          variant="standard"
                          size="small"
                          value={SubscriptionForm.values.status}
                          onChange={SubscriptionForm.handleChange}
                          sx={{
                            '&:after': {
                              borderBottomColor: '#0000',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#0071E3',
                            },
                          }}
                        >
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Field>
                        {SubscriptionForm.touched.status && SubscriptionForm.errors.status ? (
                          <FormHelperText sx={{ ml: 0 }}>
                            {SubscriptionForm.touched.status && SubscriptionForm.errors.status}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    </Grid>
                    {/* Label */}
                    <Grid item xs={12} sm={12} md={6}>
                      <CustomMuiSelect
                        disablePortal
                        label={<Typography sx={{ color: '#B6B6B6' }}>Label</Typography>}
                        size="small"
                        variant="standard"
                        options={subLabel.map((e) => ({ label: e.name, id: e._id })) || []}
                        value={subLabel?.find((ex) => ex._id === SubscriptionForm.values.label)?.name || ''}
                        onAddNew={() => {
                          setOpenCustomLabel({ open: true, data: null });
                        }}
                        onChange={(e) => {
                          if (e) {
                            SubscriptionForm.setFieldValue('label', e.id);
                          } else {
                            SubscriptionForm.setFieldValue('label', '');
                          }
                        }}
                      />
                    </Grid>
                    {/* comments */}
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '99%' }}
                      >
                        <Field
                          as={TextField}
                          id="input1"
                          inputProps={{
                            maxlength: 250,
                          }}
                          name="comments"
                          helperText={`${SubscriptionForm.values.comments.length}/${250}`}
                          multiline
                          rows={3}
                          label={<Typography sx={{ color: '#B6B6B6' }}>Comments</Typography>}
                          variant="standard"
                          size="small"
                        />
                      </FormControl>
                    </Grid>
                    {/* Rating */}
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        sx={{
                          color: ' #B6B6B6',
                          fontFamily: 'Roboto',
                          fontWeight: '400',
                          fontSize: '1rem',
                          lineHeight: '1.4375em',
                          letterSpacing: '0.00938em',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          mb: 1,
                        }}
                      >
                        Rating
                      </Typography>
                      <CustomRating
                        precision={0.5}
                        name="rating"
                        value={+SubscriptionForm.values.rating}
                        onChange={(e) => {
                          SubscriptionForm.setFieldValue('rating', e.target.value);
                        }}
                      />
                    </Grid>
                    {/* Safals - like/dislike */}
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        sx={{
                          color: ' #B6B6B6',
                          fontFamily: 'Roboto',
                          fontWeight: '400',
                          fontSize: '1rem',
                          lineHeight: '1.4375em',
                          letterSpacing: '0.00938em',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          mb: 1,
                        }}
                      >
                        Safals
                      </Typography>
                      <Box
                        sx={{ display: 'flex' }}
                        name="isLiked"
                        onChange={(e) => {
                          SubscriptionForm.handleChange(e);
                        }}
                        onClick={() => {
                          SubscriptionForm.setFieldValue('isLiked', SubscriptionForm.values.isLiked === 1 ? -1 : 1);
                        }}
                      >
                        {SubscriptionForm.values.isLiked === 1 && (
                          <img style={{ cursor: 'pointer' }} src="/assets/images/Like.png" alt="Like Symbol" />
                        )}
                        {SubscriptionForm.values.isLiked === -1 && (
                          <img style={{ cursor: 'pointer' }} src="/assets/images/disLike.png" alt="DisLike Symbol" />
                        )}
                        {SubscriptionForm.values.isLiked === 0 && (
                          <>
                            <img
                              src="/assets/images/Like.png"
                              alt="Like Symbol"
                              style={{ filter: 'grayscale(100%)', cursor: 'pointer' }}
                            />
                            <img
                              src="/assets/images/disLike.png"
                              alt="DisLike Symbol"
                              style={{ filter: 'grayscale(100%)', cursor: 'pointer' }}
                            />
                          </>
                        )}
                      </Box>
                    </Grid>
                    {/* review */}
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl
                        // fullWidth
                        sx={{ width: '99%' }}
                      >
                        <Field
                          as={TextField}
                          id="input1"
                          name="review"
                          multiline
                          rows={3}
                          inputProps={{
                            maxlength: 1000,
                          }}
                          label={<Typography sx={{ color: '#B6B6B6' }}>Review</Typography>}
                          helperText={`${SubscriptionForm.values.review?.length || 0}/${1000}`}
                          variant="standard"
                          size="small"
                          value={SubscriptionForm.values.review}
                          onChange={(e) => {
                            SubscriptionForm.handleChange(e);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    {/* Attachment */}
                    <Grid item xs={12} sm={12} md={12}>
                      <input
                        id="contained-button-file"
                        style={{ display: 'none' }}
                        type="file"
                        multiple
                        // onChange={(e) => {
                        //   const abc = [];
                        //   Object.keys(e?.target?.files).map((id) => abc.push(e?.target?.files[id]));
                        //   console.log('abc', abc);
                        //   setSelectedFileName(abc);
                        //   SubscriptionForm.setFieldValue('attachment', abc);
                        // }}
                        // ref={inputFileRef}
                        onChange={handleFileEvent}
                      />
                      <FormLabel
                        htmlFor="contained-button-file"
                        sx={{ display: 'flex', alignItems: 'center', height: 30, width: 157 }}
                      >
                        <Button
                          variant="contained"
                          component="span"
                          sx={{
                            fontWeight: 700,
                            textTransform: 'none',
                            bgcolor: '#fff',
                            color: '#1D1D1F',
                            borderRadius: '30px',
                            boxShadow: '0px 4px 25px rgba(150, 150, 150, 0.15)',
                            ':hover': { bgcolor: 'transparent' },
                            mb: 0.7,
                          }}
                        >
                          <Iconify icon="entypo:attachment" color="#3D71FF" width={20} height={20} mr={1} />
                          Attachment
                        </Button>
                      </FormLabel>
                      {uploadedFiles && (
                        <div style={{ display: 'flex', gap: '12px', flexWrap: 'wrap', marginTop: '20px' }}>
                          {uploadedFiles.map((val, index) => (
                            <FileImageView
                              key={index}
                              value={val}
                              onRemove={() => {
                                const newState = uploadedFiles.filter((arr) => val.name !== arr.name);
                                setUploadedFiles(newState);
                                SubscriptionForm.setFieldValue('attachment', newState);
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </FormikProvider>
              <MultiOptionModal
                openOptionModal={openOptionModal}
                setOpenOptionModal={setOpenOptionModal}
                handleClickOpen={handleClickOpen}
                setOpenSubModal={setOpenSubModal}
                handleCloseSub={handleCloseSub}
                id={subId}
                pagination={pagination}
              />
              <MultiOptionModalNew
                onsSubmit={onAlreadySubSave}
                oncCancel={onAlreadySubCancel}
                onClose={onAlreadySubCancel}
                open={isSaveSubsAlready.open}
              />
              <SuccsessModal open={open} handleClose={handleClose} />
            </Box>
          </Scrollbar>
          {/* Create and Cancel Buttons */}
          <Box sx={{ bottom: '20px', display: 'flex' }}>
            <Button
              // startIcon={<CloseIcon />}
              color="error"
              variant="contained"
              onClick={handleCloseSub}
              sx={{
                height: '45px',
                borderRadius: '30px',
                mt: '25px',
                ml: 'auto',
                mb: '20px',
                textTransform: 'capitalize',
              }}
            >
              Cancel
            </Button>
            <Button
              // startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              // type="submit"
              // onClick={  handleClickOpen}
              onClick={() => {
                SubscriptionForm.handleSubmit();
              }}
              // disabled={!(SubscriptionForm.isValid && SubscriptionForm.dirty && !subExist)}
              sx={{
                height: '45px',
                backgroundColor: '#3D71FF',
                borderRadius: '30px',
                mt: '25px',
                ml: '20px',
                mb: '20px',
                textTransform: 'capitalize',
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
      <EditModal
        openEditModal={openEdit}
        setOpenEditModal={setOpenEdit}
        data={editData}
        setEditData={setEditData}
        setOpenTrackModal={setOpenTrackModal}
        handleActionClose={handleActionClose}
        setOpenDelete={setOpenDelete}
        setSelected={setSelected}
        handledelete={handledelete}
      />
      <AddCustomLabel
        open={openCustomLabel.open}
        data={openCustomLabel.data}
        onClose={(data) => {
          fetchSubLabel();
          if (data) {
            SubscriptionForm.setFieldValue('label', data._id);
          }
          setOpenCustomLabel({
            open: false,
            data: null,
          });
        }}
      />
      <AddPaymentInstrumentModal
        open={addPaymentInstrumentModalOpen}
        onClose={onCloseAddPaymentInstrumentModal}
        paymentModeList={paymentModeList}
        paymentCardSchemeList={paymentCardSchemeList}
      />
    </>
  );
};

export default SubscriptionModal;
