/* eslint-disable prefer-template */
import MomentUtils from '@date-io/moment';
import {
  Box,
  Button,
  Checkbox,
  Fab,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  // Pagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Scrollbar from '../../components/Scrollbar';
import { downloadAttachmentFile, downloadReportData, filterReports, LogReportResponse } from '../../services/Service';
import { UserListHead } from '../user';
import Pagination from '../../layouts/dashboard/Pagination';
import ErrorToast from '../../components/Toast/Error';
import ReportsTabsDownloadMenu from './ReportsTabsDownloadMenu';
import LogReportFilterMenu from './LogReportFilterMenu';
import CustomRating from '../../components/UI/CustomRating';
import { tableCellPStyle } from '../../components/UI/CustomTableView';
import Inlineloader from '../../components/Loader/InlineLoader';
import { getTimeZoneAbbreviation } from '../../hooks/useUserTimeZone';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const TABLE_HEAD = [
  { id: 'changeField', label: 'Changed Category', alignRight: false },
  { id: 'description', label: 'Description', alignRight: true },
  { id: 'fromValue', label: 'From Value ', alignRight: false },
  { id: 'toValue', label: 'To Value', alignRight: false },
  { id: 'createdAt', label: 'Date & Time', alignRight: false, sort: true },
];

const LogReport = () => {
  const [logdata, setLogdata] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 5 });
  const [count, setCount] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [field, setField] = useState(null);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [order, setOrder] = useState('desc');
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector((state) => state.login);

  const login = useSelector((state) => state.login);
  const { registered } = login.user;

  function getFirstDayOfMonth() {
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    return `${year}-${month}-01T00:00:00.000Z`;
  }
  function formatDateToISOString(date = new Date()) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}T00:00:00Z`;
  }

  // useEffect(() => {
  //   const formattedDate = formatDateToISOString();
  //   setToDate(formattedDate);
  //   setFromDate(getFirstDayOfMonth());
  // }, []);

  const handleChangePage = (event, newPage) => {
    console.log('this is new page ==> ', newPage);
    const body = {
      from: fromDate,
      to: toDate,
      field: field,
    };

    filterReports(body, newPage, pagination.limit)
      .then((res) => {
        if (res.data.success === true) {
          setCount(Math.ceil(res.data.data[0].pagination[0].totalItems / pagination.limit));
          EventConverter(res.data.data[0].data);
          setTotalData(res.data.data[0].pagination[0].totalItems);
        }
      })
      .catch((err) => {
        ErrorToast('Error while Filtering');
      });
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    console.log('this is rowchange ==> ', event.target.value);
    setPagination((prev) => ({ ...prev, limit: +event.target.value }));
    const body = {
      from: fromDate,
      to: toDate,
      field: field,
    };

    filterReports(body, 1, +event.target.value)
      .then((res) => {
        if (res.data.success === true) {
          setCount(Math.ceil(res.data.data[0].pagination[0].totalItems / +event.target.value));
          EventConverter(res.data.data[0].data);
          setTotalData(res.data.data[0].pagination[0].totalItems);
          setPagination((prev) => ({ ...prev, page: 1 }));
        }
      })
      .catch((err) => {
        ErrorToast('Error while Filtering');
      });
  };

  const matchData = {
    isLiked: 'Safals',
    dateOfBirth: 'Date of Birth ',
    gender: 'Gender',
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNo: 'Phone Number',
    email: 'Email',
    rating: 'Rating',
    frequency: 'Frequency',
    status: 'Status',
    autoRenewal: 'Auto renewal',
    amount: 'Price',
    currencyAbr: 'Currency',
    startDate: 'Start Date',
    nextBilling: 'Next Billing Date',
    review: 'Review',
    comments: 'Comments',
    attachment: 'Attachment',
  };

  const conveter = (fieldname, val) => {
    switch (fieldname) {
      case 'autoRenewal':
        return val === 'true' ? 'Yes' : 'No';
      case 'isLiked':
        return val === '1' ? 'Liked' : val === '-1' ? 'Disliked' : 'Neutral';
      case 'startDate':
        return user.country === 'India'
          ? `${moment(val).format('DD/MM/yyyy')} ${getTimeZoneAbbreviation()}`
          : `${moment(val).format('MM/DD/yyyy')} ${getTimeZoneAbbreviation()}`;

      case 'nextBilling':
        return user.country === 'India'
          ? `${moment(val).format('DD/MM/yyyy')} ${getTimeZoneAbbreviation()}`
          : `${moment(val).format('MM/DD/yyyy')} ${getTimeZoneAbbreviation()}`;

      default:
        return val;
    }
  };

  const EventConverter = (edata) => {
    const keys = Object.keys(matchData);
    const data = edata.map((val) => {
      switch (val.ModuleName) {
        case 'login':
          return { ...val, event: 'You logged in' };
        case 'EditSub': {
          const data = {
            ...val,
            event: `You Edited ${val.subName} & changed ${matchData[val.event]}`,
            oldValue: conveter(val.event, val.oldValue),
            newValue: conveter(val.event, val.newValue),
          };

          return data;
        }
        case 'AddSub':
          return { ...val, event: `You added ${val.subName}` };
        case 'DeleteSub':
          return { ...val, event: `You deleted ${val.subName}` };
        case 'changeBudget':
          return { ...val, event: `You changed budget` };
        case 'EditPic':
          return { ...val, event: `You changed profile picture` };
        case 'personalDetails': {
          const data = {
            ...val,
            event: `You changed personal details & changed ${
              keys?.includes(val.event) ? matchData[val.event] : val.event
            }`,
            oldValue: conveter(val.event, val.oldValue),
            newValue: conveter(val.event, val.newValue),
          };

          return data;
          // ...val,
          // event: `You changed personal details & changed ${
          //   keys?.includes(val.event) ? matchData[val.event] : val.event
          // }`,
        }
        case 'logout':
          return { ...val, event: `You logged out` };
        case 'downloadSubdata':
          // return { ...val, event: 'You downloaded Subscription data' };
          return { ...val, event: val.event };
        case 'downloadReportsdata':
          // return { ...val, event: 'You downloaded Changelog/Activity Report' };
          return { ...val, event: val.event };
        case 'download_asset_module':
          // return { ...val, event: 'You downloaded Changelog/Activity Report' };
          return { ...val, event: `You have downloaded data in Asset Module` };
        default:
          return { ...val, event: matchData[val.event] };
      }
    });

    console.log('this is evnts ==> ', data);
    setLogdata(data);
  };

  // React.useEffect(() => {
  //   LogReportResponse(pagination.page,pagination.limit)
  //     .then((res) => {
  //       if (res.data.success === true) {
  //         console.log("limited data ==> ",res.data.data[0].data)
  //         setCount(Math.ceil(res.data.data[0].pagination[0].totalItems/pagination.limit))
  //         EventConverter(res.data.data[0].data);
  //       }
  //     })
  //     .catch((err) => {
  //       // if (err.response.status === 401) {
  //         console.log("this is useEffect LogReportResponse err ===> ", err);

  //         ErrorToast('Error!!');
  //       // }
  //     });
  // }, []);

  const handleFilterReports = () => {
    const body = {
      from: fromDate,
      to: toDate,
      field: field,
    };
    setPagination({ page: 1, limit: 5 });
    filterReports(body, pagination.page, pagination.limit)
      .then((res) => {
        if (res.data.success === true) {
          setCount(Math.ceil(res.data?.data[0]?.pagination[0]?.totalItems / pagination.limit));
          EventConverter(res.data?.data[0]?.data);
          setTotalData(res.data.data[0]?.pagination[0]?.totalItems);
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        console.log('this is useEffect filter err ===> ', err);
        ErrorToast('Error while Filtering');
        // }
      });
  };

  React.useEffect(() => {
    const body = {
      from: getFirstDayOfMonth(),
      to: toDate,
      field: field,
    };
    setIsLoading(true);
    filterReports(body, pagination.page, pagination.limit)
      .then((res) => {
        if (res.data.success === true) {
          setCount(Math.ceil(res.data?.data[0]?.pagination[0]?.totalItems / pagination.limit));
          EventConverter(res.data?.data[0]?.data);
          setTotalData(res.data.data[0]?.pagination[0]?.totalItems);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // if (err.response.status === 401) {
        console.log('this is useEffect filter err ===> ', err);
        ErrorToast('Error while Filtering');
        // }
      });
    // }, [fromDate, toDate, field, pagination]);
  }, []);

  const handleDownload = (url) => {
    console.log('url', url);
    downloadAttachmentFile({ url })
      .then((res) => {
        console.log('this is attachment download => ', res.data);
        if (res.data.success === true) {
          const link = document.createElement('a');
          link.href = res.data.data;
          link.setAttribute('download', `file`);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((err) => {
        console.log('this is attachment err => ', err);
        ErrorToast(err.response.data.message || 'Something went wrong !!');
      });
  };

  const handleClearFilters = () => {
    setPagination({ page: 1, limit: 5 });
    setFromDate('');
    setToDate('');
    setField(null);
    const body = {
      from: '',
      to: '',
      field: null,
    };
    filterReports(body, pagination.page, pagination.limit).then((res) => {
      if (res.data.success === true) {
        setTotalData(res.data.data[0]?.pagination[0]?.totalItems);
        setCount(Math.ceil(res.data?.data[0]?.pagination[0]?.totalItems / pagination.limit));
        EventConverter(res.data?.data[0]?.data);
      }
    });
  };
  const refreshData = (order, orderBy) => {
    const body = {
      from: fromDate,
      to: toDate,
      field: field,
    };

    filterReports(body, pagination.page, pagination.limit, order, orderBy)
      .then((res) => {
        if (res.data.success === true) {
          setTotalData(res.data.data[0]?.pagination[0]?.totalItems);
          setCount(Math.ceil(res.data?.data[0]?.pagination[0]?.totalItems / pagination.limit));
          EventConverter(res.data?.data[0]?.data);
        }
      })
      .catch((err) => {
        // if (err.response.status === 401) {
        console.log('this is useEffect filter err ===> ', err);
        ErrorToast('Error while Refreshing.');
        // }
      });
  };

  const downloadFileNew = async (type) => {
    try {
      const res = await downloadReportData(
        {
          from: fromDate,
          to: toDate,
          field: field,
          fileType: type,
          event: { call: 'downloadReportsdata' },
        },
        pagination.page,
        pagination.limit
      );

      if (res.data.success === true) {
        handleDownload(res.data.data);
      }
    } catch (err) {
      ErrorToast(err.response.data.message || 'Something went wrong !!');
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const ordr = isAsc ? 'desc' : 'asc';
    setOrder(ordr);
    setOrderBy(property);

    refreshData(ordr, property);
  };

  const renderNewValue = (row) => {
    if (row?.message?.includes('Safals') && row?.newValue === '1') {
      return <img src="/assets/images/Like.png" alt="Liked" />;
    }
    if (row?.message?.includes('Safals') && row?.newValue === '-1') {
      return <img src="/assets/images/disLike.png" alt="Disliked" />;
    }
    if (row?.message?.includes('Safals') && row?.newValue === '0') {
      return (
        <div style={{ display: 'flex' }}>
          <img src="/assets/images/Like.png" alt="Like Symbol" style={{ filter: 'grayscale(100%)' }} />
          <img src="/assets/images/disLike.png" alt="DisLike Symbol" style={{ filter: 'grayscale(100%)' }} />
        </div>
      );
    }
    if (row?.message?.includes('changed Rating')) {
      return <CustomRating precision={0.5} size="small" readOnly value={row?.newValue} />;
    }
    if (row?.message?.includes('changed Attachment')) {
      const filesLink = row?.newValue?.split(',');
      const filterFilesLink = filesLink.filter((ex) => ex.includes('https://'));
      if (filterFilesLink.length === 0) return row?.newValue;
      return (
        <>
          {filterFilesLink.map((e) => {
            const filename = e.split('/')[e.split('/').length - 1];
            const newFileName = filename.split('-').slice(1).join('-');
            return (
              <>
                {newFileName}
                <IconButton size="small" onClick={() => handleDownload(e)}>
                  <DownloadIcon />
                </IconButton>
              </>
            );
          })}
        </>
      );
    }
    if (row?.newValue === '') return 'N/A';
    return row?.newValue;
  };
  const renderOldValue = (row) => {
    if (row?.message?.includes('Safals') && row?.oldValue === '1') {
      return <img src="/assets/images/Like.png" alt="Liked" />;
    }
    if (row?.message?.includes('Safals') && row?.oldValue === '-1') {
      return <img src="/assets/images/disLike.png" alt="Disliked" />;
    }
    if (row?.message?.includes('Safals') && row?.oldValue === '0') {
      return (
        <div style={{ display: 'flex' }}>
          <img src="/assets/images/Like.png" alt="Like Symbol" style={{ filter: 'grayscale(100%)' }} />
          <img src="/assets/images/disLike.png" alt="DisLike Symbol" style={{ filter: 'grayscale(100%)' }} />
        </div>
      );
    }
    if (row?.message?.includes('changed Rating')) {
      return <CustomRating precision={0.5} size="small" readOnly value={row?.oldValue} />;
    }
    if (row?.message?.includes('changed Attachment')) {
      const filesLink = row?.oldValue?.split(',');
      const filterFilesLink = filesLink.filter((ex) => ex.includes('https://'));
      if (filterFilesLink.length === 0) return row?.oldValue;
      return (
        <>
          {filterFilesLink.map((e) => {
            const filename = e.split('/')[e.split('/').length - 1];
            const newFileName = filename.split('-').slice(1).join('-');
            return newFileName;
          })}
        </>
      );
    }
    if (row?.oldValue === '') return 'N/A';
    return row?.oldValue;
  };

  if (isLoading) return <Inlineloader />;
  return (
    <Box>
      <Grid container rowSpacing={3} sx={{ alignItems: 'center' }} columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
        <div
          style={{
            display: 'flex',
            marginLeft: '16px',
            alignItems: 'center',
            padding: '8px 2px',
            gap: '12px',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <LogReportFilterMenu
            setField={setField}
            onFilterApply={handleFilterReports}
            field={field}
            setToDate={setToDate}
            toDate={toDate}
            fromDate={fromDate}
            setFromDate={setFromDate}
            onFilterReset={handleClearFilters}
          />
          <Tooltip title="Refresh">
            <IconButton
              sx={{
                color: '#FFFFFF',
                backgroundColor: '#3D71FF',
                '&:hover': { backgroundColor: '#3D71FF', color: '#FFFFFF' },
              }}
              onClick={() => refreshData(order, orderBy)}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <ReportsTabsDownloadMenu downloadFile={downloadFileNew} toastSuccess />
        </div>
      </Grid>
      {logdata.length !== 0 && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>
            {logdata.length} of {totalData}
          </span>
          <TablePagination
            component="div"
            size="small"
            rowsPerPageOptions={[5, 10, 25, 50]}
            page={pagination.page - 1}
            onPageChange={(e, newPage) => handleChangePage(e, newPage + 1)}
            rowsPerPage={pagination.limit}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={count}
          />
        </div>
      )}
      {logdata.length !== 0 ? (
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader sx={{ borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <UserListHead
              order={order}
              orderBy={orderBy}
              isCheckListRemove="true"
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {logdata.length > 0 &&
                logdata.map((row) => (
                  <>
                    <TableRow hover key={row?._id} tabIndex={-1} role="checkbox">
                      <TableCell
                        align="left"
                        sx={{
                          backgroundColor: '#FFFFFF',
                        }}
                      >
                        <p style={tableCellPStyle}>
                          {row?.ModuleName.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, (c) => c.toUpperCase())}
                        </p>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          backgroundColor: '#FFFFFF',
                        }}
                      >
                        <p style={tableCellPStyle}>{row?.message}</p>
                      </TableCell>

                      <LightTooltip title={row?.oldValue || 'N/A'} placement="bottom">
                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor: '#FFFFFF',
                          }}
                        >
                          <p style={tableCellPStyle}>{renderOldValue(row)}</p>
                        </TableCell>
                      </LightTooltip>
                      <LightTooltip title={row?.newValue} placement="bottom">
                        <TableCell
                          align="left"
                          sx={{
                            backgroundColor: '#FFFFFF',
                          }}
                        >
                          <p style={tableCellPStyle}>{renderNewValue(row)}</p>
                        </TableCell>
                      </LightTooltip>
                      <TableCell
                        align="left"
                        sx={{
                          backgroundColor: '#FFFFFF',
                          borderBottomRightRadius: '60px',
                          borderTopRightRadius: '60px',
                        }}
                      >
                        <p style={tableCellPStyle}>
                          {moment(row?.createdAt).format(
                            user.country === 'India' ? 'DD/MM/yyyy, hh:mm A' : 'MM/DD/yyyy, hh:mm A'
                          )}{' '}
                          {getTimeZoneAbbreviation()}
                        </p>
                        {/* <p style={tableCellPStyle}>
                          {moment(row?.createdAt)
                            .tz(user.country === 'United States' ? 'America/New_York' : 'Asia/Kolkata')
                            .format(user.country === 'India' ? 'DD/MM/yyyy, hh:mm A' : 'MM/DD/yyyy, hh:mm A')}
                        </p> */}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5% 0',
          }}
        >
          <h2 style={{ color: '#3D71FF' }}>No Data Found</h2>
        </div>
      )}
    </Box>
  );
};

export const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })
);

export default LogReport;
