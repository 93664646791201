import { Box } from '@mui/material';

const Footer = () => (
  <Box
    sx={{
      height: { xs: '30px', sm: '40px', md: '50px', lg: '60px' },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: { xs: '0 20px', sm: '0 40px', md: '0 50px', lg: '0 64px' },
    }}
  >
    <Box sx={{ fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px' }, fontWeight: 400, color: '#000000' }}>
      © Safalvir, Inc. 2024. All rights reserved{' '}
      <span className="display-version">{process.env.REACT_APP_VERSION}</span>
    </Box>
    <Box sx={{ fontSize: { xs: '10px', sm: '12px', md: '14px', lg: '16px' }, fontWeight: 400, color: '#000000' }}>
      Terms of Service | Privacy Policy
    </Box>
  </Box>
);

export default Footer;
