import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';

import './UserPermissionToggleSetting.css';

import { getUserToggleSettingBusiness, updateUserToggleSettingBusiness } from '../../../services/Service';
import TableView from './TableView';

const UserPermissionToggleSetting = () => {
  const [rawData, setRawData] = useState([]);
  const [disableTableSwitch, setDisableTableSwitch] = useState(false);

  const fetchData = async () => {
    try {
      const res = await getUserToggleSettingBusiness();
      if (res.status === 200) {
        setRawData(res.data.data);
      }
    } catch (err) {
      console.log('UserPermissionToggleSetting fetchData error', err.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateValue = async (key, newValue) => {
    setDisableTableSwitch(true);
    try {
      const payload = { ...rawData, [key]: newValue };
      const res = await updateUserToggleSettingBusiness(payload);
      if (res.status === 200) {
        toast.success(res.data.message);
        fetchData();
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
    } finally {
      setDisableTableSwitch(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>User Permission Toggle Setting - SafalSubscriptions</title>
      </Helmet>

      <div id="UserPermissionToggleSetting">
        <div className="title-bar">
          <div className="title-search-div">
            <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027c34' }}>
              User Permission Toggle Setting
            </Typography>
          </div>
        </div>
        <div className="toggle-bar">
          <div className="toggle-bar-dix" />
          <div className="title-action-div" />
        </div>
        <TableView disabled={disableTableSwitch} updateValue={updateValue} data={rawData} />
      </div>
    </>
  );
};

export default UserPermissionToggleSetting;
