import React from 'react';
import { Box, Button, Fab, Modal, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MultiSubscriptionsOptions = ({ open, onClose, data, onClick }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #FFF',
    borderRadius: '10px',
    // boxShadow: 24,
  };

  const handleClose = () => {
    onClose();
  };

  const handleClick = (sub) => {
    const payload = {
      ...data.data,
      raw: {
        ...data.data.raw,
        subscriptions: [sub],
      },
    };
    onClick(payload, data.index);
    handleClose();
  };

  const arraySub = data?.data?.raw?.subscriptions || [];

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: 500,
            // height: 180,
            '@media (max-width: 640px)': {
              width: '95vw',
            },
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1, mr: 1 }}>
            <Fab
              onClick={handleClose}
              size="small"
              aria-label="add"
              // sx={{ background: theme.palette.secondary.title }}
            >
              <CloseIcon />
            </Fab>
          </Box>
          <Typography id="alert-dialog-title" sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 500, mb: 1.5 }}>
            Which Subscription are you want to edit ?
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '250px',
              overflow: 'auto',
              gap: '4px',
              padding: '12px',
            }}
          >
            {arraySub.map((e) => (
              <RowData
                name={e?.subscriptionName}
                frequency={e?.frequency}
                amount={`${e?.currencyAbr === 'USD' ? '$' : '₹'} ${e?.amount}`}
                onClick={() => handleClick(e)}
              />
            ))}
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default MultiSubscriptionsOptions;

const RowData = ({ name, frequency, amount, onClick }) => {
  return (
    <div
      style={{
        display: 'flex',
        flex: 'none',
        alignItems: 'center',
        padding: '4px 12px',
        borderRadius: '8px',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      }}
    >
      <p style={{ width: '200px' }}>{name}</p>
      <p style={{ width: '200px' }}>{frequency}</p>
      <p style={{ width: '100px' }}>{amount}</p>
      <Button onClick={onClick}>Edit</Button>
    </div>
  );
};
