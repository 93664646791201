/* eslint-disable no-plusplus */
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useEffect, useRef, useState } from 'react';
import { TabPanel } from '@mui/lab';

import './HeySafalSpeechRecognition.css';
import InputBoxGPT from './InputBoxGPT';
import { safalAiListGpt } from '../../services/Service';
import ChatMessageXGPT from './ChatMessageXGPT';
import useUserTimeZone from '../../hooks/useUserTimeZone';

const formatDateInterval = (arrx, formatDate) => {
  let lastDate = '';
  const arr = [...arrx];
  for (let index = arr.length - 1; index >= 0; index--) {
    const element = arr[index];
    if (formatDate(element.createdAt) !== lastDate) {
      element.isDate = true;
      lastDate = formatDate(element.createdAt);
    }
  }
  return arr;
};

const HeySafalSpeechRecognitionGPT = ({ tabValue }) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [mainChatArray, setMainChatArray] = useState([]);
  const { formatDate } = useUserTimeZone();

  const fetchChatList = async () => {
    try {
      const res = await safalAiListGpt();
      setMainChatArray(res.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchChatList();
  }, []);

  return (
    <>
      {tabValue === '2' && (
        <div id="HeySafalSpeechRecognition">
          <div className="ai-chat">
            {formatDateInterval(mainChatArray, formatDate).map((e, index) => {
              return <ChatMessageXGPT data={e} key={index} />;
            })}
          </div>
          <InputBoxGPT
            fetchChatList={fetchChatList}
            listening={listening}
            transcript={transcript}
            speechRecognition={SpeechRecognition}
            resetTranscript={resetTranscript}
          />
        </div>
      )}
    </>
  );
};
export default HeySafalSpeechRecognitionGPT;
