import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addFeedback,
  getFeedback,
  updateFeedback,
  deleteFeedback,
  getFeedbacks,
  handleFeedback,
  getUserFeedbacks,
  getShiftTiming as getShiftCallTiming,
} from '../services/Service';
import ErrorToast from '../components/Toast/Error';

const initialState = {
  isLoading: false,
  shiftTiming: [],
  feedback: {
    data: [],
    pagination: [],
    user: {
      data: [],
    },
  },
};

export const updateFeedbackAction = createAsyncThunk('update_feedback', async (params, { rejectWithValue }) => {
  try {
    console.log(params.get('_id'));
    const response = await updateFeedback(params, params.get('_id'));
    if (response.data.success) {
      rejectWithValue(response.message);
    } else {
      ErrorToast(response.data.message);
    }
    return response?.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const getUserFeedbacksAction = createAsyncThunk('feedbackUser', async (params, { rejectWithValue }) => {
  try {
    const response = await getUserFeedbacks(params);
    console.log(response.data);

    if (response.data.success) {
      rejectWithValue(response.message);
    } else {
      ErrorToast(response.data.message);
    }
    return response?.data?.response;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const getShiftTiming = createAsyncThunk('shiftTiming', async (params, { rejectWithValue }) => {
  try {
    const response = await getShiftCallTiming();
    if (response.data.success) {
      rejectWithValue(response.message);
    } else {
      ErrorToast(response.data.message);
    }
    return response?.data?.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const addFeedbackAction = createAsyncThunk('feedback', async (params, { rejectWithValue }) => {
  try {
    console.log(params);
    const response = await addFeedback(params);
    console.log(response);
    if (response.data.success) {
      rejectWithValue(response.message);
    } else {
      ErrorToast(response.data.message);
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const getFeedbacksAction = createAsyncThunk('feedback', async (params, { rejectWithValue }) => {
  try {
    const response = await getFeedbacks(params);
    if (response.data.success) {
      rejectWithValue(response.message);
    } else {
      ErrorToast(response.data.message);
    }

    return response?.data?.response;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const getFeedbackAction = createAsyncThunk('current_feedback', async (params, { rejectWithValue }) => {
  try {
    const response = await getFeedback(params);
    if (response.data.success) {
      rejectWithValue(response.message);
    } else {
      ErrorToast(response.data.message);
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const handleFeedbackAction = createAsyncThunk('handle_feedback', async (params, { rejectWithValue }) => {
  try {
    const response = await handleFeedback(params);
    if (response.data.success) {
      rejectWithValue(response.message);
    } else {
      ErrorToast(response.data.message);
    }

    return response?.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue(error?.response ? error.response : error);
  }
});

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setFeedback: (state, action) => {
      state.feedback = { ...state.feedback, ...action.payload };
      return state;
    },
    resetFeedback: () => {
      return initialState;
    },
  },
  extraReducers: {
    [addFeedbackAction.pending]: (state) => {
      state.isLoading = true;
    },
    [addFeedbackAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.feedback = payload.data;
    },
    [addFeedbackAction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },
    [getFeedbacksAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getFeedbacksAction.fulfilled]: (state, { payload: { data, pagination } }) => {
      state.isLoading = false;
      state.feedback = {
        data,
        pagination,
      };
    },
    [getFeedbacksAction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },
    [handleFeedbackAction.pending]: (state) => {
      state.isLoading = true;
    },
    [handleFeedbackAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [handleFeedbackAction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },
    [getFeedbackAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getFeedbackAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.current = payload.data[0];
    },
    [getFeedbackAction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },
    [updateFeedbackAction.pending]: (state) => {
      state.isLoading = true;
    },
    [updateFeedbackAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [updateFeedbackAction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },
    [getUserFeedbacksAction.fulfilled]: (state, { payload: { data, pagination } }) => {
      state.isLoading = false;
      state.feedback = {
        ...state.feedback,
        user: { data, pagination },
      };
    },
    [getUserFeedbacksAction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },

    [getUserFeedbacksAction.pending]: (state) => {
      state.isLoading = true;
    },
    [getShiftTiming.fulfilled]: (state, { payload }) => {
      console.log(payload);
      state.isLoading = false;
      state.shiftTiming = payload;
    },
    [getShiftTiming.rejected]: (state, { payload }) => {
      state.isLoading = false;
      console.log('Error', payload);
    },

    [getShiftTiming.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

export const { setFeedback, resetFeedback } = feedbackSlice.actions;

export default feedbackSlice.reducer;
