import { Autocomplete, Button, ClickAwayListener, Paper, Popper, TextField, Typography } from '@mui/material';
import React from 'react';

// flagXX1 only use for fix temp problem.
const CustomSelect = ({
  options,
  value,
  onChange,
  id,
  name,
  selectKey,
  labelKey,
  onCustomClick,
  label,
  disabled,
  helperText,
  companyIdError,
  setCompanyIdError,
  flagXX1,
  sortFalse,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(false);
  const nameValue = options.find((ex) => ex._id === value)?.name;
  const onChangeHandler = () => {
    setCompanyIdError && setCompanyIdError(!companyIdError);
  };

  const selectHandler = (e, val) => {
    const findCV = options.find((ex) => ex.name === e?.target?.value);
    if (findCV) {
      setCompanyIdError && setCompanyIdError(false);

      onChange(findCV._id);
    }
  };

  const onCloseHandler = () => {
    if (!flagXX1) onChange('');
    setCompanyIdError && setCompanyIdError(true);
  };

  const optionsSort = sortFalse ? options : options.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      {!disabled ? (
        <TextField
          autoComplete="new-password"
          as={Autocomplete}
          id={id}
          disabled={disabled}
          onChange={(e, val) => {
            onChange(val);
          }}
          onSelect={selectHandler}
          onInputChange={onCloseHandler}
          // onBlur={onBlur}
          // freeSolo
          name={name}
          value={value}
          disablePortal
          // eslint-disable-next-line no-unneeded-ternary
          disableCloseOnSelect={value ? false : true}
          open={anchorEl}
          options={optionsSort}
          getOptionLabel={(option) => option[labelKey]}
          onOpen={() => setAnchorEl(true)}
          onClose={() => {
            setAnchorEl(false);
          }}
          getOptionKey={(option) => option[selectKey]}
          ListboxProps={{ style: { maxHeight: 150 } }}
          // disableListWrap
          PopperComponent={
            onCustomClick !== undefined &&
            ((props) => (
              <ClickAwayListener>
                <Popper {...props} disablePortal>
                  <Paper sx={{ background: '#fff' }}>
                    {props.children}
                    <Button
                      onClick={onCustomClick}
                      onTouchEnd={onCustomClick}
                      onMouseDown={onCustomClick}
                      variant="contained"
                      size="small"
                      style={{ margin: '10px', textTransform: 'capitalize', borderRadius: '30px' }}
                    >
                      Add New
                    </Button>
                  </Paper>
                </Popper>
              </ClickAwayListener>
            ))
          }
          renderInput={(params) => (
            <ClickAwayListener
              onClickAway={() => {
                console.log('onClickAway');
                setAnchorEl(false);
              }}
            >
              <TextField
                InputLabelProps={{
                  style: { color: 'black' },
                }}
                label={label}
                {...params}
                variant="standard"
                size="small"
                error={companyIdError}
                onChange={onChangeHandler}
                helperText={companyIdError ? helperText : ''}
              />
            </ClickAwayListener>
          )}
        />
      ) : (
        <TextField label={label} value={nameValue} disabled variant="standard" size="small" />
      )}
    </>
  );
};

export default CustomSelect;
