import { Box, TableSortLabel, Typography, useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};
const useStyles = makeStyles({
  paddingHead: {
    whiteSpace: 'pre-wrap',
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 5,
    paddingTop: 5,
    marginRight: 12,
    marginLeft: 12,
  },
});

// This must be place inside a <TableContainer>...</TableContainer>
export const CustomTableView = ({ headData, children, isSort, sort }) => {
  const orderBy = sort?.orderBy;
  const order = sort?.order;
  const createSortHandler = sort?.createSortHandler;
  const sortHeadMap = sort?.sortHeadMap;

  const { user } = useSelector((state) => state.login);
  const theme = useTheme();
  const classes = useStyles();

  const TableCellStyle = {
    color: user?.role === 'business' ? '#027C34' : 'white',
    backgroundColor: theme.palette.info.table,
    '&:first-child': {
      borderBottomLeftRadius: '35px',
      borderTopLeftRadius: '35px',
    },
    '&:last-child': {
      borderBottomRightRadius: '35px',
      borderTopRightRadius: '35px',
      whiteSpace: 'pre-wrap',
      paddingRight: 0,
      paddingLeft: 0,
      paddingX: '10px !important',
    },
  };

  return (
    <Table
      stickyHeader
      sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0 10px' }}
      aria-label="caption table"
    >
      <TableHead style={{ backgroundColor: '#7b9efd', color: 'white', padding: '0px !important' }}>
        <TableRow>
          {headData.map((e, index) => (
            <TableCell
              key={index}
              className={classes.paddingHead}
              sx={TableCellStyle}
              // sortDirection={orderBy ? (orderBy === sortHeadMap[index] ? order : false) : false}
            >
              {/* {isSort && sortHeadMap[index] !== '' ? (
                <TableSortLabel
                  active={orderBy === sortHeadMap[index]}
                  direction={orderBy === sortHeadMap[index] ? order : 'asc'}
                  onClick={() => createSortHandler(sortHeadMap[index])}
                >
                  {e}
                  {orderBy === sortHeadMap[index] ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                e
              )} */}
              <Box sx={{ display: 'flex', alignItems: 'center', color: theme.palette.secondary.font, fontWeight: 700 }}>
                <span>{e}</span>
                {isSort && sortHeadMap[index] !== '' && (
                  <>
                    <Typography
                      onClick={() => createSortHandler(sortHeadMap[index])}
                      style={{
                        display: 'flex',
                        width: '16px',
                        flexDirection: 'column',
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                    >
                      <ArrowDropUpIcon
                        sx={{
                          ...(order === 'asc' && orderBy === sortHeadMap[index] && { color: '#1877F2' }),
                          position: 'absolute',
                          bottom: '-8px',
                        }}
                      />
                      <ArrowDropDownIcon
                        sx={{
                          ...(order === 'desc' && orderBy === sortHeadMap[index] && { color: '#1877F2' }),
                          position: 'absolute',
                          top: '-8px',
                        }}
                      />
                    </Typography>
                  </>
                )}
              </Box>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{children}</TableBody>
    </Table>
  );
};

export const CustomTableCellForBody = ({ children, ...props }) => {
  const sx = {
    backgroundColor: '#FFFFFF',
    '&:first-child': {
      borderBottomLeftRadius: '35px',
      borderTopLeftRadius: '35px',
    },
    '&:last-child': {
      borderBottomRightRadius: '35px',
      borderTopRightRadius: '35px',
      pr: 0,
    },
  };

  return (
    <TableCell {...props} align="left" sx={sx}>
      <p style={tableCellPStyle}>{children}</p>
    </TableCell>
  );
};

export const CustomTableCellForNoData = ({ message, ...props }) => {
  return (
    <TableCell {...props} align="center" colSpan={16} sx={{ borderRadius: '35px', backgroundColor: '#FFFFFF' }}>
      {message}
    </TableCell>
  );
};
export const CustomTableCellForLoading = ({ ...props }) => {
  return (
    <TableCell {...props} align="center" colSpan={16} sx={{ borderRadius: '35px', backgroundColor: '#FFFFFF' }}>
      <span style={{ fontSize: '32px' }}>Loading...</span>
    </TableCell>
  );
};
