import { IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  CustomTableCellForBody,
  CustomTableCellForNoData,
  CustomTableView,
} from '../../../components/UI/CustomTableView';
import useUserTimeZone, { getTimeZoneAbbreviation } from '../../../hooks/useUserTimeZone';

const tableCellPStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '250px',
  maxHeight: '100px',
};

const TableView = ({ data, onEdit, onDelete, mode }) => {
  const { formatDateTime } = useUserTimeZone();

  const headDataColor = ['Color', 'Is default', 'Created at', 'Action'];
  const headDataImage = ['Color', 'Is default', 'Created at', 'Action'];

  const dataFilter = data.filter((ex) => ex.type === mode);

  return (
    <TableContainer>
      <CustomTableView headData={mode === 'color' ? headDataColor : headDataImage}>
        {dataFilter.length === 0 && <CustomTableCellForNoData message="You Don't have any data. Please Add New." />}
        {dataFilter?.map((e, index) => (
          <TableRow key={index}>
            <CustomTableCellForBody align="center">
              {mode === 'color' ? (
                <div
                  style={{
                    backgroundColor: e?.value,
                    width: '100px',
                    height: '80px',
                    borderRadius: '20px',
                    border: '1px solid #027c34',
                  }}
                />
              ) : (
                <img
                  src={e?.value}
                  style={{ maxHeight: '100px', objectFit: 'cover', borderRadius: '20px', border: '1px solid #027c34' }}
                  alt="background"
                />
              )}
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>{e?.isDefault?.toString()}</p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <p style={tableCellPStyle}>
                {formatDateTime(e?.createdAt)} {getTimeZoneAbbreviation()}
              </p>
            </CustomTableCellForBody>
            <CustomTableCellForBody align="center">
              <div
                style={{
                  display: 'flex',
                }}
              >
                <IconButton onClick={() => onDelete(e)}>
                  <DeleteIcon />
                </IconButton>
                {/* <IconButton onClick={() => onEdit(e)}>
                  <EditIcon />
                </IconButton> */}
              </div>
            </CustomTableCellForBody>
          </TableRow>
        ))}
      </CustomTableView>
    </TableContainer>
  );
};

export default TableView;
