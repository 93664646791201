/* eslint-disable import/no-named-as-default-member */
import {
  Button,
  Stack,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Fab,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { filter } from 'lodash';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionMenu from '../business/ActionMenu';
import Attachment from '../../components/Attachments';
import Iconify from '../../components/Iconify';
import ReplyFeedbackModal from '../../components/Modals/ReplyFeedbackModal';
import Page from '../../components/Page';
import CollapseShow from '../../components/CollpseShow';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead } from '../../sections/user';
import { ExcelDownload, GetcompaniesResponse, TxtDownload, TabDownload, PDFDownload } from '../../services/Service';
import { getFeedbacksAction } from '../../store/feedbackSlice';
import { setSubscriptions } from '../../store/subscriptionSlice';
import Pagination from '../../layouts/dashboard/Pagination';
/**
 * Table
 */

const TABLE_HEAD = [
  { id: 'supportId', label: 'Feedback ID', alignRight: false },
  { id: 'name', label: 'User', alignRight: false, sort: true },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'details', label: 'Short Description', alignRight: false },
  { id: 'description', label: 'Detail Description', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false, sort: true },
  { id: 'updatedAt', label: 'Updated Date', alignRight: false, sort: true },
  { id: 'createdAt', label: 'Created Date', alignRight: false, sort: true },
  { id: 'attachment', label: 'Attachment', alignRight: false },
  { id: 'action', label: 'Comments (View/ Add)', alignRight: false },
];

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 300,
  height: 40,
  backgroundColor: '#FFFFFF',
  borderRadius: '30px',
  borderColor: '#FFFFFF',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const AdminUserFeedback = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const { user } = useSelector((state) => state.login);
  const [isEdit, setEdit] = useState('');
  const [selected, setSelected] = useState([]);
  const [editData, setEditData] = useState('');
  const [orderBy, setOrderBy] = useState('createdAt');
  const [open, setOpen] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [actionMenu, setActionmenu] = React.useState(null);
  const actionOpen = Boolean(actionMenu);
  const handleActionClick = (event) => {
    setActionmenu(event.currentTarget);
  };
  const handleActionClose = () => {
    setActionmenu(null);
  };

  const closeAttachmentPopUpRef = useRef(() => {});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.login.user);

  const { feedback } = useSelector((state) => state.feedback);
  useEffect(() => {
    dispatch(getFeedbacksAction({ page: 1, limit: 5, orderBy, order }));
  }, [dispatch]);

  useEffect(() => {
    GetcompaniesResponse()
      .then((res) => {
        if (res.data.success === true) {
          dispatch(setSubscriptions({ subscriptions: res.data.data }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/login');
        }
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const ordr = isAsc ? 'desc' : 'asc';
    setOrder(ordr);
    setOrderBy(property);

    dispatch(getFeedbacksAction({ page: page + 1, limit: rowsPerPage, orderBy: property, order: ordr }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getFeedbacksAction({ page: newPage + 1, limit: rowsPerPage, orderBy, order }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(getFeedbacksAction({ limit: event.target.value, page: 1, orderBy, order }));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFeedbackSearch = (val) => {
    dispatch(getFeedbacksAction({ page: 1, limit: rowsPerPage, orderBy, order, search: val }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const [idx, setIdx] = useState();
  const [openCol, setopenCol] = React.useState(false);
  const idxClick = (event) => {
    setIdx(event);
    setopenCol(!openCol);
  };

  const filteredSubsData = feedback?.data || [];

  const isUserNotFound = filteredSubsData.length === 0;

  const Datas = feedback?.data;

  console.log('FFFFF', Datas);
  const StatusColor = (status) => {
    if (status === 'Submitted') {
      return 'blue';
    }
    if (status === 'Opened') {
      return 'orange';
    }
    if (status === 'In-progress') {
      return 'orange';
    }
    if (status === 'Re-opened') {
      return 'red';
    }
    if (status === 'Closed') {
      return 'green';
    }
    return status;
  };
  console.log('feedback...', feedback);
  return (
    <Page title="User Feedback - SafalSubscriptions">
      <div>
        <TableContainer sx={{ overflowX: 'auto', mt: 2 }}>
          <Scrollbar>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" sx={{ fontSize: '40px', fontWeight: 700, color: '#027C34' }}>
                Feedback
              </Typography>
              <SearchStyle
                // value={filterName}
                onChange={(e) => handleFeedbackSearch(e.target.value)}
                placeholder="Search Feedback..."
                endAdornment={
                  <InputAdornment position="end">
                    <Iconify icon="eva:search-fill" sx={{ color: '#027C34', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </Stack>

            <ReplyFeedbackModal openEditModal={open} setOpenEditModal={setOpen} id={editData} isEdit={isEdit} />
            <Table sx={{ borderCollapse: 'separate', borderSpacing: '0 10px', padding: '0 10px', minWidth: 340 }}>
              <UserListHead
                isCheckListRemove={'true'}
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={feedback?.data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {feedback?.data &&
                  feedback?.data.map((row, i) => {
                    return (
                      <>
                        <TableRow
                          hover
                          key={row._id}
                          tabIndex={-1}
                          sx={{
                            backgroundColor: '#FFFFFF',
                            borderBottomLeftRadius: '200px',
                            borderTopLeftRadius: '200px',
                            paddingLeft: '30px',
                            borderRadius: '200px',
                            boxShadow: 'rgb(100 100 111 / 23%) 0px 0px 8px 2px',
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderBottomLeftRadius: '60px',
                              borderTopLeftRadius: '60px',
                              paddingLeft: '30px',
                            }}
                            className={classes.padding1}
                          >
                            <Typography align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                              <span
                                style={{
                                  width: '80px',
                                  margin: '0 0 0 15px',
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2,
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {row?.supportId && `SS${row?.supportId}`}
                              </span>
                            </Typography>
                          </TableCell>

                          <TableCell
                            component="th"
                            scope="row"
                            padding="none"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                            className={classes.padding1}
                          >
                            <Typography align="left" sx={{ backgroundColor: '#FFFFFF' }}>
                              <LightTooltip title={`${row.firstName} ${row.lastName}`} placement="bottom">
                                <span
                                  style={{
                                    width: '80px',
                                    display: '-webkit-box',
                                    overflow: 'hidden',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2,
                                    textOverflow: 'ellipsis',
                                    borderBottomLeftRadius: '200px',
                                    borderTopLeftRadius: '200px',
                                    wordBreak: 'break-all',
                                  }}
                                >
                                  {`${row?.firstName}...`}
                                </span>
                              </LightTooltip>
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ backgroundColor: '#FFFFFF', p: 0 }}
                            onClick={() => {
                              // setopenCol(!openCol);
                              idxClick(i);
                            }}
                            className={classes.padding1}
                          >
                            <LightTooltip title={row.email} placement="bottom">
                              <p
                                style={{
                                  width: '150px',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whitSpace: 'nowrap',
                                  cursor: 'pointer',
                                }}
                              >
                                {row?.email}
                              </p>
                            </LightTooltip>
                            {idx === i && openCol ? <CollapseShow openCol item={row} /> : null}
                          </TableCell>

                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }} className={classes.padding1}>
                            <LightTooltip title={row.description}>
                              <span
                                style={{
                                  // width: '200px',
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2,
                                  textOverflow: 'ellipsis',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {`${row.description.length < 15 ? row.description : row.description.slice(0, 15)}...`}
                              </span>
                            </LightTooltip>
                          </TableCell>

                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }} className={classes.padding1}>
                            <LightTooltip title={row.details}>
                              <span
                                style={{
                                  // width: '200px',
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 2,
                                  wordBreak: 'break-all',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {`${row.details.length < 15 ? row.details : row.details.slice(0, 15)}...`}
                              </span>
                            </LightTooltip>
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              fontWeight: 'bold',
                              color: StatusColor(row?.status.trim()),
                            }}
                            className={classes.padding1}
                          >
                            {row?.status}
                          </TableCell>

                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }} className={classes.padding1}>
                            {user.country === 'India'
                              ? moment(row?.updatedAt).format('DD/MM/yyyy, hh:mm A')
                              : moment(row?.updatedAt).format('MM/DD/yyyy, hh:mm A')}
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: '#FFFFFF' }} className={classes.padding1}>
                            {user.country === 'India'
                              ? moment(row?.createdAt).format('DD/MM/yyyy, hh:mm A')
                              : moment(row?.createdAt).format('MM/DD/yyyy, hh:mm A')}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              paddingRight: '30px',
                            }}
                            aria-hidden="true"
                            className={classes.padding1}
                          >
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              {row.attachment.length > 0 ? (
                                <AttachmentHOC
                                  childref={closeAttachmentPopUpRef}
                                  data={row.attachment.map((attach) => ({ url: attach }))}
                                />
                              ) : (
                                <Attachment
                                  width={34}
                                  height={34}
                                  color={'#027C34'}
                                  style={{
                                    backgroundColor: '#90EE90',
                                    paddingTop: 8,
                                    paddingBottom: 8,
                                    borderRadius: 30,
                                  }}
                                />
                              )}
                            </div>
                          </TableCell>

                          <TableCell
                            align="center"
                            sx={{
                              backgroundColor: '#FFFFFF',
                              borderBottomRightRadius: '60px',
                              borderTopRightRadius: '60px',
                            }}
                            className={classes.padding1}
                          >
                            <Button
                              onClick={(e) => {
                                e.preventDefault();
                                setEditData(row?._id);
                                setEdit('edit');
                                handleClickOpen();
                              }}
                            >
                              <Iconify
                                icon="material-symbols:sms"
                                color="#027C34"
                                width={34}
                                height={34}
                                style={{
                                  backgroundColor: '#90EE90',
                                  paddingTop: 8,
                                  paddingBottom: 8,
                                  borderRadius: 30,
                                }}
                              />
                            </Button>
                            <p style={{ marginTop: '-7px' }}>{row?.feedback?.length}</p>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                {/* {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
              </TableBody>

              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
            <ActionMenu
              row={editData}
              actionMenu={actionMenu}
              actionOpen={actionOpen}
              handleActionClick={handleActionClick}
              handleActionClose={handleActionClose}
              handleClickOpen={handleClickOpen}
              setSelected={setSelected}
              setEditData={setEditData}
              isDelete={false}
            />

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={feedback.pagination[0]?.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Scrollbar>
        </TableContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 20,
            marginTop: 40,
            width: '100%',
          }}
        >
          <Button
            id="demo-customized-button"
            aria-haspopup="true"
            variant="text"
            onClick={() => ExcelDownload({ id: null, toDate: '', fromDate: '', search: {} })}
            startIcon={
              <Fab
                aria-label="add"
                size="small"
                sx={{
                  backgroundColor: '#FFFFFF',
                }}
              >
                <img src="/assets/svgs/csv.svg" alt="downloadImage" width={28} height={28} />
              </Fab>
            }
            sx={{
              backgroundColor: '#027C34',
              color: '#FFFFFF',
              borderRadius: '30px',
              height: '50px',
              textTransform: 'none',
            }}
          >
            <Typography sx={{ pl: 3, pr: 3, fontWeight: 700 }}>Download in CSV</Typography>
          </Button>

          <Button
            id="demo-customized-button"
            aria-haspopup="true"
            variant="text"
            // onClick={() => window.open(`${process.env.REACT_APP_API_URL}/feedback/feedback-pdf/?from=&to=&search={}`)}
            onClick={() => PDFDownload({ id: null, toDate: '', fromDate: '', search: {} })}
            startIcon={
              <Fab
                aria-label="add"
                size="small"
                sx={{
                  backgroundColor: '#FFFFFF',
                }}
              >
                <img src="/assets/svgs/pdf.svg" alt="downloadImage" width={28} height={28} />
              </Fab>
            }
            sx={{
              backgroundColor: '#027C34',
              color: '#FFFFFF',
              borderRadius: '30px',
              height: '50px',
              textTransform: 'none',
            }}
          >
            <Typography sx={{ pl: 3, pr: 3, fontWeight: 700 }}>Download in PDF</Typography>
          </Button>
          <Button
            id="demo-customized-button"
            aria-haspopup="true"
            variant="text"
            onClick={() => TxtDownload({ id: null, toDate: '', fromDate: '', search: {} })}
            startIcon={
              <Fab
                aria-label="add"
                size="small"
                sx={{
                  backgroundColor: '#FFFFFF',
                }}
              >
                <img src="/assets/svgs/txt.svg" alt="downloadImage" width={28} height={28} />
              </Fab>
            }
            sx={{
              backgroundColor: '#027C34',
              color: '#FFFFFF',
              borderRadius: '30px',
              height: '50px',
              textTransform: 'none',
            }}
          >
            <Typography sx={{ pl: 3, pr: 3, fontWeight: 700 }}>Download in TXT</Typography>
          </Button>
          <Button
            id="demo-customized-button"
            aria-haspopup="true"
            variant="text"
            onClick={() => TabDownload({ id: null, toDate: '', fromDate: '', search: {} })}
            startIcon={
              <Fab
                aria-label="add"
                size="small"
                sx={{
                  backgroundColor: '#FFFFFF',
                }}
              >
                <img src="/assets/svgs/tab.svg" alt="downloadImage" width={28} height={28} />
              </Fab>
            }
            sx={{
              backgroundColor: '#027C34',
              color: '#FFFFFF',
              borderRadius: '30px',
              height: '50px',
              textTransform: 'none',
            }}
          >
            <Typography sx={{ pl: 3, pr: 3, fontWeight: 700 }}>Download in TAB Limited File</Typography>
          </Button>
          {/* </Grid> */}
          {/* </Grid> */}
        </div>
      </div>
    </Page>
  );
};

const AttachmentHOC = (props) => {
  const attachmentRef = useRef(null);
  const [openDownload, setOpenDownload] = useState(false);

  const handleClickOutside = (event) => {
    if (attachmentRef.current && !attachmentRef.current.contains(event.target)) {
      handleCloseDownload();
    }
  };
  useEffect(() => {
    console.log(' props.childref ================>', props);
    props.childref.current = handleCloseDownload;
  }, []);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickDownload = (event) => {
    setOpenDownload(event?.currentTarget);
  };

  const handleCloseDownload = () => {
    setOpenDownload(null);
  };
  return (
    <div ref={attachmentRef}>
      <Button onClick={(e) => handleClickDownload(e)}>
        <Attachment
          openDownload={openDownload}
          setOpenDownload={setOpenDownload}
          handleCloseDownload={handleCloseDownload}
          {...props}
          width={34}
          height={34}
          color={'#027C34'}
          style={{
            backgroundColor: '#90EE90',
            paddingTop: 8,
            paddingBottom: 8,
            borderRadius: 30,
          }}
        />
      </Button>
    </div>
  );
};

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  })
);

export default AdminUserFeedback;

const useStyles = makeStyles({
  padding1: {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    paddingRight: 5,
    paddingLeft: 5,
  },
});
