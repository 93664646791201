/* eslint-disable */
import * as React from 'react';
import { collection, where, getDocs, query } from 'firebase/firestore';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import UserFeedbackModal from '../Modals/UserFeedbackModal';
import UserChatModal from '../Modals/UserChatModal';
import SupportChatModal from './SupportChatModal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-hot-toast';
import { changeChatResponsiveToggle } from '../../store/ToggleSlice';
import { db } from '../../firebase';
import Swal from 'sweetalert2';
import NewChatAndCall from '../NewChatAndCall/NewChatAndCall';
import { useNewUserChatListListener } from '../../hooks/useNewChatListener';
import FaqModal from './FaqModal';
import FaqAIModal from '../FaqAIModal/FaqAIModal';
import FeedbackLogModal from './FeedbackLogModal';
import { getUserAssessmentUser } from '../../services/Service';
import UserAssessmentModal from './UserAssessmentModal';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '.MuiBackdrop-root': {
    background: 'transparent',
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialog-paper': {
    borderRadius: 22,
    position: 'absolute',
    right: '50px',
    bottom: '50px',
  },
  '& .MuiDialogTitle-root': {
    background: 'linear-gradient(180deg, #02AD50 0%, #00C45A 100%)',
    color: '#fff',
  },
}));

function BootstrapDialogTitle(props) {
  const { children, handleClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2, fontWeight: 'bold' }} {...other}>
      {children}
      {handleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#fff',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BotModal.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
};

function BotModal({ open, handleClose }) {
  const navigate = useNavigate();
  const [feedback, setFeedback] = React.useState(false);
  const [isSubmited, setSubmited] = React.useState(null);
  const [userChat, setUserChat] = React.useState(false);
  const [supportChat, setSupportChat] = React.useState(false);
  const [feedbackLogOpen, setFeedbackLogOpen] = React.useState(false);
  const [faqAIOpen, setFaqAIOpen] = React.useState(false);
  const [realTimeOnlineCount, setRealTimeOnlineCount] = React.useState(0);
  const { supportAlert, userAlert } = useSelector((state) => state.chats);
  const dynamicMenuState = useSelector((state) => state.dynamicMenuState);
  const login = useSelector((state) => state.login);
  const { registered } = login.user;
  const dispatch = useDispatch();
  const currentFirebaseUser = useSelector((state) => state.currentFirebaseUser);
  const userToUserChatEnable =
    currentFirebaseUser === null
      ? true
      : currentFirebaseUser.userToUserChat !== undefined
      ? currentFirebaseUser.userToUserChat
      : true;

  const handleFeedbackOpen = () => {
    if (!registered) {
      handleClose();
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    onFeedbackLogOpen();
    // setFeedback(true);
    // setSubmited(false);
  };
  const handleFeedbackClose = () => {
    setSubmited(false);
    setFeedback(false);
  };
  const handleUserChat = () => {
    if (!registered) {
      handleClose();
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }
    setUserChat((prev) => !prev);
  };
  const handleUserChatDisable = () => {
    toast('Please enable user to user chat.\nProfile icon => setting => chat');
  };
  const handleSupportChat = () => {
    if (!registered) {
      handleClose();
      Swal.fire('For added security, we kindly ask you to confirm your primary email before utilizing this feature.');
      return;
    }

    dispatch(changeChatResponsiveToggle(false));
    setSupportChat((prev) => !prev);
  };

  const fetchOnlineCount = async () => {
    try {
      const usersRef = collection(db, 'users');
      const list = [];
      const q = query(usersRef, where('isOnline', '==', true));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => list.push(doc.data()));
      setRealTimeOnlineCount(list.length);
    } catch (e) {
      setRealTimeOnlineCount(0);
    }
  };

  const safalBot_DynamicMenuState = dynamicMenuState?.find((e) => e.menu === 'safal bot');
  const chatWithSupport_DynamicMenuState =
    safalBot_DynamicMenuState && safalBot_DynamicMenuState?.submenu.find((e) => e === 'chat with support');
  const createFeedback_DynamicMenuState =
    safalBot_DynamicMenuState && safalBot_DynamicMenuState?.submenu.find((e) => e === 'create feedback');
  const chatWithOtherUsers_DynamicMenuState =
    safalBot_DynamicMenuState && safalBot_DynamicMenuState?.submenu.find((e) => e === 'chat with other users');
  const helpOnApplication_DynamicMenuState =
    safalBot_DynamicMenuState && safalBot_DynamicMenuState?.submenu.find((e) => e === 'help on application');
  const faqAi_DynamicMenuState =
    safalBot_DynamicMenuState && safalBot_DynamicMenuState?.submenu.find((e) => e === 'FAQ AI');

  // this is the New and Refresh chat design
  // if (registered && chatWithOtherUsers_DynamicMenuState) useNewUserChatListListener();

  React.useEffect(() => {
    if (open) fetchOnlineCount();
    fetchUserAssessmentUser();
  }, [open]);

  const [openUserAssessmentModal, setOpenUserAssessmentModal] = React.useState(false);
  const [isUserAssessmentModal, setIsUserAssessmentModal] = React.useState(false);
  const onCloseUserAssessmentModal = () => {
    setOpenUserAssessmentModal(false);
  };
  const onOpenUserAssessmentModal = async () => {
    if (isUserAssessmentModal) {
      setOpenUserAssessmentModal(true);
      return;
    }
    const swalResult = await Swal.fire({
      customClass: {
        container: 'my-swal', // Add a custom class for styling
      },
      title: 'User Assessment',
      text: `You have already participated in assessment.`,
      focusConfirm: false,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'View Report',
      confirmButtonAriaLabel: 'View Report',
      cancelButtonText: `Close`,
    });
    if (swalResult.isConfirmed) {
      handleClose();
      navigate('/reports/?page=User Assessment');
    }
  };
  const fetchUserAssessmentUser = async () => {
    try {
      const res = await getUserAssessmentUser({ scenario: 'MonthlyFeedback' });
      const notHaveAns = res.data.data?.filter((e) => !e?.answer);
      if (notHaveAns?.length > 0) {
        setIsUserAssessmentModal(true);
      } else {
        setIsUserAssessmentModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickHelp = () => {
    handleClose();
    navigate('/help');
  };
  const onClickFaq = () => {
    setFaqAIOpen(true);
  };

  const onFeedbackLogOpen = () => {
    setFeedbackLogOpen(true);
  };
  const onFeedbackLogClose = () => {
    setFeedbackLogOpen(false);
  };

  return (
    <>
      <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          style={{ display: 'flex', alignItems: 'center' }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          SafalBot <FaqModal module="SafalBot" color="white" />
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {helpOnApplication_DynamicMenuState && (
              <ListItem
                alignItems="flex-start"
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(145, 158, 171, 0.08)',
                  },
                  cursor: 'pointer',
                }}
                onClick={onClickHelp}
                secondaryAction={
                  <IconButton edge="end">
                    <ArrowForwardIosIcon style={{ fontSize: 14, color: '#02AD50' }} />
                  </IconButton>
                }
              >
                <ListItemText
                  style={{ minWidth: 200, padding: 6 }}
                  primary={
                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                      Application Help
                    </Typography>
                  }
                />
              </ListItem>
            )}
            <Divider variant="middle" />
            {faqAi_DynamicMenuState && (
              <ListItem
                alignItems="flex-start"
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(145, 158, 171, 0.08)',
                  },
                  cursor: 'pointer',
                }}
                onClick={onClickFaq}
                secondaryAction={
                  <IconButton edge="end">
                    <ArrowForwardIosIcon style={{ fontSize: 14, color: '#02AD50' }} />
                  </IconButton>
                }
              >
                <ListItemText
                  style={{ minWidth: 200, padding: 6 }}
                  primary={
                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                      FAQ's AI
                    </Typography>
                  }
                />
              </ListItem>
            )}
            {/* User Assessment */}
            <Divider variant="middle" />
            <ListItem
              alignItems="flex-start"
              sx={{
                '&:hover': {
                  backgroundColor: 'rgba(145, 158, 171, 0.08)',
                },
                cursor: 'pointer',
              }}
              onClick={onOpenUserAssessmentModal}
              secondaryAction={
                <IconButton edge="end">
                  <ArrowForwardIosIcon style={{ fontSize: 14, color: '#02AD50' }} />
                </IconButton>
              }
            >
              <ListItemText
                style={{ minWidth: 200, padding: 6 }}
                primary={
                  <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                    User Assessment
                  </Typography>
                }
              />
            </ListItem>
            <Divider variant="middle" />
            {createFeedback_DynamicMenuState && (
              <ListItem
                alignItems="flex-start"
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(145, 158, 171, 0.08)',
                  },
                  cursor: 'pointer',
                }}
                onClick={handleFeedbackOpen}
                secondaryAction={
                  <IconButton edge="end">
                    <ArrowForwardIosIcon style={{ fontSize: 14, color: '#02AD50' }} />
                  </IconButton>
                }
              >
                <ListItemText
                  style={{ minWidth: 200, padding: 6 }}
                  primary={
                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                      Feedback Log
                    </Typography>
                  }
                />
              </ListItem>
            )}
            <Divider variant="middle" />
            {chatWithSupport_DynamicMenuState && (
              <ListItem
                alignItems="flex-start"
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(145, 158, 171, 0.08)',
                  },
                  cursor: 'pointer',
                }}
                onClick={handleSupportChat}
                secondaryAction={
                  <IconButton edge="end">
                    <ArrowForwardIosIcon style={{ fontSize: 14, color: '#02AD50' }} />
                  </IconButton>
                }
              >
                <ListItemText
                  style={{ minWidth: 200, padding: 6 }}
                  primary={
                    <Typography sx={{ display: 'flex' }} component="span" variant="body2" color="text.primary">
                      Connect with Support team{' '}
                      {supportAlert && (
                        <p
                          style={{
                            display: 'block',
                            backgroundColor: 'red',
                            height: 8,
                            width: 8,
                            borderRadius: '50%',
                            marginTop: '-2px',
                            marginLeft: '2px',
                          }}
                        />
                      )}
                      {/*---- NOTIFICATION dot ---- */}
                    </Typography>
                  }
                />
              </ListItem>
            )}
            <Divider variant="middle" />
            {chatWithOtherUsers_DynamicMenuState && (
              <>
                <ListItem
                  alignItems="flex-start"
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(145, 158, 171, 0.08)',
                    },
                    cursor: 'pointer',
                  }}
                  onClick={handleUserChat}
                  secondaryAction={
                    <IconButton edge="end">
                      <ArrowForwardIosIcon style={{ fontSize: 14, color: '#02AD50' }} />
                    </IconButton>
                  }
                >
                  <ListItemText
                    style={{ minWidth: 200, padding: 6 }}
                    primary={
                      <Typography sx={{ display: 'flex' }} component="span" variant="body2" color="text.primary">
                        Connect with SafalSubscriptions Users ({`${realTimeOnlineCount}`} Online)
                        {userAlert && (
                          <p
                            style={{
                              display: 'block',
                              backgroundColor: 'red',
                              height: 8,
                              width: 8,
                              borderRadius: '50%',
                              marginTop: '-2px',
                              marginLeft: '2px',
                            }}
                          />
                        )}
                        {/*---- NOTIFICATION dot ---- */}
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider variant="middle" />
              </>
            )}
          </List>
        </DialogContent>
      </BootstrapDialog>
      <UserFeedbackModal
        open={feedback}
        handleClose={handleFeedbackClose}
        isSubmited={isSubmited}
        setSubmited={setSubmited}
      />
      <UserChatModal open={userChat} handleClose={handleUserChat} />
      <SupportChatModal open={supportChat} handleClose={handleSupportChat} />
      <FaqAIModal open={faqAIOpen} onClose={() => setFaqAIOpen(false)} />
      <FeedbackLogModal open={feedbackLogOpen} onClose={onFeedbackLogClose} />
      {/*  this is the New and Refresh chat design */}
      {/* <NewChatAndCall open /> */}
      <UserAssessmentModal
        refetch={fetchUserAssessmentUser}
        scenario="MonthlyFeedback"
        open={openUserAssessmentModal}
        onClose={onCloseUserAssessmentModal}
      />
    </>
  );
}

export default BotModal;
