/* eslint-disable no-continue */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
export const convertJSONToFormData = (jsonData, form = null, namespace = '') => {
  const formData = form || new FormData();

  for (const propertyName in jsonData) {
    if (!jsonData.hasOwnProperty(propertyName) || !jsonData[propertyName]) continue;
    const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (jsonData[propertyName] instanceof Date) formData.append(formKey, jsonData[propertyName].toISOString());
    else if (jsonData[propertyName] instanceof Array) {
      jsonData[propertyName].forEach((element, index) => {
        const tempFormKey = `${formKey}[${index}]`;
        convertJSONToFormData(element, formData, tempFormKey);
      });
    } else if (typeof jsonData[propertyName] === 'object' && !(jsonData[propertyName] instanceof File))
      convertJSONToFormData(jsonData[propertyName], formData, formKey);
    else formData.append(formKey, jsonData[propertyName].toString());
  }
  return formData;
};

5;

/* Helper function */
export const downloadFile = (fileURL, fileName) => {
  // for non-IE
  const save = document.createElement('a');
  save.href = fileURL;
  save.target = '_blank';
  const filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
  save.download = fileName || filename;
  document.location = save.href;
  // window event not working here
};

// export const debounce = (fn, delay) => {
//   let timerId = null;
//   return (...args) => {
//     clearTimeout(timerId);
//     timerId = setTimeout(() => fn(...args), delay);
//   };
// };

export const debounce = (func, delay) => {
  let timeoutId;

  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      func.apply(null, args);
    }, delay);
  };
};

/* eslint-disable */
export const sayswho = () => {
  try {
    let ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  } catch (err) {
    console.log(err.message);
    return 'Web';
  }
};
