import * as React from 'react';
import Box from '@mui/material/Box';
import { collection, query, where, getDocs, setDoc, updateDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';
import Chat from '../../components/Chat/Chat';
import { changeChatMode, changeSupport } from '../../store/ChatSlice';
import { db } from '../../firebase';
import Sidebar from '../../components/Chat/Sidebar';
import Page from '../../components/Page';

const SupportTeamChat = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { chatId, chatMode } = useSelector((state) => state.chats);
  const { currentUser } = useSelector((state) => state.auth);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
    borderRadius: '10px',
    height: '90%',
    display: 'flex',
    overflow: 'hidden',
  };

  // const chanegePerson = async () => {
  //     const q = query(collection(db, 'support'))
  //     try{
  //         const supportTeam = []
  //         const querySnapshot = await getDocs(q)
  //         querySnapshot.forEach(doc => {
  //             supportTeam.push(doc.data())
  //         })
  //         dispatch(changeSupport(supportTeam))
  //     } catch(e) {
  //         console.log(e)
  //     }
  // }

  React.useEffect(() => {
    dispatch(changeChatMode('support'));
    // chanegePerson()
    // createChatRoom()
  }, [open]);

  const createChatRoom = async () => {
    try {
      // check whether the group exists, if not CREATE
      const res = await getDoc(doc(db, 'chats', chatId));

      if (!res.exists()) {
        await setDoc(doc(db, 'chats', chatId), { messages: [] });

        console.log(chatId);

        // create user chats
        await updateDoc(doc(db, `${chatMode}Chats`, currentUser?.uid), {
          [`${chatId}.userInfo`]: {
            uid: currentUser?.uid,
            displayName: currentUser?.displayName,
            photo: currentUser?.photoURL,
          },
          [`${chatId}.date`]: serverTimestamp(),
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Page title="Support Chat - SafalSubscriptions">
      <Box sx={style}>
        <Sidebar />
        <Chat open={open} handleClose={handleClose} isSupport={'true'} />
      </Box>
    </Page>
  );
};

export default SupportTeamChat;
