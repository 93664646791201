/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { getSafalBuddyByOtherUserId, getSignedURL } from '../../services/Service';
import { UserNewProfileModal } from '../Modals/UserNewProfileModal';

const DisplayAvatar = ({ uid, isSupport, buddyProfile }) => {
  const [image, setImage] = useState(
    isSupport ? 'assets/images/SupportAvatar.png' : 'assets/images/UserAvtarBackground.png'
  );
  const [isBuddy, setIsBuddy] = useState(false);

  const fetchBuddy = async () => {
    try {
      const res = await getSafalBuddyByOtherUserId({ otherUserId: uid });
      if (res.status === 200 && res?.data?.success === true) {
        if (res.data?.data?.status === 'accept') setIsBuddy(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!isSupport) {
      if (uid) getImage();
    } else {
      setImage('assets/images/SupportAvatar.png');
    }
    if (uid) fetchBuddy();
  }, [uid]);

  const getImage = async () => {
    try {
      const res = await getDoc(doc(db, 'users', uid));
      let photoURL = '';
      if (res?.data()?.photoURL) {
        photoURL = res?.data()?.photoURL || '';
        if (photoURL.includes('safalsubscriptions-assets.s3.amazonaws.com')) {
          const newRes = await getSignedURL({ url: photoURL });
          setImage(newRes.data);
        }
      }
    } catch (err) {
      setImage('assets/images/UserAvtarBackground.png');
      console.log(err);
    }
  };

  const [openUserNewProfileModal, setOpenUserNewProfileModal] = useState({
    open: false,
    data: null,
  });
  const openUserNewProfileOpen = (userId) => {
    setOpenUserNewProfileModal({
      open: true,
      data: userId,
    });
  };
  const openUserNewProfileClose = () => {
    setOpenUserNewProfileModal({
      open: false,
      data: null,
    });
  };

  return (
    <>
      <img
        onClick={buddyProfile && (() => openUserNewProfileOpen(uid))}
        src={image}
        style={{
          flex: 'none',
          height: isBuddy ? '46px' : '50px',
          width: isBuddy ? '46px' : '50px',
          borderRadius: '9999px',
          objectFit: 'fill',
          border: isBuddy ? '2px solid #0072e2' : 'none',
          cursor: buddyProfile && 'pointer',
        }}
        alt="profile"
      />
      {buddyProfile && (
        <UserNewProfileModal
          open={openUserNewProfileModal.open}
          userId={openUserNewProfileModal.data}
          onClose={openUserNewProfileClose}
        />
      )}
    </>
  );
};

export default DisplayAvatar;
